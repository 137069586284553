import { createSlice } from "@reduxjs/toolkit";

const newAutomaticSalesInitialValue = {
  name: "",
  startDate: new Date(),
  endDate: null,
  issueInvoiceType: "", // days, dayofmonth
  day: "",
  dayofmonth: "",
  dayofmonthName: "",
  invoiceDataType: "", // new, existing
  newDocument: "",
  salesTemplate: "",
  salesTemplateName: "",
  extra: "",
  logs: "",
  files: "",
  notes: "",
  documentType: "",
  documentTypeName: "",
  client: "",
  clientName: "",
  isActive: "active",
};

const initialState = {
  automaticSales: [],
  searchAutomaticSales: [],
  totalItemsASS: 0,
  perPageASS: 0,
  newAutomaticSalesInitialValue: newAutomaticSalesInitialValue,
  newAutomaticSales: newAutomaticSalesInitialValue,
  editAutomaticSales: newAutomaticSalesInitialValue,
};

export const AutomaticSalesSlice = createSlice({
  name: "AutomaticSales",
  initialState,
  reducers: {
    setAutomaticSales: (state, { payload }) => {
      state.automaticSales = payload;
    },
    setNewAutomaticSales: (state, { payload }) => {
      state.newAutomaticSales = payload;
    },
    resetNewAutomaticSales: (state) => {
      state.newAutomaticSales = state.newAutomaticSalesInitialValue;
    },
    setEditAutomaticSales: (state, { payload }) => {
      state.editAutomaticSales = payload;
    },
    setAutomaticSalesSearch: (state, { payload }) => {
      state.searchAutomaticSales = payload;
    },
    setTotalItemsASS: (state, { payload }) => {
      state.totalItemsASS = payload;
    },
    setPerPageASS: (state, { payload }) => {
      state.perPageASS = payload;
    },
    resetAutomaticSalesSearch: (state) => {
      state.searchAutomaticSales = [];
    },
  },
});

export const {
  setAutomaticSales,
  setNewAutomaticSales,
  resetNewAutomaticSales,
  setAutomaticSalesSearch,
  setPerPageASS,
  setTotalItemsASS,
  setEditAutomaticSales,
} = AutomaticSalesSlice.actions;
