import React, {useEffect} from "react";
import SearchTable from "../../../primer/Search-Table";
import {useSelector, useDispatch} from "react-redux";
import {getCurrentUser, getOptionsByData} from '../../../../_helpers/commonFunctions';
import {
    fetchAdvancedTypesBuys,
    fetchBuysGeneralSettings,
} from "../../../../_apis/api";
import {useTranslation} from "react-i18next";
import {classicStyleBelowNavbar} from "../Statics";

function BuysSearch() {
    const [t] = useTranslation('common');
    const dispatch = useDispatch();
    const BUYS_SETTINGS = useSelector((state) => state.BUYS_SETTINGS);
    const SETTINGS = useSelector((state) => state.SETTINGS);
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const documentTypesOptions = getOptionsByData(SETTINGS.settings.advancedTypesBuys, "_id", "name");
    const paymentTypeOptions = getOptionsByData(BUYS_SETTINGS.buysGeneralSettings.paymentTypes, "_id", "name");
    const requestData = {
        company: company.id,
        year: company.year.toString(),
    };
    const currentUser = getCurrentUser();
    const requestData2 = {
        company: company.id,
        year: company.year.toString(),
        isActive: "active",
        userId: currentUser.id
    };
    useEffect(() => {
        dispatch(fetchAdvancedTypesBuys(requestData));
        dispatch(fetchBuysGeneralSettings(requestData2))
    }, [dispatch]);

    const ssColumns = [
        {
            name: t("General.date"),
            field: "date",
            width: "100px",
            editable: false,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t("General.supplier"),
            field: "supplierName",
            editable: false,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t("General.documentType"),
            field: "documentTypeName",
            width: "250px",
            editable: false,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t("General.number"),
            field: "preNumberAndNumber",
            width: "100px",
            editable: false,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t("General.totalNetValue"),
            field: "totalNetValue",
            width: "100px",
            editable: true,
            disabled: true,
            inputType: {
                type: 'accounting',
                config: {
                    suffix: "€",
                }
            }
        },
        {
            name: t("General.totalVatValue"),
            field: "totalVatValue",
            width: "100px",
            editable: true,
            disabled: true,
            inputType: {
                type: 'accounting',
                config: {
                    suffix: "€",
                }
            }
        },
        {
            name: t("General.totalValue"),
            field: "totalAmount",
            width: "100px",
            editable: true,
            disabled: true,
            inputType: {
                type: 'accounting',
                config: {
                    suffix: "€",
                }
            }
        },
        {
            name: t('General.myDataStatus'),
            field: "myDataStatus",
            width: "100px",
            editable: false,
            inputType: {
                type: 'text',
            }
        }
    ]

    const searchBuyFilters = {
        filters: [
            {
                name: "documentType",
                label: t("General.documentType"),
                type: "multiselect",
                options: documentTypesOptions
            },
            {
                name: "supplierName",
                label: t("General.supplierNameVAT"),
                type: "input",
            },
            {
                name: "totalSum_from",
                label: t("General.totalSumFrom"),
                type: "input",
            },
            {
                name: "totalSum_to",
                label: t("General.totalSumTo"),
                type: "input",
            },
            {
                name: "preNumber_from",
                label: t("General.preNumberFrom"),
                type: "input",
            },
            {
                name: "preNumber_to",
                label: t("General.preNumberTo"),
                type: "input",
            },
            {
                name: "number_from",
                label: t("General.numberFrom"),
                type: "input",
            },
            {
                name: "number_to",
                label: t("General.numberTo"),
                type: "input",
            },
            {
                name: "paymentWay",
                label: t("General.paymentType"),
                type: "multiselect",
                options: paymentTypeOptions
            },
            {
                name: "notes",
                label: t("General.notes"),
                type: "input"
            }
        ],
    };

    return (
        <div style={classicStyleBelowNavbar}>
            <SearchTable
                tableName="Buys Search"
                key={"Buys Search"}
                hideYearFilter={false}
                allowPagination={true}
                sendSelectedToMyData={true}
                columns={ssColumns}
                data={[]}
                filtersConfig={searchBuyFilters}
            />
        </div>
    )
}

export default BuysSearch;
