import React from "react";
import Input from "../../../common/Input";
import { Row, Col, Container } from "react-bootstrap";
import Dropdown from "../../../primer/Dropdown";
import { typeAffectsOptions } from "../../../../_data/settings/doc-types";
import { useSelector, useDispatch } from "react-redux";
import { setSaveEditBasicType } from "../../../../_reducers/activitySlice";
import { setSettings } from "../../../../_reducers/settingsSlice";
import { getDefaultOptionObject } from "../../../../_helpers/commonFunctions";
import { isValueAlreadyExists } from "../../../../_helpers/helperFunctions";
import { useTranslation } from "react-i18next";
import LogTable from "../../../primer/LogTable";

export const EditBasicType = () => {
  const [t] = useTranslation('common');
  const dispatch = useDispatch();
  const ACTIVITY = useSelector((state) => state.ACTIVITY);
  const SETTINGS = useSelector((state) => state.SETTINGS);
  const handleOnChange = (e, type = "default", ddname = '') => {
    // Set if editing the form
    if (!ACTIVITY.saveEditBasicType) dispatch(setSaveEditBasicType(true));

    let name;
    let value;
    if (type === 'dd') {
      name = ddname;
      value = e.value;
    } else {
      name = e.target.name;
      value = e.target.value;
    }
    // Set settings data
    let newSettings = Object.assign({}, SETTINGS.settings);
    let newSettingsData = {};
    newSettingsData[name] = value;
    newSettings.editBasicType = {...newSettings.editBasicType, ...newSettingsData}
    dispatch(setSettings(newSettings));
  }

  let isNameAlreadyExists = isValueAlreadyExists(SETTINGS.settings.basicTypes, SETTINGS.settings.editBasicType.name, 'name', SETTINGS.settings.editBasicType._id);

  return (
      <Container fluid className="p-0">
        <Row>
          <Col sm="12" md="6">
            <Input
                label={t('DocumentTypeBasicNew.name.name')}
                name="name"
                value={SETTINGS.settings.editBasicType.name}
                placeholder={t('DocumentTypeBasicNew.name.placeholder')}
                required={true}
                charLimit="20"
                onChange={(e) => handleOnChange(e, 'default', 'name')}
            />
            {SETTINGS.settings.editBasicType.name === '' && ACTIVITY.saveEditBasicType &&
                <span className="text-danger">Name is a required field.</span>}
            {isNameAlreadyExists &&
                SETTINGS.settings.editBasicType.name !== '' &&
                <div className="text-danger">Name already exists.</div>
            }
          </Col>
          <br/>
          <Col sm="12" md="6">
            <Dropdown
                label={t('DocumentTypeBasicNew.typeAffectsInventory')}
                options={typeAffectsOptions}
                name="affects_inventory"
                searchEnable={true}
                enabledValue={true}
                required={true}
                value={getDefaultOptionObject(SETTINGS.settings.editBasicType.affects_inventory)}
                onChange={(e) => handleOnChange(e, 'dd', 'affects_inventory')}
            />
          </Col>
          <br/>
          <Col sm="12" md="6">
            <Dropdown
                label={t('DocumentTypeBasicNew.typeAffectsClient')}
                options={typeAffectsOptions}
                enabledValue={true}
                searchEnable={true}
                required={true}
                value={getDefaultOptionObject(SETTINGS.settings.editBasicType.affects_client)}
                name="affects_client"
                onChange={(e) => handleOnChange(e, 'dd', 'affects_client')}
            />
          </Col>
          <br/>
          <Col sm="12" md="6">
            <Dropdown
                label={t('DocumentTypeBasicNew.typeAffectsSupplier')}
                options={typeAffectsOptions}
                name="affects_supplier"
                searchEnable={true}
                enabledValue={true}
                required={true}
                value={getDefaultOptionObject(SETTINGS.settings.editBasicType.affects_supplier)}
                onChange={(e) => handleOnChange(e, 'dd', 'affects_supplier')}
            />
          </Col>
        </Row>
      </Container>
  );
}

export const EditBasicTypeLogTable = () => {
  const { t } = useTranslation('common');
  const SETTINGS = useSelector((state) => state.SETTINGS.settings);

  return (
      <React.Fragment>
        <div className="text-muted mb-2"><i>{t('Logs.settingBasicLog')}</i></div>
        <LogTable itemId={SETTINGS.editBasicType._id}/>
      </React.Fragment>
  )
}
