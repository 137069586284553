import React, {useState} from "react";
import {useSelector} from "react-redux";
import {Button, Modal} from "react-bootstrap";
import {isEmpty} from "../../../_helpers/commonFunctions";

const AccountingRecordWarning = (props) => {
    //props.data for table row data
    const [show, setShow] = useState(false);
    const TABS_DATA = useSelector((state) => state.TABS_REDUCER);
    const ACTIVE_MAIN_TAB = useSelector((state) => state.ACTIVE_MAIN_TAB);
    const ACCOUNTING_DATA = useSelector( (state) => state.ACCOUNTING_DATA);

    const [accountData, setAccountData] = useState(props.data);

    const getDocData = () => {
        if (TABS_DATA[ACTIVE_MAIN_TAB] === "accounting-record-new") {
            return ACCOUNTING_DATA.newAccountingRecord;
        } else if (TABS_DATA[ACTIVE_MAIN_TAB] === "accounting-record-edit") {
            return ACCOUNTING_DATA.editAccountingRecord;
        } else if (TABS_DATA[ACTIVE_MAIN_TAB] === "accounting-record-settings") {
            return null;
        }
    }

    const showError = () => {
        let hasError = false;
        if(getDocData()) {
            if (getDocData().myDataType && getDocData().myDataType !== "") {
                if (isEmpty(accountData.classificationCategory) && isEmpty(accountData.classificationType)) {
                    if (accountData.mydata && accountData.mydata?.length > 0) {
                        if (accountData.mydata.findIndex((el) => String(el.invoiceType) === getDocData().myDataType) === -1 && accountData.isNetValue !== "false") {
                            hasError = true;
                        } else if (accountData.isNetValue !== "false" && (isEmpty(accountData.classificationCategory) || isEmpty(accountData.classificationType))) {
                            hasError = true;
                        }
                    } else {
                        hasError = true;
                    }
                }
            }
        }
        return hasError;
    }

    const getFlattenedMyDataCodes = () => {
        let codes = "";
        if(accountData.mydata?.length > 0) {
            accountData.mydata.map((obj, ind) => {
                if(ind !== accountData.mydata.length - 1) {
                    codes += String(obj.invoiceType) + ", "
                } else {
                    codes += String(obj.invoiceType)
                }
            })
        }
        return codes;
    }

    return (
        <React.Fragment>
            {showError() && (
                <React.Fragment>
                    <Button style={{pointerEvents: "auto", padding: "2px"}} size={"sm"} variant={"default"} title={props.title ? props.title : "Warning"} onClick={() => {setShow(true)}}>
                        <img src={"assets/images/warning.png"}/>
                    </Button>

                    <Modal show={show} onHide={() => setShow(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Προσοχή!</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>Ο παρόν λογαριασμός δεν έχει κωδικό My Data όπως απαιτείται από το γενικό παραστατικό.<br/>
                            Συγκεκριμένα, το παραστατικό έχει κωδικό My Data {getDocData().myDataType},{" "}
                            {(!accountData.mydata || accountData?.mydata.length === 0) ? (
                                <React.Fragment>
                                    ενώ ο λογαριασμός δεν έχει κανέναν χαρακτηρισμό.{" "}
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    ενώ ο λογαριασμός έχει τους χαρακτηρισμούς: {getFlattenedMyDataCodes()}.{" "}
                                </React.Fragment>
                            )}
                            Δεν θα είναι δυνατή η αποστολή της παρούσας γραμμής ως γραμμή παραστατικού στο My Data.</Modal.Body>
                        <Modal.Footer>
                            <Button variant="outline-primary" onClick={() => setShow(false)}>Εντάξει</Button>
                        </Modal.Footer>
                    </Modal>
                </React.Fragment>
            )}
        </React.Fragment>
    )
}

export default AccountingRecordWarning