import React, { useState } from "react";
import {Button, Col, Modal, Row} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import jsPDF from "jspdf";
import { validate } from 'email-validator';
import Dropdown from "../../Dropdown";
import {
  checkPermission,
  getMultiSelectOptionsToArray
} from "../../../../_helpers/commonFunctions";
import Input from "../../../common/Input";
import {useTranslation} from 'react-i18next';
import {arial} from "../../../../fonts/ARIAL-normal";
const apiURL2 = process.env.REACT_APP_API_URL2;

const SendEmail = (props) => {
  const [t] = useTranslation('common');
  const dispatch = useDispatch();
  const COMPANY_DATA = useSelector((state) => state.COMPANY_DATA);
  const SALES_SETTINGS = useSelector( (state) => state.SALES_SETTINGS );
  const company = COMPANY_DATA.company;
  const requestData = {
    company: company.id,
    year: company.year.toString(),
  };
  const modalTitle = props.modalTitle ? props.modalTitle : t('SalesSearchEmailButton.title');
  const successMessage = props.successMsg ? props.successMsg : "Email sent succesfully!";
  let errorMessage = props.successMsg ? props.successMsg : "Email not sent!";
  const logType = props.logType ? props.logType : "editSalesLog";
  const mailParams = {
    from: props?.mailFrom ? props?.mailFrom?.trim() : company?.email?.trim(),
    to: props?.mailTo?.trim(),
    subject: props?.mailSubject ? props?.mailSubject?.trim() : SALES_SETTINGS?.salesEmailSettings?.subject?.trim(),
    mailType: props.mailType ? props.mailType : 'html',
    content: props.mailContent ? props?.mailContent?.trim() : SALES_SETTINGS?.salesEmailSettings?.emailBody?.trim(),
  }
  const [isMailSent, setIsMailSent] = useState(false);
  const [show, setShow] = useState(false);
  const [showMailSend, setShowMailSend] = useState(true);
  const [errorResponse, setErrorResponse] = useState(null);
  const [recipientEmails, setRecipientEmails] = useState([]);
  const [selectedRecipientEmails, setSelectedRecipientEmails] = useState([]);
  const [defaultRecipientEmails, setDefaultRecipientEmails] = useState([]);
  const [selectedCustomRecipientEmails, setSelectedCustomRecipientEmails] = useState([]);
  const [emailError, setEmailError] = useState(false);
  const [sendButton, setSendButton] = useState(false);
  const [showInputFields, setShowInputFields] = useState(true);
  const permissions = localStorage.getItem("permissions");
  const permissionsData = JSON.parse(permissions);
  const handleShow = async () => {
    if(props.clientId) {
      let tempSelectedRecipientEmails = [];
      let tempDefaultRecipientEmails = [];
      await axios.get(apiURL2 + `/client/get-client-id-by/${props.clientId}`)
          .then(async (res) => {
            if (res.data && res.data.status === "200") {
              if(res.data.data.contactPoints.length > 0){
                let contacts = [];
                await Promise.all(res.data.data.contactPoints.map( contact => {
                  if(contact.email && contact.email !== "") {
                    contacts.push({
                      label: (contact.name !== "" ? (contact.name + ",") : "") + (contact.mobile !== "" ? (contact.mobile + ",") : "") + (contact.landline !== "" ? (contact.landline + ",") : "") + contact.email,
                      value: contact.email
                    })
                    if(contact.acceptsNotifications === "true"){
                      setDefaultRecipientEmails([...tempDefaultRecipientEmails, {
                        label: (contact.name !== "" ? (contact.name + ",") : "") + (contact.mobile !== "" ? (contact.mobile + ",") : "") + (contact.landline !== "" ? (contact.landline + ",") : "") + contact.email,
                        value: contact.email
                      }])
                      tempDefaultRecipientEmails.push({
                        label: (contact.name !== "" ? (contact.name + ",") : "") + (contact.mobile !== "" ? (contact.mobile + ",") : "") + (contact.landline !== "" ? (contact.landline + ",") : "") + contact.email,
                        value: contact.email
                      })
                      setSelectedRecipientEmails([...tempSelectedRecipientEmails, contact.email])
                      tempSelectedRecipientEmails.push(contact.email)
                      setSendButton(true);
                    }
                  }
                  return contact;
                }))
                setRecipientEmails(contacts)
              }
            }
          }).catch((err) => {
            console.log(err);
          })
    } else if (props.supplierId) {
      let tempSelectedRecipientEmails = [];
      let tempDefaultRecipientEmails = [];
      await axios.get(apiURL2 + `/supplier/get-supplier-id-by/${props.supplierId}`)
          .then(async (res) => {
            if (res.data && res.data.status === "200") {
              if(res.data.data.contactPoints.length > 0){
                let contacts = [];
                await Promise.all(res.data.data.contactPoints.map( contact => {
                  if(contact.email && contact.email !== "") {
                    contacts.push({
                      label: (contact.name !== "" ? (contact.name + ",") : "") + (contact.mobile !== "" ? (contact.mobile + ",") : "") + (contact.landline !== "" ? (contact.landline + ",") : "") + contact.email,
                      value: contact.email
                    })
                    if(contact.acceptsNotifications === "true"){
                      setDefaultRecipientEmails([...tempDefaultRecipientEmails, {
                        label: (contact.name !== "" ? (contact.name + ",") : "") + (contact.mobile !== "" ? (contact.mobile + ",") : "") + (contact.landline !== "" ? (contact.landline + ",") : "") + contact.email,
                        value: contact.email
                      }])
                      tempDefaultRecipientEmails.push({
                        label: (contact.name !== "" ? (contact.name + ",") : "") + (contact.mobile !== "" ? (contact.mobile + ",") : "") + (contact.landline !== "" ? (contact.landline + ",") : "") + contact.email,
                        value: contact.email
                      })
                      setSelectedRecipientEmails([...tempSelectedRecipientEmails, contact.email])
                      tempSelectedRecipientEmails.push(contact.email)
                      setSendButton(true);
                    }
                  }
                  return contact;
                }))
                setRecipientEmails(contacts)
              }
            }
          }).catch((err) => {
            console.log(err);
          })
    }
    else {
      toast.error("No client/supplier details was found!");
    }
    setShow(true);
  }

  const handleClose = () => {
    setShow(false);
    setTimeout(() => {
      setIsMailSent(false);
      setShowMailSend(true);
      setShowInputFields(true);
      setDefaultRecipientEmails([]);
    }, 500)
  };

  const handleSendMail = async (data) => {
    let isValid = true;
    let elementsToShow;
    if(props.documentType === "sale") {
      elementsToShow = props.pdf.getElementsByClassName('sales-print-template');
    } else if (props.documentType === "buy") {
      elementsToShow = props.pdf.getElementsByClassName('buy-print-template');
    } else if (props.documentType === "clientPayment") {
      elementsToShow = props.pdf.getElementsByClassName('clientpayment-print-template');
    }
    if(elementsToShow.length > 0) {
      for (let i = 0; i < elementsToShow.length; i++) {
        let element = elementsToShow[i];
        if (element?.id !== undefined) {
          if(props.documentType === "sale") element.classList.add('sales-print-template-show');
          if(props.documentType === "buy") element.classList.add('buy-print-template-show');
          if(props.documentType === "clientPayment") element.classList.add('clientpayment-print-template-show');
        }
      }
    }
    let requestDataForm = new FormData();
    let recipientEmails =[...selectedRecipientEmails, ...selectedCustomRecipientEmails];
    requestDataForm.append("from", SALES_SETTINGS?.salesEmailSettings?.fromEmail ? SALES_SETTINGS?.salesEmailSettings?.fromEmail.trim() : company?.email?.trim())
    requestDataForm.append("to", [...new Set(recipientEmails)].join())
    requestDataForm.append("company", company?.id?.trim())
    requestDataForm.append("documentType", props?.documentType ? props?.documentType?.trim() : "")
    requestDataForm.append("documentId", props?.documentId ? props?.documentId?.trim() : "")
    const doc = new jsPDF();
    doc.addFileToVFS("arial.ttf", arial);
    doc.addFont("arial.ttf", "Arial", "normal");
    doc.setFont("Arial");
    doc.setFontSize(8);
    await doc.html(props.pdf, {
      callback: function (doc) {
        // Save the PDF
        requestDataForm.append("file", (doc.output('datauristring').split('base64,')[1]))
      },
      html2canvas: {scale: 0.215},
      x: 20,
    });
    // Send Mail API
    if (isValid && checkPermission("settings-generalsettings/emailsmtp-read", permissionsData) === true) {
      axios
          .post(`${apiURL2}/settings/send-email`, (requestDataForm))
          .then((res) => {
            if (res.status === 200) {
              if (res.data.status === '200' && res.data.message === "send email") {
                setIsMailSent(true); // Mail sent success.
                setShowInputFields(false);
              } else {
                setIsMailSent(false); // Mail sent failed.
                setShowInputFields(false);
              }
            }
            setShowMailSend(false);
            setShowInputFields(false);
          })
          .catch((error) => {
            setErrorResponse(error?.response?.data)
            setIsMailSent(false); // Mail sent failed.
            setShowMailSend(false);
            setShowInputFields(false);
          });
    } else {
      toast.error("You dont have the permission to send emails. Please contact Primer Software in order to get it.")
    }
    if(elementsToShow.length > 0) {
      for (let i = 0; i < elementsToShow.length; i++) {
        let element = elementsToShow[i];
        if (element?.id !== undefined) {
          if(props.documentType === "sale") element.classList.remove('sales-print-template-show');
          if(props.documentType === "buy") element.classList.remove('buy-print-template-show');
          if(props.documentType === "clientPayment") element.classList.remove('clientpayment-print-template-show');
        }
      }
    }
  }

  const handleOnFilterChange = (e, type="default", ddname = '') => {
    let value = '';
    let dropdownLength = 0;
    let inputLength = 0;

    if (type === 'msdd')  {
      value = getMultiSelectOptionsToArray(e);
      setSelectedRecipientEmails(value);
      if(value.length > 0){
        setSendButton(true);
        dropdownLength = 1;
      } else {
        dropdownLength = 0;
      }
    } else if (type === 'dd') {
      if(e.target.value.length > 0) {
        value = e.target.value.split(',');
        let customEmails = [];
        if (value.length > 0) {
          value.map(email => {
            let validateEmail = validate(email);
            if (validateEmail) {
              setEmailError(false);
              setSendButton(true);
              inputLength = 1;
              customEmails.push(email);
            } else {
              setEmailError(true);
              inputLength = 1;
              setSendButton(false);
            }
            return email;
          })
        } else {
          inputLength = 0;
          setEmailError(false);
        }
        setSelectedCustomRecipientEmails(customEmails);
      } else {
        setEmailError(false);
      }
    }
    if((dropdownLength === 0 && inputLength === 0)){
      setSendButton(false);
    }
  }

  return (
    <React.Fragment>

      <Button onClick={handleShow} type='button'>
        { props.btnLabel ? props.btnLabel : t('SalesSearchEmailButton.title') }
      </Button>

      <Modal className="themeModalMedium" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{ modalTitle }</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {showInputFields &&
              <>
          <Row>
            <Col>
              <label htmlFor="contactPoints" className="mb-0 mr-2 mt-2" >{t('SalesSearchEmailButton.message1.message1')} </label>
            </Col>
            <Col>
            <Dropdown
                multiSelect={true}
                options={recipientEmails}
                placeholder={t('SalesSearchEmailButton.message1.placeholder')}
                name={'contactPoints'}
                defaultValue={defaultRecipientEmails}
                onChange = {(e) => handleOnFilterChange(e, 'msdd', 'gf') }
            />
            </Col>
          </Row>
          <Row>
            <Col>
              <label htmlFor="contactPoints" className="mb-0 mr-2 mt-4" >{t('SalesSearchEmailButton.message2.message2')} </label>
            </Col>
            <Col>
              <Input
                  name="customEmails"
                  placeholder={t('SalesSearchEmailButton.message2.placeholder')}
                  type="text"
                  onChange = {(e) => handleOnFilterChange(e, 'dd', 'customEmails') }
              />
              {emailError === true && <div className="text-danger">{t('SalesSearchEmailButton.emailError')}</div>}
            </Col>
          </Row>
              </>
          }
          { /* showMailSend && modalContent */ }
          { !showMailSend && isMailSent &&
          <div className="text-success">{successMessage}</div>
          }
          { !showMailSend && !isMailSent &&
          <div className="text-danger">
            <p>{errorMessage}</p>
            <pre>{JSON.stringify(errorResponse)}</pre>
          </div>
          }
        </Modal.Body>
        {(selectedRecipientEmails.length === 0 && selectedCustomRecipientEmails.length === 0) && <div className="text-danger align-items-center d-flex justify-content-center">{t('SalesSearchEmailButton.required')}</div>}
        { showMailSend ?
          <Modal.Footer>
            <Button variant="outline-primary" onClick={ () => handleClose() }>{t('SalesSearchEmailButton.close')}</Button>
            <Button variant="primary" disabled={!sendButton} onClick={ () => handleSendMail(mailParams) } >{t('SalesSearchEmailButton.send')}</Button>
          </Modal.Footer>
          :
          <Modal.Footer>
            <Button onClick={ () => handleClose() }>Ok</Button>
          </Modal.Footer>
        }
      </Modal>
    </React.Fragment>
  );
};

export default SendEmail;
