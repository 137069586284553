import React, {useEffect, useState} from "react";
import axios from "axios";
import {Button, Col, Row} from "react-bootstrap";
import TextArea from "../../../../common/TextArea";
import Input from "../../../../common/Input";
import DateBox from "../../../../primer/DateBox";
import EditableTable from '../../../../primer/Editable-Table';
import Dropdown from "../../../../primer/Dropdown";
import {toast} from "react-toastify";
import {useDispatch, useSelector} from "react-redux";
import {setEditing} from "../../../../../_reducers/activitySlice";
import {fetchAdvancedTypes, fetchInitialClients} from "../../../../../_apis/api";
import {
  getDefaultOptionByValue,
  getGreekCurrencyFormat,
  getLastHighestPrefixNumber,
  getModeByInstallation,
  getNumberByInstallation,
  getOptionsByData,
  getPreNumberByInstallation,
  getSelectedInstallation,
  removeDuplicateObjects,
} from "../../../../../_helpers/commonFunctions";
import {
  getLabelByValue,
  getObjectFromArrayOfObjects
} from "../../../../../_helpers/helperFunctions";
import {
  setEditClientMovements,
} from "../../../../../_reducers/DataPanel/Clients/clientSlice";
import {useTranslation} from "react-i18next";
import {AccountingView} from "../../../../primer/AccountingView/AccountingView";
import LogTable from "../../../../primer/LogTable";

const apiURL2 = process.env.REACT_APP_API_URL2;

const EditClientMovements = () => {
  const [t] = useTranslation("common");
  const dispatch = useDispatch();
  const ACTIVITY = useSelector( (state) => state.ACTIVITY );
  const CLIENTS_DATA = useSelector( (state) => state.CLIENTS_DATA );
  const SETTINGS = useSelector( (state) => state.SETTINGS );
  const company = useSelector(state => state.COMPANY_DATA.company);
  const [incrKey, setIncrKey] = useState(0);
  const [firstLoad, setFirstLoad] = useState(true);

  useEffect(() => {
    if(firstLoad) {
      setFirstLoad(false);
      dispatch(fetchInitialClients(requestData));
      dispatch(fetchAdvancedTypes(requestData));
    }
  }, [firstLoad])

  useEffect(() => {
    const clientAmountSum = getSumOfDataValues(CLIENTS_DATA.editClientMovements.clients, 'amount');
    dispatch(setEditClientMovements({...CLIENTS_DATA.editClientMovements, sum: clientAmountSum}));
  }, [dispatch])

  useEffect(() => {
    setIncrKey(incrKey + 1);
  }, [CLIENTS_DATA.editClientMovements.clients, CLIENTS_DATA.clients])

  const clientCodeColumns = [
    {
      label: t('ClientsMovementsNew.code'),
      name: "systemCode",
    },
    {
      label: t('ClientsMovementsNew.name'),
      name: "name",
    }
  ];

  const clientMovementColumns = [
    {
      name: "No",
      field: "no",
      editable: false,
    },
    {
      name: t("ClientsMovementsNew.clientCode"),
      field: "systemCode",
      editable: true,
      inputType: {
        config: {
          excludeColumns: [],
          columns: clientCodeColumns,
          data: CLIENTS_DATA.clients,
          editEnable: false ,
          deleteEnable: false,
          searchTableEnable: false,
          showTableOptionOnSelect: true,
          placeholder: t('ClientsMovementsNew.placeholder1')
        },
        type: 'advanced-combobox',
      }
    },
    {
      name: t('ClientMovementEdit.table.clientName'),
      field: "name",
      editable: true,
      inputType: {
        config: {
          excludeColumns: [],
          columns: clientCodeColumns,
          data: CLIENTS_DATA.clients,
          editEnable: false ,
          deleteEnable: false,
          searchTableEnable: false,
          showTableOptionOnSelect: true,
          placeholder: t('ClientsMovementsNew.placeholder2')
        },
        type: 'advanced-combobox',
      }
    },
    {
      name: t('ClientMovementEdit.table.amount'),
      field: 'amount',
      editable: true,
      inputType: {
        type: 'accounting',
        config: {
          focused: true,
          suffix: "nosuffix",
          maxLength: 10,
          allowNegative: true,
        }
      }
    },
  ];

  // Options for Document Type Dropdown
  const allDocumentTypes = SETTINGS.settings.advancedTypes;
  const clientMovemnetDT = getObjectFromArrayOfObjects(allDocumentTypes, 'Client Movements', 'section');
  const documentTypesOptions =  getOptionsByData(clientMovemnetDT, '_id', 'name')

  const requestData = {
    company:company.id,
    year:company.year.toString(),
  }
  let selectedDocumentTypeData = [];
  let dtInstallationsOptions = [];
  let disabledFields = false;
  if (CLIENTS_DATA.editClientMovements.documentType === undefined || CLIENTS_DATA.editClientMovements.documentType === '') {
    disabledFields = true;
  } else {
    let dtData = getObjectFromArrayOfObjects(clientMovemnetDT, CLIENTS_DATA.editClientMovements.documentType, '_id');
    if (dtData.length > 0) {
      selectedDocumentTypeData  = dtData['0'];
      dtInstallationsOptions = getOptionsByData(selectedDocumentTypeData['documentNumbering'], "installationMasterId", "installation");
      dtInstallationsOptions = removeDuplicateObjects(dtInstallationsOptions, 'value');
    }
  }


  const handleOnChange = async (e, type="default", ddname = '') => {
    // Set if editing the form
    if (!ACTIVITY.editing) {
      dispatch(setEditing(true));
    }
    let name;
    let value;
    if (type === 'dd' || type === 'ac' ) {
      value = e.value;
      name = ddname;
    } else if (type === 'dp' ) {
      value = e;
      name = ddname;
    } else {
      value = e.target.value;
      name = e.target.name;
    }
    let clientData = Object.assign({}, CLIENTS_DATA);
    let editClientMovementData = {};
    editClientMovementData[name] = value;
    if (name === 'documentType') {
      editClientMovementData.documentTypeName = getLabelByValue(documentTypesOptions, value, 'value');
      let dtData = getObjectFromArrayOfObjects(clientMovemnetDT, value, '_id');
      if (dtData.length > 0) {
        selectedDocumentTypeData  = dtData['0'];
        let selectedInstallation = getSelectedInstallation(selectedDocumentTypeData['documentNumbering']);
        // Selected Installation Fields on Document Type Select
        if (Object.keys(selectedInstallation).length > 0) {
          editClientMovementData.installation = selectedInstallation.installation;
          editClientMovementData.preNumber = selectedInstallation.preNumber;
          // Logic For Higest Number
          let installationNumber = selectedInstallation.number
          let lastHigestNumber = getLastHighestPrefixNumber(CLIENTS_DATA.clientMovements, 'number', selectedInstallation.preNumber);
          if (installationNumber > lastHigestNumber) {
            editClientMovementData.number = installationNumber;
          } else {
            editClientMovementData.number = lastHigestNumber + 1;
          }
          editClientMovementData.installationMode = selectedInstallation.installationMode;
        } else {
          editClientMovementData.installation = "";
          editClientMovementData.preNumber = "";
          editClientMovementData.number = "";
          editClientMovementData.installationMode = "";
        }
      }
    }
    // Update Installation Field Values on Change
    if (name === 'installation') {
      editClientMovementData.installationName = getLabelByValue(dtInstallationsOptions, value, 'value');
      editClientMovementData.preNumber = getPreNumberByInstallation(selectedDocumentTypeData['documentNumbering'], value , 'installation');
      // editClientMovementData.number = getLastHighestPrefixNumber(CLIENTS_DATA.clientMovements, 'number', editClientMovementData.preNumber);
      // Logic For Higest Number
      let installationNumber = getNumberByInstallation(selectedDocumentTypeData['documentNumbering'], value , 'installation')
      let lastHigestNumber = getLastHighestPrefixNumber(CLIENTS_DATA.clientMovements, 'number', editClientMovementData.preNumber);
      if (installationNumber > lastHigestNumber) {
        editClientMovementData.number = installationNumber;
      } else {
        editClientMovementData.number = lastHigestNumber + 1;
      }
      editClientMovementData.installationMode = getModeByInstallation(selectedDocumentTypeData['documentNumbering'], value , 'installation');
    }

    // It will update the last available number from the API.
    if ((name === 'documentType' || name === 'preNumber' || name === 'installation') &&
        (
          (clientData.editClientMovements.installationMode ==='automatic' || editClientMovementData.installationMode ==='automatic' ) &&
          (clientData.editClientMovements.documentType !=='' || editClientMovementData.documentType !=='') &&
          (clientData.editClientMovements.preNumber !=='' || editClientMovementData.preNumber !=='') &&
          (clientData.editClientMovements.installation !=='' || editClientMovementData.installation !=='')
        )) {

      let checkAtParams = {};

      checkAtParams.id            = !editClientMovementData.documentType ? clientData.editClientMovements.documentType : editClientMovementData.documentType;
      checkAtParams.preNumber     = !editClientMovementData.preNumber ? clientData.editClientMovements.preNumber : editClientMovementData.preNumber;
      checkAtParams.installation  = !editClientMovementData.installation.toString() ? clientData.editClientMovements.installation.toString() : editClientMovementData.installation.toString();

      await axios.post(apiURL2 + `/settings/add-setting-advance-type-check`, JSON.stringify(checkAtParams), { headers: { 'Content-Type': 'application/json'}})
      .then(res => {
        if (res.data &&  res.data.status === '200') {
          if (res.data.number !== undefined) {
            editClientMovementData.number = parseInt(res.data.number) + 1;
          }
        }
      }).catch((err) => {
            console.log(err);
          })
    }
    // Save sum on change
    editClientMovementData.sum = getSumOfDataValues(CLIENTS_DATA.editClientMovements.clients, 'amount');
    dispatch(setEditClientMovements({...clientData.editClientMovements, ...editClientMovementData}));
  }

  const getSumOfDataValues = (data, field = 'sum') => {
    let sum = 0;
    if (data && data.length > 0) {
      data.forEach(item => {
        sum += +item[field] ?? 0;
      })
    }
    return sum;
  }

  const parentMydataActions = {
    insert: (e, data) => {
      if (!ACTIVITY.editing) dispatch(setEditing(true));
      const newClient = e.row;
      if(newClient) {
        let movementClients = [...CLIENTS_DATA.editClientMovements.clients.slice()];
        if(movementClients.findIndex((el) => el.clientId === newClient._id) !== -1) {
          toast.error("This client is already selected.");
        } else {
          movementClients.push({
            no: String(movementClients.length + 1),
            id: String(movementClients.length + 1),
            systemCode: newClient.systemCode,
            clientId: newClient._id,
            name: newClient.name,
            amount: 0,
          })
          dispatch(setEditClientMovements({...CLIENTS_DATA.editClientMovements, clients: movementClients, sum: getSumOfDataValues(movementClients, 'amount')}));
        }
      }
    },
    update: (data, id, e) => {
      if (!ACTIVITY.editing) dispatch(setEditing(true));
      const newClient = e.row;
      if(newClient || data) {
        let movementClients = [...CLIENTS_DATA.editClientMovements.clients.slice()];
        movementClients = movementClients.map((a) => Object.assign({}, a));
        let idx = movementClients.findIndex((el) => String(el.id) === String(id));
        if (idx !== -1) {
          if(data.clientId || data.name || data.systemCode) {
            if(movementClients.findIndex((el) => el.clientId === newClient._id) !== -1) {
              toast.error("This client is already selected.");
            } else {
              movementClients[idx].clientId = newClient._id;
              movementClients[idx].name = newClient.name;
              movementClients[idx].systemCode = newClient.systemCode;
            }
          }
          if(data.amount) movementClients[idx].amount = data.amount;
        }
        dispatch(setEditClientMovements({...CLIENTS_DATA.editClientMovements, clients: movementClients, sum: getSumOfDataValues(movementClients, 'amount')}));
      }
    },
    selected: () => {},
    delete: (id, rowIndex) => {
      if (!ACTIVITY.editing) dispatch(setEditing(true));
      let movementClients = [...CLIENTS_DATA.editClientMovements.clients.slice()];
      movementClients = movementClients.map((a) => Object.assign({}, a));
      movementClients = movementClients.filter((a) => a.id !== String(rowIndex + 1));
      movementClients.map((cl, ind) => {
        cl.no = String(ind + 1);
        cl.id = String(ind + 1);
      })
      dispatch(setEditClientMovements({...CLIENTS_DATA.editClientMovements, clients: movementClients, sum: getSumOfDataValues(movementClients, 'amount')}));
    }
  }
  let amountSum = getSumOfDataValues(CLIENTS_DATA.editClientMovements.clients, 'amount');
  amountSum = parseFloat(amountSum).toFixed(2);
  const selectDocumentInstallation = getDefaultOptionByValue(dtInstallationsOptions, CLIENTS_DATA.editClientMovements.installation.toString(), "value", "label", "value");
  const selectedDocumentType = getDefaultOptionByValue(documentTypesOptions, CLIENTS_DATA.editClientMovements.documentType, 'value', 'label', 'value');
  return (
    <React.Fragment>
      <form className="client-form product-form position-relative" autoComplete = "off">
        <AccountingView document={CLIENTS_DATA.editClientMovements} />
      {disabledFields && <div className="overlay_w">Please select Document Type first..</div>}
      <Row>
        <Col sm={12} md={3} className="showWithOverlay">
          <Dropdown
            label={t('ClientMovementEdit.documentTypes')}
            key = { Math.random() }
            defaultValue = {selectedDocumentType}
            required= {true}
            disabled={true}
            name="documentType"
            options={documentTypesOptions}
            onChange = {(e) => handleOnChange(e, 'dd', 'documentType')}
          />
        </Col>
        <Col sm={12} md={9} className="text-right ">
          <Button className="disabled" size="small">{t('ClientMovementEdit.files')}</Button>
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={3}>
          <Dropdown
            label={t('ClientMovementEdit.installation')}
            name="installation"
            value = {Object.keys(selectDocumentInstallation).length !== 0 ? selectDocumentInstallation : ''}
            enabledValue = {true}
            disabled={true}
            options={dtInstallationsOptions}
            onChange = {(e) => handleOnChange(e, 'dd', 'installation')}
          />
        </Col>
        <Col sm={12} md={2}>
          <Input
              className="mb-3 w-100"
              label={t('ClientMovementEdit.preNumber')}
              name="preNumber"
              value={CLIENTS_DATA.editClientMovements.preNumber !== "noprefix" ? CLIENTS_DATA.editClientMovements.preNumber : "Κενή"}
              disabled={true}
          />
        </Col>
        <Col sm="12" md="3">
          <Input
            className="mb-3 w-100"
            label={t('ClientMovementEdit.number.number')}
            charLimit = {1000}
            name="number"
            value = {CLIENTS_DATA.editClientMovements.number}
            placeholder={t('ClientMovementEdit.number.placeholder')}
            onChange = {(e) => handleOnChange(e)}
            disabled={disabledFields || CLIENTS_DATA.editClientMovements.installationMode !== 'manual'}
          />
        </Col>
        <Col sm="12" md="4">
          <DateBox
            classes="w-100 mb-3"
            label={t('ClientMovementEdit.date')}
            name="date"
            required = {true}
            selected={CLIENTS_DATA.editClientMovements.date ? new Date(CLIENTS_DATA.editClientMovements.date) : ''}
            onChange = {(e) => handleOnChange(e ,'dp', 'date')}
          />
        </Col>
      </Row>
      <Row>
        <Col sm="12">
          <Input
            className="mb-3 w-100"
            label={t('ClientMovementEdit.reason.reason')}
            name="reason"
            value = { CLIENTS_DATA.editClientMovements.reason }
            onChange = {(e) => handleOnChange(e)}
            placeholder={t('ClientMovementEdit.reason.placeholder')}
          />
        </Col>
      </Row>
      <hr className="mb-4" />
      <Row>
      <div className="et-table-ui">
        <EditableTable
          tableName = "Edit Client Movements Clients" // Do not change name
          key={"Edit Client Movements Clients" + incrKey}
          allowActions={true}
          onUpdate={parentMydataActions}
          allowInsertRow="true"
          columns={clientMovementColumns}
          enableNewRowCheckbox = {true}
          data={ CLIENTS_DATA.editClientMovements.clients }
        />
      </div>

        <div className="w-100 my-3">
          <div className="sum d-flex border align-items-center justify-content-between">
            <span className="py-2 px-3 border-right"><strong>{t('ClientMovementEdit.sum')}</strong></span>
            <span></span>
            <span className="py-2 px-3 border-left"><strong>{getGreekCurrencyFormat(amountSum)}</strong></span>
          </div>
        </div>
      </Row>
      <hr />
      <Row>
        <div style={{ width: "100%" }}>
          <div className="text-muted mb-2"><i>{t('ClientMovementEdit.movementsNotes')}</i></div>
          <TextArea
            rows="4"
            placeholder={t('ClientMovementEdit.addMovementsNotes')}
            name="notes"
            limit="1000"
            value = { CLIENTS_DATA.editClientMovements.notes }
            onChange = {(e) => handleOnChange(e)}
          />
        </div>
      </Row>
      </form>
    </React.Fragment>
  );
};

export const ClientMovementsEditLog = () => {
  const { t } = useTranslation('common');
  const CLIENTS_DATA = useSelector((state) => state.CLIENTS_DATA);

  return (
      <React.Fragment>
        <div className="text-muted mb-2"><i>{t('Logs.clientMovementLog')}</i></div>
        <LogTable itemId={CLIENTS_DATA.editClientMovements._id} />
      </React.Fragment>
  )
}

export default EditClientMovements;
