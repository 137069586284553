export const incomeCharacterizationType = [
    {
        "id": "1",
        "code": "E3_106",
        "description": "Ιδιοπαραγωγή παγίων -Αυτοπαραδόσεις- Καταστροφές αποθεμάτων/Εμπορεύματα"
    },
    {
        "id": "2",
        "code": "E3_205",
        "description": "Ιδιοπαραγωγή παγίων -Αυτοπαραδόσεις- Καταστροφές αποθεμάτων/Πρώτες ύλες και λοιπά υλικά"
    },
    {
        "id": "3",
        "code": "E3_210",
        "description": "Ιδιοπαραγωγή παγίων -Αυτοπαραδόσεις- Καταστροφές αποθεμάτων/Προιόντα και παραγωγή σε εξέλιξη"
    },
    {
        "id": "4",
        "code": "E3_305",
        "description": "Ιδιοπαραγωγή παγίων -Αυτοπαραδόσεις- Καταστροφές αποθεμάτων/Πρώτες ύλες και λοιπά υλικά"
    },
    {
        "id": "5",
        "code": "E3_310",
        "description": "Ιδιοπαραγωγή παγίων -Αυτοπαραδόσεις- Καταστροφές αποθεμάτων/Προιόντα και παραγωγή σε εξέλιξη"
    },
    {
        "id": "6",
        "code": "E3_318",
        "description": "Ιδιοπαραγωγή παγίων -Αυτοπαραδόσεις- Καταστροφές αποθεμάτων/Έξοδα παραγωγής"
    },
    {
        "id": "7",
        "code": "E3_561_001",
        "description": "Πωλήσεις αγαθών και υπηρεσιών Χονδρικές -Επιτηδευμάτων "
    },
    {
        "id": "8",
        "code": "E3_561_002",
        "description": "Πωλήσεις αγαθών και υπηρεσιών Χονδρικές  βάσει άρθρου 39α παρ 5 του κώδικα ΦΠΑ (Ν2859/2000)"
    },
    {
        "id": "9",
        "code": "E3_561_003",
        "description": "Πωλήσεις αγαθών και υπηρεσιών Λιανικές - Ιδιωτική Πελατεία "
    },
    {
        "id": "10",
        "code": "E3_561_004",
        "description": "Πωλήσεις αγαθών και υπηρεσιών Λιανικές  βάσει άρθρου 39α παρ 5 του κώδικα ΦΠΑ (Ν2859/2000)"
    },
    {
        "id": "11",
        "code": "E3_561_005",
        "description": "Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Ενδοκοινοτικές"
    },
    {
        "id": "12",
        "code": "E3_561_006",
        "description": "Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Τρίτες Χώρες"
    },
    {
        "id": "13",
        "code": "E3_561_007",
        "description": "Πωλήσεις αγαθών και υπηρεσιών Λοιπά"
    },
    {
        "id": "14",
        "code": "E3_562",
        "description": "Λοιπά συνήθη έσοδα"
    },
    {
        "id": "15",
        "code": "E3_563",
        "description": "Πιστωτικοί τόκοι και συναφή έσοδα"
    },
    {
        "id": "16",
        "code": "E3_564",
        "description": "Πιστωτικές συναλλαγματικές διαφορές "
    },
    {
        "id": "17",
        "code": "E3_565",
        "description": "Έσοδα συμμετοχών"
    },
    {
        "id": "18",
        "code": "E3_566",
        "description": "Κέρδη από διάθεση μη κυκλοφορούντων περιουσιακών στοιχείων"
    },
    {
        "id": "19",
        "code": "E3_567",
        "description": "Κέρδη από αναστροφή προβλέψεων και απομειώσεων"
    },
    {
        "id": "20",
        "code": "E3_568",
        "description": "Κέρδη απο επιμέτρηση στην εύλογη αξία "
    },
    {
        "id": "21",
        "code": "E3_570",
        "description": "Ασυνήθη έσοδα και κέρδη"
    },
    {
        "id": "22",
        "code": "E3_595",
        "description": "Έξοδα σε ιδιοπαραγωγή"
    },
    {
        "id": "23",
        "code": "E3_596",
        "description": "Επιδοτήσεις- Επιχορηγήσεις"
    },
    {
        "id": "24",
        "code": "E3_597",
        "description": "Επιδοτήσεις- Επιχορηγήσεις για επενδυτικούς σκοπούς - κάλυψη δαπανών"
    },
    {
        "id": "25",
        "code": "E3_880_001",
        "description": "Πωλήσεις παγίων Χονδρικές"
    },
    {
        "id": "26",
        "code": "E3_880_002",
        "description": "Πωλήσεις παγίων Λιανικές"
    },
    {
        "id": "27",
        "code": "E3_880_003",
        "description": "Πωλήσεις παγίων Εξωτερικού Ενδοκοινοτικές"
    },
    {
        "id": "28",
        "code": "E3_880_004",
        "description": "Πωλήσεις παγίων εξωτερικού Τρίτες Χώρες"
    },
    {
        "id": "29",
        "code": "E3_881_001",
        "description": "Πωλήσεις για λογ/σμο Τρίτων Χονδρικές"
    },
    {
        "id": "30",
        "code": "E3_881_002",
        "description": "Πωλήσεις για λογ/σμο Τρίτων Λιανικές"
    },
    {
        "id": "31",
        "code": "E3_881_003",
        "description": "Πωλήσεις για λογ/σμο Τρίτων Εξωτερικού Ενδοκοινοτικές"
    },
    {
        "id": "32",
        "code": "E3_881_004",
        "description": "Πωλήσεις για λογ/σμο Τρίτων Εξωτερικού Τρίτες χώρες"
    },
    {
        "id": "100",
        "code": "",
        "description": "Δεν ενημερώνει Ε3",
    },
    {
        "id": "101",
        "code": "null",
        "description": "Δεν ενημερώνει Ε3",
    }
]
