import React, {useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import {userSigninWithUrl} from "./_apis/api";
import MainWrapper from "../src/_components/layouts/MainWrapper";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {getURLParams} from "./_helpers/helperFunctions";
import {login} from "./_reducers/Login";
import {setCompany} from "./_reducers/companySlice";

const App = () => {
    const apiLoginURL = process.env.REACT_APP_LOGIN_URL;
    let isLoggedIn = useSelector((state) => state.LOGIN_STATE);
    const dispatch = useDispatch();
    const error = 'You are not Authorized! Please Login First'

    useEffect(() => {
        // Url Based Authentication
        const params = getURLParams();
        const token = params.get("token");
        const company = params.get("company");
        const deviceId = params.get("device_id");
        const erp_ip = params.get("erp_ip");
        if (token && company && deviceId && erp_ip) { // Logging in from login page
            if (token && company) {
                const companyData = JSON.parse(company)
                let selectedCompany = {
                    year: companyData.year,
                    id: companyData._id,
                    name: companyData.name,
                    vatNumber: companyData.vatNumber,
                    email: companyData.email,
                    companyExpireDate: companyData.companyExpireDate
                }
                let objectParams = {
                    year: companyData.year,
                    companyId: companyData._id,
                    vatNumber: companyData.vatNumber,
                    token: token,
                    erp_ip: erp_ip,
                    deviceId: deviceId,
                    companyExpireDate: companyData.companyExpireDate
                }
                localStorage.setItem("company", JSON.stringify(selectedCompany));
                userSigninWithUrl(token, dispatch, selectedCompany, objectParams, companyData, null);
            } else {
                localStorage.clear();
                window.location.replace(`${apiLoginURL}?redirect=ERP&error=${error}`)
            }
        } else { // Logging in directly
            if (localStorage.getItem("token") && localStorage.getItem("company") && localStorage.getItem("user")) {
                const company = localStorage.getItem("company")
                if (company) {
                    const companyData = JSON.parse(company);
                    dispatch(login());
                    dispatch(setCompany(companyData));
                }
            } else {
                localStorage.clear();
                window.location.replace(`${apiLoginURL}?redirect=ERP&error=${error}`)
            }
        }
    }, [dispatch, apiLoginURL]);

    return (
        <React.Fragment>
            {isLoggedIn && <MainWrapper/>}
            {/* <Modal className="themeModal" show={tabCloseWarning}>
        <Modal.Header>
            <Modal.Title>ΣΗΜΑΝΤΙΚΗ ΕΝΗΜΕΡΩΣΗ!</Modal.Title>
        </Modal.Header>
        <Modal.Body>Για την αποστολή των χειρόγραφων-ετεροχρονισμένων παραστατικών του έτους 2022 θα πρέπει να συνδεθείτε με την εταιρία σας στο έτος 2022.(Πατήστε στο κουμπί κάτω δεξιά με το όνομα της εταιρίας σας και διαλέξτε έτος 2022. Ύστερα πηγαίνετε Sales-> Sales New και επιλέγετε έναν από τους 2 νέους τύπους παραστατικών "ΧΕΙΡΟΓΡΑΦΗ ΑΠΟΔΕΙΞΗ ΠΑΡΟΧΗΣ ΥΠΗΡΕΣΙΩΝ" ή "ΧΕΙΡΟΓΡΑΦΟ ΤΙΜΟΛΟΓΙΟ ΠΑΡΟΧΗΣ ΥΠΗΡΕΣΙΩΝ". Επιλέγετε την ημερομηνία ,εγκατάσταση , σειρά και πληκτρολογείτε και τον αριθμό του παραστατικού. Προχωράτε κανονικά την διαδικασία με πελάτες-προιόντα όπως γνωρίζετε και αφού είστε έτοιμοι πατάτε "Save".</Modal.Body>
        <Modal.Footer>
            <Button variant="outline-primary" onClick={ () => showTabCloseWarning(false) }>OK</Button>
        </Modal.Footer>
    </Modal> */}
            <ToastContainer
                position="top-right"
                autoClose={3000}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover={false}
            />
        </React.Fragment>
    )
}

export default App;
