import React, {useEffect, useState} from "react";
import SearchTable from "../../../primer/Search-Table";
import {getObjectFromArrayOfObjects} from "../../../../_helpers/helperFunctions";
import {useTranslation} from "react-i18next";
import {classicStyleBelowNavbar} from "../Statics";

function SearchProducts() {
    const [t, i18n] = useTranslation("common");
    const searchProductsColumns = [
        {
            name: t('ProductsSearchGeneral.table.productCode'),
            field: "code",
            width: "10%",
            editable: false,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t('ProductsSearchGeneral.table.alternativeCode'),
            field: "alternateCode",
            editable: false,
            hiddenColumn: true,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t('ProductsSearchGeneral.table.productName'),
            field: "name",
            width: "45%",
            editable: false,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t('ProductsSearchGeneral.table.productCategory'),
            field: "categoryName",
            width: "15%",
            editable: false,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t('ProductsSearchGeneral.table.productTags'),
            field: "tag",
            editable: false,
            hiddenColumn: true,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t('ProductsSearchGeneral.table.alternativeName'),
            field: "alternateName",
            editable: false,
            hiddenColumn: true,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t('ProductsSearchGeneral.table.productInventoryBalance'),
            field: "inventoryBalance",
            editable: false,
            width: "10%",
            inputType: {
                type: 'text',
            }
        },
        {
            name: t('ProductsSearchGeneral.table.productActive'),
            field: "isActive",
            editable: false,
            hiddenColumn: true,
            inputType: {
                type: 'select',
                multiselect: true,
                options: [{
                    value: 'active',
                    label: 'Active'
                }, {
                    value: 'inactive',
                    label: 'Inactive'
                }]
            }
        },
        {
            name: t('ProductsSearchGeneral.table.productPrice'),
            field: "price",
            editable: false,
            hiddenColumn: false,
            width: "10%",
            inputType: {
                type: 'accounting',
                config: {
                    suffix: "€",
                }
            }
        },

        {
            name: t('ProductsSearchGeneral.table.supplierProductName'),
            field: "supplierName",
            hiddenColumn: true,
            editable: false,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t('ProductsSearchGeneral.table.supplierProductCode'),
            field: "supplierCode",
            hiddenColumn: true,
            editable: false,
            inputType: {
                type: 'text',
            }
        },

    ]
    useEffect(() => {
        setTableColumns(searchProductsColumns.slice());
    }, [i18n.language])

    const [tableColumns, setTableColumns] = useState(searchProductsColumns.slice())

    // It will hide or show the table columns for searchtable.
    const handleColumnShowHide = (field) => {
        let tempTc = tableColumns.slice();
        let object = getObjectFromArrayOfObjects(tempTc, field, 'field')
        const index = tempTc.findIndex((item) => field === item.field);
        if (object.length > 0) {
            tempTc[index].hiddenColumn = !object['0'].hiddenColumn;
            setTableColumns(tempTc);
        }
    }
    const productsSearchFilterData = {
        filters: [
            {
                label: t('ProductsSearchGeneral.table.productName'),
                name: "productName",
                type: "input",
            },
            {
                label: t('ProductsSearchGeneral.table.alternativeName'),
                name: "alternativeName",
                type: "input",
            },
            {
                label: t('ProductsSearchGeneral.table.productCode'),
                name: "productCode",
                type: "input",
            },
            {
                label: t('ProductsSearchGeneral.table.alternativeCode'),
                name: "alternativeCode",
                type: "input",
            },
            {
                label: t('ProductsSearchGeneral.table.productCategory'),
                name: "productCategory",
                type: "input",
            },
            {
                label: t('ProductsSearchGeneral.table.supplierProductName'),
                name: "supplierProductName",
                type: "input",
            },
            {
                label: t('ProductsSearchGeneral.table.supplierProductCode'),
                name: "supplierProductCode",
                type: "input",
            },
            {
                label: t('ProductsSearchGeneral.tableFilter.showInActiveProducts'),
                name: "isActive",
                type: "checkbox",
            },
        ],

        actions: ["quick-edit", "delete"],
    };
    return (
        <div style={classicStyleBelowNavbar}>
            <SearchTable
                tableName="Search Products" // Do not change name
                key={"Search Products"}
                hideYearFilter={true}
                columns={tableColumns}
                allowToggleColumns={true}
                allowPagination={true}
                toggleColumns={handleColumnShowHide}
                showDummyData={false}
                data={[]}
                filtersConfig={productsSearchFilterData}
            />
        </div>
    )
}

export default SearchProducts;
