export const otherTaxesCategories = [
      {
          "id": "1",
          "code": "1",
          "description": "α1) ασφάλιστρα κλάδου πυρός 20%",
          "taxes": "15%"
      },
      {
          "id": "2",
          "code": "2",
          "description": "α2) ασφάλιστρα κλάδου πυρός 20%",
          "taxes": "5%"
      },
      {
          "id": "3",
          "code": "3",
          "description": "β)ασφάλιστρα κλάδου ζωής 4%",
          "taxes": "4%"
      },
      {
          "id": "4",
          "code": "4",
          "description": "γ) ασφάλιστρα λοιπών κλάδων 15%",
          "taxes": "15%"
      },
      {
          "id": "5",
          "code": "5",
          "description": "δ) απαλλασσόμενα φόρου ασφαλίστρων 0%",
          "taxes": "0%"
      },
      {
          "id": "6",
          "code": "6",
          "description": "Ξενοδοχεία 1-2 αστέρων 0,50€",
          "taxes": "ποσό"
      },
      {
          "id": "7",
          "code": "7",
          "description": "Ξενοδοχεία 3 αστέρων 1,50€",
          "taxes": "ποσό"
      },
      {
          "id": "8",
          "code": "8",
          "description": "Ξενοδοχεία 4 αστέρων 3,00€",
          "taxes": "ποσό"
      },
      {
          "id": "9",
          "code": "9",
          "description": "Ξενοδοχεία 4 αστέρων 4,00€",
          "taxes": "ποσό"
      },
      {
          "id": "10",
          "code": "10",
          "description": "Ενοικιαζόμενα- Επιπλωμένα δωμάτια- διαμερίσματα 0,50€",
          "taxes": "ποσό"
      },
      {
          "id": "11",
          "code": "11",
          "description": "Ειδικός φόρος στις διαφημίσεις που προβάλλονται απο την τηλεόραση (ΕΦΤΔ) 5%",
          "taxes": "5%"
      },
      {
          "id": "12",
          "code": "12",
          "description": "3.1 Φόρος Πολυτελείας 10% επί της φορολογούμενης αξίας  για τα ενδοικοινοτικώς&#10;αποκτούμενα και εισαγώμενα από τρίτες χώρες 10%",
          "taxes": "10%"
      },
      {
          "id": "13",
          "code": "13",
          "description": "3.2 Φόρος πολυτελείας 10% επί της τιμής πώλησης προ ΦΠΑ για τα εγχωρίως &#10;παραγόμενα είδη 10%",
          "taxes": "10%"
      },
      {
          "id": "14",
          "code": "14",
          "description": "Δικαίωμα του Δημοσίου στα εισιτήρια των καζίνο (80% επί του εισιτηρίου)",
          "taxes": "80%"
      }
  ]

// Used in Advanced Combobox
export const otherTaxesCols = [
  {
    label: "ID",
    name: "id",
  },
  {
    label: "Code",
    name: "code",
  },
  {
    label: "Description",
    name: "description",
  },
  {
    label: "Taxes",
    name: "taxes",
  },
]; 