import React, {useEffect, useState} from "react";
import {Button, Modal } from "react-bootstrap";
import { filterMovementsInstallations, getCurrentUser, getDefaultOptionByValue, getModeByInstallation, getOptionsByData, getOptionsByDataMultiFields, getPreNumberByInstallation, getSelectedInstallation, getSelectedPreNumbers } from "../../../../_helpers/commonFunctions";
import Input from '../../../common/Input';
import Dropdown from "../../../primer/Dropdown";
import Checkbox from "../../../common/CheckBox";
import { useSelector, useDispatch } from "react-redux";
import { setSaveQuickRetailSettings } from "../../../../_reducers/activitySlice";
import { setQuickRetailSettings } from "../../../../_reducers/DataPanel/Sales/salesSettingsSlice";
import { salesPaymentTypeData } from "../../../../_data/sales/sales-payment-types";
import { fetchAdvancedTypesSales, fetchClient, fetchQuickRetailSalesSettings } from "../../../../_apis/api";
import { getLabelByValue, getObjectFromArrayOfObjects } from "../../../../_helpers/helperFunctions";
import { installationsOptions, preNumberGreek } from "../../../../_data/settings/advanced-types-search";
import axios from "axios";
import {useTranslation} from "react-i18next";

function SettingsQuickRetailSales() {

  const [t] = useTranslation('common');
  const ACTIVITY = useSelector( (state) => state.ACTIVITY );
  const SALES_SETTINGS = useSelector( (state) => state.SALES_SETTINGS );
  const SETTINGS = useSelector( (state) => state.SETTINGS );
  const company = useSelector(state => state.COMPANY_DATA.company)
  const dispatch = useDispatch();

  const [preNumberGreekOptions, setPreNumberGreekOptions] = useState([]);

  const [productsData, setSalesProductList] = useState([]);
  const [retailClientsData, setRetailClientsData] = useState([]);
  const [showCateogryConfirmation, setShowCateogryConfirmation] = useState(false);
  const [tempCatValue, setTempCatValue] = useState({});

  const currentUser = getCurrentUser();

  const requestData = {
    company:company.id,
    year:company.year.toString(),
    isActive:"active",
    userId: currentUser.id
  }

  useEffect(() => {
    dispatch(fetchAdvancedTypesSales(requestData))
    dispatch(fetchQuickRetailSalesSettings(requestData))
    dispatch(fetchClient({
      company:company.id,
      year:company.year.toString(),
    }))

    // It will set the preNumber if document type and installation already set from the existing settings.
    if (SETTINGS.settings.defaultDocumentType !== '' && SETTINGS.settings.defaultInstallation !== '') {
      const selectedDocumentTypeData = getObjectFromArrayOfObjects(SETTINGS.settings.advancedTypesSales, SALES_SETTINGS.quickRetailSettings.defaultDocumentType, "_id" );
      let selectedDTData = selectedDocumentTypeData['0'];
      let newPreNumbers = getSelectedPreNumbers(selectedDTData?.documentNumbering, preNumberGreek);
      setPreNumberGreekOptions(newPreNumbers);
    }

    // Load Product based on selected advanced type if my data rule applies
    if (SALES_SETTINGS.quickRetailSettings.defaultDocumentType !== "") {
      let dtData = getObjectFromArrayOfObjects(SETTINGS.settings.advancedTypesSales, SALES_SETTINGS.quickRetailSettings.defaultDocumentType, '_id');
      if (dtData.length > 0) {
        let currentAdvancedType  = dtData['0'];
        if (currentAdvancedType.enableMyDataRules && currentAdvancedType.enableMyDataRules === 'active') {
          loadProductListData(SALES_SETTINGS.quickRetailSettings.defaultDocumentType);
          loadClientsListData(SALES_SETTINGS.quickRetailSettings.defaultDocumentType);
        }
      }
    }

  }, [dispatch])

  const loadClientsListData = (value) => {
    axios.get(process.env.REACT_APP_API_URL2 + `/client/client-list-by-advanced-type-id/${value}`)
      .then(res => {
        if (res.data &&  res.data.status === '200') {
          if (res.data.data) {
            setRetailClientsData(res.data.data);
          } else {
            setRetailClientsData([]);
          }
        } else {
          setRetailClientsData([]);
        }
      }).catch((err) => {
        console.log(err);
      })
  }

  const loadProductListData = (value) => {
    let data = {
      id: value,
      name: '',
      company : company.id
    }
   // setPRequestData({...data});
    axios.get(process.env.REACT_APP_API_URL2 + `/sale/product-list-by-advanced-type-id/${data.id}`,{params :data})
      .then(res => {
        if (res.data &&  res.data.status === '200') {
          if (res.data.data) {
            setSalesProductList(res.data.data);
          } else {
            setSalesProductList([]);
          }
        } else {
          setSalesProductList([]);
        }
      }).catch((err) => {
        console.log(err);
      })
  }

  const handleOnChange = (e, type="default", ddname = '') => {

    // Set if editing the form
    if (!ACTIVITY.saveQuickRetailSettings) {
      dispatch(setSaveQuickRetailSettings(true));
    }

    let name;
    let value;
    if (type === 'dd')  {
      name = ddname;
      value = e.value;
    } else if (type === 'ddms')  {
      name = ddname;
      value = e;
    } else {
      name = e.target.name;
      value = e.target.value;
    }

    // Set settings data
    let newSettings = Object.assign({}, SALES_SETTINGS.quickRetailSettings);
    let newSettingsData = {};
    newSettingsData[name] = value;
    // if (name === 'defaultProduct') {
      // newSettingsData['defaultProduct'] = e.label;
    // }
    if (name === 'defaultDocumentType' && SALES_SETTINGS.quickRetailSettings.defaultDocumentType !== value) {
      if (SALES_SETTINGS.quickRetailSettings.defaultDocumentType !== "") {
        // Show confirmation before changing document type
        setShowCateogryConfirmation(true);
        setTempCatValue({e: e, new: value, old: SALES_SETTINGS.quickRetailSettings.defaultDocumentType});
      } else {
        newSettingsData['defautlDocumentTypeName'] = e.label;
        const selectedDocumentTypeData = getObjectFromArrayOfObjects(SETTINGS.settings.advancedTypesSales, value, "_id" );
        if (selectedDocumentTypeData.length > 0) {
          let selectedDTData = selectedDocumentTypeData['0'];
          let selectedInstallation = getSelectedInstallation(selectedDTData['documentNumbering']);

          // Preposed Installation by the fields
          if (selectedDTData.proposedInstallation !== '') {
            newSettingsData.defaultInstallation = selectedDTData.proposedInstallation;

            // This applies first if the preposed installation is given
            let defaultPreposedInstallation = getObjectFromArrayOfObjects(selectedDTData.documentNumbering, selectedDTData.proposedInstallation, 'no' );
            if (defaultPreposedInstallation && defaultPreposedInstallation.length > 0) {
              selectedInstallation = defaultPreposedInstallation['0']
            }
          }

          if (Object.keys(selectedInstallation).length > 0) {
            newSettingsData.defaultInstallation = selectedInstallation.installation;
            newSettingsData.defaultInstallationName = getLabelByValue(dtInstallationsOptions, selectedInstallation.installation, 'value');
            newSettingsData.defaultPreNumber = selectedInstallation.preNumber;
            newSettingsData.defaultNumberingMode = selectedInstallation.installationMode;
          } else {
            newSettingsData.defaultInstallation = "";
            newSettingsData.defaultInstallationName = "";
            newSettingsData.defaultPreNumber = "";
            newSettingsData.defaultNumberingMode = "";
          }

          // This will pre number given in document number table
          let newPreNumbers = getSelectedPreNumbers(selectedDTData['documentNumbering'], preNumberGreek);
          setPreNumberGreekOptions(newPreNumbers)

          // Load Product based on selected advanced type if my data rule applies
          if (selectedDTData.enableMyDataRules && selectedDTData.enableMyDataRules === 'active') {
            loadProductListData(value);
            loadClientsListData(value);
          }

        }
      }

    }

    // Update Installtion Field Values on Change
    if (name === 'defaultInstallation') {
      newSettingsData.defaultInstallationName = getLabelByValue(dtInstallationsOptions, value, 'value');
      newSettingsData.defaultPreNumber = getPreNumberByInstallation(selectedDocumentTypeData['documentNumbering'], value , 'installation');
      newSettingsData.defaultNumberingMode = getModeByInstallation(selectedDocumentTypeData['documentNumbering'], value , 'installation');

      // This will pre number given in document number table
      let newPreNumbers = getSelectedPreNumbers(selectedDocumentTypeData['documentNumbering'], preNumberGreek);
      setPreNumberGreekOptions(newPreNumbers)
    }

    if (name === 'defaultRetailClient') {
      newSettingsData['defaultRetailClientName'] = e.label;
    }
    if (name === 'defaultSendEmailStatus') {
      newSettingsData['defaultSendEmailStatusName'] = e.label;
    }
    if (name === 'preSelectedPaymentWay') {
      newSettingsData['preSelectedPaymentWayName'] = e.label;
    }

    if (name === 'paymentWayName1' || name === 'paymentType1' || name === 'paymentActive1' || name === 'autoPayment1') {
      let paymentWays = newSettings.paymentWays.slice();
      let paymentWay = Object.assign({}, paymentWays['0']);
      if (name === 'paymentWayName1') {
        paymentWay.name = value;
      }
      if (name === 'paymentType1') {
        paymentWay.type = value;
        paymentWay.typeName = value;
      }
      if (name === 'paymentActive1') {
        paymentWay.isActive = value;
      }
      if (name === 'autoPayment1') {
        paymentWay.createAutomaticPayment = value;
      }
      paymentWays['0'] = paymentWay;
      newSettings = {...newSettings, paymentWays}
    } else if (name === 'paymentWayName2' || name === 'paymentType2' || name === 'paymentActive2' || name === 'autoPayment2') {
      let paymentWays = newSettings.paymentWays.slice();
      let paymentWay = Object.assign({}, paymentWays['1']);
      if (name === 'paymentWayName2') {
        paymentWay.name = value;
      }
      if (name === 'paymentType2') {
        paymentWay.type = value;
        paymentWay.typeName = value;
      }
      if (name === 'paymentActive2') {
        paymentWay.isActive = value;
      }
      if (name === 'autoPayment2') {
        paymentWay.createAutomaticPayment = value;
      }
      paymentWays['1'] = paymentWay;
      newSettings = {...newSettings, paymentWays}
    } else if (name === 'paymentWayName3' || name === 'paymentType3' || name === 'paymentActive3' || name === 'autoPayment3') {
      let paymentWays = newSettings.paymentWays.slice();
      let paymentWay = Object.assign({}, paymentWays['2']);
      if (name === 'paymentWayName3') {
        paymentWay.name = value;
      }
      if (name === 'paymentType3') {
        paymentWay.type = value;
        paymentWay.typeName = value;
      }
      if (name === 'paymentActive3') {
        paymentWay.isActive = value;
      }
      if (name === 'autoPayment3') {
        paymentWay.createAutomaticPayment = value;
      }
      paymentWays['2'] = paymentWay;
      newSettings = {...newSettings, paymentWays}
    } else {
      newSettings = {...newSettings, ...newSettingsData}
    }
    dispatch(setQuickRetailSettings(newSettings));
  }

  const handleConfirmCategory = () => {

    // Populate Selected Document Type Data
    let newSettings = Object.assign({}, SALES_SETTINGS.quickRetailSettings);
    let newSettingsData = {};
    let value = tempCatValue.new;

    newSettingsData.documentType = value;
    newSettingsData.documentTypeName = getLabelByValue(documentTypesOptions, value, 'value');
    const selectedDocumentTypeData = getObjectFromArrayOfObjects(SETTINGS.settings.advancedTypesSales, value, "_id" );

    if (selectedDocumentTypeData.length > 0) {
      let selectedDTData = selectedDocumentTypeData['0'];
      let selectedInstallation = getSelectedInstallation(selectedDTData['documentNumbering']);

      // Preposed Installation by the fields
      if (selectedDTData.proposedInstallation !== '') {
        newSettingsData.defaultInstallation = selectedDTData.proposedInstallation;

        // This applies first if the preposed installation is given
        let defaultPreposedInstallation = getObjectFromArrayOfObjects(selectedDTData.documentNumbering, selectedDTData.proposedInstallation, 'no' );
        if (defaultPreposedInstallation && defaultPreposedInstallation.length > 0) {
          selectedInstallation = defaultPreposedInstallation['0']
        }
      }

      if (Object.keys(selectedInstallation).length > 0) {
        newSettingsData.defaultInstallation = selectedInstallation.installation;
        newSettingsData.defaultInstallationName = getLabelByValue(dtInstallationsOptions, selectedInstallation.installation, 'value');
        newSettingsData.defaultPreNumber = selectedInstallation.preNumber;
        newSettingsData.defaultNumberingMode = selectedInstallation.installationMode;
      } else {
        newSettingsData.defaultInstallation = "";
        newSettingsData.defaultInstallationName = "";
        newSettingsData.defaultPreNumber = "";
        newSettingsData.defaultNumberingMode = "";
      }

      // This will pre number given in document number table
      let newPreNumbers = getSelectedPreNumbers(selectedDTData['documentNumbering'], preNumberGreek);
      setPreNumberGreekOptions(newPreNumbers)

      // Load Product based on selected advanced type if my data rule applies
      if (selectedDTData.enableMyDataRules && selectedDTData.enableMyDataRules === 'active') {
        loadProductListData(value);
        loadClientsListData(value);
      }

      // Clear for update default products or client.
      newSettingsData.defaultRetailClient = "";
      newSettingsData.defaultRetailClientName = "";
      newSettingsData.defaultProduct = [];

    }

    dispatch(setQuickRetailSettings({...newSettings, ...newSettingsData}));
    setShowCateogryConfirmation(false);
  }

  let salesAdvancedTypes = SETTINGS.settings.advancedTypesSales;
  let salesDocumentTypes = []

  // Filter Sales Advanced Types only if mydate type 11.1 and 11.2
  if (salesAdvancedTypes && salesAdvancedTypes.length > 0) {
    salesDocumentTypes = salesAdvancedTypes.filter((item) => {
      if (item['myDataType'] === '11.1' || item['myDataType'] === '11.2') {
        return true;
      }
      return false;
    })
  }

  const sendEmailData = [
    {
      _id: 1,
      value: 'inactive',
      name: t('SalesQuickRetailSales.deactivated')
    },
    {
      _id: 2,
      value: 'active',
      name: t('SalesQuickRetailSales.activated')
    }
  ];


  // Convert Data to Dropdown Option
  const documentTypesOptions =  getOptionsByData(salesDocumentTypes, '_id', 'name')

  let selectedDocumentTypeData = [];
  let dtInstallationsOptions = [];

  const selectedDocumentType = getDefaultOptionByValue(documentTypesOptions, SALES_SETTINGS.quickRetailSettings.defaultDocumentType, 'value', 'label', 'value');

  let dtData = getObjectFromArrayOfObjects(SETTINGS.settings.advancedTypesSales, SALES_SETTINGS.quickRetailSettings.defaultDocumentType, '_id');
  if (dtData.length > 0) {
    selectedDocumentTypeData  = dtData['0'];
    dtInstallationsOptions = filterMovementsInstallations(installationsOptions, selectedDocumentTypeData['documentNumbering']);
  }

  const retailClientOptions =  getOptionsByData(retailClientsData, '_id', 'name')
  const selectedRetailClient = getDefaultOptionByValue(retailClientOptions, SALES_SETTINGS.quickRetailSettings.defaultRetailClient, 'value', 'label', 'value');

  const productsOptions =  getOptionsByDataMultiFields(productsData, '_id', ['name', 'code'])
  let selectedProduct = null;
  if (SALES_SETTINGS.quickRetailSettings.defaultProduct !== '') {
    selectedProduct = SALES_SETTINGS.quickRetailSettings.defaultProduct;
  }

  const sendEmailStatusOptions =  getOptionsByData(sendEmailData, 'value', 'name')
  const selectedSendEmailStatus = getDefaultOptionByValue(sendEmailStatusOptions, SALES_SETTINGS.quickRetailSettings.defaultSendEmailStatus, 'value', 'label', 'value');

  const paymentWaysData = SALES_SETTINGS.quickRetailSettings.paymentWays;
  const paymentWaysOptions =  getOptionsByData(paymentWaysData, 'id', 'name')
  const selectedPaymentWays = getDefaultOptionByValue(paymentWaysOptions, SALES_SETTINGS.quickRetailSettings.preSelectedPaymentWay, 'value', 'label', 'value');

  const paymentTypes = getOptionsByData(salesPaymentTypeData, 'value', 'label');

  return (
    <>
      <div className="row">
        <div className="col-12 col-sm-6 col-md-3">
          <Dropdown
              label={t('SalesQuickRetailSales.defaultSalesDocumentType')}
              key = { Math.random() }
              defaultValue = {selectedDocumentType}
              required= {true}
              name="defaultDocumentType"
              searchEnable = {true}
              options={documentTypesOptions}
              onChange = {(e) => handleOnChange(e, 'dd', 'defaultDocumentType')}
          />
        </div>
        <div className="col-12 col-sm-6 col-md-3 d-flex align-items-end justify-content-start">
          <Checkbox
            className="mb-3"
            text = {t('SalesQuickRetailSales.activateCashRegister')}
            name="activateCashRegister"
            key = {Math.random()}
            defaultValue={SALES_SETTINGS.quickRetailSettings.activateCashRegister}
            value={ SALES_SETTINGS.quickRetailSettings.activateCashRegister === 'active' ? "inactive" : "active" }
            onChange = {(e) => handleOnChange(e) }
          />
        </div>
        <div className="col-12 col-sm-6 col-md-3 d-flex align-items-end justify-content-start">
          <Checkbox
            className="mb-3"
            text = {t('SalesQuickRetailSales.allowCreationOfNewClient')}
            name="allowNewClientCreation"
            key = {Math.random()}
            defaultValue={SALES_SETTINGS.quickRetailSettings.allowNewClientCreation}
            value={ SALES_SETTINGS.quickRetailSettings.allowNewClientCreation === 'active' ? "inactive" : "active" }
            onChange = {(e) => handleOnChange(e) }
          />
        </div>
        <div className="col-12 col-sm-6 col-md-3 d-flex align-items-end justify-content-start">
          <Checkbox
            className="mb-3"
            text = {t('SalesQuickRetailSales.allowSelectionOfClient')}
            name="allowClientSelection"
            key = {Math.random()}
            defaultValue={SALES_SETTINGS.quickRetailSettings.allowClientSelection}
            value={ SALES_SETTINGS.quickRetailSettings.allowClientSelection === 'active' ? "inactive" : "active" }
            onChange = {(e) => handleOnChange(e) }
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-sm-6 col-md-3">
          <Dropdown
              label={t('SalesQuickRetailSales.defaultRetailClient')}
              key = { Math.random() }
              defaultValue = {selectedRetailClient}
              name="defaultRetailClient"
              options={retailClientOptions}
              onChange = {(e) => handleOnChange(e, 'dd', 'defaultRetailClient')}
          />
        </div>
        <div className="col-12 col-sm-6 col-md-3">
          <Dropdown
              label={t('SalesQuickRetailSales.defaultSendEmailStatus')}
              key = { Math.random() }
              defaultValue = {selectedSendEmailStatus}
              name="defaultSendEmailStatus"
              options={sendEmailStatusOptions}
              onChange = {(e) => handleOnChange(e, 'dd', 'defaultSendEmailStatus')}
          />
        </div>
        <div className="col-12 col-sm-6 col-md-6 d-flex align-items-end justify-content-start">
          <Checkbox
            className="mb-3"
            text = {t('SalesQuickRetailSales.forbidChangeInPricesInSalesProducts')}
            name="forbitChangesSalesProducts"
            key = {Math.random()}
            defaultValue={SALES_SETTINGS.quickRetailSettings.forbitChangesSalesProducts}
            value={ SALES_SETTINGS.quickRetailSettings.forbitChangesSalesProducts === 'active' ? "inactive" : "active" }
            onChange = {(e) => handleOnChange(e) }
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-sm-6 col-md-4">
          <Dropdown
              label={t('SalesQuickRetailSales.defaultProducts')}
              key = { Math.random() }
              value = {selectedProduct}
              name="defaultProduct"
              enabledValue = {true}
              searchEnable = {true}
              multiSelect = {true}
              closeMenuOnSelect={false}
              options={productsOptions}
              onChange = {(e) => handleOnChange(e, 'ddms', 'defaultProduct')}
          />
        </div>
        <div className="col-12 col-sm-6 col-md-4">
          <div className="mb-3">
            <Dropdown
              label={t('SalesQuickRetailSales.defaultInstallation')}
              options={dtInstallationsOptions}
              value = {getDefaultOptionByValue(dtInstallationsOptions, SALES_SETTINGS.quickRetailSettings.defaultInstallation, 'value', 'label', 'value')}
              enabledValue = {true}
              searchEnable = {true}
              name="defaultInstallation"
              onChange = {(e) => handleOnChange(e, 'dd', 'defaultInstallation') }
            />
          </div>
        </div>
        <div className="col-12 col-sm-6 col-md-4">
          <div className="mb-3">
            <Dropdown
              label={t('SalesQuickRetailSales.defaultPreNumber')}
              name="defaultPreNumber"
              key = {Math.random()}
              defaultValue = {getDefaultOptionByValue(preNumberGreekOptions, SALES_SETTINGS.quickRetailSettings.defaultPreNumber, 'value', 'label', 'value')}
              options={preNumberGreekOptions}
              disabled={SALES_SETTINGS.quickRetailSettings.numberingMode === 'manual'}
              onChange = {(e) => handleOnChange(e, 'dd', 'defaultPreNumber')}
            />
          </div>
        </div>
      </div>

      {/* Payment Way 1 */}
      {SALES_SETTINGS.quickRetailSettings.paymentWays && SALES_SETTINGS.quickRetailSettings.paymentWays.length > 0 &&
      <hr className="mb-4" /> &&
      <div className="row">
        <div className="col-12 col-sm-6 col-md-4">
          <Input
            label={t('SalesQuickRetailSales.paymentWay1Name.paymentWay1Name')}
            name= "paymentWayName1"
            placeholder= {t('SalesQuickRetailSales.paymentWay1Name.placeholder')}
            value= {SALES_SETTINGS.quickRetailSettings.paymentWays['0'].name}
            onChange = {(e) => handleOnChange(e) }
          />
        </div>
        <div className="col-12 col-sm-6 col-md-4">
          <Dropdown
              label={t('SalesQuickRetailSales.paymentType1')}
              key = { Math.random() }
              defaultValue = {getDefaultOptionByValue(paymentTypes, SALES_SETTINGS.quickRetailSettings.paymentWays['0'].type, 'value', 'label', 'value')}
              name= "paymentType1"
              options={paymentTypes}
              onChange = {(e) => handleOnChange(e, 'dd', 'paymentType1')}
          />
        </div>
        <div className="col-12 col-sm-6 col-md-4 align-items-end justify-content-start">
          <Checkbox
            className="mb-3"
            text = {t('SalesQuickRetailSales.active')}
            name="paymentActive1"
            key = {Math.random()}
            defaultValue={SALES_SETTINGS.quickRetailSettings.paymentWays['0'].isActive}
            value={ SALES_SETTINGS.quickRetailSettings.paymentWays['0'].isActive === 'active' ? "inactive" : "active" }
            onChange = {(e) => handleOnChange(e) }
          />
          <Checkbox
            className="mb-3"
            text = {t('SalesQuickRetailSales.createAutomaticPayment')}
            name="autoPayment1"
            key = {Math.random()}
            defaultValue={SALES_SETTINGS.quickRetailSettings.paymentWays['0'].createAutomaticPayment}
            value={ SALES_SETTINGS.quickRetailSettings.paymentWays['0'].createAutomaticPayment === 'active' ? "inactive" : "active" }
            onChange = {(e) => handleOnChange(e) }
          />
        </div>
      </div>
      }

      {/* Payment Way 2 */}
      {SALES_SETTINGS.quickRetailSettings.paymentWays && SALES_SETTINGS.quickRetailSettings.paymentWays.length > 0 &&
      <hr className="mb-4" /> &&
      <div className="row">
        <div className="col-12 col-sm-6 col-md-4">
          <Input
            label={t('SalesQuickRetailSales.paymentWay2Name.paymentWay2Name')}
            name= "paymentWayName2"
            placeholder= {t('SalesQuickRetailSales.paymentWay2Name.placeholder')}
            value= {SALES_SETTINGS.quickRetailSettings.paymentWays['1'].name}
            onChange = {(e) => handleOnChange(e) }
          />
        </div>
        <div className="col-12 col-sm-6 col-md-4">
          <Dropdown
              label={t('SalesQuickRetailSales.paymentType2')}
              key = { Math.random() }
              defaultValue = {getDefaultOptionByValue(paymentTypes, SALES_SETTINGS.quickRetailSettings.paymentWays['1'].type, 'value', 'label', 'value')}
              name= "paymentType2"
              options={paymentTypes}
              onChange = {(e) => handleOnChange(e, 'dd', 'paymentType2')}
          />
        </div>
        <div className="col-12 col-sm-6 col-md-4 align-items-end justify-content-start">
          <Checkbox
            className="mb-3"
            text = {t('SalesQuickRetailSales.active')}
            name="paymentActive2"
            key = {Math.random()}
            defaultValue={SALES_SETTINGS.quickRetailSettings.paymentWays['1'].isActive}
            value={ SALES_SETTINGS.quickRetailSettings.paymentWays['1'].isActive === 'active' ? "inactive" : "active" }
            onChange = {(e) => handleOnChange(e) }
          />
          <Checkbox
            className="mb-3"
            text = {t('SalesQuickRetailSales.createAutomaticPayment')}
            name="autoPayment2"
            key = {Math.random()}
            defaultValue={SALES_SETTINGS.quickRetailSettings.paymentWays['1'].createAutomaticPayment}
            value={ SALES_SETTINGS.quickRetailSettings.paymentWays['1'].createAutomaticPayment === 'active' ? "inactive" : "active" }
            onChange = {(e) => handleOnChange(e) }
          />
        </div>
      </div>
      }

      {/* Payment Way 3 */}
      {SALES_SETTINGS.quickRetailSettings.paymentWays && SALES_SETTINGS.quickRetailSettings.paymentWays.length > 0 &&
      <hr className="mb-4" /> &&
      <div className="row">
        <div className="col-12 col-sm-6 col-md-4">
          <Input
            label={t('SalesQuickRetailSales.paymentWay3Name.paymentWay3Name')}
            name= "paymentWayName3"
            placeholder= {t('SalesQuickRetailSales.paymentWay3Name.placeholder')}
            value= {SALES_SETTINGS.quickRetailSettings.paymentWays['2'].name}
            onChange = {(e) => handleOnChange(e) }
          />
        </div>
        <div className="col-12 col-sm-6 col-md-4">
          <Dropdown
              label={t('SalesQuickRetailSales.paymentType3')}
              key = { Math.random() }
              defaultValue = {getDefaultOptionByValue(paymentTypes, SALES_SETTINGS.quickRetailSettings.paymentWays['2'].type, 'value', 'label', 'value')}
              name= "paymentType3"
              options={paymentTypes}
              onChange = {(e) => handleOnChange(e, 'dd', 'paymentType3')}
          />
        </div>
        <div className="col-12 col-sm-6 col-md-4 align-items-end justify-content-start">
          <Checkbox
            className="mb-3"
            text = {t('SalesQuickRetailSales.active')}
            name="paymentActive3"
            key = {Math.random()}
            defaultValue={SALES_SETTINGS.quickRetailSettings.paymentWays['2'].isActive}
            value={ SALES_SETTINGS.quickRetailSettings.paymentWays['2'].isActive === 'active' ? "inactive" : "active" }
            onChange = {(e) => handleOnChange(e) }
          />
          <Checkbox
            className="mb-3"
            text = {t('SalesQuickRetailSales.createAutomaticPayment')}
            name="autoPayment3"
            key = {Math.random()}
            defaultValue={SALES_SETTINGS.quickRetailSettings.paymentWays['2'].createAutomaticPayment}
            value={ SALES_SETTINGS.quickRetailSettings.paymentWays['2'].createAutomaticPayment === 'active' ? "inactive" : "active" }
            onChange = {(e) => handleOnChange(e) }
          />
        </div>
      </div>
      }

      <hr className="mb-4" />
      <div className="row">
        <div className="col-12 col-sm-6 col-md-4">
         <Dropdown
              label={t('SalesQuickRetailSales.preSelectedPaymentWay')}
              key = { Math.random() }
              defaultValue = {selectedPaymentWays}
              name="preSelectedPaymentWay"
              options={paymentWaysOptions}
              onChange = {(e) => handleOnChange(e, 'dd', 'preSelectedPaymentWay')}
          />
        </div>
      </div>


      {/* Confirm Category Change */}
      <Modal className="themeModal" show={showCateogryConfirmation}>
        <Modal.Header closeButton>
          <Modal.Title>{t('SalesQuickRetailSales.warning')}</Modal.Title>
        </Modal.Header>
          <Modal.Body>{t('SalesQuickRetailSales.message')}</Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={ () => setShowCateogryConfirmation(false) }>{t('SalesQuickRetailSales.no')}</Button>
          <Button variant="primary" onClick={ () => handleConfirmCategory() } >{t('SalesQuickRetailSales.yes')}</Button>
        </Modal.Footer>
      </Modal>

      { process.env.REACT_APP_DEBUG_MODE === 'true' && <pre>{JSON.stringify(SALES_SETTINGS.quickRetailSettings, null, 2)}</pre> }
    </>
  )
}

export default SettingsQuickRetailSales;
