export const myDataCharOptions = [
    {
        value: "1.1",
        label: "Τιμολόγιο Πώλησης",
        category: [
            {
                value: "category1_1",
                label: "Έσοδα από πώληση Εμπορευμάτων  (+) / (-)",
                type: [
                    {
                        value: "E3_561_001",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Χονδρικές - Επιτηδευμάτων "
                    },
                    {
                        value: "E3_561_002",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Χονδρικές βάσει άρθρου 39α παρ 5 του Κώδικα Φ.Π.Α. (Ν.2859/2000)"
                    },
                    {
                        value: "E3_561_007",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λοιπά"
                    },
                ]
            },
            {
                value: "category1_2",
                label: "Έσοδα από Πώληση Προϊόντων (+) / (-)",
                type: [
                    {
                        value: "E3_561_001",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Χονδρικές - Επιτηδευμάτων "
                    },
                    {
                        value: "E3_561_002",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Χονδρικές βάσει άρθρου 39α παρ 5 του Κώδικα Φ.Π.Α. (Ν.2859/2000)"
                    },
                    {
                        value: "E3_561_007",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λοιπά"
                    },
                ]
            },
            {
                value: "category1_3",
                label: "Έσοδα από Παροχή Υπηρεσιών (+) / (-)",
                type: [
                    {
                        value: "E3_561_001",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Χονδρικές - Επιτηδευματιών"
                    },
                    {
                        value: "E3_561_002",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Χονδρικές βάσει άρθρου 39α παρ 5 του Κώδικα Φ.Π.Α. (Ν.2859/2000)"
                    },
                    {
                        value: "E3_561_007",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λοιπά"
                    },
                    {
                        value: "E3_563",
                        label: "Πιστωτικοί τόκοι και συναφή έσοδα"
                    },
                ]
            },
            {
                value: "category1_4",
                label: "Έσοδα από Πώληση Παγίων  (+)/ (-)",
                type: [
                    {
                        value: "E3_880_001",
                        label: "Πωλήσεις παγίων Χονδρικές"
                    },
                ]
            },
            {
                value: "category1_5",
                label: "Λοιπά Έσοδα/Κέρδη (+)/ (-)",
                type: [
                    {
                        value: "E3_561_007",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λοιπά"
                    },
                    {
                        value: "E3_562",
                        label: "Λοιπά συνήθη έσοδα"
                    },
                    {
                        value: "E3_563",
                        label: "Πιστωτικοί τόκοι και συναφή έσοδα"
                    },
                    {
                        value: "E3_564",
                        label: "Πιστωτικές συναλλαγματικές διαφορές "
                    },
                    {
                        value: "E3_565",
                        label: "Έσοδα συμμετοχών"
                    },
                    {
                        value: "E3_566",
                        label: "Κέρδη από διάθεση μη κυκλοφορούντων περιουσιακών στοιχείων"
                    },
                    {
                        value: "E3_567",
                        label: "Κέρδη από αναστροφή προβλέψεων και απομειώσεων"
                    },
                    {
                        value: "E3_568",
                        label: "Κέρδη απο επιμέτρηση στην εύλογη αξία "
                    },
                    {
                        value: "E3_570",
                        label: "Ασυνήθη έσοδα και κέρδη"
                    },
                    {
                        value: "E3_561_002",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Χονδρικές βάσει άρθρου 39α παρ 5 του Κώδικα Φ.Π.Α. (Ν.2859/2000)"
                    },
                ]
            },
            {
                value: "category1_7",
                label: "Έσοδα για λ/σμο τρίτων (+) / (-)",
                type: [
                    {
                        value: "E3_881_001",
                        label: "Πωλήσεις για λογ/σμο Τρίτων Χονδρικές"
                    },
                    {
                        value: "E3_881_003",
                        label: "Πωλήσεις για λογ/σμο Τρίτων Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_881_004",
                        label: "Πωλήσεις για λογ/σμο Τρίτων Εξωτερικού Τρίτες Χώρες"
                    }
                ]
            },
            {
                value: "category1_8",
                label: "Έσοδα προηγούμενων χρήσεων (+)/ (-)",
                type: [
                    {
                        value: "E3_561_001",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Χονδρικές - Επιτηδευματιών"
                    },
                    {
                        value: "E3_561_002",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Χονδρικές βάσει άρθρου 39α παρ 5 του Κώδικα Φ.Π.Α. (Ν.2859/2000)"
                    },
                    {
                        value: "E3_561_007",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λοιπά"
                    },
                    {
                        value: "E3_880_001",
                        label: "Πωλήσεις παγίων Χονδρικές"
                    },
                    {
                        value: "E3_881_001",
                        label: "Πωλήσεις για λογ/σμο Τρίτων Χονδρικές"
                    },
                    {
                        value: "E3_881_003",
                        label: "Πωλήσεις για λογ/σμο Τρίτων Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_881_004",
                        label: "Πωλήσεις για λογ/σμο Τρίτων Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_562",
                        label: "Λοιπά συνήθη έσοδα"
                    },
                    {
                        value: "E3_563",
                        label: "Πιστωτικοί τόκοι και συναφή έσοδα"
                    },
                    {
                        value: "E3_564",
                        label: "Πιστωτικές συναλλαγματικές διαφορές"
                    },
                    {
                        value: "E3_565",
                        label: "Έσοδα συμμετοχών"
                    },
                    {
                        value: "E3_566",
                        label: "Κέρδη από διάθεση μη κυκλοφορούντων περιουσιακών στοιχείων"
                    },
                    {
                        value: "E3_567",
                        label: "Κέρδη από αναστροφή προβλέψεων και απομειώσεων"
                    },
                    {
                        value: "E3_568",
                        label: "Κέρδη απο επιμέτρηση στην εύλογη αξία "
                    },
                    {
                        value: "E3_570",
                        label: "Ασυνήθη έσοδα και κέρδη"
                    },
                ]
            },
            {
                value: "category1_9",
                label: "Έσοδα επομένων χρήσεων (+)/ (-)",
                type: [
                    {
                        value: "E3_561_001",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Χονδρικές - Επιτηδευματιών"
                    },
                    {
                        value: "E3_561_002",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Χονδρικές βάσει άρθρου 39α παρ 5 του Κώδικα Φ.Π.Α. (Ν.2859/2000)"
                    },
                    {
                        value: "E3_561_007",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λοιπά"
                    },
                    {
                        value: "E3_880_001",
                        label: "Πωλήσεις παγίων Χονδρικές"
                    },
                    {
                        value: "E3_881_001",
                        label: "Πωλήσεις για λογ/σμο Τρίτων Χονδρικές"
                    },
                    {
                        value: "E3_881_003",
                        label: "Πωλήσεις για λογ/σμο Τρίτων Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_881_004",
                        label: "Πωλήσεις για λογ/σμο Τρίτων Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_562",
                        label: "Λοιπά συνήθη έσοδα"
                    },
                    {
                        value: "E3_563",
                        label: "Πιστωτικοί τόκοι και συναφή έσοδα"
                    },
                    {
                        value: "E3_564",
                        label: "Πιστωτικές συναλλαγματικές διαφορές"
                    },
                    {
                        value: "E3_565",
                        label: "Έσοδα συμμετοχών"
                    },
                    {
                        value: "E3_566",
                        label: "Κέρδη από διάθεση μη κυκλοφορούντων περιουσιακών στοιχείων"
                    },
                    {
                        value: "E3_567",
                        label: "Κέρδη από αναστροφή προβλέψεων και απομειώσεων"
                    },
                    {
                        value: "E3_568",
                        label: "Κέρδη απο επιμέτρηση στην εύλογη αξία "
                    },
                    {
                        value: "E3_570",
                        label: "Ασυνήθη έσοδα και κέρδη"
                    },
                ]
            },
            {
                value: "category1_95",
                label: "Λοιπά Πληροφοριακά Στοιχεία Εσόδων (+) / (-)",
                type: [
                    {
                        value: "null",
                        label: "Κενό"
                    },
                    {
                        value: "E3_596",
                        label: "Επιδοτήσεις - Επιχορηγήσεις"
                    },
                    {
                        value: "E3_597",
                        label: "Επιδοτήσεις - Επιχορηγήσεις για επενδυτικούς σκοπούς - κάλυψη δαπανών"
                    },
                ]
            },
            {
                value: "category2_1",
                label: "Αγορές Εμπορευμάτων (-) / (+)",
                type: [
                    {
                        value: "E3_102_001",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Χονδρικές",
                    },
                    {
                        value: "E3_102_003",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Αγαθών του άρθρου 39α παρ.5 του Κώδικα Φ.Π.Α. (ν.2859/2000)",
                    },
                    {
                        value: "E3_102_006",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό) Λοιπά",
                    },
                    {
                        value: "E3_313_001",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Χονδρικές",
                    },
                    {
                        value: "E3_313_005",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Λοιπά",
                    }
                ],
            },
            {
                value: "category2_2",
                label: "Αγορές Α'-Β' Υλών (-) / (+)",
                type: [
                    {
                        value: "E3_202_001",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Χονδρικές",
                    },
                    {
                        value: "E3_202_005",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά",
                    },
                    {
                        value: "E3_302_001",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Χονδρικές",
                    },
                    {
                        value: "E3_302_005",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά",
                    },
                ],
            },
            {
                value: "category2_3",
                label: "Λήψη Υπηρεσιών (-) / (+)",
                type: [
                    {
                        value: "E3_585_001",
                        label: "Προμήθειες διαχείρισης ημεδαπής - αλλοδαπής (management fees)",
                    },
                    {
                        value: "E3_585_002",
                        label: "Δαπάνες από συνδεδεμένες επιχειρήσεις",
                    },
                    {
                        value: "E3_585_004",
                        label: "Δαπάνες για ενημερωτικές ημερίδες",
                    },
                    {
                        value: "E3_585_005",
                        label: "Έξοδα υποδοχής και φιλοξενίας",
                    },
                    {
                        value: "E3_585_006",
                        label: "Έξοδα ταξιδιών",
                    },
                    {
                        value: "E3_585_008",
                        label: "Έξοδα και προμήθειες παραγγελιοδόχου για λογαριασμό αγροτών",
                    },
                    {
                        value: "E3_585_009",
                        label: "Λοιπές Αμοιβές για υπηρεσίες ημεδαπής",
                    },
                    {
                        value: "E3_585_011",
                        label: "Ενέργεια",
                    },
                    {
                        value: "E3_585_012",
                        label: "Ύδρευση",
                    },
                    {
                        value: "E3_585_013",
                        label: "Τηλεπικοινωνίες",
                    },
                    {
                        value: "E3_585_014",
                        label: "Ενοίκια",
                    },
                    {
                        value: "E3_585_015",
                        label: "Διαφήμιση και προβολή",
                    },
                    {
                        value: "E3_585_016",
                        label: "Λοιπά έξοδα",
                    },
                    {
                        value: "E3_586",
                        label: "Χρεωστικοί τόκοι και συναφή έξοδα",
                    },
                    {
                        value: "E3_581_003",
                        label: "Παροχές σε εργαζόμενους/Λοιπές παροχές",
                    },
                ],
            },
            {
                value: "category2_4",
                label: "Γενικά Έξοδα με δικαίωμα έκπτωσης ΦΠΑ (-) / (+)",
                type: [
                    {
                        value: "E3_585_001",
                        label: "Προμήθειες διαχείρισης ημεδαπής - αλλοδαπής (management fees)",
                    },
                    {
                        value: "E3_585_002",
                        label: "Δαπάνες από συνδεδεμένες επιχειρήσεις",
                    },
                    {
                        value: "E3_585_004",
                        label: "Δαπάνες για ενημερωτικές ημερίδες",
                    },
                    {
                        value: "E3_585_005",
                        label: "Έξοδα υποδοχής και φιλοξενίας",
                    },
                    {
                        value: "E3_585_006",
                        label: "Έξοδα ταξιδιών",
                    },
                    {
                        value: "E3_585_008",
                        label: "Έξοδα και προμήθειες παραγγελιοδόχου για λογαριασμό αγροτών",
                    },
                    {
                        value: "E3_585_009",
                        label: "Λοιπές Αμοιβές για υπηρεσίες ημεδαπής",
                    },
                    {
                        value: "E3_585_011",
                        label: "Ενέργεια",
                    },
                    {
                        value: "E3_585_012",
                        label: "Ύδρευση",
                    },
                    {
                        value: "E3_585_013",
                        label: "Τηλεπικοινωνίες",
                    },
                    {
                        value: "E3_585_014",
                        label: "Ενοίκια",
                    },
                    {
                        value: "E3_585_015",
                        label: "Διαφήμιση και προβολή",
                    },
                    {
                        value: "E3_585_016",
                        label: "Λοιπά έξοδα",
                    },
                    {
                        value: "E3_586",
                        label: "Χρεωστικοί τόκοι και συναφή έξοδα",
                    },
                    {
                        value: "E3_882_001",
                        label: "Αγορές ενσώματων παγίων χρήσης/Χονδρικές",
                    },
                    {
                        value: "E3_883_001",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Χονδρικές",
                    },
                    {
                        value: "E3_102_001",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Χονδρικές",
                    },
                    {
                        value: "E3_102_003",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Αγαθών του άρθρου 39α παρ.5 του Κώδικα Φ.Π.Α. (ν.2859/2000)",
                    },
                    {
                        value: "E3_102_004",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
                    },
                    {
                        value: "E3_102_005",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
                    },
                    {
                        value: "E3_202_001",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Χονδρικές",
                    },
                    {
                        value: "E3_202_003",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές\n",
                    },
                    {
                        value: "E3_202_004",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες\n",
                    },
                    {
                        value: "E3_302_001",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Χονδρικές",
                    },
                    {
                        value: "E3_302_003",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές\n",
                    },
                    {
                        value: "E3_302_004",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
                    },
                    {
                        value: "E3_313_001",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Χονδρικές",
                    },
                    {
                        value: "E3_313_003",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
                    },
                    {
                        value: "E3_313_004",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
                    },
                    {
                        value: "E3_581_003",
                        label: "Παροχές σε εργαζόμενους/Λοιπές παροχές",
                    },
                ],
            },
            {
                value: "category2_5",
                label: "Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης ΦΠΑ (-) / (+)",
                type: [
                    {
                        value: "E3_585_001",
                        label: "Προμήθειες διαχείρισης ημεδαπής - αλλοδαπής (management fees)",
                    },
                    {
                        value: "E3_585_002",
                        label: "Δαπάνες από συνδεδεμένες επιχειρήσεις",
                    },
                    {
                        value: "E3_585_004",
                        label: "Δαπάνες για ενημερωτικές ημερίδες",
                    },
                    {
                        value: "E3_585_005",
                        label: "Έξοδα υποδοχής και φιλοξενίας",
                    },
                    {
                        value: "E3_585_006",
                        label: "Έξοδα ταξιδιών",
                    },
                    {
                        value: "E3_585_008",
                        label: "Έξοδα και προμήθειες παραγγελιοδόχου για λογαριασμό αγροτών",
                    },
                    {
                        value: "E3_585_009",
                        label: "Λοιπές Αμοιβές για υπηρεσίες ημεδαπής",
                    },
                    {
                        value: "E3_585_011",
                        label: "Ενέργεια",
                    },
                    {
                        value: "E3_585_012",
                        label: "Ύδρευση",
                    },
                    {
                        value: "E3_585_013",
                        label: "Τηλεπικοινωνίες",
                    },
                    {
                        value: "E3_585_014",
                        label: "Ενοίκια",
                    },
                    {
                        value: "E3_585_015",
                        label: "Διαφήμιση και προβολή",
                    },
                    {
                        value: "E3_585_016",
                        label: "Λοιπά έξοδα",
                    },
                    {
                        value: "E3_586",
                        label: "Χρεωστικοί τόκοι και συναφή έξοδα",
                    },
                    {
                        value: "E3_882_001",
                        label: "Αγορές ενσώματων παγίων χρήσης/Χονδρικές",
                    },
                    {
                        value: "E3_883_001",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Χονδρικές",
                    },
                    {
                        value: "E3_102_001",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Χονδρικές",
                    },
                    {
                        value: "E3_102_003",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Αγαθών του άρθρου 39α παρ.5 του Κώδικα Φ.Π.Α. (ν.2859/2000)",
                    },
                    {
                        value: "E3_102_004",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
                    },
                    {
                        value: "E3_102_005",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
                    },
                    {
                        value: "E3_202_001",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Χονδρικές",
                    },
                    {
                        value: "E3_202_003",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές\n",
                    },
                    {
                        value: "E3_202_004",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες\n",
                    },
                    {
                        value: "E3_302_001",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Χονδρικές",
                    },
                    {
                        value: "E3_302_003",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές\n",
                    },
                    {
                        value: "E3_302_004",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
                    },
                    {
                        value: "E3_313_001",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Χονδρικές",
                    },
                    {
                        value: "E3_313_003",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
                    },
                    {
                        value: "E3_313_004",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
                    },
                    {
                        value: "E3_581_003",
                        label: "Παροχές σε εργαζόμενους/Λοιπές παροχές",
                    },
                ],
            },
            {
                value: "category2_7",
                label: "Αγορές Παγίων (-) / (+)",
                type: [
                    {
                        value: "E3_882_001",
                        label: "Αγορές ενσώματων παγίων χρήσης/Χονδρικές",
                    },
                    {
                        value: "E3_883_001",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Χονδρικές",
                    },
                ],
            },
            {
                value: "category2_10",
                label: "Έξοδα προηγούμενων χρήσεων (-) / (+)",
                type: [
                    {
                        value: "E3_585_001",
                        label: "Προμήθειες διαχείρισης ημεδαπής - αλλοδαπής (management fees)",
                    },
                    {
                        value: "E3_585_002",
                        label: "Δαπάνες από συνδεδεμένες επιχειρήσεις",
                    },
                    {
                        value: "E3_585_004",
                        label: "Δαπάνες για ενημερωτικές ημερίδες",
                    },
                    {
                        value: "E3_585_005",
                        label: "Έξοδα υποδοχής και φιλοξενίας",
                    },
                    {
                        value: "E3_585_006",
                        label: "Έξοδα ταξιδιών",
                    },
                    {
                        value: "E3_585_008",
                        label: "Έξοδα και προμήθειες παραγγελιοδόχου για λογαριασμό αγροτών",
                    },
                    {
                        value: "E3_585_009",
                        label: "Λοιπές Αμοιβές για υπηρεσίες ημεδαπής",
                    },
                    {
                        value: "E3_585_011",
                        label: "Ενέργεια",
                    },
                    {
                        value: "E3_585_012",
                        label: "Ύδρευση",
                    },
                    {
                        value: "E3_585_013",
                        label: "Τηλεπικοινωνίες",
                    },
                    {
                        value: "E3_585_014",
                        label: "Ενοίκια",
                    },
                    {
                        value: "E3_585_015",
                        label: "Διαφήμιση και προβολή",
                    },
                    {
                        value: "E3_585_016",
                        label: "Λοιπά έξοδα",
                    },
                    {
                        value: "E3_586",
                        label: "Χρεωστικοί τόκοι και συναφή έξοδα",
                    },
                    {
                        value: "E3_882_001",
                        label: "Αγορές ενσώματων παγίων χρήσης/Χονδρικές",
                    },
                    {
                        value: "E3_883_001",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Χονδρικές",
                    },
                    {
                        value: "E3_102_001",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Χονδρικές",
                    },
                    {
                        value: "E3_102_003",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Αγαθών του άρθρου 39α παρ.5 του Κώδικα Φ.Π.Α. (ν.2859/2000)",
                    },
                    {
                        value: "E3_102_004",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
                    },
                    {
                        value: "E3_102_005",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
                    },
                    {
                        value: "E3_202_001",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Χονδρικές",
                    },
                    {
                        value: "E3_202_003",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές\n",
                    },
                    {
                        value: "E3_202_004",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες\n",
                    },
                    {
                        value: "E3_302_001",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Χονδρικές",
                    },
                    {
                        value: "E3_302_003",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές\n",
                    },
                    {
                        value: "E3_302_004",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
                    },
                    {
                        value: "E3_313_001",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Χονδρικές",
                    },
                    {
                        value: "E3_313_003",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
                    },
                    {
                        value: "E3_313_004",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
                    },
                    {
                        value: "E3_581_003",
                        label: "Παροχές σε εργαζόμενους/Λοιπές παροχές",
                    },
                ],
            },
            {
                value: "category2_11",
                label: "Έξοδα επομένων χρήσεων (-) / (+)",
                type: [
                    {
                        value: "E3_585_001",
                        label: "Προμήθειες διαχείρισης ημεδαπής - αλλοδαπής (management fees)",
                    },
                    {
                        value: "E3_585_002",
                        label: "Δαπάνες από συνδεδεμένες επιχειρήσεις",
                    },
                    {
                        value: "E3_585_004",
                        label: "Δαπάνες για ενημερωτικές ημερίδες",
                    },
                    {
                        value: "E3_585_005",
                        label: "Έξοδα υποδοχής και φιλοξενίας",
                    },
                    {
                        value: "E3_585_006",
                        label: "Έξοδα ταξιδιών",
                    },
                    {
                        value: "E3_585_008",
                        label: "Έξοδα και προμήθειες παραγγελιοδόχου για λογαριασμό αγροτών",
                    },
                    {
                        value: "E3_585_009",
                        label: "Λοιπές Αμοιβές για υπηρεσίες ημεδαπής",
                    },
                    {
                        value: "E3_585_011",
                        label: "Ενέργεια",
                    },
                    {
                        value: "E3_585_012",
                        label: "Ύδρευση",
                    },
                    {
                        value: "E3_585_013",
                        label: "Τηλεπικοινωνίες",
                    },
                    {
                        value: "E3_585_014",
                        label: "Ενοίκια",
                    },
                    {
                        value: "E3_585_015",
                        label: "Διαφήμιση και προβολή",
                    },
                    {
                        value: "E3_585_016",
                        label: "Λοιπά έξοδα",
                    },
                    {
                        value: "E3_586",
                        label: "Χρεωστικοί τόκοι και συναφή έξοδα",
                    },
                    {
                        value: "E3_882_001",
                        label: "Αγορές ενσώματων παγίων χρήσης/Χονδρικές",
                    },
                    {
                        value: "E3_883_001",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Χονδρικές",
                    },
                    {
                        value: "E3_102_001",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Χονδρικές",
                    },
                    {
                        value: "E3_102_003",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Αγαθών του άρθρου 39α παρ.5 του Κώδικα Φ.Π.Α. (ν.2859/2000)",
                    },
                    {
                        value: "E3_102_004",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
                    },
                    {
                        value: "E3_102_005",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
                    },
                    {
                        value: "E3_202_001",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Χονδρικές",
                    },
                    {
                        value: "E3_202_003",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές\n",
                    },
                    {
                        value: "E3_202_004",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες\n",
                    },
                    {
                        value: "E3_302_001",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Χονδρικές",
                    },
                    {
                        value: "E3_302_003",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές\n",
                    },
                    {
                        value: "E3_302_004",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
                    },
                    {
                        value: "E3_313_001",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Χονδρικές",
                    },
                    {
                        value: "E3_313_003",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
                    },
                    {
                        value: "E3_313_004",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
                    },
                    {
                        value: "E3_581_003",
                        label: "Παροχές σε εργαζόμενους/Λοιπές παροχές",
                    },
                ],
            },
            {
                value: "category2_95",
                label: "Λοιπά Πληροφοριακά Στοιχεία Εξόδων (-) / (+)",
                type: [
                    {
                        value: "null",
                        label: "Δεν ενημερώνει E3"
                    },
                ],
            },
        ]
    },
    {
        value: "1.2",
        label: "Τιμολόγιο Πώλησης/ Ενδοκοινοτικές Παραδόσεις",
        category: [
            {
                value: "category1_1",
                label: "Έσοδα από Πώληση Εμπορευμάτων (+) / (-)",
                type: [
                    {
                        value: "E3_561_005",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_561_007",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λοιπά"
                    },
                ]
            },
            {
                value: "category1_2",
                label: "Έσοδα από Πώληση Προϊόντων (+) / (-)",
                type: [
                    {
                        value: "E3_561_005",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_561_007",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λοιπά"
                    },
                ]
            },
            {
                value: "category1_3",
                label: "Έσοδα από Παροχή Υπηρεσιών (+) / (-)",
                type: [
                    {
                        value: "E3_561_005",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_561_007",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λοιπά"
                    },
                ]
            },
            {
                value: "category1_4",
                label: "Έσοδα από Πώληση Παγίων (+) / (-)",
                type: [
                    {
                        value: "E3_880_003",
                        label: "Πωλήσεις Παγίων Εξωτερικού Ενδοκοινοτικές"
                    },
                ]
            },
            {
                value: "category1_5",
                label: "Λοιπά Έσοδα/ Κέρδη (+) / (-)",
                type: [
                    {
                        value: "E3_561_005",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_561_007",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λοιπά"
                    },
                    {
                        value: "E3_562",
                        label: "Λοιπά συνήθη έσοδα"
                    },
                    {
                        value: "E3_570",
                        label: "Ασυνήθη έσοδα και κέρδη"
                    },
                ]
            },
            {
                value: "category1_7",
                label: "Έσοδα για λ/σμο τρίτων (+) / (-)",
                type: [
                    {
                        value: "E3_881_003",
                        label: "Πωλήσεις για λογ/σμο Τρίτων Εξωτερικού Ενδοκοινοτικές"
                    },
                ]
            },
            {
                value: "category1_8",
                label: "Έσοδα προηγούμενων χρήσεων (+) / (-)",
                type: [
                    {
                        value: "E3_561_005",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_561_007",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λοιπά"
                    },
                    {
                        value: "E3_562",
                        label: "Λοιπά συνήθη έσοδα"
                    },
                    {
                        value: "E3_570",
                        label: "Ασυνήθη έσοδα και κέρδη"
                    },
                    {
                        value: "E3_880_003",
                        label: "Πωλήσεις παγίων Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_881_003",
                        label: "Πωλήσεις για λογ/σμο Τρίτων Εξωτερικού Ενδοκοινοτικές"
                    },
                ]
            },
            {
                value: "category1_9",
                label: "Έσοδα επομένων χρήσεων (+) / (-)",
                type: [
                    {
                        value: "E3_561_005",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_561_007",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λοιπά"
                    },
                    {
                        value: "E3_562",
                        label: "Λοιπά συνήθη έσοδα"
                    },
                    {
                        value: "E3_570",
                        label: "Ασυνήθη έσοδα και κέρδη"
                    },
                    {
                        value: "E3_880_003",
                        label: "Πωλήσεις παγίων Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_881_003",
                        label: "Πωλήσεις για λογ/σμο Τρίτων Εξωτερικού Ενδοκοινοτικές"
                    },
                ]
            },
            {
                value: "category1_95",
                label: "Λοιπά Πληροφοριακά στοιχεία εσόδων (+)/ (-)",
                type: [
                    {
                        value: "null",
                        label: "Κενό"
                    },
                ]
            }
        ]
    },
    {
        value: "1.3",
        label: "Τιμολόγιο Πώλησης/ Παραδόσεις Τρίτων Χωρών",
        category: [
            {
                value: "category1_1",
                label: "Έσοδα από Πώληση Εμπορευμάτων (+) / (-)",
                type: [
                    {
                        value: "E3_561_006",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_561_007",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λοιπά"
                    },
                ]
            },
            {
                value: "category1_2",
                label: "Έσοδα από Πώληση Προϊόντων (+) / (-)",
                type: [
                    {
                        value: "E3_561_006",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_561_007",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λοιπά"
                    },
                ]
            },
            {
                value: "category1_3",
                label: "Έσοδα από Παροχή Υπηρεσιών (+) / (-)",
                type: [
                    {
                        value: "E3_561_006",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_561_007",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λοιπά"
                    },
                ]
            },
            {
                value: "category1_4",
                label: "Έσοδα από Πώληση Παγίων (+) / (-)",
                type: [
                    {
                        value: "E3_880_004",
                        label: "Πωλήσεις παγίων εξωτερικού Τρίτες Χώρες"
                    },
                ]
            },
            {
                value: "category1_5",
                label: "Λοιπά Έσοδα/Κέρδη (+) / (-)",
                type: [
                    {
                        value: "E3_561_006",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_561_007",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λοιπά"
                    },
                    {
                        value: "E3_562",
                        label: "Λοιπά συνήθη έσοδα"
                    },
                    {
                        value: "E3_570",
                        label: "Ασυνήθη έσοδα και κέρδη"
                    },
                ]
            },
            {
                value: "category1_7",
                label: "Έσοδα για λ/σμο τρίτων (+) / (-)",
                type: [
                    {
                        value: "E3_881_004",
                        label: "Πωλήσεις για λογ/σμο Τρίτων Εξωτερικού Τρίτες Χώρες"
                    }
                ]
            },
            {
                value: "category1_8",
                label: "Έσοδα προηγούμενων χρήσεων (+) / (-)",
                type: [
                    {
                        value: "E3_561_006",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_561_007",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λοιπά"
                    },
                    {
                        value: "E3_562",
                        label: "Λοιπά συνήθη έσοδα"
                    },
                    {
                        value: "E3_570",
                        label: "Ασυνήθη έσοδα και κέρδη"
                    },
                    {
                        value: "E3_880_004",
                        label: "Πωλήσεις παγίων εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_881_004",
                        label: "Πωλήσεις για λογ/σμο Τρίτων Εξωτερικού Τρίτες χώρες"
                    }
                ]
            },
            {
                value: "category1_9",
                label: "Έσοδα επομένων χρήσεων (+) / (-)",
                type: [
                    {
                        value: "E3_561_006",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_561_007",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λοιπά"
                    },
                    {
                        value: "E3_562",
                        label: "Λοιπά συνήθη έσοδα"
                    },
                    {
                        value: "E3_570",
                        label: "Ασυνήθη έσοδα και κέρδη"
                    },
                    {
                        value: "E3_880_004",
                        label: "Πωλήσεις παγίων εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_881_004",
                        label: "Πωλήσεις για λογ/σμο Τρίτων Εξωτερικού Τρίτες χώρες"
                    }
                ]
            },
            {
                value: "category1_95",
                label: "Λοιπά Πληροφοριακά στοιχεία εσόδων (+)/ (-)",
                type: [
                    {
                        value: "null",
                        label: "Κενό"
                    },
                ]
            }
        ]
    },
    {
        value: "1.4",
        label: "Τιμολόγιο Πώλησης/ Πώληση για Λογαριασμό Τρίτων",
        category: [
            {
                value: "category1_7",
                label: "Έσοδα από λογ/σμο τρίτων (+)/ (-)",
                type: [
                    {
                        value: "E3_881_001",
                        label: "Πωλήσεις για λογ/σμο Τρίτων Χονδρικές"
                    },
                    {
                        value: "E3_881_003",
                        label: "Πωλήσεις για λογ/σμο Τρίτων Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_881_004",
                        label: "Πωλήσεις για λογ/σμο Τρίτων Εξωτερικού Τρίτες Χώρες"
                    },
                ]
            },
            {
                value: "category1_95",
                label: "Λοιπά Πληροφοριακά στοιχεία εσόδων (+)/ (-)",
                type: [
                    {
                        value: "null",
                        label: "Κενό"
                    },
                ]
            },
            {
                value: "category2_1",
                label: "Αγορές Εμπορευμάτων (-) / (+)",
                type: [
                    {
                        value: "E3_102_001",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Χονδρικές"
                    },
                    {
                        value: "E3_102_003",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Αγαθών του άρθρου 39α παρ.5 του Κώδικα Φ.Π.Α. (ν.2859/2000)"
                    },
                    {
                        value: "E3_102_006",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό) Λοιπά"
                    },
                    {
                        value: "E3_313_001",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Χονδρικές"
                    },
                    {
                        value: "E3_313_005",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Λοιπά"
                    },
                ]
            },
            {
                value: "category2_2",
                label: "Αγορές Α'-Β' Υλών (-) / (+)",
                type: [
                    {
                        value: "E3_202_001",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Χονδρικές"
                    },
                    {
                        value: "E3_202_005",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά"
                    },
                    {
                        value: "E3_302_001",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Χονδρικές"
                    },
                    {
                        value: "E3_302_005",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά"
                    },
                ]
            },
            {
                value: "category2_4",
                label: "Γενικά Έξοδα με δικαίωμα έκπτωσης ΦΠΑ (-) / (+)",
                type: [
                    {
                        value: "E3_585_002",
                        label: "Δαπάνες από συνδεδεμένες επιχειρήσεις"
                    },
                    {
                        value: "E3_585_005",
                        label: "Έξοδα υποδοχής και φιλοξενίας"
                    },
                    {
                        value: "E3_585_006",
                        label: "Έξοδα ταξιδιών"
                    },
                    {
                        value: "E3_585_016",
                        label: "Λοιπά έξοδα"
                    },
                    {
                        value: "E3_102_001",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Χονδρικές"
                    },
                    {
                        value: "E3_102_003",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Αγαθών του άρθρου 39α παρ.5 του Κώδικα Φ.Π.Α. (ν.2859/2000)"
                    },
                    {
                        value: "E3_102_004",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_102_005",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_202_001",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Χονδρικές"
                    },
                    {
                        value: "E3_202_003",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_302_001",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Χονδρικές"
                    },
                    {
                        value: "E3_302_003",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_302_004",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_313_001",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Χονδρικές"
                    },
                    {
                        value: "E3_313_003",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_313_004",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_581_003",
                        label: "Παροχές σε εργαζόμενους/Λοιπές παροχές"
                    },
                ]
            },
            {
                value: "category2_5",
                label: "Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης ΦΠΑ (-) / (+)",
                type: [
                    {
                        value: "E3_585_002",
                        label: "Δαπάνες από συνδεδεμένες επιχειρήσεις"
                    },
                    {
                        value: "E3_585_005",
                        label: "Έξοδα υποδοχής και φιλοξενίας"
                    },
                    {
                        value: "E3_585_006",
                        label: "Έξοδα ταξιδιών"
                    },
                    {
                        value: "E3_585_016",
                        label: "Λοιπά έξοδα"
                    },
                    {
                        value: "E3_882_001",
                        label: "Αγορές ενσώματων παγίων χρήσης/Χονδρικές"
                    },
                    {
                        value: "E3_883_001",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Χονδρικές"
                    },
                    {
                        value: "E3_102_001",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Χονδρικές"
                    },
                    {
                        value: "E3_102_003",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Αγαθών του άρθρου 39α παρ.5 του Κώδικα Φ.Π.Α. (ν.2859/2000)"
                    },
                    {
                        value: "E3_102_004",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_102_005",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_202_001",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Χονδρικές"
                    },
                    {
                        value: "E3_202_003",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_202_004",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_302_001",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Χονδρικές"
                    },
                    {
                        value: "E3_302_003",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_302_004",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_313_001",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Χονδρικές"
                    },
                    {
                        value: "E3_313_003",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_313_004",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_581_003",
                        label: "Παροχές σε εργαζόμενους/Λοιπές παροχές"
                    },
                ]
            },
            {
                value: "category2_7",
                label: "Αγορές Παγίων (-) / (+)",
                type: [
                    {
                        value: "E3_882_001",
                        label: "Αγορές ενσώματων παγίων χρήσης/Χονδρικές"
                    },
                    {
                        value: "E3_883_001",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Χονδρικές"
                    },
                ]
            },
            {
                value: "category2_10",
                label: "Έξοδα προηγούμενων χρήσεων (-) / (+)",
                type: [
                    {
                        value: "E3_102_006",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό) Λοιπά"
                    },
                    {
                        value: "E3_313_005",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Λοιπά"
                    },
                    {
                        value: "E3_202_005",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά"
                    },
                    {
                        value: "E3_302_005",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά"
                    },
                    {
                        value: "E3_585_002",
                        label: "Δαπάνες από συνδεδεμένες επιχειρήσεις"
                    },
                    {
                        value: "E3_585_005",
                        label: "Έξοδα υποδοχής και φιλοξενίας"
                    },
                    {
                        value: "E3_585_006",
                        label: "Έξοδα ταξιδιών"
                    },
                    {
                        value: "E3_585_016",
                        label: "Λοιπά έξοδα"
                    },
                    {
                        value: "E3_882_001",
                        label: "Αγορές ενσώματων παγίων χρήσης/Χονδρικές"
                    },
                    {
                        value: "E3_883_001",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Χονδρικές"
                    },
                    {
                        value: "E3_102_001",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Χονδρικές"
                    },
                    {
                        value: "E3_102_003",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Αγαθών του άρθρου 39α παρ.5 του Κώδικα Φ.Π.Α. (ν.2859/2000)"
                    },
                    {
                        value: "E3_102_004",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_102_005",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_202_001",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Χονδρικές"
                    },
                    {
                        value: "E3_202_003",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_202_004",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_302_001",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Χονδρικές"
                    },
                    {
                        value: "E3_302_003",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_302_004",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_313_001",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Χονδρικές"
                    },
                    {
                        value: "E3_313_003",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_313_004",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_581_003",
                        label: "Παροχές σε εργαζόμενους/Λοιπές παροχές"
                    },
                ]
            },
            {
                value: "category2_11",
                label: "Έξοδα επομένων χρήσεων (-) / (+)",
                type: [
                    {
                        value: "E3_102_006",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό) Λοιπά"
                    },
                    {
                        value: "E3_313_005",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Λοιπά"
                    },
                    {
                        value: "E3_202_005",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά"
                    },
                    {
                        value: "E3_302_005",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά"
                    },
                    {
                        value: "E3_585_002",
                        label: "Δαπάνες από συνδεδεμένες επιχειρήσεις"
                    },
                    {
                        value: "E3_585_005",
                        label: "Έξοδα υποδοχής και φιλοξενίας"
                    },
                    {
                        value: "E3_585_006",
                        label: "Έξοδα ταξιδιών"
                    },
                    {
                        value: "E3_585_016",
                        label: "Λοιπά έξοδα"
                    },
                    {
                        value: "E3_882_001",
                        label: "Αγορές ενσώματων παγίων χρήσης/Χονδρικές"
                    },
                    {
                        value: "E3_883_001",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Χονδρικές"
                    },
                    {
                        value: "E3_102_001",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Χονδρικές"
                    },
                    {
                        value: "E3_102_003",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Αγαθών του άρθρου 39α παρ.5 του Κώδικα Φ.Π.Α. (ν.2859/2000)"
                    },
                    {
                        value: "E3_102_004",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_102_005",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_202_001",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Χονδρικές"
                    },
                    {
                        value: "E3_202_003",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_202_004",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_302_001",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Χονδρικές"
                    },
                    {
                        value: "E3_302_003",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_302_004",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_313_001",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Χονδρικές"
                    },
                    {
                        value: "E3_313_003",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_313_004",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_581_003",
                        label: "Παροχές σε εργαζόμενους/Λοιπές παροχές"
                    },
                ]
            },
            {
                value: "category2_95",
                label: "Λοιπά Πληροφοριακά Στοιχεία Εξόδων (-) / (+)",
                type: [
                    {
                        value: "null",
                        label: "Δεν ενημερώνει E3"
                    },
                ],
            },
        ]
    },
    {
        value: "1.5",
        label: "Τιμολόγιο Πώλησης/ Εκκαθάριση Πωλήσεων Τρίτων- Αμοιβή από Πωλήσεις Τρίτων",
        category: [
            {
                value: "category1_1",
                label: "Έσοδα από πώληση Εμπορευμάτων  (+)/ (-)",
                type: [
                    {
                        value: "E3_561_001",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Χονδρικές -Επιτηδευμάτων "
                    },
                    {
                        value: "E3_561_002",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Χονδρικές  βάσει άρθρου 39α παρ 5 του κώδικα ΦΠΑ (Ν2859/2000)"
                    },
                    {
                        value: "E3_561_007",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λοιπά"
                    },
                ]
            },
            {
                value: "category1_2",
                label: "Έσοδα από Πώληση Προϊόντων (+) / (-)",
                type: [
                    {
                        value: "E3_561_001",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Χονδρικές -Επιτηδευμάτων "
                    },
                    {
                        value: "E3_561_002",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Χονδρικές  βάσει άρθρου 39α παρ 5 του κώδικα ΦΠΑ (Ν2859/2000)"
                    },
                    {
                        value: "E3_561_007",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λοιπά"
                    },
                ]
            },
            {
                value: "category1_3",
                label: "Έσοδα από Παροχή Υπηρεσιών  (+)/ (-)",
                type: [
                    {
                        value: "E3_561_001",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Χονδρικές -Επιτηδευμάτων "
                    },
                    {
                        value: "E3_561_007",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λοιπά"
                    },
                ]
            },
            {
                value: "category1_4",
                label: "Έσοδα από Πώληση Παγίων  (+)/ (-)",
                type: [
                    {
                        value: "E3_880_001",
                        label: "Πωλήσεις παγίων Χονδρικές"
                    },
                    {
                        value: "E3_880_003",
                        label: "Πωλήσεις παγίων Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_880_004",
                        label: "Πωλήσεις παγίων εξωτερικού Τρίτες Χώρες"
                    },
                ]
            },
            {
                value: "category1_8",
                label: "Έσοδα προηγούμενων χρήσεων (+)/ (-)",
                type: [
                    {
                        value: "E3_561_001",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Χονδρικές -Επιτηδευμάτων "
                    },
                    {
                        value: "E3_561_002",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Χονδρικές  βάσει άρθρου 39α παρ 5 του κώδικα ΦΠΑ (Ν2859/2000)"
                    },
                    {
                        value: "E3_561_007",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λοιπά"
                    },
                    {
                        value: "E3_880_001",
                        label: "Πωλήσεις παγίων Χονδρικές"
                    },
                    {
                        value: "E3_880_003",
                        label: "Πωλήσεις παγίων Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_880_004",
                        label: "Πωλήσεις παγίων εξωτερικού Τρίτες Χώρες"
                    },
                ]
            },
            {
                value: "category1_9",
                label: "Έσοδα επομένων χρήσεων (+)/ (-)",
                type: [
                    {
                        value: "E3_561_001",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Χονδρικές -Επιτηδευμάτων "
                    },
                    {
                        value: "E3_561_002",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Χονδρικές  βάσει άρθρου 39α παρ 5 του κώδικα ΦΠΑ (Ν2859/2000)"
                    },
                    {
                        value: "E3_561_007",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λοιπά"
                    },
                    {
                        value: "E3_880_001",
                        label: "Πωλήσεις παγίων Χονδρικές"
                    },
                    {
                        value: "E3_880_003",
                        label: "Πωλήσεις παγίων Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_880_004",
                        label: "Πωλήσεις παγίων εξωτερικού Τρίτες Χώρες"
                    },
                ]
            },
            {
              value: "category2_3",
              label: "Λήψη Υπηρεσιών (-) / (+)",
              type: [
                  {
                      value: "E3_585_009",
                      label: "Λοιπές Αμοιβές για υπηρεσίες ημεδαπής"
                  }
              ]
            },
            {
                value: "category2_4",
                label: "Γενικά Έξοδα με δικαίωμα έκπτωσης ΦΠΑ (-) / (+)",
                type: [
                    {
                        value: "E3_585_009",
                        label: "Λοιπές Αμοιβές για υπηρεσίες ημεδαπής"
                    }
                ]
            },
            {
                value: "category2_5",
                label: "Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης ΦΠΑ (-) / (+)",
                type: [
                    {
                        value: "E3_585_009",
                        label: "Λοιπές Αμοιβές για υπηρεσίες ημεδαπής"
                    }
                ]
            },
            {
                value: "category2_9",
                label: "Έξοδα για λ/σμο τρίτων (-) / (+)",
                type: [
                    {
                        value: "null",
                        label: "Δεν ενημερώνει E3"
                    },
                ]
            },
            {
                value: "category2_10",
                label: "Έξοδα προηγούμενων χρήσεων (-) / (+)",
                type: [
                    {
                        value: "E3_585_009",
                        label: "Λοιπές Αμοιβές για υπηρεσίες ημεδαπής"
                    }
                ]
            },
            {
                value: "category2_11",
                label: "Έξοδα επομένων χρήσεων (-) / (+)",
                type: [
                    {
                        value: "E3_585_009",
                        label: "Λοιπές Αμοιβές για υπηρεσίες ημεδαπής"
                    }
                ]
            },
        ]
    },
    {
        value: "1.6",
        label: "Τιμολόγιο Πώλησης / Συμπληρωματικό Παραστατικό",
        category: [
            {
                value: "category1_1",
                label: "Έσοδα από πώληση Εμπορευμάτων  (+)/ (-)",
                type: [
                    {
                        value: "E3_561_001",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Χονδρικές - Επιτηδευμάτων "
                    },
                    {
                        value: "E3_561_002",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Χονδρικές βάσει άρθρου 39α παρ 5 του Κώδικα Φ.Π.Α. (Ν.2859/2000)"
                    },
                    {
                        value: "E3_561_007",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λοιπά"
                    },
                ]
            },
            {
                value: "category1_2",
                label: "Έσοδα από Πώληση Προϊόντων (+) / (-)",
                type: [
                    {
                        value: "E3_561_001",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Χονδρικές - Επιτηδευμάτων "
                    },
                    {
                        value: "E3_561_002",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Χονδρικές βάσει άρθρου 39α παρ 5 του Κώδικα Φ.Π.Α. (Ν.2859/2000)"
                    },
                    {
                        value: "E3_561_007",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λοιπά"
                    },
                ]
            },
            {
                value: "category1_3",
                label: "Έσοδα από Παροχή Υπηρεσιών (+) / (-)",
                type: [
                    {
                        value: "E3_561_001",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Χονδρικές - Επιτηδευματιών"
                    },
                    {
                        value: "E3_561_002",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Χονδρικές βάσει άρθρου 39α παρ 5 του Κώδικα Φ.Π.Α. (Ν.2859/2000)"
                    },
                    {
                        value: "E3_561_007",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λοιπά"
                    },
                    {
                        value: "E3_563",
                        label: "Πιστωτικοί τόκοι και συναφή έσοδα"
                    },
                ]
            },
            {
                value: "category1_4",
                label: "Έσοδα από Πώληση Παγίων  (+)/ (-)",
                type: [
                    {
                        value: "E3_880_001",
                        label: "Πωλήσεις παγίων Χονδρικές"
                    },
                ]
            },
            {
                value: "category1_5",
                label: "Λοιπά Έσοδα/Κέρδη (+)/ (-)",
                type: [
                    {
                        value: "E3_561_007",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λοιπά"
                    },
                    {
                        value: "E3_562",
                        label: "Λοιπά συνήθη έσοδα"
                    },
                    {
                        value: "E3_563",
                        label: "Πιστωτικοί τόκοι και συναφή έσοδα"
                    },
                    {
                        value: "E3_564",
                        label: "Πιστωτικές συναλλαγματικές διαφορές "
                    },
                    {
                        value: "E3_565",
                        label: "Έσοδα συμμετοχών"
                    },
                    {
                        value: "E3_566",
                        label: "Κέρδη από διάθεση μη κυκλοφορούντων περιουσιακών στοιχείων"
                    },
                    {
                        value: "E3_567",
                        label: "Κέρδη από αναστροφή προβλέψεων και απομειώσεων"
                    },
                    {
                        value: "E3_568",
                        label: "Κέρδη απο επιμέτρηση στην εύλογη αξία "
                    },
                    {
                        value: "E3_570",
                        label: "Ασυνήθη έσοδα και κέρδη"
                    },
                    {
                        value: "E3_561_002",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Χονδρικές βάσει άρθρου 39α παρ 5 του Κώδικα Φ.Π.Α. (Ν.2859/2000)"
                    },
                ]
            },
            {
                value: "category1_7",
                label: "Έσοδα για λ/σμο τρίτων (+) / (-)",
                type: [
                    {
                        value: "E3_881_001",
                        label: "Πωλήσεις για λογ/σμο Τρίτων Χονδρικές"
                    },
                    {
                        value: "E3_881_003",
                        label: "Πωλήσεις για λογ/σμο Τρίτων Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_881_004",
                        label: "Πωλήσεις για λογ/σμο Τρίτων Εξωτερικού Τρίτες Χώρες"
                    }
                ]
            },
            {
                value: "category1_8",
                label: "Έσοδα προηγούμενων χρήσεων (+)/ (-)",
                type: [
                    {
                        value: "E3_561_001",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Χονδρικές - Επιτηδευματιών"
                    },
                    {
                        value: "E3_561_002",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Χονδρικές βάσει άρθρου 39α παρ 5 του Κώδικα Φ.Π.Α. (Ν.2859/2000)"
                    },
                    {
                        value: "E3_561_007",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λοιπά"
                    },
                    {
                        value: "E3_880_001",
                        label: "Πωλήσεις παγίων Χονδρικές"
                    },
                    {
                        value: "E3_562",
                        label: "Λοιπά συνήθη έσοδα"
                    },
                    {
                        value: "E3_563",
                        label: "Πιστωτικοί τόκοι και συναφή έσοδα"
                    },
                    {
                        value: "E3_564",
                        label: "Πιστωτικές συναλλαγματικές διαφορές"
                    },
                    {
                        value: "E3_565",
                        label: "Έσοδα συμμετοχών"
                    },
                    {
                        value: "E3_566",
                        label: "Κέρδη από διάθεση μη κυκλοφορούντων περιουσιακών στοιχείων"
                    },
                    {
                        value: "E3_567",
                        label: "Κέρδη από αναστροφή προβλέψεων και απομειώσεων"
                    },
                    {
                        value: "E3_568",
                        label: "Κέρδη απο επιμέτρηση στην εύλογη αξία "
                    },
                    {
                        value: "E3_570",
                        label: "Ασυνήθη έσοδα και κέρδη"
                    },
                ]
            },
            {
                value: "category1_9",
                label: "Έσοδα επομένων χρήσεων (+)/ (-)",
                type: [
                    {
                        value: "E3_561_001",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Χονδρικές - Επιτηδευματιών"
                    },
                    {
                        value: "E3_561_002",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Χονδρικές βάσει άρθρου 39α παρ 5 του Κώδικα Φ.Π.Α. (Ν.2859/2000)"
                    },
                    {
                        value: "E3_561_007",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λοιπά"
                    },
                    {
                        value: "E3_880_001",
                        label: "Πωλήσεις παγίων Χονδρικές"
                    },
                    {
                        value: "E3_562",
                        label: "Λοιπά συνήθη έσοδα"
                    },
                    {
                        value: "E3_563",
                        label: "Πιστωτικοί τόκοι και συναφή έσοδα"
                    },
                    {
                        value: "E3_564",
                        label: "Πιστωτικές συναλλαγματικές διαφορές "
                    },
                    {
                        value: "E3_565",
                        label: "Έσοδα συμμετοχών"
                    },
                    {
                        value: "E3_566",
                        label: "Κέρδη από διάθεση μη κυκλοφορούντων περιουσιακών στοιχείων"
                    },
                    {
                        value: "E3_567",
                        label: "Κέρδη από αναστροφή προβλέψεων και απομειώσεων"
                    },
                    {
                        value: "E3_568",
                        label: "Κέρδη απο επιμέτρηση στην εύλογη αξία"
                    },
                    {
                        value: "E3_570",
                        label: "Ασυνήθη έσοδα και κέρδη"
                    },
                ]
            },
            {
                value: "category1_95",
                label: "Λοιπά Πληροφοριακά Στοιχεία Εσόδων (+) / (-)",
                type: [
                    {
                        value: "null",
                        label: "Κενό"
                    },
                    {
                        value: "E3_596",
                        label: "Επιδοτήσεις - Επιχορηγήσεις"
                    },
                    {
                        value: "E3_597",
                        label: "Επιδοτήσεις - Επιχορηγήσεις για επενδυτικούς σκοπούς - κάλυψη δαπανών"
                    },
                ]
            },
            {
                value: "category2_95",
                label: "Λοιπά Πληροφοριακά Στοιχεία Εξόδων (-) / (+)",
                type: [
                    {
                        value: "null",
                        label: "Δεν ενημερώνει Ε3"
                    },
                ]
            }
        ]
    },
    {
        value: "2.1",
        label: "Τιμολόγιο Παροχής",
        category: [
            {
                value: "category1_3",
                label: "Έσοδα από Παροχή Υπηρεσιών  (+)/ (-)",
                type: [
                    {
                        value: "E3_561_001",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Χονδρικές - Επιτηδευματιών"
                    },
                    {
                        value: "E3_561_002",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Χονδρικές βάσει άρθρου 39α παρ 5 του Κώδικα Φ.Π.Α. (Ν.2859/2000)"
                    },
                    {
                        value: "E3_561_007",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λοιπά"
                    },
                    {
                        value: "E3_563",
                        label: "Πιστωτικοί τόκοι και συναφή έσοδα"
                    },
                ]
            },
            {
                value: "category1_5",
                label: "Λοιπά Έσοδα/Κέρδη (+)/ (-)",
                type: [
                    {
                        value: "E3_561_007",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λοιπά"
                    },
                    {
                        value: "E3_562",
                        label: "Λοιπά συνήθη έσοδα"
                    },
                    {
                        value: "E3_563",
                        label: "Πιστωτικοί τόκοι και συναφή έσοδα"
                    },
                    {
                        value: "E3_564",
                        label: "Πιστωτικές συναλλαγματικές διαφορές "
                    },
                    {
                        value: "E3_565",
                        label: "Έσοδα συμμετοχών"
                    },
                    {
                        value: "E3_566",
                        label: "Κέρδη από διάθεση μη κυκλοφορούντων περιουσιακών στοιχείων"
                    },
                    {
                        value: "E3_567",
                        label: "Κέρδη από αναστροφή προβλέψεων και απομειώσεων"
                    },
                    {
                        value: "E3_568",
                        label: "Κέρδη απο επιμέτρηση στην εύλογη αξία "
                    },
                    {
                        value: "E3_570",
                        label: "Ασυνήθη έσοδα και κέρδη"
                    },
                ]
            },
            {
                value: "category1_8",
                label: "Έσοδα προηγούμενων χρήσεων (+)/ (-)",
                type: [
                    {
                        value: "E3_561_001",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Χονδρικές - Επιτηδευματιών"
                    },
                    {
                        value: "E3_561_002",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Χονδρικές βάσει άρθρου 39α παρ 5 του Κώδικα Φ.Π.Α. (Ν.2859/2000)"
                    },
                    {
                        value: "E3_561_007",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λοιπά"
                    },
                    {
                        value: "E3_562",
                        label: "Λοιπά συνήθη έσοδα"
                    },
                    {
                        value: "E3_563",
                        label: "Πιστωτικοί τόκοι και συναφή έσοδα"
                    },
                    {
                        value: "E3_564",
                        label: "Πιστωτικές συναλλαγματικές διαφορές "
                    },
                    {
                        value: "E3_565",
                        label: "Έσοδα συμμετοχών"
                    },
                    {
                        value: "E3_566",
                        label: "Κέρδη από διάθεση μη κυκλοφορούντων περιουσιακών στοιχείων"
                    },
                    {
                        value: "E3_567",
                        label: "Κέρδη από αναστροφή προβλέψεων και απομειώσεων"
                    },
                    {
                        value: "E3_568",
                        label: "Κέρδη απο επιμέτρηση στην εύλογη αξία "
                    },
                    {
                        value: "E3_570",
                        label: "Ασυνήθη έσοδα και κέρδη"
                    },
                ]
            },
            {
                value: "category1_9",
                label: "Έσοδα επομένων χρήσεων (+)/ (-)",
                type: [
                    {
                        value: "E3_561_001",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Χονδρικές - Επιτηδευματιών"
                    },
                    {
                        value: "E3_561_002",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Χονδρικές βάσει άρθρου 39α παρ 5 του Κώδικα Φ.Π.Α. (Ν.2859/2000)"
                    },
                    {
                        value: "E3_561_007",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λοιπά"
                    },
                    {
                        value: "E3_562",
                        label: "Λοιπά συνήθη έσοδα"
                    },
                    {
                        value: "E3_563",
                        label: "Πιστωτικοί τόκοι και συναφή έσοδα"
                    },
                    {
                        value: "E3_564",
                        label: "Πιστωτικές συναλλαγματικές διαφορές "
                    },
                    {
                        value: "E3_565",
                        label: "Έσοδα συμμετοχών"
                    },
                    {
                        value: "E3_566",
                        label: "Κέρδη από διάθεση μη κυκλοφορούντων περιουσιακών στοιχείων"
                    },
                    {
                        value: "E3_567",
                        label: "Κέρδη από αναστροφή προβλέψεων και απομειώσεων"
                    },
                    {
                        value: "E3_568",
                        label: "Κέρδη απο επιμέτρηση στην εύλογη αξία "
                    },
                    {
                        value: "E3_570",
                        label: "Ασυνήθη έσοδα και κέρδη"
                    },
                ]
            },
            {
                value: "category1_95",
                label: "Λοιπά Πληροφοριακά στοιχεία εσόδων (+)/ (-)",
                type: [
                    {
                        value: "null",
                        label: "Κενό"
                    },
                ]
            },
            {
                value: "category2_1",
                label: "Αγορές Εμπορευμάτων (-) / (+)",
                type: [
                    {
                        value: "E3_102_001",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Χονδρικές"
                    },
                    {
                        value: "E3_102_003",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Αγαθών του άρθρου 39α παρ.5 του Κώδικα Φ.Π.Α. (ν.2859/2000)"
                    },
                    {
                        value: "E3_102_006",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό) Λοιπά"
                    },
                    {
                        value: "E3_313_001",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Χονδρικές"
                    },
                    {
                        value: "E3_313_005",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Λοιπά"
                    }
                ]
            },
            {
                value: "category2_2",
                label: "Αγορές Α'-Β' Υλών (-) / (+)",
                type: [
                    {
                        value: "E3_202_001",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Χονδρικές"
                    },
                    {
                        value: "E3_202_005",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά"
                    },
                    {
                        value: "E3_302_001",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Χονδρικές"
                    },
                    {
                        value: "E3_302_005",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά"
                    }
                ]
            },
            {
                value: "category2_3",
                label: "Λήψη Υπηρεσιών (-) / (+)",
                type: [
                    {
                        value: "E3_585_001",
                        label: "Προμήθειες διαχείρισης ημεδαπής - αλλοδαπής (management fees)"
                    },
                    {
                        value: "E3_585_002",
                        label: "Δαπάνες από συνδεδεμένες επιχειρήσεις"
                    },
                    {
                        value: "E3_585_004",
                        label: "Δαπάνες για ενημερωτικές ημερίδες"
                    },
                    {
                        value: "E3_585_005",
                        label: "Έξοδα υποδοχής και φιλοξενίας"
                    },
                    {
                        value: "E3_585_006",
                        label: "Έξοδα ταξιδιών"
                    },
                    {
                        value: "E3_585_008",
                        label: "Έξοδα και προμήθειες παραγγελιοδόχου για λογαριασμό αγροτών"
                    },
                    {
                        value: "E3_585_009",
                        label: "Λοιπές Αμοιβές για υπηρεσίες ημεδαπής"
                    },
                    {
                        value: "E3_585_011",
                        label: "Ενέργεια"
                    },
                    {
                        value: "E3_585_012",
                        label: "Ύδρευση"
                    },
                    {
                        value: "E3_585_013",
                        label: "Τηλεπικοινωνίες"
                    },
                    {
                        value: "E3_585_014",
                        label: "Ενοίκια"
                    },
                    {
                        value: "E3_585_015",
                        label: "Διαφήμιση και προβολή"
                    },
                    {
                        value: "E3_585_016",
                        label: "Λοιπά έξοδα"
                    },
                    {
                        value: "E3_586",
                        label: "Χρεωστικοί τόκοι και συναφή έξοδα"
                    },
                    {
                        value: "E3_581_003",
                        label: "Παροχές σε εργαζόμενους/Λοιπές παροχές"
                    },
                ]
            },
            {
                value: "category2_4",
                label: "Γενικά Έξοδα με δικαίωμα έκπτωσης ΦΠΑ (-) / (+)",
                type: [
                    {
                        value: "E3_585_001",
                        label: "Προμήθειες διαχείρισης ημεδαπής - αλλοδαπής (management fees)"
                    },
                    {
                        value: "E3_585_002",
                        label: "Δαπάνες από συνδεδεμένες επιχειρήσεις"
                    },
                    {
                        value: "E3_585_004",
                        label: "Δαπάνες για ενημερωτικές ημερίδες"
                    },
                    {
                        value: "E3_585_005",
                        label: "Έξοδα υποδοχής και φιλοξενίας"
                    },
                    {
                        value: "E3_585_006",
                        label: "Έξοδα ταξιδιών"
                    },
                    {
                        value: "E3_585_008",
                        label: "Έξοδα και προμήθειες παραγγελιοδόχου για λογαριασμό αγροτών"
                    },
                    {
                        value: "E3_585_009",
                        label: "Λοιπές Αμοιβές για υπηρεσίες ημεδαπής"
                    },
                    {
                        value: "E3_585_011",
                        label: "Ενέργεια"
                    },
                    {
                        value: "E3_585_012",
                        label: "Ύδρευση"
                    },
                    {
                        value: "E3_585_013",
                        label: "Τηλεπικοινωνίες"
                    },
                    {
                        value: "E3_585_014",
                        label: "Ενοίκια"
                    },
                    {
                        value: "E3_585_015",
                        label: "Διαφήμιση και προβολή"
                    },
                    {
                        value: "E3_585_016",
                        label: "Λοιπά έξοδα"
                    },
                    {
                        value: "E3_586",
                        label: "Χρεωστικοί τόκοι και συναφή έξοδα"
                    },
                    {
                        value: "E3_102_001",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Χονδρικές"
                    },
                    {
                        value: "E3_102_003",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Αγαθών του άρθρου 39α παρ.5 του Κώδικα Φ.Π.Α. (ν.2859/2000)"
                    },
                    {
                        value: "E3_102_004",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_102_005",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_202_001",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Χονδρικές"
                    },
                    {
                        value: "E3_202_003",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_202_004",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_302_001",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Χονδρικές"
                    },
                    {
                        value: "E3_302_003",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_302_004",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_313_001",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Χονδρικές"
                    },
                    {
                        value: "E3_313_003",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_313_004",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_581_003",
                        label: "Παροχές σε εργαζόμενους/Λοιπές παροχές"
                    },
                ]
            },
            {
                value: "category2_5",
                label: "Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης ΦΠΑ (-) / (+)",
                type: [
                    {
                        value: "E3_585_001",
                        label: "Προμήθειες διαχείρισης ημεδαπής - αλλοδαπής (management fees)"
                    },
                    {
                        value: "E3_585_002",
                        label: "Δαπάνες από συνδεδεμένες επιχειρήσεις"
                    },
                    {
                        value: "E3_585_004",
                        label: "Δαπάνες για ενημερωτικές ημερίδες"
                    },
                    {
                        value: "E3_585_005",
                        label: "Έξοδα υποδοχής και φιλοξενίας"
                    },
                    {
                        value: "E3_585_006",
                        label: "Έξοδα ταξιδιών"
                    },
                    {
                        value: "E3_585_008",
                        label: "Έξοδα και προμήθειες παραγγελιοδόχου για λογαριασμό αγροτών"
                    },
                    {
                        value: "E3_585_009",
                        label: "Λοιπές Αμοιβές για υπηρεσίες ημεδαπής"
                    },
                    {
                        value: "E3_585_011",
                        label: "Ενέργεια"
                    },
                    {
                        value: "E3_585_012",
                        label: "Ύδρευση"
                    },
                    {
                        value: "E3_585_013",
                        label: "Τηλεπικοινωνίες"
                    },
                    {
                        value: "E3_585_014",
                        label: "Ενοίκια"
                    },
                    {
                        value: "E3_585_015",
                        label: "Διαφήμιση και προβολή"
                    },
                    {
                        value: "E3_585_016",
                        label: "Λοιπά έξοδα"
                    },
                    {
                        value: "E3_586",
                        label: "Χρεωστικοί τόκοι και συναφή έξοδα"
                    },
                    {
                        value: "E3_882_001",
                        label: "Αγορές ενσώματων παγίων χρήσης/Χονδρικές",
                    },
                    {
                        value: "E3_883_001",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Χονδρικές",
                    },
                    {
                        value: "E3_102_001",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Χονδρικές"
                    },
                    {
                        value: "E3_102_003",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Αγαθών του άρθρου 39α παρ.5 του Κώδικα Φ.Π.Α. (ν.2859/2000)"
                    },
                    {
                        value: "E3_102_004",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_102_005",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_202_001",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Χονδρικές"
                    },
                    {
                        value: "E3_202_003",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_202_004",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_302_001",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Χονδρικές"
                    },
                    {
                        value: "E3_302_003",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_302_004",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_313_001",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Χονδρικές"
                    },
                    {
                        value: "E3_313_003",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_313_004",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_581_003",
                        label: "Παροχές σε εργαζόμενους/Λοιπές παροχές"
                    },
                ]
            },
            {
                value: "category2_7",
                label: "Αγορές Παγίων (-) / (+)",
                type: [
                    {
                        value: "E3_882_001",
                        label: "Αγορές ενσώματων παγίων χρήσης/Χονδρικές"
                    },
                    {
                        value: "E3_882_003",
                        label: "Αγορές ενσώματων παγίων χρήσης/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_882_004",
                        label: "Αγορές ενσώματων παγίων χρήσης/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_883_001",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Χονδρικές"
                    },
                    {
                        value: "E3_883_003",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_883_004",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Εξωτερικού Τρίτες Χώρες"
                    }
                ]
            },
            {
                value: "category2_10",
                label: "Έξοδα προηγούμενων χρήσεων (-) / (+)",
                type: [
                    {
                        value: "E3_585_001",
                        label: "Προμήθειες διαχείρισης ημεδαπής - αλλοδαπής (management fees)"
                    },
                    {
                        value: "E3_585_002",
                        label: "Δαπάνες από συνδεδεμένες επιχειρήσεις"
                    },
                    {
                        value: "E3_585_004",
                        label: "Δαπάνες για ενημερωτικές ημερίδες"
                    },
                    {
                        value: "E3_585_005",
                        label: "Έξοδα υποδοχής και φιλοξενίας"
                    },
                    {
                        value: "E3_585_006",
                        label: "Έξοδα ταξιδιών"
                    },
                    {
                        value: "E3_585_008",
                        label: "Έξοδα και προμήθειες παραγγελιοδόχου για λογαριασμό αγροτών"
                    },
                    {
                        value: "E3_585_009",
                        label: "Λοιπές Αμοιβές για υπηρεσίες ημεδαπής"
                    },
                    {
                        value: "E3_585_011",
                        label: "Ενέργεια"
                    },
                    {
                        value: "E3_585_012",
                        label: "Ύδρευση"
                    },
                    {
                        value: "E3_585_013",
                        label: "Τηλεπικοινωνίες"
                    },
                    {
                        value: "E3_585_014",
                        label: "Ενοίκια"
                    },
                    {
                        value: "E3_585_015",
                        label: "Διαφήμιση και προβολή"
                    },
                    {
                        value: "E3_585_016",
                        label: "Λοιπά έξοδα"
                    },
                    {
                        value: "E3_586",
                        label: "Χρεωστικοί τόκοι και συναφή έξοδα"
                    },
                    {
                        value: "E3_882_001",
                        label: "Αγορές ενσώματων παγίων χρήσης/Χονδρικές",
                    },
                    {
                        value: "E3_883_001",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Χονδρικές",
                    },
                    {
                        value: "E3_102_001",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Χονδρικές"
                    },
                    {
                        value: "E3_102_003",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Αγαθών του άρθρου 39α παρ.5 του Κώδικα Φ.Π.Α. (ν.2859/2000)"
                    },
                    {
                        value: "E3_102_004",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_102_005",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_202_001",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Χονδρικές"
                    },
                    {
                        value: "E3_202_003",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_202_004",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_302_001",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Χονδρικές"
                    },
                    {
                        value: "E3_302_003",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_302_004",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_313_001",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Χονδρικές"
                    },
                    {
                        value: "E3_313_003",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_313_004",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_581_003",
                        label: "Παροχές σε εργαζόμενους/Λοιπές παροχές"
                    },
                    {
                        value: "E3_102_006",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό) Λοιπά"
                    },
                    {
                        value: "E3_313_005",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Λοιπά"
                    },
                    {
                        value: "E3_202_005",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά"
                    },
                    {
                        value: "E3_302_005",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά"
                    },
                    {
                        value: "E3_883_003",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_882_004",
                        label: "Αγορές ενσώματων παγίων χρήσης/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_883_004",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Εξωτερικού Τρίτες Χώρες"
                    }
                ]
            },
            {
                value: "category2_11",
                label: "Έξοδα επομένων χρήσεων (-) / (+)",
                type: [
                    {
                        value: "E3_585_001",
                        label: "Προμήθειες διαχείρισης ημεδαπής - αλλοδαπής (management fees)"
                    },
                    {
                        value: "E3_585_002",
                        label: "Δαπάνες από συνδεδεμένες επιχειρήσεις"
                    },
                    {
                        value: "E3_585_004",
                        label: "Δαπάνες για ενημερωτικές ημερίδες"
                    },
                    {
                        value: "E3_585_005",
                        label: "Έξοδα υποδοχής και φιλοξενίας"
                    },
                    {
                        value: "E3_585_006",
                        label: "Έξοδα ταξιδιών"
                    },
                    {
                        value: "E3_585_008",
                        label: "Έξοδα και προμήθειες παραγγελιοδόχου για λογαριασμό αγροτών"
                    },
                    {
                        value: "E3_585_009",
                        label: "Λοιπές Αμοιβές για υπηρεσίες ημεδαπής"
                    },
                    {
                        value: "E3_585_011",
                        label: "Ενέργεια"
                    },
                    {
                        value: "E3_585_012",
                        label: "Ύδρευση"
                    },
                    {
                        value: "E3_585_013",
                        label: "Τηλεπικοινωνίες"
                    },
                    {
                        value: "E3_585_014",
                        label: "Ενοίκια"
                    },
                    {
                        value: "E3_585_015",
                        label: "Διαφήμιση και προβολή"
                    },
                    {
                        value: "E3_585_016",
                        label: "Λοιπά έξοδα"
                    },
                    {
                        value: "E3_586",
                        label: "Χρεωστικοί τόκοι και συναφή έξοδα"
                    },
                    {
                        value: "E3_882_001",
                        label: "Αγορές ενσώματων παγίων χρήσης/Χονδρικές",
                    },
                    {
                        value: "E3_883_001",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Χονδρικές",
                    },
                    {
                        value: "E3_102_001",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Χονδρικές"
                    },
                    {
                        value: "E3_102_003",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Αγαθών του άρθρου 39α παρ.5 του Κώδικα Φ.Π.Α. (ν.2859/2000)"
                    },
                    {
                        value: "E3_102_004",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_102_005",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_202_001",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Χονδρικές"
                    },
                    {
                        value: "E3_202_003",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_202_004",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_302_001",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Χονδρικές"
                    },
                    {
                        value: "E3_302_003",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_302_004",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_313_001",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Χονδρικές"
                    },
                    {
                        value: "E3_313_003",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_313_004",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_581_003",
                        label: "Παροχές σε εργαζόμενους/Λοιπές παροχές"
                    },
                    {
                        value: "E3_102_006",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό) Λοιπά"
                    },
                    {
                        value: "E3_313_005",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Λοιπά"
                    },
                    {
                        value: "E3_202_005",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά"
                    },
                    {
                        value: "E3_302_005",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά"
                    },
                    {
                        value: "E3_883_003",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_882_004",
                        label: "Αγορές ενσώματων παγίων χρήσης/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_883_004",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Εξωτερικού Τρίτες Χώρες"
                    }
                ]
            },
            {
                value: "category2_95",
                label: "Λοιπά Πληροφοριακά Στοιχεία Εξόδων (-) / (+)",
                type: [
                    {
                        value: "null",
                        label: "Δεν ενημερώνει E3"
                    },
                ],
            },
        ]
    },
    {
        value: "2.2",
        label: "Τιμολόγιο Παροχής/ Ενδοκοινοτική Παροχή Υπηρεσιών",
        category: [
            {
                value: "category1_3",
                label: "Έσοδα από Παροχή Υπηρεσιών  (+)/ (-)",
                type: [
                    {
                        value: "E3_561_005",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_561_007",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λοιπά"
                    },
                    {
                        value: "E3_563",
                        label: "Πιστωτικοί τόκοι και συναφή έσοδα"
                    },
                ]
            },
            {
                value: "category1_5",
                label: "Λοιπά Έσοδα/Κέρδη (+)/ (-)",
                type: [
                    {
                        value: "E3_561_005",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_561_007",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λοιπά"
                    },
                    {
                        value: "E3_562",
                        label: "Λοιπά συνήθη έσοδα"
                    },
                    {
                        value: "E3_570",
                        label: "Ασυνήθη έσοδα και κέρδη"
                    },
                ]
            },
            {
                value: "category1_8",
                label: "Έσοδα προηγούμενων χρήσεων (+)/ (-)",
                type: [
                    {
                        value: "E3_561_005",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_561_007",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λοιπά"
                    },
                    {
                        value: "E3_562",
                        label: "Λοιπά συνήθη έσοδα"
                    },
                    {
                        value: "E3_563",
                        label: "Πιστωτικοί τόκοι και συναφή έσοδα"
                    },
                    {
                        value: "E3_570",
                        label: "Ασυνήθη έσοδα και κέρδη"
                    },
                ]
            },
            {
                value: "category1_9",
                label: "Έσοδα επομένων χρήσεων (+)/ (-)",
                type: [
                    {
                        value: "E3_561_005",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_561_007",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λοιπά"
                    },
                    {
                        value: "E3_562",
                        label: "Λοιπά συνήθη έσοδα"
                    },
                    {
                        value: "E3_563",
                        label: "Πιστωτικοί τόκοι και συναφή έσοδα"
                    },
                    {
                        value: "E3_570",
                        label: "Ασυνήθη έσοδα και κέρδη"
                    },
                ]
            },
            {
                value: "category1_95",
                label: "Λοιπά Πληροφοριακά στοιχεία εσόδων (+)/ (-)",
                type: [
                    {
                        value: "null",
                        label: "Κενό"
                    },
                ]
            }
        ]
    },
    {
        value: "2.3",
        label: "Τιμολόγιο Παροχής / Παροχή Υπηρεσιών σε λήπτη Τρίτης Χώρας",
        category: [
            {
                value: "category1_3",
                label: "Έσοδα από Παροχή Υπηρεσιών  (+)/ (-)",
                type: [
                    {
                        value: "E3_561_006",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_561_007",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λοιπά"
                    },
                    {
                        value: "E3_563",
                        label: "Πιστωτικοί τόκοι και συναφή έσοδα"
                    },
                ]
            },
            {
                value: "category1_5",
                label: "Λοιπά Έσοδα/Κέρδη (+)/ (-)",
                type: [
                    {
                        value: "E3_561_006",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_561_007",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λοιπά"
                    },
                    {
                        value: "E3_562",
                        label: "Λοιπά συνήθη έσοδα"
                    },
                    {
                        value: "E3_570",
                        label: "Ασυνήθη έσοδα και κέρδη"
                    },
                ]
            },
            {
                value: "category1_8",
                label: "Έσοδα προηγούμενων χρήσεων (+)/ (-)",
                type: [
                    {
                        value: "E3_561_006",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_561_007",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λοιπά"
                    },
                    {
                        value: "E3_562",
                        label: "Λοιπά συνήθη έσοδα"
                    },
                    {
                        value: "E3_563",
                        label: "Πιστωτικοί τόκοι και συναφή έσοδα"
                    },
                    {
                        value: "E3_570",
                        label: "Ασυνήθη έσοδα και κέρδη"
                    },
                ]
            },
            {
                value: "category1_9",
                label: "Έσοδα επομένων χρήσεων (+)/ (-)",
                type: [
                    {
                        value: "E3_561_006",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_561_007",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λοιπά"
                    },
                    {
                        value: "E3_562",
                        label: "Λοιπά συνήθη έσοδα"
                    },
                    {
                        value: "E3_563",
                        label: "Πιστωτικοί τόκοι και συναφή έσοδα"
                    },
                    {
                        value: "E3_570",
                        label: "Ασυνήθη έσοδα και κέρδη"
                    },
                ]
            },
            {
                value: "category1_95",
                label: "Λοιπά Πληροφοριακά στοιχεία εσόδων (+)/ (-)",
                type: [
                    {
                        value: "null",
                        label: "Κενό"
                    },
                ]
            }
        ]
    },
    {
        value: "2.4",
        label: "Τιμολόγιο Παροχής / Συμπληρωματικό Παραστατικό",
        category: [
            {
                value: "category1_3",
                label: "Έσοδα από Παροχή Υπηρεσιών  (+)/ (-)",
                type: [
                    {
                        value: "E3_561_006",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Τρίτες Χώρες"
                    },
                ]
            },
            {
                value: "category1_5",
                label: "Λοιπά Έσοδα/Κέρδη (+)/ (-)",
                type: [
                    {
                        value: "E3_562",
                        label: "Λοιπά συνήθη έσοδα"
                    },
                    {
                        value: "E3_570",
                        label: "Ασυνήθη έσοδα και κέρδη"
                    },
                ]
            },
            {
                value: "category1_8",
                label: "Έσοδα προηγούμενων χρήσεων (+)/ (-)",
                type: [
                    {
                        value: "E3_561_006",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_562",
                        label: "Λοιπά συνήθη έσοδα"
                    },
                    {
                        value: "E3_570",
                        label: "Ασυνήθη έσοδα και κέρδη"
                    },
                ]
            },
            {
                value: "category1_9",
                label: "Έσοδα επομένων χρήσεων (+)/ (-)",
                type: [
                    {
                        value: "E3_561_006",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_562",
                        label: "Λοιπά συνήθη έσοδα"
                    },
                    {
                        value: "E3_570",
                        label: "Ασυνήθη έσοδα και κέρδη"
                    },
                ]
            },
            {
                value: "category1_95",
                label: "Λοιπά Πληροφοριακά στοιχεία εσόδων (+)/ (-)",
                type: [
                    {
                        value: "null",
                        label: "Κενό"
                    },
                ]
            },
            {
                value: "category2_95",
                label: "Λοιπά Πληροφοριακά Στοιχεία Εξόδων (-) / (+)",
                type: [
                    {
                        value: "null",
                        label: "Δεν ενημερώνει Ε3"
                    },
                ]
            }
        ]
    },
    {
        value: "3.1",
        label: "Τίτλος Κτήσης ( Μη υπόχρεος Εκδότης)",
        category: [
            {
                value: "category2_1",
                label: "Αγορές Εμπορευμάτων",
                type: [
                    {
                        value: "E3_102_002",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Λιανικές"
                    },
                    {
                        value: "E3_102_003",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Αγαθών του άρθρου 39α παρ.5 του Κώδικα Φ.Π.Α. (ν.2859/2000)"
                    },
                    {
                        value: "E3_102_006",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)Λοιπά"
                    },
                    {
                        value: "E3_313_002",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Λιανικές"
                    },
                    {
                        value: "E3_313_005",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Λοιπά"
                    }
                ]
            },
            {
                value: "category2_2",
                label: "Αγορές Α'-Β' Υλών",
                type: [
                    {
                        value: "E3_202_002",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λιανικές"
                    },
                    {
                        value: "E3_202_005",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά"
                    },
                    {
                        value: "E3_302_002",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λιανικές"
                    },
                    {
                        value: "E3_302_005",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά"
                    }
                ]
            },
            {
                value: "category2_3",
                label: "Λήψη Υπηρεσιών",
                type: [
                    {
                        value: "E3_585_004",
                        label: "Δαπάνες για ενημερωτικές ημερίδες"
                    },
                    {
                        value: "E3_585_009",
                        label: "Λοιπές Αμοιβές για υπηρεσίες ημεδαπής"
                    },
                    {
                        value: "E3_585_016",
                        label: "Λοιπά έξοδα"
                    }
                ]
            },
            {
                value: "category2_5",
                label: "Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης ΦΠΑ",
                type: [
                    {
                        value: "E3_585_004",
                        label: "Δαπάνες για ενημερωτικές ημερίδες"
                    },
                    {
                        value: "E3_586",
                        label: "Χρεωστικοί τόκοι και συναφή έξοδα"
                    },
                    {
                        value: "E3_585_016",
                        label: "Λοιπά έξοδα"
                    }
                ]
            },
            {
                value: "category2_7",
                label: "Αγορές Παγίων",
                type: [
                    {
                        value: "E3_882_002",
                        label: "Αγορές ενσώματων παγίων χρήσης/Λιανικές"
                    },
                    {
                        value: "E3_883_002",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Λιανικές"
                    }
                ]
            },
            {
                value: "category2_10",
                label: "Έξοδα προηγούμενων χρήσεων",
                type: [
                    {
                        value: "E3_882_002",
                        label: "Αγορές ενσώματων παγίων χρήσης/Λιανικές"
                    },
                    {
                        value: "E3_883_002",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Λιανικές"
                    },
                    {
                        value: "E3_586",
                        label: "Χρεωστικοί τόκοι και συναφή έξοδα"
                    },
                    {
                        value: "E3_202_002",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λιανικές"
                    },
                    {
                        value: "E3_202_005",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά"
                    },
                    {
                        value: "E3_302_002",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λιανικές"
                    },
                    {
                        value: "E3_302_005",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά"
                    },
                    {
                        value: "E3_102_002",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Λιανικές"
                    },
                    {
                        value: "E3_102_003",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Αγαθών του άρθρου 39α παρ.5 του Κώδικα Φ.Π.Α. (ν.2859/2000)"
                    },
                    {
                        value: "E3_102_006",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)Λοιπά"
                    },
                    {
                        value: "E3_313_002",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Λιανικές"
                    },
                    {
                        value: "E3_313_005",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Λοιπά"
                    }
                ]
            },
            {
                value: "category2_11",
                label: "Έξοδα επομένων χρήσεων",
                type: [
                    {
                        value: "E3_882_002",
                        label: "Αγορές ενσώματων παγίων χρήσης/Λιανικές"
                    },
                    {
                        value: "E3_883_002",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Λιανικές"
                    },
                    {
                        value: "E3_586",
                        label: "Χρεωστικοί τόκοι και συναφή έξοδα"
                    },
                    {
                        value: "E3_202_002",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λιανικές"
                    },
                    {
                        value: "E3_202_005",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά"
                    },
                    {
                        value: "E3_302_002",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λιανικές"
                    },
                    {
                        value: "E3_302_005",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά"
                    },
                    {
                        value: "E3_102_002",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Λιανικές"
                    },
                    {
                        value: "E3_102_003",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Αγαθών του άρθρου 39α παρ.5 του Κώδικα Φ.Π.Α. (ν.2859/2000)"
                    },
                    {
                        value: "E3_102_006",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)Λοιπά"
                    },
                    {
                        value: "E3_313_002",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Λιανικές"
                    },
                    {
                        value: "E3_313_005",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Λοιπά"
                    }
                ]
            },
            {
                value: "category2_95",
                label: "Λοιπά Πληροφοριακά Στοιχεία Εξόδων",
                type: [
                    {
                        value: "null",
                        label: "Δεν ενημερώνει E3"
                    },
                ]
            },
        ]
    },
    {
        value: "3.2",
        label: "Τίτλος κτήσης (άρνηση χρέωσης από υπόχρεο Εκδότη)",
        category: [
            {
                value: "category2_1",
                label: "Αγορές Εμπορευμάτων",
                type: [
                    {
                        value: "E3_102_001",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Χονδρικές"
                    },
                    {
                        value: "E3_102_003",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Αγαθών του άρθρου 39α παρ.5 του Κώδικα Φ.Π.Α. (ν.2859/2000)"
                    },
                    {
                        value: "E3_102_006",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)Λοιπά"
                    },
                    {
                        value: "E3_313_001",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Χονδρικές"
                    },
                    {
                        value: "E3_313_005",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Λοιπά"
                    }
                ]
            },
            {
                value: "category2_2",
                label: "Αγορές Α'-Β' Υλών",
                type: [
                    {
                        value: "E3_202_001",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Χονδρικές"
                    },
                    {
                        value: "E3_202_005",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά"
                    },
                    {
                        value: "E3_302_001",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Χονδρικές"
                    },
                    {
                        value: "E3_302_005",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά"
                    }
                ]
            },
            {
                value: "category2_3",
                label: "Λήψη Υπηρεσιών",
                type: [
                    {
                        value: "E3_585_001",
                        label: "Προμήθειες διαχείρισης ημεδαπής - αλλοδαπής (management fees)"
                    },
                    {
                        value: "E3_585_002",
                        label: "Δαπάνες από συνδεδεμένες επιχειρήσεις"
                    },
                    {
                        value: "E3_585_004",
                        label: "Δαπάνες για ενημερωτικές ημερίδες"
                    },
                    {
                        value: "E3_585_005",
                        label: "Έξοδα υποδοχής και φιλοξενίας"
                    },
                    {
                        value: "E3_585_006",
                        label: "Έξοδα ταξιδιών"
                    },
                    {
                        value: "E3_585_008",
                        label: "Έξοδα και προμήθειες παραγγελιοδόχου για λογαριασμό αγροτών"
                    },
                    {
                        value: "E3_585_009",
                        label: "Λοιπές Αμοιβές για υπηρεσίες ημεδαπής"
                    },
                    {
                        value: "E3_585_011",
                        label: "Ενέργεια"
                    },
                    {
                        value: "E3_585_012",
                        label: "Ύδρευση"
                    },
                    {
                        value: "E3_585_013",
                        label: "Τηλεπικοινωνίες"
                    },
                    {
                        value: "E3_585_014",
                        label: "Ενοίκια"
                    },
                    {
                        value: "E3_585_015",
                        label: "Διαφήμιση και προβολή"
                    },
                    {
                        value: "E3_585_016",
                        label: "Λοιπά έξοδα"
                    },
                    {
                        value: "E3_586",
                        label: "Χρεωστικοί τόκοι και συναφή έξοδα"
                    },
                    {
                        value: "E3_581_003",
                        label: "Παροχές σε εργαζόμενους/Λοιπές παροχές"
                    },
                ]
            },
            {
                value: "category2_5",
                label: "Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης ΦΠΑ",
                type: [
                    {
                        value: "E3_585_001",
                        label: "Προμήθειες διαχείρισης ημεδαπής - αλλοδαπής (management fees)"
                    },
                    {
                        value: "E3_585_002",
                        label: "Δαπάνες από συνδεδεμένες επιχειρήσεις"
                    },
                    {
                        value: "E3_585_004",
                        label: "Δαπάνες για ενημερωτικές ημερίδες"
                    },
                    {
                        value: "E3_585_005",
                        label: "Έξοδα υποδοχής και φιλοξενίας"
                    },
                    {
                        value: "E3_585_006",
                        label: "Έξοδα ταξιδιών"
                    },
                    {
                        value: "E3_585_008",
                        label: "Έξοδα και προμήθειες παραγγελιοδόχου για λογαριασμό αγροτών"
                    },
                    {
                        value: "E3_585_009",
                        label: "Λοιπές Αμοιβές για υπηρεσίες ημεδαπής"
                    },
                    {
                        value: "E3_585_011",
                        label: "Ενέργεια"
                    },
                    {
                        value: "E3_585_012",
                        label: "Ύδρευση"
                    },
                    {
                        value: "E3_585_013",
                        label: "Τηλεπικοινωνίες"
                    },
                    {
                        value: "E3_585_014",
                        label: "Ενοίκια"
                    },
                    {
                        value: "E3_585_015",
                        label: "Διαφήμιση και προβολή"
                    },
                    {
                        value: "E3_585_016",
                        label: "Λοιπά έξοδα"
                    },
                    {
                        value: "E3_586",
                        label: "Χρεωστικοί τόκοι και συναφή έξοδα"
                    },
                    {
                        value: "E3_882_001",
                        label: "Αγορές ενσώματων παγίων χρήσης/Χονδρικές"
                    },
                    {
                        value: "E3_883_001",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Χονδρικές"
                    },
                    {
                        value: "E3_102_004",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_102_005",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_202_003",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_202_004",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_302_004",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_313_003",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_313_004",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_581_003",
                        label: "Παροχές σε εργαζόμενους/Λοιπές παροχές"
                    },
                ]
            },
            {
                value: "category2_7",
                label: "Αγορές Παγίων",
                type: [
                    {
                        value: "E3_882_001",
                        label: "Αγορές ενσώματων παγίων χρήσης/Χονδρικές"
                    },
                    {
                        value: "E3_883_001",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Χονδρικές"
                    }
                ]
            },
            {
                value: "category2_10",
                label: "Έξοδα προηγούμενων χρήσεων",
                type: [
                    {
                        value: "E3_882_002",
                        label: "Αγορές ενσώματων παγίων χρήσης/Λιανικές"
                    },
                    {
                        value: "E3_883_002",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Λιανικές"
                    },
                    {
                        value: "E3_586",
                        label: "Χρεωστικοί τόκοι και συναφή έξοδα"
                    },
                    {
                        value: "E3_202_002",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λιανικές"
                    },
                    {
                        value: "E3_202_005",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά"
                    },
                    {
                        value: "E3_302_002",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λιανικές"
                    },
                    {
                        value: "E3_302_005",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά"
                    },
                    {
                        value: "E3_102_002",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Λιανικές"
                    },
                    {
                        value: "E3_102_003",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Αγαθών του άρθρου 39α παρ.5 του Κώδικα Φ.Π.Α. (ν.2859/2000)"
                    },
                    {
                        value: "E3_102_006",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)Λοιπά"
                    },
                    {
                        value: "E3_313_002",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Λιανικές"
                    },
                    {
                        value: "E3_313_005",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Λοιπά"
                    }
                ]
            },
            {
                value: "category2_11",
                label: "Έξοδα επομένων χρήσεων",
                type: [
                    {
                        value: "E3_882_002",
                        label: "Αγορές ενσώματων παγίων χρήσης/Λιανικές"
                    },
                    {
                        value: "E3_883_002",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Λιανικές"
                    },
                    {
                        value: "E3_586",
                        label: "Χρεωστικοί τόκοι και συναφή έξοδα"
                    },
                    {
                        value: "E3_202_002",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λιανικές"
                    },
                    {
                        value: "E3_202_005",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά"
                    },
                    {
                        value: "E3_302_002",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λιανικές"
                    },
                    {
                        value: "E3_302_005",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά"
                    },
                    {
                        value: "E3_102_002",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Λιανικές"
                    },
                    {
                        value: "E3_102_003",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Αγαθών του άρθρου 39α παρ.5 του Κώδικα Φ.Π.Α. (ν.2859/2000)"
                    },
                    {
                        value: "E3_102_006",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)Λοιπά"
                    },
                    {
                        value: "E3_313_002",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Λιανικές"
                    },
                    {
                        value: "E3_313_005",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Λοιπά"
                    }
                ]
            },
            {
                value: "category2_95",
                label: "Λοιπά Πληροφοριακά Στοιχεία Εξόδων",
                type: [
                    {
                        value: "null",
                        label: "Δεν ενημερώνει E3"
                    },
                ]
            },
        ]
    },
    {
        value: "5.1",
        label: "Πιστωτικό Τιμολόγιο /Συσχετιζόμενο",
        category: [
            {
                value: "category1_1",
                label: "Έσοδα από πώληση Εμπορευμάτων  (+)/ (-)",
                type: [
                    {
                        value: "E3_561_001",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Χονδρικές -Επιτηδευμάτων "
                    },
                    {
                        value: "E3_561_002",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Χονδρικές  βάσει άρθρου 39α παρ 5 του κώδικα ΦΠΑ (Ν2859/2000)"
                    },
                    {
                        value: "E3_561_005",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_561_006",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_561_007",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λοιπά"
                    },
                ]
            },
            {
                value: "category1_2",
                label: "Έσοδα από πώληση Προιόντων  (+)/ (-)",
                type: [
                    {
                        value: "E3_561_001",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Χονδρικές -Επιτηδευμάτων "
                    },
                    {
                        value: "E3_561_002",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Χονδρικές  βάσει άρθρου 39α παρ 5 του κώδικα ΦΠΑ (Ν2859/2000)"
                    },
                    {
                        value: "E3_561_005",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_561_006",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_561_007",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λοιπά"
                    },
                ]
            },
            {
                value: "category1_3",
                label: "Έσοδα από Παροχή Υπηρεσιών  (+)/ (-)",
                type: [
                    {
                        value: "E3_561_001",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Χονδρικές -Επιτηδευμάτων "
                    },
                    {
                        value: "E3_561_005",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_561_006",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_561_007",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λοιπά"
                    },
                ]
            },
            {
                value: "category1_4",
                label: "Έσοδα από Πώληση Παγίων  (+)/ (-)",
                type: [
                    {
                        value: "E3_880_001",
                        label: "Πωλήσεις παγίων Χονδρικές"
                    },
                    {
                        value: "E3_880_003",
                        label: "Πωλήσεις παγίων Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_880_004",
                        label: "Πωλήσεις παγίων εξωτερικού Τρίτες Χώρες"
                    },
                ]
            },
            {
                value: "category1_5",
                label: "Λοιπά Έσοδα/Κέρδη (+)/ (-)",
                type: [
                    {
                        value: "E3_562",
                        label: "Λοιπά συνήθη έσοδα"
                    },
                    {
                        value: "E3_563",
                        label: "Πιστωτικοί τόκοι και συναφή έσοδα"
                    },
                    {
                        value: "E3_564",
                        label: "Πιστωτικές συναλλαγματικές διαφορές "
                    },
                    {
                        value: "E3_565",
                        label: "Έσοδα συμμετοχών"
                    },
                    {
                        value: "E3_566",
                        label: "Κέρδη από διάθεση μη κυκλοφορούντων περιουσιακών στοιχείων"
                    },
                    {
                        value: "E3_567",
                        label: "Κέρδη από αναστροφή προβλέψεων και απομειώσεων"
                    },
                    {
                        value: "E3_568",
                        label: "Κέρδη απο επιμέτρηση στην εύλογη αξία "
                    },
                    {
                        value: "E3_570",
                        label: "Ασυνήθη έσοδα και κέρδη"
                    },
                ]
            },
            {
                value: "category1_7",
                label: "Έσοδα από λογ/σμο τρίτων (+)/ (-)",
                type: [
                    {
                        value: "E3_881_001",
                        label: "Πωλήσεις για λογ/σμο Τρίτων Χονδρικές"
                    },
                    {
                        value: "E3_881_003",
                        label: "Πωλήσεις για λογ/σμο Τρίτων Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_881_004",
                        label: "Πωλήσεις για λογ/σμο Τρίτων Εξωτερικού Τρίτες χώρες"
                    }
                ]
            },
            {
                value: "category1_8",
                label: "Έσοδα προηγούμενων χρήσεων (+)/ (-)",
                type: [
                    {
                        value: "E3_561_001",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Χονδρικές -Επιτηδευμάτων "
                    },
                    {
                        value: "E3_561_002",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Χονδρικές  βάσει άρθρου 39α παρ 5 του κώδικα ΦΠΑ (Ν2859/2000)"
                    },
                    {
                        value: "E3_561_005",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_561_006",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_561_007",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λοιπά"
                    },
                    {
                        value: "E3_880_001",
                        label: "Πωλήσεις παγίων Χονδρικές"
                    },
                    {
                        value: "E3_880_003",
                        label: "Πωλήσεις παγίων Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_880_004",
                        label: "Πωλήσεις παγίων εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_562",
                        label: "Λοιπά συνήθη έσοδα"
                    },
                    {
                        value: "E3_563",
                        label: "Πιστωτικοί τόκοι και συναφή έσοδα"
                    },
                    {
                        value: "E3_564",
                        label: "Πιστωτικές συναλλαγματικές διαφορές "
                    },
                    {
                        value: "E3_565",
                        label: "Έσοδα συμμετοχών"
                    },
                    {
                        value: "E3_566",
                        label: "Κέρδη από διάθεση μη κυκλοφορούντων περιουσιακών στοιχείων"
                    },
                    {
                        value: "E3_567",
                        label: "Κέρδη από αναστροφή προβλέψεων και απομειώσεων"
                    },
                    {
                        value: "E3_568",
                        label: "Κέρδη απο επιμέτρηση στην εύλογη αξία "
                    },
                    {
                        value: "E3_570",
                        label: "Ασυνήθη έσοδα και κέρδη"
                    },
                    {
                        value: "E3_881_001",
                        label: "Πωλήσεις για λογ/σμο Τρίτων Χονδρικές"
                    },
                    {
                        value: "E3_881_003",
                        label: "Πωλήσεις για λογ/σμο Τρίτων Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_881_004",
                        label: "Πωλήσεις για λογ/σμο Τρίτων Εξωτερικού Τρίτες χώρες"
                    }
                ]
            },
            {
                value: "category1_9",
                label: "Έσοδα επομένων χρήσεων (+)/ (-)",
                type: [
                    {
                        value: "E3_561_001",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Χονδρικές -Επιτηδευμάτων "
                    },
                    {
                        value: "E3_561_002",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Χονδρικές  βάσει άρθρου 39α παρ 5 του κώδικα ΦΠΑ (Ν2859/2000)"
                    },
                    {
                        value: "E3_561_005",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_561_006",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_561_007",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λοιπά"
                    },
                    {
                        value: "E3_880_001",
                        label: "Πωλήσεις παγίων Χονδρικές"
                    },
                    {
                        value: "E3_880_003",
                        label: "Πωλήσεις παγίων Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_880_004",
                        label: "Πωλήσεις παγίων εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_562",
                        label: "Λοιπά συνήθη έσοδα"
                    },
                    {
                        value: "E3_563",
                        label: "Πιστωτικοί τόκοι και συναφή έσοδα"
                    },
                    {
                        value: "E3_564",
                        label: "Πιστωτικές συναλλαγματικές διαφορές "
                    },
                    {
                        value: "E3_565",
                        label: "Έσοδα συμμετοχών"
                    },
                    {
                        value: "E3_566",
                        label: "Κέρδη από διάθεση μη κυκλοφορούντων περιουσιακών στοιχείων"
                    },
                    {
                        value: "E3_567",
                        label: "Κέρδη από αναστροφή προβλέψεων και απομειώσεων"
                    },
                    {
                        value: "E3_568",
                        label: "Κέρδη απο επιμέτρηση στην εύλογη αξία "
                    },
                    {
                        value: "E3_570",
                        label: "Ασυνήθη έσοδα και κέρδη"
                    },
                    {
                        value: "E3_881_001",
                        label: "Πωλήσεις για λογ/σμο Τρίτων Χονδρικές"
                    },
                    {
                        value: "E3_881_003",
                        label: "Πωλήσεις για λογ/σμο Τρίτων Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_881_004",
                        label: "Πωλήσεις για λογ/σμο Τρίτων Εξωτερικού Τρίτες χώρες"
                    }
                ]
            },
            {
                value: "category1_95",
                label: "Λοιπά Πληροφοριακά στοιχεία εσόδων (+)/ (-)",
                type: [
                    {
                        value: "null",
                        label: "Κενό"
                    },
                ]
            },
            {
                value: "category2_1",
                label: "Αγορές Εμπορευμάτων (-) / (+)",
                type: [
                    {
                        value: "E3_102_001",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Χονδρικές"
                    },
                    {
                        value: "E3_102_002",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Λιανικές",
                    },
                    {
                        value: "E3_102_003",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Αγαθών του άρθρου 39α παρ.5 του Κώδικα Φ.Π.Α. (ν.2859/2000)"
                    },
                    {
                        value: "E3_102_006",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)Λοιπά"
                    },
                    {
                        value: "E3_313_001",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Χονδρικές"
                    },
                    {
                        value: "E3_313_002",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Λιανικές",
                    },
                    {
                        value: "E3_313_005",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Λοιπά"
                    }
                ]
            },
            {
                value: "category2_2",
                label: "Αγορές Α'-Β' Υλών (-) / (+)",
                type: [
                    {
                        value: "E3_202_001",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Χονδρικές",
                    },
                    {
                        value: "E3_202_002",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λιανικές",
                    },
                    {
                        value: "E3_202_005",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά",
                    },
                    {
                        value: "E3_302_001",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Χονδρικές",
                    },
                    {
                        value: "E3_302_002",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λιανικές",
                    },
                    {
                        value: "E3_302_005",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά",
                    },
                ]
            },
            {
                value: "category2_3",
                label: "Λήψη Υπηρεσιών (-) / (+)",
                type: [
                    {
                        value: "E3_585_001",
                        label: "Προμήθειες διαχείρισης ημεδαπής - αλλοδαπής (management fees)",
                    },
                    {
                        value: "E3_585_002",
                        label: "Δαπάνες από συνδεδεμένες επιχειρήσεις",
                    },
                    {
                        value: "E3_585_004",
                        label: "Δαπάνες για ενημερωτικές ημερίδες",
                    },
                    {
                        value: "E3_585_005",
                        label: "Έξοδα υποδοχής και φιλοξενίας",
                    },
                    {
                        value: "E3_585_006",
                        label: "Έξοδα ταξιδιών",
                    },
                    {
                        value: "E3_585_008",
                        label: "Έξοδα και προμήθειες παραγγελιοδόχου για λογαριασμό αγροτών",
                    },
                    {
                        value: "E3_585_009",
                        label: "Λοιπές Αμοιβές για υπηρεσίες ημεδαπής",
                    },
                    {
                        value: "E3_585_011",
                        label: "Ενέργεια",
                    },
                    {
                        value: "E3_585_012",
                        label: "Ύδρευση",
                    },
                    {
                        value: "E3_585_013",
                        label: "Τηλεπικοινωνίες",
                    },
                    {
                        value: "E3_585_014",
                        label: "Ενοίκια",
                    },
                    {
                        value: "E3_585_015",
                        label: "Διαφήμιση και προβολή",
                    },
                    {
                        value: "E3_585_016",
                        label: "Λοιπά έξοδα",
                    },
                    {
                        value: "E3_586",
                        label: "Χρεωστικοί τόκοι και συναφή έξοδα",
                    },
                    {
                        value: "E3_581_003",
                        label: "Παροχές σε εργαζόμενους/Λοιπές παροχές",
                    },
                ]
            },
            {
                value: "category2_4",
                label: "Γενικά Έξοδα με δικαίωμα έκπτωσης ΦΠΑ (-) / (+)",
                type: [
                    {
                        value: "E3_585_001",
                        label: "Προμήθειες διαχείρισης ημεδαπής - αλλοδαπής (management fees)",
                    },
                    {
                        value: "E3_585_002",
                        label: "Δαπάνες από συνδεδεμένες επιχειρήσεις",
                    },
                    {
                        value: "E3_585_004",
                        label: "Δαπάνες για ενημερωτικές ημερίδες",
                    },
                    {
                        value: "E3_585_005",
                        label: "Έξοδα υποδοχής και φιλοξενίας",
                    },
                    {
                        value: "E3_585_006",
                        label: "Έξοδα ταξιδιών",
                    },
                    {
                        value: "E3_585_008",
                        label: "Έξοδα και προμήθειες παραγγελιοδόχου για λογαριασμό αγροτών",
                    },
                    {
                        value: "E3_585_009",
                        label: "Λοιπές Αμοιβές για υπηρεσίες ημεδαπής",
                    },
                    {
                        value: "E3_585_011",
                        label: "Ενέργεια",
                    },
                    {
                        value: "E3_585_012",
                        label: "Ύδρευση",
                    },
                    {
                        value: "E3_585_013",
                        label: "Τηλεπικοινωνίες",
                    },
                    {
                        value: "E3_585_014",
                        label: "Ενοίκια",
                    },
                    {
                        value: "E3_585_015",
                        label: "Διαφήμιση και προβολή",
                    },
                    {
                        value: "E3_585_016",
                        label: "Λοιπά έξοδα",
                    },
                    {
                        value: "E3_586",
                        label: "Χρεωστικοί τόκοι και συναφή έξοδα",
                    },
                    {
                        value: "E3_102_001",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Χονδρικές",
                    },
                    {
                        value: "E3_102_003",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Αγαθών του άρθρου 39α παρ.5 του Κώδικα Φ.Π.Α. (ν.2859/2000)",
                    },
                    {
                        value: "E3_102_004",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
                    },
                    {
                        value: "E3_102_005",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
                    },
                    {
                        value: "E3_202_001",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Χονδρικές",
                    },
                    {
                        value: "E3_202_003",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
                    },
                    {
                        value: "E3_202_004",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
                    },
                    {
                        value: "E3_302_001",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Χονδρικές",
                    },
                    {
                        value: "E3_302_003",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
                    },
                    {
                        value: "E3_302_004",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
                    },
                    {
                        value: "E3_313_001",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Χονδρικές",
                    },
                    {
                        value: "E3_313_003",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
                    },
                    {
                        value: "E3_313_004",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
                    },
                    {
                        value: "E3_581_003",
                        label: "Παροχές σε εργαζόμενους/Λοιπές παροχές",
                    },
                ]
            },
            {
                value: "category2_5",
                label: "Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης ΦΠΑ (-) / (+)",
                type: [
                    {
                        value: "E3_585_001",
                        label: "Προμήθειες διαχείρισης ημεδαπής - αλλοδαπής (management fees)",
                    },
                    {
                        value: "E3_585_002",
                        label: "Δαπάνες από συνδεδεμένες επιχειρήσεις",
                    },
                    {
                        value: "E3_585_004",
                        label: "Δαπάνες για ενημερωτικές ημερίδες",
                    },
                    {
                        value: "E3_585_005",
                        label: "Έξοδα υποδοχής και φιλοξενίας",
                    },
                    {
                        value: "E3_585_006",
                        label: "Έξοδα ταξιδιών",
                    },
                    {
                        value: "E3_585_008",
                        label: "Έξοδα και προμήθειες παραγγελιοδόχου για λογαριασμό αγροτών",
                    },
                    {
                        value: "E3_585_009",
                        label: "Λοιπές Αμοιβές για υπηρεσίες ημεδαπής",
                    },
                    {
                        value: "E3_585_011",
                        label: "Ενέργεια",
                    },
                    {
                        value: "E3_585_012",
                        label: "Ύδρευση",
                    },
                    {
                        value: "E3_585_013",
                        label: "Τηλεπικοινωνίες",
                    },
                    {
                        value: "E3_585_014",
                        label: "Ενοίκια",
                    },
                    {
                        value: "E3_585_015",
                        label: "Διαφήμιση και προβολή",
                    },
                    {
                        value: "E3_585_016",
                        label: "Λοιπά έξοδα",
                    },
                    {
                        value: "E3_586",
                        label: "Χρεωστικοί τόκοι και συναφή έξοδα",
                    },
                    {
                        value: "E3_882_001",
                        label: "Αγορές ενσώματων παγίων χρήσης/Χονδρικές",
                    },
                    {
                        value: "E3_883_001",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Χονδρικές",
                    },
                    {
                        value: "E3_102_001",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Χονδρικές",
                    },
                    {
                        value: "E3_102_003",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Αγαθών του άρθρου 39α παρ.5 του Κώδικα Φ.Π.Α. (ν.2859/2000)",
                    },
                    {
                        value: "E3_102_004",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
                    },
                    {
                        value: "E3_102_005",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
                    },
                    {
                        value: "E3_202_001",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Χονδρικές",
                    },
                    {
                        value: "E3_202_003",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές\n",
                    },
                    {
                        value: "E3_202_004",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες\n",
                    },
                    {
                        value: "E3_302_001",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Χονδρικές",
                    },
                    {
                        value: "E3_302_003",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές\n",
                    },
                    {
                        value: "E3_302_004",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
                    },
                    {
                        value: "E3_313_001",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Χονδρικές",
                    },
                    {
                        value: "E3_313_003",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
                    },
                    {
                        value: "E3_313_004",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
                    },
                    {
                        value: "E3_581_003",
                        label: "Παροχές σε εργαζόμενους/Λοιπές παροχές",
                    },
                ]
            },
            {
                value: "category2_7",
                label: "Αγορές Παγίων (-) / (+)",
                type: [
                    {
                        value: "E3_882_001",
                        label: "Αγορές ενσώματων παγίων χρήσης/Χονδρικές",
                    },
                    {
                        value: "E3_882_002",
                        label: "Αγορές ενσώματων παγίων χρήσης/Λιανικές",
                    },
                    {
                        value: "E3_882_003",
                        label: "Αγορές ενσώματων παγίων χρήσης/Εξωτερικού Ενδοκοινοτικές",
                    },
                    {
                        value: "E3_882_004",
                        label: "Αγορές ενσώματων παγίων χρήσης/Εξωτερικού Τρίτες Χώρες",
                    },
                    {
                        value: "E3_883_001",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Χονδρικές",
                    },
                    {
                        value: "E3_883_002",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Λιανικές",
                    },
                    {
                        value: "E3_883_003",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Εξωτερικού Ενδοκοινοτικές",
                    },
                    {
                        value: "E3_883_004",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Εξωτερικού Τρίτες Χώρες",
                    },
                ]
            },
            {
                value: "category2_9",
                label: "Έξοδα για λ/σμο τρίτων (-) / (+)",
                type: [
                    {
                        value: "null",
                        label: "Δεν ενημερώνει E3"
                    },
                ]
            },
            {
                value: "category2_10",
                label: "Έξοδα προηγούμενων χρήσεων (-) / (+)",
                type: [
                    {
                        value: "E3_585_001",
                        label: "Προμήθειες διαχείρισης ημεδαπής - αλλοδαπής (management fees)",
                    },
                    {
                        value: "E3_585_002",
                        label: "Δαπάνες από συνδεδεμένες επιχειρήσεις",
                    },
                    {
                        value: "E3_585_004",
                        label: "Δαπάνες για ενημερωτικές ημερίδες",
                    },
                    {
                        value: "E3_585_005",
                        label: "Έξοδα υποδοχής και φιλοξενίας",
                    },
                    {
                        value: "E3_585_006",
                        label: "Έξοδα ταξιδιών",
                    },
                    {
                        value: "E3_585_008",
                        label: "Έξοδα και προμήθειες παραγγελιοδόχου για λογαριασμό αγροτών",
                    },
                    {
                        value: "E3_585_009",
                        label: "Λοιπές Αμοιβές για υπηρεσίες ημεδαπής",
                    },
                    {
                        value: "E3_585_011",
                        label: "Ενέργεια",
                    },
                    {
                        value: "E3_585_012",
                        label: "Ύδρευση",
                    },
                    {
                        value: "E3_585_013",
                        label: "Τηλεπικοινωνίες",
                    },
                    {
                        value: "E3_585_014",
                        label: "Ενοίκια",
                    },
                    {
                        value: "E3_585_015",
                        label: "Διαφήμιση και προβολή",
                    },
                    {
                        value: "E3_585_016",
                        label: "Λοιπά έξοδα",
                    },
                    {
                        value: "E3_586",
                        label: "Χρεωστικοί τόκοι και συναφή έξοδα",
                    },
                    {
                        value: "E3_882_001",
                        label: "Αγορές ενσώματων παγίων χρήσης/Χονδρικές",
                    },
                    {
                        value: "E3_883_001",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Χονδρικές",
                    },
                    {
                        value: "E3_102_001",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Χονδρικές",
                    },
                    {
                        value: "E3_102_003",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Αγαθών του άρθρου 39α παρ.5 του Κώδικα Φ.Π.Α. (ν.2859/2000)",
                    },
                    {
                        value: "E3_102_004",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
                    },
                    {
                        value: "E3_102_005",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
                    },
                    {
                        value: "E3_202_001",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Χονδρικές",
                    },
                    {
                        value: "E3_202_003",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές\n",
                    },
                    {
                        value: "E3_202_004",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες\n",
                    },
                    {
                        value: "E3_302_001",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Χονδρικές",
                    },
                    {
                        value: "E3_302_003",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές\n",
                    },
                    {
                        value: "E3_302_004",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
                    },
                    {
                        value: "E3_313_001",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Χονδρικές",
                    },
                    {
                        value: "E3_313_003",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
                    },
                    {
                        value: "E3_313_004",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
                    },
                    {
                        value: "E3_581_003",
                        label: "Παροχές σε εργαζόμενους/Λοιπές παροχές",
                    },
                    {
                        value: "E3_102_002",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Λιανικές",
                    },
                    {
                        value: "E3_102_006",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)Λοιπά"
                    },
                    {
                        value: "E3_313_002",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Λιανικές",
                    },
                    {
                        value: "E3_313_005",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Λοιπά"
                    },
                    {
                        value: "E3_882_002",
                        label: "Αγορές ενσώματων παγίων χρήσης/Λιανικές",
                    },
                    {
                        value: "E3_882_003",
                        label: "Αγορές ενσώματων παγίων χρήσης/Εξωτερικού Ενδοκοινοτικές",
                    },
                    {
                        value: "E3_882_004",
                        label: "Αγορές ενσώματων παγίων χρήσης/Εξωτερικού Τρίτες Χώρες",
                    },
                    {
                        value: "E3_883_002",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Λιανικές",
                    },
                    {
                        value: "E3_883_003",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Εξωτερικού Ενδοκοινοτικές",
                    },
                    {
                        value: "E3_883_004",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Εξωτερικού Τρίτες Χώρες",
                    },
                ]
            },
            {
                value: "category2_11",
                label: "Έξοδα επομένων χρήσεων (-) / (+)",
                type: [
                    {
                        value: "E3_585_001",
                        label: "Προμήθειες διαχείρισης ημεδαπής - αλλοδαπής (management fees)",
                    },
                    {
                        value: "E3_585_002",
                        label: "Δαπάνες από συνδεδεμένες επιχειρήσεις",
                    },
                    {
                        value: "E3_585_004",
                        label: "Δαπάνες για ενημερωτικές ημερίδες",
                    },
                    {
                        value: "E3_585_005",
                        label: "Έξοδα υποδοχής και φιλοξενίας",
                    },
                    {
                        value: "E3_585_006",
                        label: "Έξοδα ταξιδιών",
                    },
                    {
                        value: "E3_585_008",
                        label: "Έξοδα και προμήθειες παραγγελιοδόχου για λογαριασμό αγροτών",
                    },
                    {
                        value: "E3_585_009",
                        label: "Λοιπές Αμοιβές για υπηρεσίες ημεδαπής",
                    },
                    {
                        value: "E3_585_011",
                        label: "Ενέργεια",
                    },
                    {
                        value: "E3_585_012",
                        label: "Ύδρευση",
                    },
                    {
                        value: "E3_585_013",
                        label: "Τηλεπικοινωνίες",
                    },
                    {
                        value: "E3_585_014",
                        label: "Ενοίκια",
                    },
                    {
                        value: "E3_585_015",
                        label: "Διαφήμιση και προβολή",
                    },
                    {
                        value: "E3_585_016",
                        label: "Λοιπά έξοδα",
                    },
                    {
                        value: "E3_586",
                        label: "Χρεωστικοί τόκοι και συναφή έξοδα",
                    },
                    {
                        value: "E3_882_001",
                        label: "Αγορές ενσώματων παγίων χρήσης/Χονδρικές",
                    },
                    {
                        value: "E3_883_001",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Χονδρικές",
                    },
                    {
                        value: "E3_102_001",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Χονδρικές",
                    },
                    {
                        value: "E3_102_003",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Αγαθών του άρθρου 39α παρ.5 του Κώδικα Φ.Π.Α. (ν.2859/2000)",
                    },
                    {
                        value: "E3_102_004",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
                    },
                    {
                        value: "E3_102_005",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
                    },
                    {
                        value: "E3_202_001",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Χονδρικές",
                    },
                    {
                        value: "E3_202_003",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές\n",
                    },
                    {
                        value: "E3_202_004",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες\n",
                    },
                    {
                        value: "E3_302_001",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Χονδρικές",
                    },
                    {
                        value: "E3_302_003",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές\n",
                    },
                    {
                        value: "E3_302_004",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
                    },
                    {
                        value: "E3_313_001",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Χονδρικές",
                    },
                    {
                        value: "E3_313_003",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
                    },
                    {
                        value: "E3_313_004",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
                    },
                    {
                        value: "E3_581_003",
                        label: "Παροχές σε εργαζόμενους/Λοιπές παροχές",
                    },
                    {
                        value: "E3_102_002",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Λιανικές",
                    },
                    {
                        value: "E3_102_006",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)Λοιπά"
                    },
                    {
                        value: "E3_313_002",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Λιανικές",
                    },
                    {
                        value: "E3_313_005",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Λοιπά"
                    },
                    {
                        value: "E3_882_002",
                        label: "Αγορές ενσώματων παγίων χρήσης/Λιανικές",
                    },
                    {
                        value: "E3_882_003",
                        label: "Αγορές ενσώματων παγίων χρήσης/Εξωτερικού Ενδοκοινοτικές",
                    },
                    {
                        value: "E3_882_004",
                        label: "Αγορές ενσώματων παγίων χρήσης/Εξωτερικού Τρίτες Χώρες",
                    },
                    {
                        value: "E3_883_002",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Λιανικές",
                    },
                    {
                        value: "E3_883_003",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Εξωτερικού Ενδοκοινοτικές",
                    },
                    {
                        value: "E3_883_004",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Εξωτερικού Τρίτες Χώρες",
                    },
                ]
            },
            {
                value: "category2_95",
                label: "Λοιπά Πληροφοριακά Στοιχεία Εξόδων",
                type: [
                    {
                        value: "null",
                        label: "Δεν ενημερώνει E3"
                    },
                ]
            },
        ]
    },
    {
        value: "5.2",
        label: "Πιστωτικό Τιμολόγιο /Μη Συσχετιζόμενο",
        category: [
            {
                value: "category1_1",
                label: "Έσοδα από πώληση Εμπορευμάτων  (+)/ (-)",
                type: [
                    {
                        value: "E3_561_001",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Χονδρικές -Επιτηδευμάτων "
                    },
                    {
                        value: "E3_561_002",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Χονδρικές  βάσει άρθρου 39α παρ 5 του κώδικα ΦΠΑ (Ν2859/2000)"
                    },
                    {
                        value: "E3_561_005",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_561_006",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_561_007",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λοιπά"
                    },
                ]
            },
            {
                value: "category1_2",
                label: "Έσοδα από πώληση Προϊόντων  (+)/ (-)",
                type: [
                    {
                        value: "E3_561_001",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Χονδρικές -Επιτηδευμάτων "
                    },
                    {
                        value: "E3_561_002",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Χονδρικές  βάσει άρθρου 39α παρ 5 του κώδικα ΦΠΑ (Ν2859/2000)"
                    },
                    {
                        value: "E3_561_005",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_561_006",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_561_007",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λοιπά"
                    },
                ]
            },
            {
                value: "category1_3",
                label: "Έσοδα από Παροχή Υπηρεσιών  (+)/ (-)",
                type: [
                    {
                        value: "E3_561_001",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Χονδρικές -Επιτηδευμάτων "
                    },
                    {
                        value: "E3_561_005",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_561_006",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_561_007",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λοιπά"
                    },
                ]
            },
            {
                value: "category1_4",
                label: "Έσοδα από Πώληση Παγίων  (+)/ (-)",
                type: [
                    {
                        value: "E3_880_001",
                        label: "Πωλήσεις παγίων Χονδρικές"
                    },
                    {
                        value: "E3_880_003",
                        label: "Πωλήσεις παγίων Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_880_004",
                        label: "Πωλήσεις παγίων εξωτερικού Τρίτες Χώρες"
                    },
                ]
            },
            {
                value: "category1_5",
                label: "Λοιπά Έσοδα/Κέρδη (+)/ (-)",
                type: [
                    {
                        value: "E3_562",
                        label: "Λοιπά συνήθη έσοδα"
                    },
                    {
                        value: "E3_563",
                        label: "Πιστωτικοί τόκοι και συναφή έσοδα"
                    },
                    {
                        value: "E3_564",
                        label: "Πιστωτικές συναλλαγματικές διαφορές "
                    },
                    {
                        value: "E3_565",
                        label: "Έσοδα συμμετοχών"
                    },
                    {
                        value: "E3_566",
                        label: "Κέρδη από διάθεση μη κυκλοφορούντων περιουσιακών στοιχείων"
                    },
                    {
                        value: "E3_567",
                        label: "Κέρδη από αναστροφή προβλέψεων και απομειώσεων"
                    },
                    {
                        value: "E3_568",
                        label: "Κέρδη απο επιμέτρηση στην εύλογη αξία "
                    },
                    {
                        value: "E3_570",
                        label: "Ασυνήθη έσοδα και κέρδη"
                    },
                ]
            },
            {
                value: "category1_7",
                label: "Έσοδα από λογ/σμο τρίτων (+)/ (-)",
                type: [
                    {
                        value: "E3_881_001",
                        label: "Πωλήσεις για λογ/σμο Τρίτων Χονδρικές"
                    },
                    {
                        value: "E3_881_003",
                        label: "Πωλήσεις για λογ/σμο Τρίτων Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_881_004",
                        label: "Πωλήσεις για λογ/σμο Τρίτων Εξωτερικού Τρίτες χώρες"
                    }
                ]
            },
            {
                value: "category1_8",
                label: "Έσοδα προηγούμενων χρήσεων (+)/ (-)",
                type: [
                    {
                        value: "E3_561_001",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Χονδρικές -Επιτηδευμάτων "
                    },
                    {
                        value: "E3_561_002",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Χονδρικές  βάσει άρθρου 39α παρ 5 του κώδικα ΦΠΑ (Ν2859/2000)"
                    },
                    {
                        value: "E3_561_005",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_561_006",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_561_007",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λοιπά"
                    },
                    {
                        value: "E3_880_001",
                        label: "Πωλήσεις παγίων Χονδρικές"
                    },
                    {
                        value: "E3_880_003",
                        label: "Πωλήσεις παγίων Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_880_004",
                        label: "Πωλήσεις παγίων εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_562",
                        label: "Λοιπά συνήθη έσοδα"
                    },
                    {
                        value: "E3_563",
                        label: "Πιστωτικοί τόκοι και συναφή έσοδα"
                    },
                    {
                        value: "E3_564",
                        label: "Πιστωτικές συναλλαγματικές διαφορές "
                    },
                    {
                        value: "E3_565",
                        label: "Έσοδα συμμετοχών"
                    },
                    {
                        value: "E3_566",
                        label: "Κέρδη από διάθεση μη κυκλοφορούντων περιουσιακών στοιχείων"
                    },
                    {
                        value: "E3_567",
                        label: "Κέρδη από αναστροφή προβλέψεων και απομειώσεων"
                    },
                    {
                        value: "E3_568",
                        label: "Κέρδη απο επιμέτρηση στην εύλογη αξία "
                    },
                    {
                        value: "E3_570",
                        label: "Ασυνήθη έσοδα και κέρδη"
                    },
                    {
                        value: "E3_881_001",
                        label: "Πωλήσεις για λογ/σμο Τρίτων Χονδρικές"
                    },
                    {
                        value: "E3_881_003",
                        label: "Πωλήσεις για λογ/σμο Τρίτων Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_881_004",
                        label: "Πωλήσεις για λογ/σμο Τρίτων Εξωτερικού Τρίτες χώρες"
                    }
                ]
            },
            {
                value: "category1_9",
                label: "Έσοδα επομένων χρήσεων (+)/ (-)",
                type: [
                    {
                        value: "E3_561_001",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Χονδρικές -Επιτηδευμάτων "
                    },
                    {
                        value: "E3_561_002",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Χονδρικές  βάσει άρθρου 39α παρ 5 του κώδικα ΦΠΑ (Ν2859/2000)"
                    },
                    {
                        value: "E3_561_005",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_561_006",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_561_007",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λοιπά"
                    },
                    {
                        value: "E3_880_001",
                        label: "Πωλήσεις παγίων Χονδρικές"
                    },
                    {
                        value: "E3_880_003",
                        label: "Πωλήσεις παγίων Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_880_004",
                        label: "Πωλήσεις παγίων εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_562",
                        label: "Λοιπά συνήθη έσοδα"
                    },
                    {
                        value: "E3_563",
                        label: "Πιστωτικοί τόκοι και συναφή έσοδα"
                    },
                    {
                        value: "E3_564",
                        label: "Πιστωτικές συναλλαγματικές διαφορές "
                    },
                    {
                        value: "E3_565",
                        label: "Έσοδα συμμετοχών"
                    },
                    {
                        value: "E3_566",
                        label: "Κέρδη από διάθεση μη κυκλοφορούντων περιουσιακών στοιχείων"
                    },
                    {
                        value: "E3_567",
                        label: "Κέρδη από αναστροφή προβλέψεων και απομειώσεων"
                    },
                    {
                        value: "E3_568",
                        label: "Κέρδη απο επιμέτρηση στην εύλογη αξία "
                    },
                    {
                        value: "E3_570",
                        label: "Ασυνήθη έσοδα και κέρδη"
                    },
                    {
                        value: "E3_881_001",
                        label: "Πωλήσεις για λογ/σμο Τρίτων Χονδρικές"
                    },
                    {
                        value: "E3_881_003",
                        label: "Πωλήσεις για λογ/σμο Τρίτων Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_881_004",
                        label: "Πωλήσεις για λογ/σμο Τρίτων Εξωτερικού Τρίτες χώρες"
                    }
                ]
            },
            {
                value: "category1_95",
                label: "Λοιπά Πληροφοριακά στοιχεία εσόδων (+)/ (-)",
                type: [
                    {
                        value: "null",
                        label: "Κενό"
                    },
                ]
            },
            {
                value: "category2_1",
                label: "Αγορές Εμπορευμάτων (-) / (+)",
                type: [
                    {
                        value: "E3_102_001",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Χονδρικές"
                    },
                    {
                        value: "E3_102_002",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Λιανικές",
                    },
                    {
                        value: "E3_102_003",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Αγαθών του άρθρου 39α παρ.5 του Κώδικα Φ.Π.Α. (ν.2859/2000)"
                    },
                    {
                        value: "E3_102_006",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)Λοιπά"
                    },
                    {
                        value: "E3_313_001",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Χονδρικές"
                    },
                    {
                        value: "E3_313_002",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Λιανικές",
                    },
                    {
                        value: "E3_313_005",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Λοιπά"
                    }
                ]
            },
            {
                value: "category2_2",
                label: "Αγορές Α'-Β' Υλών (-) / (+)",
                type: [
                    {
                        value: "E3_202_001",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Χονδρικές",
                    },
                    {
                        value: "E3_202_002",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λιανικές",
                    },
                    {
                        value: "E3_202_005",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά",
                    },
                    {
                        value: "E3_302_001",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Χονδρικές",
                    },
                    {
                        value: "E3_302_002",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λιανικές",
                    },
                    {
                        value: "E3_302_005",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά",
                    },
                ]
            },
            {
                value: "category2_3",
                label: "Λήψη Υπηρεσιών (-) / (+)",
                type: [
                    {
                        value: "E3_585_001",
                        label: "Προμήθειες διαχείρισης ημεδαπής - αλλοδαπής (management fees)",
                    },
                    {
                        value: "E3_585_002",
                        label: "Δαπάνες από συνδεδεμένες επιχειρήσεις",
                    },
                    {
                        value: "E3_585_004",
                        label: "Δαπάνες για ενημερωτικές ημερίδες",
                    },
                    {
                        value: "E3_585_005",
                        label: "Έξοδα υποδοχής και φιλοξενίας",
                    },
                    {
                        value: "E3_585_006",
                        label: "Έξοδα ταξιδιών",
                    },
                    {
                        value: "E3_585_008",
                        label: "Έξοδα και προμήθειες παραγγελιοδόχου για λογαριασμό αγροτών",
                    },
                    {
                        value: "E3_585_009",
                        label: "Λοιπές Αμοιβές για υπηρεσίες ημεδαπής",
                    },
                    {
                        value: "E3_585_011",
                        label: "Ενέργεια",
                    },
                    {
                        value: "E3_585_012",
                        label: "Ύδρευση",
                    },
                    {
                        value: "E3_585_013",
                        label: "Τηλεπικοινωνίες",
                    },
                    {
                        value: "E3_585_014",
                        label: "Ενοίκια",
                    },
                    {
                        value: "E3_585_015",
                        label: "Διαφήμιση και προβολή",
                    },
                    {
                        value: "E3_585_016",
                        label: "Λοιπά έξοδα",
                    },
                    {
                        value: "E3_586",
                        label: "Χρεωστικοί τόκοι και συναφή έξοδα",
                    },
                    {
                        value: "E3_581_003",
                        label: "Παροχές σε εργαζόμενους/Λοιπές παροχές",
                    },
                ]
            },
            {
                value: "category2_4",
                label: "Γενικά Έξοδα με δικαίωμα έκπτωσης ΦΠΑ (-) / (+)",
                type: [
                    {
                        value: "E3_585_001",
                        label: "Προμήθειες διαχείρισης ημεδαπής - αλλοδαπής (management fees)",
                    },
                    {
                        value: "E3_585_002",
                        label: "Δαπάνες από συνδεδεμένες επιχειρήσεις",
                    },
                    {
                        value: "E3_585_004",
                        label: "Δαπάνες για ενημερωτικές ημερίδες",
                    },
                    {
                        value: "E3_585_005",
                        label: "Έξοδα υποδοχής και φιλοξενίας",
                    },
                    {
                        value: "E3_585_006",
                        label: "Έξοδα ταξιδιών",
                    },
                    {
                        value: "E3_585_008",
                        label: "Έξοδα και προμήθειες παραγγελιοδόχου για λογαριασμό αγροτών",
                    },
                    {
                        value: "E3_585_009",
                        label: "Λοιπές Αμοιβές για υπηρεσίες ημεδαπής",
                    },
                    {
                        value: "E3_585_011",
                        label: "Ενέργεια",
                    },
                    {
                        value: "E3_585_012",
                        label: "Ύδρευση",
                    },
                    {
                        value: "E3_585_013",
                        label: "Τηλεπικοινωνίες",
                    },
                    {
                        value: "E3_585_014",
                        label: "Ενοίκια",
                    },
                    {
                        value: "E3_585_015",
                        label: "Διαφήμιση και προβολή",
                    },
                    {
                        value: "E3_585_016",
                        label: "Λοιπά έξοδα",
                    },
                    {
                        value: "E3_586",
                        label: "Χρεωστικοί τόκοι και συναφή έξοδα",
                    },
                    {
                        value: "E3_102_001",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Χονδρικές",
                    },
                    {
                        value: "E3_102_003",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Αγαθών του άρθρου 39α παρ.5 του Κώδικα Φ.Π.Α. (ν.2859/2000)",
                    },
                    {
                        value: "E3_102_004",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
                    },
                    {
                        value: "E3_102_005",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
                    },
                    {
                        value: "E3_202_001",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Χονδρικές",
                    },
                    {
                        value: "E3_202_003",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
                    },
                    {
                        value: "E3_202_004",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
                    },
                    {
                        value: "E3_302_001",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Χονδρικές",
                    },
                    {
                        value: "E3_302_003",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
                    },
                    {
                        value: "E3_302_004",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
                    },
                    {
                        value: "E3_313_001",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Χονδρικές",
                    },
                    {
                        value: "E3_313_003",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
                    },
                    {
                        value: "E3_313_004",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
                    },
                    {
                        value: "E3_581_003",
                        label: "Παροχές σε εργαζόμενους/Λοιπές παροχές",
                    },
                ]
            },
            {
                value: "category2_5",
                label: "Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης ΦΠΑ (-) / (+)",
                type: [
                    {
                        value: "E3_585_001",
                        label: "Προμήθειες διαχείρισης ημεδαπής - αλλοδαπής (management fees)",
                    },
                    {
                        value: "E3_585_002",
                        label: "Δαπάνες από συνδεδεμένες επιχειρήσεις",
                    },
                    {
                        value: "E3_585_004",
                        label: "Δαπάνες για ενημερωτικές ημερίδες",
                    },
                    {
                        value: "E3_585_005",
                        label: "Έξοδα υποδοχής και φιλοξενίας",
                    },
                    {
                        value: "E3_585_006",
                        label: "Έξοδα ταξιδιών",
                    },
                    {
                        value: "E3_585_008",
                        label: "Έξοδα και προμήθειες παραγγελιοδόχου για λογαριασμό αγροτών",
                    },
                    {
                        value: "E3_585_009",
                        label: "Λοιπές Αμοιβές για υπηρεσίες ημεδαπής",
                    },
                    {
                        value: "E3_585_011",
                        label: "Ενέργεια",
                    },
                    {
                        value: "E3_585_012",
                        label: "Ύδρευση",
                    },
                    {
                        value: "E3_585_013",
                        label: "Τηλεπικοινωνίες",
                    },
                    {
                        value: "E3_585_014",
                        label: "Ενοίκια",
                    },
                    {
                        value: "E3_585_015",
                        label: "Διαφήμιση και προβολή",
                    },
                    {
                        value: "E3_585_016",
                        label: "Λοιπά έξοδα",
                    },
                    {
                        value: "E3_586",
                        label: "Χρεωστικοί τόκοι και συναφή έξοδα",
                    },
                    {
                        value: "E3_882_001",
                        label: "Αγορές ενσώματων παγίων χρήσης/Χονδρικές",
                    },
                    {
                        value: "E3_883_001",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Χονδρικές",
                    },
                    {
                        value: "E3_102_001",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Χονδρικές",
                    },
                    {
                        value: "E3_102_003",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Αγαθών του άρθρου 39α παρ.5 του Κώδικα Φ.Π.Α. (ν.2859/2000)",
                    },
                    {
                        value: "E3_102_004",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
                    },
                    {
                        value: "E3_102_005",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
                    },
                    {
                        value: "E3_202_001",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Χονδρικές",
                    },
                    {
                        value: "E3_202_003",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές\n",
                    },
                    {
                        value: "E3_202_004",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες\n",
                    },
                    {
                        value: "E3_302_001",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Χονδρικές",
                    },
                    {
                        value: "E3_302_003",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές\n",
                    },
                    {
                        value: "E3_302_004",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
                    },
                    {
                        value: "E3_313_001",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Χονδρικές",
                    },
                    {
                        value: "E3_313_003",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
                    },
                    {
                        value: "E3_313_004",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
                    },
                    {
                        value: "E3_581_003",
                        label: "Παροχές σε εργαζόμενους/Λοιπές παροχές",
                    },
                ]
            },
            {
                value: "category2_7",
                label: "Αγορές Παγίων (-) / (+)",
                type: [
                    {
                        value: "E3_882_001",
                        label: "Αγορές ενσώματων παγίων χρήσης/Χονδρικές",
                    },
                    {
                        value: "E3_882_002",
                        label: "Αγορές ενσώματων παγίων χρήσης/Λιανικές",
                    },
                    {
                        value: "E3_882_003",
                        label: "Αγορές ενσώματων παγίων χρήσης/Εξωτερικού Ενδοκοινοτικές",
                    },
                    {
                        value: "E3_882_004",
                        label: "Αγορές ενσώματων παγίων χρήσης/Εξωτερικού Τρίτες Χώρες",
                    },
                    {
                        value: "E3_883_001",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Χονδρικές",
                    },
                    {
                        value: "E3_883_002",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Λιανικές",
                    },
                    {
                        value: "E3_883_003",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Εξωτερικού Ενδοκοινοτικές",
                    },
                    {
                        value: "E3_883_004",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Εξωτερικού Τρίτες Χώρες",
                    },
                ]
            },
            {
                value: "category2_9",
                label: "Έξοδα για λ/σμο τρίτων (-) / (+)",
                type: [
                    {
                        value: "null",
                        label: "Δεν ενημερώνει E3"
                    },
                ]
            },
            {
                value: "category2_10",
                label: "Έξοδα προηγούμενων χρήσεων (-) / (+)",
                type: [
                    {
                        value: "E3_585_001",
                        label: "Προμήθειες διαχείρισης ημεδαπής - αλλοδαπής (management fees)",
                    },
                    {
                        value: "E3_585_002",
                        label: "Δαπάνες από συνδεδεμένες επιχειρήσεις",
                    },
                    {
                        value: "E3_585_004",
                        label: "Δαπάνες για ενημερωτικές ημερίδες",
                    },
                    {
                        value: "E3_585_005",
                        label: "Έξοδα υποδοχής και φιλοξενίας",
                    },
                    {
                        value: "E3_585_006",
                        label: "Έξοδα ταξιδιών",
                    },
                    {
                        value: "E3_585_008",
                        label: "Έξοδα και προμήθειες παραγγελιοδόχου για λογαριασμό αγροτών",
                    },
                    {
                        value: "E3_585_009",
                        label: "Λοιπές Αμοιβές για υπηρεσίες ημεδαπής",
                    },
                    {
                        value: "E3_585_011",
                        label: "Ενέργεια",
                    },
                    {
                        value: "E3_585_012",
                        label: "Ύδρευση",
                    },
                    {
                        value: "E3_585_013",
                        label: "Τηλεπικοινωνίες",
                    },
                    {
                        value: "E3_585_014",
                        label: "Ενοίκια",
                    },
                    {
                        value: "E3_585_015",
                        label: "Διαφήμιση και προβολή",
                    },
                    {
                        value: "E3_585_016",
                        label: "Λοιπά έξοδα",
                    },
                    {
                        value: "E3_586",
                        label: "Χρεωστικοί τόκοι και συναφή έξοδα",
                    },
                    {
                        value: "E3_882_001",
                        label: "Αγορές ενσώματων παγίων χρήσης/Χονδρικές",
                    },
                    {
                        value: "E3_883_001",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Χονδρικές",
                    },
                    {
                        value: "E3_102_001",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Χονδρικές",
                    },
                    {
                        value: "E3_102_003",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Αγαθών του άρθρου 39α παρ.5 του Κώδικα Φ.Π.Α. (ν.2859/2000)",
                    },
                    {
                        value: "E3_102_004",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
                    },
                    {
                        value: "E3_102_005",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
                    },
                    {
                        value: "E3_202_001",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Χονδρικές",
                    },
                    {
                        value: "E3_202_003",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές\n",
                    },
                    {
                        value: "E3_202_004",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες\n",
                    },
                    {
                        value: "E3_302_001",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Χονδρικές",
                    },
                    {
                        value: "E3_302_003",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές\n",
                    },
                    {
                        value: "E3_302_004",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
                    },
                    {
                        value: "E3_313_001",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Χονδρικές",
                    },
                    {
                        value: "E3_313_003",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
                    },
                    {
                        value: "E3_313_004",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
                    },
                    {
                        value: "E3_581_003",
                        label: "Παροχές σε εργαζόμενους/Λοιπές παροχές",
                    },
                    {
                        value: "E3_102_002",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Λιανικές",
                    },
                    {
                        value: "E3_102_006",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)Λοιπά"
                    },
                    {
                        value: "E3_313_002",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Λιανικές",
                    },
                    {
                        value: "E3_313_005",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Λοιπά"
                    },
                    {
                        value: "E3_882_002",
                        label: "Αγορές ενσώματων παγίων χρήσης/Λιανικές",
                    },
                    {
                        value: "E3_882_003",
                        label: "Αγορές ενσώματων παγίων χρήσης/Εξωτερικού Ενδοκοινοτικές",
                    },
                    {
                        value: "E3_882_004",
                        label: "Αγορές ενσώματων παγίων χρήσης/Εξωτερικού Τρίτες Χώρες",
                    },
                    {
                        value: "E3_883_002",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Λιανικές",
                    },
                    {
                        value: "E3_883_003",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Εξωτερικού Ενδοκοινοτικές",
                    },
                    {
                        value: "E3_883_004",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Εξωτερικού Τρίτες Χώρες",
                    },
                ]
            },
            {
                value: "category2_11",
                label: "Έξοδα επομένων χρήσεων (-) / (+)",
                type: [
                    {
                        value: "E3_585_001",
                        label: "Προμήθειες διαχείρισης ημεδαπής - αλλοδαπής (management fees)",
                    },
                    {
                        value: "E3_585_002",
                        label: "Δαπάνες από συνδεδεμένες επιχειρήσεις",
                    },
                    {
                        value: "E3_585_004",
                        label: "Δαπάνες για ενημερωτικές ημερίδες",
                    },
                    {
                        value: "E3_585_005",
                        label: "Έξοδα υποδοχής και φιλοξενίας",
                    },
                    {
                        value: "E3_585_006",
                        label: "Έξοδα ταξιδιών",
                    },
                    {
                        value: "E3_585_008",
                        label: "Έξοδα και προμήθειες παραγγελιοδόχου για λογαριασμό αγροτών",
                    },
                    {
                        value: "E3_585_009",
                        label: "Λοιπές Αμοιβές για υπηρεσίες ημεδαπής",
                    },
                    {
                        value: "E3_585_011",
                        label: "Ενέργεια",
                    },
                    {
                        value: "E3_585_012",
                        label: "Ύδρευση",
                    },
                    {
                        value: "E3_585_013",
                        label: "Τηλεπικοινωνίες",
                    },
                    {
                        value: "E3_585_014",
                        label: "Ενοίκια",
                    },
                    {
                        value: "E3_585_015",
                        label: "Διαφήμιση και προβολή",
                    },
                    {
                        value: "E3_585_016",
                        label: "Λοιπά έξοδα",
                    },
                    {
                        value: "E3_586",
                        label: "Χρεωστικοί τόκοι και συναφή έξοδα",
                    },
                    {
                        value: "E3_882_001",
                        label: "Αγορές ενσώματων παγίων χρήσης/Χονδρικές",
                    },
                    {
                        value: "E3_883_001",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Χονδρικές",
                    },
                    {
                        value: "E3_102_001",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Χονδρικές",
                    },
                    {
                        value: "E3_102_003",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Αγαθών του άρθρου 39α παρ.5 του Κώδικα Φ.Π.Α. (ν.2859/2000)",
                    },
                    {
                        value: "E3_102_004",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
                    },
                    {
                        value: "E3_102_005",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
                    },
                    {
                        value: "E3_202_001",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Χονδρικές",
                    },
                    {
                        value: "E3_202_003",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές\n",
                    },
                    {
                        value: "E3_202_004",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες\n",
                    },
                    {
                        value: "E3_302_001",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Χονδρικές",
                    },
                    {
                        value: "E3_302_003",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές\n",
                    },
                    {
                        value: "E3_302_004",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
                    },
                    {
                        value: "E3_313_001",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Χονδρικές",
                    },
                    {
                        value: "E3_313_003",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
                    },
                    {
                        value: "E3_313_004",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
                    },
                    {
                        value: "E3_581_003",
                        label: "Παροχές σε εργαζόμενους/Λοιπές παροχές",
                    },
                    {
                        value: "E3_102_002",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Λιανικές",
                    },
                    {
                        value: "E3_102_006",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)Λοιπά"
                    },
                    {
                        value: "E3_313_002",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Λιανικές",
                    },
                    {
                        value: "E3_313_005",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Λοιπά"
                    },
                    {
                        value: "E3_882_002",
                        label: "Αγορές ενσώματων παγίων χρήσης/Λιανικές",
                    },
                    {
                        value: "E3_882_003",
                        label: "Αγορές ενσώματων παγίων χρήσης/Εξωτερικού Ενδοκοινοτικές",
                    },
                    {
                        value: "E3_882_004",
                        label: "Αγορές ενσώματων παγίων χρήσης/Εξωτερικού Τρίτες Χώρες",
                    },
                    {
                        value: "E3_883_002",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Λιανικές",
                    },
                    {
                        value: "E3_883_003",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Εξωτερικού Ενδοκοινοτικές",
                    },
                    {
                        value: "E3_883_004",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Εξωτερικού Τρίτες Χώρες",
                    },
                ]
            },
            {
                value: "category2_95",
                label: "Λοιπά Πληροφοριακά Στοιχεία Εξόδων",
                type: [
                    {
                        value: "null",
                        label: "Δεν ενημερώνει E3"
                    },
                ]
            },
        ]
    },
    {
        value: "6.1",
        label: "Στοιχείο Αυτοπαράδοσης",
        category: [
            {
                value: "category1_6",
                label: "Αυτοπαραδόσεις/ Ιδιοχρησιμοποιήσεις  (+)/ (-)",
                type: [
                    {
                        value: "E3_595",
                        label: "Έξοδα σε ιδιοπαραγωγή"
                    },
                ]
            },
            {
                value: "category1_95",
                label: "Λοιπά Πληροφοριακά στοιχεία εσόδων (+)/ (-)",
                type: [
                    {
                        value: "null",
                        label: "Δεν ενημερώνει Ε3"
                    },
                ]
            },
        ]
    },
    {
        value: "6.2",
        label: "Στοιχείο Ιδιοχρησιμοποίησης",
        category: [
            {
                value: "category1_6",
                label: "Αυτοπαραδόσεις/ Ιδιοχρησιμοποιήσεις  (+)/ (-)",
                type: [
                    {
                        value: "E3_595",
                        label: "Έξοδα σε ιδιοπαραγωγή"
                    },
                ]
            },
            {
                value: "category1_95",
                label: "Λοιπά Πληροφοριακά στοιχεία εσόδων (+)/ (-)",
                type: [
                    {
                        value: "null",
                        label: "Δεν ενημερώνει Ε3"
                    },
                ]
            },
        ]
    },
    {
        value: "7.1",
        label: "Συμβόλαιο/Έσοδο",
        category: [
            {
                value: "category1_1",
                label: "Έσοδα από πώληση Εμπορευμάτων  (+)/ (-)",
                type: [
                    {
                        value: "E3_561_001",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Χονδρικές -Επιτηδευμάτων "
                    },
                    {
                        value: "E3_561_002",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Χονδρικές  βάσει άρθρου 39α παρ 5 του κώδικα ΦΠΑ (Ν2859/2000)"
                    },
                    {
                        value: "E3_561_007",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λοιπά"
                    },
                ]
            },
            {
                value: "category1_2",
                label: "Έσοδα από πώληση Προιόντων  (+)/ (-)",
                type: [
                    {
                        value: "E3_561_001",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Χονδρικές -Επιτηδευμάτων "
                    },
                    {
                        value: "E3_561_002",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Χονδρικές  βάσει άρθρου 39α παρ 5 του κώδικα ΦΠΑ (Ν2859/2000)"
                    },
                    {
                        value: "E3_561_007",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λοιπά"
                    },
                ]
            },
            {
                value: "category1_3",
                label: "Έσοδα από Παροχή Υπηρεσιών  (+)/ (-)",
                type: [
                    {
                        value: "E3_561_001",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Χονδρικές -Επιτηδευμάτων "
                    },
                    {
                        value: "E3_561_007",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λοιπά"
                    },
                ]
            },
            {
                value: "category1_4",
                label: "Έσοδα από Πώληση Παγίων  (+)/ (-)",
                type: [
                    {
                        value: "E3_880_001",
                        label: "Πωλήσεις παγίων Χονδρικές"
                    },
                ]
            },
            {
                value: "category1_5",
                label: "Λοιπά Έσοδα/Κέρδη (+)/ (-)",
                type: [
                    {
                        value: "E3_561_007",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λοιπά"
                    },
                    {
                        value: "E3_562",
                        label: "Λοιπά συνήθη έσοδα"
                    },
                    {
                        value: "E3_563",
                        label: "Πιστωτικοί τόκοι και συναφή έσοδα"
                    },
                    {
                        value: "E3_570",
                        label: "Ασυνήθη έσοδα και κέρδη"
                    },
                ]
            },
            {
                value: "category1_8",
                label: "Έσοδα προηγούμενων χρήσεων (+)/ (-)",
                type: [
                    {
                        value: "E3_561_001",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Χονδρικές -Επιτηδευμάτων "
                    },
                    {
                        value: "E3_561_002",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Χονδρικές  βάσει άρθρου 39α παρ 5 του κώδικα ΦΠΑ (Ν2859/2000)"
                    },
                    {
                        value: "E3_561_007",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λοιπά"
                    },
                    {
                        value: "E3_562",
                        label: "Λοιπά συνήθη έσοδα"
                    },
                    {
                        value: "E3_563",
                        label: "Πιστωτικοί τόκοι και συναφή έσοδα"
                    },
                    {
                        value: "E3_570",
                        label: "Ασυνήθη έσοδα και κέρδη"
                    },
                    {
                        value: "E3_880_001",
                        label: "Πωλήσεις παγίων Χονδρικές"
                    },
                ]
            },
            {
                value: "category1_9",
                label: "Έσοδα επομένων χρήσεων (+)/ (-)",
                type: [
                    {
                        value: "E3_561_001",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Χονδρικές -Επιτηδευμάτων "
                    },
                    {
                        value: "E3_561_002",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Χονδρικές  βάσει άρθρου 39α παρ 5 του κώδικα ΦΠΑ (Ν2859/2000)"
                    },
                    {
                        value: "E3_561_007",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λοιπά"
                    },
                    {
                        value: "E3_562",
                        label: "Λοιπά συνήθη έσοδα"
                    },
                    {
                        value: "E3_563",
                        label: "Πιστωτικοί τόκοι και συναφή έσοδα"
                    },
                    {
                        value: "E3_570",
                        label: "Ασυνήθη έσοδα και κέρδη"
                    },
                    {
                        value: "E3_880_001",
                        label: "Πωλήσεις παγίων Χονδρικές"
                    },
                ]
            },
            {
                value: "category1_95",
                label: "Λοιπά Πληροφοριακά στοιχεία εσόδων (+)/ (-)",
                type: [
                    {
                        value: "null",
                        label: "Δεν ενημερώνει Ε3"
                    },
                ]
            },
            {
                value: "category2_1",
                label: "Αγορές Εμπορευμάτων (-) / (+)",
                type: [
                    {
                        value: "E3_102_001",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Χονδρικές"
                    },
                    {
                        value: "E3_102_003",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Αγαθών του άρθρου 39α παρ.5 του Κώδικα Φ.Π.Α. (ν.2859/2000)"
                    },
                    {
                        value: "E3_102_006",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό) Λοιπά"
                    },
                    {
                        value: "E3_313_001",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Χονδρικές"
                    },
                    {
                        value: "E3_313_005",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Λοιπά"
                    }
                ]
            },
            {
                value: "category2_2",
                label: "Αγορές Α'-Β' Υλών (-) / (+)",
                type: [
                    {
                        value: "E3_202_001",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Χονδρικές"
                    },
                    {
                        value: "E3_202_005",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά"
                    },
                    {
                        value: "E3_302_001",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Χονδρικές"
                    },
                    {
                        value: "E3_302_005",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά"
                    }
                ]
            },
            {
                value: "category2_3",
                label: "Λήψη Υπηρεσιών (-) / (+)",
                type: [
                    {
                        value: "E3_585_001",
                        label: "Προμήθειες διαχείρισης ημεδαπής - αλλοδαπής (management fees)"
                    },
                    {
                        value: "E3_585_002",
                        label: "Δαπάνες από συνδεδεμένες επιχειρήσεις"
                    },
                    {
                        value: "E3_585_004",
                        label: "Δαπάνες για ενημερωτικές ημερίδες"
                    },
                    {
                        value: "E3_585_005",
                        label: "Έξοδα υποδοχής και φιλοξενίας"
                    },
                    {
                        value: "E3_585_006",
                        label: "Έξοδα ταξιδιών"
                    },
                    {
                        value: "E3_585_008",
                        label: "Έξοδα και προμήθειες παραγγελιοδόχου για λογαριασμό αγροτών"
                    },
                    {
                        value: "E3_585_009",
                        label: "Λοιπές Αμοιβές για υπηρεσίες ημεδαπής"
                    },
                    {
                        value: "E3_585_011",
                        label: "Ενέργεια"
                    },
                    {
                        value: "E3_585_012",
                        label: "Ύδρευση"
                    },
                    {
                        value: "E3_585_013",
                        label: "Τηλεπικοινωνίες"
                    },
                    {
                        value: "E3_585_014",
                        label: "Ενοίκια"
                    },
                    {
                        value: "E3_585_015",
                        label: "Διαφήμιση και προβολή"
                    },
                    {
                        value: "E3_585_016",
                        label: "Λοιπά έξοδα"
                    },
                    {
                        value: "E3_586",
                        label: "Χρεωστικοί τόκοι και συναφή έξοδα"
                    },
                    {
                        value: "E3_581_003",
                        label: "Παροχές σε εργαζόμενους/Λοιπές παροχές"
                    },
                ]
            },
            {
                value: "category2_4",
                label: "Γενικά Έξοδα με δικαίωμα έκπτωσης ΦΠΑ (-) / (+)",
                type: [
                    {
                        value: "E3_585_001",
                        label: "Προμήθειες διαχείρισης ημεδαπής - αλλοδαπής (management fees)"
                    },
                    {
                        value: "E3_585_002",
                        label: "Δαπάνες από συνδεδεμένες επιχειρήσεις"
                    },
                    {
                        value: "E3_585_004",
                        label: "Δαπάνες για ενημερωτικές ημερίδες"
                    },
                    {
                        value: "E3_585_005",
                        label: "Έξοδα υποδοχής και φιλοξενίας"
                    },
                    {
                        value: "E3_585_006",
                        label: "Έξοδα ταξιδιών"
                    },
                    {
                        value: "E3_585_008",
                        label: "Έξοδα και προμήθειες παραγγελιοδόχου για λογαριασμό αγροτών"
                    },
                    {
                        value: "E3_585_009",
                        label: "Λοιπές Αμοιβές για υπηρεσίες ημεδαπής"
                    },
                    {
                        value: "E3_585_011",
                        label: "Ενέργεια"
                    },
                    {
                        value: "E3_585_012",
                        label: "Ύδρευση"
                    },
                    {
                        value: "E3_585_013",
                        label: "Τηλεπικοινωνίες"
                    },
                    {
                        value: "E3_585_014",
                        label: "Ενοίκια"
                    },
                    {
                        value: "E3_585_015",
                        label: "Διαφήμιση και προβολή"
                    },
                    {
                        value: "E3_585_016",
                        label: "Λοιπά έξοδα"
                    },
                    {
                        value: "E3_586",
                        label: "Χρεωστικοί τόκοι και συναφή έξοδα"
                    },
                    {
                        value: "E3_882_001",
                        label: "Αγορές ενσώματων παγίων χρήσης/Χονδρικές",
                    },
                    {
                        value: "E3_883_001",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Χονδρικές",
                    },
                    {
                        value: "E3_102_004",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_102_005",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_202_003",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_202_004",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_302_003",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_302_004",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_313_003",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_313_004",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                ]
            },
            {
                value: "category2_5",
                label: "Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης ΦΠΑ (-) / (+)",
                type: [
                    {
                        value: "E3_585_001",
                        label: "Προμήθειες διαχείρισης ημεδαπής - αλλοδαπής (management fees)"
                    },
                    {
                        value: "E3_585_002",
                        label: "Δαπάνες από συνδεδεμένες επιχειρήσεις"
                    },
                    {
                        value: "E3_585_004",
                        label: "Δαπάνες για ενημερωτικές ημερίδες"
                    },
                    {
                        value: "E3_585_005",
                        label: "Έξοδα υποδοχής και φιλοξενίας"
                    },
                    {
                        value: "E3_585_006",
                        label: "Έξοδα ταξιδιών"
                    },
                    {
                        value: "E3_585_008",
                        label: "Έξοδα και προμήθειες παραγγελιοδόχου για λογαριασμό αγροτών"
                    },
                    {
                        value: "E3_585_009",
                        label: "Λοιπές Αμοιβές για υπηρεσίες ημεδαπής"
                    },
                    {
                        value: "E3_585_011",
                        label: "Ενέργεια"
                    },
                    {
                        value: "E3_585_012",
                        label: "Ύδρευση"
                    },
                    {
                        value: "E3_585_013",
                        label: "Τηλεπικοινωνίες"
                    },
                    {
                        value: "E3_585_014",
                        label: "Ενοίκια"
                    },
                    {
                        value: "E3_585_015",
                        label: "Διαφήμιση και προβολή"
                    },
                    {
                        value: "E3_585_016",
                        label: "Λοιπά έξοδα"
                    },
                    {
                        value: "E3_586",
                        label: "Χρεωστικοί τόκοι και συναφή έξοδα"
                    },
                    {
                        value: "E3_882_001",
                        label: "Αγορές ενσώματων παγίων χρήσης/Χονδρικές",
                    },
                    {
                        value: "E3_883_001",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Χονδρικές",
                    },
                    {
                        value: "E3_102_004",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_102_005",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_202_003",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_202_004",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_302_003",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_302_004",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_313_003",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_313_004",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                ]
            },
            {
                value: "category2_7",
                label: "Αγορές Παγίων",
                type: [
                    {
                        value: "E3_882_001",
                        label: "Αγορές ενσώματων παγίων χρήσης/Χονδρικές"
                    },
                    {
                        value: "E3_883_001",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Χονδρικές"
                    }
                ]
            },
            {
                value: "category2_10",
                label: "Έξοδα προηγούμενων χρήσεων (-) / (+)",
                type: [
                    {
                        value: "E3_585_001",
                        label: "Προμήθειες διαχείρισης ημεδαπής - αλλοδαπής (management fees)"
                    },
                    {
                        value: "E3_585_002",
                        label: "Δαπάνες από συνδεδεμένες επιχειρήσεις"
                    },
                    {
                        value: "E3_585_004",
                        label: "Δαπάνες για ενημερωτικές ημερίδες"
                    },
                    {
                        value: "E3_585_005",
                        label: "Έξοδα υποδοχής και φιλοξενίας"
                    },
                    {
                        value: "E3_585_006",
                        label: "Έξοδα ταξιδιών"
                    },
                    {
                        value: "E3_585_008",
                        label: "Έξοδα και προμήθειες παραγγελιοδόχου για λογαριασμό αγροτών"
                    },
                    {
                        value: "E3_585_009",
                        label: "Λοιπές Αμοιβές για υπηρεσίες ημεδαπής"
                    },
                    {
                        value: "E3_585_011",
                        label: "Ενέργεια"
                    },
                    {
                        value: "E3_585_012",
                        label: "Ύδρευση"
                    },
                    {
                        value: "E3_585_013",
                        label: "Τηλεπικοινωνίες"
                    },
                    {
                        value: "E3_585_014",
                        label: "Ενοίκια"
                    },
                    {
                        value: "E3_585_015",
                        label: "Διαφήμιση και προβολή"
                    },
                    {
                        value: "E3_585_016",
                        label: "Λοιπά έξοδα"
                    },
                    {
                        value: "E3_586",
                        label: "Χρεωστικοί τόκοι και συναφή έξοδα"
                    },
                    {
                        value: "E3_882_001",
                        label: "Αγορές ενσώματων παγίων χρήσης/Χονδρικές",
                    },
                    {
                        value: "E3_883_001",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Χονδρικές",
                    },
                    {
                        value: "E3_102_004",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_102_005",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_202_003",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_202_004",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_302_003",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_302_004",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_313_003",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_313_004",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_882_001",
                        label: "Αγορές ενσώματων παγίων χρήσης/Χονδρικές"
                    },
                    {
                        value: "E3_883_001",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Χονδρικές"
                    },
                    {
                        value: "E3_102_001",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Χονδρικές"
                    },
                    {
                        value: "E3_102_003",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Αγαθών του άρθρου 39α παρ.5 του Κώδικα Φ.Π.Α. (ν.2859/2000)"
                    },
                    {
                        value: "E3_102_006",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό) Λοιπά"
                    },
                    {
                        value: "E3_313_001",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Χονδρικές"
                    },
                    {
                        value: "E3_313_005",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Λοιπά"
                    },
                    {
                        value: "E3_202_001",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Χονδρικές"
                    },
                    {
                        value: "E3_202_005",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά"
                    },
                    {
                        value: "E3_302_001",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Χονδρικές"
                    },
                    {
                        value: "E3_302_005",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά"
                    }
                ]
            },
            {
                value: "category2_11",
                label: "Έξοδα επομένων χρήσεων (-) / (+)",
                type: [
                    {
                        value: "E3_585_001",
                        label: "Προμήθειες διαχείρισης ημεδαπής - αλλοδαπής (management fees)"
                    },
                    {
                        value: "E3_585_002",
                        label: "Δαπάνες από συνδεδεμένες επιχειρήσεις"
                    },
                    {
                        value: "E3_585_004",
                        label: "Δαπάνες για ενημερωτικές ημερίδες"
                    },
                    {
                        value: "E3_585_005",
                        label: "Έξοδα υποδοχής και φιλοξενίας"
                    },
                    {
                        value: "E3_585_006",
                        label: "Έξοδα ταξιδιών"
                    },
                    {
                        value: "E3_585_008",
                        label: "Έξοδα και προμήθειες παραγγελιοδόχου για λογαριασμό αγροτών"
                    },
                    {
                        value: "E3_585_009",
                        label: "Λοιπές Αμοιβές για υπηρεσίες ημεδαπής"
                    },
                    {
                        value: "E3_585_011",
                        label: "Ενέργεια"
                    },
                    {
                        value: "E3_585_012",
                        label: "Ύδρευση"
                    },
                    {
                        value: "E3_585_013",
                        label: "Τηλεπικοινωνίες"
                    },
                    {
                        value: "E3_585_014",
                        label: "Ενοίκια"
                    },
                    {
                        value: "E3_585_015",
                        label: "Διαφήμιση και προβολή"
                    },
                    {
                        value: "E3_585_016",
                        label: "Λοιπά έξοδα"
                    },
                    {
                        value: "E3_586",
                        label: "Χρεωστικοί τόκοι και συναφή έξοδα"
                    },
                    {
                        value: "E3_882_001",
                        label: "Αγορές ενσώματων παγίων χρήσης/Χονδρικές",
                    },
                    {
                        value: "E3_883_001",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Χονδρικές",
                    },
                    {
                        value: "E3_102_004",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_102_005",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_202_003",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_202_004",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_302_003",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_302_004",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_313_003",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_313_004",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_882_001",
                        label: "Αγορές ενσώματων παγίων χρήσης/Χονδρικές"
                    },
                    {
                        value: "E3_883_001",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Χονδρικές"
                    },
                    {
                        value: "E3_102_001",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Χονδρικές"
                    },
                    {
                        value: "E3_102_003",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Αγαθών του άρθρου 39α παρ.5 του Κώδικα Φ.Π.Α. (ν.2859/2000)"
                    },
                    {
                        value: "E3_102_006",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό) Λοιπά"
                    },
                    {
                        value: "E3_313_001",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Χονδρικές"
                    },
                    {
                        value: "E3_313_005",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Λοιπά"
                    },
                    {
                        value: "E3_202_001",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Χονδρικές"
                    },
                    {
                        value: "E3_202_005",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά"
                    },
                    {
                        value: "E3_302_001",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Χονδρικές"
                    },
                    {
                        value: "E3_302_005",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά"
                    }
                ]
            },
            {
                value: "category2_95",
                label: "Λοιπά Πληροφοριακά Στοιχεία Εξόδων",
                type: [
                    {
                        value: "null",
                        label: "Δεν ενημερώνει E3"
                    },
                ]
            },
        ]
    },
    {
        value: "8.1",
        label: "Ενοίκια-Έσοδο",
        category: [
            {
                value: "category1_3",
                label: "Έσοδα από Παροχή Υπηρεσιών (+) / (-)",
                type: [
                    {
                        value: "E3_561_001",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Χονδρικές - Επιτηδευματιών"
                    },
                ]
            },
            {
                value: "category1_5",
                label: "Λοιπά Έσοδα/Κέρδη (+)/ (-)",
                type: [
                    {
                        value: "E3_562",
                        label: "Λοιπά συνήθη έσοδα"
                    },
                ]
            },
            {
                value: "category1_8",
                label: "Έσοδα προηγούμενων χρήσεων (+)/ (-)",
                type: [
                    {
                        value: "E3_561_001",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Χονδρικές - Επιτηδευματιών"
                    },
                    {
                        value: "E3_562",
                        label: "Λοιπά συνήθη έσοδα"
                    },
                ]
            },
            {
                value: "category1_9",
                label: "Έσοδα επομένων χρήσεων (+)/ (-)",
                type: [
                    {
                        value: "E3_561_001",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Χονδρικές - Επιτηδευματιών"
                    },
                    {
                        value: "E3_562",
                        label: "Λοιπά συνήθη έσοδα"
                    },
                ]
            },
            {
                value: "category1_95",
                label: "Λοιπά Πληροφοριακά στοιχεία εσόδων (+)/ (-)",
                type: [
                    {
                        value: "null",
                        label: "Δεν ενημερώνει Ε3"
                    },
                ]
            },
            {
                value: "category2_4",
                label: "Γενικά Έξοδα με δικαίωμα έκπτωσης ΦΠΑ (-) / (+)",
                type: [
                    {
                        value: "E3_585_014",
                        label: "Ενοίκια"
                    },
                    {
                        value: "E3_585_016",
                        label: "Λοιπά έξοδα"
                    },
                ]
            },
            {
                value: "category2_5",
                label: "Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης ΦΠΑ (-) / (+)",
                type: [
                    {
                        value: "E3_585_014",
                        label: "Ενοίκια"
                    },
                    {
                        value: "E3_585_016",
                        label: "Λοιπά έξοδα"
                    },
                ]
            },
            {
                value: "category2_10",
                label: "Έξοδα προηγούμενων χρήσεων (-) / (+)",
                type: [
                    {
                        value: "E3_585_014",
                        label: "Ενοίκια"
                    },
                    {
                        value: "E3_585_016",
                        label: "Λοιπά έξοδα"
                    },
                ]
            },
            {
                value: "category2_11",
                label: "Έξοδα επομένων χρήσεων (-) / (+)",
                type: [
                    {
                        value: "E3_585_014",
                        label: "Ενοίκια"
                    },
                    {
                        value: "E3_585_016",
                        label: "Λοιπά έξοδα"
                    },
                ]
            },
            {
                value: "category2_95",
                label: "Λοιπά Πληροφοριακά Στοιχεία Εξόδων",
                type: [
                    {
                        value: "null",
                        label: "Δεν ενημερώνει E3"
                    },
                ]
            },
        ]
    },
    {
        value: "8.2",
        label: "Τέλος ανθεκτικότητας κλιματικής κρίσης",
        category: [
            {
                value: "category1_95",
                label: "Λοιπά Πληροφοριακά στοιχεία εσόδων (+)/ (-)",
                type: [
                    {
                        value: "null",
                        label: "Κενό"
                    },
                ]
            },
            {
                value: "category2_5",
                label: "Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης ΦΠΑ (-) / (+)",
                type: [
                    {
                        value: "E3_585_005",
                        label: "Έξοδα υποδοχής και φιλοξενίας"
                    },
                    {
                        value: "E3_585_006",
                        label: "Έξοδα ταξιδιών"
                    },
                    {
                        value: "E3_585_016",
                        label: "Λοιπά έξοδα"
                    },
                ],
            },
            {
                value: "category2_10",
                label: "Έξοδα προηγούμενων χρήσεων (-) / (+)",
                type: [
                    {
                        value: "E3_585_005",
                        label: "Έξοδα υποδοχής και φιλοξενίας"
                    },
                    {
                        value: "E3_585_006",
                        label: "Έξοδα ταξιδιών"
                    },
                    {
                        value: "E3_585_016",
                        label: "Λοιπά έξοδα"
                    },
                ],
            },
            {
                value: "category2_11",
                label: "Έξοδα επομένων χρήσεων (-) / (+)",
                type: [
                    {
                        value: "E3_585_005",
                        label: "Έξοδα υποδοχής και φιλοξενίας"
                    },
                    {
                        value: "E3_585_006",
                        label: "Έξοδα ταξιδιών"
                    },
                    {
                        value: "E3_585_016",
                        label: "Λοιπά έξοδα"
                    },
                ],
            },
            {
                value: "category2_95",
                label: "Λοιπά Πληροφοριακά Στοιχεία Εξόδων (-) / (+)",
                type: [
                    {
                        value: "null",
                        label: "Δεν ενημερώνει E3"
                    },
                ],
            },
        ]
    },
    {
        value: "9.3",
        label: "Παραστατικό Διακίνησης Μη Συσχετιζόμενο",
        category: [
            {
                value: "category3",
                label: "Διακίνηση",
                type: [
                    {
                        value: "null",
                        label: "Δεν ενημερώνει Ε3"
                    },
                ]
            }
        ]
    },
    {
        value: "11.1",
        label: "ΑΛΠ",
        category: [
            {
                value: "category1_1",
                label: "Έσοδα από πώληση Εμπορευμάτων  (+)/ (-)",
                type: [
                    {
                        value: "E3_561_003",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λιανικές - Ιδιωτική Πελατεία "
                    },
                    {
                        value: "E3_561_004",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λιανικές  βάσει άρθρου 39α παρ 5 του κώδικα ΦΠΑ (Ν2859/2000)"
                    },
                    {
                        value: "E3_561_005",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_561_006",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_561_007",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λοιπά"
                    },
                ]
            },
            {
                value: "category1_2",
                label: "Έσοδα από Πώληση Προϊόντων (+) / (-)",
                type: [
                    {
                        value: "E3_561_003",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λιανικές - Ιδιωτική Πελατεία "
                    },
                    {
                        value: "E3_561_004",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λιανικές  βάσει άρθρου 39α παρ 5 του κώδικα ΦΠΑ (Ν2859/2000)"
                    },
                    {
                        value: "E3_561_005",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_561_006",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_561_007",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λοιπά"
                    },
                ]
            },
            {
                value: "category1_3",
                label: "Έσοδα από Παροχή Υπηρεσιών  (+)/ (-)",
                type: [
                    {
                        value: "E3_561_003",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λιανικές - Ιδιωτική Πελατεία"
                    },
                    {
                        value: "E3_561_004",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λιανικές  βάσει άρθρου 39α παρ 5 του κώδικα ΦΠΑ (Ν2859/2000)"
                    },
                    {
                        value: "E3_561_005",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_561_006",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_561_007",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λοιπά"
                    },
                    {
                        value: "E3_563",
                        label: "Πιστωτικοί τόκοι και συναφή έσοδα"
                    },
                ]
            },
            {
                value: "category1_4",
                label: "Έσοδα από Πώληση Παγίων  (+)/ (-)",
                type: [
                    {
                        value: "E3_880_002",
                        label: "Πωλήσεις παγίων Λιανικές"
                    },
                    {
                        value: "E3_880_003",
                        label: "Πωλήσεις παγίων Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_880_004",
                        label: "Πωλήσεις παγίων εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_561_007",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λοιπά"
                    },
                ]
            },
            {
                value: "category1_5",
                label: "Λοιπά Έσοδα/Κέρδη (+)/ (-)",
                type: [
                    {
                        value: "E3_561_007",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λοιπά"
                    },
                    {
                        value: "E3_562",
                        label: "Λοιπά συνήθη έσοδα"
                    },
                    {
                        value: "E3_563",
                        label: "Πιστωτικοί τόκοι και συναφή έσοδα"
                    },
                    {
                        value: "E3_570",
                        label: "Ασυνήθη έσοδα και κέρδη"
                    },
                ]
            },
            {
                value: "category1_6",
                label: "Αυτοπαραδόσεις / Ιδιοχρησιμοποιήσεις (+) / (-)",
                type: [
                    {
                        value: "E3_595",
                        label: "Έξοδα σε ιδιοπαραγωγή"
                    },
                ]
            },
            {
                value: "category1_7",
                label: "Έσοδα για λ/σμο τρίτων (+) / (-)",
                type: [
                    {
                        value: "E3_881_002",
                        label: "Πωλήσεις για λογ/σμο Τρίτων Λιανικές"
                    },
                    {
                        value: "E3_881_003",
                        label: "Πωλήσεις για λογ/σμο Τρίτων Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_881_004",
                        label: "Πωλήσεις για λογ/σμο Τρίτων Εξωτερικού Τρίτες Χώρες"
                    },
                ]
            },
            {
                value: "category1_8",
                label: "Έσοδα προηγούμενων χρήσεων (+)/ (-)",
                type: [
                    {
                        value: "E3_561_003",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λιανικές - Ιδιωτική Πελατεία"
                    },
                    {
                        value: "E3_561_004",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λιανικές  βάσει άρθρου 39α παρ 5 του κώδικα ΦΠΑ (Ν2859/2000)"
                    },
                    {
                        value: "E3_561_005",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_561_006",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_561_007",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λοιπά"
                    },
                    {
                        value: "E3_562",
                        label: "Λοιπά συνήθη έσοδα"
                    },
                    {
                        value: "E3_563",
                        label: "Πιστωτικοί τόκοι και συναφή έσοδα"
                    },
                    {
                        value: "E3_570",
                        label: "Ασυνήθη έσοδα και κέρδη"
                    },
                    {
                        value: "E3_595",
                        label: "Έξοδα σε ιδιοπαραγωγή"
                    },
                    {
                        value: "E3_880_002",
                        label: "Πωλήσεις παγίων Λιανικές"
                    },
                    {
                        value: "E3_880_003",
                        label: "Πωλήσεις παγίων Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_880_004",
                        label: "Πωλήσεις παγίων εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_881_002",
                        label: "Πωλήσεις για λογ/σμο Τρίτων Λιανικές"
                    },
                    {
                        value: "E3_881_003",
                        label: "Πωλήσεις για λογ/σμο Τρίτων Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_881_004",
                        label: "Πωλήσεις για λογ/σμο Τρίτων Εξωτερικού Τρίτες Χώρες"
                    },
                ]
            },
            {
                value: "category1_9",
                label: "Έσοδα επομένων χρήσεων (+)/ (-)",
                type: [
                    {
                        value: "E3_561_003",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λιανικές - Ιδιωτική Πελατεία"
                    },
                    {
                        value: "E3_561_004",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λιανικές  βάσει άρθρου 39α παρ 5 του κώδικα ΦΠΑ (Ν2859/2000)"
                    },
                    {
                        value: "E3_561_005",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_561_006",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_561_007",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λοιπά"
                    },
                    {
                        value: "E3_562",
                        label: "Λοιπά συνήθη έσοδα"
                    },
                    {
                        value: "E3_563",
                        label: "Πιστωτικοί τόκοι και συναφή έσοδα"
                    },
                    {
                        value: "E3_570",
                        label: "Ασυνήθη έσοδα και κέρδη"
                    },
                    {
                        value: "E3_595",
                        label: "Έξοδα σε ιδιοπαραγωγή"
                    },
                    {
                        value: "E3_880_002",
                        label: "Πωλήσεις παγίων Λιανικές"
                    },
                    {
                        value: "E3_880_003",
                        label: "Πωλήσεις παγίων Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_880_004",
                        label: "Πωλήσεις παγίων εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_881_002",
                        label: "Πωλήσεις για λογ/σμο Τρίτων Λιανικές"
                    },
                    {
                        value: "E3_881_003",
                        label: "Πωλήσεις για λογ/σμο Τρίτων Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_881_004",
                        label: "Πωλήσεις για λογ/σμο Τρίτων Εξωτερικού Τρίτες Χώρες"
                    },
                ]
            },
            {
                value: "category1_95",
                label: "Λοιπά Πληροφοριακά στοιχεία εσόδων (+)/ (-)",
                type: [
                    {
                        value: "null",
                        label: "Δεν ενημερώνει Ε3"
                    },
                ]
            }
        ]
    },
    {
        value: "11.2",
        label: "ΑΠΥ",
        category: [
            {
                value: "category1_3",
                label: "Έσοδα από Παροχή Υπηρεσιών  (+)/ (-)",
                type: [
                    {
                        value: "E3_561_003",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λιανικές - Ιδιωτική Πελατεία "
                    },
                    {
                        value: "E3_561_005",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_561_006",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_561_007",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λοιπά"
                    },
                    {
                        value: "E3_563",
                        label: "Πιστωτικοί τόκοι και συναφή έσοδα"
                    },
                ]
            },
            {
                value: "category1_5",
                label: "Λοιπά Έσοδα/Κέρδη (+)/ (-)",
                type: [
                    {
                        value: "E3_561_007",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λοιπά"
                    },
                    {
                        value: "E3_562",
                        label: "Λοιπά συνήθη έσοδα"
                    },
                    {
                        value: "E3_563",
                        label: "Πιστωτικοί τόκοι και συναφή έσοδα"
                    },
                    {
                        value: "E3_570",
                        label: "Ασυνήθη έσοδα και κέρδη"
                    },
                ]
            },
            {
                value: "category1_6",
                label: "Αυτοπαραδόσεις / Ιδιοχρησιμοποιήσεις (+) / (-)",
                type: [
                    {
                        value: "E3_595",
                        label: "Έξοδα σε ιδιοπαραγωγή"
                    },
                ]
            },
            {
                value: "category1_8",
                label: "Έσοδα προηγούμενων χρήσεων (+)/ (-)",
                type: [
                    {
                        value: "E3_561_003",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λιανικές - Ιδιωτική Πελατεία "
                    },
                    {
                        value: "E3_561_005",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_561_006",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_561_007",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λοιπά"
                    },
                    {
                        value: "E3_562",
                        label: "Λοιπά συνήθη έσοδα"
                    },
                    {
                        value: "E3_563",
                        label: "Πιστωτικοί τόκοι και συναφή έσοδα"
                    },
                    {
                        value: "E3_570",
                        label: "Ασυνήθη έσοδα και κέρδη"
                    },
                    {
                        value: "E3_595",
                        label: "Έξοδα σε ιδιοπαραγωγή"
                    },
                ]
            },
            {
                value: "category1_9",
                label: "Έσοδα επομένων χρήσεων (+)/ (-)",
                type: [
                    {
                        value: "E3_561_003",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λιανικές - Ιδιωτική Πελατεία "
                    },
                    {
                        value: "E3_561_005",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_561_006",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_561_007",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λοιπά"
                    },
                    {
                        value: "E3_562",
                        label: "Λοιπά συνήθη έσοδα"
                    },
                    {
                        value: "E3_563",
                        label: "Πιστωτικοί τόκοι και συναφή έσοδα"
                    },
                    {
                        value: "E3_570",
                        label: "Ασυνήθη έσοδα και κέρδη"
                    },
                    {
                        value: "E3_595",
                        label: "Έξοδα σε ιδιοπαραγωγή"
                    },
                ]
            },
            {
                value: "category1_95",
                label: "Λοιπά Πληροφοριακά στοιχεία εσόδων (+)/ (-)",
                type: [
                    {
                        value: "null",
                        label: "Δεν ενημερώνει E3"
                    },
                ]
            }
        ]
    },
    {
        value: "11.3",
        label: "Απλοποιημένο Τιμολόγιο",
        category: [
            {
                value: "category1_1",
                label: "Έσοδα από Πώληση Εμπορευμάτων (+) / (-)",
                type: [
                    {
                        value: "E3_561_001",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Χονδρικές - Επιτηδευματιών"
                    },
                    {
                        value: "E3_561_002",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Χονδρικές βάσει άρθρου 39α παρ 5 του Κώδικα Φ.Π.Α. (Ν.2859/2000)"
                    },
                    {
                        value: "E3_561_003",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λιανικές - Ιδιωτική Πελατεία "
                    },
                    {
                        value: "E3_561_004",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λιανικές  βάσει άρθρου 39α παρ 5 του κώδικα ΦΠΑ (Ν2859/2000)"
                    },
                    {
                        value: "E3_561_005",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_561_006",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_561_007",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λοιπά"
                    },
                ]
            },
            {
                value: "category1_2",
                label: "Έσοδα από Πώληση Προϊόντων (+) / (-)",
                type: [
                    {
                        value: "E3_561_001",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Χονδρικές - Επιτηδευματιών"
                    },
                    {
                        value: "E3_561_002",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Χονδρικές βάσει άρθρου 39α παρ 5 του Κώδικα Φ.Π.Α. (Ν.2859/2000)"
                    },
                    {
                        value: "E3_561_003",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λιανικές - Ιδιωτική Πελατεία "
                    },
                    {
                        value: "E3_561_004",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λιανικές  βάσει άρθρου 39α παρ 5 του κώδικα ΦΠΑ (Ν2859/2000)"
                    },
                    {
                        value: "E3_561_005",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_561_006",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_561_007",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λοιπά"
                    },
                ]
            },
            {
                value: "category1_3",
                label: "Έσοδα από Παροχή Υπηρεσιών  (+)/ (-)",
                type: [
                    {
                        value: "E3_561_001",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Χονδρικές - Επιτηδευματιών"
                    },
                    {
                        value: "E3_561_002",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Χονδρικές βάσει άρθρου 39α παρ 5 του Κώδικα Φ.Π.Α. (Ν.2859/2000)"
                    },
                    {
                        value: "E3_561_003",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λιανικές - Ιδιωτική Πελατεία "
                    },
                    {
                        value: "E3_561_004",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λιανικές  βάσει άρθρου 39α παρ 5 του κώδικα ΦΠΑ (Ν2859/2000)"
                    },
                    {
                        value: "E3_561_005",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_561_006",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_561_007",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λοιπά"
                    },
                    {
                        value: "E3_563",
                        label: "Πιστωτικοί τόκοι και συναφή έσοδα"
                    },
                ]
            },
            {
                value: "category1_4",
                label: "Έσοδα από Πώληση Παγίων  (+)/ (-)",
                type: [
                    {
                        value: "E3_880_001",
                        label: "Πωλήσεις Παγίων Χονδρικές"
                    },
                    {
                        value: "E3_880_002",
                        label: "Πωλήσεις παγίων Λιανικές"
                    },
                    {
                        value: "E3_880_003",
                        label: "Πωλήσεις παγίων Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_880_004",
                        label: "Πωλήσεις παγίων εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_561_007",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λοιπά"
                    },
                ]
            },
            {
                value: "category1_5",
                label: "Λοιπά Έσοδα/Κέρδη (+)/ (-)",
                type: [
                    {
                        value: "E3_561_007",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λοιπά"
                    },
                    {
                        value: "E3_562",
                        label: "Λοιπά συνήθη έσοδα"
                    },
                    {
                        value: "E3_563",
                        label: "Πιστωτικοί τόκοι και συναφή έσοδα"
                    },
                    {
                        value: "E3_564",
                        label: "Πιστωτικές συναλλαγματικές διαφορές"
                    },
                    {
                        value: "E3_565",
                        label: "Έσοδα συμμετοχών"
                    },
                    {
                        value: "E3_566",
                        label: "Κέρδη από διάθεση μη κυκλοφορούντων περιουσιακών στοιχείων"
                    },
                    {
                        value: "E3_567",
                        label: "Κέρδη από αναστροφή προβλέψεων και απομειώσεων"
                    },
                    {
                        value: "E3_568",
                        label: "Κέρδη από επιμέτρηση στην εύλογη αξία"
                    },
                    {
                        value: "E3_570",
                        label: "Ασυνήθη έσοδα και κέρδη"
                    },
                ]
            },
            {
                value: "category1_6",
                label: "Αυτοπαραδόσεις / Ιδιοχρησιμοποιήσεις (+) / (-)",
                type: [
                    {
                        value: "E3_595",
                        label: "Έξοδα σε ιδιοπαραγωγή"
                    },
                ]
            },
            {
                value: "category1_7",
                label: "Έσοδα από λογ/σμο τρίτων (+)/ (-)",
                type: [
                    {
                        value: "E3_881_001",
                        label: "Πωλήσεις για λογ/σμο Τρίτων Χονδρικές"
                    },
                    {
                        value: "E3_881_002",
                        label: "Πωλήσεις για λογ/σμο Τρίτων Λιανικές"
                    },
                    {
                        value: "E3_881_003",
                        label: "Πωλήσεις για λογ/σμο Τρίτων Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_881_004",
                        label: "Πωλήσεις για λογ/σμο Τρίτων Εξωτερικού Τρίτες χώρες"
                    }
                ]
            },
            {
                value: "category1_8",
                label: "Έσοδα προηγούμενων χρήσεων (+)/ (-)",
                type: [
                    {
                        value: "E3_561_001",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Χονδρικές - Επιτηδευματιών"
                    },
                    {
                        value: "E3_561_002",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Χονδρικές βάσει άρθρου 39α παρ 5 του Κώδικα Φ.Π.Α. (Ν.2859/2000)"
                    },
                    {
                        value: "E3_561_003",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λιανικές - Ιδιωτική Πελατεία "
                    },
                    {
                        value: "E3_561_004",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λιανικές  βάσει άρθρου 39α παρ 5 του κώδικα ΦΠΑ (Ν2859/2000)"
                    },
                    {
                        value: "E3_561_005",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_561_006",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_561_007",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λοιπά"
                    },
                    {
                        value: "E3_562",
                        label: "Λοιπά συνήθη έσοδα"
                    },
                    {
                        value: "E3_563",
                        label: "Πιστωτικοί τόκοι και συναφή έσοδα"
                    },
                    {
                        value: "E3_564",
                        label: "Πιστωτικές συναλλαγματικές διαφορές"
                    },
                    {
                        value: "E3_565",
                        label: "Έσοδα συμμετοχών"
                    },
                    {
                        value: "E3_566",
                        label: "Κέρδη από διάθεση μη κυκλοφορούντων περιουσιακών στοιχείων"
                    },
                    {
                        value: "E3_567",
                        label: "Κέρδη από αναστροφή προβλέψεων και απομειώσεων"
                    },
                    {
                        value: "E3_568",
                        label: "Κέρδη από επιμέτρηση στην εύλογη αξία"
                    },
                    {
                        value: "E3_570",
                        label: "Ασυνήθη έσοδα και κέρδη"
                    },
                    {
                        value: "E3_595",
                        label: "Έξοδα σε ιδιοπαραγωγή"
                    },
                    {
                        value: "E3_880_001",
                        label: "Πωλήσεις Παγίων Χονδρικές"
                    },
                    {
                        value: "E3_880_002",
                        label: "Πωλήσεις παγίων Λιανικές"
                    },
                    {
                        value: "E3_880_003",
                        label: "Πωλήσεις παγίων Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_880_004",
                        label: "Πωλήσεις παγίων εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_881_001",
                        label: "Πωλήσεις για λογ/σμο Τρίτων Χονδρικές"
                    },
                    {
                        value: "E3_881_002",
                        label: "Πωλήσεις για λογ/σμο Τρίτων Λιανικές"
                    },
                    {
                        value: "E3_881_003",
                        label: "Πωλήσεις για λογ/σμο Τρίτων Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_881_004",
                        label: "Πωλήσεις για λογ/σμο Τρίτων Εξωτερικού Τρίτες χώρες"
                    }
                ]
            },
            {
                value: "category1_9",
                label: "Έσοδα επομένων χρήσεων (+)/ (-)",
                type: [
                    {
                        value: "E3_561_001",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Χονδρικές - Επιτηδευματιών"
                    },
                    {
                        value: "E3_561_002",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Χονδρικές βάσει άρθρου 39α παρ 5 του Κώδικα Φ.Π.Α. (Ν.2859/2000)"
                    },
                    {
                        value: "E3_561_003",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λιανικές - Ιδιωτική Πελατεία "
                    },
                    {
                        value: "E3_561_004",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λιανικές  βάσει άρθρου 39α παρ 5 του κώδικα ΦΠΑ (Ν2859/2000)"
                    },
                    {
                        value: "E3_561_005",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_561_006",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_561_007",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λοιπά"
                    },
                    {
                        value: "E3_562",
                        label: "Λοιπά συνήθη έσοδα"
                    },
                    {
                        value: "E3_563",
                        label: "Πιστωτικοί τόκοι και συναφή έσοδα"
                    },
                    {
                        value: "E3_564",
                        label: "Πιστωτικές συναλλαγματικές διαφορές"
                    },
                    {
                        value: "E3_565",
                        label: "Έσοδα συμμετοχών"
                    },
                    {
                        value: "E3_566",
                        label: "Κέρδη από διάθεση μη κυκλοφορούντων περιουσιακών στοιχείων"
                    },
                    {
                        value: "E3_567",
                        label: "Κέρδη από αναστροφή προβλέψεων και απομειώσεων"
                    },
                    {
                        value: "E3_568",
                        label: "Κέρδη από επιμέτρηση στην εύλογη αξία"
                    },
                    {
                        value: "E3_570",
                        label: "Ασυνήθη έσοδα και κέρδη"
                    },
                    {
                        value: "E3_595",
                        label: "Έξοδα σε ιδιοπαραγωγή"
                    },
                    {
                        value: "E3_880_001",
                        label: "Πωλήσεις Παγίων Χονδρικές"
                    },
                    {
                        value: "E3_880_002",
                        label: "Πωλήσεις παγίων Λιανικές"
                    },
                    {
                        value: "E3_880_003",
                        label: "Πωλήσεις παγίων Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_880_004",
                        label: "Πωλήσεις παγίων εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_881_001",
                        label: "Πωλήσεις για λογ/σμο Τρίτων Χονδρικές"
                    },
                    {
                        value: "E3_881_002",
                        label: "Πωλήσεις για λογ/σμο Τρίτων Λιανικές"
                    },
                    {
                        value: "E3_881_003",
                        label: "Πωλήσεις για λογ/σμο Τρίτων Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_881_004",
                        label: "Πωλήσεις για λογ/σμο Τρίτων Εξωτερικού Τρίτες χώρες"
                    }
                ]
            },
            {
                value: "category1_95",
                label: "Λοιπά Πληροφοριακά στοιχεία εσόδων (+)/ (-)",
                type: [
                    {
                        value: "null",
                        label: "Δεν ενημερώνει Ε3"
                    },
                ]
            }
        ]
    },
    {
        value: "11.4",
        label: "Πιστωτικό Στοιχ. Λιανικής",
        category: [
            {
                value: "category1_1",
                label: "Έσοδα από πώληση Εμπορευμάτων  (+)/ (-)",
                type: [
                    {
                        value: "E3_561_001",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Χονδρικές - Επιτηδευματιών"
                    },
                    {
                        value: "E3_561_002",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Χονδρικές βάσει άρθρου 39α παρ 5 του Κώδικα Φ.Π.Α. (Ν.2859/2000)"
                    },
                    {
                        value: "E3_561_003",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λιανικές - Ιδιωτική Πελατεία "
                    },
                    {
                        value: "E3_561_004",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λιανικές  βάσει άρθρου 39α παρ 5 του κώδικα ΦΠΑ (Ν2859/2000)"
                    },
                    {
                        value: "E3_561_005",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_561_006",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_561_007",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λοιπά"
                    },
                ]
            },
            {
                value: "category1_2",
                label: "Έσοδα από Πώληση Προϊόντων (+) / (-)",
                type: [
                    {
                        value: "E3_561_001",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Χονδρικές - Επιτηδευματιών"
                    },
                    {
                        value: "E3_561_002",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Χονδρικές βάσει άρθρου 39α παρ 5 του Κώδικα Φ.Π.Α. (Ν.2859/2000)"
                    },
                    {
                        value: "E3_561_003",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λιανικές - Ιδιωτική Πελατεία "
                    },
                    {
                        value: "E3_561_004",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λιανικές  βάσει άρθρου 39α παρ 5 του κώδικα ΦΠΑ (Ν2859/2000)"
                    },
                    {
                        value: "E3_561_005",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_561_006",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_561_007",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λοιπά"
                    },
                ]
            },
            {
                value: "category1_3",
                label: "Έσοδα από Παροχή Υπηρεσιών  (+)/ (-)",
                type: [
                    {
                        value: "E3_561_001",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Χονδρικές - Επιτηδευματιών"
                    },
                    {
                        value: "E3_561_002",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Χονδρικές βάσει άρθρου 39α παρ 5 του Κώδικα Φ.Π.Α. (Ν.2859/2000)"
                    },
                    {
                        value: "E3_561_003",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λιανικές - Ιδιωτική Πελατεία "
                    },
                    {
                        value: "E3_561_004",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λιανικές  βάσει άρθρου 39α παρ 5 του κώδικα ΦΠΑ (Ν2859/2000)"
                    },
                    {
                        value: "E3_561_005",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_561_006",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_561_007",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λοιπά"
                    },
                    {
                        value: "E3_563",
                        label: "Πιστωτικοί τόκοι και συναφή έσοδα"
                    },
                ]
            },
            {
                value: "category1_4",
                label: "Έσοδα από Πώληση Παγίων  (+)/ (-)",
                type: [
                    {
                        value: "E3_880_001",
                        label: "Πωλήσεις Παγίων Χονδρικές"
                    },
                    {
                        value: "E3_880_002",
                        label: "Πωλήσεις παγίων Λιανικές"
                    },
                    {
                        value: "E3_880_003",
                        label: "Πωλήσεις παγίων Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_880_004",
                        label: "Πωλήσεις παγίων εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_561_007",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λοιπά"
                    },
                ]
            },
            {
                value: "category1_5",
                label: "Λοιπά Έσοδα/Κέρδη (+)/ (-)",
                type: [
                    {
                        value: "E3_561_007",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λοιπά"
                    },
                    {
                        value: "E3_562",
                        label: "Λοιπά συνήθη έσοδα"
                    },
                    {
                        value: "E3_563",
                        label: "Πιστωτικοί τόκοι και συναφή έσοδα"
                    },
                    {
                        value: "E3_564",
                        label: "Πιστωτικές συναλλαγματικές διαφορές"
                    },
                    {
                        value: "E3_565",
                        label: "Έσοδα συμμετοχών"
                    },
                    {
                        value: "E3_566",
                        label: "Κέρδη από διάθεση μη κυκλοφορούντων περιουσιακών στοιχείων"
                    },
                    {
                        value: "E3_567",
                        label: "Κέρδη από αναστροφή προβλέψεων και απομειώσεων"
                    },
                    {
                        value: "E3_568",
                        label: "Κέρδη από επιμέτρηση στην εύλογη αξία"
                    },
                    {
                        value: "E3_570",
                        label: "Ασυνήθη έσοδα και κέρδη"
                    },
                    {
                        value: "E3_561_002",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Χονδρικές βάσει άρθρου 39α παρ 5 του Κώδικα Φ.Π.Α. (Ν.2859/2000)"
                    },
                ]
            },
            {
                value: "category1_6",
                label: "Αυτοπαραδόσεις / Ιδιοχρησιμοποιήσεις (+) / (-)",
                type: [
                    {
                        value: "E3_595",
                        label: "Έξοδα σε ιδιοπαραγωγή"
                    },
                ]
            },
            {
                value: "category1_7",
                label: "Έσοδα από λογ/σμο τρίτων (+)/ (-)",
                type: [
                    {
                        value: "E3_881_001",
                        label: "Πωλήσεις για λογ/σμο Τρίτων Χονδρικές"
                    },
                    {
                        value: "E3_881_002",
                        label: "Πωλήσεις για λογ/σμο Τρίτων Λιανικές"
                    },
                    {
                        value: "E3_881_003",
                        label: "Πωλήσεις για λογ/σμο Τρίτων Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_881_004",
                        label: "Πωλήσεις για λογ/σμο Τρίτων Εξωτερικού Τρίτες χώρες"
                    }
                ]
            },
            {
                value: "category1_8",
                label: "Έσοδα προηγούμενων χρήσεων (+)/ (-)",
                type: [
                    {
                        value: "E3_561_001",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Χονδρικές - Επιτηδευματιών"
                    },
                    {
                        value: "E3_561_002",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Χονδρικές βάσει άρθρου 39α παρ 5 του Κώδικα Φ.Π.Α. (Ν.2859/2000)"
                    },
                    {
                        value: "E3_561_003",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λιανικές - Ιδιωτική Πελατεία "
                    },
                    {
                        value: "E3_561_004",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λιανικές  βάσει άρθρου 39α παρ 5 του κώδικα ΦΠΑ (Ν2859/2000)"
                    },
                    {
                        value: "E3_561_005",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_561_006",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_561_007",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λοιπά"
                    },
                    {
                        value: "E3_562",
                        label: "Λοιπά συνήθη έσοδα"
                    },
                    {
                        value: "E3_563",
                        label: "Πιστωτικοί τόκοι και συναφή έσοδα"
                    },
                    {
                        value: "E3_564",
                        label: "Πιστωτικές συναλλαγματικές διαφορές"
                    },
                    {
                        value: "E3_565",
                        label: "Έσοδα συμμετοχών"
                    },
                    {
                        value: "E3_566",
                        label: "Κέρδη από διάθεση μη κυκλοφορούντων περιουσιακών στοιχείων"
                    },
                    {
                        value: "E3_567",
                        label: "Κέρδη από αναστροφή προβλέψεων και απομειώσεων"
                    },
                    {
                        value: "E3_568",
                        label: "Κέρδη από επιμέτρηση στην εύλογη αξία"
                    },
                    {
                        value: "E3_570",
                        label: "Ασυνήθη έσοδα και κέρδη"
                    },
                    {
                        value: "E3_595",
                        label: "Έξοδα σε ιδιοπαραγωγή"
                    },
                    {
                        value: "E3_880_001",
                        label: "Πωλήσεις Παγίων Χονδρικές"
                    },
                    {
                        value: "E3_880_002",
                        label: "Πωλήσεις παγίων Λιανικές"
                    },
                    {
                        value: "E3_880_003",
                        label: "Πωλήσεις παγίων Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_880_004",
                        label: "Πωλήσεις παγίων εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_881_001",
                        label: "Πωλήσεις για λογ/σμο Τρίτων Χονδρικές"
                    },
                    {
                        value: "E3_881_002",
                        label: "Πωλήσεις για λογ/σμο Τρίτων Λιανικές"
                    },
                    {
                        value: "E3_881_003",
                        label: "Πωλήσεις για λογ/σμο Τρίτων Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_881_004",
                        label: "Πωλήσεις για λογ/σμο Τρίτων Εξωτερικού Τρίτες χώρες"
                    }
                ]
            },
            {
                value: "category1_9",
                label: "Έσοδα επομένων χρήσεων (+)/ (-)",
                type: [
                    {
                        value: "E3_561_001",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Χονδρικές - Επιτηδευματιών"
                    },
                    {
                        value: "E3_561_002",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Χονδρικές βάσει άρθρου 39α παρ 5 του Κώδικα Φ.Π.Α. (Ν.2859/2000)"
                    },
                    {
                        value: "E3_561_003",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λιανικές - Ιδιωτική Πελατεία "
                    },
                    {
                        value: "E3_561_004",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λιανικές  βάσει άρθρου 39α παρ 5 του κώδικα ΦΠΑ (Ν2859/2000)"
                    },
                    {
                        value: "E3_561_005",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_561_006",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_561_007",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λοιπά"
                    },
                    {
                        value: "E3_562",
                        label: "Λοιπά συνήθη έσοδα"
                    },
                    {
                        value: "E3_563",
                        label: "Πιστωτικοί τόκοι και συναφή έσοδα"
                    },
                    {
                        value: "E3_564",
                        label: "Πιστωτικές συναλλαγματικές διαφορές"
                    },
                    {
                        value: "E3_565",
                        label: "Έσοδα συμμετοχών"
                    },
                    {
                        value: "E3_566",
                        label: "Κέρδη από διάθεση μη κυκλοφορούντων περιουσιακών στοιχείων"
                    },
                    {
                        value: "E3_567",
                        label: "Κέρδη από αναστροφή προβλέψεων και απομειώσεων"
                    },
                    {
                        value: "E3_568",
                        label: "Κέρδη από επιμέτρηση στην εύλογη αξία"
                    },
                    {
                        value: "E3_570",
                        label: "Ασυνήθη έσοδα και κέρδη"
                    },
                    {
                        value: "E3_595",
                        label: "Έξοδα σε ιδιοπαραγωγή"
                    },
                    {
                        value: "E3_880_001",
                        label: "Πωλήσεις Παγίων Χονδρικές"
                    },
                    {
                        value: "E3_880_002",
                        label: "Πωλήσεις παγίων Λιανικές"
                    },
                    {
                        value: "E3_880_003",
                        label: "Πωλήσεις παγίων Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_880_004",
                        label: "Πωλήσεις παγίων εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_881_001",
                        label: "Πωλήσεις για λογ/σμο Τρίτων Χονδρικές"
                    },
                    {
                        value: "E3_881_002",
                        label: "Πωλήσεις για λογ/σμο Τρίτων Λιανικές"
                    },
                    {
                        value: "E3_881_003",
                        label: "Πωλήσεις για λογ/σμο Τρίτων Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_881_004",
                        label: "Πωλήσεις για λογ/σμο Τρίτων Εξωτερικού Τρίτες χώρες"
                    }
                ]
            },
            {
                value: "category1_95",
                label: "Λοιπά Πληροφοριακά στοιχεία εσόδων (+)/ (-)",
                type: [
                    {
                        value: "null",
                        label: "Δεν ενημερώνει E3"
                    },
                ]
            }
        ]
    },
    {
        value: "11.5",
        label: "Απόδειξη Λιανικής πώλησης για Λογ/σμο Τρίτων",
        category: [
            {
                value: "category1_7",
                label: "Έσοδα από λογ/σμο τρίτων (+)/ (-)",
                type: [
                    {
                        value: "E3_881_002",
                        label: "Πωλήσεις για λογ/σμο Τρίτων Λιανικές"
                    },
                    {
                        value: "E3_881_003",
                        label: "Πωλήσεις για λογ/σμο Τρίτων Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_881_004",
                        label: "Πωλήσεις για λογ/σμο Τρίτων Εξωτερικού Τρίτες χώρες"
                    }
                ]
            },
            {
                value: "category1_95",
                label: "Λοιπά Πληροφοριακά στοιχεία εσόδων (+)/ (-)",
                type: [
                    {
                        value: "null",
                        label: "Δεν ενημερώνει E3"
                    },
                ]
            }
        ]
    },
    {
        value: "13.1",
        label: "Έξοδα - Αγορές Λιανικών Συναλλαγών ημεδαπής / αλλοδαπής",
        category: [
            {
                value: "category2_1",
                label: "Αγορές Εμπορευμάτων",
                type: [
                    {
                        value: "E3_102_001",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Χονδρικές"
                    },
                    {
                        value: "E3_102_002",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Λιανικές"
                    },
                    {
                        value: "E3_102_003",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Αγαθών του άρθρου 39α παρ.5 του Κώδικα Φ.Π.Α. (ν.2859/2000)"
                    },
                    {
                        value: "E3_102_006",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)Λοιπά"
                    },
                    {
                        value: "E3_313_001",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Χονδρικές"
                    },
                    {
                        value: "E3_313_002",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Λιανικές"
                    },
                    {
                        value: "E3_313_005",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Λοιπά"
                    },
                ]
            },
            {
                value: "category2_2",
                label: "Αγορές Α'-Β' Υλών",
                type: [
                    {
                        value: "E3_202_001",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Χονδρικές"
                    },
                    {
                        value: "E3_202_002",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λιανικές"
                    },
                    {
                        value: "E3_202_005",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά"
                    },
                    {
                        value: "E3_302_001",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Χονδρικές"
                    },
                    {
                        value: "E3_302_002",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λιανικές"
                    },
                    {
                        value: "E3_302_005",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά"
                    },
                ]
            },
            {
                value: "category2_3",
                label: "Λήψη Υπηρεσιών",
                type: [
                    {
                        value: "E3_585_001",
                        label: "Προμήθειες διαχείρισης ημεδαπής - αλλοδαπής (management fees)"
                    },
                    {
                        value: "E3_585_002",
                        label: "Δαπάνες από συνδεδεμένες επιχειρήσεις"
                    },
                    {
                        value: "E3_585_004",
                        label: "Δαπάνες για ενημερωτικές ημερίδες"
                    },
                    {
                        value: "E3_585_005",
                        label: "Έξοδα υποδοχής και φιλοξενίας"
                    },
                    {
                        value: "E3_585_006",
                        label: "Έξοδα ταξιδιών"
                    },
                    {
                        value: "E3_585_008",
                        label: "Έξοδα και προμήθειες παραγγελιοδόχου για λογαριασμό αγροτών"
                    },
                    {
                        value: "E3_585_009",
                        label: "Λοιπές Αμοιβές για υπηρεσίες ημεδαπής"
                    },
                    {
                        value: "E3_585_011",
                        label: "Ενέργεια"
                    },
                    {
                        value: "E3_585_012",
                        label: "Ύδρευση"
                    },
                    {
                        value: "E3_585_013",
                        label: "Τηλεπικοινωνίες"
                    },
                    {
                        value: "E3_585_014",
                        label: "Ενοίκια"
                    },
                    {
                        value: "E3_585_015",
                        label: "Διαφήμιση και προβολή"
                    },
                    {
                        value: "E3_585_016",
                        label: "Λοιπά έξοδα"
                    },
                    {
                        value: "E3_586",
                        label: "Χρεωστικοί τόκοι και συναφή έξοδα"
                    },
                    {
                        value: "E3_581_003",
                        label: "Παροχές σε εργαζόμενους/Λοιπές παροχές"
                    },
                ]
            },
            {
                value: "category2_4",
                label: "Γενικά Έξοδα με δικαίωμα έκπτωσης ΦΠΑ",
                type: [
                    {
                        value: "E3_585_001",
                        label: "Προμήθειες διαχείρισης ημεδαπής - αλλοδαπής (management fees)"
                    },
                    {
                        value: "E3_585_002",
                        label: "Δαπάνες από συνδεδεμένες επιχειρήσεις"
                    },
                    {
                        value: "E3_585_004",
                        label: "Δαπάνες για ενημερωτικές ημερίδες"
                    },
                    {
                        value: "E3_585_005",
                        label: "Έξοδα υποδοχής και φιλοξενίας"
                    },
                    {
                        value: "E3_585_006",
                        label: "Έξοδα ταξιδιών"
                    },
                    {
                        value: "E3_585_008",
                        label: "Έξοδα και προμήθειες παραγγελιοδόχου για λογαριασμό αγροτών"
                    },
                    {
                        value: "E3_585_009",
                        label: "Λοιπές Αμοιβές για υπηρεσίες ημεδαπής"
                    },
                    {
                        value: "E3_585_011",
                        label: "Ενέργεια"
                    },
                    {
                        value: "E3_585_012",
                        label: "Ύδρευση"
                    },
                    {
                        value: "E3_585_013",
                        label: "Τηλεπικοινωνίες"
                    },
                    {
                        value: "E3_585_014",
                        label: "Ενοίκια"
                    },
                    {
                        value: "E3_585_015",
                        label: "Διαφήμιση και προβολή"
                    },
                    {
                        value: "E3_585_016",
                        label: "Λοιπά έξοδα"
                    },
                    {
                        value: "E3_586",
                        label: "Χρεωστικοί τόκοι και συναφή έξοδα"
                    },
                    {
                        value: "E3_882_001",
                        label: "Αγορές ενσώματων παγίων χρήσης/Χονδρικές"
                    },
                    {
                        value: "E3_882_002",
                        label: "Αγορές ενσώματων παγίων χρήσης/Λιανικές"
                    },
                    {
                        value: "E3_883_001",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Χονδρικές"
                    },
                    {
                        value: "E3_883_002",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Λιανικές"
                    },
                    {
                        value: "E3_102_001",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Χονδρικές"
                    },
                    {
                        value: "E3_102_002",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Λιανικές"
                    },
                    {
                        value: "E3_102_003",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Αγαθών του άρθρου 39α παρ.5 του Κώδικα Φ.Π.Α. (ν.2859/2000)"
                    },
                    {
                        value: "E3_102_004",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_102_005",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_202_001",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Χονδρικές"
                    },
                    {
                        value: "E3_202_003",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_202_004",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_302_001",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Χονδρικές"
                    },
                    {
                        value: "E3_302_003",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_302_004",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_313_001",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Χονδρικές"
                    },
                    {
                        value: "E3_313_003",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_313_004",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_581_003",
                        label: "Παροχές σε εργαζόμενους/Λοιπές παροχές"
                    },
                ]
            },
            {
                value: "category2_5",
                label: "Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης ΦΠΑ",
                type: [
                    {
                        value: "E3_585_001",
                        label: "Προμήθειες διαχείρισης ημεδαπής - αλλοδαπής (management fees)"
                    },
                    {
                        value: "E3_585_002",
                        label: "Δαπάνες από συνδεδεμένες επιχειρήσεις"
                    },
                    {
                        value: "E3_585_004",
                        label: "Δαπάνες για ενημερωτικές ημερίδες"
                    },
                    {
                        value: "E3_585_005",
                        label: "Έξοδα υποδοχής και φιλοξενίας"
                    },
                    {
                        value: "E3_585_006",
                        label: "Έξοδα ταξιδιών"
                    },
                    {
                        value: "E3_585_008",
                        label: "Έξοδα και προμήθειες παραγγελιοδόχου για λογαριασμό αγροτών"
                    },
                    {
                        value: "E3_585_009",
                        label: "Λοιπές Αμοιβές για υπηρεσίες ημεδαπής"
                    },
                    {
                        value: "E3_585_011",
                        label: "Ενέργεια"
                    },
                    {
                        value: "E3_585_012",
                        label: "Ύδρευση"
                    },
                    {
                        value: "E3_585_013",
                        label: "Τηλεπικοινωνίες"
                    },
                    {
                        value: "E3_585_014",
                        label: "Ενοίκια"
                    },
                    {
                        value: "E3_585_015",
                        label: "Διαφήμιση και προβολή"
                    },
                    {
                        value: "E3_585_016",
                        label: "Λοιπά έξοδα"
                    },
                    {
                        value: "E3_586",
                        label: "Χρεωστικοί τόκοι και συναφή έξοδα"
                    },
                    {
                        value: "E3_882_001",
                        label: "Αγορές ενσώματων παγίων χρήσης/Χονδρικές"
                    },
                    {
                        value: "E3_882_002",
                        label: "Αγορές ενσώματων παγίων χρήσης/Λιανικές"
                    },
                    {
                        value: "E3_883_001",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Χονδρικές"
                    },
                    {
                        value: "E3_883_002",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Λιανικές"
                    },
                    {
                        value: "E3_102_001",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Χονδρικές"
                    },
                    {
                        value: "E3_102_002",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Λιανικές"
                    },
                    {
                        value: "E3_102_003",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Αγαθών του άρθρου 39α παρ.5 του Κώδικα Φ.Π.Α. (ν.2859/2000)"
                    },
                    {
                        value: "E3_102_004",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_102_005",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_202_001",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Χονδρικές"
                    },
                    {
                        value: "E3_202_003",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_202_004",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_302_001",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Χονδρικές"
                    },
                    {
                        value: "E3_302_003",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_302_004",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_313_001",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Χονδρικές"
                    },
                    {
                        value: "E3_313_003",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_313_004",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_581_003",
                        label: "Παροχές σε εργαζόμενους/Λοιπές παροχές"
                    },
                ]
            },
            {
                value: "category2_7",
                label: "Αγορές Παγίων",
                type: [
                    {
                        value: "E3_882_001",
                        label: "Αγορές ενσώματων παγίων χρήσης/Χονδρικές"
                    },
                    {
                        value: "E3_882_002",
                        label: "Αγορές ενσώματων παγίων χρήσης/Λιανικές"
                    },
                    {
                        value: "E3_883_001",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Χονδρικές"
                    },
                    {
                        value: "E3_883_002",
                        label: "Αγορές ενσώματων παγίων χρήσης/Εξωτερικού Ενδοκοινοτικές"
                    },
                ]
            },
            {
                value: "category2_10",
                label: "Έξοδα προηγούμενων χρήσεων",
                type: [
                    {
                        value: "E3_102_001",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Χονδρικές"
                    },
                    {
                        value: "E3_102_002",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Λιανικές"
                    },
                    {
                        value: "E3_102_003",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Αγαθών του άρθρου 39α παρ.5 του Κώδικα Φ.Π.Α. (ν.2859/2000)"
                    },
                    {
                        value: "E3_102_004",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_102_005",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_102_006",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)Λοιπά"
                    },
                    {
                        value: "E3_202_001",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Χονδρικές"
                    },
                    {
                        value: "E3_202_002",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λιανικές"
                    },
                    {
                        value: "E3_202_003",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_202_004",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_202_005",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά"
                    },
                    {
                        value: "E3_302_001",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Χονδρικές"
                    },
                    {
                        value: "E3_202_002",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λιανικές"
                    },
                    {
                        value: "E3_302_003",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_302_004",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_302_005",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά"
                    },
                    {
                        value: "E3_313_001",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Χονδρικές"
                    },
                    {
                        value: "E3_313_002",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Λιανικές"
                    },
                    {
                        value: "E3_313_003",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_313_004",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_313_005",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Λοιπά"
                    },
                    {
                        value: "E3_581_003",
                        label: "Παροχές σε εργαζόμενους/Λοιπές παροχές"
                    },
                    {
                        value: "E3_585_001",
                        label: "Προμήθειες διαχείρισης ημεδαπής - αλλοδαπής (management fees)"
                    },
                    {
                        value: "E3_585_002",
                        label: "Δαπάνες από συνδεδεμένες επιχειρήσεις"
                    },
                    {
                        value: "E3_585_004",
                        label: "Δαπάνες για ενημερωτικές ημερίδες"
                    },
                    {
                        value: "E3_585_005",
                        label: "Έξοδα υποδοχής και φιλοξενίας"
                    },
                    {
                        value: "E3_585_006",
                        label: "Έξοδα ταξιδιών"
                    },
                    {
                        value: "E3_585_008",
                        label: "Έξοδα και προμήθειες παραγγελιοδόχου για λογαριασμό αγροτών"
                    },
                    {
                        value: "E3_585_009",
                        label: "Λοιπές Αμοιβές για υπηρεσίες ημεδαπής"
                    },
                    {
                        value: "E3_585_011",
                        label: "Ενέργεια"
                    },
                    {
                        value: "E3_585_012",
                        label: "Ύδρευση"
                    },
                    {
                        value: "E3_585_013",
                        label: "Τηλεπικοινωνίες"
                    },
                    {
                        value: "E3_585_014",
                        label: "Ενοίκια"
                    },
                    {
                        value: "E3_585_015",
                        label: "Διαφήμιση και προβολή"
                    },
                    {
                        value: "E3_585_016",
                        label: "Λοιπά έξοδα"
                    },
                    {
                        value: "E3_586",
                        label: "Χρεωστικοί τόκοι και συναφή έξοδα"
                    },
                    {
                        value: "E3_882_001",
                        label: "Αγορές ενσώματων παγίων χρήσης/Χονδρικές"
                    },
                    {
                        value: "E3_882_002",
                        label: "Αγορές ενσώματων παγίων χρήσης/Λιανικές"
                    },
                    {
                        value: "E3_883_001",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Χονδρικές"
                    },
                    {
                        value: "E3_883_002",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Λιανικές"
                    },
                ]
            },
            {
                value: "category2_11",
                label: "Έξοδα επομένων χρήσεων",
                type: [
                    {
                        value: "E3_102_001",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Χονδρικές"
                    },
                    {
                        value: "E3_102_002",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Λιανικές"
                    },
                    {
                        value: "E3_102_003",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Αγαθών του άρθρου 39α παρ.5 του Κώδικα Φ.Π.Α. (ν.2859/2000)"
                    },
                    {
                        value: "E3_102_004",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_102_005",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_102_006",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)Λοιπά"
                    },
                    {
                        value: "E3_202_001",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Χονδρικές"
                    },
                    {
                        value: "E3_202_002",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λιανικές"
                    },
                    {
                        value: "E3_202_003",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_202_004",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_202_005",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά"
                    },
                    {
                        value: "E3_302_001",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Χονδρικές"
                    },
                    {
                        value: "E3_202_002",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λιανικές"
                    },
                    {
                        value: "E3_302_003",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_302_004",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_302_005",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά"
                    },
                    {
                        value: "E3_313_001",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Χονδρικές"
                    },
                    {
                        value: "E3_313_002",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Λιανικές"
                    },
                    {
                        value: "E3_313_003",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_313_004",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_313_005",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Λοιπά"
                    },
                    {
                        value: "E3_581_003",
                        label: "Παροχές σε εργαζόμενους/Λοιπές παροχές"
                    },
                    {
                        value: "E3_585_001",
                        label: "Προμήθειες διαχείρισης ημεδαπής - αλλοδαπής (management fees)"
                    },
                    {
                        value: "E3_585_002",
                        label: "Δαπάνες από συνδεδεμένες επιχειρήσεις"
                    },
                    {
                        value: "E3_585_004",
                        label: "Δαπάνες για ενημερωτικές ημερίδες"
                    },
                    {
                        value: "E3_585_005",
                        label: "Έξοδα υποδοχής και φιλοξενίας"
                    },
                    {
                        value: "E3_585_006",
                        label: "Έξοδα ταξιδιών"
                    },
                    {
                        value: "E3_585_008",
                        label: "Έξοδα και προμήθειες παραγγελιοδόχου για λογαριασμό αγροτών"
                    },
                    {
                        value: "E3_585_009",
                        label: "Λοιπές Αμοιβές για υπηρεσίες ημεδαπής"
                    },
                    {
                        value: "E3_585_011",
                        label: "Ενέργεια"
                    },
                    {
                        value: "E3_585_012",
                        label: "Ύδρευση"
                    },
                    {
                        value: "E3_585_013",
                        label: "Τηλεπικοινωνίες"
                    },
                    {
                        value: "E3_585_014",
                        label: "Ενοίκια"
                    },
                    {
                        value: "E3_585_015",
                        label: "Διαφήμιση και προβολή"
                    },
                    {
                        value: "E3_585_016",
                        label: "Λοιπά έξοδα"
                    },
                    {
                        value: "E3_586",
                        label: "Χρεωστικοί τόκοι και συναφή έξοδα"
                    },
                    {
                        value: "E3_882_001",
                        label: "Αγορές ενσώματων παγίων χρήσης/Χονδρικές"
                    },
                    {
                        value: "E3_882_002",
                        label: "Αγορές ενσώματων παγίων χρήσης/Λιανικές"
                    },
                    {
                        value: "E3_883_001",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Χονδρικές"
                    },
                    {
                        value: "E3_883_002",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Λιανικές"
                    },
                ]
            },
            {
                value: "category2_95",
                label: "Λοιπά Πληροφοριακά Στοιχεία Εξόδων",
                type: [
                    {
                        value: "null",
                        label: "Δεν ενημερώνει E3"
                    },
                ]
            }
        ]
    },
    {
        value: "13.2",
        label: "Παροχή Λιανικών Συναλλαγών ημεδαπής/αλλοδαπής",
        category: [
            {
                value: "category2_3",
                label: "Λήψη Υπηρεσιών",
                type: [
                    {
                        value: "E3_585_005",
                        label: "Έξοδα υποδοχής και φιλοξενίας"
                    },
                    {
                        value: "E3_585_006",
                        label: "Έξοδα ταξιδιών"
                    },
                    {
                        value: "E3_585_009",
                        label: "Λοιπές Αμοιβές για υπηρεσίες ημεδαπής"
                    },
                    {
                        value: "E3_585_011",
                        label: "Ενέργεια"
                    },
                    {
                        value: "E3_585_012",
                        label: "Ύδρευση"
                    },
                    {
                        value: "E3_585_013",
                        label: "Τηλεπικοινωνίες"
                    },
                    {
                        value: "E3_585_015",
                        label: "Διαφήμιση και προβολή"
                    },
                    {
                        value: "E3_585_016",
                        label: "Λοιπά έξοδα"
                    },
                    {
                        value: "E3_586",
                        label: "Χρεωστικοί τόκοι και συναφή έξοδα"
                    },
                    {
                        value: "E3_581_003",
                        label: "Παροχές σε εργαζόμενους/Λοιπές παροχές"
                    },
                ]
            },
            {
                value: "category2_4",
                label: "Γενικά Έξοδα με δικαίωμα έκπτωσης ΦΠΑ",
                type: [
                    {
                        value: "E3_585_005",
                        label: "Έξοδα υποδοχής και φιλοξενίας"
                    },
                    {
                        value: "E3_585_006",
                        label: "Έξοδα ταξιδιών"
                    },
                    {
                        value: "E3_585_009",
                        label: "Λοιπές Αμοιβές για υπηρεσίες ημεδαπής"
                    },
                    {
                        value: "E3_585_011",
                        label: "Ενέργεια"
                    },
                    {
                        value: "E3_585_012",
                        label: "Ύδρευση"
                    },
                    {
                        value: "E3_585_013",
                        label: "Τηλεπικοινωνίες"
                    },
                    {
                        value: "E3_585_015",
                        label: "Διαφήμιση και προβολή"
                    },
                    {
                        value: "E3_585_016",
                        label: "Λοιπά έξοδα"
                    },
                    {
                        value: "E3_586",
                        label: "Χρεωστικοί τόκοι και συναφή έξοδα"
                    },
                    {
                        value: "E3_882_002",
                        label: "Αγορές ενσώματων παγίων χρήσης/Λιανικές"
                    },
                    {
                        value: "E3_883_002",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Λιανικές"
                    },
                    {
                        value: "E3_102_002",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Λιανικές"
                    },
                    {
                        value: "E3_102_004",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_102_005",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_202_003",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_202_004",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_302_003",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_302_004",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_313_003",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_313_004",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_581_003",
                        label: "Παροχές σε εργαζόμενους/Λοιπές παροχές"
                    },
                ]
            },
            {
                value: "category2_5",
                label: "Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης ΦΠΑ",
                type: [
                    {
                        value: "E3_585_005",
                        label: "Έξοδα υποδοχής και φιλοξενίας"
                    },
                    {
                        value: "E3_585_006",
                        label: "Έξοδα ταξιδιών"
                    },
                    {
                        value: "E3_585_009",
                        label: "Λοιπές Αμοιβές για υπηρεσίες ημεδαπής"
                    },
                    {
                        value: "E3_585_011",
                        label: "Ενέργεια"
                    },
                    {
                        value: "E3_585_012",
                        label: "Ύδρευση"
                    },
                    {
                        value: "E3_585_013",
                        label: "Τηλεπικοινωνίες"
                    },
                    {
                        value: "E3_585_015",
                        label: "Διαφήμιση και προβολή"
                    },
                    {
                        value: "E3_585_016",
                        label: "Λοιπά έξοδα"
                    },
                    {
                        value: "E3_586",
                        label: "Χρεωστικοί τόκοι και συναφή έξοδα"
                    },
                    {
                        value: "E3_882_002",
                        label: "Αγορές ενσώματων παγίων χρήσης/Λιανικές"
                    },
                    {
                        value: "E3_883_002",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Λιανικές"
                    },
                    {
                        value: "E3_102_002",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Λιανικές"
                    },
                    {
                        value: "E3_102_004",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_102_005",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_202_003",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_202_004",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_302_003",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_302_004",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_313_003",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_313_004",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_581_003",
                        label: "Παροχές σε εργαζόμενους/Λοιπές παροχές"
                    },
                ]
            },
            {
                value: "category2_10",
                label: "Έξοδα προηγούμενων χρήσεων",
                type: [
                    {
                        value: "E3_102_002",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Λιανικές"
                    },
                    {
                        value: "E3_102_004",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_102_005",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_202_003",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_202_004",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_302_003",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_302_004",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_313_003",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_313_004",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_581_003",
                        label: "Παροχές σε εργαζόμενους/Λοιπές παροχές"
                    },
                    {
                        value: "E3_585_005",
                        label: "Έξοδα υποδοχής και φιλοξενίας"
                    },
                    {
                        value: "E3_585_006",
                        label: "Έξοδα ταξιδιών"
                    },
                    {
                        value: "E3_585_009",
                        label: "Λοιπές Αμοιβές για υπηρεσίες ημεδαπής"
                    },
                    {
                        value: "E3_585_011",
                        label: "Ενέργεια"
                    },
                    {
                        value: "E3_585_012",
                        label: "Ύδρευση"
                    },
                    {
                        value: "E3_585_013",
                        label: "Τηλεπικοινωνίες"
                    },
                    {
                        value: "E3_585_015",
                        label: "Διαφήμιση και προβολή"
                    },
                    {
                        value: "E3_585_016",
                        label: "Λοιπά έξοδα"
                    },
                    {
                        value: "E3_586",
                        label: "Χρεωστικοί τόκοι και συναφή έξοδα"
                    },
                    {
                        value: "E3_882_002",
                        label: "Αγορές ενσώματων παγίων χρήσης/Λιανικές"
                    },
                    {
                        value: "E3_883_002",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Λιανικές"
                    },
                ]
            },
            {
                value: "category2_11",
                label: "Έξοδα επομένων χρήσεων",
                type: [
                    {
                        value: "E3_102_002",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Λιανικές"
                    },
                    {
                        value: "E3_102_004",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_102_005",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_202_003",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_202_004",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_302_003",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_302_004",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_313_003",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_313_004",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_581_003",
                        label: "Παροχές σε εργαζόμενους/Λοιπές παροχές"
                    },
                    {
                        value: "E3_585_005",
                        label: "Έξοδα υποδοχής και φιλοξενίας"
                    },
                    {
                        value: "E3_585_006",
                        label: "Έξοδα ταξιδιών"
                    },
                    {
                        value: "E3_585_009",
                        label: "Λοιπές Αμοιβές για υπηρεσίες ημεδαπής"
                    },
                    {
                        value: "E3_585_011",
                        label: "Ενέργεια"
                    },
                    {
                        value: "E3_585_012",
                        label: "Ύδρευση"
                    },
                    {
                        value: "E3_585_013",
                        label: "Τηλεπικοινωνίες"
                    },
                    {
                        value: "E3_585_015",
                        label: "Διαφήμιση και προβολή"
                    },
                    {
                        value: "E3_585_016",
                        label: "Λοιπά έξοδα"
                    },
                    {
                        value: "E3_586",
                        label: "Χρεωστικοί τόκοι και συναφή έξοδα"
                    },
                    {
                        value: "E3_882_002",
                        label: "Αγορές ενσώματων παγίων χρήσης/Λιανικές"
                    },
                    {
                        value: "E3_883_002",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Λιανικές"
                    },
                ]
            },
            {
                value: "category2_95",
                label: "Λοιπά Πληροφοριακά Στοιχεία Εξόδων",
                type: [
                    {
                        value: "null",
                        label: "Δεν ενημερώνει E3"
                    },
                ]
            }
        ]
    },
    {
        value: "13.3",
        label: "Κοινόχρηστα",
        category: [
            {
                value: "category2_3",
                label: "Λήψη Υπηρεσιών",
                type: [
                    {
                        value: "E3_585_016",
                        label: "Λοιπά έξοδα"
                    },
                ]
            },
            {
                value: "category2_5",
                label: "Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης ΦΠΑ",
                type: [
                    {
                        value: "E3_585_016",
                        label: "Λοιπά έξοδα"
                    },
                ]
            },
            {
                value: "category2_10",
                label: "Έξοδα προηγούμενων χρήσεων",
                type: [
                    {
                        value: "E3_585_016",
                        label: "Λοιπά έξοδα"
                    },
                ]
            },
            {
                value: "category2_11",
                label: "Έξοδα επομένων χρήσεων",
                type: [
                    {
                        value: "E3_585_016",
                        label: "Λοιπά έξοδα"
                    },
                ]
            },
            {
                value: "category2_95",
                label: "Λοιπά Πληροφοριακά Στοιχεία Εξόδων (-) / (+)",
                type: [
                    {
                        value: "null",
                        label: "Δεν ενημερώνει E3"
                    },
                ]
            }
        ]
    },
    {
        value: "13.4",
        label: "Συνδρομές",
        category: [
            {
                value: "category2_3",
                label: "Λήψη Υπηρεσιών",
                type: [
                    {
                        value: "E3_585_016",
                        label: "Λοιπά έξοδα"
                    },
                ]
            },
            {
                value: "category2_5",
                label: "Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης ΦΠΑ",
                type: [
                    {
                        value: "E3_585_016",
                        label: "Λοιπά έξοδα"
                    },
                ]
            },
            {
                value: "category2_10",
                label: "Έξοδα προηγούμενων χρήσεων",
                type: [
                    {
                        value: "E3_585_016",
                        label: "Λοιπά έξοδα"
                    },
                ]
            },
            {
                value: "category2_11",
                label: "Έξοδα επομένων χρήσεων",
                type: [
                    {
                        value: "E3_585_016",
                        label: "Λοιπά έξοδα"
                    },
                ]
            },
            {
                value: "category2_95",
                label: "Λοιπά Πληροφοριακά Στοιχεία Εξόδων (-) / (+)",
                type: [
                    {
                        value: "null",
                        label: "Δεν ενημερώνει E3"
                    },
                ]
            }
        ]
    },
    {
        value: "13.30",
        label: "Παραστατικά Οντότητας ως Αναγράφονται από την ίδια (Δυναμικό)",
        category: [
            {
                value: "category2_3",
                label: "Λήψη Υπηρεσιών",
                type: [
                    {
                        value: "E3_585_016",
                        label: "Λοιπά έξοδα"
                    },
                    {
                        value: "E3_586",
                        label: "Χρεωστικοί τόκοι και συναφή έξοδα"
                    },
                ]
            },
            {
                value: "category2_5",
                label: "Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης ΦΠΑ",
                type: [
                    {
                        value: "E3_585_016",
                        label: "Λοιπά έξοδα"
                    },
                    {
                        value: "E3_586",
                        label: "Χρεωστικοί τόκοι και συναφή έξοδα"
                    },
                    {
                        value: "E3_588",
                        label: "Ασυνήθη έξοδα, ζημιές και πρόστιμα"
                    },
                ]
            },
            {
                value: "category2_10",
                label: "Έξοδα προηγούμενων χρήσεων",
                type: [
                    {
                        value: "E3_585_016",
                        label: "Λοιπά έξοδα"
                    },
                    {
                        value: "E3_586",
                        label: "Χρεωστικοί τόκοι και συναφή έξοδα"
                    },
                    {
                        value: "E3_588",
                        label: "Ασυνήθη έξοδα, ζημιές και πρόστιμα"
                    },
                ]
            },
            {
                value: "category2_11",
                label: "Έξοδα επομένων χρήσεων",
                type: [
                    {
                        value: "E3_585_016",
                        label: "Λοιπά έξοδα"
                    },
                    {
                        value: "E3_586",
                        label: "Χρεωστικοί τόκοι και συναφή έξοδα"
                    },
                    {
                        value: "E3_588",
                        label: "Ασυνήθη έξοδα, ζημιές και πρόστιμα"
                    },
                ]
            },
            {
                value: "category2_95",
                label: "Λοιπά Πληροφοριακά Στοιχεία Εξόδων (-) / (+)",
                type: [
                    {
                        value: "null",
                        label: "Δεν ενημερώνει E3"
                    },
                ]
            }
        ]
    },
    {
        value: "13.31",
        label: "Πιστωτικό Στοιχ. Λιανικής ημεδαπής/αλλοδαπής",
        category: [
            {
                value: "category2_1",
                label: "Αγορές Εμπορευμάτων",
                type: [
                    {
                        value: "E3_102_001",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Χονδρικές"
                    },
                    {
                        value: "E3_102_002",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Λιανικές"
                    },
                    {
                        value: "E3_102_003",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Αγαθών του άρθρου 39α παρ.5 του Κώδικα Φ.Π.Α. (ν.2859/2000)"
                    },
                    {
                        value: "E3_102_006",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)Λοιπά"
                    },
                    {
                        value: "E3_313_001",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Χονδρικές"
                    },
                    {
                        value: "E3_313_002",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Λιανικές"
                    },
                    {
                        value: "E3_313_005",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Λοιπά"
                    },
                ]
            },
            {
                value: "category2_2",
                label: "Αγορές Α'-Β' Υλών",
                type: [
                    {
                        value: "E3_202_001",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Χονδρικές"
                    },
                    {
                        value: "E3_202_002",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λιανικές"
                    },
                    {
                        value: "E3_202_005",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά"
                    },
                    {
                        value: "E3_302_001",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Χονδρικές"
                    },
                    {
                        value: "E3_302_002",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λιανικές"
                    },
                    {
                        value: "E3_302_005",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά"
                    },
                ]
            },
            {
                value: "category2_3",
                label: "Λήψη Υπηρεσιών",
                type: [
                    {
                        value: "E3_585_001",
                        label: "Προμήθειες διαχείρισης ημεδαπής - αλλοδαπής (management fees)"
                    },
                    {
                        value: "E3_585_002",
                        label: "Δαπάνες από συνδεδεμένες επιχειρήσεις"
                    },
                    {
                        value: "E3_585_004",
                        label: "Δαπάνες για ενημερωτικές ημερίδες"
                    },
                    {
                        value: "E3_585_005",
                        label: "Έξοδα υποδοχής και φιλοξενίας"
                    },
                    {
                        value: "E3_585_006",
                        label: "Έξοδα ταξιδιών"
                    },
                    {
                        value: "E3_585_008",
                        label: "Έξοδα και προμήθειες παραγγελιοδόχου για λογαριασμό αγροτών"
                    },
                    {
                        value: "E3_585_009",
                        label: "Λοιπές Αμοιβές για υπηρεσίες ημεδαπής"
                    },
                    {
                        value: "E3_585_011",
                        label: "Ενέργεια"
                    },
                    {
                        value: "E3_585_012",
                        label: "Ύδρευση"
                    },
                    {
                        value: "E3_585_013",
                        label: "Τηλεπικοινωνίες"
                    },
                    {
                        value: "E3_585_014",
                        label: "Ενοίκια"
                    },
                    {
                        value: "E3_585_015",
                        label: "Διαφήμιση και προβολή"
                    },
                    {
                        value: "E3_585_016",
                        label: "Λοιπά έξοδα"
                    },
                    {
                        value: "E3_586",
                        label: "Χρεωστικοί τόκοι και συναφή έξοδα"
                    },
                    {
                        value: "E3_581_003",
                        label: "Παροχές σε εργαζόμενους/Λοιπές παροχές"
                    },
                ]
            },
            {
                value: "category2_4",
                label: "Γενικά Έξοδα με δικαίωμα έκπτωσης ΦΠΑ",
                type: [
                    {
                        value: "E3_585_001",
                        label: "Προμήθειες διαχείρισης ημεδαπής - αλλοδαπής (management fees)"
                    },
                    {
                        value: "E3_585_002",
                        label: "Δαπάνες από συνδεδεμένες επιχειρήσεις"
                    },
                    {
                        value: "E3_585_004",
                        label: "Δαπάνες για ενημερωτικές ημερίδες"
                    },
                    {
                        value: "E3_585_005",
                        label: "Έξοδα υποδοχής και φιλοξενίας"
                    },
                    {
                        value: "E3_585_006",
                        label: "Έξοδα ταξιδιών"
                    },
                    {
                        value: "E3_585_008",
                        label: "Έξοδα και προμήθειες παραγγελιοδόχου για λογαριασμό αγροτών"
                    },
                    {
                        value: "E3_585_009",
                        label: "Λοιπές Αμοιβές για υπηρεσίες ημεδαπής"
                    },
                    {
                        value: "E3_585_011",
                        label: "Ενέργεια"
                    },
                    {
                        value: "E3_585_012",
                        label: "Ύδρευση"
                    },
                    {
                        value: "E3_585_013",
                        label: "Τηλεπικοινωνίες"
                    },
                    {
                        value: "E3_585_014",
                        label: "Ενοίκια"
                    },
                    {
                        value: "E3_585_015",
                        label: "Διαφήμιση και προβολή"
                    },
                    {
                        value: "E3_585_016",
                        label: "Λοιπά έξοδα"
                    },
                    {
                        value: "E3_586",
                        label: "Χρεωστικοί τόκοι και συναφή έξοδα"
                    },
                    {
                        value: "E3_882_001",
                        label: "Αγορές ενσώματων παγίων χρήσης/Χονδρικές"
                    },
                    {
                        value: "E3_882_002",
                        label: "Αγορές ενσώματων παγίων χρήσης/Λιανικές"
                    },
                    {
                        value: "E3_883_001",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Χονδρικές"
                    },
                    {
                        value: "E3_883_002",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Λιανικές"
                    },
                    {
                        value: "E3_102_001",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Χονδρικές"
                    },
                    {
                        value: "E3_102_002",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Λιανικές"
                    },
                    {
                        value: "E3_102_003",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Αγαθών του άρθρου 39α παρ.5 του Κώδικα Φ.Π.Α. (ν.2859/2000)"
                    },
                    {
                        value: "E3_102_004",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_102_005",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_202_001",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Χονδρικές"
                    },
                    {
                        value: "E3_202_003",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_202_004",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_302_001",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Χονδρικές"
                    },
                    {
                        value: "E3_302_003",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_302_004",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_313_001",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Χονδρικές"
                    },
                    {
                        value: "E3_313_003",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_313_004",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_581_003",
                        label: "Παροχές σε εργαζόμενους/Λοιπές παροχές"
                    },
                ]
            },
            {
                value: "category2_5",
                label: "Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης ΦΠΑ",
                type: [
                    {
                        value: "E3_585_001",
                        label: "Προμήθειες διαχείρισης ημεδαπής - αλλοδαπής (management fees)"
                    },
                    {
                        value: "E3_585_002",
                        label: "Δαπάνες από συνδεδεμένες επιχειρήσεις"
                    },
                    {
                        value: "E3_585_004",
                        label: "Δαπάνες για ενημερωτικές ημερίδες"
                    },
                    {
                        value: "E3_585_005",
                        label: "Έξοδα υποδοχής και φιλοξενίας"
                    },
                    {
                        value: "E3_585_006",
                        label: "Έξοδα ταξιδιών"
                    },
                    {
                        value: "E3_585_008",
                        label: "Έξοδα και προμήθειες παραγγελιοδόχου για λογαριασμό αγροτών"
                    },
                    {
                        value: "E3_585_009",
                        label: "Λοιπές Αμοιβές για υπηρεσίες ημεδαπής"
                    },
                    {
                        value: "E3_585_011",
                        label: "Ενέργεια"
                    },
                    {
                        value: "E3_585_012",
                        label: "Ύδρευση"
                    },
                    {
                        value: "E3_585_013",
                        label: "Τηλεπικοινωνίες"
                    },
                    {
                        value: "E3_585_014",
                        label: "Ενοίκια"
                    },
                    {
                        value: "E3_585_015",
                        label: "Διαφήμιση και προβολή"
                    },
                    {
                        value: "E3_585_016",
                        label: "Λοιπά έξοδα"
                    },
                    {
                        value: "E3_586",
                        label: "Χρεωστικοί τόκοι και συναφή έξοδα"
                    },
                    {
                        value: "E3_882_001",
                        label: "Αγορές ενσώματων παγίων χρήσης/Χονδρικές"
                    },
                    {
                        value: "E3_882_002",
                        label: "Αγορές ενσώματων παγίων χρήσης/Λιανικές"
                    },
                    {
                        value: "E3_883_001",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Χονδρικές"
                    },
                    {
                        value: "E3_883_002",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Λιανικές"
                    },
                    {
                        value: "E3_102_001",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Χονδρικές"
                    },
                    {
                        value: "E3_102_002",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Λιανικές"
                    },
                    {
                        value: "E3_102_003",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Αγαθών του άρθρου 39α παρ.5 του Κώδικα Φ.Π.Α. (ν.2859/2000)"
                    },
                    {
                        value: "E3_102_004",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_102_005",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_202_001",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Χονδρικές"
                    },
                    {
                        value: "E3_202_003",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_202_004",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_302_001",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Χονδρικές"
                    },
                    {
                        value: "E3_302_003",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_302_004",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_313_001",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Χονδρικές"
                    },
                    {
                        value: "E3_313_003",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_313_004",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_581_003",
                        label: "Παροχές σε εργαζόμενους/Λοιπές παροχές"
                    },
                ]
            },
            {
                value: "category2_7",
                label: "Αγορές Παγίων",
                type: [
                    {
                        value: "E3_882_001",
                        label: "Αγορές ενσώματων παγίων χρήσης/Χονδρικές"
                    },
                    {
                        value: "E3_882_002",
                        label: "Αγορές ενσώματων παγίων χρήσης/Λιανικές"
                    },
                    {
                        value: "E3_883_001",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Χονδρικές"
                    },
                    {
                        value: "E3_883_002",
                        label: "Αγορές ενσώματων παγίων χρήσης/Εξωτερικού Ενδοκοινοτικές"
                    },
                ]
            },
            {
                value: "category2_10",
                label: "Έξοδα προηγούμενων χρήσεων",
                type: [
                    {
                        value: "E3_102_001",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Χονδρικές"
                    },
                    {
                        value: "E3_102_002",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Λιανικές"
                    },
                    {
                        value: "E3_102_003",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Αγαθών του άρθρου 39α παρ.5 του Κώδικα Φ.Π.Α. (ν.2859/2000)"
                    },
                    {
                        value: "E3_102_004",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_102_005",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_102_006",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)Λοιπά"
                    },
                    {
                        value: "E3_202_001",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Χονδρικές"
                    },
                    {
                        value: "E3_202_002",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λιανικές"
                    },
                    {
                        value: "E3_202_003",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_202_004",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_202_005",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά"
                    },
                    {
                        value: "E3_302_001",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Χονδρικές"
                    },
                    {
                        value: "E3_202_002",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λιανικές"
                    },
                    {
                        value: "E3_302_003",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_302_004",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_302_005",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά"
                    },
                    {
                        value: "E3_313_001",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Χονδρικές"
                    },
                    {
                        value: "E3_313_002",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Λιανικές"
                    },
                    {
                        value: "E3_313_003",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_313_004",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_313_005",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Λοιπά"
                    },
                    {
                        value: "E3_581_003",
                        label: "Παροχές σε εργαζόμενους/Λοιπές παροχές"
                    },
                    {
                        value: "E3_585_001",
                        label: "Προμήθειες διαχείρισης ημεδαπής - αλλοδαπής (management fees)"
                    },
                    {
                        value: "E3_585_002",
                        label: "Δαπάνες από συνδεδεμένες επιχειρήσεις"
                    },
                    {
                        value: "E3_585_004",
                        label: "Δαπάνες για ενημερωτικές ημερίδες"
                    },
                    {
                        value: "E3_585_005",
                        label: "Έξοδα υποδοχής και φιλοξενίας"
                    },
                    {
                        value: "E3_585_006",
                        label: "Έξοδα ταξιδιών"
                    },
                    {
                        value: "E3_585_008",
                        label: "Έξοδα και προμήθειες παραγγελιοδόχου για λογαριασμό αγροτών"
                    },
                    {
                        value: "E3_585_009",
                        label: "Λοιπές Αμοιβές για υπηρεσίες ημεδαπής"
                    },
                    {
                        value: "E3_585_011",
                        label: "Ενέργεια"
                    },
                    {
                        value: "E3_585_012",
                        label: "Ύδρευση"
                    },
                    {
                        value: "E3_585_013",
                        label: "Τηλεπικοινωνίες"
                    },
                    {
                        value: "E3_585_014",
                        label: "Ενοίκια"
                    },
                    {
                        value: "E3_585_015",
                        label: "Διαφήμιση και προβολή"
                    },
                    {
                        value: "E3_585_016",
                        label: "Λοιπά έξοδα"
                    },
                    {
                        value: "E3_586",
                        label: "Χρεωστικοί τόκοι και συναφή έξοδα"
                    },
                    {
                        value: "E3_882_001",
                        label: "Αγορές ενσώματων παγίων χρήσης/Χονδρικές"
                    },
                    {
                        value: "E3_882_002",
                        label: "Αγορές ενσώματων παγίων χρήσης/Λιανικές"
                    },
                    {
                        value: "E3_883_001",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Χονδρικές"
                    },
                    {
                        value: "E3_883_002",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Λιανικές"
                    },
                ]
            },
            {
                value: "category2_11",
                label: "Έξοδα επομένων χρήσεων",
                type: [
                    {
                        value: "E3_102_001",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Χονδρικές"
                    },
                    {
                        value: "E3_102_002",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Λιανικές"
                    },
                    {
                        value: "E3_102_003",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Αγαθών του άρθρου 39α παρ.5 του Κώδικα Φ.Π.Α. (ν.2859/2000)"
                    },
                    {
                        value: "E3_102_004",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_102_005",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_102_006",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)Λοιπά"
                    },
                    {
                        value: "E3_202_001",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Χονδρικές"
                    },
                    {
                        value: "E3_202_002",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λιανικές"
                    },
                    {
                        value: "E3_202_003",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_202_004",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_202_005",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά"
                    },
                    {
                        value: "E3_302_001",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Χονδρικές"
                    },
                    {
                        value: "E3_202_002",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λιανικές"
                    },
                    {
                        value: "E3_302_003",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_302_004",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_302_005",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά"
                    },
                    {
                        value: "E3_313_001",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Χονδρικές"
                    },
                    {
                        value: "E3_313_002",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Λιανικές"
                    },
                    {
                        value: "E3_313_003",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_313_004",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_313_005",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Λοιπά"
                    },
                    {
                        value: "E3_581_003",
                        label: "Παροχές σε εργαζόμενους/Λοιπές παροχές"
                    },
                    {
                        value: "E3_585_001",
                        label: "Προμήθειες διαχείρισης ημεδαπής - αλλοδαπής (management fees)"
                    },
                    {
                        value: "E3_585_002",
                        label: "Δαπάνες από συνδεδεμένες επιχειρήσεις"
                    },
                    {
                        value: "E3_585_004",
                        label: "Δαπάνες για ενημερωτικές ημερίδες"
                    },
                    {
                        value: "E3_585_005",
                        label: "Έξοδα υποδοχής και φιλοξενίας"
                    },
                    {
                        value: "E3_585_006",
                        label: "Έξοδα ταξιδιών"
                    },
                    {
                        value: "E3_585_008",
                        label: "Έξοδα και προμήθειες παραγγελιοδόχου για λογαριασμό αγροτών"
                    },
                    {
                        value: "E3_585_009",
                        label: "Λοιπές Αμοιβές για υπηρεσίες ημεδαπής"
                    },
                    {
                        value: "E3_585_011",
                        label: "Ενέργεια"
                    },
                    {
                        value: "E3_585_012",
                        label: "Ύδρευση"
                    },
                    {
                        value: "E3_585_013",
                        label: "Τηλεπικοινωνίες"
                    },
                    {
                        value: "E3_585_014",
                        label: "Ενοίκια"
                    },
                    {
                        value: "E3_585_015",
                        label: "Διαφήμιση και προβολή"
                    },
                    {
                        value: "E3_585_016",
                        label: "Λοιπά έξοδα"
                    },
                    {
                        value: "E3_586",
                        label: "Χρεωστικοί τόκοι και συναφή έξοδα"
                    },
                    {
                        value: "E3_882_001",
                        label: "Αγορές ενσώματων παγίων χρήσης/Χονδρικές"
                    },
                    {
                        value: "E3_882_002",
                        label: "Αγορές ενσώματων παγίων χρήσης/Λιανικές"
                    },
                    {
                        value: "E3_883_001",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Χονδρικές"
                    },
                    {
                        value: "E3_883_002",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Λιανικές"
                    },
                ]
            },
            {
                value: "category2_95",
                label: "Λοιπά Πληροφοριακά Στοιχεία Εξόδων (-) / (+)",
                type: [
                    {
                        value: "null",
                        label: "Δεν ενημερώνει E3"
                    },
                ]
            }
        ]
    },
    {
        value: "14.1",
        label: "Τιμολόγιο/Ενδοκοινοτικές Αποκτήσεις",
        category: [
            {
                value: "category2_1",
                label: "Αγορές Εμπορευμάτων",
                type: [
                    {
                        value: "E3_102_004",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_102_006",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)Λοιπά"
                    },
                    {
                        value: "E3_313_003",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_313_005",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Λοιπά"
                    }
                ]
            },
            {
                value: "category2_2",
                label: "Αγορές Α'-Β' Υλών",
                type: [
                    {
                        value: "E3_202_003",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_202_005",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά"
                    },
                    {
                        value: "E3_302_003",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_302_005",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά"
                    }
                ]
            },
            {
                value: "category2_4",
                label: "Γενικά Έξοδα με δικαίωμα έκπτωσης ΦΠΑ",
                type: [
                    {
                        value: "E3_585_002",
                        label: "Δαπάνες από συνδεδεμένες επιχειρήσεις"
                    },
                    {
                        value: "E3_585_003",
                        label: "Δαπάνες από μη συνεργαζόμενα κράτη ή από κράτη με προνομιακό φορολογικό καθεστώς"
                    },
                    {
                        value: "E3_585_016",
                        label: "Λοιπά έξοδα"
                    },
                    {
                        value: "E3_882_003",
                        label: "Αγορές ενσώματων παγίων χρήσης/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_883_003",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_581_003",
                        label: "Παροχές σε εργαζόμενους/Λοιπές παροχές"
                    }
                ]
            },
            {
                value: "category2_5",
                label: "Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης ΦΠΑ",
                type: [
                    {
                        value: "E3_585_002",
                        label: "Δαπάνες από συνδεδεμένες επιχειρήσεις"
                    },
                    {
                        value: "E3_585_003",
                        label: "Δαπάνες από μη συνεργαζόμενα κράτη ή από κράτη με προνομιακό φορολογικό καθεστώς"
                    },
                    {
                        value: "E3_585_016",
                        label: "Λοιπά έξοδα"
                    },
                    {
                        value: "E3_882_003",
                        label: "Αγορές ενσώματων παγίων χρήσης/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_883_003",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_581_003",
                        label: "Παροχές σε εργαζόμενους/Λοιπές παροχές"
                    }
                ]
            },
            {
                value: "category2_7",
                label: "Αγορές Παγίων",
                type: [
                    {
                        value: "E3_882_003",
                        label: "Αγορές ενσώματων παγίων χρήσης/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_883_003",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Εξωτερικού Ενδοκοινοτικές"
                    },
                ]
            },
            {
                value: "category2_10",
                label: "Έξοδα προηγούμενων χρήσεων",
                type: [
                    {
                        value: "E3_882_003",
                        label: "Αγορές ενσώματων παγίων χρήσης/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_883_003",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_102_004",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_102_006",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)Λοιπά"
                    },
                    {
                        value: "E3_313_003",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_313_005",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Λοιπά"
                    }, {
                        value: "E3_202_003",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_202_005",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά"
                    },
                    {
                        value: "E3_302_003",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_302_005",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά"
                    },
                    {
                        value: "E3_585_002",
                        label: "Δαπάνες από συνδεδεμένες επιχειρήσεις"
                    },
                    {
                        value: "E3_585_003",
                        label: "Δαπάνες από μη συνεργαζόμενα κράτη ή από κράτη με προνομιακό φορολογικό καθεστώς"
                    },
                    {
                        value: "E3_585_016",
                        label: "Λοιπά έξοδα"
                    },
                    {
                        value: "E3_882_003",
                        label: "Αγορές ενσώματων παγίων χρήσης/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_883_003",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_581_003",
                        label: "Παροχές σε εργαζόμενους/Λοιπές παροχές"
                    }
                ]
            },
            {
                value: "category2_11",
                label: "Έξοδα επομένων χρήσεων",
                type: [
                    {
                        value: "E3_882_003",
                        label: "Αγορές ενσώματων παγίων χρήσης/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_883_003",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_102_004",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_102_006",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)Λοιπά"
                    },
                    {
                        value: "E3_313_003",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_313_005",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Λοιπά"
                    }, {
                        value: "E3_202_003",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_202_005",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά"
                    },
                    {
                        value: "E3_302_003",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_302_005",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά"
                    },
                    {
                        value: "E3_585_002",
                        label: "Δαπάνες από συνδεδεμένες επιχειρήσεις"
                    },
                    {
                        value: "E3_585_003",
                        label: "Δαπάνες από μη συνεργαζόμενα κράτη ή από κράτη με προνομιακό φορολογικό καθεστώς"
                    },
                    {
                        value: "E3_585_016",
                        label: "Λοιπά έξοδα"
                    },
                    {
                        value: "E3_882_003",
                        label: "Αγορές ενσώματων παγίων χρήσης/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_883_003",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_581_003",
                        label: "Παροχές σε εργαζόμενους/Λοιπές παροχές"
                    }
                ]
            },
            {
                value: "category2_95",
                label: "Λοιπά Πληροφοριακά Στοιχεία Εξόδων (-) / (+)",
                type: [
                    {
                        value: "null",
                        label: "Δεν ενημερώνει E3"
                    },
                ]
            }
        ]
    },
    {
        value: "14.2",
        label: "Τιμολόγιο Αποκτήσεις Τρίτων Χωρών",
        category: [
            {
                value: "category2_1",
                label: "Αγορές Εμπορευμάτων",
                type: [
                    {
                        value: "E3_102_005",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_102_006",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)Λοιπά"
                    },
                    {
                        value: "E3_313_004",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_313_005",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Λοιπά"
                    }
                ]
            },
            {
                value: "category2_2",
                label: "Αγορές Α'-Β' Υλών",
                type: [
                    {
                        value: "E3_202_004",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_202_005",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά"
                    },
                    {
                        value: "E3_302_004",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_302_005",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά"
                    }
                ]
            },
            {
                value: "category2_4",
                label: "Γενικά Έξοδα με δικαίωμα έκπτωσης ΦΠΑ",
                type: [
                    {
                        value: "E3_585_002",
                        label: "Δαπάνες από συνδεδεμένες επιχειρήσεις"
                    },
                    {
                        value: "E3_585_003",
                        label: "Δαπάνες από μη συνεργαζόμενα κράτη ή από κράτη με προνομιακό φορολογικό καθεστώς"
                    },
                    {
                        value: "E3_585_016",
                        label: "Λοιπά έξοδα"
                    },
                    {
                        value: "E3_882_004",
                        label: " Αγορές ενσώματων παγίων χρήσης/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_883_004",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_581_003",
                        label: "Παροχές σε εργαζόμενους/Λοιπές παροχές"
                    }
                ]
            },
            {
                value: "category2_5",
                label: "Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης ΦΠΑ",
                type: [
                    {
                        value: "E3_585_002",
                        label: "Δαπάνες από συνδεδεμένες επιχειρήσεις"
                    },
                    {
                        value: "E3_585_003",
                        label: "Δαπάνες από μη συνεργαζόμενα κράτη ή από κράτη με προνομιακό φορολογικό καθεστώς"
                    },
                    {
                        value: "E3_585_016",
                        label: "Λοιπά έξοδα"
                    },
                    {
                        value: "E3_882_004",
                        label: " Αγορές ενσώματων παγίων χρήσης/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_883_004",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_581_003",
                        label: "Παροχές σε εργαζόμενους/Λοιπές παροχές"
                    }
                ]
            },
            {
                value: "category2_7",
                label: "Αγορές Παγίων",
                type: [
                    {
                        value: "E3_882_004",
                        label: "Αγορές ενσώματων παγίων χρήσης/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_883_004",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Εξωτερικού Τρίτες Χώρες"
                    },
                ]
            },
            {
                value: "category2_10",
                label: "Έξοδα προηγούμενων χρήσεων",
                type: [
                    {
                        value: "E3_882_004",
                        label: " Αγορές ενσώματων παγίων χρήσης/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_883_004",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_102_004",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_102_006",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)Λοιπά"
                    },
                    {
                        value: "E3_313_003",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_313_005",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Λοιπά"
                    }, {
                        value: "E3_202_003",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_202_005",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά"
                    },
                    {
                        value: "E3_302_003",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_302_005",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά"
                    },
                    {
                        value: "E3_585_002",
                        label: "Δαπάνες από συνδεδεμένες επιχειρήσεις"
                    },
                    {
                        value: "E3_585_003",
                        label: "Δαπάνες από μη συνεργαζόμενα κράτη ή από κράτη με προνομιακό φορολογικό καθεστώς"
                    },
                    {
                        value: "E3_585_016",
                        label: "Λοιπά έξοδα"
                    },
                    {
                        value: "E3_882_003",
                        label: "Αγορές ενσώματων παγίων χρήσης/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_883_003",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_581_003",
                        label: "Παροχές σε εργαζόμενους/Λοιπές παροχές"
                    }
                ]
            },
            {
                value: "category2_11",
                label: "Έξοδα επομένων χρήσεων",
                type: [
                    {
                        value: "E3_882_004",
                        label: " Αγορές ενσώματων παγίων χρήσης/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_883_004",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_102_004",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_102_006",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)Λοιπά"
                    },
                    {
                        value: "E3_313_004",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_313_005",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Λοιπά"
                    }, {
                        value: "E3_202_004",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_202_005",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά"
                    },
                    {
                        value: "E3_302_004",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_302_005",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά"
                    },
                    {
                        value: "E3_585_002",
                        label: "Δαπάνες από συνδεδεμένες επιχειρήσεις"
                    },
                    {
                        value: "E3_585_004",
                        label: "Δαπάνες από μη συνεργαζόμενα κράτη ή από κράτη με προνομιακό φορολογικό καθεστώς"
                    },
                    {
                        value: "E3_585_016",
                        label: "Λοιπά έξοδα"
                    },
                    {
                        value: "E3_882_004",
                        label: "Αγορές ενσώματων παγίων χρήσης/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_883_004",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_581_003",
                        label: "Παροχές σε εργαζόμενους/Λοιπές παροχές"
                    }
                ]
            },
            {
                value: "category2_95",
                label: "Λοιπά Πληροφοριακά Στοιχεία Εξόδων (-) / (+)",
                type: [
                    {
                        value: "null",
                        label: "Δεν ενημερώνει E3"
                    },
                ]
            }
        ]
    },
    {
        value: "14.3",
        label: "Τιμολόγιο /Ενδοκοινοτική Λήψη Υπηρεσιών",
        category: [
            {
                value: "category2_3",
                label: "Λήψη Υπηρεσιών",
                type: [
                    {
                        value: "E3_585_001",
                        label: "Προμήθειες διαχείρισης ημεδαπής - αλλοδαπής (management fees)"
                    },
                    {
                        value: "E3_585_002",
                        label: "Δαπάνες από συνδεδεμένες επιχειρήσεις"
                    },
                    {
                        value: "E3_585_003",
                        label: "Δαπάνες από μη συνεργαζόμενα κράτη ή από κράτη με προνομιακό φορολογικό καθεστώς"
                    },
                    {
                        value: "E3_585_004",
                        label: "Δαπάνες για ενημερωτικές ημερίδες"
                    },
                    {
                        value: "E3_585_005",
                        label: "Έξοδα υποδοχής και φιλοξενίας"
                    },
                    {
                        value: "E3_585_010",
                        label: "Λοιπές Αμοιβές για υπηρεσίες αλλοδαπής"
                    },
                    {
                        value: "E3_585_015",
                        label: "Διαφήμιση και προβολή"
                    },
                    {
                        value: "E3_585_016",
                        label: "Λοιπά έξοδα"
                    },
                    {
                        value: "E3_586",
                        label: "Χρεωστικοί τόκοι και συναφή έξοδα"
                    },
                    {
                        value: "E3_581_003",
                        label: "Παροχές σε εργαζόμενους/Λοιπές παροχές"
                    },
                ]
            },
            {
                value: "category2_4",
                label: "Γενικά Έξοδα με δικαίωμα έκπτωσης ΦΠΑ",
                type: [
                    {
                        value: "E3_585_001",
                        label: "Προμήθειες διαχείρισης ημεδαπής - αλλοδαπής (management fees)"
                    },
                    {
                        value: "E3_585_002",
                        label: "Δαπάνες από συνδεδεμένες επιχειρήσεις"
                    },
                    {
                        value: "E3_585_003",
                        label: "Δαπάνες από μη συνεργαζόμενα κράτη ή από κράτη με προνομιακό φορολογικό καθεστώς"
                    },
                    {
                        value: "E3_585_004",
                        label: "Δαπάνες για ενημερωτικές ημερίδες"
                    },
                    {
                        value: "E3_585_005",
                        label: "Έξοδα υποδοχής και φιλοξενίας"
                    },
                    {
                        value: "E3_585_006",
                        label: "Έξοδα ταξιδιών"
                    },
                    {
                        value: "E3_585_010",
                        label: "Λοιπές Αμοιβές για υπηρεσίες αλλοδαπής"
                    },
                    {
                        value: "E3_585_015",
                        label: "Διαφήμιση και προβολή"
                    },
                    {
                        value: "E3_585_016",
                        label: "Λοιπά έξοδα"
                    },
                    {
                        value: "E3_586",
                        label: "Χρεωστικοί τόκοι και συναφή έξοδα"
                    },
                    {
                        value: "E3_102_004",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_102_006",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)Λοιπά"
                    },
                    {
                        value: "E3_202_003",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_202_005",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά"
                    },
                    {
                        value: "E3_302_003",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_302_005",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά"
                    },
                    {
                        value: "E3_313_003",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_313_005",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Λοιπά"
                    },
                    {
                        value: "E3_581_003",
                        label: "Παροχές σε εργαζόμενους/Λοιπές παροχές"
                    },
                ]
            },
            {
                value: "category2_5",
                label: "Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης ΦΠΑ",
                type: [
                    {
                        value: "E3_585_001",
                        label: "Προμήθειες διαχείρισης ημεδαπής - αλλοδαπής (management fees)"
                    },
                    {
                        value: "E3_585_002",
                        label: "Δαπάνες από συνδεδεμένες επιχειρήσεις"
                    },
                    {
                        value: "E3_585_003",
                        label: "Δαπάνες από μη συνεργαζόμενα κράτη ή από κράτη με προνομιακό φορολογικό καθεστώς"
                    },
                    {
                        value: "E3_585_004",
                        label: "Δαπάνες για ενημερωτικές ημερίδες"
                    },
                    {
                        value: "E3_585_005",
                        label: "Έξοδα υποδοχής και φιλοξενίας"
                    },
                    {
                        value: "E3_585_006",
                        label: "Έξοδα ταξιδιών"
                    },
                    {
                        value: "E3_585_010",
                        label: "Λοιπές Αμοιβές για υπηρεσίες αλλοδαπής"
                    },
                    {
                        value: "E3_585_015",
                        label: "Διαφήμιση και προβολή"
                    },
                    {
                        value: "E3_585_016",
                        label: "Λοιπά έξοδα"
                    },
                    {
                        value: "E3_586",
                        label: "Χρεωστικοί τόκοι και συναφή έξοδα"
                    },
                    {
                        value: "E3_882_003",
                        label: "Αγορές ενσώματων παγίων χρήσης/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_883_003",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_102_004",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_102_006",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)Λοιπά"
                    },
                    {
                        value: "E3_202_003",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_202_005",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά"
                    },
                    {
                        value: "E3_302_003",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_302_005",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά"
                    },
                    {
                        value: "E3_313_003",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_313_005",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Λοιπά"
                    },
                    {
                        value: "E3_581_003",
                        label: "Παροχές σε εργαζόμενους/Λοιπές παροχές"
                    },
                ]
            },
            {
                value: "category2_7",
                label: "Αγορές Παγίων",
                type: [
                    {
                        value: "E3_882_003",
                        label: "Αγορές ενσώματων παγίων χρήσης/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_883_003",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Εξωτερικού Ενδοκοινοτικές"
                    },
                ]
            },
            {
                value: "category2_10",
                label: "Έξοδα προηγούμενων χρήσεων",
                type: [
                    {
                        value: "E3_102_004",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_102_006",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)Λοιπά"
                    },
                    {
                        value: "E3_202_003",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_202_005",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά"
                    },
                    {
                        value: "E3_302_003",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_302_005",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά"
                    },
                    {
                        value: "E3_313_003",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_313_005",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Λοιπά"
                    },
                    {
                        value: "E3_581_003",
                        label: "Παροχές σε εργαζόμενους/Λοιπές παροχές"
                    },
                    {
                        value: "E3_585_001",
                        label: "Προμήθειες διαχείρισης ημεδαπής - αλλοδαπής (management fees)"
                    },
                    {
                        value: "E3_585_002",
                        label: "Δαπάνες από συνδεδεμένες επιχειρήσεις"
                    },
                    {
                        value: "E3_585_003",
                        label: "Δαπάνες από μη συνεργαζόμενα κράτη ή από κράτη με προνομιακό φορολογικό καθεστώς"
                    },
                    {
                        value: "E3_585_004",
                        label: "Δαπάνες για ενημερωτικές ημερίδες"
                    },
                    {
                        value: "E3_585_005",
                        label: "Έξοδα υποδοχής και φιλοξενίας"
                    },
                    {
                        value: "E3_585_006",
                        label: "Έξοδα ταξιδιών"
                    },
                    {
                        value: "E3_585_010",
                        label: "Λοιπές Αμοιβές για υπηρεσίες αλλοδαπής"
                    },
                    {
                        value: "E3_585_015",
                        label: "Διαφήμιση και προβολή"
                    },
                    {
                        value: "E3_585_016",
                        label: "Λοιπά έξοδα"
                    },
                    {
                        value: "E3_586",
                        label: "Χρεωστικοί τόκοι και συναφή έξοδα"
                    },
                    {
                        value: "E3_882_003",
                        label: "Αγορές ενσώματων παγίων χρήσης/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_883_003",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Εξωτερικού Ενδοκοινοτικές"
                    },
                ]
            },
            {
                value: "category2_11",
                label: "Έξοδα επομένων χρήσεων",
                type: [
                    {
                        value: "E3_102_004",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_102_006",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)Λοιπά"
                    },
                    {
                        value: "E3_202_003",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_202_005",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά"
                    },
                    {
                        value: "E3_302_003",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_302_005",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά"
                    },
                    {
                        value: "E3_313_003",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_313_005",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Λοιπά"
                    },
                    {
                        value: "E3_581_003",
                        label: "Παροχές σε εργαζόμενους/Λοιπές παροχές"
                    },
                    {
                        value: "E3_585_001",
                        label: "Προμήθειες διαχείρισης ημεδαπής - αλλοδαπής (management fees)"
                    },
                    {
                        value: "E3_585_002",
                        label: "Δαπάνες από συνδεδεμένες επιχειρήσεις"
                    },
                    {
                        value: "E3_585_003",
                        label: "Δαπάνες από μη συνεργαζόμενα κράτη ή από κράτη με προνομιακό φορολογικό καθεστώς"
                    },
                    {
                        value: "E3_585_004",
                        label: "Δαπάνες για ενημερωτικές ημερίδες"
                    },
                    {
                        value: "E3_585_005",
                        label: "Έξοδα υποδοχής και φιλοξενίας"
                    },
                    {
                        value: "E3_585_006",
                        label: "Έξοδα ταξιδιών"
                    },
                    {
                        value: "E3_585_010",
                        label: "Λοιπές Αμοιβές για υπηρεσίες αλλοδαπής"
                    },
                    {
                        value: "E3_585_015",
                        label: "Διαφήμιση και προβολή"
                    },
                    {
                        value: "E3_585_016",
                        label: "Λοιπά έξοδα"
                    },
                    {
                        value: "E3_586",
                        label: "Χρεωστικοί τόκοι και συναφή έξοδα"
                    },
                    {
                        value: "E3_882_003",
                        label: "Αγορές ενσώματων παγίων χρήσης/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_883_003",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Εξωτερικού Ενδοκοινοτικές"
                    },
                ]
            },
            {
                value: "category2_95",
                label: "Λοιπά Πληροφοριακά Στοιχεία Εξόδων (-) / (+)",
                type: [
                    {
                        value: "null",
                        label: "Δεν ενημερώνει E3"
                    },
                ]
            }
        ]
    },
    {
        value: "14.4",
        label: "Τιμολόγιο / Λήψη Υπηρεσιών Τρίτων Χωρών",
        category: [
            {
                value: "category2_3",
                label: "Λήψη Υπηρεσιών",
                type: [
                    {
                        value: "E3_585_001",
                        label: "Προμήθειες διαχείρισης ημεδαπής - αλλοδαπής (management fees)"
                    },
                    {
                        value: "E3_585_002",
                        label: "Δαπάνες από συνδεδεμένες επιχειρήσεις"
                    },
                    {
                        value: "E3_585_003",
                        label: "Δαπάνες από μη συνεργαζόμενα κράτη ή από κράτη με προνομιακό φορολογικό καθεστώς"
                    },
                    {
                        value: "E3_585_004",
                        label: "Δαπάνες για ενημερωτικές ημερίδες"
                    },
                    {
                        value: "E3_585_005",
                        label: "Έξοδα υποδοχής και φιλοξενίας"
                    },
                    {
                        value: "E3_585_010",
                        label: "Λοιπές Αμοιβές για υπηρεσίες αλλοδαπής"
                    },
                    {
                        value: "E3_585_015",
                        label: "Διαφήμιση και προβολή"
                    },
                    {
                        value: "E3_585_016",
                        label: "Λοιπά έξοδα"
                    },
                    {
                        value: "E3_586",
                        label: "Χρεωστικοί τόκοι και συναφή έξοδα"
                    },
                    {
                        value: "E3_581_003",
                        label: "Παροχές σε εργαζόμενους/Λοιπές παροχές"
                    },
                ]
            },
            {
                value: "category2_4",
                label: "Γενικά Έξοδα με δικαίωμα έκπτωσης ΦΠΑ",
                type: [
                    {
                        value: "E3_585_001",
                        label: "Προμήθειες διαχείρισης ημεδαπής - αλλοδαπής (management fees)"
                    },
                    {
                        value: "E3_585_002",
                        label: "Δαπάνες από συνδεδεμένες επιχειρήσεις"
                    },
                    {
                        value: "E3_585_003",
                        label: "Δαπάνες από μη συνεργαζόμενα κράτη ή από κράτη με προνομιακό φορολογικό καθεστώς"
                    },
                    {
                        value: "E3_585_004",
                        label: "Δαπάνες για ενημερωτικές ημερίδες"
                    },
                    {
                        value: "E3_585_005",
                        label: "Έξοδα υποδοχής και φιλοξενίας"
                    },
                    {
                        value: "E3_585_006",
                        label: "Έξοδα ταξιδιών"
                    },
                    {
                        value: "E3_585_010",
                        label: "Λοιπές Αμοιβές για υπηρεσίες αλλοδαπής"
                    },
                    {
                        value: "E3_585_015",
                        label: "Διαφήμιση και προβολή"
                    },
                    {
                        value: "E3_585_016",
                        label: "Λοιπά έξοδα"
                    },
                    {
                        value: "E3_586",
                        label: "Χρεωστικοί τόκοι και συναφή έξοδα"
                    },
                    {
                        value: "E3_102_005",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_102_006",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)Λοιπά"
                    },
                    {
                        value: "E3_202_004",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_202_005",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά"
                    },
                    {
                        value: "E3_302_004",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_302_005",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά"
                    },
                    {
                        value: "E3_313_004",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_313_005",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Λοιπά"
                    },
                    {
                        value: "E3_581_003",
                        label: "Παροχές σε εργαζόμενους/Λοιπές παροχές"
                    },
                ]
            },
            {
                value: "category2_5",
                label: "Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης ΦΠΑ",
                type: [
                    {
                        value: "E3_585_001",
                        label: "Προμήθειες διαχείρισης ημεδαπής - αλλοδαπής (management fees)"
                    },
                    {
                        value: "E3_585_002",
                        label: "Δαπάνες από συνδεδεμένες επιχειρήσεις"
                    },
                    {
                        value: "E3_585_003",
                        label: "Δαπάνες από μη συνεργαζόμενα κράτη ή από κράτη με προνομιακό φορολογικό καθεστώς"
                    },
                    {
                        value: "E3_585_004",
                        label: "Δαπάνες για ενημερωτικές ημερίδες"
                    },
                    {
                        value: "E3_585_005",
                        label: "Έξοδα υποδοχής και φιλοξενίας"
                    },
                    {
                        value: "E3_585_006",
                        label: "Έξοδα ταξιδιών"
                    },
                    {
                        value: "E3_585_010",
                        label: "Λοιπές Αμοιβές για υπηρεσίες αλλοδαπής"
                    },
                    {
                        value: "E3_585_015",
                        label: "Διαφήμιση και προβολή"
                    },
                    {
                        value: "E3_585_016",
                        label: "Λοιπά έξοδα"
                    },
                    {
                        value: "E3_586",
                        label: "Χρεωστικοί τόκοι και συναφή έξοδα"
                    },
                    {
                        value: "E3_882_004",
                        label: "Αγορές ενσώματων παγίων χρήσης/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_883_004",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_102_005",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_102_006",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)Λοιπά"
                    },
                    {
                        value: "E3_202_004",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_202_005",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά"
                    },
                    {
                        value: "E3_302_004",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_302_005",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά"
                    },
                    {
                        value: "E3_313_004",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_313_005",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Λοιπά"
                    },
                    {
                        value: "E3_581_003",
                        label: "Παροχές σε εργαζόμενους/Λοιπές παροχές"
                    },
                ]
            },
            {
                value: "category2_7",
                label: "Αγορές Παγίων",
                type: [
                    {
                        value: "E3_882_004",
                        label: "Αγορές ενσώματων παγίων χρήσης/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_883_004",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Εξωτερικού Τρίτες Χώρες"
                    },
                ]
            },
            {
                value: "category2_10",
                label: "Έξοδα προηγούμενων χρήσεων",
                type: [
                    {
                        value: "E3_102_005",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_102_006",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)Λοιπά"
                    },
                    {
                        value: "E3_202_004",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_202_005",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά"
                    },
                    {
                        value: "E3_302_004",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_302_005",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά"
                    },
                    {
                        value: "E3_313_004",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_313_005",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Λοιπά"
                    },
                    {
                        value: "E3_581_003",
                        label: "Παροχές σε εργαζόμενους/Λοιπές παροχές"
                    },
                    {
                        value: "E3_585_001",
                        label: "Προμήθειες διαχείρισης ημεδαπής - αλλοδαπής (management fees)"
                    },
                    {
                        value: "E3_585_002",
                        label: "Δαπάνες από συνδεδεμένες επιχειρήσεις"
                    },
                    {
                        value: "E3_585_003",
                        label: "Δαπάνες από μη συνεργαζόμενα κράτη ή από κράτη με προνομιακό φορολογικό καθεστώς"
                    },
                    {
                        value: "E3_585_004",
                        label: "Δαπάνες για ενημερωτικές ημερίδες"
                    },
                    {
                        value: "E3_585_005",
                        label: "Έξοδα υποδοχής και φιλοξενίας"
                    },
                    {
                        value: "E3_585_006",
                        label: "Έξοδα ταξιδιών"
                    },
                    {
                        value: "E3_585_010",
                        label: "Λοιπές Αμοιβές για υπηρεσίες αλλοδαπής"
                    },
                    {
                        value: "E3_585_015",
                        label: "Διαφήμιση και προβολή"
                    },
                    {
                        value: "E3_585_016",
                        label: "Λοιπά έξοδα"
                    },
                    {
                        value: "E3_586",
                        label: "Χρεωστικοί τόκοι και συναφή έξοδα"
                    },
                    {
                        value: "E3_882_004",
                        label: "Αγορές ενσώματων παγίων χρήσης/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_883_004",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Εξωτερικού Τρίτες Χώρες"
                    },
                ]
            },
            {
                value: "category2_11",
                label: "Έξοδα επομένων χρήσεων",
                type: [
                    {
                        value: "E3_102_005",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_102_006",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)Λοιπά"
                    },
                    {
                        value: "E3_202_004",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_202_005",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά"
                    },
                    {
                        value: "E3_302_004",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_302_005",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά"
                    },
                    {
                        value: "E3_313_004",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_313_005",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Λοιπά"
                    },
                    {
                        value: "E3_581_003",
                        label: "Παροχές σε εργαζόμενους/Λοιπές παροχές"
                    },
                    {
                        value: "E3_585_001",
                        label: "Προμήθειες διαχείρισης ημεδαπής - αλλοδαπής (management fees)"
                    },
                    {
                        value: "E3_585_002",
                        label: "Δαπάνες από συνδεδεμένες επιχειρήσεις"
                    },
                    {
                        value: "E3_585_003",
                        label: "Δαπάνες από μη συνεργαζόμενα κράτη ή από κράτη με προνομιακό φορολογικό καθεστώς"
                    },
                    {
                        value: "E3_585_004",
                        label: "Δαπάνες για ενημερωτικές ημερίδες"
                    },
                    {
                        value: "E3_585_005",
                        label: "Έξοδα υποδοχής και φιλοξενίας"
                    },
                    {
                        value: "E3_585_006",
                        label: "Έξοδα ταξιδιών"
                    },
                    {
                        value: "E3_585_010",
                        label: "Λοιπές Αμοιβές για υπηρεσίες αλλοδαπής"
                    },
                    {
                        value: "E3_585_015",
                        label: "Διαφήμιση και προβολή"
                    },
                    {
                        value: "E3_585_016",
                        label: "Λοιπά έξοδα"
                    },
                    {
                        value: "E3_586",
                        label: "Χρεωστικοί τόκοι και συναφή έξοδα"
                    },
                    {
                        value: "E3_882_004",
                        label: "Αγορές ενσώματων παγίων χρήσης/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_883_004",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Εξωτερικού Τρίτες Χώρες"
                    },
                ]
            },
            {
                value: "category2_95",
                label: "Λοιπά Πληροφοριακά Στοιχεία Εξόδων (-) / (+)",
                type: [
                    {
                        value: "null",
                        label: "Δεν ενημερώνει E3"
                    },
                ]
            }
        ]
    },
    {
        value: "14.5",
        label: "ΕΦΚΑ και λοιποί Ασφαλιστικοί Οργανισμοί",
        category: [
            {
                value: "category2_5",
                label: "Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης ΦΠΑ",
                type: [
                    {
                        value: "E3_585_007",
                        label: "Ασφαλιστικές Εισφορές Αυτοαπασχολούμενων"
                    },
                    {
                        value: "E3_588",
                        label: "Ασυνήθη έξοδα, ζημιές και πρόστιμα"
                    },
                ]
            },
            {
                value: "category2_10",
                label: "Έξοδα προηγούμενων χρήσεων",
                type: [
                    {
                        value: "E3_585_007",
                        label: "Ασφαλιστικές Εισφορές Αυτοαπασχολούμενων"
                    },
                    {
                        value: "E3_588",
                        label: "Ασυνήθη έξοδα, ζημιές και πρόστιμα"
                    },
                ]
            },
            {
                value: "category2_11",
                label: "Έξοδα επομένων χρήσεων",
                type: [
                    {
                        value: "E3_585_007",
                        label: "Ασφαλιστικές Εισφορές Αυτοαπασχολούμενων"
                    },
                    {
                        value: "E3_588",
                        label: "Ασυνήθη έξοδα, ζημιές και πρόστιμα"
                    },
                ]
            },
            {
                value: "category2_95",
                label: "Λοιπά Πληροφοριακά Στοιχεία Εξόδων (-) / (+)",
                type: [
                    {
                        value: "null",
                        label: "Δεν ενημερώνει E3"
                    },
                ]
            }
        ]
    },
    {
        value: "14.30",
        label: "Παραστατικά Οντότητας ως Αναγράφονται από την ίδια (Δυναμικό)",
        category: [
            {
                value: "category2_3",
                label: "Λήψη Υπηρεσιών",
                type: [
                    {
                        value: "E3_585_011",
                        label: "Ενέργεια"
                    },
                    {
                        value: "E3_585_012",
                        label: "Ύδρευση"
                    },
                    {
                        value: "E3_585_013",
                        label: "Τηλεπικοινωνίες"
                    },
                    {
                        value: "E3_585_016",
                        label: "Λοιπά έξοδα"
                    },
                    {
                        value: "E3_586",
                        label: "Χρεωστικοί τόκοι και συναφή έξοδα"
                    },
                ]
            },
            {
                value: "category2_4",
                label: "Γενικά Έξοδα με δικαίωμα έκπτωσης ΦΠΑ",
                type: [
                    {
                        value: "E3_585_011",
                        label: "Ενέργεια"
                    },
                    {
                        value: "E3_585_012",
                        label: "Ύδρευση"
                    },
                    {
                        value: "E3_585_013",
                        label: "Τηλεπικοινωνίες"
                    },
                    {
                        value: "E3_585_016",
                        label: "Λοιπά έξοδα"
                    },
                    {
                        value: "E3_586",
                        label: "Χρεωστικοί τόκοι και συναφή έξοδα"
                    },
                ]
            },
            {
                value: "category2_5",
                label: "Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης ΦΠΑ",
                type: [
                    {
                        value: "E3_585_011",
                        label: "Ενέργεια"
                    },
                    {
                        value: "E3_585_012",
                        label: "Ύδρευση"
                    },
                    {
                        value: "E3_585_013",
                        label: "Τηλεπικοινωνίες"
                    },
                    {
                        value: "E3_585_014",
                        label: "Ενοίκια"
                    },
                    {
                        value: "E3_585_016",
                        label: "Λοιπά έξοδα"
                    },
                    {
                        value: "E3_586",
                        label: "Χρεωστικοί τόκοι και συναφή έξοδα"
                    },
                ]
            },
            {
                value: "category2_10",
                label: "Έξοδα προηγούμενων χρήσεων",
                type: [
                    {
                        value: "E3_585_011",
                        label: "Ενέργεια"
                    },
                    {
                        value: "E3_585_012",
                        label: "Ύδρευση"
                    },
                    {
                        value: "E3_585_013",
                        label: "Τηλεπικοινωνίες"
                    },
                    {
                        value: "E3_585_014",
                        label: "Ενοίκια"
                    },
                    {
                        value: "E3_585_016",
                        label: "Λοιπά έξοδα"
                    },
                    {
                        value: "E3_586",
                        label: "Χρεωστικοί τόκοι και συναφή έξοδα"
                    },
                ]
            },
            {
                value: "category2_11",
                label: "Έξοδα επομένων χρήσεων",
                type: [
                    {
                        value: "E3_585_011",
                        label: "Ενέργεια"
                    },
                    {
                        value: "E3_585_012",
                        label: "Ύδρευση"
                    },
                    {
                        value: "E3_585_013",
                        label: "Τηλεπικοινωνίες"
                    },
                    {
                        value: "E3_585_014",
                        label: "Ενοίκια"
                    },
                    {
                        value: "E3_585_016",
                        label: "Λοιπά έξοδα"
                    },
                    {
                        value: "E3_586",
                        label: "Χρεωστικοί τόκοι και συναφή έξοδα"
                    },
                ]
            },
            {
                value: "category2_95",
                label: "Λοιπά Πληροφοριακά στοιχεία εσόδων (+)/ (-)",
                type: [
                    {
                        value: "null",
                        label: "Κενό"
                    },
                ]
            }
        ]
    },
    {
        value: "14.31",
        label: "Πιστωτικό ημεδαπής/αλλοδαπής",
        category: [
            {
                value: "category2_1",
                label: "Αγορές Εμπορευμάτων",
                type: [
                    {
                        value: "E3_102_004",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_102_005",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_102_006",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Λοιπά"
                    },
                    {
                        value: "E3_313_003",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_313_004",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_313_005",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Λοιπά"
                    },
                ]
            },
            {
                value: "category2_2",
                label: "Αγορές Α'-Β' Υλών",
                type: [
                    {
                        value: "E3_202_003",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_202_004",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_202_005",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά"
                    },
                    {
                        value: "E3_302_003",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_302_004",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_302_005",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά"
                    },
                ]
            },
            {
                value: "category2_3",
                label: "Λήψη Υπηρεσιών",
                type: [
                    {
                        value: "E3_585_001",
                        label: "Προμήθειες διαχείρισης ημεδαπής - αλλοδαπής (management fees)"
                    },
                    {
                        value: "E3_585_002",
                        label: "Δαπάνες από συνδεδεμένες επιχειρήσεις"
                    },
                    {
                        value: "E3_585_003",
                        label: "Δαπάνες από μη συνεργαζόμενα κράτη ή από κράτη με προνομιακό φορολογικό καθεστώς"
                    },
                    {
                        value: "E3_585_004",
                        label: "Δαπάνες για ενημερωτικές ημερίδες"
                    },
                    {
                        value: "E3_585_005",
                        label: "Έξοδα υποδοχής και φιλοξενίας"
                    },
                    {
                        value: "E3_585_006",
                        label: "Έξοδα ταξιδιών"
                    },
                    {
                        value: "E3_585_010",
                        label: "Λοιπές Αμοιβές για υπηρεσίες αλλοδαπής"
                    },
                    {
                        value: "E3_585_011",
                        label: "Ενέργεια"
                    },
                    {
                        value: "E3_585_012",
                        label: "Ύδρευση"
                    },
                    {
                        value: "E3_585_013",
                        label: "Τηλεπικοινωνίες"
                    },
                    {
                        value: "E3_585_015",
                        label: "Διαφήμιση και προβολή"
                    },
                    {
                        value: "E3_585_016",
                        label: "Λοιπά έξοδα"
                    },
                    {
                        value: "E3_586",
                        label: "Χρεωστικοί τόκοι και συναφή έξοδα"
                    },
                    {
                        value: "E3_581_003",
                        label: "Παροχές σε εργαζόμενους/Λοιπές παροχές"
                    },
                ]
            },
            {
                value: "category2_4",
                label: "Γενικά Έξοδα με δικαίωμα έκπτωσης ΦΠΑ",
                type: [
                    {
                        value: "E3_585_001",
                        label: "Προμήθειες διαχείρισης ημεδαπής - αλλοδαπής (management fees)"
                    },
                    {
                        value: "E3_585_002",
                        label: "Δαπάνες από συνδεδεμένες επιχειρήσεις"
                    },
                    {
                        value: "E3_585_003",
                        label: "Δαπάνες από μη συνεργαζόμενα κράτη ή από κράτη με προνομιακό φορολογικό καθεστώς"
                    },
                    {
                        value: "E3_585_004",
                        label: "Δαπάνες για ενημερωτικές ημερίδες"
                    },
                    {
                        value: "E3_585_005",
                        label: "Έξοδα υποδοχής και φιλοξενίας"
                    },
                    {
                        value: "E3_585_006",
                        label: "Έξοδα ταξιδιών"
                    },
                    {
                        value: "E3_585_010",
                        label: "Λοιπές Αμοιβές για υπηρεσίες αλλοδαπής"
                    },
                    {
                        value: "E3_585_011",
                        label: "Ενέργεια"
                    },
                    {
                        value: "E3_585_012",
                        label: "Ύδρευση"
                    },
                    {
                        value: "E3_585_013",
                        label: "Τηλεπικοινωνίες"
                    },
                    {
                        value: "E3_585_015",
                        label: "Διαφήμιση και προβολή"
                    },
                    {
                        value: "E3_585_016",
                        label: "Λοιπά έξοδα"
                    },
                    {
                        value: "E3_586",
                        label: "Χρεωστικοί τόκοι και συναφή έξοδα"
                    },
                    {
                        value: "E3_882_002",
                        label: "Αγορές ενσώματων παγίων χρήσης/Λιανικές"
                    },
                    {
                        value: "E3_883_002",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Λιανικές"
                    },
                    {
                        value: "E3_102_002",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Λιανικές"
                    },
                    {
                        value: "E3_102_004",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_102_005",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_202_003",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_202_004",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_302_003",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_302_004",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_313_003",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_313_004",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_581_003",
                        label: "Παροχές σε εργαζόμενους/Λοιπές παροχές"
                    },
                    {
                        value: "E3_882_003",
                        label: "Αγορές ενσώματων παγίων χρήσης/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_883_003",
                        label: " Αγορές μη ενσώματων παγίων χρήσης/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_882_004",
                        label: "Αγορές ενσώματων παγίων χρήσης/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_883_004",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Εξωτερικού Τρίτες Χώρες"
                    },
                ]
            },
            {
                value: "category2_5",
                label: "Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης ΦΠΑ",
                type: [
                    {
                        value: "E3_585_001",
                        label: "Προμήθειες διαχείρισης ημεδαπής - αλλοδαπής (management fees)"
                    },
                    {
                        value: "E3_585_002",
                        label: "Δαπάνες από συνδεδεμένες επιχειρήσεις"
                    },
                    {
                        value: "E3_585_003",
                        label: "Δαπάνες από μη συνεργαζόμενα κράτη ή από κράτη με προνομιακό φορολογικό καθεστώς"
                    },
                    {
                        value: "E3_585_004",
                        label: "Δαπάνες για ενημερωτικές ημερίδες"
                    },
                    {
                        value: "E3_585_005",
                        label: "Έξοδα υποδοχής και φιλοξενίας"
                    },
                    {
                        value: "E3_585_006",
                        label: "Έξοδα ταξιδιών"
                    },
                    {
                        value: "E3_585_009",
                        label: "Λοιπές Αμοιβές για υπηρεσίες ημεδαπής"
                    },
                    {
                        value: "E3_585_011",
                        label: "Ενέργεια"
                    },
                    {
                        value: "E3_585_012",
                        label: "Ύδρευση"
                    },
                    {
                        value: "E3_585_013",
                        label: "Τηλεπικοινωνίες"
                    },
                    {
                        value: "E3_585_015",
                        label: "Διαφήμιση και προβολή"
                    },
                    {
                        value: "E3_585_016",
                        label: "Λοιπά έξοδα"
                    },
                    {
                        value: "E3_586",
                        label: "Χρεωστικοί τόκοι και συναφή έξοδα"
                    },
                    {
                        value: "E3_882_002",
                        label: "Αγορές ενσώματων παγίων χρήσης/Λιανικές"
                    },
                    {
                        value: "E3_883_002",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Λιανικές"
                    },
                    {
                        value: "E3_102_002",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Λιανικές"
                    },
                    {
                        value: "E3_102_004",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_102_005",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_202_003",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_202_004",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_302_003",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_302_004",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_313_003",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_313_004",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_581_003",
                        label: "Παροχές σε εργαζόμενους/Λοιπές παροχές"
                    },
                    {
                        value: "E3_882_003",
                        label: "Αγορές ενσώματων παγίων χρήσης/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_883_003",
                        label: " Αγορές μη ενσώματων παγίων χρήσης/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_882_004",
                        label: "Αγορές ενσώματων παγίων χρήσης/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_883_004",
                        label: " Αγορές μη ενσώματων παγίων χρήσης/Εξωτερικού Τρίτες Χώρες"
                    },
                ]
            },
            {
                value: "category2_7",
                label: "Αγορές Παγίων",
                type: [
                    {
                        value: "E3_882_003",
                        label: "Αγορές ενσώματων παγίων χρήσης/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_883_003",
                        label: " Αγορές μη ενσώματων παγίων χρήσης/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_882_004",
                        label: "Αγορές ενσώματων παγίων χρήσης/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_883_004",
                        label: " Αγορές μη ενσώματων παγίων χρήσης/Εξωτερικού Τρίτες Χώρες"
                    },
                ]
            },
            {
                value: "category2_95",
                label: "Λοιπά πληροφοριακά Στοιχεία Εξόδων (+)/ (-)",
                type: [
                    {
                        value: "null",
                        label: "Κενό"
                    },
                ]
            },
            {
                value: "category2_10",
                label: "Έξοδα προηγούμενων χρήσεων",
                type: [
                    {
                        value: "E3_585_001",
                        label: "Προμήθειες διαχείρισης ημεδαπής - αλλοδαπής (management fees)"
                    },
                    {
                        value: "E3_585_002",
                        label: "Δαπάνες από συνδεδεμένες επιχειρήσεις"
                    },
                    {
                        value: "E3_585_003",
                        label: "Δαπάνες από μη συνεργαζόμενα κράτη ή από κράτη με προνομιακό φορολογικό καθεστώς"
                    },
                    {
                        value: "E3_585_004",
                        label: "Δαπάνες για ενημερωτικές ημερίδες"
                    },
                    {
                        value: "E3_585_005",
                        label: "Έξοδα υποδοχής και φιλοξενίας"
                    },
                    {
                        value: "E3_585_006",
                        label: "Έξοδα ταξιδιών"
                    },
                    {
                        value: "E3_585_009",
                        label: "Λοιπές Αμοιβές για υπηρεσίες ημεδαπής"
                    },
                    {
                        value: "E3_585_011",
                        label: "Ενέργεια"
                    },
                    {
                        value: "E3_585_012",
                        label: "Ύδρευση"
                    },
                    {
                        value: "E3_585_013",
                        label: "Τηλεπικοινωνίες"
                    },
                    {
                        value: "E3_585_015",
                        label: "Διαφήμιση και προβολή"
                    },
                    {
                        value: "E3_585_016",
                        label: "Λοιπά έξοδα"
                    },
                    {
                        value: "E3_586",
                        label: "Χρεωστικοί τόκοι και συναφή έξοδα"
                    },
                    {
                        value: "E3_882_002",
                        label: "Αγορές ενσώματων παγίων χρήσης/Λιανικές"
                    },
                    {
                        value: "E3_883_002",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Λιανικές"
                    },
                    {
                        value: "E3_102_002",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Λιανικές"
                    },
                    {
                        value: "E3_102_004",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_102_005",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_202_003",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_202_004",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_302_003",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_302_004",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_313_003",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_313_004",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_581_003",
                        label: "Παροχές σε εργαζόμενους/Λοιπές παροχές"
                    },
                    {
                        value: "E3_882_003",
                        label: "Αγορές ενσώματων παγίων χρήσης/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_883_003",
                        label: " Αγορές μη ενσώματων παγίων χρήσης/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_882_004",
                        label: "Αγορές ενσώματων παγίων χρήσης/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_883_004",
                        label: " Αγορές μη ενσώματων παγίων χρήσης/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_202_002",
                        label: " Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λιανικές"
                    },
                    {
                        value: "E3_202_005",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά"
                    },
                    {
                        value: "E3_302_002",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λιανικές"
                    },
                    {
                        value: "E3_302_005",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά"
                    },
                    {
                        value: "E3_102_002",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Λιανικές"
                    },
                    {
                        value: "E3_102_006",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)Λοιπά"
                    },
                    {
                        value: "E3_313_002",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Λιανικές"
                    },
                    {
                        value: "E3_313_005",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Λοιπά"
                    },
                ]
            },
            {
                value: "category2_11",
                label: "Έξοδα επομένων χρήσεων",
                type: [
                    {
                        value: "E3_585_001",
                        label: "Προμήθειες διαχείρισης ημεδαπής - αλλοδαπής (management fees)"
                    },
                    {
                        value: "E3_585_002",
                        label: "Δαπάνες από συνδεδεμένες επιχειρήσεις"
                    },
                    {
                        value: "E3_585_003",
                        label: "Δαπάνες από μη συνεργαζόμενα κράτη ή από κράτη με προνομιακό φορολογικό καθεστώς"
                    },
                    {
                        value: "E3_585_004",
                        label: "Δαπάνες για ενημερωτικές ημερίδες"
                    },
                    {
                        value: "E3_585_005",
                        label: "Έξοδα υποδοχής και φιλοξενίας"
                    },
                    {
                        value: "E3_585_006",
                        label: "Έξοδα ταξιδιών"
                    },
                    {
                        value: "E3_585_009",
                        label: "Λοιπές Αμοιβές για υπηρεσίες ημεδαπής"
                    },
                    {
                        value: "E3_585_011",
                        label: "Ενέργεια"
                    },
                    {
                        value: "E3_585_012",
                        label: "Ύδρευση"
                    },
                    {
                        value: "E3_585_013",
                        label: "Τηλεπικοινωνίες"
                    },
                    {
                        value: "E3_585_015",
                        label: "Διαφήμιση και προβολή"
                    },
                    {
                        value: "E3_585_016",
                        label: "Λοιπά έξοδα"
                    },
                    {
                        value: "E3_586",
                        label: "Χρεωστικοί τόκοι και συναφή έξοδα"
                    },
                    {
                        value: "E3_882_002",
                        label: "Αγορές ενσώματων παγίων χρήσης/Λιανικές"
                    },
                    {
                        value: "E3_883_002",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Λιανικές"
                    },
                    {
                        value: "E3_102_002",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Λιανικές"
                    },
                    {
                        value: "E3_102_004",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_102_005",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_202_003",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_202_004",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_302_003",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_302_004",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_313_003",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_313_004",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_581_003",
                        label: "Παροχές σε εργαζόμενους/Λοιπές παροχές"
                    },
                    {
                        value: "E3_882_003",
                        label: "Αγορές ενσώματων παγίων χρήσης/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_883_003",
                        label: " Αγορές μη ενσώματων παγίων χρήσης/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_882_004",
                        label: "Αγορές ενσώματων παγίων χρήσης/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_883_004",
                        label: " Αγορές μη ενσώματων παγίων χρήσης/Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_202_002",
                        label: " Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λιανικές"
                    },
                    {
                        value: "E3_202_005",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά"
                    },
                    {
                        value: "E3_302_002",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λιανικές"
                    },
                    {
                        value: "E3_302_005",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά"
                    },
                    {
                        value: "E3_102_002",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Λιανικές"
                    },
                    {
                        value: "E3_102_006",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)Λοιπά"
                    },
                    {
                        value: "E3_313_002",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Λιανικές"
                    },
                    {
                        value: "E3_313_005",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Λοιπά"
                    },
                ]
            }
        ]
    },
    {
        value: "15.1",
        label: "Συμβόλαιο- Έξοδο",
        category: [
            {
                value: "category2_1",
                label: "Αγορές Εμπορευμάτων",
                type: [
                    {
                        value: "E3_102_002",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Λιανικές"
                    },
                    {
                        value: "E3_102_006",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)Λοιπά"
                    },
                    {
                        value: "E3_313_002",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Λιανικές"
                    },
                    {
                        value: "E3_313_005",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Λοιπά"
                    },
                ]
            },
            {
                value: "category2_2",
                label: "Αγορές Α'-Β' Υλών",
                type: [
                    {
                        value: "E3_202_002",
                        label: " Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λιανικές"
                    },
                    {
                        value: "E3_202_005",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά"
                    },
                    {
                        value: "E3_302_002",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λιανικές"
                    },
                    {
                        value: "E3_302_005",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά"
                    },
                ]
            },
            {
                value: "category2_3",
                label: "Λήψη Υπηρεσιών",
                type: [
                    {
                        value: "E3_585_004",
                        label: "Δαπάνες για ενημερωτικές ημερίδες"
                    },
                    {
                        value: "E3_585_016",
                        label: "Λοιπά έξοδα"
                    },
                ]
            },
            {
                value: "category2_5",
                label: "Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης ΦΠΑ",
                type: [
                    {
                        value: "E3_585_004",
                        label: "Δαπάνες για ενημερωτικές ημερίδες"
                    },
                    {
                        value: "E3_585_016",
                        label: "Λοιπά έξοδα"
                    },
                ]
            },
            {
                value: "category2_7",
                label: "Αγορές Παγίων",
                type: [
                    {
                        value: "E3_882_002",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Λιανικές"
                    },
                    {
                        value: "E3_883_002",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Εξωτερικού Ενδοκοινοτικές"
                    },
                ]
            },
            {
                value: "category2_10",
                label: "Έξοδα προηγούμενων χρήσεων",
                type: [
                    {
                        value: "E3_882_002",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Λιανικές"
                    },
                    {
                        value: "E3_883_002",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_585_004",
                        label: "Δαπάνες για ενημερωτικές ημερίδες"
                    },
                    {
                        value: "E3_585_016",
                        label: "Λοιπά έξοδα"
                    },
                    {
                        value: "E3_202_002",
                        label: " Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λιανικές"
                    },
                    {
                        value: "E3_202_005",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά"
                    },
                    {
                        value: "E3_302_002",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λιανικές"
                    },
                    {
                        value: "E3_302_005",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά"
                    },
                    {
                        value: "E3_102_002",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Λιανικές"
                    },
                    {
                        value: "E3_102_006",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)Λοιπά"
                    },
                    {
                        value: "E3_313_002",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Λιανικές"
                    },
                    {
                        value: "E3_313_005",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Λοιπά"
                    },
                ]
            },
            {
                value: "category2_11",
                label: "Έξοδα επομένων χρήσεων",
                type: [
                    {
                        value: "E3_882_002",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Λιανικές"
                    },
                    {
                        value: "E3_883_002",
                        label: "Αγορές μη ενσώματων παγίων χρήσης/Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_585_004",
                        label: "Δαπάνες για ενημερωτικές ημερίδες"
                    },
                    {
                        value: "E3_585_016",
                        label: "Λοιπά έξοδα"
                    },
                    {
                        value: "E3_202_002",
                        label: " Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λιανικές"
                    },
                    {
                        value: "E3_202_005",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά"
                    },
                    {
                        value: "E3_302_002",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λιανικές"
                    },
                    {
                        value: "E3_302_005",
                        label: "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά"
                    },
                    {
                        value: "E3_102_002",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Λιανικές"
                    },
                    {
                        value: "E3_102_006",
                        label: "Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)Λοιπά"
                    },
                    {
                        value: "E3_313_002",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Λιανικές"
                    },
                    {
                        value: "E3_313_005",
                        label: "Αγορές ζώων - φυτών (καθαρό ποσό)/Λοιπά"
                    },
                ]
            },
        ]
    },
    {
        value: "16.1",
        label: "Ενοίκιο Έξοδο",
        category: [
            {
                value: "category2_5",
                label: "Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης ΦΠΑ",
                type: [
                    {
                        value: "E3_585_014",
                        label: "Ενοίκια"
                    },
                    {
                        value: "E3_585_016",
                        label: "Λοιπά έξοδα"
                    },
                ]
            },
            {
                value: "category2_10",
                label: "Έξοδα προηγούμενων χρήσεων",
                type: [
                    {
                        value: "E3_585_014",
                        label: "Ενοίκια"
                    },
                    {
                        value: "E3_585_016",
                        label: "Λοιπά έξοδα"
                    },
                ]
            },
            {
                value: "category2_11",
                label: "Έξοδα επομένων χρήσεων",
                type: [
                    {
                        value: "E3_585_014",
                        label: "Ενοίκια"
                    },
                    {
                        value: "E3_585_016",
                        label: "Λοιπά έξοδα"
                    },
                ]
            },
            {
                value: "category2_95",
                label: "Λοιπά πληροφοριακά Στοιχεία Εξόδων (+)/ (-)",
                type: [
                    {
                        value: "null",
                        label: "Κενό"
                    },
                ]
            }
        ]
    },
    {
        value: "17.1",
        label: "Μισθοδοσία",
        category: [
            {
                value: "category2_6",
                label: "Αμοιβές και Παροχές προσωπικού",
                type: [
                    {
                        value: "E3_581_001",
                        label: "Παροχές σε εργαζόμενους/Μικτές αποδοχές"
                    },
                    {
                        value: "E3_581_002",
                        label: "Παροχές σε εργαζόμενους/Εργοδοτικές εισφορές"
                    },
                    {
                        value: "E3_581_003",
                        label: "Παροχές σε εργαζόμενους/Λοιπές παροχές"
                    }
                ]
            },
            {
                value: "category2_95",
                label: "Λοιπά πληροφοριακά Στοιχεία Εξόδων (+)/ (-)",
                type: [
                    {
                        value: "null",
                        label: "Δεν ενημερώνει Ε3"
                    },
                ]
            }
        ]
    },
    {
        value: "17.2",
        label: "Αποσβέσεις",
        category: [
            {
                value: "category2_8",
                label: "Αποσβέσεις Παγίων",
                type: [
                    {
                        value: "E3_587",
                        label: "Αποσβέσεις"
                    },
                ]
            },
            {
                value: "category2_95",
                label: "Λοιπά πληροφοριακά Στοιχεία Εξόδων (+)/ (-)",
                type: [
                    {
                        value: "null",
                        label: "Δεν ενημερώνει Ε3"
                    },
                ]
            }
        ]
    },
    {
        value: "17.3",
        label: "Λοιπές Εγγραφές Τακτοποίησης Εσόδων -Λογιστική Βάση",
        category: [
            {
                value: "category1_8",
                label: "Έσοδα προηγούμενων χρήσεων (+)/ (-)",
                type: [
                    {
                        value: "είναι επιτρεπτοί οι παρακάτω χαρακτηρισμοί ανά τιμή ΣΤ.9",
                        label: "είναι επιτρεπτοί οι παρακάτω χαρακτηρισμοί ανά τιμή ΣΤ.9"
                    }
                ]
            },
            {
                value: "category1_9",
                label: "Έσοδα επομένων χρήσεων (+)/ (-)",
                type: [
                    {
                        value: "είναι επιτρεπτοί οι παρακάτω χαρακτηρισμοί ανά τιμή ΣΤ.9",
                        label: "είναι επιτρεπτοί οι παρακάτω χαρακτηρισμοί ανά τιμή ΣΤ.9"
                    }
                ]
            },
            {
                value: "category1_10",
                label: "Λοιπές εγγραφές τακτοποίησης εσόδων (+)/ (-)",
                type: [
                    {
                        value: "E3_561_001",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Χονδρικές -Επιτηδευμάτων "
                    },
                    {
                        value: "E3_561_002",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Χονδρικές  βάσει άρθρου 39α παρ 5 του κώδικα ΦΠΑ (Ν2859/2000)"
                    },
                    {
                        value: "E3_561_003",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λιανικές - Ιδιωτική Πελατεία "
                    },
                    {
                        value: "E3_561_004",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λιανικές  βάσει άρθρου 39α παρ 5 του κώδικα ΦΠΑ (Ν2859/2000)"
                    },
                    {
                        value: "E3_561_005",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_561_006",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_561_007",
                        label: "Πωλήσεις αγαθών και υπηρεσιών Λοιπά"
                    },
                    {
                        value: "E3_562",
                        label: "Λοιπά συνήθη έσοδα"
                    },
                    {
                        value: "E3_563",
                        label: "Πιστωτικοί τόκοι και συναφή έσοδα"
                    },
                    {
                        value: "E3_595",
                        label: "Έξοδα σε ιδιοπαραγωγή"
                    },
                    {
                        value: "E3_596",
                        label: "Επιδοτήσεις- Επιχορηγήσεις"
                    },
                    {
                        value: "E3_597",
                        label: "Επιδοτήσεις- Επιχορηγήσεις για επενδυτικούς σκοπούς - κάλυψη δαπανών"
                    },
                    {
                        value: "E3_880_001",
                        label: "Πωλήσεις παγίων Χονδρικές"
                    },
                    {
                        value: "E3_880_002",
                        label: "Πωλήσεις παγίων Λιανικές"
                    },
                    {
                        value: "E3_880_003",
                        label: "Πωλήσεις παγίων Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_880_004",
                        label: "Πωλήσεις παγίων εξωτερικού Τρίτες Χώρες"
                    },
                    {
                        value: "E3_881_001",
                        label: "Πωλήσεις για λογ/σμο Τρίτων Χονδρικές"
                    },
                    {
                        value: "E3_881_002",
                        label: "Πωλήσεις για λογ/σμο Τρίτων Λιανικές"
                    },
                    {
                        value: "E3_881_003",
                        label: "Πωλήσεις για λογ/σμο Τρίτων Εξωτερικού Ενδοκοινοτικές"
                    },
                    {
                        value: "E3_881_004",
                        label: "Πωλήσεις για λογ/σμο Τρίτων Εξωτερικού Τρίτες χώρες"
                    },
                    {
                        value: "E3_564",
                        label: "Πιστωτικές συναλλαγματικές διαφορές "
                    },
                    {
                        value: "E3_565",
                        label: "Έσοδα συμμετοχών"
                    },
                    {
                        value: "E3_566",
                        label: "Κέρδη από διάθεση μη κυκλοφορούντων περιουσιακών στοιχείων"
                    },
                    {
                        value: "E3_567",
                        label: "Κέρδη από αναστροφή προβλέψεων και απομειώσεων"
                    },
                    {
                        value: "E3_568",
                        label: "Κέρδη απο επιμέτρηση στην εύλογη αξία "
                    },
                    {
                        value: "E3_570",
                        label: "Ασυνήθη έσοδα και κέρδη"
                    },
                ]
            },
            {
                value: "category2_95",
                label: "Λοιπά πληροφοριακά Στοιχεία Εξόδων (+)/ (-)",
                type: [
                    {
                        value: "null",
                        label: "Κενό"
                    },
                ]
            }
        ]
    },
    {
        value: "17.4",
        label: "Λοιπές Εγγραφές Τακτοποίησης Εσόδων -Φορολογική Βάση",
        category: [
            {
                value: "category2_95",
                label: "Λοιπά πληροφοριακά Στοιχεία Εξόδων (+)/ (-)",
                type: [
                    {
                        value: "null",
                        label: "Κενό"
                    },
                ]
            }
        ]
    },
    {
        value: "17.5",
        label: "Λοιπές Εγγραφές Τακτοποίησης Εξόδων -Λογιστική Βάση"
    },
    {
        value: "17.6",
        label: "Λοιπές Εγγραφές Τακτοποίησης Εξόδων -Φορολογική Βάση",
        category: [
            {
                value: "category2_12",
                label: "Λοιπές εγγραφές Τακτοποίοησης Εξόδων (+)/ (-)",
                type: [
                    {
                        value: "Δεν ενημερώνει E3",
                        label: "Δεν ενημερώνει E3"
                    },
                ]
            },
            {
                value: "category2_95",
                label: "Λοιπά πληροφοριακά Στοιχεία Εξόδων (+)/ (-)",
                type: [
                    {
                        value: "Δεν ενημερώνει E3",
                        label: "Δεν ενημερώνει E3"
                    },
                ]
            }
        ]
    }
]
