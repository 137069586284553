import React, { useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import EditableTable from "../../../primer/Editable-Table";
import {paymentTypesData} from "../../../../_data/mydata/paymentTypes";
import { measurementUnitsData } from "../../../../_data/mydata/measurementUnits";
import { highlightData } from "../../../../_data/mydata/highlight";
import { myDataProductLineCodingData } from "../../../../_data/mydata/myDataProductLineCoding";
import { salesPurposeData } from "../../../../_data/mydata/salesPurpose";
import {deleteObjectFromArrayOfObjects, findObjectFromArrayOfObjects} from "../../../../_helpers/helperFunctions";
import { useDispatch, useSelector } from "react-redux";
import { setPrimerMyDataData } from "../../../../_reducers/settingsSlice";
import {setEditing, setSavePrimerMyDataData} from "../../../../_reducers/activitySlice";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";

const PrimerDataOtherData = () => {
    const [t] = useTranslation('common');
    const dispatch = useDispatch();

    const SETTINGS = useSelector( (state) => state.SETTINGS );
    let pMDDSettings = SETTINGS.primerMyDataData;

    const pTData = pMDDSettings.paymentWays !== '' ? JSON.parse(pMDDSettings.paymentWays): paymentTypesData;
    const [paymentTypesTableData, setPaymentTypes] = useState(pTData);

    const mUData = pMDDSettings.measurementUnits !== '' ? JSON.parse(pMDDSettings.measurementUnits): measurementUnitsData;
    const [musTableData, setMuTableData] = useState(mUData);

    const hData = pMDDSettings.highlight !== '' ? JSON.parse(pMDDSettings.highlight): highlightData;
    const [highlightTableData, setHighlightTableData] = useState(hData);

    const spData = pMDDSettings.salePurpose !== '' ? JSON.parse(pMDDSettings.salePurpose): salesPurposeData;
    const [salesPurposeTableData, setSalesPurposeTableData] = useState(spData);

    const mDPLCData = pMDDSettings.myDataProductLineCoding !== '' ? JSON.parse(pMDDSettings.myDataProductLineCoding): myDataProductLineCodingData;
    const [myDataPLCTableData, setMyDataPLCTableData] = useState(mDPLCData);

    let otherData = pMDDSettings?.otherData !== undefined && pMDDSettings?.otherData !== '' ? (pMDDSettings.otherData): deleteObjectFromArrayOfObjects(SETTINGS.otherData, 'delete', 'isActive');
    const [otherTableData, setOther] = useState(otherData);
    const paymentTypesColumns = [
        {
            name: t('SettingsPrimerData.tablePaymentWay.myDataCode'),
            field: "code",
            editable:false,
        },
        {
            name: t('SettingsPrimerData.tablePaymentWay.name'),
            field: "name",
            editable: false,
        },
        {
            name: t('SettingsPrimerData.tablePaymentWay.active'),
            field: "isActive",
            editable: true,
            inputType: {
                type: 'checkbox',
                options: [{
                    value: 'true',
                    label: 'True'
                }, {
                    value: 'false',
                    label: 'False'
                }]
            }
        },
    ];

    const measurementUnitsDataColumns = [
        {
            name: t('SettingsPrimerData.tableMeasurementUnits.myDataCode'),
            field: "code",
            editable:false,
        },
        {
            name: t('SettingsPrimerData.tableMeasurementUnits.name'),
            field: "name",
            editable: false,
        },
        {
            name: t('SettingsPrimerData.tableMeasurementUnits.shortName'),
            field: "shortName",
            editable: false,
        },
        {
            name: t('SettingsPrimerData.tableMeasurementUnits.active'),
            field: "isActive",
            editable: true,
            inputType: {
                type: 'checkbox',
                options: [{
                    value: 'true',
                    label: 'True'
                }, {
                    value: 'false',
                    label: 'False'
                }]
            }
        },
    ];

    const allowOlderDateColumns = [
        {
            name: t('SettingsPrimerData.tableHours.name'),
            field: "name",
            editable: false,
        },
        {
            name: t('SettingsPrimerData.tableHours.hours'),
            field: "value",
            editable: true,
            inputType: {
                type: 'text'
            }
        },
    ];

    const myDataProductLineCodingColumns = [
        {
            name: t('SettingsPrimerData.tableProductLine.myDataCode'),
            field: "code",
            editable:false,
        },
        {
            name: t('SettingsPrimerData.tableProductLine.name'),
            field: "name",
            editable: false,
        },
        {
            name: t('SettingsPrimerData.tableProductLine.developerNotes'),
            field: "developerNotes",
            editable: false,
        },
        {
            name: t('SettingsPrimerData.tableProductLine.active'),
            field: "isActive",
            editable: true,
            inputType: {
                type: 'checkbox',
                options: [{
                    value: 'true',
                    label: 'True'
                }, {
                    value: 'false',
                    label: 'False'
                }]
            }
        },
    ];

    const updatePaymentTypesActions = {
        update: (data, id) => {
            if (data.isActive) {

                dispatch(setSavePrimerMyDataData(true)); // Redux State Updated

                let paymentTypeData = paymentTypesTableData.slice()
                let paymentTypeObject = findObjectFromArrayOfObjects(paymentTypesTableData, id, 'code');
                const paymentTypeObjIdx = paymentTypesTableData.findIndex(paymentTypeObj => paymentTypeObj.code === id);

                paymentTypeObject.isActive = (paymentTypeObject.isActive === 'true') ? 'false' : 'true';
                paymentTypeData[paymentTypeObjIdx] = paymentTypeObject;

                setPaymentTypes(paymentTypeData);
                dispatch(setPrimerMyDataData({...pMDDSettings, paymentWays: JSON.stringify(paymentTypeData)})) // Redux State Updated
            }
        },
    }

    const updateMusActions = {
        update: (data, id) => {
            if (data.isActive) {

                dispatch(setSavePrimerMyDataData(true)); // Redux State Updated

                let muData = musTableData.slice()
                let muObject = findObjectFromArrayOfObjects(musTableData, id, 'code');
                muObject = Object.assign({}, muObject);
                const muObjIdx = musTableData.findIndex(muObj => muObj.code === id);

                muObject.isActive = (muObject.isActive === 'true') ? 'false' : 'true';
                muData[muObjIdx] = muObject;

                setMuTableData(muData);
                dispatch(setPrimerMyDataData({...pMDDSettings, measurementUnits: JSON.stringify(muData)})) // Redux State Updated
            }
        },
    }

    const updateHighlightsActions = {
        update: (data, id) => {
            if (data.isActive) {

                dispatch(setSavePrimerMyDataData(true)); // Redux State Updated

                let highlightData = highlightTableData.slice()
                let highlightObject = findObjectFromArrayOfObjects(highlightTableData, id, 'code');
                const highlightObjIdx = highlightTableData.findIndex(highlightObj => highlightObj.code === id);

                highlightObject.isActive = (highlightObject.isActive === 'true') ? 'false' : 'true';
                highlightData[highlightObjIdx] = highlightObject;

                setHighlightTableData(highlightData);
                dispatch(setPrimerMyDataData({...pMDDSettings, highlight: JSON.stringify(highlightData)})) // Redux State Updated
            }
        },
    }

    const updateOtherDataActions = {
        selected: () => {
            // Set if editing the form
            dispatch(setEditing(true));
        },
        update: (data, id) => {
            dispatch(setSavePrimerMyDataData(true)); // Redux State Updated
            let otherData = otherTableData.slice()
            let otherDataObject = findObjectFromArrayOfObjects(otherTableData, id, 'id');
            const otherDataObjIdx = otherTableData.findIndex(otherData => otherData.id === id);
            otherDataObject = Object.assign({}, otherDataObject);
            if(data.value){
                otherDataObject.value = data.value;
            }
            otherData[otherDataObjIdx] = otherDataObject;
            setOther(otherData);
            console.log(otherDataObjIdx)
            console.log(otherData)
            dispatch(setPrimerMyDataData({...pMDDSettings, otherData: (otherData)}));
            toast.info('1 row added. Please save your changes');
        }
    }

    const updateSalesPurposesActions = {
        update: (data, id) => {
            if (data.isActive) {

                dispatch(setSavePrimerMyDataData(true)); // Redux State Updated

                let salesPurposeData = salesPurposeTableData.slice()
                let salesPurposeObject = findObjectFromArrayOfObjects(salesPurposeTableData, id, 'code');
                const salesPurposeObjIdx = salesPurposeTableData.findIndex(salesPurposeObj => salesPurposeObj.code === id);

                salesPurposeObject.isActive = (salesPurposeObject.isActive === 'true') ? 'false' : 'true';
                salesPurposeData[salesPurposeObjIdx] = salesPurposeObject;

                setSalesPurposeTableData(salesPurposeData);
                dispatch(setPrimerMyDataData({...pMDDSettings, salePurpose: JSON.stringify(salesPurposeData)})) // Redux State Updated
            }
        },
    }

    const updateMyDataPLCsActions = {
        update: (data, id) => {
            if (data.isActive) {

                dispatch(setSavePrimerMyDataData(true)); // Redux State Updated

                let myDataPLCData = myDataPLCTableData.slice()
                let myDataPLCObject = findObjectFromArrayOfObjects(myDataPLCTableData, id, 'code');
                const myDataPLCObjIdx = myDataPLCTableData.findIndex(myDataPLCObj => myDataPLCObj.code === id);

                myDataPLCObject.isActive = (myDataPLCObject.isActive === 'true') ? 'false' : 'true';
                myDataPLCData[myDataPLCObjIdx] = myDataPLCObject;

                setMyDataPLCTableData(myDataPLCData);
                dispatch(setPrimerMyDataData({...pMDDSettings, myDataProductLineCoding: JSON.stringify(myDataPLCData)})) // Redux State Updated
            }
        },
    }

    return (
        <>
            <Container fluid>
                <Row>
                    <Col sm="12" md="4">
                        <EditableTable
                            heading={t('SettingsPrimerData.paymentWays')}
                            allowActions={false}
                            columns={paymentTypesColumns}
                            data={paymentTypesTableData}
                            onUpdate={updatePaymentTypesActions}
                        />
                    </Col>
                    <Col sm="12" md="4">
                        <EditableTable
                            heading={t('SettingsPrimerData.measurementUnits')}
                            allowActions={false}
                            columns={measurementUnitsDataColumns}
                            data={musTableData}
                            key = {Math.random()}
                            onUpdate={updateMusActions}
                        />
                    </Col>
                    <Col sm="12" md="4">
                        <EditableTable
                            heading={t('SettingsPrimerData.highlight')}
                            allowActions={false}
                            columns={paymentTypesColumns}
                            data={highlightTableData}
                            onUpdate={updateHighlightsActions}
                        />
                        <EditableTable
                            heading={t('SettingsPrimerData.allowIssuingInvoicesBefore')}
                            allowActions={false}
                            columns={allowOlderDateColumns}
                            data={otherData}
                            key={Math.random()}
                            onUpdate={updateOtherDataActions}
                        />
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col sm="12" md="4">
                        <EditableTable
                            heading={t('SettingsPrimerData.salePurpose')}
                            allowActions={false}
                            columns={paymentTypesColumns}
                            data={salesPurposeTableData}
                            onUpdate={updateSalesPurposesActions}
                        />
                    </Col>
                    <Col sm="12" md="8">
                        <EditableTable
                            heading={t('SettingsPrimerData.myDataProductLineCoding')}
                            allowActions={false}
                            columns={myDataProductLineCodingColumns}
                            data={myDataPLCTableData}
                            onUpdate={updateMyDataPLCsActions}
                        />
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default PrimerDataOtherData;
