import {getObjectFromArrayOfObjects} from "./helperFunctions";
import {vatCategoryData} from "../_data/mydata/vatCategory";
import {filterNaNValue, getGreekCurrencyFormat, isEmpty} from "./commonFunctions";
import getMyData from "./sharedmydata";
// Get Client Vat Percentage
export const getVatPercent = (vatCategory, vatPercentage, customVat = 0) => {
	let vatPer = vatPercentage; // Default vat from the product
	if (vatPercentage === "No VAT No VAT (π.χ. Μισθοδοσία, Αποσβέσεις κλπ)") {
		vatPer = 0;
	} else if (vatPercentage === "Vat For Companies Abroad - (Custom)") {
		vatPer = customVat;
	} else {
		if (vatCategory === 'Normal VAT') {
			vatPer = vatPercentage;
		}
		if (vatCategory === 'Reduced VAT') {
			let vatCatArr = getObjectFromArrayOfObjects(vatCategoryData, vatPercentage, 'percentage');
			if (vatCatArr.length > 0) {
				let vatCatObj = vatCatArr['0'];
				if (vatCatObj.reducedVat) {
					vatPer = ((vatCatObj.reducedVat === '' || vatCatObj.reducedVat === null) ? 0 : vatCatObj.reducedVat);
				}
			}
		}
		if (vatCategory === 'Zero VAT') {
			vatPer = 0;
		}
		if (vatCategory === 'Custom VAT') {
			vatPer = customVat;
		}
	}
	return vatPer;
}

// Calculate Net Values for Sales
// Value is calculated by: "Quantity" times "Unit Price" minus "Discount"
export const getNetValue = (unitPrice = 0, quantity = 1, discount = 0, discountType = 'euro') => {
	let value = 0;
	let floatDiscount = 0;

	if (quantity >= 1 && unitPrice) {

		let floatQuantity = parseFloat(quantity);
		let floatUnitPrice = parseFloat(unitPrice);

		let totalProductPrice = parseFloat(floatQuantity * floatUnitPrice);

		if (discountType === 'euro' || discountType === 'amount') {
			floatDiscount = parseFloat(discount) // if discount in euro / amount
		} else {
			floatDiscount = totalProductPrice * discount / 100; // if discount in percentage
		}

		// Return Zero if total price less then discount
		if (totalProductPrice > floatDiscount) {
			value = parseFloat(totalProductPrice) - parseFloat(floatDiscount); // Geting net value
		}

	}

	return parseFloat(value).toFixed(2);
}

// Get Unit price
// vatType:true means inclusive
export const getPriceExclusiveVat = (price = 0, vat, vatType = "false") => {
	if (price && price >= 0) {
		if (vatType === 'true') {
			// Price exclusive of VAT = (Price inclusive of VAT) / ((1 + VAT percentage as a number / 100))
			price = parseFloat(parseFloat(price) / (1 + (parseFloat(vat) / 100)));
		}
	}
	return parseFloat(price).toFixed(2);
}

// Get Discount
// vatType:true means inclusive
export const getDiscountExclusiveVat = (discount = 0, vat, vatType = "false", discountType = 'euro') => {
	if (discount && discount > 0) {
		if ((vatType === 'true') && (discountType === 'euro' || discountType === 'amount')) {
			// discount exclusive of VAT = (discount inclusive of VAT) / ((1 + VAT percentage as a number / 100))
			discount = parseFloat(parseFloat(discount) / (1 + (parseFloat(vat) / 100)));
		}
	}
	return parseFloat(discount).toFixed(2);
}

// Get Product Tax
export const getTax = (quantity = 0, price = 0, discount = 0, tax = 0, taxType) => {
	// Zero if empty
	if (tax === '' || Number(price) === 0) {
		tax = 0;
	}
	// Calculate if price greater than 0 and tax none zero, not empty
	if (price > 0 && tax !== '' && tax !== 0) {
		if (taxType === 'per' || tax.includes("%")) {
			// ((quantity * (price - discount)) * tax) // If tax in %
			tax = parseFloat(tax.toString().replaceAll('%', ''));
			tax = ((quantity * (parseFloat(price) - parseFloat(discount))) * parseFloat(tax) / 100 );
		} else if (taxType === 'euro' || tax.includes("€")) {
			// ((quantity * (price - discount)) * tax) // If tax in %
			tax = parseFloat(tax.toString().replaceAll('%', ''));
			tax = quantity * parseFloat(price);
		} else {
			tax = 0;
		}
	}

	return parseFloat(tax).toFixed(2);
}

// Get Product Taxes
export const getProductWithholdTax = (data, SETTINGS) => {
	let tax;

	let quantity = data.quantity;
	let price = data.unitPrice;
	let discount = data.discount;
	let withholdTax = GetWithholdTax(data.withHoldTax, SETTINGS);
	let taxStr = withholdTax?.tax;
	let taxType = withholdTax?.taxPer === 'true' ? 'per' : 'euro'
	tax =  getTax(quantity, price, discount, taxStr, taxType);

	return parseFloat(tax).toFixed(2);
}

export const getProductOtherTax = (data, SETTINGS) => {
	let tax;
	let quantity = data.quantity;
	let price = data.unitPrice;
	let discount = data.discount;
	let otherTax = getOtherTax(data.otherTax, SETTINGS);
	let taxStr = otherTax?.tax;
	let taxType = otherTax?.taxPer === 'true' ? 'per' : 'euro'
	tax =  getTax(quantity, price, discount, taxStr, taxType);
	return parseFloat(tax).toFixed(2);
}

export const getProductFee = (data, SETTINGS) => {
	let tax;
	let quantity = data.quantity;
	let price = data.unitPrice;
	let discount = data.discount;
	let otherTax = getFee(data.fee, SETTINGS);
	let taxStr = otherTax?.tax;
	let taxType = otherTax?.taxPer === 'true' ? 'per' : 'euro'
	tax =  getTax(quantity, price, discount, taxStr, taxType);
	return parseFloat(tax).toFixed(2);
}

export const getProductStamp = (data, SETTINGS) => {
	let tax;
	let quantity = data.quantity;
	let price = data.unitPrice;
	let discount = data.discount;
	let stamp = getStamp(data.stamp, SETTINGS);
	let taxStr = stamp?.tax;
	let taxType = stamp?.taxPer === 'true' ? 'per' : 'euro'
	tax =  getTax(quantity, price, discount, taxStr, taxType);
	return parseFloat(tax).toFixed(2);
}
// Get Product Taxes
export const getTaxes = (data, SETTINGS) => {
	let taxes = 0;
	let quantity = data.quantity;
	let price = data.unitPrice;
	let discount = data.discount;
	let withholdTax = GetWithholdTax(data.withHoldTax, SETTINGS);
	let otherTax = getOtherTax(data.otherTax);
	let fee = getFee(data.fee);
	let stamp = getStamp(data.stamp);

	let deductionsEuro = 0;
	if (!isEmpty(data?.deductionsEuro) && parseFloat(data?.deductionsEuro) > 0) {
		deductionsEuro = Number(parseFloat(data.deductionsEuro).toFixed(2));
	}

	let productTaxes = [
		{
			tax: otherTax?.tax,
			type: otherTax?.taxPer === 'true' ? 'per' : 'euro',
			taxType: "otherTax"
		},
		{
			tax: withholdTax?.tax,
			type: withholdTax?.taxPer === 'true' ? 'per' : 'euro',
			taxType: "withholdTax"
		},
		{
			tax: fee?.tax,
			type: fee?.taxPer === 'true' ? 'per' : 'euro',
			taxType: "fee"
		},
		{
			tax: stamp?.tax,
			type: 'per',
			taxType: "stamp"
		},
	];

	if (price > 0) {
		// Tax1 + Tax2 + Tax3
		productTaxes.forEach((product) => {
			if (product.tax) {
				let cptax = getTax(quantity, price, discount, product.tax, product.type);
				if(product.taxType === "withholdTax"){
					taxes = parseFloat(taxes) - parseFloat(cptax);
				} else {
					taxes = parseFloat(taxes) + parseFloat(cptax);
				}
			}
		});
	}
	if (deductionsEuro) {
		taxes = taxes - deductionsEuro;
	}
	return parseFloat(taxes).toFixed(2);
}

// Calculate Unit Price for Sales
export const getUnitPrice = (value = 0, quantity = 1, discount = 0, discountType = 'euro') => {
	let unitPrice = 0;
	let floatDiscount = 0;
	if (quantity >= 1) {
		let floatQuantity = (quantity);
		let floatValue = (value);

		if (discountType === 'euro' || discountType === 'amount') {
			floatDiscount = parseFloat(discount).toFixed(2); // if discount in euro / amount
		} else {
			floatDiscount = floatValue * discount / 100; // if discount in percentage
			//	floatDiscount = (floatValue / (discount / 100)) - floatValue;
		}
		let totalValue = parseFloat(floatValue) + parseFloat(floatDiscount);
		unitPrice = parseFloat(totalValue) / floatQuantity; // Getting unit price
	}
	return parseFloat(unitPrice).toFixed(2);
}

// Calculate Total Value for Sales
// Net Value  + VAT + Taxes - Withhold taxes
export const getTotalValue = (netValue, vat, taxes = 0, vatValue = 0) => {
	let totalValue;
	if (vat && vatValue) {
		totalValue = parseFloat(netValue) + parseFloat(vatValue) + parseFloat(taxes);
	} else if (vat && netValue) {
		totalValue = parseFloat(netValue) + parseFloat((parseFloat(netValue) * parseFloat(vat) / 100).toFixed(2)) + parseFloat(taxes);
	} else if(vat && taxes){
		totalValue = (parseFloat(taxes) + ( parseFloat(taxes) * parseFloat(vat) / 100));
	} else if(netValue && taxes){
		totalValue = parseFloat(netValue) + parseFloat(taxes);
	} else if(netValue){
		totalValue = parseFloat(netValue)
	} else {
		totalValue = parseFloat(taxes)
	}
	return parseFloat(totalValue).toFixed(2);
}

export const getVatValue = (netValue, vat, userInputTotalValue = 0) => {
	let vatValue = 0;
	if (vat && netValue) {
		vatValue =  parseFloat(netValue) * parseFloat(vat) / 100;
	}
	if (userInputTotalValue) {
		let diff = Number(Number(userInputTotalValue).toFixed(2)) - (Number(Number(netValue).toFixed(2)) + Number(Number(vatValue).toFixed(2)));
		diff = Number(Number(diff).toFixed(2));
		let toReturn = Number(Math.ceil((vatValue + diff) * 100) / 100);
		if (Number(Number(userInputTotalValue).toFixed(2)) !== Number(Number(parseFloat(netValue) + toReturn).toFixed(2))) {
			toReturn = Number(Math.floor((vatValue + diff) * 100) / 100);
		}
		return toReturn.toFixed(2);
	} else {
		return parseFloat(vatValue).toFixed(2);
	}
}
export const getNetValueByTotalValue = (totalValue, vat, taxes = 0) => {
	let netValue = 0;
	if (totalValue && vat) {
		netValue = (parseFloat(totalValue) - parseFloat(taxes)) / (1 + parseFloat(vat) / 100); // Remove Taxes
	}
	if(vat === 0){
		netValue = totalValue;
	}
	return parseFloat(netValue).toFixed(2);
}

// Get Sales Product Totals
export const getSalesTotals = (products, SETTINGS) => {
	let totals = {
		totalQuantity: 0,
		totalBeforeDiscount: 0,
		totalDiscount: 0,
		totalNetValue: 0,
		totalVatValue: 0,
		withHoldTaxes: 0,
		totalDeductions: 0,
		totalTaxesAmount: 0,
		totalAmount: 0,
		paymentAmount: 0,
	}

	if (products && products.length > 0) {
		products.forEach((item) => {
			let priceBeforeDiscount = 0;
			let quantity = item.quantity && item.quantity !== "" ? item.quantity : 1;
			let discount = item.discount && item.discount !== "" && item.discount !== "0.00" ? item.unitPrice * quantity - item.netValue : 0;
			// check
			if (item.unitPrice && quantity) {
				if (quantity === '') {
					quantity = 1;
				}

				if (item.unitPrice === '') {
					item.unitPrice = 0;
				}

				if (!isNaN(item.unitPrice) && !isNaN(item.quantity)) {
					priceBeforeDiscount = parseFloat(item.unitPrice) * parseFloat(item.quantity);
				}
			}
			totals.totalQuantity = filterNaNValue(parseFloat(parseFloat(totals.totalQuantity) + parseFloat(quantity)).toFixed(2));
			totals.totalBeforeDiscount = filterNaNValue(parseFloat(parseFloat(totals.totalBeforeDiscount) + parseFloat(priceBeforeDiscount)).toFixed(2));
			totals.totalDiscount = filterNaNValue(parseFloat(parseFloat(totals.totalDiscount) + parseFloat(discount)).toFixed(2));
			totals.totalNetValue = filterNaNValue(parseFloat(parseFloat(totals.totalNetValue) + parseFloat(item.netValue)).toFixed(2));
			totals.totalVatValue = filterNaNValue(parseFloat(parseFloat(totals.totalVatValue) + parseFloat(item.vat)).toFixed(2));
			totals.totalAmount = filterNaNValue(parseFloat(parseFloat(totals.totalAmount) + parseFloat(item.totalValue)).toFixed(2));
			totals.withHoldTaxes = filterNaNValue(parseFloat(parseFloat(totals.withHoldTaxes) + parseFloat(item.witholdTaxEuro)).toFixed(2));
			totals.totalTaxesAmount = filterNaNValue(parseFloat(parseFloat(totals.totalTaxesAmount) + parseFloat(item?.otherTaxEuro ? item.otherTaxEuro : 0) + parseFloat(item?.feeEuro ? item.feeEuro : 0) + parseFloat(item?.stampEuro ? item.stampEuro : 0)).toFixed(2));
			if (item.deductionsEuro && parseFloat(item.deductionsEuro) > 0) {
				totals.totalDeductions = filterNaNValue(parseFloat(parseFloat(totals.totalDeductions) + parseFloat(item?.deductionsEuro)).toFixed(2));
			}
		});
		totals.paymentAmount = parseFloat(totals.totalAmount).toFixed(2);
	}
	return totals;
}

// Get Withhold tax
// Returns Object
export const GetWithholdTax = (taxCode, SETTINGS) => {
	let withholdTaxes = getMyData({type: 'withhold', settings: SETTINGS});
	let withholdTaxArr = getObjectFromArrayOfObjects(withholdTaxes, taxCode, 'code');
	let withholdTax = null;
	if (withholdTaxArr && withholdTaxArr.length > 0) {
		withholdTax = withholdTaxArr['0'];
	}
	return withholdTax;
}

// Get Other Tax
// Returns Object
export const getOtherTax = (taxCode, SETTINGS) => {
	let otherTaxes = getMyData({type: 'othertaxes', settings: SETTINGS});
	let otherTaxArr = getObjectFromArrayOfObjects(otherTaxes, taxCode, 'code');
	let otherTax = null;
	if (otherTaxArr && otherTaxArr.length > 0) {
		otherTax = otherTaxArr['0'];
	}
	return otherTax;
}

export const getFee = (taxCode, SETTINGS) => {
	let fees = getMyData({type: 'fees', settings: SETTINGS});
	let feeArr = getObjectFromArrayOfObjects(fees, taxCode, 'code');
	let fee = null;
	if (feeArr && feeArr.length > 0) {
		fee = feeArr['0'];
	}
	return fee;
}

// Get Other Tax
// Returns Object
export const getStamp = (taxCode, SETTINGS) => {
	let stampTaxes = getMyData({type: 'stamp', settings: SETTINGS});
	let stampTaxArr = getObjectFromArrayOfObjects(stampTaxes, taxCode, 'code');
	let stampTax = null;
	if (stampTaxArr && stampTaxArr.length > 0) {
		stampTax = stampTaxArr['0'];
	}

	return stampTax;
}

// Get Withhold tax by code id
export const getWithholdTaxValue = (quantity, price, discount, withHoldTax, SETTINGS) => {
	let tax;
	let withholdTax = GetWithholdTax(withHoldTax, SETTINGS);
	let taxStr = withholdTax?.tax;
	let taxType = withholdTax?.taxPer === 'true' ? 'per' : 'euro'
	tax =  getTax(quantity, price, discount, taxStr, taxType);
	return parseFloat(tax).toFixed(2);
}

// Get Other tax by code id
export const getOtherTaxValue = (quantity, price, discount, othertax) => {
	let tax;
	let otherTax = getOtherTax(othertax);
	let taxStr = otherTax?.tax;
	let taxType = otherTax?.taxPer === 'true' ? 'per' : 'euro'
	tax =  getTax(quantity, price, discount, taxStr, taxType);
	return parseFloat(tax).toFixed(2);
}

export const getFeeValue = (quantity, price, discount, fee, SETTINGS) => {
	let tax;
	let Fee = getFee(fee, SETTINGS);
	let taxStr = Fee?.tax;
	let taxType = Fee?.taxPer === 'true' ? 'per' : 'euro'
	tax =  getTax(quantity, price, discount, taxStr, taxType);
	return parseFloat(tax).toFixed(2);
}

// Get Other tax by code id
export const getStampValue = (quantity, price, discount, othertax) => {
	let tax;
	let stampTax = getStamp(othertax);
	let taxStr = stampTax?.tax;
	let taxType = 'per';
	tax =  getTax(quantity, price, discount, taxStr, taxType);

	return parseFloat(tax).toFixed(2);
}
// Get Sales Products Taxes Data
export const getSalesTaxesData = (products, extraData, productTotals, SETTINGS) => {
	let taxesData = [];
	if (products && products.length > 0) {
		products.forEach((item) => {
			let vatTaxes = {};
			let pVatEuro;
			let pVatPer = item.vatPercentage ? item.vatPercentage.toString().replaceAll('%', '') : 0;
			let pNetValue;
			pNetValue = item.netValue;
			pVatEuro = parseFloat(pNetValue) * parseFloat(pVatPer) / 100;

			let withholdTaxEuro = getWithholdTaxValue(item.quantity, item.unitPrice, item.discount, item.withHoldTax, SETTINGS);
			let pWithholdTaxes = item.withHoldTax && item.withHoldTax !== '' ? parseFloat(withholdTaxEuro) : 0;
			let feeEuro = getFeeValue(item.quantity, item.unitPrice, item.discount, item.fee, SETTINGS);
			let pFees = item.fee && item.fee !== '' ? parseFloat(feeEuro) : 0;
			let stampEuro = getStampValue(item.quantity, item.unitPrice, item.discount, item.stamp, SETTINGS);
			let pStamp = item.stamp && item.stamp !== '' ? parseFloat(stampEuro) : 0;
			let otherTaxEuro = getOtherTaxValue(item.quantity, item.unitPrice, item.discount, item.otherTax, SETTINGS);
			let pOtherTaxes = item.otherTax && item.otherTax !== '' ? parseFloat(otherTaxEuro) : 0;
			let pDeductions = !isEmpty(item?.deductionsEuro) && parseFloat(item.deductionsEuro) > 0 ? parseFloat(item.deductionsEuro) : 0;

			// Check if vat percentage already exists
			pVatPer = pVatPer + "%";
			let isVatRowAlreadyExists = getObjectFromArrayOfObjects(taxesData, pVatPer, 'vatPercentage')
			if (isVatRowAlreadyExists && isVatRowAlreadyExists.length > 0) {
				let productIndex = taxesData.findIndex(xProduct => xProduct.vatPercentage === pVatPer);
				let existingRowData = isVatRowAlreadyExists['0'];
				let updateExistingRow = {}

				updateExistingRow.vatPercentage = pVatPer;
				updateExistingRow.netValue = parseFloat(parseFloat(existingRowData.netValue) + parseFloat(pNetValue));
				updateExistingRow.vatEuro = parseFloat(parseFloat(existingRowData.vatEuro) + parseFloat(pVatEuro));
				updateExistingRow.withholdTaxes = parseFloat(parseFloat(existingRowData.withholdTaxes) + parseFloat(pWithholdTaxes));
				updateExistingRow.otherTaxes = parseFloat(parseFloat(existingRowData.otherTaxes) + parseFloat(pOtherTaxes));
				updateExistingRow.fees = parseFloat(parseFloat(existingRowData.fees) + parseFloat(pFees));
				updateExistingRow.stamp = parseFloat(parseFloat(existingRowData.stamp) + parseFloat(pStamp));
				updateExistingRow.deductions = parseFloat(parseFloat(existingRowData.deductions) + parseFloat(pDeductions));
				// Update the array with new data
				taxesData[productIndex] = updateExistingRow;

			} else {
				vatTaxes[`vatPercentage`] = pVatPer;
				vatTaxes[`netValue`] = parseFloat(pNetValue).toFixed(2);
				vatTaxes[`vatEuro`] = parseFloat(pVatEuro).toFixed(2);
				vatTaxes[`withholdTaxes`] = parseFloat(pWithholdTaxes).toFixed(2);
				vatTaxes[`otherTaxes`] = parseFloat(pOtherTaxes).toFixed(2);
				vatTaxes[`fees`] = parseFloat(pFees).toFixed(2);
				vatTaxes[`stamp`] = parseFloat(pStamp).toFixed(2);
				vatTaxes[`deductions`] = parseFloat(pDeductions).toFixed(2);
				taxesData.push(vatTaxes);
			}
		});
	}
	if((extraData.otherTax !== "" || extraData.fee !== "" || extraData.withHoldTax !== "" || extraData.stamp !== "" || parseFloat(extraData.deductionsEuro) > 0) && productTotals !== undefined) {
		let vatTaxes = {};
		let pVatPer = "Invoice Level Tax";
		let pNetValue = productTotals.totalNetValue;

		// Withheld taxes
		let withholdTaxEuro = 0;
		if (SETTINGS.primerMyDataData?.withHoldTaxes?.find((el) => el.code === extraData.withHoldTax)?.taxPer === "false") {
			withholdTaxEuro = !isEmpty(extraData.witholdTaxEuro) ? extraData.witholdTaxEuro : 0;
		} else {
			withholdTaxEuro = getWithholdTaxValue(1, pNetValue, 0, extraData.withHoldTax, SETTINGS);
		}
		let pWithholdTaxes = extraData.withHoldTax && extraData.withHoldTax !== '' ? parseFloat(withholdTaxEuro) : 0;

		// Fees
		let feeEuro = 0;
		if (SETTINGS.primerMyDataData?.fees?.find((el) => el.code === extraData.fee)?.taxPer === "false") {
			feeEuro = !isEmpty(extraData.feeEuro) ? extraData.feeEuro : 0;
		} else {
			feeEuro = getFeeValue(1, pNetValue, 0, extraData.fee, SETTINGS);
		}
		let pFees = extraData.fee && extraData.fee !== '' ? parseFloat(feeEuro) : 0;

		// Stamps
		let stampEuro = 0;
		if (SETTINGS.primerMyDataData?.stamp?.find((el) => el.code === extraData.stamp)?.taxPer === "false") {
			stampEuro = !isEmpty(extraData.stampEuro) ? extraData.stampEuro : 0;
		} else {
			stampEuro = getStampValue(1, pNetValue, 0, extraData.stamp, SETTINGS);
		}
		let pStamp = extraData.stamp && extraData.stamp !== '' ? parseFloat(stampEuro) : 0;

		// Other Taxes
		let otherTaxEuro = 0;
		if (SETTINGS.primerMyDataData?.otherTaxes?.find((el) => el.code === extraData.otherTax)?.taxPer === "false") {
			otherTaxEuro = !isEmpty(extraData.otherTaxEuro) ? extraData.otherTaxEuro : 0;
		} else {
			otherTaxEuro = getOtherTaxValue(1, pNetValue, 0, extraData.otherTax, SETTINGS);
		}
		let pOtherTaxes = extraData.otherTax && extraData.otherTax !== '' ? parseFloat(otherTaxEuro) : 0;

		// Deductions
		let deductionsEuro = 0;
		if (parseFloat(extraData.deductionsEuro) > 0) {
			deductionsEuro = parseFloat(extraData.deductionsEuro);
		}

		// Check if vat percentage already exists
		let isVatRowAlreadyExists = getObjectFromArrayOfObjects(taxesData, pVatPer, 'vatPercentage')
		if (isVatRowAlreadyExists && isVatRowAlreadyExists.length > 0) {
			let productIndex = taxesData.findIndex(xProduct => xProduct.vatPercentage === pVatPer);
			let existingRowData = isVatRowAlreadyExists['0'];
			let updateExistingRow = {}

			updateExistingRow.vatPercentage = pVatPer;
			updateExistingRow.netValue = parseFloat(parseFloat(existingRowData.netValue));
			updateExistingRow.vatEuro = 0;
			updateExistingRow.withholdTaxes = parseFloat(parseFloat(existingRowData.withholdTaxes) + parseFloat(pWithholdTaxes));
			updateExistingRow.otherTaxes = parseFloat(parseFloat(existingRowData.otherTaxes) + parseFloat(pOtherTaxes));
			updateExistingRow.fees = parseFloat(parseFloat(existingRowData.fees) + parseFloat(pFees));
			updateExistingRow.stamp = parseFloat(parseFloat(existingRowData.stamp) + parseFloat(pStamp));
			updateExistingRow.deductions = parseFloat(parseFloat(existingRowData.deductions) + parseFloat(deductionsEuro));
			// Update the array with new data
			taxesData[productIndex] = updateExistingRow;
		} else {
			vatTaxes[`vatPercentage`] = pVatPer;
			vatTaxes[`netValue`] = 0;
			vatTaxes[`vatEuro`] = 0;
			vatTaxes[`withholdTaxes`] = parseFloat(pWithholdTaxes).toFixed(2);
			vatTaxes[`otherTaxes`] = parseFloat(pOtherTaxes).toFixed(2);
			vatTaxes[`fees`] = parseFloat(pFees).toFixed(2);
			vatTaxes[`stamp`] = parseFloat(pStamp).toFixed(2);
			vatTaxes[`deductions`] = parseFloat(deductionsEuro).toFixed(2);
			taxesData.unshift(vatTaxes);
		}
	}
	// Total Calculation
	if (taxesData.length > 0) {
		let totals = {
			vatPercentage: 'Totals',
			netValue: getGreekCurrencyFormat(getTotalsOfField(taxesData, 'netValue')),
			vatEuro: getGreekCurrencyFormat(getTotalsOfField(taxesData, 'vatEuro')),
			withholdTaxes: getGreekCurrencyFormat(getTotalsOfField(taxesData, 'withholdTaxes')),
			otherTaxes: getGreekCurrencyFormat(getTotalsOfField(taxesData, 'otherTaxes')),
			fees: getGreekCurrencyFormat(getTotalsOfField(taxesData, 'fees')),
			stamp: getGreekCurrencyFormat(getTotalsOfField(taxesData, 'stamp')),
			deductions: getGreekCurrencyFormat(getTotalsOfField(taxesData, 'deductions')),
		}
		taxesData.push(totals);
	}
	return taxesData;
}

// This will pass the taxes and totals to sales print template.
export const getSalesTaxesTemplateData = (products, SETTINGS) => {
	let taxesData = [];
	if (products && products.length > 0) {
		products.forEach((item) => {
			let vatTaxes = {};
			let pVatPer = item.vatPercentage ? item.vatPercentage.toString().replaceAll('%', '') : 0;
			let pNetValue = item.netValue;
			let pVatEuro = item.vat; //parseFloat(pNetValue) * parseFloat(pVatPer) / 100;
			//let withholdTaxEuro = getWithholdTaxValue(item.quantity, item.unitPrice, item.discount, item.withHoldTax, SETTINGS);
			let withholdTaxEuro = item.witholdTaxEuro;
			let pWithholdTaxes = item.withHoldTax && item.withHoldTax !== '' ? parseFloat(withholdTaxEuro) : 0;
			//let feeEuro = getFeeValue(item.quantity, item.unitPrice, item.discount, item.fee, SETTINGS);
			let feeEuro = item.feeEuro;
			let pFees = item.fee && item.fee !== '' ? parseFloat(feeEuro) : 0;
			//let stampEuro = getStampValue(item.quantity, item.unitPrice, item.discount, item.stamp, SETTINGS);
			let stampEuro = item.stampEuro;
			let pStamp = item.stamp && item.stamp !== '' ? parseFloat(stampEuro) : 0;
			//let otherTaxEuro = getOtherTaxValue(item.quantity, item.unitPrice, item.discount, item.otherTax, SETTINGS);
			let otherTaxEuro = item.otherTaxEuro;
				let pOtherTaxes = item.otherTax && item.otherTax !== '' ? parseFloat(otherTaxEuro) : 0;
			let taxes = parseFloat(pWithholdTaxes + pFees + pStamp + pOtherTaxes);

			// Check if vat percentage already exists
			pVatPer = pVatPer + "%";
			let isVatRowAlreadyExists = getObjectFromArrayOfObjects(taxesData, pVatPer, 'vatPercentage')
			if (isVatRowAlreadyExists && isVatRowAlreadyExists.length > 0) {
				let productIndex = taxesData.findIndex(xProduct => xProduct.vatPercentage === pVatPer);
				let existingRowData = isVatRowAlreadyExists['0'];
				let updateExistingRow = {}

				updateExistingRow.vatPercentage = pVatPer;
				updateExistingRow.netValue = parseFloat(parseFloat(existingRowData.netValue) + parseFloat(pNetValue));
				updateExistingRow.vatEuro = parseFloat(parseFloat(existingRowData.vatEuro) + parseFloat(pVatEuro));
				updateExistingRow.withholdTaxes = parseFloat(parseFloat(existingRowData.withholdTaxes) + parseFloat(pWithholdTaxes));
				updateExistingRow.otherTaxes = parseFloat(parseFloat(existingRowData.otherTaxes) + parseFloat(pOtherTaxes));
				updateExistingRow.fees = parseFloat(parseFloat(existingRowData.fees) + parseFloat(pFees));
				updateExistingRow.stamp = parseFloat(parseFloat(existingRowData.stamp) + parseFloat(pStamp));
				updateExistingRow.taxes = parseFloat(parseFloat(existingRowData.taxes) + parseFloat(taxes));
				updateExistingRow.totalValue = parseFloat(parseFloat(existingRowData.totalValue) + parseFloat(pNetValue) + parseFloat(pVatEuro) + parseFloat(taxes) - 2*pWithholdTaxes);
				// Update the array with new data
				taxesData[productIndex] = updateExistingRow;
			} else {
				vatTaxes[`vatPercentage`] = pVatPer;
				vatTaxes[`netValue`] = parseFloat(pNetValue).toFixed(2);
				vatTaxes[`vatEuro`] = parseFloat(pVatEuro).toFixed(2);
				vatTaxes[`withholdTaxes`] = parseFloat(pWithholdTaxes).toFixed(2);
				vatTaxes[`otherTaxes`] = parseFloat(pOtherTaxes).toFixed(2);
				vatTaxes[`fees`] = parseFloat(pFees).toFixed(2);
				vatTaxes[`stamp`] = parseFloat(pStamp).toFixed(2);
				vatTaxes[`taxes`] = parseFloat(taxes).toFixed(2);
				vatTaxes[`totalValue`] = parseFloat(parseFloat(pNetValue) + parseFloat(pVatEuro) + parseFloat(taxes) - 2*pWithholdTaxes).toFixed(2);
				taxesData.push(vatTaxes);
			}
		});
	}
	return taxesData;
}
// Get Totals of fields by data
export const getTotalsOfField = (data, field = '') => {
	let totalValue = 0;
	data.forEach((item) => {
		totalValue += parseFloat(item[field]);
	});
	return totalValue;
}
/*
* parse Int Value
*/
export const parseNumber = (value, defaultValue = 0) => {

	if (value && (value === '' || value === undefined || value === 'undefined' || isNaN(value))) {
		return defaultValue;
	}

	return value;
}

// Get Object by fields
export const salesDuplicateProductCheck = (data, filter, index = -1) => {
	if (Array.isArray(data) && data.length > 0) {
		let newData;
		newData = data.filter(function(item, idx) {
			let dcheck = true;
			for (let key in filter) {
				if (item[key]?.toString() !== filter[key]?.toString()) {
					dcheck = false;
				}
			}

			// If found on same index
			if ((idx === index) && dcheck) {
				return false;
			}

			return dcheck;
		});
		return newData.length > 0;
	}
	return false;
}
