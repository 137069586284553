import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import EditableTable from "../../../primer/Editable-Table";
import {
    addAccountingRecordCategory,
    fetchAccountingAdvancedTypes,
    fetchAccountingRecordCategories,
    updateAccountingRecordCategory
} from "../../../../_apis/api";
import {toast} from "react-toastify";
import {deleteObjectFromArrayOfObjects, getObjectFromArrayOfObjects} from "../../../../_helpers/helperFunctions";
import {
    resetNewAccountingInstance,
    resetNewAccountingRecordCategory,
    resetNewAccountingRecordSubCategory,
    setNewAccountingRecordCategory,
    setNewAccountingRecordSubCategory,
    setNewAccountInstance
} from "../../../../_reducers/DataPanel/Accounting/accountingSlice";
import {
    checkPermission,
    getDefaultOptionByValue,
    getOptionsByData, getRecordTypeOptions,
    resetTableItemsId
} from "../../../../_helpers/commonFunctions";
import Dropdown from "../../../primer/Dropdown";
import Input from "../../../common/Input";
import {Button, Col, Row} from "react-bootstrap";
import axios from "axios";

export const AccountingRecordSettings = () => {
    const [t] = useTranslation("common");
    const dispatch = useDispatch();
    const ACCOUNTING_DATA = useSelector( (state) => state.ACCOUNTING_DATA );
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const [editing, setEditing] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState({});
    const [selectedSubCategory, setSelectedSubCategory] = useState({});
    const [subCategories, setSubCategories] = useState([]);
    const accountingAdvancedTypes = ACCOUNTING_DATA.accountingAdvancedTypes;
    const documentTypesOptions = getOptionsByData(accountingAdvancedTypes, "_id", "name");
    const [showSubCategories, setShowSubCategories] = useState(false);
    const [showExtraDetails, setShowExtraDetails] = useState(false);
    const [pRequestData, setPRequestData] = useState({});
    const [accountsList, setAccountsList] = useState(ACCOUNTING_DATA.accounts?.slice());
    const permissionsData = JSON.parse(localStorage.getItem("permissions"));

    useEffect(() => {
        dispatch(fetchAccountingRecordCategories(company.id));
        dispatch(fetchAccountingAdvancedTypes(company.id));
    },[]);

    useEffect(() => {
        if (selectedSubCategory?.documentType) {
            loadAccountListData(selectedSubCategory.documentType);
        } else {
            loadAccountListData();
        }
    },[selectedSubCategory.documentType]);

    const accountingRecordCategoryColumns = [
        {
            name: t("General.categoryName"),
            field: "name",
            width: "80%",
            editable:true,
            inputType: {
                type: 'text',
            }
        }
    ];

    const accountingRecordSubCategoryColumns = [
        {
            name: t("General.subcategoryName"),
            field: "name",
            width: "80%",
            editable:true,
            inputType: {
                type: 'text',
            }
        }
    ];

    const accountsColumns = [
        {
            label: t("AccountingAccountNew.accountingCode.name"),
            name: "code",
        },
        {
            label: t("AccountingAccountNew.name.name"),
            name: "name",
        },
        {
            label: t("AccountingAccountNew.alternativeName.name"),
            name: "alternativeName",
        }
    ];

    const creditDebitDisableOptions = [
        { label : t("General.active"), value : "active" },
        { label : t("General.inactive"), value : "inactive" },
    ]

    const accountingAccountsTableColumns = [
        {
            name: t("AccountingAccountNew.accountingCode.name"),
            field: "code",
            width: "35%",
            editable:true,
            inputType: {
                config: {
                    excludeColumns: [],
                    columns: accountsColumns,
                    data: accountsList,
                    editEnable: false,
                    deleteEnable: false,
                    searchTableEnable: false,
                    showTableOptionOnSelect: true,
                    placeholder: t("AccountingAccountNew.accountingCode.placeholder")
                },
                type: 'advanced-combobox',
            }
        },
        {
            name: t("AccountingAccountNew.name.name"),
            field: "name",
            editable: true,
            width: "35%",
            inputType: {
                config: {
                    excludeColumns: [],
                    columns: accountsColumns,
                    data: accountsList,
                    editEnable: false,
                    deleteEnable: false,
                    searchTableEnable: false,
                    showTableOptionOnSelect: true,
                    placeholder: t("AccountingAccountNew.name.placeholder")
                },
                type: 'advanced-combobox',
            }
        },
        {
            name: t("General.debit"),
            field: "isDebit",
            width: "15%",
            editable: true,
            inputType: {
                type: 'dropdown',
                options: creditDebitDisableOptions
            }
        },
        {
            name: t("General.debit"),
            field: "isCredit",
            width: "15%",
            editable: true,
            inputType: {
                type: 'dropdown',
                options: creditDebitDisableOptions
            }
        },
    ]
    const requestData = {
        company: company.id,
        isActive: 'active'
    }
    const loadAccountListData = (value) => {
        let params = {
            id: value ? value : "",
            company : company.id
        }
        setPRequestData({...params});
        axios.get(process.env.REACT_APP_API_URL2 + `/accounting/account-list-by-advanced-type-id`,{
            headers: {"Content-Type": "application/json"},
            params: params
        }).then(res => {
            if (res.data && res.data.status === '200') {
                if (res.data.data) {
                    setAccountsList(res.data.data);
                } else {
                    setAccountsList([]);
                }
            } else {
                setAccountsList([]);
            }
        }).catch((err) => console.log(err))
    }

    const parentActions = {
        insert: (e, data) => {
            let tempNewCategory = Object.assign({},  ACCOUNTING_DATA.newAccountingRecordCategory);
            if (e.key !== "Enter") {
                tempNewCategory[data.field] = data.value;
                dispatch(setNewAccountingRecordCategory(tempNewCategory)); // Update redux new category state
                // On Value Enter
                if (tempNewCategory.name !== "") {
                    console.log(tempNewCategory)
                    dispatch(addAccountingRecordCategory({...tempNewCategory, ...requestData}, requestData));  // Add New Category API call
                    dispatch(resetNewAccountingRecordCategory()); // Reset new category data
                }
            }
            if (e.key === "Enter") {
                if (data.field === 'name' && data.value === '') {
                    toast.error("Category name should not be empty.");
                } else {
                    let updateNewCategory = Object.assign({}, ACCOUNTING_DATA.newAccountingRecordCategory);
                    if (data.field === 'name' && data.value !== '') {
                        updateNewCategory.name = data.value;
                    }
                    if (updateNewCategory?.name !== '') {
                        dispatch(addAccountingRecordCategory({...updateNewCategory, ...requestData}, requestData));  // Add New Category API call
                        dispatch(resetNewAccountingRecordCategory()); // Reset new category data
                    } else {
                        toast.error("Category name should not be empty.");
                    }
                }
            }
        },
        selected: (data) => {
            if (data._id) {
                axios.post(process.env.REACT_APP_API_URL2 + `/accounting/accounting-record-category-list`, {id: data._id}, {
                        headers: {"Content-Type": "application/json"},
                    })
                    .then((res) => {
                        if (res.data && res.data.status === "200") {
                            console.log(res.data.data);
                            setSelectedCategory(res.data.data);
                            setShowSubCategories(true);
                            setSubCategories(res.data.data?.subCategories);
                        } else {
                            toast.error(res.data.message);
                            setShowSubCategories(false);
                        }
                    })
                    .catch((error) => {
                        toast.error("Κάτι πήγε λάθος κατά την ανάκτηση της συγκεκριμένης κατηγορίας.");
                        console.log(error);
                        setShowSubCategories(false);
                    });
            } else {
                setShowSubCategories(false);
            }
        },
        update: (data, id) => {
            if (data?.name === '') {
                toast.error('Category name should not be empty.');
            } else {
                let tempCategoryData = ACCOUNTING_DATA.accountingRecordCategories.slice();
                if (tempCategoryData) {
                    const objindx = tempCategoryData.findIndex(obj => obj._id === id);
                    let catObj = tempCategoryData[objindx];
                    let tempUpdateObj  = {...catObj, ...data}
                    tempUpdateObj.id = id;
                    setSelectedCategory(tempUpdateObj);
                    dispatch(updateAccountingRecordCategory(tempUpdateObj, 'update', requestData));
                    setEditing(true);
                }
            }
        },
        delete: (id) => {
            let deleteCategory = getObjectFromArrayOfObjects(ACCOUNTING_DATA.accountingRecordCategories, id,'_id')['0'];
            const deleteItem =  {...deleteCategory, ...{ id: id, isActive: 'inactive', deleted: true }};
            dispatch(updateAccountingRecordCategory(deleteItem, 'delete', requestData)); // Update API Call
        }
    }

    const parentSubCategoryActions = {
        insert: (e, data) => {
            let tempNewCategory = Object.assign({},  ACCOUNTING_DATA.newAccountingRecordSubCategory);
            if (e.key !== "Enter") {
                tempNewCategory[data.field] = data.value;
                dispatch(setNewAccountingRecordSubCategory(tempNewCategory)); // Update redux new category state
                // On Value Enter
                if (tempNewCategory.name !== "") {
                    dispatch(updateAccountingRecordCategory({...selectedCategory , subCategories: [...selectedCategory.subCategories, tempNewCategory]}, 'update', requestData, setSubCategories, setSelectedCategory));  // Add New Category API call
                    dispatch(resetNewAccountingRecordSubCategory()); // Reset new category data
                }
            }
            if (e.key === "Enter") {
                if (data.field === 'name' && data.value === '') {
                    toast.error("Category name should not be empty.");
                } else {
                    let updateNewCategory = Object.assign({}, ACCOUNTING_DATA.newAccountingRecordCategory);
                    if (data.field === 'name' && data.value !== '') {
                        updateNewCategory.name = data.value;
                    }
                    if (updateNewCategory?.name !== '') {
                       dispatch(updateAccountingRecordCategory({...selectedCategory , subCategories: [...selectedCategory.subCategories, updateNewCategory]}, 'update', requestData, setSubCategories, setSelectedCategory));
                        dispatch(resetNewAccountingRecordSubCategory()); // Reset new category data
                    } else {
                        toast.error("Category name should not be empty.");
                    }
                }
            }
        },
        selected: (data) => {
            if (data._id) {
                const selected = getObjectFromArrayOfObjects(subCategories, data._id,'_id')['0'];
                setSelectedSubCategory(selected);
                setShowExtraDetails(true);
            } else {
                setShowExtraDetails(false);
            }
        },
        update: (data, id) => {
            if (data?.name === '') {
                toast.error('Category name should not be empty.');
            } else {
                let tempCategoryData = selectedCategory.subCategories.slice();
                if (tempCategoryData) {
                    const objindx = tempCategoryData.findIndex(obj => obj._id === id);
                    tempCategoryData[objindx] = {...tempCategoryData[objindx], ...data};
                    setSelectedSubCategory(tempCategoryData[objindx]);
                    dispatch(updateAccountingRecordCategory({...selectedCategory, subCategories: tempCategoryData}, 'update', requestData, setSubCategories, setSelectedCategory));
                    setEditing(true);
                }
            }
        },
        delete: (id) => {
            let subCategories = selectedCategory.subCategories.slice();
            if (id) {
                subCategories = deleteObjectFromArrayOfObjects(subCategories, id, '_id');
                dispatch(updateAccountingRecordCategory({...selectedCategory, subCategories: subCategories}, 'update', requestData, setSubCategories, setSelectedCategory));
            }
        }
    }
    const handleOnFieldChange = (e, name = '', inputType = '') => {
        setEditing(true);
        let accountingSubCategory = Object.assign({}, selectedSubCategory);
        if (inputType === 'ac' || inputType === 'dd') {
            accountingSubCategory[name] = e.value;
        } else {
            accountingSubCategory[name] = e.target.value;
        }
        setSelectedSubCategory({...accountingSubCategory});
    }
    const handleSaveSubCategory = () => {
        if (editing) {
            // In case of updating existing category
            if ((Object.keys(selectedSubCategory).length !== 0)) {
                if ((Object.keys(selectedSubCategory).length !== 0)) {
                    let subCategories = selectedCategory.subCategories.slice();
                    subCategories.map((category, index) => {
                        if (category._id === selectedSubCategory._id) {
                            subCategories[index] = selectedSubCategory;
                        }
                        return category;
                    })
                    setSelectedCategory({...selectedCategory, subCategories: subCategories});
                    dispatch(updateAccountingRecordCategory({...selectedCategory, subCategories: subCategories}, 'update', requestData, setSubCategories));
                }
                setEditing(false);
            }
        }
    }
    const parentAccountsActions = {
        insert: async (e, data) => {
            setEditing(true);
            let accountData = null;
            if (data && data?.field === 'code') {
                await axios.get(process.env.REACT_APP_API_URL2 + `/accounting/get-account`, {
                    headers: { "Content-Type": "application/json" },
                    params: {
                        code: data.value,
                        company: company.id,
                        movable: "active",
                    }
                })
                .then(res => {
                    if (res.data && res.data.data && res.data.status === '200') {
                        accountData = res.data.data;
                    }
                })
            }
            if (data && data?.field === 'name') {
                await axios.get(process.env.REACT_APP_API_URL2 + `/accounting/get-account`, {
                    headers: { "Content-Type": "application/json" },
                    params: {
                        name: data.value,
                        company: company.id,
                        movable: "active",
                    }
                })
                .then(res => {
                    if (res.data && res.data.data && res.data.status === '200') {
                        accountData = res.data.data;
                    }
                })
            }
            if (accountData && Object.keys(accountData).length > 0) {
                if (e.key !== "Enter") {
                    if(accountData.subAccount === "client" && selectedSubCategory.accounts?.findIndex((el) => el.subAccountType === "client") !== -1) {
                        toast.error("Μόνο ένας λογαριασμός με υποκατηγορία πελάτη μπορεί να υπάρχει.")
                        return;
                    }
                    if(accountData.subAccount === "supplier" && selectedSubCategory.accounts?.findIndex((el) => el.subAccountType === "supplier") !== -1) {
                        toast.error("Μόνο ένας λογαριασμός με υποκατηγορία προμηθευτή μπορεί να υπάρχει.")
                        return;
                    }
                    if(accountData.subAccount === "employee" && selectedSubCategory.accounts?.findIndex((el) => el.subAccountType === "employee") !== -1) {
                        toast.error("Μόνο ένας λογαριασμός με υποκατηγορία εργαζόμενο μπορεί να υπάρχει.")
                        return;
                    }
                    if(selectedSubCategory.accounts?.findIndex((el) => el.code === accountData.code) !== -1) {
                        toast.error("Ο λογαριασμός αυτός είναι ήδη επιλεγμένος.")
                        return;
                    }
                    let tempNewAccount = Object.assign({}, ACCOUNTING_DATA.newAccountInstance);
                    tempNewAccount._id = accountData._id;
                    tempNewAccount.no = selectedSubCategory.accounts.length + 1;
                    tempNewAccount.id = selectedSubCategory.accounts.length + 1;
                    tempNewAccount.code = accountData.code;
                    tempNewAccount.name = accountData.name;
                    tempNewAccount.isNetValue = accountData.isNetValue;
                    tempNewAccount.vat = accountData.vat;
                    tempNewAccount.vatreason = accountData.vatreason;
                    tempNewAccount.subAccountType = accountData.subAccount;
                    tempNewAccount.taxType = accountData.taxType;
                    tempNewAccount.withHoldTax = accountData.withHoldTax;
                    tempNewAccount.otherTax = accountData.otherTax;
                    tempNewAccount.stamp = accountData.stamp;
                    tempNewAccount.fee = accountData.fee;
                    tempNewAccount.mydata = accountData.mydata;
                    tempNewAccount.isDebit = "active";
                    tempNewAccount.isCredit = "active";
                    if (accountData.subAccount === "client") {
                        tempNewAccount.subAccountCategories = accountData.clientCategories;
                    } else if(accountData.subAccount === "supplier"){
                        tempNewAccount.subAccountCategories = accountData.supplierCategories;
                    } else if(accountData.subAccount === "employee"){
                        tempNewAccount.subAccountCategories = accountData.employeeCategories;
                    } else {
                        tempNewAccount.subAccountCategories = [];
                    }
                    tempNewAccount.subAccount = "";
                    tempNewAccount.credit = 0;
                    tempNewAccount.debit = 0;
                    dispatch(setNewAccountInstance(tempNewAccount));
                    let newAccounts = [...selectedSubCategory.accounts, tempNewAccount];
                    let accountingSubCategory = Object.assign({}, selectedSubCategory);
                    setSelectedSubCategory({...accountingSubCategory, accounts: newAccounts});
                    dispatch(resetNewAccountingInstance());
                }
            }
        },
        update: async (data, id) => {
            console.log(data);
            setEditing(true);
            let foundAccount;
            let accountData = null;
            let plAccounts = Object.assign([],selectedSubCategory.accounts);
            foundAccount = plAccounts.find(item => item.id === id);
            if (data.name && data.name === '') {
                toast.error("Name is mandatory field.");
            } else if (data.code && data.code === '') {
                toast.error("Code is mandatory field.");
            } else if (data.isDebit) {
                let updatedAccount = {...foundAccount};
                let foundAccountIndex = plAccounts.findIndex(item => item.id === id);
                updatedAccount.isDebit = data.isDebit;
                plAccounts[foundAccountIndex] = {...plAccounts[foundAccountIndex], ...updatedAccount};
                setSelectedSubCategory({...selectedSubCategory, accounts: plAccounts});
            } else if (data.isCredit) {
                let updatedAccount = {...foundAccount};
                let foundAccountIndex = plAccounts.findIndex(item => item.id === id);
                updatedAccount.isCredit = data.isCredit;
                plAccounts[foundAccountIndex] = {...plAccounts[foundAccountIndex], ...updatedAccount};
                setSelectedSubCategory({...selectedSubCategory, accounts: plAccounts});
            } else {
                if (foundAccount !== undefined) {
                    let updatedAccount = {...foundAccount};
                    let foundAccountIndex = plAccounts.findIndex(item => item.id === id);
                    let params = { company: company.id, movable: "active" }
                    if (data.code && data.code !== '') {
                        params.code = data.code;
                    } else if (data.name && data.name !== '') {
                        params.name = data.name;
                    }
                    // Get product data from api.
                    await axios.get(process.env.REACT_APP_API_URL2 + `/accounting/get-account`, {
                        headers: { "Content-Type": "application/json" },
                        params: params
                    })
                    .then(res => {
                        if (res.data && res.data.data && res.data.status === '200') {
                            accountData = res.data.data;
                        }
                    })
                    if (((data.code && data.code !== '') || (data.name && data.name !== ''))) {
                        updatedAccount._id = accountData._id;
                        updatedAccount.code = accountData.code;
                        updatedAccount.name = accountData.name;
                        updatedAccount.credit = 0;
                        updatedAccount.debit = 0;
                        updatedAccount.isNetValue = accountData.isNetValue;
                        updatedAccount.vat = accountData.vat;
                        updatedAccount.vatreason = accountData.vatreason;
                        updatedAccount.subAccountType = accountData.subAccount;
                        updatedAccount.mydata = accountData.mydata;
                        updatedAccount.taxType = accountData.taxType;
                        updatedAccount.withHoldTax = accountData.withHoldTax;
                        updatedAccount.otherTax = accountData.otherTax;
                        updatedAccount.stamp = accountData.stamp;
                        updatedAccount.fee = accountData.fee;
                        if(accountData.subAccount === "client"){
                            updatedAccount.subAccountCategories = accountData.clientCategories;
                        } else if(accountData.subAccount === "supplier"){
                            updatedAccount.subAccountCategories = accountData.supplierCategories;
                        } else if(accountData.subAccount === "employee"){
                            updatedAccount.subAccountCategories = accountData.employeeCategories;
                        } else {
                            updatedAccount.subAccountCategories = [];
                        }
                        updatedAccount.subAccount = "";
                    }
                    plAccounts[foundAccountIndex] = {...plAccounts[foundAccountIndex], ...updatedAccount};
                    setSelectedSubCategory({...selectedSubCategory, accounts: plAccounts});
                }
            }
        },
        selected: () => {},
        delete: (id) => {
            setEditing(true);
            let oldAccounts = selectedSubCategory.accounts.slice();
            if (id !== '' && !Number.isInteger(id)) {
                let deletedAccountsData = deleteObjectFromArrayOfObjects(oldAccounts, id, '_id');
                deletedAccountsData = resetTableItemsId(deletedAccountsData);
                setSelectedSubCategory({...selectedSubCategory, accounts: deletedAccountsData});
            }
        }
    }

    return (
        <React.Fragment>
            <div className="row">
                <p className="text-muted"><i>{t('AccountingRecordSettings.description')}</i></p>
                <div className="col-lg-6">
                    <div className="product-categories">
                        <EditableTable
                            tableName="Accounting Record Category" // Do not change name
                            selectedRow={selectedCategory._id}
                            onUpdate={parentActions}
                            key={Math.random()}
                            allowActions={false}
                            allowInsertRow={true}
                            data={ACCOUNTING_DATA.accountingRecordCategories}
                            columns={accountingRecordCategoryColumns}
                        />
                    </div>
                </div>
                {showSubCategories &&
                    <div className="col-lg-6 ">
                        <div className="product-categories">
                            <EditableTable
                                tableName="Accounting Record SubCategory" // Do not change name
                                selectedRow={selectedSubCategory._id}
                                onUpdate={parentSubCategoryActions}
                                key={Math.random()}
                                allowActions={false}
                                allowInsertRow={true}
                                data={subCategories}
                                columns={accountingRecordSubCategoryColumns}
                            />
                        </div>
                    </div>
                }
                {showExtraDetails ?
                    <>
                        <br/>
                        <p className="text-center border-bottom pb-3 pt-3 "><i>Ρυθμίσεις Υποκατηγορίας</i></p>
                        <Row>
                            <Col md={4}>
                                <Dropdown
                                    defaultValue={getDefaultOptionByValue(documentTypesOptions, selectedSubCategory.documentType, 'value', 'label', 'value')}
                                    key={Math.random()}
                                    label="Προεπιλεγμένος Τύπος Παραστατικού"
                                    onChange={(e) => handleOnFieldChange(e, 'documentType', 'dd')}
                                    options={documentTypesOptions}
                                />
                            </Col>
                            <Col md={4}>
                                <Dropdown
                                    key={Math.random()}
                                    label={"Τύπος Εγγραφής"}
                                    defaultValue={getDefaultOptionByValue(getRecordTypeOptions(), selectedSubCategory.recordType, 'value', 'label', 'value')}
                                    options={getRecordTypeOptions()}
                                    onChange={(e) => handleOnFieldChange(e, 'recordType', 'dd')}
                                />
                            </Col>
                        </Row>
                        <Row className={"mb-3"}>
                            <Col md={4}>
                                <Input
                                    label="Αιτία Λογιστικού Άρθρου"
                                    name="paymentReason"
                                    charLimit={150}
                                    placeholder={t('SalesGeneralSettings.paymentReason.placeholder')}
                                    value={selectedSubCategory.reason}
                                    onChange={(e) => handleOnFieldChange(e, 'reason')}
                                />
                            </Col>
                        </Row>
                        <Row className="row">
                            <p className="text-muted"><i>Προεπιλογή Λογιστικών Λογαριασμών του Άρθρου</i></p>
                            <div className="product-form sales-products-list position-relative">
                            <EditableTable
                                tableName="Accounting Record Accounts" // Do not change name
                                key={Math.random()}
                                onUpdate={parentAccountsActions}
                                allowActions={true}
                                allowInsertRow={true}
                                pRequestData={pRequestData}
                                columns={accountingAccountsTableColumns}
                                data={selectedSubCategory?.accounts ? selectedSubCategory.accounts : []}
                                enableNewRowCheckbox={true}
                            />
                        </div>
                        </Row>
                    </> : ''}
                <div className="d-flex justify-content-end">
                    {checkPermission("accounting-settings/recordtemplates-settings-write", permissionsData) &&
                        <Button disabled={!editing} onClick={(e) => handleSaveSubCategory(e)}
                            className="ml-2 mb-3">{t('ProductsSettings.save')}</Button>
                    }
                </div>
            </div>
        </React.Fragment>
    )
}