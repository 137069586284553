import {createSlice} from "@reduxjs/toolkit";
import moment from "moment";

const initialShift = {
    name: "",
    hourFrom: "00:00",
    hourTo: "00:00",
    splitHourFrom: "00:00",
    splitHourTo: "00:00",
}

const initialWorkMovement = {
    date: moment().toDate(),
    time: "00:00",
    employeeName: "", // firstName lastName vatNumber
    employeeId: "",
    employeeProgramData: {},
    installationMasterId: "",
    movementType: "arrive", // arrive, leave
    delayReason: "", // 001, 002, 003
    notes: "",
}

const initialPermit = {
    employeeId: "",
    employeeIdentifier: "",
    permitType: "",
    dateFrom: "",
    dateTo: "",
}

const initialWorkPermit = {
    description: "",
    installationMasterId: "0",
    employees: [],
    notes: "",
}

const initialState = {
    companyHasErgani: false,
    payrollSettings: {
        autoSendEmployeeCard: false,
        autoSendOutOfTimeDays: false,
        shifts: [],
    },
    tempNewShift: initialShift,
    workingProgram: null,
    workingProgramOnEmployee: null,
    workingProgramNeedsSave: false,

    newWorkMovement: initialWorkMovement,
    editWorkMovement: initialWorkMovement,

    newWorkPermit: initialWorkPermit,
    tempNewWorkPermit: initialPermit,
    editWorkPermit: initialWorkPermit,

    searchWorkMovements: [],
    totalItemsWM: 0,
    perPageWM: 0,

    searchWorkPermits: [],
    totalItemsWP: 0,
    perPageWP: 0,

    qrScannerRequestData: {
        installationMasterId: "0",
    },
    qrScannerResponseData: {
        data: [],
    },

    // Ergani Submissions Report
    erganiSubmissionsReportRequestData: {
        page: 1,
        date_from: "",
        date_to: "",
        installationMasterId: "",
        submissionType: "",
    },
    erganiSubmissionsReportResponseData: {
        totalPages: 0,
        data: [],
    }
}

export const PayrollSlice = createSlice({
    name: "payroll",
    initialState,
    reducers: {
        setCompanyHasErgani: (state, {payload}) => {
            state.companyHasErgani = payload;
        },
        setPayrollSettings: (state, {payload}) => {
            state.payrollSettings = payload;
        },
        setTempNewShift: (state, {payload}) => {
            state.tempNewShift = payload;
        },
        resetTempNewShift: (state) => {
            state.tempNewShift = initialShift;
        },
        setWorkingProgram: (state, {payload}) => {
            state.workingProgram = payload;
        },
        setWorkingProgramOnEmployee: (state, {payload}) => {
            state.workingProgramOnEmployee = payload;
        },
        setWorkingProgramNeedsSave: (state, {payload}) => {
            state.workingProgramNeedsSave = payload;
        },

        // Work Movements
        setNewWorkMovement: (state, {payload}) => {
            state.newWorkMovement = payload;
        },
        setEditWorkMovement: (state, {payload}) => {
            state.editWorkMovement = payload;
        },
        resetNewWorkMovement: (state) => {
            state.newWorkMovement = initialWorkMovement;
        },
        setSearchWorkMovements: (state, {payload}) => {
            state.searchWorkMovements = payload;
        },
        setSearchWorkMovementsTotalItems: (state, {payload}) => {
            state.totalItemsWM = payload;
        },
        setSearchWorkMovementsPerPage: (state, {payload}) => {
            state.perPageWM = payload;
        },

        // Work Permits
        setNewWorkPermit: (state, {payload}) => {
            state.newWorkPermit = payload;
        },
        resetNewWorkPermit: (state) => {
            state.newWorkPermit = initialWorkPermit;
        },
        setTempNewWorkPermit: (state, {payload}) => {
            state.tempNewWorkPermit = payload;
        },
        resetTempNewWorkPermit: (state) => {
            state.tempNewWorkPermit = initialPermit;
        },
        setEditWorkPermit: (state, {payload}) => {
            state.editWorkPermit = payload;
        },
        setSearchWorkPermits: (state, {payload}) => {
            state.searchWorkPermits = payload;
        },
        setSearchWorkPermitsTotalItems: (state, {payload}) => {
            state.totalItemsWP = payload;
        },
        setSearchWorkPermitsPerPage: (state, {payload}) => {
            state.perPageWP = payload;
        },

        setQRScannerRequestData: (state, {payload}) => {
            state.qrScannerRequestData = payload;
        },
        setQRScannerResponseData: (state, {payload}) => {
            state.qrScannerResponseData = payload;
        },

        // Ergani Submissions Report
        setErganiSubmissionsReportRequestData: (state, {payload}) => {
            state.erganiSubmissionsReportRequestData = payload;
        },
        setErganiSubmissionsReportResponseData: (state, {payload}) => {
            state.erganiSubmissionsReportResponseData = payload;
        }
    }
})

export const {
    setCompanyHasErgani,
    setPayrollSettings,
    setTempNewShift,
    resetTempNewShift,
    setWorkingProgram,
    setWorkingProgramOnEmployee,
    setWorkingProgramNeedsSave,

    // Work Movements
    setNewWorkMovement,
    setEditWorkMovement,
    resetNewWorkMovement,
    setSearchWorkMovements,
    setSearchWorkMovementsTotalItems,
    setSearchWorkMovementsPerPage,

    // Work Permits
    setNewWorkPermit,
    resetNewWorkPermit,
    setTempNewWorkPermit,
    resetTempNewWorkPermit,
    setEditWorkPermit,
    setSearchWorkPermits,
    setSearchWorkPermitsTotalItems,
    setSearchWorkPermitsPerPage,

    setQRScannerRequestData,
    setQRScannerResponseData,

    // Ergani Submissions Report
    setErganiSubmissionsReportRequestData,
    setErganiSubmissionsReportResponseData,
} = PayrollSlice.actions;
