import React, {useEffect, useState} from "react";
import {Button, Col, Row, Spinner, Table} from "react-bootstrap";
import axios from "axios";
import {toast} from "react-toastify";
import {useDispatch, useSelector} from "react-redux";
import {
    setAccountingYearReportRequestData,
    setAccountingYearReportResponseData
} from "../../../../_reducers/DataPanel/Accounting/accountingSlice";
import Dropdown from "../../../primer/Dropdown";
import DateHelper from "../../../primer/DateHelper/DateHelper";
import DateBox from "../../../primer/DateBox";
import moment from "moment";
import * as XLSX from "xlsx";
import {jsPDF} from "jspdf";
import {liberationSerif} from "../../../../fonts/liberationSerif";
import {dejavuSans} from "../../../../fonts/DejaVuSans-normal";
import {dejavuSansBold} from "../../../../fonts/DejaVuSans-Bold-bold";
import {isEmpty} from "../../../../_helpers/commonFunctions";
import {classicStyleBelowNavbar} from "../Statics";

const AccountingYearReport = () => {
    const dispatch = useDispatch();
    const requestData = useSelector((state) => state.ACCOUNTING_DATA.accountingYearReportRequestData);
    const responseData = useSelector((state) => state.ACCOUNTING_DATA.accountingYearReportResponseData);
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const [loading, setLoading] = useState(false);
    const userId = JSON.parse(localStorage.getItem("user"))?._id;

    const [companyAccess, setCompanyAccess] = useState([]);

    useEffect(() => {
        getCompanyAccess();
    }, []);

    const getCompanyAccess = () => {
        axios.get(`${process.env.REACT_APP_API_URL2}/company/get-user-companies-access`, {
            headers: {"Content-Type": "application/json"},
            params: {
                userId: userId,
            }
        }).then((res) => {
            if (res.data.status === "200") {
                let acc = [];
                acc.push({label: "All Companies", value: "allCompanies"});
                for (let comp of res.data.data) {
                    acc.push({label: comp.name, value: comp._id});
                }
                setCompanyAccess(acc);
                if (requestData.firstLoad === true) dispatch(setAccountingYearReportRequestData({...requestData, forCompany: [company.id], firstLoad: false}));
            }
        }).catch((err) => {
            console.log(err);
        })
    }

    const fetchResults = (showMsg = true) => {
        setLoading(true);
        axios.get(process.env.REACT_APP_API_URL2 + "/accounting/get-yearly-results", {
            headers: { "Content-Type": "application/json" },
            params: {
                date_from: requestData.date_from,
                date_to: requestData.date_to,
                forCompany: requestData.forCompany,
                userId: userId,
                year: company.year,
            }
        }).then((res) => {
            setLoading(false);
            if (res.data.status === "200") {
                let cols = [];
                for (let comp of res.data.data) {
                    for (let ruleName of Object.keys(comp)) {
                        if (!cols.includes(ruleName) && ruleName !== "company") cols.push(ruleName);
                    }
                }
                if (cols.length === 0 && res.data.data?.length > 0) cols.push("");
                dispatch(setAccountingYearReportResponseData({...responseData, data: res.data.data, columns: cols}));
                //if (showMsg) toast.success("Τα δεδομένα ανανεώθηκαν");
            } else {
                toast.error(res.data.message);
            }
        }).catch((err) => {
            setLoading(false);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
            console.log(err);
        })
    }

    const handleOnChange = (e, type, name) => {
        if (type === "msdd") {
            let vals = [];
            if (e?.length > 0) {
                for (let v of e) {
                    vals.push(v.value);
                }
            }
            dispatch(setAccountingYearReportRequestData({...requestData, [name] : vals}));
        } else if (type === "datebox") {
            if (moment(e).isValid()) {
                dispatch(setAccountingYearReportRequestData({...requestData, [name] : moment(e).format("DD/MM/YYYY")}));
            } else {
                dispatch(setAccountingYearReportRequestData({...requestData, [name] : ""}));
            }
        }
    }

    const handlePDFExport = () => {
        const doc = new jsPDF('landscape');
        let y = 20;
        doc.addFileToVFS("arialuni.ttf", liberationSerif);
        doc.addFont("arialuni.ttf", "Arial Unicode MS", "normal");
        doc.addFileToVFS("dejavusans.ttf", dejavuSans);
        doc.addFont("dejavusans.ttf", "DejaVu Sans Normal", "normal");
        doc.addFileToVFS("dejavusansBold.ttf", dejavuSansBold);
        doc.addFont("dejavusansBold.ttf", "DejaVu Sans Bold", "normal");
        doc.setFont("DejaVu Sans Bold");
        doc.setFontSize(12);
        let dateFrom = requestData.date_from;
        let dateTo = requestData.date_to;
        if (isEmpty(dateFrom)) dateFrom = moment(company.year, "YYYY", true).startOf("year").format("DD/MM/YYYY");
        if (isEmpty(dateTo)) dateTo = moment(company.year, "YYYY", true).endOf("year").format("DD/MM/YYYY");
        const header = doc.splitTextToSize(`Ετήσια Αποτελέσματα περιόδου από ${dateFrom} έως ${dateTo}`, 200);
        const headerString = header.join("\n");
        doc.text(headerString, doc.internal.pageSize.getWidth() / 4, 9, {alignment: "center"});
        doc.setFont("DejaVu Sans Normal");
        const imgData = 'assets/images/prrimer-logo.png';
        const imgX = doc.internal.pageSize.getWidth() - 45; // adjust the position as needed
        const imgY = 3; // adjust the position as needed
        doc.addImage(imgData, 'PNG', imgX, imgY, 40, 15);
        doc.setFont("DejaVu Sans Normal");
        let columns = ["Εταιρεία", ...responseData.columns];
        let rows = [];
        for (let row of responseData.data) {
            let arrRow = [row.company]
            for (let col of responseData.columns) {
                if (row[col]) {
                    arrRow.push(row[col]);
                } else {
                    arrRow.push("");
                }
            }
            rows.push(arrRow);
        }
        doc.autoTable({
            startY: y,
            head: [columns],
            body: rows,
            rowPageBreak: "avoid",
            styles: {
                font: "DejaVu Sans Normal",
                fontSize: 8, // set font size
                halign: "center", // set alignment
            },
        })
        const pageCount = doc.getNumberOfPages();
        // Add the pagination to each page
        for (let i = 1; i <= pageCount; i++) {
            doc.setPage(i);
            doc.setFontSize(10);
            doc.text(`Σελίδα ${i} από ${pageCount}`, 10, doc.internal.pageSize.getHeight() - 10);
        }
        doc.save("Ετήσια Αποτελέσματα.pdf");
    }

    const handleExcelExport = () => {
        let rows = [];
        rows.push(["Εταιρεία", ...responseData.columns]);

        for (let row of responseData.data) {
            let arrRow = [row.company]
            for (let col of responseData.columns) {
                if (row[col]) {
                    arrRow.push(row[col]);
                } else {
                    arrRow.push("");
                }
            }
            rows.push(arrRow);
        }
        const workSheet = XLSX.utils.aoa_to_sheet(rows);
        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, workSheet, "Ετήσια Αποτελέσματα");
        XLSX.writeFile(workBook, 'Ετήσια Αποτελέσματα.xlsx');
    }

    return (
        <div style={classicStyleBelowNavbar}>
            <Row>
                <Col md={3}>
                    <DateHelper
                        dateFromAndToFunction={(from, to) => dispatch(setAccountingYearReportRequestData({...requestData, date_from: from, date_to: to}))}
                    />
                </Col>
                <Col md={2}>
                    <DateBox
                        name={"date_from"}
                        label={"Ημερομηνία από"}
                        selected={moment(requestData.date_from, "DD/MM/YYYY").isValid() ? moment(requestData.date_from, "DD/MM/YYYY").toDate() : ""}
                        onChange={(e) => handleOnChange(e, "datebox", "date_from")}
                    />
                </Col>
                <Col md={2}>
                    <DateBox
                        name={"date_to"}
                        label={"Ημερομηνία μέχρι"}
                        selected={moment(requestData.date_to, "DD/MM/YYYY").isValid() ? moment(requestData.date_to, "DD/MM/YYYY").toDate() : ""}
                        onChange={(e) => handleOnChange(e, "datebox", "date_to")}
                    />
                </Col>
                <Col md={5}>
                    <Dropdown
                        name={"forCompany"}
                        label={"Εταιρείες"}
                        multiSelect={true}
                        options={companyAccess}
                        key={Math.random()}
                        defaultValue={companyAccess.filter((el) => requestData.forCompany.includes(el.value))}
                        onChange={(e) => handleOnChange(e, "msdd", "forCompany")}
                    />
                </Col>
            </Row>
            <Row>
                <Col md={3}>
                    <Button onClick={() => fetchResults()} className={"mt-3"} disabled={loading}>Ανανέωση {loading && <Spinner className={"ml-2"} animation={"border"} variant={"dark"} />}</Button>
                </Col>
            </Row>
            {(responseData?.data && responseData?.data?.length > 0 && responseData.columns?.length > 0) && (
                <React.Fragment>
                    <hr/>
                    <Row>
                        <Col md={12} className={"mb-3"}>
                            <Button size={"sm"} onClick={() => handleExcelExport()}>Εξαγωγή XLSX</Button>
                            <Button size={"sm"} className={"ml-2"} onClick={() => handlePDFExport()}>Εξαγωγή PDF</Button>
                        </Col>
                        <Col md={12}>
                            <Table bordered hover>
                                <thead>
                                <tr>
                                    <th>Εταιρεία</th>
                                    {responseData.columns.map((ruleName) => (
                                        <th>{ruleName}</th>
                                    ))}
                                </tr>
                                </thead>
                                <tbody>
                                {responseData.data.map((row, idx) => (
                                    <tr key={`comp${idx}`}>
                                        <td>{row.company}</td>
                                        {responseData.columns.map((ruleName) => (
                                            <th>{row[ruleName]}</th>
                                        ))}
                                    </tr>
                                ))}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </React.Fragment>
            )}
        </div>
    )
}

export default AccountingYearReport