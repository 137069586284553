import React, {useEffect, useState} from "react";
import Input from "../../../common/Input";
import {Col, Container, Row} from "react-bootstrap";
import Dropdown from "../../../primer/Dropdown";
import {typeAffectsOptions, typeAffectsOptionsSearch,} from "../../../../_data/settings/doc-types";
import EditableTable from '../../../primer/Editable-Table';
import {preNumberGreek} from '../../../../_data/settings/advanced-types-search';
import {useDispatch, useSelector} from "react-redux";
import {setSaveAdvancedType, setSaveBasicType,} from "../../../../_reducers/activitySlice";
import {
  resetNewDocumentNumbering,
  setNewAdvancedType,
  setNewDocumentNumbering,
  setSettings
} from "../../../../_reducers/settingsSlice";
import {
  fetchAdvancedTypes,
  fetchAdvancedTypesBuys,
  fetchAdvancedTypesSales,
  fetchBankAccounts,
  fetchBasicTypes,
  fetchBuysGeneralSettings,
  fetchPOS,
  fetchSalesGeneralSettings
} from "../../../../_apis/api";
import {
  buysPrintTemplates,
  checkPermission,
  getDefaultOptionByValue,
  getDefaultOptionObject,
  getLastHighestValue,
  getOptionsByData,
  getPrintPreference,
  isEmpty,
  resetAdvancedTypeItemsID,
  salesPrintTemplates,
  setAdvancedTypesSelectedRow,
} from "../../../../_helpers/commonFunctions";
import Checkbox from "../../../common/CheckBox";
import {toast} from "react-toastify";
import {
  deleteObjectFromArrayOfObjects,
  getLabelByValue,
  getObjectFromArrayOfObjects,
  isValueExists
} from "../../../../_helpers/helperFunctions";
import {getHighlightData, getInvoiceTypesOptions, getSalesPurposeData} from "../../../../_helpers/sharedmydata";
import SearchTable from "../../../primer/Search-Table";
import {useTranslation} from "react-i18next";
import BetterCheckBox from "../../../common/BetterCheckBox";
import {classicStyleBelowNavbar} from "../Statics";

export const BasicTypesNew = () => {
  const [t] = useTranslation('common');
  const dispatch = useDispatch();
  const ACTIVITY = useSelector((state) => state.ACTIVITY);
  const SETTINGS = useSelector((state) => state.SETTINGS);
  const handleOnChange = (e, type = "default", ddname = '') => {
    // Set if editing the form
    if (!ACTIVITY.saveBasicType) dispatch(setSaveBasicType(true));
    let name;
    let value;
    if (type === 'dd') {
      name = ddname;
      value = e.value;
    } else {
      name = e.target.name;
      value = e.target.value;
    }
    // Set settings data
    let newSettings = Object.assign({}, SETTINGS.settings);
    let newSettingsData = {};
    newSettingsData[name] = value;
    newSettings.newBasicType = {...newSettings.newBasicType, ...newSettingsData}
    dispatch(setSettings(newSettings));
  }

  return (
      <Container fluid className="p-0">
        <Row>
          <Col sm="12" md="6">
            <Input
                label={t('DocumentTypeBasicNew.name.name')}
                name="name"
                value={SETTINGS.settings.newBasicType.name}
                placeholder={t('DocumentTypeBasicNew.name.placeholder')}
                required={true}
                charLimit="20"
                autoComplete="off"
                onChange={(e) => handleOnChange(e, 'default', 'name')}
            />
            {SETTINGS.settings.newBasicType.name === '' && ACTIVITY.editing &&
                <span className="text-danger">Name is a required field.</span>}
            {isValueExists(SETTINGS.settings.basicTypes, SETTINGS.settings.newBasicType.name, 'name') &&
                SETTINGS.settings.newBasicType.name !== '' &&
                <div className="text-danger">Name already exists.</div>
            }
          </Col>
          <br/>
          <Col sm="12" md="6">
            <Dropdown
                label={t('DocumentTypeBasicNew.typeAffectsInventory')}
                options={typeAffectsOptions}
                name="affects_inventory"
                enabledValue={true}
                required={true}
                value={getDefaultOptionObject(SETTINGS.settings.newBasicType.affects_inventory)}
                onChange={(e) => handleOnChange(e, 'dd', 'affects_inventory')}
            />
          </Col>
          <br/>
          <Col sm="12" md="6">
            <Dropdown
                label={t('DocumentTypeBasicNew.typeAffectsClient')}
                options={typeAffectsOptions}
                enabledValue={true}
                required={true}
                value={getDefaultOptionObject(SETTINGS.settings.newBasicType.affects_client)}
                name="affects_client"
                onChange={(e) => handleOnChange(e, 'dd', 'affects_client')}
            />
          </Col>
          <br/>
          <Col sm="12" md="6">
            <Dropdown
                label={t('DocumentTypeBasicNew.typeAffectsSupplier')}
                options={typeAffectsOptions}
                name="affects_supplier"
                enabledValue={true}
                required={true}
                value={getDefaultOptionObject(SETTINGS.settings.newBasicType.affects_supplier)}
                onChange={(e) => handleOnChange(e, 'dd', 'affects_supplier')}
            />
          </Col>
        </Row>
      </Container>
  );
}

export const BasicTypesSearch = () => {
  const [t] = useTranslation('common');
  const basicTypesColumns = [
    {
      name: t('DocumentTypeBasicNew.table.basicTypeName'),
      field: "name",
      editable: false,
      inputType: {
        type: 'text',
      }
    },
    {
      name: t('DocumentTypeBasicNew.table.typeAffectsInventory'),
      field: "affects_inventory",
      editable: false,
      inputType: {
        type: 'select',
        options: typeAffectsOptions,
      }
    },
    {
      name: t('DocumentTypeBasicNew.table.typeAffectsClient'),
      field: "affects_client",
      editable: false,
      inputType: {
        type: 'select',
        options: typeAffectsOptions,
      }
    },
    {
      name: t('DocumentTypeBasicNew.table.typeAffectsSupplier'),
      field: "affects_supplier",
      editable: false,
      inputType: {
        type: 'select',
        options: typeAffectsOptions,
      }
    },
  ]

  const searchBasicTypeFilters = {
    filters: [
      {
        name: "name",
        label: t('DocumentTypeBasicNew.tableSearch.basicTypeNameFrom'),
        type: "input",
      },
      {
        name: "affects_inventory",
        label: t('DocumentTypeBasicNew.tableSearch.typeAffectsInventory'),
        type: "dropdown",
        options: typeAffectsOptionsSearch
      },
      {
        name: "affects_client",
        label: t('DocumentTypeBasicNew.tableSearch.typeAffectsClient'),
        type: "dropdown",
        options: typeAffectsOptionsSearch
      },
      {
        name: "affects_supplier",
        label: t('DocumentTypeBasicNew.tableSearch.typeAffectsSupplier'),
        type: "dropdown",
        options: typeAffectsOptionsSearch
      },
    ],
    actions: ["quick-edit", "delete"],
  };
  const [tableColumns, setTableColumns] = useState(basicTypesColumns.slice());
  // It will hide or show the table columns for searchtable.
  const handleColumnShowHide = (field) => {
    let tempTc = tableColumns.slice();
    let object = getObjectFromArrayOfObjects(tempTc, field, 'field')
    const index = tempTc.findIndex((item) => field === item.field);
    if (object.length > 0) {
      tempTc[index].hiddenColumn = !object['0'].hiddenColumn;
      setTableColumns(tempTc);
    }
  }

  return (
      <div style={classicStyleBelowNavbar}>
        <SearchTable
            tableName="Basic Types Search"
            key={"Basic Types Search"}
            hideYearFilter={true}
            allowToggleColumns={true}
            allowPagination={true}
            columns={tableColumns}
            toggleColumns={handleColumnShowHide}
            data={[]}
            filtersConfig={searchBasicTypeFilters}
        />
      </div>
  )
}

export const AdvancedTypesNew = () => {
  const dispatch = useDispatch();
  const [t] = useTranslation('common');
  const printPreferenceOptions = getPrintPreference();
  const ACTIVITY = useSelector( (state) => state.ACTIVITY );
  const SETTINGS = useSelector( (state) => state.SETTINGS );
  const CASH_DATA = useSelector( (state) => state.CASH_DATA );
  const SALES_SETTINGS = useSelector((state) => state.SALES_SETTINGS);
  const BUYS_SETTINGS = useSelector((state) => state.BUYS_SETTINGS);
  const installations = useSelector((state) => state.COMPANY_DATA.companyInstallations);
  const permissionsData = JSON.parse(localStorage.getItem("permissions"));

  useEffect(() => {
    dispatch(fetchPOS(requestData));
    dispatch(fetchBankAccounts(requestData));
    dispatch(fetchBasicTypes(requestData))
    dispatch(fetchAdvancedTypes(requestData));
    dispatch(fetchAdvancedTypesSales(requestData));
    dispatch(fetchAdvancedTypesBuys(requestData));
    dispatch(fetchSalesGeneralSettings(requestData));
    dispatch(fetchBuysGeneralSettings(requestData));
  }, [dispatch])

  const advancedTypeSectionOptions = [
    { value: "Product Movements", label: t('AdvanceTypesNewDropDown.productMovements'), color: "#76AEB7" },
    { value: "Sales", label: t('AdvanceTypesNewDropDown.sales'), color: "#76AEB7" },
    { value: "Client Movements", label: t('AdvanceTypesNewDropDown.clientMovements'), color: "#76AEB7" },
    { value: "Client Payments", label: t('AdvanceTypesNewDropDown.clientPayments'), color: "#76AEB7" },
    { value: "Buys", label: t('AdvanceTypesNewDropDown.buys'), color: "#76AEB7" },
    { value: "Supplier Payments", label: t('AdvanceTypesNewDropDown.supplierPayments'), color: "#76AEB7" },
    { value: "Supplier Movements", label: "Κινήσεις Προμηθευτών", color: "#76AEB7" },
    { value: "Accounting Records", label: t('AdvanceTypesNewDropDown.accountingRecords'), color: "#76AEB7" },
  ]

  const installationsColumns = [
    {
      label: t('DocumentTypeAdvancedNew.tableSmall.address'),
      name: "address",
    },
    {
      label: t('DocumentTypeAdvancedNew.tableSmall.city'),
      name: "city",
    },
    {
      label: t('DocumentTypeAdvancedNew.tableSmall.type'),
      name: "type",
    },
  ];

  const numberingModeOptions = [{
    value: 'automatic',
    label: t('DocumentTypeAdvancedNew.table2.automatic')
  }, {
    value: 'manual',
    label: t('DocumentTypeAdvancedNew.table2.manual')
  }];

  const advancedDocumentColumns = [
    {
      name: "No",
      field: "no",
      width: '50px',
    },
    {
      name: t('DocumentTypeAdvancedNew.table.installation'),
      field: "installation",
      editable: true,
      inputType: {
        config: {
          excludeColumns: [],
          columns: installationsColumns,
          data: installations,
          editEnable: false ,
          deleteEnable: false,
          searchTableEnable: false,
          showTableOptionOnSelect: true,
          placeholder: t('DocumentTypeAdvancedNew.table.placeholder')
        },
        type: 'advanced-combobox',
      }
    },
    {
      name: t('DocumentTypeAdvancedNew.table.numberingMode'),
      field: "numberingMode",
      width: '130px',
      editable: true,
      inputType: {
        type: 'select',
        multiselect: true,
        options: numberingModeOptions
      }
    },
    {
      name: t('DocumentTypeAdvancedNew.table.preNumber'),
      field: 'preNumber',
      width: '100px',
      editable: true,
      inputType: {
        type: 'select',
        options: preNumberGreek
      }
    },
    {
      name: t('DocumentTypeAdvancedNew.table.selected'),
      field: 'selected',
      width: '120px',
      editable: true,
      inputType: {
        type: 'checkbox',
        options: [{
          value: 'true',
          label: t('DocumentTypeAdvancedNew.table.true')
        }, {
          value: 'false',
          label: t('DocumentTypeAdvancedNew.table.false')
        }]
      }
    },
    {
      name: t('DocumentTypeAdvancedNew.table.number'),
      field: 'number',
      width: '100px',
      editable: true,
      inputType: {
        type: 'text',
      }
    },
  ];
  // Same is used on Data Panel index.js file to show templates on Save New / Edit page
  let printTemplateOptions = [{ label: t("Null"), value: '' }];

  // Sale Print Templates
  if (SETTINGS.settings.newAdvancedType.section === "Sales") printTemplateOptions = salesPrintTemplates();
  if (SETTINGS.settings.newAdvancedType.section === "Buys") printTemplateOptions = buysPrintTemplates();

  let posData = CASH_DATA.posList;
  let bankData = CASH_DATA.bankAccounts;
  let posOptions =  getOptionsByData(posData, '_id', 'name');
  posOptions = [{value: '', label: t("Null")}, ...posOptions];
  if (SETTINGS.settings.newAdvancedType.section === "Client Payments" && SETTINGS.settings.newAdvancedType?.myDataType === "8.4") {
    let opt = [];
    for (let pos of posData) {
      if (pos?.isETameiaki === "active") {
        opt.push({
          value: pos._id,
          label: pos.name,
        })
      }
    }
    posOptions = opt;
  }
  let bankOptions =  getOptionsByData(bankData, '_id', 'name');
  bankOptions = [{value: '', label: t("Null")}, ...bankOptions];
  const company = useSelector(state => state.COMPANY_DATA.company)
  let requestData = { company:company.id }

  let myDataTypeOptions = SETTINGS.settings.newAdvancedType?.mydataProvider === "active" ? getInvoiceTypesOptions("provider", SETTINGS.settings.newAdvancedType?.section) : SETTINGS.settings.newAdvancedType?.section === "Buys" ? getInvoiceTypesOptions("buys") : getInvoiceTypesOptions();

  if(SETTINGS.settings.newAdvancedType?.section === "Accounting Records") {
    myDataTypeOptions = getInvoiceTypesOptions("all")
  } else if (SETTINGS.settings.newAdvancedType?.section === "Client Payments" && SETTINGS.settings.newAdvancedType?.mydataProvider === "active") {
    myDataTypeOptions = getInvoiceTypesOptions("provider", "Client Payments");
  }
  myDataTypeOptions = [{value: '', label: t("Null")}, ...myDataTypeOptions];
  const selectedMyDataType = getDefaultOptionByValue(myDataTypeOptions, SETTINGS.settings.newAdvancedType.myDataType, 'value', 'label', 'value')

  // Credit documents
  let creditDocumentData1 = getObjectFromArrayOfObjects(SETTINGS.settings.advancedTypesSales, '5.1', 'myDataType');
  let creditDocumentData2 = getObjectFromArrayOfObjects(SETTINGS.settings.advancedTypesSales, '11.4', 'myDataType');
  let creditDocumentData3 = getObjectFromArrayOfObjects(SETTINGS.settings.advancedTypesSales, '5.2', 'myDataType');
  let creditDocumentData = [...creditDocumentData1, ...creditDocumentData2, ...creditDocumentData3];
  let creditDocumentOptions = getOptionsByData(creditDocumentData, '_id', 'name');
  creditDocumentOptions = [{ value: "", label: t("Null") }, ...creditDocumentOptions];

  // Conversion documents
  let conversionDocumentOptions = getOptionsByData(SETTINGS.settings.advancedTypesSales, '_id', 'name');
  conversionDocumentOptions = [{ value: "", label: t("Null") }, ...conversionDocumentOptions];

  const proposedSalePurposeOptions = getSalesPurposeData(SETTINGS.primerMyDataData);
  const thirdPartySalesOptions = getHighlightData(SETTINGS.primerMyDataData);
  const installationOptions = getOptionsByData(installations, "installationMasterId", "address");

  let proposedPaymentTypeOptions = [];
  if (SALES_SETTINGS.salesGeneralSettings.paymentTypes && SALES_SETTINGS.salesGeneralSettings.paymentTypes instanceof Array) {
    for (let paymentType of SALES_SETTINGS.salesGeneralSettings.paymentTypes) {
      proposedPaymentTypeOptions.push({
        value: paymentType._id,
        label: paymentType.name,
        type: paymentType.type,
      })
    }
  }
  let proposedPaymentTypeOptionsBuys = getOptionsByData(BUYS_SETTINGS.buysGeneralSettings.paymentTypes, "_id", "name");

  let proposedPosBankAcountOptions = [];
  if(SETTINGS.settings.newAdvancedType.proposedPaymentTypeType === 'pos') {
    proposedPosBankAcountOptions = posOptions;
  }
  if(SETTINGS.settings.newAdvancedType.proposedPaymentTypeType === 'bank-account') {
    proposedPosBankAcountOptions = bankOptions;
  }
  let etDocumentNumbering = SETTINGS.settings.newAdvancedType.documentNumbering;

  let returnPaymentDocuments = [{value: "", label: "Κενό"}];
  if (SETTINGS.settings.advancedTypes) {
    let docs = SETTINGS.settings.advancedTypes.filter((el) => el.section === "Client Payments" && el?.myDataType !== "8.4");
    if(SETTINGS.settings.newAdvancedType?.myDataType !== "8.4") { // Filter out 8.5, if doc is not 8.4
      docs = docs.filter((el) => el.section === "Client Payments" && el?.myDataType !== "8.5");
    } else if (SETTINGS.settings.newAdvancedType?.myDataType === "8.4") { // Filter only 8.5, if doc is 8.4
      docs = docs.filter((el) => el.section === "Client Payments" && el?.myDataType === "8.5");
    }
    for (let doc of docs) {
      returnPaymentDocuments.push({ value: doc._id, label: doc.name });
    }
  }

  const handleOnChange = (e, type = "default", ddname = '') => {
    // Set if editing the form
    if (!ACTIVITY.saveAdvancedType) dispatch(setSaveAdvancedType(true));

    let name;
    let value = '';
    let selectedSection = SETTINGS.settings.newAdvancedType.section;
    if (type === 'dd') {
      name = ddname;
      value = e.value;
    } else if (type === "checkbox") {
      name = ddname;
      value = e.target.checked === true ? "active" : "inactive";
    } else if (type === "msdd") {
      name = ddname;
      if (e?.length > 0) {
        let tmp = [];
        for (let c of e) {
          tmp.push(c.value);
        }
        value = tmp;
      } else {
        value = [];
      }
    } else {
      name = e.target.name;
      value = e.target.value;
    }
    // Set settings data
    let newSettings = Object.assign({}, SETTINGS.settings);
    let newSettingsData = {};
    newSettingsData[name] = value;
    // Document Type
    if (name === 'documentType') {
      const selectedDT = getObjectFromArrayOfObjects(SETTINGS.settings.basicTypes, value, '_id');
      if (selectedDT.length > 0) {
        // Sales Fields
        newSettingsData['documentTypeName'] = selectedDT['0'].name;
        if (selectedSection === 'Sales' || selectedSection === 'Buys') {
          newSettingsData['basicDocumentType'] = value;
          newSettingsData['basicDocumentTypeName'] = selectedDT['0'].name;
        }
      }
    }
    // Print Preferences
    if (name === 'printPreference') {
      newSettingsData['printPreferenceName'] = getLabelByValue(printPreferenceOptions, value, 'value');
    }
    // Print Preferences
    if (name === 'printTemplate') {
      newSettingsData['printTemplateName'] = getLabelByValue(printTemplateOptions, value, 'value');
    }
    // Name
    if (name === 'name' && (selectedSection === 'Sales' || selectedSection === 'Buys' || selectedSection === "Accounting Records")) {
      newSettingsData['name'] = value;
    }
    if (name === "section") {
      myDataTypeOptions = SETTINGS.settings.newAdvancedType?.mydataProvider === "active" ? getInvoiceTypesOptions("provider", newSettingsData.section) : value === "Buys" ? getInvoiceTypesOptions("buys") : getInvoiceTypesOptions();
      newSettingsData.proposedPaymentType = "";
      newSettingsData.proposedPaymentTypeType = "";
      newSettingsData.proposedPaymentTypeName = "";
      newSettingsData.proposedPosBankAccount = "";
      newSettingsData.returnPaymentDocument = "";
      newSettingsData.returnPaymentDocumentPreNumber = "";
      newSettingsData.enablePeppol = "inactive";
      newSettingsData.enableMyDataRules = "inactive";
      newSettingsData.enableMyData = "inactive";
      newSettingsData.mydataProvider = "inactive";
      if (value === "Accounting Records") newSettingsData.accounting = SETTINGS.newAdvancedTypeInitialValues.accounting;
    }
    // My Data Rule for Sales
    // If "Enable MyData provider" is enabled, this option is enabled by default.
    if (name === 'enableMyData' && value === 'active' && (selectedSection === 'Sales' || selectedSection === "Accounting Records")) {
      newSettingsData.enableMyDataRules = 'active';
      newSettingsData.mydataProvider = 'inactive';
      myDataTypeOptions = getInvoiceTypesOptions();
      myDataTypeOptions = [{value: '', label: t("Null")}, ...myDataTypeOptions];
    }
    if (name === 'mydataProvider' && value === 'active' && (selectedSection === 'Sales' || selectedSection === "Accounting Records")) {
      newSettingsData.enableMyDataRules = 'active';
      newSettingsData.enableMyData = 'inactive';
      myDataTypeOptions = getInvoiceTypesOptions("provider", newSettingsData.section);
      myDataTypeOptions = [{value: '', label: t("Null")}, ...myDataTypeOptions];
    }
    if (name === "mydataProvider" && value === "active" && selectedSection === "Client Payments") {
      newSettingsData.enableMyDataRules = 'active';
      newSettingsData.enableMyData = 'inactive';
    }
    if (name === "mydataProvider" && value === "inactive" && selectedSection === "Client Payments") {
      newSettingsData.enableMyDataRules = 'inactive';
      newSettingsData.enableMyData = 'inactive';
    }

    // My Data Rule for Sales
    // If "Enable MyData provider" is enabled, this option is enabled by default.
    if (name === 'enableMyData' && value === 'active' && selectedSection === 'Buys') {
      newSettingsData.enableMyDataRules = 'active';
      newSettingsData.mydataProvider = 'inactive';
      myDataTypeOptions = getInvoiceTypesOptions();
      myDataTypeOptions = [{value: '', label: 'Null (Blank)'}, ...myDataTypeOptions];
    }
    if (name === 'mydataProvider' && value === 'active' && selectedSection === 'Buys') {
      newSettingsData.enableMyDataRules = 'active';
      newSettingsData.enableMyData = 'inactive';
      myDataTypeOptions = getInvoiceTypesOptions("provider", newSettingsData.section);
      myDataTypeOptions = [{value: '', label: 'Null (Blank)'}, ...myDataTypeOptions];
    }
    if (name === "myDataType" && SETTINGS.settings.newAdvancedType.section === "Client Payments") {
      newSettingsData.proposedPaymentType = "";
      newSettingsData.proposedPaymentTypeType = "";
      newSettingsData.proposedPaymentTypeName = "";
      newSettingsData.proposedPosBankAccount = "";
      newSettingsData.returnPaymentDocument = "";
      newSettingsData.returnPaymentDocumentPreNumber = "";
    }
    newSettings.newAdvancedType = {...newSettings.newAdvancedType, ...newSettingsData}
    if (newSettings.newAdvancedType.mydataProvider !== "active" && newSettings.newAdvancedType.enableMyData !== "active") {
      newSettingsData.allowOlderDate = "inactive";
      newSettings.newAdvancedType = {...newSettings.newAdvancedType, ...newSettingsData}
    }
    if (name === "proposedPaymentType" && SETTINGS.settings.newAdvancedType.section !== 'Supplier Payments') {
      if (SALES_SETTINGS.salesGeneralSettings.paymentTypes.length > 0) {
        const paymentTypeNameOption = SALES_SETTINGS.salesGeneralSettings.paymentTypes.filter(paymentType => paymentType._id === value);
        newSettings.newAdvancedType.proposedPaymentTypeType = paymentTypeNameOption[0].type;
        newSettings.newAdvancedType.proposedPaymentTypeName = paymentTypeNameOption[0].name;
      }
    }
    if (name === "proposedPaymentType" && SETTINGS.settings.newAdvancedType.section === 'Supplier Payments') {
      if (BUYS_SETTINGS.buysGeneralSettings.paymentTypes.length > 0) {
        const paymentTypeNameOption = BUYS_SETTINGS.buysGeneralSettings.paymentTypes.filter(paymentType => paymentType._id === value);
        newSettings.newAdvancedType.proposedPaymentTypeType = paymentTypeNameOption[0].type;
        newSettings.newAdvancedType.proposedPaymentTypeName = paymentTypeNameOption[0].name;
      }
    }
    dispatch(setSettings(newSettings));
  }

  const duplicateDocumentNumberingCheck = (data, filter, id = '') => {
    if (data.length > 0) {
      let newData;
      newData = data.filter(function(item) {
        let dcheck = true;
        for (let key in filter) {
          if (item[key] !== filter[key]) {
            dcheck = false;
          }
          if (id !== '' && id === item.no) {
            dcheck = false;
          }
        }
        return dcheck;
      });
      return newData.length > 0;
    }
    return false;
  }

  const documentNumberingActions = {
    insert: (e, data) => {
      // Set if editing the form
      if (!ACTIVITY.saveAdvancedType) {
        dispatch(setSaveAdvancedType(true));
      }
      let tempDocumentNumbering = Object.assign({},  SETTINGS.newDocumentNumbering);
      if (e.key !== "Enter") {
        tempDocumentNumbering[data.field] = data.value;
        if(data.field === "installation") {
          tempDocumentNumbering.installation = e.row.type + ", " + e.row.address + ", " + e.row.city;
          tempDocumentNumbering.installationType = e.row.type;
          tempDocumentNumbering.installationMasterId = e.row.installationMasterId;
          tempDocumentNumbering.installation_id = e.row._id;
        }
        dispatch(setNewDocumentNumbering(tempDocumentNumbering)); // Update redux
        // Add on change
        if (( tempDocumentNumbering.installation !== '' && tempDocumentNumbering.numberingMode === 'manual') ||
          ( tempDocumentNumbering.installation !== '' && tempDocumentNumbering.numberingMode === 'automatic' && tempDocumentNumbering.preNumber !== '')
          ) {
          let oldDocumentNumbering = SETTINGS.settings.newAdvancedType.documentNumbering;
          let updatedDocumentNumbering = Object.assign({},  tempDocumentNumbering);
          let highestValue = getLastHighestValue(oldDocumentNumbering) // It works for table
          updatedDocumentNumbering.no = highestValue + 1;
          updatedDocumentNumbering.id = highestValue + 1;
            updatedDocumentNumbering.number = 0;
            updatedDocumentNumbering.countNumber = 0;
          // Duplicate Check
          let filterDocumentNumbering  = {
            // number: tempDocumentNumbering.number,
            numberingMode: tempDocumentNumbering.numberingMode,
            preNumber: tempDocumentNumbering.preNumber,
            installation: tempDocumentNumbering.installation,
          }
          // For Manual Mode
          if (tempDocumentNumbering.numberingMode === 'manual') {
            filterDocumentNumbering  = {
              numberingMode: tempDocumentNumbering.numberingMode,
              installation: tempDocumentNumbering.installation,
            }
          }
          let isDuplicate = duplicateDocumentNumberingCheck(SETTINGS.settings.newAdvancedType.documentNumbering, filterDocumentNumbering);
          if (isDuplicate) {
            toast.error('Duplicate Entry Not Allowed');
          } else {
            // Deselect existing selected rows
            if (updatedDocumentNumbering.selected && updatedDocumentNumbering.selected === 'true') {
              oldDocumentNumbering = setAdvancedTypesSelectedRow(oldDocumentNumbering, 0);
            }
            let documentNumbering = [...oldDocumentNumbering, updatedDocumentNumbering];
            dispatch(setNewAdvancedType({...SETTINGS.settings.newAdvancedType, documentNumbering: documentNumbering }));
            dispatch(resetNewDocumentNumbering()); // Reset new category mydata
          }
        }
      } else {
        let updatedDocumentNumbering = Object.assign({},  SETTINGS.newDocumentNumbering);
        let addCheck = true;
        if (updatedDocumentNumbering.installation === '') {
          toast.error("Installation should not be empty.");
          addCheck = false;
        }
        let oldDocumentNumbering = SETTINGS.settings.newAdvancedType.documentNumbering;
        if ((data.field === 'add' || e.key === 'Enter') && addCheck) {
          let highestValue = getLastHighestValue(oldDocumentNumbering) // It works for table
          updatedDocumentNumbering.no = highestValue + 1;
          updatedDocumentNumbering.id = highestValue + 1;
          // Duplicate Check
          let filterDocumentNumbering  = {
            // number: updatedDocumentNumbering.number,
            numberingMode: updatedDocumentNumbering.numberingMode,
            preNumber: updatedDocumentNumbering.preNumber,
            installation: updatedDocumentNumbering.installation,
          }
          let isDuplicate = duplicateDocumentNumberingCheck(SETTINGS.settings.newAdvancedType.documentNumbering, filterDocumentNumbering);
          if (isDuplicate) {
            toast.error('Duplicate Entry Not Allowed');
            if (data.field === 'add') {
              dispatch(resetNewDocumentNumbering()); // Reset new mydata
            }
          } else {
            // Deselect existing selected rows
            if (updatedDocumentNumbering.selected && updatedDocumentNumbering.selected === 'true') {
              oldDocumentNumbering = setAdvancedTypesSelectedRow(oldDocumentNumbering, 0);
            }

            let documentNumbering = [...oldDocumentNumbering, updatedDocumentNumbering];

            dispatch(setNewAdvancedType({...SETTINGS.settings.newAdvancedType, documentNumbering: documentNumbering}));
            dispatch(resetNewDocumentNumbering()); // Reset new mydata
          }
        }
      }
    },
    update: (data, id) => {
      // Set if editing the form
      if (!ACTIVITY.saveAdvancedType) {
        dispatch(setSaveAdvancedType(true));
      }
      let validCheck = true;
      if (data.installation && (data.installation.length === 0)) {
        toast.error("Installation is mandatory field.");
        validCheck = false;
      }
      if (data.numberingMode && (data.numberingMode.length === 0)) {
        toast.error("Numbering Mode is mandatory field.");
        validCheck = false;
      }
      if (validCheck) {
        let documentNumbering = SETTINGS.settings.newAdvancedType.documentNumbering.slice();
        if (documentNumbering.find(item => item.id === id) !== undefined) {
          let updatedPmProducts = { ...documentNumbering[id - 1], ...data }
          if(data?.installation && data.installation?.row && data.installation !== '') {
            updatedPmProducts.installation = data.installation.row.type + ", " + data.installation.row.address + ", " + data.installation.row.city;
            updatedPmProducts.installationType = data.installation.row.type;
            updatedPmProducts.installationMasterId = data.installation.row.installationMasterId;
            updatedPmProducts.installation_id = data.installation.row._id;
          }
          if (data.preNumber && data.preNumber !== '') {
              updatedPmProducts.number = 0;
          }
          // Duplicate Check
          let filterDocumentNumbering  = {
            number: updatedPmProducts.number,
            numberingMode: updatedPmProducts.numberingMode,
            preNumber: updatedPmProducts.preNumber,
          }
          let isDuplicate = duplicateDocumentNumberingCheck(documentNumbering, filterDocumentNumbering, id);
          if (data.selected === 'true') {
            // Set only 1 as selected row
            documentNumbering = setAdvancedTypesSelectedRow(documentNumbering, id);
          }
          if (isDuplicate && data.numberingMode !== 'manual' && updatedPmProducts.preNumber !== "" && updatedPmProducts.number) {
            toast.error('Number already exists. Please choose another number.');
            dispatch(setNewAdvancedType({...SETTINGS.settings.newAdvancedType, documentNumbering: documentNumbering})); // update data
          } else {
            documentNumbering[id-1] = updatedPmProducts;
            dispatch(setNewAdvancedType({...SETTINGS.settings.newAdvancedType, documentNumbering: documentNumbering})); // update data
          }
       }
      }
    },
    selected: () => {},
    delete: (id) => {
      // Set if editing the form
      if (!ACTIVITY.saveAdvancedType) {
        dispatch(setSaveAdvancedType(true));
      }
      let documentNumbering = SETTINGS.settings.newAdvancedType.documentNumbering.slice();
      if (id >= 1) {
        let deletedDocumentNumbering = deleteObjectFromArrayOfObjects(documentNumbering, id,'id');
        deletedDocumentNumbering = resetAdvancedTypeItemsID(deletedDocumentNumbering);
        dispatch(setNewAdvancedType({...SETTINGS.settings.newAdvancedType, documentNumbering: deletedDocumentNumbering }))
      }
    }
  }

  const disabledFields = (SETTINGS.settings.newAdvancedType.section === undefined || SETTINGS.settings.newAdvancedType.section === '');

  let selectedSection = getDefaultOptionByValue(advancedTypeSectionOptions, SETTINGS.settings.newAdvancedType.section, 'value', 'label', 'value');
  let basicTypesData = SETTINGS.settings.basicTypes;
  return (
      <Container fluid className="p-0 client-form product-form position-relative">
        {disabledFields && <div className="overlay_w">{t('DocumentTypeAdvancedNew.message')}</div>}
        <Row>
          <Col sm="12" md="6" className="showWithOverlay">
            <Dropdown
                label={t('DocumentTypeAdvancedNew.section')}
                options={advancedTypeSectionOptions}
                value={selectedSection ? selectedSection : ''}
                name="section"
                searchEnable={true}
                defaultValue={null}
                enabledValue={true}
                onChange={(e) => handleOnChange(e, 'dd', 'section')}
            />
          </Col>
        </Row>
        <Container fluid className="border py-3 mb-3">
          <Row>
            <Col sm="12" md="6">
              {SETTINGS.settings.newAdvancedType.section !== 'Accounting Records' && (
                  <div className="mb-3">
                    <Dropdown
                        label={t('DocumentTypeAdvancedNew.basicDocumentType.basicDocumentType')}
                        options={getOptionsByData(basicTypesData, '_id', 'name')}
                        value={getDefaultOptionByValue(basicTypesData, SETTINGS.settings.newAdvancedType.documentType, '_id', 'name', 'value')}
                        name="documentType"
                        searchEnable={true}
                        enabledValue={true}
                        required={true}
                        onChange={(e) => handleOnChange(e, 'dd', 'documentType')}
                        classes="w-100"
                    />
                    {ACTIVITY.saveAdvancedType && SETTINGS.settings.newAdvancedType.documentType === '' &&
                        <div className="text-danger">{t('DocumentTypeAdvancedNew.basicDocumentType.required')}</div>
                    }
                  </div>
              )}
            </Col>
            <Col sm="12" md="6" className="d-flex align-items-center justify-content-end">
              {t('DocumentTypeAdvancedNew.active')}
              <BetterCheckBox
                  className="ml-2 mb-2"
                  name="isActive"
                  checked={SETTINGS.settings.newAdvancedType.isActive === 'active'}
                  onChange={(e) => handleOnChange(e, "checkbox", "isActive")}
              />
            </Col>
            <Col sm="12" md="6">
              <div className="mb-3">
                <Input
                    label={t('DocumentTypeAdvancedNew.name.name')}
                    placeholder={t('DocumentTypeAdvancedNew.name.placeholder')}
                    name="name"
                    value={SETTINGS.settings.newAdvancedType.name}
                    className="mb-0"
                    required={true}
                    charLimit={70}
                    autoComplete="off"
                    onChange={(e) => handleOnChange(e)}
                />
                {ACTIVITY.saveAdvancedType && SETTINGS.settings.newAdvancedType.name === '' &&
                    <div className="text-danger">{t('DocumentTypeAdvancedNew.name.required')}</div>
                }
                {isValueExists(SETTINGS.settings.advancedTypes, SETTINGS.settings.newAdvancedType.name, 'name') &&
                    SETTINGS.settings.newAdvancedType.name !== '' &&
                    <div className="text-danger">{t('DocumentTypeAdvancedNew.name.warning')}</div>
                }
              </div>
            </Col>
          </Row>
          {(SETTINGS.settings.newAdvancedType.section === 'Sales' || SETTINGS.settings.newAdvancedType.section === 'Client Payments' || SETTINGS.settings.newAdvancedType.section === 'Supplier Payments') &&
              <Row>
                <Col sm="12" md="6">
                  <Dropdown
                      label={t('DocumentTypeAdvancedNew.printTemplate')}
                      options={printTemplateOptions}
                      value={getDefaultOptionByValue(printTemplateOptions, SETTINGS.settings.newAdvancedType.printTemplate, 'value', 'label', 'value')}
                      searchEnable={true}
                      name="printTemplate"
                      enabledValue={true}
                      onChange={(e) => handleOnChange(e, 'dd', 'printTemplate')}
                      classes="w-100 mb-3"
                  />
                </Col>
              </Row>
          }
          <Row>
            {(SETTINGS.settings.newAdvancedType.section === 'Sales' || SETTINGS.settings.newAdvancedType.section === 'Client Payments' || SETTINGS.settings.newAdvancedType.section === 'Supplier Payments') &&
                <Col sm="12" md="3" className="d-flex align-items-center justify-content-start mb-3">
                  {t('DocumentTypeAdvancedNew.alwaysPrintAfterSave')}
                  <Checkbox
                      className="ml-2 mb-2"
                      name="printAfterSave"
                      key={Math.random()}
                      defaultValue={SETTINGS.settings.newAdvancedType.printAfterSave === 'active'}
                      value={SETTINGS.settings.newAdvancedType.printAfterSave === 'active' ? "inactive" : "active"}
                      onChange={(e) => handleOnChange(e)}
                  />
                </Col>
            }
            {(SETTINGS.settings.newAdvancedType.section === 'Sales' || SETTINGS.settings.editAdvancedType.section === 'Client Payments' || SETTINGS.settings.newAdvancedType.section === 'Supplier Payments') &&
                <Col sm="12" md="3" className="d-flex align-items-center justify-content-start mb-3">
                  {t('DocumentTypeAdvancedNew.alwaysSendEmailAfterSave')}
                  <Checkbox
                      className="ml-2 mb-2"
                      name="sendEmailAfterSave"
                      key={Math.random()}
                      defaultValue={SETTINGS.settings.newAdvancedType.sendEmailAfterSave === 'active'}
                      value={SETTINGS.settings.newAdvancedType.sendEmailAfterSave === 'active' ? "inactive" : "active"}
                      onChange={(e) => handleOnChange(e)}
                  />
                </Col>
            }
            {(SETTINGS.settings.newAdvancedType.section === 'Sales' || SETTINGS.settings.newAdvancedType.section === 'Client Payments' || SETTINGS.settings.newAdvancedType.section === 'Supplier Payments') &&
                <Col sm="12" md="3" className="d-flex align-items-center justify-content-start mb-3">
                  {t('DocumentTypeAdvancedNew.useLogo')}
                  <Checkbox
                      className="ml-2 mb-2"
                      name="useLogo"
                      key={Math.random()}
                      defaultValue={SETTINGS.settings.newAdvancedType.useLogo === 'active'}
                      value={SETTINGS.settings.newAdvancedType.useLogo === 'active' ? "inactive" : "active"}
                      onChange={(e) => handleOnChange(e)}
                  />
                </Col>
            }
            {(SETTINGS.settings.newAdvancedType.section === 'Client Payments') && (
                <Col sm="12" md="3" className="d-flex align-items-center justify-content-start mb-3">
                  Third Party Collection
                  <BetterCheckBox
                      className="ml-2 mb-2"
                      name="thirdPartyCollection"
                      checked={SETTINGS.settings.newAdvancedType.thirdPartyCollection === 'active'}
                      onChange={(e) => handleOnChange(e, "checkbox", "thirdPartyCollection")}
                  />
                </Col>
            )}
            {(SETTINGS.settings.newAdvancedType.section === 'Product Movements' || SETTINGS.settings.newAdvancedType.section === 'Client Movements' || SETTINGS.settings.newAdvancedType.section === 'Supplier Movements') &&
                <Col sm="12" md="3" className="d-flex align-items-center justify-content-start mb-3">
                  {t('DocumentTypeAdvancedNew.isCensus')}
                  <Checkbox
                      className="ml-2 mb-2"
                      name="isCensus"
                      key={Math.random()}
                      defaultValue={SETTINGS.settings.newAdvancedType.isCensus === 'active'}
                      value={SETTINGS.settings.newAdvancedType.isCensus === 'active' ? "inactive" : "active"}
                      onChange={(e) => handleOnChange(e)}
                  />
                </Col>
            }
            {(SETTINGS.settings.newAdvancedType.section === 'Sales') && (
                <Col sm="12" md="3" className="d-flex align-items-center justify-content-start mb-3">
                  {t('DocumentTypeAdvancedNew.canBePaidOff')}
                  <Checkbox
                      className="ml-2 mb-2"
                      name="canBePaidOff"
                      key={Math.random()}
                      defaultValue={SETTINGS.settings.newAdvancedType.canBePaidOff === 'active'}
                      value={SETTINGS.settings.newAdvancedType.canBePaidOff === 'active' ? "inactive" : "active"}
                      onChange={(e) => handleOnChange(e)}
                  />
                </Col>
            )}
          </Row>
          {(SETTINGS.settings.newAdvancedType.section === 'Sales' || SETTINGS.settings.newAdvancedType.section === 'Buys' || SETTINGS.settings.newAdvancedType.section === 'Accounting Records' || SETTINGS.settings.newAdvancedType.section === 'Client Payments') &&
            <Row key={Math.random()}>
              {SETTINGS.settings.newAdvancedType.section !== 'Accounting Records' &&
                  <Col sm="12" md="3" className="mb-3 d-flex align-items-center justify-content-start">
                    {t('DocumentTypeAdvancedNew.enableMyDataProvider')}
                    <Checkbox
                        className='ml-2 mb-2'
                        name="mydataProvider"
                        key={Math.random()}
                        defaultValue={SETTINGS.settings.newAdvancedType.mydataProvider === 'active'}
                        value={SETTINGS.settings.newAdvancedType.mydataProvider === 'active' ? "inactive" : "active"}
                        onChange={(e) => handleOnChange(e)}
                    />
                  </Col>
              }
              {SETTINGS.settings.newAdvancedType.section !== 'Accounting Records' &&
                  <Col sm="12" md="3">
                    <div
                        className={(SETTINGS.settings.newAdvancedType?.enableMyData !== 'active' && SETTINGS.settings.newAdvancedType?.mydataProvider !== 'active') ? 'readonly-field mb-3 d-flex align-items-center justify-content-start' : "mb-3 d-flex align-items-center justify-content-start"}>
                      {t('DocumentTypeAdvancedNew.handwrittenDocument')}
                      <Checkbox
                          className='ml-2 mb-2'
                          name="allowOlderDate"
                          key={Math.random()}
                          defaultValue={SETTINGS.settings.newAdvancedType.allowOlderDate === 'active'}
                          value={SETTINGS.settings.newAdvancedType.allowOlderDate === 'active' ? "inactive" : "active"}
                          disabled={SETTINGS.settings.newAdvancedType?.enableMyData !== 'active' && SETTINGS.settings.newAdvancedType?.mydataProvider !== 'active'}
                          onChange={(e) => handleOnChange(e)}
                      />
                    </div>
                  </Col>
              }
              {SETTINGS.settings.newAdvancedType.section !== "Client Payments" && (
                  <Col sm="12" md="3">
                    <div className="mb-3 d-flex align-items-center justify-content-start">
                      {t('DocumentTypeAdvancedNew.enableMyData')}
                      <Checkbox
                          className="ml-2 mb-2"
                          name="enableMyData"
                          key={Math.random()}
                          defaultValue={SETTINGS.settings.newAdvancedType.enableMyData === 'active'}
                          value={SETTINGS.settings.newAdvancedType.enableMyData === 'active' ? "inactive" : "active"}
                          onChange={(e) => handleOnChange(e)}
                      />
                    </div>
                  </Col>
              )}
              <Col sm="12" md="3" hidden={SETTINGS.settings.newAdvancedType?.section === "Client Payments"}>
                <div className={(SETTINGS.settings.newAdvancedType?.enableMyData === 'active' || SETTINGS.settings.newAdvancedType?.mydataProvider === 'active') ? 'readonly-field mb-3 d-flex align-items-center justify-content-start' : "mb-3 d-flex align-items-center justify-content-start"}>
                  {t('DocumentTypeAdvancedNew.enableMyDataRules')}
                  <Checkbox
                      className={"ml-2 mb-2"}
                      name="enableMyDataRules"
                      key={Math.random()}
                      defaultValue={SETTINGS.settings.newAdvancedType.enableMyDataRules === 'active'}
                      value={SETTINGS.settings.newAdvancedType.enableMyDataRules === 'active' ? "inactive" : "active"}
                      onChange={(e) => handleOnChange(e)}
                      disabled={SETTINGS.settings.newAdvancedType?.enableMyData === 'active' || SETTINGS.settings.newAdvancedType?.mydataProvider === 'active'}
                  />
                </div>
              </Col>
              {SETTINGS.settings.newAdvancedType?.section === 'Sales' &&
                  <Row>
                    <Col md="3" className="d-flex align-items-center justify-content-start mb-3">
                      {t('DocumentTypeAdvancedNew.enableDisplayClientBalance')}
                      <BetterCheckBox
                          name={"enableDisplayClientBalance"}
                          className="mb-2"
                          checked={SETTINGS.settings.newAdvancedType.enableDisplayClientBalance === 'active'}
                          onChange={(e) => handleOnChange(e, "checkbox", "enableDisplayClientBalance")}
                      />
                    </Col>
                  </Row>
              }
              {SETTINGS.settings.newAdvancedType.enableMyDataRules === 'active' &&
                <Col sm="12" md="8">
                  <div className="mb-3">
                    <Dropdown
                        classes="w-100"
                        label={t('DocumentTypeAdvancedNew.myDataInvoiceType.myDataInvoiceType')}
                        options={SETTINGS.settings.newAdvancedType.section === 'Accounting Records' ? getInvoiceTypesOptions("all") : myDataTypeOptions}
                        value={selectedMyDataType}
                        enabledValue={true}
                        searchEnable={true}
                        required={SETTINGS.settings.newAdvancedType.enableMyDataRules === 'active'}
                        name="myDataType"
                        onChange={(e) => handleOnChange(e, 'dd', 'myDataType')}
                    />
                    {ACTIVITY.saveAdvancedType && !SETTINGS.settings.newAdvancedType.myDataType && SETTINGS.settings.newAdvancedType.enableMyDataRules === 'active' &&
                        <div className="text-danger">{t('DocumentTypeAdvancedNew.myDataInvoiceType.required')}</div>
                    }
                  </div>
                </Col>
              }
              {(SETTINGS.settings.newAdvancedType.section === 'Accounting Records' || SETTINGS.settings.newAdvancedType.section === 'Buys') &&
                <Col sm="12" md="8">
                  <div className="mb-3">
                    <Dropdown
                        label="Προτεινόμενη Εγκατάσταση"
                        options={installationOptions}
                        value={getDefaultOptionByValue(installationOptions, SETTINGS.settings.newAdvancedType.proposedInstallation, 'value', 'label', 'value')}
                        enabledValue={true}
                        searchEnable={true}
                        required={false}
                        name="proposedInstallation"
                        onChange={(e) => handleOnChange(e, 'dd', 'proposedInstallation')}
                    />
                  </div>
                </Col>
              }
              {SETTINGS.settings.newAdvancedType.section === 'Buys' && (
                  <Row>
                    <Col md={3}>
                      <Dropdown
                          label={"Χρησιμοποιείται για χαρακτηρισμό"}
                          name={"classificationsArray"}
                          options={getInvoiceTypesOptions()}
                          multiSelect={true}
                          key={Math.random()}
                          defaultValue={getInvoiceTypesOptions().filter((el) => SETTINGS.settings.newAdvancedType?.classificationsArray?.includes(el.value))}
                          onChange={(e) => handleOnChange(e, "msdd", "classificationsArray")}
                      />
                    </Col>
                  </Row>
              )}
              {(SETTINGS.settings.newAdvancedType.section === "Sales" && SETTINGS.settings.newAdvancedType.mydataProvider === "active"
                  && (SETTINGS.settings.newAdvancedType.myDataType === "1.1" || SETTINGS.settings.newAdvancedType.myDataType === "2.1"
                      || SETTINGS.settings.newAdvancedType.myDataType === "5.1" || SETTINGS.settings.newAdvancedType.myDataType === "5.2")
                  && checkPermission("custom-b2g", permissionsData)) && (
                  <Col md={3}>
                    <BetterCheckBox
                        name={"enablePeppol"}
                        className={"mt-4"}
                        text={"Αφορά δημόσια σύμβαση"}
                        checked={SETTINGS.settings.newAdvancedType.enablePeppol === "active"}
                        onChange={(e) => handleOnChange(e, "checkbox", "enablePeppol")}
                    />
                  </Col>
              )}
              {(SETTINGS.settings.newAdvancedType.section !== 'Accounting Records' && SETTINGS.settings.newAdvancedType.section !== 'Buys' && SETTINGS.settings.newAdvancedType.section !== 'Client Payments') &&
                <Col sm="12" md="8">
                  <div className="mb-3">
                    <Dropdown
                        label={t('DocumentTypeAdvancedNew.creditDocument')}
                        options={creditDocumentOptions}
                        value={getDefaultOptionByValue(creditDocumentOptions, SETTINGS.settings.newAdvancedType.creditDocument, 'value', 'label', 'value')}
                        searchEnable={true}
                        enabledValue={true}
                        name="creditDocument"
                        onChange={(e) => handleOnChange(e, 'dd', 'creditDocument')}
                    />
                  </div>
                </Col>
              }
              {SETTINGS.settings.newAdvancedType.creditDocument !== null && SETTINGS.settings.newAdvancedType.creditDocument !== "" && SETTINGS.settings.newAdvancedType.creditDocument !== undefined &&
                <Col sm="12" md="8">
                  <div className="mb-3">
                    <Dropdown
                        label={t('DocumentTypeAdvancedNew.creditDocumentPreNumber')}
                        name="creditDocumentPreNumber"
                        key={Math.random()}
                        defaultValue={getDefaultOptionByValue(preNumberGreek, SETTINGS.settings.newAdvancedType.creditDocumentPreNumber, 'value', 'label', 'value')}
                        options={preNumberGreek}
                        onChange={(e) => handleOnChange(e, "dd", "creditDocumentPreNumber")}
                    />
                  </div>
                </Col>
              }
              {(SETTINGS.settings.newAdvancedType.section === "Client Payments") && (
                  <Col sm="12" md="8">
                    <Dropdown
                        label={"Παραστατικό Επιστροφής Είσπραξης"}
                        options={returnPaymentDocuments}
                        className={"mb-3"}
                        defaultValue={getDefaultOptionByValue(returnPaymentDocuments, SETTINGS.settings.newAdvancedType.returnPaymentDocument, 'value', 'label', 'value')}
                        searchEnable={true}
                        name={"returnPaymentDocument"}
                        onChange={(e) => handleOnChange(e, "dd", "returnPaymentDocument")}
                    />
                  </Col>
              )}
              {(SETTINGS.settings.newAdvancedType.section === "Client Payments" && !isEmpty(SETTINGS.settings.newAdvancedType.returnPaymentDocument)) && (
                  <Col sm="12" md="8">
                    <div className="mb-3">
                      <Dropdown
                          label={"Σειρά Παραστατικού Επιστροφής"}
                          name="returnPaymentDocumentPreNumber"
                          key={Math.random()}
                          defaultValue={getDefaultOptionByValue(preNumberGreek, SETTINGS.settings.newAdvancedType.returnPaymentDocumentPreNumber, 'value', 'label', 'value')}
                          options={preNumberGreek}
                          onChange={(e) => handleOnChange(e, "dd", "returnPaymentDocumentPreNumber")}
                      />
                    </div>
                  </Col>
              )}
              {SETTINGS.settings.newAdvancedType.section !== 'Accounting Records' && SETTINGS.settings.newAdvancedType.section !== 'Buys' &&
                <Col sm="12" md="8">
                  <div className="mb-3">
                    <Dropdown
                        label={t('DocumentTypeAdvancedNew.conversionDocument')}
                        options={conversionDocumentOptions}
                        value={getDefaultOptionByValue(conversionDocumentOptions, SETTINGS.settings.newAdvancedType.conversionDocument, 'value', 'label', 'value')}
                        searchEnable={true}
                        enabledValue={true}
                        name="conversionDocument"
                        onChange={(e) => handleOnChange(e, 'dd', 'conversionDocument')}
                    />
                  </div>
                </Col>
              }
              {SETTINGS.settings.newAdvancedType.conversionDocument !== null && SETTINGS.settings.newAdvancedType.conversionDocument !== "" && SETTINGS.settings.newAdvancedType.conversionDocument !== undefined &&
                <Col sm="12" md="8">
                  <div className="mb-3">
                    <Dropdown
                        label={t('DocumentTypeAdvancedNew.conversionDocumentPreNumber')}
                        name="conversionDocumentPreNumber"
                        key={Math.random()}
                        defaultValue={getDefaultOptionByValue(preNumberGreek, SETTINGS.settings.newAdvancedType.conversionDocumentPreNumber, 'value', 'label', 'value')}
                        options={preNumberGreek}
                        onChange={(e) => handleOnChange(e, "dd", "conversionDocumentPreNumber")}
                    />
                  </div>
                </Col>
              }
              {SETTINGS.settings.newAdvancedType.section === 'Sales' &&
                <Col sm="12" md="8">
                  <div className="mb-3">
                    <Dropdown
                        label={t('DocumentTypeAdvancedNew.proposedSalePurpose')}
                        options={proposedSalePurposeOptions}
                        value={getDefaultOptionByValue(proposedSalePurposeOptions, SETTINGS.settings.newAdvancedType.proposedSalePurpose, 'value', 'label', 'value')}
                        searchEnable={true}
                        enabledValue={true}
                        name="proposedSalePurpose"
                        onChange={(e) => handleOnChange(e, 'dd', 'proposedSalePurpose')}
                    />
                  </div>
                </Col>
              }
              {SETTINGS.settings.newAdvancedType.section === 'Sales' &&
                <Col sm="12" md="8">
                  <div className="mb-3">
                    <Dropdown
                        label={t('DocumentTypeAdvancedNew.thirdPartySales')}
                        options={thirdPartySalesOptions}
                        value={getDefaultOptionByValue(thirdPartySalesOptions, SETTINGS.settings.newAdvancedType.thirdPartySales, 'value', 'label', 'value')}
                        searchEnable={true}
                        enabledValue={true}
                        name="thirdPartySales"
                        onChange={(e) => handleOnChange(e, 'dd', 'thirdPartySales')}
                    />
                  </div>
                </Col>
              }
            </Row>
          }
          <Row key={Math.random()}>
            {(SETTINGS.settings.newAdvancedType.section === 'Client Payments') &&
              <Col sm="12" md="8">
                <div className="mb-3">
                  <Dropdown
                      label={t('DocumentTypeAdvancedNew.proposedPaymentType.proposedPaymentType')}
                      options={SETTINGS.settings.newAdvancedType?.myDataType === "8.4" ? proposedPaymentTypeOptions.filter((el) => el.type === "pos") : proposedPaymentTypeOptions}
                      value={getDefaultOptionByValue(proposedPaymentTypeOptions, SETTINGS.settings.newAdvancedType.proposedPaymentType, 'value', 'label', 'value')}
                      enabledValue={true}
                      name="proposedPaymentType"
                      classes="w-100"
                      onChange={(e) => handleOnChange(e, 'dd', 'proposedPaymentType')}
                  />
                </div>
              </Col>
            }
            {SETTINGS.settings.newAdvancedType.section === 'Supplier Payments' &&
              <Col sm="12" md="8">
                <div className="mb-3">
                  <Dropdown
                      label="Proposed Payment Type"
                      options={proposedPaymentTypeOptionsBuys}
                      value={getDefaultOptionByValue(proposedPaymentTypeOptionsBuys, SETTINGS.settings.newAdvancedType.proposedPaymentType, 'value', 'label', 'value')}
                      enabledValue={true}
                      name="proposedPaymentType"
                      required={true}
                      classes="w-100"
                      onChange={(e) => handleOnChange(e, 'dd', 'proposedPaymentType')}
                  />
                  {ACTIVITY.saveAdvancedType && (SETTINGS.settings.newAdvancedType.proposedPaymentType === '' || !SETTINGS.settings.newAdvancedType.proposedPaymentType) &&
                      <div className="text-danger">Proposed Payment Type is required.</div>
                  }
                </div>
              </Col>
            }
            {SETTINGS.settings.newAdvancedType.proposedPaymentType && SETTINGS.settings.newAdvancedType.proposedPaymentTypeType !== "cash" && SETTINGS.settings.newAdvancedType.proposedPaymentTypeType !== "cheque" &&
              <Col sm="12" md="8">
                <div className="mb-3">
                  <Dropdown
                      label={t('DocumentTypeAdvancedNew.proposedPOSBankAccount.proposedPOSBankAccount')}
                      options={proposedPosBankAcountOptions}
                      value={getDefaultOptionByValue(proposedPosBankAcountOptions, SETTINGS.settings.newAdvancedType.proposedPosBankAccount, 'value', 'label', 'value')}
                      enabledValue={true}
                      required={(SETTINGS.settings.newAdvancedType.proposedPaymentTypeType !== "cash" && SETTINGS.settings.newAdvancedType.proposedPaymentTypeType !== "cheque")}
                      name="proposedPosBankAccount"
                      classes="w-100"
                      onChange={(e) => handleOnChange(e, 'dd', 'proposedPosBankAccount')}
                  />
                  {ACTIVITY.saveAdvancedType && (SETTINGS.settings.newAdvancedType.proposedPosBankAccount === '' || !SETTINGS.settings.newAdvancedType.proposedPosBankAccount) &&
                      <div
                          className="text-danger">{t('DocumentTypeAdvancedNew.proposedPOSBankAccount.required')}</div>
                  }
                </div>
              </Col>
            }
          </Row>
          {(SETTINGS.settings.newAdvancedType.section !== 'Accounting Records' && SETTINGS.settings.newAdvancedType.section !== 'Buys') &&
              <Row>
                <Col>
                  <label>{t('DocumentTypeAdvancedNew.documentNumbering')}<span className="text-danger"> *</span></label>
                  {etDocumentNumbering && etDocumentNumbering?.length === 0 &&
                      <div className="text-danger mb-1">{t('DocumentTypeAdvancedNew.message2')}</div>}
                  <EditableTable
                      tableName="Advanced Types Document Numbering"
                      key={Math.random()}
                      allowInsertRow="true"
                      columns={advancedDocumentColumns}
                      data={etDocumentNumbering}
                      allowActions={true}
                      enableNewRowCheckbox={true}
                      onUpdate={documentNumberingActions}
                  />
                </Col>
              </Row>
          }
        </Container>
      </Container>
  );
}

export const AdvancedTypesSearch = () => {
  const [t] = useTranslation('common');
  const dispatch = useDispatch();
  const SETTINGS = useSelector((state) => state.SETTINGS.settings.basicTypes);
  const company = useSelector(state => state.COMPANY_DATA.company);
  const basicDocumentType = getOptionsByData(SETTINGS, '_id', 'name');
  let basicDocumentTypesSearch = [{value: "", label: t("Null"), color: "#76AEB7"}];
  basicDocumentTypesSearch = [...basicDocumentTypesSearch, ...basicDocumentType];

  const requestData = {
    company: company.id,
    year: company.year.toString(),
  }

  useEffect(() => {
    dispatch(fetchBasicTypes(requestData));
    dispatch(fetchAdvancedTypes(requestData));
    dispatch(fetchAdvancedTypesSales(requestData));
    dispatch(fetchAdvancedTypesBuys(requestData));
  }, [dispatch])

  const advancedTypesColumns = [
    {
      name: t('DocumentTypeAdvancedNew.tableAdvanced.advancedTypeName'),
      field: "name",
      editable: false,
      inputType: {
        type: 'text',
      }
    },
    {
      name: t('DocumentTypeAdvancedNew.tableAdvanced.basicDocumentType'),
      field: "documentTypeName",
      editable: false,
      inputType: {
        type: 'text',
      }
    },
    {
      name: t('DocumentTypeAdvancedNew.tableAdvanced.section'),
      field: "section",
      editable: false,
      inputType: {
        type: 'text',
      }
    },
  ]

  const advancedTypeSectionOptionsSearch = [
    {value: "", label: t('AdvanceTypesNewDropDown.null'), color: "#76AEB7"},
    {value: "Product Movements", label: t('AdvanceTypesNewDropDown.productMovements'), color: "#76AEB7"},
    {value: "Sales", label: t('AdvanceTypesNewDropDown.sales'), color: "#76AEB7"},
    {value: "Client Movements", label: t('AdvanceTypesNewDropDown.clientMovements'), color: "#76AEB7"},
    {value: "Client Payments", label: t('AdvanceTypesNewDropDown.clientPayments'), color: "#76AEB7"},
    {value: "Buys", label: t('AdvanceTypesNewDropDown.buys'), color: "#76AEB7"},
    {value: "Supplier Payments", label: t('AdvanceTypesNewDropDown.supplierPayments'), color: "#76AEB7"},
    {value: "Supplier Movements", label: "Κινήσεις Προμηθευτών", color: "#76AEB7"},
  ]

  const searchAdvancedTypeFilters = {
    filters: [
      {
        name: "name",
        label: t('DocumentTypeAdvancedNew.tableAdvancedSearch.advancedTypeNameFrom'),
        type: "input",
      },
      {
        name: "section",
        label: t('DocumentTypeAdvancedNew.tableAdvancedSearch.section'),
        type: "dropdown",
        options: advancedTypeSectionOptionsSearch
      },
      {
        name: "basicDocumentType",
        label: t('DocumentTypeAdvancedNew.tableAdvancedSearch.basicDocumentType'),
        type: "dropdown",
        options: basicDocumentTypesSearch
      },
      {
        name: "isActive",
        label: t('DocumentTypeAdvancedNew.tableAdvancedSearch.showInactiveAdvancedTypes'),
        type: "checkbox",
      },
    ],
    actions: ["quick-edit", "delete"],
  };

  const [tableColumns, setTableColumns] = useState(advancedTypesColumns.slice());

  const handleColumnShowHide = (field) => {
    let tempTc = tableColumns.slice();
    let object = getObjectFromArrayOfObjects(tempTc, field, 'field')
    const index = tempTc.findIndex((item) => field === item.field);
    if (object.length > 0) {
      tempTc[index].hiddenColumn = !object['0'].hiddenColumn;
      setTableColumns(tempTc);
    }
  }

  return (
      <div style={classicStyleBelowNavbar}>
        <SearchTable
            tableName="Advanced Types Search"
            key={"Advanced Types Search"}
            hideYearFilter={true}
            allowToggleColumns={true}
            columns={tableColumns}
            allowPagination={true}
            toggleColumns={handleColumnShowHide}
            data={SETTINGS.searchAdvancedTypes}
            filtersConfig={searchAdvancedTypeFilters}
        />
      </div>
  )
}
