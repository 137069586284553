// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.reportTableRow:hover {
  background-color: #e1e3e1; }

.accountReportTable {
  width: 100%;
  margin-bottom: 20px;
  border: 1px solid #cccccc; }

.accountReportTable tr td {
  border: 1px solid #cccccc; }

.accountReportTable tr th {
  border: 1px solid #cccccc; }

.modal-50per {
  max-width: none !important;
  width: 50% !important; }

.accountingCalendarTable {
  width: 100%;
  margin-bottom: 20px;
  border: 1px solid #cccccc; }

.accountingCalendarTable tr td {
  border: 1px solid #cccccc; }

.accountingCalendarTable tr th {
  border: 1px solid #cccccc; }

.row-hoverable:hover {
  background-color: #ededed; }
`, "",{"version":3,"sources":["webpack://./src/_components/layouts/data-panel/Accounting/styles/reports.scss"],"names":[],"mappings":"AACA;EACE,yBAAyB,EAAA;;AAG3B;EACE,WAAW;EACX,mBAAmB;EACnB,yBAAyB,EAAA;;AAE3B;EACE,yBAAyB,EAAA;;AAE3B;EACE,yBAAyB,EAAA;;AAG3B;EACE,0BAA0B;EAC1B,qBAAqB,EAAA;;AAGvB;EACE,WAAW;EACX,mBAAmB;EACnB,yBAAyB,EAAA;;AAG3B;EACE,yBAAyB,EAAA;;AAE3B;EACE,yBAAyB,EAAA;;AAG3B;EACE,yBAAyB,EAAA","sourcesContent":[".reportTableRow {}\r\n.reportTableRow:hover {\r\n  background-color: #e1e3e1;\r\n}\r\n\r\n.accountReportTable {\r\n  width: 100%;\r\n  margin-bottom: 20px;\r\n  border: 1px solid #cccccc;\r\n}\r\n.accountReportTable tr td {\r\n  border: 1px solid #cccccc;\r\n}\r\n.accountReportTable tr th {\r\n  border: 1px solid #cccccc;\r\n}\r\n\r\n.modal-50per {\r\n  max-width: none !important;\r\n  width: 50% !important;\r\n}\r\n\r\n.accountingCalendarTable {\r\n  width: 100%;\r\n  margin-bottom: 20px;\r\n  border: 1px solid #cccccc;\r\n}\r\n\r\n.accountingCalendarTable tr td {\r\n  border: 1px solid #cccccc;\r\n}\r\n.accountingCalendarTable tr th {\r\n  border: 1px solid #cccccc;\r\n}\r\n\r\n.row-hoverable:hover {\r\n  background-color: #ededed;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
