import React, {useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import SearchTable from "../../../primer/Search-Table";
import {getCurrentUser, getOptionsByData} from '../../../../_helpers/commonFunctions';
import {
    fetchAdvancedTypesSales,
    fetchSalesGeneralSettings,
} from "../../../../_apis/api";
import {useTranslation} from 'react-i18next';
import {classicStyleBelowNavbar} from "../Statics";

function QuickRetailSalesSearch() {
    const [t] = useTranslation('common');
    const dispatch = useDispatch();
    const PRODUCTS_DATA = useSelector((state) => state.PRODUCTS_DATA);
    const SALES_SETTINGS = useSelector((state) => state.SALES_SETTINGS);
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const productsOptions = getOptionsByData(PRODUCTS_DATA.products, "_id", "name");
    const paymentTypeOptions = getOptionsByData(SALES_SETTINGS.salesGeneralSettings.paymentTypes, "_id", "name");

    const requestData = {
        company: company.id,
    }
    const currentUser = getCurrentUser();

    const requestData2 = {
        company: company.id,
        year: company.year.toString(),
        isActive: "active",
        userId: currentUser.id
    }

    useEffect(() => {
        dispatch(fetchAdvancedTypesSales(requestData));
        dispatch(fetchSalesGeneralSettings(requestData2))
    }, []);

    const scColumns = [
        {
            name: t('QuickRetailSearchTabs.table.date'),
            field: "date",
            editable: false,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t('QuickRetailSearchTabs.table.client'),
            field: "clientName",
            editable: false,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t('QuickRetailSearchTabs.table.preNumber'),
            field: "prenumber",
            editable: false,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t('QuickRetailSearchTabs.table.number'),
            field: "number",
            width: "50px",
            editable: false,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t('QuickRetailSearchTabs.table.products'),
            field: "products",
            editable: false,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t('QuickRetailSearchTabs.table.totalSum'),
            field: "totalSum",
            width: "100px",
            editable: false,
            inputType: {
                type: 'text',
            }
        },
    ]

    const searchSaleFilters = {
        filters: [
            {
                name: "clientName",
                label: t('QuickRetailSearchTabs.clientNameFrom'),
                type: "input",
            },
            {
                name: "totalSum_from",
                label: t('QuickRetailSearchTabs.totalSumFrom'),
                type: "input",
            },
            {
                name: "totalSum_to",
                label: t('QuickRetailSearchTabs.totalSumTo'),
                type: "input",
            },
            {
                name: "preNumber_from",
                label: t('QuickRetailSearchTabs.preNumberFrom'),
                type: "input",
            },
            {
                name: "preNumber_to",
                label: t('QuickRetailSearchTabs.preNumberTo'),
                type: "input",
            },
            {
                name: "number_from",
                label: t('QuickRetailSearchTabs.numberFrom'),
                type: "input",
            },
            {
                name: "number_to",
                label: t('QuickRetailSearchTabs.numberTo'),
                type: "input",
            },
            {
                name: t('QuickRetailSearchTabs.paymentWays'),
                label: "Payment Ways",
                type: "multiselect",
                options: paymentTypeOptions
            },
            {
                name: "products",
                label: t('QuickRetailSearchTabs.products'),
                type: "multiselect",
                options: productsOptions
            },
        ],
        actions: ["quick-edit", "delete"],
    };

    return (
        <div style={classicStyleBelowNavbar}>
            <SearchTable
                tableName="Quick Retail Sales Search"
                key={"Quick Retail Sales Search"}
                hideYearFilter={false}
                allowPagination={true}
                columns={scColumns}
                data={[]}
                filtersConfig={searchSaleFilters}
            />
        </div>
    )
}

export default QuickRetailSalesSearch;
