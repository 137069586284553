// File Name: MYDATAen.xlsx
// Sheet Name: 8.10 Income characterization category
// Table Columns: "code",	"description"

export const expensesCharacterizationCategory = [
    {
        "id" : "1",
        "code" : "category2_1",
        "description": "Αγορές Εμπορευμάτων (-) / (+)",
    },
    {
        "id" : "2",
        "code" : "category2_2",
        "description": "Αγορές Α'-Β' Υλών (-) / (+)",
    },
    {
        "id" : "3",
        "code" : "category2_3",
        "description": "Λήψη Υπηρεσιών (-) / (+)",
    },
    {
        "id" : "4",
        "code" : "category2_4",
        "description": "Γενικά Έξοδα με δικαίωμα έκπτωσης ΦΠΑ (-) / (+)",
    },
    {
        "id" : "5",
        "code" : "category2_5",
        "description": "Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης ΦΠΑ (-) / (+)",
    },
    {
        "id" : "6",
        "code" : "category2_6",
        "description": "Αμοιβές και Παροχές προσωπικού (-) / (+)",
    },
    {
        "id" : "7",
        "code" : "category2_7",
        "description": "Αγορές Παγίων (-) / (+)",
    },
    {
        "id" : "8",
        "code" : "category2_8",
        "description": "Αποσβέσεις Παγίων (-) / (+)",
    },
    {
        "id" : "9",
        "code" : "category2_9",
        "description": "Έξοδα για λ/σμο τρίτων (-) / (+)",
    },
    {
        "id" : "10",
        "code" : "category2_10",
        "description": "Έξοδα προηγούμενων χρήσεων (-) / (+)",
    },
    {
        "id" : "11",
        "code" : "category2_11",
        "description": "Έξοδα επομένων χρήσεων (-) / (+)",
    },
    {
        "id" : "12",
        "code" : "category2_12",
        "description": "Λοιπές Εγγραφές Τακτοποίησης Εξόδων (-) / (+)",
    },
    {
        "id" : "13",
        "code" : "category2_13",
        "description": "Αποθέματα Έναρξης Περιόδου (-) / (+)",
    },
    {
        "id" : "14",
        "code" : "category2_14",
        "description": "Αποθέματα Λήξης Περιόδου (-) / (+)",
    },
    {
        "id" : "15",
        "code" : "category2_95",
        "description": "Λοιπά Πληροφοριακά Στοιχεία Εξόδων (-) / (+)",
    },
]