import React from "react";
import {useTranslation} from "react-i18next";

const Input = ({
  type,
  id,
  required,
  placeholder,
  label,
  unlabbled,
  charLimit,
  onChange,
  className,
  name,
  value,
  onKeyDown,
  disabled,
  autoComplete,
  defaultValue,
  style
}) => {
  const [t] = useTranslation("common");
  let maxLength = charLimit ? charLimit : 250;
  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    e.target.value = inputValue.replace(/[$^&*()\[\]=+]/g, "");
    if(onChange) onChange(e);
  };

  return (
    <React.Fragment>
      <div className={className ? className : "w-100"}>
        {!unlabbled && (
          <label htmlFor={id}>
            {label}
            {required && <span className="text-danger"> *</span>}
          </label>
        )}
        <input
          maxLength={maxLength}
          className="form-control"
          type={type}
          disabled = {disabled}
          name={name}
          id={id}
          value = {value}
          defaultValue = {defaultValue}
          onChange={handleInputChange}
          autoComplete= {autoComplete}
          onKeyDown={onKeyDown}
          style={style}
          placeholder={!placeholder ? t('insertPlaceholders.add') + label : placeholder}
        />
        {value !==undefined && value !== "" && (value.length === maxLength) && <small className="text-info">Max length reached.</small>}
      </div>
    </React.Fragment>
  );
};

export default Input;
