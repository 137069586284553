import React, {useEffect, useState} from "react";
import axios from "axios";
import {useSelector} from "react-redux";
import Dropdown from "../../../primer/Dropdown";
import {isEmpty} from "../../../../_helpers/commonFunctions";
import {Col, Row} from "react-bootstrap";
import * as moment from "moment";
import {toast} from "react-toastify";
import {classicStyleBelowNavbar} from "../Statics";

const SettingsExpenses = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [viewingCompany, setViewingCompany] = useState("");
    const [companyOptions, setCompanyOptions] = useState([]);
    const company = useSelector((state) => state.COMPANY_DATA.company);

    useEffect(() => {
        axios.post(process.env.REACT_APP_API_URL2 + "/settings/emron-expenses", {company: company.id, type: "companies"}, {
            headers: { "Content-Type": "application/json" },
        }).then((res) => {
            if(res.data.status === "200") {
                let nOp = [];
                for(let obj of res.data.data) {
                    nOp.push({
                        value: obj.name,
                        label: obj.name,
                    })
                }
                setCompanyOptions(nOp);
            }
        }).catch((error) => {
            console.log(error);
        })
    }, [])

    useEffect(() => {
        if (!isEmpty(viewingCompany)) {
            setLoading(true);
            axios.post(process.env.REACT_APP_API_URL2 + "/settings/emron-expenses", {company: company.id, type: "data", name: viewingCompany}, {
                headers: { "Content-Type": "application/json" },
            }).then((res) => {
                setLoading(false);
                if(res.data.status === "200") {
                    setData(res.data.data);
                } else {
                    setData([]);
                    toast.error("Error while loading.");
                }
            }).catch((error) => {
                setLoading(false);
                console.log(error);
                toast.error("Error while loading.");
            })
        }
    }, [viewingCompany])

    const handleOnChange = (e, type, name) => {
        if (type === "dropdown") {
            if (name === "companyOptions") {
                setViewingCompany(e.value);
            }
        }
    }

    const getRowColorExpenses = (classified) => {
        if (classified === "true") {
            return "#a5fab5"
        } else if (classified === "false") {
            return "#faa5a9"
        } else if (classified === "non") {
            return "#e3e6e4"
        }
    }

    const getRowColorArticles = (myDataStatus) => {
        if (myDataStatus === "sent") {
            return "#a5fab5"
        } else {
            return "#faa5a9"
        }
    }

    return(
        <div style={classicStyleBelowNavbar}>
            <Row>
                <Col md={6}>
                    <Dropdown
                        name={"companyOptions"}
                        label={"Select Company"}
                        options={companyOptions}
                        key={Math.random()}
                        defaultValue={!isEmpty(viewingCompany) ? companyOptions.find((el) => el.value === viewingCompany) : ""}
                        onChange={(e) => handleOnChange(e, "dropdown", "companyOptions")}
                    />
                </Col>
            </Row>
            {loading ? (
                <h6>Loading...</h6>
            ) : (
                <Row>
                    <Col md={6}>
                        <h4 style={{textAlign: "center"}}>Expenses</h4>
                        <table style={{width: "100%", border: "1px solid gray", textAlign: "center"}} key={"mainTable"}>
                            <thead style={{borderBottom: "1px solid gray"}}>
                            <th>Invoice Type</th>
                            <th>Mark</th>
                            <th>Supplier VAT</th>
                            <th>Date</th>
                            <th>Total Amount</th>
                            <th>Classified</th>
                            </thead>
                            <tbody>
                            {data && !isEmpty(viewingCompany) && data.expenses &&
                                data.expenses.results && data?.expenses.results.length > 0 && data.expenses.results.sort((a, b) => moment(a.date) < moment(b.date) ? -1 : 1).map((row, idx) => (
                                <tr key={row + idx} style={{
                                    backgroundColor: getRowColorExpenses(row.CLASSIFIED),
                                    borderBottom: "1px solid gray"
                                }}>
                                    <td>{row.myDataType}</td>
                                    <td>{row.mark}</td>
                                    <td>{row.vat}</td>
                                    <td>{moment(row.date).format("DD/MM/YYYY")}</td>
                                    <td>{row.totalAmount}</td>
                                    <td>{row.CLASSIFIED}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </Col>
                    <Col md={6}>
                        <h4 style={{textAlign: "center"}}>Accounting Articles</h4>
                        <table style={{width: "100%", border: "1px solid gray", textAlign: "center"}} key={"mainTable"}>
                            <thead style={{borderBottom: "1px solid gray"}}>
                            <th>Invoice Type</th>
                            <th>My Data Status</th>
                            </thead>
                            <tbody>
                            {data && !isEmpty(viewingCompany) && data.articles && data.articles.length > 0 && data.articles.map((row, idx) => (
                                <tr key={row + idx} style={{
                                    backgroundColor: getRowColorArticles(row.myDataStatus),
                                    borderBottom: "1px solid gray"
                                }}>
                                    <td>{row.myDataType}</td>
                                    <td>{row.myDataStatus}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </Col>
                </Row>
                )}
        </div>
)
}

export default SettingsExpenses