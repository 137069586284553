import React, {useState, useEffect} from 'react';
import SearchTable from "../../../primer/Search-Table";
import {useSelector, useDispatch} from "react-redux";
import {getOptionsByData} from '../../../../_helpers/commonFunctions';
import {getObjectFromArrayOfObjects} from '../../../../_helpers/helperFunctions';
import {
    fetchAdvancedTypesSales
} from "../../../../_apis/api";
import {useTranslation} from "react-i18next";
import {classicStyleBelowNavbar} from "../Statics";

function AutomaticSalesSearch() {

    const [t] = useTranslation('common');
    const dispatch = useDispatch();
    const SETTINGS = useSelector((state) => state.SETTINGS);
    const allDocumentTypes = SETTINGS.settings.advancedTypesSales;
    const company = useSelector((state) => state.COMPANY_DATA.company);
    // const clientPaymentsDT = getObjectFromArrayOfObjects(allDocumentTypes, 'Client Payments', 'section');
    const documentTypesOptions = getOptionsByData(allDocumentTypes, '_id', 'name')

    const requestData = {
        company: company.id,
        year: company.year.toString(),
    };

    useEffect(() => {
        dispatch(fetchAdvancedTypesSales(requestData));
    }, [dispatch]);

    const scColumns = [
        {
            name: t('SalesAutomaticSalesSearch.table.client'),
            field: "clientName",
            width: "10%",
            editable: false,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t('SalesAutomaticSalesSearch.table.dateStarted'),
            field: "startDate",
            editable: false,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t('SalesAutomaticSalesSearch.table.endDate'),
            field: "endDate",
            editable: false,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t('SalesAutomaticSalesSearch.table.issueInterval'),
            field: "issueInterval",
            editable: false,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t('SalesAutomaticSalesSearch.table.documentType'),
            field: "documentTypeName",
            editable: false,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t('SalesAutomaticSalesSearch.table.totalSum'),
            field: "totalSum",
            editable: false,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t('SalesAutomaticSalesSearch.table.active'),
            field: "isActive",
            editable: false,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t('SalesAutomaticSalesSearch.table.lastLog'),
            field: "lastLog",
            editable: false,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t('SalesAutomaticSalesSearch.table.failReason'),
            field: "logFailReason",
            editable: false,
            inputType: {
                type: 'text',
            }
        },
    ]

    const searchAutomaticSalesFilters = {
        filters: [
            {
                name: "clientName_from",
                label: t('SalesAutomaticSalesSearch.clientNameFrom'),
                type: "input",
            },
            {
                name: "clientName_to",
                label: t('SalesAutomaticSalesSearch.clientNameTo'),
                type: "input",
            },
            {
                name: "dateStarted_from",
                label: t('SalesAutomaticSalesSearch.dateStartedFrom'),
                type: "input",
            },
            {
                name: "dateStarted_to",
                label: t('SalesAutomaticSalesSearch.dateStartedTo'),
                type: "input",
            },
            {
                name: "dateEnd_from",
                label: t('SalesAutomaticSalesSearch.endDateFrom'),
                type: "input",
            },
            {
                name: "dateEnd_to",
                label: t('SalesAutomaticSalesSearch.endDateTo'),
                type: "input",
            },
            {
                name: "documentType",
                label: t('SalesAutomaticSalesSearch.documentType'),
                type: "multiselect",
                options: documentTypesOptions
            },
            {
                name: "totalSum_from",
                label: t('SalesAutomaticSalesSearch.totalSumFrom'),
                type: "input",
            },
            {
                name: "totalSum_to",
                label: t('SalesAutomaticSalesSearch.totalSumTo'),
                type: "input",
            },
            {
                name: "isactive",
                label: t('SalesAutomaticSalesSearch.active'),
                type: "dropdown",
                options: [
                    {
                        value: "all",
                        label: t('SalesAutomaticSalesSearch.all')
                    },
                    {
                        value: "active",
                        label: t('SalesAutomaticSalesSearch.active')
                    },
                    {
                        value: "inactive",
                        label: t('SalesAutomaticSalesSearch.inActive')
                    }
                ]
            },
            {
                name: "log",
                label: "Log",
                type: "dropdown",
                options: [
                    {
                        value: "",
                        label: t('SalesAutomaticSalesSearch.null')
                    },
                    {
                        value: "success",
                        label: t('SalesAutomaticSalesSearch.success')
                    },
                    {
                        value: "fail",
                        label: t('SalesAutomaticSalesSearch.fail')
                    }
                ]
            },
        ],
        actions: ["quick-edit", "delete"],
    };

    const [tableColumns, setTableColumns] = useState(scColumns.slice());

    // It will hide or show the table columns for searchtable.
    const handleColumnShowHide = (field) => {
        let tempTc = tableColumns.slice();
        let object = getObjectFromArrayOfObjects(tempTc, field, 'field')
        const index = tempTc.findIndex((item) => field === item.field);
        if (object.length > 0) {
            tempTc[index].hiddenColumn = !object['0'].hiddenColumn;
            setTableColumns(tempTc);
        }
    }

    return (
        <div style={classicStyleBelowNavbar}>
            <SearchTable
                tableName="Automatic Sales Search"
                key={"Automatic Sales Search"}
                allowPagination={true}
                hideYearFilter={true}
                allowToggleColumns={true}
                columns={tableColumns}
                toggleColumns={handleColumnShowHide}
                data={[]}  // Empty by default
                filtersConfig={searchAutomaticSalesFilters}
            />
        </div>
    )
}

export default AutomaticSalesSearch;
