import React from 'react';

function SalesAutomaticSalesReport() {
  return (
    <div>
      There is no details for this page.
    </div>
  )
}

export default SalesAutomaticSalesReport;