import React, {useEffect, useState} from "react";
import {classicStyleBelowNavbar} from "../Statics";
import axios from "axios";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Badge, Col, Row} from "react-bootstrap";
import DateHelper from "../../../primer/DateHelper/DateHelper";
import DateBox from "../../../primer/DateBox";
import {checkPermission, isEmpty} from "../../../../_helpers/commonFunctions";
import moment from "moment/moment";
import AdvancedCombobox from "../../../primer/AdvancedCombobox";
import {toast} from "react-toastify";
import Button from "react-bootstrap/Button";
import EditableTable from "../../../primer/Editable-Table";
import {
    setClientIsozygioReportRequestData,
    setClientIsozygioReportResponseData,
    setClientIsozygioReportTotals
} from "../../../../_reducers/DataPanel/Clients/clientSlice";
import BetterCheckBox from "../../../common/BetterCheckBox";

const ReportClientIsozygio = () => {

    const [t] = useTranslation('common');
    const dispatch = useDispatch();
    const requestData = useSelector((state) => state.CLIENTS_DATA.clientIsozygioReportRequestData);
    const responseData = useSelector((state) => state.CLIENTS_DATA.clientIsozygioReportResponseData);
    const isozygioTotals = useSelector((state) => state.CLIENTS_DATA.clientIsozygioReportTotals);
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const SETTINGS = useSelector(state => state.SETTINGS);
    const permissionsData = JSON.parse(localStorage.getItem("permissions"));
    const [clientsList, setClientsList] = useState([]);

    const [loading, setLoading] = useState(false);
    const [pageChanged, setPageChanged] = useState(false);
    const [incrKey, setIncrKey] = useState(0);

    useEffect(() => {
        loadClientsListData(null, setClientsList);

        if (requestData.firstLoad) {
            const currentYear = Number(moment().format("YYYY"));
            if (Number(company.year) < currentYear) {
                dispatch(setClientIsozygioReportRequestData({
                    ...requestData,
                    date_from: `01/01/${company.year}`,
                    date_to: `31/12/${company.year}`,
                    firstLoad: false
                }));
            } else {
                dispatch(setClientIsozygioReportRequestData({
                    ...requestData,
                    date_from: moment().startOf("month").format("DD/MM/YYYY"),
                    date_to: moment().endOf("month").format("DD/MM/YYYY"),
                    firstLoad: false
                }));
            }
        }
    }, []);

    useEffect(() => {
        if (pageChanged) {
            setPageChanged(false);
            fetchReport(true);
        }
    }, [pageChanged])

    const columns = [
        {
            name: "Αρ.",
            field: "no",
            editable: false,
            inputType: {
                type: "text",
            },
        },
        {
            name: t('ReportClientIsozygio.table.carryOver'),
            field: "carryOverBalance",
            editable: false,
            inputType: {
                type: "text",
            },
        },
        {
            name: t('ReportClientIsozygio.table.clientName'),
            field: "name",
            editable: false,
            inputType: {
                type: "text",
            },
        },
        {
            name: t('ReportClientIsozygio.table.clientVat'),
            field: "vatNumber",
            editable: false,
            inputType: {
                type: "text",
            },
        },
        {
            name: t('ReportClientIsozygio.table.debit'),
            field: "debit",
            editable: false,
            inputType: {
                type: "text",
            },
        },
        {
            name: t('ReportClientIsozygio.table.credit'),
            field: "credit",
            editable: false,
            inputType: {
                type: "text",
            },
        },
        {
            name: t('ReportClientIsozygio.table.balance'),
            field: "balance",
            editable: false,
            inputType: {
                type: "text",
            },
        },
        {
            name: t('ReportClientIsozygio.table.totalBalance'),
            field: "totalBalance",
            editable: false,
            inputType: {
                type: "text",
            },
        },
    ];

    const clientDropdownColumns = [
        {
            label: t('SalesClientPaymentReport.table3.clientName'),
            name: "name",
        },
        {
            label: t('SalesClientPaymentReport.table3.vatNumber'),
            name: "vatNumber",
        },
        {
            label: t('SalesClientPaymentReport.table3.phoneNumber'),
            name: "phone",
        },
    ];

    const loadClientsListData = (keyword, setData) => {
        let reqData = {
            company: company.id,
        }
        if (keyword) reqData.name = keyword;
        axios.post(process.env.REACT_APP_API_URL2 + `/client/list-client`, reqData, {
            headers: {"Content-Type": "application/json"},
        }).then((res) => {
            if (res.data && res.data.status === "200") {
                if (res.data.data) {
                    if (setData) setData(res.data.data)
                } else {
                    if (setData) setData([])
                }
            } else {
                if (setData) setData([])
            }
        }).catch((e) => {
            console.log(e);
        });
    }

    const handleOnChange = (e, type, name) => {
        if (type === "datebox") {
            if (moment(e).isValid()) {
                dispatch(setClientIsozygioReportRequestData({...requestData, [name] : moment(e).format("DD/MM/YYYY")}))
            } else {
                dispatch(setClientIsozygioReportRequestData({...requestData, [name] : ""}))
            }
        } else if (type === "dropdown") {
            dispatch(setClientIsozygioReportRequestData({...requestData, [name] : e.value}));
        } else if (type === "acdd") {
            if (name === "clientName") {
                dispatch(setClientIsozygioReportRequestData({...requestData, clientName : e.row.name}));
            }
        }
    }

    const fetchReport = (fromPagination = false) => {
        setLoading(true);
        let reqData = {
            company: company.id,
            year: company.year,
            ...requestData,
            fromPagination
        }
        if (!fromPagination) reqData.page = 1;

        axios.post(process.env.REACT_APP_API_URL2 + `/report/client-isozygio-report`, reqData, {
            headers: {"Content-Type": "application/json"}
        }).then((res) => {
            setLoading(false);
            if (res.data.status === "200") {
                if (!fromPagination) {
                    if (res.data.totalPages === 0) {
                        toast.info("Δεν βρέθηκαν αποτελέσματα.");
                    } else {
                        toast.success("Τα δεδομένα ανανεώθηκαν.", {autoClose: 1000});
                    }
                    dispatch(setClientIsozygioReportRequestData({...requestData, page: 1}));
                }
                let cnt = 1;
                for (let d of res.data.data) {
                    d.no = String((requestData.page - 1) * 20 + cnt);
                    cnt++;
                }
                dispatch(setClientIsozygioReportResponseData({...responseData, data: res.data.data, totalPages: res.data.totalPages}));
                if (!fromPagination) dispatch(setClientIsozygioReportTotals(res.data.totals));
                setIncrKey(incrKey + 1);
            } else {
                toast.error(res.data.message);
            }
        }).catch((error) => {
            setLoading(false);
            console.log(error);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        })
    }

    const toFirstPage = () => {
        dispatch(setClientIsozygioReportRequestData({...requestData, page: 1}));
        setPageChanged(true);
    }

    const toNextPage = () => {
        dispatch(setClientIsozygioReportRequestData({...requestData, page: requestData.page + 1}));
        setPageChanged(true);
    }
    const toPreviousPage = () => {
        dispatch(setClientIsozygioReportRequestData({...requestData, page: requestData.page - 1}));
        setPageChanged(true);
    }

    const toLastPage = () => {
        dispatch(setClientIsozygioReportRequestData({...requestData, page: responseData.totalPages}));
        setPageChanged(true);
    }

    const onChangeSearch = (keyword, setData) => {
        if (String(keyword).length >= 2) {
            loadClientsListData(keyword, setData);
        }
    }

    return (
        <div style={classicStyleBelowNavbar}>
            <Row className={"mb-2"}>
                <Col md={3}>
                    <DateHelper
                        dateFromAndToFunction={(from, to) => dispatch(setClientIsozygioReportRequestData({
                            ...requestData,
                            date_from: from,
                            date_to: to
                        }))}
                    />
                </Col>
                <Col md={2}>
                    <DateBox
                        dateFormat="dd-MM-yyyy"
                        name="date_from"
                        selected={isEmpty(requestData.date_from) ? moment().toDate() : moment(requestData.date_from, "DD/MM/YYYY").toDate()}
                        label={t('SalesBalanceSheet.dateFrom')}
                        onChange={(e) => handleOnChange(e, "datebox", "date_from")}
                    />
                </Col>
                <Col md={2}>
                    <DateBox
                        dateFormat="dd-MM-yyyy"
                        name="date_to"
                        selected={isEmpty(requestData.date_to) ? moment().toDate() : moment(requestData.date_to, "DD/MM/YYYY").toDate()}
                        label={t('SalesBalanceSheet.dateTo')}
                        onChange={(e) => handleOnChange(e, "datebox", "date_to")}
                    />
                </Col>
                <Col md={5}>
                    <AdvancedCombobox
                        ACName="Sales Client" // do not change name
                        className="w-100 ac-select-dropdown"
                        searchableDropdownEnable={false}
                        defaultValue={requestData.clientName}
                        label={"Πελάτης"}
                        data={clientsList}
                        onChange={(e) => handleOnChange(e, "acdd", "clientName")}
                        resetFilter={() => dispatch(setClientIsozygioReportRequestData({
                            ...requestData,
                            clientName: ""
                        }))}
                        onChangeSearch={onChangeSearch}
                        excludeColumns={[]}
                        columns={clientDropdownColumns}
                        editEnable={false}
                        deleteEnable={false}
                        searchTableEnable={false}
                        showTableOptionOnSelect={true}
                        placeholder={"Επιλέξτε πελάτη"}
                        required={false}
                        name="clientName"
                        showInsertAddButton={true}
                        hideSaveButton={true}
                        hideInsertNewRow={true}
                        inputFieldClasses="form-control"
                    />
                </Col>
            </Row>
            <Row>
                <Col md={3} className={"ml-auto"}>
                    <BetterCheckBox
                        text = {t("General.includeZeroBalanceClients")}
                        name = "zero-balance-clients"
                        checked = {requestData.zeroBalanceClients}
                        onChange= {(e) => {
                            dispatch(setClientIsozygioReportRequestData({...requestData, zeroBalanceClients: e.target.checked}));
                        }}
                        disabled = {false}
                    />
                </Col>
            </Row>
            <Row className={"mb-2"}>
                <Col md={12} className={"ml-auto"}>
                    <Button onClick={() => fetchReport()} className="btn btn-primary float-right" disabled={loading}>
                        {t('General.applyFilter')}
                    </Button>
                </Col>
            </Row>
            {responseData.totalPages > 0 && (
                <Row>
                    <hr style={{color: "gray", height: 1}}/>
                    <Col md={12}>
                        <h3 style={{textAlign: "center", alignItems: "center", fontWeight: "bold"}}>
                            {t('ReportClientIsozygio.clientsIsozygio')}
                        </h3>
                    </Col>
                    <Col md={12}>
                        <div className="d-flex justify-content-end mb-2">
                            <Button
                                size={"sm"}
                                className={"mr-1"}
                                onClick={() => toFirstPage()}
                                disabled={requestData.page === 1 || loading}
                            >
                                {t('SalesBalanceSheet.first')}
                            </Button>
                            <Button
                                size={"sm"}
                                className={"mr-1"}
                                onClick={() => toPreviousPage()}
                                disabled={requestData.page === 1 || loading}
                            >
                                {t('SalesBalanceSheet.previous')}
                            </Button>
                            <Badge style={{fontSize: "12px", lineHeight: "20px"}} className="mr-1"
                                   bg="primary">{t('SalesBalanceSheet.page')} {requestData.page} / {responseData.totalPages}</Badge>
                            <Button
                                size={"sm"}
                                className={"mr-1"}
                                onClick={() => toNextPage()}
                                disabled={requestData.page === responseData.totalPages || loading}
                            >
                                {t('SalesBalanceSheet.next')}
                            </Button>
                            <Button
                                size={"sm"}
                                className={"mr-1"}
                                onClick={() => toLastPage()}
                                disabled={requestData.page === responseData.totalPages || loading}
                            >
                                {t('SalesBalanceSheet.last')}
                            </Button>
                        </div>
                    </Col>
                    <Col md={12}>
                        <EditableTable
                            key={"Client Isozygio Report" + incrKey}
                            tableName="Client Isozygio Report"
                            data={responseData.data}
                            allowPagination={false}
                            allowToggleColumns={true}
                            allowInsertRow={false}
                            editRedirectAllowed={true}
                            enableNewRowCheckbox={false}
                            allowBulkActions={true}
                            reportExcelPrint={true}
                            pageTotals={true}
                            company={company}
                            columns={columns}
                            allowActions={false}
                            allowPrintResults={checkPermission("clients-reports/clientisozygio-print", permissionsData) === true}
                            showIsozygioTotals={true}
                            totalCarryOverBalance={isozygioTotals.totalCarryOverBalance}
                            totalDebit={isozygioTotals.totalDebit}
                            totalCredit={isozygioTotals.totalCredit}
                            totalPeriodBalance={isozygioTotals.totalPeriodBalance}
                            totalBalanceTotal={isozygioTotals.totalBalanceTotal}
                            filters={requestData}
                        />
                    </Col>
                </Row>
            )}
        </div>
    )
}

export default ReportClientIsozygio;
