// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.clientMovementsReportTable tr td, .clientMovementsReportTable tr th {
  border: 1px solid gray; }

.clientMovementsReportTable tr:hover {
  background-color: #f3f3f3; }
`, "",{"version":3,"sources":["webpack://./src/_components/layouts/data-panel/Clients/tableStyle.scss"],"names":[],"mappings":"AAAA;EACE,sBAAsB,EAAA;;AAGxB;EACE,yBAAyB,EAAA","sourcesContent":[".clientMovementsReportTable tr td, .clientMovementsReportTable tr th {\r\n  border: 1px solid gray;\r\n}\r\n\r\n.clientMovementsReportTable tr:hover {\r\n  background-color: #f3f3f3;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
