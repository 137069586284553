export const vatZeroPer1Data = [
    {
        "id": "1",
        "code": "1",
        "description": "Χωρίς ΦΠΑ- άρθρο 2 και 3 του κώδικα ΦΠΑ"
    },
    {
        "id": "2",
        "code": "2",
        "description": "Χωρίς ΦΠΑ- άρθρο 5 του Κώδικα ΦΠΑ"
    },
    {
        "id": "3",
        "code": "3",
        "description": "Χωρίς ΦΠΑ- άρθρο 13 του Κώδικα ΦΠΑ"
    },
    {
        "id": "4",
        "code": "4",
        "description": "Χωρίς ΦΠΑ- άρθρο 14 του Κώδικα ΦΠΑ"
    },
    {
        "id": "5",
        "code": "5",
        "description": "Χωρίς ΦΠΑ- άρθρο 16 του Κώδικα ΦΠΑ"
    },
    {
        "id": "6",
        "code": "6",
        "description": "Χωρίς ΦΠΑ- άρθρο 19 του Κώδικα ΦΠΑ"
    },
    {
        "id": "7",
        "code": "7",
        "description": "Χωρίς ΦΠΑ- άρθρο 22 του Κώδικα ΦΠΑ"
    },
    {
        "id": "8",
        "code": "8",
        "description": "Χωρίς ΦΠΑ- άρθρο 24 του Κώδικα ΦΠΑ"
    },
    {
        "id": "9",
        "code": "9",
        "description": "Χωρίς ΦΠΑ- άρθρο 25 του Κώδικα ΦΠΑ"
    },
    {
        "id": "10",
        "code": "10",
        "description": "Χωρίς ΦΠΑ- άρθρο 26 του Κώδικα ΦΠΑ"
    },
    {
        "id": "11",
        "code": "11",
        "description": "Χωρίς ΦΠΑ- άρθρο 27 του Κώδικα ΦΠΑ"
    },
    {
        "id": "12",
        "code": "12",
        "description": "Χωρίς ΦΠΑ- άρθρο 27 -Πλοία Ανοικτής Θαλάσσης του Κώδικα ΦΠΑ"
    }
]

export const vatZeroPer2Data = [
    {
        "id": "13",
        "code": "13",
        "description": "Χωρίς ΦΠΑ- άρθρο 27.1.γ -Πλοία Ανοικτής Θαλάσσης του Κώδικα ΦΠΑ"
    },
    {
        "id": "14",
        "code": "14",
        "description": "Χωρίς ΦΠΑ- άρθρο 28 του Κώδικα ΦΠΑ"
    },
    {
        "id": "15",
        "code": "15",
        "description": "Χωρίς ΦΠΑ- άρθρο 39 του Κώδικα ΦΠΑ"
    },
    {
        "id": "16",
        "code": "16",
        "description": "Χωρίς ΦΠΑ- άρθρο 39α του Κώδικα ΦΠΑ"
    },
    {
        "id": "17",
        "code": "17",
        "description": "Χωρίς ΦΠΑ- άρθρο 40 του Κώδικα ΦΠΑ"
    },
    {
        "id": "18",
        "code": "18",
        "description": "Χωρίς ΦΠΑ- άρθρο 41 του Κώδικα ΦΠΑ"
    },
    {
        "id": "19",
        "code": "19",
        "description": "Χωρίς ΦΠΑ- άρθρο 47 του Κώδικα ΦΠΑ"
    },
    {
        "id": "20",
        "code": "20",
        "description": "ΦΠΑ Εμπεριεχόμενος - άρθρο 43 του Κώδικα ΦΠΑ"
    },
    {
        "id": "21",
        "code": "21",
        "description": "ΦΠΑ Εμπεριεχόμενος - άρθρο 44 του Κώδικα ΦΠΑ"
    },
    {
        "id": "22",
        "code": "22",
        "description": "ΦΠΑ Εμπεριεχόμενος - άρθρο 45 του Κώδικα ΦΠΑ"
    },
    {
        "id": "23",
        "code": "23",
        "description": "ΦΠΑ Εμπεριεχόμενος - άρθρο 46 του Κώδικα ΦΠΑ"
    },
    {
        "id": "24",
        "code": "24",
        "description": "Χωρίς ΦΠΑ- άρθρο 6 του Κώδικα ΦΠΑ"
    },
    {
        "id": "25",
        "code": "25",
        "description": "Χωρίς ΦΠΑ - ΠΟΛ.1029/1995"
    },
    {
        "id": "26",
        "code": "26",
        "description": "Χωρίς ΦΠΑ - ΠΟΛ.1167/2015"
    },
    {
        "id": "27",
        "code": "27",
        "description": "Λοιπές Εξαιρέσεις ΦΠΑ"
    },
    {
        "id": "28",
        "code": "28",
        "description": "Χωρίς ΦΠΑ – άρθρο 24 περ. β' παρ.1 του Κώδικα ΦΠΑ, (Tax Free)"
    },
    {
        "id": "29",
        "code": "29",
        "description": "Χωρίς ΦΠΑ – άρθρο 47β, του Κώδικα ΦΠΑ (OSS μη ενωσιακό καθεστώς)"
    },
    {
        "id": "30",
        "code": "30",
        "description": "Χωρίς ΦΠΑ – άρθρο 47γ, του Κώδικα ΦΠΑ (OSS ενωσιακό καθεστώς)"
    },
    {
        "id": "31",
        "code": "31",
        "description": "Χωρίς ΦΠΑ – άρθρο 47δ του Κώδικα ΦΠΑ (IOSS)"
    }
]

export const vatZeroPerData = [...vatZeroPer1Data, ...vatZeroPer2Data];