import React, {useState} from "react";
import {Button, Modal} from "react-bootstrap";
import "./modalWidths.scss"

const AnathetousesModal = (props) => {
    // props.data for client data (new/edit)
    const [show, setShow] = useState(false);

    return (
        <React.Fragment>
            {props.data && props.data?.peppolPartyIdentification && props.data?.peppolPartyIdentification?.length > 0 && (
                <React.Fragment>
                    <Button size={"sm"} onClick={() => setShow(true)}>Προβολή Αναθετουσών Αρχών</Button>
                    <Modal show={show} onHide={() => setShow(false)} dialogClassName={"modal5OPercentWidth"}>
                        <Modal.Header closeButton>
                            <Modal.Title>Αναθέτουσες Αρχές ({props.data?.name})</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {props.data.peppolPartyIdentification[0]?.anForeasTypeDescr && (
                                <div className={"mb-2"}>
                                    Ο τύπος του φορέα είναι: <strong>{props.data.peppolPartyIdentification[0]?.anForeasTypeDescr}</strong>
                                </div>
                            )}
                            <table style={{tableLayout: "fixed", width: "99%", textAlign: "center"}}>
                                <thead>
                                <tr>
                                    <th style={{border: "1px solid gray"}}>Όνομα Αναθέτουσας Αρχής</th>
                                    <th style={{border: "1px solid gray"}}>Κωδικός Αναθέτουσας Αρχής</th>
                                </tr>
                                </thead>
                                <tbody>
                                {props.data.peppolPartyIdentification.map((row, idx) => (
                                    <tr key={`anatheTable${idx}`}>
                                        <td style={{border: "1px solid gray"}}>{row?.anathetousaDescr}</td>
                                        <td style={{border: "1px solid gray"}}>{row?.anathetousaLabelId}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="outline-primary" onClick={() => setShow(false)}>Κλείσιμο</Button>
                        </Modal.Footer>
                    </Modal>
                </React.Fragment>
            )}
        </React.Fragment>
    )
}

export default AnathetousesModal