import {INTERNATIONAL_CODES_20} from "./codes20";
import {INTERNATIONAL_CODES_21} from "./codes21";

export const findDuplicates = () => {
    const codes20 = INTERNATIONAL_CODES_20;
    const codes21 = INTERNATIONAL_CODES_21;
    let cnt = 0;
    for (let row of codes20.filter((el) => el.status !== "X")) {
        cnt++;
        const findSameCode = codes21.find((el) => String(el.code) === String(row.code));
        if (findSameCode) console.log(findSameCode, row);
    }
    console.log(`Scanned ${cnt} for duplicates`);
}

export const getInternationalCodesTableData = (nameFilter = null) => {
    let data = [];
    let codes20 = INTERNATIONAL_CODES_20;
    if (nameFilter) codes20 = codes20.filter((el) => String(el.name).toLowerCase().includes(String(nameFilter).toLowerCase()) || String(el.code).toLowerCase().includes(String(nameFilter).toLowerCase()));
    let codes21 = INTERNATIONAL_CODES_21;
    if (nameFilter) codes21 = codes21.filter((el) => String(el.name).toLowerCase().includes(String(nameFilter).toLowerCase()) || String(el.code).toLowerCase().includes(String(nameFilter).toLowerCase()));
    for (let c21 of codes21) {
        data.push({
            code: c21.code,
            name: c21.name,
            description: c21.description,
        })
    }
    for (let c20 of codes20) {
        if (!data.find((el) => String(el.code) === String(c20.code))) {
            data.push({
                code: c20.code,
                name: c20.name,
                description: c20.description,
            })
        }
    }
    return data;
}

export const validInternationalCodeUnit = (mu) => {
    return getInternationalCodesTableData().findIndex((el) => String(el.code) === mu) >= 0;
}

// Mapping My Data : International Code
export const classicInternationalMeasurementUnits = {
    "1": "H87",
    "2": "KGM",
    "3": "LTR",
    "4": "E48",
    "5": "MTR",
    "6": "MTK",
    "7": "MTQ",
    "8": "H87",
}