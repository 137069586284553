import React, {useState} from "react";
import {Row, Col, Container} from "react-bootstrap";
import EditableTable from "../../../primer/Editable-Table";
import {feesDropdownOptions, feesTaxLevel, feesVatIncluded} from "../../../../_data/mydata/fees";
import {setEditing, setSavePrimerMyDataData} from "../../../../_reducers/activitySlice";
import {setNewFee, setPrimerMyDataData} from "../../../../_reducers/settingsSlice";
import {toast} from "react-toastify";
import {deleteObjectFromArrayOfObjects, findObjectFromArrayOfObjects} from "../../../../_helpers/helperFunctions";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {checkPermission} from "../../../../_helpers/commonFunctions";

const VatDataOtherTaxes2 = () => {
    const [t] = useTranslation('common');
    const dispatch = useDispatch();
    const SETTINGS = useSelector(state => state.SETTINGS);
    const ACTIVITY = useSelector((state) => state.ACTIVITY);
    let pMDDSettings = SETTINGS.primerMyDataData;
    let feesData = pMDDSettings?.fees !== undefined && pMDDSettings?.fees !== '' ? (pMDDSettings.fees) : deleteObjectFromArrayOfObjects(SETTINGS.fees, 'delete', 'isActive');
    const [feesTableData, setFees] = useState(feesData);
    const permissionsData = JSON.parse(localStorage.getItem("user"))?.permissions;

    const FeesActions = {
        insert: (e, data) => {
            // Set if editing the form
            if (!ACTIVITY.editing) {
                dispatch(setEditing(true));
            }
            let tempNewFee = Object.assign({}, SETTINGS.newFee);
            if (e.key !== "Enter") {
                tempNewFee[data.field] = data.value;

                if (data.field === 'taxAmount' && (data.value === "on" || data.value === "true")) {
                    tempNewFee.taxAmount = "true";
                    tempNewFee.taxPer = "false";
                }

                if (data.field === 'taxPer' && (data.value === "on" || data.value === "true")) {
                    tempNewFee.taxAmount = "false";
                    tempNewFee.taxPer = "true";
                }

                dispatch(setNewFee(tempNewFee)); // Update redux new category state

                // On Value Enter
                if (tempNewFee.name !== "" &&
                    tempNewFee.myData !== "" &&
                    tempNewFee.tax !== "" &&
                    (tempNewFee.taxAmount === "true" || tempNewFee.taxPer === "true") &&
                    tempNewFee.taxLevel !== "" &&
                    tempNewFee.vatIncluded !== "") {
                    let updatedNewFee = Object.assign({}, SETTINGS.newFee);
                    let tempTaxesData = feesTableData.slice();
                    if (tempTaxesData) {
                        // Generate code number
                        if (tempTaxesData.length > 0) {
                            updatedNewFee.code = parseInt(tempTaxesData[tempTaxesData.length - 1].code) + 1;
                            updatedNewFee.id = parseInt(tempTaxesData[tempTaxesData.length - 1].code) + 1;
                        } else {
                            updatedNewFee.code = 1
                            updatedNewFee.id = 1
                        }
                        updatedNewFee[data.field] = data.value; // last value updates
                        tempTaxesData[updatedNewFee.id - 1] = updatedNewFee;
                        setFees(tempTaxesData);
                        dispatch(setPrimerMyDataData({...pMDDSettings, fees: (tempTaxesData)}))
                        dispatch(setSavePrimerMyDataData(true));// Reset
                        toast.info('1 row added. Please save your changes');
                    }
                }
            }
        },
        selected: () => {
            // Set if editing the form
            if (!ACTIVITY.editing) {
                dispatch(setEditing(true));
            }

        },
        update: (data, id) => {
            // Set if editing the form
            if (!ACTIVITY.editing) {
                dispatch(setEditing(true));
            }

            let validCheck = true;
            dispatch(setSavePrimerMyDataData(true));// update redux state

            if (data.tax && ((data.tax === '') || (data.tax === 'undefined'))) {
                toast.error('Tax should not be empty');
                validCheck = false;
            }

            if (data.name && data.name === '') {
                toast.error('Name should not be empty');
                validCheck = false;
            }

            if (data.myData && data.myData === '') {
                toast.error('Tax should not be empty');
                validCheck = false;
            }

            if (data.taxAmount && (data.taxAmount === "on" || data.taxAmount === "true")) {
                data.taxAmount = "true";
                data.taxPer = "false";
            }

            if (data.taxPer && (data.taxPer === "on" || data.taxPer === "true")) {
                data.taxAmount = "false";
                data.taxPer = "true";
            }
            if (feesTableData && validCheck) {
                let oTaxData = feesTableData.slice()
                let objindx = findObjectFromArrayOfObjects(feesTableData, id.toString(), 'code');
                objindx = Object.assign({}, objindx);
                const wObjIdx = feesTableData.findIndex(wObj => wObj.code === id.toString());
                if (data.taxPer) {
                    objindx.taxPer = data.taxPer;
                }
                if (data.taxAmount) {
                    objindx.taxAmount = data.taxAmount;
                }
                if (data.tax) {
                    objindx.tax = data.tax;
                }
                if (data.name) {
                    objindx.name = data.name;
                }
                if (data.myData) {
                    objindx.myData = data.myData;
                }
                if (data.taxLevel) {
                    objindx.taxLevel = data.taxLevel;
                }
                if (data.vatIncluded) {
                    objindx.vatIncluded = data.vatIncluded;
                }
                if (data.vatPercentage) {
                    objindx.vatPercentage = data.vatPercentage;
                }
                if (data.accounting !== undefined) {
                    objindx.accounting = data.accounting;
                }
                oTaxData[wObjIdx] = objindx;
                console.log(oTaxData)
                setFees(oTaxData);
                dispatch(setPrimerMyDataData({...pMDDSettings, fees: (oTaxData)}))
                toast.info('1 row added. Please save your changes');
            }
        },
        delete: (id) => {
            let tempTaxesData = feesTableData.slice();
            if (tempTaxesData) {
                const objindx = feesTableData.findIndex(obj => obj.id === id);
                let tempUpdateObj = findObjectFromArrayOfObjects(feesTableData, id.toString(), 'code');
                tempUpdateObj = Object.assign({}, tempUpdateObj);
                tempUpdateObj.isActive = 'delete';
                tempTaxesData[objindx] = tempUpdateObj;
                tempTaxesData.splice(objindx, 1);
                setFees(tempTaxesData);
                dispatch(setPrimerMyDataData({...pMDDSettings, fees: (tempTaxesData)}));
                dispatch(setSavePrimerMyDataData(true));// update redux state
                toast.info('1 row removed. Please save your changes');
            }
        }
    }

    const stampActions = {
        update: (data, id, rowIndex) => {
            console.log(data, rowIndex);
            if (data.accounting !== undefined) {
                let cloneStamp = pMDDSettings.stamp;
                cloneStamp = cloneStamp.map((o) => Object.assign({}, o));
                cloneStamp[rowIndex].accounting = data.accounting;
                dispatch(setPrimerMyDataData({...pMDDSettings, stamp: (cloneStamp)}));
                dispatch(setSavePrimerMyDataData(true));
                toast.info('1 row removed. Please save your changes');
            }
        },
        selected : () => {}
    }

    const feesColumns = [
        {
            name: "No",
            field: "id",
            editable: false,
            width: "2%",
        },
        {
            name: t('SettingsPrimerData.tableOtherTax.name'),
            field: "name",
            width: "30%",
            editable: true,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t('SettingsPrimerData.tableOtherTax.taxLevel'),
            field: 'taxLevel',
            editable: true,
            width: "15%",
            inputType: {
                type: 'dropdown',
                options: feesTaxLevel
            }
        },
        {
            name: t('SettingsPrimerData.tableOtherTax.vatIncluded'),
            field: 'vatIncluded',
            editable: true,
            width: "10%",
            inputType: {
                type: 'dropdown',
                options: feesVatIncluded
            }
        },
        {
            name: t('SettingsPrimerData.tableOtherTax.vatPercentage'),
            field: 'vatPercentage',
            editable: true,
            width: "3%",
            inputType: {
                type: 'accounting',
                config: {
                    focused: true,
                    suffix: "%",
                    maxLength: 5
                }
            }
        },
        {
            name: '%',
            field: 'taxPer',
            editable: true,
            width: "5%",
            inputType: {
                type: 'radio',
                name: 'feesTaxType',
                radioLogic: 'oneperrow',
                options: [{
                    value: true,
                    label: 'True'
                }, {
                    value: false,
                    label: 'False'
                }]
            }
        },
        {
            name: '€',
            field: 'taxAmount',
            editable: true,
            width: "5%",
            inputType: {
                type: 'radio',
                radioLogic: 'oneperrow',
                name: 'feesTaxType',
                options: [{
                    value: true,
                    label: 'True'
                }, {
                    value: false,
                    label: 'False'
                }]
            }
        },
        {
            name: t('SettingsPrimerData.tableOtherTax.value'),
            field: 'tax',
            editable: true,
            width: "7%",
            inputType: {
                type: 'accounting',
                config: {
                    focused: true,
                    suffix: "%",
                    maxLength: 5
                }
            }
        },
        {
            name: t('SettingsPrimerData.tableOtherTax.myData'),
            field: 'myData',
            editable: true,
            width: "20%",
            inputType: {
                type: 'dropdown',
                options: feesDropdownOptions
            }
        },
        {
            name: "Πρόθεμα Λογιστικής",
            field: "accounting",
            editable: true,
            hiddenColumn: !checkPermission("accounting-settings/accounting-read", permissionsData),
            width: "10%",
            inputType: {
                type: "input",
            }
        }
    ];
    const taxStampColumns = [
        {
            name: t('SettingsPrimerData.tableStamp.code'),
            field: "code",
            editable: false,
        },
        {
            name: t('SettingsPrimerData.tableStamp.description'),
            field: "name",
            editable: false,
        },
        {
            name: t('SettingsPrimerData.tableStamp.taxStamp'),
            field: "tax",
            editable: false,
        },
        {
            name: "Πρόθεμα Λογιστικής",
            field: "accounting",
            editable: true,
            hiddenColumn: !checkPermission("accounting-settings/accounting-read", permissionsData),
            width: "10%",
            inputType: {
                type: "input",
            }
        }
    ];

    return (
        <Container fluid>
            <Row>
                <Col sm="12">
                    <EditableTable
                        heading={t('SettingsPrimerData.feesChargesCategory')}
                        tableName="Fees Table"
                        allowActions={true}
                        allowInsertRow={true}
                        columns={feesColumns}
                        onUpdate={FeesActions}
                        data={feesData}
                        key={Math.random()}
                    />
                </Col>
            </Row>
            <Row>
                <Col sm="12" md="6">
                    <EditableTable
                        heading={t('SettingsPrimerData.stamp')}
                        tableName="Stamp Table"
                        allowActions={false}
                        onUpdate={stampActions}
                        columns={taxStampColumns}
                        data={pMDDSettings?.stamp ? pMDDSettings.stamp : []}
                        key={Math.random()}
                    />
                </Col>
            </Row>
        </Container>
    )
}
export default VatDataOtherTaxes2;
