export const highlightData = [
      {
          "id": "1",
          "code": "1",
          "name": "Εκκαθάριση Πωλήσεων Τρίτων",
          "isActive": 'true'
      },
      {
          "id": "2",
          "code": "2",
          "name": "Αμοιβή από Πωλήσεις Τρίτων",
          "isActive": 'true'
      }
  ]