export const incomeCharacterizationCategory = [
    {
        "id": "1",
        "code": "category1_1",
        "description": "Έσοδα από πώληση Εμπορευμάτων  (+)/ (-)"
    },
    {
        "id": "2",
        "code": "category1_2",
        "description": "Έσοδα από πώληση Προϊόντων  (+)/ (-)"
    },
    {
        "id": "3",
        "code": "category1_3",
        "description": "Έσοδα από Παροχή Υπηρεσιών  (+)/ (-)"
    },
    {
        "id": "4",
        "code": "category1_4",
        "description": "Έσοδα από Πώληση Παγίων  (+)/ (-)"
    },
    {
        "id": "5",
        "code": "category1_5",
        "description": "Λοιπά Έσοδα/Κέρδη (+)/ (-)"
    },
    {
        "id": "6",
        "code": "category1_6",
        "description": "Αυτοπαραδόσεις/ Ιδιοχρησιμοποιήσεις  (+)/ (-)"
    },
    {
        "id": "7",
        "code": "category1_7",
        "description": "Έσοδα από λογ/σμο τρίτων (+)/ (-)"
    },
    {
        "id": "8",
        "code": "category1_8",
        "description": "Έσοδα προηγούμενων χρήσεων (+)/ (-)"
    },
    {
        "id": "9",
        "code": "category1_9",
        "description": "Έσοδα επομένων χρήσεων (+)/ (-)"
    },
    {
        "id": "10",
        "code": "category1_10",
        "description": "Λοιπές εγγραφές τακτοποίησης εσόδων (+)/ (-)"
    },
    {
        "id": "11",
        "code": "category1_95",
        "description": "Λοιπά Πληροφοριακά στοιχεία εσόδων (+)/ (-)"
    },
    {
        "id": "12",
        "code": "category3",
        "description": "Διακίνηση",
    }
]