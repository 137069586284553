export const taxStampCategoryData = [
    {
        id: "1",
        code: "1",
        name: "Συντελεστής 1.2%",
        tax: "1.20%",
        myData: 1,
        taxPer: "true",
        taxAmount: "false",
        vatPercentage: 0,
        taxLevel: 2,
        vatIncluded: 3
    },
    {
        id: "2",
        code: "2",
        name: "Συντελεστής 2.4%",
        tax: "2.40%",
        myData: 2,
        taxPer: "true",
        taxAmount: "false",
        vatPercentage: 0,
        taxLevel: 2,
        vatIncluded: 3
    },
    {
        id: "3",
        code: "3",
        name: "Συντελεστής 3.6%",
        tax: "3.60%",
        myData: 3,
        taxPer: "true",
        taxAmount: "false",
        vatPercentage: 0,
        taxLevel: 2,
        vatIncluded: 3
    }
];