import React, {useReducer} from "react";
import Joyride, {ACTIONS, EVENTS, STATUS} from "react-joyride";
import {useTranslation} from "react-i18next";

export const JOYRIDE_REDUCER = (state, action) => {
    switch (action.type) {
        case "START":
            return { ...state, run: true };
        case "RESET":
            return { ...state, stepIndex: 0 };
        case "STOP":
            return { ...state, run: false };
        case "NEXT_OR_PREV":
            return { ...state, ...action.payload };
        case "RESTART":
            return {
                ...state,
                stepIndex: 0,
                run: true,
                loading: false,
                key: new Date()
            }
        default:
            return state;
    }
};

const Tour = () => {
    const [t] = useTranslation("common");
    const JOYRIDE_STEPS = [
        {
            target: '.forJoyCB',
            content: t("SettingsImportExport.joyride.textInstead"),
            disableBeacon: true,
            disableScrolling: true,
            disableScrollParentFix: true
        },
        {
            target: '.forJoyExport',
            content: t("SettingsImportExport.joyride.exportButton"),
            disableBeacon: true,
            disableScrolling: true,
            disableScrollParentFix: true
        },
        {
            target: '.forJoyBack',
            content: t("SettingsImportExport.joyride.backButton"),
            disableBeacon: true,
            disableScrolling: true,
            disableScrollParentFix: true
        }
    ];
    const JOYRIDE_INIT_STATE = {
        key: new Date(),
        run: false,
        continuous: true,
        loading: false,
        stepIndex: 0,
        steps: JOYRIDE_STEPS
    };
    const [tourState, joy_dispatch] = useReducer(JOYRIDE_REDUCER, JOYRIDE_INIT_STATE);

    const JOYRIDE_SET_TOUR_VIEWED = () => {
        localStorage.setItem("tour", "1");
    };
    const JOYRIDE_CALLBACK = data => {
        const {action, index, type, status} = data;

        if (
            // If close button clicked, then close the tour
            action === ACTIONS.CLOSE ||
            // If skipped or end tour, then close the tour
            (status === STATUS.SKIPPED && tourState.run) ||
            status === STATUS.FINISHED
        ) {
            JOYRIDE_SET_TOUR_VIEWED();
            joy_dispatch({type: "STOP"});
        } else if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
            // Check whether next or back button click and update the step.
            joy_dispatch({
                type: "NEXT_OR_PREV",
                payload: {stepIndex: index + (action === ACTIONS.PREV ? -1 : 1)}
            });
        }
    };
    const JOYRIDE_START_TOUR = () => {
        joy_dispatch({type: "RESTART"});
    };

    return (
        <>
            <button className="btn btn-primary float-right joyHelpButton" onClick={JOYRIDE_START_TOUR}>{t("SettingsImportExport.help")}</button>
            <Joyride
                {...tourState}
                callback={JOYRIDE_CALLBACK}
                showSkipButton={true}
                styles={{
                    tooltipContainer: {
                        textAlign: "left"
                    },
                    buttonBack: {
                        color: '#76AEB7',
                        marginRight: 10
                    },
                    buttonNext: {
                        background: '#76AEB7'
                    },
                }}
                locale={{
                    back: t("SettingsImportExport.joyride.back"),
                    close: t("SettingsImportExport.joyride.close"),
                    last: t("SettingsImportExport.joyride.last"),
                    next: t("SettingsImportExport.joyride.next"),
                    open: t("SettingsImportExport.joyride.open"),
                    skip: t("SettingsImportExport.joyride.skip")
                }}
            />
        </>
    )
}
export default Tour;