// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar-offcanvas {
  min-height: calc(100vh - 50px);
  padding-top: 21px;
  position: fixed;
  max-height: 100vh;
  overflow: auto;
  bottom: 0; }

@media screen and (max-width: 991px) {
  .sidebar-offcanvas.active {
    left: 0;
    top: 50px; } }
`, "",{"version":3,"sources":["webpack://./src/_components/layouts/SIDEBAR-COMPONENTS/Sidebar/index.scss"],"names":[],"mappings":"AAAA;EACE,8BAA8B;EAC9B,iBAAiB;EACjB,eAAe;EACf,iBAAiB;EACjB,cAAc;EACd,SAAS,EAAA;;AAGX;EACE;IACE,OAAO;IACP,SAAS,EAAA,EACV","sourcesContent":[".sidebar-offcanvas {\r\n  min-height: calc(100vh - 50px);\r\n  padding-top: 21px;\r\n  position: fixed;\r\n  max-height: 100vh;\r\n  overflow: auto;\r\n  bottom: 0;\r\n}\r\n\r\n@media screen and (max-width: 991px) {\r\n  .sidebar-offcanvas.active {\r\n    left: 0;\r\n    top: 50px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
