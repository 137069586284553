import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import EditableTable from '../../../primer/Editable-Table';
import {Col} from 'react-bootstrap';
import {
  getDifficultyOptions,
  getInstallationsTypes,
  getStockholderPositions,
  resetTableItemsId
} from '../../../../_helpers/commonFunctions';
import {setSaveNewClient} from "../../../../_reducers/activitySlice";
import {
  setClientEmronStockholders,
  setNewClient,
} from '../../../../_reducers/DataPanel/Clients/clientSlice';
import {useTranslation} from "react-i18next";
import {deleteObjectFromArrayOfObjects, getLabelByValue} from "../../../../_helpers/helperFunctions";
import AccountingBox from "../../../primer/AccountingBox";
import {toast} from "react-toastify";

const NewClientEmron = () => {
  const [t] = useTranslation('common');
  const dispatch = useDispatch();
  const CLIENTS_DATA = useSelector( (state) => state.CLIENTS_DATA );
  const ACTIVITY = useSelector( (state) => state.ACTIVITY );
  let clientInstallationListData = CLIENTS_DATA.newClient?.installations?.slice();
  if(clientInstallationListData?.length > 0) {
    clientInstallationListData = clientInstallationListData.map(installation => {
      const modifiedInstallation = { ...installation }; // Create a new object with the same properties
      modifiedInstallation.type = getLabelByValue(getInstallationsTypes(), installation.type, 'value');
      modifiedInstallation.address = modifiedInstallation?.address + ", " + modifiedInstallation?.city + ", " + modifiedInstallation?.postalCode;
      return modifiedInstallation;
    });
  }
  const passwordsColumns = [
    {
      name: "No",
      field: "no",
      editable: false,
    },
    {
      name: t('ClientsNewEmron.tablePassword.type'),
      field: "type",
      editable: true,
      inputType: {
        type: 'text',
      }
    },
    {
      name: t('ClientsNewEmron.tablePassword.username'),
      field: "username",
      editable:true,
      inputType: {
        type: 'text',
      }
    },
    {
      name: t('ClientsNewEmron.tablePassword.password'),
      field: "password",
      editable: true,
      inputType: {
        type: 'text',
      }
    },
    {
      name: t('ClientsNewEmron.tablePassword.notes'),
      field: "notes",
      editable: true,
      inputType: {
        type: 'text',
      }
    },
  ]

  const stackholdersColumns = [
    {
      name: "No",
      field: "no",
      editable: false,
    },
    {
      name: t('ClientsNewEmron.stockholder.name'),
      field: "name",
      editable: true,
      inputType: {
        type: 'text',
      }
    },
    {
      name: t('ClientsNewEmron.stockholder.surname'),
      field: "surname",
      editable:true,
      inputType: {
        type: 'text',
      }
    },
    {
      name: t('ClientsNewEmron.stockholder.vat'),
      field: "vat",
      editable: true,
      inputType: {
        type: 'text',
      }
    },
    {
      name: t('ClientsNewEmron.stockholder.position'),
      field: "position",
      width: '120px',
      editable: true,
      inputType: {
        options: getStockholderPositions(),
        type: 'dropdown',
      }
    },
    {
      name: t('ClientsNewEmron.stockholder.percentage'),
      field: "percentage",
      editable: true,
      inputType: {
        type: 'accounting',
      }
    },
    {
      name: t('ClientsNewEmron.stockholder.stockValue'),
      field: "stockValue",
      editable: true,
      disabled: true,
      inputType: {
        type: 'accounting',
        config: {
          focused: true,
          suffix: "€",
          maxLength: 7 // Given in the wireframe
        }
      }
    },
    {
      name: t('ClientsNewEmron.stockholder.dateFrom'),
      field: "dateFrom",
      editable: true,
      inputType: {
        type: 'datebox',
        allowOtherYears: "true"
      }
    },
    {
      name: t('ClientsNewEmron.stockholder.dateTo'),
      field: "dateTo",
      editable: true,
      inputType: {
        type: 'datebox',
        allowOtherYears: "true"
      }
    },
  ];
  const clientInstallationsDropdownColumns = [
    {
      label: t('SalesNew.installationTable.type'),
      name: "type",
    },
    {
      label: t('SalesNew.installationTable.address'),
      name: "address",
    }
  ];
  const emronPaymentsColumns = [
    {
      name: "No",
      field: "no",
      width: "40px",
      editable: false,
    },
    {
      name: "Εγκατάσταση",
      width: "150px",
      field: "installation",
      editable: true,
      inputType: {
        config: {
          excludeColumns: [],
          columns: clientInstallationsDropdownColumns,
          data: clientInstallationListData,
          editEnable: false,
          deleteEnable: false,
          searchTableEnable: false,
          showTableOptionOnSelect: true,
          placeholder: t('SalesNew.productTable.code.placeholder')
        },
        type: 'advanced-combobox',
      }
    },
    {
      name: "Μήνας Από",
      field: "month",
      width: "90px",
      editable:true,
      inputType: {
        type: 'monthDatebox',
      }
    },
    {
      name: "Μήνας Μέχρι",
      field: "monthTo",
      width: "90px",
      editable:true,
      inputType: {
        type: 'monthDatebox',
      }
    },
    {
      name: "Καθαρή Αξία",
      field: "amount",
      width: "100px",
      editable: true,
      inputType: {
        type: 'accounting',
        config: {
          focused: true,
          suffix: "€",
          maxLength: 7 // Given in the wireframe
        }
      }
    },
    {
      name: "Σημειώσεις",
      field: "notes",
      width: '250px',
      editable: true,
      inputType: {
        type: 'textarea'
      }
    },
    {
      name: "Δυσκολία",
      field: "difficulty",
      width: "80px",
      editable: true,
      inputType: {
        options: getDifficultyOptions(),
        type: 'dropdown',
      }
    },
    {
      name: "Σημειώσεις Δυσκολίας",
      field: "difficultyNotes",
      width: "120px",
      editable: true,
      inputType: {
        type: 'text',
      }
    },
  ];

  const handleOnChange = (e) => {
    // Set if editing the form
    if (!ACTIVITY.saveNewClient) {
      dispatch(setSaveNewClient(true));
    }

    let value = e.target.value;
    dispatch(setNewClient({...CLIENTS_DATA.newClient, capital: value}));
  }


  const handlePasswordsChanges = {
    insert: (e, data) => {
      // Set if editing the form
      if (!ACTIVITY.saveNewClient) {
        dispatch(setSaveNewClient(true));
      }
      let tempEmronPassword = Object.assign({}, CLIENTS_DATA.newClientEmronPassword);
      tempEmronPassword[data.field] = data.value;
      if (tempEmronPassword && (tempEmronPassword.type || tempEmronPassword.username || tempEmronPassword.password || tempEmronPassword.notes)) {
        let updatedEmronPasswords = Object.assign({}, tempEmronPassword);
        let oldEmronPasswords = CLIENTS_DATA.newClient.emronPasswords;
        updatedEmronPasswords.no = oldEmronPasswords.length + 1;
        updatedEmronPasswords.id = oldEmronPasswords.length + 1;
        dispatch(setNewClient({...CLIENTS_DATA.newClient, emronPasswords: [...oldEmronPasswords, updatedEmronPasswords]}));
      }
    },
    update: async (data, id) => {
      // Set if editing the form
      if (!ACTIVITY.saveNewClient) {
        dispatch(setSaveNewClient(true));
      }
      let emronPasswords = CLIENTS_DATA.newClient.emronPasswords.slice();
      await Promise.all(emronPasswords.map((password, index) => {
        if (password.id === id) {
          emronPasswords[index] = {...password, ...data};
        }
        return password;
      }))
      dispatch(setNewClient({...CLIENTS_DATA.newClient, emronPasswords: emronPasswords}));
    },
    selected: () => {
      console.log('selected mydata row clicked');
    },
    delete: (id) => {
      // Set if editing the form
      if (!ACTIVITY.saveNewClient) {
        dispatch(setSaveNewClient(true));
      }
      let emronPasswords = CLIENTS_DATA.newClient.emronPasswords.slice();
      if (id) {
        emronPasswords = deleteObjectFromArrayOfObjects(emronPasswords, id, 'id');
        emronPasswords = resetTableItemsId(emronPasswords);
        dispatch(setNewClient({...CLIENTS_DATA.newClient, emronPasswords: emronPasswords}));
      }
    }
  }
  const handleStockholdersChanges = {
    insert: (e, data) => {
      // Set if editing the form
      if (!ACTIVITY.saveNewClient) {
        dispatch(setSaveNewClient(true));
      }
      if(data.field === "percentage" && Number(data.value) > 100){
        toast.error("Percentage must be below 100");
      } else {

        let tempEmronStockholder = Object.assign({}, CLIENTS_DATA.newClientEmronStockholder);
        tempEmronStockholder[data.field] = data.value;
        if (tempEmronStockholder && (
            tempEmronStockholder.name !== '' ||
            tempEmronStockholder.surname !== '' ||
            tempEmronStockholder.vat !== '' ||
            tempEmronStockholder.position !== '' ||
            tempEmronStockholder.percentage !== '' || tempEmronStockholder.stockValue !== '' || tempEmronStockholder.dateFrom !== '' || tempEmronStockholder.dateTo !== '')) {
          let updatedEmronStockholders = Object.assign({}, tempEmronStockholder);
          let oldEmronStockholders = CLIENTS_DATA.newClient.stockholders;
          updatedEmronStockholders.no = oldEmronStockholders?.length + 1;
          updatedEmronStockholders.id = oldEmronStockholders?.length + 1;
          if(data.field === "percentage"){
            updatedEmronStockholders.stockValue = CLIENTS_DATA.newClient.capital * Number(data?.value) / 100;
          }
          dispatch(setNewClient({...CLIENTS_DATA.newClient, stockholders: [...oldEmronStockholders, updatedEmronStockholders]}));
        }
      }
    },
    update: (data, id) => {
      // Set if editing the form
      if (!ACTIVITY.saveNewClient) {
        dispatch(setSaveNewClient(true));
      }
      if(data?.percentage && Number(data.percentage) > 100){
        toast.error("Percentage must be below 100");
      } else {
        let emronStockholders = CLIENTS_DATA.newClient.stockholders.slice();
        if (emronStockholders.find(item => item.id === id) !== undefined) {
          emronStockholders[id - 1] = {...emronStockholders[id - 1], ...data};
          if(data?.percentage){
            emronStockholders[id - 1].stockValue = CLIENTS_DATA.newClient.capital * Number(data?.percentage) / 100;
          }
          dispatch(setClientEmronStockholders(emronStockholders)); // update data
          dispatch(setNewClient({...CLIENTS_DATA.newClient, stockholders: emronStockholders}))
        }
      }
    },
    selected: () => {
      console.log('selected mydata row clicked');
    },
    delete: (id) => {
      // Set if editing the form
      if (!ACTIVITY.saveNewClient) {
        dispatch(setSaveNewClient(true));
      }
      let emronStockholders = CLIENTS_DATA.newClient.stockholders.slice();
      if (id) {
        emronStockholders = deleteObjectFromArrayOfObjects(emronStockholders, id, 'id');
        emronStockholders = resetTableItemsId(emronStockholders);
        dispatch(setNewClient({...CLIENTS_DATA.newClient, stockholders: emronStockholders}));
      }
    }
  }

  const handleEmronPaymentsChanges = {
    insert: (e, data) => {
      // Set if editing the form
      if (!ACTIVITY.saveNewClient) {
        dispatch(setSaveNewClient(true));
      }
      let tempEmronPayment = Object.assign({},  CLIENTS_DATA.newEmronPayment);
      if(data.field === "installation"){
        tempEmronPayment["installation"] = e.row.address;
        tempEmronPayment["installationType"] = e.row.type;
      } else {
        tempEmronPayment[data.field] = data.value;
      }
      if (tempEmronPayment && (tempEmronPayment.installationType || tempEmronPayment.amount || tempEmronPayment.month || tempEmronPayment.notes)) {
        let updatedEmronPayment = Object.assign({},  tempEmronPayment);
        let oldEmronPayments = CLIENTS_DATA.newClient.emronPayments;
        updatedEmronPayment.no = oldEmronPayments.length + 1;
        updatedEmronPayment.id = oldEmronPayments.length + 1;
        updatedEmronPayment.clientName = CLIENTS_DATA.newClient?.name;
        dispatch(setNewClient({...CLIENTS_DATA.newClient, emronPayments: [...oldEmronPayments, updatedEmronPayment]}));
      }
    },
    update: async (data, id, e) => {
      // Set if editing the form
      if (!ACTIVITY.saveNewClient) {
        dispatch(setSaveNewClient(true));
      }
      let emronPayments = CLIENTS_DATA.newClient.emronPayments.slice();
      await Promise.all(emronPayments.map((payment, index) => {
        if(payment.id === id) {
          if(data?.installation){
            data["installation"] = e.row.address;
            data["installationType"] = e.row.type;
          }
          emronPayments[index] = {...payment, ...data};
        }
        return payment;
      }))
      dispatch(setNewClient({...CLIENTS_DATA.newClient, emronPayments: emronPayments}));
    },
    selected: () => {
      console.log('selected mydata row clicked');
    },
    delete: (id) => {
      // Set if editing the form
      if (!ACTIVITY.saveNewClient) {
        dispatch(setSaveNewClient(true));
      }
      let emronPayments = CLIENTS_DATA.newClient.emronPayments.slice();
      if (id) {
        emronPayments = deleteObjectFromArrayOfObjects(emronPayments, id, 'id');
        emronPayments = resetTableItemsId(emronPayments);
        dispatch(setNewClient({...CLIENTS_DATA.newClient, emronPayments: emronPayments}));
      }
    }
  }

  const [incrKey, setIncrKey] = useState(0);
  useEffect(() => {
    setIncrKey(incrKey + 1);
  }, [CLIENTS_DATA.newClient.emronPasswords, CLIENTS_DATA.newClient.stockholders, CLIENTS_DATA.newClient.emronPayments])

  return (
    <div className="new-client-emron">
        <p className="text-muted"><i>{t('ClientsNewEmron.passwords')}</i></p>
        <EditableTable
          tableName="Emron Passwords"
          key={"Emron Passwords" + incrKey}
          allowActions={true}
          allowToggleColumns = { true }
          columns={passwordsColumns}
          data={ CLIENTS_DATA.newClient.emronPasswords }
          allowInsertRow={true}
          enableNewRowCheckbox = {true}
          onUpdate={handlePasswordsChanges}
        />
        <br />

        <p className="text-muted"><i>{t('ClientsNewEmron.stockholders')}</i></p>
        <Col sm='12' md="3">
          <div className="mb-3">
            <label className="" htmlFor="capital">{t('ClientsNewEmronEdit.capital')}</label>
            <AccountingBox
                name="capital"
                className="mb-3 form-control"
                placeholder={t('ProductsNewGeneralEdit.preferredPrice.placeholder')}
                defaultValue={CLIENTS_DATA.newClient?.capital}
                onChange={(e) => handleOnChange(e)}
            />
          </div>
        </Col>
        <EditableTable
          tableName="Emron Stockholders"
          key={"Emron Stockholders" + incrKey}
          allowActions={true}
          allowToggleColumns = { true }
          columns={stackholdersColumns}
          data={ CLIENTS_DATA.newClient.stockholders }
          allowInsertRow={true}
          enableNewRowCheckbox = {true}
          onUpdate={handleStockholdersChanges}
        />
        <p className="text-muted"><i>Μηνιαίες Χρεώσεις</i></p>
        <EditableTable
            tableName="Emron Payments"
            key={"Emron Payments" + incrKey}
            allowActions={true}
            allowToggleColumns = {true}
            columns={emronPaymentsColumns}
            data={ CLIENTS_DATA.newClient.emronPayments }
            allowInsertRow={true}
            enableNewRowCheckbox = {true}
            onUpdate={handleEmronPaymentsChanges}
        />
    </div>
  )
}

export default NewClientEmron;
