export const buysPaymentTypeData = [
    {
        value: 'bank-account',
        label: 'Επαγ. Λογαριασμός Πληρωμών Ημεδαπής',
        myDataId: '1'
    },
    {
        value: 'bank-account-outside',
        label: 'Επαγ. Λογαριασμός Πληρωμών Αλλοδαπής',
        myDataId: '2'
    },
    {
        value: 'cash',
        label: 'Μετρητά',
        myDataId: '3'
    },
    {
        value: 'cheque',
        label: 'Επιταγή',
        myDataId: '4'
    },
    {
        value: 'credit',
        label: 'Επί Πιστώσει',
        myDataId: '5'
    },
    {
        value: 'web-banking',
        label: 'Web Banking',
        myDataId: '6'
    },
    {
        value: 'pos',
        label: 'POS',
        myDataId: '7'
    },
    {
        value: 'iris',
        label: 'Άμεσες Πληρωμές IRIS',
        myDataId: '8'
    },
]
