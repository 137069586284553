import React, {useEffect, useState} from "react";
import SearchTable from "../../../primer/Search-Table";
import {useDispatch, useSelector} from "react-redux";
import {
    getOptionsByData,
    paymentStatusOptions,
    providerChannelOptions,
    salesMyDataStatus
} from '../../../../_helpers/commonFunctions';
import {useTranslation} from 'react-i18next';
import {getObjectFromArrayOfObjects} from "../../../../_helpers/helperFunctions";
import {fetchAdvancedTypesSales} from "../../../../_apis/api";
import {classicStyleBelowNavbar} from "../Statics";

function SalesSearch() {
    const [t] = useTranslation('common');
    const SALES_SETTINGS = useSelector((state) => state.SALES_SETTINGS);
    const SETTINGS = useSelector((state) => state.SETTINGS);
    const documentTypesOptions = getOptionsByData(SETTINGS.settings.advancedTypesSales, "_id", "name");
    const paymentTypeOptions = getOptionsByData(SALES_SETTINGS.salesGeneralSettings.paymentTypes, "_id", "name");
    const salesMyDataStatusOptions = salesMyDataStatus();
    const providerChannelOptionsDropdown = providerChannelOptions();
    const paymentStatusOptionsDropdown = paymentStatusOptions();
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchAdvancedTypesSales({company: company.id}))
    }, [dispatch]);

    const ssColumns = [
        {
            name: t('SalesSearch.table.date'),
            field: "date",
            width: "100px",
            editable: false,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t('SalesSearch.table.client'),
            field: "clientName",
            editable: false,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t('SalesSearch.table.documentType'),
            field: "documentTypeName",
            width: "300px",
            editable: false,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t('SalesSearch.table.number'),
            field: "number",
            width: "100px",
            editable: false,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t('SalesSearch.table.totalNetValue'),
            field: "totalNetValue",
            width: "100px",
            editable: true,
            disabled: true,
            inputType: {
                type: 'accounting',
                config: {
                    suffix: "€",
                }
            }
        },
        {
            name: t('SalesSearch.table.totalVatValue'),
            field: "totalVatValue",
            width: "100px",
            editable: true,
            disabled: true,
            inputType: {
                type: 'accounting',
                config: {
                    suffix: "€",
                }
            }
        },
        {
            name: t('SalesSearch.table.totalSum'),
            field: "totalAmount",
            width: "100px",
            editable: true,
            disabled: true,
            inputType: {
                type: 'accounting',
                config: {
                    suffix: "€",
                }
            }
        },
        {
            name: t('SalesSearch.table.myDataStatus'),
            field: "myDataStatus",
            width: "100px",
            editable: false,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t('SalesSearch.table.providerChannel'),
            field: "providerChannel",
            width: "110px",
            hiddenColumn: true,
            editable: false,
            inputType: {
                type: 'text',
            }
        },
        {
            name: "Σημειώσεις",
            field: "notes",
            width: "100px",
            hiddenColumn: true,
            editable: false,
            inputType: {
                type: 'text',
            },
        },
        {
            name: "Κατάσταση Πληρωμής",
            field: "paymentStatus",
            width: "100px",
            hiddenColumn: true,
            editable: false,
            inputType: {
                type: 'text',
            },
        },
        {
            name: "Υπόλοιπο Πληρωμής",
            field: "paymentRemainder",
            width: "100px",
            hiddenColumn: true,
            disabled: true,
            editable: true,
            inputType: {
                type: 'accounting',
                config: {
                    suffix: "€",
                }
            },
        }
    ]

    const searchSaleFilters = {
        filters: [
            {
                name: "clientName",
                label: t('SalesSearch.table2.clientNameFrom'),
                type: "input",
            },
            {
                name: "productName",
                label: t('SalesSearch.table2.productName'),
                type: "input",
            },
            {
                name: "totalSum_from",
                label: t('SalesSearch.table2.totalSumFrom'),
                type: "input",
            },
            {
                name: "totalSum_to",
                label: t('SalesSearch.table2.totalSumTo'),
                type: "input",
            },
            {
                name: "preNumber_from",
                label: t('SalesSearch.table2.preNumberFrom'),
                type: "input",
            },
            {
                name: "preNumber_to",
                label: t('SalesSearch.table2.preNumberTo'),
                type: "input",
            },
            {
                name: "number_from",
                label: t('SalesSearch.table2.numberFrom'),
                type: "input",
            },
            {
                name: "number_to",
                label: t('SalesSearch.table2.numberTo'),
                type: "input",
            },
            {
                name: "documentType",
                label: t('SalesSearch.table2.documentType'),
                type: "multiselect",
                options: documentTypesOptions
            },
            {
                name: "paymentWay",
                label: t('SalesSearch.table2.paymentWays'),
                type: "multiselect",
                options: paymentTypeOptions
            },
            {
                name: "myDataStatus",
                label: t('SalesSearch.table2.myDataStatus'),
                type: "multiselect",
                options: salesMyDataStatusOptions
            },
            {
                name: "providerChannel",
                label: t('SalesSearch.table2.providerChannel'),
                type: "multiselect",
                options: providerChannelOptionsDropdown
            },
            {
                name: "paymentStatus",
                label: t('SalesSearch.table2.paymentStatus'),
                type: "dropdown",
                options: paymentStatusOptionsDropdown
            },
            {
                name: "notes",
                label: t('SalesSearch.table2.notes'),
                type: "input",
            },
        ]
    };
    const [tableColumns, setTableColumns] = useState(ssColumns.slice());

    // It will hide or show the table columns for searchtable.
    const handleColumnShowHide = (field) => {
        let tempTc = tableColumns.slice();
        let object = getObjectFromArrayOfObjects(tempTc, field, 'field')
        const index = tempTc.findIndex((item) => field === item.field);
        if (object.length > 0) {
            tempTc[index].hiddenColumn = !object['0'].hiddenColumn;
            setTableColumns(tempTc);
        }
    }

    return (
        <div style={classicStyleBelowNavbar}>
            <SearchTable
                tableName="Sales Search"
                key={"Sales Search"}
                hideYearFilter={false}
                allowPagination={true}
                allowToggleColumns={true}
                sendSelectedToMyData={true}
                toggleColumns={handleColumnShowHide}
                allowPrintResults={true}
                columns={tableColumns}
                data={[]}
                filtersConfig={searchSaleFilters}
            />
        </div>
    )
}

export default SalesSearch;
