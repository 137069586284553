import React from "react";
import AccountingBox from "../AccountingBox";
import {Badge} from "react-bootstrap";
import "./style.scss";

const TipComponent = ({value, onChange, paymentAmount}) => {
    const handleOnChange = (e) => {
        onChange(Number(Number(e.target.value).toFixed(2)));
    }
    const handleButtonClick = (p) => {
        if (paymentAmount) {
            onChange(Number((paymentAmount*(p/100)).toFixed(2)));
        } else{
            onChange(0);
        }
    }

    return (
        <React.Fragment>
            <AccountingBox
                name={"tipAmount"}
                fromAccountTable={true}
                key={value}
                defaultValue={value}
                onBlur={(e) => handleOnChange(e)}
            />
            <div className={"mt-1 tipButtons"}>
                <Badge className={"mr-1 tipButton"} bg={"primary"} onClick={() => handleButtonClick(5)}>5%<br/>{Number((paymentAmount*(5/100)).toFixed(2))} €</Badge>
                <Badge className={"mr-1 tipButton"} bg={"primary"} onClick={() => handleButtonClick(10)}>10%<br/>{Number((paymentAmount*(10/100)).toFixed(2))} €</Badge>
                <Badge className={"tipButton"} bg={"primary"} onClick={() => handleButtonClick(20)}>20%<br/>{Number((paymentAmount*(20/100)).toFixed(2))} €</Badge>
            </div>
        </React.Fragment>
    )
}

export default TipComponent