import React, {useEffect, useState} from "react";
import {Button, Modal} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {
    getECCategoryOptions,
    getECTypeOptions,
    getICCategoryOptions,
    getICTypeOptions, getVatExpenseClassificationType
} from "../../../_helpers/sharedmydata";
import axios from "axios";
import Dropdown from "../Dropdown";
import {toast} from "react-toastify";
import {setEditAccountingRecord, setNewAccountingRecord} from "../../../_reducers/DataPanel/Accounting/accountingSlice";
import {setSaveEditAccountingRecord, setSaveNewAccountingRecord} from "../../../_reducers/activitySlice";

const AccountingRecordQuickEdit = (props) => {
    //props.data for table row data
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const TABS_DATA = useSelector((state) => state.TABS_REDUCER);
    const ACTIVE_MAIN_TAB = useSelector((state) => state.ACTIVE_MAIN_TAB);
    const ACCOUNTING_DATA = useSelector( (state) => state.ACCOUNTING_DATA);
    const ACTIVITY = useSelector( (state) => state.ACTIVITY);

    const [accountData, setAccountData] = useState(props.data);
    const [myData, setMyData] = useState([]);
    const vatExpenseType = [{label: "Κενό", value: ""}].concat(getVatExpenseClassificationType());

    const incomeTypes = ["1.1", "1.2", "1.3", "1.4", "1.5", "1.6", "2.1", "2.2", "2.3", "2.4", "5.1", "5.2", "6.1", "6.2", "7.1", "8.1", "8.2", "11.1", "11.2", "11.3", "11.4", "11.5"];
    const expenseTypes = ["3.1", "3.2", "13.1", "13.2", "13.3", "13.4", "13.30", "13.31", "14.1", "14.2", "14.3", "14.4", "14.5", "14.31", "15.1", "16.1", "17.1", "17.2"];

    const isInView = TABS_DATA[ACTIVE_MAIN_TAB] === "accounting-record-edit" && ACCOUNTING_DATA.editAccountingRecord.myDataType && ACCOUNTING_DATA.editAccountingRecord.myDataType !== "" && ACCOUNTING_DATA.editAccountingRecord.myDataStatus === "sent";

    useEffect(() => {
        if (show === true) {
            axios.get(process.env.REACT_APP_API_URL2 + "/accounting/get-account", {
                headers: { "Content-Type": "application/json" },
                params: {
                    company: company.id,
                    movable: "active",
                    code: accountData.code,
                }
            })
            .then((res) => {
                if(res.data.status === "200") {
                    setMyData(res.data.data.mydata.filter((el) => String(el.invoiceType) === String(getDocData().myDataType)));
                }
            })
            .catch((err) => console.log(err));
        }
    }, [show])

    const getDocData = () => {
        if (TABS_DATA[ACTIVE_MAIN_TAB] === "accounting-record-new") {
            return ACCOUNTING_DATA.newAccountingRecord;
        } else if (TABS_DATA[ACTIVE_MAIN_TAB] === "accounting-record-edit") {
            return ACCOUNTING_DATA.editAccountingRecord;
        }
    }
    const getCategories = () => {
        if(incomeTypes.includes(getDocData().myDataType)) {
            return getICCategoryOptions().filter(el => myData.findIndex(md => md.category === el.value) !== -1);
        } else if (expenseTypes.includes(getDocData().myDataType)) {
            return getECCategoryOptions().filter(el => myData.findIndex(md => md.category === el.value) !== -1);
        } else {
            return [];
        }
    }
    const getTypes = () => {
        if(incomeTypes.includes(getDocData().myDataType)) {
            return getICTypeOptions().filter(el => myData.findIndex(md => md.type === el.value && md.category === accountData.classificationCategory) !== -1);
        } else if (expenseTypes.includes(getDocData().myDataType)) {
            return getECTypeOptions().filter(el => myData.findIndex(md => md.type === el.value && md.category === accountData.classificationCategory) !== -1);
        } else {
            return [];
        }
    }
    const handleOnChange = (e, type, name) => {
        if(type === "dropdown") {
            if(name === "classificationCategory") {
                setAccountData({...accountData, classificationCategory : e.value, classificationType: ""});
            } else if (name === "classificationType") {
                setAccountData({...accountData, classificationType : e.value});
            } else if (name === "vatExpenseType") {
                setAccountData({...accountData, vatExpenseType : e.value});
            }
        }
    }
    const saveClicked = () => {
        if (TABS_DATA[ACTIVE_MAIN_TAB] === "accounting-record-new") {
            let allAccounts = [...ACCOUNTING_DATA.newAccountingRecord.accounts];
            allAccounts = allAccounts.map((a) => Object.assign({}, a));
            let idx = allAccounts.findIndex((el) => el.code === accountData.code);
            if(idx === -1) {
                toast.error("Σφάλμα κατά την αποθήκευση, ο λογαριασμός δεν υπάρχει πια.");
            } else {
                if (!ACTIVITY.saveNewAccountingRecord) dispatch(setSaveNewAccountingRecord(true));
                allAccounts[idx] = {...allAccounts[idx], ...accountData}
                allAccounts[idx].quickEdit = true;
                setShow(false);
                toast.success("Ο λογαριασμός ενημερώθηκε.");
                setTimeout(() => {
                    dispatch(setNewAccountingRecord({...ACCOUNTING_DATA.newAccountingRecord, accounts: allAccounts}))
                }, 150)
            }
        } else if (TABS_DATA[ACTIVE_MAIN_TAB] === "accounting-record-edit") {
            let allAccounts = [...ACCOUNTING_DATA.editAccountingRecord.accounts];
            allAccounts = allAccounts.map((a) => Object.assign({}, a));
            let idx = allAccounts.findIndex((el) => el.code === accountData.code);
            if(idx === -1) {
                toast.error("Σφάλμα κατά την αποθήκευση, ο λογαριασμός δεν υπάρχει πια.");
            } else {
                if (!ACTIVITY.saveEditAccountingRecord) dispatch(setSaveEditAccountingRecord(true));
                allAccounts[idx] = {...allAccounts[idx], ...accountData}
                allAccounts[idx].quickEdit = true;
                setShow(false);
                toast.success("Ο λογαριασμός ενημερώθηκε.");
                setTimeout(() => {
                    dispatch(setEditAccountingRecord({...ACCOUNTING_DATA.editAccountingRecord, accounts: allAccounts}))
                }, 150)
            }
        }
    }
    const isEmpty = (o) => {
        return o === null || o === undefined || String(o).trim() === "";
    }
    const invalidAccount = () => {
        return isEmpty(accountData.classificationCategory) || isEmpty(accountData.classificationType);
    }
    return(
        <React.Fragment>
            <Button style={{pointerEvents: "auto", padding: "2px", margin: "8px"}} size={"sm"} variant={"default"} title={props.title ? props.title : "Quick Edit"} onClick={() => {setShow(true)}}>
                <i className="far fa-edit"></i>
            </Button>

            <Modal show={show} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Επεξεργασία Λογαριασμού</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Dropdown
                        name={"classificationCategory"}
                        label={"Κατηγορία My Data"}
                        options={getCategories()}
                        required={true}
                        disabled={isInView}
                        key={Math.random()}
                        defaultValue={getCategories().filter((el) => el.value === accountData.classificationCategory).length > 0 ? getCategories().filter((el) => el.value === accountData.classificationCategory)[0] : ""}
                        onChange={(e) => handleOnChange(e, "dropdown", "classificationCategory")}
                    />
                    <Dropdown
                        name={"classificationType"}
                        label={"Τύπος My Data"}
                        options={getTypes()}
                        required={true}
                        disabled={isInView}
                        key={Math.random()}
                        defaultValue={getTypes().filter((el) => el.value === accountData.classificationType).length > 0 ? getTypes().filter((el) => el.value === accountData.classificationType)[0] : ""}
                        onChange={(e) => handleOnChange(e, "dropdown", "classificationType")}
                    />
                    {expenseTypes.includes(getDocData().myDataType) && (
                        <Dropdown
                            name={"vatExpenseType"}
                            label={"Τύπος Εξόδων για Φ.Π.Α."}
                            options={vatExpenseType}
                            required={true}
                            disabled={isInView}
                            key={Math.random()}
                            defaultValue={vatExpenseType.filter((el) => el.value === accountData.vatExpenseType).length > 0 ? vatExpenseType.filter((el) => el.value === accountData.vatExpenseType)[0] : ""}
                            onChange={(e) => handleOnChange(e, "dropdown", "vatExpenseType")}
                        />
                    )}
                    {isEmpty(accountData.classificationCategory) && <div style={{"color" : "red"}}>* Πρέπει να συμπληρώσετε κατηγορία MyData</div>}
                    {isEmpty(accountData.classificationType) && <div style={{"color" : "red"}}>* Πρέπει να συμπληρώσετε τύπο MyData</div>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={() => setShow(false)}>Κλείσιμο</Button>
                    {!isInView && <Button variant="primary" onClick={() => saveClicked()} disabled={invalidAccount()}>Αποθήκευση</Button>}
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
}

export default AccountingRecordQuickEdit