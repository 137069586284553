import React, {useEffect} from "react";
import {Button, Col, Row} from "react-bootstrap";
import TextArea from "../../../common/TextArea";
import Input from "../../../common/Input";
import DateBox from "../../../primer/DateBox";
import {useDispatch, useSelector} from "react-redux";
import {setSaveNewPOS} from "../../../../_reducers/activitySlice";
import Checkbox from "../../../common/CheckBox";
import {setNewPOS} from "../../../../_reducers/DataPanel/Cash/cashSlice";
import {useTranslation} from "react-i18next";
import BetterCheckBox from "../../../common/BetterCheckBox";
import {checkPermission, isEmpty} from "../../../../_helpers/commonFunctions";
import {fetchCashSettings} from "../../../../_apis/api";

const PosNew = () => {
    const [t] = useTranslation('common');
    const dispatch = useDispatch();
    const ACTIVITY = useSelector((state) => state.ACTIVITY);
    const CASH_DATA = useSelector((state) => state.CASH_DATA);
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const permissionsData = JSON.parse(localStorage.getItem("user"))?.permissions;

    useEffect(() => {
        dispatch(fetchCashSettings(company.id));
    }, [])

    useEffect(() => {
        if (!isEmpty(CASH_DATA.cashSettings?.accounting?.accountingPOS) && isEmpty(CASH_DATA.newPOS.name)) {
            dispatch(setNewPOS({...CASH_DATA.newPOS, accounting: CASH_DATA.cashSettings.accounting.accountingPOS}))
        }
    }, [CASH_DATA.cashSettings.accounting])

    const handleOnChange = (e, type = "default", ddname = '') => {
        if (!ACTIVITY.saveNewPOS) dispatch(setSaveNewPOS(true));

        let name;
        let value;
        if (type === 'dp') {
            value = e;
            name = ddname;
        } else {
            value = e.target.value;
            name = e.target.name;
            if (name === "isETameiaki") {
                value = e.target.checked === true ? "active" : "inactive";
            }
        }
        let posData = Object.assign({}, CASH_DATA);
        let newPOSData = {};
        newPOSData[name] = value;
        dispatch(setNewPOS({...posData.newPOS, ...newPOSData}));
    }

    return (
        <React.Fragment>
            <Row className="position-relative border-bottom mb-3">
                <Col xs="12" md="12" className="d-flex align-items-center justify-content-end ">
                    <div className="inline-field d-flex align-items-center acremd  mb-3">
                        <label htmlFor="product-active" className="mb-0 mr-2 ">{t('PosNew.active')}</label>
                        <Checkbox
                            className="mb-2"
                            name="isActive"
                            key={Math.random()}
                            id="product-active"
                            defaultValue={CASH_DATA.newPOS.isActive}
                            value={CASH_DATA.newPOS.isActive === 'active' ? "inactive" : "active"}
                            onChange={(e) => handleOnChange(e)}
                        />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col sm="12" md="4">
                    <div className="mb-3">
                        <Input
                            className="w-100"
                            label={t('PosNew.name.name')}
                            name="name"
                            placeholder={t('PosNew.name.placeholder')}
                            value={CASH_DATA.newPOS.name}
                            required={true}
                            onChange={(e) => handleOnChange(e)}
                        />
                        {ACTIVITY.saveNewPOS && CASH_DATA.newPOS.name === '' &&
                            <div className="text-danger">{t('PosNew.name.required')}</div>}
                    </div>
                </Col>
                <Col sm="12" md="4" className="d-flex align-items-end justify-content-center">
                    <Checkbox
                        classes="w-100"
                        text={t('PosNew.isSentToAade')}
                        name="isSentToAade"
                        key={Math.random()}
                        defaultValue={CASH_DATA.newPOS.isSentToAade}
                        value={CASH_DATA.newPOS.isSentToAade === 'active' ? "inactive" : "active"}
                        onChange={(e) => handleOnChange(e)}
                    />
                </Col>
                <Col sm={12} md={4} className="text-right ">
                    <Button className="disabled" size="small">{t('PosNew.files')}</Button>
                </Col>
            </Row>
            <Row>
                <Col sm="12" md="3">
                    <BetterCheckBox
                        name={"isETameiaki"}
                        text={t("PosNew.isETameiaki")}
                        className="pt-2"
                        checked={CASH_DATA.newPOS.isETameiaki === "active" ? true : false}
                        onChange={(e) => handleOnChange(e, "cb")}
                    />
                </Col>
            </Row>
            <Row className="mb-3">
                {CASH_DATA.newPOS.isETameiaki === "active" && (
                    <React.Fragment>
                        <Col sm="12" md="3">
                            <Input
                                name="tameiakiUsername"
                                label={t("PosNew.tameiakiUsername.label")}
                                placeholder={t("PosNew.tameiakiUsername.placeholder")}
                                required={true}
                                value={CASH_DATA.newPOS.tameiakiUsername}
                                onChange={(e) => handleOnChange(e)}
                            />
                            {ACTIVITY.saveNewPOS && String(CASH_DATA.newPOS.tameiakiUsername).trim() === "" && (
                                <div className="text-danger">{t("PosNew.tameiakiUsername.required")}</div>)}
                        </Col>
                        <Col sm="12" md="3">
                            <Input
                                name="tameiakiPassword"
                                label={t("PosNew.tameiakiPassword.label")}
                                placeholder={t("PosNew.tameiakiPassword.placeholder")}
                                required={true}
                                value={CASH_DATA.newPOS.tameiakiPassword}
                                onChange={(e) => handleOnChange(e)}
                            />
                            {ACTIVITY.saveNewPOS && String(CASH_DATA.newPOS.tameiakiPassword).trim() === "" && (
                                <div className="text-danger">{t("PosNew.tameiakiPassword.required")}</div>)}
                        </Col>
                        <Col sm="12" md="3">
                            <Input
                                name="tid"
                                label={"TID"}
                                placeholder={"TID"}
                                required={true}
                                value={CASH_DATA.newPOS.tid}
                                onChange={(e) => handleOnChange(e)}
                            />
                            {ACTIVITY.saveNewPOS && String(CASH_DATA.newPOS.tid).trim() === "" && (
                                <div className="text-danger">{t("PosNew.tid.required")}</div>)}
                        </Col>
                    </React.Fragment>
                )}
            </Row>
            <Row>
                <Col sm="12" md="4">
                    <div className="mb-3">
                        <DateBox
                            classes="w-100"
                            label={t('PosNew.date.date')}
                            name="date"
                            required={true}
                            selected="today"
                            onChange={(e) => handleOnChange(e, 'dp', 'date')}
                        />
                        {ACTIVITY.saveNewPOS && CASH_DATA.newPOS.date === '' &&
                            <div className="text-danger">{t('PosNew.date.required')}</div>}
                    </div>
                </Col>
            </Row>
            <Row>
                <Col sm="12" md="8">
                    <div className="mb-3">
                        <Input
                            className="w-100"
                            label={t('PosNew.serialNumber.serialNumber')}
                            name="serialNumber"
                            placeholder={t('PosNew.serialNumber.placeholder')}
                            value={CASH_DATA.newPOS.serialNumber}
                            required={true}
                            onChange={(e) => handleOnChange(e)}
                        />
                        {ACTIVITY.saveNewPOS && CASH_DATA.newPOS.serialNumber === '' &&
                            <div className="text-danger">{t('PosNew.serialNumber.required')}</div>}
                    </div>
                </Col>
            </Row>
            <hr className="mb-4"/>
            <Row>
                <div style={{width: "100%"}}>
                    <div className="text-muted mb-2"><i>{t('PosNew.notes')}</i></div>
                    <TextArea
                        rows="4"
                        placeholder={t('PosNew.addNotes')}
                        name="notes"
                        limit="1000"
                        value={CASH_DATA.newPOS.notes}
                        onChange={(e) => handleOnChange(e)}
                    />
                </div>
            </Row>
            {checkPermission("accounting-settings/accounting-read", permissionsData) === true && (
                <React.Fragment>
                    <hr/>
                    <div className="text-muted mb-2"><i>Λογιστική</i></div>
                    <Row className={"mb-2"}>
                        <Col md={4}>
                            <Input
                                name={"accounting"}
                                label={"Πρόθεμα Κωδικού Λογαριασμού"}
                                value={CASH_DATA.newPOS?.accounting}
                                onChange={(e) => handleOnChange(e)}
                            />
                        </Col>
                        <Col md={4}>
                            <Input
                                name={"bypassAccounting"}
                                label={"Κωδικός Λογαριασμού Παράκαμψης Λογιστικής Γέφυρας"}
                                value={CASH_DATA.newPOS?.bypassAccounting}
                                onChange={(e) => handleOnChange(e)}
                            />
                        </Col>
                    </Row>
                </React.Fragment>
            )}
        </React.Fragment>
    )
}

export default PosNew;
