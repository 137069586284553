import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Badge, Button, Col, Modal, Row, Spinner, Table} from "react-bootstrap";
import axios from "axios";
import {toast} from "react-toastify";
import {setCDRViewerRequestData, setCDRViewerResponseData} from "../../../../_reducers/crmSlice";
import DateHelper from "../../../primer/DateHelper/DateHelper";
import DateBox from "../../../primer/DateBox";
import {getCurrentUser, isEmpty} from "../../../../_helpers/commonFunctions";
import moment from "moment/moment";
import Input from "../../../common/Input";
import Dropdown from "../../../primer/Dropdown";
import "./callcenter-style.scss";
import BetterCheckBox from "../../../common/BetterCheckBox";
import * as XLSX from "xlsx";
import {classicStyleBelowNavbar} from "../Statics";

export const CDRViewer = () => {
    const dispatch = useDispatch();
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const requestData = useSelector((state) => state.CRM.cdrViewerRequestData);
    const responseData = useSelector((state) => state.CRM.cdrViewerResponseData);
    const [loading, setLoading] = useState(false);
    const [pageChanged, setPageChanged] = useState(false);
    const [showTelephonesModal, setShowTelephonesModal] = useState(false);
    const [changedDebugMode, setChangedDebugMode] = useState(false);
    const [showNoAnswerModal, setShowNoAnswerModal] = useState(false);
    const [fetchingRecording, setFetchingRecording] = useState(false);

    const tableColumns = [
        {
            name: "Αρ.",
            field: "sequence",
            width: "3%",
        },
        {
            name: "Ημερομηνία & Ώρα",
            field: "calldate",
            width: "8%",
        },
        {
            name: "Από",
            field: "src",
            width: "10%",
        },
        {
            name: "Προς",
            field: "dst",
            width: "10%",
        },
        {
            name: "disposition",
            field: "disposition",
            width: "5%",
        },
        {
            name: "dcontext",
            field: "dcontext",
            width: "8%",
        },
        {
            name: "lastapp",
            field: "lastapp",
            width: "5%",
        },
        {
            name: "ourIdentification",
            field: "ourIdentification",
            width: "7%",
        },
        {
            name: "Χρόνος Ομιλίας",
            field: "billsec",
            width: "5%",
        },
        {
            name: "Αναμονή",
            field: "waiting",
            width: "5%",
        },
        {
            name: "Ολική Διάρκεια",
            field: "duration",
            width: "5%",
        },
        {
            name: "Linked ID",
            field: "linkedid",
            width: "8%",
        },
        {
            name: "Ενέργειες",
            field: "actions",
            width: "7%",
        },
    ]

    const tableColumnsSimpleView = [
        {
            name: "Αρ.",
            field: "sequence",
            width: "3%",
        },
        {
            name: "Ημερομηνία & Ώρα",
            field: "calldate",
            width: "8%",
        },
        {
            name: "Από",
            field: "src",
            width: "8%",
        },
        {
            name: "Προς",
            field: "dst",
            width: "8%",
        },
        {
            name: "Πελάτης",
            field: "clientName",
            width: "10%",
        },
        {
            name: "Ουρές προσπέλασης",
            field: "queueHistory",
            width: "10%",
        },
        {
            name: "DID",
            field: "did",
            width: "9%",
        },
        {
            name: "Χρόνος Ομιλίας",
            field: "billsec",
            width: "5%",
        },
        {
            name: "Αναμονή",
            field: "waiting",
            width: "5%",
        },
        {
            name: "Ολική Διάρκεια",
            field: "duration",
            width: "5%",
        },
        {
            name: "Ενέργειες",
            field: "actions",
            width: "5%",
        },
    ]

    const callTypeOptions = [
        {label: "Μόνο εξερχόμενες", value: "outgoing"},
        {label: "Μόνο εισερχόμενες", value: "incoming"},
        {label: "Μόνο εσωτερικές", value: "inner"}
    ]

    useEffect(() => {
        if (requestData.firstLoad === true) {
            dispatch(setCDRViewerRequestData({...requestData, firstLoad: false}));
            fetchCDRViewer(false);
        }
    }, [])

    useEffect(() => {
        if (pageChanged === true) {
            setPageChanged(false);
            fetchCDRViewer(false, true);
        }
    }, [pageChanged])

    useEffect(() => {
        if (changedDebugMode === true) {
            setChangedDebugMode(false);
            fetchCDRViewer(false, false);
        }
    }, [changedDebugMode])

    const handleFilterChange = (e, type, name) => {
        if (type === "datebox") {
            if (moment(e).isValid()) {
                dispatch(setCDRViewerRequestData({...requestData, [name] : moment(e).format("DD/MM/YYYY"), needsFetch: true}));
            } else {
                dispatch(setCDRViewerRequestData({...requestData, [name] : "", needsFetch: true}));
            }
        } else if (type === "input") {
            dispatch(setCDRViewerRequestData({...requestData, [name]: e.target.value }));
        } else if (type === "dropdown") {
            dispatch(setCDRViewerRequestData({...requestData, [name] : e.value}));
        } else if (type === "checkbox") {
            dispatch(setCDRViewerRequestData({...requestData, [name] : e.target.checked}));
            setChangedDebugMode(true);
        } else if (type === "msdd") {
            dispatch(setCDRViewerRequestData({...requestData, [name] : e}));
        }
    }

    const fetchCDRViewer = (forceRefresh, fromPagination = false) => {
        setLoading(true);
        let newReqData = {
            ...requestData,
            forceRefresh: forceRefresh,
            company: company.id,
            fromPagination: fromPagination,
            userId: getCurrentUser()._id,
        }
        if (!fromPagination) newReqData.page = 1;
        axios.post(process.env.REACT_APP_API_URL2 + "/callcenter/fetch-cdr-viewer", newReqData, {
            headers: {"Content-Type": "application/json"},
        }).then((res) => {
            setLoading(false);
            if (res.data.status === "200") {
                let prepObj = {
                    ...responseData,
                    data: res.data.data,
                    devices: res.data.devices,
                    dids: res.data.dids,
                    queues: res.data.queues,
                    //stats: res.data.stats,
                    ivrs: res.data.ivrs,
                    lastUpdate: res.data.lastUpdate,
                    totalPages: res.data.totalPages
                }
                if (!fromPagination) prepObj.stats = res.data.stats;
                dispatch(setCDRViewerResponseData(prepObj));
                if (forceRefresh) {
                    toast.success("Data fetched.");
                    dispatch(setCDRViewerRequestData({
                        ...requestData,
                        page: 1,
                        callType: [],
                        srcdst: "",
                        did: [],
                        queue: [],
                        client: "",
                        needsFetch: false,
                        date_from: res.data.filters.date_from,
                        date_to: res.data.filters.date_to
                    }));
                } else if (!fromPagination) {
                    dispatch(setCDRViewerRequestData({
                        ...requestData,
                        page: 1,
                        date_from: res.data.filters.date_from,
                        date_to: res.data.filters.date_to
                    }));
                }
            } else {
                toast.error(res.data.message);
            }
        }).catch((err) => {
            setLoading(false);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
            console.log(err);
        })
    }

    const fetchRecording = (row) => {
        if (fetchingRecording) {
            toast.info("Παρακαλώ περιμένετε να κατέβει η προηγούμενη ηχογράφηση.");
        } else {
            if (row.recordingfile) {
                setLoading(true);
                setFetchingRecording(true);
                const mom = moment(row.calldate);
                const recFilePath = mom.format("YYYY") + "/" + mom.format("MM") + "/" + mom.format("DD") + "/" + row.recordingfile.replace(".wav", "");
                const encodedFilePath = encodeURIComponent(recFilePath);
                axios.post(process.env.REACT_APP_API_URL2 + "/callcenter/fetch-recorded-call", {company: company.id, fileName: encodedFilePath, userId: getCurrentUser()._id}, {
                    headers: {"Content-Type": "application/json"},
                    responseType: "blob",
                }).then((res) => {
                    setFetchingRecording(false);
                    setLoading(false);
                    if (res.status === 200) {
                        toast.info("Η ηχογράφηση βρέθηκε και θα κατέβει σύντομα...");
                        const blob = new Blob([res.data], { type: 'audio/wav' });
                        const link = document.createElement('a');
                        link.href = window.URL.createObjectURL(blob);
                        link.download = recFilePath + '.wav';
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    } else {
                        toast.error("Σφάλμα κατά την εύρεση αρχείου.");
                    }
                }).catch((error) => {
                    setFetchingRecording(true);
                    setLoading(false);
                    console.log(error);
                    toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
                })
            } else {
                toast.error("Η κλήση αυτή δεν έχει καταγραφεί.");
            }
        }
    }

    const firstPage = () => {
        dispatch(setCDRViewerRequestData({...requestData, page: 1}));
        setPageChanged(true);
    }
    const previousPage = () => {
        dispatch(setCDRViewerRequestData({...requestData, page: requestData.page - 1}));
        setPageChanged(true);
    }
    const nextPage = () => {
        dispatch(setCDRViewerRequestData({...requestData, page: requestData.page + 1}));
        setPageChanged(true);
    }
    const lastPage = () => {
        dispatch(setCDRViewerRequestData({...requestData, page: responseData.totalPages}));
        setPageChanged(true);
    }
    const getDIDOptions = () => {
        let opt = [];
        if (responseData && responseData.dids?.length > 0) {
            for (let did of responseData.dids) {
                opt.push({value: did, label: did});
            }
        }
        return opt;
    }
    const getQueuesOptions = () => {
        let opt = [];
        if (responseData && responseData.queues?.length > 0) {
            for (let queue of responseData.queues) {
                opt.push({value: queue.extension, label: queue.descr});
            }
        }
        return opt;
    }
    const handleTelephoneClick = (device) => {
        setShowTelephonesModal(false);
        dispatch(setCDRViewerRequestData({...requestData, srcdst: isEmpty(requestData.srcdst) ? device.user : String(requestData.srcdst) + `,${device.user}`}));
    }
    const minutesDoubleToText = (minutes) => {
        let resp = `${minutes}`;
        let totalMinutes = Math.floor(Number(minutes));
        let secondsAsDouble = Number(minutes) - Number(totalMinutes);
        secondsAsDouble = secondsAsDouble * 60;
        secondsAsDouble = Math.round(secondsAsDouble);
        if (totalMinutes === 0) {
            if (secondsAsDouble === 1) {
                resp = "1 δευτερόλεπτο"
            } else {
                resp = `${secondsAsDouble} δευτερόλεπτα`
            }
        } else if (totalMinutes === 1) {
            if (secondsAsDouble === 1) {
                resp = "1 λεπτό και 1 δευτερόλεπτο"
            } else {
                resp = `1 λεπτό και ${secondsAsDouble} δευτερόλεπτα`
            }
        } else {
            if (secondsAsDouble === 1) {
                resp = `${totalMinutes} λεπτά και 1 δευτερόλεπτο`
            } else {
                resp = `${totalMinutes} λεπτά και ${secondsAsDouble} δευτερόλεπτα`
            }
        }
        return resp;
    }
    const secondsToMinutesSeconds = (seconds) => {
        const totalMinutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds - 60*totalMinutes;
        if(String(remainingSeconds).length === 1) {
            return <>{totalMinutes}{":"}{"0" + remainingSeconds}</>
        } else {
            return <>{totalMinutes}{":"}{remainingSeconds}</>
        }
    }
    const handleExcelExport = async () => {
        let rows = [];
        try {
            setLoading(true);
            const apiResponse = await axios.post(process.env.REACT_APP_API_URL2 + "/callcenter/fetch-cdr-viewer", {
                ...requestData,
                debugMode: false,
                page: -1,
                forceRefresh: false,
                company: company.id,
                fromPagination: false,
                userId: getCurrentUser()._id,
            }, {
                headers: {"Content-Type": "application/json"},
            })
            if (apiResponse?.data?.status === "200") {
                if (apiResponse?.data?.data?.length > 0) {
                    rows.push(["Στατιστικά"]);
                    rows.push([]);
                    rows.push(["Συνολικές κλήσεις", apiResponse?.data.stats.totalInboundCalls + apiResponse?.data.stats.totalOutboundCalls]);
                    rows.push([]);
                    rows.push(["Συνολικές εισερχόμενες κλήσεις", apiResponse?.data.stats.totalInboundCalls]);
                    rows.push([]);
                    rows.push(["Συνολικές εξερχόμενες κλήσεις", apiResponse?.data.stats.totalOutboundCalls]);
                    rows.push([]);
                    rows.push(["Πλήθος εισερχόμενων κλήσεων που δεν απαντήθηκαν", apiResponse?.data.stats.noAnswer]);
                    rows.push([]);
                    if (Object.keys(apiResponse?.data.stats.meanDidWaitingTime).length > 0) {
                        rows.push(["Μέσος χρόνος αναμονής ανά DID"])
                        Object.keys(apiResponse?.data.stats.meanDidWaitingTime).map((did) => {
                            rows.push([did + " : " + minutesDoubleToText(apiResponse?.data.stats.meanDidWaitingTime[did])]);
                        })
                    }
                    rows.push([]);
                    if (Object.keys(apiResponse?.data.stats.maxSimultaneousCalls).length > 0) {
                        rows.push(["Πλήθος μέγιστων κλήσεων ανά DID"]);
                        Object.keys(apiResponse?.data.stats.maxSimultaneousCalls).map((did) => {
                            rows.push([did + " : " + apiResponse?.data.stats.maxSimultaneousCalls[did]]);
                        })
                    }
                    rows.push([]);
                    let prepHeader = [];
                    for (let col of tableColumnsSimpleView.filter((col) => col.field !== "actions")) {
                        prepHeader.push(col.name);
                    }
                    rows.push(prepHeader);
                    for (let row of apiResponse.data.data) {
                        let prepRow = [];
                        for (let col of tableColumnsSimpleView) {
                            if (col.field === "waiting") {
                                prepRow.push(Number(row.duration) - Number(row.billsec));
                            } else if (col.field === "calldate") {
                                prepRow.push(moment(row.calldate).format("DD/MM/YYYY HH:mm:ss"));
                            } else {
                                prepRow.push(row[col.field]);
                            }
                        }
                        rows.push(prepRow);
                    }
                    const workSheet = XLSX.utils.aoa_to_sheet(rows);
                    const workBook = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(workBook, workSheet, "Αναφορά Τηλεφωνικού");
                    XLSX.writeFile(workBook, 'Αναφορά Τηλεφωνικού.xlsx');
                } else {
                    toast.info("Δεν βρέθηκαν δεδομένα με αυτά τα φίλτρα.");
                }
                setLoading(false);
            } else {
                setLoading(false);
                if (apiResponse?.data?.message) {
                    toast.error(apiResponse.data.message);
                } else {
                    toast.error("Εσωτερικό σφάλμα κατά την αναζήτηση δεδομένων.");
                }
            }
        } catch (error) {
            setLoading(false);
            console.log(error);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        }
    }

    const dcontextToIvr = (dc) => {
        let str = dc;
        const split = dc.split("-");
        if (split[0] === "ivr" && !isNaN(split[1]) && responseData?.ivrs?.length > 0) {
            const findIVR = responseData.ivrs.find((el) => Number(split[1]) === Number(el.id));
            if (findIVR) {
                str = `IVR (${findIVR.name})`
            } else {
                str = `Μενού ${dc}`
            }
        }
        return str;
    }

    return (
        <div style={classicStyleBelowNavbar} onKeyDown={(e) => {
            if (e.key === "Enter") fetchCDRViewer(false)}
        }>
            <Row>
                <Col md={2}>
                    <DateHelper
                        dateFromAndToFunction={(from, to) => dispatch(setCDRViewerRequestData({...requestData, date_from: from, date_to: to}))}
                    />
                </Col>
                <Col md={2}>
                    <DateBox
                        name="date_from"
                        selected={isEmpty(requestData.date_from) ? moment().toDate() : moment(requestData.date_from, "DD/MM/YYYY").toDate()}
                        label={"Ημερομηνία από"}
                        onChange={(e) => handleFilterChange(e, "datebox", "date_from")}
                        allowOtherYears={true}
                    />
                </Col>
                <Col md={2}>
                    <DateBox
                        name="date_to"
                        selected={isEmpty(requestData.date_to) ? moment().toDate() : moment(requestData.date_to, "DD/MM/YYYY").toDate()}
                        label={"Ημερομηνία έως"}
                        onChange={(e) => handleFilterChange(e, "datebox", "date_to")}
                        allowOtherYears={true}
                    />
                </Col>
                <Col md={1}>
                    {!isEmpty(responseData.lastUpdate) && (
                        <Badge bg={"primary"} className={"mt-3"}>
                            Τελευταία ανανέωση: <br/>
                            {moment(responseData.lastUpdate).format("DD/MM/YYYY HH:mm:ss")}
                        </Badge>
                    )}
                </Col>
                <Col md={3}>
                    <Button onClick={() => fetchCDRViewer(true)} disabled={loading}>Ανανέωση Δεδομένων από το Τηλεφωνικό κέντρο {loading && <Spinner animation="border" variant="dark" />}</Button>
                    {requestData.needsFetch && (
                        <React.Fragment>
                            <br/>
                            <div style={{textAlign: "center"}}>Το φίλτρο της ημερομηνίας έχει αλλάξει, παρακαλώ
                                ανανεώστε από το τηλεφωνικό
                            </div>
                        </React.Fragment>
                    )}
                </Col>
                <Col md={2}>
                    <BetterCheckBox
                        name={"debugMode"}
                        className={"mt-4"}
                        text={"Debug Mode"}
                        checked={requestData.debugMode}
                        onChange={(e) => handleFilterChange(e, "checkbox", "debugMode")}
                    />
                </Col>
            </Row>
            <hr/>
            <Row>
                <Col md={2}>
                    <Row>
                        <Col md={10}>
                            <Input
                                name={"srcdst"}
                                label={"Τηλέφωνο"}
                                value={requestData.srcdst}
                                onChange={(e) => handleFilterChange(e, "input", "srcdst")}
                            />
                        </Col>
                        <Col md={2}>
                            <Badge bg={"primary"} title={"Εμφάνιση εσωτερικών τηλεφώνων"} style={{cursor: "pointer", position: "absolute", left: "2px", top: "30px"}} onClick={() => setShowTelephonesModal(true)}>Τηλ.</Badge>
                        </Col>
                    </Row>
                </Col>
                <Col md={2}>
                    <Input
                        name={"client"}
                        label={"Όνομα/ΑΦΜ Πελάτη"}
                        value={requestData.client}
                        onChange={(e) => handleFilterChange(e, "input", "client")}
                    />
                </Col>
                <Col md={2}>
                    <Dropdown
                        name={"callType"}
                        label={"Τύπος Κλήσης"}
                        key={Math.random()}
                        options={callTypeOptions}
                        defaultValue={requestData.callType}
                        multiSelect={true}
                        onChange={(e) => handleFilterChange(e, "msdd", "callType")}
                    />
                </Col>
                <Col md={2}>
                    <Dropdown
                        name={"did"}
                        label={"DID"}
                        key={Math.random()}
                        options={getDIDOptions()}
                        defaultValue={requestData.did}
                        multiSelect={true}
                        onChange={(e) => handleFilterChange(e, "msdd", "did")}
                    />
                </Col>
                <Col md={2}>
                    <Dropdown
                        name={"queue"}
                        label={"Ουρά"}
                        key={Math.random()}
                        options={getQueuesOptions()}
                        defaultValue={requestData.queue}
                        multiSelect={true}
                        onChange={(e) => handleFilterChange(e, "msdd", "queue")}
                    />
                </Col>
                <Col md={2}>
                    <Button onClick={() => fetchCDRViewer(false)} disabled={loading || requestData.needsFetch}>Ανανέωση δεδομένων από την Cache {loading && <Spinner animation="border" variant="dark" />}</Button>
                </Col>
            </Row>
            {responseData.totalPages > 0 && (
                <React.Fragment>
                    <hr className={"mt-1 mb-1"}/>
                    <Row className={"mb-2"}>
                        <div className="text-muted mb-1"><i>Στατιστικά & Αναφορές (πλην εσωτερικών κλήσεων)</i></div>
                        <Col md={2}>
                            <div className={"statBox"}>
                                <div className={"statBoxTitle"}>Συνολικές κλήσεις</div>
                                <hr className={"mt-1 mb-1"}/>
                                <div className={"statBoxContainer"} style={{textAlign: "center", fontSize: "20px"}}>{responseData.stats.totalInboundCalls + responseData.stats.totalOutboundCalls}</div>
                            </div>
                        </Col>
                        <Col md={2}>
                            <div className={"statBox"}>
                                <div className={"statBoxTitle"}>Συνολικές εισερχόμενες κλήσεις</div>
                                <hr className={"mt-1 mb-1"}/>
                                <div className={"statBoxContainer"} style={{textAlign: "center", fontSize: "20px"}}>{responseData.stats.totalInboundCalls}</div>
                            </div>
                        </Col>
                        <Col md={2}>
                            <div className={"statBox"}>
                                <div className={"statBoxTitle"}>Συνολικές εξερχόμενες κλήσεις</div>
                                <hr className={"mt-1 mb-1"}/>
                                <div className={"statBoxContainer"} style={{textAlign: "center", fontSize: "20px"}}>{responseData.stats.totalOutboundCalls}</div>
                            </div>
                        </Col>
                        <Col md={2}>
                            <div className={"statBox"}>
                                <div className={"statBoxTitle"}>Πλήθος εισερχόμενων κλήσεων που δεν απαντήθηκαν</div>
                                <hr className={"mt-1 mb-1"}/>
                                <div className={"statBoxContainer"} style={{textAlign: "center", fontSize: "20px"}}>
                                    {responseData.stats.noAnswer}<br/>
                                    <Badge bg={"primary"} style={{cursor: "pointer"}} onClick={() => setShowNoAnswerModal(true)}>Προβολή Ιστορικού</Badge>
                                </div>
                            </div>
                        </Col>
                        <Col md={2}>
                            <div className={"statBox"}>
                                <div className={"statBoxTitle"}>Μέσος χρόνος αναμονής ανά DID</div>
                                <hr className={"mt-1 mb-1"}/>
                                <div className={"statBoxContainer"} style={{textAlign: "center", fontSize: "16px"}}>
                                    {Object.keys(responseData.stats.meanDidWaitingTime).length > 0 ? Object.keys(responseData.stats.meanDidWaitingTime).map((did) => (
                                        <span>{did} : {minutesDoubleToText(responseData.stats.meanDidWaitingTime[did])}<br/></span>
                                    )) : (
                                        <span>Δεν υπήρχαν άτομα σε αναμονή σε κάποιο DID</span>
                                    )}
                                </div>
                            </div>
                        </Col>
                        <Col md={2}>
                            <div className={"statBox"}>
                                <div className={"statBoxTitle"}>Πλήθος μέγιστων κλήσεων ανά DID</div>
                                <hr className={"mt-1 mb-1"}/>
                                <div className={"statBoxContainer"} style={{textAlign: "center", fontSize: "16px"}}>
                                    {Object.keys(responseData.stats.maxSimultaneousCalls).length > 0 ? Object.keys(responseData.stats.maxSimultaneousCalls).map((did) => (
                                        <span>{did} : {responseData.stats.maxSimultaneousCalls[did]}<br/></span>
                                    )) : (
                                        <span>Δεν βρέθηκαν δεδομένα</span>
                                    )}
                                    {!isEmpty(responseData.stats.maxSimultaneousCallsTime) && (
                                        <span style={{fontSize: "14px"}}><i>Την στιγμή {responseData.stats.maxSimultaneousCallsTime}</i></span>
                                    )}
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="mb-3 justify-content-end">
                        <Col md={3}>
                            <Button size={"sm"} onClick={() => handleExcelExport()} disabled={loading}>Εξαγωγή XLSX {loading && <Spinner animation="border" variant="dark" />}</Button>
                        </Col>
                        <Col md={9} style={{display: "flex", justifyContent: "right"}}>
                            <Button size="sm" className="mr-1" onClick={() => firstPage()}
                                    disabled={requestData.page === 1}>{"<< Πρώτη"}</Button>
                            <Button size="sm" className="mr-1" onClick={() => previousPage()}
                                    disabled={requestData.page === 1}>{"< Προηγούμενη"}</Button>
                            <Badge style={{fontSize: "12px", lineHeight: "20px"}} className="mr-1" bg="primary">Σελίδα {requestData.page} / {responseData.totalPages}</Badge>
                            <Button size="sm" className="mr-1" onClick={() => nextPage()} disabled={requestData.page === responseData.totalPages}>{"Επόμενη >"}</Button>
                            <Button size="sm" className="mr-1" onClick={() => lastPage()} disabled={requestData.page === responseData.totalPages}>{"Τελευταία >>"}</Button>
                        </Col>
                    </Row>
                    <Row className={"mt-2 mb-2"}>
                        {requestData.debugMode ? (
                            <Col md={12}>
                                <table style={{tableLayout: "fixed", textAlign: "center", width: "100%", border: "1px solid gray"}}>
                                    <colgroup>
                                        {tableColumns.map((o) => (
                                            <col span={1} key={o.name + "col"} style={{width: o.width}}></col>
                                        ))}
                                    </colgroup>
                                    <thead>
                                    <tr>
                                        {tableColumns.map((o) => (
                                            <th key={o.name} style={{border: "1px solid gray", backgroundColor: "#f1fdff"}}>{o.name}</th>
                                        ))}
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {responseData.data?.length > 0 && responseData.data.map((row) => (
                                        <tr key={row.sequence}>
                                            {tableColumns.map((o) => {
                                                if (o.field === "calldate") {
                                                    return (<td key={o.name + row.sequence} style={{border: "1px solid gray"}}>{moment(row[o.field]).format("DD/MM/YYYY HH:mm:ss")}</td>)
                                                } else if (o.field === "waiting") {
                                                    return (<td key={o.name + row.sequence} style={{border: "1px solid gray"}}>{Number(row["duration"]) - Number(row["billsec"])}</td>)
                                                } else if (o.field === "actions") {
                                                    if (!isEmpty(row.recordingfile)) {
                                                        return (<td key={o.name + row.sequence} style={{border: "1px solid gray"}}>
                                                            <Badge style={{cursor: "pointer"}} bg={"primary"} onClick={() => fetchRecording(row)}>Λήψη κλήσης</Badge>
                                                        </td>)
                                                    } else {
                                                        return (<td key={o.name + row.sequence} style={{border: "1px solid gray"}}></td>)
                                                    }
                                                } else {
                                                    return (<td key={o.name + row.sequence} style={{border: "1px solid gray"}}>{row[o.field]}</td>)
                                                }
                                            })}
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </Col>
                        ) : (
                            <Col md={12}>
                                <table style={{tableLayout: "fixed", textAlign: "center", width: "100%", border: "1px solid gray"}}>
                                    <colgroup>
                                        {tableColumnsSimpleView.map((o) => (
                                            <col span={1} key={o.name + "col"} style={{width: o.width}}></col>
                                        ))}
                                    </colgroup>
                                    <thead>
                                    <tr>
                                        {tableColumnsSimpleView.map((o) => (
                                            <th key={o.name} style={{border: "1px solid gray", backgroundColor: "#f1fdff"}}>{o.name}</th>
                                        ))}
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {responseData.data?.length > 0 && responseData.data.map((row) => (
                                        <tr key={row.sequence}>
                                            {tableColumnsSimpleView.map((o) => {
                                                if (o.field === "calldate") {
                                                    return (<td key={o.name + row.sequence} style={{border: "1px solid gray"}}>{moment(row[o.field]).format("DD/MM/YYYY HH:mm:ss")}</td>)
                                                } else if (o.field === "waiting") {
                                                    return (<td key={o.name + row.sequence} style={{border: "1px solid gray"}}>{secondsToMinutesSeconds(Number(row["duration"]) - Number(row["billsec"]))}</td>)
                                                } else if (o.field === "duration") {
                                                    return (<td key={o.name + row.sequence} style={{border: "1px solid gray"}}>{secondsToMinutesSeconds(Number(row["duration"]))}</td>)
                                                } else if (o.field === "billsec") {
                                                    return (<td key={o.name + row.sequence} style={{border: "1px solid gray"}}>{secondsToMinutesSeconds(Number(row["billsec"]))}</td>)
                                                } else if (o.field === "actions") {
                                                    if (!isEmpty(row.recordingfile)) {
                                                        return (<td key={o.name + row.sequence} style={{border: "1px solid gray"}}>
                                                            <Badge style={{cursor: !loading ? "pointer" : ""}} bg={"primary"} onClick={() => fetchRecording(row)} hidden={loading}>Λήψη κλήσης</Badge>
                                                        </td>)
                                                    } else {
                                                        return (<td key={o.name + row.sequence} style={{border: "1px solid gray"}}></td>)
                                                    }
                                                } else {
                                                    return (<td key={o.name + row.sequence} style={{border: "1px solid gray"}}>{row[o.field]}</td>)
                                                }
                                            })}
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </Col>
                        )}
                    </Row>
                </React.Fragment>
            )}
            <Modal show={showTelephonesModal} onHide={() => setShowTelephonesModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Εσωτερικά Τηλέφωνα</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {responseData.devices?.length > 0 && [...responseData.devices].sort((a, b) => Number(a.user) < Number(b.user) ? -1 : 1).map((device) => (
                        <Badge bg={"primary"} className={"mr-1 mb-2"} onClick={() => handleTelephoneClick(device)}
                               style={{cursor: "pointer"}}
                        >{device.user} | {device.description}</Badge>
                    ))}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={() => setShowTelephonesModal(false)}>Κλείσιμο</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showNoAnswerModal} onHide={() => setShowNoAnswerModal(false)} dialogClassName={"noAnswerModal"}>
                <Modal.Header closeButton>
                    <Modal.Title>Ιστορικό εισερχόμενων κλήσεων που δεν απαντήθηκαν</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {responseData?.stats?.noAnswerArr?.length > 0 ? (
                        <table style={{tableLayout: "fixed", textAlign: "center", width: "100%"}}>
                            <colgroup>
                                <col span={1} style={{width: "10%"}}></col>
                                <col span={1} style={{width: "10%"}}></col>
                                <col span={1} style={{width: "20%"}}></col>
                                <col span={1} style={{width: "5%"}}></col>
                                <col span={1} style={{width: "5%"}}></col>
                                <col span={1} style={{width: "10%"}}></col>
                                <col span={1} style={{width: "20%"}}></col>
                                <col span={1} style={{width: "10%"}}></col>
                            </colgroup>
                            <thead>
                            <tr>
                                <th style={{border: "1px solid gray"}}>Ημερομηνία</th>
                                <th style={{border: "1px solid gray"}}>Από</th>
                                <th style={{border: "1px solid gray"}}>Προς</th>
                                <th style={{border: "1px solid gray"}}>Χρόνος αναμονής</th>
                                <th style={{border: "1px solid gray"}}>Συνολικός χρόνος κλήσης</th>
                                <th style={{border: "1px solid gray"}}>Ουρές προσπέλασης</th>
                                <th style={{border: "1px solid gray"}}>Όνομα Πελάτη</th>
                                <th style={{border: "1px solid gray"}}>ΑΦΜ Πελάτη</th>
                            </tr>
                            </thead>
                            <tbody>
                            {responseData?.stats?.noAnswerArr.map((noAns, idx) => (
                                <React.Fragment>
                                    <tr key={idx + " noAnsTable"}>
                                        <td style={{border: "1px solid gray"}}>{noAns.date}</td>
                                        <td style={{border: "1px solid gray"}}>{noAns.src}</td>
                                        <td style={{border: "1px solid gray"}}>{noAns.dst === "s" ? dcontextToIvr(noAns.dcontext) : noAns.dst}</td>
                                        <td style={{border: "1px solid gray"}}>{secondsToMinutesSeconds(Number(noAns.ringing_duration))}</td>
                                        <td style={{border: "1px solid gray"}}>{secondsToMinutesSeconds(Number(noAns.call_duration))}</td>
                                        <td style={{border: "1px solid gray"}}>{noAns.queueHistory}</td>
                                        <td style={{border: "1px solid gray"}}>{noAns.clientName ? noAns.clientName : ""}</td>
                                        <td style={{border: "1px solid gray"}}>{noAns.clientVAT ? noAns.clientVAT : ""}</td>
                                    </tr>
                                </React.Fragment>
                            ))}
                            </tbody>
                        </table>
                    ) : (
                        <div>Δεν υπάρχουν κλήσεις.</div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={() => setShowNoAnswerModal(false)}>Κλείσιμο</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}