import React, {useState} from "react";
import {useSelector} from "react-redux";
import SearchTable from "../../../primer/Search-Table";
import {getObjectFromArrayOfObjects} from "../../../../_helpers/helperFunctions";
import {getOptionsByData} from "../../../../_helpers/commonFunctions";
import {useTranslation} from "react-i18next";
import {classicStyleBelowNavbar} from "../Statics";

const SearchSupplier = () => {
    const [t, i18n] = useTranslation('common');
    const SUPPLIERS_DATA = useSelector((state) => state.SUPPLIERS_DATA.suppliersSettings.categories);
    let supplierCategories = getObjectFromArrayOfObjects(SUPPLIERS_DATA, 'active', 'isActive');
    let supplierCategoriesOption = getOptionsByData(supplierCategories, '_id', 'category');
    supplierCategoriesOption = [{value: "", label: t("General.empty")}, ...supplierCategoriesOption]
    const searchSupplierFilters = {
        filters: [
            {
                name: "name",
                label: t("General.supplierNameVAT"),
                type: "input",
            },
            {
                name: "manualCode",
                label: t("General.manualCode"),
                type: "input",
            },
            {
                name: "category",
                label: t("General.categoryName"),
                type: "dropdown",
                options: supplierCategoriesOption
            },
            {
                name: "systemCode",
                label: t("General.systemCode"),
                type: "input",
            },
            {
                name: "isActive",
                label: t("General.showInactiveSuppliers"),
                type: "checkbox",
            },
        ],
    }

    const scColumns = [
        {
            name: t("General.systemCode"),
            field: "systemCode",
            width: "10%",
            editable: false,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t("General.manualCode"),
            field: "manualCode",
            width: "10%",
            hiddenColumn: true,
            editable: false,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t("General.supplierName"),
            field: "name",
            editable:false,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t("General.category"),
            field: "categoryName",
            editable: false,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t("General.vatNumber"),
            field: "vatNumber",
            editable: false,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t("General.contactName"),
            field: "name",
            editable: false,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t("General.phone"),
            field: "phone",
            editable: false,
            inputType: {
                type: 'text',
            }
        },
    ]

    const [tableColumns, setTableColumns] = useState(scColumns.slice());

    // It will hide or show the table columns for searchtable.
    const handleColumnShowHide = (field) => {
        let tempTc = tableColumns.slice();
        let object = getObjectFromArrayOfObjects(tempTc, field, 'field')
        const index = tempTc.findIndex((item) => field === item.field);
        if (object.length > 0) {
            tempTc[index].hiddenColumn = !object['0'].hiddenColumn;
            setTableColumns(tempTc);
        }
    }
    return (
        <div style={classicStyleBelowNavbar}>
            <SearchTable
                tableName="Supplier Search"
                key={"Supplier Search"}
                hideYearFilter={true}
                allowPagination={true}
                allowToggleColumns={true}
                showDummyData={false}
                columns={tableColumns}
                toggleColumns={handleColumnShowHide}
                data={[]}
                filtersConfig={searchSupplierFilters}
            />
        </div>
    )
}

export default SearchSupplier;
