export const suppliersCategoryData = [
    {
        _id: 1,
        category: "Retail",
        maskCode: "RTL-****",
        maskCodePrefix: 'RTL',
        maskCodeCounter: 1,
        isActive: 'active',
        type: 'supplier',
    },
    {
        _id: 2,
        category: "Freelancer",
        maskCode: "FRL-****",
        maskCodePrefix: 'FRL',
        maskCodeCounter: 1,
        isActive: 'active',
        type: 'supplier',
    },
    {
        _id: 3,
        category: "O.E.",
        maskCode: "OE-****",
        maskCodePrefix: 'OE',
        maskCodeCounter: 1,
        isActive: 'active',
        type: 'supplier',
    },
    {
        _id: 4,
        category: "E.E.",
        maskCode: "EE-****",
        maskCodePrefix: 'EE',
        maskCodeCounter: 1,
        isActive: 'active',
        type: 'supplier',
    },
    {
        _id: 5,
        category: "E.P.E.",
        maskCode: "EPE-****",
        maskCodePrefix: 'EPE',
        maskCodeCounter: 1,
        isActive: 'active',
        type: 'supplier',
    },
    {
        _id: 6,
        category: "I.K.E.",
        maskCode: "IKE-****",
        maskCodePrefix: 'IKE',
        maskCodeCounter: 1,
        isActive: 'active',
        type: 'supplier',
    },
    {
        _id: 7,
        category: "A.E.",
        maskCode: "AE-****",
        maskCodePrefix: 'AE',
        maskCodeCounter: 1,
        isActive: 'active',
        type: 'supplier',
    },
    {
        _id: 9,
        category: "Non-Profit Organization",
        maskCode: "NPO-****",
        maskCodePrefix: 'NPO',
        maskCodeCounter: 1,
        isActive: 'active',
        type: 'supplier',
    },
    {
        _id: 10,
        category: "Supplier within E.U.",
        maskCode: "SWEU-****",
        maskCodePrefix: 'SWEU',
        maskCodeCounter: 1,
        isActive: 'active',
        type: 'supplier',
    },
    {
        _id: 11,
        category: "Supplier outside E.U.",
        maskCode: "SOEU-****",
        maskCodePrefix: 'SOEU',
        maskCodeCounter: 1,
        isActive: 'active',
        type: 'supplier',
    },
    {
        _id: 12,
        category: "Wholesale Others",
        maskCode: "WO-****",
        maskCodePrefix: 'WO',
        maskCodeCounter: 1,
        isActive: 'active',
        type: 'supplier',
    },
]
