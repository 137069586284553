import {createSlice} from "@reduxjs/toolkit";
import {clientsCategoryData} from "../../../_data/clients/clients-settings";
import * as moment from "moment";
import {getCurrentUser} from "../../../_helpers/commonFunctions";

const currentDate = moment().format('DD/MM/YYYY');
let date = new Date();
const currentTime = moment().format('H:mm');
const currentUser = getCurrentUser();
const cUserName = currentUser?.name;
const currentMonth = moment().format("MM");
const current = new Date();

const newInstallationInitialValue = {
    no: '',
    type: '',
    address: '',
    DOY: '',
    city: '',
    id: '',
    vatCategory: '',
    vatReason: '',
    customVat: ''
}

const newClientInitialValue = {
    category: "",
    categoryName: "",
    systemCode: "",
    manualCode: "",
    name: "",
    smallName: "",
    vatCategory: "Normal VAT",
    vatReason: "",
    customVat: "",
    vatNumber: "",
    DOY: "",
    activity: "",
    amka: "",
    country: "",
    address: "",
    city: "",
    postalCode: "",
    email: "",
    gemhNo: "",
    webpage: "",
    phone: "",
    contactPoints: [{
        no: 1,
        id: 1,
        name: "",
        mobile: "",
        landline: "",
        otherPhone: "",
        email: "",
        contactNotes: ""
    }],
    clientNotes: "",
    informationNotes: "",
    notesNotes: "",
    installations: [newInstallationInitialValue],
    communicationHistory: [],
    information: [],
    notes: [],
    emronPasswords: [],
    stockholders: [],
    emronPayments: [],
    accounting: "",
    bypassAccounting: "",
    clientsInfo: [],
    isActive: 'active',
    balance: 0,
    capital: 0,
    peppolPartyIdentification: [],
}

export const clientSettingsInitialValues = {
    categories: clientsCategoryData,
    googleCalendarApi: "",
    clientCommunicationHistory: "",
    clientsInfo: [],
    type: "clientsSettings",
    isActive: "active"
}

const clientMovementsInitialValues = {
    documentType: '',
    documentTypeName: '',
    document_file: '',
    number: '',
    date: date,
    reason: '',
    sum: 0,
    clients: [],
    notes: '',
}

const priceListInitialValues = {
    name: "",
    price: "",
    clientList: [],
    notes: "",
}

const newContactPointInitialValue = {
    no: 1,
    id: 1,
    name: "",
    mobile: "",
    landline: "",
    otherPhone: "",
    email: "",
    // acceptsNotifications: false,
    contactNotes: ""
}

const newInformationInitialValue = {
    no: '',
    description: '',
    details: '',
    date: currentDate.toString(),
    time: currentTime.toString(),
    lastEditedBy: cUserName,
}

const clientMovementsNewClientInitialValue = {
    no: 1,
    clientId: "",
    name: "",
    code: "",
    measurementUnit: "",
    quantity: 0,
}

const newCommunicationHistoryInitialValue = {
    no: 1,
    id: 1,
    date: new Date(),
    time: currentTime.toString(),
    subject: "",
    details: "",
    followUpDate: "",
    createCalenderEvent: false,
    done: false
}

const newClientPaymentInitialValue = {
    documentType: "",
    documentTypeName: "",
    installation: "",
    preNumber: "",
    number: "",
    numberingMode: "manual",
    date: null,
    paymentType: "",
    paymentTypeName: "",
    client: "",
    clientName: "",
    vatNumber: "",
    address: "",
    country: "",
    phone: "",
    email: "",
    reason: "",
    paymentNotes: "",
    amount: "0",
    posDetails: [{
        no: 1,
        id: 1,
        name: "",
        registrationNumber: "",
        amount: "0",
        notes: ""
    }],
    bankDetails: [{
        no: 1,
        id: 1,
        name: "",
        accountNumber: "",
        amount: "0",
        notes: ""
    }],
    chequeDetails: [{
        no: 1,
        id: 1,
        startDate: "",
        endDate: "",
        amount: "0",
        notes: ""
    }],
    returnPayment: "",
    returnPaymentLabel: "",
    tipAmount: 0, // Not saved the database
}

const newClientEmronPasswordInitialValue = {
    no: 1,
    id: 1,
    type: "",
    username: "",
    password: "",
    notes: ""
}
const newClientEmronStockholderInitialValue = {
    no: 1,
    id: 1,
    name: "",
    surname: "",
    vat: "",
    position: "",
    percentage: "",
    stockValue: 0,
    dateFrom: "",
    dateTo: ""
}

const newEmronPaymentInitialValue = {
    no: 1,
    id: 1,
    installationType: "",
    installation: "",
    month: "",
    monthTo: "",
    clientName: "",
    amount: 0,
    notes: "",
    difficulty: "",
    difficultyNotes: "",

}

const newCategoryInitialValue = {
    _id: 1,
    category: '',
    mask: 'CCM-0001',
    maskCode: '',
    maskCodePrefix: 'CCM',
    maskCodeCounter: 1,
    isActive: 'active',
    type: 'client',
}

const initialState = {
    clientSettingsInitialValues: clientSettingsInitialValues,
    newClientsSettings: clientSettingsInitialValues,
    clientsSettings: clientSettingsInitialValues,
    clientsListData: [],
    clientsPayment: [],
    clientPayments: [],
    newClientPaymentInitialValue: newClientPaymentInitialValue,
    newClientPayment: newClientPaymentInitialValue,
    editClientPayment: newClientPaymentInitialValue,
    loading: false,
    hasErrors: false,
    clients: [],
    installations: [],
    newClientInitialValue: newClientInitialValue,
    newClient: newClientInitialValue,
    newClientModal: newClientInitialValue,
    editClient: newClientInitialValue,
    newContactPointInitialValue: newContactPointInitialValue,
    newContactPoint: newContactPointInitialValue,
    editContactPoint: newContactPointInitialValue,
    newInstallationInitialValue: newInstallationInitialValue,
    newInstallation: newInstallationInitialValue,
    newInformationInitialValue: newInformationInitialValue,
    newInformation: newInformationInitialValue,
    newNotesInitialValue: newInformationInitialValue,
    newNotes: {},
    newCommunicationHistoryInitialValue: newCommunicationHistoryInitialValue,
    newCommunicationHistory: newCommunicationHistoryInitialValue,
    searchClients: [],
    totalItemsCS: 0,
    perPageCS: 0,
    searchClientMovements: [],
    totalItemsCMS: 0,
    perPageCMS: 0,
    searchClientPayments: [],
    perPageCPS: 0,
    totalItemsCPS: 0,
    searchClientsMovementsReport: [],
    searchCommunicationHistory: [],
    selectedClient: {},
    selectedClientTo: {},
    selectedClientFrom: {},
    selectedStartDateFrom: null,
    clientReportBalanceData: [],
    clientReportMovementsData: [],
    clientReportMovementsClientList: [],
    clientMovementsSearchByBalance: {},
    selectedDateBalance: current,
    selectedZero: false,
    selectedInactive: false,
    selectedZeroBalance: false,
    selectedInactiveBalance: false,
    selectedStartDateTo: null,
    selectedFilterBy: currentMonth,
    selectedBalanceClientFrom: {},
    selectedBalanceClientTo: {},
    selectedSalesClientFrom: {},
    selectedSalesClientTo: {},
    selectedEmronPaymentsFilters: null,
    selectedEmronPaymentsMonth: new Date(),
    selectedEmronPaymentsClientFrom: {},
    selectedEmronPaymentsClientTo: {},
    selectedEmronPaymentsInstallations: "active",
    selectedEmronPaymentsZero: "inactive",
    selectedMyDataReportClient: {},
    selectedSalesBalanceClientFrom: {},
    selectedSalesBalanceClientTo: {},
    selectedSalesGraphicClientFrom: {},
    selectedSalesGraphicClientTo: {},
    selectedClientMovement: {},
    clientTemplates: [],
    selectedClientTemplate: {},
    clientMovements: [],
    clientMovementsTemplates: [],
    selectedClientMovementsTemplate: {},
    clientMovementsNewClientInitialValue: clientMovementsNewClientInitialValue,
    clientMovementsNewClient: clientMovementsNewClientInitialValue,
    clientMovementsEditClientInitialValue: clientMovementsNewClientInitialValue,
    clientMovementsEditClient: clientMovementsNewClientInitialValue,
    clientPriceListTemplates: [],
    selectedClientPriceListTemplate: {},
    submitting: false,
    selectedCategoryData: {},
    clientMovementsInitialValues: clientMovementsInitialValues,
    clientMovementsClients: [],
    newClientMovements: clientMovementsInitialValues,
    editClientMovements: {},
    priceListInitialValues: priceListInitialValues,
    priceListClients: [],
    newPriceList: priceListInitialValues,
    newQuickClient: newClientInitialValue,
    clientEmron: {
        password: [],
        capital: '',
        stockholders: [],
        clientId: '',
    },
    newClientEmronPasswordInitialValue: newClientEmronPasswordInitialValue,
    newClientEmronPassword: newClientEmronPasswordInitialValue,
    editClientEmronPassword: newClientEmronPasswordInitialValue,
    newEmronPayment: newEmronPaymentInitialValue,
    newClientEmronStockholderInitialValue: newClientEmronStockholderInitialValue,
    newClientEmronStockholder: newClientEmronStockholderInitialValue,
    editClientEmronStockholder: newClientEmronStockholderInitialValue,
    clientPaymentsTemplates: [],
    selectedClientPaymentsTemplate: {},
    newCategoryInitialValue: newCategoryInitialValue,
    newCategory: newCategoryInitialValue,
    categories: clientsCategoryData,

    reportPrimerPaymentsRequestData: {
        page: 1,
        totalPages: 0,
        clientName: "",
        subscriptionNames: [],
        paymentDateFrom: "",
        paymentDateTo: "",
        startDateFrom: "",
        startDateTo: "",
        voucher: "both",
        status: "active",
        tid: "",
    },
    reportPrimerPaymentsResponseData: {
        totalPages: 0,
        data: [],
        totalNetValue: 0,
        totalFeeValue: 0,
    },

    // Client Balance Report Data
    clientBalanceReportRequestData: {
        showZeroBalance: "inactive",
        showInActive: "inactive",
        includeSettlements: "inactive",
        clientName: "",
        date_to: moment().format("DD/MM/YYYY"),
        page: 1,
    },
    clientBalanceReportResponseData: {
        totalPages: 0,
        data: [],
        totalBalance: 0,
        includeSettlements: "inactive",
    },

    // Client Movements Report
    clientMovementsReportRequestData: {
        page: 1,
        date_from: "",
        date_to: "",
        clientName: "",
        client_zero: "inactive",
        client_inactive: "inactive",
        showNotes: "inactive",
    },
    clientMovementsReportResponseData: {
        totalPages: 0,
        pageData: [],
        pageClientData: {},
    },

    // Client Isozygio Report
    clientIsozygioReportRequestData: {
        firstLoad: true, // For date setup
        zeroBalanceClients: false,
        page: 1,
        date_from: "",
        date_to: "",
        clientName: "",
    },
    clientIsozygioReportResponseData: {
        totalPages: 0,
        data: [],
    },
    clientIsozygioReportTotals: [],

    // Emron Monthly Report
    emronMonthlyReportRequestData: {
        page: 1,
        month: moment().toDate(),
        clientName: "",
        year: "",
        cardPayments: "both", // "both", "card", "no_card"
        cardPaymentsStatus: "", // paid, pending, failed
        groupInstallations: "inactive",
        groupClients: "inactive",
        showZero: "inactive",
    },
    emronMonthlyReportResponseData: {
        data: [],
        months: [],
        totals: {},
        totalPages: 0,
    },

    // Emron Settlements Report
    emronSettlementsReportRequestData: {
        date_from: "",
        date_to: "",
        clientId: "",
        clientName: "",
        showOverdue: false,
        doseAnalysis: false,
    },
    emronSettlementsReportResponseData: {
        data: [],
        doseAnalysis: false,
        totalPaidAmount: 0,
        totalSettledAmount: 0,
        totalRemainingAmount: 0,
    }
}

// Client Slice
export const ClientsSlice = createSlice({
    name: "client",
    initialState,
    reducers: {
        loadingClient: (state) => void (state.loading = true),

        addClient: (state, {payload}) => {
            state.clients = [...state.clients, payload];
            state.loading = false;
            state.hasErrors = false;
        },

        setClientNewCategory: (state, {payload}) => {
            state.newCategory = payload;
        },

        resetClientNewCategory: (state) => {
            state.newCategory = state.newCategoryInitialValue;
        },

        setClientCategory: (state, {payload}) => {
            state.categories = payload;
            state.clientsSettings.categories = payload;
        },

        resetClientCategory: (state) => {
            state.categories = [];
            state.clientsSettings.categories = [];
        },

        setNewQuickClient: (state, {payload}) => {
            state.newQuickClient = payload;
        },

        resetNewQuickClient: (state, {payload}) => {
            state.newQuickClient = state.newClientInitialValue;
        },

        updateClient: (state, {payload}) => {
            state.clients = [...state.clients.filter((item) => item['_id'] !== payload.id), payload.data];
            state.loading = false;
            state.hasErrors = false;
        },

        deleteClient: (state, {payload}) => {
            state.clients = state.clients.filter((item) => item['_id'] !== payload.id);
            state.loading = false;
            state.hasErrors = false;
        },

        setClients: (state, {payload}) => {
            state.clients = payload;
            state.loading = false;
            state.hasErrors = false;
        },

        setNewClient: (state, {payload}) => {
            state.newClient = payload;
        },

        resetNewClient: (state) => {
            state.newClient = state.newClientInitialValue;
        },
        setNewClientModal: (state, {payload}) => {
            state.newClientModal = payload;
        },

        resetNewClientModal: (state) => {
            state.newClientModal = state.newClientInitialValue;
        },

        setNewClientStatus: (state, {payload}) => {
            state.newClient.isActive = payload;
        },

        setEditClient: (state, {payload}) => {
            state.editClient = payload;
        },

        setEditClientStatus: (state, {payload}) => {
            state.editClient.isActive = payload;
        },

        setNewContactPoint: (state, {payload}) => {
            state.newContactPoint = payload;
        },

        setEditContactPoint: (state, {payload}) => {
            state.editContactPoint = payload;
        },

        setNewInstallations: (state, {payload}) => {
            state.newInstallation = payload;
        },

        resetNewInstallations: (state, {payload}) => {
            state.newInstallation = state.newInstallationInitialValue;
        },

        setNewInformation: (state, {payload}) => {
            state.newInformation = payload;
        },

        setNewNotes: (state, {payload}) => {
            state.newNotes = payload;
        },
        resetNewNotes: (state) => {
            state.newNotes = {};
        },
        setNewCommunicationHistory: (state, {payload}) => {
            state.newCommunicationHistory = payload;
        },

        resetNewCommunicationHistory: (state) => {
            state.newCommunicationHistory = state.newCommunicationHistoryInitialValue;
        },

        setSearchClients: (state, {payload}) => {
            state.searchClients = payload;
        },

        setSearchClientMovements: (state, {payload}) => {
            state.searchClientMovements = payload;
            state.loading = false;
            state.hasErrors = false;
        },

        setSelectedClient: (state, {payload}) => {
            state.selectedClient = payload;
        },

        setSelectedClientFrom: (state, {payload}) => {
            state.selectedClientFrom = payload;
        },
        setSelectedFilterBy: (state, {payload}) => {
            state.selectedFilterBy = payload;
        },
        setSelectedStartDateFrom: (state, {payload}) => {
            state.selectedStartDateFrom = payload;
        },
        setClientMovementsReportData: (state, {payload}) => {
            state.clientReportMovementsData = payload;
        },
        setClientMovementsClientList: (state, {payload}) => {
            state.clientReportMovementsClientList = payload;
        },
        setClientMovementsSearchByBalance: (state, {payload}) => {
            state.clientMovementsSearchByBalance = payload;
        },
        setClientBalanceReportData: (state, {payload}) => {
            state.clientReportBalanceData = payload;
        },
        setSelectedStartDateTo: (state, {payload}) => {
            state.selectedStartDateTo = payload;
        },
        setSelectedDateBalance: (state, {payload}) => {
            state.selectedDateBalance = payload;
        },
        setSelectedZero: (state, {payload}) => {
            state.selectedZero = payload;
        },
        setSelectedInactive: (state, {payload}) => {
            state.selectedInactive = payload;
        },
        setSelectedZeroBalance: (state, {payload}) => {
            state.selectedZeroBalance = payload;
        },
        setSelectedInactiveBalance: (state, {payload}) => {
            state.selectedInactiveBalance = payload;
        },

        setSelectedClientTo: (state, {payload}) => {
            state.selectedClientTo = payload;
        },

        setSelectedBalanceClientFrom: (state, {payload}) => {
            state.selectedBalanceClientFrom = payload;
        },
        setSelectedBalanceClientTo: (state, {payload}) => {
            state.selectedBalanceClientTo = payload;
        },
        setSelectedSalesClientFrom: (state, {payload}) => {
            state.selectedSalesClientFrom = payload;
        },
        setSelectedSalesClientTo: (state, {payload}) => {
            state.selectedSalesClientTo = payload;
        },
        setSelectedEmronPaymentsFilters: (state, {payload}) => {
            state.selectedEmronPaymentsFilters = payload;
        },
        setSelectedEmronPaymentsMonth: (state, {payload}) => {
            state.selectedEmronPaymentsMonth = payload;
        },
        setSelectedEmronPaymentsClientFrom: (state, {payload}) => {
            state.selectedEmronPaymentsClientFrom = payload;
        },
        setSelectedEmronPaymentsClientTo: (state, {payload}) => {
            state.selectedEmronPaymentsClientTo = payload;
        },
        setSelectedEmronPaymentsInstallations: (state, {payload}) => {
            state.selectedEmronPaymentsInstallations = payload;
        },
        setSelectedEmronPaymentsZero: (state, {payload}) => {
            state.selectedEmronPaymentsZero = payload;
        },
        setSelectedSalesBalanceClientFrom: (state, {payload}) => {
            state.selectedSalesBalanceClientFrom = payload;
        },
        setSelectedSalesBalanceClientTo: (state, {payload}) => {
            state.selectedSalesBalanceClientTo = payload;
        },
        setSelectedMyDataReportClient: (state, {payload}) => {
            state.selectedMyDataReportClient = payload;
        },
        setSelectedSalesGraphicClientFrom: (state, {payload}) => {
            state.selectedSalesGraphicClientFrom = payload;
        },
        setSelectedSalesGraphicClientTo: (state, {payload}) => {
            state.selectedSalesGraphicClientTo = payload;
        },


        setSelectedClientMovement: (state, {payload}) => {
            state.selectedClientMovement = payload;
        },

        setClientTemplates: (state, {payload}) => {
            state.clientTemplates = payload;
            state.loading = false;
            state.hasErrors = false;
        },

        setSelectedClientTemplate: (state, {payload}) => {
            state.selectedClientTemplate = payload;
            state.loading = false;
            state.hasErrors = false;
        },

        setClientMovementsTemplates: (state, {payload}) => {
            state.clientMovementsTemplates = payload;
            state.loading = false;
            state.hasErrors = false;
        },

        setClientMovements: (state, {payload}) => {
            state.clientMovements = payload;
        },

        setNewClientMovements: (state, {payload}) => {
            state.newClientMovements = payload;
        },
        resetNewClientMovements: (state) => {
            state.newClientMovements = state.clientMovementsInitialValues;
        },

        setEditClientMovements: (state, {payload}) => {
            state.editClientMovements = payload;
        },

        setSelectedClientMovementsTemplate: (state, {payload}) => {
            state.selectedClientMovementsTemplate = payload;
            state.loading = false;
            state.hasErrors = false;
        },

        setClientPriceListTemplates: (state, {payload}) => {
            state.clientPriceListTemplates = payload;
            state.loading = false;
            state.hasErrors = false;
        },

        setSelectedClientPriceListTemplate: (state, {payload}) => {
            state.selectedClientPriceListTemplate = payload;
            state.loading = false;
            state.hasErrors = false;
        },

        setClientSubmitting: (state, {payload}) => {
            state.submitting = payload;
        },

        setClientsSelectedCategoryData: (state, {payload}) => {
            state.selectedCategoryData = payload;
        },

        setNewPriceList: (state, {payload}) => {
            state.newPriceList = payload;
        },

        setPriceListClients: (state, {payload}) => {
            state.priceListClients = payload;
        },

        setClientsSettings: (state, {payload}) => {
            state.clientsSettings = payload;
            state.categories = payload.categories;
        },

        setNewClientsSettings: (state, {payload}) => {
            state.newClientsSettings = payload;
        },

        FailedClient: (state) => {
            state.loading = false;
            state.hasErrors = true;
        },

        setClientMovementsNewClient: (state, {payload}) => {
            state.clientMovementsNewClient = payload;
        },

        setClientMovementsEditClient: (state, {payload}) => {
            state.clientMovementsEditClient = payload;
        },

        setClientsPayment: (state, {payload}) => {
            state.clientsPayment = payload;
        },

        setClientsList: (state, {payload}) => {
            state.clients = payload;
        },

        setClientPayments: (state, {payload}) => {
            state.clientPayments = payload;
        },

        setNewClientPayment: (state, {payload}) => {
            state.newClientPayment = payload;
        },

        resetNewClientPayment: (state) => {
            state.newClientPayment = state.newClientPaymentInitialValue;
        },

        setEditClientPayment: (state, {payload}) => {
            state.editClientPayment = payload;
        },

        setClientPaymentsTemplates: (state, {payload}) => {
            state.clientPaymentsTemplates = payload;
        },

        setSelectedClientPaymentsTemplate: (state, {payload}) => {
            state.selectedClientPaymentsTemplate = payload;
        },

        setNewClientEmronPassword: (state, {payload}) => {
            state.newClientEmronPassword = payload;
        },

        setEditClientEmronPassword: (state, {payload}) => {
            state.editClientEmronPassword = payload;
        },
        resetEditClientEmronPassword: (state, {payload}) => {
            state.editClientEmronPassword = newClientEmronPasswordInitialValue;
        },

        resetNewClientEmronPassword: (state) => {
            state.newClientEmronPassword = state.newClientEmronPasswordInitialValue;
        },

        setNewClientEmronStockholder: (state, {payload}) => {
            state.newClientEmronStockholder = payload;
        },

        setEditClientEmronStockholder: (state, {payload}) => {
            state.editClientEmronStockholder = payload;
        },
        resetEditClientEmronStockholder: (state, {payload}) => {
            state.editClientEmronStockholder = newClientEmronStockholderInitialValue;
        },

        resetNewClientEmronStockholder: (state) => {
            state.newClientEmronStockholder = state.newClientEmronStockholderInitialValue;
        },

        setClientEmronPasswords: (state, {payload}) => {
            state.clientEmron.password = payload;
        },

        setClientEmronCapital: (state, {payload}) => {
            state.clientEmron.capital = payload;
        },

        setClientEmronStockholders: (state, {payload}) => {
            state.clientEmron.stockholders = payload;
        },

        setClientIdEmron: (state, {payload}) => {
            state.clientEmron.clientId = payload;
        },

        setClientPaymentsSearch: (state, {payload}) => {
            state.searchClientPayments = payload;
        },


        setTotalItemsCPS: (state, {payload}) => {
            state.totalItemsCPS = payload;
        },


        setperPageCPS: (state, {payload}) => {
            state.perPageCPS = payload;
        },


        setClientsMovementsReportSearch: (state, {payload}) => {
            state.searchClientsMovementsReport = payload;
        },

        setSearchCommunicationHistory: (state, {payload}) => {
            state.searchCommunicationHistory = payload;
            state.editClient = {...state.editClient, communicationHistory: payload};
        },

        resetSearchCommunicationHistory: (state) => {
            state.searchCommunicationHistory = [];
            state.editClient = {...state.editClient, communicationHistory: []};
        },

        setTotalItemsCMS: (state, {payload}) => {
            state.totalItemsCMS = payload;
        },

        setperPageCMS: (state, {payload}) => {
            state.perPageCMS = payload;
        },
        setTotalItemsCS: (state, {payload}) => {
            state.totalItemsCS = payload;
        },

        setperPageCS: (state, {payload}) => {
            state.perPageCS = payload;
        },

        // Report Primer Payments
        setPrimerPaymentsRequestData: (state, {payload}) => {
            state.reportPrimerPaymentsRequestData = payload;
        },
        setPrimerPaymentsResponseData: (state, {payload}) => {
            state.reportPrimerPaymentsResponseData = payload;
        },

        // Client Balance Report
        setClientBalanceReportRequestData: (state, {payload}) => {
            state.clientBalanceReportRequestData = payload;
        },
        setClientBalanceReportResponseData: (state, {payload}) => {
            state.clientBalanceReportResponseData = payload;
        },

        // Client Movements Report
        setClientMovementsReportRequestData: (state, {payload}) => {
            state.clientMovementsReportRequestData = payload;
        },
        setClientMovementsReportResponseData: (state, {payload}) => {
            state.clientMovementsReportResponseData = payload;
        },

        // Client Isozygio Report
        setClientIsozygioReportRequestData: (state, {payload}) => {
            state.clientIsozygioReportRequestData = payload;
        },
        setClientIsozygioReportResponseData: (state, {payload}) => {
            state.clientIsozygioReportResponseData = payload;
        },
        setClientIsozygioReportTotals: (state, {payload}) => {
            state.clientIsozygioReportTotals = payload;
        },

        // Emron Monthly Report
        setEmronMonthlyReportRequestData: (state, {payload}) => {
            state.emronMonthlyReportRequestData = payload;
        },
        setEmronMonthlyReportResponseData: (state, {payload}) => {
            state.emronMonthlyReportResponseData = payload;
        },

        // Emron Settlements Report
        setEmronSettlementsReportRequestData: (state, {payload}) => {
            state.emronSettlementsReportRequestData = payload;
        },
        setEmronSettlementsReportResponseData: (state, {payload}) => {
            state.emronSettlementsReportResponseData = payload;
        }
    },
});

export const {
    setClients,
    setNewClient,
    resetNewClient,
    setNewClientModal,
    resetNewClientModal,
    setSearchClients,
    setSearchClientMovements,
    setSelectedClient,
    setSelectedClientFrom,
    setSelectedStartDateFrom,
    setClientMovementsReportData,
    setClientBalanceReportData,
    setClientMovementsClientList,
    setClientMovementsSearchByBalance,
    setSelectedZero,
    setSelectedInactive,
    setSelectedInactiveBalance,
    setSelectedZeroBalance,
    setSelectedDateBalance,
    setSelectedStartDateTo,
    setSelectedFilterBy,
    setSelectedClientTo,
    setSelectedBalanceClientFrom,
    setSelectedBalanceClientTo,
    setSelectedSalesClientFrom,
    setSelectedSalesClientTo,
    setSelectedEmronPaymentsFilters,
    setSelectedEmronPaymentsInstallations,
    setSelectedEmronPaymentsZero,
    setSelectedEmronPaymentsClientTo,
    setSelectedEmronPaymentsClientFrom,
    setSelectedEmronPaymentsMonth,
    setSelectedSalesBalanceClientFrom,
    setSelectedSalesBalanceClientTo,
    setSelectedMyDataReportClient,
    setSelectedSalesGraphicClientFrom,
    setSelectedSalesGraphicClientTo,
    setSelectedClientMovement,
    setClientTemplates,
    setSelectedClientTemplate,
    setClientMovementsTemplates,
    setSelectedClientMovementsTemplate,
    setClientsSettings,
    setNewContactPoint,
    setEditContactPoint,
    setNewInstallations,
    resetNewInstallations,
    setNewInformation,
    setNewNotes,
    resetNewNotes,
    setClientMovementsNewClient,
    setClientMovementsEditClient,
    setNewClientMovements,
    setNewCommunicationHistory,
    resetNewCommunicationHistory,
    setEditClientMovements,
    setEditClient,
    setClientsPayment,
    setClientsList,
    setClientPayments,
    resetNewClientMovements,
    setNewClientPayment,
    resetNewClientPayment,
    setNewClientEmronPassword,
    resetNewClientEmronPassword,
    setNewClientEmronStockholder,
    resetNewClientEmronStockholder,
    setClientEmronPasswords,
    setClientEmronCapital,
    setClientEmronStockholders,
    setNewClientStatus,
    setEditClientStatus,
    setClientPaymentsSearch,
    setTotalItemsCPS,
    setperPageCPS,
    setClientPaymentsTemplates,
    setEditClientPayment,
    setEditClientEmronPassword,
    resetEditClientEmronPassword,
    setEditClientEmronStockholder,
    resetEditClientEmronStockholder,
    setSearchCommunicationHistory,
    resetSearchCommunicationHistory,
    setClientNewCategory,
    resetClientNewCategory,
    setTotalItemsCMS,
    setperPageCMS,
    setperPageCS,
    setTotalItemsCS,

    setPrimerPaymentsRequestData,
    setPrimerPaymentsResponseData,

    setClientBalanceReportRequestData,
    setClientBalanceReportResponseData,

    setClientMovementsReportRequestData,
    setClientMovementsReportResponseData,

    setClientIsozygioReportRequestData,
    setClientIsozygioReportResponseData,
    setClientIsozygioReportTotals,

    setEmronMonthlyReportRequestData,
    setEmronMonthlyReportResponseData,

    setEmronSettlementsReportRequestData,
    setEmronSettlementsReportResponseData
} = ClientsSlice.actions;
