import { createSlice } from "@reduxjs/toolkit";

const initialState = false;

export const LoginSlice = createSlice({
  name: "Login Slice",
  initialState,
  reducers: {
    login: (state) => (state = true),
    logout: (state) => (state = false),
  },
});

export const { login, logout } = LoginSlice.actions;
