import React, {useState} from "react";
import {Row, Col, Container} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {vatCategoryData} from '../../../../_data/mydata/vatCategory';
import {vatZeroPer1Data, vatZeroPer2Data} from '../../../../_data/mydata/vatZeroPer';
import {findObjectFromArrayOfObjects} from "../../../../_helpers/helperFunctions";
import {setSavePrimerMyDataData} from "../../../../_reducers/activitySlice";
import {setPrimerMyDataData} from "../../../../_reducers/settingsSlice";
import EditableTable from "../../../primer/Editable-Table";
import {useTranslation} from "react-i18next";

const VatData = () => {
    const [t] = useTranslation('common');
    const dispatch = useDispatch();
    const SETTINGS = useSelector((state) => state.SETTINGS);
    let pMDDSettings = SETTINGS.primerMyDataData;
    const vatData = pMDDSettings.vat !== '' ? JSON.parse(pMDDSettings.vat) : vatCategoryData;
    const [vatTableData, setVatTableData] = useState(vatData);

    const vatTableColumns = [
        {
            name: t('SettingsPrimerData.table.code'),
            field: "code",
            width: "30%",
            editable: false,
        },
        {
            name: t('SettingsPrimerData.table.percentage'),
            field: "percentage",
            editable: false,
        },
        {
            name: t('SettingsPrimerData.table.accountingCode'),
            field: "accountingCode",
            editable: true,
            inputType: {
                type: 'accounting',
                config: {
                    focused: true,
                    suffix: "nosuffix",
                    maxLength: 5
                }
            }
        },
        {
            name: t('SettingsPrimerData.table.defaultValue'),
            field: 'defaultValue',
            editable: true,
            inputType: {
                type: 'checkbox',
                options: [{
                    value: 'true',
                    label: 'True'
                }, {
                    value: 'false',
                    label: 'False'
                }]
            }
        },
        {
            name: t('SettingsPrimerData.table.deactivated'),
            field: 'deactivated',
            editable: true,
            inputType: {
                type: 'checkbox',
                options: [{
                    value: 'true',
                    label: 'True'
                }, {
                    value: 'false',
                    label: 'False'
                }]
            }
        },
    ];

    const vatMatchingTableColumns = [
        {
            name: t('SettingsPrimerData.tableMatching.normalVat'),
            field: "percentage",
            width: "30%",
            editable: false,
        },
        {
            name: t('SettingsPrimerData.tableMatching.reducedVat'),
            field: "reducedVat",
            editable: false,
        },
        {
            name: t('SettingsPrimerData.tableMatching.zeroVat'),
            field: "zeroVat",
            editable: false,
        },
    ];

    const vatZeroPerReasonColumns = [
        {
            name: t('SettingsPrimerData.tableZero.code'),
            field: "code",
            editable: false,
        },
        {
            name: t('SettingsPrimerData.tableZero.description'),
            field: "description",
            editable: false,
        },
    ];

    const updateVatActions = {
        update: (data, id) => {

            dispatch(setSavePrimerMyDataData(true)); // Redux State Updated

            let vatData = vatTableData.slice()
            let vatObject = findObjectFromArrayOfObjects(vatTableData, id, 'code');
            const vatObjIdx = vatTableData.findIndex(vatObj => vatObj.code === id);

            if (data.deactivated) {
                vatObject.deactivated = (vatObject.deactivated === 'true') ? 'false' : 'true';
            }

            if (data.defaultValue) {

                // Last remove the selected row.
                let defaultVatObj = findObjectFromArrayOfObjects(vatTableData, 'true', 'defaultValue');
                defaultVatObj.defaultValue = 'false';
                const defaultVatObjIdx = vatTableData.findIndex(vatObj => vatObj.defaultValue === 'true');
                vatData[defaultVatObjIdx] = defaultVatObj;

                // new selected row.
                vatObject.defaultValue = (vatObject.defaultValue === 'true') ? 'false' : 'true';
            }

            if (data.accountingCode) {
                vatObject.accountingCode = data.accountingCode ? data.accountingCode : '0';
            }

            vatData[vatObjIdx] = vatObject;

            setVatTableData(vatData); // Internal State Change
            dispatch(setPrimerMyDataData({...pMDDSettings, vat: JSON.stringify(vatData)})) // Redux State Updated

        },
    }

    return (
        <Container fluid className="p-0 defaultBehavious">
            <Row>
                <Col sm="12" md="6">
                    <div className="text-muted mb-3">
                        <i>{t('SettingsPrimerData.vat%')}</i>
                    </div>
                    <EditableTable
                        allowActions={false}
                        columns={vatTableColumns}
                        data={vatTableData}
                        onUpdate={updateVatActions}
                    />
                </Col>
                <Col sm="12" md="6">
                    <div className="text-muted mb-3">
                        <i>{t('SettingsPrimerData.vatMatchingTable')}</i>
                    </div>
                    <EditableTable
                        allowActions={false}
                        columns={vatMatchingTableColumns}
                        data={vatTableData}
                    />
                </Col>
            </Row>


            {/* Payment Types Table  */}
            <Row>
                <Col md="12" sm="12">
                    <div className="text-muted mb-3">
                        <i>{t('SettingsPrimerData.vat0%Reason')}</i>
                    </div>
                    <div className="d-flex">
                        <EditableTable
                            allowActions={false}
                            columns={vatZeroPerReasonColumns}
                            data={vatZeroPer1Data}
                        />
                        <EditableTable
                            allowActions={false}
                            columns={vatZeroPerReasonColumns}
                            data={vatZeroPer2Data}
                        />
                    </div>
                </Col>
            </Row>
        </Container>
    )
}
export default VatData;
