import React from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import {isEmpty} from "../../../_helpers/commonFunctions";

const HourPicker = (props) => {
    const handleOnChange = (e) => {
        if (props.onChange && typeof props.onChange === "function") {
            props.onChange(moment(e).format("HH:mm"));
        }
    }
    return (
        <div className={`input-container ${props.classes ? props.classes : ""}`}>
            {props.label && (
                <label htmlFor={props.label} className="input-label mb-2">
                    {props.label}
                    {props.required && <span className="text-danger">*</span>}
                </label>
            )}
            <div className="input-wrapper">
                <DatePicker
                    selected={props.selected}
                    onChange={(e) => handleOnChange(e)}
                    showTimeSelect={true}
                    showTimeSelectOnly={true}
                    timeIntervals={isEmpty(props.timeIntervals) ? 15 : props.timeIntervals}
                    timeCaption={"Time"}
                    timeFormat={"HH:mm"}
                    dateFormat={"HH:mm"}
                    disabled={props.disabled === true}
                    onKeyDown={(e) => e.preventDefault()}
                    className={"form-control " + props.classes}
                    wrapperClassName={"datepicker-full-width"}
                />
            </div>
        </div>
    )
}

export default HourPicker;
