import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import getMyData, {
    getECCategoryOptions, getECTypeOptions, getICCategoryOptions, getICTypeOptions,
    getVatExpenseClassificationType
} from "../../../_helpers/sharedmydata";
import axios from "axios";
import {toast} from "react-toastify";
import {setEditBuys, setNewBuys} from "../../../_reducers/DataPanel/Buys/buysSlice";
import {Button, Modal} from "react-bootstrap";
import Dropdown from "../Dropdown";
import Alert from "react-bootstrap/Alert";
import {vatCategoryData} from "../../../_data/mydata/vatCategory";
import BetterCheckBox from "../../common/BetterCheckBox";
import {isEmpty} from "../../../_helpers/commonFunctions";

const BuysProductQuickEdit = (props) => {
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const BUYS = useSelector(state => state.BUYS);
    const TABS_DATA = useSelector((state) => state.TABS_REDUCER);
    const ACTIVE_MAIN_TAB = useSelector((state) => state.ACTIVE_MAIN_TAB);
    const zeroVatReasonOptions = getMyData({ type: 'zerovatreason' });

    const [productData, setProductData] = useState(props.data);
    const [myData, setMyData] = useState([]);

    const isInView = TABS_DATA[ACTIVE_MAIN_TAB] === "view-buys";

    const ecCategory = isInView ?
        getECCategoryOptions().concat(getICCategoryOptions())
        :
        getECCategoryOptions().filter(el => myData.findIndex(md => md.category === el.value) !== -1);
    const ecType = isInView ?
        getECTypeOptions().concat(getICTypeOptions())
        :
        getECTypeOptions().filter(el => myData.findIndex(md => md.type === el.value && md.category === productData.classificationCategory) !== -1);
    const vatExpenseType = [{label: "Κενό", value: ""}].concat(getVatExpenseClassificationType());

    useEffect(() => {
        if(show === true) {
            axios.get(process.env.REACT_APP_API_URL2 + `/category/get-product-by-id/${productData._id}`)
                .then((res) => {
                    if(TABS_DATA[ACTIVE_MAIN_TAB] === "buys-new") {
                        setMyData(res.data.data.mydata.filter((el) => String(el.invoiceType) === String(BUYS.newBuys.myDataType)));
                    } else if (TABS_DATA[ACTIVE_MAIN_TAB] === "edit-buys" || TABS_DATA[ACTIVE_MAIN_TAB] === "view-buys") {
                        setMyData(res.data.data.mydata.filter((el) => String(el.invoiceType) === String(BUYS.editBuys.myDataType)));
                    }
                })
                .catch((err) => console.log(err));
        }
    }, [show])

    const getBuyObject = () => {
        if(TABS_DATA[ACTIVE_MAIN_TAB] === "buys-new") {
            return BUYS.newBuys;
        } else if (TABS_DATA[ACTIVE_MAIN_TAB] === "edit-buys" || TABS_DATA[ACTIVE_MAIN_TAB] === "view-buys") {
            return BUYS.editBuys;
        }
    }

    const saveClicked = () => {
        if(props.tableName === "Buys Products List" && TABS_DATA[ACTIVE_MAIN_TAB] === "buys-new") {
            let allProducts = [...BUYS.newBuys.products];
            allProducts.map((a) => Object.assign({}, a));
            let idx = allProducts.findIndex((el) => el._id === productData._id);
            if(idx === -1) {
                toast.error("Σφάλμα κατά την αποθήκευση, η γραμμή δεν υπάρχει.");
            } else {
                if(props.data.vatPercentage !== productData.vatPercentage) {
                    allProducts[idx] = {...allProducts[idx], ...productData, quantity: 1, unitPrice: 0, discount: 0, vat: 0, netValue: 0, totalValue: 0};
                } else {
                    allProducts[idx] = {...allProducts[idx], ...productData};
                }
                allProducts[idx].quickEdit = true;
                setShow(false);
                toast.success("Το είδος ενημερώθηκε.");
                setTimeout(() => {
                    dispatch(setNewBuys({...BUYS.newBuys, products: allProducts}));
                }, 200);
            }
        } else if(props.tableName === "Buys Products List" && TABS_DATA[ACTIVE_MAIN_TAB] === "edit-buys") {
            let allProducts = [...BUYS.editBuys.products];
            allProducts.map((a) => Object.assign({}, a));
            let idx = allProducts.findIndex((el) => el._id === productData._id);
            if(idx === -1) {
                toast.error("Σφάλμα κατά την αποθήκευση, η γραμμή δεν υπάρχει.");
            } else {
                if(props.data.vatPercentage !== productData.vatPercentage) {
                    allProducts[idx] = {...allProducts[idx], ...productData, quantity: 1, unitPrice: 0, discount: 0, vat: 0, netValue: 0, totalValue: 0};
                } else {
                    allProducts[idx] = {...allProducts[idx], ...productData};
                }
                allProducts[idx].quickEdit = true;
                setShow(false);
                toast.success("Το είδος ενημερώθηκε.");
                setTimeout(() => {
                    dispatch(setEditBuys({...BUYS.editBuys, products: allProducts}));
                }, 200);
            }
        }
    }
    const handleOnChange = (e, type, name) => {
        if (type === "dropdown") {
            if (name === "vatPercentage") {
                if (e.value === "8") {
                    setProductData({...productData, vatPercentage: "0", vatId: "8"});
                } else {
                    const findPercentage = vatCategoryData.find((el) => el.id === String(e.value))?.percentage;
                    if (findPercentage !== "0") {
                        setProductData({...productData, vatPercentage: findPercentage, vatId: e.value});
                    } else {
                        setProductData({...productData, vatPercentage: findPercentage, vatReason: "", vatId: e.value});
                    }
                }
            } else if (name === "vatReason") {
                setProductData({...productData, vatReason: e.value});
            } else if (name === "classificationCategory") {
                setProductData({...productData, classificationCategory: e.value, classificationType: ""});
            } else if (name === "classificationType") {
                setProductData({...productData, classificationType: e.value});
            } else if (name === "vatExpenseType") {
                setProductData({...productData, vatExpenseType: e.value});
            }
        } else if (type === "checkbox") {
            setProductData({...productData, accountingNonDeductibleVAT: e.target.checked === true ? "inactive" : "active"})
        }
    }
    const invalidProduct = () => {
        return isEmpty(productData.vatPercentage)
            || (String(productData.vatId) === "7" && isEmpty(productData.vatReason))
            || (isEmpty(productData.classificationCategory) && !isEmpty(getBuyObject()?.myDataType))
            || (isEmpty(productData.classificationType) && !isEmpty(getBuyObject()?.myDataType));
    }
    const getVatOptions = () => {
        let opt = [];
        for (let vatObj of vatCategoryData) {
            opt.push({label: vatObj.description, value: vatObj.id});
        }
        return opt;
    }
    return (
        <React.Fragment>
            <Button style={{pointerEvents: "auto"}} size={"sm"} variant={"default"} title={props.title ? props.title : "Quick Edit"} onClick={() => {setShow(true)}}>
                <i id={props.id} className="far fa-edit"></i>
            </Button>
            <Modal show={show} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{isInView ? "Προβολή" : "Τροποποίηση"} Είδους Γραμμής</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Dropdown
                        name={"quickEditVat"}
                        label={"Φ.Π.Α. %"}
                        options={getVatOptions()}
                        required={true}
                        disabled={isInView}
                        key={Math.random()}
                        defaultValue={getVatOptions().find((el) => String(el.value) === String(productData.vatId))}
                        onChange={(e) => handleOnChange(e, "dropdown", "vatPercentage")}
                    />
                    {String(productData.vatId) === "7" && (
                        <Dropdown
                            name={"quickEditVatReason"}
                            label={"Λόγος Εξαίρεσης Φ.Π.Α."}
                            options={zeroVatReasonOptions}
                            required={true}
                            disabled={isInView}
                            key={Math.random()}
                            defaultValue={zeroVatReasonOptions.filter((el) => el.value === productData.vatReason).length > 0 ? zeroVatReasonOptions.filter((el) => el.value === productData.vatReason)[0] : ""}
                            onChange={(e) => handleOnChange(e, "dropdown", "vatReason")}
                        />
                    )}
                    <Dropdown
                        name={"quickEditCategory"}
                        label={"Κατηγορία My Data"}
                        options={ecCategory}
                        required={!isEmpty(getBuyObject()?.myDataType)}
                        disabled={isInView}
                        key={Math.random()}
                        defaultValue={ecCategory.filter((el) => el.value === productData.classificationCategory).length > 0 ? ecCategory.filter((el) => el.value === productData.classificationCategory)[0] : ""}
                        onChange={(e) => handleOnChange(e, "dropdown", "classificationCategory")}
                    />
                    <Dropdown
                        name={"quickEditType"}
                        label={"Τύπος My Data"}
                        options={ecType}
                        required={!isEmpty(getBuyObject()?.myDataType)}
                        disabled={isInView}
                        key={Math.random()}
                        defaultValue={ecType.filter((el) => el.value === productData.classificationType).length > 0 ? ecType.filter((el) => el.value === productData.classificationType)[0] : ""}
                        onChange={(e) => handleOnChange(e, "dropdown", "classificationType")}
                    />
                    <Dropdown
                        name={"quickEditVatExpenseType"}
                        label={"Τύπος Εξόδων για Φ.Π.Α."}
                        options={vatExpenseType}
                        required={!isEmpty(getBuyObject()?.myDataType)}
                        disabled={isInView}
                        key={Math.random()}
                        defaultValue={vatExpenseType.filter((el) => el.value === productData.vatExpenseType).length > 0 ? vatExpenseType.filter((el) => el.value === productData.vatExpenseType)[0] : ""}
                        onChange={(e) => handleOnChange(e, "dropdown", "vatExpenseType")}
                    />
                    <BetterCheckBox
                        name={"quickEditAccountingNonDeductibleVAT"}
                        text={"Εκπίπτει τον Φ.Π.Α."}
                        checked={productData?.accountingNonDeductibleVAT !== "active"}
                        disabled={isInView}
                        onChange={(e) => handleOnChange(e, "checkbox", "accountingNonDeductibleVAT")}
                    />
                    {props.data.vatPercentage !== productData.vatPercentage && (
                        <Alert key={Math.random()} variant={"info"}>Αλλάζοντας τον Φ.Π.Α. θα μηδενιστούν οι τιμές του είδους στην πώληση.</Alert>
                    )}
                    {isEmpty(productData.vatPercentage) && <div style={{"color" : "red"}}>* Πρέπει να συμπληρώσετε Φ.Π.Α. %</div>}
                    {(String(productData.vatId) === "7" && isEmpty(productData.vatReason)) && <div style={{"color" : "red"}}>* Πρέπει να συμπληρώσετε λόγο εξαίρεσης Φ.Π.Α.</div>}
                    {(isEmpty(productData.classificationCategory) && !isEmpty(getBuyObject()?.myDataType))&& <div style={{"color" : "red"}}>* Πρέπει να συμπληρώσετε κατηγορία MyData</div>}
                    {(isEmpty(productData.classificationType) && !isEmpty(getBuyObject()?.myDataType)) && <div style={{"color" : "red"}}>* Πρέπει να συμπληρώσετε τύπο MyData</div>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={() => setShow(false)}>Κλείσιμο</Button>
                    {!isInView &&<Button variant="primary" onClick={() => saveClicked()} disabled={invalidProduct()}>Αποθήκευση</Button>}
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    );
}

export default BuysProductQuickEdit