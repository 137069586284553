import React from "react";

const SidebarLabel = ({ labelName, labelDesc, children }) => {
  return (
    <React.Fragment>
      <div id={`pills-${labelName}`} className={`tab-pane fade${labelName === 'Cash Registers - POS - Bank Accounts' ? " active show" : ""}`}>
        <ul className="nav" id={`menu-${labelName}`}>
          <li className="nav-label">{labelDesc}</li>

          {/* Here selection dropdowns come */}
          {children}
        </ul>
      </div>
    </React.Fragment>
  );
};

export default SidebarLabel;
