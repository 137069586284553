import React from "react";
import SearchTable from "../../../primer/Search-Table";
import {useTranslation} from "react-i18next";
import {classicStyleBelowNavbar} from "../Statics";

function BankSearch() {
    const [t] = useTranslation('common');

    const scColumns = [
        {
            name: t('BankAccountsSearch.table.name'),
            field: "name",
            width: "10%",
            editable: false,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t('BankAccountsSearch.table.bank'),
            field: "bankName",
            editable: false,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t('BankAccountsSearch.table.iban'),
            field: "iban",
            editable: false,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t('BankAccountsSearch.table.date'),
            field: "date",
            editable: false,
            inputType: {
                type: 'date',
            }
        },
        {
            name: t('BankAccountsSearch.table.isSentToAade'),
            field: "isSentToAade",
            editable: false,
            inputType: {
                type: 'select',
                options: [{
                    value: 'active',
                    label: t('BankAccountsSearch.active')
                }, {
                    value: 'inactive',
                    label: t('BankAccountsSearch.inactive')
                }]
            }
        },
    ]

    const searchBankAccountsFilters = {
        filters: [
            {
                name: "name",
                label: t('BankAccountsSearch.name'),
                type: "input",
            },
            {
                name: "iban",
                label: t('BankAccountsSearch.iban'),
                type: "input",
            },
            {
                name: "bankName",
                label: t('BankAccountsSearch.bank'),
                type: "input",
            },
            {
                name: "showInactive",
                label: t('General.includeInactive'),
                type: "showInactiveCheckbox",
            },
        ],
        actions: ["quick-edit", "delete"],
    };

    return (
        <div style={classicStyleBelowNavbar}>
            <SearchTable
                tableName="Bank Account Search"
                key={"Bank Account Search"}
                hideYearFilter={true}
                allowToggleColumns={true}
                columns={scColumns}
                data={[]}  // Empty by default
                filtersConfig={searchBankAccountsFilters}
            />
        </div>
    )
}

export default BankSearch;
