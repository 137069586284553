import React, {useEffect, useState} from "react";
import {Badge, Button, Col, Row} from "react-bootstrap";
import Input from "../../../common/Input";
import EditableTable from "../../../primer/Editable-Table";
import {getObjectFromArrayOfObjects} from "../../../../_helpers/helperFunctions";
import axios from "axios";
import {useSelector} from "react-redux";
import Dropdown from "../../../primer/Dropdown";
import DateBox from "../../../primer/DateBox";
import moment from "moment";
import {toast} from "react-toastify";
import {checkPermission} from "../../../../_helpers/commonFunctions";

const ReportsClientsSubscriptions = () => {
    const apiLoginURL = process.env.REACT_APP_LOGIN_URL;
    const [filterData, setFilterData] = useState({
        filterClientName: "",
        filterSubscriptionNames: [],
        startDateFrom: "",
        startDateTo: "",
        activationDateFrom: "",
        activationDateTo: "",
        page: 1,
        status: {label: "Ενεργές", value: "active"},
    })
    const [responseData, setResponseData] = useState([]);
    const [firstLoad, setFirstLoad] = useState(true);
    const [pageChanged, setPageChanged] = useState(false);
    const [subscriptionNames, setSubscriptionNames] = useState([]);
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const permissionsData = JSON.parse(localStorage.getItem("permissions"));
    const [incrKey, setIncrKey] = useState(0);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if(firstLoad) {
            setFirstLoad(false);
            getSubscriptionNames();
        }
    }, []);

    useEffect(() => {
        if(pageChanged) {
            setPageChanged(false);
            getResponseData();
        }
    }, [pageChanged])

    useEffect(() => {
        setIncrKey(incrKey + 1);
    }, [responseData.data]);

    const statusOptions = [
        {label: "Ενεργές", value: "active"},
        {label: "Ανενεργές", value: "inactive"},
        {label: "Ενεργές & Ανενεργές", value: "both"}
    ]

    const columns = [
        {
            name: "Αρ.",
            field: "no",
            hiddenColumn: false,
            disabled: true,
            inputType: {
                type: "text",
            }
        },
        {
            name: "Όνομα πελάτη",
            field: "clientName",
            hiddenColumn: false,
            disabled: true,
            inputType: {
                type: "text",
            }
        },
        {
            name: "ΑΦΜ Πελάτη",
            field: "clientVATNumber",
            hiddenColumn: false,
            disabled: true,
            inputType: {
                type: "text",
            }
        },
        {
            name: "Όνομα συνδρομής",
            field: "subscriptionName",
            hiddenColumn: false,
            disabled: true,
            inputType: {
                type: "text",
            }
        },
        {
            name: "Ημερομηνία Έναρξης",
            field: "startDate",
            hiddenColumn: false,
            disabled: true,
            inputType: {
                type: "text",
            }
        },
        {
            name: "Ημερομηνία Ενεργοποίησης",
            field: "activatedDate",
            hiddenColumn: false,
            disabled: true,
            inputType: {
                type: "text",
            }
        },
        {
            name: "Ημερομηνία Λήξης",
            field: "endDate",
            hiddenColumn: false,
            disabled: true,
            inputType: {
                type: "text",
            }
        },
        {
            name: "Κατάσταση",
            field: "status",
            hiddenColumn: false,
            disabled: true,
            inputType: {
                type: "text",
            }
        },
        {
            name: "Καθαρή αξία",
            field: "price",
            hiddenColumn: false,
            editable: true,
            disabled: true,
            inputType: {
                type: 'accounting',
                config: {
                    suffix: "€",
                }
            }
        },
    ]
    const [tableColumns, setTableColumns] = useState(columns.slice());
    const handleColumnShowHide = (field) => {
        let tempTc = tableColumns.slice();
        let object = getObjectFromArrayOfObjects(tempTc, field, "field");
        const index = tempTc.findIndex((item) => field === item.field);
        if (object.length > 0) {
            tempTc[index].hiddenColumn = !object["0"].hiddenColumn;
            setTableColumns(tempTc);
        }
    }

    const getResponseData = (showMsg) => {
        setLoading(true);
        axios.get(process.env.REACT_APP_API_URL2 + `/client/get-subscriptions-for-report`, {
            headers: { "Content-Type": "application/json" },
            params: {
                data: {
                    filterData: filterData,
                    company: company.id,
                    user: JSON.parse(localStorage.getItem("user"))._id
                },
            }
        }).then((res) => {
            setLoading(false);
            if (res.data.status === "200") {
                setResponseData(res.data);
                if(showMsg) toast.success("Τα δεδομένα ανανεώθηκαν.");
            }
        }).catch((err) => {
            setLoading(false);
            console.log(err);
        })
    }

    const getSubscriptionNames = () => {
        axios.get(process.env.REACT_APP_API_URL2 + `/settings/get-subscription-names`, {
            headers: { "Content-Type": "application/json" },
            params: {
                company: company.id,
                user: JSON.parse(localStorage.getItem("user"))._id,
            }
        }).then((res) => {
            if (res.data.status === "401") {
                localStorage.clear();
                window.location.replace(`${apiLoginURL}?redirect=ERP&error=You are not Authorized!`);
            } else if (res.data.status === "200") {
                let finalData = [];
                if(res.data.data && res.data.data?.length > 0) {
                    res.data.data.map((sub) => {
                        finalData.push({
                            label: sub,
                            value: sub,
                        })
                    })
                }
                setSubscriptionNames(finalData);
            }
        }).catch((err) => {
            console.log(err);
        })
    }

    const handleOnChange = (e, type, name) => {
        if(type === "input") {
            if(name === "filterClientName") {
                setFilterData({...filterData, filterClientName: e.target.value});
            }
        } else if (type === "dropdown") {
            if(name === "filterSubscriptionNames") {
                setFilterData({...filterData, filterSubscriptionNames: e});
            } else if (name === "status") {
                setFilterData({...filterData, status: e})
            }
        } else if (type === "datebox") {
            if(name === "startDateFrom") {
                if(moment(e).isValid) setFilterData({...filterData, startDateFrom: moment(e).toISOString() });
                if(!moment(e).isValid) setFilterData({...filterData, startDateFrom: e });
            }
            else if(name === "startDateTo") {
                if(moment(e).isValid) setFilterData({...filterData, startDateTo: moment(e).toISOString() });
                if(!moment(e).isValid) setFilterData({...filterData, startDateTo: e });
            }
            else if(name === "activationDateFrom") {
                if(moment(e).isValid) setFilterData({...filterData, activationDateFrom: moment(e).toISOString() });
                if(!moment(e).isValid) setFilterData({...filterData, activationDateFrom: e });
            }
            else if(name === "activationDateTo") {
                if(moment(e).isValid) setFilterData({...filterData, activationDateTo: moment(e).toISOString() });
                if(!moment(e).isValid) setFilterData({...filterData, activationDateTo: e });
            }
        }
    }

    const firstPage = () => {
        setFilterData({...filterData, page: 1});
        setPageChanged(true);
    }
    const previousPage = () => {
        setFilterData({...filterData, page: filterData.page - 1});
        setPageChanged(true);
    }
    const nextPage = () => {
        setFilterData({...filterData, page: filterData.page + 1});
        setPageChanged(true);
    }
    const lastPage = () => {
        setFilterData({...filterData, page: responseData.totalPages});
        setPageChanged(true);
    }
    return(
        <React.Fragment>
            <Row className="mb-3">
                <Col md={3}>
                    <Input
                        name="filterClientName"
                        label="Όνομα/ΑΦΜ Πελάτη"
                        autoComplete="off"
                        value={filterData.filterClientName}
                        onChange={(e) => handleOnChange(e, "input", "filterClientName")}
                    />
                </Col>
                <Col md={3}>
                    <Dropdown
                        name="filterSubscriptionNames"
                        label="Συνδρομές"
                        multiSelect={true}
                        options={subscriptionNames}
                        defaultValue={filterData.filterSubscriptionNames}
                        onChange={(e) => handleOnChange(e, "dropdown", "filterSubscriptionNames")}
                    />
                </Col>
                <Col md={3}>
                    <DateBox
                        name="startDateFrom"
                        label="Ημερομηνία Έναρξης από"
                        selected={moment(filterData.startDateFrom).isValid() ? moment(filterData.startDateFrom).toDate() : ""}
                        onChange={(e) => handleOnChange(e, "datebox", "startDateFrom")}
                    />
                </Col>
                <Col md={3}>
                    <DateBox
                        name="startDateTo"
                        label="Ημερομηνία Έναρξης εως"
                        selected={moment(filterData.startDateTo).isValid() ? moment(filterData.startDateTo).toDate() : ""}
                        onChange={(e) => handleOnChange(e, "datebox", "startDateTo")}
                    />
                </Col>
                <Col md={3}>
                    <DateBox
                        name="activationDateFrom"
                        label="Ημερομηνία Ενεργοποίησης από"
                        selected={moment(filterData.activationDateFrom).isValid() ? moment(filterData.activationDateFrom).toDate() : ""}
                        onChange={(e) => handleOnChange(e, "datebox", "activationDateFrom")}
                    />
                </Col>
                <Col md={3}>
                    <DateBox
                        name="activationDateTo"
                        label="Ημερομηνία Ενεργοποίησης μέχρι"
                        selected={moment(filterData.activationDateTo).isValid() ? moment(filterData.activationDateTo).toDate() : ""}
                        onChange={(e) => handleOnChange(e, "datebox", "activationDateTo")}
                    />
                </Col>
                <Col md={3}>
                    <Dropdown
                        name={"filterStatus"}
                        label={"Κατάσταση Συνδρομών"}
                        options={statusOptions}
                        defaultValue={statusOptions.filter((el) => el.value === "active")[0]}
                        value={filterData.status}
                        onChange={(e) => handleOnChange(e, "dropdown", "status")}
                    />
                </Col>
            </Row>
            <Row className="mb-3 justify-content-end">
                <Col md={3} style={{display: "flex", justifyContent: "right"}}>
                    <Button onClick={() => getResponseData(true)} disabled={loading}>Εφαρμογή φίλτρων και αναζήτηση</Button>
                </Col>
            </Row>
            {responseData.totalPages && responseData.totalPages > 0 && (
                <React.Fragment>
                    <hr/>
                    <Row className="mb-3 justify-content-end">
                        <Col md={9} style={{display: "flex", justifyContent: "right"}}>
                            <Button size="sm" className="mr-1" onClick={() => firstPage()} disabled={filterData.page === 1 || loading}>{"<< Πρώτη"}</Button>
                            <Button size="sm" className="mr-1" onClick={() => previousPage()} disabled={filterData.page === 1 || loading}>{"< Προηγούμενη"}</Button>
                            <Badge style={{fontSize: "12px", lineHeight: "20px"}} className="mr-1" bg="primary">Σελίδα {filterData.page} / {responseData.totalPages}</Badge>
                            <Button size="sm" className="mr-1" onClick={() => nextPage()} disabled={filterData.page === responseData.totalPages || loading}>{"Επόμενη >"}</Button>
                            <Button size="sm" className="mr-1" onClick={() => lastPage()} disabled={filterData.page === responseData.totalPages || loading}>{"Τελευταία >>"}</Button>
                        </Col>
                    </Row>
                    <Row>
                        <EditableTable
                            key={"Client Subscriptions Report" + incrKey}
                            tableName="Client Subscriptions Report"
                            data={responseData.data}
                            allowPagination={false}
                            allowToggleColumns={true}
                            allowInsertRow={false}
                            editRedirectAllowed = {false}
                            enableNewRowCheckbox={false}
                            toggleColumns={handleColumnShowHide}
                            columns={tableColumns}
                            allowBulkActions={true}
                            allowPrintResults={checkPermission("clients-reports/reportsclientsubscriptions-print", permissionsData) === true}
                            allowActions={false}
                            reportExcelPrint={true}

                            pageTotals={true}
                            showSubscriptionSum={true}
                            totalSubscriptionSum={responseData.totalSum ? responseData.totalSum : ""}
                            filters={filterData}
                            company={company}
                        />
                    </Row>
                </React.Fragment>
            )}
        </React.Fragment>
    )
}

export default ReportsClientsSubscriptions