import React from "react";
import SearchTable from "../../../primer/Search-Table";
import {useTranslation} from "react-i18next";
import {classicStyleBelowNavbar} from "../Statics";

function CashRegistersSearch() {
    const [t] = useTranslation('common');

    const scColumns = [
        {
            name: t('cashRegistersSearch.table.name'),
            field: "name",
            width: "10%",
            editable: false,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t('cashRegistersSearch.table.serialNumber'),
            field: "serialNumber",
            editable: false,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t('cashRegistersSearch.table.date'),
            field: "date",
            editable: false,
            inputType: {
                type: 'date',
            }
        },
        {
            name: t('cashRegistersSearch.table.isSentToAade'),
            field: "isSentToAade",
            editable: false,
            inputType: {
                type: 'select',
                options: [{
                    value: 'active',
                    label: t('cashRegistersSearch.sent')
                }, {
                    value: 'inactive',
                    label: t('cashRegistersSearch.notSent')
                }]
            }
        },
    ]

    const searchCashRegistersFilters = {
        filters: [
            {
                name: "name",
                label: t('cashRegistersSearch.name'),
                type: "input",
            },
            {
                name: "serialNumber",
                label: t('cashRegistersSearch.serialNumber'),
                type: "input",
            },
            {
                name: "showInactive",
                label: t('General.includeInactive'),
                type: "showInactiveCheckbox",
            },
        ],
        actions: ["quick-edit", "delete"],
    }

    return (
        <div style={classicStyleBelowNavbar}>
            <SearchTable
                tableName="Cash Registers Search"
                key={"Cash Registers Search"}
                hideYearFilter={true}
                allowToggleColumns={true}
                columns={scColumns}
                data={[]}  // Empty by default
                filtersConfig={searchCashRegistersFilters}
            />
        </div>
    )
}

export default CashRegistersSearch;
