// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.datepicker-full-width {
  width: 100%; }

.react-datepicker__header {
  background-color: #f1fdfe !important; }

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
  background-color: #76aeb7 !important; }

.react-datepicker__navigation-icon::before {
  border-color: #76aeb7 !important; }
`, "",{"version":3,"sources":["webpack://./src/_components/primer/DateBox/index.scss"],"names":[],"mappings":"AAAA;EACE,WAAW,EAAA;;AAGb;EACE,oCAAoC,EAAA;;AAGtC;;EAEE,oCAAqC,EAAA;;AAGvC;EACE,gCAAgC,EAAA","sourcesContent":[".datepicker-full-width {\r\n  width: 100%;\r\n}\r\n\r\n.react-datepicker__header {\r\n  background-color: #f1fdfe !important;\r\n}\r\n\r\n.react-datepicker__day--selected,\r\n.react-datepicker__day--keyboard-selected {\r\n  background-color: #76aeb7 !important ;\r\n}\r\n\r\n.react-datepicker__navigation-icon::before {\r\n  border-color: #76aeb7 !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
