import React, {useEffect, useState} from "react";
import {Badge, Button, Modal} from "react-bootstrap";
import {getInternationalCodesTableData} from "../../../_data/international_codes/international_codes";
import Input from "../../common/Input";
import "./modalWidths.scss"

const InternationalMUModal = () => {
    const [show, setShow] = useState(false);
    const [tableData, setTableData] = useState(getInternationalCodesTableData());

    useEffect(() => {
        if (show) {
            setTableData(getInternationalCodesTableData());
        }
    }, [show]);

    const handleFilterChange = (e, type, name) => {
        if (type === "input") {
            if (name === "nameFilter") {
                setTableData(getInternationalCodesTableData(e.target.value));
            }
        }
    }

    return (
        <React.Fragment>
            <Badge bg={"primary"} style={{cursor: "pointer"}} onClick={() => setShow(true)}>Προβολή όλων των κωδικών μονάδων μέτρησης παγκοσμίως</Badge>
            <Modal show={show} onHide={() => setShow(false)} dialogClassName={"modal5OPercentWidth"}>
                <Modal.Header closeButton>
                    <Modal.Title>Παγκόσμιες Μονάδες Μέτρησης</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{borderBottom: "1px solid gray"}} className={"pb-2"}>
                        Η μονάδα μέτρησης επιλέγεται από τους καταλόγους στη σύσταση
                        UN/ECE Recommendation N°. 20 “Codes for Units of Measure Used in
                        International Trade” και στη σύσταση UN/ECE Recommendation N° 21
                        “Codes for Passengers, Types of Cargo, Packages and
                        Packaging Materials (with Complementary Codes for Package Names)”.
                        Οι Πωλητές χρειάζεται να υποστηρίζουν τις μονάδες μέτρησης που
                        χρειάζονται για τα αγαθά και τις υπηρεσίες τους · Οι αγοραστές
                        χρειάζονται να επαληθεύσουν ότι οι μονάδες μέτρησης που
                        χρησιμοποιούνται στο Τιμολόγιο είναι ίδιες με τις μονάδες που
                        χρησιμοποιούνται σε άλλα νόμιμα έγγραφα (όπως Σύμβαση,
                        Κατάλογος Παραγγελιών, Δελτίο Αποστολής). Παρακάτω φαίνονται οι μονάδες μέτρησης.
                    </div>
                    <Input
                        name={"nameFilter"}
                        label={"Φίλτρο ονόματος ή κωδικού"}
                        className={"mt-1 mb-1"}
                        onChange={(e) => handleFilterChange(e, "input", "nameFilter")}
                    />
                    <table style={{tableLayout: "fixed", textAlign: "center", width: "99%"}} key={"internationalMUTable"}>
                        <colgroup>
                            <col style={{width: "10%"}}></col>
                            <col style={{width: "30%"}}></col>
                            <col style={{width: "60%"}}></col>
                        </colgroup>
                        <thead>
                        <tr style={{border: "1px solid gray"}}>
                            <th>Κωδικός</th>
                            <th>Όνομα</th>
                            <th>Περιγραφή</th>
                        </tr>
                        </thead>
                        <tbody>
                        {tableData.length > 0 && tableData.map((row, idx) => (
                            <tr key={idx + " inter_code"} style={{border: "1px solid gray"}}>
                                <td>{row.code}</td>
                                <td>{row.name}</td>
                                <td>{row.description}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={() => setShow(false)}>
                        Κλείσιμο
                    </Button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
}

export default InternationalMUModal