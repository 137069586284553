import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {Col, Row} from "react-bootstrap";
import Input from "../../../common/Input";
import Dropdown from "../../../primer/Dropdown";
import EditableTable from "../../../primer/Editable-Table";
import TextArea from "../../../common/TextArea";
import Alert from "react-bootstrap/Alert";
import {permitTypes} from "./Components/constants";

const EditWorkPermit = () => {
    const [t] = useTranslation('common');
    const workPermit = useSelector((state) => state.PAYROLL.editWorkPermit);
    const companyInstallations = useSelector((state) => state.COMPANY_DATA.companyInstallations);

    const [installationOptions, setInstallationOptions] = useState([]);

    const tableColumns = [
        {
            name: "Εργαζόμενος",
            field: "employeeIdentifier",
            editable: true,
            width: "40%",
            required: true,
            inputType: {
                type: 'text',
            }
        },
        {
            name: "Τύπος Άδειας",
            field: "permitType",
            editable: true,
            width: "30%",
            inputType: {
                type: 'select',
                options: permitTypes,
            }
        },
        {
            name: "Ημερομηνία από",
            field: "dateFrom",
            editable: true,
            width: "15%",
            inputType: {
                type: 'text',
            }
        },
        {
            name: "Ημερομηνία μέχρι",
            field: "dateTo",
            editable: true,
            width: "15%",
            inputType: {
                type: 'text',
            }
        },
    ]

    useEffect(() => {
        if (companyInstallations.length > 0) {
            let options = [];
            companyInstallations.forEach((inst) => {
                options.push({ value: inst.installationMasterId, label: inst.type + " " + inst.address + " " + inst.city });
            })
            setInstallationOptions(options);
        }
    }, [])

    return (
        <React.Fragment>
            <Alert variant={"secondary"}>
                Οι άδειες δεν είναι επεξεργάσιμες επειδή έχουν σταλεί στο My Data.
            </Alert>
            <Row className={"mb-2"}>
                <Col md={7}>
                    <Input
                        name={"description"}
                        label={"Περιγραφή"}
                        value={workPermit.description}
                        disabled={true}
                    />
                </Col>
                <Col md={5}>
                    <Dropdown
                        name={"installationMasterId"}
                        label={t("General.installation")}
                        options={installationOptions}
                        defaultValue={installationOptions.find((el) => el.value === workPermit.installationMasterId)}
                        key={Math.random()}
                        disabled={true}
                    />
                </Col>
            </Row>
            <Row className={"mb-2"}>
                <Col md={12} style={{pointerEvents: "none"}}>
                    <div className={"text-muted mb-2"}><i>Άδειες</i></div>
                    <EditableTable
                        tableName={"New Work Permit Employees"}
                        key={Math.random()}
                        data={workPermit.employees}
                        columns={tableColumns}
                        allowInsertRow={false}
                        allowActions={false}
                    />
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <TextArea
                        name={"notes"}
                        label={"Σημειώσεις"}
                        rows={4}
                        value={workPermit.notes}
                        disabled={true}
                    />
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default EditWorkPermit
