export const getLogTypeOptions = () => {
    let opt = [];
    for (let key in LOG_TYPES) {
        opt.push({label: key, value: key});
    }
    return opt;
}

const LOG_TYPES = {
    // Products
    product_create: "When a product is created",
    product_update: "When a product is updated",
    product_delete: "When a product is deleted",

    // Product Movements
    productMovement_create: "When a product movement is created",
    productMovement_update: "When a product movement is updated",
    productMovement_delete: "When a product movement is deleted",

    // Product Prices
    productPrice_create: "When a product price is created",
    productPrice_update: "When a product price is updated",
    productPrice_delete: "When a product price is deleted",

    // Product Categories
    productCategory_create: "When a product category is created",
    productCategory_update: "When a product category is updated",
    productCategory_delete: "When a product category is deleted",

    // Sales
    sale_create: "When a sale is created",
    sale_update: "When a sale is updated", // Only for imports
    sale_delete: "When a sale is deleted",
    sale_sendMyData: "When a sale is sent to my data",
    sale_sendMyDataProvider: "When a sale is sent to my data provider",

    // SalesGeneralSettings
    salesSetting_update: "When sale settings are updated",

    // QuickRetailSettings
    quickRetailSalesSetting_update: "When quick retail sale settings are updated",

    // Buys
    buy_create: "When a buy is created",
    buy_update: "When a buy is updated",
    buy_delete: "When a buy is deleted",
    buy_sendMyData: "When a buy is sent to my data",

    // BuyGeneralSettings
    buysSetting_update: "When buy settings are updated",

    // Clients
    client_create: "When a client is created",
    client_update: "When a client is updated",
    client_delete: "When a client is deleted",

    // Client Settings
    clientSetting_create: "When client settings are created",
    clientSetting_update: "When client settings are updated",

    // Client Payments
    clientPayment_create: "When a client payment is created",
    clientPayment_update: "When a client payment is updated",
    clientPayment_delete: "When a client payment is deleted",

    // Client Movements
    clientMovement_create: "When a client movement is created",
    clientMovement_update: "When a client movement is updated",
    clientMovement_delete: "When a client payment is deleted",

    // Suppliers
    supplier_create: "When a supplier is created",
    supplier_update: "When a supplier is updated",
    supplier_delete: "When a supplier is deleted",

    // Supplier Settings
    supplierSetting_create: "When supplier settings are created",
    supplierSetting_update: "When supplier settings are updated",

    // Supplier Payments
    supplierPayment_create: "When a supplier payment is created",
    supplierPayment_update: "When a supplier payment is updated",
    supplierPayment_delete: "When a supplier payment is deleted",

    // Client Movements
    supplierMovement_create: "When a supplier movement is created",
    supplierMovement_update: "When a supplier movement is updated",
    supplierMovement_delete: "When a supplier payment is deleted",

    // Cash
    cash_create: "When cash is created",
    cash_update: "When cash is updated",
    cash_delete: "When cash is deleted",

    // Cash Settings
    cashSetting_create: "When cash settings are created",
    cashSetting_update: "When cash settings are updated",

    // Bank Register
    bank_create: "When bank is created",
    bank_update: "When bank is updated",
    bank_delete: "When bank is deleted",

    // Pos
    pos_create: "When a pos is created",
    pos_update: "When a pos is updated",
    pos_delete: "When a pos is deleted",

    // Accounting
    account_create: "When an accounting account is created",
    account_update: "When an accounting account is updated",
    account_delete: "When an accounting account is deleted",
    record_create: "When an accounting record is created",
    record_update: "When an accounting record is updated",
    record_delete: "When an accounting record is deleted",

    // Accounting Settings
    accountingSetting_create: "When accounting settings are created",
    accountingSetting_update: "When accounting settings are updated",

    // Settings
    basicSetting_create: "When a basic setting is created",
    basicSetting_update: "When a basic setting is updated",
    basicSetting_delete: "When a basic setting is deleted",
    advancedSetting_create: "When an advanced setting is created",
    advancedSetting_update: "When an advanced setting is updated",
    advancedSetting_delete: "When an advanced setting is deleted",

    // File Export
    file_export: "When data is exported",
}