import React, {useState} from "react";
import {Badge, Button, CloseButton, Stack} from "react-bootstrap";
import "./style.scss"
import Dropdown from "../Dropdown";
import * as moment from "moment";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

const DateHelper = ({dateFromFunction, dateToFunction, dateFromAndToFunction}) => {
    const [t] = useTranslation("common");
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const [show, setShow] = useState(false);

    const monthOptions = [
        { label: t("January"), value: "01" },
        { label: t("February"), value: "02" },
        { label: t("March"), value: "03" },
        { label: t("April"), value: "04" },
        { label: t("May"), value: "05" },
        { label: t("June"), value: "06" },
        { label: t("July"), value: "07" },
        { label: t("August"), value: "08" },
        { label: t("September"), value: "09" },
        { label: t("October"), value: "10" },
        { label: t("November"), value: "11" },
        { label: t("December"), value: "12" },
    ]

    const handleSelection = (type, special) => {
        const thisYear = moment().format("YYYY");
        let dateFrom, dateTo;
        if(String(company.year) === thisYear) {
            if (type === "thisMonth") {
                dateFrom = moment().utc().startOf("month").format("DD/MM/YYYY");
                dateTo = moment().utc().endOf("month").format("DD/MM/YYYY");
            } else if (type === "lastMonth") {
                const someLastMonthDate = moment().startOf("month").subtract(1, "day");
                dateFrom = someLastMonthDate.utc().startOf("month").format("DD/MM/YYYY");
                dateTo = someLastMonthDate.utc().endOf("month").format("DD/MM/YYYY");
            } else if (type === "thisYear") {
                dateFrom = moment().utc().startOf("year").format("DD/MM/YYYY");
                dateTo = moment().utc().endOf("year").format("DD/MM/YYYY");
            } else if (type === "monthSelection") {
                dateFrom = moment(special, "MM", true).startOf("month").format("DD/MM/YYYY");
                dateTo = moment(special, "MM", true).endOf("month").format("DD/MM/YYYY");
            }
        } else {
            let newMoment = moment().subtract(Number(thisYear) - Number(company.year), "years");
            if (type === "thisMonth") {
                dateFrom = newMoment.utc().startOf("month").format("DD/MM/YYYY");
                dateTo = newMoment.utc().endOf("month").format("DD/MM/YYYY");
            } else if (type === "lastMonth") {
                const someLastMonthDate = newMoment.startOf("month").subtract(1, "day");
                dateFrom = someLastMonthDate.utc().startOf("month").format("DD/MM/YYYY");
                dateTo = someLastMonthDate.utc().endOf("month").format("DD/MM/YYYY");
            } else if (type === "thisYear") {
                dateFrom = newMoment.utc().startOf("year").format("DD/MM/YYYY");
                dateTo = newMoment.utc().endOf("year").format("DD/MM/YYYY");
            } else if (type === "monthSelection") {
                dateFrom = moment(`${special}/${String(company.year)}`, "MM/YYYY", true).startOf("month").format("DD/MM/YYYY");
                dateTo = moment(`${special}/${String(company.year)}`, "MM/YYYY", true).endOf("month").format("DD/MM/YYYY");
            }
        }
        if (dateFromFunction instanceof Function) dateFromFunction(dateFrom);
        if (dateToFunction instanceof Function) dateToFunction(dateTo);
        if (dateFromAndToFunction instanceof Function) dateFromAndToFunction(dateFrom, dateTo);
        setShow(false);
    }

    return (
        <React.Fragment>
            <label>{t("General.filterBy")}</label>
            <br/>
            <Button onClick={() => setShow(!show)}>{t("General.dateSelection")}</Button>
            {show && (
                <div className={"date-helper-container"}>
                    <CloseButton style={{position: "absolute", top: "5px", right: "5px"}} onClick={() => setShow(false)}/>
                    {t("General.fastFilter")}
                    <Stack className={"mt-1"} gap={1}>
                        <Badge className={"badge-style"} bg={"primary"} onClick={() => handleSelection("thisMonth")}>{t("General.thisMonth")}</Badge>
                        {Number(moment().format("MM")) > 1 && <Badge className={"badge-style"} bg={"primary"} onClick={() => handleSelection("lastMonth")}>{t("General.lastMonth")}</Badge>}
                        <Badge className={"badge-style"} bg={"primary"} onClick={() => handleSelection("thisYear")}>{t("General.thisYear")}</Badge>
                    </Stack>
                    <Dropdown
                        label={t("General.monthSelection")}
                        options={monthOptions}
                        className={"mt-1"}
                        onChange={(e) => handleSelection("monthSelection", e.value)}
                    />
                </div>
            )}
        </React.Fragment>
    )
}

export default DateHelper