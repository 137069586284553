import React, {useEffect, useState} from "react";
import SearchTable from "../../../primer/Search-Table";
import { getObjectFromArrayOfObjects } from "../../../../_helpers/helperFunctions";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {fetchAdvancedTypes} from "../../../../_apis/api";
import {classicStyleBelowNavbar} from "../Statics";

function SupplierMovementsSearch() {
  const dispatch = useDispatch();
  const [t] = useTranslation("common");
  const SETTINGS = useSelector((state) => state.SETTINGS);
  const company = useSelector((state) => state.COMPANY_DATA.company);

  useEffect(() => {
    dispatch(fetchAdvancedTypes({company: company.id}))
  }, []);

  const getDocumentTypes = () => {
    const docTypes = [...SETTINGS.settings.advancedTypes.filter((el) => el.section === "Supplier Movements")];
    let advOptions = [];
    for(let doc of docTypes) {
      advOptions.push({value: doc._id, label: doc.name})
    }
    return advOptions;
  }
  const searchSupplierFilters = {
    filters: [
      {
        name: "name",
        label: t("General.supplierName"),
        type: "input",
      },
      {
        name: "sum_from",
        label: t("General.totalSumFrom"),
        type: "input",
      },
      {
        name: "sum_to",
        label: t("General.totalSumTo"),
        type: "input",
      },
      {
        name: "documentType",
        label: t("General.documentType"),
        type: "multiselect",
        options: getDocumentTypes(),
      },
    ],
  };
  const scColumns = [
    {
      name: t("General.date"),
      field: "date",
      editable: false,
      inputType: {
        type: 'date',
      }
    },
    {
      name: t("General.documentType"),
      field: "documentTypeName",
      editable: false,
      inputType: {
        type: 'text',
      }
    },
    {
      name: t("General.reason"),
      field: "reason",
      editable:false,
      inputType: {
        type: 'text',
      }
    },
    {
      name: t("General.totalAmount"),
      field: "sum",
      editable: false,
      inputType: {
        type: 'text',
      }
    },
  ]
  const [tableColumns, setTableColumns] = useState(scColumns.slice())
  // It will hide or show the table columns for searchtable.
  const handleColumnShowHide = (field) => {
    let tempTc = tableColumns.slice();
    let object = getObjectFromArrayOfObjects(tempTc, field, 'field')
    const index = tempTc.findIndex((item) => field === item.field);
    if (object.length > 0) {
      tempTc[index].hiddenColumn = !object['0'].hiddenColumn;
      setTableColumns(tempTc);
    }
  }

  return (
      <div style={classicStyleBelowNavbar}>
        <SearchTable
          tableName="Supplier Movements Search"
          key = {"Supplier Movements Search"}
          allowPagination = {true}
          hideYearFilter={false}
          allowToggleColumns = { true }
          columns={tableColumns}
          toggleColumns = {handleColumnShowHide}
          data={[]}
          filtersConfig={searchSupplierFilters}
        />
      </div>
  )
}

export default SupplierMovementsSearch;
