import { createSlice } from "@reduxjs/toolkit";

let companyDetails = {
  name: 'Dummy Company Name',
  smallName: "Small Company",
  activity: "Test activity",
  vatNumber: "4654646",
  headquartersAddress: "Test Address",
  city: "Test",
  county: "Test",
  postalCode: "4554",
  phoneNumber: "645646544",
  emailAddress: "test@test.com",
  gemhNo: "3235354",
  webpage: "http://localhost:3000/",
  representativeName: "",
  representativeSurname: "",
  representativeVatNumber: "",
  installations: [{
    no: 1,
    _id: "1",
    type: "Main Address",
    address: "Test Address",
    doy: "Test DOY",
    city: "Test city",
    county: "Test County",
  }, {
    no: 1,
    _id: "2",
    type: "Second Address",
    address: "Test Address",
    doy: "Test DOY",
    city: "Test city",
    county: "Test County",
  }],
  users: [{
    no: 1,
    _id: "1",
    name: "test user1",
    startDate: "01/03/2022",
    endDate: "01/05/2022",
    userRights: "sales, products",
    username: "username1",
  }, {
    no: 1,
    _id: "2",
    name: "test user2",
    startDate: "01/02/2022",
    endDate: "01/08/2022",
    userRights: "sales, products",
    username: "username2",
  }],
  companyLogo: "",
  useLogo: ""
}

const initialState = {
  hasErrors: false,
  company: {
    id: '61b87129f622f2333f300aeb',
    name: 'Company 1',
    year: (new Date()).getFullYear().toString()
  },
  companyDetails: companyDetails,
  userRightsList: [],
  companyListData: [],
  companyInstallations: [],
  dashboardData: {}
};

export const CompanySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    setCompany: (state, { payload }) => {
      state.company = payload;
      state.hasErrors = false;
    },
    setCompanyDetails: (state, { payload }) => {
      state.companyDetails = payload;
      state.hasErrors = false;
    },

    setCompanyListData: (state, { payload }) => {
      state.companyListData = payload;
      state.loading = false;
      state.hasErrors = false;
    },
    setUserRightsList: (state, { payload }) => {
      state.userRightsList = payload;
      state.loading = false;
      state.hasErrors = false;
    },
    setCompanyInstallations: (state, { payload }) => {
      state.companyInstallations = payload;
      state.loading = false;
      state.hasErrors = false;
    },
    setDashboardData: (state, { payload }) => {
      state.dashboardData = payload;
    }
  },
});

export const {
  setCompany,
  setCompanyDetails,
  setUserRightsList,
  setCompanyInstallations,
  setDashboardData
} = CompanySlice.actions;
