export const salesPurposeData = [
    {
        "id": "1",
        "code": "1",
        "name": "Πώληση",
        "isActive": "true"
    },
    {
        "id": "2",
        "code": "2",
        "name": "Πώληση για Λογαριασμό Τρίτων",
        "isActive": "true"
    },
    {
        "id": "3",
        "code": "3",
        "name": "Δειγματισμός",
        "isActive": "true"
    },
    {
        "id": "4",
        "code": "4",
        "name": "Έκθεση",
        "isActive": "true"
    },
    {
        "id": "5",
        "code": "5",
        "name": "Επιστροφή",
        "isActive": "true"
    },
    {
        "id": "6",
        "code": "6",
        "name": "Φύλαξη",
        "isActive": "true"
    },
    {
        "id": "7",
        "code": "7",
        "name": "Επεξεργασία Συναρμολόγηση",
        "isActive": "true"
    },
    {
        "id": "8",
        "code": "8",
        "name": "Μεταξύ Εγκαταστάσεων Οντότητας",
        "isActive": "true"
    },
    {
        "id": "9",
        "code": "9",
        "name": "Αγορά",
        "isActive": "true"
    },
    {
        "id": "10",
        "code": "10",
        "name": "Εφοδιασμός πλοίων και αεροσκαφών",
        "isActive": "true"
    },
    {
        "id": "11",
        "code": "11",
        "name": "Δωρεάν διάθεση",
        "isActive": "true"
    },
    {
        "id": "12",
        "code": "12",
        "name": "Εγγύηση",
        "isActive": "true"
    },
    {
        "id": "13",
        "code": "13",
        "name": "Χρησιδανεισμός",
        "isActive": "true"
    },
    {
        "id": "14",
        "code": "14",
        "name": "Αποθήκευση σε Τρίτους",
        "isActive": "true"
    },
    {
        "id": "15",
        "code": "15",
        "name": "Επιστροφή από Φύλαξη",
        "isActive": "true"
    },
    {
        "id": "16",
        "code": "16",
        "name": "Ανακύκλωση",
        "isActive": "true"
    },
    {
        "id": "17",
        "code": "17",
        "name": "Καταστροφή άχρηστου υλικού",
        "isActive": "true"
    },
    {
        "id": "18",
        "code": "18",
        "name": "Διακίνηση Παγίων (Ενδοδιακίνηση)",
        "isActive": "true"
    },
    {
        "id": "19",
        "code": "19",
        "name": "Λοιπές Διακινήσεις",
        "isActive": "true"
    },
]