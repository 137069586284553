import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "../../../../_helpers/commonFunctions";
import {
    setCalendarReportDisplayData,
    setCalendarReportRequestData, setCalendarReportResponseData, setEditAccountingRecord
} from "../../../../_reducers/DataPanel/Accounting/accountingSlice";
import axios from "axios";
import {addTab, updateActiveMainTab} from "../../../../_reducers/TabsSlice";
import {toast} from "react-toastify";
import Loader from "../../../primer/POPUP-COMPONENTS/Loader/PrimerLoader";
import {Badge, Button, Col, Row} from "react-bootstrap";
import DateHelper from "../../../primer/DateHelper/DateHelper";
import DateBox from "../../../primer/DateBox";
import moment from "moment/moment";
import Dropdown from "../../../primer/Dropdown";
import Input from "../../../common/Input";
import "./styles/reports.scss"
import * as XLSX from "xlsx";
import {jsPDF} from "jspdf";
import {liberationSerif} from "../../../../fonts/liberationSerif";
import {dejavuSans} from "../../../../fonts/DejaVuSans-normal";
import {dejavuSansBold} from "../../../../fonts/DejaVuSans-Bold-bold";
import {StorageController} from "../../../../_helpers/storage-controller";
import AdvancedCombobox from "../../../primer/AdvancedCombobox";
import {classicStyleBelowNavbar} from "../Statics";

const AccountingReportCalendar = () => {
    const dispatch = useDispatch();
    const requestData = useSelector((state) => state.ACCOUNTING_DATA.calendarReportRequestData);
    const responseData = useSelector((state) => state.ACCOUNTING_DATA.calendarReportResponseData);
    const displayData = useSelector((state) => state.ACCOUNTING_DATA.calendarReportDisplayData);
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const companyInstallations = useSelector((state) => state.COMPANY_DATA.companyInstallations);
    const TABS_DATA = useSelector((state) => state.TABS_REDUCER);
    const [installationOptions, setInstallationOptions] = useState([]);
    const [loading, setLoading] = useState(false);

    const [render, setRender] = useState(false);
    const pageRef = useRef();
    const [autoScrollRunning, setAutoscrollRunning] = useState(false);

    const tdStyle = { fontSize: `${displayData.fontSize}px` }

    const [defaultSubaccountData, setDefaultSubaccountData] = useState([]);

    useEffect(() => {
        if(companyInstallations.length > 0) {
            let options = [{ value: "", label: "Όλες"}];
            companyInstallations.map((inst) => {
                options.push({ value: inst.installationMasterId, label: inst.type + " " + inst.address + " " + inst.city });
            })
            setInstallationOptions(options);
        }
        setTimeout(() => {
            setRender(true);
        }, 100)
        if (defaultSubaccountData.length === 0) {
            onChangeSubaccount(null, setDefaultSubaccountData);
        }
    }, [])

    useEffect(() => {
        if (render) {
            pageRef.current.scrollTo(0, StorageController.getKey("accountingCalendarScroll"));
            if (!autoScrollRunning) scrollHolder();
            setAutoscrollRunning(true);
        }
    }, [render]);

    const scrollHolder = () => {
        if (pageRef && pageRef?.current && !isEmpty(pageRef?.current?.scrollTop) && pageRef?.current?.scrollTop !== StorageController.getKey("accountingCalendarScroll")) {
            StorageController.setKey("accountingCalendarScroll", pageRef.current.scrollTop);
        }
        setTimeout(() => {
            if (pageRef && pageRef?.current && !isEmpty(pageRef?.current?.scrollTop)) scrollHolder();
        }, 250)
    }

    const onChangeSubaccount = (keyword, setData) => {
        let requestData = { company: company.id }
        if (!isEmpty(keyword) && String(keyword).length >= 2) requestData.keyword = keyword;
        axios.get(`${process.env.REACT_APP_API_URL2}/accounting/mass-subaccount-filter`, {
            headers: {"Content-Type": "application/json"},
            params: {
                ...requestData
            }
        }).then((res) => {
            if (res.data.status === "200") {
                setData(res.data.data);
            } else {
                setData([]);
                console.log(res.data);
            }
        }).catch((error) => {
            setData([]);
            console.log(error);
        })
    }

    const handleFilterChange = (e, type, name) => {
        if (type === "datebox") {
            if(moment(e).isValid()) {
                dispatch(setCalendarReportRequestData({...requestData, [name] : moment(e).format("DD/MM/YYYY")}));
            } else {
                dispatch(setCalendarReportRequestData({...requestData, [name] : ""}));
            }
        } else if (type === "dropdown") {
            dispatch(setCalendarReportRequestData({...requestData, [name] : e.value}));
        } else if (type === "input") {
            dispatch(setCalendarReportRequestData({...requestData, [name] : e.target.value}));
        } else if (type === "acdd" && name === "subAccount") {
            dispatch(setCalendarReportRequestData({...requestData, subAccount: e.row._id, subAccountInput: e.row.name}));
        }
    }

    const fetchReport = () => {
        setLoading(true);
        axios.post(process.env.REACT_APP_API_URL2 + "/report/accounting-calendar", {...requestData, company: company.id, year: company.year}, {
            headers: { "Content-Type": "application/json" }
        }).then((res) => {
            setLoading(false);
            if(res.data.status === "200") {
                let resData = res.data.data;
                if (resData.length > 0) {
                    let aa = 1;
                    for (let acc of resData) {
                        for (let lines of acc.lines) {
                            lines.aa = aa;
                            aa++;
                        }
                    }
                }
                dispatch(setCalendarReportResponseData(resData));
            }
        }).catch((error) => {
            setLoading(false);
            console.log(error);
            toast.error("Πρόβλημα κατά την αποστολή αιτήματος.");
        })
    }

    const handleEnter = (e) => {
        if(e.key === "Enter") fetchReport();
    }

    const handleDoubleClick = (articleId) => {
        axios.get(process.env.REACT_APP_API_URL2 + "/accounting/get-record-by-id", {
            headers: { "Content-Type": "application/json" },
            params: {
                company: company.id,
                articleId: articleId,
            }
        }).then((res) => {
            if(res.data.status === "200") {
                dispatch(setEditAccountingRecord(res.data.data));
                const idx = TABS_DATA.findIndex((item) => "accounting-record-edit" === item);
                if(idx === -1) {
                    dispatch(addTab("accounting-record-edit"));
                    dispatch(updateActiveMainTab(TABS_DATA.length));
                } else {
                    dispatch(updateActiveMainTab(idx));
                }
            } else {
                toast.error("Πρόβλημα κατά την εύρεση άρθρου.");
            }
        }).catch((error) => {
            console.log(error);
            toast.error("Πρόβλημα κατά την αποστολή αιτήματος.");
        })
    }

    const handleExcelExport = () => {
        let rows = [];
        rows.push(["Επωνυμία:", company.name]);
        rows.push(["ΑΦΜ:", company.vatNumber]);
        rows.push([]);

        rows.push(["Α/Α", "Ημερομηνία", "Αρ. Άρθρου", "Περιγραφή", "Κωδικός", "Όνομα", "Ανάλυση", "Ποσό"]);
        if (responseData.length > 0) {
            for (let acc of responseData) {
                rows.push([acc.code, acc.name, "", "", "", "", "", ""])
                for (let line of acc.lines) {
                    rows.push([
                        line.aa,
                        line.date,
                        line.number,
                        line.reason,
                        acc.code,
                        acc.name,
                        acc.subAccount,
                        line.amount
                    ])
                }
                rows.push([`Σύνολο Λογαριασμού ${acc.code}`, acc.name, "", "", "", "", "", Number(acc.totalAmount).toFixed(2)])
                rows.push([]);
            }
        }
        const workSheet = XLSX.utils.aoa_to_sheet(rows);
        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, workSheet, "Ημερολόγιο");
        XLSX.writeFile(workBook, 'Ημερολόγιο Λογαριασμών.xlsx');
    }

    const handlePDFExport = () => {
        const doc = new jsPDF('landscape');
        let y = 20;
        doc.addFileToVFS("arialuni.ttf", liberationSerif);
        doc.addFont("arialuni.ttf", "Arial Unicode MS", "normal");
        doc.addFileToVFS("dejavusans.ttf", dejavuSans);
        doc.addFont("dejavusans.ttf", "DejaVu Sans Normal", "normal");
        doc.addFileToVFS("dejavusansBold.ttf", dejavuSansBold);
        doc.addFont("dejavusansBold.ttf", "DejaVu Sans Bold", "normal");
        doc.setFont("DejaVu Sans Bold");
        doc.setFontSize(12);
        let dateFrom = requestData.date_from;
        let dateTo = requestData.date_to;
        if (isEmpty(dateFrom)) dateFrom = moment(company.year, "YYYY", true).startOf("year").format("DD/MM/YYYY");
        if (isEmpty(dateTo)) dateTo = moment(company.year, "YYYY", true).endOf("year").format("DD/MM/YYYY");
        const header = doc.splitTextToSize(`Ημερολόγιο Λογαριασμών από ${dateFrom} έως ${dateTo}`, 200);
        const headerString = header.join("\n");
        doc.text(headerString, doc.internal.pageSize.getWidth() / 4, 9, {alignment: "center"});
        doc.setFont("DejaVu Sans Normal");
        doc.setFontSize(9);
        doc.text(`${company.name}, ΑΦΜ: ${company.vatNumber}`, 15, 17, {alignment: "center"});
        const imgData = 'assets/images/prrimer-logo.png';
        const imgX = doc.internal.pageSize.getWidth() - 45; // adjust the position as needed
        const imgY = 3; // adjust the position as needed
        doc.addImage(imgData, 'PNG', imgX, imgY, 40, 15);
        doc.setFont("DejaVu Sans Normal");
        const columns = ["Α/Α", "Ημερομηνία", "Αρ. Άρθρου", "Περιγραφή", "Κωδικός", "Όνομα", "Ανάλυση", "Ποσό"];
        let rows = [];
        if (responseData.length > 0) {
            for (let acc of responseData) {
                rows.push([{content: `${acc.code} ${acc.name}`, colSpan: 8, styles: {halign: "left", fillColor: "#decae3"}}])
                for (let line of acc.lines) {
                    rows.push([
                        line.aa,
                        line.date,
                        line.number,
                        line.reason,
                        acc.code,
                        acc.name,
                        acc.subAccount,
                        line.amount
                    ])
                }
                rows.push([
                    {content: `Σύνολο Λογαριασμού ${acc.code} ${acc.name}`, colSpan: 7, styles: {halign: "left", fillColor: "#decae3"}},
                    {content: Number(acc.totalAmount).toFixed(2), styles: {halign: "center", fillColor: "#decae3"}}])
                rows.push([]);
            }
        }
        doc.autoTable({
            startY: y,
            head: [columns],
            body: rows,
            rowPageBreak: "avoid",
            styles: {
                font: "DejaVu Sans Normal",
                fontSize: 8,
                halign: "center",
            },
        })
        const pageCount = doc.getNumberOfPages();
        // Add the pagination to each page
        for (let i = 1; i <= pageCount; i++) {
            doc.setPage(i);
            doc.setFontSize(10);
            doc.text(`Σελίδα ${i} από ${pageCount}`, 10, doc.internal.pageSize.getHeight() - 10);
        }
        doc.save("Ημερολόγιο Λογαριασμών.pdf");
    }

    return (
        <React.Fragment>
            {loading && (<Loader doNotShowSmallText={true}/>)}
            <div ref={pageRef} key={"wholeBody"} hidden={loading}
                 style={classicStyleBelowNavbar}
                 onKeyDown={(e) => handleEnter(e)}>
                <Row key={"filters"} className={"mb-2"}>
                    <Col md={2}>
                        <DateHelper
                            dateFromAndToFunction={(from, to) => dispatch(setCalendarReportRequestData({...requestData, date_from: from, date_to: to}))}
                        />
                    </Col>
                    <Col md={2}>
                        <DateBox
                            dateFormat="dd-MM-yyyy"
                            name="date_from"
                            selected={requestData.date_from !== "" ? moment(requestData.date_from, "DD-MM-YYYY").toDate() : ""}
                            label={"Ημερομηνία από"}
                            onChange={(e) => handleFilterChange(e, "datebox", "date_from")}
                        />
                    </Col>
                    <Col md={2}>
                        <DateBox
                            dateFormat="dd-MM-yyyy"
                            name="date_to"
                            selected={requestData.date_to !== "" ? moment(requestData.date_to, "DD-MM-YYYY").toDate() : ""}
                            label={"Ημερομηνία μέχρι"}
                            onChange={(e) => handleFilterChange(e, "datebox", "date_to")}
                        />
                    </Col>
                    <Col md={3}>
                        <Dropdown
                            name={"installation"}
                            label={"Εγκατάσταση"}
                            options={installationOptions}
                            key={Math.random()}
                            defaultValue={installationOptions.find((el) => el.value === requestData.installation) ? installationOptions.find((el) => el.value === requestData.installation) : ""}
                            onChange={(e) => handleFilterChange(e, "dropdown", "installation")}
                        />
                    </Col>
                    <Col md={3}>
                        <Input
                            name={"code_prefix"}
                            label={"Πρόθεμα κωδικού"}
                            placeholder={"Πρόθεμα κωδικού"}
                            value={requestData.code_prefix}
                            autoComplete={"off"}
                            key={"code_prefix"}
                            onChange={(e) => handleFilterChange(e, "input", "code_prefix")}
                        />
                    </Col>
                </Row>
                <Row key={"filters2"}>
                    <Col md={4}>
                        <AdvancedCombobox
                            name={"subAccount"}
                            className="w-100 ac-select-dropdown"
                            searchableDropdownEnable={false}
                            label={"Υπολογαριασμός"}
                            placeholder={"Πληκτρολογήστε υπολογαριασμό"}
                            defaultValue={requestData.subAccountInput}
                            data={defaultSubaccountData}
                            onChange={(e) => handleFilterChange(e, "acdd", "subAccount")}
                            excludeColumns={[]}
                            columns={[
                                {label: "Όνομα", name: "name"},
                                {label: "Τύπος", name: "type"},
                            ]}
                            editEnable={false}
                            deleteEnable={false}
                            searchTableEnable={false}
                            showTableOptionOnSelect={true}
                            key={Math.random()}
                            onChangeSearch={onChangeSubaccount}
                            disabled={!isEmpty(requestData.subAccount)}
                        />
                    </Col>
                    <Col md={1}>
                        {!isEmpty(requestData.subAccount) &&
                            <button style={{position: "absolute", bottom: "16px"}}
                                    className="btn btn-sm"
                                    title="Καθαρισμός υπολογαριασμού"
                                    onClick={() => dispatch(setCalendarReportRequestData({...requestData, subAccount: "", subAccountInput: ""}))}><i className="fas fa-xmark"></i></button>
                        }
                    </Col>
                    <Col md={2}>
                        <Button onClick={() => fetchReport()}>Ανανέωση</Button>
                    </Col>
                </Row>
                {responseData.length > 0 ? (
                    <React.Fragment>
                        <Badge key={"b1"} bg={"primary"} style={{userSelect: "none", cursor: "pointer"}} onClick={() => dispatch(setCalendarReportDisplayData({...displayData, fontSize: displayData.fontSize + 1}))}>+</Badge>
                        <Badge key={"b2"} bg={"primary"} style={{userSelect: "none"}} className={"ml-1"}>Μέγεθος: {displayData.fontSize}</Badge>
                        <Badge key={"b3"} bg={"primary"} style={{userSelect: "none", cursor: "pointer"}} className={"ml-1 mt-2"} onClick={() => dispatch(setCalendarReportDisplayData({...displayData, fontSize: displayData.fontSize - 1}))}>-</Badge>
                        <Badge key={"b4"} bg={"primary"} style={{userSelect: "none", cursor: "pointer"}} className={"ml-4"} onClick={() => handleExcelExport()}>Εξαγωγή XLSX</Badge>
                        <Badge key={"b5"} bg={"primary"} style={{userSelect: "none", cursor: "pointer"}} className={"ml-1 mb-3"} onClick={() => handlePDFExport()}>Εξαγωγή PDF</Badge>
                    </React.Fragment>
                ) : (
                    <h6 key={"text1"} className={"mt-1"}>Δεν βρέθηκαν δεδομένα</h6>
                )}
                {!render && (
                    <h6 key={"text1"} className={"mt-1"}>Η σελίδα φορτώνει, παρακαλώ περιμένετε...</h6>
                )}
                {render && responseData.length > 0 && (
                    <table className={"accountingCalendarTable"} key={"mainTable"}>
                        <thead style={{textAlign: "center", position: "sticky", top: "-1px"}} key={"headers"}>
                            <tr key={"headFirstLine"} style={{backgroundColor: "#f1fdff"}}>
                                <td>Α/Α</td>
                                <td>Ημερομηνία</td>
                                <td>Αρ. Άρθρου</td>
                                <td>Περιγραφή</td>
                                <td>Κωδικός</td>
                                <td>Όνομα</td>
                                <td>Ανάλυση</td>
                                <td>Ποσό</td>
                            </tr>
                        </thead>
                        <tbody style={{textAlign: "center"}} key={"data"}>
                        {responseData.length > 0 && responseData.map((acc, idx) => (
                            <React.Fragment>
                                <tr style={{backgroundColor: "#decae3"}} key={`account-${acc.code}`}>
                                    <td style={{...tdStyle, textAlign: "left", fontWeight: "700"}} colSpan={8}><span className={"ml-2"}>{`${acc.code} ${acc.name}`}</span></td>
                                </tr>
                                {acc.lines.length > 0 && acc.lines.map((line, idx) => (
                                    <tr className={"row-hoverable"} onDoubleClick={() => handleDoubleClick(line.recordId)} key={`accountLine-${acc.code}-${idx}`}>
                                        <td style={tdStyle}>{line.aa}</td>
                                        <td style={tdStyle}>{line.date}</td>
                                        <td style={tdStyle}>{line.number}</td>
                                        <td style={tdStyle}>{line.reason}</td>
                                        <td style={tdStyle}>{acc.code}</td>
                                        <td style={tdStyle}>{acc.name}</td>
                                        <td style={tdStyle}>{acc.subAccount}</td>
                                        <td style={tdStyle}>{line.amount}</td>
                                    </tr>
                                ))}
                                <tr style={{backgroundColor: "#decae3"}} key={`accountLineTotals-${acc.code}`}>
                                    <td style={{...tdStyle, textAlign: "left", fontWeight: "700"}} colSpan={7}><span className={"ml-2"}>{`Σύνολα Λογαριασμού ${acc.code} ${acc.name}`}</span></td>
                                    <td style={{fontWeight: "700"}}>{Number(acc.totalAmount).toFixed(2)}</td>
                                </tr>
                                {idx !== responseData.length - 1 && (
                                    <tr key={`accountEmptyLine-${acc.code}`}>
                                        <td colSpan={8}>&nbsp;</td>
                                    </tr>
                                )}
                            </React.Fragment>
                        ))}
                        </tbody>
                    </table>
                )}
            </div>
        </React.Fragment>
    )
}

export default AccountingReportCalendar;