import React from "react";
const TextArea = (props) => {
  const { label, rows, value, onChange, onBlur, className, placeholder, name, limit, defaultValue, disabled } = props;
  return (
    <>
      <div className={ className ? className : 'mb-3'}>
        { label && label !== '' && <label>{label}</label>}
        <textarea 
          onChange={(e) => onChange && onChange(e)} 
          onBlur={(e) => onBlur && onBlur(e)} 
          className="form-control" 
          placeholder = {placeholder}
          name ={name}
          rows={rows} 
          maxLength={limit}
          value={value}
          defaultValue={defaultValue}
          disabled={disabled}
        />
      </div>
    </>
  );
};
export default TextArea;