import React, {useEffect, useState} from 'react';
import {classicStyleBelowNavbar} from "../Statics";
import SearchTable from "../../../primer/Search-Table";
import {getObjectFromArrayOfObjects} from "../../../../_helpers/helperFunctions";
import {useSelector} from "react-redux";

const SearchWorkPermits = () => {
    const companyInstallations = useSelector((state) => state.COMPANY_DATA.companyInstallations);
    const [installationOptions, setInstallationOptions] = useState([]);

    useEffect(() => {
        if (companyInstallations.length > 0) {
            let options = [];
            companyInstallations.forEach((inst) => {
                options.push({ value: inst.installationMasterId, label: inst.type + " " + inst.address + " " + inst.city });
            })
            setInstallationOptions(options);
        }
    }, [])

    const searchWorkPermitsFilters = {
        filters: [
            {
                name: "installationMasterId",
                label: "Εγκατάσταση",
                type: "dropdown",
                options: installationOptions,
            },
            {
                name: "erganiProtocol",
                label: "Αρ. Πρωτοκόλλου Εργάνης",
                type: "input",
            }
        ],
        actions: [],
    }

    const searchWorkPermitsColumns = [
        {
            name: "Ημερομηνία Υποβολής",
            field: "created_at",
            width: "15%",
            editable: false,
            inputType: {
                type: "text",
            }
        },
        {
            name: "Περιγραφή",
            field: "description",
            width: "35%",
            editable: false,
            inputType: {
                type: "text",
            }
        },
        {
            name: "Εγκατάσταση",
            field: "installationMasterId",
            width: "25%",
            editable: false,
            inputType: {
                type: "text",
            }
        },
        {
            name: "Αρ. Πρωτοκόλλου Εργάνης",
            field: "erganiProtocol",
            width: "25%",
            editable: false,
            inputType: {
                type: "text",
            }
        },
    ]

    const [tableColumns, setTableColumns] = useState(searchWorkPermitsColumns.slice());

    const handleColumnShowHide = (field) => {
        let tempTc = tableColumns.slice();
        let object = getObjectFromArrayOfObjects(tempTc, field, 'field')
        const index = tempTc.findIndex((item) => field === item.field);
        if (object.length > 0) {
            tempTc[index].hiddenColumn = !object['0'].hiddenColumn;
            setTableColumns(tempTc);
        }
    }

    return (
        <div style={classicStyleBelowNavbar}>
            <SearchTable
                tableName={"Work Permits Search"}
                key={"Work Permits Search"}
                hideYearFilter={false}
                allowPagination={true}
                allowToggleColumns={true}
                showDummyData={false}
                columns={tableColumns}
                toggleColumns={handleColumnShowHide}
                allowSelectionCheckbox={false}
                data={[]}
                filtersConfig={searchWorkPermitsFilters}
            />
        </div>
    )
}

export default SearchWorkPermits
