import React, {useEffect, useState} from 'react';
import {Badge, Button, Col, Modal, Row, Spinner} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import EditableTable from "../../../primer/Editable-Table";
import axios from "axios";
import {toast} from "react-toastify";
import {useDispatch, useSelector} from "react-redux";
import moment from "moment";
import Dropdown from "../../../primer/Dropdown";
import DateBox from "../../../primer/DateBox";
import {
    getECTypeOptions,
    getInvoiceTypesOptions,
    getVatExpenseClassificationType
} from "../../../../_helpers/sharedmydata";
import Input from "../../../common/Input";
import Loader from "../../../primer/POPUP-COMPONENTS/Loader/PrimerLoader";
import DateHelper from "../../../primer/DateHelper/DateHelper";
import "./mydata-report.scss";
import {fetchAdvancedTypesBuys, fetchBuysGeneralSettings} from "../../../../_apis/api";
import NewBuyModal from "../../../primer/POPUP-COMPONENTS/NewBuyModal/NewBuyModal";
import {getCategoriesByInvoiceType, isEmpty} from "../../../../_helpers/commonFunctions";
import {
    setBuysMyDataReportRequestData,
    setBuysMyDataReportTotals, setEditBuys
} from "../../../../_reducers/DataPanel/Buys/buysSlice";
import {addTab, updateActiveMainTab} from "../../../../_reducers/TabsSlice";
import Alert from "react-bootstrap/Alert";
import {salesInvoiceTypesData} from "../../../../_data/mydata/salesInvoiceTypes";
import {Popover} from "antd";

const BuysMydataReport = () => {
    const [t] = useTranslation('common');
    const dispatch = useDispatch();
    const TABS_DATA = useSelector((state) => state.TABS_REDUCER);
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const requestData = useSelector((state) => state.BUYS.myDataReportRequestData);
    const totals = useSelector((state) => state.BUYS.myDataReportTotals);

    const [buysReportData, setBuysReportData] = useState([]);
    const [incrKey, setIncrKey] = useState(0);
    const [loading, setLoading] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const invoiceTypes = getInvoiceTypesOptions("all");
    const ecType = getECTypeOptions();
    const vatExpenseTypes = getVatExpenseClassificationType();

    const [classificationsRowData, setClassificationsRowData] = useState({});
    const [showClassificationsModal, setShowClassificationsModal] = useState(false);
    const [classificationsTableData, setClassificationsTableData] = useState([]);
    const [currentMark, setCurrentMark] = useState("");
    const [pageChanged, setPageChanged] = useState(false);
    const [sendClassificationLoading, setSendClassificationLoading] = useState(false);

    const [showNewBuyModal, setShowNewBuyModal] = useState(false);
    const [showCancelExpenseModal, setShowCancelExpenseModal] = useState(false);
    const [transactionMode, setTransactionMode] = useState("");

    useEffect(() => {
        dispatch(fetchBuysGeneralSettings({company: company.id}));
        dispatch(fetchAdvancedTypesBuys({company: company.id}));
    }, [])

    useEffect(() => {
        if (showCancelExpenseModal === false) {
            setTransactionMode("");
        }
    }, [showCancelExpenseModal])

    const handleClassificationClicked = (rowData) => {
        if(rowData?.classifications.length > 0) {
            setClassificationsTableData(rowData.classifications);
            setCurrentMark(rowData.mark ? rowData.mark : "");
        } else {
            setClassificationsTableData([]);
            setCurrentMark("");
            toast.error("Δεν βρέθηκαν χαρακτηρισμοί.");
        }
        setClassificationsRowData(rowData);
        setShowClassificationsModal(true);
    }

    const saveClassifications = () => {
        setSendClassificationLoading(true);
        let expensesClassifications = [];
        for(let line of classificationsTableData) {
            if(line.vatExpenseType && line.vatExpenseType !== "") {
                expensesClassifications.push({
                    "ecls:lineNumber": line.lineNumber,
                    "ecls:expensesClassificationDetailData": [
                        {
                            "ecls:classificationType": line.classificationType,
                            "ecls:classificationCategory": line.classificationCategory,
                            "ecls:amount": line.netPrice,
                        },
                        {
                            "ecls:classificationType": line.vatExpenseType,
                            "ecls:amount": line.netPrice,
                        }
                    ]
                })
            } else {
                expensesClassifications.push({
                    "ecls:lineNumber": line.lineNumber,
                    "ecls:expensesClassificationDetailData": {
                        "ecls:classificationType": line.classificationType,
                        "ecls:classificationCategory": line.classificationCategory,
                        "ecls:amount": Number(Number(line.netPrice) + Number(line.vatAmount)).toFixed(2),
                    }
                })
            }
        }
        const requestToSend = {
            company: company.id,
            "ecls:invoiceMark": currentMark,
            "ecls:invoicesExpensesClassificationDetails": expensesClassifications,
            reportType: "buys",
        }
        axios.post(process.env.REACT_APP_API_URL2 + "/buy/sendClassifications", requestToSend, {
            headers: { 'Content-Type': 'application/json' }
        }).then((res) => {
            setSendClassificationLoading(false);
            console.log(res.data);
            if(res.data.status === "200") {
                toast.success(res.data.message);
                fetchReport();
            } else {
                toast.error(res.data.message);
            }
        }).catch((err) => {
            setSendClassificationLoading(false);
            console.log(err);
        })
        console.log(expensesClassifications);
    }

    const classificationsTableActions = {
        update: (data, id, rowIndex) => {
            let cloneClassifications = classificationsTableData.slice();
            if(cloneClassifications.length >= 0) {
                cloneClassifications = cloneClassifications.map((a) => Object.assign({}, a));
                cloneClassifications[rowIndex] = {...cloneClassifications[rowIndex], ...data};
            }
            setClassificationsTableData(cloneClassifications);
        },
        selected: () => {}
    }

    const handleApplyClassificationToAll = (rowData) => {
        if(!isEmpty(rowData.classificationType) && !isEmpty(rowData.classificationCategory)) {
            let newData = [...classificationsTableData];
            for (let line of newData) {
                line.classificationType = rowData.classificationType;
                line.classificationCategory = rowData.classificationCategory;
                line.vatExpenseType = rowData.vatExpenseType ? rowData.vatExpenseType : "";
            }
            setClassificationsTableData(newData);
        } else {
            toast.error("Επιλέξτε κατηγορία και τύπο.");
        }
    }
    const classifiedOptions = [
        { label: "Εμφάνιση όλων", value: "" },
        { label: "Μόνο χαρακτηρισμένων", value: "true" },
        { label: "Μόνο μη χαρακτηρισμένων", value: "false" }
    ]
    const columns = [
        {
            name: t('SalesMyData.table.date'),
            field: "date",
            width: "7%",
            editable: false,
            hiddenColumn: false,
            inputType: {
                type: "text",
            },
        },
        {
            name: "MARK",
            field: "mark",
            width: "9%",
            editable: false,
            hiddenColumn: false,
            inputType: {
                type: "text",
            },
        },
        {
            name: "Προμηθευτής",
            field: "supplierName",
            width: "12%",
            editable: false,
            hiddenColumn: false,
            inputType: {
                type: "text",
            },
        },
        {
            name: "ΑΦΜ Προμηθευτή",
            field: "vat",
            width: "8%",
            editable: false,
            hiddenColumn: false,
            inputType: {
                type: "text",
            },
        },
        {
            name: t('SalesMyData.table.invoiceType'),
            field: "myDataType",
            width: "12%",
            editable: false,
            hiddenColumn: false,
            inputType: {
                type: "text",
            },
        },
        {
            name: t('SalesMyData.table.number'),
            field: "preNumberAndNumber",
            width: "5%",
            editable: false,
            hiddenColumn: false,
            inputType: {
                type: "text",
            },
        },
        {
            name: t('SalesMyData.table.netValue'),
            field: "totalNetValue",
            width: "5%",
            editable: true,
            disabled: true,
            inputType: {
                type: 'accounting',
                config: {
                    suffix: "€",
                }
            }
        },
        {
            name: t('SalesMyData.table.vatAmount'),
            field: "totalVatValue",
            width: "5%",
            editable: true,
            disabled: true,
            inputType: {
                type: 'accounting',
                config: {
                    suffix: "€",
                }
            }
        },
        {
            name: "Σύνολο φόρων",
            field: "totalTaxes",
            width: "5%",
            editable: false,
            inputType: {
                type: 'accounting',
                config: {
                    suffix: "€",
                }
            }
        },
        {
            name: t('SalesMyData.table.totalValue'),
            field: "totalAmount",
            width: "5%",
            editable: true,
            disabled: true,
            inputType: {
                type: 'accounting',
                config: {
                    suffix: "€",
                }
            }
        },
        {
            name: t('SalesMyData.table.erpStatus'),
            field: "myDataStatus",
            width: "7%",
            editable: false,
            hiddenColumn: false,
            inputType: {
                type: "text",
            },
        },
        {
            name: t('SalesMyData.table.myDataStatus'),
            field: "myDataSentStatus",
            width: "6%",
            editable: false,
            hiddenColumn: false,
            inputType: {
                type: "text",
            },
        },
        {
            name: <Popover placement={"top"} title={<div style={{textAlign: "center"}}>Σημείωση</div>} content={
                <div style={{maxWidth: "220px"}}>
                    Η ένδειξη "Χαρακτηρισμένο" αποθηκεύεται τοπικά, δε συγχρονίζεται με το MyData, με αποτέλεσμα τα έξοδα που
                    έχουν χαρακτηριστεί από εξωτερικό λογισμικό να φαίνονται ως μη χαρακτηρισμένα.
                </div>
            }><div style={{textAlign: "center"}}>Χαρακτηρισμένο <strong>(*)</strong></div></Popover>,
            field: "classified",
            width: "3%",
            editable: false,
            hiddenColumn: false,
            inputType: {
                type: "text",
            },
        },
        {
            name: "Ενέργειες",
            field: "changeClassifications",
            width: "6%",
            editable: true,
            hiddenColumn: false,
            inputType: {
                config: {
                    disabled: false,
                    onClick: handleClassificationClicked,
                    text: "Εμφάνιση Χαρακτηρισμών",
                },
                type: "button-sm"
            },
        },
    ];

    const classificationsColumns = [
        {
            name: "Line Number",
            field: "lineNumber",
            width: "5%",
            editable: false,
            hiddenColumn: false,
            inputType: {
                type: "text",
            },
        },
        {
            name: t('ProductsNewGeneral.table.invoiceType'),
            field: "invoiceType",
            editable: true,
            disabled: true,
            width: "15%",
            inputType: {
                type: 'select',
                options: invoiceTypes,
            }
        },
        {
            name: t('ProductsNewGeneral.table.category'),
            field: "classificationCategory",
            editable: true,
            width: "15%",
            inputType: {
                type: 'select',
                options: getCategoriesByInvoiceType(classificationsRowData?.myDataType, "expense"),
            }
        },
        {
            name: t('ProductsNewGeneral.table.type'),
            field: 'classificationType',
            editable: true,
            width: "15%",
            inputType: {
                type: 'select',
                options: ecType,
            }
        },
        {
            name: "Καθαρή Αξία",
            field: "netPrice",
            hiddenColumn: false,
            width: "10%",
            editable: true,
            disabled: true,
            inputType: {
                type: 'accounting',
                config: {
                    suffix: "€",
                }
            }
        },
        {
            name: "Ποσό Φ.Π.Α.",
            field: "vatAmount",
            hiddenColumn: false,
            width: "10%",
            editable: true,
            disabled: true,
            inputType: {
                type: 'accounting',
                config: {
                    suffix: "€",
                }
            }
        },
        {
            name: "Φ.Π.Α.",
            field: "vatCategory",
            hiddenColumn: false,
            width: "5%",
            editable: false,
            disabled: true,
            inputType: {
                type: 'text',
            }
        },
        {
            name: "Συνολική Αξία",
            field: "totalValue",
            hiddenColumn: false,
            width: "10%",
            editable: false,
            disabled: true,
            inputType: {
                type: 'accounting',
                config: {
                    suffix: "€",
                }
            }
        },
        {
            name: t('ProductsNewGeneral.table.vatExpenseType'),
            field: "vatExpenseType",
            editable: true,
            width: "10%",
            inputType: {
                type: 'select',
                options: vatExpenseTypes,
            }
        },
        {
            name: "Ενέργειες",
            field: "buttonActions",
            editable: true,
            width: "10%",
            inputType: {
                config: {
                    disabled: false,
                    onClick: handleApplyClassificationToAll,
                    text: "Χαρ. όλων"
                },
                type: 'button-sm',
            }
        },
    ];

    const fetchReport = (forceRefresh = false, fromPagination = false) => {
        setLoading(true);
        let cloneReqData = {...requestData};
        cloneReqData.company = company.id;
        cloneReqData.year = String(company.year);
        dispatch(setBuysMyDataReportRequestData({...requestData, page: 1}));
        let reqUrl = "/report/fetch-mydata-report";
        if (forceRefresh) {
            cloneReqData.page = 1;
            reqUrl = "/report/mydata-report";
            setShowLoader(true);
        }
        let invTypes = [];
        if(cloneReqData.invoiceType.length > 0) {
            cloneReqData.invoiceType.map((obj) => {
                invTypes.push(obj.value);
            })
        }
        axios.post(process.env.REACT_APP_API_URL2 + reqUrl, {...cloneReqData, invoiceType: invTypes}, {
            headers: { "Content-Type": "application/json" }
        }).then((res) => {
            setLoading(false);
            setShowLoader(false);
            if(res.data.status === "200") {
                if (res.data.data.data) {
                    let resData = [...res.data.data.data];
                    if(resData.length > 0) {
                        resData.map((row) => {
                            if(row.date) row.date = moment(row.date).format("DD/MM/YYYY");
                            if(row.preNumber && row.preNumber !== "noprefix") {
                                row.preNumberAndNumber = String(row.preNumber + " " + row.number);
                            } else {
                                row.preNumberAndNumber = row.number;
                            }
                            row.vatAmount = row.totalVatValue;
                        })
                    } else {
                        setBuysReportData([]);
                        dispatch(setBuysMyDataReportRequestData({...requestData, page: 1, totalPages: 0}));
                        toast.info("Δεν βρέθηκε καμία αγορά.");
                    }
                    let newRequestData = {...requestData};
                    if(res.data.data.totalPages) newRequestData = {...newRequestData, totalPages: res.data.data.totalPages};
                    if(res.data.totals) dispatch(setBuysMyDataReportTotals(res.data.totals));
                    if(res.data.filters) newRequestData = {...newRequestData, ...res.data.filters};
                    if(res.data.lastUpdate) newRequestData = {...newRequestData, lastUpdate: res.data.lastUpdate};
                    dispatch(setBuysMyDataReportRequestData(newRequestData));
                    setBuysReportData(resData);
                    if (!fromPagination) toast.success("Τα δεδομένα ανανεώθηκαν.");
                } else {
                    setBuysReportData([]);
                    dispatch(setBuysMyDataReportRequestData({...requestData, page: 1, totalPages: 0}));
                    toast.info("Δεν βρέθηκε καμία αγορά.");
                }
            } else {
                setBuysReportData([]);
                let zeroTotals = {...totals};
                for(let k in zeroTotals) {
                    zeroTotals[k] = 0;
                }
                dispatch(setBuysMyDataReportTotals(zeroTotals));
                toast.error(res.data.message);
            }
        }).catch((err) => {
            setLoading(false);
            setShowLoader(false);
            console.log(err);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        })
    }

    useEffect(() => {
        fetchReport(false, true);
    }, [])

    useEffect(() => {
        if (pageChanged) {
            setPageChanged(false);
            fetchReport(false, true);
        }
    }, [pageChanged])

    useEffect(() => {
        setIncrKey(incrKey + 1);
    }, [buysReportData])

    const handlePage = (str) => {
        if(str === "first") {
            dispatch(setBuysMyDataReportRequestData({...requestData, page: 1}));
            setPageChanged(true);
        } else if (str === "previous") {
            dispatch(setBuysMyDataReportRequestData({...requestData, page: requestData.page - 1}));
            setPageChanged(true);
        } else if (str === "next") {
            dispatch(setBuysMyDataReportRequestData({...requestData, page: requestData.page + 1}));
            setPageChanged(true);
        } else if (str === "last") {
            dispatch(setBuysMyDataReportRequestData({...requestData, page: requestData.totalPages}));
            setPageChanged(true);
        }
    }

    const handleFilterChange = (e, type, name) => {
        let cloneReqData = {...requestData};
        if (type === "datebox") {
            cloneReqData = {...cloneReqData, [name] : moment(e).format("DD/MM/YYYY")};
        } else if (type === "msdd") {
            cloneReqData = {...cloneReqData, [name] : e};
        } else if (type === "input") {
            cloneReqData = {...cloneReqData, [name] : e.target.value}
        } else if (type === "dropdown") {
            cloneReqData = {...cloneReqData, [name] : e.value};
        } else if (type === "ac") {
            cloneReqData = {...cloneReqData, [name] : e.row.name};
        }
        dispatch(setBuysMyDataReportRequestData(cloneReqData));
    }

    const formatNumber = (num) => {
        return num?.toFixed(2).replace(".", ",") + " €"
    }

    const handleShowExistingBuy = () => {
        if (classificationsRowData.mark) {
            axios.get(process.env.REACT_APP_API_URL2 + "/buy/get-buy-by-mark", {
                headers: { 'Content-Type': 'application/json' },
                params: {
                    company: company.id,
                    mark: classificationsRowData.mark,
                }
            }).then((res) => {
                if (res.data.status === "200") {
                    setShowClassificationsModal(false);
                    setShowNewBuyModal(false);
                    if (res.data.data) {
                        dispatch(setEditBuys(res.data.data));
                        const idx = TABS_DATA.findIndex((item) => "view-buys" === item);
                        if(idx === -1) {
                            dispatch(addTab("view-buys"));
                            dispatch(updateActiveMainTab(TABS_DATA.length));
                        } else {
                            dispatch(updateActiveMainTab(idx));
                        }
                    } else {
                        toast.error("Σφάλμα κατά την φόρτωση της αγοράς.");
                    }
                } else {
                    toast.error(res.data.message);
                }
            }).catch((err) => {
                console.log(err);
                toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
            })
        } else {
            toast.error("Σφάλμα κατά την εύρεση του ΜΑΡΚ.");
        }
    }

    const handleSendExpenseClassificationWithTransactionMode = () => {
        if (transactionMode === "1" || transactionMode === "2") {
            axios.post(process.env.REACT_APP_API_URL2 + "/buy/sendClassificationsTransactionMode", {reportType: "buys", company: company.id, mark: classificationsRowData.mark, transactionMode: transactionMode}, {
                headers: { 'Content-Type': 'application/json' },
            }).then((res) => {
                setShowCancelExpenseModal(false);
                if (res.data.status === "200") {
                    toast.success("OLA KOMPLE");
                } else {
                    toast.error(res.data.message);
                }
            }).catch((err) => {
                setShowCancelExpenseModal(false);
                console.log(err);
                toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
            })
        }
    }

    const classificationIsASale = () => {
        return salesInvoiceTypesData.find((el) => String(el.id) === String(classificationsRowData.myDataType));
    }

    const handleBuyCorrelation = (mark) => {
        axios.post(process.env.REACT_APP_API_URL2 + "/buy/correlate-buy-from-report", {company: company.id, mark: mark}, {
            headers: { 'Content-Type': 'application/json' },
        }).then((res) => {
            if (res.data.status === "200") {
                toast.success("Η συσχέτιση έγινε επιτυχώς.");
                fetchReport(false, true);

                let clonedData = structuredClone(classificationsRowData);
                delete clonedData.correlatable;
                delete clonedData.correlatable_id;
                clonedData.myDataStatus = "sent";
                setClassificationsRowData(clonedData);
            } else {
                toast.error(res.data.message);
            }
        }).catch((error) => {
            console.log(error);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        })
    }

    return(
        <React.Fragment>
            {showLoader && (<Loader />)}
            <div hidden={showLoader}>
                {((isEmpty(requestData.date_from) || isEmpty(requestData.date_to))
                    || (!isEmpty(requestData.date_from) && !isEmpty(requestData.date_to) && moment(requestData.date_to, "DD/MM/YYYY").diff(moment(requestData.date_from, "DD/MM/YYYY"), "months") >= 1)) && (
                    <Alert variant={"secondary"}>
                        Επιλέγοντας χρονικό περιθώριο μεγαλύτερο του ενός μηνός, υπάρχει πιθανότητα η διαδικασία ανάκτησης των παραστατικών να καθυστερήσει.
                    </Alert>
                )}
                <Row>
                    <label className={"text-muted"}><i>Φίλτρα αναζήτησης MyData</i></label>
                    <Col md={2}>
                        <DateHelper
                            dateFromAndToFunction={(from, to) => dispatch(setBuysMyDataReportRequestData({
                                ...requestData,
                                date_from: from,
                                date_to: to
                            }))}
                        />
                    </Col>
                    <Col md={2}>
                        <DateBox
                            name="date_from"
                            selected={isEmpty(requestData.date_from) ? moment().toDate() : moment(requestData.date_from, "DD/MM/YYYY").toDate()}
                            label={t('SalesMyData.dateFrom')}
                            onChange={(e) => handleFilterChange(e, "datebox", "date_from")}
                            allowOtherYears={true}
                        />
                    </Col>
                    <Col md={2}>
                        <DateBox
                            name="date_to"
                            selected={isEmpty(requestData.date_to) ? moment().toDate() : moment(requestData.date_to, "DD/MM/YYYY").toDate()}
                            label={t('SalesMyData.dateTo')}
                            onChange={(e) => handleFilterChange(e, "datebox", "date_to")}
                            allowOtherYears={true}
                        />
                    </Col>
                    <Col md={3}>
                        {!isEmpty(requestData.lastUpdate) && (
                            <Badge bg={"primary"} className={"mt-3"}>
                                Τελευταία ανανέωση από το My Data: <br/>
                                {moment(requestData.lastUpdate).format("DD/MM/YYYY HH:mm:ss")}
                            </Badge>
                        )}
                    </Col>
                    <Col md={3}>
                        <br/>
                        <Button onClick={() => fetchReport(true)} className={"float-right"} disabled={loading}>Εφαρμογή
                            φίλτρων & αναζήτηση από MyData</Button>
                    </Col>
                </Row>
                <hr/>
                <Row className={"mt-2"}>
                    <label className={"text-muted"}><i>Φίλτρα Αναζήτησης</i></label>
                    <Col md={3}>
                        <Dropdown
                            label={t('SalesMyData.invoiceType')}
                            name="invoiceType"
                            defaultValue={requestData.invoiceType}
                            key={Math.random()}
                            multiSelect={true}
                            enabledValue={true}
                            options={invoiceTypes}
                            onChange={(e) => handleFilterChange(e, "msdd", "invoiceType")}
                        />
                    </Col>
                    <Col md={2}>
                        <Input
                            type={"text"}
                            label={"Αριθμός τιμολογίου από"}
                            name={"number_from"}
                            autoComplete={"off"}
                            value={requestData.number_from}
                            placeholder={"Αριθμός τιμολογίου από"}
                            onChange={(e) => handleFilterChange(e, "input", "number_from")}
                        />
                    </Col>
                    <Col md={2}>
                        <Input
                            type={"text"}
                            label={"Αριθμός τιμολογίου μέχρι"}
                            name={"number_to"}
                            autoComplete={"off"}
                            value={requestData.number_to}
                            placeholder={"Αριθμός τιμολογίου μέχρι"}
                            onChange={(e) => handleFilterChange(e, "input", "number_to")}
                        />
                    </Col>
                    <Col md={2}>
                        <Input
                            label={"ΑΦΜ Προμηθευτή"}
                            name={"supplierVAT"}
                            value={requestData.supplierVAT}
                            onChange={(e) => handleFilterChange(e, "input", "supplierVAT")}
                        />
                    </Col>
                    <Col md={3}>
                        <Dropdown
                            options={classifiedOptions}
                            name={"classified"}
                            label={"Εμφάνιση χαρακτηρισμένων"}
                            defaultValue={classifiedOptions.find((el) => el.value === requestData.classified)}
                            key={Math.random()}
                            onChange={(e) => handleFilterChange(e, "dropdown", "classified")}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={2}>
                        <Input
                            type={"number"}
                            label={"Συνολική αξία από"}
                            name={"totalSum_from"}
                            autoComplete={"off"}
                            value={requestData.totalSum_from}
                            onChange={(e) => handleFilterChange(e, "input", "totalSum_from")}
                        />
                    </Col>
                    <Col md={2}>
                        <Input
                            type={"number"}
                            label={"Συνολική αξία μέχρι"}
                            name={"totalSum_to"}
                            autoComplete={"off"}
                            value={requestData.totalSum_to}
                            onChange={(e) => handleFilterChange(e, "input", "totalSum_to")}
                        />
                    </Col>
                </Row>
                <Row className={"justify-content-end mb-2"}>
                    <Col md={3}>
                        <Button onClick={() => fetchReport()} className={"float-right"} disabled={loading}>Εφαρμογή φίλτρων</Button>
                    </Col>
                </Row>
                {requestData.totalPages > 0 && buysReportData.length > 0 && (
                    <React.Fragment>
                        <hr />
                        <div className={"d-flex justify-content-end mb-2"}>
                            <Button size={"sm"} className={"mr-1"} disabled={requestData.page === 1} onClick={() => handlePage("first")}>{t('SalesMyData.first')}</Button>
                            <Button size={"sm"} className={"mr-1"} disabled={requestData.page === 1} onClick={() => handlePage("previous")}>{t('SalesMyData.previous')}</Button>
                            <Badge style={{fontSize: "12px", lineHeight: "20px"}} className="mr-1" bg="primary">{t('SalesMyData.page')} {requestData.page} / {requestData.totalPages}</Badge>
                            <Button size={"sm"} className={"mr-1"} disabled={requestData.page === requestData.totalPages} onClick={() => handlePage("next")}>{t('SalesMyData.next')}</Button>
                            <Button size={"sm"} className={"mr-1"} disabled={requestData.page === requestData.totalPages} onClick={() => handlePage("last")}>{t('SalesMyData.last')}</Button>
                        </div>
                        <EditableTable
                            key={"MyData Report Buys" + incrKey}
                            tableName="MyData Report Buys"
                            data={buysReportData}
                            allowPagination={false}
                            allowToggleColumns={true}
                            allowInsertRow={false}

                            allowSelectionCheckbox={false}
                            sendSelectedToMyData={false}
                            cancelSelectedToMyData={false}

                            enableNewRowCheckbox={true}
                            allowBulkActions={true}
                            actionButtons={{ print: false, delete: false, export: false, edit: false }}
                            allowActions={false}
                            columns={columns}
                            filtersConfig={["quick-edit", "delete"]}
                            onDoubleClick={(e, row) => handleClassificationClicked(row)}
                        />
                        <Row className="justify-content-end mt-3">
                            <Col md={4} className="d-flex justify-content-end">
                                <table className="table table-bordered mb-3">
                                    <tbody>
                                        <tr>
                                            <td style={{textAlign: "center"}}>{t('SalesMyData.pageTotals')}</td>
                                            <td style={{textAlign: "center"}}>{t('SalesMyData.TotalsERP')}</td>
                                            <td style={{textAlign: "center"}}>{t('SalesMyData.TotalsMyData')}</td>
                                            <td style={{textAlign: "center"}}>{t('SalesMyData.differences')}</td>
                                        </tr>
                                        <tr>
                                            <td className="fw-bold">{t('SalesMyData.totalsCount')}</td>
                                            <td>{totals.totalCount}</td>
                                            <td>{totals.totalCountMyData}</td>
                                            <td>{Math.abs(totals.totalCount - totals.totalCountMyData)}</td>
                                        </tr>
                                        <tr>
                                            <td className="fw-bold">{t('SalesMyData.totalNetValue')}</td>
                                            <td>{formatNumber(totals.totalNetValue)}</td>
                                            <td>{formatNumber(totals.totalNetValueMyData)}</td>
                                            <td>{formatNumber(Math.abs(totals.totalNetValueMyData - totals.totalNetValue))}</td>
                                        </tr>
                                        <tr>
                                            <td className="fw-bold">{t('SalesMyData.totalVatAmount')}</td>
                                            <td>{formatNumber(totals.totalVatValue)}</td>
                                            <td>{formatNumber(totals.totalVatValueMyData)}</td>
                                            <td>{formatNumber(Math.abs(totals.totalVatValueMyData - totals.totalVatValue))}</td>
                                        </tr>
                                        <tr>
                                            <td className="fw-bold">{t('SalesMyData.totalValue')}</td>
                                            <td>{formatNumber(totals.totalValue)}</td>
                                            <td>{formatNumber(totals.totalValueMyData)}</td>
                                            <td>{formatNumber(Math.abs(totals.totalValueMyData - totals.totalValue))}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Col>
                        </Row>
                        <Row className="justify-content-end">
                            <Col md={4} className={"d-flex justify-content-end"}>
                                {Number(totals.totalValue.toFixed(2) - totals.totalValueMyData.toFixed(2)) === 0 ? (
                                    <p style={{ color: 'green' }}><b>{t('SalesMyData.discordOk')}</b></p>
                                ) : (
                                    <p style={{ color: 'red' }}><b>{t('SalesMyData.discord')}</b></p>
                                )}
                            </Col>
                        </Row>
                    </React.Fragment>
                )}
            </div>

            <Modal dialogClassName={"buysModalBig"} show={showClassificationsModal} onHide={() => setShowClassificationsModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('SalesMyData.table.classificationsModalTitle')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <EditableTable
                        key={Math.random()}
                        tableName={"MyData Report Classifications Buys"}
                        data={classificationsTableData}
                        allowActions={false}
                        onUpdate={classificationsTableActions}
                        columns={classificationsColumns}
                        allowBulkActions={true}
                        actionButtons={{ print: false, delete: false, export: false, edit: false }}
                    />
                    {classificationsRowData.correlatable === "true" && (
                        <div>
                            Το παραστατικό μπορεί να συσχετιστεί με αγορά από το ERP. <Button size={"sm"} onClick={() => handleBuyCorrelation(classificationsRowData.mark)}>Συσχέτιση</Button>
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    {classificationIsASale() && false && (
                        <React.Fragment>
                            <Button onClick={() => {
                                setShowClassificationsModal(false);
                                setShowCancelExpenseModal(true);
                                setTransactionMode("1");
                            }}>Απόρριψη Παραστατικού</Button>
                            <Button onClick={() => {
                                setShowClassificationsModal(false);
                                setShowCancelExpenseModal(true);
                                setTransactionMode("2");
                            }}>Απόρριψη Παραστατικού λόγω ασυμφωνίας ποσών</Button>
                        </React.Fragment>
                    )}
                    {!isEmpty(classificationsRowData.mark) && classificationsRowData.myDataStatus === "sent" && (
                        <Button variant={"primary"} onClick={() => handleShowExistingBuy()}>Εμφάνιση αγοράς</Button>
                    )}
                    {classificationsRowData.myDataStatus !== "sent" && classificationIsASale() && (
                        <Button variant="primary" onClick={() => {
                            setShowClassificationsModal(false);
                            setShowNewBuyModal(true);
                        }}>Δημιουργία Αγοράς & Αυτ. Χαρακτηρισμός</Button>
                    )}
                    <Button variant="outline-primary" onClick={() => setShowClassificationsModal(false)}>Κλείσιμο</Button>
                    <Button variant="primary" onClick={() => saveClassifications()}>Αποθήκευση {sendClassificationLoading && <Spinner animation="border" variant="dark" />}</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showCancelExpenseModal} onHide={() => setShowCancelExpenseModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Απόρριψη παραστατικού</Modal.Title>
                </Modal.Header>
                <Modal.Body>Είσαστε σίγουροι ότι θέλετε να απορρίψετε το παραστατικό με ΜΑΡΚ: {classificationsRowData.mark} ;</Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={() => setShowCancelExpenseModal(false)}>Όχι, κλείσιμο</Button>
                    <Button variant="primary" onClick={() => handleSendExpenseClassificationWithTransactionMode()}>Ναι, απόρριψη</Button>
                </Modal.Footer>
            </Modal>

            <NewBuyModal
                show={showNewBuyModal}
                setShow={(bool) => setShowNewBuyModal(bool)}
                classificationsRowData={classificationsRowData}
                refreshFunction={fetchReport}
            />
        </React.Fragment>
    )
}

export default BuysMydataReport
