import React, { useState, useEffect } from "react";
import { Popconfirm } from "antd";
import { getISOStringDateToDate } from "../../../_helpers/commonFunctions";

const AcFilterTable = (props) => {

  const { tableColumns, tableData, excludeColumns, rowActions, className, selectOption } = props;

  const [showNotFound, setNotFound] = useState(false);
  const [selectedRow, setSelectedRow] = useState(0);

  useEffect(() => {
    setTimeout(function(){
      if (!tableData || tableData.length <= 0) {
        setNotFound(true);
      } else {
        //scroll combobox
        const table = document.querySelector('.scrollAdvancedCombobox');
        const tableRect = table?.getBoundingClientRect();
        const windowHeight = window.innerHeight;
        const combobox = document.querySelector('.scrollAdvancedCombobox');
        if(tableRect?.bottom + 80 > windowHeight) {
          //style={{borderBottom: '10px solid #aed1d7'}}
          combobox?.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
        }
        setNotFound(false);
      }
    }, 100)
    return () => {
      setNotFound(false)
    }
  }, [])

  const handleDelete = (e, rowKey, row) => {
    rowActions.delete(e, rowKey, row);
  }

  const selectOnClick = (e, tr) => {
    tr._id && setSelectedRow(tr._id) // set id for table
    tr && selectOption && selectOption.selected(tr) // pass to parent
  }

  return (
      <div>
    <div className={className ? className + ' table-responsive' : 'table-responsive'}>
      <table className="table table-bordered mb-2">
        <thead><tr>
          {
            tableData?.length > 0 && tableColumns.map((col) => (
              !excludeColumns?.includes(col.name) &&
              <th className={col.name.toLowerCase()} style={{textAlign: "center"}} key={ Math.random() }>{ col.label }</th>
            ))
          }
        </tr></thead>
        <tbody>
          {
            tableData?.length > 0 ? tableData.map((tr, tri) => (
              <tr
                key={Math.random()}
                className={selectedRow && tr && (tr._id === selectedRow) ? 'active-row' : 'inactive-row'} style={(tr?.isActive === "inactive") ? {fontStyle: "italic", fontSize: "7px", important: "true"} : {}}
              >
                {
                  tableColumns.map((col) => {
                    return (
                      !excludeColumns?.includes(col.name) && (col.name !== 'actions') ?
                        <td  key={ Math.random()} style={{maxWidth: "350px", overflowWrap: "break-word"}} className={col.name.toLowerCase()} onClick={(e) => selectOnClick(e, tr)} >
                          {(col.name === 'date') ? getISOStringDateToDate(tr[col.name]) : (`${tr?.isActive === "inactive" ? "*" : ""}` + tr[col.name])}
                        </td>
                      : (col.name === 'actions') && <td key={Math.random()} className={col.name.toLowerCase() + " text-center"}><Popconfirm
                      title="Sure to delete?"
                      onConfirm={(e) => handleDelete(e, tri, tr)}
                    >
                      <span title='Delete' className="delbtn text-danger">
                        <i className="fas fa-minus-circle"></i>
                      </span>
                    </Popconfirm></td>
                    )
                  })
                }
              </tr>
            ))
            :
            showNotFound && (<tr className="nodatafound"><td colSpan={tableColumns?.length ? tableColumns.length : 1} className="text-danger">No data found</td></tr>)
          }
        </tbody>
      </table>
    </div>
      </div>
  );
};

export default AcFilterTable;
