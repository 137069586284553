import React, {useEffect, useMemo, useState} from "react";
import {Button, Col, Row} from "react-bootstrap";
import TextArea from "../../../common/TextArea";
import Input from "../../../common/Input";
import DateBox from "../../../primer/DateBox";
import EditableTable from '../../../primer/Editable-Table';
import Dropdown from "../../../primer/Dropdown";
import {toast} from "react-toastify";
import {useDispatch, useSelector} from "react-redux";
import {setSaveEditProductMovements} from "../../../../_reducers/activitySlice";
import {fetchAdvancedTypes, fetchProducts} from "../../../../_apis/api";
import {
  filterNaNValue,
  getDefaultOptionByValue,
  getGreekCurrencyFormat,
  getLastHighestPrefixNumber,
  getModeByInstallation,
  getNumberByInstallation,
  getOptionsByData,
  getPreNumberByInstallation,
  getSelectedInstallation,
  removeDuplicateObjects
} from "../../../../_helpers/commonFunctions";
import {
  setEditProductMovements,
  setProductMovementsEditProduct
} from "../../../../_reducers/DataPanel/Products/productsSlice";
import {
  deleteObjectFromArrayOfObjects,
  getLabelByValue,
  getObjectFromArrayOfObjects
} from "../../../../_helpers/helperFunctions";
import getMyData from "../../../../_helpers/sharedmydata";
import {preNumberGreek} from "../../../../_data/settings/advanced-types-search";
import LogTable from "../../../primer/LogTable";
import {useTranslation} from "react-i18next";
import {
  getDiscountExclusiveVat, getNetValue, getNetValueByTotalValue,
  getSalesTotals, getTaxes, getTotalValue,
  getUnitPrice,
  getVatPercent, getVatValue, salesDuplicateProductCheck
} from "../../../../_helpers/product-sales-calculations";
import axios from "axios";
import {vatCategoryData} from "../../../../_data/mydata/vatCategory";
import {AccountingView} from "../../../primer/AccountingView/AccountingView";

export const EditProductMovementsGeneral = () => {
  const [t] = useTranslation("common");
  const dispatch = useDispatch();
  const ACTIVITY = useSelector( (state) => state.ACTIVITY );
  const PRODUCTS_DATA = useSelector( (state) => state.PRODUCTS_DATA );
  const SETTINGS = useSelector( (state) => state.SETTINGS );
  const company = useSelector(state => state.COMPANY_DATA.company)
  const measurementUnitOptions     = getMyData({type: 'mu', settings: SETTINGS});
  const measurementUnit  = getMyData({type: 'mu'});
  const installations = useSelector((state) => state.COMPANY_DATA.companyInstallations);
  const [incrKey, setIncrKey] = useState(0);

  let possibleInnerMovements = [];
  if(installations.length > 0) {
    installations.map((inst) => {
      possibleInnerMovements.push({
        value: inst.installationMasterId,
        label: inst.type + " " + inst.address + " " + inst.city,
      })
    })
  }

  const productCodeColumns = [
    {
      label: t('ProductsMovementsNewEdit.smallTable.code'),
      name: "code",
    },
    {
      label: t('ProductsMovementsNewEdit.smallTable.productName'),
      name: "name",
    },
    {
      label: t('ProductsMovementsNewEdit.smallTable.alternativeName'),
      name: "alternateName",
    },
  ];

  const productMovementColumns = [
    {
      name: "No",
      field: "no",
      editable: false,
    },
    {
      name: t('ProductsMovementsNewEdit.table.productCode'),
      field: "code",
      editable: true,
      inputType: {
        config: {
          excludeColumns: [],
          columns: productCodeColumns,
          data: PRODUCTS_DATA.products,
          editEnable: false ,
          deleteEnable: false,
          searchTableEnable: false,
          showTableOptionOnSelect: true,
          placeholder: t('ProductsMovementsNewEdit.table.productCodePlaceholder')
        },
        type: 'advanced-combobox',
      }
    },
    {
      name: t('ProductsMovementsNewEdit.table.productName'),
      field: "name",
      editable:true,
      inputType: {
        config: {
          excludeColumns: [],
          columns: productCodeColumns,
          data: PRODUCTS_DATA.products,
          editEnable: false ,
          deleteEnable: false,
          searchTableEnable: false,
          showTableOptionOnSelect: true,
          placeholder: t('ProductsMovementsNewEdit.table.productNamePlaceholder')
        },
        type: 'advanced-combobox',
      }
    },
    {
      name: t('ProductsMovementsNew.table.mu'),
      field: 'muLabel',
      editable: false,
    },
    {
      name: t('ProductsMovementsNew.table.quantity'),
      field: 'quantity',
      editable: true,
      inputType: {
        type: 'accounting',
        config: {
          focused: true,
          suffix: "nosuffix",
          maxLength: 10
        }
      }
    },
    {
      name: t('SalesNew.productTable.vat'),
      field: "vatPercentage",
      inputType: {
        type: 'text',
      }
    },
    {
      name: t('SalesNew.productTable.netValue'),
      field: "netValue",
      editable: true,
      inputType: {
        type: 'accounting',
        config: {
          focused: true,
          suffix: "€",
          maxLength: 7
        }
      }
    },
    {
      name: t('SalesNew.productTable.vatValue'),
      field: "vat",
      editable: true,
      inputType: {
        type: 'accounting',
        config: {
          focused: true,
          suffix: "€",
          maxLength: 7
        }
      }
    },
    {
      name: t('SalesNew.productTable.totalValue'),
      field: "totalValue",
      editable: true,
      inputType: {
        type: 'accounting',
        config: {
          focused: true,
          suffix: "€",
          maxLength: 7
        }
      }
    },
  ];

  const requestData = {
    company:company.id,
    year:company.year.toString(),
  }

  // Options for Document Type Dropdown
  const allDocumentTypes = SETTINGS.settings.advancedTypes;
  const clientMovemnetDT = getObjectFromArrayOfObjects(allDocumentTypes, 'Product Movements', 'section');
  const documentTypesOptions =  getOptionsByData(clientMovemnetDT, '_id', 'name')

  let selectedDocumentTypeData = [];
  let dtInstallationsOptions = [];

  let disabledFields = false;
  if (PRODUCTS_DATA.editProductMovements.documentType === undefined || PRODUCTS_DATA.editProductMovements.documentType === '') {
    disabledFields = true;
  } else {
    let dtData = getObjectFromArrayOfObjects(clientMovemnetDT, PRODUCTS_DATA.editProductMovements.documentType, '_id');
    if (dtData.length > 0) {
      selectedDocumentTypeData  = dtData['0'];
      dtInstallationsOptions = getOptionsByData(selectedDocumentTypeData['documentNumbering'], "installationMasterId", "installation");
      dtInstallationsOptions = removeDuplicateObjects(dtInstallationsOptions, 'value');
    }
  }

  const handleOnChange = (e, type="default", ddname = '') => {

    // Set if editing the form
    if (!ACTIVITY.saveEditProductMovements) {
      dispatch(setSaveEditProductMovements(true));
    }

    let name;
    let value;

    if (type === 'dd' || type === 'ac' ) {
      value = e.value;
      name = ddname;
    } else if (type === 'dp' ) {
      value = e;
      name = ddname;
    } else {
      value = e.target.value;
      name = e.target.name;
    }

    let productData = Object.assign({}, PRODUCTS_DATA);
    let newProductMovementData = {};
    newProductMovementData[name] = value;

    if (name === 'documentType') {
      newProductMovementData.documentTypeName = getLabelByValue(documentTypesOptions, value, 'value');

      let dtData = getObjectFromArrayOfObjects(clientMovemnetDT, value, '_id');
      if (dtData.length > 0) {
        selectedDocumentTypeData  = dtData['0'];
        let selectedInstallation = getSelectedInstallation(selectedDocumentTypeData['documentNumbering']);
        // Selected Installation Fields on Document Type Select
        if (Object.keys(selectedInstallation).length > 0) {
          newProductMovementData.installation = selectedInstallation.installation;
          newProductMovementData.preNumber = selectedInstallation.preNumber;
          // Logic For Higest Number
          let installationNumber = selectedInstallation.number
          let lastHigestNumber = getLastHighestPrefixNumber(PRODUCTS_DATA.productMovements, 'number', selectedInstallation.preNumber);
          if (installationNumber > lastHigestNumber) {
            newProductMovementData.number = installationNumber;
          } else {
            newProductMovementData.number = lastHigestNumber + 1;
          }
          newProductMovementData.installationMode = selectedInstallation.installationMode;
        } else {
          newProductMovementData.installation = "";
          newProductMovementData.preNumber = "";
          newProductMovementData.number = "";
          newProductMovementData.installationMode = "";
        }
      }
    }

    // Update Installation Field Values on Change
    if (name === 'installation') {
      if(productData.editProductMovements?.innerMovement) {
        if(productData.editProductMovements?.innerMovement === value) {
          newProductMovementData.innerMovement = "";
        }
      }
      newProductMovementData.installationName = getLabelByValue(dtInstallationsOptions, value, 'value');
      newProductMovementData.preNumber = getPreNumberByInstallation(selectedDocumentTypeData['documentNumbering'], value , 'installation');
      newProductMovementData.number = getNumberByInstallation(selectedDocumentTypeData['documentNumbering'], value , 'installation');
      // newProductMovementData.number = getLastHighestPrefixNumber(PRODUCTS_DATA.productMovements, 'number', newProductMovementData.preNumber);
      // Logic For Highest Number
      let installationNumber = getNumberByInstallation(selectedDocumentTypeData['documentNumbering'], value , 'installation')
      let lastHigestNumber = getLastHighestPrefixNumber(PRODUCTS_DATA.productMovements, 'number', newProductMovementData.preNumber);
      if (installationNumber > lastHigestNumber) {
        newProductMovementData.number = installationNumber;
      } else {
        newProductMovementData.number = lastHigestNumber + 1;
      }
      newProductMovementData.installationMode = getModeByInstallation(selectedDocumentTypeData['documentNumbering'], value , 'installation');
    }

    // Update Installation Field Values on Change
    if (name === 'preNumber') {
      // Logic For Highest Number
      let installationNumber = getNumberByInstallation(selectedDocumentTypeData['documentNumbering'], value , 'installation')
      let lastHigestNumber = getLastHighestPrefixNumber(PRODUCTS_DATA.productMovements, 'number', newProductMovementData.preNumber);
      if (installationNumber > lastHigestNumber) {
        newProductMovementData.number = installationNumber;
      } else {
        newProductMovementData.number = lastHigestNumber + 1;
      }
    }

    dispatch(setEditProductMovements({...productData.editProductMovements, ...newProductMovementData}));
  }

  useEffect(() => {
    dispatch(fetchProducts(requestData))
    dispatch(fetchAdvancedTypes(requestData))
    // calculate sum of quantity
    const productQuantitySum = getSumOfDataValues(PRODUCTS_DATA.editProductMovements.products, 'quantity');
    dispatch(setEditProductMovements({...PRODUCTS_DATA.editProductMovements, sum: productQuantitySum}));
  }, [dispatch])

  const getSumOfDataValues = (data, field = 'sum') => {
    let sum = 0;
    if (data && data.length > 0) {
      data.forEach(item => {
        sum += +item[field] ?? 0;
      })
    }
    return sum;
  }
  const parentMydataActions = {
    insert: (e, data) => {
      if (!ACTIVITY.saveEditProductMovements) dispatch(setSaveEditProductMovements(true));
      let tempNewProduct = Object.assign({},  PRODUCTS_DATA.productMovementsEditProduct);
      if (e.key !== "Enter") {
        tempNewProduct[data.field] = data.value;

        // special case to populate name
        if (data.field === 'code') {
          if (data.value) {
            const products = getObjectFromArrayOfObjects(PRODUCTS_DATA.products, data.value, data.field);
            if (products.length > 0) {
              tempNewProduct['name'] = products['0'].name;
            }
          }
        }
        // special case to populate code
        if (data.field === 'name') {
          if (data.value) {
            const products = getObjectFromArrayOfObjects(PRODUCTS_DATA.products, data.value, data.field);
            if (products.length > 0) {
              tempNewProduct['code'] = products['0'].code;
            }
          }
        }
        // special case to populate measurement unit
        if (data.field === 'name' || data.field === 'code') {
          if (data.value) {
            const products = getObjectFromArrayOfObjects(PRODUCTS_DATA.products, data.value, data.field);
            if (products.length > 0) {
              tempNewProduct['measurementUnit'] = getLabelByValue(measurementUnit, products['0'].measurementUnit, 'value');
              tempNewProduct['mu'] = products['0'].measurementUnit;
            }
          }
        }
        dispatch(setProductMovementsEditProduct(tempNewProduct)); // Update redux

        // If validated fields done add new row
        if (tempNewProduct.name !=='' && tempNewProduct.code !=='' && tempNewProduct.quantity >= 0) {
           let oldProductMovementsList = PRODUCTS_DATA.editProductMovements.products;
          let updatedNewPmP = Object.assign({},  PRODUCTS_DATA.productMovementsEditProduct);
          updatedNewPmP.no = oldProductMovementsList.length + 1;
          updatedNewPmP.id = oldProductMovementsList.length + 1;
          let selectedProduct = getObjectFromArrayOfObjects(PRODUCTS_DATA.products, tempNewProduct.code, 'code');
          if (selectedProduct.length > 0) {
            updatedNewPmP.pid = selectedProduct['0']._id;
            updatedNewPmP.name = tempNewProduct.name;
            updatedNewPmP.code = tempNewProduct.code;
            updatedNewPmP.measurementUnit = tempNewProduct.measurementUnit;
            updatedNewPmP.mu = tempNewProduct.mu;
            updatedNewPmP.quantity = parseInt(tempNewProduct.quantity);
            let updatedSum = getSumOfDataValues([...oldProductMovementsList, updatedNewPmP], 'quantity')
            dispatch(setEditProductMovements({...PRODUCTS_DATA.editProductMovements, products: [...oldProductMovementsList, updatedNewPmP], sum: updatedSum}));
            dispatch(setProductMovementsEditProduct(PRODUCTS_DATA.productMovementsNewProductInitialValue)); // Reset new category mydata
          }
        }
      } else {
        let updateProductMovementsNewProduct = Object.assign({},  PRODUCTS_DATA.productMovementsEditProduct);
        let addCheck = true;
        let oldProductMovementsList = PRODUCTS_DATA.editProductMovements.products;
        if (data.field === 'add' && addCheck) {
          updateProductMovementsNewProduct.no = oldProductMovementsList.length + 1;
          updateProductMovementsNewProduct.id = oldProductMovementsList.length + 1;
          let selectedProduct = getObjectFromArrayOfObjects(PRODUCTS_DATA.products, updateProductMovementsNewProduct.code, 'code');
          if (selectedProduct.length > 0) {
            updateProductMovementsNewProduct.pid = selectedProduct['0']._id;
            let updatedSum = getSumOfDataValues([...oldProductMovementsList, updateProductMovementsNewProduct], 'quantity')
            dispatch(setEditProductMovements({...PRODUCTS_DATA.editProductMovements, products: [...oldProductMovementsList, updateProductMovementsNewProduct], sum: updatedSum}));
            dispatch(setProductMovementsEditProduct(PRODUCTS_DATA.productMovementsNewProductInitialValue)); // Reset new category mydata
          }
        }
      }
    },
    update: async (data, id) => {
      // Set if editing the form
      if (!ACTIVITY.saveEditProductMovements) {
        dispatch(setSaveEditProductMovements(true));
      }
      // Set Loading true
      let foundProduct;
      let productData = null;
      let plProducts = PRODUCTS_DATA.editProductMovements.products.slice();
      // Quick Edit Product from the sales product table
      if (data.quickEdit) {
        let qeProduct = await getObjectFromArrayOfObjects(plProducts, id, '_id');
        if (qeProduct.length > 0) {
          data.code = qeProduct['0'].code;
        }
        foundProduct = plProducts.find(item => item._id === id);
      } else {
        foundProduct = plProducts.find(item => item.id === id);
      }
      if (data.isPerDiscount && (data.isPerDiscount === "on" || data.isPerDiscount === "true")) {
        data.isPerDiscount = "true";
        data.isDiscountAmount = "false";
      }
      if (data.isDiscountAmount && (data.isDiscountAmount === "on" || data.isDiscountAmount === "true")) {
        data.isPerDiscount = "false";
        data.isDiscountAmount = "true";
      }
      if (data.name && data.name === '') {
        toast.error("Name is mandatory field.");
      } else if (data.code && data.code === '') {
        toast.error("Code is mandatory field.");
      } else {
        if (foundProduct !== undefined) {
          let papiUrl = process.env.REACT_APP_API_URL2 + `/category/get-product-by-code/${encodeURIComponent(foundProduct.code)}`;
          if (data.code && data.code !== '') {
            papiUrl = process.env.REACT_APP_API_URL2 + `/category/get-product-by-code/${encodeURIComponent(data.code)}`;
          } else if (data.name && data.name !== '') {
            papiUrl = process.env.REACT_APP_API_URL2 + `/category/get-product-by-name/${encodeURIComponent(data.name)}`;
          }
          let loadingProduct = true;
          if (loadingProduct) {
            // Get product data from api.
            await axios.get(papiUrl, {params: company})
                .then(res => {
                  if (res.data && res.data.data && (res.data.data?.length > 0) && res.data.status === '200') {
                    productData = res.data.data['0'];
                  }
                }).catch((err) => {
                  console.log(err);
                })
          }
          // Field auto population on change product
          if (((data.code && data.code !== '') || (data.name && data.name !== ''))) {
            // Get Current Product Details with the API if product and client are selected.
            if (productData.code && productData.code !== '') {
              await axios
                  .post(`${process.env.REACT_APP_API_URL2}/category/get-product-code-by-price`, {
                    code: productData.code,
                    company: requestData?.company
                  })
                  .then((res) => {
                    if (res.status === 200) {
                      console.log('Product loading success');
                      sessionStorage.setItem("salesSelectedProductData", JSON.stringify(res.data.data));
                      let plProductData = res.data.data;
                      data._id = plProductData._id;
                      data.code = plProductData?.code;
                      data.vatType = plProductData?.vatIncluded === '' ? 'true' : plProductData?.vatIncluded.toString(); // Vat Type from price table
                      data.discount = plProductData?.discount;
                      data.isPerDiscount = plProductData?.isPerDiscount;
                      data.isDiscountAmount = plProductData?.isDiscountAmount;
                      data.name = plProductData?.name;
                      data.mu = plProductData?.measurementUnit;
                      data.muLabel = getLabelByValue(measurementUnitOptions, plProductData?.measurementUnit.toString(), 'value');
                      data.unitPrice = data.unitPrice === undefined || data.unitPrice === null ? plProductData?.price : data.unitPrice;
                      data.vatId = getDefaultOptionByValue(vatCategoryData, productData?.vat, 'percentage', 'code', 'percentage').label;
                      data.vatPercentage = getVatPercent('Normal VAT', productData.vat, 0);
                      data.vatReason = plProductData?.vatReason;
                      if (plProductData?.vatType === "true") {
                        data.totalValue = filterNaNValue(plProductData?.price);
                      } else {
                        data.unitPrice = filterNaNValue(plProductData?.price); // Price from the price list table
                      }
                    } else {
                      console.log('product details loading fail.');
                    }
                  }).catch((err) => {
                    console.log(err);
                  })
            }
            data.withHoldTax = productData.withholdTax;
            data.otherTax = productData.otherTaxes;
            data.stamp = productData.stamp;
            data.fee = productData.fee;
          }
          // Updated selected product with updated field data.
          foundProduct = {...foundProduct, ...data}
          let discountType = 'euro';
          if (foundProduct.isPerDiscount === 'true') {
            discountType = 'per'
          }
          // Note: For inclusive vat the value is reducing by the vat percentage.
          if (data.unitPrice && data.unitPrice !== '') { // on Unit price field change
            //  foundProduct.unitPrice      = getPriceExclusiveVat(foundProduct.unitPrice, foundProduct?.vatPercentage, foundProduct?.vatType);
          }
          // Note: For inclusive vat the value is reducing by the vat percentage.
          if (data.discount && data.discount !== '') { // on discount field change
            foundProduct.discount = getDiscountExclusiveVat(foundProduct.discount, foundProduct?.vatPercentage, foundProduct?.vatType, discountType);
          }
          // Re Calculate Unit price if net value changed
          if (data.netValue && data.netValue !== '' && foundProduct.isTaxLine !== "true") {
            // If user enters value here, you calculate "Unit Price" automatically (based on previous axiom: "Net Value" has to equal: "Quantity" times "Unit Price" minus "Discount")
            foundProduct.unitPrice = getUnitPrice(data.netValue, foundProduct.quantity, foundProduct.discount, discountType);
          } else {
            if (foundProduct.isTaxLine === "true") {
              foundProduct.netValue = 0;
            } else {
              foundProduct.netValue = getNetValue(foundProduct.unitPrice, foundProduct.quantity, foundProduct.discount, discountType);
            }
          }
          if (foundProduct.isTaxLine === "true") {
            foundProduct.taxes = getNetValue(foundProduct.unitPrice, foundProduct.quantity, foundProduct.discount, discountType);
          } else {
            foundProduct.taxes = getTaxes(foundProduct, SETTINGS);
          }
          // Re Calculate Unit price and net value if total value changed
          if (data.totalValue && data.totalValue !== '') {
            if (foundProduct.isTaxLine === "true") {
              foundProduct.taxes = getNetValueByTotalValue(data.totalValue, foundProduct.vatPercentage, foundProduct.taxes);
              foundProduct.unitPrice = getUnitPrice(foundProduct.taxes, foundProduct.quantity, foundProduct.discount, discountType);
              foundProduct.netValue = 0;
            } else {
              // If user enters value here, you calculate "Unit Price" and "Net Value" automatically (based on axioms: "Net Value" has to equal: "Quantity" times "Unit Price" minus "Discount" and "Total Value" equals: "VAT %" times "Net Value" plus "Net Value")
              foundProduct.netValue = getNetValueByTotalValue(data.totalValue, foundProduct.vatPercentage, foundProduct.taxes);
              foundProduct.unitPrice = getUnitPrice(foundProduct.netValue, foundProduct.quantity, foundProduct.discount, discountType);
            }
          } else {
            foundProduct.totalValue = getTotalValue(foundProduct.netValue, foundProduct.vatPercentage, foundProduct.taxes);
          }
          if (foundProduct.isTaxLine === "true") {
            foundProduct.vat = getVatValue(foundProduct.taxes, foundProduct.vatPercentage);
          } else {
            foundProduct.vat = getVatValue(foundProduct.netValue, foundProduct.vatPercentage);
          }
          const updatedPmProducts = {...plProducts[foundProduct.no - 1], ...foundProduct}
          let filterFields = {
            code: data.code ? data.code : plProducts.code,
            name: data.name ? data.name : plProducts.name,
          }
          let productListData = salesDuplicateProductCheck(plProducts, filterFields, foundProduct.no - 1);
          // Logic to check duplicate product in the table.
          if (productListData && !data.quickEdit) {
            toast.error('Duplicate products not allowed.');
            let productTotals = getSalesTotals(plProducts, SETTINGS);
            dispatch(setEditProductMovements({...PRODUCTS_DATA.editProductMovements, products: plProducts, ...productTotals})); // update data
          } else {
            plProducts[foundProduct.no - 1] = updatedPmProducts;
            let productTotals = getSalesTotals(plProducts, SETTINGS);
            dispatch(setEditProductMovements({...PRODUCTS_DATA.editProductMovements, products: plProducts, ...productTotals})); // update data
          }
        }
      }
    },
    selected: () => {},
    delete: (id) => {
      // Set if editing the form
      if (!ACTIVITY.saveEditProductMovements) {
        dispatch(setSaveEditProductMovements(true));
      }

      let oldProductMovementsList = PRODUCTS_DATA.editProductMovements.products.slice();

      if (id !== '' && Number.isInteger(id)) {
        const editProductMovementsList = deleteObjectFromArrayOfObjects(oldProductMovementsList, id, 'id');
        dispatch(setEditProductMovements({...PRODUCTS_DATA.editProductMovements, products: editProductMovementsList }))
      }

      if (id !== '' && !Number.isInteger(id)) {
        const editProductMovementsList = deleteObjectFromArrayOfObjects(oldProductMovementsList, id, '_id');
        dispatch(setEditProductMovements({...PRODUCTS_DATA.editProductMovements, products: editProductMovementsList }))
      }

    }
  }

  const selectedDocumentType = getDefaultOptionByValue(documentTypesOptions, PRODUCTS_DATA.editProductMovements.documentType, 'value', 'label', 'value');
  const selectDocumentInstallation = getDefaultOptionByValue(dtInstallationsOptions, PRODUCTS_DATA.editProductMovements.installation, "value", "label", "value");
  const selectedPreNumber = getDefaultOptionByValue(preNumberGreek, PRODUCTS_DATA.editProductMovements.preNumber, 'value', 'label', 'value');
  let productMovementsTableData = PRODUCTS_DATA.editProductMovements.products;
  let productTotals = useMemo(() => getSalesTotals(PRODUCTS_DATA.editProductMovements.products, SETTINGS), [PRODUCTS_DATA.editProductMovements.products]);
  let totalQuantity = productTotals.totalQuantity;
  let totalNetValue = productTotals.totalNetValue;
  let totalAmount = productTotals.totalAmount;
  let totalVatAmount = productTotals.totalVatValue;

  useEffect(() => {
    setIncrKey(incrKey + 1);
  }, [productMovementsTableData, PRODUCTS_DATA.products])

  return (
      <React.Fragment>
        <div className="product-form position-relative" autoComplete='off'>
          <AccountingView document={PRODUCTS_DATA.editProductMovements}/>
          {disabledFields && PRODUCTS_DATA.editProductMovements.documentType === "" &&
              <div className="overlay_w">{t('ProductsMovementsNewEdit.message')}</div>}
          <Row>
            <Col sm={12} md={3} className="showWithOverlay">
              <Dropdown
                  label={t('ProductsMovementsNewEdit.documentTypes')}
                  required={true}
                  name="documentType"
                  value={Object.keys(selectedDocumentType).length !== 0 ? selectedDocumentType : ''}
                  enabledValue={true}
                  disabled={true}
                  options={documentTypesOptions}
                  onChange={(e) => handleOnChange(e, 'dd', 'documentType')}
              />
            </Col>
            <Col sm={12} md={9} className="text-right ">
              <Button disabled={true} size="small">{t('ProductsMovementsNewEdit.files')}</Button>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={3}>
              <Dropdown
                  label={t('ProductsMovementsNewEdit.installation')}
                  name="installation"
                  value={Object.keys(selectDocumentInstallation).length !== 0 ? selectDocumentInstallation : ''}
                  enabledValue={true}
                  disabled={true}
                  options={dtInstallationsOptions}
                  onChange={(e) => handleOnChange(e, 'dd', 'installation')}
              />
            </Col>
            <Col sm={12} md={2}>
              <Dropdown
                  label={t('ProductsMovementsNewEdit.preNumber')}
                  name="preNumber"
                  value={selectedPreNumber}
                  enabledValue={true}
                  disabled={true}
                  options={preNumberGreek}
                  onChange={(e) => handleOnChange(e, 'dd', 'preNumber')}
              />
            </Col>
            <Col sm="12" md="3">
              <Input
                  className="mb-3 w-100"
                  label={t('ProductsMovementsNewEdit.number.number')}
                  name="number"
                  charLimit={1000}
                  disabled={true}
                  value={PRODUCTS_DATA.editProductMovements.number}
                  placeholder={t('ProductsMovementsNewEdit.number.placeholder')}
                  onChange={(e) => handleOnChange(e)}
              />
            </Col>
            <Col sm="12" md="4">
              <DateBox
                  classes="w-100 mb-3"
                  label={t('ProductsMovementsNewEdit.date')}
                  name="date"
                  selected={PRODUCTS_DATA.editProductMovements.date ? new Date(PRODUCTS_DATA.editProductMovements.date) : ''}
                  enabledValue={true}
                  onChange={(e) => handleOnChange(e, 'dp', 'date')}
              />
            </Col>
          </Row>
          {(possibleInnerMovements.length > 1 && PRODUCTS_DATA.editProductMovements?.isCensus !== "active") && (
              <Row>
                <Col md={3} className={"mb-3"}>
                  <Dropdown
                      name="innerMovement"
                      className={"mb-0"}
                      label={t('ProductsMovementsNew.innerMovement')}
                      key={Math.random()}
                      defaultValue={possibleInnerMovements?.filter((el) => el.value === PRODUCTS_DATA.editProductMovements.innerMovement)[0]}
                      options={[{
                        label: "Κενό",
                        value: ""
                      }].concat(possibleInnerMovements.filter((el) => el.value !== selectDocumentInstallation.value))}
                      onChange={(e) => handleOnChange(e, 'dd', 'innerMovement')}
                  />
                  <span className={"text-muted"}
                        style={{margin: 0}}>{t('ProductsMovementsNew.innerMovementMessage')}</span>
                </Col>
              </Row>
          )}
          <Row>
            <Col sm="12">
              <Input
                  className="mb-3 w-100"
                  label={t('ProductsMovementsNewEdit.reason.reason')}
                  name="reason"
                  value={PRODUCTS_DATA.editProductMovements.reason}
                  onChange={(e) => handleOnChange(e)}
                  placeholder={t('ProductsMovementsNewEdit.reason.placeholder')}
              />
            </Col>
          </Row>
          <hr className="mb-4"/>
          <Row>
            <div className="et-table-ui">
              {
                  ACTIVITY.saveEditProductMovements &&
                  productMovementsTableData &&
                  productMovementsTableData.length <= 0 &&
                  <div className="text-danger">{t('ProductsMovementsNewEdit.table.required')}</div>
              }
              <EditableTable
                  tableName="Edit Product Movements Products" // Do not change name
                  key={"Edit Product Movements Products" + incrKey}
                  allowActions={true}
                  onUpdate={parentMydataActions}
                  allowInsertRow="true"
                  columns={productMovementColumns}
                  enableNewRowCheckbox={true}
                  data={productMovementsTableData}
              />
            </div>
          </Row>
          <hr/>
          <Row>
            <Col sm="12" md="8"></Col>
            <Col sm="12" md="4" className="d-flex justify-content-end">
              <span className="mr-3 fw-bold">{t('SalesNew.totalsTable.title')}</span>
              <table className="table table-bordered mb-3">
                <tbody>
                <tr>
                  <td className="fw-bold">{t('SalesNew.totalsTable.totalQuantity')}</td>
                  <td>{totalQuantity}</td>
                </tr>
                <tr>
                  <td className="fw-bold">{t('SalesNew.totalsTable.totalNetValue')}</td>
                  <td>{getGreekCurrencyFormat(totalNetValue)}</td>
                </tr>
                <tr>
                  <td className="fw-bold">{t('SalesNew.totalsTable.totalVatAmount')}</td>
                  <td>{getGreekCurrencyFormat(totalVatAmount)}</td>
                </tr>
                <tr>
                  <td className="fw-bold">{t('SalesNew.totalsTable.totalAmount')}</td>
                  <td>{getGreekCurrencyFormat(totalAmount)}</td>
                </tr>
                </tbody>
              </table>
            </Col>
          </Row>
          <Row>
            <div style={{width: "100%"}}>
              <div className="text-muted mb-2"><i>{t('ProductsMovementsNewEdit.movementsNotes')}</i></div>
              <TextArea
                  rows="4"
                  placeholder={t('ProductsMovementsNewEdit.addMovementsNotes')}
                  name="notes"
                  value={PRODUCTS_DATA.editProductMovements.notes}
                  onChange={(e) => handleOnChange(e)}
              />
            </div>
          </Row>
        </div>
      </React.Fragment>
  );
};

export const ProductMovementsEditLog = () => {
  const { t } = useTranslation('common');
  const PRODUCTS_DATA = useSelector((state) => state.PRODUCTS_DATA);

  return (
      <React.Fragment>
        <div className="text-muted mb-2"><i>{t('Logs.productMovementLog')}</i></div>
        <LogTable itemId={PRODUCTS_DATA.editProductMovements._id}/>
      </React.Fragment>
  )
};
