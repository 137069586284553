// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.showassetsbtn {
  font-size: 12px !important;
  margin-top: 14px;
  padding: 12px 10px; }

.productAssetsfileinput {
  max-width: 100px;
  height: auto; }

.productAssetsfileinput::before {
  max-width: 100px;
  font-weight: normal; }

.product-form .overlay_w {
  background: rgba(255, 255, 255, 0.7);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 3;
  cursor: pointer;
  font-size: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
  padding-top: 200px;
  color: #76AEB7; }

.product-form .showWithOverlay {
  z-index: 4;
  position: relative; }

.product-form .active-user-alert {
  z-index: 5;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  /* Old versions of Firefox */
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
  pointer-events: none; }
`, "",{"version":3,"sources":["webpack://./src/_components/layouts/data-panel/ProductsNew/index.scss"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B,gBAAgB;EAChB,kBAAkB,EAAA;;AAGpB;EACE,gBAAgB;EAChB,YAAY,EAAA;;AAGd;EACE,gBAAgB;EAChB,mBAAmB,EAAA;;AAGrB;EAEI,oCAAoC;EACpC,kBAAkB;EAClB,MAAM;EACN,QAAQ;EACR,OAAO;EACP,SAAS;EACT,UAAU;EACV,eAAe;EACf,eAAe;EACf,kBAAiB;EACjB,aAAa;EACb,uBAAuB;EACvB,kBAAkB;EAClB,cAAc,EAAA;;AAflB;EAkBI,UAAU;EACV,kBAAkB,EAAA;;AAnBtB;EAuBI,UAAU;EACV,2BAA2B;EAAE,eAAA;EAC7B,yBAAyB;EAAE,WAAA;EACD,mBAAA;EACF,4BAAA;EACD,2BAAA;EACvB,iBAAiB;EAAE,iFAAA;EACnB,oBAAoB,EAAA","sourcesContent":[".showassetsbtn {\n  font-size: 12px !important;\n  margin-top: 14px;\n  padding: 12px 10px;\n}\n\n.productAssetsfileinput {\n  max-width: 100px;\n  height: auto;\n}\n\n.productAssetsfileinput::before {\n  max-width: 100px;\n  font-weight: normal;\n}\n\n.product-form {\n  .overlay_w {\n    background: rgba(255, 255, 255, 0.7);\n    position: absolute;\n    top: 0;\n    right: 0;\n    left: 0;\n    bottom: 0;\n    z-index: 3;\n    cursor: pointer;\n    font-size: 20px;\n    text-align:center;\n    display: flex;\n    justify-content: center;\n    padding-top: 200px;\n    color: #76AEB7; \n  }\n  .showWithOverlay {\n    z-index: 4;\n    position: relative;\n  }\n\n  .active-user-alert {\n    z-index: 5;\n    -webkit-touch-callout: none; /* iOS Safari */\n    -webkit-user-select: none; /* Safari */\n    -khtml-user-select: none; /* Konqueror HTML */\n    -moz-user-select: none; /* Old versions of Firefox */\n    -ms-user-select: none; /* Internet Explorer/Edge */\n    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */\n    pointer-events: none;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
