import React from 'react';

function SalesClientPaymentGraphicReport() {
  return (
    <div>
      There is no details for this page.
    </div>
  )
}

export default SalesClientPaymentGraphicReport;