import React, { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import axios from "axios";
import {checkPermission} from "../../../../_helpers/commonFunctions";
import {useTranslation} from "react-i18next";
import {Button, Col, Row} from "react-bootstrap";

export const SettingsOfBackup = () => {
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const [consoleMSG, setConsoleMSG] = useState([]);
    const [btnBackupDisabled, setBtnBackupDisabled] = useState(false);
    const [btnExportCompanyDisabled, setBtnExportCompanyDisabled] = useState(false);
    const [btnExportAccTemplateDisabled, setBtnExportAccTemplateDisabled] = useState(false);
    const permissionsData = JSON.parse(localStorage.getItem("permissions"));
    const [t] = useTranslation("common");

    const backupClicked = () => {
        setConsoleMSG([t("SettingsBackup.creatingBackup")]);
        setBtnBackupDisabled(true);
        toast.info(t("SettingsBackup.creatingBackupToast"))
        let postData = {
            company: company.id,
            year: company.year,
        };
        axios.post(process.env.REACT_APP_API_URL2 + `/backup/backup`, postData, {
                headers: { "Content-Type": "application/json" },
        })
        .then((response) => {
            function download(content, fileName, contentType) {
                let a = document.createElement("a");
                let file = new Blob([content], {type: contentType});
                a.href = URL.createObjectURL(file);
                a.download = fileName;
                a.click();
            }
            toast.success(t("SettingsBackup.backupCreatedToast"))
            setConsoleMSG(consoleMSG => [...consoleMSG].concat(response.data.forConsole));
            setConsoleMSG(consoleMSG => [...consoleMSG, t("SettingsBackup.backupCreated")]);
            download(response.data.data, 'backup.json', 'text/plain');
            setBtnBackupDisabled(false);
        })
        .catch((error) => {
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
            console.error(error);
        })
    }
    const exportCompanyAsTemplateClicked = () => {
        setConsoleMSG([t("SettingsBackup.creatingCompanyTemplate")]);
        setBtnExportCompanyDisabled(true);
        toast.info(t("SettingsBackup.creatingCompanyTemplateToast"))
        let postData = {
            company: company.id,
            year: company.year,
        }
        axios.post(process.env.REACT_APP_API_URL2 + `/backup/exportCompanyAsTemplate`, postData, {
            headers: { "Content-Type": "application/json" },
        })
        .then((response) => {
            function download(content, fileName, contentType) {
                let a = document.createElement("a");
                let file = new Blob([content], {type: contentType});
                a.href = URL.createObjectURL(file);
                a.download = fileName;
                a.click();
            }
            toast.success(t("SettingsBackup.companyTemplateCreatedToast"));
            setConsoleMSG(consoleMSG => [...consoleMSG].concat(response.data.forConsole));
            setConsoleMSG(consoleMSG => [...consoleMSG, t("SettingsBackup.companyTemplateCreated")]);
            download(response.data.data, 'company_template.json', 'text/plain');
            setBtnExportCompanyDisabled(false);
        })
        .catch((error) => {
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
            console.error(error);
        })
    }
    const exportCompanyAccounting = () => {
        setConsoleMSG(["Γίνεται δημιουργία προτύπου λογιστικής..."]);
        setBtnExportAccTemplateDisabled(true);
        toast.info("Γίνεται δημιουργία προτύπου λογιστικής...")
        axios.post(process.env.REACT_APP_API_URL2 + `/backup/exportCompanyAccounting`, {company: company.id}, {
            headers: { "Content-Type": "application/json" },
        }).then((response) => {
            setBtnExportAccTemplateDisabled(false);
            function download(content, fileName, contentType) {
                let a = document.createElement("a");
                let file = new Blob([content], {type: contentType});
                a.href = URL.createObjectURL(file);
                a.download = fileName;
                a.click();
            }
            toast.success("Το πρότυπο είναι έτοιμο και θα κατέβει σύντομα.");
            setConsoleMSG(consoleMSG => [...consoleMSG].concat(response.data.forConsole));
            setConsoleMSG(consoleMSG => [...consoleMSG, "Το πρότυπο είναι έτοιμο."]);
            download(response.data.data, 'comp_acc_template.json', 'text/plain');
        }).catch((error) => {
            setBtnExportAccTemplateDisabled(false);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
            console.error(error);
        })
    }

    return (
        <React.Fragment>
            { checkPermission("settings-backuprestore/backup-read", permissionsData) &&
                <Row className={"mb-2"}>
                    <Col md={3}>
                        <Button onClick={() => backupClicked()} disabled={btnBackupDisabled}>{t("SettingsBackup.buttonBackup")}</Button>
                    </Col>
                    <Col md={3}>
                        <Button onClick={() => exportCompanyAsTemplateClicked()} disabled={btnExportCompanyDisabled}>{t("SettingsBackup.buttonCompanyTemplate")}</Button>
                    </Col>
                    <Col md={3}>
                        <Button onClick={() => exportCompanyAccounting()} disabled={btnExportAccTemplateDisabled}>Δημιουργία προτύπου λογιστικής</Button>
                    </Col>
                </Row>
            }
            <Row>
                <Col md={12}>
                    <p>Console</p>
                    <div className="bg-light w-100 border p-3">
                        {consoleMSG.map(e =>
                        <p> { e } </p>
                        )}
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    )
}