import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import EditableTable from '../../../../primer/Editable-Table';
import {Col} from 'react-bootstrap';
import {
    getDifficultyOptions, getInstallationsTypes,
    getStockholderPositions, isEmpty,
    resetTableItemsId
} from '../../../../../_helpers/commonFunctions';
import {setSaveEditClient} from "../../../../../_reducers/activitySlice";
import {
    setClientEmronStockholders,
    setEditClient,
    setSelectedEmronPaymentsFilters,
    resetEditClientEmronStockholder,
} from '../../../../../_reducers/DataPanel/Clients/clientSlice';
import {useTranslation} from "react-i18next";
import {
    deleteObjectFromArrayOfObjects,
    getIndexByArrayValue,
    getLabelByValue
} from "../../../../../_helpers/helperFunctions";
import AccountingBox from "../../../../primer/AccountingBox";
import Button from "react-bootstrap/Button";
import {addTab, updateActiveMainTab} from "../../../../../_reducers/TabsSlice";
import {toast} from "react-toastify";
import axios from "axios";

const EditClientEmron = () => {
    const [t] = useTranslation('common');
    const dispatch = useDispatch();
    const CLIENTS_DATA = useSelector((state) => state.CLIENTS_DATA);
    const ACTIVITY = useSelector((state) => state.ACTIVITY);
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const TABS_DATA = useSelector((state) => state.TABS_REDUCER);
    const [productListData, setProductListData] = useState([]);

    let clientInstallationListData = CLIENTS_DATA.editClient?.installations?.slice();
    if (clientInstallationListData?.length > 0) {
        clientInstallationListData = clientInstallationListData.map(installation => {
            const modifiedInstallation = {...installation}; // Create a new object with the same properties
            modifiedInstallation.type = getLabelByValue(getInstallationsTypes(), installation.type, 'value');
            modifiedInstallation.address = modifiedInstallation?.address + ", " + modifiedInstallation?.city + ", " + modifiedInstallation?.postalCode;
            return modifiedInstallation;
        });
    }

    useEffect(() => {
        onProductSearch(null, null, setProductListData);
    }, [])

    const onProductSearch = (cellName, keyword, setData) => {
        let reqData = {
            company: company.id,
        }
        if (keyword) reqData.keyword = keyword;
        axios.get(`${process.env.REACT_APP_API_URL2}/category/get-product-list`, {
            headers: { 'Content-Type': 'application/json' },
            params: reqData
        }).then((res) => {
            if (res.data.status === "200") {
                setData(res.data.data);
            } else {
                setData([]);
            }
        }).catch((err) => {
            console.log(err);
        })
    }

    const passwordsColumns = [
        {
            name: "No",
            field: "no",
            editable: false,
        },
        {
            name: t('ClientsNewEmronEdit.table1.type'),
            field: "type",
            editable: true,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t('ClientsNewEmronEdit.table1.username'),
            field: "username",
            editable: true,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t('ClientsNewEmronEdit.table1.password'),
            field: "password",
            editable: true,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t('ClientsNewEmronEdit.table1.notes'),
            field: "notes",
            editable: true,
            inputType: {
                type: 'text',
            }
        },
    ]
    const stackholdersColumns = [
        {
            name: "No",
            field: "no",
            editable: false,
        },
        {
            name: t('ClientsNewEmronEdit.table2.name'),
            field: "name",
            editable: true,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t('ClientsNewEmronEdit.table2.surname'),
            field: "surname",
            editable: true,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t('ClientsNewEmronEdit.table2.vat'),
            field: "vat",
            editable: true,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t('ClientsNewEmronEdit.table2.position'),
            field: "position",
            width: '120px',
            editable: true,
            inputType: {
                options: getStockholderPositions(),
                type: 'dropdown',
            }
        },
        {
            name: t('ClientsNewEmronEdit.table2.percentage'),
            field: "percentage",
            editable: true,
            inputType: {
                type: 'accounting',
            }
        },
        {
            name: t('ClientsNewEmronEdit.table2.stockValue'),
            field: "stockValue",
            editable: true,
            disabled: true,
            inputType: {
                type: 'accounting',
                config: {
                    focused: true,
                    suffix: "€",
                    maxLength: 7 // Given in the wireframe
                }
            }
        },
        {
            name: t('ClientsNewEmronEdit.table2.dateFrom'),
            field: "dateFrom",
            editable: true,
            inputType: {
                type: 'datebox',
                allowOtherYears: "true"
            }
        },
        {
            name: t('ClientsNewEmronEdit.table2.dateTo'),
            field: "dateTo",
            editable: true,
            inputType: {
                type: 'datebox',
                allowOtherYears: "true"
            }
        },
    ];
    const clientInstallationsDropdownColumns = [
        {
            label: t('SalesNew.installationTable.type'),
            name: "type",
        },
        {
            label: t('SalesNew.installationTable.address'),
            name: "address",
        }
    ];
    const productCodeColumns = [
        {
            label: t('SalesNewView.productCodeTable.code'),
            name: "code",
        },
        {
            label: t('SalesNewView.productCodeTable.productName'),
            name: "name",
        },
        {
            label: t('SalesNewView.productCodeTable.alternativeName'),
            name: "alternateName",
        },
    ];
    const emronPaymentsColumns = [
        {
            name: "No",
            field: "no",
            width: "2%",
            editable: false,
        },
        {
            name: "Εγκατάσταση",
            width: "15%",
            field: "installation",
            editable: true,
            inputType: {
                config: {
                    excludeColumns: [],
                    columns: clientInstallationsDropdownColumns,
                    data: clientInstallationListData,
                    editEnable: false,
                    deleteEnable: false,
                    searchTableEnable: false,
                    showTableOptionOnSelect: true,
                    placeholder: t("Placeholders.selectInstallation")
                },
                type: 'advanced-combobox',
            }
        },
        {
            name: "Μήνας Από",
            field: "month",
            width: "8%",
            editable: true,
            inputType: {
                type: 'monthDatebox',
            }
        },
        {
            name: "Μήνας Μέχρι",
            field: "monthTo",
            width: "8%",
            editable: true,
            inputType: {
                type: 'monthDatebox',
            }
        },
        {
            name: "Καθαρή Αξία",
            field: "amount",
            width: "8%",
            editable: true,
            inputType: {
                type: 'accounting',
                config: {
                    focused: true,
                    suffix: "€",
                    maxLength: 7 // Given in the wireframe
                }
            }
        },
        {
            name: "Σημειώσεις",
            field: "notes",
            width: '10%',
            editable: true,
            inputType: {
                type: 'textarea'
            }
        },
        {
            name: "Δυσκολία",
            field: "difficulty",
            width: "9%",
            editable: true,
            inputType: {
                options: getDifficultyOptions(),
                type: 'dropdown',
            }
        },
        {
            name: "Σημειώσεις Δυσκολίας",
            field: "difficultyNotes",
            width: "10%",
            editable: true,
            inputType: {
                type: 'text',
            }
        },
        {
            name: "Αποδοχή πληρωμών μέσω κάρτας",
            field: "acceptCardPayments",
            width: "5%",
            editable: true,
            inputType: {
                type: "checkbox"
            }
        },
        {
            name: "Είδος",
            field: "productName",
            width: "25%",
            editable: true,
            inputType: {
                config: {
                    excludeColumns: [],
                    columns: productCodeColumns,
                    data: productListData,
                    editEnable: false,
                    deleteEnable: false,
                    searchTableEnable: false,
                    showTableOptionOnSelect: true,
                    placeholder: "Επιλέξτε είδος",
                    onChangeSearchCell: onProductSearch,
                },
                type: 'advanced-combobox',
            }
        }
    ];
    const handleOnChange = (e) => {
        // Set if editing the form
        if (!ACTIVITY.saveEditClient) dispatch(setSaveEditClient(true));

        let value = e.target.value;
        dispatch(setEditClient({...CLIENTS_DATA.editClient, capital: value}));
    }
    const handlePasswordsChanges = {
        insert: (e, data) => {
            // Set if editing the form
            if (!ACTIVITY.saveEditClient) dispatch(setSaveEditClient(true));

            let tempEmronPassword = Object.assign({}, CLIENTS_DATA.editClientEmronPassword);
            tempEmronPassword[data.field] = data.value;
            if (tempEmronPassword && (tempEmronPassword.type || tempEmronPassword.username || tempEmronPassword.password || tempEmronPassword.notes)) {
                let updatedEmronPasswords = Object.assign({}, tempEmronPassword);
                let oldEmronPasswords = CLIENTS_DATA.editClient.emronPasswords;
                updatedEmronPasswords.no = oldEmronPasswords.length + 1;
                updatedEmronPasswords.id = oldEmronPasswords.length + 1;

                dispatch(setEditClient({...CLIENTS_DATA.editClient, emronPasswords: [...oldEmronPasswords, updatedEmronPasswords]}));
            }
        },
        update: async (data, id) => {
            // Set if editing the form
            if (!ACTIVITY.saveEditClient) dispatch(setSaveEditClient(true));

            let emronPasswords = CLIENTS_DATA.editClient.emronPasswords.slice();
            await Promise.all(emronPasswords.map((password, index) => {
                if (password.id === id) {
                    emronPasswords[index] = {...password, ...data};
                }
                return password;
            }))
            dispatch(setEditClient({...CLIENTS_DATA.editClient, emronPasswords: emronPasswords}));
        },
        selected: () => {
        },
        delete: (id) => {
            // Set if editing the form
            if (!ACTIVITY.saveEditClient) dispatch(setSaveEditClient(true));

            let emronPasswords = CLIENTS_DATA.editClient.emronPasswords.slice();
            if (id) {
                emronPasswords = deleteObjectFromArrayOfObjects(emronPasswords, id, 'id');
                emronPasswords = resetTableItemsId(emronPasswords);
                dispatch(setEditClient({...CLIENTS_DATA.editClient, emronPasswords: emronPasswords}));
            }
        }
    }

    const handleStockholdersChanges = {
        insert: (e, data) => {
            // Set if editing the form
            if (!ACTIVITY.saveEditClient) dispatch(setSaveEditClient(true));

            if (data.field === "percentage" && Number(data.value) > 100) {
                toast.error("Percentage must be below 100");
            } else {
                let tempEmronStockholder = Object.assign({}, CLIENTS_DATA.editClientEmronStockholder);
                tempEmronStockholder[data.field] = data.value;
                if (tempEmronStockholder && (
                    tempEmronStockholder.name !== '' ||
                    tempEmronStockholder.surname !== '' ||
                    tempEmronStockholder.vat !== '' ||
                    tempEmronStockholder.position !== '' ||
                    tempEmronStockholder.percentage !== '' || tempEmronStockholder.stockValue !== '' || tempEmronStockholder.dateFrom !== '' || tempEmronStockholder.dateTo !== '')) {
                    let updatedEmronStockholders = Object.assign({}, tempEmronStockholder);
                    let oldEmronStockholders = CLIENTS_DATA.editClient.stockholders;
                    updatedEmronStockholders.no = oldEmronStockholders.length + 1;
                    updatedEmronStockholders.id = oldEmronStockholders.length + 1;
                    if (data.field === "percentage") {
                        updatedEmronStockholders.stockValue = CLIENTS_DATA.editClient.capital * Number(data?.value) / 100;
                    }
                    dispatch(resetEditClientEmronStockholder());
                    dispatch(setEditClient({...CLIENTS_DATA.editClient, stockholders: [...oldEmronStockholders, updatedEmronStockholders]}));
                }
            }
        },
        update: (data, id) => {
            // Set if editing the form
            if (!ACTIVITY.saveEditClient) dispatch(setSaveEditClient(true));

            if (data?.percentage && Number(data.percentage) > 100) {
                toast.error("Percentage must be below 100");
            } else {
                let emronStockholders = CLIENTS_DATA.editClient.stockholders.slice();
                if (emronStockholders.find(item => item.id === id) !== undefined) {
                    emronStockholders[id - 1] = {...emronStockholders[id - 1], ...data};
                    if (data?.percentage) {
                        emronStockholders[id - 1].stockValue = CLIENTS_DATA.editClient.capital * Number(data?.percentage) / 100;
                    }
                    dispatch(setClientEmronStockholders(emronStockholders)); // update data
                    console.log(emronStockholders)
                    dispatch(setEditClient({...CLIENTS_DATA.editClient, stockholders: emronStockholders}))
                }
            }
        },
        selected: () => {},
        delete: (id) => {
            // Set if editing the form
            if (!ACTIVITY.saveEditClient) dispatch(setSaveEditClient(true));

            let emronStockholders = CLIENTS_DATA.editClient.stockholders.slice();
            if (id) {
                emronStockholders = deleteObjectFromArrayOfObjects(emronStockholders, id, 'id');
                console.log(emronStockholders)
                emronStockholders = resetTableItemsId(emronStockholders);
                dispatch(setEditClient({...CLIENTS_DATA.editClient, stockholders: emronStockholders}));
            }
        }
    }

    const handleEmronPaymentsChanges = {
        insert: (e, data) => {
            // Set if editing the form
            if (!ACTIVITY.saveEditClient) dispatch(setSaveEditClient(true));

            let tempEmronPayment = Object.assign({}, CLIENTS_DATA.newEmronPayment);
            if (data.field === "installation") {
                tempEmronPayment["installation"] = e.row.address;
                tempEmronPayment["installationType"] = e.row.type;
            } else {
                tempEmronPayment[data.field] = data.value;
            }
            if (tempEmronPayment && (tempEmronPayment.installationType || tempEmronPayment.amount || tempEmronPayment.month || tempEmronPayment.notes)) {
                let updatedEmronPayment = Object.assign({}, tempEmronPayment);
                let oldEmronPayments = CLIENTS_DATA.editClient.emronPayments;
                updatedEmronPayment.no = oldEmronPayments.length + 1;
                updatedEmronPayment.id = oldEmronPayments.length + 1;
                updatedEmronPayment.clientName = CLIENTS_DATA.editClient.name;
                dispatch(setEditClient({
                    ...CLIENTS_DATA.editClient,
                    emronPayments: [...oldEmronPayments, updatedEmronPayment]
                }));
            }
        },
        update: (data, id, rowIndex) => {
            // Set if editing the form
            if (!ACTIVITY.saveEditClient) dispatch(setSaveEditClient(true));

            let emronPayments = structuredClone(CLIENTS_DATA.editClient.emronPayments);
            if (data?.installation) {
                emronPayments[rowIndex].installation = data.installation.row.address;
                emronPayments[rowIndex].installationType = data.installation.row.type;
            } else if (data?.productName) {
                emronPayments[rowIndex].productName = data.productName.row.name;
                emronPayments[rowIndex].productCode = data.productName.row.code;
            } else {
                emronPayments[rowIndex] = {...emronPayments[rowIndex], ...data};
            }
            dispatch(setEditClient({...CLIENTS_DATA.editClient, emronPayments: emronPayments}));
        },
        selected: () => {},
        delete: (id, rowIndex) => {
            if (!ACTIVITY.saveEditClient) dispatch(setSaveEditClient(true));
            if (CLIENTS_DATA.editClient.emronPayments[rowIndex] && isEmpty(CLIENTS_DATA.editClient.emronPayments[rowIndex].subscription_id)) {
                let cloneEmronPayments = structuredClone(CLIENTS_DATA.editClient.emronPayments);
                cloneEmronPayments.splice(rowIndex, 1);
                dispatch(setEditClient({...CLIENTS_DATA.editClient, emronPayments: cloneEmronPayments}));
            } else {
                toast.error("Η μηνιαία πληρωμή δεν γίνεται να διαγραφεί επειδή είναι συνδεδεμένη με συνδρομή.");
            }
        }
    }
    const headerButtonOnClick = () => {
        let emronPaymentsRequestData = {
            clientName: CLIENTS_DATA.editClient.name,
        }
        dispatch(setSelectedEmronPaymentsFilters(emronPaymentsRequestData));
        const id = "emron-payments";
        if (!TABS_DATA.includes(id)) {
            dispatch(addTab(id));
            dispatch(updateActiveMainTab(TABS_DATA.length));
        } else {
            dispatch(updateActiveMainTab(getIndexByArrayValue(TABS_DATA, id)));
        }
    }

    const [incrKey, setIncrKey] = useState(0);
    useEffect(() => {
        setIncrKey(incrKey + 1);
    }, [CLIENTS_DATA.editClient.emronPasswords, CLIENTS_DATA.editClient.stockholders, CLIENTS_DATA.editClient.emronPayments])

    return (
        <div className="new-client-emron">
            <p className="text-muted"><i>{t('ClientsNewEmronEdit.password')}</i></p>
            <EditableTable
                tableName="Emron Passwords"
                key={"Emron Passwords" + incrKey}
                allowActions={true}
                allowToggleColumns={true}
                columns={passwordsColumns}
                data={CLIENTS_DATA.editClient.emronPasswords}
                allowInsertRow={true}
                enableNewRowCheckbox={true}
                onUpdate={handlePasswordsChanges}
            />
            <br/>
            <p className="text-muted"><i>{t('ClientsNewEmronEdit.stockholders')}</i></p>
            <Col sm='12' md="3">
                <div className="mb-3">
                    <label className="" htmlFor="capital">{t('ClientsNewEmronEdit.capital')}</label>
                    <AccountingBox
                        name="capital"
                        className="mb-3 form-control"
                        placeholder={t('ProductsNewGeneralEdit.preferredPrice.placeholder')}
                        defaultValue={CLIENTS_DATA.editClient?.capital}
                        onChange={(e) => handleOnChange(e)}
                    />
                </div>
            </Col>
            <EditableTable
                tableName="Emron Stockholders"
                key={"Emron Stockholders" + incrKey}
                allowActions={true}
                allowToggleColumns={true}
                columns={stackholdersColumns}
                data={CLIENTS_DATA.editClient.stockholders}
                allowInsertRow={true}
                enableNewRowCheckbox={true}
                onUpdate={handleStockholdersChanges}
            />
            <br/>
            <p className="text-muted">
                <i>Μηνιαίες Χρεώσεις</i>
                <Button variant="primary" style={{float: "right", marginBottom: "10px"}} onClick={() => headerButtonOnClick()}>Μετάβαση στην Αναφορά Μηνιαίων Χρεώσεων</Button>
            </p>
            <EditableTable
                tableName="Emron Payments"
                key={Math.random()}
                allowActions={true}
                allowToggleColumns={true}
                columns={emronPaymentsColumns}
                data={CLIENTS_DATA.editClient.emronPayments}
                allowInsertRow={true}
                enableNewRowCheckbox={true}
                onUpdate={handleEmronPaymentsChanges}
            />
        </div>
    )
}

export default EditClientEmron;
