import React, { useEffect, useState } from "react";
import Input from "../../../common/Input";
import {Button, Row, Col, Modal} from "react-bootstrap";
import { useDispatch, useSelector} from "react-redux";
import { setPrimerApiData } from "../../../../_reducers/settingsSlice";
import "./password.scss";
import { setSavePrimerMyData } from "../../../../_reducers/activitySlice";
import { toast } from "react-toastify";
import Dropdown from "../../../primer/Dropdown";
import {
  checkPermission,
  getDefaultOptionByValue,
  getMyDataEnvironments, getMyDataModes
} from "../../../../_helpers/commonFunctions";
import {
  activateMyData,
  activatePrimerMyData,
  fetchPrimerMyDataCredentials,
  triggerSyncProviderInvoices
} from "../../../../_apis/api";
import Checkbox from "../../../common/CheckBox";
import {useTranslation} from "react-i18next";
import axios from "axios";
import Alert from "react-bootstrap/Alert";

export const SettingsMyData = () => {
  const [t] = useTranslation('common');
  const company = useSelector((state) => state.COMPANY_DATA.company);
  const dispatch = useDispatch();
  const SETTINGS = useSelector( (state) => state.SETTINGS);
  const ACTIVITY = useSelector( (state) => state.ACTIVITY);
  const environmentOptions = getMyDataEnvironments();
  const myDataModes = getMyDataModes();
  const [showPassword, setShowPassword] = useState(false);
  const [enableActive, setEnableActive] = useState(true);
  const [enableActiveMyData, setEnableMyDataActive] = useState(true);
  const [myDataLoader, setMyDataLoader] = useState(false);
  const [primerMyDataLoader, setPrimerMyDataLoader] = useState(false);
  const [showInstructionsModal, setShowInstructionsModal] = useState(false);
  const [enablePrimerMyDataProviderSettings, setEnablePrimerMyDataProviderSettings] = useState(SETTINGS.primerApiData.serialNumber !== undefined && SETTINGS.primerApiData.serialNumber !== null && SETTINGS.primerApiData.serialNumber !== "");
  const permissionsData = JSON.parse(localStorage.getItem("permissions"));
  const [loadingAllYearDocuments, setLoadingAllYearDocuments] = useState(false);

  const handleOnChange = (e, type="default", ddname = '') => {
    // Set if editing the form
    if (!ACTIVITY.savePrimerMyData) {
      dispatch(setSavePrimerMyData(true));
    }
    let name;
    let value;
    if (type === 'dd')  {
      name = ddname;
      value = e.value;
    }  else {
      name = e.target.name;
      value = e.target.value;
    }
    // Set settings data
    let primerAuthData = Object.assign({}, SETTINGS.primerApiData);
    let setPrimerAuthData = {};
    setPrimerAuthData[name] = value;
    setPrimerAuthData = {...primerAuthData, ...setPrimerAuthData}
    if (setPrimerAuthData.password !== '' && setPrimerAuthData.username !== '') {
      setEnableActive(false);
    }
    if (setPrimerAuthData.Ocp_Apim_Subscription_Key !== '' && setPrimerAuthData.aade_user_id !== '') {
      setEnableMyDataActive(false);
    }
    dispatch(setPrimerApiData(setPrimerAuthData));
    if(value === "active" && name === "enableSyncProviderInvoices"){
      setShowInstructionsModal(true);
    }
  }
  useEffect(() => {
    let primerAuthData = Object.assign({}, SETTINGS.primerApiData);
    if (primerAuthData.password !== '' && primerAuthData.username !== '') {
      setEnableActive(false);
    }
    if (primerAuthData.Ocp_Apim_Subscription_Key !== '' && primerAuthData.aade_user_id !== '') {
      setEnableActive(false);
    }
  }, [SETTINGS.primerApiData])

  useEffect(() => {
    dispatch(fetchPrimerMyDataCredentials(company.id, true, setEnablePrimerMyDataProviderSettings));
  }, [dispatch])

  const submitPrimerApi = () => {
    setPrimerMyDataLoader(true);
    let primerAuthData = Object.assign({}, SETTINGS.primerApiData);
    let username = primerAuthData.username;
    let password = primerAuthData.password;
    let serialNumber = primerAuthData.serialNumber;
    if ( username && password && serialNumber) {
      let data = {
        companyId: company.id,
        username: username,
        password: password,
        serialNumber: serialNumber,
        enableSyncProviderInvoices: primerAuthData.enableSyncProviderInvoices,
        environment: primerAuthData.primerEnvironment.trim()
      }
      dispatch(activatePrimerMyData(data, setPrimerMyDataLoader, true));
    } else {
      toast.error("Please check all mandatory fields!");
      setPrimerMyDataLoader(false);
    }
  }

  const submitMyDataApi = () => {
    setMyDataLoader(true);
    let primerAuthData = Object.assign({}, SETTINGS.primerApiData);
    let userId = primerAuthData.aade_user_id.trim();
    let subscriptionKey = primerAuthData.Ocp_Apim_Subscription_Key.trim();
    let myDataMode = primerAuthData.myDataMode.trim();
    if (userId && subscriptionKey) {
      let data = {
        companyId: company.id,
        userId: userId,
        subscriptionKey: subscriptionKey,
        myDataMode: myDataMode,
        accountantMyDataApiUserId: primerAuthData.accountantMyDataApiUserId.trim(),
        accountantMyDataApiSubscriptionKey: primerAuthData.accountantMyDataApiSubscriptionKey.trim(),
        environment: primerAuthData.myDataOfflineEnvironment.trim()
      }
      dispatch(activateMyData(data, setMyDataLoader));
    } else {
      toast.error("Please check all mandatory fields!");
      setMyDataLoader(false);
    }
  }

  const searchProviderInvoices = () => {
    axios.get(process.env.REACT_APP_API_URL2 + "/company/search-provider-invoices", {
      headers: { 'Content-Type': 'application/json' },
      params: {
        company: company.id,
      }
    }).then((res) => {
      if (res.data.status === "200") {
        const urlFetched = res.data.data;
        window.open(urlFetched, '_blank').focus();
      } else {
        toast.error(res.data.message);
      }
    }).catch((err) => {
      console.log(err);
      toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
    })
  }

  const syncAllYearDocuments = () => {
    toast.info("Έγινε αποστολή αιτήματος, παρακαλώ περιμένετε.");
    dispatch(triggerSyncProviderInvoices(company.id, setLoadingAllYearDocuments, true, company.year));
  }

  return (
    <>
      <Row>
          <div className="mb-3 d-flex align-items-center justify-content-start">
            {t('SettingPrimerMyData.enablePrimerMyDataProvider')}
            <Checkbox
                className="ml-2 mb-2"
                name="enablePrimerMyDataCheckbox"
                key = {Math.random()}
                defaultValue={enablePrimerMyDataProviderSettings}
                value={ enablePrimerMyDataProviderSettings !== true }
                onChange = {(e) => setEnablePrimerMyDataProviderSettings(e.target.checked)}
            />
          </div>
      </Row>
        {enablePrimerMyDataProviderSettings === true && (
        <Row>
        <Col sm="12" md="6">
          <form autoComplete="off">
            <div className="text-muted mb-3">
              <i>{t('SettingPrimerMyData.primerMyDataProvider')}</i>
            </div>
            <div className="mb-3">
              <Input
                label={t('SettingPrimerMyData.username.username')}
                name = "username"
                value = {SETTINGS.primerApiData.username}
                placeholder={t('SettingPrimerMyData.username.placeholder')}
                required={true}
                charLimit="30"
                onChange = {(e) => handleOnChange(e, 'default', 'name') }
              />
            </div>
            <div className="mb-3 password-field">
              <Input
                label={t('SettingPrimerMyData.password.password')}
                name = "password"
                value = {SETTINGS.primerApiData.password}
                placeholder={t('SettingPrimerMyData.password.placeholder')}
                required={true}
                type= {showPassword ? 'text' : 'password'}
                charLimit="30"
                onChange = {(e) => handleOnChange(e, 'default', 'password') }
              />
              { !showPassword && <i onClick={() => setShowPassword(true)} className="fas fa-eye"></i> }
              { showPassword && <i onClick={() => setShowPassword(false)} className="fas fa-eye-slash"></i> }
            </div>
            <div className="mb-3">
              <Input
                  label={t('SettingPrimerMyData.serialNumber.serialNumber')}
                  name = "serialNumber"
                  value = {SETTINGS.primerApiData.serialNumber}
                  placeholder={t('SettingPrimerMyData.serialNumber.placeholder')}
                  required={true}
                  charLimit="30"
                  onChange = {(e) => handleOnChange(e, 'default', 'serialNumber') }
              />
            </div>
            <div className="mb-3">
              <Dropdown
                  label={t('SettingPrimerMyData.environment')}
                  classes="w-100"
                  required={true}
                  name="primerEnvironment"
                  key={Math.random()}
                  defaultValue={getDefaultOptionByValue(environmentOptions,SETTINGS.primerApiData.primerEnvironment,"value","label","value")}
                  options={environmentOptions}
                  onChange={(e) => handleOnChange(e, "dd", "primerEnvironment")}
              />
            </div>
            {SETTINGS.primerApiData.enableAdminSyncProviderInvoices === "active" && (
            <div className="mb-3 d-flex align-items-center justify-content-start">
              {t('SettingPrimerMyData.enableSyncPrimerMyDataProvider')}
              <Checkbox
                  className="ml-2 mb-2"
                  name="enableSyncProviderInvoices"
                  key = {Math.random()}
                  defaultValue={SETTINGS.primerApiData.enableSyncProviderInvoices}
                  value={SETTINGS.primerApiData.enableSyncProviderInvoices === 'active' ? "inactive" : "active" }
                  onChange = {(e) => {handleOnChange(e)}}
              />
            </div>
            )}
            <div className="text-center">
              <Button
                disabled={enableActive || primerMyDataLoader}
                className="mb-3"
                onClick={() => submitPrimerApi(false)}
                >
                {primerMyDataLoader && (
                    <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                    ></span>
                )}
                {t('SettingPrimerMyData.activate')}
              </Button>
            </div>
          </form>
        </Col>
        <Col sm="12" md="6">
          <div className="text-muted mb-3">
            <i>{t('SettingPrimerMyData.primerMyDataInformation')}</i>
          </div>
          <p>{t('SettingPrimerMyData.totalRemainingMyDataDocuments')} {SETTINGS.primerApiData.primerRemainingDocuments}</p>
          <p>{t('SettingPrimerMyData.monthlyRemainingMyDataDocuments')} {SETTINGS.primerApiData.primerMonthlyRemainingDocuments}</p>
          <p>{t('SettingPrimerMyData.contractActivationDate')} {SETTINGS.primerApiData.primerActivationDate}</p>
          <p>{t('SettingPrimerMyData.contractEndDate')} {SETTINGS.primerApiData.primerEndDate}</p>
          <Button
          onClick={() => submitPrimerApi(false)}
          className="mb-3">
            {primerMyDataLoader && (
                <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                ></span>
            )}
            {t('SettingPrimerMyData.updateInformation')}
          </Button>
          <br/>
          <Button className={"mb-3"} onClick={() => searchProviderInvoices()}>
            Αναζήτηση παραστατικών στον πάροχο
          </Button>
          {checkPermission("custom-ssss", permissionsData) === true && (
              <Button className={"mb-3"} onClick={() => syncAllYearDocuments()} disabled={loadingAllYearDocuments}>
                Μαζική άντληση παραστατικών φετινής χρονιάς από τον πάροχο
              </Button>
          )}
        </Col>
      </Row>
        )}
      <Row>
        <Col md={6}>
          <form autoComplete="off">
            <div className="text-muted mb-3">
              <i>{t('SettingPrimerMyData.myDataCredentials')}</i>
            </div>
            <div className="mb-3">
              <Input
                  label={t('SettingPrimerMyData.userId.userId')}
                  name = "aade_user_id"
                  value = {SETTINGS.primerApiData.aade_user_id}
                  placeholder={t('SettingPrimerMyData.userId.placeholder')}
                  required={true}
                  charLimit="30"
                  onChange = {(e) => handleOnChange(e, 'default', 'name') }
              />
            </div>

            <div className="mb-3 password-field">
              <Input
                  label={t('SettingPrimerMyData.subscriptionKey.subscriptionKey')}
                  name = "Ocp_Apim_Subscription_Key"
                  value = {SETTINGS.primerApiData.Ocp_Apim_Subscription_Key}
                  placeholder={t('SettingPrimerMyData.subscriptionKey.placeholder')}
                  required={true}
                  type= {showPassword ? 'text' : 'password'}
                  charLimit="50"
                  onChange = {(e) => handleOnChange(e, 'default', 'password') }
              />

              { !showPassword && <i onClick={() => setShowPassword(true)} className="fas fa-eye"></i> }
              { showPassword && <i onClick={() => setShowPassword(false)} className="fas fa-eye-slash"></i> }
            </div>
            <div className="mb-3">
              <Dropdown
                  label={t('SettingPrimerMyData.environment')}
                  classes="w-100"
                  required={true}
                  name="myDataOfflineEnvironment"
                  key={Math.random()}
                  defaultValue={getDefaultOptionByValue(environmentOptions,SETTINGS.primerApiData.myDataOfflineEnvironment,"value","label","value")}
                  options={environmentOptions}
                  onChange={(e) => handleOnChange(e, "dd", "myDataOfflineEnvironment")}
              />
            </div>
            <div className="mb-3">
              <Dropdown
                  label={t('SettingPrimerMyData.myDataSendingMode')}
                  classes="w-100"
                  required={true}
                  name="myDataMode"
                  key={Math.random()}
                  defaultValue={getDefaultOptionByValue(myDataModes,SETTINGS.primerApiData.myDataMode,"value","label","value")}
                  options={myDataModes}
                  onChange={(e) => handleOnChange(e, "dd", "myDataMode")}
              />
            </div>
            {checkPermission("settings-primermydata/primermydata-write", permissionsData) &&
              <div className="text-center">
                <Button
                    disabled={enableActiveMyData || myDataLoader}
                    className="mb-3"
                    onClick={() => submitMyDataApi()}
                > {myDataLoader && (
                    <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                    ></span>
                )}
                  {t('SettingPrimerMyData.activate')}</Button>
              </div>
            }
          </form>
        </Col>
        {checkPermission("buys-mydata/mydatareport-write", permissionsData) && (
            <Col md={6}>
              <div className="text-muted mb-3">
                <i>Κωδικοί MyData Λογιστή</i>
              </div>
              <Alert variant={"secondary"}>Οι κωδικοί MyData λογιστή θα χρησιμοποιούνται (αν χρειάζονται) κατά τον χαρακτηρισμό εξόδων από τις Αγορές</Alert>
              <Input
                  name={"accountantMyDataApiUserId"}
                  label={"Όνομα Χρήστη Λογιστή"}
                  className={"mb-3"}
                  autoComplete={"off"}
                  value={SETTINGS.primerApiData?.accountantMyDataApiUserId ? SETTINGS.primerApiData.accountantMyDataApiUserId : ""}
                  onChange={(e) => handleOnChange(e, "default", "accountantMyDataApiUserId")}
              />
              <Input
                  name={"accountantMyDataApiSubscriptionKey"}
                  label={"Subscription key Λογιστή"}
                  className={"mb-3"}
                  autoComplete={"off"}
                  value={SETTINGS.primerApiData?.accountantMyDataApiSubscriptionKey ? SETTINGS.primerApiData.accountantMyDataApiSubscriptionKey : ""}
                  onChange={(e) => handleOnChange(e, "default", "accountantMyDataApiSubscriptionKey")}
              />
            </Col>
        )}
      </Row>
      <Modal className="themeModalBig" show={showInstructionsModal}>
        <Modal.Header closeButton>
          <Modal.Title>Οδηγίες Συγχρονισμού</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            Για τον συγχρονισμό των παραστατικών από τον Πάροχο.....
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={() => setShowInstructionsModal(false)}>ΟΚ</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default SettingsMyData;
