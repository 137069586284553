import React, {useEffect, useState} from "react";
import EditableTable from "../../../primer/Editable-Table";
import {suppliersCategoryData} from '../../../../_data/suppliers/suppliers-settings';
import Input from '../../../common/Input';
import {setEditing} from "../../../../_reducers/activitySlice";
import {useDispatch, useSelector} from "react-redux";
import {
    resetSupplierNewCategory,
    setSupplierNewCategory,
    setSuppliersSettings
} from "../../../../_reducers/DataPanel/Suppliers/supplierSlice";
import {fetchSuppliersSettings, postSuppliersSettings} from "../../../../_apis/api";
import {getObjectFromArrayOfObjects} from "../../../../_helpers/helperFunctions";
import {
    checkPermission,
    getLastHighestValue,
    getMaskCode,
    getMaskCodePrefix,
    leadingZeros
} from "../../../../_helpers/commonFunctions";
import {Popconfirm} from "antd";
import {toast} from "react-toastify";
import {Button, Col, Modal, Row, Spinner} from "react-bootstrap";
import axios from "axios";
import {useTranslation} from "react-i18next";
import LogTable from "../../../primer/LogTable";

function SuppliersSettings() {
    const [t] = useTranslation("common");
    const ACTIVITY = useSelector((state) => state.ACTIVITY);
    const SUPPLIERS_DATA = useSelector((state) => state.SUPPLIERS_DATA);
    const company = useSelector(state => state.COMPANY_DATA.company)
    const dispatch = useDispatch();
    const [infoNewField, setInfoNewField] = useState('')
    const [suppliersInfoInput, setSuppliersInfoInput] = useState('');
    const [selectedCategory, setSelectedCategory] = useState({});
    const permissionsData = JSON.parse(localStorage.getItem("permissions"));

    const [loadingApplyToAll, setLoadingApplyToAll] = useState(false);
    const [showApplyToAll, setShowApplyToAll] = useState(false);

    const requestData = {
        company: company.id,
        year: company.year.toString(),
        isActive: "active"
    }

    const settingsColumns = [
        {
            name: t("General.categoryName"),
            field: "category",
            width: "200px",
            editable: true,
            disabled: true,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t("General.codeMask"),
            field: "maskCode",
            width: "100px",
            editable: true,
            mask: true,
            inputType: {
                type: 'text',
            }
        },
    ];

    useEffect(() => {
        dispatch(fetchSuppliersSettings(requestData))
    }, [dispatch])

    const handleOnChange = (e, type = "default", ddname = '') => {
        // Set if editing the form
        if (!ACTIVITY.editing) {
            dispatch(setEditing(true));
        }
        let name;
        let value;
        if (type === 'dd') {
            name = ddname;
            value = e.value;
        } else {
            name = e.target.name;
            value = e.target.value;
        }
        // Set settings data
        let newSettings = Object.assign({}, SUPPLIERS_DATA.suppliersSettings);
        let newSettingsData = {};
        newSettingsData[name] = value;
        newSettings = {...newSettings, ...newSettingsData}
        dispatch(setSuppliersSettings(newSettings));
    }

    const handeKeyDown = (e, type = "default", ddname = '') => {
        // Add supplier info on enter
        if (e.key === 'Enter') {
            dispatch(setSuppliersSettings(infoNewField));
            setSuppliersInfoInput(''); // clear new input value
        }
    }

    const addInfoField = (e, type = "default", ddname = '') => {
        // Add supplier info on press add button
        dispatch(setSuppliersSettings(infoNewField));
        setSuppliersInfoInput(''); // clear new input value
    }

    const setInputToState = (e, type = "default", ddname = '') => {
        // Set if editing the form
        if (!ACTIVITY.editing) {
            dispatch(setEditing(true));
        }
        let name;
        let value;
        if (type === 'dd') {
            name = ddname;
            value = e.value;
        } else {
            name = e.target.name;
            value = e.target.value;
        }
        setSuppliersInfoInput(value); // set input value
        // Set Input Data to State
        let newSettings = Object.assign({}, SUPPLIERS_DATA.suppliersSettings);
        let newSettingsData = {};
        let suppliersInfo = newSettings.suppliersInfo.slice();
        suppliersInfo.push(value);
        newSettingsData[name] = suppliersInfo;
        newSettings = {...newSettings, ...newSettingsData}
        setInfoNewField(newSettings);
    }
    const handleOnSupplierInfoChange = (e, i) => {
        console.log('not editable');
    }
    const deleteInfoField = (label, index) => {
        let oldSupplierSettings = Object.assign({}, SUPPLIERS_DATA.suppliersSettings);
        let oldSupplierInfo = oldSupplierSettings.suppliersInfo;
        if (index > -1) {
            oldSupplierInfo = oldSupplierInfo.filter(item => item !== label)
        }
        dispatch(setSuppliersSettings({...oldSupplierSettings, suppliersInfo: oldSupplierInfo}));
        dispatch(setEditing(true));
    }
    const parentActions = {
        insert: (e, data) => {
            dispatch(setEditing(true));
            let tempNewCategory = Object.assign({}, SUPPLIERS_DATA.newCategory);
            if (e.key !== "Enter") {
                if (data.field === 'maskCode') {
                    tempNewCategory[data.field] = getMaskCode(data.value);
                    tempNewCategory['maskCodePrefix'] = getMaskCodePrefix(data.value);
                    tempNewCategory['mask'] = data.value;
                } else {
                    tempNewCategory[data.field] = data.value;
                    // tempNewCategory['maskCode'] = `PCM-${categoryData.length + 1}`;
                }
                dispatch(setSupplierNewCategory(tempNewCategory)); // Update redux new category state

                // On Value Enter
                if (tempNewCategory.category !== "" && tempNewCategory.maskCode !== "") {
                    let updateNewCategory = Object.assign({}, SUPPLIERS_DATA.newCategory);
                    const lastVal = getLastHighestValue(SUPPLIERS_DATA.categories, 'maskCodeCounter') + 1;
                    updateNewCategory.maskCode = tempNewCategory.maskCode;
                    updateNewCategory.mask = getMaskCodePrefix(data.value) + '-' + leadingZeros(lastVal, 4);
                    updateNewCategory.maskCodePrefix = getMaskCodePrefix(data.value);
                    updateNewCategory._id = getLastHighestValue(SUPPLIERS_DATA.categories, '_id') + 1;
                    updateNewCategory.maskCodeCounter = lastVal;

                    dispatch(setSuppliersSettings({
                        ...SUPPLIERS_DATA.suppliersSettings,
                        categories: [...SUPPLIERS_DATA.categories, updateNewCategory]
                    }));
                    dispatch(resetSupplierNewCategory()); // Reset new category
                    dispatch(postSuppliersSettings({
                        ...SUPPLIERS_DATA.suppliersSettings,
                        categories: [...SUPPLIERS_DATA.categories, updateNewCategory]
                    }, {company: company.id, year: company.year}));
                    // toast.info("Please save the changes.");
                }
            }
            // If pressed enter
            if (e.key === "Enter") {
                if (data.field === 'category' && data.value === '') {
                    toast.error("Category name should not be empty.");
                } else {
                    let updateNewCategory = Object.assign({}, SUPPLIERS_DATA.newCategory);

                    if (data.field === 'category' && data.value !== '') {
                        updateNewCategory.category = data.value;
                    }

                    if (updateNewCategory?.category !== '') {
                        if (data.field === 'maskCode' && data.value !== '') {
                            const lastVal = getLastHighestValue(SUPPLIERS_DATA.categories, 'maskCodeCounter') + 1;
                            updateNewCategory.mask = getMaskCodePrefix(data.value) + '-' + leadingZeros(lastVal, 4);
                            updateNewCategory.maskCode = getMaskCode(data.value);
                            updateNewCategory.maskCodePrefix = getMaskCodePrefix(data.value);
                            updateNewCategory._id = getLastHighestValue(SUPPLIERS_DATA.categories, '_id') + 1;
                            updateNewCategory.maskCodeCounter = lastVal;
                        }
                        dispatch(setSuppliersSettings({
                            ...SUPPLIERS_DATA.suppliersSettings,
                            categories: [...SUPPLIERS_DATA.categories, updateNewCategory]
                        }));
                        dispatch(resetSupplierNewCategory()); // Reset new category
                        dispatch(postSuppliersSettings({
                            ...SUPPLIERS_DATA.suppliersSettings,
                            categories: [...SUPPLIERS_DATA.categories, updateNewCategory]
                        }, {company: company.id, year: company.year}));
                        // toast.info("Please save the changes.");

                    } else {
                        toast.error("Category name should not be empty.");
                    }
                }
            }
        },

        selected: (data) => {
            if (typeof (data._id) !== 'undefined') {
                const selected = getObjectFromArrayOfObjects(SUPPLIERS_DATA.categories, data._id, '_id');
                if (selected && selected.length > 0) {
                    setSelectedCategory(selected['0']);
                }
            }
        },

        update: (data, id) => {
            if (data.category) {
                toast.error("Το όνομα δεν γίνεται να τροποποιηθεί.");
            } else {
                let tempCategoryData = SUPPLIERS_DATA.categories.slice();
                if (tempCategoryData) {
                    const objindx = tempCategoryData.findIndex(obj => obj._id === id);
                    let catObj = tempCategoryData[objindx];
                    let titleMatch = (data.maskCode === catObj.maskCode); // it will check if same text

                    let tempUpdateObj = {...catObj, ...data}
                    tempUpdateObj._id = id;

                    if (data?.maskCode === '') {
                        toast.error('Mask code should not be empty.');
                    } else if (data.maskCode && data.maskCode !== '') {
                        tempUpdateObj.mask = getMaskCodePrefix(data.maskCode) + '-' + leadingZeros(tempUpdateObj.maskCodeCounter, 4);
                        tempUpdateObj.maskCode = getMaskCode(data.maskCode);
                        tempUpdateObj.maskCodePrefix = getMaskCodePrefix(data.maskCode);
                    }
                    if (!titleMatch) {
                        tempCategoryData[objindx] = tempUpdateObj;
                        dispatch(setSuppliersSettings({
                            ...SUPPLIERS_DATA.suppliersSettings,
                            categories: tempCategoryData
                        }));
                        dispatch(setEditing(true));
                        dispatch(postSuppliersSettings({
                            ...SUPPLIERS_DATA.suppliersSettings,
                            categories: tempCategoryData
                        }, {company: company.id, year: company.year}));
                        // toast.info("Please save the changes.");
                    }
                }
            }
        },
        delete: (id) => {
        }
    }
    const handleAccountingChange = (e) => {
        if (!ACTIVITY.editing) dispatch(setEditing(true));
        const name = e.target.name;
        const value = e.target.value;
        setSelectedCategory({...selectedCategory, [name]: value});

        let cloneCategories = SUPPLIERS_DATA.suppliersSettings.categories.slice();
        cloneCategories = cloneCategories.map((a) => Object.assign({}, a));
        let idx = cloneCategories?.findIndex((el) => el._id === selectedCategory._id);
        if (idx !== -1) {
            cloneCategories[idx][name] = value;
            dispatch(setSuppliersSettings({...SUPPLIERS_DATA.suppliersSettings, categories: cloneCategories}));
        }
    }
    const handleApplyToAll = () => {
        setLoadingApplyToAll(true);
        axios.post(process.env.REACT_APP_API_URL2 + "/supplier/apply-accounting-settings-to-all", {
            company: company.id,
            category: selectedCategory.category,
            accounting: selectedCategory?.accounting ? selectedCategory.accounting : "",
            bypassAccounting: selectedCategory?.bypassAccounting ? selectedCategory.bypassAccounting : "",
        }, {
            headers: {"Content-Type": "application/json"},
        }).then((res) => {
            setLoadingApplyToAll(false);
            if (res.data.status === "200") {
                toast.success("Η διαδικασία ολοκληρώθηκε επιτυχώς.");
                setShowApplyToAll(false);
            } else {
                toast.error(res.data.message);
            }
        }).catch((err) => {
            setLoadingApplyToAll(false);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
            console.log(err);
        })
    }
    return (
        <form className="supplier-settings" autoComplete="off">
            <div className="row">
                <div className="col-lg-6">
                    <EditableTable
                        tableName="Supplier Category" // Do not change name
                        selectedRow={selectedCategory._id}
                        onUpdate={parentActions}
                        key={Math.random()}
                        allowActions={false}
                        allowInsertRow={false}
                        data={SUPPLIERS_DATA.categories && SUPPLIERS_DATA.categories.length > 0 ? getObjectFromArrayOfObjects(SUPPLIERS_DATA.suppliersSettings.categories, 'active', 'isActive') : suppliersCategoryData}  // Default Category
                        columns={settingsColumns}
                    />
                </div>
                <div className="col-lg-6 ">
                    <p className="text-muted"><i>{t("General.instructions")}:</i></p>
                    <p>{t("General.instructionsCodeMask")}</p>
                    {(selectedCategory.category && checkPermission("suppliers-suppliers/new-accounting-read", permissionsData)) && (
                        <React.Fragment>
                            <hr/>
                            <p className="text-muted"><i>Λογιστική κατηγορίας {selectedCategory.category}</i></p>
                            <Row>
                                <Col md={6}>
                                    <Input
                                        name={"accounting"}
                                        className="mb-3"
                                        charLimit={20}
                                        placeholder="Εισάγετε Πρόθεμα Κωδικού Λογαριασμού"
                                        label="Πρόθεμα Κωδικού Λογαριασμού"
                                        value={selectedCategory.accounting ? selectedCategory.accounting : ""}
                                        onChange={(e) => handleAccountingChange(e)}
                                    />
                                </Col>
                                <Col md={6}>
                                    <Input
                                        name="bypassAccounting"
                                        className="mb-3"
                                        charLimit={20}
                                        placeholder="Εισάγετε Κωδικό Λογαριασμού Παράκαμψης"
                                        label="Κωδικός Λογαριασμού Παράκαμψης"
                                        value={selectedCategory.bypassAccounting ? selectedCategory.bypassAccounting : ""}
                                        onChange={(e) => handleAccountingChange(e)}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <Button onClick={() => setShowApplyToAll(true)}>Εφαρμογή Λογιστικής σε όλους τους προμηθευτές αυτής της κατηγορίας</Button>
                                </Col>
                            </Row>
                        </React.Fragment>
                    )}
                </div>
            </div>
            <hr/>
            <div className="row">
                <div className="col-12 col-lg-6">
                    <Input
                        className="mb-3"
                        label={t("ClientsSettings.googleCalendarApi")}
                        placeholder="API"
                        name="googleCalendarApi"
                        value={SUPPLIERS_DATA.suppliersSettings.googleCalendarApi}
                        onChange={(e) => handleOnChange(e)}
                    />
                    <div className="supplier-communication mb-3">
                        <Input
                            label={t("ClientsSettings.subjectOptions.subjectOptions")}
                            placeholder="eg subject1, subject2"
                            name="supplierCommunicationHistory"
                            value={SUPPLIERS_DATA.suppliersSettings.supplierCommunicationHistory}
                            onChange={(e) => handleOnChange(e)}
                        />
                        <small>{t('ClientsSettings.instructionsSubject')}</small>
                    </div>
                </div>
                <div className="col-12 col-lg-6 ">
                    <p className="text-muted"><i>Suppliers "info" tab settings</i></p>
                    {
                        SUPPLIERS_DATA.suppliersSettings.suppliersInfo &&
                        SUPPLIERS_DATA.suppliersSettings.suppliersInfo.length > 0 &&
                        SUPPLIERS_DATA.suppliersSettings.suppliersInfo.map((item, index) => {
                            return (
                                <div className="sectrow d-flex align-items-end justify-content-center" key={Math.random() + index}>
                                    <Input
                                        label={`Section ${index + 1}`}
                                        placeholder="Enter Section Label"
                                        name={index}
                                        value={item}
                                        disabled
                                        className="mb-3 w-100"
                                        onChange={(e) => handleOnSupplierInfoChange(e, index)}
                                    />
                                    <Popconfirm
                                        title="Are you sure? It will also delete the related information for this section."
                                        onConfirm={() => deleteInfoField(item, index)}
                                    >
                                        <span className="btn border-danger btn-sm mb-3 ml-2">
                                            <i className="fas text-danger fa-minus-circle"></i>
                                        </span>
                                    </Popconfirm>
                                </div>
                            )
                        })
                    }
                    <div className="d-flex align-items-end justify-content-center mb-3">
                        <Input
                            id="suppliersInfo-input"
                            label={t('ClientsSettings.addNewSection.addNewSection')}
                            placeholder={t('ClientsSettings.addNewSection.placeholder')}
                            name="suppliersInfo"
                            charLimit="30"
                            value={suppliersInfoInput ? suppliersInfoInput : ''}
                            onKeyDown={(e) => handeKeyDown(e)}
                            onChange={(e) => setInputToState(e)}
                        />
                        <label onClick={(e) => addInfoField(e)} className="btn btn-primary btn-sm m-0 ml-2"
                               htmlFor="suppliersInfo-input">
                            <i className="fas fa-plus"></i>
                        </label>
                    </div>
                </div>
            </div>
            <Modal show={showApplyToAll} onHide={() => setShowApplyToAll(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Εφαρμογή Λογιστικής σε όλους του προμηθευτές</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <strong>Αποθηκεύστε τις ρυθμίσεις πρωτού ξεκινήσετε αυτήν την διαδικασία.</strong><br/>
                    Είσαστε σίγουροι ότι θέλετε να εφαρμόσετε τις τωρινές ρυθμίσεις λογιστικής σε όλους τους προμηθευτές της κατηγορίας {selectedCategory.category};<br/>
                    Συγκεκριμένα θα τοποθετηθεί:<br/>
                    - Πρόθεμα Κωδικού λογαριασμού: {selectedCategory?.accounting ? selectedCategory.accounting : "Κενό"}<br/>
                    - Πρόθεμα Λογαριασμού παράκαμψης: {selectedCategory?.bypassAccounting ? selectedCategory.bypassAccounting : "Κενό"}<br/>
                    Η διαδικασία μπορεί να πάρει λίγο χρόνο.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={() => setShowApplyToAll(false)}>Όχι, κλείσιμο</Button>
                    <Button variant="primary" onClick={() => handleApplyToAll()} disabled={loadingApplyToAll}>Ναι, εφαρμογή σε όλους {loadingApplyToAll && <Spinner animation="border" variant="dark" />}</Button>
                </Modal.Footer>
            </Modal>
        </form>
    )
}

export const SupplierSettingsLog = () => {
    const { t } = useTranslation('common');

    return (
        <React.Fragment>
            <div className="text-muted mb-2"><i>{t('Logs.supplierSettingsLog')}</i></div>
            <LogTable model="supplierSetting"/>
        </React.Fragment>
    )
};

export default SuppliersSettings;
