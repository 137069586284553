import React from "react";
import TableFilterCell from "./TableFilterCell";
import {useTranslation} from "react-i18next";

const TableFilterRow = (props) => {
  const [t] = useTranslation('common');
  const { columns, tableName, filterFieldValue, filters, filterRowConfig } = props;

  return (
    <>
    {
      columns && columns.map((oldcell, celli) => {
        let cell =  Object.assign({}, oldcell);
        let enableDynamicOptions = 'false';
        let data = filters[cell.field];
        let rowData = {};
        let inputPlaceholder = t('dateFilterColumn.filter');
        let disabled = false;
        return (
          cell.field !== 'actions' ?
            <TableFilterCell
              enableDynamicOptions = {enableDynamicOptions}
              dynamicOptions = {[]}
              isInsertRow = {true}
              disabled={disabled}
              filterFieldValue = {filterFieldValue}
              placeholder={inputPlaceholder}
              dataKey={data && data._id ? data._id: '0'}
              key={celli}
              cell={cell}
              data={data}
              rowData = {rowData}
              tableName = {tableName}
              filterConfig = {cell && cell?.field && filterRowConfig ? filterRowConfig[cell.field]?.inputType : {}}
            />
          :
            (props.enableNewRowCheckbox && props.enableNewRowCheckbox === true) ?
            <td key={celli} className="text-center w-auto"></td>
            :
            <td key={celli} className="text-center w-auto"></td>
        )
      })
    }
    </>
  )

}
export default TableFilterRow;
