// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.et-table-ui .table-responsive,
.price-list-table .table-responsive {
  overflow: visible; }

.et-table-ui .advanced-combobox .popupOptions,
.price-list-table .advanced-combobox .popupOptions {
  min-width: 400px;
  /* overflow-y: scroll; */ }
  .et-table-ui .advanced-combobox .popupOptions th, .et-table-ui .advanced-combobox .popupOptions td,
  .price-list-table .advanced-combobox .popupOptions th,
  .price-list-table .advanced-combobox .popupOptions td {
    padding: 10px !important;
    font-size: 12px;
    font-weight: normal; }
  .et-table-ui .advanced-combobox .popupOptions th,
  .price-list-table .advanced-combobox .popupOptions th {
    font-weight: 600; }

.et-table-ui th .form-control,
.et-table-ui td .form-control,
.price-list-table th .form-control,
.price-list-table td .form-control {
  margin: 0px !important; }

.et-table-ui th.client,
.et-table-ui td.client,
.price-list-table th.client,
.price-list-table td.client {
  width: 200px; }

.et-table-ui .client .mb-3,
.price-list-table .client .mb-3 {
  margin: 0px !important; }

.et-table-ui .css-1vp9nu5-control,
.et-table-ui .css-5duvv3-control,
.price-list-table .css-1vp9nu5-control,
.price-list-table .css-5duvv3-control {
  background: transparent !important;
  border-radius: 0 !important;
  box-shadow: unset !important;
  border: unset !important; }
`, "",{"version":3,"sources":["webpack://./src/_components/layouts/data-panel/ProductsPriceList/index.scss"],"names":[],"mappings":"AAAA;;EAGI,iBAAiB,EAAA;;AAHrB;;EAOM,gBAAgB;EACjB,wBAAA,EAAyB;EAR9B;;;IAUQ,wBAAwB;IACxB,eAAe;IACf,mBAAmB,EAAA;EAZ3B;;IAgBQ,gBAAgB,EAAA;;AAhBxB;;;;EAuBM,sBAAsB,EAAA;;AAvB5B;;;;EA2BM,YAAY,EAAA;;AA3BlB;;EAgCI,sBAAsB,EAAA;;AAhC1B;;;;EAoCI,kCAAkC;EAClC,2BAA2B;EAC3B,4BAA4B;EAC5B,wBAAwB,EAAA","sourcesContent":[".et-table-ui,\r\n.price-list-table {\r\n  .table-responsive {\r\n    overflow: visible;\r\n  }\r\n  .advanced-combobox {\r\n    .popupOptions {\r\n      min-width: 400px;\r\n     /* overflow-y: scroll; */\r\n      th, td {\r\n        padding: 10px !important;\r\n        font-size: 12px;\r\n        font-weight: normal;\r\n      }\r\n\r\n      th {\r\n        font-weight: 600;\r\n      }\r\n    }\r\n  }\r\n  th,\r\n  td  {\r\n    .form-control {\r\n      margin: 0px !important;\r\n    }\r\n\r\n    &.client {\r\n      width: 200px;\r\n    }\r\n  }\r\n\r\n  .client .mb-3 {\r\n    margin: 0px !important;\r\n  }\r\n  .css-1vp9nu5-control,\r\n  .css-5duvv3-control  {\r\n    background: transparent !important;\r\n    border-radius: 0 !important;\r\n    box-shadow: unset !important;\r\n    border: unset !important;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
