import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { FormControl } from "react-bootstrap";
import Alert from 'react-bootstrap/Alert';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { postTemplate } from "../../../_apis/api";
import Checkbox from "../CheckBox";
import { saveAsTemplateClearTableFields } from "../../../_helpers/commonFunctions";
import { toast } from "react-toastify";
import {useTranslation} from "react-i18next";
const SaveAsTemplate = ({ templateData, requestData, templateType }) => {

  const [t] = useTranslation('common');
  const dispatch = useDispatch();

  const [templateName, setTemplateName] = useState("");
  const [allowUsersWithNoRights, setAllowUsersWithNoRights] = useState("inactive");
  const [useForAutomaticSales, setUseForAutomaticSales] = useState("inactive");
  const [templateAlert, setTemplateAlert] = useState({});
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleClick = () => {

    let updatedTemplateData = Object.assign({}, templateData);

    // Do Not save "Product code", because it is produced by the system
    // Do Not save "Product Name"
    // Do not save "Alternative code", "EAN Barcode"
    if (templateType === 'product') {
      updatedTemplateData.name = updatedTemplateData.alternateName = updatedTemplateData.code = updatedTemplateData.alternateCode = updatedTemplateData.barcode = '';
    }

    // Do Not save "Date", "Quantity" column
    if (templateType === 'productMovements') {
      updatedTemplateData.date = updatedTemplateData.number = '';
      let utproduct = saveAsTemplateClearTableFields(updatedTemplateData.products, ['quantity'])
      updatedTemplateData.products = utproduct;
    }

    // Do Not save "Name, Columns: Price, V.A.T."
    if (templateType === 'priceList') {
      updatedTemplateData.name = '';
      let utproduct = saveAsTemplateClearTableFields(updatedTemplateData.productList, ['price', 'vat'])
      updatedTemplateData.productList = utproduct;
    }

    // Do Not save "Name, Columns: Price, V.A.T."
    if (templateType === 'sales' && useForAutomaticSales === 'inactive') {
      updatedTemplateData.name = '';
     // let utproduct = saveAsTemplateClearTableFields(updatedTemplateData.products, ['price', 'vat', 'unitPrice', 'vatPercentage', 'netValue', 'totalValue'])
     // updatedTemplateData.products = utproduct;
    }

    // Template Data
    let postData = { name: templateName, allowUsersWithNoRights: allowUsersWithNoRights, templateData: JSON.stringify(updatedTemplateData) }

    // Sales Template Data
    if (templateType === 'sales') {
      postData = {...postData, useForAutomaticSales: useForAutomaticSales}
    }

    // Add Template
    let postTemplateData = {...postData, ...requestData};

    if (templateName && templateName !== '') {
      if (templateData && Object.keys(templateData).length > 0) {
        dispatch(postTemplate(postTemplateData, requestData));
      } else {
        toast.error("Template data not found.");
      }
    } else {
      toast.error("Template name is mandatory field.");
    }

    // Clear States Data
    clearStates();
  };

  const clearStates = () => {
    setTimeout(() => {
      setTemplateAlert({});
      setTemplateName("")
      handleClose();
    }, 3000);
  }

  return (
    <React.Fragment>
      <Button size="sm" onClick={handleShow} >{t('SaveAsTemplateIndex.saveAsTemplate')}</Button>
      <Modal className="themeModal" show={show} onHide={handleClose}>
        <Modal.Header closeButton className="border-0">
        </Modal.Header>
        <Modal.Body>
          {
            templateAlert && templateAlert.showAlert ? <Alert variant={ templateAlert?.type }>{ templateAlert.message }</Alert> : ''
          }
          <div className="d-flex align-items-center justify-content-center mb-3">
            <h5 className="w-100">{t('SaveAsTemplateIndex.nameOfNewTemplate')} <span className="text-danger">*</span> </h5>
            <FormControl
              onChange={(e) => setTemplateName(e.target.value)}
              placeholder={t('SaveAsTemplateIndex.placeholder')}
              value={templateName}
            />
          </div>
          <div className="w-100">
            <div className="inline-field d-flex align-items-center mb-3">
              <Checkbox
                id ="allow-users-with-no-rights"
                text ={t('SaveAsTemplateIndex.message')}
                className="mb-0"
                name="allowUsersWithNoRights"
                key = {Math.random()}
                defaultValue = {allowUsersWithNoRights}
                value={ allowUsersWithNoRights === 'active' ? "inactive" : "active" }
                onChange = {(e) => setAllowUsersWithNoRights(e.target.value) }
              />
            </div>
          </div>
          { templateType && (templateType === 'sales' || templateType === 'editSales') &&
            <div className="w-100">
              <div className="inline-field d-flex align-items-center mb-3">
                <Checkbox
                  id ="use-for-automatic-sales"
                  text ={t('SaveAsTemplateIndex.useTemplateForAutomaticSales')}
                  className="mb-0"
                  name="useForAutomaticSales"
                  key = {Math.random()}
                  defaultValue = {useForAutomaticSales}
                  value={ useForAutomaticSales === 'active' ? "inactive" : "active" }
                  onChange = {(e) => setUseForAutomaticSales(e.target.value) }
                />
              </div>
            </div>
          }
        </Modal.Body>
        <Modal.Footer>
          <div className="modal-actions">
            <Button variant="outline-primary" onClick={ () => handleClose() } className="mr-2">{t('SaveAsTemplateIndex.cancel')}</Button>
            <Button variant="primary"  onClick={() => handleClick()}>{t('SaveAsTemplateIndex.save')}</Button>
          </div>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default SaveAsTemplate;
