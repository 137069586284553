// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.offline-alert {
  z-index: 999;
  position: fixed;
  left: 0;
  right: 0;
  padding: 0;
  text-align: center; }
`, "",{"version":3,"sources":["webpack://./src/_components/common/OnlineOffline/offlineAlert.scss"],"names":[],"mappings":"AAAA;EACI,YAAY;EACZ,eAAe;EACf,OAAO;EACP,QAAQ;EACR,UAAU;EACV,kBAAkB,EAAA","sourcesContent":[".offline-alert {\n    z-index: 999;\n    position: fixed;\n    left: 0;\n    right: 0;\n    padding: 0;\n    text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
