import React from "react";
import "./index.scss";

const Checkbox = ({ text, id, className, name, value, onChange, defaultValue, disabled, required, checked }) => {
  let classes = 'form-group';
  if (className) {
    classes = className;
  }

  const inputName = name ? name : 'val-terms';
  const inputChecked = (defaultValue === 'true' || defaultValue === 'active' || defaultValue === true || defaultValue === 'yes' || defaultValue === 1);
  return (
    <div className={classes}>
      <label className="css-control css-checkbox" htmlFor={id}>
        { inputChecked ?
          <input
            disabled = {disabled}
            type="checkbox"
            className="css-control-input mr-2"
            id={id}
            checked={true}
            name={inputName}
            value={value}
            onChange={(e) => onChange(e)}
          />
        :
          <input
            disabled = {disabled}
            type="checkbox"
            className="css-control-input mr-2"
            id={id}
            name={inputName}
            value={value}
            checked={checked}
            onChange={(e) => onChange(e)}
          />
        }
        <span className="normal-text">{text}</span>
        {required === true && <span className="text-danger"> *</span>}
        <span className="checkmark"></span>
      </label>
    </div>
  )
}

export default Checkbox;
