import React from "react";
import QuickEdit from "../../primer/QuickEdit";
import Dropdown from "../../primer/Dropdown";
import DateBox from "../../primer/DateBox";
import AccountingBox from "../../primer/AccountingBox";
import SampleAdvancedCombobox from "../../primer/AdvancedCombobox/SampleAdvancedCombobox";
import TimePickerInput from "../TimePickerInput";

const Components = () => {

  return (
    <React.Fragment>
      <h3>Time Picker</h3>
      <TimePickerInput />
      <hr />
      <h3>Quick Edit</h3>
      <QuickEdit />
      <hr />
      <h3>Advanced Combo Box</h3>
        <SampleAdvancedCombobox />
      <hr />
      <h3>Dropdown</h3>
      <Dropdown options={[{ value: "Unit 1", label: "Template 1", color: "#76AEB7" },{ value: "Template 2", label: "Template 2", color: "#76AEB7" }]} multiSelect searchEnable />
      <hr />
      <h3>Date Box</h3>
      <DateBox language="e" />
      <hr />
      <h3>Accounting Box</h3>
      <AccountingBox />
      <hr />
    </React.Fragment>
  );
};

export default Components;
