export const paymentTypesData = [
      {
          "id": "1",
          "code": "1",
          "name": "Επαγ. Λογαριασμός Πληρωμών Ημεδαπής",
          "isActive": 'true'
      },
      {
          "id": "2",
          "code": "2",
          "name": "Επαγ. Λογαριασμός Πληρωμών Αλλοδαπής",
          "isActive": 'true'
      },
      {
          "id": "3",
          "code": "3",
          "name": "Μετρητά",
          "isActive": 'true'
      },
      {
          "id": "4",
          "code": "4",
          "name": "Επιταγή",
          "isActive": 'true'
      },
      {
          "id": "5",
          "code": "5",
          "name": "Επί Πιστώσει",
          "isActive": 'true'
      }
  ]