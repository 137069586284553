import {useState, useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import {toast} from "react-toastify";
import {Badge, Col, Row} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import React from "react";
import AdvancedCombobox from "../../../primer/AdvancedCombobox";
import axios from "axios";
import "jspdf-autotable";
import {
    setProductBalanceReportData, setProductBalanceReportRequestData,
} from "../../../../_reducers/DataPanel/Products/productsSlice";
import EditableTable from "../../../primer/Editable-Table";
import {useTranslation} from "react-i18next";
import {checkPermission, getDefaultOptionByValue, getOptionsByData, isEmpty} from "../../../../_helpers/commonFunctions";
import Loader from "../../../primer/POPUP-COMPONENTS/Loader/PrimerLoader";
import Dropdown from "../../../primer/Dropdown";
import DateBox from "../../../primer/DateBox";
import * as moment from "moment";
import BetterCheckBox from "../../../common/BetterCheckBox";

const BalanceSheetReports = () => {
    const [t, i18n] = useTranslation("common");
    const dispatch = useDispatch();
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const requestData = useSelector((state) => state.PRODUCTS_DATA.productBalanceReportRequestData);
    const reportData = useSelector((state) => state.PRODUCTS_DATA.productBalanceReportData);
    const [productList, setProductList] = useState([]);
    const [loader, setLoader] = useState(false);
    const [totalBalance, setTotalBalance] = useState(0);
    const installations = useSelector((state) => state.COMPANY_DATA.companyInstallations);
    let installationOptions = getOptionsByData(installations, "installationMasterId", "address");
    installationOptions = [{value: '', label: "Όλες"}, ...installationOptions];
    const permissionsData = JSON.parse(localStorage.getItem("permissions"));
    const [firstLoad, setFirstLoad] = useState(true);

    let columns = [
        {
            name: t('ReportsBalanceSheet.table.inventory'),
            field: "inventory",
            hiddenColumn: false,
            editable: true,
            disabled: true,
            inputType: {
                type: 'accounting',
                config: {
                    suffix: "€",
                }
            }
        },
        {
            name: t('ReportsBalanceSheet.table.productCode'),
            field: "code",
            editable: false,
            hiddenColumn: false,
            inputType: {
                type: "text",
            },
        },
        {
            name: t('ReportsBalanceSheet.table.productName'),
            field: "productName",
            editable: false,
            hiddenColumn: false,
            inputType: {
                type: "text",
            },
        },

        {
            name: t('ReportsBalanceSheet.table.inventoryBalance'),
            field: "balance",
            hiddenColumn: false,
            editable: true,
            disabled: true,
            inputType: {
                type: 'input',
            }
        },
    ];
    const [tableColumns, setTableColumns] = useState(columns.slice());

    useEffect(() => {
        setTableColumns(columns.slice());
    }, [i18n.language]);

    useEffect(() => {
        loadProductsListInitialData();
        if(isEmpty(requestData.date_to)) {
            const currentYear = Number(moment().format("YYYY"));
            if (Number(company.year) < currentYear) {
                dispatch(setProductBalanceReportRequestData({...requestData, date_to: `31/12/${company.year}`}));
            } else {
                dispatch(setProductBalanceReportRequestData({...requestData, date_to: moment().format("DD/MM/YYYY")}));
            }
        }
    }, []);

    useEffect(() => {
        if (firstLoad) {
            setFirstLoad(false);
        } else {
            fetchReport();
        }
    }, [requestData.page])

    const loadProductsListInitialData = () => {
        axios.post(process.env.REACT_APP_API_URL2 + `/category/list-product`, {company: company.id}, {
            headers: {"Content-Type": "application/json"}
        }).then((res) => {
            if (res.data && res.data.status === '200') {
                if (res.data.data) {
                    setProductList(res.data.data);
                } else {
                    setProductList([]);
                }
            } else {
                setProductList([]);
            }
        }).catch((e) => {
            console.log(e);
        });
    }

    const loadProductsListData = (name, setData) => {
        axios.post(process.env.REACT_APP_API_URL2 + `/category/list-product`, {name: name, company: company.id}, {
            headers: {"Content-Type": "application/json"}
        }).then((res) => {
            if (res.data && res.data.status === "200") {
                if (res.data.data) {
                    setData(res.data.data)
                } else {
                    setData([])
                }
            } else {
                setData([])
            }
        }).catch((e) => {
            console.log(e);
        })
    }

    const productDropdownColumns = [
        {
            label: t('ReportsBalanceSheet.smallTable.productCode'),
            name: "code",
        },
        {
            label: t('ReportsBalanceSheet.smallTable.productName'),
            name: "name",
        },
    ];

    const handleOnChange = (e, type, name) => {
        if (type === "datebox") {
            if (moment(e).isValid()) {
                dispatch(setProductBalanceReportRequestData({...requestData, [name]: moment(e).format("DD/MM/YYYY")}))
            } else {
                dispatch(setProductBalanceReportRequestData({...requestData, [name]: ""}))
            }
        } else if (type === "dropdown") {
            dispatch(setProductBalanceReportRequestData({...requestData, [name]: e.value}));
        } else if (type === "acdd") {
            dispatch(setProductBalanceReportRequestData({...requestData, [name]: e.row.name}));
        } else if (type === "checkbox") {
            const val = e.target.checked ? "active" : "inActive";
            dispatch(setProductBalanceReportRequestData({...requestData, [name]: val}));
        }
    }

    const fetchReport = () => {
        setLoader(true);
        const newData = {...requestData, company: company.id, year: company.year}
        axios.post(process.env.REACT_APP_API_URL2 + `/report/product-balance-report`, newData, {
            headers: {"Content-Type": "application/json"},
        }).then((res) => {
            if (res.data && res.data.status === "200") {
                dispatch(setProductBalanceReportRequestData({...requestData, totalPages: res.data.totalPages}))
                let dR = [];
                setTotalBalance(0);
                dispatch(setProductBalanceReportData([]));
                if (res.data.data.length > 0) {
                    dR = res.data.data;
                    dR.forEach((e, i) => {
                        e.productName = dR[i]._id.productName;
                    });
                    setTotalBalance(res.data?.totalBalance);
                    dispatch(setProductBalanceReportData([...dR]));
                }
                if (res.data.data && res.data.data.length === 0) {
                    dispatch(setProductBalanceReportData(res.data.data));
                }
            } else {
                if (res.data && res.data.message) {
                    toast.error(res.data && res.data.message);
                } else {
                    toast.error(res.data && res.data.error);
                }
                dispatch(setProductBalanceReportData([]));
            }
            setLoader(false);
        }).catch((err) => {
            console.log(err);
            setLoader(false);
        })
    }

    const toFirstPage = () => {
        dispatch(setProductBalanceReportRequestData({...requestData, page: 1}));
    }
    const toNextPage = () => {
        dispatch(setProductBalanceReportRequestData({...requestData, page: requestData.page + 1}));
    }
    const toPreviousPage = () => {
        dispatch(setProductBalanceReportRequestData({...requestData, page: requestData.page - 1}));
    }
    const toLastPage = () => {
        dispatch(setProductBalanceReportRequestData({...requestData, page: requestData.totalPages}));
    }

    const onChangeSearch = (keyword, setData) => {
        if (keyword.length >= 2) {
            loadProductsListData(keyword, setData)
        }
    }

    return (
        <div className="product-balance-sheet">
            {loader ? <Loader/> :
                <div>
                    <div className="no-print">
                        <Row>
                            <Col md={3}>
                                <DateBox
                                    name="date_to"
                                    selected={isEmpty(requestData.date_to) ? moment().toDate() : moment(requestData.date_to, "DD/MM/YYYY").toDate()}
                                    onChange={(e) => handleOnChange(e, "datebox", "date_to")}
                                    label={t("ClientsReportsClientBalance.calculateBalanceUpTo")}
                                />
                            </Col>
                            <Col md={5}>
                                <Dropdown
                                    name="installation"
                                    label="Εγκατάσταση"
                                    options={installationOptions}
                                    value={getDefaultOptionByValue(installationOptions, requestData.installation, 'value', 'label', 'value')}
                                    enabledValue={true}
                                    searchEnable={true}
                                    required={true}
                                    onChange={(e) => handleOnChange(e, "dropdown", "installation")}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={3}>
                                <AdvancedCombobox
                                    ACName="Sales Product" // do not change name
                                    className="w-100 ac-select-dropdown"
                                    searchableDropdownEnable={false}
                                    defaultValue={requestData.productName}
                                    key={Math.random()}
                                    label={t('ReportsBalanceSheet.productName')}
                                    data={productList}
                                    onChange={(e) => handleOnChange(e, "acdd", "productName")}
                                    resetFilter={() => dispatch(setProductBalanceReportRequestData({
                                        ...requestData,
                                        productName: ""
                                    }))}
                                    onChangeSearch={onChangeSearch}
                                    excludeColumns={[]}
                                    columns={productDropdownColumns}
                                    editEnable={false}
                                    deleteEnable={false}
                                    searchTableEnable={false}
                                    showTableOptionOnSelect={true}
                                    placeholder={t('ReportsBalanceSheet.productName')}
                                    name="productName"
                                    showInsertAddButton={true}
                                    hideSaveButton={true}
                                    hideInsertNewRow={true}
                                    inputFieldClasses="form-control"
                                />
                            </Col>
                            <Col sm="3" md="2 mt-4">
                                <BetterCheckBox
                                    text={t('ReportsBalanceSheet.table.inactiveInventoryBalance')}
                                    className="mb-0 mr-2 centered-checkbox"
                                    name="product_inactive"
                                    checked={requestData.product_inactive === "active"}
                                    onChange={(e) => handleOnChange(e, "checkbox", "product_inactive")}
                                />
                            </Col>
                            <Col sm="3" md="3 mt-4">
                                <BetterCheckBox
                                    text={t('ReportsBalanceSheet.includeZeroBalanceProducts')}
                                    className="mb-0 mr-2 centered-checkbox"
                                    name="product_zero"
                                    checked={requestData.product_zero === "active"}
                                    onChange={(e) => handleOnChange(e, "checkbox", "product_zero")}
                                />
                            </Col>
                        </Row>
                        {checkPermission("products-reports/balancesheet-general-read", permissionsData) &&
                            <Row>
                                <Col md={12} className="ml-auto">
                                    <Button onClick={() => fetchReport()} className="btn btn-primary float-right">
                                        {t('ReportsBalanceSheet.applyFilter')}
                                    </Button>
                                </Col>
                            </Row>
                        }
                        <hr style={{color: 'gray', height: 1}}/>
                    </div>
                    <div>
                        {reportData.length > 0 ? (
                            <>
                                <Row>
                                    <h3 style={{
                                        textAlign: "center",
                                        alignItems: "center",
                                        fontWeight: "bold"
                                    }}>{t('ReportsBalanceSheet.productsBalance')}</h3>
                                </Row>
                                {requestData.totalPages > 0 && (
                                    <>
                                        <div className="d-flex justify-content-end mb-2">
                                            <Button
                                                size={"sm"}
                                                className={"mr-1 paginate" + (requestData.page !== 1 ? "yes" : "no")}
                                                onClick={() => {
                                                    toFirstPage();
                                                }}
                                                disabled={requestData.page === 1 || loader}
                                            >
                                                {t('ReportsBalanceSheet.first')}
                                            </Button>
                                            <Button
                                                size={"sm"}
                                                className={"mr-1 paginate" + (requestData.page !== 1 ? "yes" : "no")}
                                                onClick={() => {
                                                    toPreviousPage();
                                                }}
                                                disabled={requestData.page === 1 || loader}
                                            >
                                                {t('ReportsBalanceSheet.previous')}
                                            </Button>
                                            <Badge style={{fontSize: "12px", lineHeight: "20px"}} className="mr-1"
                                                   bg="primary">{t('ReportsBalanceSheet.page')} {requestData.page} / {requestData.totalPages}</Badge>
                                            <Button
                                                size={"sm"}
                                                className={
                                                    "mr-1 paginate" + (requestData.page !== requestData.totalPages ? "yes" : "no")
                                                }
                                                onClick={() => {
                                                    toNextPage();
                                                }}
                                                disabled={requestData.page === requestData.totalPages || loader}
                                            >
                                                {t('ReportsBalanceSheet.next')}
                                            </Button>
                                            <Button
                                                size={"sm"}
                                                className={
                                                    "mr-1 paginate" + (requestData.page !== requestData.totalPages ? "yes" : "no")
                                                }
                                                onClick={() => {
                                                    toLastPage();
                                                }}
                                                disabled={requestData.page === requestData.totalPages || loader}
                                            >
                                                {t('ReportsBalanceSheet.last')}
                                            </Button>
                                        </div>
                                    </>
                                )}
                                <EditableTable
                                    key={Math.random() + "table"}
                                    tableName="Product Balance Report"
                                    data={reportData}
                                    allowPagination={false}
                                    allowToggleColumns={true}
                                    allowInsertRow={false}
                                    pageTotals={true}
                                    enableNewRowCheckbox={false}
                                    allowBulkActions={true}
                                    allowPrintResults={true}
                                    columns={tableColumns}
                                    reportExcelPrint={true}
                                    totalBalanceTrue={true}
                                    totalBalance={totalBalance}
                                    company={company}
                                    allowActions={false}
                                    filters={requestData}
                                />
                            </>
                        ) : (
                            <Row>
                                <h3 style={{
                                    textAlign: "center",
                                    alignItems: "center",
                                }}>{t('ReportsBalanceSheet.filterMessage')}</h3>
                            </Row>
                        )}
                    </div>
                </div>}
        </div>
    );
}
export default BalanceSheetReports;
