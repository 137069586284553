import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Badge, Button, Col, Modal, Row, Spinner} from "react-bootstrap";
import pdfToText from 'react-pdftotext'
import {getOptionsByData, isEmpty} from "../../../../_helpers/commonFunctions";
import {toast} from "react-toastify";
import * as moment from "moment";
import {setPrimerClientPaymentsRequestData} from "../../../../_reducers/DataPanel/Sales/salesSlice";
import axios from "axios";
import {fetchAdvancedTypes, fetchBankAccounts, fetchPOS, fetchSalesGeneralSettings} from "../../../../_apis/api";
import {getObjectFromArrayOfObjects} from "../../../../_helpers/helperFunctions";
import Dropdown from "../../../primer/Dropdown";
import {preNumberGreek} from "../../../../_data/settings/advanced-types-search";
import Alert from "react-bootstrap/Alert";
import {classicStyleBelowNavbar} from "../Statics";

const PrimerClientPayments = () => {
    /* Client Payment Data Object Format:
    {
        no: "1",
        date: "DD/MM/YYYY" format
        transactionNumber: "...."
        status: "..."
    }
     */
    const dispatch = useDispatch();
    const requestData = useSelector((state) => state.SALES.primerClientPaymentsRequestData);
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const SETTINGS = useSelector((state) => state.SETTINGS);
    const SALES_SETTINGS = useSelector((state) => state.SALES_SETTINGS);
    const CASH_DATA = useSelector((state) => state.CASH_DATA);
    const [loadStatuses, setLoadStatuses] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showCreateModal, setShowCreateModal] = useState(false);

    const allDocumentTypes = SETTINGS.settings.advancedTypes;
    const clientPaymentsDT = getObjectFromArrayOfObjects(allDocumentTypes.filter((el) => el.myDataType !== "8.4" && el.myDataType !== "8.5"), "Client Payments", "section");
    const documentTypesOptions = getOptionsByData(clientPaymentsDT, "_id", "name");
    const paymentTypeOptions = getOptionsByData(SALES_SETTINGS.salesGeneralSettings.paymentTypes?.filter((el) => el.type !== "credit"), "_id", "name");

    const posOptions = getOptionsByData(CASH_DATA.posList, '_id', 'name');
    const bankOptions = getOptionsByData(CASH_DATA.bankAccounts, '_id', 'name');

    useEffect(() => {
        dispatch(fetchAdvancedTypes({company: company.id}));
        dispatch(fetchSalesGeneralSettings({company: company.id}));
        dispatch(fetchPOS({company: company.id}));
        dispatch(fetchBankAccounts({company: company.id}));
    }, [])

    useEffect(() => {
        if (loadStatuses) {
            setLoadStatuses(false);
            getStatuses();
        }
    }, [loadStatuses]);

    const getStatuses = async () => {
        const cps = requestData.clientPaymentsData;
        if (cps.length > 0) {
            setLoading(true);
            let tNums = [];
            for (let cp of cps) {
                tNums.push(String(cp.transactionNumber));
            }
            try {
                const serverResponse = await axios.get(process.env.REACT_APP_API_URL2 + "/client/primer-client-payments-statuses", {
                    headers: { "Content-Type": "application/json" },
                    params: {
                        company: company.id,
                        year: company.year,
                        transactionNumbers: tNums,
                    }
                })
                if (serverResponse.data.status === "200") {
                    let cloneCPs = structuredClone(requestData.clientPaymentsData);
                    for (let key in serverResponse.data.data) {
                        cloneCPs.find((el) => String(el.transactionNumber) === String(key)).status = serverResponse.data.data[key];
                    }
                    dispatch(setPrimerClientPaymentsRequestData({...requestData, clientPaymentsData: cloneCPs}));
                    setLoading(false);
                } else {
                    toast.error(serverResponse.data.message);
                    setLoading(false);
                }
            } catch (error) {
                setLoading(false);
                console.log(error);
                toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
            }
        }
    }

    const handleCreateClientPayments = () => {
        const cps = requestData.clientPaymentsData;
        if (cps.length > 0) {
            setLoading(true);
            let tNums = [];
            for (let cp of cps) {
                tNums.push(String(cp.transactionNumber));
            }
            axios.post(process.env.REACT_APP_API_URL2 + "/client/primer-clients-payments-create", {...requestData, company: company.id, year: company.year, transactionNumbers: tNums}, {
                headers: { "Content-Type": "application/json" },
            }).then((res) => {
                setLoading(false);
                if (res.data.status === "200") {
                    if (res.data.count === 0) {
                        toast.info("Δεν δημιουργήθηκε καμία είσπραξη.");
                    } else {
                        toast.success(`${res.data.count} ${res.data.count === 1 ? "είσπραξη δημιουργήθηκε" : "εισπράξεις δημιουργήθηκαν"} επιτυχώς.`);
                    }
                    setShowCreateModal(false);
                    setLoadStatuses(true);
                } else {
                    toast.error(res.data.message);
                }
            }).catch((err) => {
                setLoading(false);
                console.log(err);
                toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
            })
        }
    }

    const handleOnChange = (e, type, name) => {
        if (type === "file" && name === "file") {
            const selectedFile = e.target.files[0];
            pdfToText(selectedFile).then((data) => {
                if (!isEmpty(data)) {
                    let extraInfoJSON = {}
                    const regex = /Πακέτο: \s*(\d+)/;
                    let packet = data.match(regex);
                    if (packet && packet.length > 1) extraInfoJSON["Πακέτο"] = packet[1];

                    let extraInfo = data.split("Α/  Α")[0];
                    extraInfo = extraInfo.split("Σύνολα")[1];
                    extraInfo = extraInfo.replace(/ {3}/g, " ");
                    extraInfo = extraInfo.replace(/ {2}/g, "|");
                    let ex = extraInfo.split("|");
                    for (let str of ex) {
                        let split = String(str).split(":");
                        if (split && split[0] && split[1]) extraInfoJSON[split[0]] = String(split[1]).trim();
                    }

                    let tableContents = data.split("Α/  Α")[1];
                    tableContents = String(tableContents.split("Εισπρακτέο  ποσό")[1]).trim();
                    tableContents = tableContents.replace(/ {3}/g, "|");
                    let rows = tableContents.split(/ {2}/g);
                    // Look for new page
                    let cloneRows = [];
                    for (let row of rows) {
                        const split = row.split("|")
                        if (split.length > 14) {
                            const lastCell = row.split("|")[13];
                            const commaIdx = lastCell.indexOf(",");
                            const rowNum = lastCell.slice(commaIdx + 3);
                            cloneRows.push(`${split.slice(0, 13).join("|")}|${lastCell.slice(0, commaIdx + 3)}`);
                            cloneRows.push(`${rowNum}|${split.slice(14).join("|")}`);
                        } else {
                            cloneRows.push(row);
                        }
                    }
                    rows = cloneRows.filter((el) => el !== "Εκτύπωση|Επιστροφή");
                    let jsonRows = [];
                    for (let row of rows) {
                        const arrRow = row.split("|");
                        let date = moment(String(arrRow[1]), "DD/MM/YY HH:mm");
                        if (String(arrRow[1]).includes("μμ")) date = date.add(12, "hours");
                        jsonRows.push({
                            no: arrRow[0],
                            date: date.format("DD/MM/YYYY HH:mm"),
                            transactionNumber: arrRow[4],
                            amount: parseFloat(String(arrRow[6]).replaceAll(".", "").replaceAll(",", ".")),
                            status: "loading",
                        })
                    }
                    dispatch(setPrimerClientPaymentsRequestData({...requestData, clientPaymentsData: jsonRows, extraInfo: extraInfoJSON}));
                    e.target.value = "";
                    setLoadStatuses(true);
                }
            }).catch((err) => {
                e.target.value = "";
                toast.error("Σφάλμα κατά την ανάγνωση εγγράφου");
                console.log(err);
            })
        } else if (type === "dropdown") {
            if (name === "paymentType") {
                const paymentObjArr = SALES_SETTINGS.salesGeneralSettings.paymentTypes.find((el) => el._id === e.value);
                if (paymentObjArr) {
                    dispatch(setPrimerClientPaymentsRequestData({...requestData, paymentType : e.value, paymentTypeType : paymentObjArr.type, bankOrPOS: ""}));
                }
            } else {
                dispatch(setPrimerClientPaymentsRequestData({...requestData, [name] : e.value}));
            }
        }
    }

    const getStatus = (status) => {
        if (status === "loading") {
            return <Badge style={{fontSize: "12px"}} bg={"primary"}>Γίνεται συσχέτιση...</Badge>
        } else if (status === "error") {
            return <Badge style={{fontSize: "12px"}} bg={"danger"}>Το παραστατικό δεν βρέθηκε</Badge>
        } else if (String(status).startsWith("ok")) {
            let split = String(status).split("|");
            return <React.Fragment><Badge style={{fontSize: "12px"}} bg={"success"}>Το παραστατικό βρέθηκε. Υπ: {split[1]}</Badge><br/><strong>Παραστατικό:</strong> {split[2]}<br/><strong>Πελάτης:</strong> {split[3]}</React.Fragment>
        } else if (String(status).startsWith("already")) {
            let split = String(status).split("|");
            return <React.Fragment><Badge style={{fontSize: "12px"}} bg={"success"}>Το παραστατικό είναι εξοφλημένο</Badge><br/><strong>Παραστατικό:</strong> {split[1]}<br/><strong>Πελάτης:</strong> {split[2]}</React.Fragment>
        } else if (String(status).startsWith("used")) {
            let split = String(status).split("|");
            return <React.Fragment><Badge style={{fontSize: "12px"}} bg={"danger"}>Η είσπραξη έχει ήδη περαστεί</Badge><br/><strong>Παραστατικό:</strong> {split[1]}<br/><strong>Πελάτης:</strong> {split[2]}</React.Fragment>
        }
    }

    const invalidCreation = () => {
        return isEmpty(requestData.documentType) || isEmpty(requestData.preNumber) || isEmpty(requestData.paymentType)
            || (requestData.paymentTypeType === "bank-account" && isEmpty(requestData.bankOrPOS))
            || (requestData.paymentTypeType === "pos" && isEmpty(requestData.bankOrPOS));
    }

    const amountsEqual = () => {
        const incomingAmount = String(requestData.extraInfo["Εισπρακτέο ποσό"]).replaceAll(".", "").replace(",", ".");
        const feeAmount = String(requestData.extraInfo["Ποσό προμήθειας"]).replaceAll(".", "").replace(",", ".");
        let totalCPs = 0;
        const cps = requestData.clientPaymentsData;
        for (let cp of cps) {
            totalCPs += cp.amount;
        }
        totalCPs = Number(Number(totalCPs).toFixed(2));
        let pseudoInc = Number(Number(Number(incomingAmount) + Number(feeAmount)).toFixed(2));
        return [totalCPs === pseudoInc, totalCPs, pseudoInc];
    }

    return (
        <div style={classicStyleBelowNavbar} hidden={company.vatNumber !== "800566476"}>
            <Row>
                <Col md={3}>
                    <div className={"text-muted mb-1"}><i>Επιλέξτε αρχείο</i></div>
                    <input
                        type="file"
                        className="form-control"
                        accept="application/pdf"
                        onChange={(e) => handleOnChange(e, "file", "file")}
                        disabled={loading}
                    />
                </Col>
                {loading && (
                    <Col md={3}>
                        <div style={{marginTop: "30px"}}>Γίνεται συσχέτιση παρακαλώ περιμένετε...</div>
                    </Col>
                )}
            </Row>
            {requestData?.clientPaymentsData?.length > 0 && (
                <React.Fragment>
                    <hr />
                    <Alert variant={"secondary"} className={"mb-1"}>
                        Μία είσπραξη δεν δημιουργείται αν ισχύει τουλάχιστον μία από τις παρακάτω περιπτώσεις:<br/>
                        - Το παραστατικό είναι πλήρως εξοφλημένο<br/>
                        - Το υπόλοιπο του παραστατικού είναι μικρότερο από το ποσό πληρωμής<br/>
                        - Το παραστατικό δεν έχει βρεθεί<br/>
                        - Η συγκεκριμένη είσπραξη έχει ήδη περαστεί<br/>
                        Η διαδικασία της δημιουργίας εισπράξεων θα δημιουργήσει εισπράξεις που ΔΕΝ έχουν κάποιο από τα παραπάνω κριτήρια
                    </Alert>
                    {Object.keys(requestData.extraInfo).length > 0 && (
                        <Row className={"mb-2"}>
                            <div className={"text-muted mb-1"}><i>Πληροφορίες Πακέτου</i></div>
                            <Col md={3}>
                                <div style={{
                                    border: "2px solid #6ea2a9",
                                    borderRadius: "5px",
                                    padding: "5px",
                                    fontSize: "14px"
                                }}>
                                    {Object.keys(requestData.extraInfo).map((key) => (
                                        <React.Fragment>
                                            <strong>{key}</strong>: {requestData.extraInfo[key]}<br/>
                                        </React.Fragment>
                                    ))}
                                </div>
                            </Col>
                            {!amountsEqual()[0] && (
                                <Col md={3}>
                                    <div style={{color: "red", fontWeight: "600"}}>ΠΡΟΣΟΧΗ ΤΑ ΠΟΣΑ ΔΕΝ ΣΥΜΦΩΝΟΥΝ (ΕΝΤΟΠΙΣΜΕΝΟ ΣΥΝΟΛΟ {amountsEqual()[1]} ΕΝΑΝΤΙ ΣΥΝΟΛΟ ΠΑΚΕΤΟΥ {amountsEqual()[2]})</div>
                                </Col>
                            )}
                        </Row>
                        )}
                    <div className={"text-muted mb-1"}><i>Εντοπισμένες Συναλλαγές</i></div>
                    <Row>
                        {requestData?.clientPaymentsData.map((row) => (
                            <Col md={3} className={"mb-2"}>
                                <div style={{border: "2px solid #6ea2a9", borderRadius: "5px", padding: "5px", fontSize: "14px"}}>
                                    <strong>Α/Α:</strong> {row.no}<br/>
                                    <strong>Ημερομηνία:</strong> {row.date}<br/>
                                    <strong>Αρ. Συναλλαγής:</strong> {row.transactionNumber}<br/>
                                    <strong>Ποσό</strong> {row.amount}<br/>
                                    {getStatus(row.status)}
                                </div>
                            </Col>
                        ))}
                    </Row>
                    <Row className={"mt-3"}>
                        <Col md={6}>
                            <Button onClick={() => getStatuses()} className={"mr-3"}>Ανανέωση καταστάσεων</Button>
                            {requestData.clientPaymentsData?.filter((el) => String(el.status).startsWith("ok")).length > 0 && (
                                <Button onClick={() => setShowCreateModal(true)}>Δημιουργία Εισπράξεων</Button>
                            )}
                        </Col>
                    </Row>
                    <Modal show={showCreateModal} onHide={() => setShowCreateModal(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Δημιουργία Εισπράξεων</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <i>Παρακαλώ επιλέξτε τις ρυθμίσεις που επιθυμείτε παρακάτω.</i>
                            <Dropdown
                                name={"documentType"}
                                label={"Τύπος Παραστατικού"}
                                className={"mt-2 mb-3"}
                                options={documentTypesOptions}
                                key={Math.random()}
                                required={true}
                                defaultValue={documentTypesOptions?.find((el) => el.value === requestData.documentType)}
                                onChange={(e) => handleOnChange(e, "dropdown", "documentType")}
                            />
                            <Dropdown
                                name={"preNumber"}
                                label={"Σειρά"}
                                className={"mb-3"}
                                options={preNumberGreek}
                                key={Math.random()}
                                required={true}
                                defaultValue={preNumberGreek?.find((el) => el.value === requestData.preNumber)}
                                onChange={(e) => handleOnChange(e, "dropdown", "preNumber")}
                            />
                            <Dropdown
                                name={"paymentType"}
                                label={"Τρόπος Πληρωμής"}
                                className={"mb-3"}
                                options={paymentTypeOptions}
                                key={Math.random()}
                                required={true}
                                defaultValue={paymentTypeOptions?.find((el) => el.value === requestData.paymentType)}
                                onChange={(e) => handleOnChange(e, "dropdown", "paymentType")}
                            />
                            {(requestData.paymentTypeType === "bank-account" || requestData.paymentTypeType === "pos") && (
                                <Dropdown
                                    name={"bankOrPOS"}
                                    label={requestData.paymentTypeType === "bank-account" ? "Τραπεζικός Λογαριασμός" : "POS"}
                                    className={"mb-3"}
                                    options={requestData.paymentTypeType === "bank-account" ? bankOptions : posOptions}
                                    key={Math.random()}
                                    required={true}
                                    defaultValue={requestData.paymentTypeType === "bank-account" ? bankOptions.find((el) => el.value === requestData.bankOrPOS) : posOptions.find((el) => el.value === requestData.bankOrPOS)}
                                    onChange={(e) => handleOnChange(e, "dropdown", "bankOrPOS")}
                                />
                            )}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="outline-primary" onClick={() => setShowCreateModal(false)}>
                                Κλείσιμο
                            </Button>
                            <Button variant="primary" onClick={() => handleCreateClientPayments()} disabled={invalidCreation() || loading}>
                                Δημιουργία Εισπράξεων {loading && <Spinner className={"ml-2"} animation={"border"} variant={"dark"} />}
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </React.Fragment>
            )}
        </div>
    )
}

export default PrimerClientPayments