export const sampleSalesTemplateData = {
    ISSUER_NAME: "{ISSUER_NAME}",
    ISSUER_SMALL_NAME: "{ISSUER_SMALL_NAME}",
    ISSUER_SUBJECTFIELD: "{ISSUER_SUBJECTFIELD}",
    ISSUER_ADDRESS: "Issuer Address",
    ISSUER_PHONE: "789789789",
    ISSUER_DOY: "DOY",
    ISSUER_VAT: "VAT",
    INVOICE_TYPE: "Sales",
    SERIES: "SE6565455656",
    NUMBER: "54634654654654",
    INVOICE_DATE: "13/05/2023",
    INVOICE_TIME: "14:30",
    CUSTOMER_CODE: "CC0001",
    CUSTOMER_COMPANY: "Test",
    CUSTOMER_ACTIVITY: "Test",
    CUSTOMER_EMAIL: "Test",
    CUSTOMER_VAT: "Test",
    CUSTOMER_ADDRESS: "Test",
    CUSTOMER_CITY: "Test",
    CUSTOMER_ZIP: "Test",
    SALER_NAME: "Test",
    SALER_COMPANY_NAME: "Test",
    SALER_EMAIL: "Test",
    SALER_GEMH: "Test",
    SALER_VAT: "Test",
    TAX_REPRESENTATIVE: "Test",
    TAX_REPRESENTATIVE_VAT: "Test",
    LOCATION_NAME: "Test",
    LOCATION_ADDRESS: "Test",
    LOCATION_CITY: "Test",
    LOCATION_ZIP: "Test",
    PAYMENT_METHOD: "Test",
    PURPOSE: "Test",
    HIGHLIGHT: "Test",
    CORRELATION: "Test",
    DISPATCH_TIME: "Test",
    VEHICLE_NUMBER: "Test",
    PRODUCT_DETAILS: [
        {
            CODE: "PC-156",
            PRODUCT_DESCRIPTION: "TETWTEST ",
            QTY: "5",
            PRICE_QTY: "1",
            VAT: "45",
            DISCOUNT: "5465",
            PRICE_NOVAT: "546",
            VAT_PRICE: "5456",
            OTHER_TAXES: "54456",
            TOTAL: "546",
        }
    ],
    VAT_TABLE: [{
        TAX_PERCENT: "30%",
        TAX_CODE: "14",
        NET_VALUE: "14",
        TAX_VALUE: "14",
        TOTAL_VALUE: "14",
        OTHER_TAXES: "14",
    }],
    VATEXPCAT: "464654564",
    VATEXPCATCODE: "345454",
    INVOICE_NOTES: "dsfgdfgd dfgdfgsdg dsdfgsdfgsd sdfgdsfg sdfgsdfg",
    TOTAL_QTY: "10",
    TOTAL_WITHOUT_DISCOUNT: "0",
    TOTAL_DISCOUNT: "0",
    TOTAL_NOVAT: "0",
    TOTAL_VAT: "0",
    PRICE_WITHVAT: "0",
    TOTAL_PRICE: "1000",
}
