export const clientsCategoryData = [
    {
        _id: 1,
        category: "Retail",
        maskCode: "RTL-****",
        maskCodePrefix: 'RTL',
        maskCodeCounter: 1,
        isActive: 'active',
        type: 'client',
    },
    {
        _id: 2,
        category: "Freelancer",
        maskCode: "FRL-****",
        maskCodePrefix: 'FRL',
        maskCodeCounter: 1,
        isActive: 'active',
        type: 'client',
    },
    {
        _id: 3,
        category: "O.E.",
        maskCode: "OE-****",
        maskCodePrefix: 'OE',
        maskCodeCounter: 1,
        isActive: 'active',
        type: 'client',
    },
    {
        _id: 4,
        category: "E.E.",
        maskCode: "EE-****",
        maskCodePrefix: 'EE',
        maskCodeCounter: 1,
        isActive: 'active',
        type: 'client',
    },
    {
        _id: 5,
        category: "E.P.E.",
        maskCode: "EPE-****",
        maskCodePrefix: 'EPE',
        maskCodeCounter: 1,
        isActive: 'active',
        type: 'client',
    },
    {
        _id: 6,
        category: "I.K.E.",
        maskCode: "IKE-****",
        maskCodePrefix: 'IKE',
        maskCodeCounter: 1,
        isActive: 'active',
        type: 'client',
    },
    {
        _id: 7,
        category: "A.E.",
        maskCode: "AE-****",
        maskCodePrefix: 'AE',
        maskCodeCounter: 1,
        isActive: 'active',
        type: 'client',
    },
    {
        _id: 8,
        category: "Patient",
        maskCode: "PTN-****",
        maskCodePrefix: 'PTN',
        maskCodeCounter: 1,
        isActive: 'active',
        type: 'client',
    },
    {
        _id: 9,
        category: "Non-Profit Organization",
        maskCode: "NPO-****",
        maskCodePrefix: 'NPO',
        maskCodeCounter: 1,
        isActive: 'active',
        type: 'client',
    },
    {
        _id: 10,
        category: "Client within E.U.",
        maskCode: "CWEU-****",
        maskCodePrefix: 'CWEU',
        maskCodeCounter: 1,
        isActive: 'active',
        type: 'client',
    },
    {
        _id: 11,
        category: "Client outside E.U.",
        maskCode: "COEU-****",
        maskCodePrefix: 'COEU',
        maskCodeCounter: 1,
        isActive: 'active',
        type: 'client',
    },
    {
        _id: 12,
        category: "Wholesale Others",
        maskCode: "WO-****",
        maskCodePrefix: 'WO',
        maskCodeCounter: 1,
        isActive: 'active',
        type: 'client',
    },
]
