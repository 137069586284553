import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {getObjectFromArrayOfObjects} from "../../../../_helpers/helperFunctions";
import {useSelector} from "react-redux";
import SearchTable from "../../../primer/Search-Table";
import {getEmployeeTypeOptions, getOptionsByData} from "../../../../_helpers/commonFunctions";
import {classicStyleBelowNavbar} from "../Statics";

const SearchEmployee = () => {
    const [t] = useTranslation('common');
    const installations = useSelector((state) => state.COMPANY_DATA.companyInstallations);
    const companyInstallations = [{ value: "", label: "Όλα", color: "#76AEB7" }, ...getOptionsByData(installations, "installationMasterId", "address")];
    const searchEmployeeFilters = {
        filters: [
            {
                name: "firstName",
                label: t("EmployeeSearch.filters.firstName"),
                type: "input",
            },
            {
                name: "lastName",
                label: t("EmployeeSearch.filters.lastName"),
                type: "input",
            },
            {
                name: "installation",
                label: t("EmployeeSearch.filters.installation"),
                type: "dropdown",
                options: companyInstallations
            },
            {
                name: "vatNumber",
                label: t("EmployeeSearch.filters.vatNumber"),
                type: "input",
            },
            {
                name: "employeeType",
                label: t("EmployeeSearch.filters.employeeType"),
                type: "multiselect",
                options: getEmployeeTypeOptions()
            },
            {
                name: "isActive",
                label: t("EmployeeSearch.filters.isActive"),
                type: "checkbox",
            },
        ]
    };
    const scColumns = [
        {
            name: t('EmployeeSearch.columns.firstName'),
            field: "firstName",
            width: "10%",
            editable: false,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t('EmployeeSearch.columns.lastName'),
            field: "lastName",
            width: "10%",
            hiddenColumn: false,
            editable: false,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t('EmployeeSearch.columns.employeeType'),
            field: "employeeType",
            editable:false,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t('EmployeeSearch.columns.installation'),
            field: "installation",
            editable: false,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t('EmployeeSearch.columns.employmentDate'),
            field: "employmentDate",
            editable: false,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t('EmployeeSearch.columns.resignationDate'),
            field: "resignationDate",
            hiddenColumn: true,
            editable: false,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t('EmployeeSearch.columns.resignationCause'),
            field: "resignationCause",
            hiddenColumn: true,
            editable: false,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t('EmployeeSearch.columns.birthDate'),
            field: "birthDate",
            hiddenColumn: true,
            editable: false,
            inputType: {
                type: 'date',
            }
        },
        {
            name: t('EmployeeSearch.columns.vatNumber'),
            field: "vatNumber",
            editable: false,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t('EmployeeSearch.columns.AMKA'),
            field: "amka",
            hiddenColumn: true,
            editable: false,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t('EmployeeSearch.columns.AMIKA'),
            field: "amika",
            hiddenColumn: true,
            editable: false,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t('EmployeeSearch.columns.salary'),
            field: "salary",
            editable: true,
            disabled: true,
            inputType: {
                type: 'accounting',
                config: {
                    suffix: "€",
                }
            }
        },
    ]

    const [tableColumns, setTableColumns] = useState(scColumns.slice());

    // It will hide or show the table columns for searchtable.
    const handleColumnShowHide = (field) => {
        let tempTc = tableColumns.slice();
        let object = getObjectFromArrayOfObjects(tempTc, field, 'field')
        const index = tempTc.findIndex((item) => field === item.field);
        if (object.length > 0) {
            tempTc[index].hiddenColumn = !object['0'].hiddenColumn;
            setTableColumns(tempTc);
        }
    }
    return (
        <div style={classicStyleBelowNavbar}>
            <SearchTable
                tableName="Employees Search"
                key={"Employees Search"}
                hideYearFilter={true}
                allowPagination={true}
                allowToggleColumns={true}
                toggleColumns={handleColumnShowHide}
                allowPrintResults={true}
                columns={tableColumns}
                data={[]}
                filtersConfig={searchEmployeeFilters}
            />
        </div>
    )
}
export default SearchEmployee;
