export const preNumberGreek = [{label: "Κενό", value: "noprefix"}, {label: "A", value: "Α"}, {
    label: "B",
    value: "B"
}, {label: "Γ", value: "Γ"}, {label: "∆", value: "Δ"}, {label: "E", value: "Ε"}, {label: "Z", value: "Ζ"}, {
    label: "H",
    value: "Η"
}, {label: "Θ", value: "Θ"}, {label: "I", value: "Ι"}, {label: "K", value: "Κ"}, {label: "Λ", value: "Λ"}, {
    label: "M",
    value: "M"
}, {label: "N", value: "N"}, {label: "Ξ", value: "Ξ"}, {label: "O", value: "O"}, {label: "Π", value: "Π"}, {
    label: "P",
    value: "P"
}, {label: "Σ", value: "Σ"}, {label: "T", value: "T"}, {label: "Υ", value: "Υ"}, {label: "Φ", value: "Φ"}, {
    label: "X",
    value: "X"
}, {label: "Ψ", value: "Ψ"}, {label: "Ω", value: "Ω"}]

export const numberingModeOptions = [{
    value: 'automatic',
    label: 'Αυτόματη'
}, {
    value: 'manual',
    label: 'Χειροκίνητη'
}]

export const installationsOptions = [{
    value: 1,
    label: 'Office Address Main'
}, {
    value: 2,
    label: 'Office Address Subsidiary 1'
},
    {
        value: 3,
        label: 'Office Address Subsidiary 2'
    },
    {
        value: 4,
        label: 'Office Address Subsidiary 3'
    },
    {
        value: 5,
        label: 'Office Address Subsidiary 5'
    }, {
        value: 6,
        label: 'Office Address Subsidiary 6'
    },
    {
        value: 7,
        label: 'Office Address Subsidiary 7'
    },
    {
        value: 8,
        label: 'Office Address Subsidiary 8'
    },
    {
        value: 9,
        label: 'Office Address Subsidiary 9'
    }, {
        value: 10,
        label: 'Office Address Subsidiary 10'
    },
    {
        value: 11,
        label: 'Office Address Subsidiary 11'
    },
    {
        value: 12,
        label: 'Office Address Subsidiary 12'
    },
    {
        value: 13,
        label: 'Office Address Subsidiary 13'
    }, {
        value: 14,
        label: 'Office Address Subsidiary 14'
    },
    {
        value: 15,
        label: 'Office Address Subsidiary 15'
    },
    {
        value: 16,
        label: 'Office Address Subsidiary 16'
    },
    {
        value: 17,
        label: 'Office Address Subsidiary 17'
    },
    {
        value: 18,
        label: 'Office Address Subsidiary 18'
    },
    {
        value: 19,
        label: 'Office Address Subsidiary 19'
    },
    {
        value: 20,
        label: 'Office Address Subsidiary 20'
    },
]
