import Components from "../_components/common/Components/";
import DataPanel from "../_components/primer/Data-Panel";
import * as config from "../_components/primer/Data-Panel/config";
import Start from "../_components/layouts/data-panel/start";
import SearchProducts from "../_components/layouts/data-panel/products/search-products";
import SearchProductMovements from "../_components/layouts/data-panel/products/search-product-movements";
import SearchProductPriceList from "../_components/layouts/data-panel/products/search-product-pricelist";
import AccountingReportIsozygio from "../_components/layouts/data-panel/Accounting/accounting-report-isozygio";
import AccountingReportAccounts from "../_components/layouts/data-panel/Accounting/accounting-report-accounts";
import {AdvancedTypesSearch, BasicTypesSearch} from "../_components/layouts/data-panel/Settings-Document-Types";
import SearchClient from "../_components/layouts/data-panel/Clients/search-client";
import SearchSupplier from "../_components/layouts/data-panel/Suppliers/search-supplier";
import ClientMovementsSearch from "../_components/layouts/data-panel/Clients/client-movements-search";
import SupplierMovementsSearch from "../_components/layouts/data-panel/Suppliers/supplier-movements-search";
import SalesSearch from "../_components/layouts/data-panel/Sales/sales-search";
import BuysSearch from "../_components/layouts/data-panel/Buys/buys-search";
import QuickRetailSalesSearch from "../_components/layouts/data-panel/Sales/quick-retail-sales-search";
import ClientPaymentsSearch from "../_components/layouts/data-panel/Sales/client-payments-search";
import SupplierPaymentsSearch from "../_components/layouts/data-panel/Buys/supplier-payments-search";
import AutomaticSalesSearch from "../_components/layouts/data-panel/Sales/automatic-sales-search";
import POSSearch from "../_components/layouts/data-panel/Cash/pos-search";
import BankSearch from "../_components/layouts/data-panel/Cash/bank-search";
import CashRegistersSearch from "../_components/layouts/data-panel/Cash/cash-registers-search";
import SearchEmployee from "../_components/layouts/data-panel/Payroll/employee-search";
import SearchAccountingAccount from "../_components/layouts/data-panel/Accounting/accounting-account-search";
import {SearchAccountingRecord} from "../_components/layouts/data-panel/Accounting/accounting-record-search";
import SettingsExpenses from "../_components/layouts/data-panel/Settings/settings-expenses";
import AccountingReportCalendar from "../_components/layouts/data-panel/Accounting/accounting-report-calendar";
import ProductMovementsReports from "../_components/layouts/data-panel/products/product-movments-reports";
import {AccountingBridgeSettings} from "../_components/layouts/data-panel/Accounting/accounting-bridge-settings";
import {ReportsPrimerPayments} from "../_components/layouts/data-panel/Clients/reports-primer-payments";
import {CDRViewer} from "../_components/layouts/data-panel/CRM/cdr-viewer";
import AccountingYearReport from "../_components/layouts/data-panel/Accounting/accounting-year-report";
import PrimerClientPayments from "../_components/layouts/data-panel/Sales/primer-client-payments";
import ClientBalance from "../_components/layouts/data-panel/Clients/reports-client-balance";
import ClientsMovementsReport from "../_components/layouts/data-panel/Clients/reports-clients-movements";
import EmronClientPayments from "../_components/layouts/data-panel/Sales/emron-client-payments";
import {UserSettings} from "../_components/layouts/data-panel/UserSettings";
import SalesBalanceSheet from "../_components/layouts/data-panel/Sales/sales-balance-sheet";
import SalesClientPaymentReport from "../_components/layouts/data-panel/Sales/sales-client-payment-report";
import BuysBalanceSheet from "../_components/layouts/data-panel/Buys/buys-balance-sheet";
import BuysSupplierPaymentReport from "../_components/layouts/data-panel/Buys/buys-supplier-payment-report";
import {ReportsEmronSettlements} from "../_components/layouts/data-panel/Clients/reports-emron-settlements";
import SalesGraphicReport from "../_components/layouts/data-panel/Sales/sales-graphic-report";
import BuysGraphicReport from "../_components/layouts/data-panel/Buys/buys-graphic-report";
import SuppliersMovementsReport from "../_components/layouts/data-panel/Suppliers/reports-supplier-movements";
import WorkingProgram from "../_components/layouts/data-panel/Payroll/Components/working-program";
import EmployeeWorkingProgram from "../_components/layouts/data-panel/Payroll/Components/employee-working-program";
import SearchWorkMovements from "../_components/layouts/data-panel/Payroll/search-work-movements";
import MasterLogSearch from "../_components/layouts/data-panel/Settings/master-log-search";
import PayrollQrScanner from "../_components/layouts/data-panel/Payroll/payroll-qr-scanner";
import ErganiSubmissionsReport from "../_components/layouts/data-panel/Payroll/ergani-submissions-report";
import EmployeeView from "../_components/layouts/data-panel/Payroll/employee-view";
import SearchWorkPermits from "../_components/layouts/data-panel/Payroll/search-work-permits";
import ReportClientIsozygio from "../_components/layouts/data-panel/Clients/report-client-isozygio";
import ReportSupplierIsozygio from "../_components/layouts/data-panel/Suppliers/reports-supplier-isozygio";

export const checkViewFunc = (tab) => {
  // console.log('tab______', tab);
  switch (tab) {
    // PRODUCTS SECTION CASES
    case "products-new":
      return (
        <DataPanel
          company={config.products_DP_JSON.company}
          templateEnable={config.products_DP_JSON.templateEnable}
          tabs={config.products_DP_JSON.tabs}
          deleteBtn={config.products_DP_JSON.deleteBtn}
          saveBtn={config.products_DP_JSON.saveBtn}
          saveandrepeat={config.products_DP_JSON.saveandrepeat}
          headerNew={config.products_DP_JSON.header.headerNew}
          headerEdited={config.products_DP_JSON.header.headerEdited}
          templateType = {config.products_DP_JSON.templateType}
        />
      );

    case "edit-product":
      return (
        <DataPanel
          company={config.edit_Product_DP_JSON.company}
          templateEnable={config.edit_Product_DP_JSON.templateEnable}
          tabs={config.edit_Product_DP_JSON.tabs}
          deleteBtn={config.edit_Product_DP_JSON.deleteBtn}
          saveBtn={config.edit_Product_DP_JSON.saveBtn}
          saveandrepeat={config.edit_Product_DP_JSON.saveandrepeat}
          headerNew={config.edit_Product_DP_JSON.header.headerNew}
          headerEdited={config.edit_Product_DP_JSON.header.headerEdited}
          templateType = {config.edit_Product_DP_JSON.templateType}
        />
      );

    case "products-search":
      return (
        <SearchProducts />
      );
    case "client-balance":
      return (
          <ClientBalance />
      )
    case "product-movements-new":
      return (
        <DataPanel
          company={config.productsMovements_DP_JSON.company}
          templateEnable={config.productsMovements_DP_JSON.templateEnable}
          tabs={config.productsMovements_DP_JSON.tabs}
          deleteBtn={config.productsMovements_DP_JSON.deleteBtn}
          saveBtn={config.productsMovements_DP_JSON.saveBtn}
          saveandrepeat={config.productsMovements_DP_JSON.saveandrepeat}
          headerNew={config.productsMovements_DP_JSON.header.headerNew}
          headerEdited={config.productsMovements_DP_JSON.header.headerEdited}
          templateType = {config.productsMovements_DP_JSON.templateType}
        />
      );

      case "edit-product-movements":
      return (
        <DataPanel
          company={config.editProductsMovements_DP_JSON.company}
          templateEnable={config.editProductsMovements_DP_JSON.templateEnable}
          tabs={config.editProductsMovements_DP_JSON.tabs}
          deleteBtn={config.editProductsMovements_DP_JSON.deleteBtn}
          saveBtn={config.editProductsMovements_DP_JSON.saveBtn}
          saveandrepeat={config.editProductsMovements_DP_JSON.saveandrepeat}
          headerNew={config.editProductsMovements_DP_JSON.header.headerNew}
          headerEdited={config.editProductsMovements_DP_JSON.header.headerEdited}
          templateType = {config.editProductsMovements_DP_JSON.templateType}
        />
      );

    case "product-movements-search":
      return (
        <SearchProductMovements />
      );

    case "product-pricelist-new":
      return (
        <DataPanel
          company={config.pricelist_DP_JSON.company}
          templateEnable={config.pricelist_DP_JSON.templateEnable}
          tabs={config.pricelist_DP_JSON.tabs}
          deleteBtn={config.pricelist_DP_JSON.deleteBtn}
          saveBtn={config.pricelist_DP_JSON.saveBtn}
          saveandrepeat={config.pricelist_DP_JSON.saveandrepeat}
          headerNew={config.pricelist_DP_JSON.header.headerNew}
          headerEdited={config.pricelist_DP_JSON.header.headerEdited}
          templateType = {config.pricelist_DP_JSON.templateType}
        />
      );

    case "edit-price-list":
      return (
        <DataPanel
          company={config.editPricelist_DP_JSON.company}
          templateEnable={config.editPricelist_DP_JSON.templateEnable}
          tabs={config.editPricelist_DP_JSON.tabs}
          deleteBtn={config.editPricelist_DP_JSON.deleteBtn}
          saveBtn={config.editPricelist_DP_JSON.saveBtn}
          saveandrepeat={config.editPricelist_DP_JSON.saveandrepeat}
          headerNew={config.editPricelist_DP_JSON.header.headerNew}
          headerEdited={config.editPricelist_DP_JSON.header.headerEdited}
          templateType = {config.editPricelist_DP_JSON.templateType}
        />
      );

    case "pricelist-search":
      return (
        <SearchProductPriceList />
      );

    case "product-balancesheet-reports":
      return (
        <DataPanel
          company={config.reportsBalanceSheetMovements_DP_JSON.company}
          templateEnable={config.reportsBalanceSheetMovements_DP_JSON.templateEnable}
          templateType={config.reportsBalanceSheetMovements_DP_JSON.templateType}
          tabs={config.reportsBalanceSheetMovements_DP_JSON.tabs}
          deleteBtn={config.reportsBalanceSheetMovements_DP_JSON.deleteBtn}
          saveBtn={config.reportsBalanceSheetMovements_DP_JSON.saveBtn}
          saveandrepeat={config.reportsBalanceSheetMovements_DP_JSON.saveandrepeat}
          headerNew={config.reportsBalanceSheetMovements_DP_JSON.header.headerNew}
          headerEdited={config.reportsBalanceSheetMovements_DP_JSON.header.headerEdited}
        />
    );
    case "product-movments-reports":
      return (
        <ProductMovementsReports />
    );
    case "products-settings-product-categories":
      return (
        <DataPanel
          company={config.productSettings_Movements_DP_JSON.company}
          templateEnable={config.productSettings_Movements_DP_JSON.templateEnable}
          templateType={config.productSettings_Movements_DP_JSON.templateType}
          tabs={config.productSettings_Movements_DP_JSON.tabs}
          deleteBtn={config.productSettings_Movements_DP_JSON.deleteBtn}
          saveBtn={config.productSettings_Movements_DP_JSON.saveBtn}
          saveandrepeat={config.productSettings_Movements_DP_JSON.saveandrepeat}
          headerNew={config.productSettings_Movements_DP_JSON.header.headerNew}
          headerEdited={config.productSettings_Movements_DP_JSON.header.headerEdited}
        />
      );

    case "products-settings-product-settings":
      return (
        <DataPanel
          company={config.productSettings_Settings_DP_JSON.company}
          templateEnable={config.productSettings_Settings_DP_JSON.templateEnable}
          templateType={config.productSettings_Settings_DP_JSON.templateType}
          tabs={config.productSettings_Settings_DP_JSON.tabs}
          deleteBtn={config.productSettings_Settings_DP_JSON.deleteBtn}
          saveBtn={config.productSettings_Settings_DP_JSON.saveBtn}
          saveandrepeat={config.productSettings_Settings_DP_JSON.saveandrepeat}
          headerNew={config.productSettings_Settings_DP_JSON.header.headerNew}
          headerEdited={config.productSettings_Settings_DP_JSON.header.headerEdited}
        />
      );

    case "accounting-settings":
      return (
          <DataPanel
              company={config.accounting_account_settings_DP_JSON.company}
              templateEnable={config.accounting_account_settings_DP_JSON.templateEnable}
              templateType={config.accounting_account_settings_DP_JSON.templateType}
              tabs={config.accounting_account_settings_DP_JSON.tabs}
              deleteBtn={config.accounting_account_settings_DP_JSON.deleteBtn}
              saveBtn={config.accounting_account_settings_DP_JSON.saveBtn}
              saveandrepeat={config.accounting_account_settings_DP_JSON.saveandrepeat}
              headerNew={config.accounting_account_settings_DP_JSON.header.headerNew}
              headerEdited={config.accounting_account_settings_DP_JSON.header.headerEdited}
          />
      );

    case "accounting-record-settings":
      return (
          <DataPanel
              company={config.accounting_record_settings_DP_JSON.company}
              templateEnable={config.accounting_record_settings_DP_JSON.templateEnable}
              templateType={config.accounting_record_settings_DP_JSON.templateType}
              tabs={config.accounting_record_settings_DP_JSON.tabs}
              deleteBtn={config.accounting_record_settings_DP_JSON.deleteBtn}
              saveBtn={config.accounting_record_settings_DP_JSON.saveBtn}
              saveandrepeat={config.accounting_record_settings_DP_JSON.saveandrepeat}
              headerNew={config.accounting_record_settings_DP_JSON.header.headerNew}
              headerEdited={config.accounting_record_settings_DP_JSON.header.headerEdited}
          />
      );

    case "components":
      return <Components />;

    case "primer-my-data":
      return (
        <DataPanel
          company={config.settingsPrimerMyData.company}
          templateEnable={config.settingsPrimerMyData.templateEnable}
            templateType={config.settingsPrimerMyData.templateType}
          tabs={config.settingsPrimerMyData.tabs}
          deleteBtn={config.settingsPrimerMyData.deleteBtn}
          saveBtn={config.settingsPrimerMyData.saveBtn}
          saveandrepeat={config.settingsPrimerMyData.saveandrepeat}
          headerNew={config.settingsPrimerMyData.header.headerNew}
          headerEdited={config.settingsPrimerMyData.header.headerEdited}
        />
      );

      case "primer-my-data-data":
        return (
          <DataPanel
            company={config.settingsPrimerData.company}
            templateEnable={config.settingsPrimerData.templateEnable}
            templateType={config.settingsPrimerData.templateType}
            tabs={config.settingsPrimerData.tabs}
            deleteBtn={config.settingsPrimerData.deleteBtn}
            saveBtn={config.settingsPrimerData.saveBtn}
            saveandrepeat={config.settingsPrimerData.saveandrepeat}
            headerNew={config.settingsPrimerData.header.headerNew}
            headerEdited={config.settingsPrimerData.header.headerEdited}
          />
        );

      case "basic-types-new":
        return (
          <DataPanel
            company={config.settingsDocumentTypesBasicTypesNew.company}
            templateEnable={config.settingsDocumentTypesBasicTypesNew.templateEnable}
            templateType={config.settingsDocumentTypesBasicTypesNew.templateType}
            tabs={config.settingsDocumentTypesBasicTypesNew.tabs}
            deleteBtn={config.settingsDocumentTypesBasicTypesNew.deleteBtn}
            saveBtn={config.settingsDocumentTypesBasicTypesNew.saveBtn}
            saveandrepeat={config.settingsDocumentTypesBasicTypesNew.saveandrepeat}
            headerNew={config.settingsDocumentTypesBasicTypesNew.header.headerNew}
            headerEdited={config.settingsDocumentTypesBasicTypesNew.header.headerEdited}
          />
      );

      case "edit-basic-types":
        return (
          <DataPanel
            company={config.settingsDocumentTypesEditBasicTypes.company}
            templateEnable={config.settingsDocumentTypesEditBasicTypes.templateEnable}
            templateType={config.settingsDocumentTypesEditBasicTypes.templateType}
            tabs={config.settingsDocumentTypesEditBasicTypes.tabs}
            deleteBtn={config.settingsDocumentTypesEditBasicTypes.deleteBtn}
            saveBtn={config.settingsDocumentTypesEditBasicTypes.saveBtn}
            saveandrepeat={config.settingsDocumentTypesEditBasicTypes.saveandrepeat}
            headerNew={config.settingsDocumentTypesEditBasicTypes.header.headerNew}
            headerEdited={config.settingsDocumentTypesEditBasicTypes.header.headerEdited}
          />
      );

      case "basic-types-search":
        return (
            <BasicTypesSearch />
        );
      case "advanced-types-new":
        return (
          <DataPanel
            company={config.settingsDocumentTypesAdvancedTypesNew.company}
            templateEnable={config.settingsDocumentTypesAdvancedTypesNew.templateEnable}
            templateType={config.settingsDocumentTypesAdvancedTypesNew.templateType}
            tabs={config.settingsDocumentTypesAdvancedTypesNew.tabs}
            deleteBtn={config.settingsDocumentTypesAdvancedTypesNew.deleteBtn}
            saveBtn={config.settingsDocumentTypesAdvancedTypesNew.saveBtn}
            saveandrepeat={config.settingsDocumentTypesAdvancedTypesNew.saveandrepeat}
            headerNew={config.settingsDocumentTypesAdvancedTypesNew.header.headerNew}
            headerEdited={config.settingsDocumentTypesAdvancedTypesNew.header.headerEdited}
          />
      );
      case "edit-advanced-types":
        return (
          <DataPanel
            company={config.settingsDocumentTypesEditAdvancedTypes.company}
            templateEnable={config.settingsDocumentTypesEditAdvancedTypes.templateEnable}
            templateType={config.settingsDocumentTypesEditAdvancedTypes.templateType}
            tabs={config.settingsDocumentTypesEditAdvancedTypes.tabs}
            deleteBtn={config.settingsDocumentTypesEditAdvancedTypes.deleteBtn}
            saveBtn={config.settingsDocumentTypesEditAdvancedTypes.saveBtn}
            saveandrepeat={config.settingsDocumentTypesEditAdvancedTypes.saveandrepeat}
            headerNew={config.settingsDocumentTypesEditAdvancedTypes.header.headerNew}
            headerEdited={config.settingsDocumentTypesEditAdvancedTypes.header.headerEdited}
          />
      );
      case "advanced-types-search":
        return (
            <AdvancedTypesSearch />
        );
      case "company-settings":
        return (
          <DataPanel
            company={config.settingsGeneralSettingsCompanySettings.company}
            templateEnable={config.settingsGeneralSettingsCompanySettings.templateEnable}
            templateType={config.settingsGeneralSettingsCompanySettings.templateType}
            tabs={config.settingsGeneralSettingsCompanySettings.tabs}
            deleteBtn={config.settingsGeneralSettingsCompanySettings.deleteBtn}
            saveBtn={config.settingsGeneralSettingsCompanySettings.saveBtn}
            saveandrepeat={config.settingsGeneralSettingsCompanySettings.saveandrepeat}
            headerNew={config.settingsGeneralSettingsCompanySettings.header.headerNew}
            headerEdited={config.settingsGeneralSettingsCompanySettings.header.headerEdited}
          />
      );
      case "system-tasks":
        return (
          <DataPanel
            company={config.settingsGeneralSettingsSystemTasks.company}
            templateEnable={config.settingsGeneralSettingsSystemTasks.templateEnable}
            templateType={config.settingsGeneralSettingsSystemTasks.templateType}
            tabs={config.settingsGeneralSettingsSystemTasks.tabs}
            deleteBtn={config.settingsGeneralSettingsSystemTasks.deleteBtn}
            saveBtn={config.settingsGeneralSettingsSystemTasks.saveBtn}
            saveandrepeat={config.settingsGeneralSettingsSystemTasks.saveandrepeat}
            headerNew={config.settingsGeneralSettingsSystemTasks.header.headerNew}
            headerEdited={config.settingsGeneralSettingsSystemTasks.header.headerEdited}
          />
      );
      case "email-smtp":
        return (
          <DataPanel
            company={config.settingsGeneralSettingsEmailSmtp.company}
            templateEnable={config.settingsGeneralSettingsEmailSmtp.templateEnable}
            templateType={config.settingsGeneralSettingsEmailSmtp.templateType}
            tabs={config.settingsGeneralSettingsEmailSmtp.tabs}
            deleteBtn={config.settingsGeneralSettingsEmailSmtp.deleteBtn}
            saveBtn={config.settingsGeneralSettingsEmailSmtp.saveBtn}
            saveandrepeat={config.settingsGeneralSettingsEmailSmtp.saveandrepeat}
            headerNew={config.settingsGeneralSettingsEmailSmtp.header.headerNew}
            headerEdited={config.settingsGeneralSettingsEmailSmtp.header.headerEdited}
          />
      );
      case "new-print-template":
        return (
          <DataPanel
            company={config.settingsPrintTemplatesNew.company}
            templateEnable={config.settingsPrintTemplatesNew.templateEnable}
            templateType={config.settingsDocumentTypesBasicTypesNew.templateType}
            tabs={config.settingsPrintTemplatesNew.tabs}
            deleteBtn={config.settingsPrintTemplatesNew.deleteBtn}
            saveBtn={config.settingsPrintTemplatesNew.saveBtn}
            saveandrepeat={config.settingsPrintTemplatesNew.saveandrepeat}
            headerNew={config.settingsPrintTemplatesNew.header.headerNew}
            headerEdited={config.settingsPrintTemplatesNew.header.headerEdited}
          />
      );
      case "search-print-template":
        return (
          <DataPanel
            company={config.settingsPrintTemplatesSearch.company}
            templateEnable={config.settingsPrintTemplatesSearch.templateEnable}
            templateType={config.settingsDocumentTypesBasicTypesNew.templateType}
            tabs={config.settingsPrintTemplatesSearch.tabs}
            deleteBtn={config.settingsPrintTemplatesSearch.deleteBtn}
            saveBtn={config.settingsPrintTemplatesSearch.saveBtn}
            saveandrepeat={config.settingsPrintTemplatesSearch.saveandrepeat}
            headerNew={config.settingsPrintTemplatesSearch.header.headerNew}
            headerEdited={config.settingsPrintTemplatesSearch.header.headerEdited}
          />
      );
      case "settings-import":
        return (
          <DataPanel
            company={config.settingsImport.company}
            templateEnable={config.settingsImport.templateEnable}
            templateType={config.settingsDocumentTypesBasicTypesNew.templateType}
            tabs={config.settingsImport.tabs}
            deleteBtn={config.settingsImport.deleteBtn}
            saveBtn={config.settingsImport.saveBtn}
            saveandrepeat={config.settingsImport.saveandrepeat}
            headerNew={config.settingsImport.header.headerNew}
            headerEdited={config.settingsImport.header.headerEdited}
          />
      );
      case "settings-export":
        return (
          <DataPanel
            company={config.settingsExport.company}
            templateEnable={config.settingsExport.templateEnable}
            templateType={config.settingsDocumentTypesBasicTypesNew.templateType}
            tabs={config.settingsExport.tabs}
            deleteBtn={config.settingsExport.deleteBtn}
            saveBtn={config.settingsExport.saveBtn}
            saveandrepeat={config.settingsExport.saveandrepeat}
            headerNew={config.settingsExport.header.headerNew}
            headerEdited={config.settingsExport.header.headerEdited}
          />
      );
    case "settings-backup":
      return (
          <DataPanel
              company={config.settingsBackup.company}
              templateEnable={config.settingsBackup.templateEnable}
              templateType={config.settingsDocumentTypesBasicTypesNew.templateType}
              tabs={config.settingsBackup.tabs}
              deleteBtn={config.settingsBackup.deleteBtn}
              saveBtn={config.settingsBackup.saveBtn}
              saveandrepeat={config.settingsBackup.saveandrepeat}
              headerNew={config.settingsBackup.header.headerNew}
              headerEdited={config.settingsBackup.header.headerEdited}
          />
      );
    case "settings-restore":
      return (
          <DataPanel
              company={config.settingsRestore.company}
              templateEnable={config.settingsRestore.templateEnable}
              templateType={config.settingsDocumentTypesBasicTypesNew.templateType}
              tabs={config.settingsRestore.tabs}
              deleteBtn={config.settingsRestore.deleteBtn}
              saveBtn={config.settingsRestore.saveBtn}
              saveandrepeat={config.settingsRestore.saveandrepeat}
              headerNew={config.settingsRestore.header.headerNew}
              headerEdited={config.settingsRestore.header.headerEdited}
          />
      );
      case "move-data-new":
        return (
          <DataPanel
            company={config.settingsMoveDataNew.company}
            templateEnable={config.settingsMoveDataNew.templateEnable}
            templateType={config.settingsDocumentTypesBasicTypesNew.templateType}
            tabs={config.settingsMoveDataNew.tabs}
            deleteBtn={config.settingsMoveDataNew.deleteBtn}
            saveBtn={config.settingsMoveDataNew.saveBtn}
            saveandrepeat={config.settingsMoveDataNew.saveandrepeat}
            headerNew={config.settingsMoveDataNew.header.headerNew}
            headerEdited={config.settingsMoveDataNew.header.headerEdited}
          />
      );
      case "move-data-search":
        return (
          <DataPanel
            company={config.settingsMoveDataSearch.company}
            templateEnable={config.settingsMoveDataSearch.templateEnable}
            templateType={config.settingsDocumentTypesBasicTypesNew.templateType}
            tabs={config.settingsMoveDataSearch.tabs}
            deleteBtn={config.settingsMoveDataSearch.deleteBtn}
            saveBtn={config.settingsMoveDataSearch.saveBtn}
            saveandrepeat={config.settingsMoveDataSearch.saveandrepeat}
            headerNew={config.settingsMoveDataSearch.header.headerNew}
            headerEdited={config.settingsMoveDataSearch.header.headerEdited}
          />
      );
      case "new-client":
        return (
          <DataPanel
            company={config.newClient_DP_JSON.company}
            templateEnable={config.newClient_DP_JSON.templateEnable}
            templateType={config.newClient_DP_JSON.templateType}
            tabs={config.newClient_DP_JSON.tabs}
            deleteBtn={config.newClient_DP_JSON.deleteBtn}
            saveBtn={config.newClient_DP_JSON.saveBtn}
            saveandrepeat={config.newClient_DP_JSON.saveandrepeat}
            headerNew={config.newClient_DP_JSON.header.headerNew}
            headerEdited={config.newClient_DP_JSON.header.headerEdited}
          />
      );

      case "new-supplier":
        return (
          <DataPanel
            company={config.newSupplier_DP_JSON.company}
            templateEnable={config.newSupplier_DP_JSON.templateEnable}
            templateType={config.newSupplier_DP_JSON.templateType}
            tabs={config.newSupplier_DP_JSON.tabs}
            deleteBtn={config.newSupplier_DP_JSON.deleteBtn}
            saveBtn={config.newSupplier_DP_JSON.saveBtn}
            saveandrepeat={config.newSupplier_DP_JSON.saveandrepeat}
            headerNew={config.newSupplier_DP_JSON.header.headerNew}
            headerEdited={config.newSupplier_DP_JSON.header.headerEdited}
          />
      );
      case "edit-client":
        return (
          <DataPanel
            company={config.editClient_DP_JSON.company}
            templateEnable={config.editClient_DP_JSON.templateEnable}
            templateType={config.editClient_DP_JSON.templateType}
            tabs={config.editClient_DP_JSON.tabs}
            deleteBtn={config.editClient_DP_JSON.deleteBtn}
            saveBtn={config.editClient_DP_JSON.saveBtn}
            saveandrepeat={config.editClient_DP_JSON.saveandrepeat}
            headerNew={config.editClient_DP_JSON.header.headerNew}
            headerEdited={config.editClient_DP_JSON.header.headerEdited}
          />
      );
      case "edit-supplier":
        return (
          <DataPanel
            company={config.editSupplier_DP_JSON.company}
            templateEnable={config.editSupplier_DP_JSON.templateEnable}
            templateType={config.editSupplier_DP_JSON.templateType}
            tabs={config.editSupplier_DP_JSON.tabs}
            deleteBtn={config.editSupplier_DP_JSON.deleteBtn}
            saveBtn={config.editSupplier_DP_JSON.saveBtn}
            saveandrepeat={config.editSupplier_DP_JSON.saveandrepeat}
            headerNew={config.editSupplier_DP_JSON.header.headerNew}
            headerEdited={config.editSupplier_DP_JSON.header.headerEdited}
          />
      );
      case "search-client":
        return (
            <SearchClient />
        );
      case "search-supplier":
        return (
            <SearchSupplier />
        );
      case "new-client-movements":
        return (
          <DataPanel
            company={config.clientMovementsNew_DP_JSON.company}
            templateEnable={config.clientMovementsNew_DP_JSON.templateEnable}
            templateType={config.clientMovementsNew_DP_JSON.templateType}
            tabs={config.clientMovementsNew_DP_JSON.tabs}
            deleteBtn={config.clientMovementsNew_DP_JSON.deleteBtn}
            saveBtn={config.clientMovementsNew_DP_JSON.saveBtn}
            saveandrepeat={config.clientMovementsNew_DP_JSON.saveandrepeat}
            headerNew={config.clientMovementsNew_DP_JSON.header.headerNew}
            headerEdited={config.clientMovementsNew_DP_JSON.header.headerEdited}
          />
      );
      case "edit-client-movements":
        return (
          <DataPanel
            company={config.editClientMovements_DP_JSON.company}
            templateEnable={config.editClientMovements_DP_JSON.templateEnable}
            templateType={config.editClientMovements_DP_JSON.templateType}
            tabs={config.editClientMovements_DP_JSON.tabs}
            deleteBtn={config.editClientMovements_DP_JSON.deleteBtn}
            saveBtn={config.editClientMovements_DP_JSON.saveBtn}
            saveandrepeat={config.editClientMovements_DP_JSON.saveandrepeat}
            headerNew={config.editClientMovements_DP_JSON.header.headerNew}
            headerEdited={config.editClientMovements_DP_JSON.header.headerEdited}
          />
      );
      case "new-supplier-movements":
        return (
          <DataPanel
            company={config.supplierMovementsNew_DP_JSON.company}
            templateEnable={config.supplierMovementsNew_DP_JSON.templateEnable}
            templateType={config.supplierMovementsNew_DP_JSON.templateType}
            tabs={config.supplierMovementsNew_DP_JSON.tabs}
            deleteBtn={config.supplierMovementsNew_DP_JSON.deleteBtn}
            saveBtn={config.supplierMovementsNew_DP_JSON.saveBtn}
            saveandrepeat={config.supplierMovementsNew_DP_JSON.saveandrepeat}
            headerNew={config.supplierMovementsNew_DP_JSON.header.headerNew}
            headerEdited={config.supplierMovementsNew_DP_JSON.header.headerEdited}
          />
      );
      case "edit-supplier-movements":
        return (
          <DataPanel
            company={config.editSupplierMovements_DP_JSON.company}
            templateEnable={config.editSupplierMovements_DP_JSON.templateEnable}
            templateType={config.editSupplierMovements_DP_JSON.templateType}
            tabs={config.editSupplierMovements_DP_JSON.tabs}
            deleteBtn={config.editSupplierMovements_DP_JSON.deleteBtn}
            saveBtn={config.editSupplierMovements_DP_JSON.saveBtn}
            saveandrepeat={config.editSupplierMovements_DP_JSON.saveandrepeat}
            headerNew={config.editSupplierMovements_DP_JSON.header.headerNew}
            headerEdited={config.editSupplierMovements_DP_JSON.header.headerEdited}
          />
      );
      case "search-client-movements":
        return (
            <ClientMovementsSearch />
        );
      case "search-supplier-movements":
        return (
            <SupplierMovementsSearch />
        );
    case "emron-payments":
      return (
          <DataPanel
              company={config.reportsEmronPayments_DP_JSON.company}
              templateEnable={config.reportsEmronPayments_DP_JSON.templateEnable}
              templateType={config.reportsEmronPayments_DP_JSON.templateType}
              tabs={config.reportsEmronPayments_DP_JSON.tabs}
              deleteBtn={config.reportsEmronPayments_DP_JSON.deleteBtn}
              saveBtn={config.reportsEmronPayments_DP_JSON.saveBtn}
              saveandrepeat={config.reportsEmronPayments_DP_JSON.saveandrepeat}
              headerNew={config.reportsEmronPayments_DP_JSON.header.headerNew}
              headerEdited={config.reportsEmronPayments_DP_JSON.header.headerEdited}
          />
      );
      case "clients-movements":
        return (
            <ClientsMovementsReport />
        )
      case "supplier-balance":
        return (
          <DataPanel
            company={config.reportsSupplierBalance_DP_JSON.company}
            templateEnable={config.reportsSupplierBalance_DP_JSON.templateEnable}
            templateType={config.reportsSupplierBalance_DP_JSON.templateType}
            tabs={config.reportsSupplierBalance_DP_JSON.tabs}
            deleteBtn={config.reportsSupplierBalance_DP_JSON.deleteBtn}
            saveBtn={config.reportsSupplierBalance_DP_JSON.saveBtn}
            saveandrepeat={config.reportsSupplierBalance_DP_JSON.saveandrepeat}
            headerNew={config.reportsSupplierBalance_DP_JSON.header.headerNew}
            headerEdited={config.reportsSupplierBalance_DP_JSON.header.headerEdited}
          />
      );
      case "suppliers-movements":
        return (
          <SuppliersMovementsReport />
      )
      case "clients-settings":
        return (
          <DataPanel
            company={config.settingsClientsSettings_DP_JSON.company}
            templateEnable={config.settingsClientsSettings_DP_JSON.templateEnable}
            templateType={config.settingsClientsSettings_DP_JSON.templateType}
            tabs={config.settingsClientsSettings_DP_JSON.tabs}
            deleteBtn={config.settingsClientsSettings_DP_JSON.deleteBtn}
            saveBtn={config.settingsClientsSettings_DP_JSON.saveBtn}
            saveandrepeat={config.settingsClientsSettings_DP_JSON.saveandrepeat}
            headerNew={config.settingsClientsSettings_DP_JSON.header.headerNew}
            headerEdited={config.settingsClientsSettings_DP_JSON.header.headerEdited}
          />
      );
      case "suppliers-settings":
        return (
          <DataPanel
            company={config.settingsSuppliersSettings_DP_JSON.company}
            templateEnable={config.settingsSuppliersSettings_DP_JSON.templateEnable}
            templateType={config.settingsSuppliersSettings_DP_JSON.templateType}
            tabs={config.settingsSuppliersSettings_DP_JSON.tabs}
            deleteBtn={config.settingsSuppliersSettings_DP_JSON.deleteBtn}
            saveBtn={config.settingsSuppliersSettings_DP_JSON.saveBtn}
            saveandrepeat={config.settingsSuppliersSettings_DP_JSON.saveandrepeat}
            headerNew={config.settingsSuppliersSettings_DP_JSON.header.headerNew}
            headerEdited={config.settingsSuppliersSettings_DP_JSON.header.headerEdited}
          />
      );
      case "sales-new":
        return (
          <DataPanel
            company={config.salesNew_DP_JSON.company}
            templateEnable={config.salesNew_DP_JSON.templateEnable}
            templateType={config.salesNew_DP_JSON.templateType}
            tabs={config.salesNew_DP_JSON.tabs}
            deleteBtn={config.salesNew_DP_JSON.deleteBtn}
            saveBtn={config.salesNew_DP_JSON.saveBtn}
            saveandrepeat={config.salesNew_DP_JSON.saveandrepeat}
            sendEmailOnSave={config.salesNew_DP_JSON.sendEmailOnSave}
            printLayoutDropdown={config.salesNew_DP_JSON.printLayoutDropdown}
            printBtn={config.salesNew_DP_JSON.printBtn}
            convertDocument={config.salesNew_DP_JSON.convertDocument}
            issueCreditDocument={config.salesNew_DP_JSON.issueCreditDocument}
            headerNew={config.salesNew_DP_JSON.header.headerNew}
            headerEdited={config.salesNew_DP_JSON.header.headerEdited}
          />
      );
      case "buys-new":
        return (
          <DataPanel
            company={config.buysNew_DP_JSON.company}
            templateEnable={config.buysNew_DP_JSON.templateEnable}
            templateType={config.buysNew_DP_JSON.templateType}
            tabs={config.buysNew_DP_JSON.tabs}
            deleteBtn={config.buysNew_DP_JSON.deleteBtn}
            saveBtn={config.buysNew_DP_JSON.saveBtn}
            saveandrepeat={config.buysNew_DP_JSON.saveandrepeat}
            sendEmailOnSave={config.buysNew_DP_JSON.sendEmailOnSave}
            printLayoutDropdown={config.buysNew_DP_JSON.printLayoutDropdown}
            printBtn={config.buysNew_DP_JSON.printBtn}
            convertDocument={config.buysNew_DP_JSON.convertDocument}
            issueCreditDocument={config.buysNew_DP_JSON.issueCreditDocument}
            headerNew={config.buysNew_DP_JSON.header.headerNew}
            headerEdited={config.buysNew_DP_JSON.header.headerEdited}
          />
      );
      case "view-sales":
        return (
          <DataPanel
            company={config.salesEdit_DP_JSON.company}
            templateEnable={config.salesEdit_DP_JSON.templateEnable}
            templateType={config.salesEdit_DP_JSON.templateType}
            tabs={config.salesEdit_DP_JSON.tabs}
            deleteBtn={config.salesEdit_DP_JSON.deleteBtn}
            saveBtn={config.salesEdit_DP_JSON.saveBtn}
            saveandrepeat={config.salesEdit_DP_JSON.saveandrepeat}
            sendEmailOnSave={config.salesEdit_DP_JSON.sendEmailOnSave}
            printLayoutDropdown={config.salesEdit_DP_JSON.printLayoutDropdown}
            printBtn={config.salesEdit_DP_JSON.printBtn}
            convertDocument={config.salesEdit_DP_JSON.convertDocument}
            issueCreditDocument={config.salesEdit_DP_JSON.issueCreditDocument}
            cancelMyDataInvoice={config.salesEdit_DP_JSON.cancelMyDataInvoice}
            headerNew={config.salesEdit_DP_JSON.header.headerNew}
            headerEdited={config.salesEdit_DP_JSON.header.headerEdited}
          />
      );
    case "view-buys":
        return (
          <DataPanel
            company={config.buysEdit_DP_JSON.company}
            templateEnable={config.buysEdit_DP_JSON.templateEnable}
            templateType={config.buysEdit_DP_JSON.templateType}
            tabs={config.buysEdit_DP_JSON.tabs}
            deleteBtn={config.buysEdit_DP_JSON.deleteBtn}
            saveBtn={config.buysEdit_DP_JSON.saveBtn}
            saveandrepeat={config.buysEdit_DP_JSON.saveandrepeat}
            sendEmailOnSave={config.buysEdit_DP_JSON.sendEmailOnSave}
            printLayoutDropdown={config.buysEdit_DP_JSON.printLayoutDropdown}
            printBtn={config.buysEdit_DP_JSON.printBtn}
            convertDocument={config.buysEdit_DP_JSON.convertDocument}
            issueCreditDocument={config.buysEdit_DP_JSON.issueCreditDocument}
            headerNew={config.buysEdit_DP_JSON.header.headerNew}
            headerEdited={config.buysEdit_DP_JSON.header.headerEdited}
            cancelMyDataInvoice={config.buysEdit_DP_JSON.cancelMyDataInvoice}
          />
      );
    case "edit-sales":
      return (
          <DataPanel
              company={config.salesEditTrue_DP_JSON.company}
              templateEnable={config.salesEditTrue_DP_JSON.templateEnable}
              templateType={config.salesEditTrue_DP_JSON.templateType}
              tabs={config.salesEditTrue_DP_JSON.tabs}
              deleteBtn={config.salesEditTrue_DP_JSON.deleteBtn}
              saveBtn={config.salesEditTrue_DP_JSON.saveBtn}
              saveandrepeat={config.salesEditTrue_DP_JSON.saveandrepeat}
              sendEmailOnSave={config.salesEditTrue_DP_JSON.sendEmailOnSave}
              printLayoutDropdown={config.salesEditTrue_DP_JSON.printLayoutDropdown}
              printBtn={config.salesEditTrue_DP_JSON.printBtn}
              convertDocument={config.salesEditTrue_DP_JSON.convertDocument}
              issueCreditDocument={config.salesEditTrue_DP_JSON.issueCreditDocument}
              cancelMyDataInvoice={config.salesEdit_DP_JSON.cancelMyDataInvoice}
              headerNew={config.salesEditTrue_DP_JSON.header.headerNew}
              headerEdited={config.salesEditTrue_DP_JSON.header.headerEdited}
          />
      );
      case "edit-buys":
        return (
            <DataPanel
                company={config.buysEditTrue_DP_JSON.company}
                templateEnable={config.buysEditTrue_DP_JSON.templateEnable}
                templateType={config.buysEditTrue_DP_JSON.templateType}
                tabs={config.buysEditTrue_DP_JSON.tabs}
                deleteBtn={config.buysEditTrue_DP_JSON.deleteBtn}
                saveBtn={config.buysEditTrue_DP_JSON.saveBtn}
                saveandrepeat={config.buysEditTrue_DP_JSON.saveandrepeat}
                sendEmailOnSave={config.buysEditTrue_DP_JSON.sendEmailOnSave}
                printLayoutDropdown={config.buysEditTrue_DP_JSON.printLayoutDropdown}
                printBtn={config.buysEditTrue_DP_JSON.printBtn}
                convertDocument={config.buysEditTrue_DP_JSON.convertDocument}
                issueCreditDocument={config.buysEditTrue_DP_JSON.issueCreditDocument}
                headerNew={config.buysEditTrue_DP_JSON.header.headerNew}
                headerEdited={config.buysEditTrue_DP_JSON.header.headerEdited}
            />
        );
      case "sales-search":
        return (
            <SalesSearch />
        );
      case "buys-search":
        return (
            <BuysSearch />
        );
      case "new-quick-retail-sales":
        return (
          <DataPanel
            company={config.quickRetailSalesNew_DP_JSON.company}
            templateEnable={config.quickRetailSalesNew_DP_JSON.templateEnable}
            templateType={config.quickRetailSalesNew_DP_JSON.templateType}
            tabs={config.quickRetailSalesNew_DP_JSON.tabs}
            deleteBtn={config.quickRetailSalesNew_DP_JSON.deleteBtn}
            saveBtn={config.quickRetailSalesNew_DP_JSON.saveBtn}
            saveandrepeat={config.quickRetailSalesNew_DP_JSON.saveandrepeat}
            headerNew={config.quickRetailSalesNew_DP_JSON.header.headerNew}
            headerEdited={config.quickRetailSalesNew_DP_JSON.header.headerEdited}
          />
      );
      case "edit-quick-retail-sales":
        return (
          <DataPanel
            company={config.quickRetailSalesEdit_DP_JSON.company}
            templateEnable={config.quickRetailSalesEdit_DP_JSON.templateEnable}
            templateType={config.quickRetailSalesEdit_DP_JSON.templateType}
            tabs={config.quickRetailSalesEdit_DP_JSON.tabs}
            deleteBtn={config.quickRetailSalesEdit_DP_JSON.deleteBtn}
            saveBtn={config.quickRetailSalesEdit_DP_JSON.saveBtn}
            saveandrepeat={config.quickRetailSalesEdit_DP_JSON.saveandrepeat}
            headerNew={config.quickRetailSalesEdit_DP_JSON.header.headerNew}
            headerEdited={config.quickRetailSalesEdit_DP_JSON.header.headerEdited}
          />
      );
      case "search-quick-retail-sales":
        return (
            <QuickRetailSalesSearch />
        );
      case "new-client-payments":
        return (
          <DataPanel
            company={config.clientPaymentsNew_DP_JSON.company}
            templateEnable={config.clientPaymentsNew_DP_JSON.templateEnable}
            templateType={config.clientPaymentsNew_DP_JSON.templateType}
            tabs={config.clientPaymentsNew_DP_JSON.tabs}
            deleteBtn={config.clientPaymentsNew_DP_JSON.deleteBtn}
            saveBtn={config.clientPaymentsNew_DP_JSON.saveBtn}
            saveandrepeat={config.clientPaymentsNew_DP_JSON.saveandrepeat}
            headerNew={config.clientPaymentsNew_DP_JSON.header.headerNew}
            headerEdited={config.clientPaymentsNew_DP_JSON.header.headerEdited}
            sendEmailOnSave={config.clientPaymentsNew_DP_JSON.sendEmailOnSave}
            printLayoutDropdown={config.clientPaymentsNew_DP_JSON.printLayoutDropdown}
          />
      );
      case "edit-client-payments":
        return (
          <DataPanel
            company={config.clientPaymentsEdit_DP_JSON.company}
            templateEnable={config.clientPaymentsEdit_DP_JSON.templateEnable}
            templateType={config.clientPaymentsEdit_DP_JSON.templateType}
            tabs={config.clientPaymentsEdit_DP_JSON.tabs}
            deleteBtn={config.clientPaymentsEdit_DP_JSON.deleteBtn}
            saveBtn={config.clientPaymentsEdit_DP_JSON.saveBtn}
            saveandrepeat={config.clientPaymentsEdit_DP_JSON.saveandrepeat}
            printLayoutDropdown={config.clientPaymentsEdit_DP_JSON.printLayoutDropdown}
            printBtn={config.clientPaymentsEdit_DP_JSON.printBtn}
            headerNew={config.clientPaymentsEdit_DP_JSON.header.headerNew}
            headerEdited={config.clientPaymentsEdit_DP_JSON.header.headerEdited}
          />
      );
      case "search-client-payments":
        return (
            <ClientPaymentsSearch />
        );
      case "new-supplier-payments":
        return (
          <DataPanel
            company={config.supplierPaymentsNew_DP_JSON.company}
            templateEnable={config.supplierPaymentsNew_DP_JSON.templateEnable}
            templateType={config.supplierPaymentsNew_DP_JSON.templateType}
            tabs={config.supplierPaymentsNew_DP_JSON.tabs}
            deleteBtn={config.supplierPaymentsNew_DP_JSON.deleteBtn}
            saveBtn={config.supplierPaymentsNew_DP_JSON.saveBtn}
            saveandrepeat={config.supplierPaymentsNew_DP_JSON.saveandrepeat}
            headerNew={config.supplierPaymentsNew_DP_JSON.header.headerNew}
            headerEdited={config.supplierPaymentsNew_DP_JSON.header.headerEdited}
          />
      );
      case "edit-supplier-payments":
        return (
          <DataPanel
            company={config.supplierPaymentsEdit_DP_JSON.company}
            templateEnable={config.supplierPaymentsEdit_DP_JSON.templateEnable}
            templateType={config.supplierPaymentsEdit_DP_JSON.templateType}
            tabs={config.supplierPaymentsEdit_DP_JSON.tabs}
            deleteBtn={config.supplierPaymentsEdit_DP_JSON.deleteBtn}
            saveBtn={config.supplierPaymentsEdit_DP_JSON.saveBtn}
            saveandrepeat={config.supplierPaymentsEdit_DP_JSON.saveandrepeat}
            headerNew={config.supplierPaymentsEdit_DP_JSON.header.headerNew}
            headerEdited={config.supplierPaymentsEdit_DP_JSON.header.headerEdited}
          />
      );
      case "search-supplier-payments":
        return (
            <SupplierPaymentsSearch />
        );
      case "new-automatic-sales":
        return (
          <DataPanel
            company={config.automaticSalesNew_DP_JSON.company}
            templateEnable={config.automaticSalesNew_DP_JSON.templateEnable}
            templateType={config.automaticSalesNew_DP_JSON.templateType}
            tabs={config.automaticSalesNew_DP_JSON.tabs}
            deleteBtn={config.automaticSalesNew_DP_JSON.deleteBtn}
            saveBtn={config.automaticSalesNew_DP_JSON.saveBtn}
            saveandrepeat={config.automaticSalesNew_DP_JSON.saveandrepeat}
            headerNew={config.automaticSalesNew_DP_JSON.header.headerNew}
            headerEdited={config.automaticSalesNew_DP_JSON.header.headerEdited}
          />
      );
      case "edit-automatic-sales":
        return (
          <DataPanel
            company={config.automaticSalesEdit_DP_JSON.company}
            templateEnable={config.automaticSalesEdit_DP_JSON.templateEnable}
            templateType={config.automaticSalesEdit_DP_JSON.templateType}
            tabs={config.automaticSalesEdit_DP_JSON.tabs}
            deleteBtn={config.automaticSalesEdit_DP_JSON.deleteBtn}
            saveBtn={config.automaticSalesEdit_DP_JSON.saveBtn}
            saveandrepeat={config.automaticSalesEdit_DP_JSON.saveandrepeat}
            headerNew={config.automaticSalesEdit_DP_JSON.header.headerNew}
            headerEdited={config.automaticSalesEdit_DP_JSON.header.headerEdited}
          />
      );
      case "search-automatic-sales":
        return (
            <AutomaticSalesSearch />
        );
      case "mydata-report":
        return (
          <DataPanel
            company={config.salesMyDataReport_DP_JSON.company}
            templateEnable={config.salesMyDataReport_DP_JSON.templateEnable}
            templateType={config.salesMyDataReport_DP_JSON.templateType}
            tabs={config.salesMyDataReport_DP_JSON.tabs}
            deleteBtn={config.salesMyDataReport_DP_JSON.deleteBtn}
            saveBtn={config.salesMyDataReport_DP_JSON.saveBtn}
            saveandrepeat={config.salesMyDataReport_DP_JSON.saveandrepeat}
            headerNew={config.salesMyDataReport_DP_JSON.header.headerNew}
            headerEdited={config.salesMyDataReport_DP_JSON.header.headerEdited}
          />
      );
      case "sales-balance-sheet":
        return (
          <SalesBalanceSheet />
        )
      case "sales-graphic-report":
        return (
            <SalesGraphicReport />
      )
      case "client-payments-report":
        return (
          <SalesClientPaymentReport />
      )
      case "buys-balance-sheet":
        return (
          <BuysBalanceSheet />
        );
      case "buys-graphic-report":
        return (
          <BuysGraphicReport />
      );
      case "supplier-payments-report":
        return (
          <BuysSupplierPaymentReport />
        )
      case "client-payments-report-graphic":
        return (
          <DataPanel
            company={config.salesClientPaymentGraphicReport_DP_JSON.company}
            templateEnable={config.salesClientPaymentGraphicReport_DP_JSON.templateEnable}
            templateType={config.salesClientPaymentGraphicReport_DP_JSON.templateType}
            tabs={config.salesClientPaymentGraphicReport_DP_JSON.tabs}
            deleteBtn={config.salesClientPaymentGraphicReport_DP_JSON.deleteBtn}
            saveBtn={config.salesClientPaymentGraphicReport_DP_JSON.saveBtn}
            saveandrepeat={config.salesClientPaymentGraphicReport_DP_JSON.saveandrepeat}
            headerNew={config.salesClientPaymentGraphicReport_DP_JSON.header.headerNew}
            headerEdited={config.salesClientPaymentGraphicReport_DP_JSON.header.headerEdited}
          />
      )
      case "automatic-sales-report":
        return (
          <DataPanel
            company={config.salesAutomaticSalesReport_DP_JSON.company}
            templateEnable={config.salesAutomaticSalesReport_DP_JSON.templateEnable}
            templateType={config.salesAutomaticSalesReport_DP_JSON.templateType}
            tabs={config.salesAutomaticSalesReport_DP_JSON.tabs}
            deleteBtn={config.salesAutomaticSalesReport_DP_JSON.deleteBtn}
            saveBtn={config.salesAutomaticSalesReport_DP_JSON.saveBtn}
            saveandrepeat={config.salesAutomaticSalesReport_DP_JSON.saveandrepeat}
            headerNew={config.salesAutomaticSalesReport_DP_JSON.header.headerNew}
            headerEdited={config.salesAutomaticSalesReport_DP_JSON.header.headerEdited}
          />
      )
      case "cash-registers-new":
        return (
          <DataPanel
            company={config.cashRegistersNew_DP_JSON.company}
            templateEnable={config.cashRegistersNew_DP_JSON.templateEnable}
            templateType={config.cashRegistersNew_DP_JSON.templateType}
            tabs={config.cashRegistersNew_DP_JSON.tabs}
            deleteBtn={config.cashRegistersNew_DP_JSON.deleteBtn}
            saveBtn={config.cashRegistersNew_DP_JSON.saveBtn}
            saveandrepeat={config.cashRegistersNew_DP_JSON.saveandrepeat}
            headerNew={config.cashRegistersNew_DP_JSON.header.headerNew}
            headerEdited={config.cashRegistersNew_DP_JSON.header.headerEdited}
          />
      );
      case "edit-cash-registers":
        return (
          <DataPanel
            company={config.editCashRegisters_DP_JSON.company}
            templateEnable={config.editCashRegisters_DP_JSON.templateEnable}
            templateType={config.editCashRegisters_DP_JSON.templateType}
            tabs={config.editCashRegisters_DP_JSON.tabs}
            deleteBtn={config.editCashRegisters_DP_JSON.deleteBtn}
            saveBtn={config.editCashRegisters_DP_JSON.saveBtn}
            saveandrepeat={config.editCashRegisters_DP_JSON.saveandrepeat}
            headerNew={config.editCashRegisters_DP_JSON.header.headerNew}
            headerEdited={config.editCashRegisters_DP_JSON.header.headerEdited}
          />
      );
      case "new-bank-account":
        return (
          <DataPanel
            company={config.bankAccountsNew_DP_JSON.company}
            templateEnable={config.bankAccountsNew_DP_JSON.templateEnable}
            templateType={config.bankAccountsNew_DP_JSON.templateType}
            tabs={config.bankAccountsNew_DP_JSON.tabs}
            deleteBtn={config.bankAccountsNew_DP_JSON.deleteBtn}
            saveBtn={config.bankAccountsNew_DP_JSON.saveBtn}
            saveandrepeat={config.bankAccountsNew_DP_JSON.saveandrepeat}
            headerNew={config.bankAccountsNew_DP_JSON.header.headerNew}
            headerEdited={config.bankAccountsNew_DP_JSON.header.headerEdited}
          />
      );

      case "new-pos":
        return (
          <DataPanel
            company={config.PosNew_DP_JSON.company}
            templateEnable={config.PosNew_DP_JSON.templateEnable}
            templateType={config.PosNew_DP_JSON.templateType}
            tabs={config.PosNew_DP_JSON.tabs}
            deleteBtn={config.PosNew_DP_JSON.deleteBtn}
            saveBtn={config.PosNew_DP_JSON.saveBtn}
            saveandrepeat={config.PosNew_DP_JSON.saveandrepeat}
            headerNew={config.PosNew_DP_JSON.header.headerNew}
            headerEdited={config.PosNew_DP_JSON.header.headerEdited}
          />
      );
      case "edit-pos":
        return (
          <DataPanel
            company={config.PosEdit_DP_JSON.company}
            templateEnable={config.PosEdit_DP_JSON.templateEnable}
            templateType={config.PosEdit_DP_JSON.templateType}
            tabs={config.PosEdit_DP_JSON.tabs}
            deleteBtn={config.PosEdit_DP_JSON.deleteBtn}
            saveBtn={config.PosEdit_DP_JSON.saveBtn}
            saveandrepeat={config.PosEdit_DP_JSON.saveandrepeat}
            headerNew={config.PosEdit_DP_JSON.header.headerNew}
            headerEdited={config.PosEdit_DP_JSON.header.headerEdited}
          />
      );
      case "search-pos":
        return (
            <POSSearch />
        );
      case "search-bank-account":
        return (
            <BankSearch />
        );
      case "edit-bank-account":
        return (
          <DataPanel
            company={config.editBankAccounts_DP_JSON.company}
            templateEnable={config.editBankAccounts_DP_JSON.templateEnable}
            templateType={config.editBankAccounts_DP_JSON.templateType}
            tabs={config.editBankAccounts_DP_JSON.tabs}
            deleteBtn={config.editBankAccounts_DP_JSON.deleteBtn}
            saveBtn={config.editBankAccounts_DP_JSON.saveBtn}
            saveandrepeat={config.editBankAccounts_DP_JSON.saveandrepeat}
            headerNew={config.editBankAccounts_DP_JSON.header.headerNew}
            headerEdited={config.editBankAccounts_DP_JSON.header.headerEdited}
          />
      );
      case "cash-registers-search":
        return (
            <CashRegistersSearch />
        );
      case "sales-general-settings":
        return (
          <DataPanel
            company={config.salesGeneralSettings_DP_JSON.company}
            templateEnable={config.salesGeneralSettings_DP_JSON.templateEnable}
            templateType={config.salesGeneralSettings_DP_JSON.templateType}
            tabs={config.salesGeneralSettings_DP_JSON.tabs}
            deleteBtn={config.salesGeneralSettings_DP_JSON.deleteBtn}
            saveBtn={config.salesGeneralSettings_DP_JSON.saveBtn}
            saveandrepeat={config.PosNew_DP_JSON.saveandrepeat}
            headerNew={config.salesGeneralSettings_DP_JSON.header.headerNew}
            headerEdited={config.salesGeneralSettings_DP_JSON.header.headerEdited}
          />
        );
      case "buys-general-settings":
        return (
          <DataPanel
            company={config.buysGeneralSettings_DP_JSON.company}
            templateEnable={config.buysGeneralSettings_DP_JSON.templateEnable}
            templateType={config.buysGeneralSettings_DP_JSON.templateType}
            tabs={config.buysGeneralSettings_DP_JSON.tabs}
            deleteBtn={config.buysGeneralSettings_DP_JSON.deleteBtn}
            saveBtn={config.buysGeneralSettings_DP_JSON.saveBtn}
            saveandrepeat={config.PosNew_DP_JSON.saveandrepeat}
            headerNew={config.buysGeneralSettings_DP_JSON.header.headerNew}
            headerEdited={config.buysGeneralSettings_DP_JSON.header.headerEdited}
          />
        );
      case "quick-retail-sales-settings":
        return (
          <DataPanel
            company={config.quickRetailSalesSettings_DP_JSON.company}
            templateEnable={config.quickRetailSalesSettings_DP_JSON.templateEnable}
            templateType={config.quickRetailSalesSettings_DP_JSON.templateType}
            tabs={config.quickRetailSalesSettings_DP_JSON.tabs}
            deleteBtn={config.quickRetailSalesSettings_DP_JSON.deleteBtn}
            saveBtn={config.quickRetailSalesSettings_DP_JSON.saveBtn}
            saveandrepeat={config.quickRetailSalesSettings_DP_JSON.saveandrepeat}
            headerNew={config.quickRetailSalesSettings_DP_JSON.header.headerNew}
            headerEdited={config.quickRetailSalesSettings_DP_JSON.header.headerEdited}
          />
        );
    case "employee-new":
      return (
          <DataPanel
              company={config.EmployeeNew_DP_JSON.company}
              templateEnable={config.EmployeeNew_DP_JSON.templateEnable}
              templateType={config.EmployeeNew_DP_JSON.templateType}
              tabs={config.EmployeeNew_DP_JSON.tabs}
              deleteBtn={config.EmployeeNew_DP_JSON.deleteBtn}
              saveBtn={config.EmployeeNew_DP_JSON.saveBtn}
              headerNew={config.EmployeeNew_DP_JSON.header.headerNew}
              headerEdited={config.EmployeeNew_DP_JSON.header.headerEdited}
          />
      );
    case "employee-edit":
      return (
          <DataPanel
              company={config.EmployeeEdit_DP_JSON.company}
              templateEnable={config.EmployeeEdit_DP_JSON.templateEnable}
              templateType={config.EmployeeEdit_DP_JSON.templateType}
              tabs={config.EmployeeEdit_DP_JSON.tabs}
              deleteBtn={config.EmployeeEdit_DP_JSON.deleteBtn}
              saveBtn={config.EmployeeEdit_DP_JSON.saveBtn}
              headerNew={config.EmployeeEdit_DP_JSON.header.headerNew}
              headerEdited={config.EmployeeEdit_DP_JSON.header.headerEdited}
          />
      );
    case "employee-search":
      return (
          <SearchEmployee />
      );
    case "accounting-account-new":
      return (
          <DataPanel
              company={config.AccountingAccountNew_DP_JSON.company}
              templateEnable={config.AccountingAccountNew_DP_JSON.templateEnable}
              templateType={config.AccountingAccountNew_DP_JSON.templateType}
              tabs={config.AccountingAccountNew_DP_JSON.tabs}
              deleteBtn={config.AccountingAccountNew_DP_JSON.deleteBtn}
              saveBtn={config.AccountingAccountNew_DP_JSON.saveBtn}
              headerNew={config.AccountingAccountNew_DP_JSON.header.headerNew}
              headerEdited={config.AccountingAccountNew_DP_JSON.header.headerEdited}
          />
      );
    case "accounting-account-edit":
      return (
          <DataPanel
              company={config.AccountingAccountEdit_DP_JSON.company}
              templateEnable={config.AccountingAccountEdit_DP_JSON.templateEnable}
              templateType={config.AccountingAccountEdit_DP_JSON.templateType}
              tabs={config.AccountingAccountEdit_DP_JSON.tabs}
              deleteBtn={config.AccountingAccountEdit_DP_JSON.deleteBtn}
              saveBtn={config.AccountingAccountEdit_DP_JSON.saveBtn}
              headerNew={config.AccountingAccountEdit_DP_JSON.header.headerNew}
              headerEdited={config.AccountingAccountEdit_DP_JSON.header.headerEdited}
          />
      );
    case "accounting-account-search":
      return (
          <SearchAccountingAccount />
      );
    case "accounting-record-new":
      return (
          <DataPanel
              company={config.AccountingRecordNew_DP_JSON.company}
              templateEnable={config.AccountingRecordNew_DP_JSON.templateEnable}
              templateType={config.AccountingRecordNew_DP_JSON.templateType}
              tabs={config.AccountingRecordNew_DP_JSON.tabs}
              deleteBtn={config.AccountingRecordNew_DP_JSON.deleteBtn}
              saveBtn={config.AccountingRecordNew_DP_JSON.saveBtn}
              saveandrepeat={config.AccountingRecordNew_DP_JSON.saveandrepeat}
              headerNew={config.AccountingRecordNew_DP_JSON.header.headerNew}
              headerEdited={config.AccountingRecordNew_DP_JSON.header.headerEdited}
          />
      );
    case "accounting-record-edit":
      return (
          <DataPanel
              company={config.AccountingRecordEdit_DP_JSON.company}
              templateEnable={config.AccountingRecordEdit_DP_JSON.templateEnable}
              templateType={config.AccountingRecordEdit_DP_JSON.templateType}
              tabs={config.AccountingRecordEdit_DP_JSON.tabs}
              deleteBtn={config.AccountingRecordEdit_DP_JSON.deleteBtn}
              saveBtn={config.AccountingRecordEdit_DP_JSON.saveBtn}
              headerNew={config.AccountingRecordEdit_DP_JSON.header.headerNew}
              headerEdited={config.AccountingRecordEdit_DP_JSON.header.headerEdited}
              cancelMyDataInvoice={config.AccountingRecordEdit_DP_JSON.cancelMyDataInvoice}
          />
      );
    case "accounting-record-search":
      return (
          <SearchAccountingRecord />
      );
    case "subscriptions":
      return (
        <DataPanel
            company={config.settingsSubscriptions_DP_JSON.company}
            templateEnable={config.settingsSubscriptions_DP_JSON.templateEnable}
            templateType={config.settingsSubscriptions_DP_JSON.templateType}
            tabs={config.settingsSubscriptions_DP_JSON.tabs}
            deleteBtn={config.settingsSubscriptions_DP_JSON.deleteBtn}
            saveBtn={config.settingsSubscriptions_DP_JSON.saveBtn}
            headerNew={config.settingsSubscriptions_DP_JSON.header.headerNew}
            headerEdited={config.settingsSubscriptions_DP_JSON.header.headerEdited}
        />
      );
    case "reports-client-subscriptions":
      return (
          <DataPanel
              company={config.reportsClientSubscriptions_DP_JSON.company}
              templateEnable={config.reportsClientSubscriptions_DP_JSON.templateEnable}
              templateType={config.reportsClientSubscriptions_DP_JSON.templateType}
              tabs={config.reportsClientSubscriptions_DP_JSON.tabs}
              deleteBtn={config.reportsClientSubscriptions_DP_JSON.deleteBtn}
              saveBtn={config.reportsClientSubscriptions_DP_JSON.saveBtn}
              headerNew={config.reportsClientSubscriptions_DP_JSON.header.headerNew}
              headerEdited={config.reportsClientSubscriptions_DP_JSON.header.headerEdited}
          />
      );
    case "buys-mydata-report":
      return (
          <DataPanel
              company={config.buysMyDataReport_DP_JSON.company}
              templateEnable={config.buysMyDataReport_DP_JSON.templateEnable}
              tabs={config.buysMyDataReport_DP_JSON.tabs}
              deleteBtn={config.buysMyDataReport_DP_JSON.deleteBtn}
              saveBtn={config.buysMyDataReport_DP_JSON.saveBtn}
              headerNew={config.buysMyDataReport_DP_JSON.header.headerNew}
              headerEdited={config.buysMyDataReport_DP_JSON.header.headerEdited}
          />
      )
    case "cash-settings-accounting":
      return (
          <DataPanel
              company={config.cashAccountingSettings_DP_JSON.company}
              templateEnable={config.cashAccountingSettings_DP_JSON.templateEnable}
              templateType={config.cashAccountingSettings_DP_JSON.templateType}
              tabs={config.cashAccountingSettings_DP_JSON.tabs}
              deleteBtn={config.cashAccountingSettings_DP_JSON.deleteBtn}
              saveBtn={config.cashAccountingSettings_DP_JSON.saveBtn}
              headerNew={config.cashAccountingSettings_DP_JSON.header.headerNew}
              headerEdited={config.cashAccountingSettings_DP_JSON.header.headerEdited}
          />
      )
    case "payroll-settings":
      return (
          <DataPanel
              company={config.payrollSettings_DP_JSON.company}
              templateEnable={config.payrollSettings_DP_JSON.templateEnable}
              templateType={config.payrollSettings_DP_JSON.templateType}
              tabs={config.payrollSettings_DP_JSON.tabs}
              deleteBtn={config.payrollSettings_DP_JSON.deleteBtn}
              saveBtn={config.payrollSettings_DP_JSON.saveBtn}
              headerNew={config.payrollSettings_DP_JSON.header.headerNew}
              headerEdited={config.payrollSettings_DP_JSON.header.headerEdited}
          />
      )
    case "new-work-movement":
      return (
          <DataPanel
              company={config.newWorkMovement_DP_JSON.company}
              templateEnable={config.newWorkMovement_DP_JSON.templateEnable}
              templateType={config.newWorkMovement_DP_JSON.templateType}
              tabs={config.newWorkMovement_DP_JSON.tabs}
              deleteBtn={config.newWorkMovement_DP_JSON.deleteBtn}
              saveBtn={config.newWorkMovement_DP_JSON.saveBtn}
              headerNew={config.newWorkMovement_DP_JSON.header.headerNew}
              headerEdited={config.newWorkMovement_DP_JSON.header.headerEdited}
          />
      )
    case "edit-work-movement":
      return (
          <DataPanel
              company={config.editWorkMovement_DP_JSON.company}
              templateEnable={config.editWorkMovement_DP_JSON.templateEnable}
              templateType={config.editWorkMovement_DP_JSON.templateType}
              tabs={config.editWorkMovement_DP_JSON.tabs}
              deleteBtn={config.editWorkMovement_DP_JSON.deleteBtn}
              saveBtn={config.editWorkMovement_DP_JSON.saveBtn}
              headerNew={config.editWorkMovement_DP_JSON.header.headerNew}
              headerEdited={config.editWorkMovement_DP_JSON.header.headerEdited}
          />
      )
    case "search-work-movements":
      return (
          <SearchWorkMovements />
      )
    case "new-work-permit":
      return (
          <DataPanel
              company={config.newWorkPermit_DP_JSON.company}
              templateEnable={config.newWorkPermit_DP_JSON.templateEnable}
              templateType={config.newWorkPermit_DP_JSON.templateType}
              tabs={config.newWorkPermit_DP_JSON.tabs}
              deleteBtn={config.newWorkPermit_DP_JSON.deleteBtn}
              saveBtn={config.newWorkPermit_DP_JSON.saveBtn}
              headerNew={config.newWorkPermit_DP_JSON.header.headerNew}
              headerEdited={config.newWorkPermit_DP_JSON.header.headerEdited}
          />
      )
    case "edit-work-permit":
      return (
          <DataPanel
              company={config.editWorkPermit_DP_JSON.company}
              templateEnable={config.editWorkPermit_DP_JSON.templateEnable}
              templateType={config.editWorkPermit_DP_JSON.templateType}
              tabs={config.editWorkPermit_DP_JSON.tabs}
              deleteBtn={config.editWorkPermit_DP_JSON.deleteBtn}
              saveBtn={config.editWorkPermit_DP_JSON.saveBtn}
              headerNew={config.editWorkPermit_DP_JSON.header.headerNew}
              headerEdited={config.editWorkPermit_DP_JSON.header.headerEdited}
          />
      )
    case "search-work-permits":
      return (
          <SearchWorkPermits />
      )
    case "accounting-report-isozygio":
      return (<AccountingReportIsozygio />)
    case "accounting-report-accounts":
      return (<AccountingReportAccounts />)
    case "accounting-report-calendar":
      return (<AccountingReportCalendar />)
    case "accounting-year-report":
      return <AccountingYearReport />
    case "settings-emronExpenses":
      return (<SettingsExpenses />)
    case "accounting-bridge-settings":
      return <AccountingBridgeSettings />
    case "reports-primer-payments":
      return <ReportsPrimerPayments />
    case "cdr-viewer":
      return <CDRViewer />
    case "primer-client-payments":
      return <PrimerClientPayments />
    case "emron-client-payments":
      return <EmronClientPayments />
    case "reports-emron-settlements":
      return <ReportsEmronSettlements />
    case "user-settings":
      return <UserSettings />
    case "start":
      return <Start />;
    case "working-program":
      return <WorkingProgram />
    case "working-program-employee":
      return <EmployeeWorkingProgram />
    case "master-log-search":
      return <MasterLogSearch />
    case "payroll-qr-scanner":
      return <PayrollQrScanner />
    case "ergani-submissions-report":
      return <ErganiSubmissionsReport />
    case "employee-view":
      return <EmployeeView />
    case "report-client-isozygio":
      return <ReportClientIsozygio />
    case "report-supplier-isozygio":
      return <ReportSupplierIsozygio />
    default:
      return <Start />
  }
}

// Form Submit to Object Convertor
// e: Takes the event of the from
// Return: the object of fields names
export const formEventToObjectConvertor = (e) => {
  const filter = {};
  for (let i = 0; i <= e.target.length - 2; i++) {
    if (e.target[i].value !== '') {
      let siblingClasses = String(e.target[i]?.parentNode?.previousSibling?.getAttribute("class"));
      if (filter[e.target[i].name]) { // Already exists, must be multi select dropdown
        if (!Array.isArray(filter[e.target[i].name])) {
          let arr = [];
          arr.push(filter[e.target[i].name]);
          arr.push(e.target[i].value);
          filter[e.target[i].name] = arr;
        } else {
          filter[e.target[i].name].push(e.target[i].value);
        }
      } else {
        if (siblingClasses.includes("MULTI_SELECT_DO_NOT_TOUCH")) {
          filter[e.target[i].name] = [e.target[i].value];
        } else {
          filter[e.target[i].name] = e.target[i].value;
        }
      }
    }
  }
  return filter;
}
// Delete Object by unique ID or Key
export const deleteObjectFromArrayOfObjects = (data, id, field = 'id') => {
  if (data && data.length > 0 ) {
    return data.filter((item) => item[field] !== id)
  } else {
    return data
  }
}

// Get Object by unique ID or Key
export const getObjectFromArrayOfObjects = (data, id, field = 'id') => {
  return data.filter((item) => item[field] === id)
}

// Find Object by unique ID or Key
export const findObjectFromArrayOfObjects = (data, id, field = 'id') => {
  return data.find((item) => item[field] === id)
}

// Get Object by fields
// let filter  = {
//   field: 'value',
// }
export const getObjectFromArrayOfObjectsByFields = (data, filter) => {
  if (Array.isArray(data) && data.length > 0) {
    let newData;
    newData = data.filter(function(item) {
      let dcheck = true;
      for (let key in filter) {
        if (item[key] !== filter[key]) {
          dcheck = false;
        }
      }
      return dcheck;
    });
    return newData.length > 0;
  }
  return false;
}

export const getObjectFromArrayOfObjectsByFields2 = (data, filter) => {
  if (Array.isArray(data) && data.length > 0) {
    let newData;
    newData = data.filter(function(item) {
      let dcheck = true;
      for (let key in filter) {
        if (item[key] !== filter[key]) {
          dcheck = false;
        }
      }
      return dcheck;
    });
    return (newData.length > 0) ? newData : [];
  }
  return [];
}

// Sort Array of object by field value
export const sortArrayObjectByValue = (data, field = 'id') => {
  if (data && data.length > 0) {
    return data.sort((a, b) => {
      return (a[field] > b[field]) ? 1 : -1;
    });
  } else {
    return data;
  }
}

// Get Array Last Item
export const getArrayLastItem = (data) => {
  if (data && data.length > 0) {
    return data[data.length - 1];
  } else {
    return '';
  }
}

// Get Index by Value from Array
export const getIndexByArrayValue = (array, value) => {
  return array.indexOf(value);
}

// Set Authentication Using URL
export const getURLParams = () => {
  return new URLSearchParams(window.location.search);
}

// Get Random String
export const getRandomString = (length) => {
  var result           = '';
  var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for ( var i = 0; i < length; i++ ) {
    result += characters.charAt(Math.floor(Math.random() *
    charactersLength));
  }
 return result;
}


// Table Header to CSV Header
export const getCSVHeader = (data) => {
  let array = [];
  if (data && data.length > 0) {
    data.forEach((item) => {
      let hobj = {};
      hobj.label = item.name;
      hobj.key = item.field;
      if (hobj.key !== "select_checkbox" && hobj.key !== "actions") {
        array.push(hobj);
      }
    });
  }
  return array;
}

// Get Object by unique ID or Key
export const getLabelByValue = (data, id, field = 'id') => {
  let output = '';
  if (data.length > 0) {
    const opobj = getObjectFromArrayOfObjects(data, id, field);
    if (opobj.length > 0 && opobj !== 'undefined') {
      output = opobj['0'].label;
      if(output === "Λιανικής"){
        output = "Retail";
      } else if(output === "Ασθενής"){
        output = "Patient";
      } else if(output === "Ελεύθερος Επαγγελματίας"){
        output = "Freelancer";
      } else if(output === "Μη κερδοσκοπική οργάνωση"){
        output = "Non-Profit Organization";
      } else if(output === "Πελάτης εντός Ε.Ε."){
        output = "Client within E.U.";
      } else if(output === "Πελάτης εκτός Ε.Ε."){
        output = "Client outside E.U.";
      } else if(output === "Προμηθευτής εντός Ε.Ε."){
        output = "Supplier within E.U.";
      } else if(output === "Προμηθευτής εκτός Ε.Ε."){
        output = "Supplier outside E.U.";
      } else if(output === "Λοιποί Χονδρικής"){
        output = "Wholesale Others";
      } else if(output === "Ε.Π.Ε."){
        output = "E.P.E.";
      }
    }
  }
  return output;
}

export const translateClientCategories = (label) => {
  let translated = "";
  if(label === "Retail"){
    translated = "Λιανικής";
  } else if(label === "Patient"){
    translated = "Ασθενής";
  } else if(label === "Freelancer"){
    translated = "Ελεύθερος Επαγγελματίας";
  } else if(label === "Non-Profit Organization"){
    translated = "Μη κερδοσκοπική οργάνωση";
  } else if(label === "Client within E.U."){
    translated = "Πελάτης εντός Ε.Ε.";
  } else if(label === "Client outside E.U."){
    translated = "Πελάτης εκτός Ε.Ε.";
  } else if(label === "Supplier within E.U."){
    translated = "Προμηθευτής εντός Ε.Ε.";
  } else if(label === "Supplier outside E.U."){
    translated = "Προμηθευτής εκτός Ε.Ε.";
  } else if(label === "Wholesale Others"){
    translated = "Λοιποί Χονδρικής";
  } else if(label === "E.P.E."){
    translated = "Ε.Π.Ε.";
  } else {
    translated = label;
  }
  return translated;
}

// Get Object by unique ID or Key
export const getValueByLabel = (data, id, field = 'id') => {
  let output = '';
  if (data.length > 0) {
    const opobj = getObjectFromArrayOfObjects(data, id, field);
    if (opobj.length > 0 && opobj !== 'undefined') {
      output = opobj['0'].label;
    }
  }
  return output;
}

// Check if a value exists in array of object
export const isValueExists = (data, value, field='productName') => {
	if (data.length > 0) {
    const res = data.filter((item) => item[field].toLowerCase().trim() === value.toLowerCase().trim());
		if (res.length > 0 && res !== 'undefined') {
			return true;
		}
	}
	return false;
}

// Check if a value exists in array of object
export const isValueExistsReturn = (data, value, field='productName') => {
	if (data.length > 0) {
		return data.filter((item) => item[field].toLowerCase() === value.toLowerCase());
	}
  return [];
}

// check if value already exists by _id
export const isValueAlreadyExists = (data, value, field='productName', currentId = '') => {
	if (data.length > 0) {

    const res = data.filter((item) =>
       item[field] && item[field].toLowerCase().trim() === value.toLowerCase().trim()
    );


  if (res.length > 0) {
      if (currentId !== "" && currentId === res['0']._id) {
        return false;
      } else {
        return true;
      }
		}
	}
	return false;
}
