import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import EditableTable from "../../../primer/Editable-Table";
import {checkPermission} from "../../../../_helpers/commonFunctions";
import {Badge, Button, Col, Row, Spinner} from "react-bootstrap";
import Input from "../../../common/Input";
import {
    setClientBalanceReportRequestData,
    setClientBalanceReportResponseData
} from "../../../../_reducers/DataPanel/Clients/clientSlice";
import axios from "axios";
import {toast} from "react-toastify";
import BetterCheckBox from "../../../common/BetterCheckBox";
import DateBox from "../../../primer/DateBox";
import moment from "moment";
import {classicStyleBelowNavbar} from "../Statics";

const ClientBalance = () => {
    const dispatch = useDispatch();
    const [t] = useTranslation("common");
    const permissionsData = JSON.parse(localStorage.getItem("permissions"));
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const requestData = useSelector((state) => state.CLIENTS_DATA.clientBalanceReportRequestData);
    const responseData = useSelector((state) => state.CLIENTS_DATA.clientBalanceReportResponseData);

    const isEmron = company.vatNumber === "800434990";

    const [loading, setLoading] = useState(false);
    const [pageChanged, setPageChanged] = useState(false);
    const [incrKey, setIncrKey] = useState(0);

    let columns = responseData.includeSettlements === "inactive" ? [
        {
            name: t('General.census'),
            field: "census",
            hiddenColumn: false,
            editable: true,
            disabled: true,
            inputType: {
                type: 'accounting',
                config: {
                    suffix: "€",
                }
            }
        },
        {
            name: t('General.clientName'),
            field: "clientName",
            editable: false,
            hiddenColumn: false,
            inputType: {
                type: "text",
            },
        },
        {
            name: t('General.vatNumber'),
            field: "vatNumber",
            width: "100px",
            editable: false,
            hiddenColumn: false,
            inputType: {
                type: "text",
            },
        },
        {
            name: t('General.balance'),
            field: "balance",
            hiddenColumn: false,
            editable: true,
            disabled: true,
            inputType: {
                type: 'accounting',
                config: {
                    suffix: "€",
                }
            }
        },
    ] : [
        {
            name: t('General.census'),
            field: "census",
            hiddenColumn: false,
            editable: true,
            disabled: true,
            inputType: {
                type: 'accounting',
                config: {
                    suffix: "€",
                }
            }
        },
        {
            name: t('General.clientName'),
            field: "clientName",
            editable: false,
            hiddenColumn: false,
            inputType: {
                type: "text",
            },
        },
        {
            name: t('General.vatNumber'),
            field: "vatNumber",
            width: "100px",
            editable: false,
            hiddenColumn: false,
            inputType: {
                type: "text",
            },
        },
        {
            name: t('General.balance'),
            field: "balance",
            hiddenColumn: false,
            editable: true,
            disabled: true,
            inputType: {
                type: 'accounting',
                config: {
                    suffix: "€",
                }
            }
        },
        {
            name: "Διακανονισμένο ποσό",
            field: "totalSettledAmount",
            hiddenColumn: false,
            editable: true,
            disabled: true,
            inputType: {
                type: 'accounting',
                config: {
                    suffix: "€",
                }
            }
        },
        {
            name: "Υπόλοιπο μετά διακανονισμών",
            field: "balanceWithSettledAmount",
            hiddenColumn: false,
            editable: true,
            disabled: true,
            inputType: {
                type: 'accounting',
                config: {
                    suffix: "€",
                }
            }
        },
    ]

    useEffect(() => {
        if (pageChanged) {
            setPageChanged(false);
            fetchReport(true);
        }
    }, [pageChanged])

    useEffect(() => {
        setIncrKey(incrKey + 1);
    }, [responseData])

    const fetchReport = (fromPagination = false) => {
        setLoading(true);
        let reqData = {
            company: company.id,
            year: company.year,
            ...requestData,
        }
        if (!fromPagination) {
            reqData.page = 1;
            reqData.recalculateTotalBalance = true;
            dispatch(setClientBalanceReportRequestData({...requestData, page: 1}));
        }
        axios.post(`${process.env.REACT_APP_API_URL2}/report/client-balance-report`, reqData, {
            headers: { "Content-Type": "application/json" },
        }).then((res) => {
            setLoading(false);
            if (res.data.status === "200") {
                if (!fromPagination) {
                    let setObj = {
                        ...responseData,
                        totalPages: res.data.totalPages,
                        data: [...res.data.data],
                        totalBalance: res.data.totalBalance,
                        includeSettlements: res.data.includeSettlements,
                    }
                    if (setObj.includeSettlements === "active") {
                        setObj.totalSettledAmount = res.data.totalSettledAmount;
                        setObj.totalBalanceWithSettledAmount = res.data.totalBalanceWithSettledAmount;
                    }
                    dispatch(setClientBalanceReportResponseData(setObj));
                } else {
                    dispatch(setClientBalanceReportResponseData({...responseData, totalPages: res.data.totalPages, data: [...res.data.data]}));
                }
                if (!fromPagination) toast.success("Τα δεδομένα ανανεώθηκαν.");
            } else {
                toast.error(res.data.message);
            }
        }).catch((err) => {
            setLoading(false);
            console.log(err);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        })
    }

    const handleOnChange = (e, type, name) => {
        if (type === "input") {
            dispatch(setClientBalanceReportRequestData({...requestData, [name]: e.target.value}));
        } else if (type === "checkbox") {
            dispatch(setClientBalanceReportRequestData({...requestData, [name]: e.target.checked ? "active" : "inactive"}));
        } else if (type === "datebox") {
            if (moment(e).isValid()) {
                dispatch(setClientBalanceReportRequestData({...requestData, [name]: moment(e).format("DD/MM/YYYY")}));
            } else {
                dispatch(setClientBalanceReportRequestData({...requestData, [name]: ""}));
            }
        }
    }

    const toFirstPage = () => {
        dispatch(setClientBalanceReportRequestData({...requestData, page: 1}));
        setPageChanged(true);
    }

    const toPreviousPage = () => {
        dispatch(setClientBalanceReportRequestData({...requestData, page: requestData.page - 1}));
        setPageChanged(true);
    }

    const toNextPage = () => {
        dispatch(setClientBalanceReportRequestData({...requestData, page: requestData.page + 1}));
        setPageChanged(true);
    }

    const toLastPage = () => {
        dispatch(setClientBalanceReportRequestData({...requestData, page: responseData.totalPages}));
        setPageChanged(true);
    }

    return (
        <div style={classicStyleBelowNavbar}>
            <Row className={"mb-3"}>
                <Col md={2}>
                    <DateBox
                        name={"date_to"}
                        label={t("General.BalanceTo")}
                        selected={moment(requestData.date_to, "DD/MM/YYYY").isValid() ? moment(requestData.date_to, "DD/MM/YYYY").toDate() : ""}
                        onChange={(e) => handleOnChange(e, "datebox", "date_to")}
                    />
                </Col>
                <Col md={4}>
                    <Input
                        name={"clientName"}
                        label={t("SalesSearch.table2.clientNameFrom")}
                        value={requestData.clientName}
                        autoComplete={"off"}
                        onChange={(e) => handleOnChange(e, "input", "clientName")}
                    />
                </Col>
                <Col md={3}>
                    <BetterCheckBox
                        name={"showZeroBalance"}
                        className={"mt-4"}
                        text={t("General.includeZeroBalanceClients")}
                        checked={requestData.showZeroBalance === "active"}
                        onChange={(e) => handleOnChange(e, "checkbox", "showZeroBalance")}
                    />
                </Col>
                <Col md={3}>
                    <BetterCheckBox
                        name={"showInActive"}
                        className={"mt-4"}
                        text={t("General.includeInactiveClients")}
                        checked={requestData.showInActive === "active"}
                        onChange={(e) => handleOnChange(e, "checkbox", "showInActive")}
                    />
                </Col>
            </Row>
            {isEmron && (
                <Row>
                    <Col md={3}>
                        <BetterCheckBox
                            name={"includeSettlements"}
                            className={"mt-3"}
                            text={t("General.includeSettlements")}
                            checked={requestData.includeSettlements === "active"}
                            onChange={(e) => handleOnChange(e, "checkbox", "includeSettlements")}
                        />
                    </Col>
                </Row>
            )}
            <Row>
                <Col md={12}>
                    <Button className="btn btn-primary float-right" onClick={() => fetchReport(false)} disabled={loading}>
                        {t("General.applyFilter")} {loading && <Spinner className={"ml-2"} animation={"border"} variant={"dark"} />}
                    </Button>
                </Col>
            </Row>
            {responseData.totalPages > 0 && (
                <React.Fragment>
                    <hr/>
                    <Row className="mb-3 justify-content-end">
                        <Col md={9} style={{display: "flex", justifyContent: "right"}}>
                            <Button size="sm" className="mr-1" onClick={() => toFirstPage()} disabled={requestData.page === 1 || loading}>{"<< Πρώτη"}</Button>
                            <Button size="sm" className="mr-1" onClick={() => toPreviousPage()} disabled={requestData.page === 1 || loading}>{"< Προηγούμενη"}</Button>
                            <Badge style={{fontSize: "12px", lineHeight: "20px"}} className="mr-1" bg="primary">Σελίδα {requestData.page} / {responseData.totalPages}</Badge>
                            <Button size="sm" className="mr-1" onClick={() => toNextPage()} disabled={requestData.page === responseData.totalPages || loading}>{"Επόμενη >"}</Button>
                            <Button size="sm" className="mr-1" onClick={() => toLastPage()} disabled={requestData.page === responseData.totalPages || loading}>{"Τελευταία >>"}</Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <EditableTable
                                key={"Client Balance Report" + incrKey}
                                tableName="Client Balance Report"
                                data={responseData.data}
                                allowPagination={false}
                                allowToggleColumns={false}
                                allowInsertRow={false}
                                editRedirectAllowed={true}
                                enableNewRowCheckbox={false}
                                columns={columns}
                                pageTotals={true}
                                allowBulkActions={true}
                                allowPrintResults={checkPermission("clients-reports/clientbalance-general-print", permissionsData) === true}
                                allowActions={false}
                                totalBalanceTrue={true}
                                totalBalance={responseData.totalBalance}
                                reportExcelPrint={true}
                                company={company}
                                filters={requestData}

                                totalSettledAmountTrue={isEmron && checkPermission("clients-reports/reportsemronsettlements", permissionsData) === true && responseData.includeSettlements === "active"}
                                totalSettledAmount={responseData.totalSettledAmount}

                                totalBalanceWithSettledAmountTrue={isEmron && checkPermission("clients-reports/reportsemronsettlements", permissionsData) === true && responseData.includeSettlements === "active"}
                                totalBalanceWithSettledAmount={responseData.totalBalanceWithSettledAmount}
                            />
                        </Col>
                    </Row>
                </React.Fragment>
            )}
        </div>
    )
}

export default ClientBalance;
