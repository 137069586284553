import React, {useState } from "react";
import { Button } from 'react-bootstrap';
import { Popconfirm } from "antd";
import { getArrayLastItem, sortArrayObjectByValue } from "../../../_helpers/helperFunctions";

const AcModalEditTable = (props) => {
  const { data, tableColumns, tableData, acData, closeModal, saveChanges, rowActions, excludeColumns, ACName, hideSaveButton, showInsertAddButton } = props;
  const sortData = sortArrayObjectByValue(acData, 'id');
  const lastItem = getArrayLastItem(sortData);
  const lastItemId = lastItem && (lastItem.id !== 'undefined') ? lastItem.id : '';
  const [updatedData, setUpdatedData] = useState(data);
  const [newRow, setNewRow] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});

  const handleOnChangeRow = (e, name, row) => {
    if (selectedRow) {
      const newName = e.target.value;
      setSelectedRow({...selectedRow, name: newName});
    }
  }


  const handleSaveRow = (e) => {
    if (newRow && newRow.length === 0) {
      console.log('no data to save');
    } else {
      if (newRow.label !== '') {
        saveChanges(e, updatedData, newRow)
        setUpdatedData([...updatedData, newRow]);
        setNewRow({});
      }
    }
  }

  const setNewRowValue = (e) => {
    const field = e.target.name;
    const value = e.target.value;
    let newData

    if (ACName === 'Measurement Unit') {
      newData = {
        id: parseInt(lastItem.value) + 1,
        [field]: value
      };
    } else {
      newData = {
        id: parseInt(lastItemId) + 1,
        [field]: value
      };
    }
    setNewRow({...newRow, ...newData});
  }

  const handleKeyDownEnter = (e) => {
    const keyPressed = e.key;
    if (keyPressed === "Enter") {
      setUpdatedData([...updatedData, newRow]);
    }
  }

  const handleDelete = (e, rowKey, row) => {
    rowActions.delete(e, rowKey, row);
  }

  const fieldType = (name, value = '', placeholder="", type="", row) => {
    switch (name) {
      case "id":
        return <div className='text-center'>{value}</div>;
      default:
        return (
          <input
            className="form-control w-100"
            defaultValue ={value}
            onBlur={(e) => handleOnChangeRow(e, name, row)}
            type={type}
            placeholder={placeholder}
            name={name}
          />
        );
    }
  };

  const InsertfieldType = (name, value = '', placeholder="", type="") => {
    switch (name) {
      case "id":
        return <div className='text-center'>{value}</div>;
      default:
        return (
          <input
            className="form-control w-100"
            value ={value}
            onKeyDown={(e) => handleKeyDownEnter(e)}
            onChange={(e) => setNewRowValue(e)}
            type={type}
            placeholder={placeholder}
            name={name}
          />
        );
    }
  };

  const handleSelectedRow = (row) => {
    setSelectedRow(row)
  }

  return (
    <div className="modalClass">
      <div className="align-center col-lg-4 mx-auto modalSubClass">
      <table className="table table-bordered mb-2 editable-table-ui allow-insert-row">
        <thead><tr>
          {
            updatedData.length > 0 && tableColumns.map((col, coli) => (
              <th key={ coli } className={col.name}>{ col.label }</th>
            ))
          }
        </tr></thead>
        <tbody>
          {
            tableData.length > 0 ? tableData.map((tr, tri) => (
              <tr className={selectedRow && (selectedRow === tr) ? 'active-row' : 'inactive-row'} key={Math.random()} onClick={() => handleSelectedRow(tr)} >
                {
                  tableColumns.map((col, i) => {
                    return (
                      !excludeColumns.includes(col.name) && (col.name !== 'actions') ?
                        <td key={i}>
                          { fieldType(col.name, tr[col.name], '', '', tr) }
                        </td>
                      : <td key={i} className="text-center actions"><Popconfirm
                      title="Sure to delete?"
                      onConfirm={(e) => handleDelete(e, tri, tr)}
                    >
                      <span className="delbtn text-danger">
                        <i className="fas fa-minus-circle"></i>
                      </span>
                    </Popconfirm>
                    </td>
                    )
                  })
                }
              </tr>
            ))
            :
            ''
          }

          {/* Insert More Data */}
          <tr className="insert-new-row">
            {
              tableColumns.map((col, i) => {
                const value  = col.name === 'id' ? lastItemId + 1 : newRow[col.name];
                const placeholder = "Insert new...";
                return (
                  !excludeColumns.includes(col.name) && (col.name !== 'actions') ?
                    <td key={i}>
                      { InsertfieldType(col.name, value, placeholder) }
                    </td>
                  : <td key={i} className="text-center">
                    { showInsertAddButton && <Button size="sm" title="Add Activity" onClick={(e) => handleSaveRow(e)} variant="primary">Add</Button> }
                  </td>
                )
              })
            }
          </tr>

        </tbody>
      </table>

        <span className="acb-actions">
          <Button size="sm" onClick={() => closeModal(false)} variant="outline-primary mr-2">Close</Button>
          { !hideSaveButton && <Button size="sm" onClick={(e) => handleSaveRow(e)} variant="primary">Save</Button> }
        </span>
      </div>
    </div>
  );
};

export default AcModalEditTable;
