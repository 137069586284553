import { createSlice } from "@reduxjs/toolkit";

const newQuickRetailSalesInitialValue = {
  client: "",
  clientName: "",
  clientCategory: 1,
  clientCategoryName: "Retail",
  documentType: "",
  documentTypeName: "",
  installation: "",
  installationName: "",
  preNumber: "",
  numberingMode: "manual",
  number: 0, // it will auto generate at backend while saving.
  address: "",
  phone: "",
  email: "",
  isActive: "active",
  products: [],
  totalQuantity: "",
  totalPrice: "",
  totals: "",
  seletedPaymentWays: [],
  sendEmail: "",
  sendEmailData: "",
  issues: "",
  cash: "",
  paymentWay1: "",
  paymentWay2: "",
  paymentWay3: "",
  clientPaymentTotals: "",
  clientChange: "",
  cashRegisterIssue: "",
  extra: "",
  clientType: "Existing",
  allowNewClient: "inactive",
  // date: new Date(),
}

const newQuickRetailSalesNewProductInitialValue = {
  no: 1,
  id: 1,
  product: "",
  productName: "",
  quantity: "",
  price: "",
  totals: "",
  isActive: "active",
}

const initialState = {
  quickRetailSales: [],
  totalItemsQRS: 0,
  perPageQRS: 0,
  searchQuickRetailSales: [],
  newQuickRetailSalesInitialValue: newQuickRetailSalesInitialValue,
  newQuickRetailSales: newQuickRetailSalesInitialValue,
  newQuickRetailSalesNewProductInitialValue: newQuickRetailSalesNewProductInitialValue,
  newQuickRetailSalesNewProduct: newQuickRetailSalesNewProductInitialValue,
  editQuickRetailSales: newQuickRetailSalesInitialValue,
  editQuickRetailSalesNewProduct: newQuickRetailSalesNewProductInitialValue,
  qrsSearchProducts: [],
  productSearchKeyword: "",
  quickRetailSalesTemplates: [],
  quickRetailSalesSelectedTemplate: {},
};

export const QuickRetailSalesSlice = createSlice({
  name: "QuickRetailSales",
  initialState,
  reducers: {
    setQuickRetailSales: (state, { payload }) => {
      state.quickRetailSales = payload;
    },

    setNewQuickRetailSales: (state, { payload }) => {
      state.newQuickRetailSales = payload;
    },

    resetNewQuickRetailSales: (state) => {
      state.newQuickRetailSales = state.newQuickRetailSalesInitialValue;
    },

    setNewQuickRetailSalesNewProduct: (state, { payload }) => {
      state.newQuickRetailSalesNewProduct = payload;
    },

    resetNewQuickRetailSalesNewProduct: (state) => {
      state.newQuickRetailSalesNewProduct = state.newQuickRetailSalesNewProductInitialValue;
    },

    setQuickRetailSalesProducts: (state, { payload }) => {
      state.newQuickRetailSales.products = payload;
    },

    setEditQuickRetailSales: (state, { payload }) => {
      state.editQuickRetailSales = payload;
    },

    setEditQuickRetailSalesNewProduct: (state, { payload }) => {
      state.editQuickRetailSalesNewProduct = payload;
    },

    setQrsSearchProducts: (state, { payload }) => {
      state.qrsSearchProducts = payload;
    },

    resetQrsSearchProducts: (state, { payload }) => {
      state.qrsSearchProducts = [];
    },

    setProductSearchKeyword: (state, { payload }) => {
      state.productSearchKeyword = payload;
    },

    setSeletedPaymentWays: (state, { payload }) => {
      state.newQuickRetailSales.seletedPaymentWays = payload;
    },

    setQuickRetailSalesSearch: (state, { payload }) => {
      state.searchQuickRetailSales = payload;
    },
    setTotalItemsQRS: (state, { payload }) => {
      state.totalItemsQRS = payload;
    },
    setPerPageQRS: (state, { payload }) => {
      state.perPageQRS = payload;
    },

    resetQuickRetailSalesSearch: (state, { payload }) => {
      state.searchQuickRetailSales = [];
    },
    setQuickRetailSalesTemplates: (state, { payload }) => {
      state.quickRetailSalesTemplates = payload;
    },
    setSelectedQuickRetailSalesTemplate: (state, { payload }) => {
      state.quickRetailSalesSelectedTemplate = payload;
    },
  },
});

export const {
  setQuickRetailSales,
  setNewQuickRetailSales,
  setPerPageQRS,
  setTotalItemsQRS,
  resetNewQuickRetailSales,
  setQrsSearchProducts,
  setProductSearchKeyword,
  setSeletedPaymentWays,
  resetQrsSearchProducts,
  setQuickRetailSalesProducts,
  setQuickRetailSalesSearch,
  setQuickRetailSalesTemplates,
  setEditQuickRetailSales,
} = QuickRetailSalesSlice.actions;
