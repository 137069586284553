export const banksDummyData = [
    {
        _id: 1,
        name: "ΤΡΑΠΕΖΑ ΤΗΣ ΕΛΛΑΔΟΣ",
        swift: "BNGRGRAA",
        country: "GR",
    },
    {
        _id: 2,
        name: "ΕΘΝΙΚΗ ΤΡΑΠΕΖΑ ΤΗΣ ΕΛΛΑΔΟΣ Α.Ε.",
        swift: "ETHNGRAA",
        country: "GR",
    },
    {
        _id: 3,
        name: "ΑΛΦΑ ΤΡΑΠΕΖΑ ΑΝΩΝΥΜΟΣ ΕΤΑΙΡΕΙΑ",
        swift: "CRBAGRAA",
        country: "GR",
    },
    {
        _id: 4,
        name: "ATTICA BANK, ΑΝΩΝΥΜΗ ΤΡΑΠΕΖΙΚΗ ΕΤΑΙΡΕΙΑ",
        swift: "ATTIGRAA",
        country: "GR",
    },
    {
        _id: 5,
        name: "ΤΡΑΠΕΖΑ ΠΕΙΡΑΙΩΣ Α.Ε.",
        swift: "PIRBGRAA",
        country: "GR",
    },
    {
        _id: 6,
        name: "ΤΡΑΠΕΖΑ EUROBANK Α.Ε.",
        swift: "ERBKGRAA",
        country: "GR",
    },
    {
        _id: 7,
        name: "ΤΡΑΠΕΖΑ OPTIMA BANK Α.Ε.",
        swift: "IBOGGRAA",
        country: "GR",
    },
    {
        _id: 8,
        name: "BNP PARIBAS SECURITIES SERVICES",
        swift: "PARBGRAX",
        country: "GR",
    },
    {
        _id: 9,
        name: "AEGEAN BALTIC BANK Α.Τ.Ε.",
        swift: "AEBAGRAA",
        country: "GR",
    },
    {
        _id: 10,
        name: "VIVABANK ΜΟΝΟΠΡΟΣΩΠΗ A.Τ.E.",
        swift: "PRXBGRAA",
        country: "GR",
    },
    {
        _id: 11,
        name: "ΣΥΝΕΤΑΙΡΙΣΤΙΚΗ ΤΡΑΠΕΖΑ ΧΑΝΙΩΝ ΣΥΝ.Π.Ε.",
        swift: "STXAGRA1",
        country: "GR",
    },
    {
        _id: 12,
        name: "HSBC CONTINENTAL EUROPE GREECE",
        swift: "MIDLGRAA",
        country: "GR",
    },
    {
        _id: 13,
        name: "ΣΥΝΕΤΑΙΡΙΣΤΙΚΗ ΤΡΑΠΕΖΑ ΗΠΕΙΡΟΥ ΣΥΝ.ΠΕ.",
        swift: "STIOGR21",
        country: "GR",
    },
    {
        _id: 14,
        name: "BANK OF AMERICA EUROPE DESIGNATED ACTIVITY COMPANY – ATHENS BRANCH",
        swift: "BOFAGR2X",
        country: "GR",
    },
    {
        _id: 15,
        name: "CITIBANK EUROPE PLC (CEP)",
        swift: "CITIGRAA",
        country: "GR",
    },
    {
        _id: 16,
        name: "ΠΑΓΚΡΗΤΙΑ ΤΡΑΠΕΖΑ ΑΝΩΝΥΜΗ ΕΤΑΙΡEΙΑ",
        swift: "STPGGRAA",
        country: "GR",
    },
    {
        _id: 17,
        name: "ΣΥΝΕΤΑΙΡΙΣΤΙΚΗ ΤΡΑΠΕΖΑ ΚΑΡΔΙΤΣΑΣ ΣΥΝ.Π.Ε.",
        swift: "STKAGRA1",
        country: "GR",
    },
    {
        _id: 18,
        name: "ΣΥΝΕΤΑΙΡΙΣΤΙΚΗ ΤΡΑΠΕΖΑ ΘΕΣΣΑΛΙΑΣ ΣΥΝ.Π.Ε.",
        swift: "STTKGR21",
        country: "GR",
    },
    {
        _id: 19,
        name: "ΣΥΝΕΤΑΙΡΙΣΤΙΚΗ ΤΡΑΠΕΖΑ ΟΛΥΜΠΟΣ ΣΥΝ.Π.Ε.",
        swift: "STDRGR21",
        country: "GR",
    },
    {
        _id: 20,
        name: "ΣΥΝΕΤΑΙΡΙΣΤΙΚΗ ΤΡΑΠΕΖΑ ΚΕΝΤΡΙΚΗΣ ΜΑΚΕΔΟΝΙΑΣ ΣΥΝ.Π.Ε.",
        swift: "COESGR21",
        country: "GR",
    },
    {
        _id: 21,
        name: "T.C. ZIRAAT BANKASI A.S.",
        swift: "TCZBGRAT",
        country: "GR",
    },
    {
        _id: 22,
        name: "PROCREDIT BANK (BULGARIA) EAD",
        swift: "PRCBGRAA",
        country: "GR",
    },
    {
        _id: 23,
        name: "VIVA ΥΠΗΡΕΣΙΕΣ ΠΛΗΡΩΜΩΝ Α.Ε.",
        swift: "VPAYGRAA",
        country: "GR",
    },
    {
        _id: 24,
        name: "ΤΩΡΑ ΓΟΥΟΛΕΤ ΑΝΩΝΥΜΗ ΕΤΑΙΡΕΙΑ ΥΠΗΡΕΣΙΩΝ ΗΛΕΚΤΡΟΝΙΚΟΥ ΧΡΗΜΑΤΟΣ",
        swift: "TWSAGRA1",
        country: "GR",
    },
    {
        _id: 25,
        name: "COSMOTE PAYMENTS ΥΠΗΡΕΣΙΕΣ ΗΛΕΚΤΡΟΝΙΚΟΥ ΧΡΗΜΑΤΟΣ ΑΝΩΝΥΜΟΣ ΕΤΑΙΡΙΑ",
        swift: "CPEMGRA2",
        country: "GR",
    },
]
