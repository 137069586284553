import React from "react";
import { getGreekCurrencyFormat } from "../../../_helpers/commonFunctions";

const TableFooter = (props) => {
  let { data, footerData, enableFooter, columns } = props;

  let tableData = {no: "", ...footerData, actions: ""}

  if (enableFooter) {
    return (
      <tfoot>
        <tr>
          {
            data && data.length > 0 && columns && columns.map((cell) => {
              let tdWidth = cell.width ? cell.width : 'auto';
              let tableCellValue = tableData[cell.field];

              // Show in greek currency if found amount, totals, price etc
              if (cell.field === 'amount' || cell.field === 'totals' || cell.field === 'price' || cell.field === 'unitPrice') {
                tableCellValue = getGreekCurrencyFormat(tableCellValue);
              }

              return <td style={{ width: tdWidth }} className="productName"><span className="tdelement text-bold"> { tableCellValue }</span></td>
            })
          }
        </tr>
      </tfoot>
    );
  } else {
    return (
      <>Please add something for footer.</>
    )
  }
}
export default TableFooter;
