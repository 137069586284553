import React, {useEffect} from "react";
import {Col, Row} from "react-bootstrap";
import Input from "../../../common/Input";
import {useDispatch, useSelector} from "react-redux";
import {setCashAccountingSettings} from "../../../../_reducers/DataPanel/Cash/cashSlice";
import {setSaveCashAccountingSettings} from "../../../../_reducers/activitySlice";
import {fetchCashSettings} from "../../../../_apis/api";
import {useTranslation} from "react-i18next";
import LogTable from "../../../primer/LogTable";

export const CashSettingsAccounting = () => {
    const dispatch = useDispatch();
    const ACTIVITY = useSelector((state) => state.ACTIVITY);
    const accountingSettings = useSelector((state) => state.CASH_DATA.cashSettings.accounting);
    const company = useSelector((state) => state.COMPANY_DATA.company);

    useEffect(() => {
        dispatch(fetchCashSettings(company.id));
    }, [])

    const handleOnChange = (e, type, name) => {
        if (!ACTIVITY.saveCashAccountingSettings) dispatch(setSaveCashAccountingSettings(true));

        if (type === "input") {
            dispatch(setCashAccountingSettings({...accountingSettings, [name] : e.target.value}));
        }
    }

    return (
        <Row className={"mb-2"}>
            <Col md={3}>
                <Input
                    name={"accountingCashRegister"}
                    label={"Πρόθεμα Ταμειακής Μηχανής"}
                    value={accountingSettings.accountingCashRegister}
                    autoComplete={"off"}
                    onChange={(e) => handleOnChange(e, "input", "accountingCashRegister")}
                />
            </Col>
            <Col md={3}>
                <Input
                    name={"accountingPOS"}
                    label={"Πρόθεμα POS"}
                    value={accountingSettings.accountingPOS}
                    autoComplete={"off"}
                    onChange={(e) => handleOnChange(e, "input", "accountingPOS")}
                />
            </Col>
            <Col md={3}>
                <Input
                    name={"accountingBankAccount"}
                    label={"Πρόθεμα Τραπεζικού Λογαριασμού"}
                    value={accountingSettings.accountingBankAccount}
                    autoComplete={"off"}
                    onChange={(e) => handleOnChange(e, "input", "accountingBankAccount")}
                />
            </Col>
        </Row>
    )
}

export const CashSettingsLog = () => {
    const { t } = useTranslation('common');

    return (
        <React.Fragment>
            <div className="text-muted mb-2"><i>{t('Logs.cashSettingsLog')}</i></div>
            <LogTable model="cashSetting"/>
        </React.Fragment>
    )
};