import React, {useState} from 'react';
import {getObjectFromArrayOfObjects} from "../../../../_helpers/helperFunctions";
import {classicStyleBelowNavbar} from "../Statics";
import SearchTable from "../../../primer/Search-Table";

const SearchWorkMovements = () => {
    const searchWorkMovementsFilters = {
        filters: [
            {
                name: "identifier",
                label: "Όνομα/Επώνυμο/ΑΦΜ εργαζομένου",
                type: "input",
            },
            {
                name: "installationMasterId",
                label: "Εγκατάσταση",
                type: "dropdown",
            },
        ],
        actions: [],
    }

    const searchWorkMovementsColumns = [
        {
            name: "Όνομα",
            field: "firstName",
            width: "15%",
            editable: false,
            inputType: {
                type: "text",
            }
        },
        {
            name: "Επώνυμο",
            field: "lastName",
            width: "15%",
            editable: false,
            inputType: {
                type: "text",
            }
        },
        {
            name: "ΑΦΜ",
            field: "vatNumber",
            width: "20%",
            editable: false,
            inputType: {
                type: "text",
            }
        },
        {
            name: "Εγκατάσταση",
            field: "installationMasterId",
            width: "25%",
            editable: false,
            inputType: {
                type: "text",
            }
        },
        {
            name: "Τύπος Κίνησης",
            field: "movementType",
            width: "25%",
            editable: false,
            inputType: {
                type: "text",
            }
        }
    ]

    const [tableColumns, setTableColumns] = useState(searchWorkMovementsColumns.slice());

    const handleColumnShowHide = (field) => {
        let tempTc = tableColumns.slice();
        let object = getObjectFromArrayOfObjects(tempTc, field, 'field')
        const index = tempTc.findIndex((item) => field === item.field);
        if (object.length > 0) {
            tempTc[index].hiddenColumn = !object['0'].hiddenColumn;
            setTableColumns(tempTc);
        }
    }

    return (
        <div style={classicStyleBelowNavbar}>
            <SearchTable
                tableName={"Work Movements Search"}
                key={"Work Movements Search"}
                hideYearFilter={false}
                allowPagination={true}
                allowToggleColumns={true}
                showDummyData={false}
                columns={tableColumns}
                toggleColumns={handleColumnShowHide}
                allowSelectionCheckbox={false}
                data={[]}
                filtersConfig={searchWorkMovementsFilters}
            />
        </div>
    )
}

export default SearchWorkMovements
