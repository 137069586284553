import React, {useState, useEffect} from "react";
import SearchTable from "../../../primer/Search-Table";
import {getObjectFromArrayOfObjects} from "../../../../_helpers/helperFunctions";
import {useTranslation} from "react-i18next";
import {classicStyleBelowNavbar} from "../Statics";

const SearchProductPriceList = () => {

    const [t, i18n] = useTranslation("common");

    const searchClientFilters = {
        filters: [
            {
                name: "name",
                label: t('ProductsListSearch.smallTable.priceListName'),
                type: "input",
            },
            {
                name: "isActive",
                label: t('ProductsListSearch.smallTable.showActivePriceList'),
                type: "checkbox",
            },
        ],
        actions: ["quick-edit", "delete"],
    }


    // It will hide or show the table columns for searchtable.
    const handleColumnShowHide = (field) => {
        let tempTc = tableColumns.slice();
        let object = getObjectFromArrayOfObjects(tempTc, field, 'field')
        const index = tempTc.findIndex((item) => field === item.field);
        if (object.length > 0) {
            tempTc[index].hiddenColumn = !object['0'].hiddenColumn;
            setTableColumns(tempTc);
        }
    }

    const priceListColumns = [
        {
            name: t('ProductsListSearch.table.productPriceList'),
            field: "name",
            editable: false,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t('ProductsListSearch.table.active'),
            field: 'isActive',
            editable: false,
            inputType: {
                type: 'checkbox',
                options: [{
                    value: 'active',
                    label: 'Active'
                }, {
                    value: "inactive",
                    label: 'In Active'
                }]
            }
        },
    ]
    useEffect(() => {
        setTableColumns(priceListColumns.slice());
    }, [i18n.language]);
    const [tableColumns, setTableColumns] = useState(priceListColumns.slice())

    return (
        <div style={classicStyleBelowNavbar}>
            <SearchTable
                tableName="Search Product Price List"
                key={"Search Product Price List"}
                hideYearFilter={true}
                columns={tableColumns}
                allowToggleColumns={true}
                allowPagination={true}
                toggleColumns={handleColumnShowHide}
                showDummyData={false}
                data={[]}
                filtersConfig={searchClientFilters}
            />
        </div>
    )
}
export default SearchProductPriceList;
