import React, {useRef, useState} from "react";
import Tabs from "../../layouts/Tabs";
import {Button, Row, Col, Modal} from "react-bootstrap";
import Dropdown from "../../primer/Dropdown";
import {checkPermission, companyYearOptions, isEmpty} from "../../../_helpers/commonFunctions";
import "./index.scss";
import Input from "../../common/Input";
import {useDispatch, useSelector} from "react-redux";
import {setCompany} from "../../../_reducers/companySlice";
import {useEffect} from "react";
import axios from "axios";
import {toast} from "react-toastify";
import {logout} from "../../../_reducers/Login";
import {useTranslation} from "react-i18next";
import {
    fetchPrimerMyDataCredentials,
} from "../../../_apis/api";
import CallCenterLive from "../data-panel/CRM/callcenter-live";
import * as moment from "moment";
import "../../primer/POPUP-COMPONENTS/modalWidths.scss";

const apiLoginURL = process.env.REACT_APP_LOGIN_URL;
const errorAuth = "You are not Authorized!";

const ViewWrapper = () => {
    const [t] = useTranslation('common');
    const dispatch = useDispatch();
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const [companyData, setCompanyData] = useState([]);
    const [companyFilterInput, setCompanyFilterInput] = useState("");
    const [show, setShow] = useState(false);
    const [showButton, setShowButton] = useState(false);
    const [tableData, setTableData] = useState(companyData);
    const [selectedData, setSelectedData] = useState({year: new Date().getFullYear(), _id: ""});
    const permissions = localStorage.getItem("permissions");
    const permissionsData = JSON.parse(permissions);
    const [incrKey, setIncrKey] = useState(0);
    const [prefillCompany, setPrefillCompany] = useState(true);
    const [doubleClicked, setDoubleClicked] = useState(false);

    let companyNameAndYear = `${company.name} - ${company.year}`;

    const selectCompanyRef = useRef();

    useEffect(() => {
        if (doubleClicked) {
            setDoubleClicked(false);
            handleSubmit();
        }
    }, [doubleClicked])

    useEffect(() => {
        if (show) {
            getCompanies(selectedData);
        }
    }, [show, selectedData.year])

    useEffect(() => {
        filterCompanies();
        setIncrKey(incrKey + 1);
    }, [companyFilterInput, companyData])

    useEffect(() => {
        if (prefillCompany) {
            const current = localStorage.getItem("company");
            if (current) {
                const companyObject = JSON.parse(current);
                if (companyObject) {
                    setSelectedData({year: companyObject.year, name: companyObject.name, _id: companyObject.id, vatNumber: companyObject.vatNumber});
                    setShowButton(true);
                    setPrefillCompany(false);
                }
            }
        }
    }, [prefillCompany])

    const handleFooterModalClose = () => {
        setCompanyFilterInput("");
        setTableData(companyData);
        setShow(false);
    }

    const handleFooterModalOpen = () => {
        setTableData([]);
        setShow(true);
    }

    const getCompanies = (selectedData) => {
        const user = JSON.parse(localStorage.getItem('user'));
        let uType = "user";
        if (user?.userType === "Admin") uType = "admin";
        axios.get(process.env.REACT_APP_LOGIN_BACKEND_URL + `/api/${uType}/company/list?year=${selectedData.year.toString()}`, {
            headers: {authorization: `Bearer ${localStorage.getItem("token")}`},
        }).then((res) => {
            if (res.status === 200) {
                setCompanyData(res.data.data);
                setTableData(res.data.data);
                setTimeout(() => {
                    if (selectCompanyRef && selectCompanyRef?.current) {
                        selectCompanyRef.current?.scrollIntoView({
                            behavior: "instant",
                            block: "center",
                            inline: "start"
                        })
                    }
                }, 50)
            } else if (res.status === 401) {
                localStorage.clear();
                window.location.replace(`${apiLoginURL}?redirect=ERP&error=${errorAuth}`)
            }
        }).catch((err) => {
            if (err) {
                let message = 'Service is unavailable for the moment. Please try again later.'
                localStorage.clear();
                dispatch(logout());
                window.location.replace(`${apiLoginURL}?redirect=ERP&error=${message}`);
            }
        })
    }

    const handleYearChange = (e) => {
        setSelectedData({...selectedData, year: e.value});
        //axios.get(apiURL + `/company/list?year=${e.value.toString()}`)
        const user = JSON.parse(localStorage.getItem('user'));
        let uType = "user";
        if (user?.userType === "Admin") uType = "admin";
        axios.get(process.env.REACT_APP_LOGIN_BACKEND_URL + `/api/${uType}/company/list?year=${e.value.toString()}`, {
            headers: {authorization: `Bearer ${localStorage.getItem("token")}`},
        }).then((res) => {
            console.log(res.data);
            if (res.data.status === 200) {
                setCompanyData(res.data.data);
                if (res.data.data?.find((el) => el.vatNumber === selectedData.vatNumber) && !isEmpty(selectedData.vatNumber)) {
                    setSelectedData({...selectedData, year: e.value});
                }
            } else if (res.data.status === "401") {
                localStorage.clear();
                window.location.replace(`${apiLoginURL}?redirect=ERP&error=${errorAuth}`)
            }
        }).catch((err) => {
            if (err) {
                let message = 'Service is unavailable for the moment. Please try again later.'
                localStorage.clear();
                dispatch(logout());
                window.location.replace(`${apiLoginURL}?redirect=ERP&error=${message}`);
            }
        })
    }

    const filterCompanies = () => {
        if (String(companyFilterInput).length > 0) {
            let filteredData = [];
            let value = companyFilterInput.toString().toLowerCase();
            const pattern = new RegExp(value, "gi");
            if (companyData)
                filteredData = companyData.filter((item) => {
                    let returnType = false;
                    for (const key in item) {
                        if (key === "name" || key === "vatNumber") {
                            let string = item[key].replace(/\s/g, '')
                            if (pattern.test(string)) {
                                returnType = true;
                            }
                        }
                    }
                    return returnType;
                });
            setTableData(filteredData);
        } else {
            setTableData(companyData);
        }
    }

    const handleSubmit = () => {
        let erpURL = "";
        if (String(selectedData._id) === String(company.id) && String(selectedData.year) === String(company.year)) {
            handleFooterModalClose();
        } else if (selectedData._id) {
            let companyD = {
                id: selectedData._id,
                name: selectedData.name,
                vatNumber: selectedData.vatNumber,
                year: selectedData.year,
            };
            dispatch(setCompany(companyD));
            localStorage.setItem("company", JSON.stringify(companyD));
            companyData.forEach((c) => {
                c.years.forEach((y) => {
                    if (y.year === selectedData.year && selectedData._id === c._id) {
                        erpURL = y.erp_ip;
                    }
                });
            });
            toast.success("Company Changed successfully.");
            handleFooterModalClose();
            dispatch(fetchPrimerMyDataCredentials(companyD.id));
            window.location.replace(`${erpURL}?token=${localStorage.getItem("token")}&company=${JSON.stringify(selectedData)}&device_id=${localStorage.getItem("deviceId")}&erp_ip=${erpURL}`);
        }
    }

    const handleCompanySelection = (company, doubleClick = false) => {
        if (!doubleClick) {
            setShowButton(true);
            setSelectedData({...selectedData, ...company});
        } else {
            setShowButton(false);
            setSelectedData({...selectedData, ...company});
            setDoubleClicked(true);
        }
    }

    const isSelected = (vatNumber) => {
        return vatNumber === selectedData.vatNumber;
    }

    const tdStyle = {
        padding: "7px",
        borderLeft: "1px solid lightgray",
        borderTop: "1px solid lightgray",
        borderRight: "1px solid lightgray",
        borderBottom: "1px solid black",
    }

    return (
        <div className="main-panel fixedPanel">
            <div className="content-wrapper" key={"view-wrapper-static"}>
                <Tabs/>
            </div>

            <Modal className="themeModal" show={show} onHide={() => handleFooterModalClose()} dialogClassName={"modal42PercentWidth"}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('CompanyTable.selectCompanyAndYear')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="pb-3">
                        <Col md={9}>
                            <Input
                                type="text"
                                name="search"
                                label={"Αναζήτηση"}
                                autoComplete={"off"}
                                placeholder={t('CompanyTable.placeholder')}
                                onChange={(e) => setCompanyFilterInput(e.target.value)}
                            />
                        </Col>
                        <Col md={3}>
                            <Dropdown
                                label={"Έτος"}
                                defaultValue={{
                                    label: selectedData.year,
                                    value: selectedData.year,
                                }}
                                options={companyYearOptions()}
                                classes="w-100"
                                onChange={(e) => handleYearChange(e)}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <div style={{maxHeight: "55vh", overflowY: "auto"}}>
                                <table style={{width: "100%"}}>
                                    <colgroup>
                                        <col span={1} style={{width: "80%"}}></col>
                                        <col span={1} style={{width: "20%"}}></col>
                                    </colgroup>
                                    <thead style={{textAlign: "center", backgroundColor: "var(--light-skyblue)", position: "sticky", top: "-1px"}}>
                                    <tr>
                                        <th style={tdStyle}>{t('CompanyTable.company')}</th>
                                        <th style={tdStyle}>{t("General.vatNumber")}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {tableData?.length > 0 ? (
                                        <React.Fragment>
                                            {tableData.map((company, idx) => (
                                                <tr key={`companyModalTable-${idx}`}
                                                    onClick={() => handleCompanySelection(company, false)}
                                                    onDoubleClick={() => handleCompanySelection(company, true)}
                                                    style={{backgroundColor: isSelected(company.vatNumber) ? "var(--light-skyblue)" : "", border: isSelected(company.vatNumber) ? "2px solid black" : ""}}
                                                    ref={isSelected(company.vatNumber) ? selectCompanyRef : null}>
                                                    <td style={tdStyle}>{company.name}</td>
                                                    <td style={{
                                                        ...tdStyle,
                                                        textAlign: "center"
                                                    }}>{company.vatNumber}</td>
                                                </tr>
                                            ))}
                                        </React.Fragment>
                                    ) : (
                                        <tr>
                                            <td colSpan={2}>Δεν βρέθηκαν δεδομένα.</td>
                                        </tr>
                                    )}
                                    </tbody>
                                </table>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    {showButton === true &&
                        <Button variant="primary" onClick={() => handleSubmit()}>{t('CompanyTable.select')}</Button>
                    }
                </Modal.Footer>
            </Modal>

            {/* Footer */}
            <footer className="footer d-flex align-items-center">
                {checkPermission("custom-callcenter", permissionsData) && (company.vatNumber === "800566476" || company.vatNumber === "801866110" || company.vatNumber === "800434990") && <CallCenterLive/>}
                <span className="text-white" style={{position: "relative"}}>{" "}
                    Primer ERP Copyright © Primer Software P.C. {moment().format("YYYY")} Version 3.0.0
                    {(company.companyExpireDate && moment(company.companyExpireDate).diff(moment(), "days") < 30 && moment().toDate() < moment(company.companyExpireDate).toDate()) && (
                        <React.Fragment>
                            <br/>
                            <span
                                className={"text-danger"}>Η συνδρομή σας λήγει σε {moment(company.companyExpireDate).diff(moment(), "days")} ημέρες. Παρακαλώ επικοινωνήστε με την Primer Software.</span>
                        </React.Fragment>
                    )}
                </span>
                {!permissionsData?.find((el) => el.permissionName === "custom-epay" || el.permissionName === "custom-peiraios" || el.permissionName === "custom-daras") && (
                    <Button variant="primary" size="sm" style={{position: "absolute", right: "10px"}} onClick={() => handleFooterModalOpen()}>{companyNameAndYear}</Button>
                )}
            </footer>
        </div>
    )
}

export default ViewWrapper;
