import React, {useEffect, useState} from "react";
import {Card, Col, ListGroup, Row} from "react-bootstrap";
import EditableTable from "../../../primer/Editable-Table";
import Input from "../../../common/Input";
import {setSaveBuysSettings} from "../../../../_reducers/activitySlice";
import {useDispatch, useSelector} from "react-redux";
import {
  setBuysGeneralSettings,
  setBuysPaymentTypes,
  setNewCarNumbers,
  setNewPaymentTypes,
  setNewSendingGoods,
} from "../../../../_reducers/DataPanel/Buys/buysSettingsSlice";
import {
  fetchAdvancedTypes,
  fetchBankAccounts,
  fetchBuysGeneralSettings,
  fetchPOS,
} from "../../../../_apis/api";
import {
  deleteObjectFromArrayOfObjects,
  getLabelByValue,
  getObjectFromArrayOfObjects,
} from "../../../../_helpers/helperFunctions";
import {
  buysPaymentReasonWildcardsList,
  getCurrentUser,
  getDefaultOptionByValue,
  getOptionsByData,
} from "../../../../_helpers/commonFunctions";
import {toast} from "react-toastify";
import Dropdown from "../../../primer/Dropdown";
import Checkbox from "../../../common/CheckBox";
import {buysPaymentTypeData} from "../../../../_data/buys/buys-payment-types";
import {preNumberGreek} from "../../../../_data/settings/advanced-types-search";
import BetterCheckBox from "../../../common/BetterCheckBox";
import {useTranslation} from "react-i18next";
import LogTable from "../../../primer/LogTable";

function BuysGeneralSettings() {
  const [t] = useTranslation('common');

  const buysTypeColumns = [
    {
      name: t("General.no"),
      field: "no",
      editable: false,
    },
    {
      name: t("General.name"),
      field: "name",
      editable: true,
      inputType: {
        type: "text",
      },
    },
  ];

  const carNumbersColumns = [
    {
      name: t("General.no"),
      field: "no",
      editable: false,
    },
    {
      name: t("General.number"),
      field: "number",
      editable: true,
      inputType: {
        type: "text",
      },
    },
  ];

  const paymentTypesColumns = [
    {
      name: t("General.no"),
      field: "no",
      editable: false,
    },
    {
      name: t("General.name"),
      field: "name",
      editable: true,
      inputType: {
        type: "text",
      },
    },
    {
      name: t("General.type"),
      field: "type",
      editable: true,
      width: "150px",
      inputType: {
        type: "dropdown",
        options: getOptionsByData(buysPaymentTypeData, "value", "label"),
      },
    },
  ];

  const ACTIVITY = useSelector((state) => state.ACTIVITY);
  const SETTINGS = useSelector((state) => state.SETTINGS);
  const BUYS_SETTINGS = useSelector((state) => state.BUYS_SETTINGS);
  const CASH_DATA = useSelector((state) => state.CASH_DATA);
  const company = useSelector((state) => state.COMPANY_DATA.company);
  const dispatch = useDispatch();

  const [cptOptions, setSelectedPaymentType] = useState({});
  const [showPaymentTypeOptions, setShowPaymentTypeOptions] = useState(false);
  const [showWildcardsList, setShowWildcardsList] = useState(false);

  const currentUser = getCurrentUser();

  const requestData = {
    company: company.id,
    year: company.year.toString(),
    isActive: "active",
    userId: currentUser.id,
  }
  useEffect(() => {
    dispatch(fetchBuysGeneralSettings(requestData));
    dispatch(fetchPOS(requestData));
    dispatch(fetchBankAccounts(requestData));
    dispatch(fetchAdvancedTypes(requestData));
  }, [dispatch]);

  const handleOnChange = (e, type = "default", ddname = "") => {
    if (!ACTIVITY.saveBuysSettings) dispatch(setSaveBuysSettings(true));
    let name;
    let value;
    if (type === "dd") {
      name = ddname;
      value = e.value;
    } else if (type === "checkbox") {
      name = ddname;
      value = e.target.checked === true ? "active" : "inactive";
    } else {
      name = e.target.name;
      value = e.target.value;
    }
    // Set settings data
    let newSettings = Object.assign({}, BUYS_SETTINGS.buysGeneralSettings);

    let newSettingsData = {};
    newSettingsData[name] = value;
    newSettings = { ...newSettings, ...newSettingsData };
    dispatch(setBuysGeneralSettings(newSettings));
  }

  const handlePaymentOptionsChange = (e, type = "default", ddname = "") => {
    // Set if editing the form
    if (!ACTIVITY.saveBuysSettings) {
      dispatch(setSaveBuysSettings(true));
    }

    let name;
    let value;

    if (type === "dd") {
      name = ddname;
      value = e.value;
    } else {
      name = e.target.name;
      value = e.target.value;
    }

    // Set settings data
    let newSettings = Object.assign({}, BUYS_SETTINGS.buysGeneralSettings);
    let newPaymentOptions = {};
    newPaymentOptions[name] = value;
    if (name === "documentType") {
      newPaymentOptions["documentTypeName"] = e.label;
      newPaymentOptions.documentTypeName = getLabelByValue(documentTypesOptions, value, 'value');
    }

    if (name === "preSelectedPosBankAccount") {
      newPaymentOptions["preSelectedPosBankAccountName"] = e.label;
    }
    let newPaymentTypes = [];
    let oldPaymentTypes = BUYS_SETTINGS.buysGeneralSettings.paymentTypes.slice();
    if (oldPaymentTypes && oldPaymentTypes.length > 0) {
      let cptOptionsName = cptOptions.name;
      const ptIndex = oldPaymentTypes.findIndex(
        (obj) => obj.name === cptOptionsName
      );
      if (ptIndex >= 0) {
        let paymentTypeObject = oldPaymentTypes[ptIndex];
        let paymentTypeOldOptions = oldPaymentTypes[ptIndex].options;
        let tempUpdateObj = {
          ...paymentTypeObject,
          options: { ...paymentTypeOldOptions, ...newPaymentOptions },
        };
        oldPaymentTypes[ptIndex] = tempUpdateObj;
        setSelectedPaymentType(tempUpdateObj); // To current state
      }
      newPaymentTypes = oldPaymentTypes;
    }
    dispatch(setBuysGeneralSettings({ ...newSettings, paymentTypes: newPaymentTypes }));
  };

  const sendingGoodsActions = {
    insert: (e, data) => {
      // Set if editing the form
      if (!ACTIVITY.saveBuysSettings) {
        dispatch(setSaveBuysSettings(true));
      }

      let tempSendingGoods = Object.assign({}, BUYS_SETTINGS.newSendingGoods);
      if (e.key !== "Enter") {
        tempSendingGoods[data.field] = data.value;
        dispatch(setNewSendingGoods(tempSendingGoods)); // Update redux

        if (tempSendingGoods && tempSendingGoods.name !== "") {
          let updatedSendingGood = Object.assign({}, tempSendingGoods);
          let oldSendingGood = BUYS_SETTINGS.buysGeneralSettings.sendingGoods;
          updatedSendingGood.no = oldSendingGood.length + 1;
          updatedSendingGood.id = oldSendingGood.length + 1;
          dispatch(
            setBuysGeneralSettings({
              ...BUYS_SETTINGS.buysGeneralSettings,
              sendingGoods: [...oldSendingGood, updatedSendingGood],
            })
          );
          dispatch(
            setNewSendingGoods(BUYS_SETTINGS.newSendingGoodsInitialValue)
          ); // Reset new category mydata
        }
      } else {
        if (data.field === "name" && data.value === "") {
          toast.error("Name should not be empty.");
        } else {
          let updatedSendingGood = Object.assign(
            {},
            BUYS_SETTINGS.newSendingGoods
          );
          let addCheck = true;

          if (data.field === "name") {
            updatedSendingGood.name = data.value;
          }

          let oldSendingGood = BUYS_SETTINGS.buysGeneralSettings.sendingGoods;
          if ((data.field === "add" || e.key === "Enter") && addCheck) {
            updatedSendingGood.no = oldSendingGood.length + 1;
            updatedSendingGood.id = oldSendingGood.length + 1;
            dispatch(
              setBuysGeneralSettings({
                ...BUYS_SETTINGS.buysGeneralSettings,
                sendingGoods: [...oldSendingGood, updatedSendingGood],
              })
            );
            dispatch(
              setNewSendingGoods(BUYS_SETTINGS.newSendingGoodsInitialValue)
            ); // Reset new category mydata
          }
        }
      }
    },
    update: (data, id) => {
      // Set if editing the form
      if (!ACTIVITY.saveBuysSettings) {
        dispatch(setSaveBuysSettings(true));
      }

      if (data.name && data.name.length === 0) {
        toast.error("Name is mandatory field.");
      } else {
        setSaveBuysSettings(true);
        let sendingGoods =
          BUYS_SETTINGS.buysGeneralSettings.sendingGoods.slice();
        if (sendingGoods.find((item) => item.id === id) !== undefined) {
          sendingGoods[id - 1] = {...sendingGoods[id - 1], ...data};
          dispatch(
            setBuysGeneralSettings({
              ...BUYS_SETTINGS.buysGeneralSettings,
              sendingGoods: sendingGoods,
            })
          ); // update data
        }
      }
    },
    selected: () => {
      console.log("selected mydata row clicked");
    },
    delete: (id) => {
      // Set if editing the form
      if (!ACTIVITY.saveBuysSettings) {
        dispatch(setSaveBuysSettings(true));
      }

      let sendingGoods =
        BUYS_SETTINGS.buysGeneralSettings.sendingGoods.slice();

      if (id !== "" && Number.isInteger(id)) {
        let deletedSendingGoods = deleteObjectFromArrayOfObjects(
          sendingGoods,
          id,
          "id"
        );
        dispatch(
          setBuysGeneralSettings({
            ...BUYS_SETTINGS.buysGeneralSettings,
            sendingGoods: deletedSendingGoods,
          })
        );
      }

      if (id !== "" && !Number.isInteger(id)) {
        let deletedSendingGoods = deleteObjectFromArrayOfObjects(
          sendingGoods,
          id,
          "_id"
        );
        dispatch(
          setBuysGeneralSettings({
            ...BUYS_SETTINGS.buysGeneralSettings,
            sendingGoods: deletedSendingGoods,
          })
        );
      }
    },
  };

  const carNumbersActions = {
    insert: (e, data) => {
      // Set if editing the form
      if (!ACTIVITY.saveBuysSettings) {
        dispatch(setSaveBuysSettings(true));
      }

      let tempCarNumbers = Object.assign({}, BUYS_SETTINGS.newCarNumbers);
      if (e.key !== "Enter") {
        tempCarNumbers[data.field] = data.value;
        dispatch(setNewCarNumbers(tempCarNumbers)); // Update redux

        if (tempCarNumbers && tempCarNumbers.number !== "") {
          let UpdatedCarNumbers = Object.assign({}, tempCarNumbers);
          let oldCarNumbers = BUYS_SETTINGS.buysGeneralSettings.carNumbers;
          UpdatedCarNumbers.no = oldCarNumbers.length + 1;
          UpdatedCarNumbers.id = oldCarNumbers.length + 1;
          dispatch(
            setBuysGeneralSettings({
              ...BUYS_SETTINGS.buysGeneralSettings,
              carNumbers: [...oldCarNumbers, UpdatedCarNumbers],
            })
          );
          dispatch(setNewCarNumbers(BUYS_SETTINGS.newCarNumbersInitialValue)); // Reset new category mydata
        }
      } else {
        if (data.field === "number" && data.value === "") {
          toast.error("Number should not be empty.");
        } else {
          let UpdatedCarNumbers = Object.assign(
            {},
            BUYS_SETTINGS.newCarNumbers
          );
          let addCheck = true;

          if (data.field === "number") {
            UpdatedCarNumbers.number = data.value;
          }

          let oldCarNumbers = BUYS_SETTINGS.buysGeneralSettings.carNumbers;
          if ((data.field === "add" || e.key === "Enter") && addCheck) {
            UpdatedCarNumbers.no = oldCarNumbers.length + 1;
            UpdatedCarNumbers.id = oldCarNumbers.length + 1;
            dispatch(
              setBuysGeneralSettings({
                ...BUYS_SETTINGS.buysGeneralSettings,
                carNumbers: [...oldCarNumbers, UpdatedCarNumbers],
              })
            );
            dispatch(
              setNewCarNumbers(BUYS_SETTINGS.newCarNumbersInitialValue)
            ); // Reset new category mydata
          }
        }
      }
    },
    update: (data, id) => {
      // Set if editing the form
      if (!ACTIVITY.saveBuysSettings) {
        dispatch(setSaveBuysSettings(true));
      }

      if (data.number && data.number.length === 0) {
        toast.error("Number is mandatory field.");
      } else {
        setSaveBuysSettings(true);
        let carNumbers = BUYS_SETTINGS.buysGeneralSettings.carNumbers.slice();
        if (carNumbers.find((item) => item.id === id) !== undefined) {
          carNumbers[id - 1] = {...carNumbers[id - 1], ...data};
          dispatch(
            setBuysGeneralSettings({
              ...BUYS_SETTINGS.buysGeneralSettings,
              carNumbers: carNumbers,
            })
          ); // update data
        }
      }
    },
    selected: () => {
      console.log("selected mydata row clicked");
    },
    delete: (id) => {
      // Set if editing the form
      if (!ACTIVITY.saveBuysSettings) {
        dispatch(setSaveBuysSettings(true));
      }

      let carNumbers = BUYS_SETTINGS.buysGeneralSettings.carNumbers.slice();

      if (id !== "" && Number.isInteger(id)) {
        let deletedCarNumbers = deleteObjectFromArrayOfObjects(
          carNumbers,
          id,
          "id"
        );
        dispatch(
          setBuysGeneralSettings({
            ...BUYS_SETTINGS.buysGeneralSettings,
            carNumbers: deletedCarNumbers,
          })
        );
      }

      if (id !== "" && !Number.isInteger(id)) {
        let deletedCarNumbers = deleteObjectFromArrayOfObjects(
          carNumbers,
          id,
          "_id"
        );
        dispatch(
          setBuysGeneralSettings({
            ...BUYS_SETTINGS.buysGeneralSettings,
            carNumbers: deletedCarNumbers,
          })
        );
      }
    },
  };

  const paymentTypesActions = {
    insert: (e, data) => {
      // Set if editing the form
      if (!ACTIVITY.saveBuysSettings) {
        dispatch(setSaveBuysSettings(true));
      }

      let tempPaymentTypes = Object.assign({}, BUYS_SETTINGS.newPaymentTypes);
      if (e.key !== "Enter") {
        tempPaymentTypes[data.field] = data.value;
        if(data.field === "type"){
          tempPaymentTypes["myDataPaymentId"] = getDefaultOptionByValue(buysPaymentTypeData, data.value, 'value', 'myDataId', 'value').label;
        }
        dispatch(setNewPaymentTypes(tempPaymentTypes)); // Update redux
        if (
          tempPaymentTypes &&
          tempPaymentTypes.name !== "" &&
          tempPaymentTypes.type !== ""
        ) {
          let UpdatedPaymentTypes = Object.assign({}, tempPaymentTypes);
          let oldPaymentTypess =
            BUYS_SETTINGS.buysGeneralSettings.paymentTypes;
          UpdatedPaymentTypes.no = oldPaymentTypess.length + 1;
          UpdatedPaymentTypes.id = oldPaymentTypess.length + 1;
          dispatch(
            setBuysGeneralSettings({
              ...BUYS_SETTINGS.buysGeneralSettings,
              paymentTypes: [...oldPaymentTypess, UpdatedPaymentTypes],
            })
          );
          dispatch(
            setNewPaymentTypes(BUYS_SETTINGS.newPaymentTypesInitialValue)
          ); // Reset new category mydata
        }
      } else {
        if (data.field === "name" && data.value === "") {
          toast.error("Name should not be empty.");
        } else {
          let UpdatedPaymentTypes = Object.assign(
            {},
            BUYS_SETTINGS.newPaymentTypes
          );
          let addCheck = true;

          if (data.field === "name") {
            UpdatedPaymentTypes.name = data.value;
          }

          let oldPaymentTypess =
            BUYS_SETTINGS.buysGeneralSettings.paymentTypes;
          if ((data.field === "add" || e.key === "Enter") && addCheck) {
            UpdatedPaymentTypes.no = oldPaymentTypess.length + 1;
            UpdatedPaymentTypes.id = oldPaymentTypess.length + 1;
            dispatch(
              setBuysGeneralSettings({
                ...BUYS_SETTINGS.buysGeneralSettings,
                paymentTypes: [...oldPaymentTypess, UpdatedPaymentTypes],
              })
            );
            dispatch(
              setNewPaymentTypes(BUYS_SETTINGS.newPaymentTypesInitialValue)
            ); // Reset new category mydata
          }
        }
      }
    },
    update: (data, id) => {
      // Set if editing the form
      if (!ACTIVITY.saveBuysSettings) {
        dispatch(setSaveBuysSettings(true));
      }

      if (data.name && data.name.length === 0) {
        toast.error("Name is mandatory field.");
      } else {
        setSaveBuysSettings(true);
        let paymentTypes = BUYS_SETTINGS.buysGeneralSettings.paymentTypes.slice();
        if (paymentTypes.find((item) => item.id === id) !== undefined) {
          if(data.type){
            data["myDataPaymentId"] = getDefaultOptionByValue(buysPaymentTypeData, data.type, 'value', 'myDataId', 'value').label;
          }
          const updatedPmProducts = { ...paymentTypes[id - 1], ...data };
          paymentTypes[id - 1] = updatedPmProducts;
          setSelectedPaymentType(updatedPmProducts); // Update selected payment type
          dispatch(
            setBuysGeneralSettings({
              ...BUYS_SETTINGS.buysGeneralSettings,
              paymentTypes: paymentTypes,
            })
          ); // update data
        }
      }
    },
    selected: (data) => {
      if (typeof data._id !== "undefined") {
        let paymentTypesData =
          BUYS_SETTINGS.buysGeneralSettings.paymentTypes.slice();
        const selectedPaymentTypeData = getObjectFromArrayOfObjects(
          paymentTypesData,
          data._id,
          "_id"
        );
        if (selectedPaymentTypeData && selectedPaymentTypeData.length > 0) {
          let selectedPaymentType = selectedPaymentTypeData["0"];
          setSelectedPaymentType(selectedPaymentType); // Set data to state
          setShowPaymentTypeOptions(true);
        }
      }
      // }
    },
    delete: (id) => {
      // Set if editing the form
      if (!ACTIVITY.saveBuysSettings) {
        dispatch(setSaveBuysSettings(true));
      }

      let paymentTypes =
        BUYS_SETTINGS.buysGeneralSettings.paymentTypes.slice();

      if (id !== "" && Number.isInteger(id)) {
        let deletedPaymentTypes = deleteObjectFromArrayOfObjects(
          paymentTypes,
          id,
          "id"
        );
        dispatch(
          setBuysGeneralSettings({
            ...BUYS_SETTINGS.buysGeneralSettings,
            paymentTypes: deletedPaymentTypes,
          })
        );
      }

      if (id !== "" && !Number.isInteger(id)) {
        let deletedPaymentTypes = deleteObjectFromArrayOfObjects(
          paymentTypes,
          id,
          "_id"
        );
        dispatch(
          setBuysGeneralSettings({
            ...BUYS_SETTINGS.buysGeneralSettings,
            paymentTypes: deletedPaymentTypes,
          })
        );
      }
    },
  };

  let advancedTypes = SETTINGS.settings.advancedTypes;
  const paymentDocumentTypes = getObjectFromArrayOfObjects(
    advancedTypes,
    "Supplier Payments",
    "section"
  );

  let posData = CASH_DATA.posList;
  let bankData = CASH_DATA.bankAccounts;

  let posOptions = getOptionsByData(posData, "_id", "name");
  posOptions = [{ value: "", label: t("General.empty") }, ...posOptions];

  let bankOptions = getOptionsByData(bankData, "_id", "name");
  bankOptions = [{ value: "", label: t("General.empty") }, ...bankOptions];

  let posbankAccountOptions = [];
  if (showPaymentTypeOptions) {
    if (cptOptions.type === "pos") {
      posbankAccountOptions = posOptions;
    }
    if (cptOptions.type === "bank-account") {
      posbankAccountOptions = bankOptions;
    }
  }

  let documentTypesOptions = getOptionsByData(
    paymentDocumentTypes,
    "_id",
    "name"
  );
  documentTypesOptions = [
    { value: "", label: t("General.empty") },
    ...documentTypesOptions,
  ];
  let wildcardsList = buysPaymentReasonWildcardsList();
  const handleAddWildcard = (e, item, _id) => {
    // Set if editing the form
    if (!ACTIVITY.saveBuysSettings) {
      dispatch(setSaveBuysSettings(true));
    }

    // Set settings data
    let generalSettings = Object.assign(
      {},
      BUYS_SETTINGS.buysGeneralSettings
    );
    let paymentTypes = generalSettings.paymentTypes.slice();
    const pyIndex = paymentTypes.findIndex((item) => _id === item._id);
    let cptOptions = Object.assign({}, paymentTypes[pyIndex].options);
    cptOptions.paymentReason = `${cptOptions?.paymentReason} ${item}`;
    paymentTypes[pyIndex] = {
      ...paymentTypes[pyIndex],
      ...{ options: cptOptions },
    };

    setSelectedPaymentType(paymentTypes[pyIndex]);
    dispatch(setBuysPaymentTypes(paymentTypes));
  };
  return (
    <form className="supplier-settings" autoComplete="off">
      <div className="row">
        <div className="col-12">
          <p className="text-muted">
            <i>{t("GeneralTab")}</i>
          </p>
        </div>
        <div className="col-lg-6 col-12">
          <p>{t("General.waysOfSendingGoods")}</p>
          <EditableTable
            className="mb-3"
            tableName="Buys Ways Of Sending Goods" // Do not change name
            data={BUYS_SETTINGS.buysGeneralSettings.sendingGoods}
            columns={buysTypeColumns}
            onUpdate={sendingGoodsActions}
            key={Math.random()}
            allowActions={true}
            allowInsertRow={true}
          />
        </div>
        <div className="col-lg-6  col-12">
          <p>{t("General.carNumbers")}</p>
          <EditableTable
            className="mb-3"
            tableName="Buys Car Numbers" // Do not change name
            data={BUYS_SETTINGS.buysGeneralSettings.carNumbers}
            columns={carNumbersColumns}
            onUpdate={carNumbersActions}
            key={Math.random()}
            allowActions={true}
            allowInsertRow={true}
          />
        </div>
        <div className="h-100 mb-3 d-flex align-items-center justify-content-start">
          <Checkbox
            className="m-0"
            text={t("General.forbidChangeProductsBuys")}
            name="forbitChange"
            key={Math.random()}
            defaultValue={BUYS_SETTINGS.buysGeneralSettings.forbitChange}
            value={
              BUYS_SETTINGS.buysGeneralSettings.forbitChange === "active"
                ? "inactive"
                : "active"
            }
            onChange={(e) => handleOnChange(e)}
          />
        </div>
        <Row>
          <Col md={4}>
            <BetterCheckBox
                name={"autofillProductClassifications"}
                text={t("General.autocompleteProductsInClassification")}
                checked={BUYS_SETTINGS.buysGeneralSettings.autofillProductClassifications === "active"}
                onChange={(e) => handleOnChange(e, "checkbox", "autofillProductClassifications")}
            />
          </Col>
        </Row>
      </div>

      <hr />
      <div className="row">
        <div className="col-12">
          <p className="text-muted">
            <i>{t("General.paymentTypes")}</i>
          </p>
        </div>
        <div className="col-lg-6 col-12">
          <EditableTable
            tableName="Buys Settings Payment Types" // Do not change name
            selectedRow={cptOptions._id}
            data={BUYS_SETTINGS.buysGeneralSettings.paymentTypes}
            columns={paymentTypesColumns}
            onUpdate={paymentTypesActions}
            key={Math.random()}
            allowInsertRow={true}
          />
        </div>
      </div>

      {showPaymentTypeOptions && (
        <div className="p-3 border mb-3 bg-light">
          <div className="row">
            <div className="col-12">
              <p className="text-muted">
                <i>{t("General.paymentOptions")}</i>
              </p>
            </div>
            <div className="col-12">
              <p>
                <i>{t("General.paymentType")} - {cptOptions.name}</i>
              </p>
            </div>
            {(cptOptions.type === "pos" || cptOptions.type === "bank-account") && (
              <div className="col-12 col-md-4">
                <div className="d-flex align-items-center justify-content-center">
                  <Dropdown
                    label={t("General.preselectedPOSBankAccount")}
                    key={Math.random()}
                    defaultValue={getDefaultOptionByValue(
                      posbankAccountOptions,
                      cptOptions.options?.preSelectedPosBankAccount,
                      "value",
                      "label",
                      "value"
                    )}
                    name="preSelectedPosBankAccount"
                    options={posbankAccountOptions}
                    onChange={(e) =>
                      handlePaymentOptionsChange(
                        e,
                        "dd",
                        "preSelectedPosBankAccount"
                      )
                    }
                  />
                </div>
              </div>
            )}
          </div>
          <div className="row">
            <div className="col-12  col-md-4">
              <div className="h-100 d-flex align-items-end justify-content-start">
                <Checkbox
                  className="mb-3"
                  text={t("General.createAutomaticSupplierPayment")}
                  name="autopayment"
                  key={Math.random()}
                  defaultValue={cptOptions.options?.autopayment}
                  value={
                    cptOptions.options?.autopayment === "active"
                      ? "inactive"
                      : "active"
                  }
                  onChange={(e) => handlePaymentOptionsChange(e)}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-4">
              <div className="mb-3">
                <Dropdown
                  label={t("General.documentType")}
                  key={Math.random()}
                  defaultValue={getDefaultOptionByValue(
                    documentTypesOptions,
                    cptOptions.options?.documentType,
                    "value",
                    "label",
                    "value"
                  )}
                  // required= {true}
                  name="documentType"
                  options={documentTypesOptions}
                  onChange={(e) =>
                    handlePaymentOptionsChange(e, "dd", "documentType")
                  }
                />
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="mb-3">
                <Dropdown
                  label={t("General.preNumber")}
                  name="preNumber"
                  key={Math.random()}
                  defaultValue={getDefaultOptionByValue(preNumberGreek, cptOptions.options?.preNumber , 'value', 'label', 'value')}
                  options={preNumberGreek}
                  onChange={(e) => handlePaymentOptionsChange(e, "dd", "preNumber")}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-8">
              <div className="mb-3">
                <Input
                  label={t("General.reason")}
                  name="paymentReason"
                  charLimit={150}
                  placeholder={t("General.reason")}
                  value={cptOptions.options?.paymentReason}
                  onChange={(e) => handlePaymentOptionsChange(e)}
                />
              </div>
            </div>
            <div className="col-12 col-md-4 d-flex align-items-end">
              <button
                type="button"
                className="btn btn-primary btn-sm mb-3"
                onClick={() => setShowWildcardsList(!showWildcardsList)}
              >
                {t("General.addWildcard")}
              </button>
            </div>
          </div>
          {/* Wildcards List */}
          {showWildcardsList && (
            <Card className="wildcards-list">
              <Card.Title>
                {t("General.wildcards")} -{" "}
                <small>{t("General.clickWildcard")}</small>
              </Card.Title>
              <ListGroup>
                {wildcardsList &&
                  wildcardsList.length > 0 &&
                  wildcardsList.map((item) => {
                    return (
                      <ListGroup.Item
                        title="Click to Add"
                        onClick={(e) =>
                          handleAddWildcard(e, item, cptOptions._id)
                        }
                        key={Math.random()}
                      >
                        {item}
                      </ListGroup.Item>
                    );
                  })}
              </ListGroup>
            </Card>
          )}
        </div>
      )}
    </form>
  )
}

export const BuysGeneralSettingsLog = () => {
  const { t } = useTranslation('common');

  return (
      <React.Fragment>
        <div className="text-muted mb-2"><i>{t('Logs.buysGeneralSettingsLog')}</i></div>
        <LogTable model="buysSetting"/>
      </React.Fragment>
  )
};

export default BuysGeneralSettings;
