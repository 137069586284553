import React, {useEffect, useState} from 'react';
import {Button, Modal} from "react-bootstrap";
import {isEmpty} from "../../../../_helpers/commonFunctions";
import QR from "qrcode-base64";
import {useSelector} from "react-redux";

const ShowQRModal = ({employeeData}) => {
    const companyDetails = useSelector((state) => state.COMPANY_DATA.companyDetails);
    const [show, setShow] = useState(false);
    const [qr, setQR] = useState("");

    useEffect(() => {
        if (show) {
            const firstName = employeeData?.firstName;
            const lastName = employeeData?.lastName;
            const vatNumber = employeeData?.vatNumber;
            const id = companyDetails.erganiEmployerId;
            if (!isEmpty(companyDetails.erganiEmployerId) && !isEmpty(firstName) && !isEmpty(lastName) && !isEmpty(vatNumber)) {
                const QR = `erg|nm:${String(firstName).trim()};ln:${String(lastName).trim()};afm:${String(vatNumber).trim()};id:${id}`;
                setQR(QR);
            }
        } else {
            setQR("");
        }
    },[show])

    return (
        <React.Fragment>
            <Button onClick={() => setShow(true)}>Προβολή QR</Button>
            <Modal show={show} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>QR Εργαζομένου</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {!isEmpty(qr) ? (
                        <div className={"d-flex justify-content-center"}>
                            <img src={QR.drawImg(qr, {
                                typeNumber: 4,
                                errorCorrectLevel: 'M',
                                size: 190
                            })} alt="QR Code" />
                        </div>
                    ) : (
                        <div>Πρόβλημα κατά την παραγωγή QR. Παρακαλώ βεβαιωθείτε ότι έχετε συμπληρώσει, όνομα, επώνυμο και ΑΦΜ εργαζομένου.</div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={() => setShow(false)}>
                        Κλείσιμο
                    </Button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
}

export default ShowQRModal
