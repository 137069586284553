import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  activePage: {}, // will have table name : last active page
  forceRefresh: {}, // will have table name : boolean
  filters: {}, // will have table name : filters
  accordionOpen: {}, // will have table name : boolean
}

export const SearchTableSlice = createSlice({
  name: "search-table-slice",
  initialState,
  reducers: {
    // populate the data in redux store
    setActivePage: (state, { payload }) => {
      state.activePage = payload;
    },
    setForceRefresh: (state, { payload }) => {
      state.forceRefresh = payload;
    },
    addToForceRefresh: (state, { payload }) => {
      state.forceRefresh[payload] = true;
    },
    setFilters: (state, { payload }) => {
      state.filters = payload;
    },
    setAccordionOpen: (state, { payload }) => {
      state.accordionOpen = payload;
    }
  },
});

export const {
  setActivePage,
  addToForceRefresh,
  setForceRefresh,
  setFilters,
  setAccordionOpen
} = SearchTableSlice.actions;
