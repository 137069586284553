import React, {useState, useEffect} from "react";
import axios from "axios";
import {useSelector, useDispatch} from "react-redux";
import {
    setSupplierBalanceReportRequestData, setSupplierBalanceReportResponseData,
} from "../../../../_reducers/DataPanel/Suppliers/supplierSlice";
import {Badge, Button, Col, Row} from "react-bootstrap";
import DateBox from "../../../primer/DateBox";
import AdvancedCombobox from "../../../primer/AdvancedCombobox";
import {toast} from "react-toastify";
import {checkPermission} from "../../../../_helpers/commonFunctions";
import {useTranslation} from "react-i18next";
import moment from "moment";
import EditableTable from "../../../primer/Editable-Table";
import BetterCheckBox from "../../../common/BetterCheckBox";

const SupplierBalance = () => {
    const [t] = useTranslation("common");
    const dispatch = useDispatch();
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const requestData = useSelector((state) => state.SUPPLIERS_DATA.reportSupplierBalanceRequestData);
    const responseData = useSelector((state) => state.SUPPLIERS_DATA.reportSupplierBalanceResponseData);
    const [supplierListData, setSuppliersListData] = useState([]);
    const [loading, setLoading] = useState(false);
    const permissionsData = JSON.parse(localStorage.getItem("permissions"));
    const [pageChanged, setPageChanged] = useState(false);

    useEffect(() => {
        loadInitialSuppliersListData();
    }, [])

    useEffect(() => {
        if (pageChanged) {
            setPageChanged(false);
            fetchReport(false);
        }
    }, [pageChanged]);

    const supplierDropdownColumns = [
        {
            label: t("General.supplierName"),
            name: "name",
        },
        {
            label: t("General.vatNumber"),
            name: "vatNumber",
        },
        {
            label: t("General.phone"),
            name: "phone",
        },
    ];

    let columns = [
        {
            name: t("General.census"),
            field: "census",
            width: "15%",
            editable: true,
            disabled: true,
            inputType: {
                type: 'accounting',
                config: {
                    suffix: "€",
                }
            }
        },
        {
            name: t("General.supplierName"),
            field: "supplierName",
            width: "55%",
            editable: false,
        },
        {
            name: t("General.supplierVAT"),
            field: "vatNumber",
            width: "15%",
            editable: false,
            inputType: {
                type: "text",
            },
        },
        {
            name: t("General.balance"),
            field: "balance",
            width: "15%",
            editable: true,
            disabled: true,
            inputType: {
                type: 'accounting',
                config: {
                    suffix: "€",
                }
            }
        },
    ];

    const loadInitialSuppliersListData = (keyword, setData) => {
        let reqData = {
            company: company.id
        }
        if (keyword) reqData.name = keyword;
        axios.post(process.env.REACT_APP_API_URL2 + `/supplier/list-supplier`, reqData, {
            headers: {"Content-Type": "application/json"},
        }).then((res) => {
            if (res.data && res.data.status === "200") {
                if (res.data.data) {
                    if (setData) {
                        setData(res.data.data);
                    } else {
                        setSuppliersListData(res.data.data);
                    }
                } else {
                    if (setData) {
                        setData([]);
                    } else {
                        setSuppliersListData([]);
                    }
                }
            } else {
                if (setData) {
                    setData([]);
                } else {
                    setSuppliersListData([]);
                }
            }
        }).catch((e) => {
            console.log(e);
        })
    }

    const handleOnChange = (e, type, name) => {
        if (type === "acdd") {
            dispatch(setSupplierBalanceReportRequestData({...requestData, [name] : e.row.name}));
        } else if (type === "datebox") {
            if (!moment(e).isValid()) {
                dispatch(setSupplierBalanceReportRequestData({...requestData, [name] : ""}));
            } else {
                dispatch(setSupplierBalanceReportRequestData({...requestData, [name] : moment(e).format("DD/MM/YYYY")}));
            }
        } else if (type === "checkbox") {
            dispatch(setSupplierBalanceReportRequestData({...requestData, [name] : e.target.checked}));
        }
    }

    const fetchReport = (showMsg) => {
        setLoading(true);
        let reqData = {
            ...requestData,
            year: String(company.year),
            company: company.id
        }
        if (showMsg) reqData.page = 1;
        axios.post(process.env.REACT_APP_API_URL2 + `/report/supplier-balance-report`, reqData, {
            headers: {"Content-Type": "application/json"}
        }).then((res) => {
            setLoading(false);
            if (res.data && res.data.status === "200") {
                if (showMsg) toast.success("Τα δεδομένα ανανεώθηκαν.");
                dispatch(setSupplierBalanceReportResponseData({
                    ...responseData,
                    totalPages: res.data.totalPages,
                    data: res.data.data,
                    totalBalance: res.data.totalBalance,
                }))
            } else {
                if (res.data && res.data.message) {
                    toast.success(res.data && res.data.message);
                } else {
                    toast.error(res.data && res.data.error);
                }
                dispatch(setSupplierBalanceReportResponseData({...responseData, totalPages: 0, data: [], totalBalance: 0}))
            }
            if (showMsg) dispatch(setSupplierBalanceReportRequestData({...requestData, page: 1}));
        }).catch((error) => {
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
            setLoading(false);
            console.log(error);
        })
    }

    const toFirstPage = () => {
        dispatch(setSupplierBalanceReportRequestData({...requestData, page: 1}));
        setPageChanged(true);
    }
    const toPreviousPage = () => {
        dispatch(setSupplierBalanceReportRequestData({...requestData, page: requestData.page - 1}));
        setPageChanged(true);
    }
    const toNextPage = () => {
        dispatch(setSupplierBalanceReportRequestData({...requestData, page: requestData.page + 1}));
        setPageChanged(true);
    }
    const toLastPage = () => {
        dispatch(setSupplierBalanceReportRequestData({...requestData, page: responseData.totalPages}));
        setPageChanged(true);
    }

    return (
        <React.Fragment>
            <Row>
                <Col md={3}>
                    <DateBox
                        name="date_to"
                        label={t("General.dateTo")}
                        selected={moment(requestData.date_to, "DD/MM/YYYY").isValid() ? moment(requestData.date_to, "DD/MM/YYYY").toDate() : ""}
                        onChange={(e) => handleOnChange(e, "datebox", "date_to")}
                    />
                </Col>
                <Col md={3}>
                    <AdvancedCombobox
                        ACName="Sales Supplier"
                        className="w-100 ac-select-dropdown"
                        searchableDropdownEnable={false}
                        defaultValue={requestData.supplier}
                        key={Math.random()}
                        label={t("General.supplier")}
                        data={supplierListData}
                        onChange={(e) => handleOnChange(e, "acdd", "supplier")}
                        resetFilter={() => dispatch(setSupplierBalanceReportRequestData({...requestData, supplier: ""}))}
                        onChangeSearch={(keyword, setData) => loadInitialSuppliersListData(keyword, setData)}
                        excludeColumns={[]}
                        columns={supplierDropdownColumns}
                        editEnable={false}
                        deleteEnable={false}
                        searchTableEnable={false}
                        showTableOptionOnSelect={true}
                        placeholder={t("Placeholders.selectSupplier")}
                        required={false}
                        name="supplier"
                        showInsertAddButton={true}
                        hideSaveButton={true}
                        hideInsertNewRow={true}
                        inputFieldClasses="form-control"
                    />
                </Col>
                <Col md={3}>
                    <BetterCheckBox
                        name={"includeInactive"}
                        className={"mt-4"}
                        text={t("General.includeInactiveSuppliers")}
                        checked={requestData.includeInactive}
                        onChange={(e) => handleOnChange(e, "checkbox", "includeInactive")}
                    />
                </Col>
                <Col md={3}>
                    <BetterCheckBox
                        name={"includeZeroBalance"}
                        className={"mt-4"}
                        text={t("General.includeZeroBalanceSuppliers")}
                        checked={requestData.includeZeroBalance}
                        onChange={(e) => handleOnChange(e, "checkbox", "includeZeroBalance")}
                    />
                </Col>
            </Row>
            {checkPermission("suppliers-reports/supplierbalance-read", permissionsData) &&
                <Row>
                    <Col md={12} className={"ml-auto"}>
                        <Button onClick={() => fetchReport(true)} className="float-right" disabled={loading}>
                            {t('General.applyFilter')}
                        </Button>
                    </Col>
                </Row>
            }
            {responseData.totalPages > 0 ? (
                <React.Fragment>
                    <hr style={{color: "gray", height: 1}}/>
                    <div className={"d-flex justify-content-end mb-2"}>
                        <Button
                            size={"sm"}
                            className={"mr-1 paginate" + (requestData.page !== 1 ? "yes" : "no")}
                            onClick={() => {
                                toFirstPage();
                            }}
                            disabled={requestData.page === 1 || loading}
                        >
                            {t('SalesBalanceSheet.first')}
                        </Button>
                        <Button
                            size={"sm"}
                            className={"mr-1 paginate" + (requestData.page !== 1 ? "yes" : "no")}
                            onClick={() => {
                                toPreviousPage();
                            }}
                            disabled={requestData.page === 1 || loading}
                        >
                            {t('SalesBalanceSheet.previous')}
                        </Button>
                        <Badge style={{fontSize: "12px", lineHeight: "20px"}} className="mr-1"
                               bg="primary">{t('SalesBalanceSheet.page')} {requestData.page} / {responseData.totalPages}</Badge>
                        <Button
                            size={"sm"}
                            className={
                                "mr-1 paginate" + (requestData.page !== responseData.totalPages ? "yes" : "no")
                            }
                            onClick={() => {
                                toNextPage();
                            }}
                            disabled={requestData.page === responseData.totalPages || loading}
                        >
                            {t('SalesBalanceSheet.next')}
                        </Button>
                        <Button
                            size={"sm"}
                            className={
                                "mr-1 paginate" + (requestData.page !== responseData.totalPages ? "yes" : "no")
                            }
                            onClick={() => {
                                toLastPage();
                            }}
                            disabled={requestData.page === responseData.totalPages || loading}
                        >
                            {t('SalesBalanceSheet.last')}
                        </Button>
                    </div>
                    <Row>
                        <EditableTable
                            key={Math.random() + "table"}
                            tableName="Supplier Balance Report"
                            data={responseData.data}
                            allowPagination={false}
                            allowToggleColumns={true}
                            allowInsertRow={false}
                            editRedirectAllowed={true}
                            enableNewRowCheckbox={false}
                            columns={columns}
                            pageTotals={true}
                            allowBulkActions={true}
                            allowPrintResults={checkPermission("suppliers-reports/supplierbalance-general-print", permissionsData) === true}
                            allowActions={false}
                            totalBalanceTrue={true}
                            totalBalance={responseData.totalBalance}
                            company={company}
                            reportExcelPrint={true}
                            filters={requestData}
                        />
                    </Row>
                </React.Fragment>
            ) : (
                <Row className={"mb-2"}>
                    <div>{t("General.noData")}</div>
                </Row>
            )}
        </React.Fragment>
    )
}

export default SupplierBalance;