import React from "react";
import AdvancedCombobox from "./index"

const SampleAdvancedCombobox = () => {
    const excludeColumns = ['id'];

    const users = [
        {
            "_id": 1,
            "first_name": "Rebecka",
            "last_name": "Fennick"
        },
        {
            "_id": 2,
            "first_name": "Genovera",
            "last_name": "Blaszczyk"
        },
        {
            "_id": 3,
            "first_name": "Desiree",
            "last_name": "Hellin"
        },
        {
            "_id": 4,
            "first_name": "Austin",
            "last_name": "Bernette"
        },
        {
            "_id": 5,
            "first_name": "Roxane",
            "last_name": "Rous"
        },
        {
            "_id": 6,
            "first_name": "Auslina",
            "last_name": "Crix"
        },
        {
            "_id": 7,
            "first_name": "Magdalen",
            "last_name": "Blackley"
        },
        {
            "_id": 8,
            "first_name": "Pepito",
            "last_name": "Winslade"
        },
        {
            "_id": 9,
            "first_name": "Ausann",
            "last_name": "Raikes"
        },
        {
            "_id": 10,
            "first_name": "Dorey",
            "last_name": "Probate"
        }
    ]

    const columns = [
        {
            label: "ID",
            name: "id",
        },
        {
            label: "First Name",
            name: "first_name",
        },
        {
            label: "Last Name",
            name: "last_name",
        },
    ]

    return <>
        <AdvancedCombobox
            excludeColumns={excludeColumns}
            columns={columns}
            data={users}
            editEnable={false}
            deleteEnable={false}
            searchTableEnable={false}
            showTableOptionOnSelect={true}
        />
    </>
}

export default SampleAdvancedCombobox;
