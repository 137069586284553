// Products New
import {
  ProductsNewAccounting,
  ProductsNewGeneral,
  ProductsNewOthers,
  ProductsNewProductCodes,
} from "../../layouts/data-panel/ProductsNew/new-product";

// Product Movements  New
import {ProductMovementsGeneral,} from "../../layouts/data-panel/ProductMovements";

// PriceList New
import {PriceListGeneral,} from "../../layouts/data-panel/ProductsPriceList";

import {
  ProductSettings,
  ProductSettingsCategories,
  ProductSettingsLog,
} from "../../layouts/data-panel/ProductsSettings";

import {SettingsMyData} from "../../layouts/data-panel/Settings-MyData";
import {
  AdvancedTypesNew,
  AdvancedTypesSearch,
  BasicTypesNew,
  BasicTypesSearch
} from "../../layouts/data-panel/Settings-Document-Types";
import {
  CompanySettings,
  EmailSmtp,
  MoveDataNew,
  MoveDataSearch,
  PrintTemplatesNew,
  PrintTemplatesSearch,
  SystemTasks
} from "../../layouts/data-panel/Settings";
import NewClientGeneral from "../../layouts/data-panel/Clients/new-client-general";
import NewSupplierGeneral from "../../layouts/data-panel/Suppliers/new-supplier-general";
import NewClientInstallations from "../../layouts/data-panel/Clients/new-client-installations";
import NewClientCommunicationHistory from "../../layouts/data-panel/Clients/new-client-communication-history";
import NewClientNotes from "../../layouts/data-panel/Clients/new-client-notes";
import NewClientEmron from "../../layouts/data-panel/Clients/new-client-emron";
import NewClientAccounting from "../../layouts/data-panel/Clients/new-client-accounting";
import SearchClient from "../../layouts/data-panel/Clients/search-client";
import ClientMovementsNew from "../../layouts/data-panel/Clients/client-movements-new";
import ClientMovementsSearch from "../../layouts/data-panel/Clients/client-movements-search";
import ClientsSettings, {ClientSettingsLog} from "../../layouts/data-panel/Clients/clients-settings";
import VatData from "../../layouts/data-panel/Settings-Data/VatData";
import PrimerDataOtherData from "../../layouts/data-panel/Settings-Data/PrimerDataOtherData";
import VatDataOtherTaxes from "../../layouts/data-panel/Settings-Data/VatDataOtherTaxes";
import VatDataOtherTaxes2 from "../../layouts/data-panel/Settings-Data/VatDataOtherTaxes2";
import LogTable from "../LogTable";
import SalesNew from "../../layouts/data-panel/Sales/sales-new";
import SalesSearch from "../../layouts/data-panel/Sales/sales-search";
import QuickRetailSalesNew from "../../layouts/data-panel/Sales/quick-retail-sales-new";
import QuickRetailSalesSearch from "../../layouts/data-panel/Sales/quick-retail-sales-search";
import ClientPaymentsNew from "../../layouts/data-panel/Sales/client-payments-new";
import ClientPaymentsSearch from "../../layouts/data-panel/Sales/client-payments-search";
import AutomaticSalesNew from "../../layouts/data-panel/Sales/automatic-sales-new";
import AutomaticSalesSearch from "../../layouts/data-panel/Sales/automatic-sales-search";
import {
  ProductsEditAccounting,
  ProductsEditGeneral,
  ProductsEditLog,
  ProductsEditOthers,
  ProductsEditProductCodes
} from "../../layouts/data-panel/products/edit-product";
import CashRegistersNew from "../../layouts/data-panel/Cash/cash-registers-new";
import BankAccountNew from "../../layouts/data-panel/Cash/bank-accounts-new";
import PosNew from "../../layouts/data-panel/Cash/pos-new";
import SalesGeneralSettings, {
  SalesGeneralSettingsLog,
} from "../../layouts/data-panel/Sales/sales-general-settings";
import SalesEmailSettings from "../../layouts/data-panel/Sales/sales-email-settings";
import SettingsQuickRetailSales, {
} from "../../layouts/data-panel/Sales/sales-quick-retail-settings";
import {
  EditPriceListGeneral,
  PriceListEditLog
} from "../../layouts/data-panel/ProductsPriceList/edit-product-price-list";
import {
  EditProductMovementsGeneral,
  ProductMovementsEditLog
} from "../../layouts/data-panel/ProductMovements/edit-product-movements";
import {
  EditAdvancedTypeLogTable,
  EditAdvancedTypes
} from "../../layouts/data-panel/Settings-Document-Types/edit-advanced-types";
import {EditBasicType, EditBasicTypeLogTable} from "../../layouts/data-panel/Settings-Document-Types/edit-basic-types";
import POSSearch from "../../layouts/data-panel/Cash/pos-search";
import BankSearch from "../../layouts/data-panel/Cash/bank-search";
import CashRegistersSearch from "../../layouts/data-panel/Cash/cash-registers-search";
import EditPos, {PosEditLog} from "../../layouts/data-panel/Cash/edit-pos";
import EditBankAccounts, {BankEditLog} from "../../layouts/data-panel/Cash/edit-bank-accounts";
import EditCashRegisters, {CashEditLog} from "../../layouts/data-panel/Cash/edit-cash-registers";
import ViewSales, {SalesEditLog} from "../../layouts/data-panel/Sales/view-sales";
import SalesMydataReport from "../../layouts/data-panel/Sales/sales-mydata-report";
import SalesBalanceSheet from "../../layouts/data-panel/Sales/sales-balance-sheet";
import SalesGraphicReport from "../../layouts/data-panel/Sales/sales-graphic-report";
import SalesClientPaymentReport from "../../layouts/data-panel/Sales/sales-client-payment-report";
import SalesClientPaymentGraphicReport from "../../layouts/data-panel/Sales/sales-client-payment-graphic-report";
import SalesAutomaticSalesReport from "../../layouts/data-panel/Sales/sales-automatic-sales-report";
import EditQuickRetailSales from "../../layouts/data-panel/Sales/edit-quick-retail-sales";
import EditClientGeneral, {ClientsEditLog} from "../../layouts/data-panel/Clients/Edit/edit-client-general";
import EditClientInstallations from "../../layouts/data-panel/Clients/Edit/edit-client-installations";
import EditClientCommunicationHistory from "../../layouts/data-panel/Clients/Edit/edit-client-communication-history";
import EditClientNotes from "../../layouts/data-panel/Clients/Edit/edit-client-notes";
import EditClientEmron from "../../layouts/data-panel/Clients/Edit/edit-client-emron";
import EditClientAccounting from "../../layouts/data-panel/Clients/Edit/edit-client-accounting";
import ProductMovementsReports from "../../layouts/data-panel/products/product-movments-reports";
import BalanceSheetReports from "../../layouts/data-panel/products/balance-sheet-reports";
import EditClientPayments, {ClientPaymentsEditLog} from "../../layouts/data-panel/Sales/edit-client-payments";
import EditSales from "../../layouts/data-panel/Sales/_edit-sales_bck";
import SearchSupplier from "../../layouts/data-panel/Suppliers/search-supplier";
import NewSupplierInstallations from "../../layouts/data-panel/Suppliers/new-supplier-installations";
import NewSupplierCommunicationHistory from "../../layouts/data-panel/Suppliers/new-supplier-communication-history";
import NewSupplierAccounting from "../../layouts/data-panel/Suppliers/new-supplier-accounting";
import SuppliersSettings, {SupplierSettingsLog} from "../../layouts/data-panel/Suppliers/suppliers-settings";
import SupplierBalance from "../../layouts/data-panel/Suppliers/reports-supplier-balance";
import SuppliersMovementsReport from "../../layouts/data-panel/Suppliers/reports-supplier-movements";
import EditSupplierGeneral, {SuppliersEditLog} from "../../layouts/data-panel/Suppliers/Edit/edit-supplier-general";
import EditSupplierInstallations from "../../layouts/data-panel/Suppliers/Edit/edit-supplier-installations";
import EditSupplierCommunicationHistory
  from "../../layouts/data-panel/Suppliers/Edit/edit-supplier-communication-history";
import EditSupplierAccounting from "../../layouts/data-panel/Suppliers/Edit/edit-supplier-accounting";
import SupplierMovementsNew from "../../layouts/data-panel/Suppliers/supplier-movements-new";
import SupplierMovementsSearch from "../../layouts/data-panel/Suppliers/supplier-movements-search";
import ViewBuys from "../../layouts/data-panel/Buys/view-buys";
import EditBuys, {BuysEditLog} from "../../layouts/data-panel/Buys/edit-buys";
import BuysNew from "../../layouts/data-panel/Buys/buys-new";
import BuysSearch from "../../layouts/data-panel/Buys/buys-search";
import SupplierPaymentsNew from "../../layouts/data-panel/Buys/supplier-payments-new";
import EditSupplierPayments, {SupplierPaymentsEditLog} from "../../layouts/data-panel/Buys/edit-supplier-payments";
import SupplierPaymentsSearch from "../../layouts/data-panel/Buys/supplier-payments-search";
import BuysGeneralSettings, {BuysGeneralSettingsLog} from "../../layouts/data-panel/Buys/buys-general-settings";
import BuysSupplierPaymentReport from "../../layouts/data-panel/Buys/buys-supplier-payment-report";
import BuysGraphicReport from "../../layouts/data-panel/Buys/buys-graphic-report";
import BuysBalanceSheet from "../../layouts/data-panel/Buys/buys-balance-sheet";
import EditClientMovements, {ClientMovementsEditLog} from "../../layouts/data-panel/Clients/Edit/edit-client-movements";
import EmronPayments from "../../layouts/data-panel/Clients/reports-emron-payments";
import NewEmployee from "../../layouts/data-panel/Payroll/new-employee";
import EditEmployee from "../../layouts/data-panel/Payroll/edit-employee";
import SearchEmployee from "../../layouts/data-panel/Payroll/employee-search";
import NewAccountingAccount from "../../layouts/data-panel/Accounting/new-accounting-acount";
import EditAccountingAccount, {
  AccountingAccountsEditLog
} from "../../layouts/data-panel/Accounting/edit-accounting-account";
import SearchAccountingAccount from "../../layouts/data-panel/Accounting/accounting-account-search";
import AccountingAccountEditMyData from "../../layouts/data-panel/Accounting/accounting-account-edit-mydata";
import AccountingAccountNewMyData from "../../layouts/data-panel/Accounting/accounting-account-new-mydata";
import {ImportLog, SettingsImport, SettingsOfImport} from "../../layouts/data-panel/Settings/settings-import";
import {SettingsExport, SettingsOfExport} from "../../layouts/data-panel/Settings/settings-export";
import {SettingsOfBackup} from "../../layouts/data-panel/Settings/settings-backup";
import {SettingsOfRestore} from "../../layouts/data-panel/Settings/settings-restore";
import {
  AccountingAccountSettings,
  AccountingPaymentMethods, AccountingSettingsLog,
  AccountingYearReportSettings
} from "../../layouts/data-panel/Accounting/accounting-account-settings";
import {AccountingRecordSettings} from "../../layouts/data-panel/Accounting/accounting-record-settings";
import {NewAccountingRecord} from "../../layouts/data-panel/Accounting/new-accounting-record";
import {
  AccountingRecordEditLog,
  EditAccountingRecord
} from "../../layouts/data-panel/Accounting/edit-accounting-record";
import {SearchAccountingRecord} from "../../layouts/data-panel/Accounting/accounting-record-search";
import EditSupplierMovements, {
  SupplierMovementsEditLog
} from "../../layouts/data-panel/Suppliers/Edit/edit-supplier-movements";
import SettingsSubscriptions from "../../layouts/data-panel/Settings/settings-subscriptions";
import EditClientSubscriptions from "../../layouts/data-panel/Clients/Edit/edit-client-subscriptions";
import ReportsClientsSubscriptions from "../../layouts/data-panel/Clients/reports-clients-subscriptions";
import BuysMydataReport from "../../layouts/data-panel/Buys/buys-mydata-report";
import {
  NewAdvancedTypesAccounting
} from "../../layouts/data-panel/Settings-Document-Types/new-advanced-types-accounting";
import {
  EditAdvancedTypesAccounting
} from "../../layouts/data-panel/Settings-Document-Types/edit-advanced-types-accounting";
import {CashSettingsAccounting, CashSettingsLog} from "../../layouts/data-panel/Cash/cash-settings-accounting";
import {PayrollSettings, PayrollSettingsLogs} from "../../layouts/data-panel/Payroll/payroll-settings";
import EditWorkMovement from "../../layouts/data-panel/Payroll/edit-work-movement";
import NewWorkMovement from "../../layouts/data-panel/Payroll/new-work-movement";
import NewWorkPermit from "../../layouts/data-panel/Payroll/new-work-permit";
import EditWorkPermit from "../../layouts/data-panel/Payroll/edit-work-permit";

export const products_DP_JSON = {
  company: "company_name",
  templateEnable: true,
  templateType: 'product',
  tabs: [
    { key:"products-new-general",
      tabNumber: 1,
      tabName: "ProductsNewGeneralTabs.general",
      tabData: <ProductsNewGeneral />,
      permission: "products-products/new-general-read",
    },
    {key:"products-new-otherTaxes",
      tabNumber: 2,
      tabName: "ProductsNewGeneralTabs.otherTaxes",
      tabData: <ProductsNewOthers />,
      permission: "products-products/new-othertaxes-read"
    },
    {key:"products-new-accounting",
      tabNumber: 3,
      tabName: "ProductsNewGeneralTabs.accounting",
      tabData: <ProductsNewAccounting />,
      permission: "products-products/new-accounting",
    },
    {key:"products-new-productCodes",
      tabNumber: 4,
      tabName: "ProductsNewGeneralTabs.tags",
      tabData: <ProductsNewProductCodes />,
      permission: "products-products/new-tags-read",
    },
  ],
  deleteBtn: false,
  saveBtn: true,
  clearBtn: false,
  clearForm: false,
  saveandrepeat: true,
  header: {
    headerNew: "Headings.newProduct",
    headerEdited: "New Product",
  },
};

export const edit_Product_DP_JSON = {
  company: "company_name",
  templateEnable: true,
  templateType: 'editProduct',
  tabs: [
    {key:"products-new-general",
      tabNumber: 1,
      tabName: "ProductsNewGeneralEditTabs.general",
      tabData: <ProductsEditGeneral />,
      permission: "products-products/edit-general-read",
    },
    {key:"products-new-otherTaxes",
      tabNumber: 2,
      tabName: "ProductsNewGeneralEditTabs.otherTaxes",
      tabData: <ProductsEditOthers />,
      permission: "products-products/edit-othertaxes-read",
    },
    {key:"products-new-accounting",
      tabNumber: 3,
      tabName: "ProductsNewGeneralEditTabs.accounting",
      tabData: <ProductsEditAccounting />,
      permission: "products-products/edit-accounting-read",
    },
    {key:"products-new-productCodes",
      tabNumber: 4,
      tabName: "ProductsNewGeneralEditTabs.tags",
      tabData: <ProductsEditProductCodes />,
      permission: "products-products/edit-tags-read",
    },
    {key:"products-new-log",
      tabNumber: 5,
      tabName: "Log",
      tabData: <ProductsEditLog />,
      permission: "products-products/edit-read",
    },
  ],
  deleteBtn: true,
  saveBtn: true,
  clearBtn: false,
  clearForm: false,
  saveandrepeat: false,
  header: {
    headerNew: "Headings.editProduct",
    headerEdited: "Edit Product",
  },
};

export const productsMovements_DP_JSON = {
  company: "company_name",
  templateEnable: true,
  templateType: 'productMovements',
  tabs: [
    {key:"productMovements-new-general",
      tabNumber: 1,
      tabName: "ProductsMovementsNewTabs.general",
      tabData: <ProductMovementsGeneral />,
      permission: "products-productsmovements/new-general-read",
    },
  ],
  deleteBtn: false,
  saveBtn: true,
  clearBtn: false,
  clearForm: false,
  saveandrepeat: false,
  header: {
    headerNew: "Headings.newProductMovements",
    headerEdited: "New Product Movements",
  },
};

export const editProductsMovements_DP_JSON = {
  company: "company_name",
  templateEnable: true,
  templateType: 'editProductMovements',
  tabs: [
    {key:"productMovements-new-general",
      tabNumber: 1,
      tabName: "ProductsMovementsNewTabs.general",
      tabData: <EditProductMovementsGeneral />,
      permission: "products-productsmovements/edit-general-read",
    },

    {key:"productMovements-new-log",
      tabNumber: 2,
      tabName: "Log",
      tabData: <ProductMovementsEditLog />,
      permission: "products-productsmovements/edit-read",
    },
  ],
  deleteBtn: true,
  saveBtn: true,
  clearBtn: false,
  clearForm: false,
  saveandrepeat: false,
  header: {
    headerNew: "Headings.editProductMovements",
    headerEdited: "Edit Product Movements",
  },
};
export const reportsBalanceSheetMovements_DP_JSON = {
  company: "company_name",
  templateEnable: false,
  tabs: [
    {key:"productMovements-new-general",
      tabNumber: 1,
      tabName: "ReportsBalanceSheetTabs.general",
      tabData: <BalanceSheetReports />,
      permission: "products-reports/balancesheet-read"
    }
  ],
  deleteBtn: false,
  saveBtn: false,
  clearForm: false,
  header: {
    headerNew: "Headings.reportsBalanceSheet",
    headerEdited: "Balance Sheet",
  },
};

export const reportsProductsMovements_DP_JSON = {
  company: "company_name",
  templateEnable: false,
  tabs: [
    {key:"products-reports-productMovements-general",
      tabNumber: 1,
      tabName: "ReportsBalanceSheetTabs.general",
      tabData: <ProductMovementsReports />,
      permission: "products-reports/productmovements-read"
    },
    {key:"products-reports-productMovements-log",
      tabNumber: 2,
      tabName: "Log",
      tabData: <LogTable logType="productMovementsReportLog" />,
      permission: "products-reports/productmovements-read"
    },
  ],
  header: {
    headerNew: "Headings.productMovements",
    headerEdited: "Product Movements",
  },
};


export const pricelist_DP_JSON = {
  company: "company_name",
  templateEnable: true,
  templateType: 'priceList',
  tabs: [
    {key:"products-priceList-new-general",
      tabNumber: 1,
      tabName: "ProductsListNewTabs.general",
      tabData: <PriceListGeneral />,
      permission: "products-pricelist/new-general-read"
    },
  ],
  deleteBtn: false,
  saveBtn: true,
  clearBtn: false,
  clearForm: false,
  saveandrepeat: true,
  header: {
    headerNew: "Headings.newPriceList",
    headerEdited: "New Price List",
  },
};

export const editPricelist_DP_JSON = {
  company: "company_name",
  templateEnable: true,
  templateType: 'editPriceList',
  tabs: [
    {key:"products-priceList-new-general",
      tabNumber: 1,
      tabName: "ProductsListNewTabs.general",
      tabData: <EditPriceListGeneral />,
      permission: "products-pricelist/edit-general-read"
    },

    {key:"products-priceList-new-log",
      tabNumber: 2,
      tabName: "Log",
      tabData: <PriceListEditLog />,
      permission: "products-pricelist/edit-read"
    },
  ],
  deleteBtn: true,
  saveBtn: true,
  clearBtn: false,
  clearForm: false,
  saveandrepeat: false,
  header: {
    headerNew: "Headings.editPriceList",
    headerEdited: "Edit Price List",
  },
};

export const productSettings_Movements_DP_JSON = {
  company: "company_name",
  templateEnable: false,
  templateType: 'productCategory',
  tabs: [
    {key:"products-settings-productCategories-settings",
      tabNumber: 1,
      tabName: "ProductsSettingsTabs.settings",
      tabData: <ProductSettingsCategories />,
      permission: "products-settings/productcategories-read"
    },
    {key:"products-settings-productCategories-log",
      tabNumber: 2,
      tabName: "Log",
      tabData: <ProductSettingsLog />,
      permission: "products-settings/productcategories-read"
    },
  ],
  deleteBtn: false,
  saveBtn: false,
  clearForm: false,
  header: {
    headerNew: "Headings.productCategoriesSettings",
    headerEdited: "Product Categories Settings",
  },
};

export const productSettings_Settings_DP_JSON = {
  company: "company_name",
  templateEnable: false,
  tabs: [
    {
      tabNumber: 1,
      tabName: "Settings",
      tabData: <ProductSettings />,
      permission: "products-components/primercomponents-read"
    },
    // {key:"products-settings-productCategories-log",
    //   tabNumber: 2,
    //   tabName: "Log",
    //   tabData: <ProductSettingsLog />,
    //   permission: "products-settings/productcategories-read"
    // },
  ],
  deleteBtn: true,
  saveBtn: true,
  clearForm: true,
  header: {
    headerNew: "Product Settings",
    headerEdited: "Product Settings",
  },
};

export const accounting_account_settings_DP_JSON = {
  company: "company_name",
  templateEnable: false,
  tabs: [
    {
      tabNumber: 1,
      tabName: "Settings",
      tabData: <AccountingAccountSettings />,
      permission: "accounting-settings/accounting-settings-read",
    },
    {
      tabNumber: 2,
      tabName: "Τρόποι πληρωμής",
      tabData: <AccountingPaymentMethods />,
      permission: "accounting-settings/accounting-paymentmethods-read",
    },
    {
      tabNumber: 3,
      tabName: "Κανόνες Ετησίων Αποτ.",
      tabData: <AccountingYearReportSettings />,
      permission: "accounting-settings/accounting-yearreportrules-read",
    },
    {
      tabNumber: 4,
      tabName: "Log",
      tabData: <AccountingSettingsLog />,
      permission: "accounting-settings/accounting-settings-read",
    }
  ],
  deleteBtn: false,
  saveBtn: false,
  clearForm: false,
  header: {
    headerNew: "Ρυθμίσεις Προτύπων Λογαριασμών",
    headerEdited: "Accounting Settings",
  },
};

export const accounting_record_settings_DP_JSON = {
  company: "company_name",
  templateEnable: false,
  tabs: [
    {
      tabNumber: 1,
      tabName: "Settings",
      tabData: <AccountingRecordSettings />,
      permission: "accounting-settings/recordtemplates-settings-read",
    },
  ],
  deleteBtn: false,
  saveBtn: false,
  clearForm: false,
  header: {
    headerNew: "Ρυθμίσεις Προτύπων Εγγραφής",
    headerEdited: "Accounting Record Settings",
  },
};


export const settingsPrimerMyData = {
  company: "company_name",
  templateEnable: false,
  tabs: [
    {key:"primer-my-data-vat",
      tabNumber: 1,
      tabName: "SettingPrimerMyDataTabs.setting",
      tabData: <SettingsMyData />,
      permission: "settings-primermydata/primermydata-read",
    },

    {key:"products-components-primerCompnents",
      tabNumber: 2,
      tabName: "Log",
      tabData: <LogTable logType="settingsLog" />,
      permission: "settings-primermydata/primermydata-read",
    },
  ],
  deleteBtn: false,
  saveBtn: false,
  clearForm: false,
  header: {
    headerNew: "Headings.primerMyData",
    headerEdited: "Primer MyData",
  },
};

export const settingsPrimerData = {
  company: "company_name",
  templateEnable: false,
  templateType: 'settingsPrimeData',
  tabs: [
    {key:"primer-my-data-vat",
      tabNumber: 1,
      tabName: "SettingsPrimerDataTabs.vat",
      tabData: <VatData />,
      permission: "settings-primermydata/data-read",
    },
    {key:"primer-my-data-otherTaxes1",
      tabNumber: 2,
      tabName: "SettingsPrimerDataTabs.otherTaxes1",
      tabData: <VatDataOtherTaxes />,
      permission: "settings-primermydata/data-read",
    },
    {key:"primer-my-data-otherTaxes2",
      tabNumber: 3,
      tabName: "SettingsPrimerDataTabs.otherTaxes2",
      tabData: <VatDataOtherTaxes2 />,
      permission: "settings-primermydata/data-read",
    },
    {key:"primer-my-data-otherData",
      tabNumber: 4,
      tabName: "SettingsPrimerDataTabs.otherData",
      tabData: <PrimerDataOtherData />,
      permission: "settings-primermydata/data-read",
    },
    {key:"primer-my-data-log",
      tabNumber: 5,
      tabName: "Log",
      tabData: <LogTable logType="primerMyDataDataLog" />,
      permission: "settings-primermydata/data-read",
    },
  ],
  deleteBtn: false,
  saveBtn: true,
  clearForm: false,
  header: {
    headerNew: "Headings.settingsPrimerData",
    headerEdited: "Settings Primer Data",
  },
};

export const settingsDocumentTypesBasicTypesNew = {
  company: "company_name",
  templateEnable: false,
  templateType: 'basicType',
  tabs: [
    {key:"basic-types-new-general",
      tabNumber: 1,
      tabName: "DocumentTypeBasicNewTabs.basicTypes",
      tabData: <BasicTypesNew />,
      permission: "settings-documenttype/basictypesnew-read",
    },
  ],
  deleteBtn: false,
  saveBtn: true,
  clearBtn: false,
  clearForm: false,
  header: {
    headerNew: "Headings.newBasicTypes",
    headerEdited: "New Basic Types",
  },
};

export const settingsDocumentTypesEditBasicTypes = {
  company: "company_name",
  templateEnable: false,
  templateType: 'editBasicType',
  tabs: [
    {key:"basic-types-new-general",
      tabNumber: 1,
      tabName: "DocumentTypeBasicNewTabs.basicTypes",
      tabData: <EditBasicType />,
      permission: "settings-documenttype/basictypesedit-read",
    },

    {key:"basic-types-new-log",
      tabNumber: 2,
      tabName: "Log",
      tabData: <EditBasicTypeLogTable />,
      permission: "settings-documenttype/basictypesedit-read",
    },
  ],
  deleteBtn: true,
  saveBtn: true,
  clearBtn: false,
  clearForm: false,
  header: {
    headerNew: "Headings.editBasicTypes",
    headerEdited: "Edit Basic Types",
  },
};

export const settingsDocumentTypesBasicTypesSearch = {
  company: "company_name",
  templateEnable: false,
  templateType: 'basicTypesSearch',
  tabs: [
    {key:"basic-types-search-general",
      tabNumber: 1,
      tabName: "DocumentTypeBasicNewTabs.basicTypesSearch",
      tabData: <BasicTypesSearch />,
      permission: "settings-documenttype/basictypessearch-read",
    },
    {key:"basic-types-search-log",
      tabNumber: 2,
      tabName: "Log",
      tabData: <LogTable logType="searchBasicTypeLogs" />,
      permission: "settings-documenttype/basictypessearch-read",
    },
  ],
  deleteBtn: false,
  saveBtn: false,
  clearForm: false,
  header: {
    headerNew: "Headings.basicTypesSearch",
    headerEdited: "Basic Types Search",
  },
};

export const settingsDocumentTypesAdvancedTypesNew = {
  company: "company_name",
  templateEnable: false,
  templateType: 'advancedType',
  tabs: [
    {
      key:"advanced-types-new-general",
      tabNumber: 1,
      tabName: "DocumentTypeAdvancedNewTabs.advancedTypes",
      tabData: <AdvancedTypesNew />,
      permission: "settings-documenttype/advancedtypesnew-read",
    },
    {
      key: "advanced-types-new-accounting",
      tabNumber: 2,
      tabName: "Λογιστική",
      tabData: <NewAdvancedTypesAccounting />,
      permission: "settings-documenttype/advancedtypesnew-accounting-read",
    },
  ],
  deleteBtn: false,
  saveBtn: true,
  clearForm: false,
  header: {
    headerNew: "Headings.newAdvancedType",
    headerEdited: "New Advanced Type",
  },
};

export const settingsDocumentTypesEditAdvancedTypes = {
  company: "company_name",
  templateEnable: false,
  templateType: 'editAdvancedType',
  tabs: [
    {
      key:"advanced-types-new-general",
      tabNumber: 1,
      tabName: "DocumentTypeAdvancedNewTabs.advancedTypes",
      tabData: <EditAdvancedTypes />,
      permission: "settings-documenttype/advancedtypesedit-read",
    },
    {
      key: "advanced-types-edit-accounting",
      tabNumber: 2,
      tabName: "Λογιστική",
      tabData: <EditAdvancedTypesAccounting />,
      permission: "settings-documenttype/advancedtypesedit-accounting-read",
    },
    {
      key:"advanced-types-new-log",
      tabNumber: 3,
      tabName: "Log",
      tabData: <EditAdvancedTypeLogTable />,
      permission: "settings-documenttype/advancedtypesedit-read",
    },
  ],
  deleteBtn: true,
  saveBtn: true,
  clearForm: false,
  header: {
    headerNew: "Headings.editAdvancedType",
    headerEdited: "Edit Advanced Type",
  },
};

export const settingsDocumentTypesAdvancedTypesSearch = {
  company: "company_name",
  templateEnable: false,
  templateType: 'advancedTypesSearch',
  tabs: [
    {key:"advanced-types-search-general",
      tabNumber: 1,
      tabName: "DocumentTypeAdvancedNewTabs.advancedTypesSearch",
      tabData: <AdvancedTypesSearch />,
      permission: "settings-documenttype/advancedtypessearch-read",
    },
    {key:"advanced-types-search-log",
      tabNumber: 2,
      tabName: "Log",
      tabData: <LogTable logType="searchAdvancedTypeLogs" />,
      permission: "settings-documenttype/advancedtypessearch-read",
    },
  ],
  deleteBtn: false,
  saveBtn: false,
  clearForm: false,
  header: {
    headerNew: "Headings.advancedTypesSearch",
    headerEdited: "Advanced Types Search",
  },
};

export const settingsGeneralSettingsCompanySettings = {
  company: "company_name",
  templateEnable: false,
  tabs: [
    {key:"company-settings-general",
      tabNumber: 1,
      tabName: "GeneralSettingsTabs.companySettings",
      tabData: <CompanySettings />,
      permission: "settings-generalsettings/companysettings-read",
    },
  ],
  deleteBtn: false,
  saveBtn: false,
  clearForm: false,
  header: {
    headerNew: "Headings.companySettings",
    headerEdited: "Company Settings",
  },
};

export const settingsGeneralSettingsSystemTasks = {
  company: "company_name",
  templateEnable: false,
  tabs: [
    {key:"system-tasks-tasks",
      tabNumber: 1,
      tabName: "GeneralSettingsTabs.tasks",
      tabData: <SystemTasks />,
      permission: "settings-generalsettings/systemtasks-read",
    },

    {key:"system-tasks-log",
      tabNumber: 2,
      tabName: "Log",
      tabData: <LogTable logType="systemTasksLog" />,
      permission: "settings-generalsettings/systemtasks-read",
    },
  ],
  deleteBtn: false,
  saveBtn: false,
  clearForm: true,
  header: {
    headerNew: "Headings.systemTasks",
    headerEdited: "System Tasks",
  },
};

export const settingsGeneralSettingsEmailSmtp = {
  company: "company_name",
  templateEnable: false,
  templateType: 'settingsEmailSmtp',
  tabs: [
    {
      key: "settings-email-smtp",
      tabNumber: 1,
      tabName: "Smtp",
      tabData: <EmailSmtp />,
      permission: "settings-generalsettings/emailsmtp-read",
    },

    {
      key: "settings-email-smtp",
      tabNumber: 2,
      tabName: "Log",
      tabData: <LogTable logType="smtpLog" />,
      permission: "settings-generalsettings/emailsmtp-read",
    },
  ],
  deleteBtn: false,
  saveBtn: true,
  clearForm: true,
  header: {
    headerNew: "Email Smtp",
    headerEdited: "Email Smtp",
  },
};

export const settingsPrintTemplatesNew = {
  company: "company_name",
  templateEnable: false,
  tabs: [
    {
      tabNumber: 1,
      tabName: "New",
      tabData: <PrintTemplatesNew />,
    },
    {
      tabNumber: 2,
      tabName: "Log",
      tabData: <LogTable logType="printTemplatesLog" />,
    },
  ],
  deleteBtn: true,
  saveBtn: true,
  clearForm: true,
  header: {
    headerNew: "Print Templates New",
    headerEdited: "Print Templates",
  },
};

export const settingsPrintTemplatesSearch = {
  company: "company_name",
  templateEnable: false,
  tabs: [
    {
      tabNumber: 1,
      tabName: "Search",
      tabData: <PrintTemplatesSearch />,
    },
    {
      tabNumber: 2,
      tabName: "Log",
      tabData: <LogTable logType="printTemplatesSearchLog" />,
    },
  ],
  deleteBtn: true,
  saveBtn: true,
  clearForm: true,
  header: {
    headerNew: "Print Templates Search",
    headerEdited: "Print Templates",
  },
};

export const settingsImport = {
  company: "company_name",
  templateEnable: false,
  tabs: [
    {key:"settings-import-settings",
      tabNumber: 1,
      tabName: "Settings",
      tabData: <SettingsOfImport />,
      permission: "settings-importexport/import-read",
    },
    {key:"settings-import-import",
      tabNumber: 2,
      tabName: "Import",
      tabData: <SettingsImport />,
      permission: "settings-importexport/import-read",
    },
    {key:"settings-import-log",
      tabNumber: 3,
      tabName: "Log",
      tabData: <ImportLog />,
      permission: "settings-importexport/import-read",
    },
  ],
  deleteBtn: false,
  saveBtn: false,
  clearForm: false,
  header: {
    headerNew: "Import-Export",
    headerEdited: "Import-Export",
  },
};

export const settingsExport = {
  company: "company_name",
  templateEnable: false,
  tabs: [
    { key:"settings-export-settings",
      tabNumber: 1,
      tabName: "Settings",
      tabData: <SettingsOfExport />,
      permission: "settings-importexport/export-read",
    },
    { key:"settings-export-export",
      tabNumber: 2,
      tabName: "Export",
      tabData: <SettingsExport />,
      permission: "settings-importexport/export-read",
    },
    { key:"settings-export-logs",
      tabNumber: 3,
      tabName: "Log",
      tabData: <LogTable logType="exportSettingsLog" />,
      permission: "settings-importexport/export-read",
    },
  ],
  deleteBtn: false,
  saveBtn: false,
  clearForm: false,
  header: {
    headerNew: "Import-Export",
    headerEdited: "Import-Export",
  },
};

export const settingsBackup = {
  company: "company_name",
  templateEnable: false,
  tabs: [
    {key:"settings-backup",
      tabNumber: 1,
      tabName: "Settings",
      tabData: <SettingsOfBackup />,
      permission: "settings-backuprestore/backup-read",
    },
  ],
  deleteBtn: false,
  saveBtn: false,
  clearForm: false,
  header: {
    headerNew: "Backup",
    headerEdited: "Backup",
  },
}

export const settingsRestore = {
  company: "company_name",
  templateEnable: false,
  tabs: [
    {key:"settings-restore",
      tabNumber: 1,
      tabName: "Settings",
      tabData: <SettingsOfRestore />,
      permission: "settings-backuprestore/restore-read",
    },
  ],
  deleteBtn: false,
  saveBtn: false,
  clearForm: false,
  header: {
    headerNew: "Restore",
    headerEdited: "Restore",
  },
}

export const settingsMoveDataNew = {
  company: "company_name",
  templateEnable: false,
  tabs: [
    {
      tabNumber: 1,
      tabName: "New",
      tabData: <MoveDataNew />,
    },
    {
      tabNumber: 3,
      tabName: "Log",
      tabData: <LogTable logType="moveDataLog" />,
    },
  ],
  deleteBtn: true,
  saveBtn: true,
  clearForm: true,
  header: {
    headerNew: "Move Data New",
    headerEdited: "Move Data",
  },
};

export const settingsMoveDataSearch = {
  company: "company_name",
  templateEnable: false,
  tabs: [
    {
      tabNumber: 1,
      tabName: "Search",
      tabData: <MoveDataSearch />,
    },
    {
      tabNumber: 2,
      tabName: "Log",
      tabData: <LogTable logType="moveDataSearchLog" />,
    },
  ],
  deleteBtn: true,
  saveBtn: true,
  clearForm: true,
  header: {
    headerNew: "Move Data Search",
    headerEdited: "Move Data Search",
  },
};

export const newClient_DP_JSON = {
  company: "company_name",
  templateEnable: true,
  templateType: 'client',
  tabs: [
    {
      key:"new-client-general",
      tabNumber: 1,
      tabName: "ClientsNewTabs.general",
      tabData: <NewClientGeneral pageType="new" />,
      permission: "clients-clients/new-general-read",
    },
    {key:"new-client-installations",
      tabNumber: 2,
      tabName: "ClientsNewTabs.installations",
      tabData: <NewClientInstallations pageType="new" />,
      permission: "clients-clients/new-installations-read",
    },
    {key:"new-client-history",
      tabNumber: 3,
      tabName: "ClientsNewTabs.communicationHistory",
      tabData: <NewClientCommunicationHistory pageType="new" />,
      permission: "clients-clients/new-communicationhistory-read",
    },
    {key:"new-client-notes",
      tabNumber: 4,
      tabName: "ClientsNewTabs.notes",
      tabData: <NewClientNotes pageType="new" />,
      permission: "clients-clients/new-notes-read",
    },
    {key:"new-client-emron",
      tabNumber: 5,
      tabName: "Emron",
      tabData: <NewClientEmron pageType="new" />,
      permission: "clients-clients/new-emron-read",
    },
    {key:"new-client-accounting",
      tabNumber: 6,
      tabName: "ClientsNewTabs.accounting",
      tabData: <NewClientAccounting pageType="new" />,
      permission: "clients-clients/new-accounting-read",
    },
  ],
  deleteBtn: false,
  saveBtn: true,
  clearForm: false,
  saveandrepeat: true,
  header: {
    headerNew: "Headings.newClient",
    headerEdited: "New Client",
  },
};


export const newSupplier_DP_JSON = {
  company: "company_name",
  templateEnable: true,
  templateType: 'supplier',
  tabs: [
    {
      key:"new-supplier-general",
      tabNumber: 1,
      tabName: "GeneralTab",
      tabData: <NewSupplierGeneral pageType="new" />,
      permission: "suppliers-suppliers/new-general-read",
    },
    {key:"new-supplier-installations",
      tabNumber: 2,
      tabName: "InnerTabs.installations",
      tabData: <NewSupplierInstallations pageType="new" />,
      permission: "suppliers-suppliers/new-installations-read",
    },
    {key:"new-supplier-history",
      tabNumber: 3,
      tabName: "InnerTabs.communicationHistory",
      tabData: <NewSupplierCommunicationHistory pageType="new" />,
      permission: "suppliers-suppliers/new-communicationhistory",
    },
    {key:"new-supplier-accounting",
      tabNumber: 4,
      tabName: "InnerTabs.accounting",
      tabData: <NewSupplierAccounting pageType="new" />,
      permission: "suppliers-suppliers/new-accounting-read",
    },
  ],
  deleteBtn: false,
  saveBtn: true,
  clearForm: false,
  saveandrepeat: true,
  header: {
    headerNew: "Headers.SupplierNew",
    headerEdited: "Headers.SupplierNew",
  },
}

export const editClient_DP_JSON = {
  company: "company_name",
  templateEnable: true,
  templateType: 'editClient',
  tabs: [
    {
      key:"new-client-general",
      tabNumber: 1,
      tabName: "ClientsNewTabs.general",
      tabData: <EditClientGeneral />,
      permission: "clients-clients/edit-general-read",
    },
    {key:"new-client-installations",
      tabNumber: 2,
      tabName: "ClientsNewTabs.installations",
      tabData: <EditClientInstallations />,
      permission: "clients-clients/edit-installations-read",
    },
    {key:"new-client-information",
      tabNumber: 3,
      tabName: "ClientsNewTabs.communicationHistory",
      tabData: <EditClientCommunicationHistory />,
      permission: "clients-clients/edit-communicationhistory-read",
    },
    {key:"new-client-notes",
      tabNumber: 4,
      tabName: "ClientsNewTabs.notes",
      tabData: <EditClientNotes />,
      permission: "clients-clients/edit-notes-read",
    },
    {key:"new-client-emron",
      tabNumber: 5,
      tabName: "Emron",
      tabData: <EditClientEmron />,
      permission: "clients-clients/edit-emron-read",
    },
    {key:"new-client-accounting",
      tabNumber: 6,
      tabName: "ClientsNewTabs.accounting",
      tabData: <EditClientAccounting />,
      permission: "clients-clients/edit-accounting-read",
    },
    {
      tabNumber: 7,
      tabName: JSON.parse(localStorage.getItem("company"))?.vatNumber === "800434990" ? "Διακανονισμοί" : "Συνδρομές",
      tabData: <EditClientSubscriptions />,
      permission: "clients-clients/edit-subscriptions-read"
    },
    {key:"new-client-log",
      tabNumber: 8,
      tabName: "Log",
      tabData: <ClientsEditLog />,
      permission: "clients-clients/edit-read",
    },
  ],
  deleteBtn: true,
  saveBtn: true,
  clearForm: true,
  saveandrepeat: false,
  header: {
    headerNew: "Headings.editClient",
    headerEdited: "Edit Client",
  },
};

export const editSupplier_DP_JSON = {
  company: "company_name",
  templateEnable: true,
  templateType: 'editSupplier',
  tabs: [
    {
      tabNumber: 1,
      tabName: "GeneralTab",
      tabData: <EditSupplierGeneral />,
      permission: "suppliers-suppliers/edit-general-read",
    },
    {key:"new-supplier-installations",
      tabNumber: 2,
      tabName: "InnerTabs.installations",
      tabData: <EditSupplierInstallations />,
      permission: "suppliers-suppliers/edit-installations-read",
    },
    {key:"new-supplier-information",
      tabNumber: 3,
      tabName: "InnerTabs.communicationHistory",
      tabData: <EditSupplierCommunicationHistory />,
      permission: "suppliers-suppliers/edit-communicationhistory-read",
    },
    {key:"new-supplier-accounting",
      tabNumber: 4,
      tabName: "InnerTabs.accounting",
      tabData: <EditSupplierAccounting />,
      permission: "suppliers-suppliers/edit-accounting-read",
    },
    {key:"new-supplier-log",
      tabNumber: 5,
      tabName: "Log",
      tabData: <SuppliersEditLog />,
      permission: "suppliers-suppliers/edit-read",
    },
  ],
  deleteBtn: true,
  saveBtn: true,
  clearForm: true,
  saveandrepeat: false,
  header: {
    headerNew: "Headers.SupplierEdit",
    headerEdited: "Headers.SupplierEdit",
  },
}


export const searchClient_DP_JSON = {
  company: "company_name",
  templateEnable: false,
  tabs: [
    {key:"client-search-general",
      tabNumber: 1,
      tabName: "ClientsGeneralSearchTabs.search",
      tabData: <SearchClient />,
      permission: "clients-clients/search-read",
    },
    {key:"client-search-log",
      tabNumber: 2,
      tabName: "Log",
      tabData: <LogTable logType="searchClientsLog" />,
      permission: "clients-clients/search-read",
    },
  ],
  deleteBtn: false,
  saveBtn: false,
  clearForm: false,
  header: {
    headerNew: "Headings.searchClient",
    headerEdited: "Search Client",
  },
};

export const searchSupplier_DP_JSON = {
  company: "company_name",
  templateEnable: false,
  tabs: [
    {key:"supplier-search-general",
      tabNumber: 1,
      tabName: "Search",
      tabData: <SearchSupplier />,
      permission: "suppliers-suppliers/search-read",
    },
    {key:"supplier-search-log",
      tabNumber: 2,
      tabName: "Log",
      tabData: <LogTable logType="searchSuppliersLog" />,
      permission: "suppliers-suppliers/search-read",
    },
  ],
  deleteBtn: false,
  saveBtn: false,
  clearForm: false,
  header: {
    headerNew: "Search Supplier",
    headerEdited: "Search Supplier",
  },
};

export const clientMovementsNew_DP_JSON = {
  company: "company_name",
  templateEnable: true,
  templateType: 'clientMovements',
  tabs: [
    {key:"client-movements-new-general",
      tabNumber: 1,
      tabName: "ClientsMovementNewTabs.general",
      tabData: <ClientMovementsNew />,
      permission: "clients-clientmovements/new-read",
    },
  ],
  deleteBtn: false,
  saveBtn: true,
  clearForm: true,
  saveandrepeat: true,
  header: {
    headerNew: "Headings.newClientMovements",
    headerEdited: "New Client Movements",
  },
};

export const editClientMovements_DP_JSON = {
  company: "company_name",
  templateEnable: true,
  templateType: 'editClientMovements',
  tabs: [
    {key:"client-movements-new-general",
      tabNumber: 1,
      tabName: "ClientsMovementNewTabs.general",
      tabData: <EditClientMovements />,
      permission: "clients-clientmovements/edit-read",
    },
    {key:"client-movements-new-log",
      tabNumber: 2,
      tabName: "Log",
      tabData: <ClientMovementsEditLog />,
      permission: "clients-clientmovements/edit-read",
    },
  ],
  deleteBtn: true,
  saveBtn: true,
  clearForm: true,
  saveandrepeat: false,
  header: {
    headerNew: "Headings.editClientMovements",
    headerEdited: "Edit Client Movements",
  },
};

export const supplierMovementsNew_DP_JSON = {
  company: "company_name",
  templateEnable: true,
  templateType: 'supplierMovements',
  tabs: [
    {key:"supplier-movements-new-general",
      tabNumber: 1,
      tabName: "GeneralTab",
      tabData: <SupplierMovementsNew />,
      permission: "suppliers-suppliermovements/new-read",
    },
  ],
  deleteBtn: false,
  saveBtn: true,
  clearForm: true,
  saveandrepeat: true,
  header: {
    headerNew: "Headers.SupplierMovementNew",
    headerEdited: "Headers.SupplierMovementNew",
  },
}

export const editSupplierMovements_DP_JSON = {
  company: "company_name",
  templateEnable: true,
  templateType: 'editSupplierMovements',
  tabs: [
    {key:"supplier-movements-new-general",
      tabNumber: 1,
      tabName: "GeneralTab",
      tabData: <EditSupplierMovements pageType = "edit" />,
      permission: "suppliers-suppliermovements/edit-read",
    },
    {key:"supplier-movements-new-log",
      tabNumber: 2,
      tabName: "Log",
      tabData: <SupplierMovementsEditLog />,
      permission: "suppliers-suppliermovements/edit-read",
    },
  ],
  deleteBtn: true,
  saveBtn: true,
  clearForm: true,
  saveandrepeat: false,
  header: {
    headerNew: "Headers.SupplierMovementEdit",
    headerEdited: "Headers.SupplierMovementEdit",
  },
}

export const clientMovementsSearch_DP_JSON = {
  company: "company_name",
  templateEnable: false,
  tabs: [
    {key:"client-movements-search-general",
      tabNumber: 1,
      tabName: "ClientsMovementSearchTabs.general",
      tabData: <ClientMovementsSearch />,
      permission: "clients-clientmovements/search-read",
    },
    {key:"client-movements-search-log",
      tabNumber: 2,
      tabName: "Log",
      tabData: <LogTable logType="searchClientMovementsLog" />,
      permission: "clients-clientmovements/search-read",
    },
  ],
  deleteBtn: false,
  saveBtn: false,
  clearForm: false,
  header: {
    headerNew: "Headings.clientMovementsSearch",
    headerEdited: "Client Movements Search",
  },
};

export const supplierMovementsSearch_DP_JSON = {
  company: "company_name",
  templateEnable: false,
  tabs: [
    {key:"supplier-movements-search-general",
      tabNumber: 1,
      tabName: "General",
      tabData: <SupplierMovementsSearch />,
      permission: "suppliers-suppliermovements/search-read",
    },
    {key:"supplier-movements-search-log",
      tabNumber: 2,
      tabName: "Log",
      tabData: <LogTable logType="searchSupplierMovementsLog" />,
      permission: "suppliers-suppliermovements/search-read",
    },
  ],
  deleteBtn: false,
  saveBtn: false,
  clearForm: false,
  header: {
    headerNew: "Supplier Movements Search",
    headerEdited: "Supplier Movements Search",
  },
};

export const reportsEmronPayments_DP_JSON = {
  company: "company_name",
  templateEnable: false,
  tabs: [
    {key:"emron-payments-general",
      tabNumber: 1,
      tabName: "Μηνιαίες Χρεώσεις",
      tabData: <EmronPayments />,
      permission: "clients-reports/emronpayments-read",
    }
  ],
  deleteBtn: false,
  saveBtn: false,
  clearForm: false,
  header: {
    headerNew: "Μηνιαίες Χρεώσεις",
    headerEdited: "Μηνιαίες Χρεώσεις",
  },
}

export const reportsSupplierBalance_DP_JSON = {
  company: "company_name",
  templateEnable: false,
  tabs: [
    {key:"supplier-reports-balancesheet-general",
      tabNumber: 1,
      tabName: "GeneralTab",
      tabData: <SupplierBalance />,
      permission: "suppliers-reports/supplierbalance-read",
    }
  ],
  deleteBtn: false,
  saveBtn: false,
  clearForm: false,
  header: {
    headerNew: "Headers.SupplierBalanceReport",
    headerEdited: "Headers.SupplierBalanceReport",
  },
}

export const reportsSuppliersMovements_DP_JSON = {
  company: "company_name",
  templateEnable: false,
  tabs: [
    {key:"supplier-reports-movements-general",
      tabNumber: 1,
      tabName: "GeneralTab",
      tabData: <SuppliersMovementsReport />,
      permission: "suppliers-reports/suppliersmovements-read"
    }
  ],
  deleteBtn: false,
  saveBtn: false,
  clearForm: false,
  header: {
    headerNew: "Headers.SupplierMovementReport",
    headerEdited: "Headers.SupplierMovementReport",
  },
}

export const settingsClientsSettings_DP_JSON = {
  company: "company_name",
  templateEnable: false,
  templateType: 'clientsSettings',
  tabs: [
    {key:"client-settings-general",
      tabNumber: 1,
      tabName: "ReportsClientsSettingsTabs.general",
      tabData: <ClientsSettings />,
      permission: "clients-settings/clientsettings-read",
    },
    {key:"client-settings-massEmails",
      tabNumber: 2,
      tabName: "ReportsClientsSettingsTabs.massEmail",
      tabData: <p>No information </p>,
      permission: "clients-settings/clientsettings-read",
    },
    {key:"client-settings-log",
      tabNumber: 3,
      tabName: "Log",
      tabData: <ClientSettingsLog />,
      permission: "clients-settings/clientsettings-read",
    },
  ],
  deleteBtn: false,
  saveBtn: true,
  clearForm: false,
  header: {
    headerNew: "Headings.clientsSettings",
    headerEdited: "Clients Settings",
  },
};

export const settingsSuppliersSettings_DP_JSON = {
  company: "company_name",
  templateEnable: false,
  templateType: 'suppliersSettings',
  tabs: [
    {key:"supplier-settings-general",
      tabNumber: 1,
      tabName: "GeneralTab",
      tabData: <SuppliersSettings />,
      permission: "suppliers-settings/suppliersettings-read",
    },
    /*{key:"supplier-settings-massEmails",
      tabNumber: 2,
      tabName: "Mass Emails",
      tabData: <p>No information </p>,
      permission: "suppliers-settings/suppliersettings-read",
    },*/
    {key:"supplier-settings-log",
      tabNumber: 3,
      tabName: "Log",
      tabData: <SupplierSettingsLog />,
      permission: "suppliers-settings/suppliersettings-read",
    },
  ],
  deleteBtn: false,
  saveBtn: true,
  clearForm: false,
  header: {
    headerNew: "Headers.SupplierSettings",
    headerEdited: "Headers.SupplierSettings",
  },
}

// Sales
export const salesNew_DP_JSON = {
  company: "company_name",
  templateEnable: true,
  templateType: 'sales',
  tabs: [
    {key:"sales-new-sales",
      tabNumber: 1,
      tabName: "SalesNewTabs.sales",
      tabData: <SalesNew />,
      permission: "sales-sales/new-read"
    },
  ],
  deleteBtn: false,
  saveBtn: true,
  clearForm: false,
  saveandrepeat: true,
  sendEmailOnSave: true,
  printLayoutDropdown: true,
  printBtn: false,
  convertDocument: false,
  issueCreditDocument: false,
  header: {
    headerNew: "Headings.newSales",
    headerEdited: "New Sales",
  },
};

// Buys
export const buysNew_DP_JSON = {
  company: "company_name",
  templateEnable: true,
  templateType: 'buys',
  tabs: [
    {key:"buys-new-buys",
      tabNumber: 1,
      tabName: "Buys",
      tabData: <BuysNew />,
      permission: "buys-buy/new-read",
    },
  ],
  deleteBtn: false,
  saveBtn: true,
  clearForm: false,
  saveandrepeat: true,
  sendEmailOnSave: true,
  printLayoutDropdown: true,
  printBtn: false,
  convertDocument: false,
  issueCreditDocument: false,
  header: {
    headerNew: "Headers.BuysNew",
    headerEdited: "Headers.BuysNew",
  },
};

// Sales
export const salesEdit_DP_JSON = {
  company: "company_name",
  templateEnable: false,
  templateType: 'editSales',
  tabs: [
    {key:"sales-new-sales",
      tabNumber: 1,
      tabName: "SalesNewTabs.sales",
      tabData: <ViewSales />,
      permission: "sales-sales/edit-read",
    },
    {key:"sales-edit-log",
      tabNumber: 2,
      tabName: "Log",
      tabData: <SalesEditLog />,
      permission: "sales-sales/edit-read",
    },
  ],
  deleteBtn: false,
  saveBtn: false,
  clearForm: false,
  saveandrepeat: false,
  sendEmailOnSave: false,
  printLayoutDropdown: true,
  printBtn: true,
  convertDocument: true,
  issueCreditDocument: true,
  cancelMyDataInvoice: true,
  header: {
    headerNew: "Headings.viewSales",
    headerEdited: "View Sales",
  },
};

export const salesEditTrue_DP_JSON = {
  company: "company_name",
  templateEnable: false,
  templateType: 'editSales',
  tabs: [
    {key:"sales-edit-sales",
      tabNumber: 1,
      tabName: "SalesNewTabs.sales",
      tabData: <EditSales />,
      permission: "sales-sales/edit-read",
    },
    {key:"sales-edit-log",
      tabNumber: 2,
      tabName: "Log",
      tabData: <SalesEditLog />,
      permission: "sales-sales/edit-read",
    },
  ],
  deleteBtn: false,
  saveBtn: true,
  clearForm: false,
  saveandrepeat: false,
  sendEmailOnSave: true,
  printLayoutDropdown: true,
  printBtn: true,
  convertDocument: true,
  issueCreditDocument: true,
  cancelMyDataInvoice: true,
  header: {
    headerNew: "Headings.editSales",
    headerEdited: "Edit Sales",
  },
};

// buys
export const buysEdit_DP_JSON = {
  company: "company_name",
  templateEnable: false,
  templateType: 'editBuys',
  tabs: [
    {key:"buys-new-buys",
      tabNumber: 1,
      tabName: "Buys",
      tabData: <ViewBuys />,
      permission: "buys-buy/edit-read",
    },
    {key:"buys-edit-log",
      tabNumber: 2,
      tabName: "Log",
      tabData: <BuysEditLog />,
      permission: "buys-buy/edit-read",
    },
  ],
  deleteBtn: false,
  saveBtn: false,
  clearForm: false,
  saveandrepeat: false,
  sendEmailOnSave: false,
  printLayoutDropdown: true,
  printBtn: true,
  convertDocument: false,
  issueCreditDocument: false,
  cancelMyDataInvoice: true,
  header: {
    headerNew: "Headers.BuysView",
    headerEdited: "Headers.BuysView",
  },
};

export const buysEditTrue_DP_JSON = {
  company: "company_name",
  templateEnable: false,
  templateType: 'editBuys',
  tabs: [
    {key:"buys-edit-buys",
      tabNumber: 1,
      tabName: "Buys",
      tabData: <EditBuys />,
      permission: "buys-buy/edit-read",
    },
    {key:"buys-edit-log",
      tabNumber: 2,
      tabName: "Log",
      tabData: <BuysEditLog />,
      permission: "buys-buy/edit-read",
    },
  ],
  deleteBtn: false,
  saveBtn: true,
  clearForm: false,
  saveandrepeat: false,
  sendEmailOnSave: true,
  printLayoutDropdown: true,
  printBtn: true,
  convertDocument: false,
  issueCreditDocument: false,
  header: {
    headerNew: "Headers.BuysEdit",
    headerEdited: "Headers.BuysEdit",
  },
};

export const salesSearch_DP_JSON = {
  company: "company_name",
  templateEnable: false,
  tabs: [
    {key:"sales-search-search",
      tabNumber: 1,
      tabName: "SalesSearchTabs.search",
      tabData: <SalesSearch />,
      permission: "sales-sales/search-read",
    },
    {key:"sales-search-log",
      tabNumber: 2,
      tabName: "Log",
      tabData: <LogTable logType="searchSalesLog" />,
      permission: "sales-sales/search-read",
    },
  ],
  deleteBtn: false,
  saveBtn: false,
  clearForm: false,
  header: {
    headerNew: "Headings.salesSearch",
    headerEdited: "Sales Search",
  },
};

export const buysSearch_DP_JSON = {
  company: "company_name",
  templateEnable: false,
  tabs: [
    {key:"buys-search-search",
      tabNumber: 1,
      tabName: "Search",
      tabData: <BuysSearch />,
      permission: "buys-buy/search-read",
    },
    {key:"buys-search-log",
      tabNumber: 2,
      tabName: "Log",
      tabData: <LogTable logType="searchBuysLog" />,
      permission: "buys-buy/search-read",
    },
  ],
  deleteBtn: false,
  saveBtn: false,
  clearForm: false,
  header: {
    headerNew: "Buys Search",
    headerEdited: "Buys Search",
  },
};


export const quickRetailSalesNew_DP_JSON = {
  company: "company_name",
  templateEnable: true,
  templateType: 'quickRetailSales',
  tabs: [
    {key:"quick-retail-sales-new-retail-read",
      tabNumber: 1,
      tabName: "QuickRetailSalesNewTabs.retail",
      tabData: <QuickRetailSalesNew />,
      permission: "sales-quickretailsales/new-read"
    },
    {key:"quick-retail-sales-new-log-read",
      tabNumber: 2,
      tabName: "Log",
      tabData: <LogTable logType="quickRetailSalesLog" />,
      permission: "sales-quickretailsales/new-read"
    },
  ],
  deleteBtn: false,
  saveBtn: true,
  clearForm: false,
  saveandrepeat: false,
  header: {
    headerNew: "Headings.newQuickRetailSales",
    headerEdited: "Quick Retail Sales New",
  },
};

export const quickRetailSalesEdit_DP_JSON = {
  company: "company_name",
  templateEnable: true,
  templateType: 'editQuickRetailSales',
  tabs: [
    {key:"quick-retail-sales-new-retail-read",
      tabNumber: 1,
      tabName: "QuickRetailSalesNewTabs.retail",
      tabData: <EditQuickRetailSales />,
      permission: "sales-quickretailsales/edit-read"
    },
    {keyL:"quick-retail-sales-new-log-read",
      tabNumber: 2,
      tabName: "Log",
      tabData: <LogTable logType="quickRetailEditLog" />,
      permission: "sales-quickretailsales/edit-read"
    },
  ],
  deleteBtn: false,
  saveBtn: true,
  clearForm: false,
  saveandrepeat: false,
  header: {
    headerNew: "Headings.editQuickRetailSales",
    headerEdited: "Edit Quick Retail Sales",
  },
};

export const quickRetailSalesSearch_DP_JSON = {
  company: "company_name",
  templateEnable: false,
  tabs: [
    {key:"quick-retail-sales-search-read",
      tabNumber: 1,
      tabName: "Search",
      tabData: <QuickRetailSalesSearch />,
      permission: "sales-quickretailsales/search-read"
    },
    {key:"quick-retail-sales-search-log-read",
      tabNumber: 2,
      tabName: "Log",
      tabData: <LogTable logType="searchQuickRetailSalesLog" />,
      permission: "sales-quickretailsales/search-read"
    },
  ],
  deleteBtn: false,
  saveBtn: false,
  clearForm: false,
  header: {
    headerNew: "Headings.QuickRetailSalesSearch",
    headerEdited: "Quick Retail Sales Search",
  },
};

export const clientPaymentsNew_DP_JSON = {
  company: "company_name",
  templateEnable: true,
  templateType: 'clientPayments',
  tabs: [
    {key:"client-payments-new-general-read",
      tabNumber: 1,
      tabName: "SalesClientPaymentsTabs.general",
      tabData: <ClientPaymentsNew />,
      permission: "sales-clientpayments/new-read",
    },
  ],
  deleteBtn: false,
  saveBtn: true,
  clearForm: false,
  saveandrepeat: false,
  sendEmailOnSave: true,
  printLayoutDropdown: true,
  header: {
    headerNew: "Headings.clientPaymentsNew",
    headerEdited: "Client Payments New",
  },
};

export const clientPaymentsEdit_DP_JSON = {
  company: "company_name",
  templateEnable: true,
  templateType: 'editClientPayments',
  tabs: [
    {key:"client-payments-new-general-read",
      tabNumber: 1,
      tabName: "SalesClientPaymentsTabs.general",
      tabData: <EditClientPayments />,
      permission: "sales-clientpayments/edit-read",
    },
    {key:"client-payments-new-log-read",
      tabNumber: 2,
      tabName: "Log",
      tabData: <ClientPaymentsEditLog />,
      permission: "sales-clientpayments/edit-read",
    },
  ],
  deleteBtn: true,
  saveBtn: true,
  clearForm: false,
  saveandrepeat: false,
  printLayoutDropdown: true,
  printBtn: true,
  header: {
    headerNew: "Headings.editClientPayments",
    headerEdited: "Edit Client Payments",
  },
};

export const clientPaymentsSearch_DP_JSON = {
  company: "company_name",
  templateEnable: false,
  tabs: [
    {key:"client-payments-search-search",
      tabNumber: 1,
      tabName: "SalesClientPaymentsSearchTabs.search",
      tabData: <ClientPaymentsSearch />,
      permission: "sales-clientpayments/search-read",
    },
    {key:"client-payments-search-log",
      tabNumber: 2,
      tabName: "Log",
      tabData: <LogTable logType="searchClientPaymentsLog" />,
      permission: "sales-clientpayments/search-read",
    },
  ],
  deleteBtn: false,
  saveBtn: false,
  clearForm: false,
  header: {
    headerNew: "Headings.clientPaymentsSearch",
    headerEdited: "Client Payments Search",
  },
};


export const supplierPaymentsNew_DP_JSON = {
  company: "company_name",
  templateEnable: true,
  templateType: 'supplierPayments',
  tabs: [
    {key:"supplier-payments-new-general-read",
      tabNumber: 1,
      tabName: "GeneralTab",
      tabData: <SupplierPaymentsNew />,
      permission: "buys-supplierpayments/new-read",
    },
  ],
  deleteBtn: false,
  saveBtn: true,
  clearForm: false,
  saveandrepeat: false,
  header: {
    headerNew: "Headers.SupplierPaymentNew",
    headerEdited: "Headers.SupplierPaymentEdit",
  },
};

export const supplierPaymentsEdit_DP_JSON = {
  company: "company_name",
  templateEnable: true,
  templateType: 'editSupplierPayments',
  tabs: [
    {key:"supplier-payments-new-general-read",
      tabNumber: 1,
      tabName: "GeneralTab",
      tabData: <EditSupplierPayments />,
      permission: "buys-supplierpayments/edit-read",
    },
    {key:"supplier-payments-new-log-read",
      tabNumber: 2,
      tabName: "Log",
      tabData: <SupplierPaymentsEditLog />,
      permission: "buys-supplierpayments/edit-read",
    },
  ],
  deleteBtn: false,
  saveBtn: true,
  clearForm: false,
  saveandrepeat: false,
  header: {
    headerNew: "Headers.SupplierPaymentEdit",
    headerEdited: "Headers.SupplierPaymentEdit",
  },
};

export const supplierPaymentsSearch_DP_JSON = {
  company: "company_name",
  templateEnable: false,
  tabs: [
    {key:"supplier-payments-search-search",
      tabNumber: 1,
      tabName: "Search",
      tabData: <SupplierPaymentsSearch />,
      permission: "buys-supplierpayments/search-read",
    },
    {key:"supplier-payments-search-log",
      tabNumber: 2,
      tabName: "Log",
      tabData: <LogTable logType="searchSupplierPaymentsLog" />,
      permission: "buys-supplierpayments/search-read",
    },
  ],
  deleteBtn: false,
  saveBtn: false,
  clearForm: false,
  header: {
    headerNew: "Supplier Payments Search",
    headerEdited: "Supplier Payments Search",
  },
};

export const automaticSalesNew_DP_JSON = {
  company: "company_name",
  templateEnable: false,
  templateType: 'automaticSales',
  tabs: [
    {key:"automatic-sales-new-general",
      tabNumber: 1,
      tabName: "SalesAutomaticSalesNewTabs.general",
      tabData: <AutomaticSalesNew />,
      permission: "sales-automaticsales/new-read",
    },
    {key:"automatic-sales-new-log",
      tabNumber: 2,
      tabName: "Log",
      tabData: <LogTable logType="automaticSalesLog" />,
      permission: "sales-automaticsales/new-read",
    },
  ],
  deleteBtn: false,
  saveBtn: true,
  clearForm: false,
  header: {
    headerNew: "Headings.automaticSalesNew",
    headerEdited: "Automatic Sales New",
  },
};

export const automaticSalesEdit_DP_JSON = {
  company: "company_name",
  templateEnable: false,
  templateType: 'editAutomaticSales',
  tabs: [
    {key:"automatic-sales-new-general",
      tabNumber: 1,
      tabName: "SalesAutomaticSalesNewTabs.general",
      tabData: <AutomaticSalesNew />,
      permission: "sales-automaticsales/edit-read",
    },
    {key:"automatic-sales-new-log",
      tabNumber: 2,
      tabName: "Log",
      tabData: <LogTable logType="editAutomaticSalesLog" />,
      permission: "sales-automaticsales/edit-read",
    },
  ],
  deleteBtn: false,
  saveBtn: true,
  clearForm: false,
  header: {
    headerNew: "Headings.editAutomaticSales",
    headerEdited: "Edit Automatic Sales",
  },
};

export const automaticSalesSearch_DP_JSON = {
  company: "company_name",
  templateEnable: false,
  templateType: 'automaticSalesSearch',
  tabs: [
    {key:"automatic-sales-search-search",
      tabNumber: 1,
      tabName: "SalesAutomaticSalesSearchTabs.search",
      tabData: <AutomaticSalesSearch />,
      permission: "sales-automaticsales/search-read",
    },
    {key:"automatic-sales-search-log",
      tabNumber: 2,
      tabName: "Log",
      tabData: <LogTable logType="searchAutomaticSalesLog" />,
      permission: "sales-automaticsales/search-read",
    },
  ],
  deleteBtn: false,
  saveBtn: false,
  clearForm: false,
  header: {
    headerNew: "Headings.automaticSalesSearch",
    headerEdited: "Automatic Sales Search",
  },
};

export const salesMyDataReport_DP_JSON = {
  company: "company_name",
  templateEnable: false,
  tabs: [
    {key:"send-my-data-report",
      tabNumber: 1,
      tabName: "SalesMyDataTabs.myDataReport",
      tabData: <SalesMydataReport />,
      permission: "sales-mydata/mydatareport-read",
    },
  ],
  deleteBtn: false,
  saveBtn: false,
  clearForm: true,
  header: {
    headerNew: "Headings.myDataDataReport",
    headerEdited: "MyData Data Report",
  },
};

export const buysMyDataReport_DP_JSON = {
  company: "company_name",
  templateEnable: false,
  tabs: [
    {
      key:"buys-mydata-report",
      tabNumber: 1,
      tabName: "InnerTabs.buysMyData",
      tabData: <BuysMydataReport />,
      permission: "buys-mydata/mydatareport-read",
    },
  ],
  deleteBtn: false,
  saveBtn: false,
  clearForm: false,
  header: {
    headerNew: "Headers.BuysMyData",
    headerEdited: "Headers.BuysMyData",
  },
}

export const salesBalanceSheet_DP_JSON = {
  company: "company_name",
  templateEnable: false,
  tabs: [
    {key:"sales-report-balance-sheet-sheet",
      tabNumber: 1,
      tabName: "SalesBalanceSheetTabs.balanceSheet",
      tabData: <SalesBalanceSheet />,
      permission: "sales-reports/salesbalancesheet-read"
    },
  ],
  deleteBtn: false,
  saveBtn: false,
  clearForm: false,
  header: {
    headerNew: "Headings.salesBalanceSheet",
    headerEdited: "Balance Sheet",
  },
};

export const salesGraphicReport_DP_JSON = {
  company: "company_name",
  templateEnable: false,
  tabs: [
    {key:"sales-reports-graphic-report",
      tabNumber: 1,
      tabName: "SalesGraphicReportTabs.graphicReport",
      tabData: <SalesGraphicReport />,
      permission: "sales-reports/salesgraphicreport-read"
    },
  ],
  deleteBtn: false,
  saveBtn: false,
  clearForm: false,
  header: {
    headerNew: "Headings.salesGraphicReport",
    headerEdited: "Graphic Report",
  },
};

export const salesClientPaymentReport_DP_JSON = {
  company: "company_name",
  templateEnable: false,
  tabs: [
    {key:"sales-reports-client-payemnt-report",
      tabNumber: 1,
      tabName: "SalesClientPaymentReportTabs.clientPaymentReport",
      tabData: <SalesClientPaymentReport />,
      permission: "sales-reports/clientpaymentreport-read",
    },
  ],
  deleteBtn: false,
  saveBtn: false,
  clearForm: false,
  header: {
    headerNew: "Headings.salesClientPaymentReport",
    headerEdited: "Client Payment Report",
  },
};

export const salesClientPaymentGraphicReport_DP_JSON = {
  company: "company_name",
  templateEnable: false,
  tabs: [
    {
      key:"sales-reports-graphic-report",
      tabNumber: 1,
      tabName: "SalesClientPaymentReportTabs.clientPaymentReport",
      tabData: <SalesClientPaymentGraphicReport />,
    },
  ],
  deleteBtn: false,
  saveBtn: false,
  clearForm: false,
  header: {
    headerNew: "Client Payment Graphic Report",
    headerEdited: "Client Payment Graphic Report",
  },
};


export const buysBalanceSheet_DP_JSON = {
  company: "company_name",
  templateEnable: false,
  tabs: [
    {key:"buys-report-balance-sheet-sheet",
      tabNumber: 1,
      tabName: "GeneralTab",
      tabData: <BuysBalanceSheet />,
      permission: "buys-reports/buybalancesheet-read",
    },
  ],
  deleteBtn: false,
  saveBtn: false,
  clearForm: false,
  header: {
    headerNew: "Headers.BuysBalanceSheet",
    headerEdited: "Headers.BuysBalanceSheet",
  },
};

export const buysGraphicReport_DP_JSON = {
  company: "company_name",
  templateEnable: false,
  tabs: [
    {key:"buys-reports-graphic-report",
      tabNumber: 1,
      tabName: "GeneralTab",
      tabData: <BuysGraphicReport/>,
      permission: "buys-reports/buygraphicreport-read",
    },
  ],
  deleteBtn: false,
  saveBtn: false,
  clearForm: false,
  header: {
    headerNew: "Headers.BuysGraphicReport",
    headerEdited: "Headers.BuysGraphicReport",
  },
};

export const buysSupplierPaymentReport_DP_JSON = {
  company: "company_name",
  templateEnable: false,
  tabs: [
    {key:"buys-reports-supplier-payemnt-report",
      tabNumber: 1,
      tabName: "GeneralTab",
      tabData: <BuysSupplierPaymentReport />,
      permission: "buys-reports/supplierpaymentreport-read"
    },
  ],
  deleteBtn: false,
  saveBtn: false,
  clearForm: false,
  header: {
    headerNew: "Headers.SupplierBalanceSheet",
    headerEdited: "Headers.SupplierBalanceSheet",
  },
};

export const salesAutomaticSalesReport_DP_JSON = {
  company: "company_name",
  templateEnable: false,
  tabs: [
    {
      tabNumber: 1,
      tabName: "Automatic Sales Report",
      tabData: <SalesAutomaticSalesReport />,
    },
  ],
  deleteBtn: false,
  saveBtn: false,
  clearForm: false,
  header: {
    headerNew: "Automatic Sales Report",
    headerEdited: "Automatic Sales Report",
  },
};

// Cash
export const cashRegistersNew_DP_JSON = {
  company: "company_name",
  templateEnable: false,
  templateType: 'cashRegisters',
  tabs: [
    {key:"cash-register-new-cashRegister",
      tabNumber: 1,
      tabName: "cashRegistersNewTabs.cashRegisters",
      tabData: <CashRegistersNew />,
      permission: "cash-cashregisters/new-read",
    },
  ],
  deleteBtn: false,
  saveBtn: true,
  clearForm: true,
  header: {
    headerNew: "Headings.newCashRegisters",
    headerEdited: "New Cash Registers",
  },
};

export const editCashRegisters_DP_JSON = {
  company: "company_name",
  templateEnable: false,
  templateType: 'editCashRegisters',
  tabs: [
    {key:"cash-register-new-cashRegister",
      tabNumber: 1,
      tabName: "cashRegistersNewTabs.cashRegisters",
      tabData: <EditCashRegisters />,
      permission: "cash-cashregisters/edit-read",
    },
    {key:"cash-register-new-log",
      tabNumber: 2,
      tabName: "Log",
      tabData: <CashEditLog />,
      permission: "cash-cashregisters/edit-read",
    },
  ],
  deleteBtn: true,
  saveBtn: true,
  clearForm: false,
  header: {
    headerNew: "Headings.editCashRegisters",
    headerEdited: "Edit Cash Registers",
  },
};

export const CashRegistersSearch_DP_JSON = {
  company: "company_name",
  templateEnable: false,
  tabs: [
    {key:"cash-register-search-search",
      tabNumber: 1,
      tabName: "cashRegistersSearchTabs.cashRegisters",
      tabData: <CashRegistersSearch />,
      permission: "cash-cashregisters/search-read",
    },
  ],
  deleteBtn: false,
  saveBtn: false,
  clearForm: false,
  header: {
    headerNew: "Headings.cashRegistersSearch",
    headerEdited: "Cash Registers Search",
  },
};

export const bankAccountsNew_DP_JSON = {
  company: "company_name",
  templateEnable: false,
  templateType: 'bankAccounts',
  tabs: [
    {key:"cash-register-bank-accounts-new-register",
      tabNumber: 1,
      tabName: "BankAccountsNewTabs.registers",
      tabData: <BankAccountNew />,
      permission: "cash-bankaccounts/new-read",
    },
  ],
  deleteBtn: false,
  saveBtn: true,
  clearForm: true,
  header: {
    headerNew: "Headings.newBankAccount",
    headerEdited: "New Bank Account",
  },
};

export const editBankAccounts_DP_JSON = {
  company: "company_name",
  templateEnable: false,
  templateType: 'editBankAccounts',
  tabs: [
    {key:"cash-register-bank-accounts-new-register",
      tabNumber: 1,
      tabName: "BankAccountsNewTabs.registers",
      tabData: <EditBankAccounts />,
      permission: "cash-bankaccounts/edit-read",
    },
    {key:"cash-register-bank-accounts-new-log",
      tabNumber: 2,
      tabName: "Log",
      tabData: <BankEditLog />,
      permission: "cash-bankaccounts/edit-read",
    },
  ],
  deleteBtn: true,
  saveBtn: true,
  clearForm: false,
  header: {
    headerNew: "Headings.editBankAccount",
    headerEdited: "Edit Bank Account",
  },
};

export const BankAccountSearch_DP_JSON = {
  company: "company_name",
  templateEnable: false,
  tabs: [
    {key:"cash-register-bank-accounts-search-search",
      tabNumber: 1,
      tabName: "BankAccountsSearchTabs.search",
      tabData: <BankSearch />,
      permission: "cash-bankaccounts/search-read",
    },
  ],
  deleteBtn: false,
  saveBtn: false,
  clearForm: false,
  header: {
    headerNew: "Headings.bankSearch",
    headerEdited: "Bank Search",
  },
};

export const PosNew_DP_JSON = {
  company: "company_name",
  templateEnable: false,
  templateType: 'POS',
  tabs: [
    {key:"cash-register-pos-new-register",
      tabNumber: 1,
      tabName: "PosNewTabs.registers",
      tabData: <PosNew />,
      permission: "cash-pos/new-read",
    },
  ],
  deleteBtn: false,
  saveBtn: true,
  clearForm: true,
  header: {
    headerNew: "Headings.newPOS",
    headerEdited: "New POS",
  },
};

export const PosEdit_DP_JSON = {
  company: "company_name",
  templateEnable: false,
  templateType: 'editPOS',
  tabs: [
    {key:"cash-register-pos-new-register",
      tabNumber: 1,
      tabName: "PosNewTabs.registers",
      tabData: <EditPos />,
      permission: "cash-pos/edit-read",
    },
    {key:"cash-register-pos-new-log",
      tabNumber: 2,
      tabName: "Log",
      tabData: <PosEditLog />,
      permission: "cash-pos/edit-read",
    },
  ],
  deleteBtn: true,
  saveBtn: true,
  clearForm: false,
  header: {
    headerNew: "Headings.editPOS",
    headerEdited: "Edit POS",
  },
};

export const PosSearch_DP_JSON = {
  company: "company_name",
  templateEnable: false,
  tabs: [
    {key:"cash-register-pos-search-search",
      tabNumber: 1,
      tabName: "PosSearchTabs.search",
      tabData: <POSSearch />,
      permission: "cash-pos/search-read",
    },
  ],
  deleteBtn: false,
  saveBtn: false,
  clearForm: false,
  header: {
    headerNew: "Headings.searchPOS",
    headerEdited: "POS Search",
  },
};

export const cashAccountingSettings_DP_JSON = {
  company: "company_name",
  templateEnable: false,
  templateType: 'cashAccountingSettings',
  tabs: [
    {
      key: "cash-settings-accounting",
      tabNumber: 1,
      tabName: "Λογιστική",
      tabData: <CashSettingsAccounting />,
      permission: "cash-settings/accounting-accounting-read",
    },
    {key:"cash-settings-log",
      tabNumber: 2,
      tabName: "Log",
      tabData: <CashSettingsLog />,
      permission: "cash-settings/accounting-accounting-read",
    },
  ],
  deleteBtn: false,
  saveBtn: true,
  clearForm: false,
  header: {
    headerNew: "Λογιστική Ταμειακών",
    headerEdited: "Λογιστική Ταμειακών"
  }
}

export const EmployeeNew_DP_JSON = {
  company: "company_name",
  templateEnable: false,
  templateType: 'newEmployee',
  tabs: [
    {key:"employee-new",
      tabNumber: 1,
      tabName: "EmployeeTabs.New",
      tabData: <NewEmployee />,
      permission: "accounting-employees/new-read",
    },
    {key:"employee-new-log",
      tabNumber: 2,
      tabName: "Log",
      tabData: <LogTable logType="employeeLog" />,
      permission: "accounting-employees/new-read",
    },
  ],
  deleteBtn: false,
  saveBtn: true,
  clearForm: true,
  header: {
    headerNew: "Headings.newEmployee",
    headerEdited: "New Employee",
  },
};

export const EmployeeEdit_DP_JSON = {
  company: "company_name",
  templateEnable: false,
  templateType: 'editEmployee',
  tabs: [
    {key:"employee-edit",
      tabNumber: 1,
      tabName: "EmployeeTabs.Edit",
      tabData: <EditEmployee />,
      permission: "accounting-employees/edit-read",
    },
    {key:"employee-edit-log",
      tabNumber: 2,
      tabName: "Log",
      tabData: <LogTable logType="employeeLog" />,
      permission: "accounting-employees/edit-read",
    },
  ],
  deleteBtn: false,
  saveBtn: true,
  clearForm: true,
  header: {
    headerNew: "Headings.editEmployee",
    headerEdited: "Edit Employee",
  },
};

export const EmployeeSearch_DP_JSON = {
  company: "company_name",
  templateEnable: false,
  tabs: [
    {key:"employee-search",
      tabNumber: 1,
      tabName: "EmployeeTabs.Search",
      tabData: <SearchEmployee />,
      permission: "accounting-employees/search-read",
    }
  ],
  deleteBtn: false,
  saveBtn: false,
  clearForm: false,
  header: {
    headerNew: "Headings.searchEmployee",
    headerEdited: "Search Employee",
  },
};

export const AccountingAccountNew_DP_JSON = {
  company: "company_name",
  templateEnable: false,
  templateType: 'newAccountingAccount',
  tabs: [
    {key:"accounting-account-new",
      tabNumber: 1,
      tabName: "AccountingAccountTabs.New",
      tabData: <NewAccountingAccount />,
      permission: "accounting-accountingaccounts/new-general-read",
    },
    {key:"accounting-account-new-mydata",
      tabNumber: 2,
      tabName: "AccountingAccountTabs.NewMyData",
      tabData: <AccountingAccountNewMyData />,
      permission: "accounting-accountingaccounts/new-mydata-read",
    },
  ],
  deleteBtn: false,
  saveBtn: true,
  clearForm: true,
  header: {
    headerNew: "Headings.newAccountingAccount",
    headerEdited: "New Accounting Account",
  },
};

export const AccountingAccountEdit_DP_JSON = {
  company: "company_name",
  templateEnable: false,
  templateType: 'editAccountingAccount',
  tabs: [
    {key:"accounting-account-edit",
      tabNumber: 1,
      tabName: "AccountingAccountTabs.Edit",
      tabData: <EditAccountingAccount />,
      permission: "accounting-accountingaccounts/edit-general-read",
    },
    {key:"accounting-account-edit-mydata",
      tabNumber: 1,
      tabName: "AccountingAccountTabs.EditMyData",
      tabData: <AccountingAccountEditMyData />,
      permission: "accounting-accountingaccounts/edit-mydata-read",
    },
    {key:"accounting-account-edit-log",
      tabNumber: 2,
      tabName: "Log",
      tabData: <AccountingAccountsEditLog />,
      permission: "accounting-accountingaccounts/edit-general-read",
    },
  ],
  deleteBtn: false,
  saveBtn: true,
  clearForm: true,
  header: {
    headerNew: "Headings.editAccountingAccount",
    headerEdited: "Edit Accounting Account",
  },
};

export const AccountingAccountSearch_DP_JSON = {
  company: "company_name",
  templateEnable: false,
  tabs: [
    {key:"accounting-account-search",
      tabNumber: 1,
      tabName: "AccountingAccountTabs.Search",
      tabData: <SearchAccountingAccount />,
      permission: "accounting-accountingaccounts/search-read"
    }
  ],
  deleteBtn: false,
  saveBtn: false,
  clearForm: false,
  header: {
    headerNew: "Headings.searchAccountingAccount",
    headerEdited: "Search Accounting Account",
  },
};


export const AccountingRecordNew_DP_JSON = {
  company: "company_name",
  templateEnable: false,
  templateType: 'newAccountingRecord',
  tabs: [
    {key:"accounting-record-new",
      tabNumber: 1,
      tabName: "AccountingRecordTabs.New",
      tabData: <NewAccountingRecord />,
      permission: "accounting-accountingrecords/new-general-read",
    },
      /*
      {key:"accounting-record-new-commercial",
      tabNumber: 2,
      tabName: "AccountingRecordTabs.Commercial",
      tabData: <NewAccountingRecord />,
      permission: "accounting-accountingrecords/new-commercialrecord-read",
    },
       */
  ],
  deleteBtn: false,
  saveBtn: true,
  saveandrepeat: true,
  clearForm: true,
  header: {
    headerNew: "Headings.newAccountingRecord",
    headerEdited: "New Accounting Record",
  },
};

export const AccountingRecordEdit_DP_JSON = {
  company: "company_name",
  templateEnable: false,
  templateType: 'editAccountingRecord',
  tabs: [
    {key:"accounting-record-edit",
      tabNumber: 1,
      tabName: "AccountingRecordTabs.Edit",
      tabData: <EditAccountingRecord />,
      permission: "accounting-accountingrecords/edit-general-read",
    },
      /*
      {key:"accounting-account-edit-commercial",
      tabNumber: 1,
      tabName: "AccountingRecordTabs.Commercial",
      tabData: <EditAccountingRecord />,
      permission: "accounting-accountingrecords/edit-commercialrecord-read",
    },
       */
    {key:"accounting-record-edit-log",
      tabNumber: 2,
      tabName: "Log",
      tabData: <AccountingRecordEditLog />,
      permission: "accounting-accountingaccounts/new-general-read",
    },
  ],
  deleteBtn: false,
  saveBtn: true,
  clearForm: true,
  cancelMyDataInvoice: true,
  header: {
    headerNew: "Headings.editAccountingRecord",
    headerEdited: "Edit Accounting Record",
  },
};

export const AccountingRecordSearch_DP_JSON = {
  company: "company_name",
  templateEnable: false,
  tabs: [
    {key:"accounting-record-search",
      tabNumber: 1,
      tabName: "AccountingAccountTabs.Search",
      tabData: <SearchAccountingRecord />,
      permission: "accounting-accountingrecords/search-read",
    }
  ],
  deleteBtn: false,
  saveBtn: false,
  clearForm: false,
  header: {
    headerNew: "Headings.searchAccountingRecord",
    headerEdited: "Search Accounting Account",
  },
};


export const salesGeneralSettings_DP_JSON = {
  company: "company_name",
  templateEnable: false,
  templateType: 'salesGeneralSettings',
  tabs: [
    {
      tabNumber: 1,
      tabName: "SalesGeneralSettingsTabs.settings",
      tabData: <SalesGeneralSettings />,
      permission: "sales-settings/salesgeneralsettings-read",
    },
    {
      tabNumber: 2,
      tabName: "Sales Email Settings",
      tabData: <SalesEmailSettings />,
      permission: "sales-settings/salesgeneralsettings-read",
    },
    {
      tabNumber: 3,
      tabName: "Log",
      tabData: <SalesGeneralSettingsLog />,
      permission: "sales-settings/salesgeneralsettings-read",
    },
  ],
  deleteBtn: false,
  saveandrepeat: false,
  saveBtn: true,
  clearForm: true,
  header: {
    headerNew: "Headings.salesGeneralSettings",
    headerEdited: "Sales Settings",
  },
};


export const buysGeneralSettings_DP_JSON = {
  company: "company_name",
  templateEnable: false,
  templateType: 'buysGeneralSettings',
  tabs: [
    {
      tabNumber: 1,
      tabName: "InnerTabs.settings",
      tabData: <BuysGeneralSettings />,
      permission: "buys-settings/buygeneralsettings-read"
    },
    /*{
      tabNumber: 2,
      tabName: "InnerTabs.buysEmailSettings",
      tabData: <BuysEmailSettings />,
      permission: "buys-settings/buygeneralsettings-read"
    },*/
    {
      tabNumber: 3,
      tabName: "Log",
      tabData: <BuysGeneralSettingsLog />,
      permission: "buys-settings/buygeneralsettings-read"
    },
  ],
  deleteBtn: false,
  saveandrepeat: false,
  saveBtn: true,
  clearForm: true,
  header: {
    headerNew: "Headers.BuysSettings",
    headerEdited: "Headers.BuysSettings",
  },
};



export const quickRetailSalesSettings_DP_JSON = {
  company: "company_name",
  templateEnable: false,
  templateType: 'settingsQuickRetailSales',
  tabs: [
    {
      tabNumber: 1,
      tabName: "SalesGeneralSettingsTabs.settings",
      tabData: <SettingsQuickRetailSales />,
      permission: "sales-settings/quickretailsales-read",
    }
  ],
  deleteBtn: false,
  saveandrepeat: false,
  saveBtn: true,
  clearForm: false,
  header: {
    headerNew: "Headings.quickRetailSalesSettings",
    headerEdited: "Quick Retail Sales Settings",
  },
};

export const settingsSubscriptions_DP_JSON = {
  company: "company_name",
  templateEnable: false,
  templateType: 'settingsSubscriptions',
  tabs: [
    {
      tabNumber: 1,
      tabName: "Subscriptions",
      tabData: <SettingsSubscriptions />,
      permission: "settings-generalsettings/subscriptions-read",
    },
  ],
  deleteBtn: false,
  saveandrepeat: false,
  saveBtn: false,
  clearForm: false,
  header: {
    headerNew: "Subscriptions",
    headerEdited: "Subscriptions",
  },
};

export const reportsClientSubscriptions_DP_JSON = {
  company: "company_name",
  templateEnable: false,
  templateType: 'reportsClientSubscriptions',
  tabs: [
    {
      tabNumber: 1,
      tabName: "Εκτύπωση Συνδρομών",
      tabData: <ReportsClientsSubscriptions />,
      permission: "clients-reports/reportsclientsubscriptions-read",
    },
  ],
  deleteBtn: false,
  saveandrepeat: false,
  saveBtn: false,
  clearForm: false,
  header: {
    headerNew: "Συνδρομές Πελατών",
    headerEdited: "Συνδρομές Πελατών",
  },
}

export const newWorkMovement_DP_JSON = {
  company: "company_name",
  templateEnable: false,
  templateType: 'newWorkMovement',
  tabs: [
    {
      tabNumber: 1,
      tabName: "Γενικά",
      tabData: <NewWorkMovement />,
      permission: "payroll-workmovements/new-read",
    },
  ],
  deleteBtn: false,
  saveandrepeat: false,
  saveBtn: true,
  clearForm: false,
  header: {
    headerNew: "Εισαγωγή Κίνησης Εργαζομένου",
    headerEdited: "Εισαγωγή Κίνησης Εργαζομένου",
  },
}

export const editWorkMovement_DP_JSON = {
  company: "company_name",
  templateEnable: false,
  templateType: 'editWorkMovement',
  tabs: [
    {
      tabNumber: 1,
      tabName: "Γενικά",
      tabData: <EditWorkMovement />,
      permission: "payroll-workmovements/edit-read",
    },
  ],
  deleteBtn: false,
  saveandrepeat: false,
  saveBtn: false,
  clearForm: false,
  header: {
    headerNew: "Επεξεργασία Κίνησης Εργαζομένου",
    headerEdited: "Επεξεργασία Κίνησης Εργαζομένου",
  },
}

export const newWorkPermit_DP_JSON = {
  company: "company_name",
  templateEnable: false,
  templateType: 'newWorkPermit',
  tabs: [
    {
      tabNumber: 1,
      tabName: "Γενικά",
      tabData: <NewWorkPermit />,
      permission: "payroll-workpermits/new-read",
    },
  ],
  deleteBtn: false,
  saveandrepeat: false,
  saveBtn: true,
  clearForm: false,
  header: {
    headerNew: "Εισαγωγή Άδειας Εργαζομένου",
    headerEdited: "Εισαγωγή Άδειας Εργαζομένου",
  },
}

export const editWorkPermit_DP_JSON = {
  company: "company_name",
  templateEnable: false,
  templateType: 'editWorkPermit',
  tabs: [
    {
      tabNumber: 1,
      tabName: "Γενικά",
      tabData: <EditWorkPermit />,
      permission: "payroll-workpermits/edit-read",
    },
  ],
  deleteBtn: false,
  saveandrepeat: false,
  saveBtn: false,
  clearForm: false,
  header: {
    headerNew: "Επεξεργασία Άδειας Εργαζομένου",
    headerEdited: "Επεξεργασία Άδειας Εργαζομένου",
  },
}

export const payrollSettings_DP_JSON = {
  company: "company_name",
  templateEnable: false,
  templateType: 'payrollSettings',
  tabs: [
    {
      tabNumber: 1,
      tabName: "Ρυθμίσεις",
      tabData: <PayrollSettings />,
      permission: "payroll-settings/settings-read",
    },
    {
      tabNumber: 2,
      tabName: "Log",
      tabData: <PayrollSettingsLogs />,
      permission: "payroll-settings/settings-read",
    },
  ],
  deleteBtn: false,
  saveandrepeat: false,
  saveBtn: false,
  clearForm: false,
  header: {
    headerNew: "Ρυθμίσεις Μισθοδοσίας",
    headerEdited: "Ρυθμίσεις Μισθοδοσίας",
  },
}