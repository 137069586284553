import React from "react";
import {checkPermission} from "../../../_helpers/commonFunctions";
import Alert from "react-bootstrap/Alert";
import {Button} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {toast} from "react-toastify";
import {setEditAccountingRecord} from "../../../_reducers/DataPanel/Accounting/accountingSlice";
import {addTab, updateActiveMainTab} from "../../../_reducers/TabsSlice";

export const AccountingView = ({document}) => {
    const dispatch = useDispatch();
    const TABS_DATA = useSelector((state) => state.TABS_REDUCER);
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const permissionsData = JSON.parse(localStorage.getItem("user"))?.permissions;

    const handleShowBridgedRecord = () => {
        if (String(document?.bridgedDocument).length === 24) {
            axios.post(process.env.REACT_APP_API_URL2 + "/accounting/show-bridged-document", {
                company: company.id,
                docId: document?._id,
                section: "Accounting Records"
            }, {
                headers: { "Content-Type": "application/json" },
            }).then((res) => {
                if (res.data.status === "200") {
                    dispatch(setEditAccountingRecord(res.data.data));
                    const idx = TABS_DATA.findIndex((item) => "accounting-record-edit" === item);
                    if(idx === -1) {
                        dispatch(addTab("accounting-record-edit"));
                        dispatch(updateActiveMainTab(TABS_DATA.length));
                    } else {
                        dispatch(updateActiveMainTab(idx));
                    }
                } else {
                    toast.error(res.data.message);
                }
            }).catch((err) => {
                console.log(err);
                toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
            })
        } else {
            toast.error("Σφάλμα κατά την εύρεση άρθρου.")
        }
    }

    return (
        <React.Fragment>
            {(checkPermission("accounting-accountingrecords/edit-read", permissionsData) === true && String(document?.bridgedDocument).length === 24) && (
                <Alert variant={"secondary"} className={"mb-1"}>
                    Το παρόν έγγραφο είναι γεφυρωμένο με λογιστικό άρθρο.
                    <Button size={"sm"} onClick={() => handleShowBridgedRecord()} style={{position: "absolute", right: "5px", top: "5px"}}>Προβολή λογιστικού άρθρου</Button>
                </Alert>
            )}
        </React.Fragment>
    )
}