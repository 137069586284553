import React, {useEffect, useMemo, useRef, useState} from "react";
import {useReactToPrint} from "react-to-print";
import SaveAsTemplate from "../../common/SaveAsTemplate";
import ModalInstructions from "../../primer/POPUP-COMPONENTS/Instructions";
import "./index.scss";
import {useDispatch, useSelector} from "react-redux";
import {
    fetchTemplates,
    postAdvancedType,
    postAdvancedTypeBuys,
    postAdvancedTypeSales,
    postAutomaticSales,
    postBankAccounts,
    postBasicType,
    postBuys,
    postBuysGeneralSettings,
    postCashRegisters,
    postClient,
    postClientMovements,
    postClientPayment,
    postClientsSettings,
    postPOS,
    postPriceList,
    postProduct,
    postProductMovements,
    postQuickRetailSales,
    postQuickRetailSalesSettings,
    postSales,
    sendMail,
    postSalesGeneralSettings,
    postSupplier,
    postSupplierMovements,
    postSupplierPayment,
    postSuppliersSettings,
    putAdvancedType,
    putAdvancedTypeBuys,
    putAdvancedTypeSales,
    putBankAccounts,
    putBasicType,
    putBuys,
    putBuysGeneralSettings,
    putCashRegisters,
    putClient,
    putClientMovements,
    putClientPayment,
    putCompanyEmailSmtp,
    putPOS,
    putPriceList,
    putPrimerMyDataData,
    putProduct,
    putProductMovements,
    putSales,
    putSalesGeneralSettings,
    putTemplate,
    cancelMyDataSale,
    putSupplier,
    putSupplierMovements,
    putSupplierPayment,
    postEmployee,
    putEmployee,
    postAccountingAccount,
    putAccountingAccount,
    postAccountingRecord,
    putAccountingRecord,
    cancelMyDataBuy, cancelMyDataAccountingRecord, postCashSettings, postWorkMovement, postWorkPermit,
} from "../../../_apis/api";
import AdvancedCombobox from "../AdvancedCombobox";
import {
    checkPermission,
    filterNaNValue,
    clientPaymentsPrintTemplates,
    getClientPaymentPrintData,
    getCurrentUser,
    getDefaultOptionByValue,
    getGeneratedMaskCode,
    getGeneratedMaskCodeCLient,
    getMaskCodeWithNewPrefix,
    getMultiSelectOptionsToArray,
    getSalesPrintData,
    getTemplateOptions,
    isValidBuysData,
    isValidEmail,
    isValidSalesData,
    leadingZeros,
    salesPrintTemplates,
    getBuysPrintData,
    buysPrintTemplates, isEmpty,
} from "../../../_helpers/commonFunctions";
import {Button, Col, Modal, Row, Spinner} from "react-bootstrap";
import {
    setNewPriceList,
    setNewProductMovements,
    setSelectedProduct,
    setSelectedProductMovementsTemplate,
    setSelectedProductPriceListTemplate,
    setSelectedProductTemplate,
} from "../../../_reducers/DataPanel/Products/productsSlice";
import {
    findObjectFromArrayOfObjects, getObjectFromArrayOfObjects,
} from "../../../_helpers/helperFunctions";
import {
    setSaveBuys,
    setSaveBuysSettings,
    setSaveClientPayments,
    setSaveEditBankAccounts,
    setSaveEditCashRegister,
    setSaveEditClientPayments,
    setSaveEditPOS,
    setSaveEditSupplierPayments,
    setSaveNewBankAccounts,
    setSaveNewCashRegister,
    setSaveNewPOS,
    setSaveNewPriceList,
    setSaveNewProduct,
    setSaveNewProductMovements,
    setSaveQuickRetailSettings,
    setSaveSales,
    setSaveSalesSettings,
    setSendEmail,
    setSaveSupplierPayments,
    setSaveEditProduct,
    setSaveEditBuys, setSaveCashAccountingSettings, setSendEmployeeQROnSave, setSaveNewWorkPermit,
} from "../../../_reducers/activitySlice";
import {
    setEditClient,
    setEditClientMovements,
    setNewClient,
    setNewClientMovements,
    setNewClientPayment,
    setSelectedClientMovementsTemplate,
    setSelectedClientTemplate,
} from "../../../_reducers/DataPanel/Clients/clientSlice";
import {toast} from "react-toastify";
import {
    setNewBankAccount, setNewCashRegisters,
} from "../../../_reducers/DataPanel/Cash/cashSlice";
import {resetNewAutomaticSales} from "../../../_reducers/DataPanel/Sales/automaticSalesSlice";
import Checkbox from "../../common/CheckBox";
import Dropdown from "../Dropdown";
import {
    resetNewSales,
    setNewSales,
    setPrintLayout,
    setSelectedSalesTemplate
} from "../../../_reducers/DataPanel/Sales/salesSlice";
import {resetNewQuickRetailSales} from "../../../_reducers/DataPanel/Sales/quickRetailSalesSlice";
import axios from "axios";
import SalesPrintTemplateEnglish1 from "../../layouts/PrintLayout/sales-print-template1-english";
import SalesPrintTemplateGreek1 from "../../layouts/PrintLayout/sales-print-template1-greek";
import {
    setEditSupplier,
    setEditSupplierMovements,
    setNewSupplier,
    setNewSupplierMovements,
    setSelectedSupplierMovementsTemplate,
    setSelectedSupplierTemplate
} from "../../../_reducers/DataPanel/Suppliers/supplierSlice";
import {setNewBuys} from "../../../_reducers/DataPanel/Buys/buysSlice";
import {validate} from "email-validator";
import {validateAFM} from '@lytrax/afm';
import Input from "../../common/Input";
import Start from "../../layouts/data-panel/start";
import {useTranslation} from "react-i18next";
import {removeTab} from "../../../_reducers/TabsSlice";
import {setSettingExportTab, setSettingImportTab} from "../../../_reducers/settingsSlice";
import ClientPaymentPrintTemplate1Greek from "../../layouts/PrintLayout/client-payment-print-template1-greek";
import SalesPrintA5Greek from "../../layouts/PrintLayout/sales-print-a5-greek";
import SalesPrintA5English from "../../layouts/PrintLayout/sales-print-a5-english";
import SalesPrintA5DoubleGreek from "../../layouts/PrintLayout/sales-print-a5-double-greek";
import SalesPrintA5DoubleEnglish from "../../layouts/PrintLayout/sales-print-a5-double-english";
import BuyPrintA4Greek from "../../layouts/PrintLayout/buy-print-a4-greek";
import BuyPrintA4English from "../../layouts/PrintLayout/buy-print-a4-english";
import ClientpaymentPrintTemplate1English from "../../layouts/PrintLayout/client-payment-print-template1-english";
import moment from "moment";
import DeleteModal from "../POPUP-COMPONENTS/Delete-Modal/deleteModal";
import {setForceRefresh} from "../../../_reducers/SearchTableSlice";
import {getInternationalCodesTableData} from "../../../_data/international_codes/international_codes";
import BetterCheckBox from "../../common/BetterCheckBox";

const apiURL2 = process.env.REACT_APP_API_URL2;

const DataPanel = ({
                       templateEnable,
                       templateType,
                       tabs,
                       deleteBtn,
                       saveBtn,
                       clearForm,
                       clearBtn,
                       saveandrepeat,
                       headerNew,
                       headerEdited,
                       printLayoutDropdown,
                       printBtn,
                       sendEmailOnSave,
                       convertDocument,
                       issueCreditDocument,
                       cancelMyDataInvoice
                   }) => {
    // Check for active sublink
    const [activeLink, setActiveLink] = useState(tabs[0].tabName === "Start" ? "Start" : 0);
    const permissions = localStorage.getItem("permissions");
    const permissionsData = JSON.parse(permissions);
    const [t] = useTranslation('common');
    const SETTINGS = useSelector((state) => state.SETTINGS);
    const PRODUCTS_DATA = useSelector((state) => state.PRODUCTS_DATA);
    const CLIENTS_DATA = useSelector((state) => state.CLIENTS_DATA);
    const SUPPLIERS_DATA = useSelector((state) => state.SUPPLIERS_DATA);
    const PRODUCTS_CATEGORY = useSelector((state) => state.PRODUCTS_CATEGORY.categories);
    const CASH_DATA = useSelector((state) => state.CASH_DATA);
    const SALES = useSelector((state) => state.SALES);
    const BUYS = useSelector((state) => state.BUYS);
    const COMPANY_DATA = useSelector((state) => state.COMPANY_DATA);
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const ACTIVITY = useSelector((state) => state.ACTIVITY);
    const SALES_SETTINGS = useSelector((state) => state.SALES_SETTINGS);
    const BUYS_SETTINGS = useSelector((state) => state.BUYS_SETTINGS);
    const AUTOMATIC_SALES = useSelector((state) => state.AUTOMATIC_SALES);
    const ACCOUNTING_DATA = useSelector((state) => state.ACCOUNTING_DATA);
    const QUICK_RETAIL_SALES = useSelector((state) => state.QUICK_RETAIL_SALES);
    const SEARCH_TABLE = useSelector((state) => state.SEARCH_TABLE);
    const TABS_DATA = useSelector((state) => state.TABS_REDUCER);
    const PAYROLL = useSelector((state) => state.PAYROLL);
    const [showTemplateConfirmation, setShowTemplateConfirmation] = useState(false);
    const [defaultTemplate, setDefaultTemplate] = useState(null);
    const [selectedTemplate, setSelectedTemplate] = useState();
    const [loadingSave, setLoadingSave] = useState(false);
    const [activePriceListModal, setActivePriceListModal] = useState(false);
    const [loadingSaveAndRepeat, setLoadingSaveAndRepeat] = useState(false);
    const [recipientEmails, setRecipientEmails] = useState([]);
    const [selectedRecipientEmails, setSelectedRecipientEmails] = useState([]);
    const [defaultRecipientEmails, setDefaultRecipientEmails] = useState([]);
    const [emailError, setEmailError] = useState(false);
    const [selectedCustomRecipientEmails, setSelectedCustomRecipientEmails] = useState([]);
    const [showConvertDocumentConfirmation, setShowConvertDocumentConfirmation] = useState(false);
    const [showIssueCreditDocumentConfirmation, setShowIssueCreditDocumentConfirmation,] = useState(false);
    const [showCancelDocumentConfirmation, setShowCancelDocumentConfirmation] = useState(false);
    const scrollToEnd = useRef();
    // This settings works from the view-sales.js file as per the selected conversion and credit document
    const [enableConvertDocumentButton, setEnableCDBtn] = useState(false);
    const [enableIssueCreditDocumentButton, setEnableIssueCDBtn] = useState(false);
    // Disable button to reduce the changes of double click.
    const [disableConvertOkBtn, setDisableConvertOkBtn] = useState(false);
    const [disableIssueCreditOkBtn, setDisableIssueCreditOkBtn] = useState(false);
    const [disableCancelDocumentOkBtn, setDisableCancelDocumentOkBtn] = useState(false);
    // Same is used Settings Document Type file for advanced document types.
    let printLayoutOptions = salesPrintTemplates();

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [toBeDeleted, setToBeDeleted] = useState("");
    const [deleteModalTableName, setDeleteModalTableName] = useState("");

    const [showPOSModal, setShowPOSModal] = useState(false);
    const [showPOSModalText, setShowPOSModalText] = useState("");
    const [showPOSModalCancel, setShowPOSModalCancel] = useState(false);
    const [paymentReturnable, setPaymentReturnable] = useState(false);
    const [showPaymentReturnModal, setShowPaymentReturnModal] = useState(false);

    const [emronAdminCancellationModal, setEmronAdminCancellationModal] = useState(false);

    useEffect(() => {
        //if(tabs[SETTINGS.settingImportTab].key == 'settings-export-settings' || tabs[SETTINGS.settingImportTab].key == 'settings-export-import') {
        setActiveLink(SETTINGS.settingExportTab);
        //}
    }, [SETTINGS.settingExportTab]);

    useEffect(() => {
        //if(tabs[SETTINGS.settingImportTab].key == 'settings-export-settings' || tabs[SETTINGS.settingImportTab].key == 'settings-export-import') {
        setActiveLink(SETTINGS.settingImportTab);
        //}
    }, [SETTINGS.settingImportTab]);

    useEffect(() => {
        getPaymentReturnable();
    }, []);

    const getPaymentReturnable = () => {
        if (templateType === "editClientPayments" && CLIENTS_DATA.editClientPayment._id) {
            axios.post(process.env.REACT_APP_API_URL2 + "/client/is-client-payment-returnable", { company: company.id, cpId: CLIENTS_DATA.editClientPayment._id}, {
                headers: {"Content-Type": "application/json"},
            }).then((res) => {
                if (res.data.status === "200") {
                    if (!isEmpty(res.data.returnable)) {
                        setPaymentReturnable(res.data.returnable);
                    } else {
                        setPaymentReturnable(false);
                    }
                }
            }).catch((err) => {
                console.log(err);
            })
        }
    }

    const changeLinkImport = (i) => {
        console.log("i", i);
        dispatch(setSettingImportTab(i));
        setActiveLink(i);
    }
    const changeLinkExport = (i) => {
        console.log("i", i);
        dispatch(setSettingExportTab(i));
        setActiveLink(i);
    }
    if (printLayoutOptions?.length >= 2 && SALES.printLayout === '') {
        let sdocumentTypeId = "";
        if (templateType === "sales") {
            sdocumentTypeId = SALES?.newSales?.documentType;
        }

        if (templateType === "buys") {
            sdocumentTypeId = BUYS?.newBuys?.documentType;
        }

        if (templateType === "editSales") {
            sdocumentTypeId = SALES?.editSales?.documentType;
        }

        if (templateType === "editBuys") {
            sdocumentTypeId = BUYS?.editBuys?.documentType;
        }

        const sdocumentTypeData = getObjectFromArrayOfObjects(SETTINGS.settings.advancedTypesSales, sdocumentTypeId, "_id");

        if (sdocumentTypeData.length > 0) {
            let atPrintTemplate = sdocumentTypeData["0"].printTemplate;
            let atPrintTemplateName = sdocumentTypeData["0"].printTemplateName;
            //TO-DO auto print from adv type
        }
    }

    const dataInstructions = {
        title: "Instructions", content: "Instructions",
    }

    const dispatch = useDispatch();

    const requestData = {
        type: templateType, isActive: "active", company: company.id, year: company.year.toString(),
    }

    // This will handle the different options
    const templateOptions = (type) => {
        switch (type) {
            case "product":
                return getTemplateOptions(PRODUCTS_DATA.productTemplates);
            case "editProduct":
                return getTemplateOptions(PRODUCTS_DATA.productTemplates);
            case "productMovements":
                return getTemplateOptions(PRODUCTS_DATA.productMovementsTemplates);
            case "editProductMovements":
                return getTemplateOptions(PRODUCTS_DATA.productMovementsTemplates);
            case "priceList":
                return getTemplateOptions(PRODUCTS_DATA.productPriceListTemplates);
            case "editPriceList":
                return getTemplateOptions(PRODUCTS_DATA.productPriceListTemplates);
            case "client":
                return getTemplateOptions(CLIENTS_DATA.clientTemplates);
            case "clientMovements":
                return getTemplateOptions(CLIENTS_DATA.clientMovementsTemplates);
            case "supplier":
                return getTemplateOptions(SUPPLIERS_DATA.supplierTemplates);
            case "supplierMovements":
                return getTemplateOptions(SUPPLIERS_DATA.supplierMovementsTemplates);
            case "sales":
                return getTemplateOptions(SALES.salesTemplates);
            case "buys":
                return getTemplateOptions(BUYS.buysTemplates);
            case "quickRetailSales":
                return getTemplateOptions(QUICK_RETAIL_SALES.quickRetailSalesTemplates);
            case "clientPayments":
                return getTemplateOptions(CLIENTS_DATA.clientPaymentsTemplates);
            case "supplierPayments":
                return getTemplateOptions(SUPPLIERS_DATA.clientPaymentsTemplates);
            default:
                return [];
        }
    }

    // This will handle the different options
    const getSalesPrintTemplateData = (type) => {
        switch (type) {
            case "sales":
                return getSalesPrintData(type, SALES, COMPANY_DATA, null, SALES.printLayout);
            case "editSales":
                return getSalesPrintData(type, SALES, COMPANY_DATA, null, SALES.printLayout);
            case "clientPayments":
                return getClientPaymentPrintData(COMPANY_DATA, CLIENTS_DATA.newClientPayment);
            case "editClientPayments":
                return getClientPaymentPrintData(COMPANY_DATA, CLIENTS_DATA.editClientPayment);
            case "buys":
                return getBuysPrintData(type, BUYS, COMPANY_DATA, null, SALES.printLayout);
            case "editBuys":
                return getBuysPrintData(type, BUYS, COMPANY_DATA, null, SALES.printLayout);
            default:
                return [];
        }
    }

    const printTemplateData = useMemo(() => getSalesPrintTemplateData(templateType));
    // This will handle the form data for save as template
    const templateFormData = (type) => {
        switch (type) {
            case "product":
                return PRODUCTS_DATA.selectedProduct;
            case "editProduct":
                return PRODUCTS_DATA.selectedEditProduct;
            case "productMovements":
                return PRODUCTS_DATA.newProductMovements;
            case "editProductMovements":
                return PRODUCTS_DATA.editProductMovements;
            case "priceList":
                return PRODUCTS_DATA.newPriceList;
            case "editPriceList":
                return PRODUCTS_DATA.editPriceList;
            case "client":
                return CLIENTS_DATA.newClient;
            case "clientMovements":
                return CLIENTS_DATA.newClientMovements;
            case "supplier":
                return SUPPLIERS_DATA.newSupplier;
            case "supplierMovements":
                return SUPPLIERS_DATA.newSupplierMovements;
            case "sales":
                return SALES.newSales;
            case "buys":
                return BUYS.newBuys;
            case "quickRetailSales":
                return QUICK_RETAIL_SALES.newQuickRetailSales;
            case "clientPayments":
                return CLIENTS_DATA.newClientPayment;
            case "supplierPayments":
                return SUPPLIERS_DATA.newSupplierPayment
            default:
                return [];
        }
    }

    useEffect(() => {
        if (templateEnable) {
            dispatch(fetchTemplates(requestData));
        }
    }, [dispatch]);

    useEffect(() => {
        let selectedDocumentTypeData;
        if (SALES.editSales?.documentType) {
            let dtData = getObjectFromArrayOfObjects(SETTINGS.settings.advancedTypesSales, SALES.editSales.documentType, '_id');
            if (dtData.length > 0) {
                selectedDocumentTypeData = dtData['0'];
            }
            if (selectedDocumentTypeData?.conversionDocument) {
                setEnableCDBtn(true);
            }
            if (selectedDocumentTypeData?.creditDocument) {
                setEnableIssueCDBtn(true);
            }
            if (SALES.editSales?._id && templateType === "editSales" && SALES.editSales?.documentCategory !== "Convert Document" && SALES.editSales?.documentCategory !== "Credit Document") {
                axios
                    .get(process.env.REACT_APP_API_URL2 + `/sale/credit-document-list/${SALES.editSales?._id}`)
                    .then((res) => {
                        if (res.data && res.data.status === "200") {
                            if (res.data.data.length > 0) {
                                setEnableIssueCDBtn(false);
                            }
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
                axios
                    .get(process.env.REACT_APP_API_URL2 + `/sale/list-convert-document/${SALES.editSales._id}`)
                    .then((res) => {
                        if (res.data && res.data.status === "200") {
                            if (res.data.data.length > 0) {
                                setEnableCDBtn(false);
                            }
                        }

                        if (res.data && res.data.status === "422") {
                            console.log("Convert document not found.");
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
            // Hide the covert and issues credit document button from their pages.
            if (templateType === "editSales" && (SALES.editSales?.documentCategory === "Convert Document" || SALES.editSales?.documentCategory === "Credit Document")) {
                setEnableCDBtn(false);
                setEnableIssueCDBtn(false);
            }
        }
    }, [SALES.editSales.documentType, SETTINGS.settings.advancedTypesSales])

    /*
    useEffect(() => {
        let selectedDocumentTypeData;
        if (BUYS.editBuys?.documentType) {
            let dtData = getObjectFromArrayOfObjects(SETTINGS.settings.advancedTypesBuys, BUYS.editBuys.documentType, '_id');
            if (dtData.length > 0) {
                selectedDocumentTypeData = dtData['0'];
            }
            if (selectedDocumentTypeData?.conversionDocument) {
                setEnableCDBtn(true);
            }
            if (selectedDocumentTypeData?.creditDocument) {
                setEnableIssueCDBtn(true);
            }
            if (BUYS.editBuys?._id && templateType === "editBuys" && BUYS.editBuys?.documentCategory !== "Convert Document" && BUYS.editBuys?.documentCategory !== "Credit Document") {
                axios
                    .get(process.env.REACT_APP_API_URL2 + `/buy/credit-document-list/${BUYS.editBuys?._id}`)
                    .then((res) => {
                        if (res.data && res.data.status === "200") {
                            if (res.data.data.length > 0) {
                                setEnableIssueCDBtn(false);
                            }
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
                axios
                    .get(process.env.REACT_APP_API_URL2 + `/buy/list-convert-document/${BUYS.editBuys._id}`)
                    .then((res) => {
                        if (res.data && res.data.status === "200") {
                            if (res.data.data.length > 0) {
                                setEnableCDBtn(false);
                            }
                        }

                        if (res.data && res.data.status === "422") {
                            console.log("Convert document not found.");
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
            // Hide the covert and issues credit document button from their pages.
            if (templateType === "editSales" && (BUYS.editBuys?.documentCategory === "Convert Document" || BUYS.editBuys?.documentCategory === "Credit Document")) {
                setEnableCDBtn(false);
                setEnableIssueCDBtn(false);
            }
        }
    }, [BUYS.editBuys.documentType, SETTINGS.settings.advancedTypesBuys])
     */

    const handleTemplateChange = (e) => {
        e = e.row; // advanced combobox data
        setSelectedTemplate(e); // This will set the template details temporarily.
        if (ACTIVITY.editing || ACTIVITY.saveNewProduct || ACTIVITY.saveSales || ACTIVITY.saveQuickRetailSales || ACTIVITY.saveAutomaticSales || ACTIVITY.saveClientPayments || ACTIVITY.saveNewProductMovements || ACTIVITY.saveNewPriceList) {
            // Ask for confirmation if editing true
            setShowTemplateConfirmation(true);
        } else {
            // Set Selected Template to redux
            setTemplateToRedux(templateType, e);

            // Change selected template option
            setDefaultTemplate(e);

            // Set Editing True
            if (templateType === "product") {
                dispatch(setSaveNewProduct(true));
            }
            if (templateType === "productMovements") {
                dispatch(setSaveNewProductMovements(true));
            }
            if (templateType === "priceList") {
                dispatch(setSaveNewPriceList(true));
            }
            if (templateType === "sales") {
                dispatch(setSaveSales(true));
            }
            if (templateType === "editSales") {
                dispatch(setSaveSales(true));
            }
            if (templateType === "buys") {
                dispatch(setSaveBuys(true));
            }
            if (templateType === "editBuys") {
                dispatch(setSaveEditBuys(true));
            }
        }
    };

    const handleConfirmTemplate = () => {
        // Change selected template option
        setDefaultTemplate(selectedTemplate);

        // Set Selected Template to redux
        setTemplateToRedux(templateType, selectedTemplate);

        // Hide Popup
        setShowTemplateConfirmation(false);
    };

    // It will set selected template data
    const setTemplateToRedux = (templateType, selectedTemplate) => {
        let selectedTemplateData = [];
        if (selectedTemplate && selectedTemplate.value) {
            switch (templateType) {
                case "product":
                    selectedTemplateData = getObjectFromArrayOfObjects(PRODUCTS_DATA.productTemplates, selectedTemplate.value, "_id");
                    if (selectedTemplateData && selectedTemplateData.length > 0) {
                        let productData = JSON.parse(selectedTemplateData["0"].templateData);
                        let tempProductCode = getGeneratedMaskCode('product', productData.category, PRODUCTS_CATEGORY)
                        productData.code = getMaskCodeWithNewPrefix(tempProductCode, productData.categoryMaskCodePrefix);
                        productData.installationsInventory = [];
                        console.log(productData)
                        dispatch(setSelectedProductTemplate(selectedTemplateData["0"])); // Set products selected template data
                        dispatch(setSelectedProduct(productData)); // set template data
                    }
                    break;
                case "productMovements":
                    selectedTemplateData = getObjectFromArrayOfObjects(PRODUCTS_DATA.productMovementsTemplates, selectedTemplate.value, "_id");
                    if (selectedTemplateData && selectedTemplateData.length > 0) {
                        dispatch(setSelectedProductMovementsTemplate(selectedTemplateData["0"])); // Set products movement selected template data
                        dispatch(setNewProductMovements(JSON.parse(selectedTemplateData["0"].templateData))); // set template data
                    }
                    break;
                case "priceList":
                    selectedTemplateData = getObjectFromArrayOfObjects(PRODUCTS_DATA.productPriceListTemplates, selectedTemplate.value, "_id");
                    if (selectedTemplateData && selectedTemplateData.length > 0) {
                        dispatch(setSelectedProductPriceListTemplate(selectedTemplateData["0"])); // Set price data to selected template data
                        dispatch(setNewPriceList(JSON.parse(selectedTemplateData["0"].templateData))); // set template data
                    }
                    break;
                case "client":
                    selectedTemplateData = getObjectFromArrayOfObjects(CLIENTS_DATA.clientTemplates, selectedTemplate.value, "_id");
                    if (selectedTemplateData && selectedTemplateData.length > 0) {
                        dispatch(setSelectedClientTemplate(selectedTemplateData["0"])); // Set client selected template data
                        let clientData = JSON.parse(selectedTemplateData["0"].templateData);
                        const clientCategories = getObjectFromArrayOfObjects(CLIENTS_DATA.clientsSettings.categories, "active", "isActive");
                        console.log(clientCategories)
                        const selectedCategory = findObjectFromArrayOfObjects(clientCategories, clientData.category, "_id");
                        if (selectedCategory) {
                            clientData.categoryMaskCodePrefix = selectedCategory.maskCodePrefix;
                            clientData.systemCode = getGeneratedMaskCodeCLient(selectedCategory._id, clientCategories, selectedCategory.maskCodePrefix); // New With Updated Prefix
                        }
                        dispatch(setNewClient(clientData)); // set new client data
                    }
                    break;
                case "supplier":
                    selectedTemplateData = getObjectFromArrayOfObjects(SUPPLIERS_DATA.supplierTemplates, selectedTemplate.value, "_id");
                    if (selectedTemplateData && selectedTemplateData.length > 0) {
                        dispatch(setSelectedSupplierTemplate(selectedTemplateData["0"])); // Set client selected template data
                        let supplierData = JSON.parse(selectedTemplateData["0"].templateData);
                        const supplierCategories = getObjectFromArrayOfObjects(SUPPLIERS_DATA.suppliersSettings.categories, "active", "isActive");
                        console.log(supplierCategories)
                        const selectedCategory = findObjectFromArrayOfObjects(supplierCategories, supplierData.category, "_id");
                        if (selectedCategory) {
                            supplierData.categoryMaskCodePrefix = selectedCategory.maskCodePrefix;
                            supplierData.systemCode = getGeneratedMaskCodeCLient(selectedCategory._id, supplierCategories, selectedCategory.maskCodePrefix); // New With Updated Prefix
                        }
                        dispatch(setNewSupplier(supplierData)); // set new client data
                    }
                    break;
                case "editClient":
                    selectedTemplateData = getObjectFromArrayOfObjects(CLIENTS_DATA.clientTemplates, selectedTemplate.value, "_id");
                    if (selectedTemplateData && selectedTemplateData.length > 0) {
                        dispatch(setSelectedClientTemplate(selectedTemplateData["0"])); // Set client selected template data
                        let clientData = JSON.parse(selectedTemplateData["0"].templateData);
                        const clientCategories = getObjectFromArrayOfObjects(CLIENTS_DATA.clientsSettings.categories, "active", "isActive");
                        const selectedCategory = findObjectFromArrayOfObjects(clientCategories, clientData.category, "_id");
                        if (selectedCategory) {
                            clientData.categoryMaskCodePrefix = selectedCategory.maskCodePrefix;
                            clientData.systemCode = getGeneratedMaskCodeCLient(selectedCategory._id, clientCategories, selectedCategory.maskCodePrefix); // New With Updated Prefix
                        }
                        dispatch(setEditClient(clientData)); // set new client data
                    }
                    break;
                case "editSupplier":
                    selectedTemplateData = getObjectFromArrayOfObjects(SUPPLIERS_DATA.supplierTemplates, selectedTemplate.value, "_id");
                    if (selectedTemplateData && selectedTemplateData.length > 0) {
                        dispatch(setSelectedSupplierTemplate(selectedTemplateData["0"])); // Set supplier selected template data
                        let supplierData = JSON.parse(selectedTemplateData["0"].templateData);
                        const supplierCategories = getObjectFromArrayOfObjects(SUPPLIERS_DATA.suppliersSettings.categories, "active", "isActive");
                        const selectedCategory = findObjectFromArrayOfObjects(supplierCategories, supplierData.category, "_id");
                        if (selectedCategory) {
                            supplierData.categoryMaskCodePrefix = selectedCategory.maskCodePrefix;
                            supplierData.systemCode = getGeneratedMaskCodeCLient(selectedCategory._id, supplierCategories, selectedCategory.maskCodePrefix); // New With Updated Prefix
                        }
                        dispatch(setEditSupplier(supplierData)); // set new supplier data
                    }
                    break;
                case "clientMovements":
                    selectedTemplateData = getObjectFromArrayOfObjects(CLIENTS_DATA.clientMovementsTemplates, selectedTemplate.value, "_id");
                    if (selectedTemplateData && selectedTemplateData.length > 0) {
                        dispatch(setSelectedClientMovementsTemplate(selectedTemplateData["0"])); // Set client movements selected template data
                        dispatch(setNewClientMovements(JSON.parse(selectedTemplateData["0"].templateData))); // set new client data
                    }
                    break;
                case "supplierMovements":
                    selectedTemplateData = getObjectFromArrayOfObjects(SUPPLIERS_DATA.supplierMovementsTemplates, selectedTemplate.value, "_id");
                    if (selectedTemplateData && selectedTemplateData.length > 0) {
                        dispatch(setSelectedSupplierMovementsTemplate(selectedTemplateData["0"])); // Set supplier movements selected template data
                        dispatch(setNewSupplierMovements(JSON.parse(selectedTemplateData["0"].templateData))); // set new supplier data
                    }
                    break;
                case "editClientMovements":
                    selectedTemplateData = getObjectFromArrayOfObjects(CLIENTS_DATA.clientMovementsTemplates, selectedTemplate.value, "_id");
                    if (selectedTemplateData && selectedTemplateData.length > 0) {
                        dispatch(setSelectedClientMovementsTemplate(selectedTemplateData["0"])); // Set client movements selected template data
                        dispatch(setEditClientMovements(JSON.parse(selectedTemplateData["0"].templateData))); // set new client data
                    }
                    break;
                case "editSupplierMovements":
                    selectedTemplateData = getObjectFromArrayOfObjects(SUPPLIERS_DATA.supplierMovementsTemplates, selectedTemplate.value, "_id");
                    if (selectedTemplateData && selectedTemplateData.length > 0) {
                        dispatch(setSelectedSupplierMovementsTemplate(selectedTemplateData["0"])); // Set supplier movements selected template data
                        dispatch(setEditSupplierMovements(JSON.parse(selectedTemplateData["0"].templateData))); // set new supplier data
                    }
                    break;
                case "sales":
                    selectedTemplateData = getObjectFromArrayOfObjects(SALES.salesTemplates, selectedTemplate.value, "_id");
                    if (selectedTemplateData.length > 0) {
                        dispatch(setSelectedSalesTemplate(selectedTemplateData["0"])); // Set sales selected template data
                        let salesTemplateData = JSON.parse(selectedTemplateData["0"].templateData);
                        let date = new Date();
                        const currentTime = leadingZeros(date.getHours(), 2) + ":" + leadingZeros(date.getMinutes(), 2);

                        // Passing updated time on template selection
                        salesTemplateData.date = date;
                        salesTemplateData.time = currentTime;

                        dispatch(setNewSales(salesTemplateData)); // set new sales data
                    }
                    break;
                case "buys":
                    selectedTemplateData = getObjectFromArrayOfObjects(BUYS.buysTemplates, selectedTemplate.value, "_id");
                    if (selectedTemplateData.length > 0) {
                        dispatch(setSelectedSalesTemplate(selectedTemplateData["0"])); // Set buys selected template data
                        let buysTemplateData = JSON.parse(selectedTemplateData["0"].templateData);
                        let date = new Date();
                        const currentTime = leadingZeros(date.getHours(), 2) + ":" + leadingZeros(date.getMinutes(), 2);
                        // Passing updated time on template selection
                        buysTemplateData.date = date;
                        buysTemplateData.time = currentTime;

                        dispatch(setNewBuys(buysTemplateData)); // set new buy data
                    }
                    break;
                case "quickRetailSales":
                    selectedTemplateData = getObjectFromArrayOfObjects(QUICK_RETAIL_SALES.quickRetailSalesTemplates, selectedTemplate.value, "_id");
                    if (selectedTemplateData.length > 0) {
                        // dispatch(setSelectedProductTemplate(selectedTemplateData['0'])) // Set quick retail sales selected template data
                    }
                    break;
                case "clientPayments":
                    selectedTemplateData = getObjectFromArrayOfObjects(CLIENTS_DATA.clientPaymentsTemplates, selectedTemplate.value, "_id");
                    if (selectedTemplateData.length > 0) {
                        // dispatch(setSelectedProductTemplate(selectedTemplateData['0'])) // Set client payments selected template data
                    }
                    break;
                case "supplierPayments":
                    selectedTemplateData = getObjectFromArrayOfObjects(SUPPLIERS_DATA.supplierPaymentsTemplates, selectedTemplate.value, "_id");
                    if (selectedTemplateData.length > 0) {
                        // dispatch(setSelectedProductTemplate(selectedTemplateData['0'])) // Set client payments selected template data
                    }
                    break;
                default:
                    return [];
            }
        }
    };

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => {
            return componentRef.current
        }
    });

    const handleDpActions = async (action, extraData) => {
        if (action === "print" || action === "printOnSave") {
            handlePrint();
        } else if (action === "sendEmailOnSave") {
            if (checkPermission("settings-generalsettings/emailsmtp-read", permissionsData)) {
                let elementsToShow;
                if (templateType === "sales") {
                    elementsToShow = componentRef.current.getElementsByClassName('sales-print-template');
                } else if (templateType === "clientPayments") {
                    elementsToShow = componentRef.current.getElementsByClassName('clientpayment-print-template');
                } else if (templateType === "buys") {
                    elementsToShow = componentRef.current.getElementsByClassName('buy-print-template');
                }
                if (elementsToShow.length > 0) {
                    for (let i = 0; i < elementsToShow.length; i++) {
                        let element = elementsToShow[i];
                        if (element?.id !== undefined) {
                            if (templateType === "sales") element.classList.add('sales-print-template-show');
                            if (templateType === "clientPayments") element.classList.add('clientpayment-print-template-show');
                            if (templateType === "buys") element.classList.add('buy-print-template-show')
                        }
                    }
                }
                let recipientEmails = [...selectedRecipientEmails, ...selectedCustomRecipientEmails];
                if (templateType === "sales") await dispatch(sendMail(componentRef.current, extraData, SETTINGS.salesEmailSettings, recipientEmails, "sale"));
                if (templateType === "clientPayments") await dispatch(sendMail(componentRef.current, extraData, SALES_SETTINGS.salesEmailSettings, recipientEmails, "clientPayment"));
                if (templateType === "buys") await dispatch(sendMail(componentRef.current, extraData, SALES_SETTINGS.salesEmailSettings, recipientEmails, "buys"));
                if (elementsToShow.length > 0) {
                    for (let i = 0; i < elementsToShow.length; i++) {
                        let element = elementsToShow[i];
                        if (element?.id !== undefined) {
                            if (templateType === "sales") element.classList.remove('sales-print-template-show');
                            if (templateType === "clientPayments") element.classList.remove('clientpayment-print-template-show');
                            if (templateType === "buys") element.classList.remove('buy-print-template-show');
                        }
                    }
                }
            } else {
                toast.error("You dont have the permission to send emails. Please contact Primer Software in order to get it.");
            }
        } else if (action === "convertDocument") {
            setShowConvertDocumentConfirmation(true);
        } else if (action === "issueCreditDocument") {
            setShowIssueCreditDocumentConfirmation(true);
        } else if (action === "cancelMyDataInvoice") {
            setShowCancelDocumentConfirmation(true);
        } else if ((action === "save" || action === "saveandrepeat") && templateType !== "undefined") {
            // Save Product
            if (templateType === "product") {
                let toasterror = false;
                let postProductData = {...PRODUCTS_DATA.selectedProduct, ...requestData}
                if (postProductData.mydata && postProductData.mydata?.length > 0) {
                    const mydataRows = postProductData.mydata;
                    mydataRows.map((row) => {
                        if (row.rowType === "" || row.invoiceType === "" || row.category === "" || row.type === "") {
                            toast.error('Ελέγξτε όλα τα υποχρεωτικά πεδία MyData.');
                            toasterror = true;
                        }
                        return row;
                    });
                }
                if (!isEmpty(postProductData.cpvCode) && /^\d{8}-\d$/.test(postProductData.cpvCode) === false) {
                    toast.error("Ο κωδικός CPV δεν είναι δεκτός.");
                } else if (postProductData.name === "" || postProductData.category === "" || postProductData.vat === "" || (postProductData.vatReason === "" && postProductData.vat === 0) || (postProductData.customVat === "" && postProductData.vat === "Vat For Companies Abroad - (Custom)") || postProductData.measurementUnit === "" || toasterror) {
                    toast.error('Please check all mandatory fields.');
                } else if (postProductData?.showAlternativeNameOnPrint === "true" && isEmpty(postProductData.alternateName)) {
                    toast.error("Το εναλλακτικό όνομα δεν μπορεί να είναι άδειο.");
                } else if (postProductData?.showAlternativeCodeOnPrint === "true" && isEmpty(postProductData.alternateCode)) {
                    toast.error("Ο εναλλακτικός κωδικός δεν μπορεί να είναι άδειος.");
                } else {
                    // Save and Clear Form
                    if (action === 'save') {
                        setLoadingSave(true);
                        setTimeout(() => {
                            setLoadingSave(false);
                        }, 2000);
                        if (PRODUCTS_DATA.selectedProduct.isActive === 'draft') {
                            postProductData.isActive = 'active';
                            dispatch(putProduct(postProductData, requestData, 'update', true)) // Update To API
                        } else {
                            dispatch(postProduct(postProductData, requestData)) // Post To API
                        }
                    }
                    // Save and Repeat
                    if (action === 'saveandrepeat') {
                        setLoadingSaveAndRepeat(true);
                        setTimeout(() => {
                            setLoadingSaveAndRepeat(false);
                        }, 2000);
                        if (PRODUCTS_DATA.selectedProduct.isActive === 'draft') {
                            dispatch(putProduct(postProductData, requestData)) // Update To API
                        } else {
                            postProductData.isActive = 'draft';
                            dispatch(postProduct(postProductData, requestData)) // Post To API
                        }
                    }
                }
            }
            // Update Product
            else if (templateType === "editProduct") {
                dispatch(setSaveEditProduct(false));
                let toasterror = false;
                let putProductData = {...requestData, ...PRODUCTS_DATA.selectedEditProduct};
                const mydataRows = putProductData.mydata;
                if (PRODUCTS_DATA.selectedEditProduct.productCodes && PRODUCTS_DATA.selectedEditProduct.productCodes.length > 0) {
                    putProductData.productCodes = JSON.stringify(PRODUCTS_DATA.selectedEditProduct.productCodes);
                }
                mydataRows.map((row) => {
                    if (row.rowType === "" || row.invoiceType === "" || row.category === "" || row.type === "") {
                        toast.error('Ελέγξτε όλα τα υποχρεωτικά πεδία MyData.');
                        toasterror = true;
                    }
                    return row;
                });
                if (!isEmpty(putProductData.cpvCode) && /^\d{8}-\d$/.test(putProductData.cpvCode) === false) {
                    toast.error("Ο κωδικός CPV δεν είναι δεκτός.");
                } else if (putProductData.name === "" || putProductData.category === "" || putProductData.vat === "" || (putProductData.vatReason === "" && putProductData.vat === 0) || putProductData.measurementUnit === "" || toasterror) {
                    toast.error('Ελέγξτε όλα τα υποχρεωτικά πεδία.');
                } else if (putProductData?.showAlternativeNameOnPrint === "true" && isEmpty(putProductData.alternateName)) {
                    toast.error("Το εναλλακτικό όνομα δεν μπορεί να είναι άδειο.");
                } else if (putProductData?.showAlternativeCodeOnPrint === "true" && isEmpty(putProductData.alternateCode)) {
                    toast.error("Ο εναλλακτικός κωδικός δεν μπορεί να είναι άδειος.");
                } else {
                    dispatch(putProduct(putProductData, requestData, "update", false, setLoadingSave)); // Update to API
                }
            }

            // Save Product Movements
            else if (templateType === "productMovements") {
                const requestDatapm = {
                    isActive: "active", company: company.id, year: company.year.toString(),
                };
                let validCheck = true;

                let postProductMovementsData = {
                    ...PRODUCTS_DATA.newProductMovements, ...requestDatapm,
                };
                if ((!PRODUCTS_DATA.newProductMovements.date || new Date(PRODUCTS_DATA.newProductMovements.date).getFullYear() !== company.year) && validCheck) {
                    toast.error("Please select a date included in the current Year.");
                    validCheck = false;
                }
                if (postProductMovementsData.documentType === "" || postProductMovementsData.products.length <= 0) {
                    toast.error("Please check all mandatory fields.");
                } else {
                    let zeroQuantity = false;
                    postProductMovementsData.products.map(product => {
                        if (product.quantity === 0) {
                            toast.error("Please insert quantity greater than 0");
                            zeroQuantity = true;
                        }
                        return product;
                    })
                    if (!zeroQuantity && validCheck) {
                        setLoadingSave(true);
                        setTimeout(() => {
                            setLoadingSave(false);
                        }, 2000);
                        dispatch(postProductMovements(postProductMovementsData, requestDatapm)); // Post Product Movement To API
                    }
                }
            }

            // Edit Product Movements
            else if (templateType === "editProductMovements") {
                const requestDatapm = {
                    isActive: "active", company: company.id, year: company.year.toString(),
                };
                let editProductMovementsData = {
                    ...PRODUCTS_DATA.editProductMovements, ...requestDatapm,
                };
                if (editProductMovementsData.documentType === "" || editProductMovementsData.products.length <= 0) {
                    toast.error("Please check all mandatory fields.");
                } else {
                    dispatch(putProductMovements(editProductMovementsData, requestDatapm)); // Put Product Movement To API
                }
            }

            // Save Price List
            else if (templateType === "priceList") {
                let postPriceListData = {
                    ...requestData, ...PRODUCTS_DATA.newPriceList,
                };

                let haveProducts = false;
                let haveName = false;
                let haveClients = true;

                if (postPriceListData.name && postPriceListData?.name !== "") {
                    haveName = true;
                } else {
                    toast.error("Name is required field.");
                }

                if (postPriceListData.productList && postPriceListData?.productList?.length > 0) {
                    haveProducts = true;
                } else {
                    toast.error("Please check all mandatory fields.");
                }

                for (let prod of postPriceListData.productList) {
                    if (prod.client.length === 0) {
                        haveClients = false;
                        toast.error("Please select clients on every row.");
                    }
                }

                if (haveProducts && haveName && haveClients) {
                    if (action === "save") {
                        if (postPriceListData._id) {
                            dispatch(putPriceList(postPriceListData, requestData, "update", setLoadingSave, setActivePriceListModal)); // Post Price List To API
                        } else {
                            dispatch(postPriceList(postPriceListData, requestData, true, setLoadingSave, setActivePriceListModal)); // Post Price List To API
                        }
                    }
                    if (action === "saveandrepeat") {
                        postPriceListData.isActive = "inactive";
                        if (postPriceListData._id) {
                            dispatch(putPriceList(postPriceListData, requestData, "update", setLoadingSave, setActivePriceListModal)); // Post Price List To API
                        } else {
                            dispatch(postPriceList(postPriceListData, requestData, false, setLoadingSave, setActivePriceListModal)); // Post Price List To API
                        }
                    }
                }
            }
            // Update Price List
            else if (templateType === "editPriceList") {
                let postPriceListData = {...requestData, ...PRODUCTS_DATA.editPriceList};
                let haveProducts = false;
                let haveName = false;
                let haveClients = true;
                if (postPriceListData.name && postPriceListData?.name !== "") {
                    haveName = true;
                } else {
                    toast.error("Name is required field.");
                }
                if (postPriceListData.productList && postPriceListData?.productList?.length > 0) {
                    haveProducts = true;
                } else {
                    toast.error("Please check all mandatory fields.");
                }
                for (let prod of postPriceListData.productList) {
                    if (prod.client.length === 0) {
                        haveClients = false;
                        toast.error("Please select clients on every row.");
                    }
                }
                if (haveProducts && haveName && haveClients) {
                    dispatch(putPriceList(postPriceListData, requestData, "update", setLoadingSave, setActivePriceListModal)); // Put Price List To API
                }
            }
            // Save Basic Type
            else if (templateType === "basicType") {
                setLoadingSave(true);
                setTimeout(() => {
                    setLoadingSave(false);
                }, 2000);
                let postBasicTypeData = {...SETTINGS.settings.newBasicType, ...requestData};
                dispatch(postBasicType(postBasicTypeData, requestData)); // Post Basic Type to API
            }

            // Update Basic Type
            else if (templateType === "editBasicType") {
                let editBasicTypeData = {
                    ...SETTINGS.settings.editBasicType, ...requestData,
                };
                dispatch(putBasicType(editBasicTypeData, requestData)); // Post Basic Type to API
            }

            // Save Advanced Type
            else if (templateType === "advancedType") {
                let postAdvancedTypeData = { ...requestData, ...SETTINGS.settings.newAdvancedType }

                if ((postAdvancedTypeData.documentType === "" && postAdvancedTypeData.section !== "Accounting Records" && postAdvancedTypeData.section !== "Buys") || postAdvancedTypeData.name === "" || (postAdvancedTypeData.documentNumbering?.length === 0 && postAdvancedTypeData.section !== "Accounting Records" && postAdvancedTypeData.section !== "Buys")) {
                    toast.error("Please check all mandatory fields.");
                } else {
                    setLoadingSave(true);
                    if (postAdvancedTypeData.section === "Sales") {
                        let valid = true;
                        if (postAdvancedTypeData.conversionDocument !== null && postAdvancedTypeData.conversionDocument !== "" && postAdvancedTypeData.conversionDocument !== undefined && (postAdvancedTypeData.conversionDocumentPreNumber === undefined || postAdvancedTypeData.conversionDocumentPreNumber === "")) {
                            toast.error("Conversion Document PreNumber is mandatory for Conversion Document.");
                            valid = false;
                        }
                        if (postAdvancedTypeData.creditDocument !== null && postAdvancedTypeData.creditDocument !== "" && postAdvancedTypeData.creditDocument !== undefined && (postAdvancedTypeData.creditDocumentPreNumber === undefined || postAdvancedTypeData.creditDocumentPreNumber === "")) {
                            toast.error("Credit Document  PreNumber is mandatory for Conversion Document.");
                            valid = false;
                        }
                        if (postAdvancedTypeData?.enableMyDataRules === "active" && !postAdvancedTypeData.myDataType) {
                            toast.error("Please select a myData Type for this document!");
                            valid = false;
                        }
                        if (valid) {
                            dispatch(postAdvancedTypeSales(postAdvancedTypeData, setLoadingSave)); // Post Advanced Type to API
                        } else {
                            setLoadingSave(false);
                        }
                    } else if (postAdvancedTypeData.section === "Buys") {
                        let valid = true;
                        if (postAdvancedTypeData.conversionDocument !== null && postAdvancedTypeData.conversionDocument !== "" && postAdvancedTypeData.conversionDocument !== undefined && (postAdvancedTypeData.conversionDocumentPreNumber === undefined || postAdvancedTypeData.conversionDocumentPreNumber === "")) {
                            toast.error("Conversion Document PreNumber is mandatory for Conversion Document.");
                            valid = false;
                        }
                        if (postAdvancedTypeData.creditDocument !== null && postAdvancedTypeData.creditDocument !== "" && postAdvancedTypeData.creditDocument !== undefined && (postAdvancedTypeData.creditDocumentPreNumber === undefined || postAdvancedTypeData.creditDocumentPreNumber === "")) {
                            toast.error("Credit Document  PreNumber is mandatory for Conversion Document.");
                            valid = false;
                        }
                        if ((postAdvancedTypeData?.accounting?.bridge?.find((el) => el.type === "vatDed") || postAdvancedTypeData?.accounting?.bridge?.find((el) => el.type === "vatNonDed"))
                        && postAdvancedTypeData?.accounting?.bridge?.find((el) => el.type === "vat")) {
                            toast.error("Η γραμμή Φ.Π.Α. δεν μπορεί να υπάρχει όταν υπάρχουν οι γραμμές Φ.Π.Α. Μη εκπιπτόμενου και Φ.Π.Α. Εκπιπτόμενου.");
                            valid = false;
                        }
                        if ((postAdvancedTypeData?.accounting?.bridge?.find((el) => el.type === "vatDed") && !postAdvancedTypeData?.accounting?.bridge?.find((el) => el.type === "vatNonDed"))) {
                            toast.error("Πρέπει να συμπληρωθεί η γραμμή Φ.Π.Α. Μη εκπιπτόμενου.");
                            valid = false;
                        }
                        if (!postAdvancedTypeData?.accounting?.bridge?.find((el) => el.type === "vatDed") && postAdvancedTypeData?.accounting?.bridge?.find((el) => el.type === "vatNonDed")) {
                            toast.error("Πρέπει να συμπληρωθεί η γραμμή Φ.Π.Α. Εκπιπτόμενου.");
                            valid = false;
                        }
                        if (valid) {
                            dispatch(postAdvancedTypeBuys(postAdvancedTypeData, setLoadingSave)); // Post Advanced Type to API
                        } else {
                            setLoadingSave(false);
                        }
                    } else {
                        let valid = true;
                        if (postAdvancedTypeData.section === "Client Payments" && postAdvancedTypeData?.myDataType === "8.4" && isEmpty(postAdvancedTypeData?.proposedPaymentType)) {
                            toast.error("Ο προτεινόμενος τρόπος πληρωμής πρέπει να μην είναι άδειος στον τύπο παραστατικού 8.4");
                            valid = false;
                        }
                        if (postAdvancedTypeData.section === "Client Payments" && postAdvancedTypeData?.myDataType === "8.4" && isEmpty(postAdvancedTypeData?.proposedPosBankAccount)) {
                            toast.error("Πρέπει να επιλέξετε προτεινόμενο POS.");
                            valid = false;
                        }
                        if(valid) {
                            dispatch(postAdvancedType(postAdvancedTypeData, setLoadingSave)); // Post Advanced Type to API
                        } else {
                            setLoadingSave(false);
                        }
                    }
                }
            }

            // Update Advanced Type
            else if (templateType === "editAdvancedType") {
                let putAdvancedTypeData = { ...requestData, ...SETTINGS.settings.editAdvancedType }
                if ((putAdvancedTypeData.documentType === "" && putAdvancedTypeData.section !== "Accounting Records" && putAdvancedTypeData.section !== "Buys") || putAdvancedTypeData.name === "" || (putAdvancedTypeData.documentNumbering?.length === 0 && putAdvancedTypeData.section !== "Accounting Records" && putAdvancedTypeData.section !== "Buys")) {
                    toast.error("Please check all mandatory fields.");
                } else {
                    setLoadingSave(true);
                    if (putAdvancedTypeData.section === "Sales") {
                        let valid = true;
                        if (putAdvancedTypeData.conversionDocument !== null && putAdvancedTypeData.conversionDocument !== "" && putAdvancedTypeData.conversionDocument !== undefined && (putAdvancedTypeData.conversionDocumentPreNumber === undefined || putAdvancedTypeData.conversionDocumentPreNumber === "")) {
                            toast.error("Conversion Document PreNumber is mandatory for Conversion Document.");
                            valid = false;
                        }
                        if (putAdvancedTypeData.creditDocument !== null && putAdvancedTypeData.creditDocument !== "" && putAdvancedTypeData.creditDocument !== undefined && (putAdvancedTypeData.creditDocumentPreNumber === undefined || putAdvancedTypeData.creditDocumentPreNumber === "")) {
                            toast.error("Credit Document PreNumber is mandatory for Conversion Document.");
                            valid = false;
                        }
                        if (putAdvancedTypeData?.enableMyDataRules === "active" && !putAdvancedTypeData.myDataType) {
                            toast.error("Please select a myData Type for this document!");
                            valid = false;
                        }
                        if (valid) {
                            dispatch(putAdvancedTypeSales(putAdvancedTypeData, setLoadingSave));
                        } else {
                            setLoadingSave(false);
                        }
                    } else if (putAdvancedTypeData.section === "Buys") {
                        let valid = true;
                        if (putAdvancedTypeData.conversionDocument !== null && putAdvancedTypeData.conversionDocument !== "" && putAdvancedTypeData.conversionDocument !== undefined && (putAdvancedTypeData.conversionDocumentPreNumber === undefined || putAdvancedTypeData.conversionDocumentPreNumber === "")) {
                            toast.error("Conversion Document PreNumber is mandatory for Conversion Document.");
                            valid = false;
                        }
                        if (putAdvancedTypeData.creditDocument !== null && putAdvancedTypeData.creditDocument !== "" && putAdvancedTypeData.creditDocument !== undefined && (putAdvancedTypeData.creditDocumentPreNumber === undefined || putAdvancedTypeData.creditDocumentPreNumber === "")) {
                            toast.error("Credit Document PreNumber is mandatory for Conversion Document.");
                            valid = false;
                        }
                        if ((putAdvancedTypeData?.accounting?.bridge?.find((el) => el.type === "vatDed") || putAdvancedTypeData?.accounting?.bridge?.find((el) => el.type === "vatNonDed"))
                            && putAdvancedTypeData?.accounting?.bridge?.find((el) => el.type === "vat")) {
                            toast.error("Η γραμμή Φ.Π.Α. δεν μπορεί να υπάρχει όταν υπάρχουν οι γραμμές Φ.Π.Α. Μη εκπιπτόμενου και Φ.Π.Α. Εκπιπτόμενου.");
                            valid = false;
                        }
                        if ((putAdvancedTypeData?.accounting?.bridge?.find((el) => el.type === "vatDed") && !putAdvancedTypeData?.accounting?.bridge?.find((el) => el.type === "vatNonDed"))) {
                            toast.error("Πρέπει να συμπληρωθεί η γραμμή Φ.Π.Α. Μη εκπιπτόμενου.");
                            valid = false;
                        }
                        if (!putAdvancedTypeData?.accounting?.bridge?.find((el) => el.type === "vatDed") && putAdvancedTypeData?.accounting?.bridge?.find((el) => el.type === "vatNonDed")) {
                            toast.error("Πρέπει να συμπληρωθεί η γραμμή Φ.Π.Α. Εκπιπτόμενου.");
                            valid = false;
                        }
                        if (valid) {
                            dispatch(putAdvancedTypeBuys(putAdvancedTypeData, setLoadingSave));
                        } else {
                            setLoadingSave(false);
                        }
                    } else {
                        let valid = true;
                        if (putAdvancedTypeData.section === "Client Payments" && putAdvancedTypeData?.myDataType === "8.4" && isEmpty(putAdvancedTypeData?.proposedPaymentType)) {
                            toast.error("Ο προτεινόμενος τρόπος πληρωμής πρέπει να μην είναι άδειος στον τύπο παραστατικού 8.4");
                            valid = false;
                        }
                        if (putAdvancedTypeData.section === "Client Payments" && putAdvancedTypeData?.myDataType === "8.4" && isEmpty(putAdvancedTypeData?.proposedPosBankAccount)) {
                            toast.error("Πρέπει να επιλέξετε προτεινόμενο POS.");
                            valid = false;
                        }
                        if (valid) {
                            dispatch(putAdvancedType(putAdvancedTypeData, setLoadingSave));
                        } else {
                            setLoadingSave(false);
                        }
                    }
                }
            }

            // Save Client
            else if (templateType === "client") {
                let validCheck = true;
                let isValidVatNumber = true;
                let isValidDOY = true;
                let isValidActivity = true;
                let isValidCountry = true;
                let isValidAddress = true;
                let isValidCity = true;
                let isValidPC = true;
                let isValidVatCategory = true;
                let isValidEmailPhone = true;
                let isValidVatReason = true;
                let isValidCustomVat = true;

                let newClientData = CLIENTS_DATA.newClient;

                // Freelancer, O.E., E.E., E.P.E., I.K.E., A.E.:  Mandatory field
                if (newClientData.categoryName === "Freelancer" || newClientData.categoryName === "O.E." || newClientData.categoryName === "E.E." || newClientData.categoryName === "E.P.E." || newClientData.categoryName === 'Wholesale Others' || newClientData.categoryName === "I.K.E." || newClientData.categoryName === "Non-Profit Organization" || newClientData.categoryName === "A.E." || newClientData.categoryName === "Client within E.U." || newClientData.categoryName === "Client outside E.U.") {
                    if (newClientData.vatNumber === "") {
                        isValidVatNumber = false;
                        console.log("invalid vat number");
                    }
                    if (newClientData.DOY === "" && newClientData.categoryName !== "Client within E.U." && newClientData.categoryName !== "Client outside E.U.") {
                        isValidDOY = false;
                        console.log("invalid doy");
                    }
                }

                // Mandatory field EXCEPT if category retail & patient
                if (newClientData.categoryName !== "Retail" && newClientData.categoryName !== "Patient") {
                    if (newClientData.activity === "" && newClientData.categoryName !== "Client within E.U." && newClientData.categoryName !== "Client outside E.U.") {
                        isValidActivity = false;
                        console.log("invalid activity");
                    }
                    if (newClientData.country === "") {
                        isValidCountry = false;
                        console.log("invalid country");
                    }
                    if (newClientData.address === "") {
                        isValidAddress = false;
                        console.log("invalid address");
                    }
                    if (newClientData.city === "") {
                        isValidCity = false;
                        console.log("invalid city");
                    }
                    if (newClientData.postalCode === "") {
                        isValidPC = false;
                        console.log("invalid pc");
                    }
                }
                //mandatory address in patients
                if (newClientData.categoryName === "Patient") {
                    if (newClientData.address === "") {
                        isValidAddress = false;
                        console.log("invalid address");
                    }
                }

                // Do not show if Client Category is "Client within E.U." or "Client outside E.U."
                if (newClientData.vatCategory === '') {
                    isValidVatCategory = false
                    console.log('invalid category');
                }
                console.log(newClientData.vatCategory)
                if (newClientData.vatCategory === 'Zero VAT' && newClientData.vatReason === '') {
                    isValidVatReason = false
                    console.log('invalid reason');
                }
                if (newClientData.vatCategory === 'Custom VAT' && newClientData.customVat === '' && newClientData.customVat <= 100) {
                    isValidVatReason = false
                    console.log('invalid Custom Vat');
                }

                if (newClientData.email === "" && newClientData.phone === "" && newClientData.categoryName !== "Patient" && newClientData.categoryName !== "I.K.E." && newClientData.categoryName !== "Freelancer" && newClientData.categoryName !== "O.E." && newClientData.categoryName !== "E.E." && newClientData.categoryName !== "A.E." && newClientData.categoryName !== "Non-Profit Organization" && newClientData.categoryName !== "E.P.E." && newClientData.categoryName !== "Wholesale Others") {
                    isValidEmailPhone = false;
                    console.log("invalid email or phone");
                }
                if (newClientData.email === "" && newClientData.phone === "" && newClientData.amka === "" && newClientData.categoryName === "Patient") {
                    isValidEmailPhone = false;
                    console.log("invalid email or phone or amka");
                }

                if (newClientData.email) {
                    if (!isValidEmail(newClientData.email)) {
                        isValidEmailPhone = false;
                        toast.error("Invalid Email address.");
                    }
                }
                if (newClientData.category === '' || newClientData.name === '' || !isValidVatNumber || !isValidDOY || !isValidActivity || !isValidCountry || !isValidAddress || !isValidCity || !isValidPC || !isValidEmailPhone || !isValidVatCategory || !isValidVatReason || !isValidCustomVat) {
                    toast.error("Please check all mandatory fields.");
                    validCheck = false;
                }
                if (validateAFM(newClientData.vatNumber) === false && (newClientData.categoryName === "Freelancer" || newClientData.categoryName === "O.E." || newClientData.categoryName === "E.E." || newClientData.categoryName === "E.P.E." || newClientData.categoryName === 'Wholesale Others' || newClientData.categoryName === "I.K.E." || newClientData.categoryName === "Non-Profit Organization" || newClientData.categoryName === "A.E.")) {
                    toast.error("Vat Number is wrong. Please check again!");
                    validCheck = false;
                }

                // Check if contact points empty
                if (CLIENTS_DATA.newClient.contactPoints.length > 0 && validCheck) {
                    let emptyCps = getObjectFromArrayOfObjects(CLIENTS_DATA.newClient.contactPoints, "", "name");
                    if (emptyCps && emptyCps.length > 0) {
                        validCheck = false;
                        toast.error("Please check table mandatory fields.");
                    }
                }
                if (CLIENTS_DATA.newClient.notes[0] && Object.keys(CLIENTS_DATA.newClient.notes[0]).length > 0 && validCheck) {
                    Object.keys(CLIENTS_DATA.newClient.notes[0]).map(section => {
                        CLIENTS_DATA.newClient.notes[0][section].map(note => {
                            if (!note.description || !note.details) {
                                validCheck = false;
                                toast.error("Description and Details are required.");
                            }
                            return note;
                        })
                        return section;
                    })
                }
                if (CLIENTS_DATA.newClient.emronPasswords.length > 0 && validCheck) {
                    CLIENTS_DATA.newClient.emronPasswords.map(password => {
                        if (!password.type || !password.username || !password.password) {
                            validCheck = false;
                            toast.error('Type and username and password are required');
                        }
                        return password;
                    })
                }
                if (CLIENTS_DATA.newClient.stockholders.length > 0 && validCheck) {
                    CLIENTS_DATA.newClient.stockholders.map(stockholder => {
                        if (!stockholder.name || !stockholder.surname || !stockholder.percentage || !stockholder.position) {
                            validCheck = false;
                            toast.error('Please fill all the necessary info for each stockholder!');
                        }
                        return stockholder;
                    })
                }
                if (CLIENTS_DATA.newClient.installations.length > 0 && validCheck) {
                    let emptyCps = getObjectFromArrayOfObjects(CLIENTS_DATA.newClient.installations, '', 'name');
                    if (emptyCps && emptyCps.length > 0) {
                        validCheck = false;
                        toast.error('Please check table mandatory fields.');
                    }
                    CLIENTS_DATA.newClient.installations.map((installation) => {
                        if (installation.vatCategory === 'Custom VAT' && installation.customVat === '' && installation.customVat <= 100) {
                            validCheck = false;
                            toast.error('Please Select a custom VAT percentage');
                        }
                        if (installation.vatCategory === 'Zero VAT' && installation.vatReason === '') {
                            validCheck = false;
                            toast.error('Please Select a VAT exemption reason');
                        }
                        return installation;
                    })
                }

                if (validCheck && templateType === 'client') {
                    // Client Data
                    let postClientData = {...CLIENTS_DATA.newClient, ...requestData};
                    postClientData.isActive = CLIENTS_DATA.newClient.isActive; // set status
                    // Client Emron Data
                    let postClientEmronData = {...CLIENTS_DATA.clientEmron, ...requestData};
                    postClientEmronData.isActive = CLIENTS_DATA.clientEmron.isActive; // set status
                    setLoadingSave(true);
                    setTimeout(() => {
                        setLoadingSave(false);
                    }, 2000);
                    if (action === "save") {
                        if (postClientData.isActive === "draft") {
                            dispatch(putClient(postClientData, requestData, "update", {}, true)); // Update Client To API
                        } else {
                            dispatch(postClient(postClientData, requestData, postClientEmronData, true)); // Post Client To API
                        }
                    }
                    if (action === "saveandrepeat") {
                        setLoadingSaveAndRepeat(true);
                        setTimeout(() => {
                            setLoadingSaveAndRepeat(false);
                        }, 2000);
                        postClientData.isActive = "draft";
                        dispatch(postClient(postClientData, requestData, postClientEmronData)); // Post Client To API
                    }
                }
            }
            // Save Supplier
            else if (templateType === "supplier") {
                let validCheck = true;
                let isValidVatNumber = true;
                let isValidDOY = true;
                let isValidActivity = true;
                let isValidCountry = true;
                let isValidAddress = true;
                let isValidCity = true;
                let isValidPC = true;
                let isValidVatCategory = true;
                let isValidEmailPhone = true;
                let isValidVatReason = true;
                let isValidCustomVat = true;

                let newSupplierData = SUPPLIERS_DATA.newSupplier;

                // Freelancer, O.E., E.E., E.P.E., I.K.E., A.E.:  Mandatory field
                if (newSupplierData.categoryName === "Freelancer" || newSupplierData.categoryName === "O.E." || newSupplierData.categoryName === "E.E." || newSupplierData.categoryName === "E.P.E." || newSupplierData.categoryName === 'Wholesale Others' || newSupplierData.categoryName === "I.K.E." || newSupplierData.categoryName === "A.E.") {
                    if (newSupplierData.vatNumber === "") {
                        isValidVatNumber = false;
                        console.log("invalid vat number");
                    }
                    if (newSupplierData.DOY === "") {
                        isValidDOY = false;
                        console.log("invalid doy");
                    }
                }

                // Mandatory field EXCEPT if category retail & patient
                if (newSupplierData.categoryName !== "Retail" && newSupplierData.categoryName !== "Patient") {
                    if (newSupplierData.activity === "" && newSupplierData.categoryName !== "Supplier within E.U." && newSupplierData.categoryName !== "Supplier outside E.U.") {
                        isValidActivity = false;
                        console.log("invalid activity");
                    }
                    if (newSupplierData.country === "") {
                        isValidCountry = false;
                        console.log("invalid country");
                    }
                    if (newSupplierData.address === "") {
                        isValidAddress = false;
                        console.log("invalid address");
                    }
                    if (newSupplierData.city === "") {
                        isValidCity = false;
                        console.log("invalid city");
                    }
                    if (newSupplierData.postalCode === "") {
                        isValidPC = false;
                        console.log("invalid pc");
                    }
                }

                // Do not show if Supplier Category is "Supplier within E.U." or "Supplier outside E.U."
                if (newSupplierData.vatCategory === '') {
                    isValidVatCategory = false
                    console.log('invalid category');
                }
                console.log(newSupplierData.vatCategory)
                if (newSupplierData.vatCategory === 'Zero VAT' && newSupplierData.vatReason === '') {
                    isValidVatReason = false
                    console.log('invalid reason');
                }
                if (newSupplierData.vatCategory === 'Custom VAT' && newSupplierData.customVat === '' && newSupplierData.customVat <= 100) {
                    isValidVatReason = false
                    console.log('invalid Custom Vat');
                }

                if ((newSupplierData.email === "" && newSupplierData.phone === "") && newSupplierData.categoryName !== "I.K.E." && newSupplierData.categoryName !== "Freelancer" && newSupplierData.categoryName !== "O.E." && newSupplierData.categoryName !== "E.E." && newSupplierData.categoryName !== "A.E." && newSupplierData.categoryName !== "Non-Profit Organization" && newSupplierData.categoryName !== "E.P.E." && newSupplierData.categoryName !== "Wholesale Others") {
                    isValidEmailPhone = false;
                    console.log("invalid email or phone");
                }

                if (newSupplierData.email) {
                    if (!isValidEmail(newSupplierData.email)) {
                        isValidEmailPhone = false;
                        toast.error("Invalid Email address.");
                    }
                }
                if (newSupplierData.category === '' || newSupplierData.name === '' || !isValidVatNumber || !isValidDOY || !isValidActivity || !isValidCountry || !isValidAddress || !isValidCity || !isValidPC || !isValidEmailPhone || !isValidVatCategory || !isValidVatReason || !isValidCustomVat) {
                    toast.error("Please check all mandatory fields.");
                    validCheck = false;
                }

                // Check if contact points empty
                if (SUPPLIERS_DATA.newSupplier.contactPoints.length > 0 && validCheck) {
                    let emptyCps = getObjectFromArrayOfObjects(SUPPLIERS_DATA.newSupplier.contactPoints, "", "name");
                    if (emptyCps && emptyCps.length > 0) {
                        validCheck = false;
                        toast.error("Please check table mandatory fields.");
                    }
                }

                if (SUPPLIERS_DATA.newSupplier.installations.length > 0 && validCheck) {
                    let emptyCps = getObjectFromArrayOfObjects(SUPPLIERS_DATA.newSupplier.installations, '', 'name');
                    if (emptyCps && emptyCps.length > 0) {
                        validCheck = false;
                        toast.error('Please check table mandatory fields.');
                    }
                    SUPPLIERS_DATA.newSupplier.installations.map((installation) => {
                        if (installation.vatCategory === 'Custom VAT' && installation.customVat === '' && installation.customVat <= 100) {
                            validCheck = false;
                            toast.error('Please Select a custom VAT percentage');
                        }
                        if (installation.vatCategory === 'Zero VAT' && installation.vatReason === '') {
                            validCheck = false;
                            toast.error('Please Select a VAT exemption reason');
                        }
                        return installation;
                    })
                }

                if (validCheck && templateType === 'supplier') {

                    // Supplier Data
                    let postSupplierData = {...SUPPLIERS_DATA.newSupplier, ...requestData};
                    postSupplierData.isActive = SUPPLIERS_DATA.newSupplier.isActive; // set status

                    // Supplier Emron Data
                    let postSupplierEmronData = {
                        ...SUPPLIERS_DATA.supplierEmron, ...requestData,
                    };
                    postSupplierEmronData.isActive = SUPPLIERS_DATA.supplierEmron.isActive; // set status

                    setLoadingSave(true);
                    setTimeout(() => {
                        setLoadingSave(false);
                    }, 2000);
                    if (action === "save") {
                        if (postSupplierData.isActive === "draft") {
                            dispatch(putSupplier(postSupplierData, requestData, "update", {}, true)); // Update Supplier To API
                        } else {
                            dispatch(postSupplier(postSupplierData, requestData, postSupplierEmronData, true)); // Post Supplier To API
                        }
                    }
                    if (action === "saveandrepeat") {
                        setLoadingSaveAndRepeat(true);
                        setTimeout(() => {
                            setLoadingSaveAndRepeat(false);
                        }, 2000);

                        postSupplierData.isActive = "draft";
                        // dispatch(postSupplier(postSupplierData, requestData)) // Post Supplier To API
                        dispatch(postSupplier(postSupplierData, requestData, postSupplierEmronData)); // Post Supplier To API
                    }
                }
            }

            // Edit Client
            else if (templateType === "editClient") {
                let validCheck = true;
                let isValidVatNumber = true;
                let isValidDOY = true;
                let isValidActivity = true;
                let isValidCountry = true;
                let isValidAddress = true;
                let isValidCity = true;
                let isValidPC = true;
                let isValidVatCategory = true;
                let isValidEmailPhone = true;
                let isValidVatReason = true;
                let isValidCustomVat = true;

                let editClientData = CLIENTS_DATA.editClient;

                // Freelancer, O.E., E.E., E.P.E., I.K.E., A.E.:  Mandatory field
                if (editClientData.categoryName === "Freelancer" || editClientData.categoryName === "O.E." || editClientData.categoryName === "E.E." || editClientData.categoryName === "E.P.E." || editClientData.categoryName === 'Wholesale Others' || editClientData.categoryName === "I.K.E." || editClientData.categoryName === "Non-Profit Organization" || editClientData.categoryName === "A.E." || editClientData.categoryName === "Client within E.U." || editClientData.categoryName === "Client outside E.U.") {
                    if (editClientData.vatNumber === "") {
                        isValidVatNumber = false;
                        console.log("invalid vat number");
                    }
                    if (editClientData.DOY === "" && editClientData.categoryName !== "Client within E.U." && editClientData.categoryName !== "Client outside E.U.") {
                        isValidDOY = false;
                        console.log("invalid doy");
                    }
                }

                // Mandatory field EXCEPT if category retail & patient
                if (editClientData.categoryName !== "Retail" && editClientData.categoryName !== "Patient") {
                    if (editClientData.activity === "" && editClientData.categoryName !== "Client within E.U." && editClientData.categoryName !== "Client outside E.U.") {
                        isValidActivity = false;
                        console.log("invalid activity");
                    }
                    if (editClientData.country === "") {
                        isValidCountry = false;
                        console.log("invalid country");
                    }
                    if (editClientData.address === "") {
                        isValidAddress = false;
                        console.log("invalid address");
                    }
                    if (editClientData.city === "") {
                        isValidCity = false;
                        console.log("invalid city");
                    }
                    if (editClientData.postalCode === "") {
                        isValidPC = false;
                        console.log("invalid pc");
                    }
                }
                //mandatory address in patients
                if (editClientData.categoryName === "Patient") {
                    if (editClientData.address === "") {
                        isValidAddress = false;
                        console.log("invalid address");
                    }
                }

                // Do not show if Client Category is "Client within E.U." or "Client outside E.U."
                if (editClientData.vatCategory === '') {
                    isValidVatCategory = false
                    console.log('invalid category');
                }
                if (editClientData.vatCategory === 'Zero VAT' && editClientData.vatReason === '') {
                    isValidVatReason = false
                    console.log('invalid Reason');
                }

                if (editClientData.vatCategory === 'Custom VAT' && editClientData.customVat === '' && editClientData.customVat <= 100) {
                    isValidVatReason = false
                    console.log('invalid Custom Vat');
                }

                if (editClientData.email === "" && editClientData.phone === "" && editClientData.categoryName !== "Patient" && editClientData.categoryName !== "I.K.E." && editClientData.categoryName !== "Freelancer" && editClientData.categoryName !== "O.E." && editClientData.categoryName !== "E.E." && editClientData.categoryName !== "A.E." && editClientData.categoryName !== "Non-Profit Organization" && editClientData.categoryName !== "E.P.E." && editClientData.categoryName !== "Wholesale Others") {
                    isValidEmailPhone = false;
                    console.log("invalid email or phone");
                }
                if (editClientData.email === "" && editClientData.phone === "" && editClientData.amka === "" && editClientData.categoryName === "Patient") {
                    isValidEmailPhone = false;
                    console.log("invalid email or phone or phone");
                }

                if (editClientData.email) {
                    if (!isValidEmail(editClientData.email)) {
                        isValidEmailPhone = false;
                        toast.error("Invalid Email address.");
                    }
                }

                if (editClientData.category === "" || editClientData.name === "" || !isValidVatNumber || !isValidDOY || !isValidActivity || !isValidCountry || !isValidAddress || !isValidCity || !isValidPC || !isValidEmailPhone || !isValidVatCategory || !isValidVatReason || !isValidCustomVat) {
                    toast.error("Please check all mandatory fields.");
                    validCheck = false;
                }
                console.log(validateAFM(editClientData.vatNumber))
                if (validateAFM(editClientData.vatNumber) === false && (editClientData.categoryName === "Freelancer" || editClientData.categoryName === "O.E." || editClientData.categoryName === "E.E." || editClientData.categoryName === "E.P.E." || editClientData.categoryName === 'Wholesale Others' || editClientData.categoryName === "I.K.E." || editClientData.categoryName === "Non-Profit Organization" || editClientData.categoryName === "A.E.")) {
                    toast.error("Vat Number is wrong. Please check again!");
                    validCheck = false;
                }

                // Check if contact points empty
                if (CLIENTS_DATA.editClient?.contactPoints?.length > 0 && validCheck) {
                    let emptyCps = getObjectFromArrayOfObjects(CLIENTS_DATA.editClient.contactPoints, "", "name");
                    if (emptyCps && emptyCps.length > 0) {
                        validCheck = false;
                        toast.error("Please check table mandatory fields.");
                    }
                }
                if (CLIENTS_DATA.editClient.notes[0] && Object.keys(CLIENTS_DATA.editClient.notes[0]).length > 0 && validCheck) {
                    Object.keys(CLIENTS_DATA.editClient.notes[0]).map(section => {
                        CLIENTS_DATA.editClient.notes[0][section].map(note => {
                            if (!note.description || !note.details) {
                                validCheck = false;
                                toast.error("Description and Details are required.");
                            }
                            return note;
                        })
                        return section;
                    })
                }
                if (CLIENTS_DATA.editClient?.emronPasswords?.length > 0 && validCheck) {
                    CLIENTS_DATA.editClient.emronPasswords.map(password => {
                        if (!password.type || !password.username || !password.password) {
                            validCheck = false;
                            toast.error('Type and username and password are required');
                        }
                        return password;
                    })
                }
                if (CLIENTS_DATA.editClient?.stockholders?.length > 0 && validCheck) {
                    CLIENTS_DATA.editClient.stockholders.map(stockholder => {
                        if (!stockholder.name || !stockholder.surname || !stockholder.percentage || !stockholder.position) {
                            validCheck = false;
                            toast.error('Please fill all the necessary info for each stockholder!');
                        }
                        return stockholder;
                    })
                }
                if (CLIENTS_DATA.editClient?.emronPayments?.length > 0 && validCheck) {
                    let instCount = {} // Will have type : count
                    CLIENTS_DATA.editClient.emronPayments.map((emronPayment) => {
                        if (isEmpty(emronPayment.monthTo)) {
                            if (!instCount[emronPayment.installationType]) {
                                instCount[emronPayment.installationType] = 1;
                            } else {
                                instCount[emronPayment.installationType] += 1;
                            }
                        }
                        if (!emronPayment.installation || !emronPayment.month) {
                            validCheck = false;
                            toast.error('Please fill all the necessary info for a monthly payment!');
                        }
                        return emronPayment;
                    })
                    for (let key in instCount) {
                        if (instCount[key] > 1) {
                            validCheck = false;
                            toast.error("Only 1 non expiring monthly payment can exist per installation.");
                        }
                    }
                }
                if (CLIENTS_DATA.editClient?.installations?.length > 0 && validCheck) {
                    let emptyCps = getObjectFromArrayOfObjects(CLIENTS_DATA.editClient.installations, '', 'name');
                    if (emptyCps && emptyCps.length > 0) {
                        validCheck = false;
                        toast.error('Please check table mandatory fields.');
                    }
                    CLIENTS_DATA.editClient.installations.map((installation) => {
                        if (installation.vatCategory === 'Custom VAT' && installation.customVat === '' && installation.customVat <= 100) {
                            validCheck = false;
                            toast.error('Please Select a custom VAT percentage');
                        }
                        if (installation.vatCategory === 'Zero VAT' && installation.vatReason === '') {
                            validCheck = false;
                            toast.error('Please Select a VAT exemption reason');
                        }
                        return installation;
                    })
                }

                if (company.vatNumber === "800434990" && getCurrentUser()?.userType === "Admin" && !emronAdminCancellationModal && validCheck) {
                    try {
                        const preCheckResponse = await axios.post(`${process.env.REACT_APP_API_URL2}/emron/client-edit-pre-check`, {...CLIENTS_DATA.editClient}, {
                            headers: { 'Content-Type': 'application/json' },
                        });
                        if (preCheckResponse.data.status === "418") {
                            validCheck = false; // Proceed with modal of confirmation
                            setEmronAdminCancellationModal(true);
                        } else if (preCheckResponse.data.status !== "200") {
                            toast.error(preCheckResponse.data.message);
                        }
                    } catch (error) {
                        console.log(error);
                        toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
                    }
                }
                if (emronAdminCancellationModal) setEmronAdminCancellationModal(false);

                if (validCheck) {
                    // Client Data
                    let postClientData = {...CLIENTS_DATA.editClient, ...requestData};
                    postClientData.isActive = CLIENTS_DATA.editClient.isActive; // set status

                    // Client Emron Data
                    let postClientEmronData = {
                        ...CLIENTS_DATA.clientEmron, ...requestData,
                    };
                    postClientEmronData.isActive = CLIENTS_DATA.clientEmron.isActive; // set status

                    setLoadingSave(true);
                    dispatch(putClient(postClientData, requestData, "update", {}, true, setLoadingSave)); // Update Client To API
                    // dispatch(postClient(postClientData, requestData, postClientEmronData, true)) // Post Client To API
                }
            }

            // Edit Supplier
            else if (templateType === "editSupplier") {
                let validCheck = true;
                let isValidVatNumber = true;
                let isValidDOY = true;
                let isValidActivity = true;
                let isValidCountry = true;
                let isValidAddress = true;
                let isValidCity = true;
                let isValidPC = true;
                let isValidVatCategory = true;
                let isValidEmailPhone = true;
                let isValidVatReason = true;
                let isValidCustomVat = true;

                let editSupplierData = SUPPLIERS_DATA.editSupplier;

                // Freelancer, O.E., E.E., E.P.E., I.K.E., A.E.:  Mandatory field
                if (editSupplierData.categoryName === "Freelancer" || editSupplierData.categoryName === "O.E." || editSupplierData.categoryName === "E.E." || editSupplierData.categoryName === "E.P.E." || editSupplierData.categoryName === 'Wholesale Others' || editSupplierData.categoryName === "I.K.E." || editSupplierData.categoryName === "A.E.") {
                    if (editSupplierData.vatNumber === "") {
                        isValidVatNumber = false;
                        console.log("invalid vat number");
                    }
                    if (editSupplierData.DOY === "") {
                        isValidDOY = false;
                        console.log("invalid doy");
                    }
                }

                // Mandatory field EXCEPT if category retail & patient
                if (editSupplierData.categoryName !== "Retail" && editSupplierData.categoryName !== "Patient") {
                    if (editSupplierData.activity === "" && editSupplierData.categoryName !== "Supplier within E.U." && editSupplierData.categoryName !== "Supplier outside E.U.") {
                        isValidActivity = false;
                        console.log("invalid activity");
                    }
                    if (editSupplierData.country === "") {
                        isValidCountry = false;
                        console.log("invalid country");
                    }
                    if (editSupplierData.address === "") {
                        isValidAddress = false;
                        console.log("invalid address");
                    }
                    if (editSupplierData.city === "") {
                        isValidCity = false;
                        console.log("invalid city");
                    }
                    if (editSupplierData.postalCode === "") {
                        isValidPC = false;
                        console.log("invalid pc");
                    }
                }

                // Do not show if Supplier Category is "Supplier within E.U." or "Supplier outside E.U."
                if (editSupplierData.vatCategory === '') {
                    isValidVatCategory = false
                    console.log('invalid category');
                }
                if (editSupplierData.vatCategory === 'Zero VAT' && editSupplierData.vatReason === '') {
                    isValidVatReason = false
                    console.log('invalid Reason');
                }

                if (editSupplierData.vatCategory === 'Custom VAT' && editSupplierData.customVat === '' && editSupplierData.customVat <= 100) {
                    isValidVatReason = false
                    console.log('invalid Custom Vat');
                }

                if (editSupplierData.email === "" && editSupplierData.phone === "" && editSupplierData.categoryName !== "Patient" && editSupplierData.categoryName !== "I.K.E." && editSupplierData.categoryName !== "Freelancer" && editSupplierData.categoryName !== "O.E." && editSupplierData.categoryName !== "E.E." && editSupplierData.categoryName !== "A.E." && editSupplierData.categoryName !== "Non-Profit Organization" && editSupplierData.categoryName !== "E.P.E." && editSupplierData.categoryName !== "Wholesale Others") {
                    isValidEmailPhone = false;
                    console.log("invalid email or phone");
                }

                if (editSupplierData.email) {
                    if (!isValidEmail(editSupplierData.email)) {
                        isValidEmailPhone = false;
                        toast.error("Invalid Email address.");
                    }
                }

                if (editSupplierData.category === "" || editSupplierData.name === "" || !isValidVatNumber || !isValidDOY || !isValidActivity || !isValidCountry || !isValidAddress || !isValidCity || !isValidPC || !isValidEmailPhone || !isValidVatCategory || !isValidVatReason || !isValidCustomVat) {
                    toast.error("Please check all mandatory fields.");
                    validCheck = false;
                }

                // Check if contact points empty
                if (SUPPLIERS_DATA.editSupplier.contactPoints.length > 0 && validCheck) {
                    let emptyCps = getObjectFromArrayOfObjects(SUPPLIERS_DATA.editSupplier.contactPoints, "", "name");
                    if (emptyCps && emptyCps.length > 0) {
                        validCheck = false;
                        toast.error("Please check table mandatory fields.");
                    }
                }

                if (SUPPLIERS_DATA.editSupplier.installations.length > 0 && validCheck) {
                    let emptyCps = getObjectFromArrayOfObjects(SUPPLIERS_DATA.editSupplier.installations, '', 'name');
                    if (emptyCps && emptyCps.length > 0) {
                        validCheck = false;
                        toast.error('Please check table mandatory fields.');
                    }
                    SUPPLIERS_DATA.editSupplier.installations.map((installation) => {
                        if (installation.vatCategory === 'Custom VAT' && installation.customVat === '' && installation.customVat <= 100) {
                            validCheck = false;
                            toast.error('Please Select a custom VAT percentage');
                        }
                        if (installation.vatCategory === 'Zero VAT' && installation.vatReason === '') {
                            validCheck = false;
                            toast.error('Please Select a VAT exemption reason');
                        }
                        return installation;
                    })
                }

                if (validCheck) {
                    // Supplier Data
                    let postSupplierData = {...SUPPLIERS_DATA.editSupplier, ...requestData};
                    postSupplierData.isActive = SUPPLIERS_DATA.editSupplier.isActive; // set status

                    // Supplier Emron Data
                    let postSupplierEmronData = {
                        ...SUPPLIERS_DATA.supplierEmron, ...requestData,
                    };
                    postSupplierEmronData.isActive = SUPPLIERS_DATA.supplierEmron.isActive; // set status

                    setLoadingSave(true);
                    setTimeout(() => {
                        setLoadingSave(false);
                    }, 2000);

                    dispatch(putSupplier(postSupplierData, requestData, "update", {}, true)); // Update Supplier To API
                    // dispatch(postSupplier(postSupplierData, requestData, postSupplierEmronData, true)) // Post Supplier To API
                }
            }

            // Save Client Settings
            else if (templateType === "clientsSettings") {
                setLoadingSave(true);
                let postClientsSetting = {...CLIENTS_DATA.clientsSettings, ...requestData}
                dispatch(postClientsSettings(postClientsSetting, requestData, setLoadingSave)); // Post Client Settings To API
            }

            // Save Supplier Settings
            else if (templateType === "suppliersSettings") {
                setLoadingSave(true);
                setTimeout(() => {
                    setLoadingSave(false);
                }, 2000);

                let postSuppliersSetting = {
                    ...SUPPLIERS_DATA.suppliersSettings, ...requestData,
                };
                dispatch(postSuppliersSettings(postSuppliersSetting, requestData)); // Post Supplier Settings To API
            }
            // Save Client Movements
            else if (templateType === "clientMovements") {
                let validCheck = true;
                if ((!CLIENTS_DATA.newClientMovements.date || new Date(CLIENTS_DATA.newClientMovements.date).getFullYear() !== company.year) && validCheck) {
                    toast.error("Please select a date included in the current Year.");
                    validCheck = false;
                }
                if (CLIENTS_DATA.newClientMovements.clients.length === 0) {
                    toast.error("Please select at least one client.");
                    validCheck = false;
                }
                if (CLIENTS_DATA.newClientMovements.clients.length > 0) {
                    CLIENTS_DATA.newClientMovements.clients.forEach(e => {
                        if (Number(e.amount) === 0 || e.amount === null || e.amount === undefined) {
                            toast.error("Each client should have a amount greater than zero.");
                            validCheck = false;
                        }
                    });
                }
                if (validCheck) {
                    setLoadingSave(true);
                    setTimeout(() => {
                        setLoadingSave(false);
                    }, 2000);
                    let postClientMovementsData = {...CLIENTS_DATA.newClientMovements, ...requestData};
                    dispatch(postClientMovements(postClientMovementsData, requestData)); // Post Client Movement To API
                }
            } else if (templateType === "editClientMovements") {
                let validCheck = true;
                if ((!CLIENTS_DATA.editClientMovements.date || new Date(CLIENTS_DATA.editClientMovements.date).getFullYear() !== company.year) && validCheck) {
                    toast.error("Please select a date included in the current Year.");
                    validCheck = false;
                }
                if (CLIENTS_DATA.editClientMovements.clients.length === 0) {
                    toast.error("Please select at least one client.");
                    validCheck = false;
                }
                if (CLIENTS_DATA.editClientMovements.clients.length > 0) {
                    CLIENTS_DATA.editClientMovements.clients.forEach(e => {
                        if (Number(e.amount) === 0 || e.amount === null || e.amount === undefined) {
                            toast.error("Each client should have a amount greater than zero.");
                            validCheck = false;
                        }
                    });
                }
                if (validCheck) {
                    setLoadingSave(true);
                    setTimeout(() => {
                        setLoadingSave(false);
                    }, 2000);
                    let postClientMovementsData = {...CLIENTS_DATA.editClientMovements, ...requestData};
                    dispatch(putClientMovements(postClientMovementsData, requestData)); // Post Client Movement To API
                }
            } else if (templateType === "supplierMovements") {
                let validCheck = true;
                console.log(new Date(SUPPLIERS_DATA.newSupplierMovements.date))
                if ((!SUPPLIERS_DATA.newSupplierMovements.date || new Date(SUPPLIERS_DATA.newSupplierMovements.date).getFullYear() !== company.year) && validCheck) {
                    toast.error("Please select a date included in the current Year.");
                    validCheck = false;
                }
                if (SUPPLIERS_DATA.newSupplierMovements.suppliers.length === 0) {
                    toast.error("Please select at least one supplier.");
                    validCheck = false;
                }
                if (SUPPLIERS_DATA.newSupplierMovements.suppliers.length > 0) {
                    SUPPLIERS_DATA.newSupplierMovements.suppliers.forEach(e => {
                        if (Number(e.amount) === 0 || e.amount === null || e.amount === undefined) {
                            toast.error("Each supplier should have a balance greater than zero.");
                            validCheck = false;
                        }
                    });
                }
                if (validCheck) {
                    setLoadingSave(true);
                    setTimeout(() => {
                        setLoadingSave(false)
                    }, 2000);
                    let postSupplierMovementsData = {...SUPPLIERS_DATA.newSupplierMovements, ...requestData};
                    dispatch(postSupplierMovements(postSupplierMovementsData, requestData)); // Post Supplier Movement To API
                    dispatch(setNewSupplierMovements(SUPPLIERS_DATA.supplierMovementsInitialValues)); // Reset supplier form
                }
            } else if (templateType === "editSupplierMovements") {
                let validCheck = true;
                if ((!SUPPLIERS_DATA.editSupplierMovements.date || new Date(SUPPLIERS_DATA.editSupplierMovements.date).getFullYear() !== company.year) && validCheck) {
                    toast.error("Please select a date included in the current Year.");
                    validCheck = false;
                }
                if (SUPPLIERS_DATA.editSupplierMovements.suppliers.length === 0) {
                    toast.error("Please select at least one supplier.");
                    validCheck = false;
                }
                if (SUPPLIERS_DATA.editSupplierMovements.suppliers.length > 0) {
                    SUPPLIERS_DATA.editSupplierMovements.suppliers.forEach(e => {
                        if (Number(e.amount) === 0 || e.amount === null || e.amount === undefined) {
                            toast.error("Each supplier should have a balance greater than zero.");
                            validCheck = false;
                        }
                    });
                }
                if (validCheck) {
                    setLoadingSave(true);
                    setTimeout(() => {
                        setLoadingSave(false)
                    }, 2000);
                    let postSupplierMovementsData = {...SUPPLIERS_DATA.editSupplierMovements, ...requestData};
                    dispatch(putSupplierMovements(postSupplierMovementsData, requestData)); // Post Supplier Movement To API
                }
            }
            // Save My Data Data Taxes
            else if (templateType === "settingsPrimeData") {
                if (SETTINGS.primerMyDataData) {
                    let pMDDSettings = Object.assign({}, SETTINGS.primerMyDataData);
                    if ("_id" in pMDDSettings) {
                        delete pMDDSettings._id;
                    }
                    if ("__v" in pMDDSettings) {
                        delete pMDDSettings.__v;
                    }
                    const currentUser = getCurrentUser();
                    const cUserID = currentUser._id;
                    let pMDDRequestData = {
                        year: requestData?.year, company: requestData?.company, userId: cUserID
                    };
                    pMDDSettings = {...pMDDSettings, ...pMDDRequestData};
                    dispatch(putPrimerMyDataData(pMDDSettings, pMDDRequestData));
                }
            }
            //Save email SMTP
            else if (templateType === "settingsEmailSmtp") {
                if (SETTINGS.emailSmtp) {
                    let smtpSettings = Object.assign({}, SETTINGS.emailSmtp);
                    if (smtpSettings.smtpOption !== "" && smtpSettings.username !== "" && smtpSettings.password !== "" && smtpSettings.encryptionType !== "" && smtpSettings.smtpAuthentication !== "" && smtpSettings.subject !== "" && smtpSettings.smtpAuthentication !== "") {

                    }
                    if ("_id" in smtpSettings) {
                        delete smtpSettings._id;
                    }

                    if ("__v" in smtpSettings) {
                        delete smtpSettings.__v;
                    }
                    let pMDDRequestData = {
                        company: requestData?.company,
                    };
                    smtpSettings = {...smtpSettings, ...pMDDRequestData};
                    dispatch(putCompanyEmailSmtp(smtpSettings, pMDDRequestData));
                }
            }

            // Save Cash Registers
            else if (templateType === "cashRegisters") {
                let validCheck = true;
                const newCashRegistersData = CASH_DATA.newCashRegisters;

                if (newCashRegistersData.name === "") {
                    toast.error("Name should not be empty.");
                    validCheck = false;
                }

                if (newCashRegistersData.date === "" || newCashRegistersData.date === null) {
                    toast.error("Date should not be empty.");
                    validCheck = false;
                }

                if (newCashRegistersData.serialNumber === "") {
                    toast.error("Serial Number should not be empty.");
                    validCheck = false;
                }

                if (validCheck && templateType === "cashRegisters") {
                    let postCashRegisterData = {
                        ...CASH_DATA.newCashRegisters, ...requestData,
                    };
                    postCashRegisterData.isActive = CASH_DATA.newCashRegisters.isActive; // set status

                    if (action === "save") {
                        // Clear for only on save not saveandrepeat

                        setLoadingSave(true);
                        setTimeout(() => {
                            setLoadingSave(false);
                        }, process.env.BUTTONLOADERTIME ? process.env.BUTTONLOADERTIME : 2000);

                        dispatch(setNewCashRegisters(CASH_DATA.newCashRegistersInitialValue)); // Reset form
                        if (postCashRegisterData.isActive === "draft") {
                            dispatch(putCashRegisters(postCashRegisterData, requestData)); // Update To API
                            dispatch(setSaveEditCashRegister(false)); // Set save status false
                        } else {
                            dispatch(postCashRegisters(postCashRegisterData, requestData)); // Post To API
                            dispatch(setSaveNewCashRegister(false)); // Set save status false
                        }
                    }
                    if (action === "saveandrepeat") {
                        setLoadingSaveAndRepeat(true);
                        setTimeout(() => {
                            setLoadingSaveAndRepeat(false);
                        }, 2000);

                        postCashRegisterData.isActive = "draft";
                        dispatch(postCashRegisters(postCashRegisterData, requestData)); // Post To API
                        dispatch(setSaveNewCashRegister(false)); // Set save status false
                    }
                }

                if (validCheck && templateType === "editCashRegisters" && action === "save") {
                    let postCashRegisterData = {
                        ...CASH_DATA.newCashRegisters, ...requestData,
                    };
                    postCashRegisterData.isActive = CASH_DATA.newCashRegisters.isActive; // set status
                    dispatch(putCashRegisters(postCashRegisterData, requestData)); // post To API
                    dispatch(setSaveEditCashRegister(false)); // Set save status false
                }
            }

            // Update Cash Registers
            else if (templateType === "editCashRegisters") {
                let validCheck = true;
                const editCashRegistersData = CASH_DATA.editCashRegisters;

                if (editCashRegistersData.name === "") {
                    toast.error("Name should not be empty.");
                    validCheck = false;
                }

                if (editCashRegistersData.date === "" || editCashRegistersData.date === null) {
                    toast.error("Date should not be empty.");
                    validCheck = false;
                }

                if (editCashRegistersData.serialNumber === "") {
                    toast.error("Serial Number should not be empty.");
                    validCheck = false;
                }

                if (validCheck && templateType === "editCashRegisters" && action === "save") {
                    setLoadingSave(true);
                    setTimeout(() => {
                        setLoadingSave(false);
                    }, 2000);

                    let editCashRegisterData = {
                        ...CASH_DATA.editCashRegisters, ...requestData,
                    };
                    editCashRegisterData.isActive = CASH_DATA.editCashRegisters.isActive; // set status
                    dispatch(putCashRegisters(editCashRegisterData, requestData)); // put To API
                    dispatch(setSaveEditCashRegister(false)); // Set save status false
                }
            }

            // Save Bank Accounts
            else if (templateType === "bankAccounts") {
                let validCheck = true;
                const newBankAccountData = CASH_DATA.newBankAccount;

                if (newBankAccountData.name === "") {
                    toast.error("Name should not be empty.");
                    validCheck = false;
                }

                if (newBankAccountData.date === "" || newBankAccountData.date === null) {
                    toast.error("Date should not be empty.");
                    validCheck = false;
                }

                if (newBankAccountData.bank === "") {
                    toast.error("Bank Number should not be empty.");
                    validCheck = false;
                }

                if (newBankAccountData.iban === "") {
                    toast.error("IBAN Number should not be empty.");
                    validCheck = false;
                }

                if (validCheck && templateType === "bankAccounts") {
                    let postBankAccountData = {
                        ...CASH_DATA.newBankAccount, ...requestData,
                    };
                    postBankAccountData.isActive = CASH_DATA.newBankAccount.isActive; // set status
                    if (action === "save") {
                        // Clear for only on save not saveandrepeat

                        setLoadingSave(true);
                        setTimeout(() => {
                            setLoadingSave(false);
                        }, process.env.BUTTONLOADERTIME ? process.env.BUTTONLOADERTIME : 2000);

                        dispatch(setNewBankAccount(CASH_DATA.newBankAccountInitialValue)); // Reset form
                        if (postBankAccountData.isActive === "draft") {
                            dispatch(putBankAccounts(postBankAccountData, requestData)); // Update To API
                            dispatch(setSaveEditBankAccounts(false)); // Set save status false
                        } else {
                            dispatch(postBankAccounts(postBankAccountData, requestData)); // Update To API
                            dispatch(setSaveNewBankAccounts(false)); // Set save status false
                        }
                    }
                    if (action === "saveandrepeat") {
                        setLoadingSaveAndRepeat(true);
                        setTimeout(() => {
                            setLoadingSaveAndRepeat(false);
                        }, 2000);

                        postBankAccountData.isActive = "draft";
                        dispatch(postBankAccounts(postBankAccountData, requestData)); // Post To API
                        dispatch(setSaveNewBankAccounts(false)); // Set save status false
                    }
                }

                if (validCheck && templateType === "editBankAccounts" && action === "save") {
                    let postBankAccountData = {
                        ...CASH_DATA.newBankAccount, ...requestData,
                    };
                    postBankAccountData.isActive = CASH_DATA.newBankAccount.isActive; // set status
                    dispatch(putBankAccounts(postBankAccountData, requestData)); // Update To API
                    dispatch(setSaveEditBankAccounts(false)); // Set save status false
                }
            }

            // Update Bank Accounts
            else if (templateType === "editBankAccounts") {
                let validCheck = true;
                const editBankAccountData = CASH_DATA.editBankAccount;

                if (editBankAccountData.name === "") {
                    toast.error("Name should not be empty.");
                    validCheck = false;
                }

                if (editBankAccountData.date === "" || editBankAccountData.date === null) {
                    toast.error("Date should not be empty.");
                    validCheck = false;
                }

                if (editBankAccountData.bank === "") {
                    toast.error("Bank Number should not be empty.");
                    validCheck = false;
                }

                if (editBankAccountData.iban === "") {
                    toast.error("IBAN Number should not be empty.");
                    validCheck = false;
                }

                if (validCheck && templateType === "editBankAccounts" && action === "save") {
                    let editBankAccountData = {
                        ...CASH_DATA.editBankAccount, ...requestData,
                    };
                    editBankAccountData.isActive = CASH_DATA.editBankAccount.isActive;
                    dispatch(putBankAccounts(editBankAccountData, requestData));
                    dispatch(setSaveEditBankAccounts(false));
                }
            }

            else if (templateType === "cashAccountingSettings") {
                const postData = {...CASH_DATA.cashSettings, company: requestData.company}
                dispatch(postCashSettings(postData));
                dispatch(setSaveCashAccountingSettings(false));
            }

            // Save POS
            else if (templateType === "POS") {
                let validCheck = true;
                const newPOSData = CASH_DATA.newPOS;

                if (newPOSData.name === "") {
                    toast.error("Name should not be empty.");
                    validCheck = false;
                }

                if (newPOSData.date === "" || newPOSData.date === null) {
                    toast.error("Date should not be empty.");
                    validCheck = false;
                }

                if (newPOSData.serialNumber === "") {
                    toast.error("Serial Number should not be empty.");
                    validCheck = false;
                }

                if (newPOSData.isETameiaki === "active" && isEmpty(newPOSData.tameiakiUsername)) {
                    toast.error("Username cannot be empty.");
                    validCheck = false;
                }

                if (newPOSData.isETameiaki === "active" && isEmpty(newPOSData.tameiakiPassword)) {
                    toast.error("Password cannot be empty.");
                    validCheck = false;
                }

                if (newPOSData.isETameiaki === "active" && isEmpty(newPOSData.tid)) {
                    toast.error("TID cannot be empty.");
                    validCheck = false;
                }

                if (validCheck && templateType === "POS") {
                    let postPOSData = {...CASH_DATA.newPOS, ...requestData};
                    postPOSData.isActive = CASH_DATA.newPOS.isActive; // set status
                    if (action === "save") {
                        // Clear for only on save not saveandrepeat
                        setLoadingSave(true);
                        dispatch(postPOS(postPOSData, requestData, setLoadingSave)); // Update To API
                        dispatch(setSaveNewPOS(false)); // Set save status false
                    }
                }
            }

            // Update POS
            else if (templateType === "editPOS") {
                let validCheck = true;
                const editPOSData = CASH_DATA.editPOS;

                if (editPOSData.name === "") {
                    toast.error("Name should not be empty.");
                    validCheck = false;
                }

                if (editPOSData.date === "" || editPOSData.date === null) {
                    toast.error("Date should not be empty.");
                    validCheck = false;
                }

                if (editPOSData.serialNumber === "") {
                    toast.error("Serial Number should not be empty.");
                    validCheck = false;
                }

                if (editPOSData.isETameiaki === "active" && isEmpty(editPOSData.tameiakiUsername)) {
                    toast.error("Username cannot be empty.");
                    validCheck = false;
                }

                if (editPOSData.isETameiaki === "active" && isEmpty(editPOSData.tameiakiPassword)) {
                    toast.error("Password cannot be empty.");
                    validCheck = false;
                }

                if (editPOSData.isETameiaki === "active" && isEmpty(editPOSData.tid)) {
                    toast.error("TID cannot be empty.");
                    validCheck = false;
                }

                if (validCheck && templateType === "editPOS" && action === "save") {
                    let editPOSData = {...CASH_DATA.editPOS, ...requestData};
                    editPOSData.isActive = CASH_DATA.editPOS.isActive; // set status
                    setLoadingSave(true);
                    dispatch(putPOS(editPOSData, requestData, setLoadingSave)); // Update To API
                    dispatch(setSaveEditPOS(false)); // Set save status false
                }
            }

            // Save Sales General and Email
            else if (templateType === "salesGeneralSettings") {
                let validCheck = true;
                const salesGeneralSettingsData = SALES_SETTINGS.salesGeneralSettings;

                if (salesGeneralSettingsData.defaultDocumentType === "") {
                    toast.error("Default Document Type should not be empty.");
                    validCheck = false;
                }
                if (salesGeneralSettingsData?.defaultClientPaymentForPOS && isEmpty(salesGeneralSettingsData.defaultClientPaymentForPOSPreNumber)) {
                    toast.error("Η σειρά προκαθορισμένης είσπραξης για πληρωμές με POS δεν μπορεί να είναι άδεια.");
                    validCheck = false;
                }
                if (validCheck) {
                    let postSalesGeneralSettingsData = {...SALES_SETTINGS.salesGeneralSettings, ...requestData}
                    if (postSalesGeneralSettingsData._id) {
                        dispatch(putSalesGeneralSettings(postSalesGeneralSettingsData, requestData)); // Update To API
                    } else {
                        dispatch(postSalesGeneralSettings(postSalesGeneralSettingsData, requestData)); // Add To API
                    }
                    dispatch(setSaveSalesSettings(false)); // Set save status false
                }
            }

            // Save Buys General and Email
            else if (templateType === "buysGeneralSettings") {
                let validCheck = true;
                const buysGeneralSettingsData = BUYS_SETTINGS.buysGeneralSettings;

                if (buysGeneralSettingsData.defaultDocumentType === "") {
                    toast.error("Default Document Type should not be empty.");
                    validCheck = false;
                }

                if (validCheck) {
                    let postBuysGeneralSettingsData = {
                        ...BUYS_SETTINGS.buysGeneralSettings, ...requestData,
                    };
                    if (postBuysGeneralSettingsData._id) {
                        dispatch(putBuysGeneralSettings(postBuysGeneralSettingsData, requestData)); // Update To API
                    } else {
                        dispatch(postBuysGeneralSettings(postBuysGeneralSettingsData, requestData)); // Add To API
                    }
                    dispatch(setSaveBuysSettings(false)); // Set save status false
                }
            }

            // Save Sales Quick Retails Settings
            else if (templateType === "settingsQuickRetailSales") {
                let validCheck = true;
                const quickRetailSettingsData = SALES_SETTINGS.quickRetailSettings;

                if (quickRetailSettingsData.defaultDocumentType === "") {
                    toast.error("Default Document Type should not be empty.");
                    validCheck = false;
                }

                if (validCheck) {
                    let postQuickRetailSalesSettingsData = {
                        ...SALES_SETTINGS.quickRetailSettings, ...requestData,
                    };
                    dispatch(postQuickRetailSalesSettings(postQuickRetailSalesSettingsData, requestData)); // Update To API
                    dispatch(setSaveQuickRetailSettings(false)); // Set save status false
                }
            }

            // Save Automatic Sales
            else if (templateType === "automaticSales") {
                setLoadingSave(true);
                setTimeout(() => {
                    setLoadingSave(false);
                }, 2000);

                let postAutomaticSalesData = {
                    ...AUTOMATIC_SALES.newAutomaticSales, ...requestData,
                };
                dispatch(postAutomaticSales(postAutomaticSalesData, requestData)); // Post Client Movement To API
                dispatch(resetNewAutomaticSales()); // Reset client form
            }

            // Save Sales
            else if (templateType === "sales") {
                dispatch(setSaveSales(false));
                const newSalesData = {...SALES.newSales};
                let validCheck = true;
                if (newSalesData.newSalesData === "") {
                    toast.error("Document Type should not be empty.");
                    validCheck = false;
                }
                if ((newSalesData.clientType === "Existing" && newSalesData.client === "") || (newSalesData.clientType === "New" && newSalesData.newClient === "")) {
                    toast.error("Client field should not be empty.");
                    validCheck = false;
                }
                if (SALES.newSales.paymentTypeName === "") {
                    const selectedDocumentTypeData = getObjectFromArrayOfObjects(SETTINGS.settings.advancedTypesSales, newSalesData.documentType, "_id");
                    if (selectedDocumentTypeData.length > 0) {
                        let selectedDTData = selectedDocumentTypeData['0'];
                        if (selectedDTData.enableMyDataRules && selectedDTData.enableMyDataRules === 'active' && SALES.newSales.paymentTypeName === "") {
                            toast.error("Payment Type field should not be empty.");
                            validCheck = false;
                        }
                    }
                }
                let paymentTypesData = getObjectFromArrayOfObjects(SALES_SETTINGS.salesGeneralSettings.paymentTypes, SALES.newSales.paymentType, '_id');
                if (paymentTypesData && paymentTypesData.length > 0) {
                    if ((paymentTypesData['0']?.type === "bank-account" || paymentTypesData['0']?.type === "pos"
                        || paymentTypesData['0']?.type === "bank-account-outside" || paymentTypesData['0']?.type === "web-banking") && SALES.newSales.bankAccount === "") {
                        toast.error("POS/Bank Account field should not be empty.");
                        validCheck = false;
                    }
                }
                if (newSalesData.clientType === "New" && (newSalesData.email === "" || newSalesData.email === "")) {
                    toast.error("Please add Email or Phone number.");
                    validCheck = false;
                }

                if (newSalesData.products && newSalesData.products?.length === 0 && validCheck) {
                    toast.error("Please add at least one product.");
                    validCheck = false;
                }

                if ((!newSalesData.installation || newSalesData.installation === "") && validCheck) {
                    toast.error("Please add related installation for this invoice.");
                    validCheck = false;
                }
                if ((!newSalesData.preNumber || newSalesData.preNumber === "") && validCheck) {
                    toast.error("Please add PreNumber for this invoice.");
                    validCheck = false;
                }
                if ((!newSalesData.number || newSalesData.number === "") && validCheck) {
                    toast.error("Please add Number for this invoice.");
                    validCheck = false;
                }
                if ((!newSalesData.date || newSalesData.date.getFullYear() !== company.year) && validCheck) {
                    toast.error("Please select a date included in the current Year.");
                    validCheck = false;
                }
                if (newSalesData.products.length > 0 && newSalesData.myDataType !== "9.3") {
                    newSalesData.products.map(product => {
                        if (Number(product.unitPrice) === 0) {
                            toast.error("Please enter a unit price greater than 0 in all products.");
                            validCheck = false;
                        }
                        return product;
                    })
                }
                if(newSalesData.salePurpose === "19" && (!newSalesData.salePurposeName || String(newSalesData.salePurposeName).trim() === "")) {
                    validCheck = false;
                    toast.error("Η περιγραφή σκοπού διακίνησης δεν μπορεί να είναι άδεια.");
                }
                if(newSalesData.myDataType === "9.3") {
                    newSalesData.products.map((product) => {
                        if(Number(product.unitPrice) !== 0) {
                            toast.error("Το δελτίο διακίνησης/αποστολής πρέπει να έχει μηδενικές τιμές στα είδη.");
                            validCheck = false;
                        }
                    })
                }
                if(newSalesData.isDeliveryNote === true && isEmpty(newSalesData.salePurpose) && (newSalesData.myDataType === "1.1" || newSalesData.myDataType === "1.2" || newSalesData.myDataType === "1.3")) {
                    validCheck = false;
                    toast.error("Παρακαλώ επιλέξτε σκοπό διακίνησης.");
                }
                if ((newSalesData?.myDataType === "1.6" || newSalesData?.myDataType === "2.4" || newSalesData?.myDataType === "5.1") && isEmpty(newSalesData.corelatedDocument)) {
                    validCheck = false;
                    toast.error("Παρακαλώ επιλέξτε συσχετιζόμενο παραστατικό.");
                }
                if (newSalesData.enablePeppol === "active") {
                    if (isEmpty(newSalesData?.peppol?.partyIdentification) || (isEmpty(newSalesData?.peppol?.projectReference) && newSalesData?.myDataType !== "5.1")
                        //|| isEmpty(newSalesData?.peppol?.documentMeasurementUnit)
                    ) {
                        validCheck = false;
                        toast.error("Παρακαλώ ελέγξτε τα πεδία της δημόσιας σύμβασης.");
                    } else if (!newSalesData.products.every((el) => !isEmpty(el.cpvCode))) {
                        validCheck = false;
                        toast.error("Τα είδη πρέπει να έχουν όλα CPV.");
                    }
                }
                if (newSalesData.enablePeppol === "active" && newSalesData.extraData.deliveryLocation === "custom"
                    && (isEmpty(newSalesData.extraData.locationAddress) || isEmpty(newSalesData.extraData.locationCountry) || isEmpty(newSalesData.extraData.locationCity)
                        || isEmpty(newSalesData.extraData.locationZip) || isEmpty(newSalesData.extraData.locationVatNumber) || isEmpty(newSalesData.extraData.locationCompanyName))) {
                    validCheck = false;
                    toast.error("Παρακαλώ συμπληρώστε όλα τα πεδία τόπου παράδοσης.");
                }
                if (!validCheck) dispatch(setSaveSales(true));

                if (action === "save" && validCheck) {
                    // Clear for only on save not saveandrepeat
                    let postSalesData = {...SALES.newSales, ...requestData};
                    const advDoc = SETTINGS.settings.advancedTypesSales.filter((doc) => doc._id === postSalesData.documentType);
                    if (postSalesData.customTime !== "true") {
                        let date = new Date();
                        postSalesData.date = date;
                        postSalesData.time = date.getHours() + ':' + String(date.getMinutes()).padStart(2, '0');
                    }
                    if (newSalesData.myDataType !== "1.1" && newSalesData.myDataType !== "1.2" && newSalesData.myDataType !== "1.3") {
                        delete postSalesData.isDeliveryNote
                    }
                    if (postSalesData.numberingMode === "automatic") {
                        let checkAtParams = {};
                        checkAtParams.id = !postSalesData.documentType ? postSalesData.newSales.documentType : postSalesData.documentType;
                        checkAtParams.preNumber = !postSalesData.preNumber ? postSalesData.newSales.preNumber : postSalesData.preNumber;
                        checkAtParams.installation = !postSalesData.installation ? postSalesData.newSales?.installation?.toString() : postSalesData?.installation?.toString();
                        if (checkAtParams.id && checkAtParams.preNumber && checkAtParams.installation) {
                            await axios.post(apiURL2 + `/settings/add-setting-advance-type-check-sale`, JSON.stringify(checkAtParams), {headers: {'Content-Type': 'application/json'}})
                                .then(res => {
                                    if (res.data && res.data.status === '200') {
                                        if (res.data.number !== undefined) {
                                            postSalesData.number = parseInt(res.data.number) + 1;
                                        }
                                    }
                                })
                        }
                    }
                    let printAfterSave = advDoc[0] && advDoc[0]?.printAfterSave === "active";
                    if (postSalesData._id) {
                        dispatch(putSales(postSalesData, requestData, setLoadingSave)); // Post to API
                    } else {
                        if (SALES.newSales.myDataPaymentId === "7" && !isEmpty(SALES.newSales.myDataType) && advDoc[0]?.mydataProvider === "active" && SALES.newSales?.myDataType !== "5.1"
                        && SALES.newSales?.myDataType !== "5.2" && SALES.newSales?.myDataType !== "11.4") {
                            const findPOS = CASH_DATA.posList.find((el) => el._id === SALES.newSales.bankAccount);
                            if(findPOS && findPOS.isETameiaki === "active") {
                                setShowPOSModal(true);
                                setShowPOSModalText("Η πληρωμή στάλθηκε. Παρακαλώ ελέγξτε το POS.")
                                try {
                                    const request = await axios.post(process.env.REACT_APP_API_URL2 + "/sale/pos-payment", {
                                        company: company.id,
                                        pos: findPOS._id,
                                        amount: SALES.newSales.paymentAmount,
                                        transactionAmount: SALES.newSales.paymentAmount,
                                        netValue: SALES.newSales.totalNetValue,
                                        vatValue: SALES.newSales.totalVatValue,
                                        myDataType: SALES.newSales.myDataType,
                                        preNumber: SALES.newSales.preNumber,
                                        number: SALES.newSales.number,
                                        installation: postSalesData.installation,
                                        tipAmount: SALES.newSales.tipAmount,
                                        saleObject: postSalesData,
                                    });
                                    setShowPOSModal(false);
                                    setShowPOSModalText("");
                                    if (request.data && request.data.status === "200") {
                                        toast.success(request.data.message);
                                        dispatch(setSaveSales(false));
                                        if (request.data.saleData) {
                                            await dispatch(setNewSales(request.data.saleData));
                                            if (printAfterSave === true) await handleDpActions("printOnSave");
                                            if (ACTIVITY?.sendEmail === "active") await handleDpActions("sendEmailOnSave", request.data.saleData);
                                        }
                                        setTimeout(() => {
                                            dispatch(resetNewSales());
                                        }, 100)
                                    } else {
                                        console.log(request.data.message);
                                        toast.error(request.data.message, {autoClose: 15000});
                                        dispatch(setSaveSales(true));
                                    }
                                } catch (error) {
                                    toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
                                    dispatch(setSaveSales(true));
                                }
                                return;
                            }
                        }
                        dispatch(postSales(postSalesData, requestData, handleDpActions, printAfterSave, ACTIVITY?.sendEmail, setLoadingSave, SETTINGS.settings.advancedTypesSales)); // Post to API
                    }
                }

                if (action === "saveandrepeat" && validCheck) {
                    let postSalesData = {...SALES.newSales, ...requestData};
                    postSalesData.isActive = "draft";
                    if (postSalesData.customTime !== "true") {
                        let date = new Date();
                        postSalesData.date = date;
                        postSalesData.time = date.getHours() + ':' + String(date.getMinutes()).padStart(2, '0');
                    }
                    if (newSalesData.myDataType !== "1.1" && newSalesData.myDataType !== "1.2" && newSalesData.myDataType !== "1.3") {
                        delete postSalesData.isDeliveryNote
                    }
                    if (postSalesData.numberingMode === "automatic") {
                        let checkAtParams = {};
                        checkAtParams.id = !postSalesData.documentType ? postSalesData.newSales.documentType : postSalesData.documentType;
                        checkAtParams.preNumber = !postSalesData.preNumber ? postSalesData.newSales.preNumber : postSalesData.preNumber;
                        checkAtParams.installation = !postSalesData.installation ? postSalesData.newSales?.installation?.toString() : postSalesData?.installation?.toString();
                        if (checkAtParams.id && checkAtParams.preNumber && checkAtParams.installation) {
                            await axios.post(apiURL2 + `/settings/add-setting-advance-type-check-sale`, JSON.stringify(checkAtParams), {headers: {'Content-Type': 'application/json'}})
                                .then(res => {
                                    if (res.data && res.data.status === '200') {
                                        if (res.data.number !== undefined) {
                                            postSalesData.number = parseInt(res.data.number) + 1;
                                        }
                                    }
                                })
                        }
                    }
                    const advDoc = SETTINGS.settings.advancedTypesSales.filter((doc) => doc._id === postSalesData.documentType);
                    let printAfterSave = advDoc[0] && advDoc[0]?.printAfterSave === "active";
                    if (postSalesData._id) {
                        dispatch(putSales(postSalesData, requestData, handleDpActions, printAfterSave, setLoadingSaveAndRepeat)); // Post to API
                    } else {
                        // Show print preview on save
                        dispatch(postSales(postSalesData, requestData, handleDpActions, printAfterSave, ACTIVITY?.sendEmail, setLoadingSaveAndRepeat, SETTINGS.settings.advancedTypesSales)); // Post to API
                    }
                }
            }

            // Save Buys
            else if (templateType === "buys") {
                let validCheck = true;
                const newBuysData = BUYS.newBuys;

                if (newBuysData.newBuysData === "") {
                    toast.error("Document Type should not be empty.");
                    validCheck = false;
                }

                if (isEmpty(newBuysData.date)) {
                    toast.error("Date cannot be empty.");
                    validCheck = false;
                }

                if ((newBuysData.supplierType === "Existing" && newBuysData.supplier === "") || (newBuysData.supplierType === "New" && newBuysData.newSupplier === "")) {
                    toast.error("Supplier field should not be empty.");
                    validCheck = false;
                }
                if (BUYS.newBuys.paymentTypeName === "") {
                    const selectedDocumentTypeData = getObjectFromArrayOfObjects(SETTINGS.settings.advancedTypesBuys, newBuysData.documentType, "_id");
                    if (selectedDocumentTypeData.length > 0) {
                        let selectedDTData = selectedDocumentTypeData['0'];
                        if (selectedDTData.enableMyDataRules && selectedDTData.enableMyDataRules === 'active' && BUYS.newBuys.paymentTypeName === "") {
                            toast.error("Payment Type field should not be empty.");
                            validCheck = false;
                        }
                    }
                }
                if (newBuysData.supplierType === "New" && (newBuysData.email === "" || newBuysData.email === "")) {
                    toast.error("Please add Email or Phone number.");
                    validCheck = false;
                }

                if (newBuysData.products && newBuysData.products?.length === 0 && validCheck) {
                    toast.error("Please add at least one product.");
                    validCheck = false;
                }

                if ((!newBuysData.installation || newBuysData.installation === "") && validCheck) {
                    toast.error("Please add related installation for this invoice.");
                    validCheck = false;
                }
                if ((!newBuysData.preNumber || newBuysData.preNumber === "") && validCheck) {
                    toast.error("Please add PreNumber for this invoice.");
                    validCheck = false;
                }
                if ((!newBuysData.number || newBuysData.number === "") && validCheck) {
                    toast.error("Please add Number for this invoice.");
                    validCheck = false;
                }
                if ((!newBuysData.date || newBuysData.date.getFullYear() !== company.year) && validCheck) {
                    toast.error("Please select a date included in the current Year.");
                    validCheck = false;
                }
                if (action === "save" && validCheck) {
                    // Clear for only on save not saveandrepeat
                    let postBuysData = {...BUYS.newBuys, ...requestData};
                    const advDoc = SETTINGS.settings.advancedTypesBuys.filter((doc) => doc._id === postBuysData.documentType);
                    if (postBuysData.customTime !== "true") {
                        let date = new Date();
                        postBuysData.time = date.getHours() + ':' + String(date.getMinutes()).padStart(2, '0');
                    }
                    let printAfterSave = advDoc[0] && advDoc[0]?.printAfterSave === "active";
                    if (postBuysData._id) {
                        dispatch(putBuys(postBuysData, requestData, setLoadingSave)); // Post to API
                    } else {
                        postBuysData.date = moment(postBuysData.date).set({hour: Number(moment().format("HH")), minute: Number(moment().format("mm")), second: Number(moment().format("ss"))}).toDate();
                        // Show print preview on save
                        dispatch(postBuys(postBuysData, requestData, handleDpActions, printAfterSave, setLoadingSave, SETTINGS.settings.advancedTypesBuys, ACTIVITY?.sendEmail)); // Post to API
                    }
                }

                if (action === "saveandrepeat" && validCheck) {
                    let postBuysData = {...BUYS.newBuys, ...requestData};
                    postBuysData.isActive = "draft";
                    if (postBuysData.customTime !== "true") {
                        let date = new Date();
                        postBuysData.time = date.getHours() + ':' + String(date.getMinutes()).padStart(2, '0');
                    }
                    const advDoc = SETTINGS.settings.advancedTypesBuys.filter((doc) => doc._id === postBuysData.documentType);
                    let printAfterSave = advDoc[0] && advDoc[0]?.printAfterSave === "active";
                    if (postBuysData._id) {
                        dispatch(putBuys(postBuysData, requestData, handleDpActions, printAfterSave, setLoadingSaveAndRepeat)); // Post to API
                    } else {
                        // Show print preview on save
                        dispatch(postBuys(postBuysData, requestData, handleDpActions, printAfterSave, setLoadingSaveAndRepeat, SETTINGS.settings.advancedTypesBuys, ACTIVITY?.sendEmail)); // Post to API
                    }
                }
            }

            // Update Sales
            else if (templateType === "editSales") {
                let validCheck = true;
                const newSalesData = SALES.editSales;
                if (newSalesData.newSalesData === "") {
                    toast.error("Document Type should not be empty.");
                    validCheck = false;
                }
                if ((newSalesData.clientType === "Existing" && newSalesData.client === "") || (newSalesData.clientType === "New" && newSalesData.newClient === "")) {
                    toast.error("Client field should not be empty.");
                    validCheck = false;
                }

                if (SALES.newSales.paymentTypeName === "") {
                    const selectedDocumentTypeData = getObjectFromArrayOfObjects(SETTINGS.settings.advancedTypesSales, newSalesData.documentType, "_id");
                    if (selectedDocumentTypeData.length > 0) {
                        let selectedDTData = selectedDocumentTypeData['0'];
                        if (selectedDTData.enableMyDataRules && selectedDTData.enableMyDataRules === 'active' && SALES.newSales.paymentTypeName === "") {
                            toast.error("Payment Type field should not be empty.");
                            validCheck = false;
                        }
                    }
                }

                if (newSalesData.clientType === "New" && (newSalesData.email === "" || newSalesData.email === "")) {
                    toast.error("Please add Email or Phone number.");
                    validCheck = false;
                }
                if ((!newSalesData.installation || newSalesData.installation === "") && validCheck) {
                    toast.error("Please add related installation for this invoice.");
                    validCheck = false;
                }
                if ((!newSalesData.preNumber || newSalesData.preNumber === "") && validCheck) {
                    toast.error("Please add PreNumber for this invoice.");
                    validCheck = false;
                }
                if ((!newSalesData.number || newSalesData.number === "") && validCheck) {
                    toast.error("Please add Number for this invoice.");
                    validCheck = false;
                }
                if ((!newSalesData.date || newSalesData.date.getFullYear() !== company.year) && validCheck) {
                    toast.error("Please select a date included in the current Year.");
                    validCheck = false;
                }
                if (newSalesData.products && newSalesData.products?.length === 0 && validCheck) {
                    toast.error("Please add at least one product.");
                    validCheck = false;
                }

                if (action === "save" && validCheck) {
                    // Clear for only on save not saveandrepeat
                    setLoadingSave(true);
                    setTimeout(() => {
                        setLoadingSave(false);
                    }, 2000);
                    let postSalesData = {...SALES.editSales, ...requestData};
                    if (postSalesData._id) {
                        dispatch(putSales(postSalesData, requestData)); // Post to API
                    } else {
                        // Show print preview on save
                        dispatch(putSales(postSalesData, requestData)); // Post to API
                    }
                }
            }

            // Update Buys
            else if (templateType === "editBuys") {
                let validCheck = true;
                const newBuysData = BUYS.editBuys;
                if (newBuysData.newBuysData === "") {
                    toast.error("Document Type should not be empty.");
                    validCheck = false;
                }
                if ((newBuysData.clientType === "Existing" && newBuysData.client === "") || (newBuysData.clientType === "New" && newBuysData.newClient === "")) {
                    toast.error("Client field should not be empty.");
                    validCheck = false;
                }

                if (isEmpty(newBuysData.date)) {
                    toast.error("Date cannot be empty.");
                    validCheck = false;
                }

                if (BUYS.editBuys.paymentTypeName === "") {
                    const selectedDocumentTypeData = getObjectFromArrayOfObjects(SETTINGS.settings.advancedTypesSales, newBuysData.documentType, "_id");
                    if (selectedDocumentTypeData.length > 0) {
                        let selectedDTData = selectedDocumentTypeData['0'];
                        if (selectedDTData.enableMyDataRules && selectedDTData.enableMyDataRules === 'active' && BUYS.editBuys.paymentTypeName === "") {
                            toast.error("Payment Type field should not be empty.");
                            validCheck = false;
                        }
                    }
                }

                if (newBuysData.clientType === "New" && (newBuysData.email === "" || newBuysData.email === "")) {
                    toast.error("Please add Email or Phone number.");
                    validCheck = false;
                }
                if ((!newBuysData.installation || newBuysData.installation === "") && validCheck) {
                    toast.error("Please add related installation for this invoice.");
                    validCheck = false;
                }
                if ((!newBuysData.preNumber || newBuysData.preNumber === "") && validCheck) {
                    toast.error("Please add PreNumber for this invoice.");
                    validCheck = false;
                }
                if ((!newBuysData.number || newBuysData.number === "") && validCheck) {
                    toast.error("Please add Number for this invoice.");
                    validCheck = false;
                }
                if ((!newBuysData.date || moment(newBuysData.date).toDate().getFullYear() !== company.year) && validCheck) {
                    toast.error("Please select a date included in the current Year.");
                    validCheck = false;
                }

                if (newBuysData.products && newBuysData.products?.length === 0 && validCheck) {
                    toast.error("Please add at least one product.");
                    validCheck = false;
                }

                if (action === "save" && validCheck) {
                    setLoadingSave(true);
                    setTimeout(() => {
                        setLoadingSave(false);
                    }, 2000);
                    let postBuysData = {...BUYS.editBuys, ...requestData};
                    if (postBuysData._id) {
                        dispatch(putBuys(postBuysData, requestData)); // Post to API
                    }
                }
            }
            // Save Client Payments
            else if (templateType === "clientPayments") {
                let validCheck = true;
                const newClientPaymentData = CLIENTS_DATA.newClientPayment;
                if (newClientPaymentData.installation === "") {
                    toast.error("Installation field should not be empty.");
                    validCheck = false;
                }
                if (isEmpty(newClientPaymentData.date)) {
                    toast.error("Date should not be empty.");
                    validCheck = false;
                }
                if ((!newClientPaymentData.date || moment(newClientPaymentData.date).toDate().getFullYear() !== company.year) && validCheck) {
                    toast.error("Please select a date included in the current Year.");
                    validCheck = false;
                }
                if (Number(newClientPaymentData.amount) === 0 || newClientPaymentData.amount === undefined || newClientPaymentData.amount === null) {
                    toast.error("Payment amount should be greater than zero");
                    validCheck = false;
                }
                if (newClientPaymentData?.myDataType === "8.5" && isEmpty(newClientPaymentData.returnPayment)) {
                    toast.error("Η επιστροφή μέσω POS απαιτεί συσχετιζόμενη είσπραξη.");
                    validCheck = false;
                }
                if (validCheck && templateType === "clientPayments") {
                    let postClientPaymentData = {...CLIENTS_DATA.newClientPayment, ...requestData}
                    postClientPaymentData.isActive = CLIENTS_DATA.newClientPayment.isActive; // set status
                    const advDoc = SETTINGS.settings.advancedTypes.filter((doc) => doc._id === CLIENTS_DATA.newClientPayment.documentType);
                    const printAfterSave = advDoc[0]?.printAfterSave === "active";
                    if (action === "save") {
                        if (postClientPaymentData.isActive === "draft" || postClientPaymentData._id) {
                            dispatch(putClientPayment(postClientPaymentData, requestData)); // Update To API
                            dispatch(setSaveEditClientPayments(false)); // Set save status false
                        } else {
                            if (postClientPaymentData.myDataType === "8.4" && postClientPaymentData.paymentTypeType === "pos" && advDoc[0]?.mydataProvider === "active") {
                                setShowPOSModal(true);
                            } else if (postClientPaymentData.myDataType === "8.5" && postClientPaymentData.paymentTypeType === "pos" && advDoc[0]?.mydataProvider === "active") {
                                setShowPOSModalCancel(true);
                            } else if (postClientPaymentData.openPOS === "true" && postClientPaymentData.paymentTypeType === "pos" && postClientPaymentData.openPOS === "true") {
                                // openPOS is true when forSale is provider and payment type 5 (credit)
                                setShowPOSModal(true);
                            }
                            postClientPaymentData.date = moment(postClientPaymentData.date).set({hour: Number(moment().format("HH")), minute: Number(moment().format("mm")), second: Number(moment().format("ss"))}).toDate();
                            dispatch(postClientPayment(postClientPaymentData, requestData, setLoadingSave, handleDpActions, printAfterSave, ACTIVITY?.sendEmail, setShowPOSModal, setShowPOSModalCancel)); // Update To API
                            dispatch(setSaveClientPayments(false)); // Set save status false
                        }
                    }
                }
            }
            // Save client payment
            else if (templateType === "editClientPayments") {
                let validCheck = true;
                const editClientPaymentData = Object.assign({}, CLIENTS_DATA.editClientPayment);

                if (editClientPaymentData.installation === "") {
                    toast.error("Installation field should not be empty.");
                    validCheck = false;
                }
                if (isEmpty(editClientPaymentData.date)) {
                    toast.error("Date should not be empty.");
                    validCheck = false;
                }
                if ((!editClientPaymentData.date || moment(editClientPaymentData.date).toDate().getFullYear() !== company.year) && validCheck) {
                    toast.error("Please select a date included in the current year.");
                    validCheck = false;
                }
                if (Number(editClientPaymentData.amount) === 0 || editClientPaymentData.amount === undefined || editClientPaymentData.amount === null) {
                    toast.error("Payment amount should be greater than zero.");
                    validCheck = false;
                }

                if (validCheck) {
                    const advDoc = SETTINGS.settings.advancedTypes.filter((doc) => doc._id === CLIENTS_DATA.editClientPayment.documentType);
                    const printAfterSave = advDoc[0]?.printAfterSave === "active";
                    if (action === "save") {
                        dispatch(putClientPayment(editClientPaymentData, requestData)); // Update To API
                        //dispatch(putClientPayment(CLIENTS_DATA.editClientPayment, requestData, setLoadingSave, handleDpActions, printAfterSave, ACTIVITY?.sendEmail)); // Update To API
                        dispatch(setSaveEditClientPayments(false)); // Set save status false
                    }
                }
            } else if (templateType === "supplierPayments") {
                let validCheck = true;
                const newSupplierPaymentData = SUPPLIERS_DATA.newSupplierPayment;
                if (newSupplierPaymentData.supplierName === "" || newSupplierPaymentData.supplierName === null) {
                    toast.error("Supplier cannot be empty.");
                    validCheck = false;
                }
                if ((!newSupplierPaymentData.date || newSupplierPaymentData.date.getFullYear() !== company.year) && validCheck) {
                    toast.error("Please select a date included in the current year.");
                    validCheck = false;
                }
                if (Number(newSupplierPaymentData.amount) === 0 || newSupplierPaymentData.amount === undefined || newSupplierPaymentData.amount === null) {
                    toast.error("Payment amount should be greater than zero.");
                    validCheck = false;
                }
                if (newSupplierPaymentData.installation === "") {
                    toast.error("Installation field should not be empty.");
                    validCheck = false;
                }
                if (isEmpty(newSupplierPaymentData.date)) {
                    toast.error("Date should not be empty.");
                    validCheck = false;
                }
                if (validCheck) {
                    let postSupplierPaymentData = {...SUPPLIERS_DATA.newSupplierPayment, ...requestData};
                    if (action === "save") {
                        setLoadingSave(true);
                        postSupplierPaymentData.date = moment(postSupplierPaymentData.date).set({hour: Number(moment().format("HH")), minute: Number(moment().format("mm")), second: Number(moment().format("ss"))}).toDate();
                        dispatch(postSupplierPayment(postSupplierPaymentData, requestData, setLoadingSave));
                        dispatch(setSaveSupplierPayments(false));
                    }
                }
            } else if (templateType === "editSupplierPayments") {
                let validCheck = true;
                const newSupplierPaymentData = SUPPLIERS_DATA.editSupplierPayment;
                if (newSupplierPaymentData.supplierName === "" || newSupplierPaymentData.supplierName === null) {
                    toast.error("Supplier cannot be empty.");
                    validCheck = false;
                }
                if ((!newSupplierPaymentData.date || moment(newSupplierPaymentData.date).toDate().getFullYear() !== company.year) && validCheck) {
                    toast.error("Please select a date included in the current Year.");
                    validCheck = false;
                }
                if (Number(newSupplierPaymentData.amount) === 0 || newSupplierPaymentData.amount === undefined || newSupplierPaymentData.amount === null) {
                    toast.error("Payment amount should be greater than zero");
                    validCheck = false;
                }
                if (newSupplierPaymentData.installation === "") {
                    toast.error("Installation field should not be empty.");
                    validCheck = false;
                }
                if (isEmpty(newSupplierPaymentData.date)) {
                    toast.error("Date should not be empty.");
                    validCheck = false;
                }
                if (validCheck) {
                    let postSupplierPaymentData = {...SUPPLIERS_DATA.editSupplierPayment, ...requestData};
                    setLoadingSave(true);
                    dispatch(putSupplierPayment(postSupplierPaymentData, requestData, setLoadingSave));
                    dispatch(setSaveEditSupplierPayments(false));
                }
            } else if (templateType === "quickRetailSales") {
                let postQuickRetailSalesData = {...QUICK_RETAIL_SALES.newQuickRetailSales, ...requestData};
                dispatch(postQuickRetailSales(postQuickRetailSalesData, requestData)); // Post To API
                dispatch(resetNewQuickRetailSales()); // Reset
            } else if (templateType === "newEmployee") {
                let isValid = true;
                let postNewEmployeeData = {...ACCOUNTING_DATA.newEmployee, ...requestData};
                if (postNewEmployeeData.firstName === "" || postNewEmployeeData.lastName === "" || postNewEmployeeData.fatherFirstName === "" || postNewEmployeeData.fatherLastName === "" || postNewEmployeeData.motherFirstName === "" || postNewEmployeeData.motherLastName === "" || postNewEmployeeData.specialty === "" || !postNewEmployeeData?.employeeCategory || postNewEmployeeData.vatNumber === "" || postNewEmployeeData.amka === "" || postNewEmployeeData.amika === "" || postNewEmployeeData.salary === 0 || postNewEmployeeData.sex === "" || postNewEmployeeData.birthDate === "" || postNewEmployeeData.employeeType === "" || postNewEmployeeData.installation === "" || postNewEmployeeData.employmentDate === "" || isEmpty(postNewEmployeeData.flexibleAttendanceMinutes)) {
                    toast.error("Παρακαλώ ελέγξτε όλα τα απαραίτητα πεδία");
                    isValid = false;
                }
                if (postNewEmployeeData.resignationCause !== "" && !postNewEmployeeData?.resignationDate) {
                    toast.error("Παρακαλώ επιλέξτε πρώτα ημερομηνία αποχώρησης");
                    isValid = false;
                }
                if (postNewEmployeeData.resignationCause === "" && postNewEmployeeData?.resignationDate) {
                    toast.error("Παρακαλώ επιλέξτε αιτία αποχώρησης");
                    isValid = false;
                }
                if (isValid) {
                    if (ACTIVITY.sendEmployeeQROnSave) postNewEmployeeData.sendEmployeeQROnSave = true;
                    dispatch(postEmployee(postNewEmployeeData, requestData, setLoadingSave));
                }
            } else if (templateType === "editEmployee") {
                let isValid = true;
                let postEditEmployeeData = {...ACCOUNTING_DATA.editEmployee, ...requestData};
                if ((postEditEmployeeData.firstName === "" || postEditEmployeeData.lastName === "" || postEditEmployeeData.fatherFirstName === "" || postEditEmployeeData.fatherLastName === "" || postEditEmployeeData.motherFirstName === "" || !postEditEmployeeData?.employeeCategory || postEditEmployeeData.motherLastName === "" || postEditEmployeeData.specialty === "" || postEditEmployeeData.vatNumber === "" || postEditEmployeeData.amka === "" || postEditEmployeeData.amika === "" || postEditEmployeeData.salary === 0 || postEditEmployeeData.sex === "" || postEditEmployeeData.birthDate === "" || postEditEmployeeData.employeeType === "" || postEditEmployeeData.installation === "" || postEditEmployeeData.employmentDate === "" || isEmpty(postEditEmployeeData.flexibleAttendanceMinutes)) && postEditEmployeeData.isFromHyper !== "true") {
                    toast.error("Παρακαλώ ελέγξτε όλα τα απαραίτητα πεδία");
                    isValid = false;
                }
                if (postEditEmployeeData.resignationCause !== "" && !postEditEmployeeData?.resignationDate) {
                    toast.error("Παρακαλώ επιλέξτε πρώτα ημερομηνία αποχώρησης");
                    isValid = false;
                }
                if (postEditEmployeeData.resignationCause === "" && postEditEmployeeData?.resignationDate) {
                    toast.error("Παρακαλώ επιλέξτε αιτία αποχώρησης");
                    isValid = false;
                }
                if (isValid) {
                    if (ACTIVITY.sendEmployeeQROnSave) postEditEmployeeData.sendEmployeeQROnSave = true;
                    dispatch(putEmployee(postEditEmployeeData, requestData, "update", setLoadingSave));
                }
            } else if (templateType === "newAccountingAccount") {
                let isValid = true;
                let postNewAccountingAccountData = {...ACCOUNTING_DATA.newAccountingAccount, ...requestData};
                if (postNewAccountingAccountData.name === "" || postNewAccountingAccountData.code === "" || postNewAccountingAccountData.category === "") {
                    toast.error("Παρακαλώ ελέγξτε όλα τα απαραίτητα πεδία");
                    isValid = false;
                }
                const mydataRows = postNewAccountingAccountData.mydata;
                mydataRows.map((row) => {
                    if (row.invoiceType === "" || row.category === "" || row.type === "") {
                        toast.error('Ελέγξτε όλα τα υποχρεωτικά πεδία MyData.');
                        isValid = false;
                    }
                    return row;
                })
                if (postNewAccountingAccountData.subAccount === "client" && postNewAccountingAccountData.clientCategories.length === 0) {
                    toast.error("Παρακαλώ ελέγξτε όλα τα απαραίτητα πεδία");
                    isValid = false;
                }
                if (postNewAccountingAccountData.subAccount === "supplier" && postNewAccountingAccountData.supplierCategories.length === 0) {
                    toast.error("Παρακαλώ ελέγξτε όλα τα απαραίτητα πεδία");
                    isValid = false;
                }
                if (postNewAccountingAccountData.subAccount === "employee" && postNewAccountingAccountData.employeeCategories.length === 0) {
                    toast.error("Παρακαλώ ελέγξτε όλα τα απαραίτητα πεδία");
                    isValid = false;
                }
                if (isValid) {
                    dispatch(postAccountingAccount(postNewAccountingAccountData, requestData, setLoadingSave));
                }
            } else if (templateType === "editAccountingAccount") {
                let isValid = true;
                let postEditAccountingAccountData = {...ACCOUNTING_DATA.editAccountingAccount, ...requestData};
                if (postEditAccountingAccountData.name === "" || postEditAccountingAccountData.code === "" || postEditAccountingAccountData.category === "") {
                    toast.error("Παρακαλώ ελέγξτε όλα τα απαραίτητα πεδία.");
                    isValid = false;
                }
                const mydataRows = postEditAccountingAccountData.mydata;
                mydataRows.map((row) => {
                    if (row.invoiceType === "" || row.category === "" || row.type === "") {
                        toast.error('Ελέγξτε όλα τα υποχρεωτικά πεδία MyData.');
                        isValid = false;
                    }
                    return row;
                });
                if (postEditAccountingAccountData.subAccount === "client" && postEditAccountingAccountData.clientCategories.length === 0) {
                    toast.error("Παρακαλώ ελέγξτε όλα τα απαραίτητα πεδία");
                    isValid = false;
                }
                if (postEditAccountingAccountData.subAccount === "supplier" && postEditAccountingAccountData.supplierCategories.length === 0) {
                    toast.error("Παρακαλώ ελέγξτε όλα τα απαραίτητα πεδία");
                    isValid = false;
                }
                if (postEditAccountingAccountData.subAccount === "employee" && postEditAccountingAccountData.employeeCategories.length === 0) {
                    toast.error("Παρακαλώ ελέγξτε όλα τα απαραίτητα πεδία");
                    isValid = false;
                }
                if (isValid) {
                    dispatch(putAccountingAccount(postEditAccountingAccountData, requestData, setLoadingSave));
                }
            } else if (templateType === "newAccountingRecord") {
                let isValid = true;
                let postNewAccountingRecord = {...ACCOUNTING_DATA.newAccountingRecord, ...requestData};
                if (postNewAccountingRecord.installation === "") {
                    toast.error("Παρακαλώ ελέγξτε όλα τα απαραίτητα πεδία.")
                    isValid = false;
                }
                if (isEmpty(postNewAccountingRecord.category) || isEmpty(postNewAccountingRecord.subCategory)) {
                    toast.error("Πρέπει να επιλέξετε κατηγορία και υποκατηγορία.");
                    isValid = false;
                }
                if (postNewAccountingRecord?.myDataType && postNewAccountingRecord.number === "") {
                    toast.error("Παρακαλώ ελέγξτε όλα τα απαραίτητα πεδία.")
                    isValid = false;
                }
                let date = new Date();
                if (date.getFullYear() === postNewAccountingRecord.date.getFullYear() && date.getMonth() === postNewAccountingRecord.date.getMonth() && date.getDate() === postNewAccountingRecord.date.getDate()) {
                    postNewAccountingRecord.date = date;
                    postNewAccountingRecord.time = date.getHours() + ':' + String(date.getMinutes()).padStart(2, '0');
                }
                let totals = {};
                postNewAccountingRecord.accounts = postNewAccountingRecord.accounts.filter((el) => Number(el.credit) !== 0 || Number(el.debit) !== 0);
                if (postNewAccountingRecord.accounts.length > 0) {
                    let totalCredit = 0;
                    let totalDebit = 0;
                    for (const account of postNewAccountingRecord.accounts) {
                        totalCredit = totalCredit + Number(account.credit);
                        totalDebit = totalDebit + Number(account.debit);
                        if (account.code === "" || account.name === "" || ((account.subAccount === "" || account.subAccountId === "") && account.subAccountType !== "") || (Number(account.credit) === 0 && Number(account.debit) === 0)) {
                            isValid = false;
                            toast.error(`Παρακαλώ ελέγξτε όλα τα απαραίτητα πεδία στον λογαριασμό ${account?.name}`);
                        }
                        if (account?.vat) {
                            totals[`vat${account.vat}`] = filterNaNValue(totals[`vat${account.vat}`]) + (account.debit - account.credit);
                        }
                        if (account?.isNetValue && account.isNetValue !== "false" && postNewAccountingRecord?.myDataType && account.isNetValue !== "0" && account.isNetValue !== "-") {
                            totals[account.isNetValue] = filterNaNValue(totals[account.isNetValue]) + (account.debit - account.credit);
                            let foundVatAccount = false;
                            for (const vatAccount of postNewAccountingRecord.accounts) {
                                if (vatAccount?.vat && vatAccount.vat === account.isNetValue) {
                                    foundVatAccount = true;
                                }
                            }
                            if (!foundVatAccount) {
                                isValid = false;
                                toast.error(`Παρακαλώ προσθέστε λογαριασμό Φ.Π.Α. για τον λογαριασμό ${account.name} που αφορά καθαρή αξία για ΦΠΑ ${account.isNetValue}%`);
                                break;
                            }
                        }
                    }
                    for (const key in totals) {
                        if (!key.includes("vat")) {
                            if (Number(totals[`vat${key}`]) !== Number(parseFloat(totals[key]) * parseFloat(key) / 100)) {
                                isValid = false;
                                toast.error(`Παρακαλώ ελέγξτε τα σύνολο των λογαριασμών Φ.Π.Α. και καθαρής αξίας που αφορούν Φ.Π.Α. ${key}%`);
                                break;
                            }
                        }
                    }
                    if (parseFloat(totalCredit).toFixed(2) !== parseFloat(totalDebit).toFixed(2) && isValid) {
                        isValid = false;
                        toast.error("Παρακαλώ ελέγξτε τα σύνολα χρέωσης και πίστωσης καθώς η συνολική χρέωση δεν είναι ίση με την συνολική πίστωση");
                    }
                } else {
                    isValid = false;
                    toast.error("Παρακαλώ εισάγετε τουλάχιστον ένα λογαριασμό στο άρθρο");
                }
                if (!isEmpty(postNewAccountingRecord.myDataType)) {
                    const requiresClient = ["1.1", "1.2", "1.3", "1.4", "1.5", "1.6", "2.1", "2.2", "2.3", "2.4", "5.1", "5.2", "8.1", "8.2", "11.1", "11.2", "11.4"];
                    const requiresSupplier = ["3.1", "3.2", "14.1", "14.2", "14.3", "14.4", "14.31", "16.1"];
                    const requiresEmployee = ["17.1"];
                    if (requiresClient.includes(String(postNewAccountingRecord.myDataType))) {
                        const idx = postNewAccountingRecord.accounts.findIndex((el) => el.subAccountType === "client");
                        if(idx === -1) {
                            isValid = false;
                            toast.error("Ο παρόν τύπος παραστατικού πρέπει να περιέχει λογαριασμό με υποκατηγορία πελάτη.");
                        }
                    } else if (requiresSupplier.includes(String(postNewAccountingRecord.myDataType))) {
                        const idx = postNewAccountingRecord.accounts.findIndex((el) => el.subAccountType === "supplier");
                        if(idx === -1) {
                            isValid = false;
                            toast.error("Ο παρόν τύπος παραστατικού πρέπει να περιέχει λογαριασμό με υποκατηγορία προμηθευτή.");
                        }
                    } else if (requiresEmployee.includes(String(postNewAccountingRecord.myDataType))) {
                        const idx = postNewAccountingRecord.accounts.findIndex((el) => el.subAccountType === "employee");
                        if(idx === -1) {
                            isValid = false;
                            toast.error("Ο παρόν τύπος παραστατικού πρέπει να περιέχει λογαριασμό με υποκατηγορία εργαζομένου.");
                        }
                    }
                    if(String(postNewAccountingRecord.myDataType) === "3.1" || String(postNewAccountingRecord.myDataType) === "3.2") {
                        const idx = postNewAccountingRecord.accounts.findIndex((el) => el.subAccountType === "employee" || el.subAccountType === "client");
                        if(idx !== -1) {
                            isValid = false;
                            toast.error("Ο παρόν τύπος παραστατικού πρέπει να μην περιέχει λογαριασμό πελάτη ή εργαζομένου.");
                        }
                    }
                    if(String(postNewAccountingRecord.myDataType) === "17.1") {
                        const idx = postNewAccountingRecord.accounts.findIndex((el) => el.subAccountType === "supplier" || el.subAccountType === "client");
                        if(idx !== -1) {
                            isValid = false;
                            toast.error("Ο παρόν τύπος παραστατικού πρέπει να μην περιέχει λογαριασμό πελάτη ή προμηθευτή.");
                        }
                    }
                    if(String(postNewAccountingRecord.myDataType) === "5.1" && String(postNewAccountingRecord.correlatedRecord).length !== 24) {
                        isValid = false;
                        toast.error("Η επιλογή συσχετιζόμενου παραστατικού δεν γίνεται να είναι άδεια.");
                    }
                    if(postNewAccountingRecord.accounts.findIndex((el) => el.isNetValue !== "false") === -1) {
                        isValid = false;
                        toast.error("Πρέπει να υπάρχει λογαριασμός καθαρής αξίας.");
                    }
                    if (!postNewAccountingRecord.paymentType || String(postNewAccountingRecord.paymentType).trim() === "") {
                        toast.error("Ο τρόπος πληρωμής δεν μπορεί να είναι άδειος.");
                        isValid = false;
                    }
                }
                if(isValid) {
                    for(let accountData of postNewAccountingRecord.accounts) {
                        if(accountData.isNetValue !== "false") {
                            if (postNewAccountingRecord.myDataType && postNewAccountingRecord.myDataType !== "") {
                                if (accountData.mydata && accountData.mydata?.length > 0) {
                                    if (accountData.mydata.findIndex((el) => el.invoiceType === postNewAccountingRecord.myDataType) === -1) {
                                        isValid = false;
                                    }
                                } else {
                                    isValid = false;
                                }
                            }
                        }
                    }
                    if(!isValid) {
                        toast.error("Μερικοί λογαριασμοί δεν έχουν τον σωστό χαρακτηρισμό My Data.");
                    }
                }
                if (isValid) {
                    if(isEmpty(postNewAccountingRecord.preNumber)) postNewAccountingRecord.preNumber = "noprefix";
                    postNewAccountingRecord.date = moment(postNewAccountingRecord.date).set({hour: 12, minute: 0, second: 0}).toDate();
                    dispatch(postAccountingRecord(postNewAccountingRecord, requestData, setLoadingSave, action));
                }
            } else if (templateType === "editAccountingRecord") {
                let isValid = true;
                let postNewAccountingRecord = {...ACCOUNTING_DATA.editAccountingRecord, ...requestData};
                if (postNewAccountingRecord.installation === "") {
                    toast.error("Παρακαλώ ελέγξτε όλα τα απαραίτητα πεδία")
                    isValid = false;
                }
                if (isEmpty(postNewAccountingRecord.category) || isEmpty(postNewAccountingRecord.subCategory)) {
                    toast.error("Πρέπει να επιλέξετε κατηγορία και υποκατηγορία.");
                    isValid = false;
                }
                if (postNewAccountingRecord?.myDataType && postNewAccountingRecord.number === "") {
                    toast.error("Παρακαλώ ελέγξτε όλα τα απαραίτητα πεδία.")
                    isValid = false;
                }
                let totals = {};
                postNewAccountingRecord.accounts = postNewAccountingRecord.accounts.filter((el) => Number(el.credit) !== 0 || Number(el.debit) !== 0);
                if (postNewAccountingRecord.accounts.length > 0) {
                    let totalCredit = 0;
                    let totalDebit = 0;
                    for (const account of postNewAccountingRecord.accounts) {
                        totalCredit = totalCredit + Number(account.credit);
                        totalDebit = totalDebit + Number(account.debit);
                        if (account.code === "" || account.name === "" || ((account.subAccount === "" || account.subAccountId === "") && account.subAccountType !== "") || (Number(account.credit) === 0 && Number(account.debit) === 0)) {
                            isValid = false;
                            toast.error(`Παρακαλώ ελέγξτε όλα τα απαραίτητα πεδία στον λογαριασμό ${account?.name}`);
                        }
                        if (account?.vat) {
                            totals[`vat${account.vat}`] = filterNaNValue(totals[`vat${account.vat}`]) + (account.debit - account.credit);
                        }
                        if (account?.isNetValue && account.isNetValue !== "false" && postNewAccountingRecord?.myDataType && account.isNetValue !== "0" && account.isNetValue !== "-") {
                            totals[account.isNetValue] = filterNaNValue(totals[account.isNetValue]) + (account.debit - account.credit);
                            let foundVatAccount = false;
                            for (const vatAccount of postNewAccountingRecord.accounts) {
                                if (vatAccount?.vat && vatAccount.vat === account.isNetValue) {
                                    foundVatAccount = true;
                                }
                            }
                            if (!foundVatAccount) {
                                isValid = false;
                                toast.error(`Παρακαλώ προσθέστε λογαριασμό Φ.Π.Α. για τον λογαριασμό ${account.name} που αφορά καθαρή αξία για ΦΠΑ ${account.isNetValue}%`);
                                break;
                            }
                        }
                    }
                    for (const key in totals) {
                        if (!key.includes("vat")) {
                            if (Number(totals[`vat${key}`]) !== Number(parseFloat(totals[key]) * parseFloat(key) / 100)) {
                                isValid = false;
                                toast.error(`Παρακαλώ ελέγξτε τα σύνολο των λογαριασμών Φ.Π.Α. και καθαρής αξίας που αφορούν Φ.Π.Α. ${key}%`);
                                break;
                            }
                        }
                    }
                    if (parseFloat(totalCredit).toFixed(2) !== parseFloat(totalDebit).toFixed(2) && isValid) {
                        isValid = false;
                        toast.error("Παρακαλώ ελέγξτε τα σύνολα χρέωσης και πίστωσης καθώς η συνολική χρέωση δεν είναι ίση με την συνολική πίστωση");
                    }
                } else {
                    isValid = false;
                    toast.error("Παρακαλώ εισάγετε τουλάχιστον ένα λογαριασμό στο άρθρο");
                }
                if (!isEmpty(postNewAccountingRecord.myDataType)) {
                    const requiresClient = ["1.1", "1.2", "1.3", "1.4", "1.5", "1.6", "2.1", "2.2", "2.3", "2.4", "5.1", "5.2", "8.1", "8.2", "11.1", "11.2", "11.4"];
                    const requiresSupplier = ["3.1", "3.2", "14.1", "14.2", "14.3", "14.4", "14.31", "16.1"];
                    const requiresEmployee = ["17.1"];
                    if (requiresClient.includes(String(postNewAccountingRecord.myDataType))) {
                        const idx = postNewAccountingRecord.accounts.findIndex((el) => el.subAccountType === "client");
                        if(idx === -1) {
                            isValid = false;
                            toast.error("Ο παρόν τύπος παραστατικού πρέπει να περιέχει λογαριασμό με υποκατηγορία πελάτη.");
                        }
                    } else if (requiresSupplier.includes(String(postNewAccountingRecord.myDataType))) {
                        const idx = postNewAccountingRecord.accounts.findIndex((el) => el.subAccountType === "supplier");
                        if(idx === -1) {
                            isValid = false;
                            toast.error("Ο παρόν τύπος παραστατικού πρέπει να περιέχει λογαριασμό με υποκατηγορία προμηθευτή.");
                        }
                    } else if (requiresEmployee.includes(String(postNewAccountingRecord.myDataType))) {
                        const idx = postNewAccountingRecord.accounts.findIndex((el) => el.subAccountType === "employee");
                        if(idx === -1) {
                            isValid = false;
                            toast.error("Ο παρόν τύπος παραστατικού πρέπει να περιέχει λογαριασμό με υποκατηγορία εργαζομένου.");
                        }
                    }
                    if(String(postNewAccountingRecord.myDataType) === "3.1" || String(postNewAccountingRecord.myDataType) === "3.2") {
                        const idx = postNewAccountingRecord.accounts.findIndex((el) => el.subAccountType === "employee" || el.subAccountType === "client");
                        if(idx !== -1) {
                            isValid = false;
                            toast.error("Ο παρόν τύπος παραστατικού πρέπει να μην περιέχει λογαριασμό πελάτη ή εργαζομένου.");
                        }
                    }
                    if(String(postNewAccountingRecord.myDataType) === "17.1") {
                        const idx = postNewAccountingRecord.accounts.findIndex((el) => el.subAccountType === "supplier" || el.subAccountType === "client");
                        if(idx !== -1) {
                            isValid = false;
                            toast.error("Ο παρόν τύπος παραστατικού πρέπει να μην περιέχει λογαριασμό πελάτη ή προμηθευτή.");
                        }
                    }
                    if(String(postNewAccountingRecord.myDataType) === "5.1" && String(postNewAccountingRecord.correlatedRecord).length !== 24) {
                        isValid = false;
                        toast.error("Η επιλογή συσχετιζόμενου παραστατικού δεν γίνεται να είναι άδεια.");
                    }
                    if(postNewAccountingRecord.accounts.findIndex((el) => el.isNetValue !== "false") === -1) {
                        isValid = false;
                        toast.error("Πρέπει να υπάρχει λογαριασμός καθαρής αξίας.");
                    }
                    if (!postNewAccountingRecord.paymentType || String(postNewAccountingRecord.paymentType).trim() === "") {
                        toast.error("Ο τρόπος πληρωμής δεν μπορεί να είναι άδειος.");
                        isValid = false;
                    }
                }
                if(isValid) {
                    for(let accountData of postNewAccountingRecord.accounts) {
                        if(accountData.isNetValue !== "false") {
                            if (postNewAccountingRecord.myDataType && postNewAccountingRecord.myDataType !== "") {
                                if (accountData.mydata && accountData.mydata?.length > 0) {
                                    if (accountData.mydata.findIndex((el) => el.invoiceType === postNewAccountingRecord.myDataType) === -1) {
                                        isValid = false;
                                    }
                                } else {
                                    isValid = false;
                                }
                            }
                        }
                    }
                    if(!isValid) {
                        toast.error("Μερικοί λογαριασμοί δεν έχουν τον σωστό χαρακτηρισμό My Data.");
                    }
                }
                if (isValid) {
                    if(isEmpty(postNewAccountingRecord.preNumber)) postNewAccountingRecord.preNumber = "noprefix";
                    postNewAccountingRecord.date = moment(postNewAccountingRecord.date).set({hour: 12, minute: 0, second: 0}).toDate();
                    dispatch(putAccountingRecord(postNewAccountingRecord, requestData, setLoadingSave));
                }
            } else if (templateType === "newWorkMovement") {
                let postData = {...PAYROLL.newWorkMovement, ...requestData};
                dispatch(postWorkMovement(postData));
            } else if (templateType === "editWorkMovement") {

            } else if (templateType === "newWorkPermit") {
                dispatch(setSaveNewWorkPermit(false));
                let postData = {...PAYROLL.newWorkPermit, ...requestData};
                dispatch(postWorkPermit(postData, setLoadingSave));
            } else if (templateType === "editWorkPermit") {

            }
        }
    }

    // Handle Print Layout Change
    const handePrintLayoutChange = (e) => {
        dispatch(setPrintLayout(e.value));
    }

    // Handle Send Email for Sales page on save
    const handleSendEmailOnSave = async (e) => {
        if (e.target && e.target.value) {
            if (e.target.value === "inactive") {
                dispatch(setSendEmail(e.target.value));
            }
            // Sales Send Mail on Save Option
            if (templateType === "sales" && e.target.value === "active") {
                let salesData = Object.assign({}, SALES);
                let tempDefaultRecipientEmails = [];
                let tempSelectedRecipientEmails = [];
                let newSalesData = {sendEmail: e.target.value};
                if (salesData.newSales.client && salesData.newSales.client !== "") {
                    await axios.get(apiURL2 + `/client/get-client-id-by/${salesData.newSales.client}`)
                        .then((res) => {
                            if (res.data && res.data.status === "200") {
                                if (res.data.data.contactPoints.length > 0) {
                                    let contacts = [];
                                    res.data.data.contactPoints.map(contact => {
                                        if (contact.email && contact.email !== "") {
                                            contacts.push({
                                                label: (contact.name !== "" ? (contact.name + ",") : "") + (contact.mobile !== "" ? (contact.mobile + ",") : "") + (contact.landline !== "" ? (contact.landline + ",") : "") + contact.email,
                                                value: contact.email
                                            })
                                            if (contact.acceptsNotifications === "true") {
                                                setDefaultRecipientEmails([...tempDefaultRecipientEmails, {
                                                    label: (contact.name !== "" ? (contact.name + ",") : "") + (contact.mobile !== "" ? (contact.mobile + ",") : "") + (contact.landline !== "" ? (contact.landline + ",") : "") + contact.email,
                                                    value: contact.email
                                                }])
                                                tempDefaultRecipientEmails.push({
                                                    label: (contact.name !== "" ? (contact.name + ",") : "") + (contact.mobile !== "" ? (contact.mobile + ",") : "") + (contact.landline !== "" ? (contact.landline + ",") : "") + contact.email,
                                                    value: contact.email
                                                })
                                                setSelectedRecipientEmails([...tempSelectedRecipientEmails, contact.email])
                                                tempSelectedRecipientEmails.push(contact.email)
                                            }
                                        }
                                        return contact;
                                    })
                                    setRecipientEmails(contacts)
                                }
                                dispatch(setNewSales({...salesData.newSales, ...newSalesData}));
                            }
                        })
                    dispatch(setSendEmail(e.target.value));
                    scrollToEnd.current?.scrollIntoView({behavior: 'smooth'})
                } else {
                    dispatch(setSendEmail(e.target.value));
                    dispatch(setSendEmail("inactive"));
                    toast.error("Please Select Client first!");
                }
            } else if (templateType === "clientPayments" && e.target.value === "active") {
                let clients_data = Object.assign({}, CLIENTS_DATA)
                let tempDefaultRecipientEmails = [];
                let tempSelectedRecipientEmails = [];
                let new_clients_data = {sendEmail: e.target.value}
                if (clients_data.newClientPayment.client && clients_data.newClientPayment.client !== "") {
                    await axios.get(apiURL2 + `/client/get-client-id-by/${clients_data.newClientPayment.client}`).then((res) => {
                        if (res.data && res.data.status === "200") {
                            if (res.data.data.contactPoints.length > 0) {
                                let contacts = [];
                                res.data.data.contactPoints.map(contact => {
                                    if (contact.email && contact.email !== "") {
                                        contacts.push({
                                            label: (contact.name !== "" ? (contact.name + ",") : "") + (contact.mobile !== "" ? (contact.mobile + ",") : "") + (contact.landline !== "" ? (contact.landline + ",") : "") + contact.email,
                                            value: contact.email
                                        })
                                        if (contact.acceptsNotifications === "true") {
                                            setDefaultRecipientEmails([...tempDefaultRecipientEmails, {
                                                label: (contact.name !== "" ? (contact.name + ",") : "") + (contact.mobile !== "" ? (contact.mobile + ",") : "") + (contact.landline !== "" ? (contact.landline + ",") : "") + contact.email,
                                                value: contact.email
                                            }])
                                            tempDefaultRecipientEmails.push({
                                                label: (contact.name !== "" ? (contact.name + ",") : "") + (contact.mobile !== "" ? (contact.mobile + ",") : "") + (contact.landline !== "" ? (contact.landline + ",") : "") + contact.email,
                                                value: contact.email
                                            })
                                            setSelectedRecipientEmails([...tempSelectedRecipientEmails, contact.email])
                                            tempSelectedRecipientEmails.push(contact.email)
                                        }
                                    }
                                    return contact;
                                })
                                setRecipientEmails(contacts)
                            }
                            dispatch(setNewClientPayment({...clients_data.newClientPayment, ...new_clients_data}));
                        }
                    })
                    dispatch(setSendEmail(e.target.value));
                    scrollToEnd.current?.scrollIntoView({behavior: 'smooth'})
                } else {
                    dispatch(setSendEmail(e.target.value));
                    dispatch(setSendEmail("inactive"));
                    toast.error("Please Select Client first!");
                }
            } else if (templateType === "buys" && e.target.value === "active") {
                let buys = Object.assign({}, BUYS)
                let tempDefaultRecipientEmails = [];
                let tempSelectedRecipientEmails = [];
                let new_buys = {sendEmail: e.target.value}
                if (buys.newBuys.supplier && buys.newBuys.supplier !== "") {
                    await axios.get(apiURL2 + `/supplier/get-supplier-id-by/${buys.newBuys.supplier}`).then((res) => {
                        if (res.data && res.data.status === "200") {
                            if (res.data.data.contactPoints.length > 0) {
                                let contacts = [];
                                res.data.data.contactPoints.map(contact => {
                                    if (contact.email && contact.email !== "") {
                                        contacts.push({
                                            label: (contact.name !== "" ? (contact.name + ",") : "") + (contact.mobile !== "" ? (contact.mobile + ",") : "") + (contact.landline !== "" ? (contact.landline + ",") : "") + contact.email,
                                            value: contact.email
                                        })
                                        if (contact.acceptsNotifications === "true") {
                                            setDefaultRecipientEmails([...tempDefaultRecipientEmails, {
                                                label: (contact.name !== "" ? (contact.name + ",") : "") + (contact.mobile !== "" ? (contact.mobile + ",") : "") + (contact.landline !== "" ? (contact.landline + ",") : "") + contact.email,
                                                value: contact.email
                                            }])
                                            tempDefaultRecipientEmails.push({
                                                label: (contact.name !== "" ? (contact.name + ",") : "") + (contact.mobile !== "" ? (contact.mobile + ",") : "") + (contact.landline !== "" ? (contact.landline + ",") : "") + contact.email,
                                                value: contact.email
                                            })
                                            setSelectedRecipientEmails([...tempSelectedRecipientEmails, contact.email])
                                            tempSelectedRecipientEmails.push(contact.email)
                                        }
                                    }
                                    return contact;
                                })
                                setRecipientEmails(contacts)
                            }
                            dispatch(setNewBuys({...buys.newBuys, ...new_buys}));
                        }
                    })
                    dispatch(setSendEmail(e.target.value));
                    scrollToEnd.current?.scrollIntoView({behavior: 'smooth'})
                } else {
                    dispatch(setSendEmail(e.target.value));
                    dispatch(setSendEmail("inactive"));
                    toast.error("Please Select Client first!");
                }
            } else {
                setDefaultRecipientEmails([]);// Update State
            }
        }
    };

    useEffect(async () => {
        if (templateType === "sales" && SALES.newSales.client !== "" && SALES.newSales.documentType !== "" && ACTIVITY?.sendEmail === "active") {
            dispatch(setSendEmail("inactive"));
            setDefaultRecipientEmails([]);
            setSelectedRecipientEmails([]);
            let tempDefaultRecipientEmails = [];
            let tempSelectedRecipientEmails = [];
            let salesData = Object.assign({}, SALES);
            await axios.get(apiURL2 + `/client/get-client-id-by/${SALES.newSales.client}`)
                .then(async (res) => {
                    if (res.data && res.data.status === "200") {
                        if (res.data.data.contactPoints.length > 0) {
                            let contacts = [];
                            res.data.data.contactPoints.map(async contact => {
                                if (contact.email && contact.email !== "") {
                                    contacts.push({
                                        label: (contact.name !== "" ? (contact.name + ",") : "") + (contact.mobile !== "" ? (contact.mobile + ",") : "") + (contact.landline !== "" ? (contact.landline + ",") : "") + contact.email,
                                        value: contact.email
                                    })
                                    if (contact.acceptsNotifications === "true") {
                                        setDefaultRecipientEmails([...tempDefaultRecipientEmails, {
                                            label: (contact.name !== "" ? (contact.name + ",") : "") + (contact.mobile !== "" ? (contact.mobile + ",") : "") + (contact.landline !== "" ? (contact.landline + ",") : "") + contact.email,
                                            value: contact.email
                                        }])
                                        tempDefaultRecipientEmails.push({
                                            label: (contact.name !== "" ? (contact.name + ",") : "") + (contact.mobile !== "" ? (contact.mobile + ",") : "") + (contact.landline !== "" ? (contact.landline + ",") : "") + contact.email,
                                            value: contact.email
                                        })
                                        setSelectedRecipientEmails([...tempSelectedRecipientEmails, contact.email])
                                        tempSelectedRecipientEmails.push(contact.email)
                                    }
                                }
                                return contact;
                            })
                            setRecipientEmails(contacts)
                            let newSalesData = {sendEmail: "true"};
                            dispatch(setNewSales({...salesData.newSales, ...newSalesData}));
                        }
                    }
                })
            dispatch(setSendEmail("active"));
        }
        if (templateType === "sales" && SALES.newSales.documentType !== "" && SALES.newSales.client === "") {
            const advDoc = SETTINGS.settings.advancedTypesSales.filter((doc) => doc._id === SALES.newSales.documentType);
            if (advDoc.length > 0) {
                if (advDoc[0].sendEmailAfterSave === "active") {
                    dispatch(setSendEmail("active"));
                } else {
                    setDefaultRecipientEmails([]);
                    setSelectedRecipientEmails([]);
                    dispatch(setSendEmail("inactive"));
                }
            }

            // Sales Send Mail on Save Option
            if (templateType === "buys") {
                let buysData = Object.assign({}, BUYS);
                dispatch(setNewBuys(buysData.newBuys));
            }
        }
        if (templateType === "clientPayments" && CLIENTS_DATA.newClientPayment.documentType !== "") {
            const advDoc = SETTINGS.settings.advancedTypes.filter((doc) => doc._id === CLIENTS_DATA.newClientPayment.documentType);
            if (advDoc.length > 0) {
                if (advDoc[0].sendEmailAfterSave === "active") {
                    dispatch(setSendEmail("active"));
                } else {
                    setDefaultRecipientEmails([]);
                    setSelectedRecipientEmails([]);
                    dispatch(setSendEmail("inactive"));
                }
            }
        }
        if (templateType === "buys" && BUYS.newBuys.documentType !== "") {
            const advDoc = SETTINGS.settings.advancedTypes.filter((doc) => doc._id === BUYS.newBuys.documentType);
            if (advDoc.length > 0) {
                if (advDoc[0].sendEmailAfterSave === "active") {
                    dispatch(setSendEmail("active"));
                } else {
                    setDefaultRecipientEmails([]);
                    setSelectedRecipientEmails([]);
                    dispatch(setSendEmail("inactive"));
                }
            }
        }
    }, [SALES.newSales.client, SALES.newSales.documentType, SALES.editSales.client, SALES.editSales.documentType, CLIENTS_DATA.newClientPayment.documentType, BUYS.newBuys])

    const handleOnEmailChange = (e, type = "default", ddname = '') => {
        let value = '';
        if (type === 'msdd') {
            value = getMultiSelectOptionsToArray(e);
            setSelectedRecipientEmails(value);
            if (value.length > 0) {
                // setSendButton(true);
            }
        } else if (type === 'dd') {
            value = e.target.value.split(',');
            let customEmails = [];
            value.map(email => {
                let validateEmail = validate(email);
                if (validateEmail) {
                    setEmailError(false);
                    customEmails.push(email);
                } else if (email.length === 0) {
                    setEmailError(false);
                } else {
                    setEmailError(true);
                }
                return email;
            })
            setSelectedCustomRecipientEmails(customEmails);
        }
    }

    const handleSaveActivePriceList = () => {
        if (templateType === "editPriceList") {
            let postPriceListData = {...requestData, ...PRODUCTS_DATA.editPriceList};
            postPriceListData.updateAnyway = "true";
            let haveProducts = false;
            let haveName = false;
            if (postPriceListData.name && postPriceListData?.name !== "") {
                haveName = true;
            } else {
                toast.error("Name is required field.");
            }
            if (postPriceListData.productList && postPriceListData?.productList?.length > 0) {
                haveProducts = true;
            } else {
                toast.error("Please check all mandatory fields.");
            }
            if (haveProducts && haveName) {
                dispatch(putPriceList(postPriceListData, requestData, "update", setLoadingSave, setActivePriceListModal)); // Put Price List To API
            }
        } else {
            let postPriceListData = {...requestData, ...PRODUCTS_DATA.newPriceList};
            postPriceListData.updateAnyway = "true";
            let haveProducts = false;
            let haveName = false;
            if (postPriceListData.name && postPriceListData?.name !== "") {
                haveName = true;
            } else {
                toast.error("Name is required field.");
            }
            if (postPriceListData.productList && postPriceListData?.productList?.length > 0) {
                haveProducts = true;
            } else {
                toast.error("Please check all mandatory fields.");
            }
            if (haveProducts && haveName) {
                if (postPriceListData._id) {
                    dispatch(putPriceList(postPriceListData, requestData, "update", setLoadingSave, setActivePriceListModal)); // Post Price List To API
                } else {
                    dispatch(postPriceList(postPriceListData, requestData, true, setLoadingSave, setActivePriceListModal)); // Post Price List To API
                }
            }
        }
    }

    const handleDelete = () => {
        //console.log(TABS_DATA);
        //console.log(templateType);
        showDeletionModal(true);
        if (templateType === "editProduct") {
            setToBeDeleted(PRODUCTS_DATA.selectedEditProduct._id);
            setDeleteModalTableName("Search Products");
        } else if (templateType === "editClientPayments") {
            setToBeDeleted(CLIENTS_DATA.editClientPayment._id);
            setDeleteModalTableName("Client Payments Search");
        } else if (templateType === "editProductMovements") {
            setToBeDeleted(PRODUCTS_DATA.editProductMovements._id);
            setDeleteModalTableName("Search Product Movements");
        } else if (templateType === "editPriceList") {
            setToBeDeleted(PRODUCTS_DATA.editPriceList._id);
            setDeleteModalTableName("Search Product Price List");
        } else if (templateType === "editClient") {
            setToBeDeleted(CLIENTS_DATA.editClient._id);
            setDeleteModalTableName("Client Search");
        } else if (templateType === "editClientMovements") {
            setToBeDeleted(CLIENTS_DATA.editClientMovements._id);
            setDeleteModalTableName("Client Movements Search");
        } else if (templateType === "editSupplier") {
            setToBeDeleted(SUPPLIERS_DATA.editSupplier._id);
            setDeleteModalTableName("Supplier Search");
        } else if (templateType === "editSupplierMovements") {
            setToBeDeleted(SUPPLIERS_DATA.editSupplierMovements._id);
            setDeleteModalTableName("Supplier Movements Search");
        } else if (templateType === "editCashRegisters") {
            setToBeDeleted(CASH_DATA.editCashRegisters._id);
            setDeleteModalTableName("Cash Registers Search");
        } else if (templateType === "editPOS") {
            setToBeDeleted(CASH_DATA.editPOS._id);
            setDeleteModalTableName("POS Search");
        } else if (templateType === "editBankAccounts") {
            setToBeDeleted(CASH_DATA.editBankAccount._id);
            setDeleteModalTableName("Bank Account Search");
        } else if (templateType === "editBasicType") {
            setToBeDeleted(SETTINGS.settings.editBasicType._id);
            setDeleteModalTableName("Basic Types Search");
        } else if (templateType === "editAdvancedType") {
            setToBeDeleted(SETTINGS.settings.editAdvancedType._id);
            setDeleteModalTableName("Advanced Types Search");
        } else if (templateType === "editSales") {
            setToBeDeleted(SALES.editSales._id);
            setDeleteModalTableName("Sales Search");
        }
    }

    const showDeletionModal = (bool) => {
        if (bool === false) {
            setToBeDeleted("");
            setDeleteModalTableName("");
        }
        setShowDeleteModal(bool);
    }

    const handleAfterDelete = () => {
        if (templateType === "editProduct") {
            dispatch(setForceRefresh({...SEARCH_TABLE.forceRefresh, "Search Products": true}));
            dispatch(removeTab("edit-product"));
        } else if (templateType === "editClientPayments") {
            dispatch(setForceRefresh({...SEARCH_TABLE.forceRefresh, "Client Payments Search": true}));
            dispatch(removeTab("edit-client-payments"));
        } else if (templateType === "editProductMovements") {
            dispatch(setForceRefresh({...SEARCH_TABLE.forceRefresh, "Search Product Movements": true}));
            dispatch(removeTab("edit-product-movements"));
        } else if (templateType === "editPriceList") {
            dispatch(setForceRefresh({...SEARCH_TABLE.forceRefresh, "Search Product Price List": true}));
            dispatch(removeTab("edit-price-list"));
        } else if (templateType === "editClient") {
            dispatch(setForceRefresh({...SEARCH_TABLE.forceRefresh, "Client Search": true}));
            dispatch(removeTab("edit-client"));
        } else if (templateType === "editClientMovements") {
            dispatch(setForceRefresh({...SEARCH_TABLE.forceRefresh, "Client Movements Search": true}));
            dispatch(removeTab("edit-client-movements"));
        } else if (templateType === "editSupplier") {
            dispatch(setForceRefresh({...SEARCH_TABLE.forceRefresh, "Supplier Search": true}));
            dispatch(removeTab("edit-supplier"));
        } else if (templateType === "editSupplierMovements") {
            dispatch(setForceRefresh({...SEARCH_TABLE.forceRefresh, "Supplier Movements Search": true}));
            dispatch(removeTab("edit-supplier-movements"));
        } else if (templateType === "editCashRegisters") {
            dispatch(setForceRefresh({...SEARCH_TABLE.forceRefresh, "Cash Registers Search": true}));
            dispatch(removeTab("edit-cash-registers"));
        } else if (templateType === "editPOS") {
            dispatch(setForceRefresh({...SEARCH_TABLE.forceRefresh, "POS Search": true}));
            dispatch(removeTab("edit-pos"));
        } else if (templateType === "editBankAccounts") {
            dispatch(setForceRefresh({...SEARCH_TABLE.forceRefresh, "Bank Account Search": true}));
            dispatch(removeTab("edit-bank-account"));
        } else if (templateType === "editBasicType") {
            dispatch(setForceRefresh({...SEARCH_TABLE.forceRefresh, "Basic Types Search": true}));
            dispatch(removeTab("edit-basic-types"));
        } else if (templateType === "editAdvancedType") {
            dispatch(setForceRefresh({...SEARCH_TABLE.forceRefresh, "Advanced Types Search": true}));
            dispatch(removeTab("edit-advanced-types"));
        } else if (templateType === "editSales") {
            dispatch(setForceRefresh({...SEARCH_TABLE.forceRefresh, "Sales Search": true}));
            dispatch(removeTab("view-sales"));
        }
    }

    // Convert Document Confirmation
    const handleConvertDocumentConfirm = (salesId) => {
        setDisableConvertOkBtn(true);

        if (salesId) {
            let data = {
                salesDocumentId: salesId,
            };
            axios
                .post(apiURL2 + `/sale/create-convert-document`, JSON.stringify(data), {
                    headers: {"Content-Type": "application/json"},
                })
                .then((res) => {
                    if (res.data && res.data.status === "200") {
                        toast.success(res.data.message);
                        setEnableCDBtn(false);
                    }

                    if (res.data && res.data?.error === "400") {
                        toast.error(res.data.message);
                    }

                    if (res.data && res.data?.error === "409") {
                        setEnableCDBtn(false);
                        toast.error(res.data.message);
                    }

                    setShowConvertDocumentConfirmation(false);
                })
                .catch((error) => {
                    if (error && error.response && error.response.data && error.response.data.errors) {
                        toast.error(error.response.data.errors);
                    }
                    setDisableConvertOkBtn(false);
                });
        } else {
            toast.success("Sales id not found.");
            setDisableConvertOkBtn(false);
        }
    };

    // Issue Credit Document Confirmation
    const handleIssueCreditDocumentConfirm = (salesId, setButton) => {
        setDisableIssueCreditOkBtn(true);
        if (salesId) {
            let data = {
                salesDocumentId: salesId,
            };
            setButton(true);
            axios.post(apiURL2 + `/sale/issue-credit-document`, JSON.stringify(data), {
                headers: {"Content-Type": "application/json"},
            }).then((res) => {
                setButton(false);
                if (res.data && res.data.status === "200") {
                    toast.success(res.data.message);
                    setEnableIssueCDBtn(false);
                } else if (res.data && res.data?.error === "400") {
                    toast.error(res.data.message);
                } else if (res.data && res.data?.error === "409") {
                    setEnableIssueCDBtn(false);
                    toast.error(res.data.message);
                } else {
                    toast.error(res.data?.message);
                }

                setShowIssueCreditDocumentConfirmation(false);
            }).catch((error) => {
                setButton(false);
                if (error && error.response && error.response.data && error.response.data.errors) {
                    toast.error(error.response.data.errors);
                    setDisableIssueCreditOkBtn(true);
                }
            });
        } else {
            toast.success("Sales id not found.");
            setDisableIssueCreditOkBtn(true);
        }
    };

    // Handle Delete Template
    const handleDeleteTemplate = (e, key, row) => {
        const deleteRequest = {
            id: row.value,
            name: row.label,
            isActive: "delete",
            company: company.id,
            year: company.year.toString(),
            type: templateType,
        };
        dispatch(putTemplate(deleteRequest, requestData, "delete"));
    };

    const templateColumns = [{
        label: t('SaveAsTemplateIndex.placeholder'), name: "label",
    },];

    let showPrintBtn = false;
    // Enable or Disable Save / Save Repeat Button
    let actionsDisabled = !ACTIVITY.editing;

    if (templateType === "product") {
        actionsDisabled = !ACTIVITY.saveNewProduct;
    }

    if (templateType === "editProduct") {
        actionsDisabled = !ACTIVITY.saveEditProduct;
    }

    if (templateType === "sales") {
        actionsDisabled = !ACTIVITY.saveSales;
        showPrintBtn = isValidSalesData(SALES.newSales);
        if (ACTIVITY?.sendEmail === "active" && ((selectedRecipientEmails.length === 0 && selectedCustomRecipientEmails.length === 0) || emailError === true)) {
            actionsDisabled = true;
        }
    }

    if (templateType === "buys") {
        actionsDisabled = !ACTIVITY.saveBuys;
        showPrintBtn = isValidBuysData(BUYS.newBuys);
        printLayoutOptions = buysPrintTemplates();
        if (!(BUYS.newBuys?.myDataType === "3.1" || BUYS.newBuys?.myDataType === "3.2")) {
            printLayoutDropdown = false;
            printBtn = false;
            sendEmailOnSave = false;
            if (ACTIVITY?.sendEmail) {
                dispatch(setSendEmail(false));
            }
        }
    }

    if (templateType === "client") {
        actionsDisabled = !ACTIVITY.saveNewClient;
    }

    if (templateType === "editClient") {
        actionsDisabled = !ACTIVITY.saveEditClient;
    }
    if (templateType === "supplier") {
        actionsDisabled = !ACTIVITY.saveNewSupplier;
    }

    if (templateType === "editSupplier") {
        actionsDisabled = !ACTIVITY.saveEditSupplier;
    }

    if (templateType === "editSales") {
        actionsDisabled = !ACTIVITY.saveEditSales;
        showPrintBtn = isValidSalesData(SALES.editSales);
    }
    if (templateType === "editBuys") {
        actionsDisabled = !ACTIVITY.saveEditBuys;
        showPrintBtn = isValidBuysData(BUYS.editBuys);
        printLayoutOptions = buysPrintTemplates();
        if (!(BUYS.editBuys?.myDataType === "3.1" || BUYS.editBuys?.myDataType === "3.2")) {
            printLayoutDropdown = false;
            printBtn = false;
            if (ACTIVITY?.sendEmail) {
                dispatch(setSendEmail(false));
            }
        }
    }

    if (templateType === "productMovements") {
        actionsDisabled = !ACTIVITY.saveNewProductMovements;
    }

    if (templateType === "editProductMovements") {
        actionsDisabled = !ACTIVITY.saveEditProductMovements;
    }

    if (templateType === "priceList") {
        actionsDisabled = !ACTIVITY.saveNewPriceList;
    }

    if (templateType === "editPriceList") {
        actionsDisabled = !ACTIVITY.saveEditPriceList;
    }

    if (templateType === "cashRegisters") {
        actionsDisabled = !ACTIVITY.saveNewCashRegister;
    }

    if (templateType === "POS") {
        actionsDisabled = !ACTIVITY.saveNewPOS;
    }

    if (templateType === "bankAccounts") {
        actionsDisabled = !ACTIVITY.saveNewBankAccounts;
    }

    if (templateType === "editCashRegisters") {
        actionsDisabled = !ACTIVITY.saveEditCashRegister;
    }

    if (templateType === "editPOS") {
        actionsDisabled = !ACTIVITY.saveEditPOS;
    }

    if (templateType === "editBankAccounts") {
        actionsDisabled = !ACTIVITY.saveEditBankAccounts;
    }

    if (templateType === "salesGeneralSettings") {
        actionsDisabled = !ACTIVITY.saveSalesSettings;
    }

    if (templateType === "buysGeneralSettings") {
        actionsDisabled = !ACTIVITY.saveBuysSettings;
    }

    if (templateType === "settingsQuickRetailSales") {
        actionsDisabled = !ACTIVITY.saveQuickRetailSettings;
    }

    if (templateType === "basicType") {
        actionsDisabled = !ACTIVITY.saveBasicType;
    }

    if (templateType === "editBasicType") {
        actionsDisabled = !ACTIVITY.saveEditBasicType;
    }

    if (templateType === "advancedType") {
        actionsDisabled = !ACTIVITY.saveAdvancedType;
    }

    if (templateType === "editAdvancedType") {
        actionsDisabled = !ACTIVITY.saveEditAdvancedType;
    }

    if (templateType === "automaticSales") {
        actionsDisabled = !ACTIVITY.saveAutomaticSales;
    }

    if (templateType === "quickRetailSales") {
        actionsDisabled = !ACTIVITY.saveQuickRetailSales;
    }

    if (templateType === "clientPayments") {
        actionsDisabled = !ACTIVITY.saveClientPayments;
        printLayoutOptions = clientPaymentsPrintTemplates();
    }
    if (templateType === "supplierPayments") {
        actionsDisabled = !ACTIVITY.saveSupplierPayments;
    }

    if (templateType === "editClientPayments") {
        actionsDisabled = !ACTIVITY.saveEditClientPayments;
        showPrintBtn = true
        printLayoutOptions = clientPaymentsPrintTemplates();
    }

    if (templateType === "editSupplierPayments") {
        actionsDisabled = !ACTIVITY.saveEditSupplierPayments;
    }

    if (templateType === "settingsPrimeData") {
        actionsDisabled = !ACTIVITY.savePrimerMyDataData;
    }

    if (templateType === "settingsEmailSmtp") {
        actionsDisabled = !ACTIVITY.saveEmailSMtp;
    }
    if (templateType === "newEmployee") {
        actionsDisabled = !ACTIVITY.saveNewEmployee;
    }
    if (templateType === "newAccountingRecord") {
        actionsDisabled = !ACTIVITY.saveNewAccountingRecord;
    }
    if (templateType === "editAccountingRecord") {
        actionsDisabled = !ACTIVITY.saveEditAccountingRecord;
    }
    if (templateType === "editEmployee") {
        actionsDisabled = !ACTIVITY.saveEditEmployee;
    }
    if (templateType === "newAccountingAccount") {
        actionsDisabled = !ACTIVITY.saveNewAccountingAccount;
    }
    if (templateType === "editAccountingAccount") {
        actionsDisabled = !ACTIVITY.saveEditAccountingAccount;
    }
    if (templateType === "cashAccountingSettings") {
        actionsDisabled = !ACTIVITY.saveCashAccountingSettings;
    }
    if (templateType === "newWorkMovement") {
        actionsDisabled = !ACTIVITY.saveNewWorkMovement;
    }
    if (templateType === "editWorkMovement") {
        actionsDisabled = !ACTIVITY.saveEditWorkMovement;
    }
    if (templateType === "newWorkPermit") {
        actionsDisabled = !ACTIVITY.saveNewWorkPermit;
    }
    if (templateType === "editWorkPermit") {
        actionsDisabled = !ACTIVITY.saveEditWorkPermit;
    }

    useEffect(() => {
        if (printLayoutOptions.filter((el) => el.value === SALES.printLayout).length === 0) {
            dispatch(setPrintLayout(''));
        }
    }, [printLayoutOptions])

    /*
  useEffect(() => {
    if(ACTIVITY.rememberScroll[tabs[activeLink].tabName]) {
      wholePageRef.current.scrollTo({top: ACTIVITY.rememberScroll[tabs[activeLink].tabName]})
      dispatch(setScroll({...ACTIVITY.rememberScroll, [tabs[activeLink].tabName] : 0}));
    }
    wholePageRef.current?.addEventListener("scroll", handleScrollChange, { passive: true });
    return () => wholePageRef.current?.removeEventListener('scroll', handleScrollChange);
  }, [])

  const handleScrollChange = (e) => {
    if(wholePageRef.current.scrollTop % 100 === 0) {
      dispatch(setScroll({...ACTIVITY.rememberScroll, [tabs[activeLink].tabName] : wholePageRef.current.scrollTop}));
      console.log(wholePageRef.current.scrollTop);
    }
  }
  */

    // template type -> permission prefix for save and delete buttons plus edit functionality
    const permissionsMappingSave = {
        "product": "products-products/new",
        "editProduct" : "products-products/edit",
        "productMovements": "products-productsmovements/new",
        "editProductMovements": "products-productsmovements/edit",
        "priceList": "products-pricelist/new",
        "editPriceList": "products-pricelist/edit",
        "sales": "sales-sales/new",
        "quickRetailSales": "sales-quickretailsales/new",
        "clientPayments": "sales-clientpayments/new",
        "editClientPayments": "sales-clientpayments/edit",
        "automaticSales": "sales-automaticsales/new",
        "buys": "buys-buy/new",
        "editBuys": "buys-buy/edit",
        "supplierPayments": "buys-supplierpayments/new",
        "editSupplierPayments": "buys-supplierpayments/edit",
        "client": "clients-clients/new",
        "editClient": "clients-clients/edit",
        "clientMovements": "clients-clientmovements/new",
        "editClientMovements": "clients-clientmovements/edit",
        "supplier": "suppliers-suppliers/new",
        "editSupplier": "suppliers-suppliers/edit",
        "supplierMovements": "suppliers-suppliermovements/new",
        "editSupplierMovements": "suppliers-suppliermovements/edit",
        "cashRegisters": "cash-cashregisters/new",
        "editCashRegisters": "cash-cashregisters/edit",
        "POS": "cash-pos/new",
        "editPOS": "cash-pos/edit",
        "bankAccounts": "cash-bankaccounts/new",
        "editBankAccounts": "cash-bankaccounts/edit",
        "salesGeneralSettings": "sales-settings/salesgeneralsettings",
        "settingsQuickRetailSales": "sales-settings/quickretailsales",
        "buysGeneralSettings": "buys-settings/buygeneralsettings",
        "clientsSettings": "clients-settings/clientsettings",
        "suppliersSettings": "suppliers-settings/suppliersettings",
        "basicType": "settings-documenttype/basictypesnew",
        "editBasicType": "settings-documenttype/basictypesedit",
        "advancedType": "settings-documenttype/advancedtypesnew",
        "editAdvancedType": "settings-documenttype/advancedtypesedit",
        "settingsPrimeData": "settings-primermydata/data",
        "settingsEmailSmtp": "settings-generalsettings/emailsmtp",
        "newEmployee": "accounting-employees/new",
        "editEmployee": "accounting-employees/edit",
        "newAccountingAccount": "accounting-accountingaccounts/new",
        "editAccountingAccount": "accounting-accountingaccounts/edit",
        "newAccountingRecord": "accounting-accountingrecords/new",
        "editAccountingRecord": "accounting-accountingrecords/edit",
        "newWorkMovement": "payroll-workmovements/new",
        "editWorkMovement": "payroll-workmovements/edit",
        "newWorkPermit": "payroll-workpermits/new",
        "editWorkPermit": "payroll-workpermits/edit",
    }
    const handlePaymentReturn = () => {
        if (CLIENTS_DATA.editClientPayment.myDataType === "8.4") {
            setShowPaymentReturnModal(false);
            setShowPOSModalCancel(true);
        }
        axios.post(process.env.REACT_APP_API_URL2 + "/client/return-client-payment", {company: company.id, cpId: CLIENTS_DATA.editClientPayment._id}, {
            headers: {"Content-Type": "application/json"},
        }).then((res) => {
            setShowPaymentReturnModal(false);
            setShowPOSModalCancel(false);
            if (res.data.status === "200") {
                toast.success(res.data.message);
                setPaymentReturnable(false);
            } else {
                toast.error(res.data.message);
            }
        }).catch((err) => {
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
            setShowPaymentReturnModal(false);
            setShowPOSModalCancel(false);
            console.log(err);
        })
    }

    return (<div className="data-panel-wr">
            {templateEnable && checkPermission(permissionsMappingSave[templateType] + "-write", permissionsData) && (
                <div className="template-header-ui template-select border-bottom pb-1 mb-3">
                    <Row>
                        <Col xs="12" md="4">
                            <div className="inline-select-ui-4 template-dropdown">
                                <AdvancedCombobox
                                    className="w-100 ac-select-dropdown"
                                    searchableDropdownEnable={false}
                                    defaultValue={defaultTemplate !== null ? defaultTemplate.label : ""}
                                    key={Math.random()}
                                    label={t('DataPanelIndex.template.template')}
                                    data={templateOptions(templateType)}
                                    onChange={(e) => {
                                        handleTemplateChange(e);
                                    }}
                                    excludeColumns={[]}
                                    columns={templateColumns}
                                    editEnable={false}
                                    deleteEnable={true}
                                    searchTableEnable={false}
                                    showTableOptionOnSelect={true}
                                    placeholder={t('DataPanelIndex.template.placeholder')}
                                    onDelete={handleDeleteTemplate}
                                />
                            </div>
                        </Col>
                        <Col
                            className="text-center justify-content-center align-items-center d-flex"
                            xs="12"
                            md="4"
                            xxl="6"
                        >
                            {<b className="heading">{t(headerNew)}</b>}
                        </Col>
                        <Col
                            xs="12"
                            md="4"
                            className=" justify-content-end align-items-center d-flex"
                        >
                            <ModalInstructions data={dataInstructions}/>
                            <SaveAsTemplate
                                templateData={templateFormData(templateType)}
                                requestData={requestData}
                                templateType={templateType}
                            />
                        </Col>
                    </Row>
                </div>)}

            {(!templateEnable || !checkPermission(permissionsMappingSave[templateType] + "-write", permissionsData)) && (
                <div className="template-header-ui dp-heading text-center border-bottom pb-4 mb-3 no-print">
                    {<b className="heading">{t(headerNew)}</b>}
                </div>)}

            {/* Product Inner Tabs */}

            <ul className="react-tabs__tab-list tab-style tab-min template-min-tab no-print">
                {tabs.map((tab, i) => {
                    if (checkPermission(tab.permission, permissionsData)) {
                        if (!(templateType === "advancedType" && SETTINGS.settings.newAdvancedType?.section === "Accounting Records" && tab.key === "advanced-types-new-accounting")
                        && !(templateType === "editAdvancedType" && SETTINGS.settings.editAdvancedType?.section === "Accounting Records" && tab.key === "advanced-types-edit-accounting")) {
                            return (<li key={i} className="react-tabs__tab">
                                <button
                                    onClick={() => {
                                        if (tab.key === "settings-import-import" || tab.key === "settings-import-settings") {
                                            changeLinkImport(i);
                                        } else if (tab.key === "settings-export-export" || tab.key === "settings-export-settings") {
                                            changeLinkExport(i);
                                        } else {
                                            setActiveLink(i);
                                        }
                                    }}
                                    className={`customNavBtn ${activeLink === i && "btnActive"}`}
                                >
                                    <span className="textWrapper">{t(tab.tabName)}</span>
                                </button>
                            </li>);
                        }
                    }
                })}
            </ul>

            {/* BODY */}

            <div className="innerTabPanel data-panel-ui">
                {activeLink === "Start" ? <Start/> : tabs[activeLink]?.tabData}
            </div>
            {ACTIVITY?.sendEmail === "active" && sendEmailOnSave && (
                <div className="dp-footer d-flex justify-content-between">
                    <div className="d-flex justify-content-start">
                    </div>
                    <div className="d-flex justify-content-end">
                        <Row>
                            <Col>
                                <label htmlFor="contactPoints"
                                       className="mb-1 ml-3">{t('DataPanelIndex.sendEmailToExistingClientContactPoints')} </label>
                                <Dropdown
                                    multiSelect={true}
                                    position="top"
                                    options={recipientEmails}
                                    placeholder={t('DataPanelIndex.placeholder')}
                                    name={'contactPoints'}
                                    className="email-recipients"
                                    defaultValue={defaultRecipientEmails}
                                    value={defaultRecipientEmails}
                                    onChange={(e) => handleOnEmailChange(e, 'msdd', 'gf')}
                                />
                                {(selectedRecipientEmails.length === 0 && selectedCustomRecipientEmails.length === 0) &&
                                    <div
                                        className="text-danger align-items-center d-flex justify-content-center">{t('DataPanelIndex.pleaseSelectAtLeastOneEmailAddress')}</div>}
                            </Col>
                            <Col>
                                <Input
                                    name="customEmails"
                                    placeholder={t('DataPanelIndex.sendEmailToCustomEmailAddresses.placeholder')}
                                    type="text"
                                    label={t('DataPanelIndex.sendEmailToCustomEmailAddresses.sendEmailToCustomEmailAddresses')}
                                    className="mb-3 custom-email-recipients"
                                    // value={selectedCustomRecipientEmails}
                                    onChange={(e) => handleOnEmailChange(e, 'dd', 'customEmails')}
                                />
                                {emailError === true && <div
                                    className="text-danger">{t('DataPanelIndex.sendEmailToCustomEmailAddresses.warning')}</div>}
                            </Col>
                        </Row>
                    </div>
                </div>)}

            <div className="dp-footer d-flex justify-content-between">
                <div className="d-flex justify-content-start">
                    {printLayoutDropdown && (<Dropdown
                            classes="inline-dropdown print-layout"
                            label={t('DataPanelIndex.printLayout')}
                            key={Math.random()}
                            defaultValue={getDefaultOptionByValue(printLayoutOptions, SALES.printLayout, "value", "label", "value")}
                            name="printLayout"
                            options={printLayoutOptions}
                            position="top"
                            onChange={(e) => handePrintLayoutChange(e)}
                        />)}

                    {printBtn && (<Button
                            name="clear"
                            onClick={() => handleDpActions("print")}
                            className="ml-2 mb-3"
                            disabled={!showPrintBtn}
                        >
                            {t('DataPanelIndex.print')}
                        </Button>)}
                </div>
                <div className="d-flex justify-content-end">
                    {sendEmailOnSave && (
                        <Row>
                            <Col className="mt-3">
                                <Checkbox
                                    className="w-100 sendEmailOnSave"
                                    text={t('DataPanelIndex.sendEmailOnSave')}
                                    name="sendEmailOnSave"
                                    key={Math.random()}
                                    defaultValue={ACTIVITY?.sendEmail}
                                    value={ACTIVITY?.sendEmail === "active" ? "inactive" : "active"}
                                    onChange={(e) => handleSendEmailOnSave(e)}
                                />
                            </Col>
                        </Row>
                    )}
                    {(PAYROLL.companyHasErgani && (templateType === "newEmployee" || templateType === "editEmployee")) && (
                        <Row>
                            <Col className={"mt-2"}>
                                <BetterCheckBox
                                    text={"Αποστολή QR κατά την αποθήκευση"}
                                    checked={ACTIVITY.sendEmployeeQROnSave}
                                    onChange={(e) => dispatch(setSendEmployeeQROnSave(e.target.checked))}
                                />
                            </Col>
                        </Row>
                    )}
                    {convertDocument && enableConvertDocumentButton && (<Button
                            name="clear"
                            onClick={() => handleDpActions("convertDocument")}
                            disabled={loadingSave}
                            className="ml-2 mb-3"
                        >
                            {t('DataPanelIndex.covertDocument')}
                        </Button>)}

                    {issueCreditDocument && enableIssueCreditDocumentButton && (<Button
                            name="clear"
                            onClick={() => handleDpActions("issueCreditDocument")}
                            disabled={loadingSave}
                            className="ml-2 mb-3"
                        >
                            {t('DataPanelIndex.issueCreditDocument')}
                        </Button>)}
                    {cancelMyDataInvoice && templateType === "editSales" && SALES.editSales.myDataStatus === "sent" && SALES.editSales.myDataDestination !== "Provider" && SALES.editSales.myDataDestination !== "provider" && (
                        <Button
                            name="clear"
                            onClick={() => handleDpActions("cancelMyDataInvoice")}
                            disabled={loadingSave}
                            className="ml-2 mb-3"
                        >
                            {t('DataPanelIndex.cancelInvoiceFromMyData')}
                        </Button>
                    )}
                    {(cancelMyDataInvoice && templateType === "editBuys" && BUYS.editBuys.myDataStatus === "sent" && BUYS.editBuys.myDataDestination !== "Provider" && BUYS.editBuys.myDataDestination !== "provider" && BUYS.editBuys?.fromMyDataReport !== "true") && (
                        <Button
                            name="clear"
                            onClick={() => handleDpActions("cancelMyDataInvoice")}
                            disabled={loadingSave}
                            className="ml-2 mb-3"
                        >
                            {t('DataPanelIndex.cancelInvoiceFromMyData')}
                        </Button>
                    )}
                    {(cancelMyDataInvoice && templateType === "editAccountingRecord" && ACCOUNTING_DATA.editAccountingRecord.myDataStatus === "sent") && (
                        <Button
                            name="clear"
                            onClick={() => handleDpActions("cancelMyDataInvoice")}
                            disabled={loadingSave}
                            className="ml-2 mb-3"
                        >
                            {t('DataPanelIndex.cancelInvoiceFromMyData')}
                        </Button>
                    )}
                    {clearBtn && (<Button
                            name="clear"
                            onClick={() => handleDpActions("clear")}
                            disabled={loadingSave}
                            className="ml-2 mb-3"
                        >
                            {t('DataPanelIndex.clear')}
                        </Button>)}

                    {saveandrepeat && checkPermission(permissionsMappingSave[templateType] + "-write", permissionsData) && (
                        <Button
                            name="saveandrepeat"
                            onClick={() => handleDpActions("saveandrepeat")}
                            disabled={actionsDisabled || loadingSaveAndRepeat || loadingSave}
                            className="ml-2 mb-3"
                        >
                            {loadingSaveAndRepeat && (<span
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                ></span>)}
                            {t('DataPanelIndex.saveAndRepeat')}
                        </Button>)}

                    {deleteBtn && checkPermission(permissionsMappingSave[templateType] + "-delete", permissionsData) && (
                        <Button
                            name="delete"
                            onClick={() => handleDelete()}
                            disabled={loadingSave}
                            className="ml-2 mb-3"
                        >
                            {t('DataPanelIndex.delete')}
                        </Button>)}
                    {(templateType === "editSales" && SALES.editSales?.myDataDestination !== "provider" && SALES.editSales?.myDataStatus === "not_sent" && checkPermission(permissionsMappingSave[templateType] + "-delete", permissionsData)) && (
                        <Button
                            name="delete"
                            onClick={() => handleDelete()}
                            disabled={loadingSave}
                            className="ml-2 mb-3"
                        >
                            {t('DataPanelIndex.delete')}
                        </Button>)}
                    {(templateType === "editClientPayments" && paymentReturnable) && (
                        <Button className="ml-2 mb-3" onClick={() => setShowPaymentReturnModal(true)}>Έκδοση επιστροφής</Button>
                    )}
                    {saveBtn && checkPermission(permissionsMappingSave[templateType] + "-write", permissionsData) && (
                        <Button
                            name="save"
                            onClick={() => handleDpActions("save")}
                            disabled={actionsDisabled || loadingSave || loadingSaveAndRepeat}
                            type="submit"
                            className="ml-2 mb-3"
                        >
                            {loadingSave && (<span
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                ></span>)}
                            {t('DataPanelIndex.save')}
                        </Button>)}
                </div>
            </div>
            <div ref={scrollToEnd}></div>

            {/* Confirm Template Change */}
            <Modal className="themeModal" show={showTemplateConfirmation}>
                <Modal.Header>
                    <Modal.Title>{t('DataPanelIndex.templateChange')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {t('DataPanelIndex.templateWarning')}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="outline-primary"
                        onClick={() => setShowTemplateConfirmation(false)}
                    >
                        {t('DataPanelIndex.no')}
                    </Button>
                    <Button variant="primary" onClick={() => handleConfirmTemplate()}>
                        {t('DataPanelIndex.yes')}
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Price List Confirmation */}
            <Modal className="themeModal" show={activePriceListModal}>
                <Modal.Header>
                    <Modal.Title>{t('DataPanelIndex.activateConfirmation')}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    {t('DataPanelIndex.activateMessage')}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="outline-primary"
                        onClick={() => setActivePriceListModal(false)}
                    >
                        {t('DataPanelIndex.cancel')}
                    </Button>
                    <Button variant="primary" onClick={() => handleSaveActivePriceList()}>
                        {t('DataPanelIndex.yes')}
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Convert Document Confirmation */}
            <Modal className="themeModal" show={showConvertDocumentConfirmation}>
                <Modal.Header>
                    <Modal.Title>{t('DataPanelIndex.convertDocumentConfirmation')}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    {t('DataPanelIndex.convertDocumentMessage')}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        disabled={disableConvertOkBtn}
                        variant="primary"
                        onClick={() => handleConvertDocumentConfirm(SALES.editSales?._id)}
                    >
                        {t('DataPanelIndex.yes')}
                    </Button>
                    <Button
                        variant="outline-primary"
                        onClick={() => setShowConvertDocumentConfirmation(false)}
                    >
                        {t('DataPanelIndex.no')}
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Issue Credit Document */}
            <Modal className="themeModal" show={showIssueCreditDocumentConfirmation}>
                <Modal.Header>
                    <Modal.Title>{t('DataPanelIndex.issueCreditDocumentConfirmation')}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    {t('DataPanelIndex.issueCreditDocumentMessage')}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        disabled={disableIssueCreditOkBtn}
                        variant="primary"
                        onClick={() => handleIssueCreditDocumentConfirm(SALES.editSales?._id, setDisableIssueCreditOkBtn)}
                    >
                        {t('DataPanelIndex.yes')} {disableIssueCreditOkBtn && <Spinner className={"ml-2"} animation="border" variant="dark"/>}
                    </Button>
                    <Button
                        variant="outline-primary"
                        disabled={disableIssueCreditOkBtn}
                        onClick={() => setShowIssueCreditDocumentConfirmation(false)}
                    >
                        {t('DataPanelIndex.no')}
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal className="themeModal" show={showCancelDocumentConfirmation}>
                <Modal.Header>
                    <Modal.Title>{t('DataPanelIndex.cancelMyDataDocumentConfirmation')}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    {t('DataPanelIndex.cancelMyDataDocumentMessage')}
                </Modal.Body>
                <Modal.Footer>
                    {templateType === "editSales" && (
                        <Button
                            disabled={disableCancelDocumentOkBtn}
                            variant="primary"
                            onClick={() => dispatch(cancelMyDataSale({
                                saleId: SALES.editSales?._id, company: company.id, year: company.year
                            }, setShowCancelDocumentConfirmation, setDisableCancelDocumentOkBtn))}
                        >
                            {t('DataPanelIndex.yes')} {disableCancelDocumentOkBtn && <Spinner className={"ml-2"} animation="border" variant="dark"/>}
                        </Button>
                    )}
                    {templateType === "editBuys" && (
                        <Button
                            disabled={disableCancelDocumentOkBtn}
                            variant="primary"
                            onClick={() => dispatch(cancelMyDataBuy({
                                buyId: BUYS.editBuys?._id, company: company.id, year: company.year
                            }, setShowCancelDocumentConfirmation, setDisableCancelDocumentOkBtn, TABS_DATA))}
                        >
                            {t('DataPanelIndex.yes')} {disableCancelDocumentOkBtn && <Spinner className={"ml-2"} animation="border" variant="dark"/>}
                        </Button>
                    )}
                    {templateType === "editAccountingRecord" && (
                        <Button
                            disabled={disableCancelDocumentOkBtn}
                            variant="primary"
                            onClick={() => dispatch(cancelMyDataAccountingRecord({
                                recordId: ACCOUNTING_DATA.editAccountingRecord?._id, company: company.id, year: company.year
                            }, setShowCancelDocumentConfirmation, setDisableCancelDocumentOkBtn))}
                        >
                            {t('DataPanelIndex.yes')} {disableCancelDocumentOkBtn && <Spinner className={"ml-2"} animation="border" variant="dark"/>}
                        </Button>
                    )}
                    <Button
                        variant="outline-primary"
                        disabled={disableCancelDocumentOkBtn}
                        onClick={() => setShowCancelDocumentConfirmation(false)}
                    >
                        {t('DataPanelIndex.no')}
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showPOSModal}>
                <Modal.Header>
                    <Modal.Title>Πληρωμή με POS</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {showPOSModalText}
                    <img src={"assets/images/POS.gif"} style={{display: "block", marginLeft: "auto", marginRight: "auto", width: "80%"}} />
                </Modal.Body>
            </Modal>

            <Modal show={showPOSModalCancel}>
                <Modal.Header>
                    <Modal.Title>Ακύρωση Πληρωμής</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <img src={"assets/images/POSReturn.gif"} style={{display: "block", marginLeft: "auto", marginRight: "auto", width: "80%"}} />
                </Modal.Body>
            </Modal>

            <Modal show={showPaymentReturnModal} onHide={() => setShowPaymentReturnModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Έκδοση Επιστροφής</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Είσαστε σίγουροι ότι θέλετε να εκδώσετε επιστροφή σε αυτήν την είσπραξη;
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={() => setShowPaymentReturnModal(false)}>Όχι, ακύρωση</Button>
                    <Button onClick={() => handlePaymentReturn()}>Ναι, έκδοση</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={emronAdminCancellationModal} backdrop="static" onHide={() => setEmronAdminCancellationModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Απαιτείται προσοχή!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Οι τροποποιήσεις που γίνανε στον πελάτη απαιτούν ακύρωση συνδρομής. Είσαστε σίγουροι ότι θέλετε να προχωρήσετε;
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={() => setEmronAdminCancellationModal(false)}>Όχι</Button>
                    <Button onClick={() => handleDpActions("save", "editClient")}>Ναι, ακύρωση συνδρομής</Button>
                </Modal.Footer>
            </Modal>

            {/* Print Layout Templates */}
            {(SALES.printLayout === 'sales-print-template1-greek' || !SALES.printLayout) && (templateType === "sales" || templateType === "editSales") &&
                <SalesPrintTemplateGreek1 showWaterMark={false} ref={componentRef} data={printTemplateData}/>}
            {SALES.printLayout === 'sales-print-template1-english' && (templateType === "sales" || templateType === "editSales") &&
                <SalesPrintTemplateEnglish1 showWaterMark={false} ref={componentRef} data={printTemplateData}/>}
            {SALES.printLayout === 'sales-print-a5-greek' && (templateType === "sales" || templateType === "editSales") &&
                <SalesPrintA5Greek showWaterMark={false} ref={componentRef} data={printTemplateData}/>}
            {SALES.printLayout === 'sales-print-a5-english' && (templateType === "sales" || templateType === "editSales") &&
                <SalesPrintA5English showWaterMark={false} ref={componentRef} data={printTemplateData}/>}
            {SALES.printLayout === 'sales-print-a5-double-greek' && (templateType === "sales" || templateType === "editSales") &&
                <SalesPrintA5DoubleGreek showWaterMark={false} ref={componentRef} data={printTemplateData}/>}
            {SALES.printLayout === 'sales-print-a5-double-english' && (templateType === "sales" || templateType === "editSales") &&
                <SalesPrintA5DoubleEnglish showWaterMark={false} ref={componentRef} data={printTemplateData}/>}
            {(SALES.printLayout === 'clientpayments-print-template1-greek' || !SALES.printLayout) && (templateType === "clientPayments" || templateType === "editClientPayments") &&
                <ClientPaymentPrintTemplate1Greek showWaterMark={false} ref={componentRef} data={printTemplateData}/>}
            {(SALES.printLayout === 'clientpayments-print-template1-english') && (templateType === "clientPayments" || templateType === "editClientPayments") &&
                <ClientpaymentPrintTemplate1English showWaterMark={false} ref={componentRef} data={printTemplateData}/>}
            {(SALES.printLayout === 'buy-print-a4-greek' || !SALES.printLayout) && (templateType === "buys" || templateType === "editBuys") &&
                <BuyPrintA4Greek showWaterMark={false} ref={componentRef} data={printTemplateData}/>}
            {(SALES.printLayout === 'buy-print-a4-english') && (templateType === "buys" || templateType === "editBuys") &&
                <BuyPrintA4English showWaterMark={false} ref={componentRef} data={printTemplateData}/>}
            <DeleteModal
                showDelete={showDeleteModal}
                showDeleteModal={showDeletionModal}
                toBeDeleted={toBeDeleted}
                tableName={deleteModalTableName}
                updateFunction={handleAfterDelete}
            />
        </div>);
};

export default DataPanel;
