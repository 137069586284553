import React from "react";
import {Row, Col, Button} from "react-bootstrap";
import TextArea from "../../../common/TextArea";
import Input from "../../../common/Input";
import DateBox from "../../../primer/DateBox";
import {useSelector, useDispatch} from "react-redux";
import {
    setSaveEditBankAccounts,
} from "../../../../_reducers/activitySlice";
import Checkbox from "../../../common/CheckBox";
import {setEditBankAccount} from "../../../../_reducers/DataPanel/Cash/cashSlice";
import {banksDummyData} from "../../../../_data/dummy_banks";
import {getObjectFromArrayOfObjects} from "../../../../_helpers/helperFunctions";
import AdvancedCombobox from "../../../primer/AdvancedCombobox";
import {useTranslation} from "react-i18next";
import {checkPermission} from "../../../../_helpers/commonFunctions";
import LogTable from "../../../primer/LogTable";

const EditBankAccounts = () => {
    const [t] = useTranslation('common');
    const dispatch = useDispatch();
    const ACTIVITY = useSelector((state) => state.ACTIVITY);
    const CASH_DATA = useSelector((state) => state.CASH_DATA);
    const banksData = banksDummyData;
    const permissionsData = JSON.parse(localStorage.getItem("user"))?.permissions;

    const handleOnChange = (e, type = "default", ddname = '') => {
        if (!ACTIVITY.saveEditBankAccounts) dispatch(setSaveEditBankAccounts(true));

        let name;
        let value;
        if (type === 'dd' || type === 'ac') {
            value = e.value;
            name = ddname;
        } else if (type === 'dp') {
            value = e;
            name = ddname;
        } else if (type === 'acdd') {
            e = e.row;
            value = e._id;
            name = ddname;
        } else {
            value = e.target.value;
            name = e.target.name;
        }
        let bankAccountData = Object.assign({}, CASH_DATA);
        let editBankAccountData = {};
        if (name === 'bank') {
            editBankAccountData[name] = value;
            const selectedBank = getObjectFromArrayOfObjects(banksData, value, '_id');
            if (selectedBank.length > 0) {
                editBankAccountData['bankName'] = selectedBank['0'].name;
                editBankAccountData['bankSwift'] = selectedBank['0'].swift;
                editBankAccountData['bankCountry'] = selectedBank['0'].country;
            }
        } else {
            editBankAccountData[name] = value;
        }
        dispatch(setEditBankAccount({...bankAccountData.editBankAccount, ...editBankAccountData}));
    }

    const bankColumns = [
        {
            label: t('BankAccountsNewEdit.smallTable.name'),
            name: "name",
        },
        {
            label: t('BankAccountsNewEdit.smallTable.swift'),
            name: "swift",
        },
        {
            label: t('BankAccountsNewEdit.smallTable.country'),
            name: "country",
        },
    ]

    return (
        <React.Fragment>
            <Row className="position-relative border-bottom mb-3">
                <Col xs="12" md="12" className="d-flex align-items-center justify-content-end ">
                    <div className="inline-field d-flex align-items-center acremd  mb-3">
                        <label htmlFor="product-active"
                               className="mb-0 mr-2 ">{t('BankAccountsNewEdit.active')}</label>
                        <Checkbox
                            className="mb-2"
                            name="isActive"
                            key={Math.random()}
                            id="bank-active"
                            defaultValue={CASH_DATA.editBankAccount.isActive}
                            value={CASH_DATA.editBankAccount.isActive === 'active' ? "inactive" : "active"}
                            onChange={(e) => handleOnChange(e)}
                        />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col sm="12" md="4">
                    <div className="mb-3">
                        <Input
                            className="w-100"
                            label={t('BankAccountsNewEdit.name.name')}
                            name="name"
                            placeholder={t('BankAccountsNewEdit.name.placeholder')}
                            value={CASH_DATA.editBankAccount.name}
                            required={true}
                            onChange={(e) => handleOnChange(e)}
                        />
                        {ACTIVITY.saveEditBankAccounts && CASH_DATA.editBankAccount.name === '' &&
                            <div className="text-danger">{t('BankAccountsNewEdit.name.required')}</div>}
                    </div>
                </Col>
                <Col sm={12} md={4}>
                    <AdvancedCombobox
                        className="w-100 ac-select-dropdown"
                        searchableDropdownEnable={false}
                        defaultValue={CASH_DATA.editBankAccount.bankName}
                        key={Math.random()}
                        label={t('BankAccountsNewEdit.bank.bank')}
                        data={banksData}
                        onChange={(e) => {
                            handleOnChange(e, 'acdd', 'bank')
                        }}
                        excludeColumns={[]}
                        columns={bankColumns}
                        editEnable={false}
                        deleteEnable={false}
                        searchTableEnable={false}
                        showTableOptionOnSelect={true}
                        placeholder={t('BankAccountsNewEdit.bank.placeholder')}
                    />
                </Col>
                <Col sm={12} md={4} className="text-right ">
                    <Button className="disabled" size="small">{t('BankAccountsNewEdit.files')}</Button>
                </Col>
            </Row>
            <Row>
                <Col sm="12" md="4">
                    <div className="mb-3">
                        <DateBox
                            classes="w-100"
                            label={t('BankAccountsNewEdit.date.date')}
                            name="date"
                            required={true}
                            selected="today"
                            onChange={(e) => handleOnChange(e, 'dp', 'date')}
                        />
                        {ACTIVITY.saveEditBankAccounts && CASH_DATA.editBankAccount.date === '' &&
                            <div className="text-danger">{t('BankAccountsNewEdit.date.required')} </div>}
                    </div>
                </Col>
            </Row>
            <Row>
                <Col sm="12" md="8">
                    <div className="mb-3">
                        <Input
                            className="w-100"
                            label={t('BankAccountsNewEdit.iban.iban')}
                            name="iban"
                            placeholder={t('BankAccountsNewEdit.iban.placeholder')}
                            value={CASH_DATA.editBankAccount.iban}
                            required={true}
                            onChange={(e) => handleOnChange(e)}
                        />
                        {ACTIVITY.saveEditBankAccounts && CASH_DATA.editBankAccount.iban === '' &&
                            <div className="text-danger">{t('BankAccountsNewEdit.iban.required')}</div>}
                    </div>
                </Col>
                <Col sm="12" md="4" className="d-flex align-items-center justify-content-center">
                    <Checkbox
                        classes="w-100"
                        text={t('BankAccountsNewEdit.isSentToAade')}
                        name="isSentToAade"
                        key={Math.random()}
                        defaultValue={CASH_DATA.editBankAccount.isSentToAade}
                        value={CASH_DATA.editBankAccount.isSentToAade === 'active' ? "inactive" : "active"}
                        onChange={(e) => handleOnChange(e)}
                    />
                </Col>
            </Row>
            <hr className="mb-4"/>
            <Row>
                <div style={{width: "100%"}}>
                    <div className="text-muted mb-2"><i>{t('BankAccountsNewEdit.notes')}</i></div>
                    <TextArea
                        rows="4"
                        placeholder={t('BankAccountsNewEdit.addNotes')}
                        name="notes"
                        limit="1000"
                        value={CASH_DATA.editBankAccount.notes}
                        onChange={(e) => handleOnChange(e)}
                    />
                </div>
            </Row>
            {checkPermission("accounting-settings/accounting-read", permissionsData) === true && (
                <React.Fragment>
                    <hr/>
                    <div className="text-muted mb-2"><i>Λογιστική</i></div>
                    <Row className={"mb-2"}>
                        <Col md={4}>
                            <Input
                                name={"accounting"}
                                label={"Πρόθεμα Κωδικού Λογαριασμού"}
                                value={CASH_DATA.editBankAccount?.accounting}
                                onChange={(e) => handleOnChange(e)}
                            />
                        </Col>
                        <Col md={4}>
                            <Input
                                name={"bypassAccounting"}
                                label={"Κωδικός Λογαριασμού Παράκαμψης Λογιστικής Γέφυρας"}
                                value={CASH_DATA.editBankAccount?.bypassAccounting}
                                onChange={(e) => handleOnChange(e)}
                            />
                        </Col>
                    </Row>
                </React.Fragment>
            )}
        </React.Fragment>
    )
}

export const BankEditLog = () => {
    const { t } = useTranslation('common');
    const CASH_DATA = useSelector((state) => state.CASH_DATA);

    return (
        <React.Fragment>
            <div className="text-muted mb-2"><i>{t('Logs.bankAccountLog')}</i></div>
            <LogTable itemId={CASH_DATA.editBankAccount._id}/>
        </React.Fragment>
    )
}

export default EditBankAccounts;
