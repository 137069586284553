import React from "react";
import AdvancedCombobox from '../AdvancedCombobox/index';
import Dropdown from "../Dropdown";
import {
  getDefaultOptionByValue,
} from '../../../_helpers/commonFunctions';
import DateBox from "../DateBox";
import TimePickerInput from "../../common/TimePickerInput";
import {useTranslation} from "react-i18next";

const TableFilterCell = (props) => {

  const [t] = useTranslation('common');

  const {filterConfig, tableName, data, rowData, cell, filterFieldValue, disabled, placeholder } = props;

  const handleOnChange = (e, field, ftype = 'default') => {

    if (ftype === 'dd') {
      filterFieldValue && filterFieldValue(e?.value, field);
    } else {
      filterFieldValue && filterFieldValue(e.target?.value, field);
    }
  }

  let tdWidth = cell.width ? cell.width : 'auto';
  let rowId = ((rowData && rowData._id !== 'undefined')) ? rowData._id : '';
  if (rowId === undefined) {
    rowId = ((rowData && rowData.id !== 'undefined')) ? rowData.id : '';
  }

  const fieldRenderer = (tableName, rowData, rowId, cell) => {
    if ((cell.field !== 'actions') && (cell.field !== 'select_checkbox') && (cell.field !== 'no')) {
      switch(filterConfig?.type) {
        case 'advanced-combobox':
          return <span>
            <AdvancedCombobox
              {...cell.inputType.config}
              defaultValue={data}
              onChange={(e) => handleOnChange(e, cell.field, 'ac')}
              field={cell.field}
            />
          </span>
        case 'datebox':
          return <DateBox
            {...cell.inputType.config}
            selected={data}
            name = {cell.field}
            autoComplete = "off"
            classes = "m-0"
            onChange={(e) => handleOnChange(e, cell.field, 'db')}
            field={cell.field}
          />
        case 'time':
          return <TimePickerInput
              {...cell.inputType.config}
              value={data ? data : ""}
              hideValidation = {true}
              onChange={(e) => handleOnChange(e, cell.field, 'tp')}
            />
        case 'select':
          return <span>
            <select
              defaultValue={data}
              onChange={(e) => handleOnChange(e, cell.field)}
              className="tdelement custom-select"
              >
              <option value="">{t('insertPlaceholders.select')}</option>
              {
                filterConfig?.options?.length > 0 && filterConfig?.options.map((op, idx) => {
                  return <option key={ idx } value={ op.value }>{ op.label }</option>
                })
              }
            </select>
          </span>
        case 'dropdown':
          let dddefaultData = data;

          if (data === 'true') {
            dddefaultData = true;
          }

          if (data === 'false') {
            dddefaultData = false;
          }

          return <span>
            <Dropdown
            {...cell.inputType.config}
            disabled={disabled}
            classes={'table-dropdown'}
            defaultValue={getDefaultOptionByValue(filterConfig?.options, dddefaultData, 'value' ,'label', 'value')}
            options={filterConfig?.options}
            onChange={(e) => handleOnChange(e, cell.field, 'dd')}
          />
        </span>
        default:
          return <span>
            <input
              type="text"
              placeholder={placeholder}
              defaultValue={ data }
              key = {rowId + cell.field}
              onChange={(e) => handleOnChange(e, cell.field)}
              className="tdelement form-control"
              maxLength={40}
            />
          </span>
      }
    } else {
      return <span className="tdelement"></span>;
    }
  }

  return (
    <>
      { !cell.hiddenColumn &&
        <td className={ cell.field } style={{ width: tdWidth }}>
          { fieldRenderer(tableName, rowData, rowId, cell) }
        </td>
      }
    </>
  )

}
export default TableFilterCell;
