export const withHoldingTaxesData = [
    {
        id: "1",
        code: "1",
        name: "Περίπτ. β' - Τόκοι - 15%",
        tax: "15",
        myData: 1,
        taxPer: "true",
        taxAmount: "false",
        vatPercentage: 0,
        taxLevel: 1,
        vatIncluded: 3
    },
    {
        id: "2",
        code: "2",
        name: "Περίπτ. Γ' - Δικαιώματα- 20%",
        tax: "20",
        myData: 2,
        taxPer: "true",
        taxAmount: "false",
        vatPercentage: 0,
        taxLevel: 1,
        vatIncluded: 3
    },
    {
        id: "3",
        code: "3",
        name: "Περίπτ. Δ΄- Αμοιβές Συμβουλών Διοίκησης - 20%",
        tax: "20",
        myData: 3,
        taxPer: "true",
        taxAmount: "false",
        vatPercentage: 0,
        taxLevel: 1,
        vatIncluded: 3
    },
    {
        id: "4",
        code: "4",
        name: "Περίπτ. δ'- Τεχνικά Έργα- 3%",
        tax: "3",
        myData: 4,
        taxPer: "true",
        taxAmount: "false",
        vatPercentage: 0,
        taxLevel: 1,
        vatIncluded: 3
    },
    {
        id: "5",
        code: "5",
        name: "Υγρά καύσιμα και προϊόντα καπνοβιομηχανίας 1%",
        tax: "1",
        myData: 5,
        taxPer: "true",
        taxAmount: "false",
        vatPercentage: 0,
        taxLevel: 1,
        vatIncluded: 3
    },
    {
        id: "6",
        code: "6",
        name: "Λοιπά Αγαθά 4%",
        tax: "4",
        myData: 6,
        taxPer: "true",
        taxAmount: "false",
        vatPercentage: 0,
        taxLevel: 1,
        vatIncluded: 3
    },
    {
        id: "7",
        code: "7",
        name: "Παροχή Υπηρεσιών 8%",
        tax: "8",
        myData: 7,
        taxPer: "true",
        taxAmount: "false",
        vatPercentage: 0,
        taxLevel: 1,
        vatIncluded: 3
    },
    {
        id: "8",
        code: "8",
        name: "Προκαταβλητέος Φόρος Αρχιτεκτόνων και Μηχανικών επί συμβατικών Αμοιβών, για εκπόνηση μελετών και σχεδίων 4%",
        tax: "4",
        myData: 8,
        taxPer: "true",
        taxAmount: "false",
        vatPercentage: 0,
        taxLevel: 1,
        vatIncluded: 3
    },
    {
        id: "9",
        code: "9",
        name: "Προκαταβλητέος Φόρος Αρχιτεκτόνων και Μηχανικών επί συμβατικών Αμοιβών, που αφορούν οποιασδήποτε άλλης φύσης έργα 10%",
        tax: "10",
        myData: 9,
        taxPer: "true",
        taxAmount: "false",
        vatPercentage: 0,
        taxLevel: 1,
        vatIncluded: 3
    },
    {
        id: "10",
        code: "10",
        name: "Προκαταβλητέος Φόρος στις Αμοιβές  Δικηγόρων 15%",
        tax: "15",
        myData: 10,
        taxPer: "true",
        taxAmount: "false",
        vatPercentage: 0,
        taxLevel: 1,
        vatIncluded: 3
    },
    {
        id: "11",
        code: "11",
        name: "Παρακράτηση Φόρου Μισθωτών Υπηρεσιών παρ.1 αρ. 15 ν. 4172/2013",
        tax: "0",
        myData: 11,
        taxPer: "false",
        taxAmount: "true",
        vatPercentage: 0,
        taxLevel: 1,
        vatIncluded: 3
    },
    {
        id: "12",
        code: "12",
        name: "Παρακράτηση Φόρου Μισθωτών Υπηρεσιών παρ.2 αρ. 15 ν. 4172/2013 - Αξιωματικών Εμπορικού Ναυτικού",
        tax: "15",
        myData: 12,
        taxPer: "true",
        taxAmount: "false",
        vatPercentage: 0,
        taxLevel: 1,
        vatIncluded: 3
    },
    {
        id: "13",
        code: "13",
        name: "Παρακράτηση Φόρου Μισθωτών Υπηρεσιών παρ.2 αρ. 15 ν. 4172/2013 - Κατώτερο Πλήρωμα Εμπορικού Ναυτικού",
        tax: "10",
        myData: 13,
        taxPer: "true",
        taxAmount: "false",
        vatPercentage: 0,
        taxLevel: 1,
        vatIncluded: 3
    },
    {
        id: "14",
        code: "14",
        name: "Παρακράτηση Ειδικής εισφοράς αλληλεγγύης",
        tax: "0",
        myData: 14,
        taxPer: "false",
        taxAmount: "true",
        vatPercentage: 0,
        taxLevel: 1,
        vatIncluded: 3
    },
    {
        id: "15",
        code: "15",
        name: "Παρακράτηση Φόρου Αποζημίωσης λόγω Διακοπής Σχέσης Εργασίας παρ.3 αρ. 15 ν. 4172/2013",
        tax: "0",
        myData: 15,
        taxPer: "false",
        taxAmount: "true",
        vatPercentage: 0,
        taxLevel: 1,
        vatIncluded: 3
    },
    {
        id: "16",
        code: "16",
        name: "Παρακρατήσεις συναλλαγών αλλοδαπής βάσει συμβάσεων αποφυγής διπλής φορολογίας (Σ.Α.Δ.Φ.)",
        tax: "0",
        myData: 16,
        taxPer: "false",
        taxAmount: "true",
        vatPercentage: 0,
        taxLevel: 1,
        vatIncluded: 3
    },
    {
        id: "17",
        code: "17",
        name: "Λοιπές Παρακρατήσεις Φόρου",
        tax: "0",
        myData: 17,
        taxPer: "false",
        taxAmount: "true",
        vatPercentage: 0,
        taxLevel: 1,
        vatIncluded: 3
    },
    {
        id: "18",
        code: "18",
        name: "Παρακράτηση Φόρου Μερίσματα περ.α παρ. 1 αρ. 64 ν. 4172/2013",
        tax: "5",
        myData: 18,
        taxPer: "true",
        taxAmount: "false",
        vatPercentage: 0,
        taxLevel: 1,
        vatIncluded: 3
    }
]


export const withHoldingTaxesDropdownOptions = [
    {
        "no": 1,
        "label": "Περίπτ. β' - Τόκοι - 15%",
        "value": 1,
        "color": "#76AEB7"
    },
    {
        "no": 2,
        "label": "Περίπτ. Γ' - Δικαιώματα- 20%",
        "value": 2,
        "color": "#76AEB7"
    },
    {
        "no": 3,
        "label": "Περίπτ. Δ΄- Αμοιβές Συμβουλών Διοίκησης - 20%",
        "value": 3,
        "color": "#76AEB7"
    },
    {
        "no": 4,
        "label": "Περίπτ. δ'- Τεχνικά Έργα- 3%",
        "value": 4,
        "color": "#76AEB7"
    },
    {
        "no": 5,
        "label": "Υγρά καύσιμα και προιόντα καπνοβιομηχανίας 1%",
        "value": 5,
        "color": "#76AEB7"
    },
    {
        "no": 6,
        "label": "Λοιπά Αγαθά 4%",
        "value": 6,
        "color": "#76AEB7"
    },
    {
        "no": 7,
        "label": "Παροχή Υπηρεσιών 8%",
        "value": 7,
        "color": "#76AEB7"
    },
    {
        "no": 8,
        "label": "Προκαταβλητέος Φόρος Αρχιτεκτόνων και Μηχανικών επί συμβατικών Αμοιβών, για εκπόνηση μελετών και σχεδίων 4%",
        "value": 8,
        "color": "#76AEB7"
    },
    {
        "no": 9,
        "label": "Προκαταβλητέος Φόρος Αρχιτεκτόνων και Μηχανικών επί συμβατικών Αμοιβών, που αφορούν οποιασδήποτε άλλης φύσης έργα 10%",
        "value": 9,
        "color": "#76AEB7"
    },
    {
        "no": 10,
        "label": "Προκαταβλητέος Φόρος στις Αμοιβές  Δικηγόρων 15%",
        "value": 10,
        "color": "#76AEB7"
    },
    {
        "no": 11,
        "label": "Παρακράτηση Φόρου Μισθωτών Υπηρεσιών παρ.1 αρ. 15 ν. 4172/2013",
        "value": 11,
        "color": "#76AEB7"
    },
    {
        "no": 12,
        "label": "Παρακράτηση Φόρου Μισθωτών Υπηρεσιών παρ.2 αρ. 15 ν. 4172/2013 - Αξιωματικών Εμπορικού Ναυτικού",
        "value": 12,
        "color": "#76AEB7"
    },
    {
        "no": 13,
        "label": "Παρακράτηση Φόρου Μισθωτών Υπηρεσιών παρ.2 αρ. 15 ν. 4172/2013 - Κατώτερο Πλήρωμα Εμπορικού Ναυτικού",
        "value": 13,
        "color": "#76AEB7"
    },
    {
        "no": 14,
        "label": "Παρακράτηση Ειδικής εισφοράς αλληλεγγύης ",
        "value": 14,
        "color": "#76AEB7"
    },
    {
        "no": 15,
        "label": "Παρακράτηση Φόρου Αποζημίωσης λόγω Διακοπής Σχέσης Εργασίας παρ.3 αρ. 15 ν. 4172/2013",
        "value": 15,
        "color": "#76AEB7"
    },
    {
        "no": 16,
        "label": "Παρακρατήσεις συναλλαγών αλλοδαπής βάσει συμβάσεων αποφυγής διπλής φορολογίας (Σ.Α.Δ.Φ.)",
        "value": 16,
        "color": "#76AEB7"
    },
    {
        "no": 17,
        "label": "Λοιπές Παρακρατήσεις Φόρου",
        "value": 17,
        "color": "#76AEB7"
    },
    {
        "no": 18,
        "label": "Παρακράτηση Φόρου Μερίσματα περ.α παρ. 1 αρ. 64 ν. 4172/2013",
        "value": 18,
        "color": "#76AEB7"
    }
];

export const withHoldTaxesTaxLevel = [
    {
        "no": 1,
        "label": "Σε επίπεδο παραστατικού",
        "value": 1,
        "color": "#76AEB7"
    },
    {
        "no": 2,
        "label": "Σε επίπεδο γραμμής παραστατικού",
        "value": 2,
        "color": "#76AEB7"
    }
];

export const withHoldTaxesVatIncluded = [
    {
        "no": 1,
        "label": "Συμπεριλαμβάνει ΦΠΑ",
        "value": 1,
        "color": "#76AEB7"
    },
    {
        "no": 2,
        "label": "Δεν συμπεριλαμβάνει ΦΠΑ",
        "value": 2,
        "color": "#76AEB7"
    },
    {
        "no": 3,
        "label": "Εξαιρείται ΦΠΑ",
        "value": 3,
        "color": "#76AEB7"
    }
];