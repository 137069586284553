import React, {useState, useEffect} from "react";
import axios from "axios";
import {useSelector} from "react-redux";
import {getISOStringDateToDate, isEmpty} from "../../../_helpers/commonFunctions";
import {toast} from "react-toastify";
import "./logTable.scss";
import {useTranslation} from "react-i18next";
import Button from "react-bootstrap/Button";
import {Badge} from "react-bootstrap";

const LogTable = ({logType, model, itemId, fromFile = false}) => {
    const company = useSelector(state => state.COMPANY_DATA.company);
    const [logData, setLogData] = useState([]);
    const [t] = useTranslation('common');
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(false);

    const tableColumns =
    [
        {
            name: "Ημερομηνία",
            field: "created_at",
            width: "10%",
        },
        {
            name: "Ώρα",
            field: "created_at",
            width: "10%",
        },
        {
            name: "Χρήστης",
            field: "user",
            width: "10%",
        },
        {
            name: "Τύπος Εγγραφής",
            field: "type",
            width: "15%",
        },
        {
            name: "Εγγραφή",
            width: "45%",
        },
        {
            name: "Λοιπά Στοιχεία",
            field: "extraNotes",
            width: "10%",
        }
    ];
    useEffect(() => {
        fetchLogs();
    }, [page])

    const fetchLogs = () => {
        setLoading(true);
        let params = {
            page: page,
            company: company.id,
        }
        if (!isEmpty(logType)) params.logType = logType;
        if (!isEmpty(model)) params.model = model;
        if (!isEmpty(itemId)) params.itemId = itemId;
        params.fromFile = fromFile;
        axios.get(`${process.env.REACT_APP_API_URL2}/company/get-logs`, {
            headers: { "Content-Type": "application/json" },
            params: params
        }).then((res) => {
            setLoading(false);
            if (res.data.status === "200") {
                setLogData(res.data.data);
                setTotalPages(res.data.totalPages);
            } else {
                toast.error(res.data.message);
            }
        }).catch((err) => {
            setLoading(false);
            console.log(err);
        })
    }

    return (
        <React.Fragment>
            {totalPages > 0 && (
                <div className="d-flex justify-content-end mb-2">
                    <Button
                        size={"sm"}
                        className={"mr-1"}
                        onClick={() => setPage(1)}
                        disabled={page === 1 || loading}
                    >
                        {t('SalesBalanceSheet.first')}
                    </Button>
                    <Button
                        size={"sm"}
                        className={"mr-1"}
                        onClick={() => setPage(page - 1)}
                        disabled={page === 1 || loading}
                    >
                        {t('SalesBalanceSheet.previous')}
                    </Button>
                    <Badge style={{fontSize: "12px", lineHeight: "20px"}} className="mr-1"
                           bg="primary">{t('SalesBalanceSheet.page')} {page} / {totalPages}</Badge>
                    <Button
                        size={"sm"}
                        className={"mr-1"}
                        onClick={() => setPage(page + 1)}
                        disabled={page === totalPages || loading}
                    >
                        {t('SalesBalanceSheet.next')}
                    </Button>
                    <Button
                        size={"sm"}
                        className={"mr-1"}
                        onClick={() => setPage(totalPages)}
                        disabled={page === totalPages || loading}
                    >
                        {t('SalesBalanceSheet.last')}
                    </Button>
                </div>
            )}
            <table style={{width: "100%", tableLayout: "fixed", textAlign: "center"}} className={"logTable mb-3"}>
                <colgroup>
                    {tableColumns.map((col, idx) => (
                        <col key={"colInGroup" + idx} style={{width: col.width}}></col>
                    ))}
                </colgroup>
                <thead>
                <tr>
                    {tableColumns.map((col, idx) => (
                        <th key={"logTableHead" + idx}>
                            {col["name"]}
                        </th>
                    ))}
                </tr>
                </thead>
                <tbody>
                {logData.map((row, rIdx) => (
                    <tr key={"logTableRow" + rIdx} className="border-top">
                        {tableColumns.map((col, cIdx) => {
                            let value;

                            if (col.name === "Ημερομηνία") {
                                value = getISOStringDateToDate(row[col.field]);
                            } else if (col.name === "Ώρα") {
                                value = new Date(row[col.field]).toLocaleTimeString('en-GB');
                            } else if (col.name === "Τύπος Εγγραφής") {
                                value = t(`Logs.${row[col.field]}`);

                            } else if (col.name === "Εγγραφή") {
                                col["field"] = row.fieldsChanged ? "fieldsChanged" : "item";

                                if (col.field === "fieldsChanged") {
                                    value = [];
                                    let changes = JSON.parse(row[col.field]);
                                    for (let changeProp in changes) {
                                        if (changes.hasOwnProperty(changeProp)) {
                                            let oldValue = changes[changeProp]["oldValue"];
                                            let newValue = changes[changeProp]["newValue"];
                                            if (typeof oldValue === "object") {
                                                oldValue = JSON.stringify(oldValue).replaceAll(/[,{}]/g, " ");
                                            }
                                            if (typeof newValue === "object") {
                                                newValue = JSON.stringify(newValue).replaceAll(/[,{}]/g, " ");
                                            }
                                            if (isEmpty(oldValue)) {
                                                value.push(
                                                    <React.Fragment>
                                                        <div style={{
                                                            width: "100%",
                                                            display: "flex",
                                                            justifyContent: "space-evenly"
                                                        }}>
                                                            <div style={{width: "30%", textAlign: "right", overflow: "hidden"}}>
                                                                <strong>(+){t(`Logs.${changeProp}`) === `Logs.${changeProp}` ? changeProp : t(`Logs.${changeProp}`)}:</strong>
                                                            </div>
                                                            <div style={{width: "60%", textAlign: "left", overflow: "hidden"}}>{newValue}</div>
                                                        </div>
                                                    </React.Fragment>);
                                            } else if (isEmpty(newValue)) {
                                                value.push(<React.Fragment>
                                                    <div style={{
                                                        width: "100%",
                                                        display: "flex",
                                                        justifyContent: "space-evenly"
                                                    }}>
                                                        <div style={{width: "30%", textAlign: "right", overflow: "hidden"}}>
                                                            <strong>(-){t(`Logs.${changeProp}`) === `Logs.${changeProp}` ? changeProp : t(`Logs.${changeProp}`)}:</strong>
                                                        </div>
                                                        <div style={{width: "60%", textAlign: "left", overflow: "hidden"}}>{oldValue}</div>
                                                    </div>
                                                </React.Fragment>);
                                            } else {
                                                if (oldValue === "false" && newValue === "true") {
                                                    oldValue = t("Logs.no");
                                                    newValue = t("Logs.yes");
                                                } else if (oldValue === "true" && newValue === "false") {
                                                    oldValue = t("Logs.yes");
                                                    newValue = t("Logs.no");
                                                }
                                                value.push(<React.Fragment>
                                                        <div style={{
                                                            width: "100%",
                                                            display: "flex",
                                                            justifyContent: "space-evenly"
                                                        }}>
                                                            <div style={{width: "30%", textAlign: "right", overflow: "hidden"}}>
                                                                <strong>{t(`Logs.${changeProp}`) === `Logs.${changeProp}` ? changeProp : t(`Logs.${changeProp}`)}:</strong>
                                                            </div>
                                                            <div style={{width: "60%", textAlign: "left", overflow: "hidden"}}>{oldValue} &nbsp;
                                                                <strong>--></strong>&nbsp; {newValue}</div>
                                                        </div>
                                                    </React.Fragment>
                                                )
                                            }
                                        }
                                    }
                                } else {
                                    value = <strong>{row[col.field]}</strong>;
                                }
                            } else {
                                value = row[col.field];
                            }
                            return <td key={`logTable${rIdx}C${cIdx}`} style={{padding: "10px 0px"}}>
                                {value}
                            </td>
                        })}
                    </tr>
                ))}
                </tbody>
            </table>
        </React.Fragment>
    )
}

export default LogTable;
