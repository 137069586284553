import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";
import {Badge, Col, Modal, ProgressBar, Row, Table} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import moment from "moment";
import AdvancedCombobox from "../../../primer/AdvancedCombobox";
import axios from "axios";
import "jspdf-autotable";
import {
    setProductMovementsReportCollectData,
    setProductMovementsReportData,
    setProductMovementsReportRequestData,
} from "../../../../_reducers/DataPanel/Products/productsSlice";
import {useTranslation} from "react-i18next";
import {checkPermission, getGreekCurrencyFormat, isEmpty} from "../../../../_helpers/commonFunctions";
import BetterCheckBox from "../../../common/BetterCheckBox";
import DateHelper from "../../../primer/DateHelper/DateHelper";
import DateBox from "../../../primer/DateBox";
import OurDropdown from "../../../primer/Dropdown";
import Dropdown from "react-bootstrap/Dropdown";
import Loader from "../../../primer/POPUP-COMPONENTS/Loader/PrimerLoader";
import * as XLSX from "xlsx";
import {jsPDF} from "jspdf";
import {liberationSerif} from "../../../../fonts/liberationSerif";
import {dejavuSans} from "../../../../fonts/DejaVuSans-normal";
import {dejavuSansBold} from "../../../../fonts/DejaVuSans-Bold-bold";

const ProductMovementsReports = () => {
    const [t] = useTranslation("common");
    const dispatch = useDispatch();
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const companyInstallations = useSelector((state) => state.COMPANY_DATA.companyInstallations);
    const requestData = useSelector((state) => state.PRODUCTS_DATA.productMovementsRequestData);
    const reportData = useSelector((state) => state.PRODUCTS_DATA.productMovementsReportData);
    const permissionsData = JSON.parse(localStorage.getItem("permissions"));
    const [loader, setLoader] = useState(false);
    const [productsList, setProductsList] = useState([]);
    const [firstLoad, setFirstLoad] = useState(true);
    const [showCollectModal, setShowCollectModal] = useState(false);
    const [collectLength, setCollectLength] = useState(0);
    const [installationOptions, setInstallationOptions] = useState([]);

    useEffect(() => {
        loadProductsListInitialData();
    }, []);

    useEffect(() => {
        if (firstLoad) {
            setFirstLoad(false);
            if(companyInstallations.length > 0) {
                let options = [{ value: "", label: "Όλες"}];
                companyInstallations.map((inst) => {
                    options.push({ value: inst.installationMasterId, label: inst.type + " " + inst.address + " " + inst.city });
                })
                setInstallationOptions(options);
            }
        } else {
            fetchProductMovements();
        }
    }, [requestData.page])

    const collectDataForExport = async () => {
        setLoader(true);
        setCollectLength(0);
        let newDataCollection = [];
        const pages = [];
        for (let page = 1; page <= requestData.totalPages; page++) {
            pages.push(page);
        }
        for (let page of pages) {
            try {
                let toReq = {...requestData, page: page, company: company.id, year: String(company.year)}
                if (!isEmpty(toReq.productName)) {
                    toReq.product_zero = "active";
                    toReq.product_inactive = "active";
                }
                const response = await axios.post(process.env.REACT_APP_API_URL2 + `/report/product-movements-report`, toReq, {headers: {"Content-Type": "application/json"}});
                if (response.data.status === "200" && response.data.data) {
                    if (response.data.data.history.length > 0) {
                        let totalNetValue = 0;
                        let totalVatValue = 0;
                        let totalValue = 0;
                        let totalSale = 0;
                        let totalBuy = 0;
                        for (let line of response.data.data.history) {
                            if (line?.isMovement === "active" || line?.isCensus === "active") continue;
                            if (line.quantity < 0) {
                                totalSale += Math.abs(line.quantity);
                            } else {
                                totalBuy += Math.abs(line.quantity);
                            }
                            if (!isNaN(line.netValue)) totalNetValue += line.netValue;
                            if (!isNaN(line.vatValue)) totalVatValue += line.vatValue;
                            if (!isNaN(line.totalValue)) totalValue += line.totalValue;
                            totalNetValue = Number(Number(totalNetValue).toFixed(2));
                            totalVatValue = Number(Number(totalVatValue).toFixed(2));
                            totalValue = Number(Number(totalValue).toFixed(2));
                        }
                        let prepObj = {
                            totalSale: totalSale,
                            totalBuy: totalBuy,
                            balance: response.data.data.history[response.data.data.history.length - 1].balance,
                            isTotal: "active",
                            totalNetValue: totalNetValue,
                            totalVatValue: totalVatValue,
                            totalValue: totalValue,
                        }
                        response.data.data.history.push(prepObj);
                    }
                    newDataCollection.push(response.data.data);
                    setCollectLength((c) => c + 1);
                }
                setLoader(false);
            } catch (e) {
                setLoader(false);
                console.log(e);
                toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
            }
        }
        dispatch(setProductMovementsReportCollectData(newDataCollection));
        return newDataCollection;
    }
    const handlePDFExport = async (printer = false) => {
        // Collect all pages
        setShowCollectModal(true);
        const collectedData = await collectDataForExport();

        // Export to PDF
        if (collectedData.length > 0) {
            const doc = new jsPDF('landscape');
            let y = 20;
            doc.addFileToVFS("arialuni.ttf", liberationSerif);
            doc.addFont("arialuni.ttf", "Arial Unicode MS", "normal");
            doc.addFileToVFS("dejavusans.ttf", dejavuSans);
            doc.addFont("dejavusans.ttf", "DejaVu Sans Normal", "normal");
            doc.addFileToVFS("dejavusansBold.ttf", dejavuSansBold);
            doc.addFont("dejavusansBold.ttf", "DejaVu Sans Bold", "normal");
            doc.setFont("DejaVu Sans Bold");
            doc.setFontSize(12);
            let dateFrom = requestData.date_from;
            let dateTo = requestData.date_to;
            if (isEmpty(dateFrom)) dateFrom = moment(company.year, "YYYY", true).startOf("year").format("DD/MM/YYYY");
            if (isEmpty(dateTo)) dateTo = moment(company.year, "YYYY", true).endOf("year").format("DD/MM/YYYY");
            const header = doc.splitTextToSize(`Κινήσεις ειδών από ${dateFrom} έως ${dateTo}`, 200);
            const headerString = header.join("\n");
            doc.text(headerString, doc.internal.pageSize.getWidth() / 4, 9, {alignment: "center"});
            doc.setFont("DejaVu Sans Normal");
            const imgData = 'assets/images/prrimer-logo.png';
            const imgX = doc.internal.pageSize.getWidth() - 45; // adjust the position as needed
            const imgY = 3; // adjust the position as needed
            doc.addImage(imgData, 'PNG', imgX, imgY, 40, 15);
            doc.setFont("DejaVu Sans Normal");
            const columns = ["Αρ.", "Ημερομηνία", "Τύπος Κίνησης", "Τύπος παραστατικού", "Αρίθμηση", "Καθαρή Αξία", "Αξία Φ.Π.Α.", "Τελική Αξία", "Ποσ. Πώλησης", "Ποσ. Πληρωμής", "Υπόλοιπο"];
            let rows = [];
            for (let collectedProduct of collectedData) {
                if (collectedProduct.history.length === 0) continue;
                rows.push([{content: `Είδος: ${collectedProduct.name} με κωδικό ${collectedProduct.code}`, colSpan: 11, styles: {halign: "left", fillColor: "#decae3"}}]);
                for (let line of collectedProduct.history) {
                    if (line?.isTotal !== "active") {
                        let toPush = [
                            line.no,
                            line.date,
                            line.movementType,
                            line.documentTypeName,
                            line.number,
                            getGreekCurrencyFormat(line.netValue),
                            getGreekCurrencyFormat(line.vatValue),
                            getGreekCurrencyFormat(line.totalValue),
                            line.quantity < 0 ? Math.abs(line.quantity) : "",
                            line.quantity > 0 ? Math.abs(line.quantity) : "",
                            line.balance,
                        ]
                        if (line?.isCensus === "active" && line?.isMovement !== "active") {
                            toPush[8] = "Απογραφή";
                            toPush[9] = "Απογραφή";
                        }
                        if (line?.isMovement) {
                            toPush[8] = "Από μεταφορά";
                            toPush[9] = "Από μεταφορά";
                        }
                        rows.push(toPush);
                    } else {
                        rows.push([
                            {content: "Σύνολα Είδους", colSpan: 5, styles: {halign: "left", fillColor: "#decae3"}},
                            {content: getGreekCurrencyFormat(line.totalNetValue), styles: {halign: "left", fillColor: "#decae3"}},
                            {content: getGreekCurrencyFormat(line.totalVatValue), styles: {halign: "left", fillColor: "#decae3"}},
                            {content: getGreekCurrencyFormat(line.totalValue), styles: {halign: "left", fillColor: "#decae3"}},
                            {content: line.totalSale, styles: {halign: "left", fillColor: "#decae3"}},
                            {content: line.totalBuy, styles: {halign: "left", fillColor: "#decae3"}},
                            {content: line.balance, styles: {halign: "left", fillColor: "#decae3"}},
                        ]);
                    }
                }
            }
            doc.autoTable({
                startY: y,
                head: [columns],
                body: rows,
                rowPageBreak: "avoid",
                styles: {
                    font: "DejaVu Sans Normal",
                    fontSize: 8,
                    halign: "center",
                },
            })
            const pageCount = doc.getNumberOfPages();
            // Add the pagination to each page
            for (let i = 1; i <= pageCount; i++) {
                doc.setPage(i);
                doc.setFontSize(10);
                doc.text(`Σελίδα ${i} από ${pageCount}`, 10, doc.internal.pageSize.getHeight() - 10);
            }
            if (printer) {
                window.open(doc.output('bloburl'), '_blank');
            } else {
                doc.save("Κινήσεις Ειδών.pdf");
            }
        } else {
            toast.error("Δεν βρέθηκαν είδη για εξαγωγή.");
        }
        setShowCollectModal(false);
    }

    const handleExcelExport = async () => {
        // Collect all pages
        const collectedData = await collectDataForExport();

        // Export to XLSX
        if (collectedData.length > 0) {
            let rows = [];
            for (let collectedProduct of collectedData) {
                if (collectedProduct.history.length === 0) continue;
                rows.push([`Είδος: ${collectedProduct.name} με κωδικό ${collectedProduct.code}`, "", "", "", "", "", "", "", "", "", ""]);
                rows.push(["Αρ.", "Ημερομηνία", "Τύπος Κίνησης", "Τύπος παραστατικού", "Αρίθμηση", "Καθαρή Αξία", "Αξία Φ.Π.Α.", "Τελική Αξία", "Ποσότητα Πώλησης", "Ποσότητα Πληρωμής", "Υπόλοιπο"]);
                for (let line of collectedProduct.history) {
                    if (line?.isTotal !== "active") {
                        let toPush = [
                            line.no,
                            line.date,
                            line.movementType,
                            line.documentTypeName,
                            line.number,
                            line.netValue,
                            line.vatValue,
                            line.totalValue,
                            line.quantity < 0 ? Math.abs(line.quantity) : "",
                            line.quantity > 0 ? Math.abs(line.quantity) : "",
                            line.balance,
                        ]
                        if (line?.isCensus === "active" && line?.isMovement !== "active") {
                            toPush[8] = "Απογραφή";
                            toPush[9] = "Απογραφή";
                        }
                        if (line?.isMovement) {
                            toPush[8] = "Από μεταφορά";
                            toPush[9] = "Από μεταφορά";
                        }
                        rows.push(toPush);
                    } else {
                        rows.push(["Σύνολα Είδους", "", "", "", "", line.totalNetValue, line.totalVatValue, line.totalValue, line.totalSale, line.totalBuy, line.balance]);
                    }
                }
                rows.push([]);
            }
            const workSheet = XLSX.utils.aoa_to_sheet(rows);
            const workBook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workBook, workSheet, "Κινήσεις");
            XLSX.writeFile(workBook, 'Κινήσεις Ειδών.xlsx');
        } else {
            toast.error("Δεν βρέθηκαν είδη για εξαγωγή.");
        }
        setShowCollectModal(false);
    }

    const loadProductsListInitialData = () => {
        axios.post(process.env.REACT_APP_API_URL2 + `/category/list-product`, {company: company.id}, {
            headers: {"Content-Type": "application/json"},
        }).then((res) => {
            if (res.data && res.data.status === "200") {
                if (res.data.data) {
                    let filteredData = res.data.data;
                    setProductsList(filteredData);
                } else {
                    setProductsList([]);
                }
            } else {
                setProductsList([]);
            }
        }).catch((e) => {
            console.log(e);
        })
    }

    const loadProductsListData = (name, setData) => {
        axios.post(process.env.REACT_APP_API_URL2 + `/category/list-product`, {name: name, company: company.id}, {
            headers: {"Content-Type": "application/json"},
        }).then((res) => {
            if (res.data && res.data.status === "200") {
                if (res.data.data) {
                    let filteredData = res.data.data;
                    if (setData) {
                        setData(filteredData);
                    }
                } else {
                    if (setData) setData([]);
                }
            } else {
                if (setData) setData([]);
            }
        }).catch((e) => {
            console.log(e);
            setData([]);
        })
    }

    const productDropdownColumns = [
        {
            label: "Product Code",
            name: "code",
        },
        {
            label: "Product Name",
            name: "name",
        },
    ];

    const handleOnChange = (e, type, name) => {
        if (type === "acdd") {
            dispatch(setProductMovementsReportRequestData({...requestData, [name]: e.row.name}));
        } else if (type === "checkbox") {
            const val = e.target.checked ? "active" : "inActive";
            dispatch(setProductMovementsReportRequestData({...requestData, [name]: val}));
        } else if (type === "datebox") {
            if (moment(e).isValid()) {
                dispatch(setProductMovementsReportRequestData({...requestData, [name]: moment(e).format("DD/MM/YYYY")}));
            } else {
                dispatch(setProductMovementsReportRequestData({...requestData, [name]: ""}));
            }
        } else if (type === "dropdown") {
            dispatch(setProductMovementsReportRequestData({...requestData, [name]: e.value}));
        }
    }

    const fetchProductMovements = (cleanup = false) => {
        setLoader(true);
        const toReq = {...requestData, company: company.id, year: String(company.year)}
        if (cleanup) dispatch(setProductMovementsReportRequestData({...requestData, totalPages: 0}))
        if (!isEmpty(toReq.productName)) {
            toReq.product_zero = "active";
            toReq.product_inactive = "active";
        }
        axios.post(process.env.REACT_APP_API_URL2 + `/report/product-movements-report`, toReq, {
            headers: {"Content-Type": "application/json"}
        }).then((res) => {
            setLoader(false);
            if (res.data.status === "200") {
                if (res.data.data.history && res.data.data.history.length > 0) {
                    // Calculate total
                    let totalNetValue = 0;
                    let totalVatValue = 0;
                    let totalValue = 0;
                    let totalSale = 0;
                    let totalBuy = 0;
                    for (let line of res.data.data.history) {
                        if (line?.isMovement === "active" || line?.isCensus === "active") continue;
                        if (line.quantity < 0) {
                            totalSale += Math.abs(line.quantity);
                        } else {
                            totalBuy += Math.abs(line.quantity);
                        }
                        if (!isNaN(line.netValue)) totalNetValue += line.netValue;
                        if (!isNaN(line.vatValue)) totalVatValue += line.vatValue;
                        if (!isNaN(line.totalValue)) totalValue += line.totalValue;
                        totalNetValue = Number(Number(totalNetValue).toFixed(2));
                        totalVatValue = Number(Number(totalVatValue).toFixed(2));
                        totalValue = Number(Number(totalValue).toFixed(2));
                    }
                    let prepObj = {
                        totalSale: totalSale,
                        totalBuy: totalBuy,
                        balance: res.data.data.history[res.data.data.history.length - 1].balance,
                        isTotal: "active",
                        totalNetValue: totalNetValue,
                        totalVatValue: totalVatValue,
                        totalValue: totalValue,
                    }
                    res.data.data.history.push(prepObj);
                }
                dispatch(setProductMovementsReportData(res.data.data));
                dispatch(setProductMovementsReportRequestData({...requestData, totalPages: res.data.totalPages}))
            } else {
                toast.error(res.data.message);
            }
        }).catch((error) => {
            setLoader(false);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος");
            console.log(error);
        })
    }

    const toFirstPage = () => {
        dispatch(setProductMovementsReportRequestData({...requestData, page: 1}));
    }
    const toNextPage = () => {
        dispatch(setProductMovementsReportRequestData({...requestData, page: requestData.page + 1}));
    }
    const toPreviousPage = () => {
        dispatch(setProductMovementsReportRequestData({...requestData, page: requestData.page - 1}));
    }
    const toLastPage = () => {
        dispatch(setProductMovementsReportRequestData({...requestData, page: requestData.totalPages}));
    }
    const onChangeSearch = (keyword, setData) => {
        if (keyword.length >= 2) {
            loadProductsListData(keyword, setData);
        }
    }

    return (
        <div className="product-balance-sheet" style={{overflowY: "auto", height: "calc(100vh - 175px)", overflowX: "hidden", paddingLeft: "12px", paddingRight: "15px"}}>
            <div className="no-print">
                <Row>
                    <Col md={3}>
                        <DateHelper
                            dateFromAndToFunction={(from, to) => dispatch(setProductMovementsReportRequestData({...requestData, date_from: from, date_to: to}))}
                        />
                    </Col>
                    <Col md={2}>
                        <DateBox
                            name="date_from"
                            selected={isEmpty(requestData.date_from) ? "" : moment(requestData.date_from, "DD/MM/YYYY").toDate()}
                            onChange={(e) => handleOnChange(e, "datebox", "date_from")}
                            label={t("ReportsProductMovements.table.dateFrom")}
                        />
                    </Col>
                    <Col md={2}>
                        <DateBox
                            name="date_to"
                            selected={isEmpty(requestData.date_to) ? "" : moment(requestData.date_to, "DD/MM/YYYY").toDate()}
                            onChange={(e) => handleOnChange(e, "datebox", "date_to")}
                            label={t("ReportsProductMovements.table.dateTo")}
                        />
                    </Col>
                    <Col md={5}>
                        <AdvancedCombobox
                            ACName="Sales Product" // do not change name
                            className="w-100 ac-select-dropdown"
                            searchableDropdownEnable={false}
                            defaultValue={requestData.productName}
                            key={Math.random()}
                            label={t('ReportsProductMovements.table.productName')}
                            data={productsList}
                            onChange={(e) => handleOnChange(e, "acdd", "productName")}
                            resetFilter={() => dispatch(setProductMovementsReportRequestData({
                                ...requestData,
                                productName: ""
                            }))}
                            onChangeSearch={onChangeSearch}
                            columns={productDropdownColumns}
                            editEnable={false}
                            deleteEnable={false}
                            searchTableEnable={false}
                            showTableOptionOnSelect={true}
                            placeholder={t('ReportsProductMovements.table.productFrom.placeholder')}
                            required={true}
                            name="productName"
                            showInsertAddButton={true}
                            hideSaveButton={true}
                            hideInsertNewRow={true}
                            inputFieldClasses="form-control"
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={3}>
                        <OurDropdown
                            name={"installation"}
                            label={"Εγκατάσταση"}
                            options={installationOptions}
                            key={Math.random()}
                            defaultValue={installationOptions.find((el) => el.value === requestData.installation) ? installationOptions.find((el) => el.value === requestData.installation) : ""}
                            onChange={(e) => handleOnChange(e, "dropdown", "installation")}
                        />
                    </Col>
                    <Col md={3}>
                        <BetterCheckBox
                            text={t('ReportsProductMovements.table.includeProductsWithZeroBalance')}
                            className="mt-2 mb-0 mr-2 centered-checkbox"
                            name="product_zero"
                            checked={requestData.product_zero === "active"}
                            onChange={(e) => handleOnChange(e, "checkbox", "product_zero")}
                        />
                    </Col>
                    <Col md={3}>
                        <BetterCheckBox
                            text={t('ReportsProductMovements.table.includeInactiveProducts')}
                            className="mt-2 mb-0 mr-2 centered-checkbox"
                            name="product_inactive"
                            checked={requestData.product_inactive === "active"}
                            onChange={(e) => handleOnChange(e, "checkbox", "product_inactive")}
                        />
                    </Col>
                </Row>
                {checkPermission("products-reports/productmovements-general-read", permissionsData) &&
                    <Row>
                        <Col md={12}>
                            <Button className="btn btn-primary float-right" onClick={() => fetchProductMovements(true)} disabled={loader}>
                                {t('ReportsProductMovements.applyFilter')}
                            </Button>
                        </Col>
                    </Row>
                }
                <hr style={{color: "gray", height: 1}}/>
            </div>
            <Row>
                <h3 style={{textAlign: "center", alignItems: "center", fontWeight: "bold"}}>Κινήσεις Ειδών</h3>
            </Row>
            {requestData?.totalPages > 0 && (
                <Row>
                    <Col md={4}>
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="mt-3">
                                <h5>
                                    <strong>Κωδικός είδους: </strong> {reportData?.code}
                                </h5>
                                <h5>
                                    <strong>Όνομα είδους: </strong> {reportData?.name}
                                </h5>
                            </div>
                        </div>
                    </Col>
                    <Col md={8}>
                        <div style={{position: "absolute", bottom: "5px", right: 0}}>
                            <Button
                                size={"sm"}
                                className={"mr-1 paginate" + (requestData.page !== 1 ? "yes" : "no")}
                                onClick={() => {
                                    toFirstPage();
                                }}
                                disabled={requestData.page === 1 || loader}
                            >
                                {t('ReportsBalanceSheet.first')}
                            </Button>
                            <Button
                                size={"sm"}
                                className={"mr-1 paginate" + (requestData.page !== 1 ? "yes" : "no")}
                                onClick={() => {
                                    toPreviousPage();
                                }}
                                disabled={requestData.page === 1 || loader}
                            >
                                {t('ReportsBalanceSheet.previous')}
                            </Button>
                            <Badge style={{fontSize: "12px", lineHeight: "20px"}} className="mr-1"
                                   bg="primary">{t('ReportsBalanceSheet.page')} {requestData.page} / {requestData.totalPages}</Badge>
                            <Button
                                size={"sm"}
                                className={
                                    "mr-1 paginate" + (requestData.page !== requestData.totalPages ? "yes" : "no")
                                }
                                onClick={() => {
                                    toNextPage();
                                }}
                                disabled={requestData.page === requestData.totalPages || loader}
                            >
                                {t('ReportsBalanceSheet.next')}
                            </Button>
                            <Button
                                size={"sm"}
                                className={
                                    "mr-1 paginate" + (requestData.page !== requestData.totalPages ? "yes" : "no")
                                }
                                onClick={() => {
                                    toLastPage();
                                }}
                                disabled={requestData.page === requestData.totalPages || loader}
                            >
                                {t('ReportsBalanceSheet.last')}
                            </Button>
                        </div>
                    </Col>
                </Row>
            )}
            {reportData?.code ? (
                <React.Fragment>
                    {loader ? (
                        <Loader />
                    ) : (
                        <React.Fragment>
                            <Row>
                                <Table bordered hover style={{textAlign: "center", tableLayout: "fixed"}}>
                                    <thead>
                                    <tr>
                                        <th>Αρ.</th>
                                        <th>Ημερομηνία</th>
                                        <th>Τύπος κίνησης</th>
                                        <th>Τύπος παραστατικού</th>
                                        <th>Αρίθμηση</th>
                                        <th>Καθαρή Αξία</th>
                                        <th>Αξία Φ.Π.Α.</th>
                                        <th>Τελική Αξία</th>
                                        <th colSpan={2}>Ποσότητα</th>
                                        <th>Υπόλοιπο</th>
                                    </tr>
                                    <tr>
                                        <th colSpan={8}>&nbsp;</th>
                                        <th>Πώληση</th>
                                        <th>Πληρωμή</th>
                                        <th>&nbsp;</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {reportData?.history?.length === 0 && (
                                        <tr>
                                            <td colSpan={11}>Δεν βρέθηκαν κινήσεις</td>
                                        </tr>
                                    )}
                                    {reportData?.history?.length > 0 && reportData.history.map((line, idx) => (
                                        <React.Fragment>
                                            {line?.isTotal === "active" ? (
                                                <tr key={reportData.code + idx} style={{fontWeight: "700"}}>
                                                    <td colSpan={5}>Σύνολα Είδους</td>
                                                    <td>{getGreekCurrencyFormat(line.totalNetValue)}</td>
                                                    <td>{getGreekCurrencyFormat(line.totalVatValue)}</td>
                                                    <td>{getGreekCurrencyFormat(line.totalValue)}</td>
                                                    <td>{line.totalSale}</td>
                                                    <td>{line.totalBuy}</td>
                                                    <td>{line.balance}</td>
                                                </tr>
                                            ) : (
                                                <tr key={reportData.code + idx}>
                                                    <td>{line.no}</td>
                                                    <td>{line.date}</td>
                                                    <td>{line.movementType}</td>
                                                    <td>{line.documentTypeName}</td>
                                                    <td>{line.number}</td>
                                                    <td>{getGreekCurrencyFormat(line.netValue)}</td>
                                                    <td>{getGreekCurrencyFormat(line.vatValue)}</td>
                                                    <td>{getGreekCurrencyFormat(line.totalValue)}</td>
                                                    {line.quantity < 0 && line?.isCensus !== "active" && line?.isMovement !== "active" && (
                                                        <React.Fragment>
                                                            <td>{Math.abs(line.quantity)}</td>
                                                            <td>&nbsp;</td>
                                                        </React.Fragment>
                                                    )}
                                                    {line.quantity > 0 && line?.isCensus !== "active" && line?.isMovement !== "active" && (
                                                        <React.Fragment>
                                                            <td>&nbsp;</td>
                                                            <td>{Math.abs(line.quantity)}</td>
                                                        </React.Fragment>
                                                    )}
                                                    {line.quantity === 0 && line?.isCensus !== "active" && line?.isMovement !== "active" && (
                                                        <React.Fragment>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                        </React.Fragment>
                                                    )}
                                                    {line?.isCensus === "active" && line?.isMovement !== "active" && (
                                                        <React.Fragment>
                                                            <td colSpan={2}><i>ΑΠΟΓΡΑΦΗ</i></td>
                                                        </React.Fragment>
                                                    )}
                                                    {line?.isMovement === "active" && (
                                                        <React.Fragment>
                                                            <td colSpan={2}><i>ΑΠΟ ΜΕΤΑΦΟΡΑ</i></td>
                                                        </React.Fragment>
                                                    )}
                                                    <td>{line.balance}</td>
                                                </tr>
                                            )}
                                        </React.Fragment>
                                    ))}
                                    </tbody>
                                </Table>
                            </Row>
                            {checkPermission("products-reports/productmovements-print", permissionsData) === true && (
                                <Row>
                                    <Dropdown className="mb-3 mt-3">
                                        <Dropdown.Toggle variant="primary" id="dropdown-basic">
                                            Εκτύπωση αποτελεσμάτων
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={() => handlePDFExport(true)}>
                                                Εκτυπωτής
                                            </Dropdown.Item>
                                            <Dropdown.Item onClick={() => handlePDFExport()}>
                                                Αποθήκευση ως PDF
                                            </Dropdown.Item>
                                            <Dropdown.Item onClick={() => handleExcelExport()}>
                                                Αποθήκευση ως XLSX
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Row>
                            )}
                        </React.Fragment>
                    )}
                </React.Fragment>
            ) : (
                <React.Fragment>
                    {Object.keys(reportData).length > 0 && (
                        <span>Δεν υπάρχουν δεδομένα με αυτά τα φίλτρα.</span>
                    )}
                </React.Fragment>
            )}
            <Modal show={showCollectModal}>
                <Modal.Header>
                    <Modal.Title>Εξαγωγή Κινήσεων Ειδών</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Γίνεται φόρτωση δεδομένων, παρακαλώ περιμένετε...
                    <ProgressBar
                        variant={"primary"}
                        className={"mt-2"}
                        now={Math.ceil((collectLength / requestData.totalPages) * 100)}
                    />
                    <div
                        style={{textAlign: "center"}}>{Number((collectLength / requestData.totalPages) * 100).toFixed(2)}%
                        ({collectLength}/{requestData.totalPages})
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}
export default ProductMovementsReports;
