let storage = {
    accountingIsozygioScroll: 0,
    accountingCalendarScroll: 0,
    accountingAccountsScroll: 0,
    accountingBridgeScroll: 0,
    maintenanceMode: false,
}

export const StorageController = {
    setKey: (key, value) => {
        storage[key] = value;
    },
    getKey: (key) => {
        if (storage[key] !== undefined) {
            return storage[key];
        } else {
            return null;
        }
    },
    deleteKey: (key) => {
        delete storage[key];
    }
}