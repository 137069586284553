import React from 'react';
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Button, Col, Row} from "react-bootstrap";
import Checkbox from "../../../common/CheckBox";
import Input from "../../../common/Input";
import DateBox from "../../../primer/DateBox";
import TextArea from "../../../common/TextArea";
import {setSaveNewEmployee} from "../../../../_reducers/activitySlice";
import {setNewEmployee} from "../../../../_reducers/DataPanel/Accounting/accountingSlice";
import Dropdown from "../../../primer/Dropdown";
import {
    getDefaultOptionByValue, getEmployeeCategoryOptions,
    getEmployeeTypeOptions, getOptionsByData, getResignationCauseOptions,
    getSexOptions, getWorkingDaysPerWeekOptions, isEmpty
} from "../../../../_helpers/commonFunctions";
import AccountingBox from "../../../primer/AccountingBox";
import BetterCheckBox from "../../../common/BetterCheckBox";

const NewEmployee = () => {
    const [t] = useTranslation('common');
    const dispatch = useDispatch();
    const ACTIVITY = useSelector((state) => state.ACTIVITY);
    const ACCOUNTING_DATA = useSelector((state) => state.ACCOUNTING_DATA);
    const PAYROLL = useSelector((state) => state.PAYROLL);
    const currentDate = new Date();
    const installations = useSelector((state) => state.COMPANY_DATA.companyInstallations);
    const companyInstallations = getOptionsByData(installations, "installationMasterId", "address");

    const handleOnChange = (e, type = "default", ddname = '') => {
        if (!ACTIVITY.saveNewEmployee) dispatch(setSaveNewEmployee(true));

        let name;
        let value;
        if (type === 'dp') {
            value = e;
            name = ddname;
        } else if (type === 'ac') {
            value = e.target.value;
            name = ddname;
        } else if (type === 'dd') {
            name = ddname;
            value = e.value;
        } else if (type === "bcb") {
            value = e.target.checked;
            name = ddname;
        } else {
            value = e.target.value;
            name = e.target.name;
        }
        let posData = Object.assign({}, ACCOUNTING_DATA);
        let newPOSData = {};
        newPOSData[name] = value;
        if (name === "resignationDate") {
            if (value) {
                newPOSData.isActive = "inactive";
            } else {
                newPOSData.isActive = "active";
            }
        }
        dispatch(setNewEmployee({...posData.newEmployee, ...newPOSData}));
    }

    return (
        <React.Fragment>
            <Row className="position-relative border-bottom mb-3">
                <Col md={12} className="d-flex align-items-center justify-content-end">
                    <div className="inline-field d-flex align-items-center acremd mb-3">
                        <label htmlFor="product-active" className="mb-0 mr-2 ">{t('PosNew.active')}</label>
                        <Checkbox
                            className="mb-2"
                            name="isActive"
                            key={Math.random()}
                            id="isActive"
                            defaultValue={ACCOUNTING_DATA.newEmployee.isActive}
                            value={ACCOUNTING_DATA.newEmployee.isActive === 'active' ? "inactive" : "active"}
                            onChange={(e) => handleOnChange(e)}
                        />
                    </div>
                </Col>
            </Row>
            <p className="text-muted mb-1">
                <i>{t('EmployeeNew.personalLabel')}</i>
            </p>
            <Row className={"mb-3"}>
                <Col md={4}>
                    <Input
                        className="w-100"
                        label={t('EmployeeNew.firstName.name')}
                        name="firstName"
                        placeholder={t('EmployeeNew.firstName.placeholder')}
                        value={ACCOUNTING_DATA.newEmployee.firstName}
                        required={true}
                        onChange={(e) => handleOnChange(e)}
                    />
                    {ACTIVITY.saveNewEmployee && ACCOUNTING_DATA.newEmployee.firstName === '' && <div className="text-danger">{t('EmployeeNew.firstName.required')}</div>}
                </Col>
                <Col md={4}>
                    <Input
                        className="w-100"
                        label={t('EmployeeNew.lastName.name')}
                        name="lastName"
                        placeholder={t('EmployeeNew.lastName.placeholder')}
                        value={ACCOUNTING_DATA.newEmployee.lastName}
                        required={true}
                        onChange={(e) => handleOnChange(e)}
                    />
                    {ACTIVITY.saveNewEmployee && ACCOUNTING_DATA.newEmployee.lastName === '' && <div className="text-danger">{t('EmployeeNew.lastName.required')}</div>}
                </Col>
            </Row>
            <Row className={"mb-3"}>
                <Col md={3}>
                    <Input
                        className="w-100"
                        label={t('EmployeeNew.fatherFirstName.name')}
                        name="fatherFirstName"
                        placeholder={t('EmployeeNew.fatherFirstName.placeholder')}
                        value={ACCOUNTING_DATA.newEmployee.fatherFirstName}
                        required={true}
                        onChange={(e) => handleOnChange(e)}
                    />
                    {ACTIVITY.saveNewEmployee && ACCOUNTING_DATA.newEmployee.fatherFirstName === '' && <div className="text-danger">{t('EmployeeNew.fatherFirstName.required')}</div>}
                </Col>
                <Col md={3}>
                    <Input
                        className="w-100"
                        label={t('EmployeeNew.fatherLastName.name')}
                        name="fatherLastName"
                        placeholder={t('EmployeeNew.fatherLastName.placeholder')}
                        value={ACCOUNTING_DATA.newEmployee.fatherLastName}
                        required={true}
                        onChange={(e) => handleOnChange(e)}
                    />
                    {ACTIVITY.saveNewEmployee && ACCOUNTING_DATA.newEmployee.fatherLastName === '' && <div className="text-danger">{t('EmployeeNew.fatherLastName.required')}</div>}
                </Col>
                <Col md={3}>
                    <Input
                        className="w-100"
                        label={t('EmployeeNew.motherFirstName.name')}
                        name="motherFirstName"
                        placeholder={t('EmployeeNew.motherFirstName.placeholder')}
                        value={ACCOUNTING_DATA.newEmployee.motherFirstName}
                        required={true}
                        onChange={(e) => handleOnChange(e)}
                    />
                    {ACTIVITY.saveNewEmployee && ACCOUNTING_DATA.newEmployee.motherFirstName === '' &&
                        <div className="text-danger">{t('EmployeeNew.motherFirstName.required')}</div>}
                </Col>
                <Col md={3}>
                    <Input
                        className="w-100"
                        label={t('EmployeeNew.motherLastName.name')}
                        name="motherLastName"
                        placeholder={t('EmployeeNew.motherLastName.placeholder')}
                        value={ACCOUNTING_DATA.newEmployee.motherLastName}
                        required={true}
                        onChange={(e) => handleOnChange(e)}
                    />
                    {ACTIVITY.saveNewEmployee && ACCOUNTING_DATA.newEmployee.motherLastName === '' && <div className="text-danger">{t('EmployeeNew.motherLastName.required')}</div>}
                </Col>
            </Row>
            <Row className={"mb-2"}>
                <Col md={2}>
                    <Dropdown
                        label={t('EmployeeNew.sex.name')}
                        name="sex"
                        width="30%"
                        placeholder="Επιλέξτε Φύλο"
                        options={getSexOptions()}
                        enabledValue={true}
                        required={true}
                        onChange={(e) => handleOnChange(e, "dd", "sex")}
                        value={getDefaultOptionByValue(getSexOptions(), ACCOUNTING_DATA.newEmployee.sex, 'value', 'label', 'value')}
                        className={"mb-0"}
                    />
                    {ACTIVITY.saveNewEmployee && ACCOUNTING_DATA.newEmployee.sex === '' && <div className="text-danger">{t('EmployeeNew.sex.required')}</div>}
                </Col>
                <Col md={2}>
                    <DateBox
                        label={t('EmployeeNew.birthDate.name')}
                        name="birthDate"
                        allowOtherYears={true}
                        required={true}
                        minDate={new Date(currentDate.getFullYear() - 100, currentDate.getMonth(), currentDate.getDate())}
                        maxDate={new Date(currentDate.getFullYear() - 18, currentDate.getMonth(), currentDate.getDate())}
                        selected={ACCOUNTING_DATA.newEmployee.birthDate}
                        onChange={(e) => handleOnChange(e, 'dp', 'birthDate')}
                    />
                    {ACTIVITY.saveNewEmployee && ACCOUNTING_DATA.newEmployee.birthDate === '' && <div className="text-danger">{t('EmployeeNew.birthDate.required')}</div>}
                </Col>
                <Col md={2}>
                    <Input
                        className="w-100"
                        label={t('EmployeeNew.vatNumber.name')}
                        name="vatNumber"
                        placeholder={t('EmployeeNew.vatNumber.placeholder')}
                        value={ACCOUNTING_DATA.newEmployee.vatNumber}
                        required={true}
                        onChange={(e) => handleOnChange(e)}
                    />
                    {ACTIVITY.saveNewEmployee && ACCOUNTING_DATA.newEmployee.vatNumber === '' && <div className="text-danger">{t('EmployeeNew.vatNumber.required')}</div>}
                </Col>
                <Col md={3}>
                    <Input
                        className="w-100"
                        label="Τηλέφωνο"
                        name="phone"
                        placeholder="Εισάγετε Τηλέφωνο"
                        value={ACCOUNTING_DATA.newEmployee.phone}
                        required={false}
                        onChange={(e) => handleOnChange(e)}
                    />
                </Col>
                <Col md={3}>
                    <Input
                        label={"E-Mail"}
                        name={"email"}
                        placeholder={"Εισάγετε E-Mail"}
                        value={ACCOUNTING_DATA.newEmployee.email}
                        required={false}
                        onChange={(e) => handleOnChange(e)}
                    />
                </Col>
            </Row>
            <hr/>
            <p className="text-muted mt-2 mb-1">
                <i>{t('EmployeeNew.securityLabel')}</i>
            </p>
            <Row className={"mb-2"}>
                <Col md={4}>
                    <Input
                        className="w-100"
                        label={t('EmployeeNew.amka.name')}
                        name="amka"
                        placeholder={t('EmployeeNew.amka.placeholder')}
                        value={ACCOUNTING_DATA.newEmployee.amka}
                        required={true}
                        onChange={(e) => handleOnChange(e)}
                    />
                    {ACTIVITY.saveNewEmployee && ACCOUNTING_DATA.newEmployee.amka === '' && <div className="text-danger">{t('EmployeeNew.amka.required')}</div>}
                </Col>
                <Col md={4}>
                    <Input
                        className="w-100"
                        label={t('EmployeeNew.amika.name')}
                        name="amika"
                        placeholder={t('EmployeeNew.amika.placeholder')}
                        value={ACCOUNTING_DATA.newEmployee.amika}
                        required={true}
                        onChange={(e) => handleOnChange(e)}
                    />
                    {ACTIVITY.saveNewEmployee && ACCOUNTING_DATA.newEmployee.amika === '' && <div className="text-danger">{t('EmployeeNew.amika.required')}</div>}
                </Col>
                <Col md={4}>
                    <Input
                        label={"ΑΜΑ"}
                        name={"ama"}
                        placeholder={"Εισάγετε ΑΜΑ"}
                        value={ACCOUNTING_DATA.newEmployee.ama}
                        required={false}
                        onChange={(e) => handleOnChange(e)}
                    />
                </Col>
            </Row>
            <hr/>
            <p className="text-muted mt-2 mb-1">
                <i>{t('EmployeeNew.workingLabel')}</i>
            </p>
            <Row>
                <Col md={3}>
                    <Dropdown
                        label={t('EmployeeNew.employeeCategory.name')}
                        name="employeeCategory"
                        width="30%"
                        placeholder={t('EmployeeNew.employeeCategory.placeholder')}
                        options={getEmployeeCategoryOptions()}
                        enabledValue={true}
                        required={true}
                        onChange={(e) => handleOnChange(e, "dd", "employeeCategory")}
                        value={getDefaultOptionByValue(getEmployeeCategoryOptions(), ACCOUNTING_DATA.newEmployee.employeeCategory, 'value', 'label', 'value')}
                        className={"mb-1"}
                    />
                    {ACTIVITY.saveNewEmployee && ACCOUNTING_DATA.newEmployee.employeeCategory === '' &&
                        <div className="text-danger">{t('EmployeeNew.employeeCategory.required')}</div>}
                </Col>
                <Col md={3}>
                    <Dropdown
                        label={t('EmployeeNew.employeeType.name')}
                        name="employeeType"
                        width="30%"
                        placeholder={t('EmployeeNew.employeeType.placeholder')}
                        options={getEmployeeTypeOptions()}
                        enabledValue={true}
                        required={true}
                        onChange={(e) => handleOnChange(e, "dd", "employeeType")}
                        value={getDefaultOptionByValue(getEmployeeTypeOptions(), ACCOUNTING_DATA.newEmployee.employeeType, 'value', 'label', 'value')}
                        className={"mb-1"}
                    />
                    {ACTIVITY.saveNewEmployee && ACCOUNTING_DATA.newEmployee.employeeType === '' &&
                        <div className="text-danger">{t('EmployeeNew.employeeType.required')}</div>}
                </Col>
                <Col md={6}>
                    <Input
                        className="w-100"
                        label={t('EmployeeNew.specialty.name')}
                        name="specialty"
                        placeholder={t('EmployeeNew.specialty.placeholder')}
                        value={ACCOUNTING_DATA.newEmployee.specialty}
                        required={true}
                        onChange={(e) => handleOnChange(e)}
                    />
                    {ACTIVITY.saveNewEmployee && ACCOUNTING_DATA.newEmployee.specialty === '' &&
                        <div className="text-danger">{t('EmployeeNew.specialty.required')}</div>}
                </Col>
            </Row>
            <Row className={"mt-2"}>
                <Col md={5}>
                    <Dropdown
                        label={t('EmployeeNew.installation.name')}
                        options={companyInstallations}
                        value={getDefaultOptionByValue(companyInstallations, ACCOUNTING_DATA.newEmployee.installation, 'value', 'label', 'value')}
                        enabledValue={true}
                        searchEnable={true}
                        required={true}
                        name="installation"
                        onChange={(e) => handleOnChange(e, 'dd', 'installation')}
                        className={"mb-1"}
                    />
                    {ACTIVITY.saveNewEmployee && ACCOUNTING_DATA.newEmployee.installation === '' &&
                        <div className="text-danger">{t('EmployeeNew.installation.required')}</div>}
                </Col>
                <Col md={4}>
                    <Dropdown
                        label={"Εβδομαδιαίες ημέρες εργασίας"}
                        name={"workingDaysPerWeek"}
                        key={Math.random()}
                        options={getWorkingDaysPerWeekOptions()}
                        defaultValue={getDefaultOptionByValue(getWorkingDaysPerWeekOptions(), ACCOUNTING_DATA.newEmployee.workingDaysPerWeek, 'value', 'label', 'value')}
                        onChange={(e) => handleOnChange(e, 'dd', 'workingDaysPerWeek')}
                    />
                </Col>
                <Col md={3}>
                    <Input
                        name={"workingHoursPerWeek"}
                        label={"Εβδομαδιαίες ώρες εργασίας"}
                        type={"number"}
                        value={ACCOUNTING_DATA.newEmployee.workingHoursPerWeek}
                        onChange={(e) => handleOnChange(e)}
                    />
                </Col>
            </Row>
            <Row className={"mt-2"}>
                <Col md={3}>
                    <Input
                        name={"flexibleAttendanceMinutes"}
                        label={"Χρόνος Προσέλευσης (σε λεπτά)"}
                        type={"number"}
                        required={true}
                        value={ACCOUNTING_DATA.newEmployee.flexibleAttendanceMinutes}
                        onChange={(e) => handleOnChange(e)}
                    />
                    {ACTIVITY.saveNewEmployee && isEmpty(ACCOUNTING_DATA.newEmployee.flexibleAttendanceMinutes) && <div className="text-danger">Απαιτείται ο χρόνος προσέλευσης</div>}
                </Col>
                <Col md={3}>
                    <Input
                        name={"workingExperienceYears"}
                        label={"Χρόνια προϋπηρεσίας"}
                        type={"number"}
                        value={ACCOUNTING_DATA.newEmployee.workingExperienceYears}
                        onChange={(e) => handleOnChange(e)}
                    />
                </Col>
                <Col md={3}>
                    <Input
                        name={"firstYearPermits"}
                        label={"Άδειες πρώτου έτους"}
                        type={"number"}
                        value={ACCOUNTING_DATA.newEmployee.firstYearPermits}
                        onChange={(e) => handleOnChange(e)}
                    />
                </Col>
                <Col md={3}>
                    <Input
                        name={"breakMinutes"}
                        label={"Χρόνος διαλείμματος (σε λεπτά)"}
                        type={"number"}
                        value={ACCOUNTING_DATA.newEmployee.breakMinutes}
                        onChange={(e) => handleOnChange(e)}
                    />
                </Col>
            </Row>
            <Row className={"mt-2 mb-2"}>
                <Col md={3}>
                    <DateBox
                        classes="w-100"
                        label={t('EmployeeNew.employmentDate.name')}
                        name="employmentDate"
                        allowOtherYears={true}
                        required={true}
                        minDate={new Date(currentDate.getFullYear() - 100, currentDate.getMonth(), currentDate.getDate())}
                        selected={ACCOUNTING_DATA.newEmployee.employmentDate}
                        onChange={(e) => handleOnChange(e, 'dp', 'employmentDate')}
                    />
                    {ACTIVITY.saveNewEmployee && ACCOUNTING_DATA.newEmployee.installation === '' &&
                        <div className="text-danger">{t('EmployeeNew.employmentDate.required')}</div>}
                </Col>
                <Col md={3}>
                    <label className="" htmlFor="prefered-price">{t('EmployeeNew.salary.name')}</label>
                    <AccountingBox
                        fromAccountTable={true}
                        name="salary"
                        className="form-control"
                        placeholder={t('ProductsNewGeneralEdit.preferredPrice.placeholder')}
                        defaultValue={ACCOUNTING_DATA.newEmployee.salary}
                        onChange={(e) => handleOnChange(e, 'ac', 'salary')}
                    />
                    {ACTIVITY.saveNewEmployee && ACCOUNTING_DATA.newEmployee.salary === 0 &&
                        <div className="text-danger">{t('EmployeeNew.salary.required')}</div>}
                </Col>
                <Col md={3}>
                    <DateBox
                        classes="w-100 mb-3"
                        label={t('EmployeeNew.resignationDate')}
                        name="resignationDate"
                        allowOtherYears={true}
                        required={true}
                        minDate={ACCOUNTING_DATA.newEmployee.employmentDate ? ACCOUNTING_DATA.newEmployee.employmentDate : new Date(currentDate.getFullYear() - 100, currentDate.getMonth(), currentDate.getDate())}
                        maxDate={new Date()}
                        selected={ACCOUNTING_DATA.newEmployee.resignationDate}
                        onChange={(e) => handleOnChange(e, 'dp', 'resignationDate')}
                    />
                </Col>
                <Col md={3}>
                    <Dropdown
                        label={t('EmployeeNew.resignationCause')}
                        options={getResignationCauseOptions()}
                        value={getDefaultOptionByValue(getResignationCauseOptions(), ACCOUNTING_DATA.newEmployee.resignationCause, 'value', 'label', 'value')}
                        enabledValue={true}
                        searchEnable={true}
                        required={true}
                        name="resignationCause"
                        onChange={(e) => handleOnChange(e, 'dd', 'resignationCause')}
                    />
                </Col>
                {PAYROLL.companyHasErgani && (
                    <Col md={12}>
                        <Row>
                            <Col md={6}></Col>
                            <Col md={3}>
                                <BetterCheckBox
                                    name={"autoSendErganiCard"}
                                    text={"Αυτόματη αποστολή κάρτας εργασίας"}
                                    checked={ACCOUNTING_DATA.newEmployee.autoSendErganiCard}
                                    onChange={(e) => handleOnChange(e, "bcb", "autoSendErganiCard")}
                                />
                            </Col>
                            <Col md={3}>
                                <Button>Προβολή QR</Button>
                            </Col>
                        </Row>
                    </Col>
                )}
            </Row>
            <hr/>
            <Row>
                <div style={{width: "100%"}}>
                    <div className="text-muted mb-2"><i>{t('PosNew.notes')}</i></div>
                    <TextArea
                        rows="4"
                        placeholder={t('PosNew.addNotes')}
                        name="notes"
                        limit="1000"
                        value={ACCOUNTING_DATA.newEmployee.notes}
                        onChange={(e) => handleOnChange(e)}
                    />
                </div>
            </Row>
        </React.Fragment>
    )
}

export default NewEmployee;
