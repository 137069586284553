import { createSlice } from "@reduxjs/toolkit";

const newLogInitialValue = {
  action: '',
  fieldChanged: '',
  user: '',
  userId: '',
  date: '',
  time: '',
  type: 'product',
  itemId: '',
  extra: '',
}

const initialState = {
  newLog: newLogInitialValue,

  // Products
  productsLog: [],
  editProductsLog: [],
  productMovementsLog: [],
  editProductMovementsLog: [],
  productPriceListLog: [],
  editProductPriceListLog: [],
  productCategoryLog: [],
  editProductCategoryLog: [],

  // Clients
  clientsLog: [],
  clientMovementsLog: [],
  clientSettingsLog: [],

  // Suppliers
  suppliersLog: [],
  supplierMovementsLog: [],
  supplierSettingsLog: [],

  // Sales
  salesLog: [],
  quickRetailSalesLog: [],

    // Buys
    buysLog: [],
    quickRetailBuysLog: [],


  // Cash
  cashRegistersLog: [],
  posLog: [],
  bankAccountLog: [],

  // Settings
  basicTypeLogs: [],
  editBasicTypeLogs: [],
  advancedTypeLogs: [],
  editAdvancedTypeLogs: []
};

export const LogSlice = createSlice({
  name: "logsSlice",
  initialState,
  reducers: {
    setLog: (state, { payload }) => {
      state[payload?.type] = payload.data;
    },
    setProductsLog: (state, { payload }) => {
      state.productsLog = payload;
    },
    setProductMovementsLog: (state, { payload }) => {
      state.productMovementsLog = payload;
    },
    setProductPriceListLog: (state, { payload }) => {
      state.productPriceListLog = payload;
    },
    setProductCategoryLog: (state, { payload }) => {
      state.productCategoryLog = payload;
    },
    setClientsLog: (state, { payload }) => {
      state.clientsLog = payload;
    },
    setSuppliersLog: (state, { payload }) => {
      state.suppliersLog = payload;
    },
    setClientMovementsLog: (state, { payload }) => {
      state.clientMovementsLog = payload;
    },
    setSupplierMovementsLog: (state, { payload }) => {
      state.supplierMovementsLog = payload;
    },

    setClientSettingsLog: (state, { payload }) => {
      state.clientSettingsLog = payload;
    },
    setSupplierSettingsLog: (state, { payload }) => {
      state.supplierSettingsLog = payload;
    },
    setSalesLog: (state, { payload }) => {
      state.salesLog = payload;
    },
    setQuickRetailSalesLog: (state, { payload }) => {
      state.quickRetailSalesLog = payload;
    },

    setBuysLog: (state, { payload }) => {
      state.buysLog = payload;
    },
    setQuickRetailBuysLog: (state, { payload }) => {
      state.quickRetailBuysLog = payload;
    },
    setCashRegistersLog: (state, { payload }) => {
      state.cashRegistersLog = payload;
    },
    setPosLog: (state, { payload }) => {
      state.posLog = payload;
    },
    setBankAccountLog: (state, { payload }) => {
      state.bankAccountLog = payload;
    },
  },
});

export const {
  setProductsLog,
  setProductMovementsLog,
  setProductPriceListLog,
  setProductCategoryLog,
  setClientsLog,
  setClientMovementsLog,
  setClientSettingsLog,
  setSalesLog,
  setQuickRetailSalesLog,
  setLog,
} = LogSlice.actions;
