import React, {useEffect, useState} from "react";
import {Button, Col, Modal, Row} from "react-bootstrap";
import Input from "../../../common/Input";
import TextArea from "../../../common/TextArea";
import {getGreekCurrencyFormat, isEmpty} from "../../../../_helpers/commonFunctions";
import moment from "moment";
import "../modalWidths.scss"
import {toast} from "react-toastify";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import {addTab, updateActiveMainTab} from "../../../../_reducers/TabsSlice";
import {setEditSales} from "../../../../_reducers/DataPanel/Sales/salesSlice";

const EmronMonthlyPaymentDetails = (props) => {
    //props.data for table row data
    const dispatch = useDispatch();
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const TABS_DATA = useSelector((state) => state.TABS_REDUCER);
    const [show, setShow] = useState(false);
    const [wooData, setWooData] = useState({
        startDate: "",
        endDate: "",
        nextPaymentDate: "",
        nextPaymentAmount: "",
        tableData: [],
    });

    useEffect(() => {
        if (show && props.data.wooResponse) {
            try {
                const dat = JSON.parse(props.data.wooResponse);
                setWooData({
                    ...wooData,
                    startDate: moment(dat.start_date).format("DD/MM/YYYY HH:mm:ss"),
                    endDate: !isEmpty(dat.end_date) ? moment(dat.end_date).format("DD/MM/YYYY HH:mm:ss") : "",
                    nextPaymentDate: !isEmpty(dat.next_payment_date) ? moment(dat.next_payment_date).format("DD/MM/YYYY HH:mm:ss") : "",
                    nextPaymentAmount: getGreekCurrencyFormat(Number(dat.total)),
                })
            } catch (error) {
                console.log(error);
            }
        }
    }, [show])

    const getStatusText = (status) => {
        if (status === "active") {
            return "Ενεργή"
        } else if (status === "cancelled") {
            return "Ακυρωμένη"
        } else {
            return status;
        }
    }

    const handleOrderTransactionViewClicked = (transactionId) => {
        if (!isEmpty(transactionId)) {
            axios.get(process.env.REACT_APP_API_URL2 + "/emron/find-subscription-sale", {
                headers: { 'Content-Type': 'application/json' },
                params: {
                    company: company.id,
                    transactionId: transactionId,
                }
            }).then((res) => {
                if (res.data.status === "200") {
                    dispatch(setEditSales(res.data.data));
                    const idx = TABS_DATA.findIndex((item) => "view-sales" === item);
                    if(idx === -1) {
                        dispatch(addTab("view-sales"));
                        dispatch(updateActiveMainTab(TABS_DATA.length));
                    } else {
                        dispatch(updateActiveMainTab(idx));
                    }
                } else {
                    toast.error(res.data.message);
                }
            }).catch((error) => {
                console.log(error);
                toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
            })
        } else {
            toast.error("Δεν βρέθηκε το παραστατικό, παρακαλώ δοκιμάστε αργότερα.");
        }
    }

    return (
        <React.Fragment>
            <Button style={{pointerEvents: "auto", padding: "2px", margin: "8px"}} size={"sm"} variant={"default"} title={props.title ? props.title : "Πληροφορίες"} onClick={() => setShow(true)}>
                <i className="fa-regular fa-file-lines"></i>
            </Button>

            <Modal show={show} onHide={() => setShow(false)} dialogClassName={"modal40PercentWidth"}>
                <Modal.Header closeButton>
                    <Modal.Title>Πληροφορίες Μηνιαίας Χρέωσης</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className={"mb-2"}>
                        <Col md={6}>
                            <Input label={"Αρ. Συνδρομής"} disabled={true} value={props.data.subscription_id} />
                        </Col>
                        <Col md={6}>
                            <Input label={"Κατάσταση"} disabled={true} value={getStatusText(props.data.siteStatus)} />
                        </Col>
                    </Row>
                    <Row className={"mb-2"}>
                        <Col md={6}>
                            <Input label={"Ημερομηνία Έναρξης"} disabled={true} value={wooData.startDate} />
                        </Col>
                        <Col md={6}>
                            <Input label={"Ημερομηνία Λήξης"} disabled={true} value={isEmpty(wooData.endDate) ? "Χωρίς ημ/νια λήξης" : wooData.endDate} />
                        </Col>
                    </Row>
                    <Row className={"mb-2"}>
                        <Col md={6}>
                            <Input label={"Ημερομηνία Επόμενης Πληρωμής"} disabled={true} value={isEmpty(wooData.nextPaymentDate) ? "-" : wooData.nextPaymentDate} />
                        </Col>
                        <Col md={6}>
                            <Input label={"Ποσό Επόμενης Πληρωμής"} disabled={true} value={isEmpty(wooData.nextPaymentDate) ? "-" : wooData.nextPaymentAmount} />
                        </Col>
                    </Row>
                    <Row className={"mb-2"}>
                        <Col md={12}>
                            <TextArea
                                label={"Σημειώσεις"}
                                rows={3}
                                disabled={true}
                                value={props.data.notes}
                            />
                        </Col>
                    </Row>
                    {props.data?.orders?.length > 0 && (
                        <Row>
                            <Col md={12}>
                                <div style={{textAlign: "center"}}><h6>Ιστορικό παραγγελιών</h6></div>
                                <table style={{tableLayout: "fixed", width: "100%", textAlign: "center"}}>
                                    <thead>
                                    <tr>
                                        <th style={{border: "1px solid gray"}}>Ημ/νια πληρωμής</th>
                                        <th style={{border: "1px solid gray"}}>Αρ. Παραγγελίας</th>
                                        <th style={{border: "1px solid gray"}}>Transaction ID</th>
                                        <th style={{border: "1px solid gray"}}>Ποσό πληρωμής</th>
                                        <th style={{border: "1px solid gray", width: "25px"}}>&nbsp;</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {props.data?.orders?.map((order, idx) => (
                                        <tr key={`orderRowEP${idx}`}>
                                            <td style={{border: "1px solid gray"}}>{isEmpty(order.date_paid) ? "-" : moment(order.date_paid).format("DD/MM/YYYY HH:mm:ss")}</td>
                                            <td style={{border: "1px solid gray"}}>{order.id}</td>
                                            <td style={{border: "1px solid gray"}}>{isEmpty(order.transaction_id) ? "-" : order.transaction_id}</td>
                                            <td style={{border: "1px solid gray"}}>{getGreekCurrencyFormat(Number(order.total))}</td>
                                            <td style={{border: "1px solid gray", width: "25px"}}>{!isEmpty(order.transaction_id) && (
                                                <i className="fa-regular fa-file-lines" style={{padding: 0, cursor: "pointer"}} title={"Προβολή Παραστατικού"} onClick={() => handleOrderTransactionViewClicked(order.transaction_id)}></i>
                                            )}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </Col>
                        </Row>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={() => setShow(false)}>
                        Κλείσιμο
                    </Button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
}

export default EmronMonthlyPaymentDetails