import RootReducer from "./_reducers/rootReducer";
import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";
// composer is used to implement redux extention in browser for better flow of data and to visualize state management
const composer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// Thunk is used to pass dispath to actions
let middlewares = [thunk];
// Store contains all the implementation of reducers ready to be exported to provider of the application
const store = createStore(
  RootReducer,
  composer(applyMiddleware(...middlewares))
);
export default store;
