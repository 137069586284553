import React, {useState} from "react";
import "./modalWidths.scss"
import {Badge, Button, Modal} from "react-bootstrap";
import {toast} from "react-toastify";
import axios from "axios";

const SelectAnathetousaModal = (props) => {
    /*
    props.data for new sale/view sale
    props.selectFunction for callback, passes object with data:
    - anForeasTypeDescr
    - anForeasId
    - anAfm
    - anForeasDescr
    - anathetousaLabelId
    - anathetousaDescr
     */
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [partyIdentifications, setPartyIdentifications] = useState([]);

    const handleShow = () => {
        if (String(props.data.client).length !== 24) {
            toast.error("Παρακαλώ επιλέξτε πελάτη πρώτα.");
        } else {
            setShow(true);
            setLoading(true);
            axios.get(process.env.REACT_APP_API_URL2 + `/client/get-client-id-by/${props.data.client}`, {
                headers: { "Content-Type": "application/json" },
                params: {}
            }).then((res) => {
                setLoading(false);
                if (res.data.status === "200") {
                    if (res.data.data?.peppolPartyIdentification && res.data.data?.peppolPartyIdentification?.length > 0) {
                        setPartyIdentifications(res.data.data.peppolPartyIdentification);
                    }
                } else {
                    setShow(false);
                    toast.error("Σφάλμα κατά την εύρεση του πελάτη.");
                }
            }).catch((err) => {
                setLoading(false);
                setShow(false);
                console.log(err);
                toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
            })
        }
    }

    const handleHide = () => {
        setPartyIdentifications([]);
        setShow(false);
    }

    const handleSelect = (selection) => {
        if (props.selectFunction) {
            props.selectFunction(selection);
        }
        handleHide();
    }

    return (
        <React.Fragment>
            <Badge bg={"primary"} style={{cursor: "pointer", marginTop: "30px"}} onClick={() => handleShow()}>Επιλογή</Badge>
            <Modal show={show} onHide={() => handleHide()} dialogClassName={"modal5OPercentWidth"}>
                <Modal.Header closeButton>
                    <Modal.Title>Επιλογή Αναθέτουσας Αρχής</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {(loading && partyIdentifications.length === 0) && (
                        <div>Παρακαλώ περιμένετε...</div>
                    )}
                    {(!loading && partyIdentifications.length === 0) && (
                        <div>Δεν βρέθηκαν αναθέτουσες αρχές. Παρακαλώ κατευθυνθείτε στην καρτέλα του πελάτη και πατήστε το κουμπί "Ανάκτηση Αναθετουσών από Α.Φ.Μ."</div>
                    )}
                    {(!loading && partyIdentifications.length > 0) && show && (
                        <table style={{tableLayout: "fixed", width: "99%", textAlign: "center"}}>
                            <colgroup>
                                <col style={{width: "50%"}}></col>
                                <col style={{width: "40%"}}></col>
                                <col style={{width: "10%"}}></col>
                            </colgroup>
                            <thead>
                            <tr>
                                <th style={{border: "1px solid gray"}}>Όνομα Αναθέτουσας Αρχής</th>
                                <th style={{border: "1px solid gray"}}>Κωδικός Αναθέτουσας Αρχής</th>
                                <th style={{border: "1px solid gray"}}>Ενέργεια</th>
                            </tr>
                            </thead>
                            <tbody>
                            {partyIdentifications.length > 0 && partyIdentifications.map((row, idx) => (
                                <tr key={`anatheTable${idx}`}>
                                    <td style={{border: "1px solid gray"}}>{row?.anathetousaDescr}</td>
                                    <td style={{border: "1px solid gray"}}>{row?.anathetousaLabelId}</td>
                                    <td style={{border: "1px solid gray"}}><Badge bg={"primary"} style={{cursor: "pointer"}} onClick={() => handleSelect(row)}>Επιλογή</Badge></td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={() => setShow(false)}>Κλείσιμο</Button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
}

export default SelectAnathetousaModal;