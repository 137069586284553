export const typeAffectsOptions = [
  { value: "No", label: "No", color: "#76AEB7" },
  { value: "+", label: "+", color: "#76AEB7" },
  { value: "-", label: "-", color: "#76AEB7" }
]

export const typeAffectsOptionsSearch = [
  { value: "null", label: "Κενό", color: "#76AEB7" },
  { value: "No", label: "No", color: "#76AEB7" },
  { value: "+", label: "+", color: "#76AEB7" },
  { value: "-", label: "-", color: "#76AEB7" }
]

export const excelOptions = [
  { value: 0, label: "A", color: "#76AEB7" },
  { value: "1", label: "B", color: "#76AEB7" },
  { value: "2", label: "C", color: "#76AEB7" },
  { value: "3", label: "D", color: "#76AEB7" },
  { value: "4", label: "E", color: "#76AEB7" },
  { value: "5", label: "F", color: "#76AEB7" },
  { value: "6", label: "G", color: "#76AEB7" },
  { value: "7", label: "H", color: "#76AEB7" },
  { value: "8", label: "I", color: "#76AEB7" },
  { value: "9", label: "J", color: "#76AEB7" },
  { value: "10", label: "K", color: "#76AEB7" },
  { value: "11", label: "L", color: "#76AEB7" },
  { value: "12", label: "M", color: "#76AEB7" },
  { value: "13", label: "N", color: "#76AEB7" },
  { value: "14", label: "O", color: "#76AEB7" },
  { value: "15", label: "P", color: "#76AEB7" },
  { value: "16", label: "Q", color: "#76AEB7" },
  { value: "17", label: "R", color: "#76AEB7" },
  { value: "18", label: "S", color: "#76AEB7" },
  { value: "19", label: "T", color: "#76AEB7" },
  { value: "20", label: "U", color: "#76AEB7" },
  { value: "21", label: "V", color: "#76AEB7" },
  { value: "22", label: "W", color: "#76AEB7" },
  { value: "23", label: "X", color: "#76AEB7" },
  { value: "24", label: "Y", color: "#76AEB7" },
  { value: "25", label: "Z", color: "#76AEB7" },
  { value: "26", label: "AA", color: "#76AEB7" },
  { value: "27", label: "AB", color: "#76AEB7" },
  { value: "28", label: "AC", color: "#76AEB7" },
  { value: "29", label: "AD", color: "#76AEB7" },
  { value: "30", label: "AE", color: "#76AEB7" },
  { value: "31", label: "AF", color: "#76AEB7" },
  { value: "32", label: "AG", color: "#76AEB7" },
  { value: "33", label: "AH", color: "#76AEB7" },
  { value: "34", label: "AH", color: "#76AEB7" },
  { value: "35", label: "AI", color: "#76AEB7" },
  { value: "36", label: "AJ", color: "#76AEB7" },
]
