// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-time-picker {
  font-family: monospace; }
  .custom-time-picker input {
    padding: 0 5px;
    text-align: center; }
  .custom-time-picker .text-danger {
    font-size: 12px; }
`, "",{"version":3,"sources":["webpack://./src/_components/common/TimePickerInput/custom-time-picker.scss"],"names":[],"mappings":"AAAA;EACE,sBAAsB,EAAA;EADxB;IAGI,cAAc;IACd,kBAAkB,EAAA;EAJtB;IAQI,eAAe,EAAA","sourcesContent":[".custom-time-picker {\n  font-family: monospace;\n  input {\n    padding: 0 5px;\n    text-align: center;\n  }\n\n  .text-danger {\n    font-size: 12px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
