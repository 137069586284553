import React, {useEffect, useState} from 'react';
import {Button, Col, Row, Spinner} from "react-bootstrap";
import Input from "../../../common/Input";
import Dropdown from "../../../primer/Dropdown";
import {toast} from "react-toastify";
import {isEmpty} from "../../../../_helpers/commonFunctions";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import {Popover} from "antd";
import {
    resetTempNewShift, setCompanyHasErgani,
    setPayrollSettings,
    setTempNewShift
} from "../../../../_reducers/DataPanel/Payroll/payrollSlice";
import EditableTable from "../../../primer/Editable-Table";
import BetterCheckBox from "../../../common/BetterCheckBox";
import {fetchPayrollSettings} from "../../../../_apis/api";

export const PayrollSettings = () => {
    const dispatch = useDispatch();
    const tempNewShift = useSelector((state) => state.PAYROLL.tempNewShift);
    const payrollSettings = useSelector((state) => state.PAYROLL.payrollSettings);
    const company = useSelector((state) => state.COMPANY_DATA.company);

    const [activationLoading, setActivationLoading] = useState(false);
    const [loadingSettings, setLoadingSettings] = useState(false);

    const envOptions = [
        { label: "Δοκιμή", value: "test" },
        { label: "Παραγωγικό", value: "production" }
    ]
    const [erganiCredentials, setErganiCredentials] = useState({
        username: "",
        password: "",
        environment: "test",
    })

    const tableColumns = [
        {
            name: "Όνομα βάρδιας",
            field: "name",
            editable: true,
            width: "25%",
            required: true,
            inputType: {
                type: "input"
            }
        },
        {
            name: "Ώρα από",
            field: "hourFrom",
            editable: true,
            width: "15%",
            inputType: {
                type: "hour-picker"
            }
        },
        {
            name: "Ώρα μέχρι",
            field: "hourTo",
            editable: true,
            width: "15%",
            inputType: {
                type: "hour-picker"
            }
        },
        {
            name: "Σπαστό ωράριο από",
            field: "splitHourFrom",
            editable: true,
            width: "15%",
            inputType: {
                type: "hour-picker"
            }
        },
        {
            name: "Σπαστό ωράριο μέχρι",
            field: "splitHourTo",
            editable: true,
            width: "15%",
            inputType: {
                type: "hour-picker"
            }
        },
    ]

    useEffect(() => {
        fetchSettings();
    }, []);

    const handleActivateErgani = () => {
        if (!isEmpty(erganiCredentials) && !isEmpty(erganiCredentials.password)) {
            setActivationLoading(true);
            axios.post(`${process.env.REACT_APP_API_URL2}/payroll/activate-company`, {
                ...erganiCredentials,
                company: company.id
            }, {
                headers: {'Content-Type': 'application/json'}
            }).then((res) => {
                setActivationLoading(false);
                if (res.data.status === "200") {
                    dispatch(setCompanyHasErgani(true));
                    toast.success(res.data.message);
                } else {
                    toast.error(res.data.message, {autoClose: 8000});
                }
            }).catch((err) => {
                setActivationLoading(false);
                console.log(err);
                toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
            })
        } else {
            toast.error("Παρακαλώ συμπληρώστε όνομα και κωδικό χρήστη.");
        }
    }

    const fetchSettings = () => {
        axios.get(`${process.env.REACT_APP_API_URL2}/payroll/fetch-settings`, {
            headers: {'Content-Type': 'application/json'},
            params: {
                company: company.id,
            }
        }).then((res) => {
            if (res.data.status === "200") {
                setErganiCredentials(res.data.erganiCredentials);
                if (res.data.payrollSettings) {
                    dispatch(setPayrollSettings({...res.data.payrollSettings}));
                }
            } else {
                toast.error(res.data.message);
            }
        }).catch((err) => {
            console.log(err);
            toast.error("Σφάλμα κατά την ανάκτηση στοιχείων.");
        })
    }

    const tableShiftsFunctions = {
        insert: (e, data) => {
            const cloneNewTempShift = structuredClone(tempNewShift);
            cloneNewTempShift[data.field] = data.value;

            if (!isEmpty(cloneNewTempShift.name) && (cloneNewTempShift.hourFrom !== "00:00" || cloneNewTempShift.hourTo !== "00:00")) { // Insert
                let cloneShifts = structuredClone(payrollSettings.shifts);
                cloneShifts.push(cloneNewTempShift);
                dispatch(setPayrollSettings({...payrollSettings, shifts: cloneShifts}));
                dispatch(resetTempNewShift());
            } else { // Pre-insert row update
                dispatch(setTempNewShift(cloneNewTempShift));
            }
        },
        update: (data, id, rowIndex) => {
            let cloneShifts = structuredClone(payrollSettings.shifts);
            cloneShifts[rowIndex] = {...cloneShifts[rowIndex], ...data};
            dispatch(setPayrollSettings({...payrollSettings, shifts: cloneShifts}));
        },
        selected: () => {},
        delete: (id, rowIndex) => {
            let cloneShifts = structuredClone(payrollSettings.shifts);
            cloneShifts.splice(rowIndex, 1);
            dispatch(setPayrollSettings({...payrollSettings, shifts: cloneShifts}));
        }
    }

    const handleOnChange = (e, type, name) => {
        if (type === "cb") {
            dispatch(setPayrollSettings({...payrollSettings, [name] : e.target.checked}));
        }
    }

    const handleSavePayrollSettings = () => {
        setLoadingSettings(true);
        axios.post(`${process.env.REACT_APP_API_URL2}/payroll/save-settings`, {
            ...payrollSettings,
            company: company.id,
        }).then((res) => {
            setLoadingSettings(false);
            if (res.data.status === "200") {
                toast.success("Επιτυχής αποθήκευση ρυθμίσεων!");
                dispatch(fetchPayrollSettings(company.id));
            } else {
                toast.error(res.data.message);
            }
        }).catch((err) => {
            setLoadingSettings(false);
            console.log(err);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        })
    }

    return (
        <React.Fragment>
            <Row className={"mb-2"}>
                <Col md={5}>
                    <div className={"text-muted mb-1"}><i>Στοιχεία Εργάνη</i></div>
                    <Popover placement={"right"} content={
                        <div>
                            Το όνομα χρήστη που χρησιμοποιείτε για να συνδεθείτε στο https://eservices.yeka.gr
                        </div>
                    }>
                        <>
                            <Input
                                className={"mb-2"}
                                name={"erganiUsername"}
                                label={"Όνομα Χρήστη"}
                                value={erganiCredentials.username}
                                autoComplete={"off"}
                                onChange={(e) => setErganiCredentials({...erganiCredentials, username: e.target.value})}
                            />
                        </>
                    </Popover>

                    <Popover placement={"right"} content={
                        <div>
                            Ο κωδικός που χρησιμοποιείτε για να συνδεθείτε στο https://eservices.yeka.gr
                        </div>
                    }>
                        <>
                            <Input
                                className={"mb-2"}
                                name={"erganiPassword"}
                                label={"Κωδικός Χρήστη"}
                                type={"password"}
                                value={erganiCredentials.password}
                                autoComplete={"off"}
                                onChange={(e) => setErganiCredentials({...erganiCredentials, password: e.target.value})}
                            />
                        </>
                    </Popover>
                    <Dropdown
                        name={"erganiEnvironment"}
                        options={envOptions}
                        label={"Περιβάλλον"}
                        defaultValue={envOptions.find((el) => el.value === erganiCredentials.environment)}
                        key={Math.random()}
                        onChange={(e) => setErganiCredentials({...erganiCredentials, environment: e.value})}
                    />
                    <div style={{textAlign: "center"}}>
                        <Button onClick={() => handleActivateErgani()} disabled={activationLoading}>
                            Ενεργοποίηση {activationLoading && <Spinner className={"ml-2"} animation={"border"} variant={"dark"} />}
                        </Button>
                    </div>
                </Col>
                <Col md={7} style={{borderLeft: "1px solid gray"}}>
                    <div className={"text-muted mb-2"}><i>Βάρδιες</i></div>
                    <EditableTable
                        tableName={"Payroll Settings Shifts"}
                        key={Math.random()}
                        data={payrollSettings.shifts}
                        columns={tableColumns}
                        onUpdate={tableShiftsFunctions}
                        allowInsertRow={true}
                        allowActions={true}
                    />
                    <div className={"text-muted mb-2 mt-2"}><i>Αυτοματισμοί</i></div>
                    <Row>
                        <Col md={6}>
                            <BetterCheckBox
                                name={"autoSendEmployeeCard"}
                                text={"Να γίνεται αυτόματη αποστολή καρτών στην Εργάνη"}
                                checked={payrollSettings.autoSendEmployeeCard}
                                onChange={(e) => handleOnChange(e, "cb", "autoSendEmployeeCard")}
                            />
                        </Col>
                        <Col md={6}>
                            <BetterCheckBox
                                name={"autoSendOutOfTimeDays"}
                                text={"Να γίνεται αυτόματη αποστολή απολογιστικού στο τέλος του μήνα"}
                                checked={payrollSettings.autoSendOutOfTimeDays}
                                onChange={(e) => handleOnChange(e, "cb", "autoSendEmployeeCard")}
                            />
                        </Col>
                    </Row>
                    <Button size={"sm"} className={"float-right"} disabled={loadingSettings} onClick={() => handleSavePayrollSettings()}>
                        Αποθήκευση ρυθμίσεων {loadingSettings && <Spinner className={"ml-2"} animation={"border"} variant={"dark"} />}
                    </Button>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export const PayrollSettingsLogs = () => {
    return (
        <React.Fragment>

        </React.Fragment>
    )
}