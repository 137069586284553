// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.subscription-box {
  position: relative;
  border: 2px solid #6ea2a9;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  transition: transform 100ms linear; }

.subscription-box-permission:hover {
  cursor: pointer;
  transform: scale(1.02); }

.subscription-box-inner {
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px; }

.subscription-box-header {
  line-height: 20px;
  text-align: center;
  padding-top: 10px; }

.dLabel {
  font-weight: 600; }
`, "",{"version":3,"sources":["webpack://./src/_components/layouts/data-panel/Settings/styles/settings-subscriptions.scss"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,yBAAyB;EACzB,kBAAkB;EAClB,0CAA0C;EAC1C,kCAAkC,EAAA;;AAGpC;EACE,eAAe;EACf,sBAAsB,EAAA;;AAGxB;EACE,iBAAiB;EACjB,kBAAkB;EAClB,mBAAmB,EAAA;;AAErB;EACE,iBAAiB;EACjB,kBAAkB;EAClB,iBAAiB,EAAA;;AAEnB;EACE,gBAAgB,EAAA","sourcesContent":[".subscription-box {\r\n  position: relative;\r\n  border: 2px solid #6ea2a9;\r\n  border-radius: 5px;\r\n  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);\r\n  transition: transform 100ms linear;\r\n}\r\n.subscription-box-permission {}\r\n.subscription-box-permission:hover {\r\n  cursor: pointer;\r\n  transform: scale(1.02);\r\n}\r\n\r\n.subscription-box-inner {\r\n  margin-left: 10px;\r\n  margin-right: 10px;\r\n  margin-bottom: 10px;\r\n}\r\n.subscription-box-header {\r\n  line-height: 20px;\r\n  text-align: center;\r\n  padding-top: 10px;\r\n}\r\n.dLabel {\r\n  font-weight: 600;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
