// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-supplier-icon {
  border: 1px solid #000 !important;
  border-radius: 100%;
  height: 24px;
  width: 24px;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 9px;
  cursor: pointer;
  font-size: 13px; }

.new-supplier-modal-body .overlay_w {
  background: rgba(255, 255, 255, 0.7);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 3;
  cursor: pointer;
  font-size: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
  padding-top: 200px;
  color: #76AEB7; }

.new-supplier-modal-body .showWithOverlay {
  position: relative;
  z-index: 3; }
`, "",{"version":3,"sources":["webpack://./src/_components/layouts/data-panel/Suppliers/supplierModal.scss"],"names":[],"mappings":"AAAA;EACI,iCAAiC;EACjC,mBAAmB;EACnB,YAAY;EACZ,WAAW;EACX,cAAc;EACd,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,eAAe;EACf,eAAe;EACf,eAAe,EAAA;;AAGnB;EAEQ,oCAAoC;EACpC,kBAAkB;EAClB,MAAM;EACN,QAAQ;EACR,OAAO;EACP,SAAS;EACT,UAAU;EACV,eAAe;EACf,eAAe;EACf,kBAAkB;EAClB,aAAa;EACb,uBAAuB;EACvB,kBAAkB;EAClB,cAAc,EAAA;;AAftB;EAkBQ,kBAAkB;EAClB,UAAU,EAAA","sourcesContent":[".add-supplier-icon {\r\n    border: 1px solid #000 !important;\r\n    border-radius: 100%;\r\n    height: 24px;\r\n    width: 24px;\r\n    line-height: 1;\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    margin-top: 9px;\r\n    cursor: pointer;\r\n    font-size: 13px;\r\n}\r\n\r\n.new-supplier-modal-body {\r\n    .overlay_w {\r\n        background: rgba(255, 255, 255, 0.7);\r\n        position: absolute;\r\n        top: 0;\r\n        right: 0;\r\n        left: 0;\r\n        bottom: 0;\r\n        z-index: 3;\r\n        cursor: pointer;\r\n        font-size: 20px;\r\n        text-align: center;\r\n        display: flex;\r\n        justify-content: center;\r\n        padding-top: 200px;\r\n        color: #76AEB7;\r\n    }\r\n    .showWithOverlay {\r\n        position: relative;\r\n        z-index: 3;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
