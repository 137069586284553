import React, {useEffect, useState} from "react";
import axios from "axios";
import {Col, Row} from "react-bootstrap";
import TextArea from "../../../common/TextArea";
import Input from "../../../common/Input";
import DateBox from "../../../primer/DateBox";
import EditableTable from '../../../primer/Editable-Table';
import Dropdown from "../../../primer/Dropdown";
import {toast} from "react-toastify";
import {useDispatch, useSelector} from "react-redux";
import {setEditing} from "../../../../_reducers/activitySlice";
import {fetchAdvancedTypes, fetchInitialSuppliers} from "../../../../_apis/api";
import {
  getDefaultOptionByValue,
  getGreekCurrencyFormat,
  getLastHighestPrefixNumber,
  getModeByInstallation,
  getNumberByInstallation,
  getOptionsByData,
  getPreNumberByInstallation,
  getSelectedInstallation,
  getSelectedPreNumbers,
  removeDuplicateObjects,
  resetTableItemsId
} from "../../../../_helpers/commonFunctions";
import {
  deleteObjectFromArrayOfObjects,
  getLabelByValue,
  getObjectFromArrayOfObjects
} from "../../../../_helpers/helperFunctions";
import getMyData from "../../../../_helpers/sharedmydata";
import {
  setNewSupplierMovements,
} from "../../../../_reducers/DataPanel/Suppliers/supplierSlice";
import {preNumberGreek} from "../../../../_data/settings/advanced-types-search";
import {useTranslation} from "react-i18next";

const SupplierMovementsNew = () => {
  const [t] = useTranslation('common');
  const dispatch = useDispatch();
  const ACTIVITY = useSelector( (state) => state.ACTIVITY );
  const SUPPLIERS_DATA = useSelector( (state) => state.SUPPLIERS_DATA );
  const SETTINGS = useSelector( (state) => state.SETTINGS );
  const company = useSelector(state => state.COMPANY_DATA.company)
  const measurementUnit  = getMyData({type: 'mu'});
  let selectedSupplier = [];
  const supplierCodeColumns = [
    {
      label: t("General.systemCode"),
      name: "systemCode",
    },
    {
      label: t("General.name"),
      name: "name",
    },
  ];

  const supplierMovementColumns = [
    {
      name: t("General.no"),
      field: "no",
      editable: false,
    },
    {
      name: t("General.systemCode"),
      field: "systemCode",
      editable: true,
      inputType: {
        config: {
          excludeColumns: [],
          columns: supplierCodeColumns,
          data: SUPPLIERS_DATA.suppliers,
          editEnable: false,
          deleteEnable: false,
          searchTableEnable: false,
          showTableOptionOnSelect: true,
          placeholder: t("Placeholders.selectSupplier")
        },
        type: 'advanced-combobox',
      }
    },
    {
      name: t("General.supplierName"),
      field: "name",
      editable:true,
      inputType: {
        config: {
          excludeColumns: [],
          columns: supplierCodeColumns,
          data: SUPPLIERS_DATA.suppliers,
          editEnable: false ,
          deleteEnable: false,
          searchTableEnable: false,
          showTableOptionOnSelect: true,
          placeholder: t("Placeholders.selectSupplier")
        },
        type: 'advanced-combobox',
      }
    },
    {
      name: t("General.amount"),
      field: 'amount',
      editable: true,
      inputType: {
        type: 'accounting',
        config: {
          focused: true,
          suffix: "nosuffix",
          maxLength: 10,
          allowNegative: true,
        }
      }
    },
  ];

  // Options for Document Type Dropdown
  const allDocumentTypes = SETTINGS.settings.advancedTypes;
  const supplierMovemnetDT = getObjectFromArrayOfObjects(allDocumentTypes, 'Supplier Movements', 'section');
  const documentTypesOptions =  getOptionsByData(supplierMovemnetDT, '_id', 'name')
  const requestData = {
    company:company.id,
    year:company.year.toString(),
  }
  const [preNumberGreekOptions, setPreNumberGreekOptions] = useState([]);
  let selectedDocumentTypeData = [];
  let dtInstallationsOptions = [];
  let disabledFields = false;
  if (SUPPLIERS_DATA.newSupplierMovements.documentType === undefined || SUPPLIERS_DATA.newSupplierMovements.documentType === '') {
    disabledFields = true;
  } else {
    let dtData = getObjectFromArrayOfObjects(supplierMovemnetDT, SUPPLIERS_DATA.newSupplierMovements.documentType, '_id');
    if (dtData.length > 0) {
      selectedDocumentTypeData  = dtData['0'];
      dtInstallationsOptions = getOptionsByData(selectedDocumentTypeData['documentNumbering'], "installationMasterId", "installation");
      dtInstallationsOptions = removeDuplicateObjects(dtInstallationsOptions, 'value');
    }
  }


  const handleOnChange = async (e, type="default", ddname = '') => {
    // Set if editing the form
    if (!ACTIVITY.editing) {
      dispatch(setEditing(true));
    }
    let name;
    let value;
    if (type === 'dd' || type === 'ac' ) {
      value = e.value;
      name = ddname;
    } else if (type === 'dp' ) {
      value = e;
      name = ddname;
    } else {
      value = e.target.value;
      name = e.target.name;
    }
    let supplierData = Object.assign({}, SUPPLIERS_DATA);
    let newSupplierMovementData = {};
    newSupplierMovementData[name] = value;
    if (name === 'documentType') {
      newSupplierMovementData.documentTypeName = getLabelByValue(documentTypesOptions, value, 'value');
      let dtData = getObjectFromArrayOfObjects(supplierMovemnetDT, value, '_id');
      if (dtData.length > 0) {
        selectedDocumentTypeData  = dtData['0'];
        let selectedInstallation = getSelectedInstallation(selectedDocumentTypeData['documentNumbering']);
        // Selected Installation Fields on Document Type Select
        if (Object.keys(selectedInstallation).length > 0) {
          newSupplierMovementData.installation = selectedInstallation.installationMasterId;
          newSupplierMovementData.preNumber = selectedInstallation.preNumber;
          // Logic For Higest Number
          let installationNumber = selectedInstallation.number + 1;
          let lastHigestNumber = getLastHighestPrefixNumber(SUPPLIERS_DATA.supplierMovements, 'number', selectedInstallation.preNumber);
          if (installationNumber > lastHigestNumber) {
            newSupplierMovementData.number = installationNumber;
          } else {
            newSupplierMovementData.number = lastHigestNumber + 1;
          }
          newSupplierMovementData.installationMode = selectedInstallation.installationMode;
        } else {
          newSupplierMovementData.installation = "";
          newSupplierMovementData.preNumber = "";
          newSupplierMovementData.number = "";
          newSupplierMovementData.installationMode = "";
        }
        // This will pre number given in document number table
        let newPreNumbers = getSelectedPreNumbers(selectedDocumentTypeData['documentNumbering'], preNumberGreek);
        setPreNumberGreekOptions(newPreNumbers);
      }
    }

    // Update Installtion Field Values on Change
    if (name === 'installation') {
      newSupplierMovementData.installationName = getLabelByValue(dtInstallationsOptions, value, 'value');
      newSupplierMovementData.preNumber = getPreNumberByInstallation(selectedDocumentTypeData['documentNumbering'], value , 'installation');
      let installationNumber = getNumberByInstallation(selectedDocumentTypeData['documentNumbering'], value , 'installation')
      let lastHigestNumber = getLastHighestPrefixNumber(SUPPLIERS_DATA.supplierMovements, 'number', newSupplierMovementData.preNumber);
      if (installationNumber > lastHigestNumber) {
        newSupplierMovementData.number = installationNumber;
      } else {
        newSupplierMovementData.number = lastHigestNumber + 1;
      }
      // This will pre number given in document number table
      let newPreNumbers = getSelectedPreNumbers(selectedDocumentTypeData['documentNumbering'], preNumberGreek);
      setPreNumberGreekOptions(newPreNumbers);
      newSupplierMovementData.installationMode = getModeByInstallation(selectedDocumentTypeData['documentNumbering'], value , 'installation');
    }

    // It will update the last available number from the API.
    if ((name === 'documentType' || name === 'preNumber' || name === 'installation') &&
        (
          (supplierData.newSupplierMovements.installationMode ==='automatic' || newSupplierMovementData.installationMode ==='automatic' ) &&
          (supplierData.newSupplierMovements.documentType !=='' || newSupplierMovementData.documentType !=='') &&
          (supplierData.newSupplierMovements.preNumber !=='' || newSupplierMovementData.preNumber !=='') &&
          (supplierData.newSupplierMovements.installation !=='' || newSupplierMovementData.installation !==''))) {
      let checkAtParams = {};
      checkAtParams.id            = !newSupplierMovementData.documentType ? supplierData.newSupplierMovements.documentType : newSupplierMovementData.documentType;
      checkAtParams.preNumber     = !newSupplierMovementData.preNumber ? supplierData.newSupplierMovements.preNumber : newSupplierMovementData.preNumber;
      checkAtParams.installation  = !newSupplierMovementData.installation.toString() ? supplierData.newSupplierMovements.installation.toString() : newSupplierMovementData.installation.toString();

      await axios.post(process.env.REACT_APP_API_URL2 + `/settings/add-setting-advance-type-check`, JSON.stringify(checkAtParams), { headers: { 'Content-Type': 'application/json'}})
      .then(res => {
        if (res.data &&  res.data.status === '200') {
          if (res.data.number !== undefined) {
            newSupplierMovementData.number = parseInt(res.data.number) + 1;
          }
        }
      }).catch((err) => {
        console.log(err);
      })
    }
    // Save sum on change
    newSupplierMovementData.sum = getSumOfDataValues(SUPPLIERS_DATA.newSupplierMovements.suppliers, 'amount');
    dispatch(setNewSupplierMovements({...supplierData.newSupplierMovements, ...newSupplierMovementData}));
  }

  useEffect(() => {
    dispatch(fetchInitialSuppliers(requestData));
    dispatch(fetchAdvancedTypes(requestData));
    // calculate sum of amount
    const supplierAmountSum = getSumOfDataValues(SUPPLIERS_DATA.newSupplierMovements.suppliers, 'amount');
    dispatch(setNewSupplierMovements({...SUPPLIERS_DATA.newSupplierMovements, sum: supplierAmountSum}));
  }, [dispatch])

  useEffect(() => {
    if(SUPPLIERS_DATA.newSupplierMovements.installation !== undefined && SUPPLIERS_DATA.newSupplierMovements.installation !== ''){
      let newPreNumbers = getSelectedPreNumbers(selectedDocumentTypeData['documentNumbering'], preNumberGreek, SUPPLIERS_DATA.newSupplierMovements.installation);
      setPreNumberGreekOptions(newPreNumbers);
    }
  }, []);


  const getSumOfDataValues = (data, field = 'sum') => {
    let sum = 0;
    if (data && data.length > 0) {
      data.forEach(item => {
        sum += +item[field] ?? 0;
      })
    }
    return sum;
  }

  const parentMydataActions = {
    insert: (e, data) => {
      if (!ACTIVITY.editing) dispatch(setEditing(true));
      const newSupplier = e.row;
      if(newSupplier) {
        let movementSuppliers = [...SUPPLIERS_DATA.newSupplierMovements.suppliers];
        if (movementSuppliers.findIndex((el) => el.supplierId === newSupplier._id) !== -1) {
          toast.error("This supplier is already selected.");
        } else {
          movementSuppliers.push({
            no: String(movementSuppliers.length + 1),
            id: String(movementSuppliers.length + 1),
            systemCode: newSupplier.systemCode,
            supplierId: newSupplier._id,
            name: newSupplier.name,
          })
          dispatch(setNewSupplierMovements({...SUPPLIERS_DATA.newSupplierMovements, suppliers: movementSuppliers, sum: getSumOfDataValues(movementSuppliers, 'amount')}));
        }
      }
    },
    update: (data, id, e) => {
      if (!ACTIVITY.editing) dispatch(setEditing(true));
      const newSupplier = e.row;
      if(newSupplier || data) {
        let movementSuppliers = [...SUPPLIERS_DATA.newSupplierMovements.suppliers];
        movementSuppliers = movementSuppliers.map((a) => Object.assign({}, a));
        let idx = movementSuppliers.findIndex((el) => String(el.id) === String(id));
        if (idx !== -1) {
          if (data.supplierId || data.name || data.systemCode) {
            if (movementSuppliers.findIndex((el) => el.supplierId === newSupplier._id) !== -1) {
              toast.error("This supplier is already selected.");
            } else {
              movementSuppliers[idx].supplierId = newSupplier._id;
              movementSuppliers[idx].name = newSupplier.name;
              movementSuppliers[idx].systemCode = newSupplier.systemCode;
            }
          }
          if (data.amount) movementSuppliers[idx].amount = data.amount;
        }
        dispatch(setNewSupplierMovements({...SUPPLIERS_DATA.newSupplierMovements, suppliers: movementSuppliers, sum: getSumOfDataValues(movementSuppliers, 'amount')}));
      }
    },
    selected: () => {},
    delete: (id, rowIndex) => {
      if (!ACTIVITY.editing) dispatch(setEditing(true));
      let movementSuppliers = [...SUPPLIERS_DATA.newSupplierMovements.suppliers];
      movementSuppliers = movementSuppliers.map((a) => Object.assign({}, a));
      movementSuppliers = movementSuppliers.filter((a) => a.id !== String(rowIndex + 1));
      movementSuppliers.map((sp, ind) => {
        sp.no = String(ind + 1);
        sp.id = String(ind + 1)
      })
      dispatch(setNewSupplierMovements({...SUPPLIERS_DATA.newSupplierMovements, suppliers: movementSuppliers, sum: getSumOfDataValues(movementSuppliers, 'amount')}));
    }
  }
  const selectedDocumentType = getDefaultOptionByValue(documentTypesOptions, SUPPLIERS_DATA.newSupplierMovements.documentType, 'value', 'label', 'value');
  const selectedPreNumber = getDefaultOptionByValue(preNumberGreek, SUPPLIERS_DATA.newSupplierMovements.preNumber, 'value', 'label', 'value');
  return (
    <React.Fragment>
      <form className="supplier-form product-form position-relative" autoComplete = "off">
      {disabledFields && <div className="overlay_w">{t("Required.selectDocumentFirst")}</div>}
      <Row>
        <Col sm={12} md={3} className="showWithOverlay">
          <Dropdown
            label={t("General.documentType")}
            key = { Math.random() }
            defaultValue = {selectedDocumentType}
            required= {true}
            name="documentType"
            options={documentTypesOptions}
            onChange = {(e) => handleOnChange(e, 'dd', 'documentType')}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={3}>
          <Dropdown
            label={t("General.installation")}
            name="installation"
            value = {getDefaultOptionByValue(dtInstallationsOptions, SUPPLIERS_DATA.newSupplierMovements.installation , 'value', 'label', 'value')}
            enabledValue = {true}
            options={dtInstallationsOptions}
            onChange = {(e) => handleOnChange(e, 'dd', 'installation')}
          />
        </Col>
        <Col sm={12} md={2}>
          <Dropdown
            label={t("General.preNumber")}
            name="preNumber"
            value = {selectedPreNumber}
            enabledValue = {true}
            options={preNumberGreekOptions}
            onChange = {(e) => handleOnChange(e, 'dd', 'preNumber')}
          />
        </Col>
        <Col sm="12" md="3">
          <Input
            className="mb-3 w-100"
            label={t("General.number")}
            charLimit = {1000}
            name="number"
            value = {SUPPLIERS_DATA.newSupplierMovements.number}
            onChange = {(e) => handleOnChange(e)}
            disabled={disabledFields || SUPPLIERS_DATA.newSupplierMovements.installationMode !== 'manual'}
          />
        </Col>
        <Col sm="12" md="4">
          <DateBox
            classes="w-100"
            label={t("General.date")}
            name="date"
            required = {true}
            selected="today"
            onChange = {(e) => handleOnChange(e ,'dp', 'date')}
          />
        </Col>
      </Row>
      <Row>
        <Col sm="12">
          <Input
            className="mb-3 w-100"
            label={t("General.reason")}
            name="reason"
            value = { SUPPLIERS_DATA.newSupplierMovements.reason }
            onChange = {(e) => handleOnChange(e)}
          />
        </Col>
      </Row>
      <hr className="mb-4" />
      <Row>
      <div className="et-table-ui">
        <EditableTable
          tableName = "Supplier Movements Suppliers" // Do not change name
          key={Math.random()}
          allowActions={true}
          onUpdate={parentMydataActions}
          allowInsertRow="true"
          columns={supplierMovementColumns}
          enableNewRowCheckbox = {true}
          data={ SUPPLIERS_DATA.newSupplierMovements.suppliers }
        />
      </div>

        <div className="w-100 my-3">
          <div className="sum d-flex border align-items-center justify-content-between">
            <span className="py-2 px-3 border-right"><strong>{t("General.sum")}</strong></span>
            <span></span>
            <span className="py-2 px-3 border-left"><strong>{getGreekCurrencyFormat(parseFloat(SUPPLIERS_DATA.newSupplierMovements.sum).toFixed(2))}</strong></span>
          </div>
        </div>
      </Row>
      <hr />
      <Row>
        <div style={{ width: "100%" }}>
          <div className="text-muted mb-2"><i>{t("General.notes")}</i></div>
          <TextArea
            rows="4"
            placeholder={t("Placeholders.notes")}
            name="notes"
            limit="1000"
            value = { SUPPLIERS_DATA.newSupplierMovements.notes }
            onChange = {(e) => handleOnChange(e)}
          />
        </div>
      </Row>
      </form>
    </React.Fragment>
  )
}

export default SupplierMovementsNew;
