import React, {useEffect, useState} from 'react';
import {classicStyleBelowNavbar} from "../Statics";
import axios from "axios";
import {useSelector} from "react-redux";
import {toast} from "react-toastify";
import {Button, Col, Modal, Row, Spinner} from "react-bootstrap";
import Input from "../../../common/Input";
import QR from "qrcode-base64";

const EmployeeView = () => {
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const [displayData, setDisplayData] = useState({
        firstName: "",
        lastName: "",
        vatNumber: "",
        dateHired: "",
        todayProgram: "",
        cardDetails: "",
        enableCard: false,

        arrival: "",
        leave: "",
    });
    const [loading, setLoading] = useState(false);
    const [QRModal, setQRModal] = useState(false);

    useEffect(() => {
        fetchEmployeeView();
    }, [])

    const fetchEmployeeView = () => {
        axios.get(`${process.env.REACT_APP_API_URL2}/payroll/get-employee-view`, {
            headers: { "Content-Type": "application/json" },
            params: {
                company: company.id,
            }
        }).then((res) => {
            if (res.data.status === "200") {
                setDisplayData({...displayData, ...res.data.data});
            } else {
                toast.error(res.data.message);
            }
        }).catch((err) => {
            console.log(err);
            toast.error("Σφάλμα κατά την άντληση δεδομένων.");
        })
    }

    const handleSendEmployeeCard = () => {
        setLoading(true);
        axios.post(`${process.env.REACT_APP_API_URL2}/payroll/post-qr-movement`, {
            company: company.id,
            qr: displayData.cardDetails,
            installationMasterId: "auto"
        }, {
            headers: { "Content-Type": "application/json" },
        }).then((res) => {
            setLoading(false);
            if (res.data.status === "200") {
                toast.success("Η αποστολή έγινε επιτυχώς");
                fetchEmployeeView();
            } else {
                toast.error(res.data.message);
            }
        }).catch((err) => {
            setLoading(false);
            console.log(err);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος, η κάρτα ΔΕΝ πέρασε.");
        })
    }

    return (
        <div style={classicStyleBelowNavbar}>
            <Row className={"d-flex justify-content-center"}>
                <Col md={6}>
                    <div style={{borderRadius: "12px", border: "1px solid #6ea2a9", padding: "12px", width: "100%", textAlign: "center"}} className={"mb-3"}>
                        <div className={"text-muted"}><i>Στοιχεία εργαζομένου</i></div>
                        <Input
                            label={"Α.Φ.Μ."}
                            value={displayData.vatNumber}
                            disabled={true}
                            className={"mb-1"}
                        />
                        <Input
                            label={"Ονοματεπώνυμο"}
                            value={`${displayData.firstName} ${displayData.lastName}`}
                            disabled={true}
                            className={"mb-1"}
                        />
                        <Input
                            label={"Ημερομηνία Πρόσληψης"}
                            value={displayData.dateHired}
                            disabled={true}
                            className={"mb-1"}
                        />
                        <Input
                            label={"Σημερινό Πρόγραμμα"}
                            value={displayData.todayProgram}
                            disabled={true}
                            className={"mb-2"}
                        />
                        {displayData.enableCard && (
                            <Button size={"sm"} style={{width: "100%"}} onClick={() => setQRModal(true)}>Προβολή του QR μου</Button>
                        )}
                    </div>
                    {displayData.enableCard && (
                        <div style={{
                            borderRadius: "12px",
                            border: "1px solid #6ea2a9",
                            padding: "12px",
                            width: "100%",
                            textAlign: "center"
                        }}>
                            <div className={"text-muted"}><i>Κάρτα εργασίας</i></div>
                            <Input
                                label={"Ώρα προσέλευσης"}
                                value={displayData.arrival}
                                disabled={true}
                                className={"mb-1"}
                                placeholder={" "}
                            />
                            <Input
                                label={"Ώρα αναχώρησης"}
                                value={displayData.leave}
                                disabled={true}
                                className={"mb-2"}
                                placeholder={" "}
                            />
                            <Button size={"sm"} style={{width: "100%"}} onClick={() => handleSendEmployeeCard()} disabled={loading}>
                                Αποστολή τώρα {loading && <Spinner className={"ml-2"} animation="border" variant="dark"/>}
                            </Button>
                        </div>
                    )}
                    <Modal show={QRModal} onHide={() => setQRModal(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Το QR μου</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className={"d-flex justify-content-center"}>
                                <img style={{width: "100%"}} src={QR.drawImg(displayData.cardDetails, {
                                    typeNumber: 4,
                                    errorCorrectLevel: 'M',
                                })} alt="QR Code"/>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="outline-primary" onClick={() => setQRModal(false)}>
                                Κλείσιμο
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </Col>
            </Row>
        </div>
    )
}

export default EmployeeView
