import React, {useEffect, useState} from "react";
import styled, {keyframes} from "styled-components";
import {useTranslation} from "react-i18next";
const spin = keyframes` to {transform: rotate(360deg);}`;

const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const Spinner = styled.div`
  border: 5px solid rgba(0, 0, 0, 0.1);
  border-top-color: #76aeb7;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: ${spin} 0.8s ease-in-out infinite;
`;

const Button = styled.button`
  background-color: #76aeb7;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  margin-top: 20px;

  &:hover {
    background-color: #76aeb7;
  }`;
const Text = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-top: 20px;`;
const SmallText = styled.div`
  font-size: 12px;
  font-style: italic;
  margin-top: 10px;
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
`;
const Loader = ({ cancel, doNotShowSmallText }) => {
    const [t] = useTranslation('common');
    const [showSmallText, setShowSmallText] = useState(false);
    const [showSmallText15, setShowSmallText15] = useState(false);

    useEffect(() => {
        if(doNotShowSmallText !== true) {
            const timeoutId = setTimeout(() => {
                setShowSmallText(true);
            }, 5000);

            return () => clearTimeout(timeoutId);
        }
    }, []);
    useEffect(() => {
        const timeoutId15 = setTimeout(() => {
            setShowSmallText(false);
            setShowSmallText15(true);
        }, 300000);

        return () => clearTimeout(timeoutId15);
    }, []);
    return (
        <LoaderContainer>
            <Spinner />
            <Text>{t("LoaderText.loading")}</Text>
            {showSmallText && (
                <SmallText>
                    {t("LoaderText.loaderDesc5")}
                </SmallText>
            )}
            {showSmallText15 && (
                <SmallText>
                    {t("LoaderText.loaderDesc15")}
                </SmallText>
            )}
            {cancel && (
            <Button onClick={cancel}>{t("LoaderText.cancel")}</Button>
            )}
        </LoaderContainer>
    );
};

export default Loader;
