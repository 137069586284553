import React from 'react';
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {setNewClient} from "../../../../_reducers/DataPanel/Clients/clientSlice";
import Input from "../../../common/Input";
import {Col, Row} from "react-bootstrap";
import {setSaveNewClient} from "../../../../_reducers/activitySlice";

const NewClientAccounting = () => {
    const dispatch = useDispatch();
    const [t] = useTranslation('common');
    const ACTIVITY = useSelector((state) => state.ACTIVITY);
    const CLIENTS_DATA = useSelector((state) => state.CLIENTS_DATA);

    const handleOnChange = (e, type, compName) => {
        if (!ACTIVITY.saveNewClient) dispatch(setSaveNewClient(true));
        if(type === "input") {
            if(compName === "accounting") {
                dispatch(setNewClient({...CLIENTS_DATA.newClient, accounting: e.target.value}));
            } else if (compName === "bypassAccounting") {
                dispatch(setNewClient({...CLIENTS_DATA.newClient, bypassAccounting: e.target.value}));
            }
        }
    }

    return (
        <React.Fragment>
            <Row>
                <Col md={4}>
                    <Input
                        name="accounting"
                        className="mb-3"
                        charLimit={20}
                        placeholder="Εισάγετε Πρόθεμα Κωδικού Λογαριασμού"
                        label="Πρόθεμα Κωδικού Λογαριασμού"
                        value={CLIENTS_DATA.newClient.accounting}
                        onChange={(e) => handleOnChange(e, "input", "accounting")}
                    />
                </Col>
                <Col md={4}>
                    <Input
                        name="bypassAccounting"
                        className="mb-3"
                        charLimit={20}
                        placeholder="Εισάγετε Κωδικό Λογαριασμού Παράκαμψης"
                        label="Κωδικός Λογαριασμού Παράκαμψης"
                        value={CLIENTS_DATA.newClient.bypassAccounting}
                        onChange={(e) => handleOnChange(e, "input", "bypassAccounting")}
                    />
                </Col>
            </Row>
        </React.Fragment>
    )
}
export default NewClientAccounting;
