import React, {useState} from "react";
import {Row, Col, Container} from "react-bootstrap";
import EditableTable from "../../../primer/Editable-Table";
import {
    withHoldingTaxesDropdownOptions,
    withHoldTaxesTaxLevel,
    withHoldTaxesVatIncluded
} from "../../../../_data/mydata/withHoldingTaxes";
import {
    otherTaxesDropdownOptions,
    otherTaxesTaxLevel,
    otherTaxesVatIncluded
} from "../../../../_data/mydata/otherTaxes";
import {useSelector, useDispatch} from "react-redux";
import {
    setEditing
} from "../../../../_reducers/activitySlice";
import {toast} from "react-toastify";
import {
    setNewOtherHoldTax,
    setNewWithHoldTax,
    setPrimerMyDataData
} from "../../../../_reducers/settingsSlice";
import {deleteObjectFromArrayOfObjects, findObjectFromArrayOfObjects} from "../../../../_helpers/helperFunctions";
import {setSavePrimerMyDataData} from "../../../../_reducers/activitySlice";
import {useTranslation} from "react-i18next";
import {checkPermission} from "../../../../_helpers/commonFunctions";

const VatDataOtherTaxes = () => {
    const [t] = useTranslation('common');
    const dispatch = useDispatch();
    const SETTINGS = useSelector(state => state.SETTINGS);
    const ACTIVITY = useSelector((state) => state.ACTIVITY);
    let pMDDSettings = SETTINGS.primerMyDataData;
    let wTData = pMDDSettings?.withHoldTaxes !== undefined && pMDDSettings?.withHoldTaxes !== '' ? (pMDDSettings.withHoldTaxes) : deleteObjectFromArrayOfObjects(SETTINGS.withHoldTaxes, 'delete', 'isActive');
    let oTData = pMDDSettings?.otherTaxes !== undefined && pMDDSettings?.otherTaxes !== '' ? (pMDDSettings.otherTaxes) : deleteObjectFromArrayOfObjects(SETTINGS.otherHoldTaxes, 'delete', 'isActive');
    const [withholdTableData, setWithHoldTaxes] = useState(wTData);
    const [otherTableData, setOtherTaxes] = useState(oTData);
    const permissionsData = JSON.parse(localStorage.getItem("user"))?.permissions;

    const withHoldingTaxesColumns = [
        {
            name: "No",
            field: "id",
            editable: false,
            width: "2%",
        },
        {
            name: t('SettingsPrimerData.tableWithhold.name'),
            field: "name",
            width: "30%",
            editable: true,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t('SettingsPrimerData.tableWithhold.taxLevel'),
            field: 'taxLevel',
            editable: true,
            width: "15%",
            inputType: {
                type: 'dropdown',
                options: withHoldTaxesTaxLevel
            }
        },
        {
            name: t('SettingsPrimerData.tableWithhold.vatIncluded'),
            field: 'vatIncluded',
            editable: true,
            width: "10%",
            inputType: {
                type: 'dropdown',
                options: withHoldTaxesVatIncluded
            }
        },
        {
            name: t('SettingsPrimerData.tableWithhold.vatPercentage'),
            field: 'vatPercentage',
            editable: true,
            width: "3%",
            inputType: {
                type: 'accounting',
                config: {
                    focused: true,
                    suffix: "%",
                    maxLength: 5
                }
            }
        },
        {
            name: '%',
            field: 'taxPer',
            editable: true,
            width: "5%",
            inputType: {
                type: 'radio',
                name: 'withHoldTaxType',
                radioLogic: 'oneperrow',
                options: [{
                    value: true,
                    label: 'True'
                }, {
                    value: false,
                    label: 'False'
                }]
            }
        },
        {
            name: '€',
            field: 'taxAmount',
            editable: true,
            width: "5%",
            inputType: {
                type: 'radio',
                radioLogic: 'oneperrow',
                name: 'withHoldTaxType',
                options: [{
                    value: true,
                    label: 'True'
                }, {
                    value: false,
                    label: 'False'
                }]
            }
        },
        {
            name: t('SettingsPrimerData.tableWithhold.value'),
            field: 'tax',
            editable: true,
            width: "7%",
            inputType: {
                type: 'accounting',
                config: {
                    focused: true,
                    suffix: "%",
                    maxLength: 5
                }
            }
        },
        {
            name: t('SettingsPrimerData.tableWithhold.myData'),
            field: 'myData',
            editable: true,
            width: "20%",
            inputType: {
                type: 'dropdown',
                options: withHoldingTaxesDropdownOptions
            }
        },
        {
            name: "Πρόθεμα Λογιστικής",
            field: "accounting",
            editable: true,
            hiddenColumn: !checkPermission("accounting-settings/accounting-read", permissionsData),
            width: "10%",
            inputType: {
                type: "input",
            }
        }
    ];

    const otherTaxesColumns = [
        {
            name: "No",
            field: "id",
            editable: false,
            width: "2%",
        },
        {
            name: t('SettingsPrimerData.tableOtherTax.name'),
            field: "name",
            width: "30%",
            editable: true,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t('SettingsPrimerData.tableOtherTax.taxLevel'),
            field: 'taxLevel',
            editable: true,
            width: "15%",
            inputType: {
                type: 'dropdown',
                options: otherTaxesTaxLevel
            }
        },
        {
            name: t('SettingsPrimerData.tableOtherTax.vatIncluded'),
            field: 'vatIncluded',
            editable: true,
            width: "10%",
            inputType: {
                type: 'dropdown',
                options: otherTaxesVatIncluded
            }
        },
        {
            name: t('SettingsPrimerData.tableOtherTax.vatPercentage'),
            field: 'vatPercentage',
            editable: true,
            width: "5%",
            inputType: {
                type: 'accounting',
                config: {
                    focused: true,
                    suffix: "%",
                    maxLength: 5
                }
            }
        },
        {
            name: '%',
            field: 'taxPer',
            editable: true,
            width: "5%",
            inputType: {
                type: 'radio',
                name: 'withHoldTaxType',
                radioLogic: 'oneperrow',
                options: [{
                    value: true,
                    label: 'True'
                }, {
                    value: false,
                    label: 'False'
                }]
            }
        },
        {
            name: '€',
            field: 'taxAmount',
            editable: true,
            width: "5%",
            inputType: {
                type: 'radio',
                radioLogic: 'oneperrow',
                name: 'withHoldTaxType',
                options: [{
                    value: true,
                    label: 'True'
                }, {
                    value: false,
                    label: 'False'
                }]
            }
        },
        {
            name: t('SettingsPrimerData.tableOtherTax.value'),
            field: 'tax',
            editable: true,
            width: "7%",
            inputType: {
                type: 'accounting',
                config: {
                    focused: true,
                    suffix: "%",
                    maxLength: 5
                }
            }
        },
        {
            name: t('SettingsPrimerData.tableOtherTax.myData'),
            field: 'myData',
            editable: true,
            width: "20%",
            inputType: {
                type: 'dropdown',
                options: otherTaxesDropdownOptions
            }
        },
        {
            name: "Πρόθεμα Λογιστικής",
            field: "accounting",
            editable: true,
            width: "10%",
            inputType: {
                type: "input",
            }
        }
    ];

    const withHoldTaxesActions = {
        insert: (e, data) => {
            // Set if editing the form
            if (!ACTIVITY.editing) {
                dispatch(setEditing(true));
            }

            let tempNewWithholdTax = Object.assign({}, SETTINGS.newWithHoldTax);
            if (e.key !== "Enter") {
                tempNewWithholdTax[data.field] = data.value;

                if (data.field === 'taxAmount' && (data.value === "on" || data.value === "true")) {
                    tempNewWithholdTax.taxAmount = "true";
                    tempNewWithholdTax.taxPer = "false";
                }

                if (data.field === 'taxPer' && (data.value === "on" || data.value === "true")) {
                    tempNewWithholdTax.taxAmount = "false";
                    tempNewWithholdTax.taxPer = "true";
                }

                dispatch(setNewWithHoldTax(tempNewWithholdTax)); // Update redux new category state
                // On Value Enter
                if (tempNewWithholdTax.name !== "" &&
                    tempNewWithholdTax.myData !== "" &&
                    tempNewWithholdTax.tax !== "" &&
                    (tempNewWithholdTax.taxAmount === "true" || tempNewWithholdTax.taxPer === "true") &&
                    tempNewWithholdTax.taxLevel !== "" &&
                    tempNewWithholdTax.vatIncluded !== "") {
                    let updatedNewWithholdTax = Object.assign({}, SETTINGS.newWithHoldTax);
                    let tempTaxesData = withholdTableData.slice();
                    if (tempTaxesData) {
                        // Generate code number
                        if (tempTaxesData.length > 0) {
                            updatedNewWithholdTax.code = parseInt(tempTaxesData[tempTaxesData.length - 1].code) + 1;
                            updatedNewWithholdTax.id = parseInt(tempTaxesData[tempTaxesData.length - 1].code) + 1;
                        } else {
                            updatedNewWithholdTax.code = 1
                            updatedNewWithholdTax.id = 1
                        }
                        updatedNewWithholdTax[data.field] = data.value; // last value updates
                        tempTaxesData[updatedNewWithholdTax.id - 1] = updatedNewWithholdTax;
                        setWithHoldTaxes(tempTaxesData);  // update redux state
                        console.log(tempTaxesData);
                        dispatch(setPrimerMyDataData({...pMDDSettings, withHoldTaxes: (tempTaxesData)}))
                        dispatch(setSavePrimerMyDataData(true));// Reset
                        toast.info('1 row added. Please save your changes');
                        dispatch(setNewWithHoldTax(''));
                    }
                }

            }
        },
        selected: () => {
            // Set if editing the form
            if (!ACTIVITY.editing) {
                dispatch(setEditing(true));
            }

        },
        update: (data, id) => {
            // Set if editing the form
            if (!ACTIVITY.editing) {
                dispatch(setEditing(true));
            }

            let validCheck = true;
            dispatch(setSavePrimerMyDataData(true));// update redux state

            if (data.tax && ((data.tax === '') || (data.tax === 'undefined'))) {
                toast.error('Tax should not be empty');
                validCheck = false;
            }

            if (data.name && data.name === '') {
                toast.error('Name should not be empty');
                validCheck = false;
            }

            if (data.myData && data.myData === '') {
                toast.error('Tax should not be empty');
                validCheck = false;
            }
            console.log(data)

            if (data.taxAmount && (data.taxAmount === "on" || data.taxAmount === "true")) {
                data.taxAmount = "true";
                data.taxPer = "false";
            }

            if (data.taxPer && (data.taxPer === "on" || data.taxPer === "true")) {
                data.taxAmount = "false";
                data.taxPer = "true";
            }
            if (withholdTableData && validCheck) {
                let wTaxData = withholdTableData.slice()
                let objindx = findObjectFromArrayOfObjects(withholdTableData, id.toString(), 'code');
                objindx = Object.assign({}, objindx);
                const wObjIdx = withholdTableData.findIndex(wObj => wObj.code === id.toString());
                if (data.taxPer) {
                    objindx.taxPer = data.taxPer;
                }
                if (data.taxAmount) {
                    objindx.taxAmount = data.taxAmount;
                }
                if (data.tax) {
                    objindx.tax = data.tax;
                }
                if (data.name) {
                    objindx.name = data.name;
                }
                if (data.myData) {
                    objindx.myData = data.myData;
                }
                if (data.taxLevel) {
                    objindx.taxLevel = data.taxLevel;
                }
                if (data.vatIncluded) {
                    objindx.vatIncluded = data.vatIncluded;
                }
                if (data.vatPercentage) {
                    objindx.vatPercentage = data.vatPercentage;
                }
                if (data.accounting !== undefined) {
                    objindx.accounting = data.accounting;
                }
                wTaxData[wObjIdx] = objindx;
                setWithHoldTaxes(wTaxData);
                console.log(wTaxData)
                dispatch(setPrimerMyDataData({...pMDDSettings, withHoldTaxes: (wTaxData)}));
                toast.info('1 row updated. Please save your changes');
            }
        },
        delete: (id) => {
            let tempTaxesData = withholdTableData.slice();
            if (tempTaxesData) {
                const objindx = withholdTableData.findIndex(obj => obj.id === id);
                let tempUpdateObj = findObjectFromArrayOfObjects(withholdTableData, id.toString(), 'code');
                tempUpdateObj = Object.assign({}, tempUpdateObj);
                tempUpdateObj.isActive = 'delete';
                tempTaxesData[objindx] = tempUpdateObj;
                tempTaxesData.splice(objindx, 1);
                setWithHoldTaxes(tempTaxesData);
                dispatch(setPrimerMyDataData({...pMDDSettings, withHoldTaxes: (tempTaxesData)}));
                dispatch(setSavePrimerMyDataData(true));// update redux state
                toast.info('1 row removed. Please save your changes');
            }
        }
    }

    const OtherTaxesActions = {
        insert: (e, data) => {
            // Set if editing the form
            if (!ACTIVITY.editing) {
                dispatch(setEditing(true));
            }

            let tempNewOtherholdTax = Object.assign({}, SETTINGS.newOtherHoldTax);
            if (e.key !== "Enter") {
                tempNewOtherholdTax[data.field] = data.value;

                if (data.field === 'taxAmount' && (data.value === "on" || data.value === "true")) {
                    tempNewOtherholdTax.taxAmount = "true";
                    tempNewOtherholdTax.taxPer = "false";
                }

                if (data.field === 'taxPer' && (data.value === "on" || data.value === "true")) {
                    tempNewOtherholdTax.taxAmount = "false";
                    tempNewOtherholdTax.taxPer = "true";
                }

                dispatch(setNewOtherHoldTax(tempNewOtherholdTax)); // Update redux new category state

                // On Value Enter
                if (tempNewOtherholdTax.name !== "" &&
                    tempNewOtherholdTax.myData !== "" &&
                    tempNewOtherholdTax.tax !== "" &&
                    (tempNewOtherholdTax.taxAmount === "true" || tempNewOtherholdTax.taxPer === "true") &&
                    tempNewOtherholdTax.taxLevel !== "" &&
                    tempNewOtherholdTax.vatIncluded !== "") {
                    let updatedNewOtherholdTax = Object.assign({}, SETTINGS.newOtherHoldTax);
                    let tempTaxesData = otherTableData.slice();
                    if (tempTaxesData) {
                        // Generate code number
                        if (tempTaxesData.length > 0) {
                            updatedNewOtherholdTax.code = parseInt(tempTaxesData[tempTaxesData.length - 1].code) + 1;
                            updatedNewOtherholdTax.id = parseInt(tempTaxesData[tempTaxesData.length - 1].code) + 1;
                        } else {
                            updatedNewOtherholdTax.code = 1
                            updatedNewOtherholdTax.id = 1
                        }
                        updatedNewOtherholdTax[data.field] = data.value; // last value updates
                        tempTaxesData[updatedNewOtherholdTax.id - 1] = updatedNewOtherholdTax;
                        setOtherTaxes(tempTaxesData);
                        dispatch(setPrimerMyDataData({...pMDDSettings, otherTaxes: (tempTaxesData)}))
                        dispatch(setSavePrimerMyDataData(true));// Reset
                        toast.info('1 row added. Please save your changes');
                    }
                }

            }
        },
        selected: () => {
            // Set if editing the form
            if (!ACTIVITY.editing) {
                dispatch(setEditing(true));
            }

        },
        update: (data, id) => {
            // Set if editing the form
            if (!ACTIVITY.editing) {
                dispatch(setEditing(true));
            }

            let validCheck = true;
            dispatch(setSavePrimerMyDataData(true));// update redux state

            if (data.tax && ((data.tax === '') || (data.tax === 'undefined'))) {
                toast.error('Tax should not be empty');
                validCheck = false;
            }

            if (data.name && data.name === '') {
                toast.error('Name should not be empty');
                validCheck = false;
            }

            if (data.myData && data.myData === '') {
                toast.error('Tax should not be empty');
                validCheck = false;
            }

            if (data.taxAmount && (data.taxAmount === "on" || data.taxAmount === "true")) {
                data.taxAmount = "true";
                data.taxPer = "false";
            }

            if (data.taxPer && (data.taxPer === "on" || data.taxPer === "true")) {
                data.taxAmount = "false";
                data.taxPer = "true";
            }
            if (otherTableData && validCheck) {
                let oTaxData = otherTableData.slice()
                let objindx = findObjectFromArrayOfObjects(otherTableData, id.toString(), 'code');
                objindx = Object.assign({}, objindx);
                const wObjIdx = otherTableData.findIndex(wObj => wObj.code === id.toString());
                if (data.taxPer) {
                    objindx.taxPer = data.taxPer;
                }
                if (data.taxAmount) {
                    objindx.taxAmount = data.taxAmount;
                }
                if (data.tax) {
                    objindx.tax = data.tax;
                }
                if (data.name) {
                    objindx.name = data.name;
                }
                if (data.myData) {
                    objindx.myData = data.myData;
                }
                if (data.taxLevel) {
                    objindx.taxLevel = data.taxLevel;
                }
                if (data.vatIncluded) {
                    objindx.vatIncluded = data.vatIncluded;
                }
                if (data.vatPercentage) {
                    objindx.vatPercentage = data.vatPercentage;
                }
                if (data.accounting !== undefined) {
                    objindx.accounting = data.accounting;
                }
                oTaxData[wObjIdx] = objindx;
                setOtherTaxes(oTaxData);
                dispatch(setPrimerMyDataData({...pMDDSettings, otherTaxes: (oTaxData)}))
                toast.info('1 row added. Please save your changes');
            }
        },
        delete: (id) => {
            let tempTaxesData = otherTableData.slice();
            if (tempTaxesData) {
                const objindx = otherTableData.findIndex(obj => obj.id === id);
                let tempUpdateObj = findObjectFromArrayOfObjects(otherTableData, id.toString(), 'code');
                tempUpdateObj = Object.assign({}, tempUpdateObj);
                tempUpdateObj.isActive = 'delete';
                tempTaxesData[objindx] = tempUpdateObj;
                tempTaxesData.splice(objindx, 1);
                setOtherTaxes(tempTaxesData);
                dispatch(setPrimerMyDataData({...pMDDSettings, otherTaxes: (tempTaxesData)}));
                dispatch(setSavePrimerMyDataData(true));// update redux state
                toast.info('1 row removed. Please save your changes');
            }
        }
    }

    return (
        <Container fluid>
            <Row>
                <Col sm="12">
                    <EditableTable
                        heading={t('SettingsPrimerData.withholdTaxes')}
                        tableName="Withhold Taxes Table"
                        allowActions={true}
                        allowInsertRow={true}
                        columns={withHoldingTaxesColumns}
                        data={withholdTableData}
                        onUpdate={withHoldTaxesActions}
                        key={Math.random()}
                    />
                </Col>
            </Row>
            <hr/>
            <Row>
                <Col sm="12">
                    <EditableTable
                        heading={t('SettingsPrimerData.otherTaxes')}
                        tableName="Other Taxes Table"
                        allowActions={true}
                        allowInsertRow={true}
                        columns={otherTaxesColumns}
                        data={otherTableData}
                        onUpdate={OtherTaxesActions}
                        key={Math.random()}
                    />
                </Col>
            </Row>
        </Container>
    )
}
export default VatDataOtherTaxes;
