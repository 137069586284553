import axios from "axios";
import React, { useEffect, useState } from "react";
import Input from "../../../common/Input";
import {Row, Col, Button, Modal, FormLabel, Card, ListGroup, Spinner} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {fetchCompanyDetails, fetchCompanyInstallations, testSmtpConnection} from "../../../../_apis/api";
import EditableTable from "../../../primer/Editable-Table";
import Upload from "rc-upload";
import { Line } from "rc-progress";
import {toast} from "react-toastify";
import Checkbox from "../../../common/CheckBox";
import {setSaveEmailSmtp} from "../../../../_reducers/activitySlice";
import {setEmailSmtp} from "../../../../_reducers/settingsSlice";
import {
  checkPermission,
  emailSettingsWildcardsList,
  getCurrentUser, getDOYOptions, getStockholderPositions,
  isEmpty, recalculateStockHolders, stockHolderPercentageDiff
} from "../../../../_helpers/commonFunctions";
import RichTextEditor from 'react-rte';
import {useTranslation} from "react-i18next";
import DateBox from "../../../primer/DateBox";
import Alert from "react-bootstrap/Alert";
import BetterCheckBox from "../../../common/BetterCheckBox";
import moment from "moment";
import {setCompanyDetails, setCompanyInstallations} from "../../../../_reducers/companySlice";
import Dropdown from "../../../primer/Dropdown";
const apiURL = process.env.REACT_APP_API_URL2;
const apiLoginURL = process.env.REACT_APP_LOGIN_URL;

export const CompanySettings = () => {
  const [t] = useTranslation('common');
  const dispatch = useDispatch();
  const company = useSelector((state) => state.COMPANY_DATA.company);
  const installations = useSelector((state) => state.COMPANY_DATA.companyInstallations);
  const companyDetails = useSelector(state => state.COMPANY_DATA.companyDetails);
  const [companyData, setCompanyData] = useState({});
  const [userData, setUserData] = useState([]);
  const [imgData, setImgdata] = useState(companyDetails.companyLogo !== undefined && companyDetails.companyLogo !== "" ? companyDetails.companyLogo : "");
  const [useLogo = companyDetails.useLogo !== undefined && companyDetails.useLogo !== "" ? companyDetails.useLogo : "inactive",setUseLogo] = useState();
  const [percentage, setPercentage] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const permissionsData = JSON.parse(localStorage.getItem("permissions"));

  const initNewInstallation = {
    vatNumber: company.vatNumber,
    type: "Κεντρικό",
    installationMasterId: "0",
    companyId: company.id,
    doy: "",
    country: "GR",
    address: "",
    city: "",
    isActive: "active",
    postalCode: "",
    phone: "",
    installationAccounting: "",
  }
  const [newInstallation, setNewInstallation] = useState({...initNewInstallation});
  const [newInstallationLoading, setNewInstallationLoading] = useState(false);
  const [showNewInstallationModal, setShowNewInstallationModal] = useState(false);

  const [editInstallation, setEditInstallation] = useState({});
  const [editInstallationLoading, setEditInstallationLoading] = useState(false);
  const [showEditInstallationModal, setShowEditInstallationModal] = useState(false);

  const [installationOptions, setInstallationOptions] = useState([]);

  useEffect(() => {
    if (installationOptions.length === 0) {
      let tOpt = [{label: "Κεντρικό", value: "0"}];
      for (let i = 1; i <= 25; i++) {
        tOpt.push({label: `Υποκατάστημα ${i}`, value: String(i)});
      }
      setInstallationOptions(tOpt);
    }
  }, [])

  const activityOptions = [
    {value: "active", label: "Ενεργή"},
    {value: "inactive", label: "Ανενεργή"}
  ]

  const stockHoldersTableColumns = [
    {
      name: "Αρ.",
      field: "no",
      editable: false,
    },
    {
      name: "Όνομα",
      field: "name",
      editable: true,
      required: true,
      inputType: {
        type: 'text',
      }
    },
    {
      name: "Επώνυμο",
      field: "surname",
      editable: true,
      required: true,
      inputType: {
        type: 'text',
      }
    },
    {
      name: "ΑΦΜ",
      field: "vat",
      editable: true,
      required: true,
      inputType: {
        type: 'text',
      }
    },
    {
      name: "Θέση",
      field: "position",
      width: '120px',
      editable: true,
      required: true,
      inputType: {
        options: getStockholderPositions(),
        type: 'dropdown',
      }
    },
    {
      name: "Ποσοστό %",
      field: "percentage",
      editable: true,
      required: true,
      inputType: {
        type: 'accounting',
      }
    },
    {
      name: "Συνολική Αξία μετοχών",
      field: "stockValue",
      editable: false,
      disabled: true,
      inputType: {
        type: 'accounting',
        config: {
          focused: true,
          suffix: "€",
          maxLength: 7 // Given in the wireframe
        }
      }
    },
    {
      name: "Ημ/νια από",
      field: "dateFrom",
      editable: true,
      required: true,
      inputType: {
        type: 'datebox',
        allowOtherYears: "true"
      }
    },
    {
      name: "Ημ/νια έως",
      field: "dateTo",
      editable: true,
      inputType: {
        type: 'datebox',
        allowOtherYears: "true"
      }
    },
    {
      name: "Κατάσταση",
      field: "isActive",
      editable: true,
      required: true,
      width: "15%",
      inputType: {
        type: "select",
        options: [{label: "Ενεργός", value: "true"}, {label: "Ανενεργός", value: "false"}]
      }
    }
  ]

  const props = {
    action: apiURL + "/company/uploadCompanyLogo",
    accept: ".png, .jpeg, .jpg",
    method: "post",
    data: {
      company: company.id,
      logo: imgData.toString()
    },
    headers: { "Content-Type": "application/json" },

    onStart(file) {
      console.log('onStart', file, file.name);
    },
    onSuccess() {
      // Finish upload
      setIsUploading(false);
    },
    onProgress(step) {
      // Update progress
      setPercentage(Math.round(step.percent));
    },
    onError(err) {
      console.log("onError", err);
    },
    async customRequest({action, data, file, filename, headers, onError, onProgress, onSuccess, withCredentials}) {
      if (data) {
        data.company = company.id;
        console.log(file)
        if ((file.type === "image/png" || file.type === "image/jpeg") && file.size < 70000) {
          const reader = new FileReader();
          reader.onloadend = await( async () => {
            var image = new Image();
            //Set the Base64 string return from FileReader as source.
            image.src = reader.result;
            //Validate the File Height and Width.
            image.onload = await(async function () {
              var height = this.height;
              var width = this.width;
              console.log(width)
              if (height > 100 || width > 350) {
                    toast.error("Please upload a file with the appropriate dimensions 350x100");
              } else {
                  setImgdata(reader.result);
                  data.logo = reader.result.toString();
                  console.log(data.logo.substring(0, 500))
                  console.log(data)
                  await axios.post(action, data, {
                    maxBodyLength: Infinity,
                    maxContentLength: Infinity,
                    onUploadProgress: ({total, loaded}) => {
                      onProgress({percent: Math.round((loaded / total) * 100).toFixed(2)}, file);
                    },
                  })
                      .then(({data: response}) => {
                        onSuccess(response, file);
                        toast.success("Company Logo was uploaded successfully");
                        dispatch(fetchCompanyDetails(company.id));
                      })
                      .catch((error) => {
                        console.log(error)
                      });
              }
            });
          });
          await reader.readAsDataURL(file);
          return {
            abort() {
              toast.error("Something went wrong! Please try again later.");
            },
          };
        } else {
          toast.error("Please upload a file with the appropriate size and type")
        }
      }
    },
  };
  const handleUseLogo = async (e) => {
    let requestData = {
      useLogo: e.target.value.toString(),
      company: company.id
    }
    await axios.post(apiURL + "/company/uploadCompanyLogo", requestData, {
      maxBodyLength: Infinity,
      maxContentLength: Infinity,
    })
        .then((res) => {
          if(res.data.status === "200") {
            toast.success("Company Logo Usage was updated successfully");
            dispatch(fetchCompanyDetails(company.id));
            setUseLogo(e.target.value);
          } else {
            setUseLogo("inactive");
            toast.error(res.data.message);
          }
        })
        .catch((error) => {
          console.log(error)
          setUseLogo("inactive");
          toast.error(error);
        });
  }

  useEffect(() => {
    let requestData = {
      companyId: company?.id,
      year: company.year,
    };
    axios.get(apiURL + `/company/details/${company.id}`)
    .then((res) => {
      if (res.data && res.data.status === "200") {
        setCompanyData(res.data.data); // Update data
      }
    })
    .catch((err) => {
      console.log(err);
    });
      axios
      .post(apiURL + `/company/users`, requestData)
      .then((res) => {
        let users = res.data.data;

        users.forEach((d, i) => {
          d.no = ++i;
          d.name = d.firstName + " " + d.lastName
          delete d.permissions
        });
        setUserData([...users]);
      })
      .catch((err) => {
        console.log(err);
        setUserData([]);
      });
  }, [company.id, company.year]);

  const installationsColumns = [
    {
      name: "No",
      field: "no",
      editable: false,
      width: "5%",
    },
    {
      name: t('GeneralSettings.tableClientInstallations.type'),
      field: "type",
      editable: false,
      width: "10%",
      inputType: {
        type: "dropdown",
        config: {
          searchEnable: true,
        },
      },
    },
    {
      name: t('GeneralSettings.tableClientInstallations.address'),
      field: "address",
      width: "15%",
      editable: false,
      inputType: {
        type: "text",
      },
    },
    {
      name: t('GeneralSettings.tableClientInstallations.doy'),
      field: "doy",
      editable: false,
      width: "15%",
      inputType: {
        type: "dropdown",
        config: {
          searchEnable: true,
        },
      },
    },
    {
      name: t('GeneralSettings.tableClientInstallations.city'),
      field: "city",
      width: "15%",
      editable: false,
      inputType: {
        type: "text",
        config: {
          searchEnable: true,
        },
      },
    },
    {
      name: t('GeneralSettings.tableClientInstallations.postalCode'),
      field: "postalCode",
      editable: false,
      width: "10%",
      inputType: {
        type: "text",
        config: {
          searchEnable: true,
        },
      },
    },
    {
      name: "Κατάσταση",
      field: "isActive",
      editable: false,
      width: "10%",
      inputType: {
        type: "input",
      }
    },
    {
      name: "Πρόθεμα Εγκατάστασης Λογιστικής",
      field: "installationAccounting",
      editable: true,
      hiddenColumn: !checkPermission("accounting-settings/accounting-read", permissionsData),
      width: "5%",
      inputType: {
        type: "input",
      }
    },
    {
      name: "Πρόθεμα Μετρητών Λογιστικής",
      field: "cashAccounting",
      editable: true,
      hiddenColumn: !checkPermission("accounting-settings/accounting-read", permissionsData),
      width: "5%",
      inputType: {
        type: "input",
      }
    },
    {
      name: "Ενέργειες",
      field: "installationActions",
      editable: false,
      width: "5%",
      inputType: {
        type: "input",
        editInstallationClicked: (rowData) => handleOpenEditInstallationModal(rowData)
      }
    }
  ];

  const companyUsersColumns = [
    {
      name: "No",
      field: "no",
      editable: false,
    },
    {
      name: t('GeneralSettings.tableCompanyUser.firstName'),
      field: "firstName",
      editable: false,
      width: "20%",
      inputType: {
        type: "text",
      },
    },
    {
      name: t('GeneralSettings.tableCompanyUser.lastName'),
      field: "lastName",
      editable: false,
      width: "20%",
      inputType: {
        type: "text",
      },
    },
    {
      name: t('GeneralSettings.tableCompanyUser.email'),
      field: "email",
      width: "20%",
      editable: false,
      inputType: {
        type: "text",
      },
    },
    {
      name: t('GeneralSettings.tableCompanyUser.username'),
      field: "userName",
      editable: false,
      width: "20%",
      inputType: {
        type: "text",
      },
    },
  ];

  const installationTableActions = {
    update: (data, id) => {
      if (data.installationAccounting !== undefined) {
        let cloneInstallations = [...installations];
        cloneInstallations = cloneInstallations.map((o) => Object.assign({}, o));
        const findMatching = cloneInstallations.find((el) => el._id === id);
        if (findMatching) {
          findMatching.installationAccounting = data.installationAccounting;
        }
        dispatch(setCompanyInstallations(cloneInstallations));
      } else if (data.cashAccounting !== undefined) {
        let cloneInstallations = [...installations];
        cloneInstallations = cloneInstallations.map((o) => Object.assign({}, o));
        const findMatching = cloneInstallations.find((el) => el._id === id);
        if (findMatching) {
          findMatching.cashAccounting = data.cashAccounting;
        }
        dispatch(setCompanyInstallations(cloneInstallations));
      }
    },
    selected: () => {}
  }

  const handleSaveAccounting = () => {
    if (installations?.length > 0) {
      axios.post(process.env.REACT_APP_API_URL2 + "/accounting/update-cash-installation-accounting", {installations: installations, company: company.id}, {
        headers: { "Content-Type": "application/json" },
      }).then((res) => {
        if (res.data.status === "200") {
          toast.success("Η αποθήκευση έγινε με επιτυχία.");
          dispatch(setCompanyInstallations(res.data.data));
        } else {
          toast.error(res.data.message);
        }
      }).catch((err) => {
        toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        console.log(err);
      })
    }
  }

  const handleOnChange = (e, type = "default", name = "") => {
    let key = name;
    let value;
    if (type === "default") {
      key = e.target.name;
      value = e.target.value;
    }
    if (key === "capital") {
      let cloneStockHolders = recalculateStockHolders(companyDetails.stockholders, Number(value));
      dispatch(setCompanyDetails({...companyDetails, [key] : value, stockholders: cloneStockHolders}));
    } else {
      dispatch(setCompanyDetails({...companyDetails, [key] : value}));
    }
  }

  const handleSaveStockholders = () => {
    axios.post(`${process.env.REACT_APP_API_URL2}/company/updateCompanyStockholders`, {
      company: company.id,
      vatNumber: company.vatNumber,
      stockholders: companyDetails.stockholders,
      capital: companyDetails.capital,
      sharesOut: companyDetails.sharesOut
    }, {
      headers: { 'Content-Type': 'application/json' },
    }).then((res) => {
      if (res.data.status === "200") {
        toast.success("Η αποθήκευση έγινε επιτυχώς.");
        dispatch(fetchCompanyDetails(company.id));
      } else {
        toast.error(res.data.message);
      }
    }).catch((err) => {
      console.log(err);
      toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
    })
  }

  const newStockHolderInitialValue = {
    no: "",
    id: "",
    name: "",
    surname: "",
    vat: "",
    position: "",
    percentage: "0",
    stockValue: 0,
    dateFrom: "",
    dateTo: "",
    isActive: "true",
  }

  const stockholdersTableFunctions = {
    insert: (e, data) => {
      if (!isEmpty(data.value)) {
        let cloneStockHolders = [];
        if (companyDetails?.stockholders) cloneStockHolders = structuredClone(companyDetails.stockholders);

        const newRow = structuredClone(newStockHolderInitialValue);
        newRow["id"] = String(cloneStockHolders.length + 1);
        newRow["no"] = String(cloneStockHolders.length + 1);
        newRow[data.field] = data.value;
        cloneStockHolders.push(newRow);
        cloneStockHolders = recalculateStockHolders(cloneStockHolders, companyDetails.capital);
        dispatch(setCompanyDetails({...companyDetails, stockholders: cloneStockHolders}));
      }
    },
    update: (data, id, rowIndex) => {
      if (rowIndex >= 0) {
        if (data.percentage) {
          if (parseFloat(data.percentage) < 0 || parseFloat(data.percentage) > 100 || isNaN(data.percentage)) {
            dispatch(setCompanyDetails({...companyDetails}));
            toast.error("Stockholder percentage must be a number from 0 to 100");
            return;
          }
        }
        if (data.percentage && isEmpty(data.percentage)) data.percentage = "0";
        if (data.percentage && !isEmpty(data.percentage)) data.percentage = parseFloat(data.percentage).toFixed(2);

        let cloneStockHolders = structuredClone(companyDetails.stockholders);
        cloneStockHolders[rowIndex] = {...cloneStockHolders[rowIndex], ...data};
        cloneStockHolders = recalculateStockHolders(cloneStockHolders, companyDetails.capital);
        dispatch(setCompanyDetails({...companyDetails, stockholders: cloneStockHolders}));
      }
    },
    selected: () => {},
    delete: (id, rowIndex) => {
      if (companyDetails.stockholders[rowIndex]._id) {
        axios.get(`${process.env.REACT_APP_API_URL2}/accounting/stockholder-deletable`, {
          headers: { "Content-Type": "application/json" },
          params: {
            company: company.id,
            stockholderId: companyDetails.stockholders[rowIndex]._id,
          }
        }).then((res) => {
          if (res.data.status === "200") {
            let cloneStockHolders = structuredClone(companyDetails.stockholders);
            cloneStockHolders.splice(rowIndex, 1);
            cloneStockHolders = recalculateStockHolders(cloneStockHolders, companyDetails.capital);
            dispatch(setCompanyDetails({...companyDetails, stockholders: cloneStockHolders}));
          } else {
            toast.error("Ο μέτοχος δεν μπορεί να διαγραφεί διότι χρησιμοποιείται σε κάποια λογιστική εγγραφή.");
          }
        }).catch((err) => {
          console.log(err);
          toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        })
      } else { // For sure deletable, no _id, he is a new one that is not saved
        if (rowIndex >= 0) {
          let cloneStockHolders = structuredClone(companyDetails.stockholders);
          cloneStockHolders.splice(rowIndex, 1);
          cloneStockHolders = recalculateStockHolders(cloneStockHolders, companyDetails.capital);
          dispatch(setCompanyDetails({...companyDetails, stockholders: cloneStockHolders}));
        }
      }
    }
  }

  const handleOnChangeInstallation = (modalType, e, type, name) => {
    let cloneObj = modalType === "new" ? structuredClone(newInstallation) : structuredClone(editInstallation);

    if (type === "input") {
      cloneObj[name] = e.target.value;
    } else if (type === "dropdown") {
      if (name === "installationMasterId") {
        cloneObj["installationMasterId"] = e.value;
        cloneObj["type"] = e.label;
      } else if (name === "doy") {
        cloneObj["doy"] = e.label;
      } else {
        cloneObj[name] = e.value;
      }
    }

    if (modalType === "new") {
      setNewInstallation(cloneObj);
    } else {
      setEditInstallation(cloneObj);
    }
  }
  const handleAddNewInstallation = () => {
    setNewInstallationLoading(true);
    axios.post(process.env.REACT_APP_API_URL2 + "/company/add-installation-from-erp", newInstallation, {
      headers: { "Content-Type": "application/json" }
    }).then((res) => {
      setNewInstallationLoading(false);
      if (res.data.status === "200") {
        toast.success("Η εγκατάσταση προστέθηκε επιτυχώς.");
        dispatch(fetchCompanyInstallations(company.id));
        setNewInstallation(initNewInstallation);
        setShowNewInstallationModal(false);
      } else {
        toast.error(res.data.message);
      }
    }).catch((err) => {
      setNewInstallationLoading(false);
      console.log(err);
      toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
    })
  }
  const newInstallationInvalid = () => {
    return isEmpty(newInstallation.address) || isEmpty(newInstallation.city) || isEmpty(newInstallation.postalCode)
    || isEmpty(newInstallation.doy) || isEmpty(newInstallation.phone);
  }

  const handleEditInstallation = () => {
    setEditInstallationLoading(true);
    axios.post(process.env.REACT_APP_API_URL2 + "/company/edit-installation-from-erp", editInstallation, {
      headers: { "Content-Type": "application/json" }
    }).then((res) => {
      setEditInstallationLoading(false);
      if (res.data.status === "200") {
        toast.success("Η εγκατάσταση προστέθηκε επιτυχώς.");
        dispatch(fetchCompanyInstallations(company.id));
        setEditInstallation({});
        setShowEditInstallationModal(false);
      } else {
        toast.error(res.data.message);
      }
    }).catch((err) => {
      setEditInstallationLoading(false);
      console.log(err);
      toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
    })
  }

  const editInstallationInvalid = () => {
    return isEmpty(editInstallation.address) || isEmpty(editInstallation.city) || isEmpty(editInstallation.postalCode)
        || isEmpty(editInstallation.doy) || isEmpty(editInstallation.phone);
  }

  const handleOpenEditInstallationModal = (rowData) => {
    setShowEditInstallationModal(true);
    setEditInstallation({...rowData});
  }

  return (
    <React.Fragment>
      <Row>
        <Col sm="12">
            <p>{t('GeneralSettings.licensedTo')}</p>

            <div className="mb-3">
              <Input
                label={t('GeneralSettings.companyName.companyName')}
                name="name"
                value={companyData?.name}
                readonly
                placeholder={t('GeneralSettings.companyName.placeholder')}
                className="inline-label-field disabled"
                charLimit="30"
              />
            </div>

            <div className="mb-3">
              <Input
                label={t('GeneralSettings.companySmallName.companySmallName')}
                name="smallName"
                value={companyData?.smallName}
                readonly
                placeholder={t('GeneralSettings.companySmallName.placeholder')}
                className="inline-label-field disabled"
                charLimit="30"
              />
            </div>

            <div className="mb-3">
              <Input
                label={t('GeneralSettings.activity.activity')}
                name="activity"
                value={companyData?.activity}
                readonly
                placeholder={t('GeneralSettings.activity.placeholder')}
                className="inline-label-field disabled"
                charLimit="30"
              />
            </div>

            <div className="mb-3">
              <Input
                label={t('GeneralSettings.vatNumber.vatNumber')}
                name="vatNumber"
                value={companyData?.vatNumber}
                readonly
                placeholder={t('GeneralSettings.vatNumber.placeholder')}
                className="inline-label-field disabled"
                charLimit="30"
              />
            </div>

            <div className="mb-3">
              <Input
                label={t('GeneralSettings.headquartersAddress.headquartersAddress')}
                name="headquartersAddress"
                value={companyData?.headquartersAddress}
                readonly
                placeholder={t('GeneralSettings.headquartersAddress.required')}
                className="inline-label-field disabled"
                charLimit="30"
              />
            </div>

            <div className="mb-3">
              <Input
                label={t('GeneralSettings.city.city')}
                name="city"
                value={companyData?.city}
                readonly
                placeholder={t('GeneralSettings.city.required')}
                className="inline-label-field disabled"
                charLimit="30"
              />
            </div>

            <div className="mb-3">
              <Input
                label={t('GeneralSettings.country.country')}
                name="country"
                value={companyData?.country}
                readonly
                placeholder={t('GeneralSettings.country.placeholder')}
                className="inline-label-field disabled"
                charLimit="30"
              />
            </div>

            <div className="mb-3">
              <Input
                label={t('GeneralSettings.postalCode.postalCode')}
                name="postalCode"
                value={companyData?.postalCode}
                readonly
                placeholder={t('GeneralSettings.postalCode.placeholder')}
                className="inline-label-field disabled"
                charLimit="30"
              />
            </div>

            <div className="mb-3">
              <Input
                label={t('GeneralSettings.phoneNumber.phoneNumber')}
                name="phone"
                value={companyData?.phone}
                readonly
                placeholder={t('GeneralSettings.phoneNumber.placeholder')}
                className="inline-label-field disabled"
                charLimit="30"
              />
            </div>

            <div className="mb-3">
              <Input
                label={t('GeneralSettings.email.email')}
                name="email"
                value={companyData?.email}
                readonly
                placeholder={t('GeneralSettings.email.placeholder')}
                className="inline-label-field disabled"
                charLimit="30"
              />
            </div>

            <div className="mb-3">
              <Input
                label={t('GeneralSettings.gemh.gemh')}
                name="gemhNo"
                value={companyData?.gemhNo}
                readonly
                placeholder={t('GeneralSettings.gemh.placeholder')}
                className="inline-label-field disabled"
                charLimit="30"
              />
            </div>

            <div className="mb-3">
              <Input
                  label={t('GeneralSettings.representativeName.representativeName')}
                  name="representativeName"
                  value={companyData?.representativeName}
                  readonly
                  placeholder={t('GeneralSettings.representativeName.placeholder')}
                  className="inline-label-field disabled"
                  charLimit="30"
              />
            </div>
            <div className="mb-3">
              <Input
                  label={t('GeneralSettings.representativeSurname.representativeSurname')}
                  name="representativeSurname"
                  value={companyData?.representativeSurname}
                  readonly
                  placeholder={t('GeneralSettings.representativeSurname.placeholder')}
                  className="inline-label-field disabled"
                  charLimit="30"
              />
            </div>
            <div className="mb-3">
              <Input
                  label={t('GeneralSettings.representativeVatNumber.representativeVatNumber')}
                  name="representativeVatNumber"
                  value={companyData?.representativeVatNumber}
                  readonly
                  placeholder={t('GeneralSettings.representativeVatNumber.placeholder')}
                  className="inline-label-field disabled"
                  charLimit="30"
              />
            </div>
            <div className="mb-3">
              <Input
                  label={t('GeneralSettings.representativeVatNumber.representativeVatNumber')}
                  name="representativeVatNumber"
                  value={companyData?.representativeVatNumber}
                  readonly
                  placeholder={t('GeneralSettings.representativeVatNumber.placeholder')}
                  className="inline-label-field disabled"
                  charLimit="30"
              />
            </div>
            <div className="mb-3">
              <DateBox
                  classes="inline-label-field disabled"
                  dateFormat="dd-MM-yyyy HH:mm"
                  label={t('GeneralSettings.companyStartDate')}
                  name="date"
                  selected={new Date(companyData?.companyEstablishedDate ? companyData?.companyEstablishedDate : null)}
                  readOnly={true}
              />
            </div>
            <div className="mb-3">
              <DateBox
                  classes="inline-label-field disabled"
                  dateFormat="dd-MM-yyyy HH:mm"
                  label={t('GeneralSettings.companyExpireDate')}
                  name="date"
                  selected={new Date(companyData?.companyExpireDate ? companyData?.companyExpireDate : null)}
                  readOnly={true}
              />
            </div>
            <Row>
              <Col>
            <FormLabel className="mr-5" htmlFor="price"> {t('GeneralSettings.companyLogo')} </FormLabel>
                <Upload {...props}>
                  {checkPermission("settings-generalsettings/companysettings-write", permissionsData) &&
                      <Button id="upload-button">{t('GeneralSettings.uploadFile')}</Button>
                  }
                </Upload>
                <div className="text-muted mb-2">
                  <i>{t('GeneralSettings.uploadFileDesc')}</i>
                </div>
              </Col>
              <Col>
                <div className="mt-3">
                  <Checkbox
                      className="w-100 sendEmailOnSave"
                      text={t('GeneralSettings.useLogoInInvoices')}
                      name="useLogo"
                      key={Math.random()}
                      defaultValue={useLogo === "active"}
                      value={useLogo === "active" ? "inactive" : "active"}
                      onChange={(e) => handleUseLogo(e)}
                  />
                </div>
              </Col>
            {imgData && (
                <div className="mb-3 mt-3">
                  <img src={imgData} alt="uploaded" width="250" />
                </div>
            )}
            <div className="progress-container">
              <Line
                  percent={percentage}
                  strokeWidth={1}
                  trailWidth={1}
                  trailColor="#76aeb7"
                  strokeColor={isUploading ? "#76aeb7" : "#76aeb7"}
              />
              <div className="progress-text">
                {isUploading ? `Uploading ${percentage}% ` : t('GeneralSettings.finished')}
              </div>
            </div>
            </Row>


          <Row>
            <Col md={12}>
              <EditableTable
                  heading={t('GeneralSettings.installations')}
                  key={Math.random()}
                  tableName="Company Installations List"
                  columns={installationsColumns}
                  data={installations}
                  className="mb-2"
                  onUpdate={installationTableActions}
              />
            </Col>
          </Row>
          <Row className={"mb-2"}>
            <Col md={3}>
              <Button size={"sm"} onClick={() => setShowNewInstallationModal(true)}>Προσθήκη εγκατάστασης</Button>
            </Col>
            {checkPermission("accounting-settings/accounting-read", permissionsData) && (
                <Col md={9}>
                  <Button size={"sm"} style={{float: "right"}} onClick={() => handleSaveAccounting()}>Αποθήκευση λογιστικών προθεμάτων</Button>
                </Col>
            )}
          </Row>

          <EditableTable
            heading={t('GeneralSettings.companyUsers')}
            tableName="Compayn Users List"
            columns={companyUsersColumns}
            data={userData}
            className="mb-2"
          />
        </Col>
      </Row>
      <hr/>
      <p className="text-muted">
        <i>Stock holders:</i>
      </p>
      <Row className={"mb-2"}>
        <Col md={3}>
          <Input
              name={"capital"}
              type={"number"}
              label={"Capital"}
              value={companyDetails?.capital}
              autoComplete={"off"}
              placeholder={"Enter capital"}
              onChange={(e) => handleOnChange(e)}
          />
        </Col>
        <Col md={3}>
          <Input
              name={"sharesOut"}
              type={"number"}
              label={"Shares Out"}
              value={companyDetails?.sharesOut}
              autoComplete={"off"}
              placeholder={"Enter shares out"}
              onChange={(e) => handleOnChange(e)}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <EditableTable
              tableName="Company Stock Holders"
              actionButtons={{
                print: false,
                delete: true,
                export: false,
                edit: false,
              }}
              key={Math.random()}
              allowActions={true}
              columns={stockHoldersTableColumns}
              data={companyDetails?.stockholders ? companyDetails?.stockholders : []}
              allowInsertRow={true}
              onUpdate={stockholdersTableFunctions}
          />
        </Col>
      </Row>
      {stockHolderPercentageDiff(companyDetails?.stockholders) !== 0 && (
          <Row>
            <Col md={6}>
              <span style={{color: "red"}}>Active Stockholders must share 100% of the company capital. Total difference: {stockHolderPercentageDiff(companyDetails?.stockholders)} %</span>
            </Col>
          </Row>
      )}
      <Row className={"mb-2"}>
        <Col md={12}>
          <Button size={"sm"} style={{float: "right"}} onClick={() => handleSaveStockholders()}>Αποθήκευση αλλαγών μετόχων</Button>
        </Col>
      </Row>

      <Modal show={showNewInstallationModal} onHide={() => setShowNewInstallationModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Προσθήκη νέας εγκατάστασης</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Dropdown
              name={"installationMasterId"}
              label={"Τύπος εγκατάστασης"}
              options={installationOptions}
              defaultValue={installationOptions.find((el) => el.value === newInstallation.installationMasterId)}
              key={Math.random()}
              required={true}
              onChange={(e) => handleOnChangeInstallation("new", e, "dropdown", "installationMasterId")}
          />
          <Dropdown
              name={"isActive"}
              label={"Κατάσταση εγκατάστασης"}
              options={activityOptions}
              defaultValue={activityOptions.find((el) => el.value === newInstallation.isActive)}
              key={Math.random()}
              required={true}
              onChange={(e) => handleOnChangeInstallation("new", e, "dropdown", "isActive")}
          />
          <Input
              name={"city"}
              label={"Πόλη"}
              className={"mb-2"}
              autoComplete={"off"}
              value={newInstallation.city}
              required={true}
              onChange={(e) => handleOnChangeInstallation("new", e, "input", "city")}
          />
          <Input
              name={"address"}
              label={"Διεύθυνση"}
              className={"mb-2"}
              autoComplete={"off"}
              value={newInstallation.address}
              required={true}
              onChange={(e) => handleOnChangeInstallation("new", e, "input", "address")}
          />
          <Input
              className={"mb-2"}
              name={"postalCode"}
              label={"Τ.Κ."}
              autoComplete={"off"}
              value={newInstallation.postalCode}
              required={true}
              onChange={(e) => handleOnChangeInstallation("new", e, "input", "postalCode")}
          />
          <Dropdown
              name={"doy"}
              label={"Δ.Ο.Υ."}
              options={getDOYOptions()}
              defaultValue={getDOYOptions().find((el) => el.label === newInstallation.doy) ? getDOYOptions().find((el) => el.label === newInstallation.doy) : ""}
              key={Math.random()}
              required={true}
              onChange={(e) => handleOnChangeInstallation("new", e, "dropdown", "doy")}
          />
          <Input
              name={"phone"}
              label={"Τηλέφωνο"}
              className={"mb-2"}
              autoComplete={"off"}
              value={newInstallation.phone}
              required={true}
              onChange={(e) => handleOnChangeInstallation("new", e, "input", "phone")}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={() => setShowNewInstallationModal(false)}>
            Κλείσιμο
          </Button>
          <Button variant="primary" onClick={() => handleAddNewInstallation()} disabled={newInstallationInvalid() || newInstallationLoading}>
            Προσθήκη {newInstallationLoading && <Spinner className={"ml-2"} animation={"border"} variant={"dark"} />}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showEditInstallationModal} onHide={() => setShowEditInstallationModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Τροποποίηση εγκατάστασης</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Dropdown
              name={"installationMasterId"}
              label={"Τύπος εγκατάστασης"}
              options={installationOptions}
              defaultValue={installationOptions.find((el) => el.value === editInstallation.installationMasterId)}
              key={Math.random()}
              required={true}
              disabled={true}
              onChange={(e) => handleOnChangeInstallation("edit", e, "dropdown", "installationMasterId")}
          />
          <Dropdown
              name={"isActive"}
              label={"Κατάσταση εγκατάστασης"}
              options={activityOptions}
              defaultValue={activityOptions.find((el) => el.value === editInstallation.isActive)}
              key={Math.random()}
              required={true}
              onChange={(e) => handleOnChangeInstallation("edit", e, "dropdown", "isActive")}
          />
          <Input
              name={"city"}
              label={"Πόλη"}
              className={"mb-2"}
              autoComplete={"off"}
              value={editInstallation.city}
              required={true}
              onChange={(e) => handleOnChangeInstallation("edit", e, "input", "city")}
          />
          <Input
              name={"address"}
              label={"Διεύθυνση"}
              className={"mb-2"}
              autoComplete={"off"}
              value={editInstallation.address}
              required={true}
              onChange={(e) => handleOnChangeInstallation("edit", e, "input", "address")}
          />
          <Input
              name={"postalCode"}
              label={"Τ.Κ."}
              className={"mb-2"}
              autoComplete={"off"}
              value={editInstallation.postalCode}
              required={true}
              onChange={(e) => handleOnChangeInstallation("edit", e, "input", "postalCode")}
          />
          <Dropdown
              name={"doy"}
              label={"Δ.Ο.Υ."}
              options={getDOYOptions()}
              defaultValue={getDOYOptions().find((el) => el.label === editInstallation.doy) ? getDOYOptions().find((el) => el.label === editInstallation.doy) : ""}
              key={Math.random()}
              required={true}
              onChange={(e) => handleOnChangeInstallation("edit", e, "dropdown", "doy")}
          />
          <Input
              name={"phone"}
              label={"Τηλέφωνο"}
              className={"mb-2"}
              autoComplete={"off"}
              value={editInstallation.phone}
              required={true}
              onChange={(e) => handleOnChangeInstallation("edit", e, "input", "phone")}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={() => setShowEditInstallationModal(false)}>
            Κλείσιμο
          </Button>
          <Button variant="primary" onClick={() => handleEditInstallation()} disabled={editInstallationInvalid() || editInstallationLoading}>
            Τροποποίηση {editInstallationLoading && <Spinner className={"ml-2"} animation={"border"} variant={"dark"} />}
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  )
}

export const SystemTasks = () => {
  const [t] = useTranslation('common');
  const currentUser = getCurrentUser();
  const [showUpdateProductBalance, setShowUpdateProductBalance] = useState(false);
  const [showUpdateClientBalance, setShowUpdateClientBalance] = useState(false);
  const [showUpdateSupplierBalance, setShowUpdateSupplierBalance] = useState(false);
  const [showUpdateClientInfo, setShowUpdateClientInfo] = useState(false);
  const [showUpdateSupplierInfo, setShowUpdateSupplierInfo] = useState(false);
  const [clientLoader, setClientLoader] = useState(false);
  const [supplierLoader, setSupplierLoader] = useState(false);
  const [productLoader, setProductLoader] = useState(false);
  const [clientUpdateLoader, setClientUpdateLoader] = useState(false);
  const [clientUpdateModalBody, setClientUpdateModalBody] = useState("");
  const [supplierUpdateModalBody, setSupplierUpdateModalBody] = useState("");
  const [supplierUpdateLoader, setSupplierUpdateLoader] = useState(false);
  const [disableSyncCP, setDisableSyncCP] = useState(false);
  const [showSyncModal, setShowSyncModal] = useState(false);
  const [showCreateCensus, setShowCreateCensus] = useState(false);

  const [firstLoad, setFirstLoad] = useState(true);
  const [censusSend, setCensusSend] = useState({
    year: "0",
    doClients: false,
    doClientsDate: "",
    doSuppliers: false,
    doSuppliersDate: "",
    doProducts: false,
    doProductsDate: "",
  })
  const [censusLoad, setCensusLoad] = useState(false);
  const company = useSelector((state) => state.COMPANY_DATA.company);
  const permissionsData = JSON.parse(localStorage.getItem("permissions"));

  useEffect(() => {
    if(firstLoad) {
      setFirstLoad(false);
      const y = String(Number(company.year) + 1);
      setCensusSend({...censusSend, year: y, doClientsDate: `01/01/${y}`, doSuppliersDate: `01/01/${y}`, doProductsDate: `01/01/${y}`});
    }
  }, [firstLoad])

  useEffect(() => {
    if (showUpdateClientInfo) {
      setClientUpdateModalBody("");
    }
    if (showUpdateSupplierInfo) {
      setSupplierUpdateModalBody("");
    }
  }, [showUpdateClientInfo, showUpdateSupplierInfo])

  const handleConfirmUpdateProductBalance = async () => {
    setProductLoader(true);
    let requestData = {
      company: company.id,
      year: company.year,
      user: currentUser._id
    }
    await axios.post(apiURL + "/settings/re-update-product-balance", requestData, {headers: {"Content-Type": "application/json"}})
        .then((res) => {
          if (res.data.status === "200") {
            toast.success(res.data.message);
          } else {
            toast.error(res.data.message);
          }
          setProductLoader(false);
          setShowUpdateProductBalance(false);
        })
        .catch((error) => {
          console.log(error)
          toast.error(error);
          setProductLoader(false);
          setShowUpdateProductBalance(false);
        });
  };
  const handleConfirmUpdateClientBalance = async () => {
    setClientLoader(true);
    let requestData = {
      company: company.id,
      year: company.year,
      user: currentUser._id
    }
    await axios.post(apiURL + "/settings/re-update-client-balance", requestData, { headers: { "Content-Type": "application/json" }})
        .then((res) => {
          if (res.data.status === "200") {
            toast.success(res.data.message);
          } else {
            toast.error(res.data.message);
          }
          setClientLoader(false);
          setShowUpdateClientBalance(false);
        })
        .catch((error) => {
          console.log(error)
          toast.error(error);
          setClientLoader(false);
          setShowUpdateClientBalance(false);
        });
  }
  const handleConfirmUpdateSupplierBalance = async () => {
    setSupplierLoader(true);
    let requestData = {
      company: company.id,
      year: company.year,
      user: currentUser._id
    }
    await axios.post(apiURL + "/settings/re-update-supplier-balance", requestData, { headers: { "Content-Type": "application/json" }})
        .then((res) => {
          if (res.data.status === "200") {
            toast.success(res.data.message);
          } else {
            toast.error(res.data.message);
          }
          setSupplierLoader(false);
          setShowUpdateSupplierBalance(false);
        })
        .catch((error) => {
          console.log(error)
          toast.error(error);
          setClientLoader(false);
          setShowUpdateSupplierBalance(false);
        });
  }
  const handleConfirmUpdateClientInfo = async () => {
    setClientUpdateLoader(true);
    let requestData = {
      company: company.id,
      year: company.year,
      user: currentUser._id
    }
    await axios.post(apiURL + "/settings/re-update-client-info", requestData, { headers: { "Content-Type": "application/json" }})
        .then((res) => {
          if (res.data.status === "200") {
            setClientUpdateModalBody(
                <React.Fragment>
                  {t('SettingsSystemTasks.clientUpdateCompletedMessage1')}<br/>{t('SettingsSystemTasks.clientUpdateCompletedMessage2')+' '+res.data.totalCount}<br/>{t('SettingsSystemTasks.clientUpdateCompletedMessage3')+' '+res.data.modifiedCount}
                </React.Fragment>
            );
            toast.success(res.data.message);
          } else {
            toast.error(res.data.message);
          }
          setClientUpdateLoader(false);
        })
        .catch((error) => {
          console.log(error)
          toast.error(error);
          setClientUpdateLoader(false);
          setShowUpdateClientInfo(false);
        });
  }
  const handleConfirmUpdateSupplierInfo = async () => {
    setSupplierUpdateLoader(true);
    let requestData = {
      company: company.id,
      year: company.year,
      user: currentUser._id
    }
    await axios.post(apiURL + "/settings/re-update-supplier-info", requestData, { headers: { "Content-Type": "application/json" }})
        .then((res) => {
          if (res.data.status === "200") {
            setSupplierUpdateModalBody(
                <React.Fragment>
                  {t('SettingsSystemTasks.supplierUpdateCompletedMessage1')}<br/>{t('SettingsSystemTasks.supplierUpdateCompletedMessage2')+' '+res.data.totalCount}<br/>{t('SettingsSystemTasks.supplierUpdateCompletedMessage3')+' '+res.data.modifiedCount}
                </React.Fragment>
            );
            toast.success(res.data.message);
          } else {
            toast.error(res.data.message);
          }
          setSupplierUpdateLoader(false);
        })
        .catch((error) => {
          console.log(error)
          toast.error(error);
          setSupplierUpdateLoader(false);
          setShowUpdateSupplierInfo(false);
        });
  }
  const syncClientPaymentsWithSales = () => {
      setShowSyncModal(false);
      setDisableSyncCP(true);
      const requestData = {
          company: company.id,
          year: company.year,
          user: currentUser._id
      }
      axios.post(apiURL + "/settings/sync-client-payments-with-sales", requestData, { headers: { "Content-Type": "application/json" }})
          .then((res) => {
              if(res.data.status === "200") {
                  toast.success("Ο συγχρονισμός έγινε με επιτυχία.");
                  setDisableSyncCP(false);
              } else {
                  toast.error("Κάτι πήγε στραβά");
                  setDisableSyncCP(false);
              }
          }).catch((err) => {
          console.log(err);
          setDisableSyncCP(false);
      })
  }
  const createCensusClicked = () => {
    const reqData = {...censusSend, company: company.id};
    if(censusSend.year === "0") {
      toast.error("Something went wrong with current year.");
    } else {
      setCensusLoad(true);
      axios.post(apiURL + "/settings/create-new-year-census", reqData, { headers: { "Content-Type": "application/json" }})
        .then((res) => {
          if(res.data.status === "200") {
            toast.success(res.data.message);
          } else {
            toast.error(res.data.message);
          }
          setCensusLoad(false);
        })
        .catch((err) => {
          setCensusLoad(false);
          console.log(err);
        })
    }
  }
  const createCensusInvalid = () => {
    return censusSend.year === "0"
        || (censusSend.doClients === false && censusSend.doProducts === false && censusSend.doSuppliers === false)
        || isEmpty(censusSend.doClientsDate) || isEmpty(censusSend.doSuppliersDate) || isEmpty(censusSend.doProductsDate)
        || (!moment(censusSend.doClientsDate, "DD/MM/YYYY", true).isValid())
        || (!moment(censusSend.doSuppliersDate, "DD/MM/YYYY", true).isValid())
        || (!moment(censusSend.doProductsDate, "DD/MM/YYYY", true).isValid())
        || (moment(censusSend.doClientsDate, "DD/MM/YYYY", true).isValid() && String(moment(censusSend.doClientsDate, "DD/MM/YYYY").year()) !== censusSend.year)
        || (moment(censusSend.doSuppliersDate, "DD/MM/YYYY", true).isValid() && String(moment(censusSend.doSuppliersDate, "DD/MM/YYYY").year()) !== censusSend.year)
        || (moment(censusSend.doProductsDate, "DD/MM/YYYY", true).isValid() && String(moment(censusSend.doProductsDate, "DD/MM/YYYY").year()) !== censusSend.year);
  }
  return (
    <React.Fragment>
      <Row>
        <Col sm="12" className="d-flex align-items-center justify-content-start">
          <span className="mr-3 mb-3">{t('SettingsSystemTasks.reUpdateProductsInventoryBalance')}</span>
          {checkPermission("settings-generalsettings/systemtasks-write", permissionsData) &&
              <Button
                onClick={() => setShowUpdateProductBalance(true)} className="mb-3" type="button">
                {t('SettingsSystemTasks.reupdate')}
              </Button>
          }
        </Col>
      </Row>
      <Row>
        <Col sm="12" className="d-flex align-items-center justify-content-start">
          <span className="mr-3 mb-3">{t('SettingsSystemTasks.reUpdateClientBalance')}</span>{" "}
          {checkPermission("settings-generalsettings/systemtasks-write", permissionsData) &&
              <Button onClick={() => setShowUpdateClientBalance(true)} className="mb-3" type="button">{t('SettingsSystemTasks.reupdate')}</Button>
          }
        </Col>
      </Row>
      <Row>
        <Col sm="12" className="d-flex align-items-center justify-content-start">
          <span className="mr-3 mb-3">{t('SettingsSystemTasks.reUpdateSupplierBalance')}</span>{" "}
          {checkPermission("settings-generalsettings/systemtasks-write", permissionsData) &&
              <Button onClick={() => setShowUpdateSupplierBalance(true)} className="mb-3" type="button">{t('SettingsSystemTasks.reupdate')}</Button>
          }
        </Col>
      </Row>
      <Row>
        <Col sm="12" className="d-flex align-items-center justify-content-start">
          <span className="mr-3 mb-3">{t('SettingsSystemTasks.reUpdateClientInfo')}</span>{" "}
          {checkPermission("settings-generalsettings/systemtasks-write", permissionsData) &&
              <Button onClick={() => setShowUpdateClientInfo(true)} className="mb-3" type="button">{t('SettingsSystemTasks.reupdate')}</Button>
          }
        </Col>
      </Row>
      <Row>
        <Col sm="12" className="d-flex align-items-center justify-content-start">
          <span className="mr-3 mb-3">{t('SettingsSystemTasks.reUpdateSupplierInfo')}</span>{" "}
          {checkPermission("settings-generalsettings/systemtasks-write", permissionsData) &&
              <Button onClick={() => setShowUpdateSupplierInfo(true)} className="mb-3" type="button">{t('SettingsSystemTasks.reupdate')}</Button>
          }
        </Col>
      </Row>
      <Row className="mb-3">
          <Col sm="12">
              <span className="mr-3 mb-3">{t('SettingsSystemTasks.syncClientPaymentsWithSales')}</span>
              <Button onClick={() => setShowSyncModal(true)} disabled={disableSyncCP}>{t('SettingsSystemTasks.sync')}</Button>
          </Col>
      </Row>
      <Row className="mb-3">
        <Col sm="12">
          <span className={"mr-3 mb-3"}>Μεταφορά υπολοίπων έτους</span>
          <Button onClick={() => setShowCreateCensus(true)}>Μεταφορά</Button>
        </Col>
      </Row>
      {/* Confirm Category Change */}
      <Modal className="themeModal" show={showUpdateProductBalance} onHide={() => setShowUpdateProductBalance(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t('SettingsSystemTasks.warning')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t('SettingsSystemTasks.message')}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={() => setShowUpdateProductBalance(false)}>
            {t('SettingsSystemTasks.no')}
          </Button>
          <Button variant="primary" onClick={() => handleConfirmUpdateProductBalance()}>
            {t('SettingsSystemTasks.yes')}
            {productLoader && (<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>)}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal className="themeModal" show={showUpdateClientBalance} onHide={() => setShowUpdateClientBalance(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t('SettingsSystemTasks.warning')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t('SettingsSystemTasks.clientMessage')}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={() => setShowUpdateClientBalance(false)}>
            {t('SettingsSystemTasks.no')}
          </Button>
          <Button variant="primary" onClick={() => handleConfirmUpdateClientBalance()}>
            {t('SettingsSystemTasks.yes')}
            {clientLoader && (
                <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                ></span>
            )}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal className="themeModal" show={showUpdateSupplierBalance} onHide={() => setShowUpdateSupplierBalance(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t('SettingsSystemTasks.warning')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t('SettingsSystemTasks.supplierMessage')}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={() => setShowUpdateSupplierBalance(false)}>
            {t('SettingsSystemTasks.no')}
          </Button>
          <Button variant="primary" onClick={() => handleConfirmUpdateSupplierBalance()}>
            {t('SettingsSystemTasks.yes')}
            {supplierLoader && (
                <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                ></span>
            )}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal className="themeModal" show={showUpdateClientInfo} onHide={() => !clientUpdateLoader && setShowUpdateClientInfo(false)}>
        <Modal.Header closeButton disabled={clientUpdateLoader}>
          <Modal.Title>{t('SettingsSystemTasks.warning')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t('SettingsSystemTasks.clientUpdateMessage')}
          <br/><br/>
          {clientUpdateModalBody}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" disabled={clientUpdateLoader} onClick={() => setShowUpdateClientInfo(false)}>
            {t('SettingsSystemTasks.no')}
          </Button>
          <Button variant="primary" disabled={clientUpdateLoader} onClick={() => handleConfirmUpdateClientInfo()}>
            {t('SettingsSystemTasks.yes')}
            {clientUpdateLoader && (
                <span
                    className="spinner-border spinner-border-sm ml-1"
                    role="status"
                    aria-hidden="true"
                ></span>
            )}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal className="themeModal" show={showUpdateSupplierInfo} onHide={() => !supplierUpdateLoader && setShowUpdateSupplierInfo(false)}>
        <Modal.Header closeButton disabled={supplierUpdateLoader}>
          <Modal.Title>{t('SettingsSystemTasks.warning')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t('SettingsSystemTasks.supplierUpdateMessage')}
          <br/><br/>
          {supplierUpdateModalBody}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" disabled={supplierUpdateLoader} onClick={() => setShowUpdateSupplierInfo(false)}>
            {t('SettingsSystemTasks.no')}
          </Button>
          <Button variant="primary" disabled={supplierUpdateLoader} onClick={() => handleConfirmUpdateSupplierInfo()}>
            {t('SettingsSystemTasks.yes')}
            {supplierUpdateLoader && (
                <span
                    className="spinner-border spinner-border-sm ml-1"
                    role="status"
                    aria-hidden="true"
                ></span>
            )}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showSyncModal} onHide={() => setShowSyncModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t('SettingsSystemTasks.warning')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t('SettingsSystemTasks.syncMessage')}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={() => setShowSyncModal(false)}>
            {t('SettingsSystemTasks.no')}
          </Button>
          <Button variant="primary" onClick={() => syncClientPaymentsWithSales()}>
            {t('SettingsSystemTasks.yes')}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showCreateCensus} onHide={() => setShowCreateCensus(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Μεταφορά υπολοίπων μέσω απογραφής</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant={"secondary"}>Πραγματοποιείται η μεταφορά υπολοίπων της τωρινής χρονιάς, στην επόμενη χρονιά, μέσω της δημιουργίας απογραφής.</Alert>
          <Input
              name={"censusYear"}
              label={"Χρονιά"}
              className={"mb-2"}
              disabled={true}
              value={censusSend.year}
          />
          <Row>
            <Col md={6}>
              <BetterCheckBox
                  checked={censusSend.doClients}
                  className={"mt-1"}
                  text={"Απογραφή πελατών"}
                  onChange={(e) => {setCensusSend({...censusSend, doClients: e.target.checked})}}
              />
            </Col>
            {censusSend.doClients === true && (
                <Col md={6}>
                  <Input
                      name={"doClientsDate"}
                      unlabbled={true}
                      value={censusSend.doClientsDate}
                      placeholder={"Ημερομηνία απογραφής"}
                      onChange={(e) => {setCensusSend({...censusSend, doClientsDate: e.target.value})}}
                  />
                </Col>
            )}
          </Row>
          <Row>
            <Col md={6}>
              <BetterCheckBox
                  checked={censusSend.doSuppliers}
                  className={"mt-1"}
                  text={"Απογραφή προμηθευτών"}
                  onChange={(e) => {setCensusSend({...censusSend, doSuppliers: e.target.checked})}}
              />
            </Col>
            {censusSend.doSuppliers === true && (
                <Col md={6}>
                  <Input
                      name={"doSuppliersDate"}
                      unlabbled={true}
                      value={censusSend.doSuppliersDate}
                      placeholder={"Ημερομηνία απογραφής"}
                      onChange={(e) => {setCensusSend({...censusSend, doSuppliersDate: e.target.value})}}
                  />
                </Col>
            )}
          </Row>
          <Row>
            <Col md={6}>
              <BetterCheckBox
                  checked={censusSend.doProducts}
                  className={"mt-1"}
                  text={"Απογραφή ειδών"}
                  onChange={(e) => {setCensusSend({...censusSend, doProducts: e.target.checked})}}
              />
            </Col>
            {censusSend.doProducts === true && (
                <Col md={6}>
                  <Input
                      name={"doProductsDate"}
                      unlabbled={true}
                      value={censusSend.doProductsDate}
                      placeholder={"Ημερομηνία απογραφής"}
                      onChange={(e) => {setCensusSend({...censusSend, doProductsDate: e.target.value})}}
                  />
                </Col>
            )}
          </Row>
          {(censusSend.doClients === true || censusSend.doSuppliers === true || censusSend.doProducts === true) && (
              <Alert className={"mt-2"} variant={"danger"}>Η ημερομηνία πρέπει αυστηρά να έχει την μορφή ΗΗ/ΜΜ/ΧΧΧΧ, π.χ. 06/01/2024</Alert>
          )}
          {createCensusInvalid() && (
              <div style={{color: "red"}}>(*) Επιλέξτε τουλάχιστον ένα πεδίο απογραφής και εισάγετε σωστά την ημερομηνία. Η χρονιά της ημερομηνίας πρέπει να είναι ίδια με της απογραφής.</div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={() => setShowCreateCensus(false)}>Ακύρωση</Button>
          <Button variant="primary" onClick={() => createCensusClicked()} disabled={censusLoad || createCensusInvalid()}>Μεταφορά {censusLoad && <Spinner animation="border" variant="dark" />}</Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export const EmailSmtp = () => {
  const [t] = useTranslation('common');
  const dispatch = useDispatch();
  const SETTINGS = useSelector( (state) => state.SETTINGS);
  const smtpSettings = useSelector( (state) => state.SETTINGS.emailSmtp);
  const ACTIVITY = useSelector( (state) => state.ACTIVITY);
  const [showPassword, setShowPassword] = useState(false);
  const [showWildcardsList, setShowWildcardsList] = useState(false);
  const [showWildcardsListEditor, setShowWildcardsListEditor] = useState(false);
  let wildcardsList = emailSettingsWildcardsList();
  let wildcardsListEditor = emailSettingsWildcardsList();
  const [editorValue, setEditorValue] = useState(RichTextEditor.createValueFromString(smtpSettings.mailBody, 'html'));
  const handleOnChange = (e, type="default", ddname = '') => {
    // Set if editing the form
    if (!ACTIVITY.saveEmailSMtp) {
      dispatch(setSaveEmailSmtp(true));
    }
    let name;
    let value;
      if (type === 'dd') {
        name = ddname;
        value = e.value;
      } else if(type === 're') {
        name = ddname;
        value = e;
      }else {
        name = e.target.name;
        value = e.target.value;
      }
    // Set settings data
    let emailSmtpSettings = Object.assign({}, SETTINGS.emailSmtp);
    let setEmailSmtpSettings = {};
    if(name === "smtpOptionPrimer" && value === "active"){
      setEmailSmtpSettings["smtpOption"] = "primer";
    } else if(name === "smtpOptionCustom" && value === "active"){
      setEmailSmtpSettings["smtpOption"] = "custom";
    } else if(name === "encryptionNone" && value === "on"){
      setEmailSmtpSettings["encryptionType"] = "none";
    } else if(name === "encryptionSSL" && value === "on"){
      setEmailSmtpSettings["encryptionType"] = "SSL/TLS";
    } else if(name === "encryptionSTART" && value === "on"){
      setEmailSmtpSettings["encryptionType"] = "Start TLS";
    } else if(name === "authenticationTrue" && value === "on"){
      setEmailSmtpSettings["smtpAuthentication"] = "true";
    } else if(name === "authenticationFalse" && value === "on"){
      setEmailSmtpSettings["smtpAuthentication"] = "false";
    } else if(name === "mailBody"){
      setEmailSmtpSettings["mailBody"] = value.toString('html');
      setEditorValue(value);
    } else {
      setEmailSmtpSettings[name] = value;
    }
    setEmailSmtpSettings = {...emailSmtpSettings, ...setEmailSmtpSettings}
    if (setEmailSmtpSettings.password !== '' && setEmailSmtpSettings.username !== '') {
     // setEnableActive(false);
    }
    dispatch(setEmailSmtp(setEmailSmtpSettings));
  }
  const handleAddWildcard = (e, item, object) => {
    if (!ACTIVITY.saveEmailSMtp) {
      dispatch(setSaveEmailSmtp(true));
    }
    let emailSmtpSettings = Object.assign({}, SETTINGS.emailSmtp);
    let setEmailSmtpSettings = {};
    if(object === "subject"){
      setEmailSmtpSettings["subject"] = `${emailSmtpSettings?.subject} ${item}`;
    } else if(object === "mailBody"){
      let itemEditor = `<p>${item}</p>`;
      setEmailSmtpSettings["mailBody"] = `${emailSmtpSettings?.mailBody}${itemEditor}`;
      console.log(RichTextEditor.createValueFromString(`${emailSmtpSettings?.mailBody}${itemEditor}`, 'html').toString('html'))
      setEditorValue(RichTextEditor.createValueFromString(`${emailSmtpSettings?.mailBody}${itemEditor}`, 'html'));
    }
    setEmailSmtpSettings = {...emailSmtpSettings, ...setEmailSmtpSettings}
    dispatch(setEmailSmtp(setEmailSmtpSettings));
  };

  const testSmtpConnectionButton = () => {
    dispatch(testSmtpConnection(smtpSettings))
  }
  return (
    <>
      <Row>
        <Col sm="12" className="mb-3 d-flex align-items-center justify-content-start">
          <div className="mb-3 d-flex align-items-center justify-content-start">
            {t('SettingsEmailSMTP.usePrimerSmtp')}
            <Checkbox
                className="ml-2 mb-2"
                name="smtpOptionPrimer"
                key = {Math.random()}
                defaultValue={smtpSettings.smtpOption === "primer" ? "active" : "inactive"}
                value={ smtpSettings.smtpOption === 'primer' ? "inactive" : "active" }
                onChange = {(e) => handleOnChange(e) }
            />
          </div>
        </Col>
        <Col sm="12" className="mb-3 d-flex align-items-center justify-content-start">
          <div className="mb-3 d-flex align-items-center justify-content-start">
            {t('SettingsEmailSMTP.useCustomSmtp')}
            <Checkbox
                className="ml-2 mb-2"
                name="smtpOptionCustom"
                key = {Math.random()}
                defaultValue={smtpSettings.smtpOption === "custom" ? "active" : "inactive"}
                value={ smtpSettings.smtpOption === 'custom' ? "inactive" : "active" }
                onChange = {(e) => handleOnChange(e) }
            />
          </div>
        </Col>
        <Row></Row>
        {smtpSettings.smtpOption === "custom" &&
        <>
          <Row>
        <Col sm="12" md="6">
          <label className="w-100 text-muted">
            <i>{t('SettingsEmailSMTP.smtpSettings')}</i>
          </label>
        </Col>
          </Row>
          <Row>
          <div className="mb-3 w-50">
            <Input
                label={t('SettingsEmailSMTP.fromEmail.fromEmail')}
                name = "fromEmail"
                value = {smtpSettings.fromEmail}
                placeholder={t('SettingsEmailSMTP.fromEmail.placeholder')}
                required={true}
                charLimit="30"
                onChange = {(e) => handleOnChange(e, 'default', 'fromEmail') }
            />
          </div>
          </Row>
          <div className="mb-3 w-25">
          <Input
              label={t('SettingsEmailSMTP.username.username')}
              name = "username"
              value = {smtpSettings.username}
              placeholder={t('SettingsEmailSMTP.username.placeholder')}
              required={true}
              charLimit="30"
              onChange = {(e) => handleOnChange(e, 'default', 'username') }
          />
        </div>
          <div className="mb-3 w-25 password-field">
            <Input
                label={t('SettingsEmailSMTP.password.password')}
                name = "password"
                type= {showPassword ? 'text' : 'password'}
                value = {smtpSettings.password}
                placeholder={t('SettingsEmailSMTP.password.placeholder')}
                required={true}
                charLimit="30"
                onChange = {(e) => handleOnChange(e, 'default', 'password') }
            />
            { !showPassword && <i onClick={() => setShowPassword(true)} className="fas fa-eye"></i> }
            { showPassword && <i onClick={() => setShowPassword(false)} className="fas fa-eye-slash"></i> }
          </div>
        <Col sm="12" className="d-flex align-items-center mb-3">
          <span className="mr-3">{t('SettingsEmailSMTP.encryptionType')}</span>
          {t('SettingsEmailSMTP.none')} <input className="ml-1 mr-1" type="radio" checked={smtpSettings.encryptionType === "none"} name="encryptionNone" onChange = {(e) => handleOnChange(e, 'default', 'encryptionNone') } />
          SSL/TLS <input className="ml-1 mr-1" type="radio" name="encryptionSSL" checked={smtpSettings.encryptionType === "SSL/TLS"} onChange = {(e) => handleOnChange(e, 'default', 'encryptionSSL') } />
          {t('SettingsEmailSMTP.start')} TLS <input className="ml-1 mr-1" type="radio" name="encryptionSTART" checked={smtpSettings.encryptionType === "Start TLS"} onChange = {(e) => handleOnChange(e, 'default', 'encryptionSTART') } />
        </Col>
        <Row></Row>
        <Col sm="12">
          <label className="w-100 text-muted">
            <i>{t('SettingsEmailSMTP.message')}</i>
          </label>
        </Col>
        <Col sm="12" className="d-flex align-items-center">
          <span className="mr-3">{t('SettingsEmailSMTP.smtpAuthentication')}</span>
          {t('SettingsEmailSMTP.yes')} <input className="ms-2 mr-2" type="radio" checked={smtpSettings.smtpAuthentication === "true"} name="authenticationTrue" onChange = {(e) => handleOnChange(e, 'default', 'authenticationTrue') } />
          {t('SettingsEmailSMTP.no')} <input className="ms-2 mr-2" type="radio" checked={smtpSettings.smtpAuthentication !== "true"} name="authenticationFalse" onChange = {(e) => handleOnChange(e, 'default', 'authenticationFalse') } />
        </Col>
          <div className="mb-3 mt-3 w-25">
            <Input
                label="SMTP Server"
                name = "smtpServer"
                value = {smtpSettings.smtpServer}
                placeholder= {t('SettingsEmailSMTP.smtpServerPlaceholder')}
                required={true}
                charLimit="30"
                onChange = {(e) => handleOnChange(e, 'default', 'smtpServer') }
            />
          </div>
          <div className="mb-3 mt-3 w-25">
            <Input
                label={t('SettingsEmailSMTP.smtpPort')}
                name = "smtpPort"
                typ="number"
                value = {smtpSettings.smtpPort}
                placeholder={t('SettingsEmailSMTP.smtpPortPlaceholder')}
                required={true}
                charLimit="30"
                onChange = {(e) => handleOnChange(e, 'default', 'smtpPort') }
            />
          </div>
        </>
        }
        <Row className="mt-3"></Row>
        <Col sm="12">
          <label className="w-100 text-muted">
            <i>{t('SettingsEmailSMTP.emailSettings')}</i>
          </label>
        </Col>
        <div className="mb-3 w-25">
          <Input
              label={t('SettingsEmailSMTP.correspondentName.correspondentName')}
              name = "correspondentName"
              value = {smtpSettings.correspondentName}
              placeholder={t('SettingsEmailSMTP.correspondentName.required')}
              required={true}
              charLimit="30"
              onChange = {(e) => handleOnChange(e, 'default', 'correspondentName') }
          />
        </div>
        <Row>
          <div className="mb-3 mt-3 w-50">
            <Input
                label={t('SettingsEmailSMTP.mailSubject.mailSubject')}
                name = "subject"
                value = {smtpSettings.subject}
                placeholder={t('SettingsEmailSMTP.mailSubject.placeholder')}
                required={true}
                charLimit="30"
                onChange = {(e) => handleOnChange(e, 'default', 'subject') }
            />
            <div className="col-12 col-md-4 d-flex align-items-end">
              <button
                  type="button"
                  className="btn btn-primary btn-sm mb-1 mt-2"
                  onClick={() => setShowWildcardsList(!showWildcardsList)}
              >
                {t('SettingsEmailSMTP.addWildcard')}
              </button>
            </div>
          </div>
          {showWildcardsList && (
              <Card className="wildcards-list">
                <Card.Title>
                  {t('SettingsEmailSMTP.wildcards')}{" "}
                  <small>{t('SettingsEmailSMTP.message2')}</small>
                </Card.Title>
                <ListGroup>
                  {wildcardsList &&
                      wildcardsList.length > 0 &&
                      wildcardsList.map((item) => {
                        return (
                            <ListGroup.Item
                                title="Click to Add"
                                onClick={(e) =>
                                    handleAddWildcard(e, item, "subject")
                                }
                                key={Math.random()}
                            >
                              {item}
                            </ListGroup.Item>
                        );
                      })}
                </ListGroup>
              </Card>
          )}
        </Row>
        <Row>

          <RichTextEditor
              value={editorValue}
              name="mailBody"
              onChange={(e) => handleOnChange(e, 're', "mailBody")}
          />
          <div className="col-12 col-md-4 d-flex align-items-end">
            <button
                type="button"
                className="btn btn-primary btn-sm mb-1 mt-2"
                onClick={() => setShowWildcardsListEditor(!showWildcardsListEditor)}
            >
              {t('SettingsEmailSMTP.addWildcard')}
            </button>
          </div>
        </Row>
        {showWildcardsListEditor && (
            <Card className="wildcards-list">
              <Card.Title>
                {t('SettingsEmailSMTP.wildcards')}{" "}
                <small>{t('SettingsEmailSMTP.message2')}</small>
              </Card.Title>
              <ListGroup>
                {wildcardsListEditor &&
                    wildcardsListEditor.length > 0 &&
                    wildcardsListEditor.map((item) => {
                      return (
                          <ListGroup.Item
                              title="Click to Add"
                              onClick={(e) =>
                                  handleAddWildcard(e, item, "mailBody")
                              }
                              key={Math.random()}
                          >
                            {item}
                          </ListGroup.Item>
                      );
                    })}
              </ListGroup>
            </Card>
        )}
        <Col sm="12">
          <Button type="button" onClick={ () => testSmtpConnectionButton() }>{t('SettingsEmailSMTP.testSMTPConnection')}</Button>
        </Col>
      </Row>
    </>
  );
};

export const PrintTemplatesNew = () => {
  return (
    <>
      <p>There is no information for this page in wireframe.</p>
    </>
  );
};

export const PrintTemplatesSearch = () => {
  return (
    <>
      <p>There is no information for this page in wireframe.</p>
    </>
  );
};

export const MoveDataNew = () => {
  return (
    <>
      <p>There is no information for this page in wireframe.</p>
    </>
  );
};

export const MoveDataSearch = () => {
  return (
    <>
      <p>There is no information for this page in wireframe.</p>
    </>
  );
};
