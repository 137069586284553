import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

const initialState = {
    // CDR Viewer
    cdrViewerRequestData: {
        date_from: moment().startOf("day").format("DD/MM/YYYY"),
        date_to: moment().endOf("day").format("DD/MM/YYYY"),
        needsFetch: false,
        page: 1,
        srcdst: "",
        callType: [],
        did: [],
        queue: [],
        client: "",
        firstLoad: true,
        debugMode: false,
    },
    cdrViewerResponseData: {
        data: [],
        devices: [],
        dids: [],
        queues: [],
        ivrs: [],
        lastUpdate: "",
        totalPages: 0,
        stats: {
            totalInboundCalls: 0,
            totalOutboundCalls: 0,
            meanDidWaitingTime: {}, // Per DID
            maxSimultaneousCalls: {}, // Per DID
            maxSimultaneousCallsTime: "",
            noAnswer: 0,
            noAnswerArr: [], // Will contain objects with keys (src, dst, date, clientName, clientVAT, queueHistory)
        }
    }
}

export const CRMSlice = createSlice({
    name: "crm-slice",
    initialState,
    reducers: {
        setCDRViewerRequestData: (state, {payload}) => {
            state.cdrViewerRequestData = payload;
        },
        setCDRViewerResponseData: (state, {payload}) => {
            state.cdrViewerResponseData = payload;
        },
    },
});

export const {
    setCDRViewerRequestData,
    setCDRViewerResponseData
} = CRMSlice.actions;
