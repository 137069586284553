import React, {useEffect, useState} from "react";
import {Button, Col, Modal, Row} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import moment from "moment";
import {
    checkPermission,
    getCurrentUser,
    getGreekCurrencyFormat
} from "../../../_helpers/commonFunctions";
import {
    fetchCompanyDetails,
    fetchDashboardData,
    fetchSyncInvoicesProviderLog, triggerCleanFailedSyncs,
    triggerSyncProviderInvoices
} from "../../../_apis/api";
import {Box, useTheme} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import {toast} from "react-toastify";
import {classicStyleBelowNavbar} from "./Statics";
import {OverviewChart2} from "../../../dashboardComponents/OverviewChart2";
import {SmallClientChart} from "../../../dashboardComponents/SmallClientChart";

export const Start = () => {
    const theme = useTheme();
    const apiURL2 = process.env.REACT_APP_API_URL2;
    const permissionsData = JSON.parse(localStorage.getItem("permissions"));
    const currentUser = getCurrentUser();
    const SALES_SETTINGS = useSelector((state) => state.SALES_SETTINGS);
    const [articles, setArticles] = useState([]);
    const [updateLoader, setUpdateLoader] = useState(false);
    const [showLogModal, setShowLogModal] = useState(false);
    const [displayedLogs, setDisplayedLogs] = useState([]);
    const [syncLoader, setSyncLoader] = useState(false);
    const currentDate = new Date();
    const currentHour = currentDate.getHours();
    const currentMinute = currentDate.getMinutes();
    const startTime = 4; // 04:00
    const endTime = 5; // 05:00
    const dashboardData = useSelector(state => state.COMPANY_DATA.dashboardData);
    const company = useSelector(state => state.COMPANY_DATA.company);
    const dispatch = useDispatch();
    const companyDetails = useSelector(state => state.COMPANY_DATA.companyDetails);
    const [showBankModal, setShowBankModal] = useState(false);

    const [firstLoad, setFirstLoad] = useState(true);

    useEffect(() => {
        if(firstLoad) {
            setFirstLoad(false);
            if(Object.keys(dashboardData).length === 0 && checkPermission("custom-dashboarddata", permissionsData) === true) {
                dispatch(fetchDashboardData(company.id, company.year));
            }
        }
    }, [firstLoad])

    /*
    const history = useHistory();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const bankCode = searchParams.get('code');

    useEffect( () => {
        if(companyDetails?._id && !bankCode && checkPermission("custom-donotshowpersistentmodals", permissionsData) && (companyDetails?.enablePeiraeusTransactions && companyDetails.enablePeiraeusTransactions === "active")){
            if((!companyDetails?.bankToken || (moment(currentDate).date() === 1 && (!companyDetails?.peiraeusBankSyncDate || moment(companyDetails.peiraeusBankSyncDate).date() !== 1))) && companyDetails?.hasPeiraeusBanksToShowModal === "true") {
                //setShowBankModal(true);
            }
        }
    }, [companyDetails])

    useEffect( () => {
        if (bankCode && localStorage.getItem("token")) {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
            var urlencoded = new URLSearchParams();
            urlencoded.append("client_id", "d6eea592-2a90-4257-8e12-04f3ff52cf17");
            urlencoded.append("client_secret", "U1eG2pC6xP1cC2nD6vN3nU6xA1bP8bV2xI1gI7cK7aP1cI1hC2");
            urlencoded.append("grant_type", "authorization_code");
            urlencoded.append("code", bankCode.toString());
            urlencoded.append("redirect_uri", "https://dev-erp.primer.gr");
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: urlencoded,
                redirect: 'follow'
            };
            fetch("https://api.rapidlink.piraeusbank.gr/piraeusbank/production/v1.2/oauth/oauth2/token", requestOptions)
                .then(response => {
                    if (response.ok) {
                        // Successful response (status code in the range of 200-299)
                        return response.text();
                    } else {
                        console.log(response.body)
                    }
                })
                .then(result => {
                    if(result){
                        const jsonResult = JSON.parse(result);
                        let requestData = {
                            token: jsonResult.access_token,
                            company: company.id
                        }
                    try {
                        axios
                            .post(
                                `${apiURL2}/company/update-bank-tokens`,
                                requestData,
                                { headers: { "Content-Type": "application/json" } }
                            )
                            .then((res) => {
                                if (res.data && res.data.status === "200") {
                                    toast.success(res.data.message);
                                    clearParam();
                                    triggerBankCalls();
                                } else {
                                    toast.error(res.data.message);
                                }
                            });
                    } catch (error) {
                        console.log(error?.response?.data?.errors);
                    }
                    }
                })
                .catch(error => {
                    console.log('error', error)
                });
        }
    }, [bankCode]);

    const clearParam = () => {
        const searchParams = new URLSearchParams(location.search);
        searchParams.delete('code'); // Replace 'paramName' with the actual parameter name you want to clear
        // Generate the new URL without the parameter
        const newUrl = `${location.pathname}?${searchParams.toString()}`;
        // Replace the current URL with the new one
        history.replace(newUrl);
    };

    const triggerBankCalls = () => {
        axios.post(
                `${apiURL2}/company/trigger-bank-calls`,
                {company: company.id},
                { headers: { "Content-Type": "application/json" } }
            )
            .then((res) => {
                if (res.data && res.data.status === "200") {
                    toast.success(res.data.message);
                } else {
                    toast.error(res.data.message);
                }
            });
    }

     */

   useEffect(() => {
       const fetchArticles = async () => {
           const targetUrl = 'https://primer.gr/feed/rss';
           fetch(targetUrl, {
               method: 'GET',
               headers: {},
           })
               .then(response => response.text()) // Extract the response data as text
               .then(xmlData => {
           const parser = new DOMParser();
           const xml = parser.parseFromString(xmlData, 'application/xml');
           const items = xml.querySelectorAll('item');
           const rssArticles = [];
           items.forEach((item) => {
               const title = item.querySelector('title').textContent;
               const link = item.querySelector('link').textContent;
               const pubDate = item.querySelector('pubDate').textContent;
               const itemCategory = item.querySelector('category').textContent;
               if(itemCategory === "Primer ERP") {
                   const contentEncoded = item.getElementsByTagName('content:encoded')[0];
                   const content = contentEncoded ? contentEncoded.innerHTML.replace("]]>","") : '';
                   const article = {
                       title: title,
                       link: link,
                       pubDate: pubDate,
                       content: content
                   };
                   rssArticles.push(article);
               }
           });
           setArticles(rssArticles);
               })
               .catch(error => {
                   console.log(error);
               })
       }
       fetchArticles();
   }, []);

   useEffect( () => {
       dispatch(fetchSyncInvoicesProviderLog(company.id));
   },[dispatch]);

   const setShowModal = (logId) => {
       const logs = SALES_SETTINGS.syncInvoicesProviderLog?.syncs.find(sync => sync._id === logId);
       if(logs?.logs && logs.logs.length > 0){
           setDisplayedLogs(logs.logs);
       }
       setShowLogModal(true);
   }
    const columns = [
        {
            field: "syncDate",
            headerName: "Ημερομηνία",
            flex: 0.7,
            renderCell: (params) => `${moment(params.value).format("DD-MM-YYYY HH:mm")}`,
            hideMenu: true
        },
        {
            field: "totalInvoicesSynced",
            headerName: "Σύνολο συγχρονισμένων",
            flex: 0.65,
            hideMenu: true
        },
        {
            field: "totalNetValue",
            headerName: "Σύνολο Καθαρής Αξίας",
            flex: 0.50,
            renderCell: (params) => `${getGreekCurrencyFormat(params.value)}`,
            hideMenu: true,
        },
        {
            field: "totalVatValue",
            headerName: "Σύνολο ΦΠΑ",
            flex: 0.40,
            renderCell: (params) => `${getGreekCurrencyFormat(params.value)}`,
            hideMenu: true
        },
        {
            field: "totalValue",
            headerName: "Συνολική Αξία",
            flex: 0.50,
            renderCell: (params) => `${getGreekCurrencyFormat(params.value)}`,
            hideMenu: true
        },
        {
            field: "totalFailed",
            headerName: "Σύνολο Αποτυχημένων",
            flex: 0.60,
            hideMenu: true
        },
        {
            field: "_id",
            headerName: "Σφάλματα",
            flex: 0.80,
            renderCell: (params) => <Button disabled={SALES_SETTINGS.syncInvoicesProviderLog?.syncs.find(sync => sync._id === params.value)?.logs.length === 0} style={{float: "left"}} size="sm" onClick={() => setShowModal(params.value)}>Εμφάνιση </Button>,
            sortable: false,
            hideMenu: true
        },
    ];
    const getRowClassName = (params) => {
        return params.indexRelativeToCurrentPage % 2 === 0 ? 'even-row' : 'odd-row';
    };

    const authorizeBank = () => {
        console.log("clicked")
        window.location.href ="https://api.rapidlink.piraeusbank.gr/piraeusbank/production/v1.2/oauth/oauth2/authorize?response_type=code&client_id=d6eea592-2a90-4257-8e12-04f3ff52cf17&redirect_uri=https://dev-erp.primer.gr&scope=/sandboxapi";
   // window.open("https://openbank.piraeusbank.gr/identityserver/connect/authorize?response_type=code&client_id=d6eea592-2a90-4257-8e12-04f3ff52cf17&redirect_uri=https://dev-erp.primer.gr&scope=winbankAccess%20winbankAccess.info");
    }

    const closeBankTokenModal = () => {
        console.log(companyDetails)
        if(companyDetails?.bankTokenModalCounter >= 3) {
            toast.error("Δεν μπορεις να το κλεισεις");
        } else {
            axios.post(
                    `${apiURL2}/company/update-bank-modal-counter`, {company: company.id}, { headers: { "Content-Type": "application/json" } })
                .then(async ( res) => {
                    if (res.data && res.data.status === "200") {
                       console.log(res.data.message);
                       await dispatch(fetchCompanyDetails(company.id));
                        setTimeout(() => {
                            setShowBankModal(false);
                        }, 500);
                    } else {
                        toast.error(res.data.message);
                        setShowBankModal(false);
                    }
                })
                .catch(error => {
                    console.log(error);
                    setShowBankModal(false);
                })
        }
    }

    const formatGreekName = (name, isLastName = false) => {
        if (isLastName && String(name).endsWith("ος")) {
            return String(name).slice(0, -2) + "ε";
        }
        if (String(name).endsWith("ιος")) {
            return String(name).slice(0, -3) + "ιε";
        } else if (String(name).endsWith("ς")) {
            return String(name).slice(0, -1);
        }
        return name;
    }

    const firstBoxStyle = {
        position: "relative",
        height: "100%",
        width: "100%",
        borderRadius: "12px",
        backgroundColor: "#635BFF",
        boxShadow: "0px 2px 4px -1px gray",
        padding: "25px",
        color: "white",
        fontWeight: "500",
        fontSize: "18px",
    }

    const clientBoxStyle = {
        position: "relative",
        height: "100%",
        width: "100%",
        minHeight: "180px",
        borderTopLeftRadius: "12px",
        borderTopRightRadius: "12px",
        backgroundColor: "#f4f7fb",
        boxShadow: "0px 2px 4px -1px lightgray",
        color: "black",
        fontWeight: "400",
        fontSize: "18px",
    }

    const monthlySalesBoxStyle = {
        position: "relative",
        height: "100%",
        width: "100%",
        borderRadius: "12px",
        backgroundColor: "#f4f7fb",
        boxShadow: "0px 2px 4px -1px lightgray",
        padding: "25px",
        color: "black",
        fontWeight: "400",
        fontSize: "18px",
    }

    const monthlyClientPaymentsBoxStyle = {
        position: "relative",
        height: "100%",
        width: "100%",
        borderRadius: "12px",
        backgroundColor: "#f4f7fb",
        boxShadow: "0px 2px 4px -1px lightgray",
        padding: "25px",
        color: "black",
        fontWeight: "400",
        fontSize: "18px",
    }

    return (
        <div style={classicStyleBelowNavbar}>
            {checkPermission("custom-dashboarddata", permissionsData) === true ? (
                <React.Fragment>
                    <Row>
                        <Col md={12}>
                            <Button
                                disabled={updateLoader}
                                style={{marginLeft: "10px", marginRight: "10px"}}
                                className="mb-2 float-right"
                                size="sm"
                                onClick={() => dispatch(fetchDashboardData(company.id, company.year, setUpdateLoader))}>
                                Ενημέρωση Σελίδας
                                {!updateLoader && (
                                    <i className="fa-solid fa-rotate ml-1" style={{fontSize: "12px"}}></i>
                                )}
                                {updateLoader && (
                                    <span
                                        className="spinner-border spinner-border-sm ml-1"
                                        role="status"
                                        aria-hidden="true"
                                    ></span>
                                )}
                            </Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <Row className={"pt-1"}>
                                <Col md={6}>
                                    <div style={firstBoxStyle}>
                                        <span>{Number(moment().format("HH")) < 12 ? "Καλημέρα" : "Καλησπέρα"} {formatGreekName(currentUser.firstName)} {formatGreekName(currentUser.lastName, true)}, καλώς ορίσατε στο Primer ERP!</span>
                                        <Row style={{fontSize: "15px", marginTop: "30px"}}>
                                            <Col md={6} style={{borderRight: "1px solid gray"}}>
                                                <span style={{opacity: "0.75"}}>Σημερινές Πωλήσεις</span>
                                                <br/>
                                                <span style={{fontSize: "18px"}}>{getGreekCurrencyFormat(dashboardData.todaySales)}</span>
                                            </Col>
                                            <Col md={6}>
                                                <span style={{opacity: "0.75"}}>Χθεσινές Πωλήσεις</span>
                                                <br/>
                                                <span style={{fontSize: "18px"}}>{getGreekCurrencyFormat(dashboardData.yesterdaySales)}</span>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div style={clientBoxStyle}>
                                        <div style={{paddingLeft: "25px", paddingRight: "25px", paddingTop: "20px"}}>
                                            <span>Σύνολο πελατών</span><br/>
                                            <span style={{
                                                fontWeight: "600",
                                                fontSize: "20px",
                                                marginTop: "15px"
                                            }}>{dashboardData?.totalClients}</span>
                                        </div>
                                        <div style={{position: "absolute", bottom: 0, left: 0, width: "100%"}}>
                                            <SmallClientChart />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row style={{marginTop: "20px"}}>
                                <Col md={6} className={"pt-2"}>
                                    <div style={monthlySalesBoxStyle}>
                                        <span>Μηνιαίες Πωλήσεις</span><br/>
                                        <span style={{
                                            fontWeight: "600",
                                            fontSize: "20px",
                                            paddingTop: "20px",
                                        }}>{getGreekCurrencyFormat(dashboardData?.monthlySales)}</span><br/>
                                        <span style={{
                                            fontWeight: "600",
                                            opacity: "0.80",
                                            fontSize: "16px",
                                            color: "#ba68c8",
                                            fontStyle: "italic",
                                        }}>{dashboardData?.monthlySalesPercentage}%</span>
                                        <div style={{marginTop: "15px"}}>Από τον τελευταίο μήνα</div>
                                    </div>
                                </Col>
                                <Col md={6} className={"pt-2"}>
                                    <div style={monthlyClientPaymentsBoxStyle}>
                                        <span>Μηνιαίες Εισπράξεις</span><br/>
                                        <span style={{
                                            fontWeight: "600",
                                            fontSize: "20px",
                                            paddingTop: "20px",
                                        }}>{getGreekCurrencyFormat(dashboardData?.monthlyPayments)}</span><br/>
                                        <span style={{
                                            fontWeight: "600",
                                            opacity: "0.80",
                                            fontSize: "16px",
                                            color: "#ba68c8",
                                            fontStyle: "italic",
                                        }}>{dashboardData?.monthlyPaymentsPercentage}%</span>
                                        <div style={{marginTop: "15px"}}>Από τον τελευταίο μήνα</div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={6} className={"p-1"}>
                            <OverviewChart2 />
                        </Col>
                    </Row>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <h2>Πίνακας Ελέγχου</h2>
                    <h6>Καλώς ορίσατε στο Primer ERP, {formatGreekName(currentUser.firstName)} {formatGreekName(currentUser.lastName, true)}!</h6>
                </React.Fragment>
            )}
            <Row className={"mt-3 mb-3"}>
                <Col md={6}>
                    <div style={{height: "380px", width: "100%", backgroundColor: "var(--light-skyblue)", borderRadius: "8px", padding: "15px", overflowY: "scroll"}}>
                        <div style={{textAlign: "center", fontSize: "20px", fontWeight: "600"}} className={"mb-3"}>Νέα - Ειδήσεις</div>
                        {articles.length > 0 && articles.map((article, index) => (
                            <div key={`article ${index}`} style={{padding: "10px", backgroundColor: "white", borderRadius: "8px", boxShadow: "0px 2px 4px -1px lightgray"}}>
                                <div style={{fontSize: "18px"}} className={"mb-1"}>{article.title}</div>
                                {article.content && (<div dangerouslySetInnerHTML={{ __html: article.content }} />)}
                                {article.pubDate && ( <div>{moment(article.pubDate).format("DD/MM/YYYY HH:mm")}</div>)}
                                {articles.length !== index + 1 && (
                                    <hr/>
                                )}
                            </div>
                        ))}
                    </div>
                </Col>
                <Col md={6}>
                    <div style={{height: "100%", width: "100%"}}>
                        <Box
                            gridColumn="span 6"
                            gridRow="span 2"
                            style={{borderRadius: "6px"}}
                            sx={{
                                backgroundColor: 'var(--light-skyblue)',
                                "& .MuiDataGrid-root": {
                                    border: "none",
                                    borderRadius: "5rem",
                                },
                                "& .MuiDataGrid-cell": {
                                    borderBottom: "none",
                                },
                                "& .MuiDataGrid-columnHeaderTitle": {
                                    whiteSpace: "normal",
                                    lineHeight: "normal"
                                },
                                "& .MuiDataGrid-columnHeader": {
                                    // Forced to use important since overriding inline styles
                                    height: "unset !important"
                                },
                                "& .MuiDataGrid-columnHeaders": {
                                    backgroundColor: theme.palette.background.alt,
                                    maxHeight: "168px !important",
                                    color: theme.palette.secondary[100],
                                    borderBottom: "none",
                                },
                                "& .MuiDataGrid-virtualScroller": {
                                    backgroundColor: theme.palette.background.alt,
                                },
                                "& .MuiDataGrid-footerContainer": {
                                    backgroundColor: theme.palette.background.alt,
                                    color: theme.palette.secondary[100],
                                    borderTop: "none",
                                },
                                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                    color: `${theme.palette.secondary[200]} !important`,
                                },
                            }}
                        >
                            <div style={{display: "flex", alignItems: "center", padding: "8px"}}>
                                <Row>
                                    <Col md={6}>
                                        <span style={{fontSize: "16px", fontWeight: "600"}}>Αναφορά αποτελεσμάτων συγχρονισμού παραστατικών από λοιπές εφαρμογές</span>
                                    </Col>
                                    <Col md={6}>
                                        <Button size="sm" style={{float: "right"}} disabled={syncLoader} onClick={() => dispatch(triggerCleanFailedSyncs(company.id, setSyncLoader))}>Καθαρισμός αποτυχημένων</Button>
                                        <Button
                                            disabled={syncLoader || ((currentHour === startTime && currentMinute >= 0) ||
                                                (currentHour === endTime && currentMinute < 0) ||
                                                (currentHour > startTime && currentHour < endTime))}
                                            style={{marginLeft: "auto", marginRight: "10px", float: "right"}}
                                            className={"ml-1"} size="sm"
                                            onClick={() => dispatch(triggerSyncProviderInvoices(company.id, setSyncLoader))}>
                                            {(currentHour === startTime && currentMinute >= 0) ||
                                            (currentHour === endTime && currentMinute < 0) ||
                                            (currentHour > startTime && currentHour < endTime) ? "Ενημέρωση σε εξέλιξη" : "Ενημέρωση"}
                                            {!syncLoader && (
                                                <i className="fa-solid fa-rotate ml-1" style={{fontSize: "12px"}}></i>
                                            )}
                                            {syncLoader && (
                                                <span
                                                    className="spinner-border spinner-border-sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                ></span>
                                            )}
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                            <DataGrid
                                loading={!SALES_SETTINGS.syncInvoicesProviderLog}
                                getRowId={(row) => row._id}
                                rows={(SALES_SETTINGS.syncInvoicesProviderLog && SALES_SETTINGS.syncInvoicesProviderLog.syncs) || []}
                                columns={columns}
                                getRowClassName={getRowClassName}
                            />
                        </Box>
                    </div>
                </Col>
            </Row>
            <Modal className="themeModalBig" show={showLogModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Σφάλματα</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {displayedLogs && displayedLogs.length > 0 && (
                        <div>
                            {displayedLogs.map((log) => (
                                <>
                                    <div>{log?.invoiceData}</div>
                                    <div>Μήνυμα Λάθους: {log?.message}</div>
                                    <hr />
                                </>
                            ))}
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={() => setShowLogModal(false)}>ΟΚ</Button>
                </Modal.Footer>
            </Modal>
            <Modal className="themeModalBig" show={showBankModal}>
                <Modal.Header closeButton onClick={() => closeBankTokenModal()}>
                    <Modal.Title>Αυθεντικοποίηση Τραπεζικού Λογαριασμού</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Είναι υποχρεωτικό να κάνετε αυθεντικοποίηση.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => authorizeBank()}>Αυθεντικοποίηση Τράπεζας Πειραιώς</Button>
                    <Button variant="outline-primary" onClick={() => closeBankTokenModal()}>Κλείσιμο</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Start;
