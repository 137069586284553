import React, {useEffect, useState} from "react";
import {Button, Col, Modal, Row} from "react-bootstrap";
import Input from "../../../common/Input";
import EditableTable from "../../../primer/Editable-Table";
import {useDispatch, useSelector} from "react-redux";
import {setSaveQuickRetailSales} from "../../../../_reducers/activitySlice";
import AccountingBox from "../../../primer/AccountingBox"
import {
  getAmountWithVat,
  getClientVatPercentage,
  getGreekCurrencyFormat,
  getLastHighestValue,
  getOptionsByData,
  getQrsProductTotals,
  isValidEmail
} from "../../../../_helpers/commonFunctions";
import {
  resetQrsSearchProducts,
  setNewQuickRetailSales,
  setProductSearchKeyword,
  setQuickRetailSalesProducts,
  setSeletedPaymentWays
} from "../../../../_reducers/DataPanel/Sales/quickRetailSalesSlice";
import {
  deleteObjectFromArrayOfObjects,
  getLabelByValue,
  getObjectFromArrayOfObjects
} from "../../../../_helpers/helperFunctions";
import {toast} from "react-toastify";
import {fetchProductByKeywords} from "../../../../_apis/api";
import AdvancedCombobox from "../../../primer/AdvancedCombobox";
import axios from "axios";
import Checkbox from "../../../common/CheckBox";
import moment from "moment";
import {useTranslation} from 'react-i18next';

const apiURL2 = process.env.REACT_APP_API_URL2;

const tableButtons =  {
  print: false,
  delete: true,
  export: false,
  edit: false
}

function QuickRetailSalesNew() {

  const [t] = useTranslation('common');
  const dispatch = useDispatch();
  const CLIENTS_DATA = useSelector( (state) => state.CLIENTS_DATA );
  const QUICK_RETAIL_SALES = useSelector( (state) => state.QUICK_RETAIL_SALES );
  const ACTIVITY = useSelector( (state) => state.ACTIVITY );
  const SETTINGS = useSelector( (state) => state.SETTINGS );
  const SALES_SETTINGS = useSelector( (state) => state.SALES_SETTINGS );
  const company = useSelector(state => state.COMPANY_DATA.company);
  const [clientsList, setClientsListData] = useState([]);
  let clientsOptions = getOptionsByData(clientsList, '_id', 'name');

  const [showIssuesModal, setShowIssuesModal] = useState(false);
  const [fullScreenMode, setFullScreenMode] = useState(false);
  const [clientPaymentTotalsValue, setClientPaymentTotal] = useState(0);
  const [clientChangeValue, setClientChangeValue] = useState(0);
  const [cashRegisterValues, setCashRegisterValues] = useState({
    cashAmount: 0,
    paymentWay1Amount: 0,
    paymentWay2Amount: 0,
    paymentWay3Amount: 0,
  });

  const [clientVatCategory, setClientVatCategory] = useState('Normal VAT');

  const searchProducts = QUICK_RETAIL_SALES.qrsSearchProducts.slice();
  const defaultProductsList = SALES_SETTINGS.quickRetailSettings?.defaultProduct;
  let settingsDefaultProducts = [];

  if(defaultProductsList.length > 0) {
    settingsDefaultProducts = defaultProductsList;
  }

  const requestDataF = {
    company:company.id,
    year:company.year.toString(),
    name: ''
  }

  useEffect(() => {

    // dispatch(fetchInitialClients(requestData));
    loadInitialClientsData()
  },[])

  useEffect(() => {

    // Set data on page load
    let newQuickRetailSales = Object.assign({}, QUICK_RETAIL_SALES.newQuickRetailSales);
    let newQuickRetailSalesData = {};

    // Check if new client is allowed.
    newQuickRetailSalesData.allowNewClient = SALES_SETTINGS.quickRetailSettings.allowNewClientCreation;

    // Default Values from the quick retail settings
    if ((newQuickRetailSales.client === '' || newQuickRetailSales.client === SALES_SETTINGS.quickRetailSettings.defaultRetailClient)) {
      let defaultSettingsClient = SALES_SETTINGS.quickRetailSettings.defaultRetailClient;
      let defaultSettingsClientName = SALES_SETTINGS.quickRetailSettings.defaultRetailClientName;
      newQuickRetailSalesData.client = defaultSettingsClient;
      newQuickRetailSalesData.clientName = defaultSettingsClientName;

      // Similar logic used on handle change
      let selectedClient = getObjectFromArrayOfObjects(CLIENTS_DATA.clients.slice(), defaultSettingsClient, '_id');

      if (selectedClient && selectedClient.length > 0) {
        let sClient             = selectedClient['0'];
        newQuickRetailSalesData.address    = sClient.address;
        newQuickRetailSalesData.phone      = sClient.phone;
        newQuickRetailSalesData.email      = sClient.email.replaceAll("mailto:", '');
        setClientVatCategory(sClient?.vatCategory); // set client vat category
      }
    }

    // Default Values from the quick retail settings
    newQuickRetailSalesData.documentType        = SALES_SETTINGS.quickRetailSettings.defaultDocumentType;
    newQuickRetailSalesData.documentTypeName    = SALES_SETTINGS.quickRetailSettings.defautlDocumentTypeName;
    newQuickRetailSalesData.installation        = SALES_SETTINGS.quickRetailSettings.defaultInstallation;
    newQuickRetailSalesData.installationName    = SALES_SETTINGS.quickRetailSettings.defaultInstallationName;
    newQuickRetailSalesData.preNumber           = SALES_SETTINGS.quickRetailSettings.defaultPreNumber;
    newQuickRetailSalesData.numberingMode       = SALES_SETTINGS.quickRetailSettings.defaultPreNumber ? 'automatic' : SALES_SETTINGS.quickRetailSettings?.defaultNumberingMode;
    newQuickRetailSalesData.number              = 0;

    newQuickRetailSales = {...newQuickRetailSales, ...newQuickRetailSalesData}

    // Passing the total values
    let qrsProductListTotals  = getQrsProductTotals(qrsProductListData);
    newQuickRetailSales.totalQuantity = qrsProductListTotals.quantity ? qrsProductListTotals.quantity : 0;
    newQuickRetailSales.totalPrice    = qrsProductListTotals.price ? qrsProductListTotals.price : 0;
    newQuickRetailSales.totals        = qrsProductListTotals.totals ? qrsProductListTotals.totals : 0;

    // Passing cash register values
    newQuickRetailSales.cash               = qrsProductListTotals.totals ? qrsProductListTotals.totals : 0;
    newQuickRetailSales.paymentWay1        = qrsProductListTotals.totals ? qrsProductListTotals.totals : 0;
    newQuickRetailSales.paymentWay2        = qrsProductListTotals.totals ? qrsProductListTotals.totals : 0;
    newQuickRetailSales.paymentWay3        = qrsProductListTotals.totals ? qrsProductListTotals.totals : 0;

    dispatch(setNewQuickRetailSales(newQuickRetailSales));

    // Load Product based on default advanced type form the quick retail settings
    if (SALES_SETTINGS.quickRetailSettings.defaultDocumentType !== "") {
      let dtData = getObjectFromArrayOfObjects(SETTINGS.settings.advancedTypesSales, SALES_SETTINGS.quickRetailSettings.defaultDocumentType, '_id');
      if (dtData.length > 0) {
        let currentAdvancedType  = dtData['0'];
        if (currentAdvancedType.enableMyDataRules && currentAdvancedType.enableMyDataRules === 'active') {
          // loadClientsListData(SALES_SETTINGS.quickRetailSettings.defaultDocumentType);
        }
      }
    }

  }, [])

    // fetching clients list
    const loadInitialClientsData = () => {
      let c = company.id;
      let y = moment().format("YYYY");
      axios
        .post(process.env.REACT_APP_API_URL2 + `/client/list-client`, {company: c, year: y})
        .then((res) => {
          if (res.data && res.data.status === "200") {
            if (res.data.data) {
              // Remove none empty client name data
              let filteredData = res.data.data;
              setClientsListData(filteredData);
            } else {
              setClientsListData([]);
            }
          } else {
            setClientsListData([]);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    };

   // fetching clients list
   const loadClientsData = (value, setData) => {
    let c = company.id;
    let y = moment().format("YYYY");
    axios
      .post(process.env.REACT_APP_API_URL2 + `/client/list-client`, {name:value, company: c, year: y})
      .then((res) => {
        if (res.data && res.data.status === "200") {
          if (res.data.data) {
            // Remove none empty client name data
            let filteredData = res.data.data;
            // setClientsListData(filteredData);
            setData(filteredData);
            clientsOptions = getOptionsByData(filteredData, '_id', 'name');

          } else {
            // setClientsListData([]);
            setData([]);
          }
        } else {
          // setClientsListData([]);
          setData([]);

        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const onChangeSearch = (keyword,setData) => {
    requestDataF.name= keyword
    if(keyword.length >= 2) {
      loadClientsData(keyword,setData)
      // dispatch(fetchClients(requestDataF, setData));
    }
    }

  const handleOnChange = (e, type="default", ddname = '') => {

    // Set if editing the form
    if (!ACTIVITY.saveQuickRetailSales) {
      dispatch(setSaveQuickRetailSales(true));
    }

    let name;
    let value;

    if (type === 'dd')  {
      name = ddname;
      value = e.value;
    } else if (type === 'acdd' ) {
      e = e.row;
      value = e._id;
      name = ddname;
    } else {
      name = e.target.name;
      value = e.target.value;
    }

    // Set data
    let newQuickRetailSales = Object.assign({}, QUICK_RETAIL_SALES.newQuickRetailSales);
    let newQuickRetailSalesData = {};

    newQuickRetailSalesData.allowNewClient = SALES_SETTINGS.quickRetailSettings.allowNewClientCreation;

    if (name === 'client') {
      newQuickRetailSalesData[name] = value;
      newQuickRetailSalesData.clientName = getLabelByValue(clientsOptions, value, 'value');

      // Client Extra Details
      // Similar logic used in useEffect on load
      let selectedClient = getObjectFromArrayOfObjects(clientsList, value, '_id');
      if (selectedClient && selectedClient.length > 0) {
        let sClient             = selectedClient['0'];
        newQuickRetailSalesData.address    = sClient.address;
        newQuickRetailSalesData.phone      = sClient.phone;
        newQuickRetailSalesData.email      = sClient.email.replaceAll("mailto:", '');
        newQuickRetailSalesData.clientType = 'Existing';

        setClientVatCategory(sClient?.vatCategory); // set client vat category

      }

    } else if(name === 'clientName') {
      newQuickRetailSalesData.clientName = value;
    } else if(name === 'clientType') {
      if (value === "New") {
        newQuickRetailSalesData.address    = "";
        newQuickRetailSalesData.phone      = "";
        newQuickRetailSalesData.email      = "";
        newQuickRetailSalesData.clientType = 'New';
        newQuickRetailSalesData.clientName = '';
      } else {
        let selectedClient = getObjectFromArrayOfObjects(clientsList, QUICK_RETAIL_SALES.newQuickRetailSales.client, '_id');
        if (selectedClient && selectedClient.length > 0) {
          let sClient             = selectedClient['0'];
          newQuickRetailSalesData.address    = sClient.address;
          newQuickRetailSalesData.phone      = sClient.phone;
          newQuickRetailSalesData.email      = sClient.email.replaceAll("mailto:", '');
          newQuickRetailSalesData.clientType = 'Existing';
          newQuickRetailSalesData.clientName = getLabelByValue(clientsOptions, QUICK_RETAIL_SALES.newQuickRetailSales.client, 'value');

          setClientVatCategory(sClient?.vatCategory); // set client vat category

        }
      }
    } else {
      newQuickRetailSalesData[name] = value;
    }

    newQuickRetailSales = {...newQuickRetailSales, ...newQuickRetailSalesData}

    // Passing the total values
    let qrsProductListTotals  = getQrsProductTotals(qrsProductListData);
    newQuickRetailSales.totalQuantity = qrsProductListTotals.quantity ? qrsProductListTotals.quantity : 0;
    newQuickRetailSales.totalPrice    = qrsProductListTotals.price ? qrsProductListTotals.price : 0;
    newQuickRetailSales.totals        = qrsProductListTotals.totals ? qrsProductListTotals.totals : 0;

    dispatch(setNewQuickRetailSales(newQuickRetailSales));
  }

  const handlePaymentAnalysis = (e, type="default", ddname = '') => {

    // Set if editing the form
    if (!ACTIVITY.saveQuickRetailSales) {
      dispatch(setSaveQuickRetailSales(true));
    }

    let name = e?.target?.value;
    let value = e?.target?.name;

    if (type === 'ac' ) {
      value = e.target?.value;
      name = ddname;
    }

    // Payment Analysis Calculation
    let paymentAnalysis = cashRegisterValues;

    if ((name === 'cash') && value && value > 0) {
      setCashRegisterValues({...cashRegisterValues, cashAmount: value});
      paymentAnalysis.cashAmount = value;
    }

    if ((name === 'paymentway1') && value && value >= 0) {
      setCashRegisterValues({...cashRegisterValues, paymentWay1Amount: value});
      paymentAnalysis.paymentWay1Amount = value;
    }

    if ((name === 'paymentway2') && value && value >= 0) {
      setCashRegisterValues({...cashRegisterValues, paymentWay2Amount: value});
      paymentAnalysis.paymentWay2Amount = value;
    }

    if ((name === 'paymentway3') && value && value >= 0) {
      setCashRegisterValues({...cashRegisterValues, paymentWay3Amount: value});
      paymentAnalysis.paymentWay3Amount = value;
    }

    let clientPaymentTotal = Object.keys(paymentAnalysis).reduce((sum,key) => sum + parseFloat(paymentAnalysis[key] || 0), 0);
    setClientPaymentTotal(parseFloat(clientPaymentTotal).toFixed(2));

    let clientChangeAmount = clientPaymentTotal - qrsProductListTotals?.totals;
    setClientChangeValue(clientChangeAmount)

    // Passing the Analysis values
    let newQuickRetailSales = Object.assign({}, QUICK_RETAIL_SALES.newQuickRetailSales);
    let newQuickRetailSalesData = {
      cash: paymentAnalysis.cashAmount ? paymentAnalysis.cashAmount : 0,
      paymentWay1: paymentAnalysis.paymentWay1Amount ? paymentAnalysis.paymentWay1Amount : 0,
      paymentWay2: paymentAnalysis.paymentWay2Amount ? paymentAnalysis.paymentWay2Amount : 0,
      paymentWay3: paymentAnalysis.paymentWay3Amount ? paymentAnalysis.paymentWay3Amount : 0,
      clientPaymentTotals: clientPaymentTotal ? clientPaymentTotal : 0,
      clientChange: clientChangeAmount ? clientChangeAmount : 0
    }
    newQuickRetailSales = {...newQuickRetailSales, ...newQuickRetailSalesData}
    dispatch(setNewQuickRetailSales(newQuickRetailSales))

  }

  const handleSearchkeyword = (e) => {
    let keyword = e.target.value;
    let c = company.id;
    let y = moment().format("YYYY");

    dispatch(setProductSearchKeyword(keyword));

    if (keyword.length >= 3) {
      dispatch(fetchProductByKeywords({name: keyword, company: c, year: y}))
    } else {
      dispatch(resetQrsSearchProducts())
    }
   }

  const handleIssueRecept = () => {
    setShowIssuesModal(false);
    toast.success('Issues the receipt.');
  }

  const handleIssueCashRegister = () => {
    let paymentWays = QUICK_RETAIL_SALES.newQuickRetailSales.seletedPaymentWays;
    if (SALES_SETTINGS.quickRetailSettings.activateCashRegister === 'active' || paymentWays.length > 1) {
      setShowIssuesModal(true)
    } else {
      if (paymentWays.length === 0) {
        toast.error('At least one option is mandatory.');
      } else {
        toast.success('Issues the receipt.');
      }
    }
  }

  const handleFullScreenMode = () => {
    setFullScreenMode(!fullScreenMode);
    if (!fullScreenMode) {
      sessionStorage.setItem('qrsFullScreen', 'true');
      toast.success('Full screen mode enabled');
    } else {
      toast.info('Full screen mode disabled');
      sessionStorage.setItem('qrsFullScreen', 'false');
    }
  }

  const handleSendEmail = () => {
    if (!QUICK_RETAIL_SALES.newQuickRetailSales.email || QUICK_RETAIL_SALES.newQuickRetailSales.email === '') {
      toast.error('Email address not found.');
    } else {
      toast.success('Mail sent successfully.');
    }
  }

  const handlePaymentWays = (value) => {
    let paymentWays = QUICK_RETAIL_SALES.newQuickRetailSales.seletedPaymentWays.slice();
    if (paymentWays.includes(value)) {
      paymentWays = paymentWays.filter(function(item) {
          return item !== value
      })
    } else {
      paymentWays.push(value);
    }
    dispatch(setSeletedPaymentWays(paymentWays));
  }

  // const selectedClient = getDefaultOptionByValue(clientsOptions, QUICK_RETAIL_SALES.newQuickRetailSales.client, 'value', 'label', 'value');
  const disabledFields = (QUICK_RETAIL_SALES.newQuickRetailSales.client === undefined || QUICK_RETAIL_SALES.newQuickRetailSales.client === '');

  const clientDropdownColumns = [
    {
      label: t('QuickRetailSalesNew.smallTable.code'),
      name: "systemCode",
    },
    {
      label: t('QuickRetailSalesNew.smallTable.name'),
      name: "name",
    },
    {
      label: t('QuickRetailSalesNew.smallTable.phone'),
      name: "phone",
    },
    {
      label: t('QuickRetailSalesNew.smallTable.email'),
      name: "email",
    },
  ];

  const quickRetailSalesColumns = [
    {
      name: "No",
      field: "no",
      width: "20px",
      editable: false,
      inputType: {
        type: 'text',
      }
    },
    {
      name: t('QuickRetailSalesNew.table.productName'),
      field: "productName",
      width: "130px",
      editable: false,
      inputType: {
        type: 'text',
      }
    },
    {
      name: t('QuickRetailSalesNew.table.productName.quantity'),
      field: "quantity",
      width: "80px",
      editable:true,
      inputType: {
        type: 'accounting',
        config: {
          maxLength: 10 // Given in the wireframe
        }
      }
    },
    {
      name: t('QuickRetailSalesNew.table.productName.price'),
      field: "price",
      editable: true,
      disabledAccountingBox: SALES_SETTINGS.quickRetailSettings.forbitChangesSalesProducts === "active",
      width: "120px",
      inputType: {
        type: 'accounting',
        config: {
          focused: true,
          suffix: "€",
          maxLength: 100
        }
      }
    },
    {
      name: t('QuickRetailSalesNew.table.productName.totals'),
      field: "totals",
      width: "120px",
      disabledAccountingBox: SALES_SETTINGS.quickRetailSettings.forbitChangesSalesProducts === "active",
      editable: true,
      inputType: {
        type: 'accounting',
        config: {
          focused: true,
          suffix: "€",
          maxLength: 100
        }
      }
    },
  ]

  let screenMode = sessionStorage.getItem('qrsFullScreen');
  let fullScreenClasses = 'normal-mode';
  if (screenMode && screenMode === 'true') {
    fullScreenClasses = 'fullscreen-mode';
  }

  let settingsPaymentWays = SALES_SETTINGS.quickRetailSettings.paymentWays;

  // Check if name is an array then show the name only
  const filterSearchProductName = (name) => {
    if (Array.isArray(name)) {
      name = name['0'];
    }
    return name;
  }

  // On Searched Or Related Product add them to the products list table
  const insertProductToTable = (product, type = 'recommended') => {

    // Set if editing the form
    if (!ACTIVITY.saveQuickRetailSales) {
      dispatch(setSaveQuickRetailSales(true));
    }

    let productId = null;

    if (type === 'search') {
      productId = product._id;
    } else {
      productId = product.value;
    }

    if (productId !== null && productId !== undefined) {
      // Get Product Full Details
      axios.get(apiURL2 + `/category/get-product-by-id/${productId}`)
      .then(res => {
        if (res.data &&  res.data.status === '200') {
          let productData = res.data.data;
          // productData.vat

          let vatValue = getClientVatPercentage(clientVatCategory, productData.vat);

          // if (productData.vat === 'Vat For Companies Abroad - (Custom)') {
          //   vatValue = productData.customVat;
          // }

          let amountWithVat = getAmountWithVat(productData.price, vatValue, productData?.vatIncluded?.toString());

          // Add Product to Quick Retail Sales table
          let qrsProducts = QUICK_RETAIL_SALES.newQuickRetailSales.products.slice();

          // Row Product Data
          let qrsProductRowData = {
            id: 1,
            no: 1,
            product: productData?._id,
            productName: productData?.name,
            quantity: 1,
            price: productData.price && productData.price !== "" ? amountWithVat : 0,
            totals: productData.price && productData.price !== "" ? amountWithVat : 0,
            isActive: "active",
            vat: productData.vat,
            customVat: productData.customVat,
            vatIncluded: productData.vatIncluded,
            vatReason: productData.vatReason,
          };

          // Check if product list have any data then increment the numbers
          if (qrsProducts.length > 0) {
            let lastId = getLastHighestValue(qrsProducts);
            qrsProductRowData.id = lastId + 1;
            qrsProductRowData.no = lastId + 1;
          }

          const pObjIndx = qrsProducts.findIndex(pObj => pObj.product === productId);

          if (pObjIndx < 0) {
            // Update products list

            // Passing the total values
            let qrsProductListTotals  = getQrsProductTotals(qrsProductListData);
            qrsProducts.totalQuantity = qrsProductListTotals.quantity ? qrsProductListTotals.quantity : 0;
            qrsProducts.totalPrice    = qrsProductListTotals.price ? qrsProductListTotals.price : 0;
            qrsProducts.totals        = qrsProductListTotals.totals ? qrsProductListTotals.totals : 0;

            dispatch(setQuickRetailSalesProducts([...qrsProducts, qrsProductRowData]));
            toast.success('Product added to listing table.')
          } else {
            // toast.info('Product already added to listing table.')
          }

        }
      })
    }
  }

  const qrsProductListActions = {
    insert: () => {},
    update: (data, id) => {

      // Set if editing the form
      if (!ACTIVITY.saveQuickRetailSales) {
        dispatch(setSaveQuickRetailSales(true));
      }
      let qrsProducts = QUICK_RETAIL_SALES.newQuickRetailSales.products.slice();
      let foundProduct = qrsProducts.find(item => item.id === id);
      if (foundProduct !== undefined) {
        // Updated selected product with updated field data.
        foundProduct = {...foundProduct, ...data}
        // Calculations for total calculation
        if(foundProduct.quantity !== '' && foundProduct.price !== '' && !data.totals) {
          foundProduct.totals = parseFloat(foundProduct.quantity) * parseFloat(foundProduct.price);
        }

        // Calculations for price if total is updated
        if(foundProduct.quantity !== '' && foundProduct.price !== '' && data.totals) {
          if (parseFloat(foundProduct.quantity) > 0 && parseFloat(foundProduct.totals) > 0) {
            let newPrice = parseFloat(parseFloat(foundProduct.totals) / parseFloat(foundProduct.quantity));
            foundProduct.price = newPrice.toFixed(2);
          }
        }
        qrsProducts[id-1] = {...qrsProducts[id - 1], ...foundProduct};
        dispatch(setQuickRetailSalesProducts(qrsProducts));
      }

    },
    selected: () => {
      console.log('selected mydata row clicked');
    },
    delete: (id) => {

      // Set if editing the form
      if (!ACTIVITY.saveQuickRetailSales) {
        dispatch(setSaveQuickRetailSales(true));
      }

      let qrsProducts = QUICK_RETAIL_SALES.newQuickRetailSales.products.slice();

      if (id !== '' && Number.isInteger(id)) {
        const deletedProductsData = deleteObjectFromArrayOfObjects(qrsProducts, id, 'id');
        dispatch(setQuickRetailSalesProducts(deletedProductsData));
      }

      if (id !== '' && !Number.isInteger(id)) {
        const deletedProductsData = deleteObjectFromArrayOfObjects(qrsProducts, id, '_id');
        dispatch(setQuickRetailSalesProducts(deletedProductsData));
      }

    }
  }

  let qrsProductListData = QUICK_RETAIL_SALES.newQuickRetailSales?.products;

  // adding Totals
  let qrsProductListTotals = getQrsProductTotals(qrsProductListData);

  // Manage Client Field Disable
  // Condition 1: If new client allowed and adding new client
  // Condition 2: if selection allowed but new client not allowed.
  // Condition 3: if selection and new client both not allowed then using default client
  let disableClientField = true;
  if ((SALES_SETTINGS.quickRetailSettings.allowNewClientCreation === 'active' && QUICK_RETAIL_SALES.newQuickRetailSales.clientType === "New") ||
  SALES_SETTINGS.quickRetailSettings.allowClientSelection === 'active') {
    disableClientField = false;
  }

  // If new client don't show dropdown.
  let allowClientSelection = SALES_SETTINGS.quickRetailSettings.allowClientSelection === 'active';
  if (QUICK_RETAIL_SALES.newQuickRetailSales.clientType === "New") {
    allowClientSelection = false;
  }

  let disableClientDetailsFields = true;
  if (SALES_SETTINGS.quickRetailSettings.allowNewClientCreation === 'active' && QUICK_RETAIL_SALES.newQuickRetailSales.clientType === "New") {
    disableClientDetailsFields = false;
  }

  return (
    <form className={fullScreenClasses} autoComplete = "off">

      <Row>
        <Col sm={12} md={4} className={QUICK_RETAIL_SALES.newQuickRetailSales.clientName === "" ? "qrssearchClients" : ""}>
          { QUICK_RETAIL_SALES.newQuickRetailSales.clientType === 'Existing' &&
            <AdvancedCombobox
              className ="w-100 ac-select-dropdown"
              searchableDropdownEnable = {false}
              defaultValue ={ QUICK_RETAIL_SALES.newQuickRetailSales.clientName }
              key ={Math.random()}
              label={t('QuickRetailSalesNew.client.client')}
              data={clientsList}
              onChange={(e) => { handleOnChange(e, 'acdd', 'client')}}
              excludeColumns = {[]}
              columns = { clientDropdownColumns}
              onChangeSearch = {onChangeSearch}
              editEnable = {false}
              deleteEnable = {false}
              searchTableEnable = {false}
              showTableOptionOnSelect = {true}
              placeholder = {t('QuickRetailSalesNew.client.placeholder')}
              required= {true}
              allowSelection = {allowClientSelection}
              disabled = {disableClientField}
            />
          }

          { QUICK_RETAIL_SALES.newQuickRetailSales.clientType === "New" &&
            <Input
              label={t('QuickRetailSalesNew.client2.client2')}
              className="mb-2 w-100"
              name="clientName"
              type="text"
              required= {true}
              value={QUICK_RETAIL_SALES.newQuickRetailSales.clientName}
              placeholder={t('QuickRetailSalesNew.client2.placeholder')}
              onChange = {(e) => handleOnChange(e) }
            />
          }
        </Col>
        <Col sm={12} md={3} className="d-flex align-items-end justify-content-start " >
          { SALES_SETTINGS.quickRetailSettings.allowNewClientCreation === 'active' &&
            <div className="inline-field d-flex align-items-center acremd  mb-3">
              <label htmlFor="client-active" className="mb-0 mr-2 " >{t('QuickRetailSalesNew.client2.question')} </label>
              <Checkbox
                className="mb-2"
                name="clientType"
                key = {Math.random()}
                id="client-type"
                defaultValue={ QUICK_RETAIL_SALES.newQuickRetailSales.clientType === 'Existing' ? "inactive" : "active" }
                value={ QUICK_RETAIL_SALES.newQuickRetailSales.clientType === 'Existing' ? "New" : "Existing" }
                onChange = {(e) => handleOnChange(e, 'default', 'clientType') }
              />
            </div>
          }
        </Col>
        <Col sm={12} md={5} className="mb-3 text-right d-flex align-items-end justify-content-end">
          <span className="mr-2 expendview" onClick={() => handleFullScreenMode()}>
            { fullScreenMode ? <i title="Full Screen Mode Disable" className="fas fa-compress"></i> : <i title="Full Screen Mode Enable" className="fas fa-expand"></i> }
          </span>
          <Button className="disabled">{t('QuickRetailSalesNew.files')}</Button>
        </Col>
      </Row>


      <Row className="">
        <Col sm='12' md="6">
            <Input
              label={t('QuickRetailSalesNew.address.address')}
              className="mb-3 w-100"
              name="address"
              type="text"
              value={QUICK_RETAIL_SALES.newQuickRetailSales.address}
              placeholder={t('QuickRetailSalesNew.address.placeholder')}
              onChange = {(e) => handleOnChange(e) }
              disabled = {disableClientDetailsFields}
            />
        </Col>
        <Col sm='12' md="3">
            <Input
              label={t('QuickRetailSalesNew.phone.phone')}
              className="mb-3 w-100"
              name="phone"
              type="tel"
              value={QUICK_RETAIL_SALES.newQuickRetailSales.phone}
              placeholder={t('QuickRetailSalesNew.phone.placeholder')}
              onChange = {(e) => handleOnChange(e) }
              disabled = {disableClientDetailsFields}
            />
        </Col>
        <Col sm='12' md="3">
          <div className="mb-3">
            <Input
              label={t('QuickRetailSalesNew.email.email')}
              className="w-100"
              name="email"
              type="email"
              value={QUICK_RETAIL_SALES.newQuickRetailSales.email}
              placeholder={t('QuickRetailSalesNew.email.placeholder')}
              onChange = {(e) => handleOnChange(e) }
              disabled = {disableClientDetailsFields}
            />
            { QUICK_RETAIL_SALES.newQuickRetailSales.email !== '' && !isValidEmail(QUICK_RETAIL_SALES.newQuickRetailSales.email) &&
              <div className="text-danger">{t('QuickRetailSalesNew.email.warning')}</div>
            }
          </div>
        </Col>
      </Row>

      <Row className="product-form position-relative">
        {disabledFields && <div className="overlay_w pt-0 align-items-center">{t('QuickRetailSalesNew.message')}</div>}
        <Col sm='12' md='8'>
          {
            QUICK_RETAIL_SALES.newQuickRetailSales?.products &&
            QUICK_RETAIL_SALES.newQuickRetailSales?.products?.length === 0 &&
            ACTIVITY.saveQuickRetailSales &&
            <span className="text-danger d-inline-block">{t('QuickRetailSalesNew.messageTable')}</span>
          }
          <EditableTable
            actionButtons={tableButtons}
            allowInsertRow={false}
            allowPagination={false}
            allowBulkDelete={false}
            allowSorting={false}
            allowActions={ true }
            key = {Math.random()}
            columns={quickRetailSalesColumns}
            onUpdate={qrsProductListActions}
            data={qrsProductListData}
            footerData = {qrsProductListTotals}
            enableFooter = {true}
          />

          <Row className="qrsnbtns">
            <Col sm='12' md='9'>
              <div className="w-100 text-right">
                <Button
                  className='mr-2 mb-3 qrs-send-email'
                  disabled = {SALES_SETTINGS.quickRetailSettings?.defaultSendEmailStatusName === 'Deactivated'}
                  onClick={() => handleSendEmail()}>{t('QuickRetailSalesNew.sendEmail')}</Button>
              </div>
              <Button className='mr-2 mb-3' onClick={() => handlePaymentWays('cash')} >
              { QUICK_RETAIL_SALES.newQuickRetailSales.seletedPaymentWays.includes('cash') && <i className="fas fa-check mr-2"></i> }
              CASH</Button>
              { settingsPaymentWays['0']?.isActive === 'active' &&
                <Button className='mr-2 mb-3' onClick={() => handlePaymentWays('paymentWay1')} >
                { QUICK_RETAIL_SALES.newQuickRetailSales.seletedPaymentWays.includes('paymentWay1') && <i className="fas fa-check mr-2"></i> }
                {settingsPaymentWays['0']?.name}</Button>
              }

              { settingsPaymentWays['1']?.isActive === 'active' &&
                <Button className='mr-2 mb-3' onClick={() => handlePaymentWays('paymentWay2')} >
                { QUICK_RETAIL_SALES.newQuickRetailSales.seletedPaymentWays.includes('paymentWay2') && <i className="fas fa-check mr-2"></i> }
                {settingsPaymentWays['1']?.name}</Button>
              }

              { settingsPaymentWays['2']?.isActive === 'active' &&
                <Button className='mr-2 mb-3' onClick={() => handlePaymentWays('paymentWay3')} >
                { QUICK_RETAIL_SALES.newQuickRetailSales.seletedPaymentWays.includes('paymentWay3') && <i className="fas fa-check mr-2"></i> }
                {settingsPaymentWays['2']?.name}</Button>
              }
            </Col>
            <Col sm='12' md='3'>
              <Button className='mr-2 mb-3 issuebuttons' onClick={() => handleIssueCashRegister(true)}>{t('QuickRetailSalesNew.issue')}</Button>
            </Col>
          </Row>
        </Col>
        <Col sm='12' md='4'>
          <div className="search-product-box border px-3 pt-3 ">
            <p className="text-center">{t('QuickRetailSalesNew.products')}</p>
            <input
              className="form-control mb-2"
              type="text"
              placeholder="Search Product"
              name="search-product"
              autoComplete="off"
              onChange = {(e) => handleSearchkeyword(e) }
            />
            { searchProducts &&
              <div className="search-respose">
                <p className="mb-2">{t('QuickRetailSalesNew.searchResult')}</p>
                <div className="product-search-result row no-gutters">
                  { searchProducts.length > 0 && searchProducts.map((item) =>
                    {
                      return(
                        <Col md="6">
                          <div onClick = {() => insertProductToTable(item, 'search')} className="searched-product mb-3 position-relative text-center">
                            <svg className="product-img" version="1.1" width="88px" height="80px" xmlns="http://www.w3.org/2000/svg">
                              <g transform="matrix(1 0 0 1 -820 -280 )">
                                <path d="M 820 280  L 908 280  L 908 360  L 820 360  L 820 280  " fillRule="nonzero" fill="#cccccc" stroke="none" />
                                <path d="M 826 286  L 902 286  L 902 354  L 826 354  L 826 286  " fillRule="nonzero" fill="#ffffff" stroke="none" />
                                <path d="M 871.76 304  C 868.4 304  865.76 301.36  865.76 298  C 865.76 294.64  868.4 292  871.76 292  C 875.12 292  877.76 294.64  877.76 298  C 877.76 301.36  875.12 304  871.76 304  " fillRule="nonzero" fill="#cccccc" stroke="none" />
                                <path d="M 828 352  L 828 316.8  L 846 300.8  L 871.2 317.090909090909  L 885.6 310.4  L 900 317.090909090909  L 900 352  L 828 352  " fillRule="nonzero" fill="#76AEB7" stroke="none" />
                              </g>
                            </svg>
                            <div className="product-name">{item.name}</div>
                          </div>
                        </Col>
                      )
                    }
                  )}
                  { QUICK_RETAIL_SALES.productSearchKeyword !== '' && searchProducts && searchProducts.length === 0 &&
                    <div className="notfound p-2 text-primary bg-light">{t('QuickRetailSalesNew.notFound')}</div>
                  }
                </div>
              </div>
            }
            <hr className="my-2" />
            <p className="mb-2">{t('QuickRetailSalesNew.recommendedProducts')}</p>
            <div className="product-search-result default-products row no-gutters">
              { settingsDefaultProducts && settingsDefaultProducts.map((item) =>
                {
                  return(
                    <Col md="6">
                      <div onClick = {() => insertProductToTable(item)} className="searched-product mb-3 position-relative text-center">
                        <svg className="product-img" version="1.1" width="88px" height="80px" xmlns="http://www.w3.org/2000/svg">
                          <g transform="matrix(1 0 0 1 -820 -280 )">
                            <path d="M 820 280  L 908 280  L 908 360  L 820 360  L 820 280  " fillRule="nonzero" fill="#cccccc" stroke="none" />
                            <path d="M 826 286  L 902 286  L 902 354  L 826 354  L 826 286  " fillRule="nonzero" fill="#ffffff" stroke="none" />
                            <path d="M 871.76 304  C 868.4 304  865.76 301.36  865.76 298  C 865.76 294.64  868.4 292  871.76 292  C 875.12 292  877.76 294.64  877.76 298  C 877.76 301.36  875.12 304  871.76 304  " fillRule="nonzero" fill="#cccccc" stroke="none" />
                            <path d="M 828 352  L 828 316.8  L 846 300.8  L 871.2 317.090909090909  L 885.6 310.4  L 900 317.090909090909  L 900 352  L 828 352  " fillRule="nonzero" fill="#76AEB7" stroke="none" />
                          </g>
                        </svg>
                        <div className="product-name">{filterSearchProductName(item.label)}</div>
                      </div>
                    </Col>
                  )
                }
              )}
            </div>
          </div>
        </Col>
      </Row>

      <Modal className="themeModal cashRegisterModal" show={showIssuesModal} onHide={setShowIssuesModal}>
        <Modal.Header className="fw-bold justify-content-center" >
          {t('QuickRetailSalesNew.cashRegister')}
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm="7">
              <div className="fw-bold mb-3">{t('QuickRetailSalesNew.totalAmount')}{ getGreekCurrencyFormat(qrsProductListTotals?.totals) }</div>
              <p><i>{t('QuickRetailSalesNew.paymentAnalysis')}</i></p>


              { QUICK_RETAIL_SALES.newQuickRetailSales.seletedPaymentWays.includes('cash') &&
                <div className="field mb-3 d-flex align-items-center justify-content-start">
                  <span>{t('QuickRetailSalesNew.cash2.cash2')} </span>
                  <AccountingBox
                    name="cash"
                    placeholder={t('QuickRetailSalesNew.cash2.placeholder')}
                    defaultValue={cashRegisterValues?.cashAmount}
                    onChange = {(e) => handlePaymentAnalysis(e, 'ac', 'cash')}
                  />
                </div>
              }

              { QUICK_RETAIL_SALES.newQuickRetailSales.seletedPaymentWays.includes('paymentWay1') &&
                <div className="field mb-3 d-flex align-items-center justify-content-start">
                  <span>{settingsPaymentWays['0']?.name}: </span>
                  <AccountingBox
                    name="paymentway1"
                    placeholder={t('QuickRetailSalesNew.cash2.placeholder')}
                    defaultValue={cashRegisterValues?.paymentWay1Amount}
                    onChange = {(e) => handlePaymentAnalysis(e, 'ac', 'paymentway1')}
                  />
                </div>
              }

              { QUICK_RETAIL_SALES.newQuickRetailSales.seletedPaymentWays.includes('paymentWay2') &&
                <div className="field mb-3 d-flex align-items-center justify-content-start">
                  <span>{settingsPaymentWays['1']?.name}: </span>
                  <AccountingBox
                    name="paymentway2"
                    placeholder={t('QuickRetailSalesNew.cash2.placeholder')}
                    defaultValue={cashRegisterValues?.paymentWay2Amount}
                    onChange = {(e) => handlePaymentAnalysis(e, 'ac', 'paymentway2')}
                  />
                </div>
              }

              { QUICK_RETAIL_SALES.newQuickRetailSales.seletedPaymentWays.includes('paymentWay3') &&
                <div className="field mb-3 d-flex align-items-center justify-content-start">
                  <span>{settingsPaymentWays['2']?.name}: </span>
                  <AccountingBox
                    name="paymentWay3"
                    placeholder={t('QuickRetailSalesNew.cash2.placeholder')}
                    defaultValue={cashRegisterValues?.paymentWay3Amount}
                    onChange = {(e) => handlePaymentAnalysis(e, 'ac', 'paymentway3')}
                  />
                </div>
              }

              <div className="fw-bold mb-3">{t('QuickRetailSalesNew.clientPaymentTotals')} { getGreekCurrencyFormat(clientPaymentTotalsValue) }</div>
            </Col>
            <Col sm="5">
              <div className="fw-bold mb-3">{t('QuickRetailSalesNew.clientChange.clientChange')} { getGreekCurrencyFormat(clientChangeValue) }</div>
              <Button className='mr-2 mb-3 qrs-send-email' onClick={() => handleIssueRecept()} >{t('QuickRetailSalesNew.clientChange.issue')}</Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      { process.env.REACT_APP_DEBUG_MODE === 'true' && <pre>{JSON.stringify(QUICK_RETAIL_SALES.newQuickRetailSales, null, 2)}</pre> }
    </form>
  )
}

export default QuickRetailSalesNew;
