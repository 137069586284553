export const feesData = [
      {
          id: "1",
          code: "1",
          name: "Για μηνιαίο λογαριασμό μέχρι και 50 ευρώ 12%",
          tax: "12",
          myData: 1,
          taxPer: "true",
          taxAmount: "false",
          vatPercentage: 0,
          taxLevel: 1,
          vatIncluded : 3
      },
      {
          id: "2",
          code: "2",
          name: "Για μηνιαίο λογαριασμό από 50,01 μέχρι και 100 Ευρώ 15%",
          tax: "15",
          myData: 2,
          taxPer: "true",
          taxAmount: "false",
          vatPercentage: 0,
          taxLevel: 1,
          vatIncluded : 3
      },
      {
          id: "3",
          code: "3",
          name: "Για μηνιαίο λογαριασμό από 100,01 μέχρι και 150 Ευρώ 18%",
          tax: "18",
          myData: 3,
          taxPer: "true",
          taxAmount: "false",
          vatPercentage: 0,
          taxLevel: 1,
          vatIncluded : 3
      },
      {
          id: "4",
          code: "4",
          name: "Για μηνιαίο λογαριασμό από 150,01 ευρώ μέχρι και άνω 20%",
          tax: "20",
          myData: 4,
          taxPer: "true",
          taxAmount: "false",
          vatPercentage: 0,
          taxLevel: 1,
          vatIncluded : 3
      },
      {
          id: "5",
          code: "5",
          name: "Τέλος καρτοκινητής επ'ι της αξίας χρόνου ομιλίας (12%)",
          tax: "12",
          myData: 5,
          taxPer: "true",
          taxAmount: "false",
          vatPercentage: 0,
          taxLevel: 1,
          vatIncluded : 3
      },
      {
          id: "6",
          code: "6",
          name: "Τέλος στην συνδρομητική τηλεόραση 10%",
          tax: "10",
          myData: 6,
          taxPer: "true",
          taxAmount: "false",
          vatPercentage: 0,
          taxLevel: 1,
          vatIncluded : 3
      },
      {
          id: "7",
          code: "7",
          name: "Τέλος συνδρομητών σταθερής τηλεφωνίας 5%",
          tax: "5",
          myData: 7,
          taxPer: "true",
          taxAmount: "false",
          vatPercentage: 0,
          taxLevel: 1,
          vatIncluded : 3
      },
      {
          id: "8",
          code: "8",
          name: "Περιβαλλοντικό Τέλος & πλαστικής σακούλας ν.2339/2001 αρ. 6α 0,07 ευρώ ανα τεμάχιο",
          tax: "0.07",
          myData: 8,
          taxPer: "false",
          taxAmount: "true",
          vatPercentage: 24,
          taxLevel: 1,
          vatIncluded : 2
      },
      {
          id: "9",
          code: "9",
          name: "Εισφορά δακοκτονίας 2%",
          tax: "2",
          myData: 9,
          taxPer: "true",
          taxAmount: "false",
          vatPercentage: 0,
          taxLevel: 1,
          vatIncluded : 3
      },
    {
        id: "10",
        code: "10",
        name: "Λοιπά τέλη",
        tax: "0",
        myData: 10,
        taxPer: "false",
        taxAmount: "true",
        vatPercentage: 0,
        taxLevel: 1,
        vatIncluded : 3
    },
    {
        id: "11",
        code: "11",
        name: "Τέλη Λοιπών Φόρων",
        tax: "0",
        myData: 11,
        taxPer: "false",
        taxAmount: "true",
        vatPercentage: 0,
        taxLevel: 1,
        vatIncluded : 3
    },
    {
        id: "12",
        code: "12",
        name: "Εισφορά δακοκτονίας",
        tax: "0",
        myData: 12,
        taxPer: "false",
        taxAmount: "true",
        vatPercentage: 0,
        taxLevel: 1,
        vatIncluded : 3
    },
    {
        id: "13",
        code: "13",
        name: "Για μηνιαίο λογαριασμό κάθε σύνδεσης (10%)",
        tax: "10",
        myData: 13,
        taxPer: "true",
        taxAmount: "false",
        vatPercentage: 0,
        taxLevel: 1,
        vatIncluded : 3
    },
    {
        id: "14",
        code: "14",
        name: "Τέλος καρτοκινητής επί της αξίας του χρόνου ομιλίας (10%)",
        tax: "10",
        myData: 14,
        taxPer: "true",
        taxAmount: "false",
        vatPercentage: 0,
        taxLevel: 1,
        vatIncluded : 3
    },
    {
        id: "15",
        code: "15",
        name: "Τέλος κινητής και καρτοκινητής για φυσικά πρόσωπα ηλικίας 15 έως και 29 ετών (0%)",
        tax: "0",
        myData: 15,
        taxPer: "true",
        taxAmount: "false",
        vatPercentage: 0,
        taxLevel: 1,
        vatIncluded : 3
    },
    {
        id: "16",
        code: "16",
        name: "Εισφορά προστασίας περιβάλλοντος πλαστικών προϊόντων 0,04 λεπτά ανά τεμάχιο [άρθρο 4 ν. 4736/2020] ",
        tax: "0.04",
        myData: 16,
        taxPer: "false",
        taxAmount: "true",
        vatPercentage: 24,
        taxLevel: 1,
        vatIncluded : 2
    },
    {
        id: "17",
        code: "17",
        name: "Τέλος ανακύκλωσης 0,08 λεπτά ανά τεμάχιο [άρθρο 80 ν. 4819/2021]",
        tax: "0.08",
        myData: 17,
        taxPer: "false",
        taxAmount: "true",
        vatPercentage: 24,
        taxLevel: 1,
        vatIncluded : 2
    },
    {
        id: "18",
        code: "18",
        name: "Τέλος διαμονής παρεπιδημούντων",
        tax: "0",
        myData: 18,
        taxPer: "false",
        taxAmount: "true",
        vatPercentage: 0,
        taxLevel: 1,
        vatIncluded : 3
    },
    {
        id: "19",
        code: "19",
        name: "Tέλος επί των ακαθάριστων εσόδων των εστιατορίων και συναφών καταστημάτων",
        tax: "0",
        myData: 19,
        taxPer: "false",
        taxAmount: "true",
        vatPercentage: 0,
        taxLevel: 1,
        vatIncluded : 3
    },
    {
        id: "20",
        code: "20",
        name: "Τέλος επί των ακαθάριστων εσόδων των κέντρων διασκέδασης",
        tax: "0",
        myData: 20,
        taxPer: "false",
        taxAmount: "true",
        vatPercentage: 0,
        taxLevel: 1,
        vatIncluded : 3
    },
    {
        id: "21",
        code: "21",
        name: "Τέλος επί των ακαθάριστων εσόδων των καζίνο",
        tax: "0",
        myData: 21,
        taxPer: "false",
        taxAmount: "true",
        vatPercentage: 0,
        taxLevel: 1,
        vatIncluded : 3
    },
    {
        id: "22",
        code: "22",
        name: "Λοιπά τέλη επί των ακαθάριστων εσόδων",
        tax: "0",
        myData: 22,
        taxPer: "false",
        taxAmount: "true",
        vatPercentage: 0,
        taxLevel: 1,
        vatIncluded : 3
    }
];

export const feesTaxLevel = [
    {
        "no": 1,
        "label": "Σε επίπεδο παραστατικού",
        "value": 1,
        "color": "#76AEB7"
    },
    {
        "no": 2,
        "label": "Σε επίπεδο γραμμής παραστατικού",
        "value": 2,
        "color": "#76AEB7"
    }
];

export const feesVatIncluded = [
    {
        "no": 1,
        "label": "Συμπεριλαμβάνει ΦΠΑ",
        "value": 1,
        "color": "#76AEB7"
    },
    {
        "no": 2,
        "label": "Δεν συμπεριλαμβάνει ΦΠΑ",
        "value": 2,
        "color": "#76AEB7"
    },
    {
        "no": 3,
        "label": "Εξαιρείται ΦΠΑ",
        "value": 3,
        "color": "#76AEB7"
    }
];

export const feesDropdownOptions = [
    {
        "no": 1,
        "label": "Για μηνιαίο λογαριασμό μέχρι και 50 ευρώ 12%",
        "value": 1,
        "color": "#76AEB7"
    },
    {
        "no": 2,
        "label": "Για μηνιαίο λογαριασμό από 50,01 μέχρι και 100 ευρώ 15%",
        "value": 2,
        "color": "#76AEB7"
    },
    {
        "no": 3,
        "label": "Για μηνιαίο λογαριασμό από 100,01 μέχρι και 150 ευρώ 18%",
        "value": 3,
        "color": "#76AEB7"
    },
    {
        "no": 4,
        "label": "Για μηνιαίο λογαριασμό από 150,01 ευρώ και άνω 20%",
        "value": 4,
        "color": "#76AEB7"
    },
    {
        "no": 5,
        "label": "Τέλος καρτοκινητής επί της αξίας του χρόνου ομιλίας (12%)",
        "value": 5,
        "color": "#76AEB7"
    },
    {
        "no": 6,
        "label": "Τέλος στη συνδρομητική τηλεόραση 10%",
        "value": 6,
        "color": "#76AEB7"
    },
    {
        "no": 7,
        "label": "Τέλος συνδρομητών σταθερής τηλεφωνίας 5%",
        "value": 7,
        "color": "#76AEB7"
    },
    {
        "no": 8,
        "label": "Περιβαλλοντικό Τέλος & πλαστικής σακούλας ν. 2339/2001 αρ. 6α 0,07 ευρώ ανά τεμάχιο",
        "value": 8,
        "color": "#76AEB7"
    },
    {
        "no": 9,
        "label": "Εισφορά δακοκτονίας 2%",
        "value": 9,
        "color": "#76AEB7"
    },
    {
        "no": 10,
        "label": "Λοιπά τέλη",
        "value": 10,
        "color": "#76AEB7"
    },
    {
        "no": 11,
        "label": "Τέλη Λοιπών Φόρων",
        "value": 11,
        "color": "#76AEB7"
    },
    {
        "no": 12,
        "label": "Εισφορά δακοκτονίας",
        "value": 12,
        "color": "#76AEB7"
    },
    {
        "no": 13,
        "label": "Για μηνιαίο λογαριασμό κάθε σύνδεσης (10%)",
        "value": 13,
        "color": "#76AEB7"
    },
    {
        "no": 14,
        "label": "Τέλος καρτοκινητής επί της αξίας του χρόνου ομιλίας (10%)",
        "value": 14,
        "color": "#76AEB7"
    },
    {
        "no": 15,
        "label": "Τέλος κινητής και καρτοκινητής για φυσικά πρόσωπα ηλικίας 15 έως και 29 ετών (0%)",
        "value": 15,
        "color": "#76AEB7"
    },
    {
        "no": 16,
        "label": "Εισφορά προστασίας περιβάλλοντος πλαστικών προϊόντων 0,04 λεπτά ανά τεμάχιο [άρθρο 4 ν. 4736/2020] ",
        "value": 16,
        "color": "#76AEB7"
    },
    {
        "no": 17,
        "label": "Τέλος ανακύκλωσης 0,08 λεπτά ανά τεμάχιο [άρθρο 80 ν. 4819/2021]",
        "value": 17,
        "color": "#76AEB7"
    },
    {
        "no": 18,
        "label": "Τέλος διαμονής παρεπιδημούντων",
        "value": 18,
        "color": "#76AEB7"
    },
    {
        "no": 19,
        "label": "Tέλος επί των ακαθάριστων εσόδων των εστιατορίων και συναφών καταστημάτων",
        "value": 19,
        "color": "#76AEB7"
    },
    {
        "no": 20,
        "label": "Τέλος επί των ακαθάριστων εσόδων των κέντρων διασκέδασης",
        "value": 20,
        "color": "#76AEB7"
    },
    {
        "no": 21,
        "label": "Τέλος επί των ακαθάριστων εσόδων των καζίνο",
        "value": 21,
        "color": "#76AEB7"
    },
    {
        "no": 22,
        "label": "Λοιπά τέλη επί των ακαθάριστων εσόδων",
        "value": 22,
        "color": "#76AEB7"
    },
];
