import React, {useState, useEffect, useMemo} from "react";
import { Form, Row, Col, Button, Modal } from "react-bootstrap";
import Dropdown from "../../../primer/Dropdown";
import Input from "../../../common/Input";
import DateBox from "../../../primer/DateBox";
import {
  fetchAdvancedTypesSales,
  fetchBankAccounts,
  fetchClientPaymentData,
  fetchPOS,
  fetchSalesGeneralSettings,
  postClient,
  putClient,
} from "../../../../_apis/api";
import EditableTable from "../../../primer/Editable-Table";
import { useSelector, useDispatch } from "react-redux";
import {
  getDefaultOptionByValue,
  getOptionsByData,
  getSelectedPreNumbers,
  getPreNumberByInstallation,
  getNumberByInstallation,
  getModeByInstallation,
  getVatCategoryOptions,
  getSelectedInstallation,
  salesMyDataStatus,
  filterNaNValue,
  getCurrentUser,
  removeDuplicateObjects,
  getDOYOptions,
  getInstallationsTypes,
  isAlreadyExists,
  getGreekCurrencyFormat, checkPermission,
} from "../../../../_helpers/commonFunctions";
import { setSaveEditSales } from "../../../../_reducers/activitySlice";
import {
  deleteObjectFromArrayOfObjects,
  findObjectFromArrayOfObjects,
  getLabelByValue,
  getObjectFromArrayOfObjects
} from "../../../../_helpers/helperFunctions";
import {
  setEditSalesExtraData,
  setEditSalesNewProduct,
  setEditSales
} from "../../../../_reducers/DataPanel/Sales/salesSlice";
import TextArea from "../../../common/TextArea";
import { preNumberGreek } from "../../../../_data/settings/advanced-types-search";
import { toast } from "react-toastify";
import getMyData from "../../../../_helpers/sharedmydata";
import axios from "axios";
import AdvancedCombobox from "../../../primer/AdvancedCombobox";
import {useTranslation} from "react-i18next";
import {
  getDiscountExclusiveVat, getNetValue, getNetValueByTotalValue,
  getPriceExclusiveVat, getProductFee, getProductOtherTax, getProductStamp, getProductWithholdTax,
  getSalesTaxesData,
  getSalesTotals, getTaxes, getTotalValue, getUnitPrice,
  getVatPercent, getVatValue,
  parseNumber, salesDuplicateProductCheck
} from "../../../../_helpers/product-sales-calculations";
import {vatCategoryData} from "../../../../_data/mydata/vatCategory";
import TimePickerInput from "../../../common/TimePickerInput";
import QuickEdit from "../../../primer/QuickEdit";
import NewClientModal from "../Clients/new-client-modal";
const apiURL2 = process.env.REACT_APP_API_URL2;

const EditSales = () => {

  const [t] = useTranslation('common');
  let date = new Date();
  const currentTime = date.getHours() + ':' + String(date.getMinutes()).padStart(2, '0');
  const dispatch = useDispatch();
  const ACTIVITY = useSelector((state) => state.ACTIVITY);
  const PRODUCTS_DATA = useSelector((state) => state.PRODUCTS_DATA);
  const SALES = useSelector((state) => state.SALES);
  const company = useSelector((state) => state.COMPANY_DATA.company);
  const CLIENTS_DATA = useSelector((state) => state.CLIENTS_DATA);
  const SETTINGS = useSelector((state) => state.SETTINGS);
  const SALES_SETTINGS = useSelector((state) => state.SALES_SETTINGS);
  const CASH_DATA = useSelector((state) => state.CASH_DATA);
  const requestData = {
    company: company.id,
    year: company.year.toString(),
  };

  const currentUser = getCurrentUser();

  const requestData2 = {
    company: company.id,
    year: company.year.toString(),
    isActive: "active",
    userId: currentUser.id
  }

  const productCodeColumns = [
    {
      label: t('SalesNewEdit.tableProduct.code'),
      name: "code",
    },
    {
      label: t('SalesNewEdit.tableProduct.name'),
      name: "name",
    },
    {
      label: t('SalesNewEdit.tableProduct.alternateName'),
      name: "alternateName",
    },
  ];

  const clientDropdownColumns = [
    {
      label: t('SalesNewEdit.tableClient.code'),
      name: "systemCode",
    },
    {
      label: t('SalesNewEdit.tableClient.name'),
      name: "name",
    },
    {
      label: t('SalesNewEdit.tableClient.phone'),
      name: "phone",
    },
    {
      label: t('SalesNewEdit.tableClient.email'),
      name: "email",
    },
    {
      label: t('SalesNewEdit.tableClient.vatNumber'),
      name: "vatNumber",
    }
  ];
  const clientInstallationsDropdownColumns = [
    {
      label: t('SalesNewEdit.tableClientInstallations.type'),
      name: "type",
    },
    {
      label: t('SalesNewEdit.tableClientInstallations.address'),
      name: "address",
    }
  ];
  const corelatedDocumentColumns = [
    {
      label: t('SalesNewEdit.table.clientName'),
      name: "clientName",
    },
    {
      label: t('SalesNewEdit.table.clientVat'),
      name: "clientVat",
    },
    {
      label: t('SalesNewEdit.table.documentType'),
      name: "documentTypeName",
    },
    {
      label: t('SalesNewEdit.table.date'),
      name: "date",
    },
    {
      label: t('SalesNewEdit.table.preNumber'),
      name: "preNumber",
    },
    {
      label: t('SalesNewEdit.table.number'),
      name: "number",
    },
    {
      label: t('SalesNewEdit.table.totalAmount'),
      name: "totalAmount",
    },
  ];

  const [refreshPage, setRefreshPage] = useState(0);
  const [showCorelatedDocument, setShowCorelatedDocument] = useState(false);
  const [isCorelatedDocumentMandatory, setIsCorelatedDocumentMandatory] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [clientVatCategory, setClientVatCategory] = useState('Normal VAT');
  const [clientCustomVat, setClientCustomVat] = useState(0);
  const [showCateogryConfirmation, setShowCateogryConfirmation] = useState(false);
  const [tempCatValue, setTempCatValue] = useState({});
  const [readOnlySalesTime, setReadOnlySalesTime] = useState(false);
  const [readOnlySalesDate, setReadOnlySalesDate] = useState(false);
  const [allowOlderDate, setAllowOlderDate] = useState(false);
  const [showProductTableSpinner, setProductTableSpinner] = useState('false');
  const [salesProductList, setSalesProductList] = useState(PRODUCTS_DATA.products.slice());
  const [clientsListData, setClientsListData] = useState(CLIENTS_DATA.clients.slice());
  const [clientInstallationListData, setClientInstallationListData] = useState(CLIENTS_DATA.installations.slice());
  const [corelatedDocumentsList, setCorelatedDocumentsList] = useState([]);
  const [pRequestData, setPRequestData] = useState({});
  const permissions = localStorage.getItem("permissions");
  const permissionsData = JSON.parse(permissions);
  // Fetch Corelated Documents List
  const fetchCorelatedDocumentsList = async () => {
    const salesDid = {
      "salesDocumentId": "624ecb2b2c9d02ef2da880d5"
    }
    await axios.post(apiURL2 + `/sale/corelated-document-list/`, JSON.stringify(salesDid), { headers: { 'Content-Type': 'application/json'}})
        .then((res) => {
          if (res.data && res.data.status === "200") {
            if (res.data.data && res.data.data.length > 0) {
              setCorelatedDocumentsList(res.data.data);
            }
          }
        })
  };

  useEffect(() => {
    dispatch(fetchAdvancedTypesSales(requestData));
    dispatch(fetchSalesGeneralSettings(requestData2));
    dispatch(fetchClientPaymentData(requestData));
    dispatch(fetchPOS(requestData2));
    dispatch(fetchBankAccounts(requestData2));
  }, [dispatch]);

  useEffect(() => {
    // Load Product based on selected advanced type if my data rule applies
    if (SALES.editSales.documentType !== "") {
      loadClientsListData(SALES.editSales.documentType)
      let dtData = getObjectFromArrayOfObjects(SETTINGS.settings.advancedTypesSales, SALES.editSales.documentType, '_id');
      if (dtData.length > 0) {
        let currentAdvancedType = dtData['0'];
        // Load Corelated document on page render
        loadCorelatedFieldSettings(currentAdvancedType);

        if (currentAdvancedType.enableMyDataRules && currentAdvancedType.enableMyDataRules === 'active') {
          loadProductListData(SALES.editSales.documentType);
          loadClientsListData(SALES.editSales.documentType);
          let isEnabledMyDataRule = currentAdvancedType.enableMyDataRules === 'active';
          console.log(currentAdvancedType)
          setReadOnlySalesTime(isEnabledMyDataRule);
          setReadOnlySalesDate(isEnabledMyDataRule);
          let isAllowOlderDate = currentAdvancedType.enableMyDataRules === 'active' && currentAdvancedType.allowOlderDate === 'active';
          setAllowOlderDate(isAllowOlderDate);
          console.log(allowOlderDate)
        }
      }
      if(SALES.editSales.installation !== undefined && SALES.editSales.installation !== ''){
        let newPreNumbers = getSelectedPreNumbers(selectedDocumentTypeData['documentNumbering'], preNumberGreek, SALES.editSales.installation);
        setPreNumberGreekOptions(newPreNumbers);
      }
    }
  },[])
  // Options for Document Type Dropdown
  let pMDDSettings = SETTINGS.primerMyDataData;
  const documentTypesOptions = useMemo(() => getOptionsByData(SETTINGS.settings.advancedTypesSales, "_id", "name"), [SETTINGS.settings.advancedTypesSales]);
  const waysOfSendingGoodsOptions = useMemo(() => getOptionsByData(SALES_SETTINGS.salesGeneralSettings.sendingGoods, "_id", "name"), [SALES_SETTINGS.salesGeneralSettings.sendingGoods]);
  const carNumbersOptions = useMemo(() => getOptionsByData(SALES_SETTINGS.salesGeneralSettings.carNumbers, "_id", "number"), [SALES_SETTINGS.salesGeneralSettings.carNumbers]);
  const paymentTypeOptions = useMemo(() => getOptionsByData(SALES_SETTINGS.salesGeneralSettings.paymentTypes, "_id", "name"), [SALES_SETTINGS.salesGeneralSettings.paymentTypes]);
  let otherTaxesOptions = useMemo(() => getOptionsByData(pMDDSettings?.otherTaxes !== undefined && pMDDSettings?.otherTaxes !== '' ? pMDDSettings.otherTaxes.filter(otherTax => otherTax.taxPer === "true" && otherTax.taxLevel === 1) : SETTINGS.otherHoldTaxes, 'code', 'name'), [pMDDSettings, SETTINGS.otherHoldTaxes]);
  let withHoldTaxesOptions = useMemo(() => getOptionsByData(pMDDSettings?.withHoldTaxes !== undefined && pMDDSettings?.withHoldTaxes !== '' ? pMDDSettings.withHoldTaxes.filter(withHoldTax => withHoldTax.taxPer === "true" && withHoldTax.taxLevel === 1) : SETTINGS.withHoldTaxes, 'code', 'name'), [pMDDSettings, SETTINGS.withHoldTaxes]);
  let stampOptions = useMemo(() => getOptionsByData(pMDDSettings?.stamp !== undefined && pMDDSettings?.stamp !== '' ? pMDDSettings.stamp.filter(stamp => stamp.taxPer === "true" && stamp.taxLevel === 1) : SETTINGS.stamp, 'code', 'name'), [pMDDSettings, SETTINGS.stamp]);
  let feesOptions = useMemo(() => getOptionsByData(pMDDSettings?.fees !== undefined && pMDDSettings?.fees !== '' ? pMDDSettings.fees.filter(fee => fee.taxPer === "true" && fee.taxLevel === 1) : SETTINGS.fees, 'code', 'name'), [pMDDSettings, SETTINGS.fees]);
  const salesPurposeOptions = getMyData({type: 'salePurpose', settings: SETTINGS});
  const measurementUnitOptions     = getMyData({type: 'mu', settings: SETTINGS});
  const salesMyDataStatusOptions = salesMyDataStatus();
  const [preNumberGreekOptions, setPreNumberGreekOptions] = useState([]);
  let selectedDocumentTypeData = [];
  let dtInstallationsOptions = [];
  let disabledFields = false;
  if (SALES.editSales.documentType === undefined || SALES.editSales.documentType === '') {
    disabledFields = true;
  } else {
    let dtData = getObjectFromArrayOfObjects(SETTINGS.settings.advancedTypesSales, SALES.editSales.documentType, '_id');
    if (dtData.length > 0) {
      selectedDocumentTypeData = dtData['0'];
      dtInstallationsOptions = getOptionsByData(selectedDocumentTypeData['documentNumbering'], "installationMasterId", "installationType");
      dtInstallationsOptions = removeDuplicateObjects(dtInstallationsOptions, 'value');
    }
  }

  const clientProductsColumns = [
    {
      name: "No",
      field: "no",
      width: "40px",
      editable: false,
      inputType: {
        type: 'text',
      }
    },
    {
      name: t('SalesNewEdit.tableClientProducts.productCode.productCode'),
      field: "code",
      width: "150px",
      editable: true,
      inputType: {
        config: {
          excludeColumns: [],
          columns: productCodeColumns,
          data: salesProductList,
          editEnable: false,
          deleteEnable: false,
          searchTableEnable: false,
          showTableOptionOnSelect: true,
          placeholder: t('SalesNewEdit.tableClientProducts.productCode.placeholder')
        },
        type: 'advanced-combobox',
      }
    },
    {
      name: t('SalesNewEdit.tableClientProducts.productName.productName'),
      field: "name",
      width: "150px",
      editable: true,
      inputType: {
        config: {
          excludeColumns: [],
          columns: productCodeColumns,
          data: salesProductList,
          editEnable: false,
          deleteEnable: false,
          searchTableEnable: false,
          showTableOptionOnSelect: true,
          placeholder: t('SalesNewEdit.tableClientProducts.productName.placeholder')
        },
        type: 'advanced-combobox',
      }
    },
    {
      name: t('SalesNewEdit.tableClientProducts.quantity'),
      field: "quantity",
      width: "30px",
      editable: SALES_SETTINGS.salesGeneralSettings?.forbitChange !== "active",
      inputType: {
        type: 'accounting',
        config: {
          maxLength: 10 // Given in the wireframe
        }
      }
    },
    {
      name: t('SalesNewEdit.tableClientProducts.mu'),
      field: "muLabel",
      editable: false,
      width: "80px",
      inputType: {
        type: 'text',
      }
    },
    {
      name: t('SalesNewEdit.tableClientProducts.unitPrice'),
      field: "unitPrice",
      width: "120px",
      editable: SALES_SETTINGS.salesGeneralSettings?.forbitChange !== "active",
      inputType: {
        type: 'accounting',
        config: {
          focused: true,
          suffix: "€",
          // maxLength: 5
          maxLength: 10 // Given in the wireframe
        }
      }
    },
    {
      name: t('SalesNewEdit.tableClientProducts.discount'),
      field: "discount",
      editable: SALES_SETTINGS.salesGeneralSettings?.forbitChange !== "active",
      width: "100px",
      inputType: {
        type: 'accounting',
        config: {
          focused: true,
          suffix: "€",
          maxLength: 10
        }
      }
    },
    {
      name: "%",
      field: "isPerDiscount",
      editable: SALES_SETTINGS.salesGeneralSettings?.forbitChange !== "active",
      width: "40px",
      inputType: {
        type: 'radio',
        name: 'discountType',
        radioLogic: 'oneperrow',
        options: [{
          value: true,
          label: 'True'
        }, {
          value: false,
          label: 'False'
        }]
      }
    },
    {
      name: "€",
      field: "isDiscountAmount",
      editable: SALES_SETTINGS.salesGeneralSettings?.forbitChange !== "active",
      width: "40px",
      inputType: {
        type: 'radio',
        radioLogic: 'oneperrow',
        name: 'discountType',
        options: [{
          value: true,
          label: 'True'
        }, {
          value: false,
          label: 'False'
        }]
      }
    },
    {
      name: t('SalesNewEdit.tableClientProducts.vat%'),
      field: "vatPercentage",
      width: "80px",
      inputType: {
        type: 'text',
      }
    },
    {
      name: t('SalesNewEdit.tableClientProducts.netValue'),
      field: "netValue",
      width: "100px",
      editable: SALES_SETTINGS.salesGeneralSettings?.forbitChange !== "active",
      inputType: {
        type: 'accounting',
        config: {
          focused: true,
          suffix: "€",
          maxLength: 10
        }
      }
    },
    {
      name: t('SalesNewEdit.tableClientProducts.taxes'),
      field: "taxes",
      width: "100px",
      editable: false,
      inputType: {
        type: 'accounting',
        config: {
          focused: true,
          suffix: "€",
          maxLength: 10
        }
      }
    },
    {
      name: t('SalesNewEdit.tableClientProducts.totalValue'),
      field: "totalValue",
      editable: true,
      width: "100px",
      inputType: {
        type: 'accounting',
        config: {
          focused: true,
          suffix: "€",
          maxLength: 10
        }
      }
    },
  ]

  const handleConfirmCategory = async () => {
    // Populate Selected Category Data
    let newSalesData = {};
    let value = tempCatValue.new;
    newSalesData.documentType = value;
    newSalesData.documentTypeName = getLabelByValue(documentTypesOptions, value, 'value');
    const selectedDocumentTypeData = getObjectFromArrayOfObjects(SETTINGS.settings.advancedTypesSales, value, "_id");
    if (selectedDocumentTypeData.length > 0) {
      let selectedDTData = selectedDocumentTypeData['0'];
      console.log(selectedDTData)
      newSalesData.myDataType = selectedDTData.myDataType.toString();
      loadCorelatedFieldSettings(selectedDTData);
      let selectedInstallation = getSelectedInstallation(selectedDTData['documentNumbering']);
      // This applies only if selected check box is tick in the table.
      console.log(selectedInstallation)
      if (Object.keys(selectedInstallation).length > 0) {
        newSalesData.installation = selectedInstallation.installationMasterId;
        newSalesData.preNumber = selectedInstallation.preNumber;
        newSalesData.installationMode = selectedInstallation.numberingMode;
        newSalesData.numberingMode = selectedInstallation.numberingMode;
        newSalesData.number = selectedInstallation.number + 1;
      } else {
        newSalesData.installation = "";
        newSalesData.preNumber = "";
        newSalesData.number = "";
        newSalesData.installationMode = "";
        newSalesData.numberingMode = "";
      }
      // This will pre number given in document number table
      let newPreNumbers = getSelectedPreNumbers(selectedDTData['documentNumbering'], preNumberGreek, selectedInstallation.installationMasterId);
      setPreNumberGreekOptions(newPreNumbers)

      // Preposed Sales Purpose from the advanced types page
      newSalesData.salePurpose = selectedDTData.proposedSalePurpose;

      // Load Product based on selected advanced type if my data rule applies
      if (selectedDTData.enableMyDataRules && selectedDTData.enableMyDataRules === 'active') {
        loadProductListData(value);
        loadClientsListData(value);
        let isEnabledMyDataRule = selectedDTData.enableMyDataRules === 'active';
        console.log(selectedDTData)
        setReadOnlySalesTime(isEnabledMyDataRule);
        setReadOnlySalesDate(isEnabledMyDataRule);
        let isAllowOlderDate = selectedDTData.enableMyDataRules === 'active' && selectedDTData.allowOlderDate === 'active';
        setAllowOlderDate(isAllowOlderDate);
        console.log(allowOlderDate)
      }

      // Clear Product List on change
      newSalesData.products = [];

      // This applies on Date and Time fields
      let isEnabledMyDataRule = selectedDTData.enableMyDataRules === 'active';
      console.log(selectedDTData)
      setReadOnlySalesTime(isEnabledMyDataRule);
      setReadOnlySalesDate(isEnabledMyDataRule);
      let isAllowOlderDate = selectedDTData.enableMyDataRules === 'active' && selectedDTData.allowOlderDate === 'active';
      setAllowOlderDate(isAllowOlderDate);
      console.log(allowOlderDate)

    }

    dispatch(setEditSales({ ...SALES.editSales, ...newSalesData }));
    setShowCateogryConfirmation(false);
  }

  const loadClientsListDataOnChange = (value, setData) => {
    let data = {
      id: SALES.editSales.documentType,
      name: value,
      company : company.id
    }
    axios.get(process.env.REACT_APP_API_URL2 + `/client/client-list-by-advanced-type-id/${data.id}`,{params :data})
        .then(res => {
          if (res.data && res.data.status === '200') {
            if (res.data.data) {
              // Remove none empty client name data
              let filteredData = deleteObjectFromArrayOfObjects(res.data.data, '', 'name');
              setData(filteredData);
            } else {
              setData([]);
            }
          } else {
            setData([]);
          }
        })
  }
  const onChangeSearch = (keyword,setData) => {
    if(keyword.length >= 2) {
      loadClientsListDataOnChange(keyword,setData)
    }
  }

  const loadClientsListData = (value) => {
    const data = {
      id: value,
      company : company.id,
      name:''
    }
    axios.get(process.env.REACT_APP_API_URL2 + `/client/client-list-by-advanced-type-id/${value}`,{params :data})
        .then(res => {
          if (res.data && res.data.status === '200') {
            if (res.data.data) {
              // Remove none empty client name data
              let filteredData = deleteObjectFromArrayOfObjects(res.data.data, '', 'name');
              setClientsListData([...filteredData]);
            } else {
              setClientsListData([]);
            }
          } else {
            setClientsListData([]);
          }
        })
  }

  const loadProductListData = (value) => {
    let data = {
      id: value,
      name: '',
      company : company.id
    }
    setPRequestData({...data})
    axios.get(process.env.REACT_APP_API_URL2 + `/sale/product-list-by-advanced-type-id/${data.id}`,{params :data})
        .then(res => {
          if (res.data && res.data.status === '200') {
            if (res.data.data) {
              setSalesProductList(res.data.data);
            } else {
              setSalesProductList([]);
            }
          } else {
            setSalesProductList([]);
          }
        })
  }

  const loadCorelatedFieldSettings = (selectedDTData) => {
    if (selectedDTData.myDataType && selectedDTData.myDataType !== '') {
      // Show field only if "Document type" supports "MyData Document Type"
      // Is settings-Document Types-Advanced types that is 1.6, 2.4, 5.1
      if (
          selectedDTData.myDataType.toString() === '1.6' ||
          selectedDTData.myDataType.toString() === '2.4' ||
          selectedDTData.myDataType.toString() === '5.1'
      ) {
        fetchCorelatedDocumentsList();
        setShowCorelatedDocument(true);
      } else {
        setShowCorelatedDocument(false);
      }

      // If Advanced type is 5.1, field is mandatory, if not, field is not mandatory.
      if (selectedDTData.myDataType.toString() === '5.1') {
        setIsCorelatedDocumentMandatory(true);
      } else {
        setIsCorelatedDocumentMandatory(false);
      }
    }
  }

  const getCorelatedDocumentName = (selectedCDoc) => {
    let dName = `${selectedCDoc.documentTypeName}`;
    if (selectedCDoc.preNumber && selectedCDoc.preNumber !== 'noprefix') {
      dName += ` | ${selectedCDoc.preNumber}`
    }
    if (selectedCDoc.number) {
      dName += ` | ${selectedCDoc.number}`;
    }
    return dName;
  }

  const handleOnChange = async (e, type = "default", ddname = '') => {

    // Set if editing the form
    if (!ACTIVITY.saveSales) {
      dispatch(setSaveEditSales(true));
    }

    let name;
    let value = '';

    if (type === 'dd' || type === 'ac') {
      value = e.value;
      name = ddname;
    } else if (type === 'dp') {
      value = e;
      name = ddname;
    } else if (type === 'acdd') {
      e = e.row;
      value = e._id;
      name = ddname;
    } else if (type === 'tp') {
      value = e.target.value;
      name = ddname;
    } else {
      value = e.target.value;
      name = e.target.name;
    }

    let salesData = Object.assign({}, SALES);
    let newSalesData = {};

    if (name === 'documentType' && salesData.editSales.documentType !== value) {
      if (salesData.editSales.documentType !== "") {
        // Show confirmation before category change.
        setShowCateogryConfirmation(true);
        setTempCatValue({ e: e, new: value, old: salesData.editSales.documentType });
      } else {
        // Populate Selected Category Data
        newSalesData[name] = value;
        newSalesData.documentTypeName = getLabelByValue(documentTypesOptions, value, 'value');
        const selectedDocumentTypeData = getObjectFromArrayOfObjects(SETTINGS.settings.advancedTypesSales, value, "_id");
        if (selectedDocumentTypeData.length > 0) {
          let selectedDTData = selectedDocumentTypeData['0'];
          console.log(selectedDTData)
          newSalesData.myDataType = selectedDTData.myDataType.toString();
          loadCorelatedFieldSettings(selectedDTData);
          let selectedInstallation = getSelectedInstallation(selectedDTData['documentNumbering']);
          if (Object.keys(selectedInstallation).length > 0) {
            newSalesData.installation = selectedInstallation.installationMasterId;
            newSalesData.preNumber = selectedInstallation.preNumber;
            newSalesData.installationMode = selectedInstallation.numberingMode;
            newSalesData.numberingMode = selectedInstallation.numberingMode;
            newSalesData.number = selectedInstallation.number + 1;
          } else {
            newSalesData.installation = "";
            newSalesData.preNumber = "";
            newSalesData.number = "";
            newSalesData.installationMode = "";
            newSalesData.numberingMode = "";
          }

          // This will pre number given in document number table
          let newPreNumbers = getSelectedPreNumbers(selectedDTData['documentNumbering'], preNumberGreek, newSalesData.installation);
          setPreNumberGreekOptions(newPreNumbers);
          // Preposed Sales Purpose from the advanced types page
          newSalesData.salePurpose = selectedDTData.proposedSalePurpose;

          // Load Product based on selected advanced type if my data rule applies
          if (selectedDTData.enableMyDataRules && selectedDTData.enableMyDataRules === 'active') {
            loadProductListData(value);
            loadClientsListData(value);
            let isEnabledMyDataRule = selectedDTData.enableMyDataRules === 'active';
            console.log(selectedDTData)
            setReadOnlySalesTime(isEnabledMyDataRule);
            setReadOnlySalesDate(isEnabledMyDataRule);
            let isAllowOlderDate = selectedDTData.enableMyDataRules === 'active' && selectedDTData.allowOlderDate === 'active';
            setAllowOlderDate(isAllowOlderDate);
          }

        }
      }
    } else {
      newSalesData[name] = value;
    }
  //  newSalesData['date'] = new Date(SALES.editSales.date);
  //  newSalesData['time'] = currentTime;
    if (name === 'client') {
      // populating client values on the sales form
      // Same code is used with quick edit client to repopulate the data after change. Starts from line number 933.
      let selectedClient = getObjectFromArrayOfObjects(clientsListData, value, '_id');
      // Get Client Data from the API.
      await axios.get(apiURL2 + `/client/get-client-id-by/${value}`)
          .then((res) => {
            if (res.data && res.data.status === "200") {
              selectedClient['0'] = res.data.data;
            }
          })
      if (selectedClient.length > 0) {
        let sClient = selectedClient['0'];
        newSalesData.clientName = sClient.name;
        newSalesData.clientCode = sClient.systemCode; // It is the client code
        newSalesData.address = sClient.address;
        newSalesData.vat = sClient.vatNumber;
        newSalesData.doy = getLabelByValue(getDOYOptions(), sClient.DOY, 'value');
        newSalesData.city = sClient.city;
        newSalesData.country = sClient.country;
        newSalesData.pc = sClient.postalCode;
        newSalesData.email = sClient.email;
        newSalesData.phone = sClient.phone;
        newSalesData.activity = sClient.activity;
        newSalesData.vatCategory = sClient.vatCategory;
        sClient.installations.map(installation => {
          installation.type = getLabelByValue(getInstallationsTypes(), installation.type, 'value');
          return installation;
        })
        setClientInstallationListData(sClient.installations);
        newSalesData.clientInstallationType = sClient.installations[0]?.type;
        newSalesData.clientInstallationId = sClient.installations[0]?.no;
        // Set Client Vat Category used for product vat calculation
        setClientVatCategory(sClient?.vatCategory);
        setClientCustomVat(sClient?.customVat);
      }
    }

    if(name === 'clientInstallation'){
      let selectedClientInstallation = "";
      clientInstallationListData.map(installation => {
        if(value === installation._id){
          selectedClientInstallation = installation;
        }
        return installation;
      })
      if(selectedClientInstallation !== ""){
        newSalesData.clientInstallationType = selectedClientInstallation.type;
        newSalesData.clientInstallationId = selectedClientInstallation.no;
        newSalesData.address = selectedClientInstallation.address;
        newSalesData.doy = getLabelByValue(getDOYOptions(), selectedClientInstallation.DOY, 'value');
        newSalesData.city = selectedClientInstallation.city;
        newSalesData.pc = selectedClientInstallation.postalCode;
      }
    }

    if (name === 'paymentType') {
      newSalesData.paymentTypeName = getLabelByValue(paymentTypeOptions, value, 'value');

      // This is will update the pre selected POS / Bank
      let paymentTypesData = getObjectFromArrayOfObjects(SALES_SETTINGS.salesGeneralSettings.paymentTypes, value, '_id');
      if (paymentTypesData && paymentTypesData.length > 0) {
        let selectedPaymentType = paymentTypesData['0'];
        newSalesData.myDataPaymentId = selectedPaymentType?.myDataPaymentId;
        if (selectedPaymentType?.type === "pos") {
          newSalesData.bankAccount = selectedPaymentType.options?.preSelectedPosBankAccount;
        }

        if (selectedPaymentType?.type === "bank-account") {
          newSalesData.bankAccount = selectedPaymentType.options?.preSelectedPosBankAccount;
        }

        if (selectedPaymentType?.type === "cash") {
          newSalesData.bankAccount = "";
        }
      }
    }

    // Get Corelated Document Name
    if (name === 'corelatedDocument') {
      let selectedCDoc = findObjectFromArrayOfObjects(corelatedDocumentsList, value, '_id');
      newSalesData.corelatedDocumentName = getCorelatedDocumentName(selectedCDoc)
    }

    // Bank Account name
    if (name === 'bankAccount') {
      newSalesData.bankAccountName = getLabelByValue(bankOptions, value, 'value')
    }
    // Update Installtion Field Values on Change
    if (name === 'installation') {
      newSalesData.installationName = getLabelByValue(dtInstallationsOptions, value, 'value');
      newSalesData.preNumber = getPreNumberByInstallation(selectedDocumentTypeData['documentNumbering'], value, 'installationMasterId');
      newSalesData.installationMode = getModeByInstallation(selectedDocumentTypeData['documentNumbering'], value, 'installationMasterId');
      newSalesData.numberingMode = getModeByInstallation(selectedDocumentTypeData['documentNumbering'], value, 'installationMasterId');
      newSalesData.number = getNumberByInstallation(selectedDocumentTypeData['documentNumbering'], value, 'installationMasterId') + 1;
      // This will pre number given in document number table
      let newPreNumbers = getSelectedPreNumbers(selectedDocumentTypeData['documentNumbering'], preNumberGreek, value);
      setPreNumberGreekOptions(newPreNumbers);
    }
    if(name === "date" && (readOnlySalesDate === false || (readOnlySalesDate === true  && allowOlderDate === true))){
      if(readOnlySalesDate === true  && allowOlderDate === true){
        let allowedDateHours = checkPermission("custom-logistirio", permissionsData) === false ? pMDDSettings.otherData.filter(otherData => otherData.name === "allowOlderDate")[0].value : "999999";
        let allowedDate = new Date();
        allowedDate.setHours(allowedDate.getHours() - Number(allowedDateHours));
        console.log(allowedDate)
        if(allowedDate > new Date(value)){
          toast.error("You are not allowed");
          newSalesData['date'] = new Date();
        } else {
          newSalesData['date'] = new Date(value);
        }
      } else {
        newSalesData['date'] = new Date(value);
      }
    }
    // It will update the last available number from the API.
    if ((name === 'documentType' || name === 'preNumber' || name === 'installation') &&
        (
            (salesData.editSales.installationMode === 'automatic' || newSalesData.installationMode === 'automatic') &&
            (salesData.editSales.documentType !== '' || newSalesData.documentType !== '') &&
            (salesData.editSales.preNumber !== '' || newSalesData.preNumber !== '') &&
            (salesData.editSales.installation !== '' || newSalesData.installation !== '')
        )) {
      let checkAtParams = {};
      checkAtParams.id = !newSalesData.documentType ? salesData.editSales.documentType : newSalesData.documentType;
      checkAtParams.preNumber = !newSalesData.preNumber ? salesData.editSales.preNumber : newSalesData.preNumber;
      checkAtParams.installation = !newSalesData.installation ? salesData.editSales?.installation?.toString() : newSalesData?.installation?.toString();

      if (checkAtParams.id && checkAtParams.preNumber && checkAtParams.installation) {
        await axios.post(apiURL2 + `/settings/add-setting-advance-type-check-sale`, JSON.stringify(checkAtParams), { headers: { 'Content-Type': 'application/json' } })
            .then(res => {
              if (res.data && res.data.status === '200') {
                if (res.data.number !== undefined) {
                  newSalesData.number = parseInt(res.data.number) + 1;
                }
              }
              dispatch(setEditSales({ ...salesData.editSales, ...newSalesData }));
            })
      }
    } else {
      dispatch(setEditSales({ ...salesData.editSales, ...newSalesData }));
    }
  }

  const handleOnChangeExtraData = (e, type = "default", ddname = '') => {
    // Set if editing the form
    if (!ACTIVITY.saveSales) {
      dispatch(setSaveEditSales(true));
    }
    let name;
    let value;

    if (type === 'dd' || type === 'ac') {
      value = e.value;
      name = ddname;
    } else if (type === 'dp') {
      value = e;
      name = ddname;
    } else if (type === 'tp') {
      value = e;
      name = ddname;
    } else {
      value = e.target.value;
      name = e.target.name;
    }

    let extraData = {};
    extraData[name] = value;

    if (name === 'wayOfSendingGoods') {
      console.log(waysOfSendingGoodsOptions)
      extraData.wayOfSendingGoodsName = getLabelByValue(waysOfSendingGoodsOptions, value, 'value');
    }

    if (name === 'carNumber') {
      extraData.carNumberName = getLabelByValue(carNumbersOptions, value, 'value');
    }
    if (name === 'otherTax') {
      extraData.otherTax = value;
    }
    if (name === 'withHoldTax') {
      extraData.withHoldTax = value;
    }
    if (name === 'fee') {
      extraData.fee = value;
    }
    if (name === 'stamp') {
      extraData.stamp = value;
    }
    let productTotals = getSalesTotals(SALES.editSales.products, SETTINGS);
    let productSalesTaxes = getSalesTaxesData(SALES.editSales.products,extraData, productTotals, SETTINGS);
    productSalesTaxes.map((salesTax) => {
      if(salesTax.vatPercentage === "Invoice Level Tax"){
        extraData.witholdTaxEuro = salesTax.withholdTaxes;
        extraData.otherTaxEuro = salesTax.otherTaxes;
        extraData.feeEuro = salesTax.fees;
        extraData.stampEuro = salesTax.stamp;
      }
      return salesTax;
    })
    let totalTaxes = 0;
    let withHoldTaxes = 0;
    if(productSalesTaxes.length > 0 && productSalesTaxes[0].vatPercentage === "Invoice Level Tax"){
      totalTaxes = parseFloat( (Number(productSalesTaxes[0].otherTaxes) + Number(productSalesTaxes[0].fees) + Number(productSalesTaxes[0].stamp)).toString());
      withHoldTaxes = parseFloat(productSalesTaxes[0].withholdTaxes).toFixed(2);
    }
    if(totalTaxes !== 0 || withHoldTaxes !== 0){
      productTotals.totalAmount = parseFloat(parseFloat(productTotals.totalAmount) + parseFloat(totalTaxes) - parseFloat(withHoldTaxes)).toFixed(2);
      productTotals.paymentAmount = parseFloat(parseFloat(productTotals.paymentAmount) + parseFloat(totalTaxes) - parseFloat(withHoldTaxes)).toFixed(2);
    }
    dispatch(setEditSales({ ...SALES.editSales,salesTaxes: productSalesTaxes, ...productTotals }));
    console.log(SALES.editSales.extraData)
    dispatch(setEditSalesExtraData(extraData));
  }

  const quickClientFields = [
    {
      name: t('SalesNewEdit.tableQuickClient.clientName'),
      field: "name",
      editable: true,
      inputType: {
        type: "text",
      },
    },
    {
      name: t('SalesNewEdit.tableQuickClient.vatCategory'),
      field: "vatCategory",
      editable: true,
      inputType: {
        options: getVatCategoryOptions(),
        type: 'select',
      }
    },
    {
      name: t('SalesNewEdit.tableQuickClient.vatNumber'),
      field: "vatNumber",
      editable: true,
      inputType: {
        type: "text",
      },
    },
    {
      name: t('SalesNewEdit.tableQuickClient.phoneNumber'),
      field: "phone",
      editable: true,
      inputType: {
        type: "text",
      },
    },
    {
      name: t('SalesNewEdit.tableQuickClient.email'),
      field: "email",
      editable: true,
      inputType: {
        type: "text",
      },
    },
    {
      name: t('SalesNewEdit.tableQuickClient.address'),
      field: "address",
      editable: true,
      inputType: {
        type: "text",
      },
    },
    {
      name: t('SalesNewEdit.tableQuickClient.city'),
      field: "city",
      editable: true,
      inputType: {
        type: "text",
      },
    },
  ];

  const salesProductListActions = {
    insert: async (e, data) => {

      // Set if editing the form
      if (!ACTIVITY.saveSales) {
        dispatch(setSaveEditSales(true));
      }
      let productData = null;
      // Get Product By Code
      if (data && data?.field === 'code') {
        await axios.get(process.env.REACT_APP_API_URL2 + `/category/get-product-by-code/${encodeURIComponent(data.value)}`)
            .then(res => {
              if (res.data && res.data.data && (res.data.data?.length > 0) && res.data.status === '200') {
                productData = res.data.data['0'];
              }
            })
      }
      // Get Product By Name
      if (data && data?.field === 'name') {
        await axios.get(process.env.REACT_APP_API_URL2 + `/category/get-product-by-name/${encodeURIComponent(data.value)}`)
            .then(res => {
              if (res.data && res.data.data && (res.data.data?.length > 0) && res.data.status === '200') {
                productData = res.data.data['0'];
              }
            })
      }
      if (productData !== null && Object.keys(productData).length > 0) {

        let productExists = isAlreadyExists(SALES.editSales.products, data.field, data.value);
        if (productExists) {
          toast.error('Product already added in the table.');
        } else {
          // Set Loading true
          setProductTableSpinner('true');
          // auto close in 2 seconds
          setTimeout(() => {
            setProductTableSpinner('false');
          }, 3000);
          if (e.key !== "Enter") {
            let tempNewProduct = Object.assign({}, SALES.editSalesNewProduct);
            tempNewProduct.name = productData?.name;
            tempNewProduct.code = productData?.code;
            tempNewProduct.quantity = 1;
            tempNewProduct.mu = productData?.measurementUnit;
            tempNewProduct.muLabel = getLabelByValue(measurementUnitOptions, productData?.measurementUnit.toString(), 'value');
            tempNewProduct.discount = 0;
            tempNewProduct.taxes = 0;
            tempNewProduct.isPerDiscount = "false";
            tempNewProduct.isDiscountAmount = "true";
            tempNewProduct.unitPrice = parseNumber(productData.price); // Direct price from the product preffered price
            tempNewProduct[data.field] = data.value; // Updates from table edit
            if (data.field === 'isPerDiscount' && (data.value === "on" || data.value === "true")) {
              tempNewProduct.isPerDiscount = "true";
              tempNewProduct.isDiscountAmount = "false";
            }
            if (data.field === 'isDiscountAmount' && (data.value === "on" || data.value === "true")) {
              tempNewProduct.isPerDiscount = "false";
              tempNewProduct.isDiscountAmount = "true";
            }
            // Get Current Product Details with the API if product and client are selected.
            if (tempNewProduct.code && tempNewProduct.code !== '' && SALES.editSales.client && SALES.editSales.client !== '') {
              await axios
                  .post(`${process.env.REACT_APP_API_URL2}/category/get-product-code-by-price`, { code: tempNewProduct.code, client: SALES.editSales.client, company: requestData?.company})
                  .then((res) => {
                    if (res.status === 200) {
                      console.log('product details loading success');
                      sessionStorage.setItem("salesSelectedProductData", JSON.stringify(res.data.data));
                      let pldata = res.data?.data;
                      tempNewProduct.vatType = pldata.vatType === '' ? 'true' : pldata.vatType.toString();
                      tempNewProduct.isPerDiscount = pldata.isPerDiscount;
                      tempNewProduct.isDiscountAmount = pldata.isDiscountAmount;
                      tempNewProduct.discount = pldata.discount; // Discount from the price list table
                      tempNewProduct.unitPrice = filterNaNValue(pldata.productPrice); // Price from the price list table
                    } else {
                      console.log('Product details loading fail.');
                    }
                    setProductTableSpinner('false');
                  }).catch(error => {
                    setProductTableSpinner('false');
                    console.log(error);
                    console.log('Unable to fetch product price list.');
                  })
            }
            // Fix for loading calculation on insert
            let discountType = 'euro';
            if (tempNewProduct.isPerDiscount === 'true') {
              discountType = 'per';
            }
            const myDataData = productData.mydata.filter(myData => myData.invoiceType.toString() === SALES.editSales.myDataType.toString());
            if(productData?.isTaxLine === "true"){
              tempNewProduct.vatPercentage  = getVatPercent(clientVatCategory, productData.vat, clientCustomVat);
              tempNewProduct.vatId = getDefaultOptionByValue(vatCategoryData, productData?.vat, 'percentage', 'code', 'percentage').label;
              tempNewProduct.vatReason      = productData?.vatReason;
              tempNewProduct.unitPrice      = getPriceExclusiveVat(tempNewProduct.unitPrice, tempNewProduct?.vatPercentage, tempNewProduct?.vatType);
              tempNewProduct.discount       = getDiscountExclusiveVat(tempNewProduct.discount, tempNewProduct?.vatPercentage, tempNewProduct?.vatType, discountType);
              tempNewProduct.netValue       = 0;
              tempNewProduct.withHoldTax    = productData.withholdTax;
              tempNewProduct.otherTax       = productData.otherTaxes;
              tempNewProduct.stamp          = productData.stamp;
              tempNewProduct.fee           = productData.fee;
              tempNewProduct.stamp           = productData.stamp;
              tempNewProduct._id            = productData._id;
              tempNewProduct.isTaxLine      = productData.isTaxLine;
              tempNewProduct.classificationCategory = myDataData[0].category;
              tempNewProduct.classificationType = myDataData[0].type;
              tempNewProduct.isTaxLineWithoutVat      = productData.isTaxLineWithoutVat;
              tempNewProduct.taxes          = getNetValue(tempNewProduct.unitPrice, tempNewProduct.quantity, tempNewProduct.discount, discountType);
              tempNewProduct.otherTaxEuro   = getProductOtherTax(tempNewProduct, SETTINGS);
              tempNewProduct.witholdTaxEuro = getProductWithholdTax(tempNewProduct, SETTINGS);
              tempNewProduct.feeEuro        = getProductFee(tempNewProduct, SETTINGS);
              tempNewProduct.stampEuro      = getProductStamp(tempNewProduct, SETTINGS);
              tempNewProduct.vat            = getVatValue(tempNewProduct.taxes, tempNewProduct.vatPercentage);
              tempNewProduct.totalValue     = getTotalValue(tempNewProduct.netValue, tempNewProduct.vatPercentage, tempNewProduct.taxes);
            } else {
              tempNewProduct.vatPercentage = getVatPercent(clientVatCategory, productData.vat, clientCustomVat);
              tempNewProduct.vatId = getDefaultOptionByValue(vatCategoryData, productData?.vat, 'percentage', 'code', 'percentage').label;
              tempNewProduct.vatReason = productData?.vatReason;
              tempNewProduct.unitPrice = getPriceExclusiveVat(tempNewProduct.unitPrice, tempNewProduct?.vatPercentage, tempNewProduct?.vatType);
              tempNewProduct.discount = getDiscountExclusiveVat(tempNewProduct.discount, tempNewProduct?.vatPercentage, tempNewProduct?.vatType, discountType);
              tempNewProduct.netValue = getNetValue(tempNewProduct.unitPrice, tempNewProduct.quantity, tempNewProduct.discount, discountType);
              tempNewProduct.withHoldTax = productData.withholdTax;
              tempNewProduct.otherTax = productData.otherTaxes;
              tempNewProduct.stamp = productData.stamp;
              tempNewProduct.fee = productData.fee;
              tempNewProduct.stamp           = productData.stamp;
              tempNewProduct._id = productData._id;
              tempNewProduct.classificationCategory = myDataData[0].category;
              tempNewProduct.classificationType = myDataData[0].type;
              tempNewProduct.taxes = getTaxes(tempNewProduct, SETTINGS);
              tempNewProduct.otherTaxEuro = getProductOtherTax(tempNewProduct, SETTINGS);
              tempNewProduct.witholdTaxEuro = getProductWithholdTax(tempNewProduct, SETTINGS);
              tempNewProduct.feeEuro = getProductFee(tempNewProduct, SETTINGS);
              tempNewProduct.stampEuro        = getProductStamp(tempNewProduct, SETTINGS);
              tempNewProduct.vat = getVatValue(tempNewProduct.netValue, tempNewProduct.vatPercentage);
              console.log(tempNewProduct.taxes)
              tempNewProduct.totalValue = getTotalValue(tempNewProduct.netValue, tempNewProduct.vatPercentage, tempNewProduct.taxes);
            }
            // If validated fields done add new row
            let oldSalesProducts = SALES.editSales.products;
            let updatePriceListNewProduct = Object.assign({}, tempNewProduct);
            updatePriceListNewProduct.name = tempNewProduct.name;
            updatePriceListNewProduct.code = tempNewProduct.code;
            updatePriceListNewProduct.no = oldSalesProducts.length + 1;
            updatePriceListNewProduct.id = oldSalesProducts.length + 1;
            updatePriceListNewProduct._id = tempNewProduct._id
            dispatch(setEditSalesNewProduct(tempNewProduct)); // Update redux
            let pPlroducts = [...oldSalesProducts, updatePriceListNewProduct];
            let productTotals = getSalesTotals(pPlroducts, SETTINGS);
            let productSalesTaxes = getSalesTaxesData(pPlroducts,SALES.editSales.extraData, productTotals, SETTINGS);
            if(productSalesTaxes.length > 0){
              productSalesTaxes.map((salesTax) => {
                if(salesTax.vatPercentage === "Invoice Level Tax"){
                  let extraData = {};
                  extraData.witholdTaxEuro = salesTax.withholdTaxes;
                  extraData.otherTaxEuro = salesTax.otherTaxes;
                  extraData.feeEuro = salesTax.fees;
                  extraData.stampEuro = salesTax.stamp;
                  dispatch(setEditSalesExtraData({ ...SALES.editSales.extraData, ...extraData }));
                }
                return salesTax;
              })
            }
            let totalTaxes = 0;
            let withHoldTaxes = 0;
            if(productSalesTaxes.length > 0 && productSalesTaxes[0].vatPercentage === "Invoice Level Tax"){
              totalTaxes = parseFloat( (Number(productSalesTaxes[0].otherTaxes) + Number(productSalesTaxes[0].fees) + Number(productSalesTaxes[0].stamp)).toString());
              withHoldTaxes = parseFloat(productSalesTaxes[0].withholdTaxes).toFixed(2);
            }
            if(totalTaxes !== 0 || withHoldTaxes !== 0){
              productTotals.totalAmount = parseFloat(parseFloat(productTotals.totalAmount) + parseFloat(totalTaxes) - parseFloat(withHoldTaxes)).toFixed(2);
              productTotals.paymentAmount = parseFloat(parseFloat(productTotals.paymentAmount) + parseFloat(totalTaxes) - parseFloat(withHoldTaxes)).toFixed(2);
            }
            dispatch(setEditSalesNewProduct(SALES.editSalesNewProductInitialValue)); // Reset new category mydata
            dispatch(setEditSales({ ...SALES.editSales, products: pPlroducts, salesTaxes: productSalesTaxes, ...productTotals }));
          }
        }
      }
    },
    update: async (data, id) => {
      // Set if editing the form
      if (!ACTIVITY.saveSales) {
        dispatch(setSaveEditSales(true));
      }
      // Set Loading true
      setProductTableSpinner('true');
      let foundProduct;
      let productData = null;
      let plProducts = SALES.editSales.products.slice();
      // Quick Edit Product from the sales product table
      if (data.quickEdit) {
        let qeProduct = await getObjectFromArrayOfObjects(plProducts, id, '_id');
        if (qeProduct.length > 0) {
          data.code = qeProduct['0'].code;
        }
        foundProduct = plProducts.find(item => item._id === id);
      } else {
        foundProduct = plProducts.find(item => item.id === id);
      }
      if (data.isPerDiscount && (data.isPerDiscount === "on" || data.isPerDiscount === "true")) {
        data.isPerDiscount = "true";
        data.isDiscountAmount = "false";
      }
      if (data.isDiscountAmount && (data.isDiscountAmount === "on" || data.isDiscountAmount === "true")) {
        data.isPerDiscount = "false";
        data.isDiscountAmount = "true";
      }
      if (data.name && data.name === '') {
        toast.error("Name is mandatory field.");
      } else if (data.code && data.code === '') {
        toast.error("Code is mandatory field.");
      } else {
        if (foundProduct !== undefined) {
          let papiUrl = process.env.REACT_APP_API_URL2 + `/category/get-product-by-code/${encodeURIComponent(foundProduct.code)}`;
          if (data.code && data.code !== '') {
            papiUrl = process.env.REACT_APP_API_URL2 + `/category/get-product-by-code/${encodeURIComponent(data.code)}`;
          } else if (data.name && data.name !== '') {
            papiUrl = process.env.REACT_APP_API_URL2 + `/category/get-product-by-name/${encodeURIComponent(data.name)}`;
          }
          let loadingProduct = true;
          if (loadingProduct) {
            // Get product data from api.
            await axios.get(papiUrl)
                .then(res => {
                  if (res.data && res.data.data && (res.data.data?.length > 0) && res.data.status === '200') {
                    productData = res.data.data['0'];
                  }
                })
          }
          // Field auto population on change product
          if (((data.code && data.code !== '') || (data.name && data.name !== ''))) {
            // Get Current Product Details with the API if product and client are selected.
            if (productData.code && productData.code !== '' && SALES.editSales.client && SALES.editSales.client !== '') {
              await axios
                  .post(`${process.env.REACT_APP_API_URL2}/category/get-product-code-by-price`, { code: productData.code, client: SALES.editSales.client, company: requestData?.company})
                  .then((res) => {
                    if (res.status === 200) {
                      console.log('Product loading success');
                      sessionStorage.setItem("salesSelectedProductData", JSON.stringify(res.data.data));
                      let plProductData = res.data.data;
                      const myDataData = plProductData.mydata.filter(myData => myData.invoiceType.toString() === SALES.editSales.myDataType.toString());
                      data.vatType = plProductData?.vatType === '' ? 'true' : plProductData?.vatType.toString(); // Vat Type from price table
                      data.discount = plProductData?.discount;
                      data.isPerDiscount = plProductData?.isPerDiscount;
                      data.isDiscountAmount = plProductData?.isDiscountAmount;
                      data.name = plProductData?.productName;
                      data.classificationCategory = myDataData[0].category;
                      data.classificationType = myDataData[0].type;
                      data.mu = plProductData?.measurementUnit;
                      data.muLabel = getLabelByValue(measurementUnitOptions, plProductData?.measurementUnit.toString(), 'value');
                      data.unitPrice = data.unitPrice === '' || data.unitPrice === null ? plProductData?.price : data.unitPrice;
                      data.vatId = getDefaultOptionByValue(vatCategoryData, productData?.vat, 'percentage', 'code', 'percentage').label;
                      data.vatPercentage = getVatPercent(clientVatCategory, productData.vat, clientCustomVat);
                      data.vatReason = plProductData?.vatReason;
                      if(plProductData?.vatType === "true"){
                        data.totalValue = filterNaNValue(plProductData?.productPrice);
                      }else {
                        data.unitPrice = filterNaNValue(plProductData?.productPrice); // Price from the price list table
                      }
                    } else {
                      console.log('product details loading fail.');
                    }
                  })
            }
            data.withHoldTax = productData.withholdTax;
            data.otherTax = productData.otherTaxes;
            data.stamp          = productData.stamp;
            data.fee           = productData.fee;
          }
          // Updated selected product with updated field data.
          foundProduct = { ...foundProduct, ...data }
          let discountType = 'euro';
          if (foundProduct.isPerDiscount === 'true') {
            discountType = 'per'
          }
          // Note: For inclusive vat the value is reducing by the vat percentage.
          if (data.unitPrice && data.unitPrice !== '') { // on Unit price field change
            //  foundProduct.unitPrice      = getPriceExclusiveVat(foundProduct.unitPrice, foundProduct?.vatPercentage, foundProduct?.vatType);
          }
          // Note: For inclusive vat the value is reducing by the vat percentage.
          if (data.discount && data.discount !== '') { // on discount field change
            foundProduct.discount       = getDiscountExclusiveVat(foundProduct.discount, foundProduct?.vatPercentage, foundProduct?.vatType, discountType);
          }
          // Re Calculate Unit price if net value changed
          if (data.netValue && data.netValue !== '' && foundProduct.isTaxLine !== "true") {
            // If user enters value here, you calculate "Unit Price" automatically (based on previous axiom: "Net Value" has to equal: "Quantity" times "Unit Price" minus "Discount")
            foundProduct.unitPrice = getUnitPrice(data.netValue, foundProduct.quantity, foundProduct.discount, discountType);
          } else {
            if(foundProduct.isTaxLine === "true"){
              foundProduct.netValue = 0;
            } else {
              foundProduct.netValue = getNetValue(foundProduct.unitPrice, foundProduct.quantity, foundProduct.discount, discountType);
            }
          }
          foundProduct.witholdTaxEuro = getProductWithholdTax(foundProduct, SETTINGS);
          foundProduct.otherTaxEuro   = getProductOtherTax(foundProduct, SETTINGS);
          foundProduct.feeEuro   = getProductFee(foundProduct, SETTINGS);
          foundProduct.stampEuro   = getProductStamp(foundProduct, SETTINGS);
          if(foundProduct.isTaxLine === "true"){
            foundProduct.taxes = getNetValue(foundProduct.unitPrice, foundProduct.quantity, foundProduct.discount, discountType);
          } else {
            foundProduct.taxes = getTaxes(foundProduct, SETTINGS);
          }
          // Re Calculate Unit price and net value if total value changed
          if (data.totalValue && data.totalValue !== '') {
            if(foundProduct.isTaxLine === "true"){
              foundProduct.taxes = getNetValueByTotalValue(data.totalValue, foundProduct.vatPercentage, foundProduct.taxes);
              foundProduct.unitPrice      = getUnitPrice(foundProduct.taxes, foundProduct.quantity, foundProduct.discount, discountType);
              foundProduct.netValue       = 0;
            } else {
              // If user enters value here, you calculate "Unit Price" and "Net Value" automatically (based on axioms: "Net Value" has to equal: "Quantity" times "Unit Price" minus "Discount" and "Total Value" equals: "VAT %" times "Net Value" plus "Net Value")
              foundProduct.netValue = getNetValueByTotalValue(data.totalValue, foundProduct.vatPercentage, foundProduct.taxes);
              foundProduct.unitPrice = getUnitPrice(foundProduct.netValue, foundProduct.quantity, foundProduct.discount, discountType);
            }
          } else {
            foundProduct.totalValue = getTotalValue(foundProduct.netValue, foundProduct.vatPercentage, foundProduct.taxes);
          }
          if(foundProduct.isTaxLine === "true"){
            foundProduct.vat            = getVatValue(foundProduct.taxes, foundProduct.vatPercentage);
          } else {
            foundProduct.vat = getVatValue(foundProduct.netValue, foundProduct.vatPercentage);
          }
          const updatedPmProducts = { ...plProducts[foundProduct.no - 1], ...foundProduct }
          let filterFields = {
            code: data.code ? data.code : plProducts.code,
            name: data.name ? data.name : plProducts.name,
          }
          let productListData = salesDuplicateProductCheck(plProducts, filterFields, foundProduct.no - 1);
          // Logic to check duplicate product in the table.
          let productSalesTaxes ;
          if (productListData && !data.quickEdit) {
            toast.error('Duplicate products not allowed.');
            let productTotals = getSalesTotals(plProducts, SETTINGS);
            productSalesTaxes = getSalesTaxesData(plProducts,SALES.editSales.extraData, productTotals, SETTINGS);
            let totalTaxes = 0;
            let withHoldTaxes = 0;
            if(productSalesTaxes.length > 0 && productSalesTaxes[0].vatPercentage === "Invoice Level Tax"){
              totalTaxes = parseFloat( (Number(productSalesTaxes[0].otherTaxes) + Number(productSalesTaxes[0].fees) + Number(productSalesTaxes[0].stamp)).toString());
              withHoldTaxes = parseFloat(productSalesTaxes[0].withholdTaxes).toFixed(2);
            }
            if(totalTaxes !== 0 || withHoldTaxes !== 0){
              productTotals.totalAmount = parseFloat(parseFloat(productTotals.totalAmount) + parseFloat(totalTaxes) - parseFloat(withHoldTaxes)).toFixed(2);
              productTotals.paymentAmount = parseFloat(parseFloat(productTotals.paymentAmount) + parseFloat(totalTaxes) - parseFloat(withHoldTaxes)).toFixed(2);
            }
            dispatch(setEditSales({ ...SALES.editSales, products: plProducts, salesTaxes: productSalesTaxes, ...productTotals })); // update data
            setRefreshPage(refreshPage + 1); // Refresh page
          } else {
            plProducts[foundProduct.no - 1] = updatedPmProducts;
            let productTotals = getSalesTotals(plProducts, SETTINGS);
            productSalesTaxes = getSalesTaxesData(plProducts,SALES.editSales.extraData, productTotals, SETTINGS);
            let totalTaxes = 0;
            let withHoldTaxes = 0;
            if(productSalesTaxes.length > 0 && productSalesTaxes[0].vatPercentage === "Invoice Level Tax"){
              totalTaxes = parseFloat( (Number(productSalesTaxes[0].otherTaxes) + Number(productSalesTaxes[0].fees) + Number(productSalesTaxes[0].stamp)).toString());
              withHoldTaxes = parseFloat(productSalesTaxes[0].withholdTaxes).toFixed(2);
            }
            if(totalTaxes !== 0 || withHoldTaxes !== 0){
              productTotals.totalAmount = parseFloat(parseFloat(productTotals.totalAmount) + parseFloat(totalTaxes) - parseFloat(withHoldTaxes)).toFixed(2);
              productTotals.paymentAmount = parseFloat(parseFloat(productTotals.paymentAmount) + parseFloat(totalTaxes) - parseFloat(withHoldTaxes)).toFixed(2);
            }
            dispatch(setEditSales({ ...SALES.editSales, products: plProducts, salesTaxes: productSalesTaxes, ...productTotals })); // update data
            setRefreshPage(refreshPage + 1); // Refresh page
          }
          if(productSalesTaxes.length > 0){
            productSalesTaxes.map((salesTax) => {
              if(salesTax.vatPercentage === "Invoice Level Tax"){
                let extraData = {};
                extraData.witholdTaxEuro = salesTax.withholdTaxes;
                extraData.otherTaxEuro = salesTax.otherTaxes;
                extraData.feeEuro = salesTax.fees;
                extraData.stampEuro = salesTax.stamp;
                dispatch(setEditSalesExtraData({ ...SALES.editSales.extraData, ...extraData }));
              }
              return salesTax;
            })
          }
          setProductTableSpinner('false');

        }
      }
    },
    selected: () => {
      console.log('selected mydata row clicked');
    },
    delete: (id) => {
      // Set if editing the form
      if (!ACTIVITY.saveSales) {
        dispatch(setSaveEditSales(true));
      }

      let oldSalesProducts = SALES.editSales.products.slice();

      if (id !== '' && Number.isInteger(id)) {
        const deletedSalesData = deleteObjectFromArrayOfObjects(oldSalesProducts, id, 'id');
        dispatch(setEditSales({ ...SALES.editSales, products: deletedSalesData }))
      }

      if (id !== '' && !Number.isInteger(id)) {
        const deletedSalesData = deleteObjectFromArrayOfObjects(oldSalesProducts, id, '_id');
        dispatch(setEditSales({ ...SALES.editSales, products: deletedSalesData }))
      }
    }
  }

  const selectedDocumentType = getDefaultOptionByValue(documentTypesOptions, SALES.editSales.documentType, 'value', 'label', 'value');
  // const selectedCorelatedDocument = getDefaultOptionByValue(corelatedDocumentOptions, SALES.editSales.corelatedDocument, 'value', 'label', 'value');

  let tableWrClasses = "product-form sales-products-list position-relative";

  if (SALES.editSales.client !== '') {
    tableWrClasses += " normalIndex";
  }

  const newSalesTab = [
    {
      tabName: "Products",
      tabData: (
          <div className={tableWrClasses}>
            {(SALES.editSales.client === '' && SALES.editSales.newClient === '') && <div className="overlay_w">{t('SalesNewEdit.message')}</div>}
            {showProductTableSpinner && showProductTableSpinner === 'true' && <div className="overlay_w"> <div className="spinner-border" role="status"> <span className="sr-only">{t('SalesNewEdit.loading')}</span> </div> </div>}
            {SALES.editSales?.products && SALES.editSales?.products?.length === 0 && <span className="text-danger">{t('SalesNewEdit.message2')}</span>}
            <EditableTable
                tableName="Sales Products List"
                key={Math.random()}
                actionButtons={{
                  print: false,
                  delete: true,
                  export: false,
                  edit: true,
                }}
                columns={clientProductsColumns}
                pRequestData={pRequestData}
                data={SALES.editSales.products}
                allowInsertRow={true}
                onUpdate={salesProductListActions}
                allowActions={true}
                allowToggleColumns={false}
                allowPrintResults={false}
            />
          </div>
      ),
    },
    {
      tabName: "Extra Data",
      tabData: (
          <>
            <Row>
              <Col sm={12} md={3} className="mb-2">
                <Dropdown
                    label={t('SalesNewEdit.wayOfSendingGoods')}
                    key={Math.random()}
                    defaultValue={getDefaultOptionByValue(waysOfSendingGoodsOptions, SALES.editSales.extraData.wayOfSendingGoods, 'value', 'label', 'value')}
                    name="wayOfSendingGoods"
                    options={waysOfSendingGoodsOptions}
                    onChange={(e) => handleOnChangeExtraData(e, 'dd', 'wayOfSendingGoods')}
                />
              </Col>
              <Col sm={12} md={3} className="mb-2">
                <Dropdown
                    label={t('SalesNewEdit.carNumber')}
                    key={Math.random()}
                    defaultValue={getDefaultOptionByValue(carNumbersOptions, SALES.editSales.extraData.carNumber, 'value', 'label', 'value')}
                    name="carNumber"
                    options={carNumbersOptions}
                    onChange={(e) => handleOnChangeExtraData(e, 'dd', 'carNumber')}
                />
              </Col>
              <Col sm={12} md={3} className="mb-2">
                <Dropdown
                    label={t('SalesNewEdit.otherTaxes')}
                    key={Math.random()}
                    defaultValue={getDefaultOptionByValue(otherTaxesOptions, SALES.editSales.extraData.otherTax, 'value', 'label', 'value')}
                    name="otherTax"
                    options={otherTaxesOptions}
                    onChange={(e) => handleOnChangeExtraData(e, 'dd', 'otherTax')}
                />
              </Col>
              <Col sm={12} md={3} className="mb-2">
                <Dropdown
                    label={t('SalesNewEdit.withholdTaxes')}
                    key={Math.random()}
                    defaultValue={getDefaultOptionByValue(withHoldTaxesOptions, SALES.editSales.extraData.withHoldTax, 'value', 'label', 'value')}
                    name="withHoldTax"
                    options={withHoldTaxesOptions}
                    onChange={(e) => handleOnChangeExtraData(e, 'dd', 'withHoldTax')}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={3}>
                <DateBox
                    classes="w-100 mb-3"
                    label={t('SalesNewEdit.goodsSendDate')}
                    name="goodsSendDate"
                    selected={SALES.editSales.extraData.goodsSendDate}
                    onChange={(e) => handleOnChangeExtraData(e, 'dp', 'goodsSendDate')}
                />
              </Col>
              <Col></Col>
              <Col sm={12} md={3} className="mb-2">
                <Dropdown
                    label={t('SalesNewEdit.fees')}
                    key={Math.random()}
                    defaultValue={getDefaultOptionByValue(feesOptions, SALES.editSales.extraData.fee, 'value', 'label', 'value')}
                    name="fee"
                    options={feesOptions}
                    onChange={(e) => handleOnChangeExtraData(e, 'dd', 'fee')}
                />
              </Col>
              <Col sm={12} md={3} className="mb-2">
                <Dropdown
                    label={t('SalesNewEdit.stampDuty')}
                    key={Math.random()}
                    defaultValue={getDefaultOptionByValue(stampOptions, SALES.editSales.extraData.stamp, 'value', 'label', 'value')}
                    name="stamp"
                    options={stampOptions}
                    onChange={(e) => handleOnChangeExtraData(e, 'dd', 'stamp')}
                />
              </Col>
            </Row>
          </>
      ),
    },
  ];

  const handleQuickRetailClient = () => {
    let type = (SALES.editSales.clientType === 'Existing') ? 'New' : "Existing";
    let newSalesWithNewClient = Object.assign({}, SALES.editSales);

    if (type === 'New') {
      // Clear New Client Data
      newSalesWithNewClient.doy = "";
      newSalesWithNewClient.city = "";
      newSalesWithNewClient.pc = "";
      newSalesWithNewClient.vat = "";
      newSalesWithNewClient.address = "";
      newSalesWithNewClient.email = "";
      newSalesWithNewClient.phone = "";
    } else {
      let newSalesSelectedClient = newSalesWithNewClient.client;
      if (newSalesSelectedClient !== '') {
        let selectedClient = getObjectFromArrayOfObjects(clientsListData, newSalesSelectedClient, '_id');
        if (selectedClient.length > 0) {
          let sClient = selectedClient['0'];
          newSalesWithNewClient.address = sClient.address;
          newSalesWithNewClient.vat = sClient.vatNumber;
          newSalesWithNewClient.doy = getLabelByValue(getDOYOptions(), sClient.DOY, 'value');
          newSalesWithNewClient.city = sClient.city;
          newSalesWithNewClient.pc = sClient.postalCode;
          if (sClient.installations.length > 0) {
            // setAddressOptions(getOptionsByData(sClient.installations, 'id', 'address'))
            newSalesWithNewClient.address = 1;
          }
        }
      }
    }
    newSalesWithNewClient.clientType = type;
    dispatch(setEditSales(newSalesWithNewClient)); // Update type in redux state
  }

  // Data For Quick Edit Client Details on selection
  let quickEditClientData = {};
  if (SALES.editSales.clientType === 'Existing') {
    let selectedClient = getObjectFromArrayOfObjects(clientsListData, SALES.editSales.client, '_id');
    if (selectedClient.length > 0) {
      let sClient = selectedClient['0'];
      quickEditClientData.name = sClient.name;
      quickEditClientData.id = sClient._id;
      quickEditClientData.vatCategory = sClient.vatCategory;
      quickEditClientData.address = sClient.address;
      quickEditClientData.vatNumber = sClient.vatNumber;
      quickEditClientData.doy = getLabelByValue(getDOYOptions(), sClient.DOY, 'value');
      quickEditClientData.city = sClient.city;
      quickEditClientData.pc = sClient.postalCode;
      quickEditClientData.email = sClient.email;
      quickEditClientData.phone = sClient.phone;
    }
  }

  const quickEditClient = (e, type, data) => {

    let selectedClient = getObjectFromArrayOfObjects(clientsListData, SALES.editSales.client, '_id');
    if (selectedClient.length > 0) {
      let quickClientData = Object.assign({}, selectedClient['0']);

      // Quick Edit Client Details Data
      quickClientData.name = data.name;
      quickClientData.vatCategory = data.vatCategory;
      quickClientData.vatNumber = data.vatNumber;
      quickClientData.address = data.address;
      quickClientData.city = data.city;
      quickClientData.email = data.email;
      quickClientData.phone = data.phone;

      if ((data.email !== '' || data.phone !== '') && data.name !== '' && data.vatCategory !== '') {
        console.log('edit quick client', quickClientData);
        dispatch(putClient(quickClientData, requestData));

        // Repopulate the client data to sales after updating the client.
        let sClient = data;
        let repopulateClient = {};
        repopulateClient.clientName = sClient.name;
        repopulateClient.address = sClient.address;
        repopulateClient.vat = sClient.vatNumber;
        repopulateClient.vatCategory = sClient.vatCategory;
        repopulateClient.city = sClient.city;
        repopulateClient.email = sClient.email;
        repopulateClient.pc = sClient.postalCode;
        repopulateClient.phone = sClient.phone;

        let salesData = Object.assign({}, SALES);
        dispatch(setEditSales({ ...salesData.editSales, ...repopulateClient }));

      } else {
        toast.error('Name, Email or Phone and Vat Category required fields.');
      }
    }
  }

  const quickAddClient = (e, type, data) => {
    let quickClientData = Object.assign({}, CLIENTS_DATA.newClient);
    // Default Category Retails form sales page.
    quickClientData.category = '1';
    quickClientData.categoryName = 'Retail';
    quickClientData.name = data.name;
    quickClientData.vatCategory = data.vatCategory;
    quickClientData.vatNumber = data.vatNumber;
    quickClientData.address = data.address;
    quickClientData.city = data.city;
    quickClientData.email = data.email;
    quickClientData.phone = data.phone;
    // Company and Year
    quickClientData.company = requestData.company;
    quickClientData.year = requestData.year;

    quickClientData.isActive = 'active'; // Active by default

    if ((data.email !== '' || data.phone !== '') && data.name !== '' && data.vatCategory !== '') {
      dispatch(postClient(quickClientData, requestData));
    } else {
      toast.error('Name, Email or Phone and Vat Category required fields.');
    }
  }

  let posData = CASH_DATA.posList;
  let bankData = CASH_DATA.bankAccounts;
  let posOptions = getOptionsByData(posData, '_id', 'name');
  let bankOptions = getOptionsByData(bankData, '_id', 'name');
  let hidePosBankField = false;

  // This is will update the list of pos / bank on type selection
  let paymentTypesData = getObjectFromArrayOfObjects(SALES_SETTINGS.salesGeneralSettings.paymentTypes, SALES.editSales.paymentType, '_id');
  if (paymentTypesData && paymentTypesData.length > 0) {
    if (paymentTypesData['0']?.type === "pos") {
      bankOptions = posOptions;
    }

    if (paymentTypesData['0']?.type === "cash" || paymentTypesData['0']?.type === "credit" || paymentTypesData['0']?.type === "cheque") {
      bankOptions = [];
      hidePosBankField = true;
    }
  }
  let salesTaxesColumns = [
    {
      name: t('SalesNewEdit.tableSalesTaxes.vat%'),
      field: "vatPercentage",
      editable: false,
    },
    {
      name: t('SalesNewEdit.tableSalesTaxes.netValue'),
      field: "netValue",
      editable: false,
    },
    {
      name: t('SalesNewEdit.tableSalesTaxes.vat'),
      field: "vatEuro",
      editable: false,
    },
    {
      name: t('SalesNewEdit.tableSalesTaxes.withholdTaxes'),
      field: "withholdTaxes",
      editable: false,
    },
    {
      name: t('SalesNewEdit.tableSalesTaxes.otherTaxes'),
      field: "otherTaxes",
      editable: false,
    },
    {
      name: t('SalesNewEdit.tableSalesTaxes.fees'),
      field: "fees",
      editable: false,
    },
    {
      name: t('SalesNewEdit.tableSalesTaxes.stampDuty'),
      field: "stamp",
      editable: false,
    }
  ];
  let totalTaxes = 0;
  let withHoldTaxes = 0;
  let productTotals = useMemo(() => getSalesTotals(SALES.editSales.products, SETTINGS), [SALES.editSales.products]);
  let salesTaxesData = useMemo(() => getSalesTaxesData(SALES.editSales.products,SALES.editSales.extraData, productTotals, SETTINGS), [SALES.editSales.products, SALES.editSales.extraData]);
  if(salesTaxesData.length > 0 && salesTaxesData[salesTaxesData.length - 1].vatPercentage === "Totals"){
    totalTaxes = parseFloat( (Number(salesTaxesData[0].otherTaxes) + Number(salesTaxesData[0].fees) + Number(salesTaxesData[0].stamp)).toString());
    withHoldTaxes = parseFloat(salesTaxesData[0].withholdTaxes).toFixed(2);
  }
  let totalQuantity = productTotals.totalQuantity;
  let totalBeforeDiscount = productTotals.totalBeforeDiscount;
  let totalDiscount = productTotals.totalDiscount;
  let totalNetValue = productTotals.totalNetValue;
  let totalAmount = productTotals.totalAmount;
  let paymentAmount = productTotals.paymentAmount;
  let totalVatAmount = productTotals.totalVatValue;
  if(totalTaxes !== 0 || withHoldTaxes !== 0){
    totalAmount = parseFloat(totalAmount) + parseFloat(totalTaxes) - parseFloat(withHoldTaxes);
    paymentAmount = parseFloat(paymentAmount) + parseFloat(totalTaxes) - parseFloat(withHoldTaxes);
  }
  totalTaxes += Number(productTotals.totalTaxesAmount);
  withHoldTaxes = parseFloat(parseFloat(productTotals.withHoldTaxes) + parseFloat(withHoldTaxes));
  const selectDocumentInstallation = getDefaultOptionByValue(dtInstallationsOptions, SALES.editSales.installation, 'value', 'label', 'value');
  const selectPaymentTypeOptions = getDefaultOptionByValue(paymentTypeOptions, SALES.editSales.paymentType, 'value', 'label', 'value');
  const selectPosBankAccount = getDefaultOptionByValue(bankOptions, SALES.editSales.bankAccount, 'value', 'label', 'value');
  const selectSalePurpose = getDefaultOptionByValue(salesPurposeOptions, SALES.editSales.salePurpose, 'value', 'label', 'value');
  return (
      <div className="new-sales">
        <Form className="client-form product-form position-relative" autoComplete="off">
          {disabledFields && <div className="overlay_w">Please select Document Type first..</div>}
          <Row className="border-bottom mb-3">
            <Col sm={12} md={3} className="showWithOverlay">
              <Dropdown
                  label={t('SalesNewEdit.DocumentType')}
                  key={Math.random()}
                  defaultValue={selectedDocumentType}
                  required={true}
                  searchEnable={true}
                  name="documentType"
                  options={documentTypesOptions}
                  onChange={(e) => handleOnChange(e, 'dd', 'documentType')}
              />
            </Col>
            <Col sm={12} md={9}>
              <div className="d-flex justify-content-end">
                <div className="mydata-status">
                  <Dropdown
                      label={t("SalesNewEdit.myDataStatus")}
                      options={salesMyDataStatusOptions}
                      defaultValue={getDefaultOptionByValue(salesMyDataStatusOptions, SALES.editSales.myDataStatus, 'value', 'label', 'value')}
                      name="myDataStatus"
                      onChange={(e) => handleOnChange(e, 'dd', 'myDataStatus')}
                  />
                </div>
                <Button className="disabled mb-3" size="small">{t("SalesNewEdit.files")}</Button>
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm="12" md="3">
              {/* If "MyData rules" is enabled in "Settings-Document Types-Advanced Types", then FIELD IS READ ONLY. Select current date only. */}
              <div className="d-flex">
                <DateBox
                    classes="w-100 mb-3"
                    label={t('SalesNewEdit.Date')}
                    name="date"
                    selected={new Date(SALES.editSales.date)}
                    readOnly={readOnlySalesDate}
                    allowOlderDate={allowOlderDate}
                    allowOlderDateValue={checkPermission("custom-logistirio", permissionsData) === false ? pMDDSettings.otherData.filter(otherData => otherData.name === "allowOlderDate")[0].value : "999999"}
                    onChange={(e) => handleOnChange(e, 'dp', 'date')}
                />
              </div>
            </Col>
            <Col sm="12" md="3">
              {/* If "MyData rules" is enabled in "Settings-Document Types-Advanced Types", then FIELD IS READ ONLY. Select current date only. */}
              <label htmlFor="time">{t('SalesNewEdit.Date')}</label>
              <TimePickerInput
                  className="w-100"
                  key = {Math.random()}
                  value={SALES.editSales.time ? SALES.editSales.time : currentTime}
                  disabled = {readOnlySalesTime}
                  invalidMessage = "Invalid."
                  onChange={(e) => handleOnChange(e, 'tp', 'time')}
              />
            </Col>
          </Row>

          <Row>
            <Col sm="12" md="4">
              <div className="mb-3">
                <Dropdown
                    label={t('SalesNewEdit.installation')}
                    options={dtInstallationsOptions}
                    value={Object.keys(selectDocumentInstallation).length !== 0 ? selectDocumentInstallation : ''}
                    enabledValue={true}
                    searchEnable={true}
                    required={true}
                    name="installation"
                    onChange={(e) => handleOnChange(e, 'dd', 'installation')}
                />
              </div>
            </Col>
            <Col sm="12" md="4">
              <div className="mb-3">
                <Dropdown
                    label={t('SalesNewEdit.preNumber')}
                    name="preNumber"
                    key={Math.random()}
                    defaultValue={getDefaultOptionByValue(preNumberGreekOptions, SALES.editSales.preNumber, 'value', 'label', 'value')}
                    options={preNumberGreekOptions}
                    disabled={SALES.editSales.numberingMode === 'manual'}
                    onChange={(e) => handleOnChange(e, 'dd', 'preNumber')}
                />
              </div>
            </Col>
            <Col sm="12" md="4">
              <div className="d-flex">
                <Input
                    className="mb-3 w-100"
                    label={t('SalesNewEdit.number')}
                    name="number"
                    value={SALES.editSales.number}
                    placeholder={t('SalesNewEdit.number')}
                    onChange={(e) => handleOnChange(e)}
                    disabled={SALES.editSales.numberingMode !== 'manual'}
                />
              </div>
            </Col>
          </Row>
          {
              showCorelatedDocument &&
              <Row>
                <Col sm="12" md="4">
                  <div className="mb-3">
                    <AdvancedCombobox
                        ACName="Sales Corelated Document" // do not change name
                        className="w-100 ac-select-dropdown corelated-doc"
                        popupClassName="corelated-doc-popup"
                        searchableDropdownEnable={false}
                        defaultValue={SALES.editSales?.corelatedDocumentName}
                        key={Math.random()}
                        label={t('SalesNewEdit.correlatedDocument.correlatedDocument')}
                        data={corelatedDocumentsList}
                        onChange={(e) => { handleOnChange(e, 'acdd', 'corelatedDocument') }}
                        excludeColumns={[]}
                        columns={corelatedDocumentColumns}
                        editEnable={false}
                        deleteEnable={false}
                        searchTableEnable={false}
                        showTableOptionOnSelect={true}
                        placeholder={t('SalesNewEdit.correlatedDocument.placeholder')}
                        showInsertAddButton={true}
                        hideSaveButton={true}
                        hideInsertNewRow={true}
                        required={isCorelatedDocumentMandatory}
                        inputFieldClasses="form-control"
                    />
                    {
                        ACTIVITY.saveSales &&
                        isCorelatedDocumentMandatory &&
                        (SALES.editSales.client !== '' || SALES.editSales.newClient !== '') &&
                        SALES.editSales.corelatedDocument === "" &&
                        <div className="text-danger">{t('SalesNewEdit.correlatedDocument.required')}</div>}
                  </div>
                </Col>
              </Row>
          }
          <Row>
            <Col sm="12" md="2">
              <div className="d-flex">
                {SALES.editSales.clientType === 'Existing' &&
                    <AdvancedCombobox
                        ACName="Sales Client" // do not change name
                        className="w-100 ac-select-dropdown"
                        searchableDropdownEnable={false}
                        defaultValue={SALES.editSales.clientName}
                        key={Math.random()}
                        label={t('SalesNewEdit.client.client')}
                        data={clientsListData}
                        onChangeSearch = {onChangeSearch}
                        onChange={(e) => { handleOnChange(e, 'acdd', 'client') }}
                        excludeColumns={[]}
                        columns={clientDropdownColumns}
                        editEnable={false}
                        deleteEnable={false}
                        searchTableEnable={false}
                        showTableOptionOnSelect={true}
                        placeholder={t('SalesNewEdit.client.placeholder')}
                        required={true}
                        showInsertAddButton={true}
                        hideSaveButton={true}
                        hideInsertNewRow={true}
                        inputFieldClasses="form-control"
                    />
                }
                {SALES.editSales.clientType === 'New' &&
                    <Input
                        className="w-100 newclient-field"
                        label={t('SalesNewEdit.newRetailClient.newRetailClient')}
                        name="newClient"
                        value={SALES.editSales.newClient}
                        placeholder={t('SalesNewEdit.newRetailClient.placeholder')}
                        onChange={(e) => handleOnChange(e)}
                    />
                }
                <div className="quick-client-actions">
                  <button title="Quick Retail Client" type="button" onClick={() => handleQuickRetailClient()} className="btn quick-retail-clientbtn btn-default btn-sm">
                    <i className="fas fa-male"></i>
                  </button>
                  {SALES.editSales.client !== '' && SALES.editSales.clientType === 'Existing' && <QuickEdit title="Edit selected client." key={Math.random()} columns={quickClientFields} data={quickEditClientData} hideCurrentValue={true} updateData={quickEditClient} />}
                  {/* {SALES.editSales.clientType !== 'Existing' && <QuickEdit title="Add new client" columns={quickClientFields} data={{}} hideCurrentValue={true} updateData={quickAddClient}  />} */}
                  {((SALES.editSales.client === '' && SALES.editSales.newClient === '') || SALES.editSales.clientType !== 'Existing') && <NewClientModal title="Add new client" columns={quickClientFields} data={{}} hideCurrentValue={true} myDataType={SALES.editSales.myDataType} updateData={quickAddClient} />}
                  {/* {SALES.editSales.clientType !== 'Existing' && <NewClientModal title="Add new client" columns={quickClientFields} data={{}} hideCurrentValue={true} updateData={quickAddClient} />} */}
                </div>
              </div>
              {ACTIVITY.saveSales && SALES.editSales.clientType === 'New' && SALES.editSales.newClient === "" && <div className="text-danger">{t('SalesNewEdit.clientIsRequired')}</div>}
              {ACTIVITY.saveSales && SALES.editSales.clientType === 'Existing' && SALES.editSales.client === "" && <div className="text-danger">{t('SalesNewEdit.clientIsRequired')}</div>}
            </Col>
            <Col sm="12" md="2">
              <div className="d-flex">
                {SALES.editSales.clientType === 'Existing' && (
                    <AdvancedCombobox
                        ACName="Client Installation" // do not change name
                        className="w-100 ac-select-dropdown"
                        searchableDropdownEnable={false}
                        defaultValue={SALES.editSales.clientInstallationType}
                        key={Math.random()}
                        label={t('SalesNewEdit.clientInstallation.clientInstallation')}
                        data={clientInstallationListData}
                        onChangeSearch = {onChangeSearch}
                        onChange={(e) => { handleOnChange(e, 'acdd', 'clientInstallation') }}
                        excludeColumns={[]}
                        columns={clientInstallationsDropdownColumns}
                        editEnable={false}
                        deleteEnable={false}
                        searchTableEnable={false}
                        showTableOptionOnSelect={true}
                        placeholder={t('SalesNewEdit.clientInstallation.placeholder')}
                        required={true}
                        showInsertAddButton={true}
                        hideSaveButton={true}
                        hideInsertNewRow={true}
                        inputFieldClasses="form-control"
                    />
                )}
              </div>
            </Col>
            <Col sm="12" md="2">
              <div className="mb-3 ">
                <Input
                    className="w-100"
                    label={t('SalesNewEdit.vat')}
                    name="vat"
                    value={SALES.editSales.vat}
                    onChange={(e) => handleOnChange(e)}
                    disabled={true}
                    readonly
                />
              </div>
            </Col>

            {/* Show if new client */}
            {SALES.editSales.clientType === 'New' &&
                <Col sm="12" md="2">
                  <div className="mb-3 ">
                    <Input
                        className="w-100"
                        label={t('SalesNewEdit.email')}
                        name="email"
                        required={SALES.editSales.clientType === 'New' && SALES.editSales.phone === ''}
                        value={SALES.editSales.email}
                        onChange={(e) => handleOnChange(e)}
                    />
                    {ACTIVITY.saveSales && SALES.editSales.clientType === 'New' && SALES.editSales.email === "" && SALES.editSales.phone === "" && <div className="text-danger">{t('SalesNewEdit.emailOrPhoneIsRequired')}</div>}
                  </div>
                </Col>
            }
            {/* Show if new client */}
            {SALES.editSales.clientType === 'New' &&
                <Col sm="12" md="2">
                  <div className="mb-3 ">
                    <Input
                        className="w-100"
                        label={t('SalesNewEdit.phone')}
                        name="phone"
                        required={SALES.editSales.clientType === 'New' && SALES.editSales.email === ''}
                        value={SALES.editSales.phone}
                        onChange={(e) => handleOnChange(e)}
                    />
                  </div>
                </Col>
            }
            <Col sm="12" md="2">
              <div className="mb-3 ">
                <Input
                    className="w-100"
                    label={t('SalesNewEdit.address')}
                    name="address"
                    value={SALES.editSales.address}
                    onChange={(e) => handleOnChange(e)}
                />
              </div>
            </Col>
            <Col sm="12" md="2">
              <div className="mb-3 ">
                <Input
                    className="w-100"
                    label={t('SalesNewEdit.doy')}
                    name="doy"
                    value={SALES.editSales.doy}
                    onChange={(e) => handleOnChange(e)}
                    disabled={true}
                    readonly
                />
              </div>
            </Col>
            <Col sm="12" md="2">
              <div className="mb-3 ">
                <Input
                    className="w-100"
                    label={t('SalesNewEdit.city')}
                    name="city"
                    value={SALES.editSales.city}
                    onChange={(e) => handleOnChange(e)}
                    disabled={true}
                    readonly
                />
              </div>
            </Col>
            <Col sm="12" md="2">
              <div className="mb-3 ">
                <Input
                    className="w-100"
                    label={"PC"}
                    name="pc"
                    value={SALES.editSales.pc}
                    onChange={(e) => handleOnChange(e)}
                    disabled={true}
                    readonly
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm="12" md="4">
              <Dropdown
                  label={t('SalesNewEdit.paymentType')}
                  options={paymentTypeOptions}
                  value={Object.keys(selectPaymentTypeOptions).length !== 0 ? selectPaymentTypeOptions : ''}
                  enabledValue={true}
                  name="paymentType"
                  searchEnable={true}
                  onChange={(e) => handleOnChange(e, 'dd', 'paymentType')}
              />
            </Col>
            {!hidePosBankField &&
                <Col sm="12" md="4">
                  <Dropdown
                      label={t('SalesNewEdit.posBankAccount')}
                      options={bankOptions}
                      value={Object.keys(selectPosBankAccount).length !== 0 ? selectPosBankAccount : ''}
                      enabledValue={true}
                      name="bankAccount"
                      onChange={(e) => handleOnChange(e, 'dd', 'bankAccount')}
                  />
                </Col>
            }
            <Col sm="12" md="4">
              <Dropdown
                  label={t('SalesNewEdit.salePurpose')}
                  options={salesPurposeOptions}
                  value={Object.keys(selectSalePurpose).length !== 0 ? selectSalePurpose : ''}
                  enabledValue={true}
                  searchEnable={true}
                  name="salePurpose"
                  onChange={(e) => handleOnChange(e, 'dd', 'salePurpose')}
              />
            </Col>
          </Row>
          <Row>
            <Col md="12" className="mb-3">
              <ul className="react-tabs__tab-list tab-style tab-min">
                {newSalesTab.map((tab, i) => (
                    <li key={i} className="react-tabs__tab">
                      <button
                          type="button"
                          onClick={() => setActiveTab(i)}
                          className={`customNavBtn ${activeTab === i && "btnActive"}`}
                      >
                        <span className="textWrapper">{tab.tabName}</span>
                      </button>
                    </li>
                ))}
              </ul>
              <div className="innerTabPanel">{newSalesTab[activeTab].tabData}</div>
            </Col>
          </Row>

          <Row>
            <Col sm="12" md="8">
              <EditableTable
                  className="sales-taxes-calculations"
                  allowActions={false}
                  key={Math.random()}
                  data={salesTaxesData}
                  pRequestData={pRequestData}
                  columns={salesTaxesColumns}
                  actionButtons={{
                    print: false,
                    delete: false,
                    export: false,
                    edit: false,
                  }}
              />
            </Col>
            <Col sm="12" md="4" className="d-flex justify-content-end">
              <span className="mr-3 fw-bold">{t('SalesNewEdit.totals.title')}</span>
              <table className="table table-bordered mb-3">
                <tbody>
                <tr><td className="fw-bold">{t('SalesNewEdit.totals.totalQuantity')}</td><td>{totalQuantity}</td></tr>
                <tr><td className="fw-bold">{t('SalesNewEdit.totals.totalBeforeDiscount')}</td><td>{getGreekCurrencyFormat(totalBeforeDiscount)}</td></tr>
                <tr><td className="fw-bold">{t('SalesNewEdit.totals.totalDiscount')}</td><td>{getGreekCurrencyFormat(totalDiscount)}</td></tr>
                <tr><td className="fw-bold">{t('SalesNewEdit.totals.totalNetValue')}</td><td>{getGreekCurrencyFormat(totalNetValue)}</td></tr>
                <tr><td className="fw-bold">{t('SalesNewEdit.totals.totalVATAmount')}</td><td>{getGreekCurrencyFormat(totalVatAmount)}</td></tr>
                <tr><td className="fw-bold">{t('SalesNewEdit.totals.totalWithHoldTaxesAmount')}</td><td>{getGreekCurrencyFormat(withHoldTaxes)}</td></tr>
                <tr><td className="fw-bold">{t('SalesNewEdit.totals.totalTaxesAmount')}</td><td>{getGreekCurrencyFormat(totalTaxes)}</td></tr>
                <tr><td className="fw-bold">{t('SalesNewEdit.totals.totalAmount')}</td><td>{getGreekCurrencyFormat(totalAmount)}</td></tr>
                <tr><td className="fw-bold">{t('SalesNewEdit.totals.paymentAmount')}</td><td>{getGreekCurrencyFormat(paymentAmount)}</td></tr>
                </tbody>
              </table>
            </Col>
          </Row>
          <Row>
            <div style={{ width: "100%" }}>
              <div className="text-muted mb-2"><i>{t('SalesNewEdit.sales')}</i></div>
              <TextArea
                  rows="4"
                  placeholder={t('SalesNewEdit.addNotes')}
                  name="notes"
                  value={SALES.editSales.notes}
                  onChange={(e) => handleOnChange(e)}
              />
            </div>
          </Row>

        </Form>

        {/* Confirm Category Change */}
        <Modal className="themeModal" show={showCateogryConfirmation}>
          <Modal.Header closeButton>
            <Modal.Title>{t('SalesNewEdit.warning')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{t('SalesNewEdit.warningMessage')}</Modal.Body>
          <Modal.Footer>
            <Button variant="outline-primary" onClick={() => setShowCateogryConfirmation(false)}>{t('SalesNewEdit.no')}</Button>
            <Button variant="primary" onClick={() => handleConfirmCategory()} >{t('SalesNewEdit.yes')}</Button>
          </Modal.Footer>
        </Modal>

        {process.env.REACT_APP_DEBUG_MODE === 'true' && SALES.editSales && <pre>{JSON.stringify(SALES.editSales, null, 2)}</pre>}
      </div>
  );
};

export default EditSales;
